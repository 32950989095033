import React, { useEffect, useMemo, useRef, useState, type KeyboardEventHandler } from 'react';
import { styled } from '@compiled/react';
import CheckIcon from '@atlaskit/icon/core/migration/check-mark--check';
import { Box, Pressable, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { ScreenReaderText } from '@atlassian/jira-accessibility/src/common/ui/screenreader-text/index.tsx';
import { AnnouncerV2 } from '@atlassian/jira-accessibility/src/ui/announcer-v2/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import {
	getPaletteEntryForMainColor,
	flatPalette,
} from '@atlassian/jira-polaris-lib-color-palette/src/ui/index.tsx';
import messages from './messages.tsx';

// replace with react 18 useId once stable
const useId = () => useMemo(() => `color-picker-header-id-${Math.random()}}`, []);

type ColorItemCheckIconProps = {
	isSelected: boolean;
	compact: boolean;
};

const ColorItemCheckIcon = ({ isSelected, compact }: ColorItemCheckIconProps) => {
	if (isSelected) {
		return <CheckIcon LEGACY_size={compact ? 'small' : 'medium'} color="currentColor" label="" />;
	}
	return null;
};

type ColorProps = {
	isSelected: boolean;
	isFocused: boolean;
	color: string;
	compact: boolean;
	onChange: (color: string | undefined) => void;
};

export const Color = ({ isSelected, isFocused, color, onChange, compact }: ColorProps) => {
	const palette = useMemo(() => getPaletteEntryForMainColor(color), [color]);

	const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
		if (event.code === 'Enter' || event.code === 'Space') {
			onChange(isSelected ? undefined : color);
		}
	};

	return (
		<ColorItem
			data-testid="polaris-lib-color-picker.ui.color-item"
			compact={compact}
			color={color}
			textColor={palette.textColor}
			tabIndex={isFocused ? 0 : -1}
			aria-selected={isSelected}
			aria-label={palette.textColor}
			role="gridcell"
			onKeyDown={handleKeyDown}
			onClick={() => (isSelected ? onChange(undefined) : onChange(color))}
		>
			<ColorItemCheckIcon isSelected={isSelected} compact={compact} />
		</ColorItem>
	);
};

export type ColorPickerProps = {
	compact: boolean;
	color: string | undefined;
	onChange: (color: string | undefined) => void;
	clearColorButtonLabel?: string;
};

export const ColorPicker = ({
	color,
	onChange,
	compact,
	clearColorButtonLabel,
}: ColorPickerProps) => {
	const { formatMessage } = useIntl();
	const [focusedColorIndex, setFocusedColorIndex] = useState(
		color ? flatPalette.findIndex((e) => e.mainColor === color) : 0,
	);
	const [shouldAnnounceColorChange, setShouldAnnounceColorChange] = useState(false);
	const colorGridRef = useRef<HTMLDivElement | null>(null);
	const isMountedRef = useRef(false);
	const previousColorRef = useRef(color);
	const headerId = useId();

	useEffect(() => {
		if (!colorGridRef.current) {
			return;
		}

		if (!isMountedRef.current) {
			isMountedRef.current = true;
			return;
		}

		colorGridRef.current.querySelector<HTMLElement>('[tabindex="0"]')?.focus();
	}, [focusedColorIndex]);

	useEffect(() => {
		setShouldAnnounceColorChange(previousColorRef.current !== color);
		previousColorRef.current = color;
	}, [color]);

	const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
		// enabling bottom and top arrows would require using table and tr elements to
		// have multiple rows, hence using a single "row" below
		if (event.key === 'ArrowRight') {
			setFocusedColorIndex(
				focusedColorIndex === flatPalette.length - 1 ? 0 : focusedColorIndex + 1,
			);
		} else if (event.key === 'ArrowLeft') {
			setFocusedColorIndex(
				focusedColorIndex === 0 ? flatPalette.length - 1 : focusedColorIndex - 1,
			);
		}
	};

	const handleClearFormattingClick = () => {
		onChange(undefined);
		setFocusedColorIndex(0);
	};

	return (
		<Box role="grid" aria-labelledby={headerId}>
			<ColorPickerHeading data-component-selector="color-picker-heading-3Ts5">
				<span id={headerId}>
					{formatMessage(messages.colorPickerHeader)}{' '}
					{color && (
						<ScreenReaderText>{formatMessage(messages.selectedColor, { color })}</ScreenReaderText>
					)}
				</span>
				{color !== undefined && (
					<Pressable
						testId="polaris-lib-color-picker.ui.clear-formatting"
						onClick={handleClearFormattingClick}
						xcss={clearFormattingButtonStyles}
					>
						{clearColorButtonLabel ?? formatMessage(messages.clearColorFormatting)}
					</Pressable>
				)}
			</ColorPickerHeading>
			<Box role="rowgroup">
				<ColorGrid compact={compact} role="row" onKeyDown={handleKeyDown} ref={colorGridRef}>
					{flatPalette.map(({ mainColor }, index) => (
						<Color
							key={mainColor}
							color={mainColor}
							isSelected={color === mainColor}
							isFocused={focusedColorIndex === index}
							onChange={onChange}
							compact={compact}
						/>
					))}
				</ColorGrid>
			</Box>
			<AnnouncerV2
				message={
					color
						? formatMessage(messages.colorChanged, { color })
						: formatMessage(messages.colorCleared)
				}
				shouldAnnounce={shouldAnnounceColorChange}
				liveMode="assertive"
			/>
		</Box>
	);
};

const clearFormattingButtonStyles = xcss({
	padding: '0',
	color: 'color.text.subtlest',
	background: 'none',
	font: token('font.body.small'),
	':hover': {
		textDecoration: 'underline',
		background: 'none',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ColorPickerHeading = styled.div({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	font: token('font.body.small'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest'),
	paddingTop: token('space.100'),
	paddingBottom: token('space.100'),
	overflow: 'hidden',
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ColorItem = styled.div<{ compact: any; color: string; textColor: string }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	borderRadius: ({ compact }) => `${compact ? 4 : 6}px`,
	boxSizing: 'border-box',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: ({ compact }) => `${compact ? 16 : 28}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: ({ compact }) => `${compact ? 16 : 28}px`,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: (props: any) => props.color,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	color: (props: any) => props.textColor,
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	'&:hover': {
		cursor: 'pointer',
	},
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any,  @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ColorGrid = styled.div<{ compact: any }>({
	marginBottom: token('space.100'),
	display: 'grid',
	gridTemplateColumns: 'auto auto auto auto auto auto auto auto auto',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	rowGap: ({ compact }) => `${compact ? token('space.025') : token('space.050')}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	columnGap: ({ compact }) => `${compact ? token('space.025') : token('space.050')}`,
	justifyContent: 'space-between',
});
