import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	buttonLabelLegacy: {
		id: 'polaris-ideas.right-sidebar.ideas-bucket.footer.button-label',
		defaultMessage: 'Create a new idea',
		description: 'Button label for creating new idea from ideas bucket',
	},
	buttonLabel: {
		id: 'polaris-ideas.right-sidebar.ideas-bucket.footer.button-label-generic',
		defaultMessage: 'Create',
		description: 'Button label for creating new issue from issues bucket',
	},
	placeholderLegacy: {
		id: 'polaris-ideas.right-sidebar.ideas-bucket.footer.placeholder',
		defaultMessage: 'Add a quick summary of your idea and press enter',
		description: 'Placeholder message visible when adding an idea',
	},
	placeholder: {
		id: 'polaris-ideas.right-sidebar.ideas-bucket.footer.placeholder-text',
		defaultMessage: 'Add a quick summary and press enter',
		description: 'Placeholder message visible when adding an issue',
	},
});
