import { useEffect, useState } from 'react';
import type { ProjectOption } from '@atlassian/jira-polaris-project-select/src/ui/types.tsx';
import { loadProjectsOptions } from '@atlassian/jira-polaris-project-select/src/ui/utils.tsx';
import {
	useEnvironmentContainer,
	PolarisEnvironmentContainerTypes,
} from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import { useIssueTypesForProject } from '@atlassian/jira-polaris-component-issue-types/src/controllers/index.tsx';
import { useProjectMetadata } from '@atlassian/jira-polaris-component-project-metadata/src/controllers/index.tsx';
import { useErrorHandlers } from '@atlassian/jira-polaris-lib-errors/src/controllers/index.tsx';

export const useCurrentProject = (): ProjectOption | null => {
	const container = useEnvironmentContainer();
	const projectId =
		container?.type === PolarisEnvironmentContainerTypes.PROJECT ? container.projectId : '';
	const currentProjectIssueTypes = useIssueTypesForProject({ projectId });
	const currentProjectMeta = useProjectMetadata({ projectId });

	if (!currentProjectMeta) {
		return null;
	}

	return {
		id: currentProjectMeta.id,
		value: currentProjectMeta.key,
		label: currentProjectMeta.name,
		imageUrl: currentProjectMeta.avatarUrls['16x16'],
		issueTypes: currentProjectIssueTypes.map(({ id, avatarId, name }) => ({
			id,
			avatarId: parseInt(avatarId, 10),
			name,
		})),
	};
};

export const useSelectedProjects = ({
	projectIds = [],
	currentProject,
}: {
	projectIds?: string[];
	currentProject: ProjectOption | null;
}) => {
	const hasOtherProjectsSelected = projectIds.some((id) => id !== currentProject?.id);
	const [selectedProjects, setSelectedProjects] = useState<Readonly<ProjectOption[]>>(
		!hasOtherProjectsSelected && currentProject ? [currentProject] : [],
	);
	const [loading, setLoading] = useState<boolean>(hasOtherProjectsSelected);
	const { generalDataLoadingFailedError } = useErrorHandlers();

	const loadProjectDetails = async () => {
		if (!hasOtherProjectsSelected) {
			return;
		}

		try {
			const projects = await loadProjectsOptions({ projectIds });
			setSelectedProjects(projects);
		} catch (err) {
			err instanceof Error && generalDataLoadingFailedError(err);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		loadProjectDetails();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		selectedProjects,
		setSelectedProjects,
		loading,
	};
};
