import React, { type PropsWithChildren } from 'react';
import uniqBy from 'lodash/uniqBy';
import isEqual from 'lodash/isEqual';
import noop from 'lodash/noop';
import { Stack, Text, xcss, Box, Inline } from '@atlaskit/primitives';
import { components } from '@atlaskit/select';
import Button, { IconButton } from '@atlaskit/button/new';
import Skeleton from '@atlaskit/skeleton';
import DeleteIcon from '@atlaskit/icon/core/delete';
import { useIntl } from '@atlassian/jira-intl';

import { ProjectSelect } from '@atlassian/jira-polaris-project-select/src/ui/index.tsx';
import type { ConnectionFieldIssueTypeNameFilter } from '@atlassian/jira-polaris-domain-field/src/field/connection/types.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import {
	useConnectionFieldIssueTypeIds,
	useConnectionFieldIssueTypeNameFilter,
	useFieldKey,
} from '../../../controllers/selectors/field-hooks.tsx';
import { useReadonly } from '../../../controllers/selectors/config-hooks.tsx';
import { useCallbacks } from '../../../controllers/selectors/callback-hooks.tsx';
import {
	ConnectionSourceSelect,
	ConnectionSourceSelectLegacy,
} from './connection-source-select/index.tsx';
import { messages } from './messages.tsx';
import { ProjectSelectControl } from './project-select/index.tsx';
import { useCurrentProject, useSelectedProjects } from './project-select/utils.tsx';
import { ProjectSelectMenuList } from './menu-list/index.tsx';

const Filter = ({
	onClear,
	isClearDisabled,
	children,
}: PropsWithChildren<{ isClearDisabled: boolean; onClear: () => void }>) => {
	const readonly = useReadonly();

	if (readonly) {
		return children;
	}

	return (
		<Inline space="space.100" alignBlock="center">
			<Box xcss={filterInputStyles}>{children}</Box>

			<IconButton
				appearance="subtle"
				icon={DeleteIcon}
				isDisabled={isClearDisabled}
				label="clear filter"
				onClick={onClear}
			/>
		</Inline>
	);
};

const ConnectionConfigurationNew = () => {
	const { formatMessage } = useIntl();
	const currentProject = useCurrentProject();
	const fieldKey = useFieldKey();
	const readonly = useReadonly();
	const connectionFieldIssueTypeFilter = useConnectionFieldIssueTypeNameFilter();
	const { onConnectionFilterUpdated } = useCallbacks();

	const { selectedProjects, setSelectedProjects, loading } = useSelectedProjects({
		projectIds: connectionFieldIssueTypeFilter.projectIds,
		currentProject,
	});

	const selectedProjectIds = selectedProjects.map(({ id }) => id);
	const hasOtherProjectsSelected = selectedProjectIds.some((id) => id !== currentProject?.id);

	const updateFilterAndRefreshFieldValues = async (
		filter: Omit<ConnectionFieldIssueTypeNameFilter, 'type'>,
	) => {
		if (!fieldKey) {
			return;
		}

		const getNewProjectIds = () => {
			if (filter.projectIds.length) {
				return filter.projectIds;
			}

			if (currentProject) {
				return [currentProject.id];
			}

			return [];
		};

		const newFilter: ConnectionFieldIssueTypeNameFilter = {
			...filter,
			type: 'name',
			projectIds: getNewProjectIds(),
		};

		if (!filter.projectIds.length) {
			setSelectedProjects(currentProject ? [currentProject] : []);
		}

		if (
			isEqual(
				[...newFilter.projectIds].sort(),
				[...connectionFieldIssueTypeFilter.projectIds].sort(),
			) &&
			isEqual(newFilter.names, connectionFieldIssueTypeFilter.names)
		) {
			return;
		}

		onConnectionFilterUpdated?.(newFilter);
	};

	const uniqIssueTypes = uniqBy(
		selectedProjects
			.map((p) => p.issueTypes)
			.filter(Boolean)
			.flat(),
		'name',
	);

	return (
		<Stack xcss={[containerStyles]} space="space.100">
			<Text weight="semibold">{formatMessage(messages.headerNonFinal)}</Text>
			{loading ? (
				<>
					<Skeleton height={40} width="100%" borderRadius={4} />
					<Skeleton height={40} width="100%" borderRadius={4} />
				</>
			) : (
				<>
					<Filter
						isClearDisabled={!hasOtherProjectsSelected}
						onClear={() =>
							updateFilterAndRefreshFieldValues({
								names: connectionFieldIssueTypeFilter.names,
								projectIds: [],
							})
						}
					>
						<ProjectSelect
							isDisabled={readonly}
							value={selectedProjects}
							components={{
								Input: () => null,
								MultiValue: () => null,
								ClearIndicator: () => null,
								ValueContainer: selectedProjects.length ? () => null : components.ValueContainer,
								Control: ProjectSelectControl,
								MenuList: ProjectSelectMenuList,
								DropdownIndicator: (props) => (
									<components.DropdownIndicator
										{...props}
										innerProps={{
											...props.innerProps,
											onMouseDown: noop,
											onTouchEnd: noop,
										}}
									/>
								),
							}}
							onMenuClose={() =>
								updateFilterAndRefreshFieldValues({
									names: connectionFieldIssueTypeFilter.names,
									projectIds: selectedProjectIds,
								})
							}
							onChange={(values) => setSelectedProjects(values)}
						/>
					</Filter>

					<Filter
						isClearDisabled={!connectionFieldIssueTypeFilter.names.length}
						onClear={() =>
							updateFilterAndRefreshFieldValues({
								names: [],
								projectIds: selectedProjectIds,
							})
						}
					>
						<ConnectionSourceSelect
							isDisabled={readonly}
							issueTypes={uniqIssueTypes}
							value={connectionFieldIssueTypeFilter.names[0]}
							onChange={(value) =>
								updateFilterAndRefreshFieldValues({
									names: value ? [value] : [],
									projectIds: selectedProjectIds,
								})
							}
						/>
					</Filter>

					{!readonly && (
						<Box xcss={clearButtonWrapperStyles}>
							<Button
								isDisabled={
									!connectionFieldIssueTypeFilter.names.length && !hasOtherProjectsSelected
								}
								onClick={() => updateFilterAndRefreshFieldValues({ names: [], projectIds: [] })}
							>
								{formatMessage(messages.clearAllButtonLabelNonFinal)}
							</Button>
						</Box>
					)}
				</>
			)}
		</Stack>
	);
};

const ConnectionConfigurationOld = () => {
	const { formatMessage } = useIntl();
	const readonly = useReadonly();
	const connectionFieldIssueTypeIds = useConnectionFieldIssueTypeIds();
	const { onConnectionFilterUpdated } = useCallbacks();

	return (
		<Stack xcss={containerStyles} space="space.100">
			<Text weight="semibold">{formatMessage(messages.headerNonFinal)}</Text>
			<ConnectionSourceSelectLegacy
				value={connectionFieldIssueTypeIds[0]}
				isDisabled={readonly}
				onChange={(value) => onConnectionFilterUpdated?.({ type: 'id', ids: value ? [value] : [] })}
			/>
		</Stack>
	);
};

export const ConnectionConfiguration = componentWithFG(
	'jpd_cross_project_connecting',
	ConnectionConfigurationNew,
	ConnectionConfigurationOld,
);

const clearButtonWrapperStyles = xcss({
	width: '85px',
});

const containerStyles = xcss({
	paddingBlock: 'space.200',
});

const filterInputStyles = xcss({ flex: 1 });
