import React from 'react';
import { styled } from '@compiled/react';
import Avatar from '@atlaskit/avatar';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useAllUserInformationOnIssues } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/users-hooks.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import messages from './messages.tsx';

type ColumnHeaderComponentProps = {
	fieldKey: FieldKey;
	groupIdentity: string;
};

const UserColumnHeader = ({ groupIdentity }: ColumnHeaderComponentProps) => {
	const issueUserInfos = useAllUserInformationOnIssues();
	const userInfo = issueUserInfos[groupIdentity];

	if (userInfo === undefined) {
		return <Avatar />;
	}

	return (
		<Container>
			<Avatar appearance="circle" src={userInfo.avatarUrls?.['48x48']} size="small" />
			<DisplayName>{userInfo.displayName}</DisplayName>
		</Container>
	);
};

const EmptyUserColumnHeader = () => {
	const { formatMessage } = useIntl();

	return (
		<Container>
			<Avatar appearance="circle" size="small" />
			<DisplayName>{formatMessage(messages.emptyColumnHeader)}</DisplayName>
		</Container>
	);
};

export const userColumnHeaderConfig = {
	isHideable: true,
	isDecoratable: false,
	Component: UserColumnHeader,
	EmptyComponent: EmptyUserColumnHeader,
} as const;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	maxWidth: 'fit-content',
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DisplayName = styled.div({
	paddingLeft: token('space.100'),
});
