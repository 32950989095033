// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import {
	MAX_COLUMN_WIDTH,
	MIN_COLUMN_WIDTH,
	TIMELINE_END_SPACE,
} from '../../../common/constants.tsx';
import type { Scale } from '../../../common/types/timeline/index.tsx';
import type { Props } from '../../../types.tsx';
import { getColumnsCount, getHasOffset } from '../../selectors/timeline.tsx';
import type { State } from '../../types.tsx';

export const setColumnWidth =
	(isResizing = false, prevScale?: Scale): Action<State, Props> =>
	(
		{ setState, getState },
		{ minColumnWidth = MIN_COLUMN_WIDTH, maxColumnWidth = MAX_COLUMN_WIDTH, containerRef },
	) => {
		if (!containerRef || !containerRef.current) {
			return;
		}

		const state = getState();
		const isScaleChanged = prevScale && prevScale !== state.scale;
		const columnsCount = getColumnsCount(state);
		const hasOffset = getHasOffset(state);
		const containerWidth = containerRef.current.clientWidth;
		const timelineWrapperWidth = containerRef.current.children[0]?.clientWidth;

		if (
			timelineWrapperWidth !== 0 &&
			!isScaleChanged &&
			(hasOffset || (!isResizing && containerWidth < timelineWrapperWidth))
		) {
			return;
		}

		const columnWidth = Math.min(
			Math.max(
				(containerWidth - TIMELINE_END_SPACE) / columnsCount,
				minColumnWidth - TIMELINE_END_SPACE / columnsCount,
			),
			maxColumnWidth,
		);

		setState({
			columnWidth: columnWidth > minColumnWidth ? columnWidth : minColumnWidth,
		});
	};
