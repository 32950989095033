import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	title: {
		id: 'polaris-lib-onboarding-flows.invite-from-share-dialog.spotlight.changeboarding-title',
		defaultMessage: 'Add your teammates',
		description: 'Title for the invite from share dialog spotlight',
	},
	content: {
		id: 'polaris-lib-onboarding-flows.invite-from-share-dialog.spotlight.changeboarding-content',
		defaultMessage:
			'Collaborate on prioritization discussions and align your team with product roadmaps.',
		description: 'Content for the invite from share dialog spotlight',
	},
	cta: {
		id: 'polaris-lib-onboarding-flows.invite-from-share-dialog.spotlight.cta',
		defaultMessage: 'Add people',
		description: 'CTA text for the invite from share dialog spotlight',
	},
});
