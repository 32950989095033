import React, { useRef } from 'react';
import Button from '@atlaskit/button/new';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import ModalDialog, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
	ModalTransition,
} from '@atlaskit/modal-dialog';
import { Text } from '@atlaskit/primitives';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { messages } from './messages.tsx';
import type { DeleteViewModalProps } from './types.tsx';

export const DeleteViewModal = ({ isOpen, title, onCancel, onConfirm }: DeleteViewModalProps) => {
	const { formatMessage } = useIntl();
	const focusRef = useRef<HTMLButtonElement>(null);

	return (
		<ModalTransition>
			{isOpen && (
				<ShortcutScope>
					<ModalDialog
						// eslint-disable-next-line jsx-a11y/no-autofocus
						autoFocus={focusRef}
						onClose={onCancel}
					>
						<ModalHeader>
							<ModalTitle appearance="danger">
								{formatMessage(messages.deleteHeader, { title })}
							</ModalTitle>
						</ModalHeader>
						<ModalBody>
							<Text>{formatMessage(messages.description, { title })}</Text>
						</ModalBody>
						<ModalFooter>
							<Button onClick={onCancel}>{formatMessage(messages.cancel)}</Button>
							<Button appearance="danger" onClick={onConfirm} ref={focusRef}>
								{formatMessage(messages.delete)}
							</Button>
						</ModalFooter>
					</ModalDialog>
				</ShortcutScope>
			)}
		</ModalTransition>
	);
};
