import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import { ROW_GAP } from '../../common/constants.tsx';
import type { GridIntervals } from '../../common/types/timeline/index.tsx';
import {
	getTimelinePrimaryGridInterval,
	getTimelineSecondaryGridInterval,
} from '../../common/utils.tsx';
import { type GroupId, type GroupedItemArrangement, NO_VALUE_GROUP_ID } from '../../types.tsx';
import type { State, Selector } from '../types.tsx';
import { getMaxRow } from '../utils/arrangement.tsx';

export const getScale = (state: State) => state.scale;

export const getPrimaryGridInterval = createSelector(getScale, (scale) =>
	getTimelinePrimaryGridInterval(scale),
);
export const getSecondaryGridInterval = createSelector(getScale, (scale) =>
	getTimelineSecondaryGridInterval(scale),
);
export const getGridIntervals = createSelector(
	getPrimaryGridInterval,
	getSecondaryGridInterval,
	(primaryGridInterval, secondaryGridInterval): GridIntervals => [
		primaryGridInterval,
		secondaryGridInterval,
	],
);

export const getColumnsHeaders = (state: State) => state.headers;
export const getColumnsCount = createSelector(
	getColumnsHeaders,
	getPrimaryGridInterval,
	(items, primaryGridInterval) => items.length * primaryGridInterval,
);

export const getGroupedItemRows: Selector<GroupedItemArrangement> = (state: State) =>
	state.itemArrangement;

export const createGetRowsCount = (groupId?: GroupId) =>
	createSelector(getGroupedItemRows, (groupedItemRows) => {
		const itemRows = groupedItemRows[groupId || NO_VALUE_GROUP_ID];
		const maximumRow = getMaxRow(itemRows);
		return isEmpty(itemRows) && !groupId
			? 0
			: Math.max(maximumRow !== undefined ? maximumRow : -1, 0) + 1;
	});

export const isReadOnly = (state: State) => state.isReadOnly;
export const isSorted = (state: State) => state.isSorted;

export const isGroupByFieldEditable = (state: State) => state.isGroupByFieldEditable;
export const isMoveBetweenGroupsDisabled = (state: State) => state.isMoveBetweenGroupsDisabled;

export const isVerticalCardReorderingEnabled = (state: State) =>
	state.isVerticalCardReorderingEnabled;

export const getItemHeight = (state: State) => state.itemHeight;
export const getRowHeight = (state: State) => state.itemHeight + ROW_GAP;

const getColumnWidthInternal = (state: State) => state.columnWidth;
export const getColumnWidth = createSelector(getColumnWidthInternal, (columnWidth) => columnWidth);

export const getResizePosition = (state: State) => state.resizePosition;

export const isMarkerReadOnly = (state: State) => state.isMarkerReadOnly;
export const getStartDate = (state: State) => state.startDate;
export const getEndDate = (state: State) => state.endDate;
export const getMarkers = (state: State) => {
	const sortedMarkers = [...state.markers];
	// Sort the markers in descending order by date for the correct rendering queue
	return sortedMarkers.sort(({ date: dateA }, { date: dateB }) => dateB.localeCompare(dateA));
};
export const getHasMarkers = createSelector(getMarkers, (markers) => markers.length > 0);
export const getMinColumnWidth = (state: State) => state.minColumnWidth;

export const getHasOffset = (state: State) => state.hasOffset;
