import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import type { ProjectActorsResponse, Filter } from './types.tsx';

const getQuery = () =>
	`query ProjectActorsQuery($filter: ProjectActorInputs, $projectId: Long!) {
  projectActors(filter: $filter, projectId: $projectId) {
    actors {
      email
      avatarUrl
      active
      accountId
      roleTypeId
      type
      displayName
      roles     
    }
    isLastBatch
  }
}`;

export const generateGraphqlUrl = (): string => '/rest/gira/latest/?jpd-project-actors';

export const getProjectActors = (
	projectId: string,
	filter: Filter,
): Promise<ProjectActorsResponse> =>
	fetchJson(generateGraphqlUrl(), {
		method: 'POST',
		body: JSON.stringify({
			operationName: 'ProjectActorsQuery',
			query: getQuery(),
			variables: {
				filter,
				projectId,
			},
		}),
	}).then((response) => response?.data?.projectActors);
