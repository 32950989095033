import React, { useEffect } from 'react';
import Avatar from '@atlaskit/avatar';
import { Inline, Text, xcss } from '@atlaskit/primitives';
import { SkeletonCompiled } from '@atlassian/jira-common-components-skeleton/src/styled.tsx';
import { useCollectionOwnerId } from '@atlassian/jira-polaris-component-collections/src/controllers/collections/index.tsx';
import { useCollectionOwner } from '@atlassian/jira-polaris-component-collections/src/controllers/combined-selectors.tsx';
import {
	useUsersLoading,
	useUsersHasError,
	useUsersActions,
} from '@atlassian/jira-polaris-component-collections/src/controllers/users/index.tsx';
import { useErrorHandlers } from '@atlassian/jira-polaris-lib-errors/src/controllers/index.tsx';

type Props = {
	collectionUUID: string;
};

export const CollectionOwner = ({ collectionUUID }: Props) => {
	const isLoading = useUsersLoading();
	const hasError = useUsersHasError();
	const collectionOwner = useCollectionOwner({ collectionUUID });
	const { loadUsers } = useUsersActions();
	const { generalDataLoadingFailedError } = useErrorHandlers();
	const ownerId = useCollectionOwnerId({ collectionUUID });

	useEffect(() => {
		if (ownerId) {
			loadUsers([ownerId]).catch(generalDataLoadingFailedError);
		}
	}, [generalDataLoadingFailedError, loadUsers, ownerId]);

	if (isLoading || hasError) {
		return (
			<Inline xcss={ownerSkeletonContainerStyles}>
				<SkeletonCompiled height="24px" width="24px" />
			</Inline>
		);
	}

	return (
		<Inline alignBlock="center" space="space.100">
			<Avatar
				appearance="circle"
				src={collectionOwner?.avatarUrls['48x48']}
				size="small"
				name={collectionOwner?.displayName}
			/>
			<Text weight="medium">{collectionOwner?.displayName}</Text>
		</Inline>
	);
};

const ownerSkeletonContainerStyles = xcss({
	borderRadius: 'border.radius.circle',
	height: '24px',
	width: '24px',
	overflow: 'hidden',
});
