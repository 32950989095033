import type { ViewUUID } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { CollabParticipant, User } from '../../../domain/types.tsx';
import type { State } from '../types.tsx';

export const presenceJoined =
	(viewUUID: ViewUUID, joined: CollabParticipant[]) =>
	({ getState, setState }: StoreActionApi<State>) => {
		const newParticipantSessionIds = joined.map(({ sessionId }) => sessionId);
		setState({
			...getState(),
			views: {
				...getState().views,
				[viewUUID]: {
					...(getState().views[viewUUID] || {}),
					presence: [
						...joined,
						...(getState().views[viewUUID].presence || []).filter(
							({ sessionId }) => !newParticipantSessionIds.includes(sessionId),
						),
					],
				},
			},
		});
	};

export const updatePresence =
	(viewUUID: ViewUUID, user: User | {}) =>
	({ getState, setState }: StoreActionApi<State>) => {
		setState({
			...getState(),
			views: {
				...getState().views,
				[viewUUID]: {
					...(getState().views[viewUUID] || {}),
					presence: (getState().views[viewUUID]?.presence || []).map((participant) => {
						if ('userId' in user && participant.clientId === user.userId) {
							return {
								...participant,
								avatar: user.avatar,
								name: user.name,
								email: user.email,
							};
						}
						return participant;
					}),
				},
			},
		});
	};

export const presenceLeft =
	(viewUUID: ViewUUID, left: { sessionId: string }[]) =>
	({ getState, setState }: StoreActionApi<State>) => {
		const leftSessionIds = left.map(({ sessionId }) => sessionId);
		setState({
			...getState(),
			views: {
				...getState().views,
				[viewUUID]: {
					...(getState().views[viewUUID] || {}),
					presence: [
						...(getState().views[viewUUID]?.presence || []).filter(
							({ sessionId }) => !leftSessionIds.includes(sessionId),
						),
					],
				},
			},
		});
	};
