import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	timelineFieldsSpotlightText: {
		id: 'polaris-lib-onboarding-flows.timeline.section-message.timeline-fields-spotlight-text',
		defaultMessage:
			'Admins can configure the start or end date fields to reflect dates from linked delivery tickets automatically.',
		description: 'Spotlight help message for admins to configure timeline fields',
	},
	timelineFieldsSpotlightTextIssueTermRefresh: {
		id: 'polaris-lib-onboarding-flows.timeline.section-message.timeline-fields-spotlight-text-issue-term-refresh',
		defaultMessage:
			'Admins can configure the start or end date fields to reflect dates from linked work items automatically.',
		description: 'Spotlight help message for admins to configure timeline fields',
	},
	timelineFieldsSpotlightOkBtn: {
		id: 'polaris-lib-onboarding-flows.timeline.section-message.timeline-fields-spotlight-ok-btn',
		defaultMessage: 'Got it',
		description: 'Section message action button',
	},
	timelineFieldsSpotlightLearnMore: {
		id: 'polaris-lib-onboarding-flows.timeline.section-message.timeline-fields-spotlight-learn-more',
		defaultMessage: 'Learn more',
		description: 'Section message learn more button',
	},
});
