import { useMemo, useCallback, useState } from 'react';
import sumBy from 'lodash/sumBy';
import debounce from 'lodash/debounce';
import type { GroupBase, OptionsOrGroups } from '@atlaskit/react-select';
import { createFilter } from '@atlaskit/select';
import { getSearchParams } from '@atlassian/jira-polaris-common/src/ui/common/issue-select/utils.tsx';
import { useAllIssueTypeValues } from '@atlassian/jira-polaris-component-issue-types/src/controllers/index.tsx';
import { useCanCreateAndEditIssues } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { useIssueRemote } from '@atlassian/jira-polaris-remote-issue/src/main.tsx';
import { useProjectsIdsForContainer } from '@atlassian/jira-polaris-component-project-metadata/src/controllers/index.tsx';
import { useEnvironmentContainerId } from '@atlassian/jira-polaris-component-environment-container/src/controllers/store/index.tsx';
import { isIssueTypeNameFilter } from '@atlassian/jira-polaris-domain-field/src/field/connection/types.tsx';
import { logSafeErrorWithoutCustomerDataWrapper } from '@atlassian/jira-polaris-lib-errors/src/common/utils/index.tsx';
import {
	ISSUETYPE_FIELDKEY,
	SUMMARY_FIELDKEY,
} from '@atlassian/jira-polaris-domain-field/src/field/constants.tsx';
import type { RemoteIssue } from '@atlassian/jira-polaris-remote-issue/src/controllers/crud/types.tsx';
import { useConnectionFieldIssueTypeIds } from '../../../controllers/field/selectors/field-hooks.tsx';
import { useField } from '../../../controllers/issue/selectors/fields-hooks.tsx';
import {
	useAllIssueIdsMatchingConnectionFieldFilters,
	useConnectionFieldSortedIssueIds,
} from '../../../controllers/issue/selectors/connection-hooks.tsx';
import {
	useLocalIssueIdToJiraIssueId,
	useLocalIssueIdsByJiraIssueId,
} from '../../../controllers/issue/selectors/issue-ids-hooks.tsx';
import {
	useIssueTypes,
	useKeys,
	useSummaries,
} from '../../../controllers/issue/selectors/properties/hooks.tsx';
import type { IssueForConnectionField } from '../../../controllers/issue/types.tsx';
import {
	isCreatableOption,
	type ConnectionFieldOption,
	type CreatableConnectionFieldOption,
	type SelectableConnectionFieldOption,
} from './types.tsx';
import { MAX_OPTIONS } from './constants.tsx';

const sortIssueOptions = (issue1: { label: string }, issue2: { label: string }) =>
	issue1.label.localeCompare(issue2.label);

export const useConnectionFieldOptions = (
	localIssueId: string,
	fieldKey: string,
	siteIssues: IssueForConnectionField[],
	issueCardType?: SelectableConnectionFieldOption['issueCardType'],
) => {
	const localIssueIdToJiraId = useLocalIssueIdToJiraIssueId();
	const localIssueIdByJiraIssueId = useLocalIssueIdsByJiraIssueId();
	const linkedIssueIds = useConnectionFieldSortedIssueIds(fieldKey, localIssueId);
	const projectIssuesIds = useAllIssueIdsMatchingConnectionFieldFilters(localIssueId, fieldKey);
	const issueTypes = useIssueTypes();
	const summaries = useSummaries();
	const keys = useKeys();

	const projectOptions: SelectableConnectionFieldOption[] = useMemo(
		() =>
			projectIssuesIds
				.map<SelectableConnectionFieldOption>((id) => ({
					value: localIssueIdToJiraId[id],
					label: summaries[id],
					issueKey: keys[id],
					issueType: issueTypes[id],
					type: 'selectable',
					issueCardType,
				}))
				.sort(sortIssueOptions),
		[projectIssuesIds, issueTypes, keys, localIssueIdToJiraId, summaries, issueCardType],
	);

	const siteOptions: SelectableConnectionFieldOption[] = useMemo(
		() =>
			siteIssues
				.map<SelectableConnectionFieldOption>((issue) => ({
					value: issue.id,
					label: issue.summary,
					issueKey: issue.issueKey,
					issueType: issue.issueType,
					type: 'selectable',
					issueCardType,
				}))
				.sort(sortIssueOptions),
		[issueCardType, siteIssues],
	);

	const selectedOptions: SelectableConnectionFieldOption[] = linkedIssueIds
		.map<SelectableConnectionFieldOption>(({ id }) => {
			const jiraId = parseInt(id, 10);
			const localId = localIssueIdByJiraIssueId[jiraId];

			return {
				value: id,
				issueKey: keys[localId],
				label: summaries[localId],
				issueType: issueTypes[localId],
				type: 'selectable',
				issueCardType,
			};
		})
		.sort(sortIssueOptions);

	return [projectOptions, siteOptions, selectedOptions] as const;
};

export const isGroup = (
	optionOrGroup: ConnectionFieldOption | GroupBase<ConnectionFieldOption>,
): optionOrGroup is GroupBase<ConnectionFieldOption> => 'options' in optionOrGroup;

export const filterOption = (option: ConnectionFieldOption, inputValue: string) => {
	const { searchString } = getSearchParams(inputValue);

	if (isCreatableOption(option)) {
		const filter = createFilter<CreatableConnectionFieldOption>({
			stringify: () => inputValue,
		});
		return filter({ value: inputValue, label: inputValue, data: option }, searchString);
	}

	const filter = createFilter<SelectableConnectionFieldOption>({
		stringify: ({ data }) => `${data.label} ${data.issueKey}`,
	});
	return filter({ value: option.value, label: option.label, data: option }, searchString);
};

export const getTotalOptions = (
	options: OptionsOrGroups<ConnectionFieldOption, GroupBase<ConnectionFieldOption>>,
) => sumBy(options, (option) => (isGroup(option) ? option.options.length : 1));

export const useCreatableOptions = (fieldKey: string): CreatableConnectionFieldOption[] => {
	const connectionFieldIssueTypeIds = useConnectionFieldIssueTypeIds(fieldKey);
	const allIssueTypesValues = useAllIssueTypeValues();
	const canCreateAndEditIssues = useCanCreateAndEditIssues();

	if (!canCreateAndEditIssues) {
		return [];
	}

	return connectionFieldIssueTypeIds
		.map((id): CreatableConnectionFieldOption | null => {
			const issueType = allIssueTypesValues.get(id);

			if (!issueType) {
				return null;
			}

			return {
				issueType,
				type: 'creatable',
			};
		})
		.filter(Boolean);
};

const RELOAD_SITE_ISSUES_DEBOUNCE_TIME = 500;

type UseSiteIssuesRequest = { fieldKey: FieldKey };
type UseSiteIssuesResponse = {
	siteIssues: IssueForConnectionField[];
	appliedIssueNameSearch: string | undefined;
	loadSiteIssues: (issueNameSearch?: string) => Promise<void>;
	debouncedLoadSiteIssues: (issueNameSearch?: string) => void;
};

export const useSiteIssues = ({ fieldKey }: UseSiteIssuesRequest): UseSiteIssuesResponse => {
	const { fetchIssuesForProjects } = useIssueRemote();
	const field = useField(fieldKey);
	const containerId = useEnvironmentContainerId();
	const containerProjectIds = useProjectsIdsForContainer({ containerId });
	const [siteIssues, setSiteIssues] = useState<IssueForConnectionField[]>([]);
	const [appliedIssueNameSearch, setAppliedIssueNameSearch] = useState<string | undefined>(
		undefined,
	);

	const loadSiteIssues = useCallback(
		async (issueNameSearch?: string) => {
			if (fg('jpd_cross_project_connecting')) {
				const issueTypeNameFilter =
					field?.configuration?.issueTypeFilters?.find(isIssueTypeNameFilter);

				if (
					!issueTypeNameFilter ||
					issueTypeNameFilter.projectIds.length === 0 ||
					(issueTypeNameFilter.projectIds.length === 1 &&
						containerProjectIds.includes(issueTypeNameFilter.projectIds[0]))
				) {
					setSiteIssues([]);
					setAppliedIssueNameSearch(issueNameSearch ?? '');
					return;
				}

				try {
					const result = await fetchIssuesForProjects({
						projectIds: issueTypeNameFilter.projectIds.filter(
							(id) => !containerProjectIds.includes(id),
						),
						issueTypeNames: issueTypeNameFilter.names,
						fields: [SUMMARY_FIELDKEY, ISSUETYPE_FIELDKEY],
						maxResults: MAX_OPTIONS,
						issueNameSearch,
					});

					const mappedSiteIssues = result.issues.map((issue: RemoteIssue) => ({
						id: issue.id,
						summary: issue.fields.summary,
						issueKey: issue.key,
						issueType: issue.fields[ISSUETYPE_FIELDKEY],
					}));

					setSiteIssues(mappedSiteIssues);
				} catch (error) {
					logSafeErrorWithoutCustomerDataWrapper(
						'polaris.error.loadIssuesForConnectionField',
						'Failed to load issues from different projects for connection field',
						error instanceof Error
							? error
							: new Error('Failed to load issues from different projects for connection field'),
					);
					setSiteIssues([]);
				}
				setAppliedIssueNameSearch(issueNameSearch ?? '');
			}
		},
		[containerProjectIds, fetchIssuesForProjects, field],
	);

	const debouncedLoadSiteIssues = useMemo(
		() => debounce(loadSiteIssues, RELOAD_SITE_ISSUES_DEBOUNCE_TIME),
		[loadSiteIssues],
	);

	return {
		siteIssues,
		appliedIssueNameSearch,
		loadSiteIssues,
		debouncedLoadSiteIssues,
	};
};
