import React, { type ReactNode, useEffect, useRef, useState } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useLeftSidebarFlyoutLock } from '@atlassian/jira-navigation-system/src/index.tsx';
import { TextField } from '@atlassian/jira-polaris-common/src/common/ui/field/text-field/index.tsx';
import { VIEW_NAME_MAX_LENGTH } from '@atlassian/jira-polaris-component-navigation-store/src/common/constants.tsx';

type Props = {
	isRenaming: boolean;
	title: string;
	disableTooltip?: boolean;
	onChanged: (arg1: string) => void;
	onCancel: (arg1: string | undefined) => void;
};

const ClickBoundary = (props: { isVisible?: boolean; children?: ReactNode }) => {
	useLeftSidebarFlyoutLock();
	return (
		<Container
			isVisible={props.isVisible}
			onClick={(e) => e.stopPropagation()}
			onMouseDown={(e) => e.stopPropagation()}
			onMouseUp={(e) => e.stopPropagation()}
		>
			{props.children}
		</Container>
	);
};

export const EditableTitle = (props: Props) => {
	const { title, onChanged, onCancel, isRenaming, disableTooltip } = props;
	const ref = useRef<HTMLDivElement | null>(null);
	const [isOverflown, setIsOverflown] = useState(false);
	useEffect(() => {
		const element = ref?.current;
		if (element) {
			setIsOverflown(element.scrollHeight > element.clientHeight);
		}
	}, []);

	return isRenaming ? (
		<ClickBoundary isVisible>
			<TextFieldContainer>
				<TextField
					defaultValue={title}
					isCompact
					isFocused
					onCancel={onCancel}
					onConfirm={onChanged}
					maxLength={VIEW_NAME_MAX_LENGTH}
				/>
			</TextFieldContainer>
		</ClickBoundary>
	) : (
		<Tooltip
			content={isOverflown && !disableTooltip ? title : null}
			delay={700}
			hideTooltipOnMouseDown
		>
			<TitleWrapper ref={ref}>{title}</TitleWrapper>
		</Tooltip>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TitleWrapper = styled.div({
	display: '-webkit-box',
	WebkitLineClamp: '2',
	WebkitBoxOrient: 'vertical',
	maxHeight: '36px',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	wordBreak: 'break-word',
	font: token('font.body'),
	minHeight: 'calc(2em - 8px)',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	paddingTop: () => token('space.050'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	paddingBottom: () => token('space.050'),
	marginRight: token('space.050'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div<{ isVisible?: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	display: ({ isVisible }) => (!isVisible ? 'none' : 'initial'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TextFieldContainer = styled.div({
	marginLeft: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&&': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		input: {
			height: 'calc(2em - 4px)',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
			padding: () => `3px ${token('space.075')} ${token('space.050')} ${token('space.075')}`,
		},
	},
});
