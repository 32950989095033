import React, { memo } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';

type UserFieldLoadingProps = {
	name: string;
};

export const UserFieldLoading = memo<UserFieldLoadingProps>(({ name }: UserFieldLoadingProps) => (
	<Wrapper>
		<Skeleton />
		<Container>
			<Text>{name}</Text>
		</Container>
	</Wrapper>
));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div({
	alignItems: 'center',
	borderWidth: '2px',
	borderStyle: 'solid',
	borderColor: 'transparent',
	boxSizing: 'border-box',
	color: 'inherit',
	display: 'flex',

	fontWeight: token('font.weight.regular'),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: 1,
	outline: 'none',
	margin: 0,
	paddingTop: token('space.050'),
	paddingRight: token('space.050'),
	paddingBottom: token('space.050'),
	paddingLeft: token('space.050'),
	textAlign: 'left',
	textDecoration: 'none',
	width: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	minWidth: 0,
	maxWidth: '100%',
	paddingLeft: token('space.100'),
	flex: '1 1 100%',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: '1.4',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Text = styled.div({
	display: 'block',
	margin: 0,
	color: token('color.text'),
	overflowX: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Skeleton = styled.div({
	width: '16px',
	height: '16px',
	minHeight: '16px',
	minWidth: '16px',
	display: 'inline-block',
	borderRadius: '50%',
	backgroundColor: 'currentcolor',
	borderWidth: '2px',
	borderStyle: 'solid',
	borderColor: 'transparent',
	opacity: 0.15,
});
