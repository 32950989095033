// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import { getPermissionConfiguration } from '../../../services/jira/get-permission-configuration/index.tsx';
import type { State, Props } from '../../types.tsx';

export const fetchPermissionConfiguration =
	(): Action<State, Props> =>
	({ setState }, { projectId, onError }) => {
		getPermissionConfiguration(projectId)
			.then((response) => {
				const newState = {
					permissions: response.permissions,
					categories: response.categories.filter(({ key }) => key !== 'APP_PERMISSIONS'),
				};

				setState(newState);
			})
			.catch((err) => onError(err));
	};
