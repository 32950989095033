import { usePermissionActions } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/main.tsx';
import { useProjectPropertiesActions } from '../../index.tsx';
import { PROJECT_PROPERTIES } from '../../types.tsx';

export const useSaveIsIssueCreationEnabled = () => {
	const { saveProperty } = useProjectPropertiesActions();
	const { setContributorCreateIssuePermission } = usePermissionActions();

	return (isEnabled: boolean) => {
		saveProperty(PROJECT_PROPERTIES.IDEA_CREATION_BY_CONTRIBUTORS, isEnabled);
		setContributorCreateIssuePermission?.(isEnabled);
	};
};

export const useSaveSubmitIdeaFormViewId = () => {
	const { saveProperty } = useProjectPropertiesActions();

	return (viewId: string | undefined) =>
		saveProperty(PROJECT_PROPERTIES.SUBMIT_IDEA_FORM_VIEW, viewId);
};

export const useSaveSubmitIdeaFormMandatoryFields = () => {
	const { saveProperty } = useProjectPropertiesActions();

	return (areFieldsMandatory: boolean) =>
		saveProperty(PROJECT_PROPERTIES.SUBMIT_IDEA_FORM_MANDATORY_FIELDS, areFieldsMandatory);
};

export const useSaveSubmitIdeaDescriptionTemplateId = () => {
	const { saveProperty } = useProjectPropertiesActions();

	return (templateId: string | undefined) =>
		saveProperty(PROJECT_PROPERTIES.SUBMIT_IDEA_FORM_DESCRIPTION_TEMPLATE, templateId);
};
