import React, { useMemo } from 'react';
import ArrowRightIcon from '@atlaskit/icon/core/migration/arrow-right';
import { Flex, Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { useSourceField } from '../../../../../../controllers/index.tsx';
import { useTargetField } from '../../../../../../controllers/options-mapping/index.tsx';
import { FieldOptionItem } from './field-option-selector/field-option-item/index.tsx';
import { FieldOptionSelector, type FieldOptionData } from './field-option-selector/index.tsx';
import messages from './messages.tsx';
import { Section } from './options-section/index.tsx';

const useFieldOptionsById = (field: Field | undefined) =>
	useMemo(
		() =>
			field?.options?.reduce<Record<string, FieldOptionData>>((acc, option) => {
				acc[option.id] = option;
				return acc;
			}, {}) ?? {},
		[field],
	);

export type OptionsValueMapping = Record<
	string,
	{ optionId: string | undefined; automapped: boolean } | undefined
>;

type OptionsMapperProps = {
	optionValueMapping: OptionsValueMapping;
	onSetOptionValueMapping: (fromOptionId: string, toOptionId: string | undefined) => void;
};

export const OptionsMapper = ({
	optionValueMapping,
	onSetOptionValueMapping,
}: OptionsMapperProps) => {
	const fromSelectField = useSourceField();
	const toSelectField = useTargetField();

	const fromSelectFieldOptionsById = useFieldOptionsById(fromSelectField);
	const toSelectFieldOptionsById = useFieldOptionsById(toSelectField);

	if (!fromSelectField || !toSelectField) {
		return null;
	}

	return (
		<>
			{Object.entries(optionValueMapping).map(([fromOptionId, toOption]) => (
				<Flex
					xcss={optionsRowStyles}
					key={fromOptionId}
					testId="polaris-component-copy-values.ui.copy-values-sidebar.copy-values-form.options-mapping.options-mapping-modal.field-options-mapper.option"
				>
					<Flex alignItems="center" xcss={fieldOptionStyles}>
						<FieldOptionItem
							optionValue={fromSelectFieldOptionsById[fromOptionId].value}
							optionId={fromOptionId}
							fieldKey={fromSelectField.key}
						/>
					</Flex>
					<Flex alignItems="center" justifyContent="center" xcss={fieldNameArrowIconWrapperStyles}>
						<ArrowRightIcon spacing="spacious" label="" color={token('color.icon.accent.gray')} />
					</Flex>
					<FieldOptionSelector
						optionId={toOption?.optionId}
						options={toSelectFieldOptionsById}
						field={toSelectField}
						onChange={(optionId) => {
							onSetOptionValueMapping(fromOptionId, optionId);
						}}
					/>
				</Flex>
			))}
		</>
	);
};

type GroupedOptionsProps = OptionsMapperProps;

export const GroupedOptions = ({ optionValueMapping, ...restProps }: GroupedOptionsProps) => {
	const { formatMessage } = useIntl();

	const { automappedOptions, otherOptions } = useMemo(() => {
		const automappedOptionsMap: Record<
			string,
			{ optionId: string | undefined; automapped: boolean } | undefined
		> = {};
		const otherOptionsMap: Record<
			string,
			{ optionId: string | undefined; automapped: boolean } | undefined
		> = {};

		for (const [fromOptionId, toOption] of Object.entries(optionValueMapping)) {
			if (toOption?.automapped) {
				automappedOptionsMap[fromOptionId] = toOption;
			} else {
				otherOptionsMap[fromOptionId] = toOption;
			}
		}

		return {
			automappedOptions: automappedOptionsMap,
			otherOptions: otherOptionsMap,
		};
	}, [optionValueMapping]);

	return (
		<>
			<Section
				testId="polaris-component-copy-values.ui.copy-values-sidebar.copy-values-form.options-mapping.options-mapping-modal.field-options-mapper.to-map"
				title={formatMessage(messages.mapOptions)}
				defaultExpanded
			>
				<OptionsMapper {...restProps} optionValueMapping={otherOptions} />
			</Section>
			<Section
				testId="polaris-component-copy-values.ui.copy-values-sidebar.copy-values-form.options-mapping.options-mapping-modal.field-options-mapper.auto-map"
				title={formatMessage(messages.reviewOptions)}
			>
				{Object.keys(automappedOptions).length > 0 ? (
					<OptionsMapper {...restProps} optionValueMapping={automappedOptions} />
				) : (
					<Box
						testId="polaris-component-copy-values.ui.copy-values-sidebar.copy-values-form.options-mapping.options-mapping-modal.field-options-mapper.no-automapped-options"
						xcss={noAutomappedOptionsStyles}
					>
						{formatMessage(messages.noAutomappedOptions)}
					</Box>
				)}
			</Section>
		</>
	);
};

const optionsRowStyles = xcss({
	marginBottom: 'space.150',
});

const fieldNameArrowIconWrapperStyles = xcss({
	width: '40px',
});

const noAutomappedOptionsStyles = xcss({
	padding: 'space.100',
	paddingTop: '0',
});

const fieldOptionStyles = xcss({
	width: '250px',
});
