import React, { memo, useMemo } from 'react';
import { styled } from '@compiled/react';
import { Stack, Text } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import { ConfigHeader } from '../../../common/ui/header/index.tsx';
import { useIsPreview } from '../../../controllers/selectors/config-hooks.tsx';
import { useFieldType } from '../../../controllers/selectors/field-hooks.tsx';
import messages from './messages.tsx';

export const DecorationConfigHeader = memo(() => {
	const { formatMessage } = useIntl();
	const fieldType = useFieldType();
	const isPreview = useIsPreview();

	const content = useMemo(() => {
		switch (fieldType) {
			case FIELD_TYPES.SINGLE_SELECT:
			case FIELD_TYPES.MULTI_SELECT:
			case FIELD_TYPES.JSW_MULTI_SELECT:
				return formatMessage(messages.options);
			case FIELD_TYPES.CUSTOM_LABELS:
			case FIELD_TYPES.LABELS:
				return formatMessage(messages.labelsAcrossIdeas);
			case FIELD_TYPES.STATUS:
				return formatMessage(messages.actions);
			case FIELD_TYPES.VOTES:
				return formatMessage(messages.voteHeader);
			case FIELD_TYPES.NUMBER:
			case FIELD_TYPES.RATING:
			case FIELD_TYPES.SLIDER:
			case FIELD_TYPES.LINKED_ISSUES:
			case FIELD_TYPES.FORMULA:
				return (
					<ConfigHeader
						title={formatMessage(messages.formatting)}
						tooltipText={
							isPreview || fieldType === FIELD_TYPES.RATING
								? undefined
								: formatMessage(messages.formattingTooltip)
						}
					/>
				);
			case FIELD_TYPES.DATE:
			default:
				return formatMessage(messages.formatting);
		}
	}, [fieldType, formatMessage, isPreview]);

	const displayLabelDisclaimer =
		fieldType === FIELD_TYPES.CUSTOM_LABELS || fieldType === FIELD_TYPES.LABELS;

	return (
		<Stack space="space.050">
			<SectionHeader>{content}</SectionHeader>
			{displayLabelDisclaimer && (
				<Text color="color.text.subtlest" size="small">
					{formatMessage(messages.labelsDisclaimer)}
				</Text>
			)}
		</Stack>
	);
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SectionHeader = styled.div({
	display: 'flex',
	alignItems: 'center',
	justifyItems: 'center',
	justifyContent: 'space-between',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	font: token('font.body.small'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtle'),
	paddingBottom: token('space.100'),
	paddingInline: '0',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: undefined,
});
