import React, { useState, type PropsWithChildren } from 'react';
import { Box, Pressable, Text, xcss } from '@atlaskit/primitives';
import Popup from '@atlaskit/popup';
import { useFieldEditable } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks.tsx';
import { useProjectForIssue } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/project/hooks.tsx';
import { useCanEditIssues } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { useIsCollectionView } from '@atlassian/jira-polaris-common/src/controllers/environment/index.tsx';
import type { CellProps } from '@atlassian/jira-polaris-lib-list/src/types.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useIsFieldAssociatedToIssue } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import messages from './messages.tsx';

export const CellTooltip = ({
	rowId,
	columnId,
	children,
}: PropsWithChildren<Pick<CellProps, 'rowId' | 'columnId'>>) => {
	const { formatMessage } = useIntl();
	const [isNoPermissionsTooltipOpen, setIsNoPermissionsTooltipOpen] = useState(false);
	const project = useProjectForIssue(rowId);
	const [canEditIssues] = useCanEditIssues({ projectId: project?.id });
	const fieldEditable = useFieldEditable(columnId);
	const isCollectionView = useIsCollectionView();
	const isFieldAssociatedToIssue = useIsFieldAssociatedToIssue(columnId, rowId);

	if (
		isCollectionView &&
		project &&
		fieldEditable &&
		(!canEditIssues || !isFieldAssociatedToIssue)
	) {
		const errorMessage = !canEditIssues
			? messages.noProjectPermissions
			: messages.fieldNotAddedToProject;
		return (
			<Popup
				isOpen={isNoPermissionsTooltipOpen}
				onClose={() => setIsNoPermissionsTooltipOpen(false)}
				placement="bottom"
				content={() => (
					<Box xcss={noPermissionsTooltipStyles}>
						<Text color="color.text.inverse">
							{formatMessage(errorMessage, {
								projectName: project.name,
								b: (chunks: React.ReactNode[]) => <Text weight="bold">{chunks}</Text>,
							})}
						</Text>
					</Box>
				)}
				trigger={(triggerProps) => (
					<Pressable
						{...triggerProps}
						xcss={noPermissionsTooltipTriggerStyles}
						onClick={() => setIsNoPermissionsTooltipOpen(!isNoPermissionsTooltipOpen)}
					>
						{children}
					</Pressable>
				)}
			/>
		);
	}

	return children;
};

const noPermissionsTooltipStyles = xcss({
	backgroundColor: 'color.background.neutral.bold',
	paddingInline: 'space.075',
	paddingBlock: 'space.025',
	maxWidth: '240px',
});

const noPermissionsTooltipTriggerStyles = xcss({
	background: 'none',
	padding: '0',
	cursor: 'pointer',
	textAlign: 'left',
});
