import { token } from '@atlaskit/tokens';

export const DEFAULT_FOOTER_HEIGHT = 45;
export const OVERSCAN_ROW_COUNT = 4;

export const DEFAULT_ROW_HEIGHT = 42;
export const DEFAULT_HEADER_HEIGHT = 42;
export const ROW_BORDER_WIDTH = 1;
export const ROW_BORDER_RADIUS = 6;

export const ACTIVE_ROW_HEIGHT = DEFAULT_ROW_HEIGHT + ROW_BORDER_WIDTH; // to account for the selection border

export const columnPadding = '7.5px';
export const backgroundColor = token('elevation.surface');
export const borderColor = token('color.border');
export const activeBorderColor = token('color.border.selected');
export const boxShadowColor = token('elevation.surface.raised.hovered');
export const boxShadowOffset = '2px';
export const boxShadowBlur = '4px';
export const rowHoveredBackgroundColor = token('elevation.surface.raised.hovered');
export const rowSelectedBackgroundColor = token('color.background.selected');
export const rowSelectedHoveredBackgroundColor = token('color.background.selected.hovered');
export const columnResizerColor = token('color.text.brand');
export const activeChangingCellBackGroundColor = token('color.background.accent.blue.subtler');

// this id is used as selector in export process
export const EXPORT_LIST_ID = 'list-view-table';

// The CSS custom property has a suffix at the end to avoid
// potential global namespace conflict
export const TABLE_VERTICAL_SCROLLBAR_SIZE_CSS_VARIABLE = '--tableVerticalScrollbarSize-72kl1n';
