import omit from 'lodash/omit';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { fg } from '@atlassian/jira-feature-gating';
import { logSafeErrorWithoutCustomerDataWrapper } from '@atlassian/jira-polaris-lib-errors/src/common/utils/index.tsx';
import type { State, Props } from '../types.tsx';
import { isOptimisticInsight } from '../utils/optimistic-updates.tsx';
import { signalInsightsUpdates } from '../utils/signal.tsx';

export const deleteInsight =
	(
		id: string,
		{
			performSideEffects = true,
			isBulkDeletion = false,
		}: { performSideEffects?: boolean; isBulkDeletion?: boolean } = {},
	) =>
	async ({ getState, setState }: StoreActionApi<State>, containerProps: Props) => {
		const { insightsRemote, onDeleteFailed, createAnalyticsEvent } = containerProps;
		const state = getState();

		if (isOptimisticInsight(id)) {
			return;
		}

		const newState = {
			...state,
			insights: {
				...omit(state.insights, id),
			},
		};

		setState(newState);

		if (performSideEffects) {
			signalInsightsUpdates(newState, containerProps);

			try {
				await insightsRemote.deleteInsight?.({ id });

				fireTrackAnalytics(
					createAnalyticsEvent({
						containers: {
							comment: {
								id,
								type: 'insight',
							},
						},
					}),
					'insight deleted',
					{
						isBulkDeletion,
					},
				);
			} catch (err) {
				const error = err instanceof Error ? err : new Error('Unknown error');
				onDeleteFailed(error);

				logSafeErrorWithoutCustomerDataWrapper(
					isBulkDeletion ? 'polaris.bulk-delete-insight-error' : 'polaris.delete-insight-error',
					'Failed to delete insight',
					error,
				);

				if (fg('jpd_insights_manipulation_slo')) {
					throw error;
				}
			}
		}
	};
