import flatten from 'lodash/flatten';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import {
	setRoleForActor,
	setRoleForGroup,
	setRoleForUser,
} from '../../../services/jira/set-role-for-actor/index.tsx';
import { isIdentityBasedContributorHandlingEnabled } from '../../selectors/contributor-group.tsx';
import { getGroupNameToGroupIdMapping } from '../../selectors/project-actors.tsx';
import { getPermissionsOnRole } from '../../selectors/roles.tsx';
import { type State, type Props, type ActorType, GROUP_ROLE_ACTOR } from '../../types.tsx';

export const setActorRole =
	(
		actorsRoleTypeId: string,
		actorsType: ActorType,
		numericRoleId: number,
	): Action<State, Props, Promise<string[] | undefined>> =>
	async ({ getState, setState }, { projectId, projectKey, onError }) => {
		const oldState = getState();
		const rolePermissions = getPermissionsOnRole(numericRoleId)(oldState);

		if (oldState.projectActors.data === undefined) {
			return;
		}

		const newActors = oldState.projectActors.data.actors.map((actor) => {
			if (actor.roleTypeId !== actorsRoleTypeId) {
				return actor;
			}
			return {
				...actor,
				roles: flatten([actor.roles, numericRoleId]),
			};
		});

		setState({
			...oldState,
			projectActors: {
				...oldState.projectActors,
				data: {
					...oldState.projectActors.data,
					actors: newActors,
				},
			},
		});

		try {
			if (isIdentityBasedContributorHandlingEnabled(oldState)) {
				if (actorsType === GROUP_ROLE_ACTOR) {
					const groupId = getGroupNameToGroupIdMapping(oldState)[actorsRoleTypeId];
					if (groupId) {
						await setRoleForGroup(numericRoleId, groupId, projectId);
					}
				} else {
					await setRoleForUser(numericRoleId, actorsRoleTypeId, projectId);
				}
			} else {
				await setRoleForActor({
					numericRoleId,
					projectKey,
					actorsRoleTypeId,
					isGroup: actorsType === GROUP_ROLE_ACTOR,
				});
			}

			return rolePermissions;
		} catch (error) {
			if (error instanceof Error) {
				onError(error);
			}
		}
	};
