import React, { useEffect } from 'react';
import { styled } from '@compiled/react';
import startsWith from 'lodash/startsWith';
import { token } from '@atlaskit/tokens';
import UFOLoadHold from '@atlaskit/react-ufo/load-hold';
import { fg } from '@atlassian/jira-feature-gating';

import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { useAtlasFieldsEnabled } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks.tsx';
import {
	useCurrentPolarisRoute,
	usePolarisRouter,
} from '@atlassian/jira-polaris-common/src/controllers/route/index.tsx';
import {
	useAllIdeasViewId,
	useViewSlug,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { ViewSectionTypeViews } from '@atlassian/jira-polaris-common/src/controllers/views/types.tsx';
import {
	useIsLoadingPermissions,
	useIsProjectAdmin,
} from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';

import { PolarisProjectAccess } from './access/index.tsx';
import { PolarisAdvancedConfig } from './advanced/index.tsx';
import { PolarisProjectAutomation } from './automation/async.tsx';
import { PolarisProjectConnectConfig } from './connect/index.tsx';
import { PolarisProjectDetails } from './details/index.tsx';
import { PolarisProjectFields } from './fields/index.tsx';
import { PolarisFormsSettings } from './forms/index.tsx';
import { PolarisProjectNotification } from './notifications/index.tsx';
import { PolarisProjectTypes } from './types/polaris-project-types.tsx';
import { PolarisWorkflowEditor } from './workflow/index.tsx';
import { SettingsUfoData } from './ufo-data/index.tsx';
import { ConnectionsPage } from './connections/connections-page.tsx';

export const PolarisProjectSettingsContent = () => {
	const { resource } = useCurrentPolarisRoute();
	const [isProjectAdmin] = useIsProjectAdmin();
	const { routeTo } = usePolarisRouter();

	const allIdeasViewId = useAllIdeasViewId();
	const allIdeasViewSlug = useViewSlug(allIdeasViewId);
	const [atlasFieldsEnabled] = useAtlasFieldsEnabled();

	const [isLoadingProjectPermissions] = useIsLoadingPermissions();
	const { isSiteAdmin, isAdmin } = useTenantContext();

	useEffect(() => {
		// let's redirect to the all ideas page if the user does not have admin permissions
		if (!isSiteAdmin && !isAdmin && !isLoadingProjectPermissions && !isProjectAdmin) {
			routeTo({
				section: ViewSectionTypeViews,
				resource: allIdeasViewSlug,
			});
		}
	}, [
		isLoadingProjectPermissions,
		isProjectAdmin,
		allIdeasViewSlug,
		routeTo,
		isSiteAdmin,
		isAdmin,
	]);

	// don't render the content when no admin permissions are available for the current user
	const isContentAvailable =
		isSiteAdmin || isAdmin || (isProjectAdmin && !isLoadingProjectPermissions);

	const getContent = () => {
		if (resource === 'details') {
			return <PolarisProjectDetails />;
		}
		if (resource === 'access') {
			return <PolarisProjectAccess />;
		}
		if (fg('jpd_issue_types_ga')) {
			if (resource === 'types') {
				return <PolarisProjectTypes />;
			}
		}
		if (resource === 'connections' && fg('jpd_issues_relationships_eap_config_fe')) {
			return <ConnectionsPage />;
		}
		if (resource === 'fields') {
			return <PolarisProjectFields />;
		}
		if (resource === 'notifications') {
			return <PolarisProjectNotification />;
		}
		if (resource === 'create-ideas') {
			return <PolarisFormsSettings />;
		}
		if (resource === 'workflow') {
			return <PolarisWorkflowEditor />;
		}
		if (resource === 'automation') {
			return <PolarisProjectAutomation />;
		}
		if (resource === 'atlas-integration' && atlasFieldsEnabled) {
			return <PolarisAdvancedConfig />;
		}
		if (resource && startsWith(resource, 'app-')) {
			return <PolarisProjectConnectConfig appId={resource.substr('app-'.length)} />;
		}

		// default to details view
		return <PolarisProjectDetails />;
	};

	let width = '960px';
	if (resource === 'automation') {
		width = '1100px';
	} else if (resource === 'fields' || resource === 'connections') {
		width = '1080px';
	}

	const emptyContent =
		isLoadingProjectPermissions && fg('jpd-trace-ufo-transition') ? (
			<UFOLoadHold name="jpd.settings-loading-project-permissions" />
		) : null;

	return isContentAvailable ? (
		<Container width={width}>
			{fg('jpd-trace-ufo-transition') && <SettingsUfoData activeSection={resource} />}
			<UFOSegment name="jpd.settings-content">{getContent()}</UFOSegment>
		</Container>
	) : (
		emptyContent
	);
};

export const PolarisProjectSettings = () => (
	<OuterContainer>
		<Placeholder name="polaris-project-settings" fallback={null}>
			<PolarisProjectSettingsContent />
		</Placeholder>
	</OuterContainer>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div<{ width: string }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: ({ width }) => width,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OuterContainer = styled.div({
	display: 'flex',
	marginInline: 'auto',
	paddingInline: token('space.300'),
	height: '100%',
});
