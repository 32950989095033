/** @jsx jsx */
import React, { useCallback, useMemo } from 'react';
import { css, jsx, styled } from '@compiled/react';
import { fg } from '@atlassian/jira-feature-gating';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';
import type { MaybeChoreographedComponentProps } from '@atlassian/jira-choreographer-services/src/types.tsx';
import {
	JiraInlineDialog as InlineDialog,
	type JiraInlineDialogProps,
} from '@atlassian/jira-inline-dialog/src/ui/jira-inline-dialog.tsx';
import { OutsideClickAlerter } from '@atlassian/jira-polaris-lib-outside-click-alerter/src/index.tsx';

export type PolarisInlineDialogProps = Omit<JiraInlineDialogProps, 'onClose'> & {
	noPadding?: boolean;
	// onClose is called if a click occurred outside of the dialog - the function argument can be a ref of the target
	onClose: (arg1: HTMLElement) => void;
};

export const PolarisInlineDialog = (
	props: PolarisInlineDialogProps & MaybeChoreographedComponentProps,
) => {
	const {
		children,
		onClose,
		noPadding,
		content,
		messageId = 'polaris-lib-inline-dialog.ui.inline-dialog',
		messageType = 'transactional',
		...rest
	} = props;

	const handleClickOutside = useCallback(
		(event: Event) => {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			onClose(event.target as HTMLElement);
		},
		[onClose],
	);

	const wrappedContent = useMemo(
		() => (
			<OutsideClickAlerter onClickOutside={handleClickOutside}>
				{(outsideClickAlerterProps) => (
					<div {...outsideClickAlerterProps}>
						{typeof content === 'function' ? content() : content}
					</div>
				)}
			</OutsideClickAlerter>
		),
		[content, handleClickOutside],
	);

	if (fg('polaris_pol-14065_fix_popup_display')) {
		return (
			<div
				css={[
					polarisInlineDialogContainerStyles,
					noPadding && polarisInlineDialogContainerNoPaddingStyles,
				]}
			>
				<InlineDialog
					{...rest}
					content={wrappedContent}
					messageId={messageId}
					messageType={messageType}
				>
					{children}
				</InlineDialog>
			</div>
		);
	}

	return (
		<PolarisInlineDialogContainer noPadding={noPadding ?? false}>
			<InlineDialog
				{...rest}
				content={wrappedContent}
				messageId={messageId}
				messageType={messageType}
			>
				{children}
			</InlineDialog>
		</PolarisInlineDialogContainer>
	);
};

const polarisInlineDialogContainerStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'> div': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
		zIndex: layers.modal,
	},
});

const polarisInlineDialogContainerNoPaddingStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'> div': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles
		padding: '0px !important',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PolarisInlineDialogContainer = styled.div<{ noPadding: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> div': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		padding: ({ noPadding }) => (noPadding ? '0px !important' : undefined),
	},
});
