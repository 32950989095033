import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	licenseLimitReachedTitle: {
		id: 'polaris-ideas.view-header.share-dialog.share-dialog-content.invite.license-limit-reached-title',
		defaultMessage: '{count, plural, one {# invite} other {# invites}} not sent',
		description:
			'The title of a notification shown indicating some invites failed due to user limit reached',
	},
	oneLicenseLimitReachedDescription: {
		id: 'polaris-ideas.view-header.share-dialog.share-dialog-content.invite.one-license-limit-reached-description',
		defaultMessage: 'We couldn’t invite {email} because you’ve reached your user limit.',
		description:
			'The description of a notification shown indicating one invite failed due to user limit reached',
	},
	manyLicenseLimitReachedDescription: {
		id: 'polaris-ideas.view-header.share-dialog.share-dialog-content.invite.many-license-limit-reached-description',
		defaultMessage:
			'We couldn’t send {count, plural, one {# of your invite} other {# of your invites}} because you’ve reached your user limit.',
		description:
			'The description of a notification shown indicating many invites failed due to user limit reached',
	},
	unknownErrorTitle: {
		id: 'polaris-ideas.view-header.share-dialog.share-dialog-content.invite.unknown-error-title',
		defaultMessage: '{count, plural, one {# invite} other {# invites}} not sent',
		description:
			'The title of a notification shown indicating some invites failed due to unknown error',
	},
	oneUnknownErrorDescription: {
		id: 'polaris-ideas.view-header.share-dialog.share-dialog-content.invite.one-unknown-error-description',
		defaultMessage: 'We couldn’t invite {email} because of technical problem',
		description:
			'The description of a notification shown indicating one invite failed due to unknown error',
	},
	manyUnknownErrorDescription: {
		id: 'polaris-ideas.view-header.share-dialog.share-dialog-content.invite.many-unknown-error-description',
		defaultMessage:
			'We couldn’t send {count, plural, one {# of your invite} other {# of your invites}} because of technical problem',
		description:
			'The description of a notification shown indicating many invites failed due to unknown error',
	},
	successTitle: {
		id: 'polaris-ideas.view-header.share-dialog.share-dialog-content.invite.success-title',
		defaultMessage: '{count, plural, one {Invite} other {Invites}} sent',
		description:
			'The title of a notification shown to users indicating an invite has been successfully sent',
	},
	oneSuccessDescription: {
		id: 'polaris-ideas.view-header.share-dialog.share-dialog-content.invite.one-success-description',
		defaultMessage:
			'You’ve added {name} to your project. You’ll see them on the board once they’ve been assigned an issue.',
		description:
			'The content of a notification shown to users indicating an invite has been sent to the selected user',
	},
	manySuccessDescription: {
		id: 'polaris-ideas.view-header.share-dialog.share-dialog-content.invite.many-success-description',
		defaultMessage:
			'You’ve added {count, plural, one {# person} other {# people}} to your project. You’ll see them on the board once they’ve been assigned issues.',
		description:
			'The content of a notification shown to users indicating many invites have been sent to the selected users',
	},
	contactSupport: {
		id: 'polaris-ideas.view-header.share-dialog.share-dialog-content.invite.contact-support',
		defaultMessage: 'Contact support',
		description:
			'The link label on a notification shown to users so that they may click to be shown a page and contact support',
	},
	upgradePlan: {
		id: 'polaris-ideas.view-header.share-dialog.share-dialog-content.invite.upgrade-plan',
		defaultMessage: 'Upgrade plan',
		description:
			'The link label on a notification shown to users so that they may click to load a new page to upgrade their products plan',
	},
	manageAccess: {
		id: 'polaris-ideas.view-header.share-dialog.share-dialog-content.invite.manage-access',
		defaultMessage: 'Manage access',
		description: 'Manage access link to redirect to the project settings page',
	},
});
