import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	placeholder: {
		id: 'polaris-ideas.view-content.idea-list.cell.cells.linked-issues-field.empty.placeholder',
		defaultMessage: 'Create a delivery ticket',
		description:
			'Link displayed when hovering over an empty delivery status field. There is only limited space available for this message and is optimized for the length of the default message.',
	},
	placeholderIssueTermRefresh: {
		id: 'polaris-ideas.view-content.idea-list.cell.cells.linked-issues-field.empty.placeholder-issue-term-refresh',
		defaultMessage: 'Create a work item',
		description:
			'Link displayed when hovering over an empty delivery status field. There is only limited space available for this message and is optimized for the length of the default message.',
	},
	linkIssueCaption: {
		id: 'polaris-ideas.view-content.idea-list.cell.cells.linked-issues-field.empty.link-issue-caption',
		defaultMessage: 'Link delivery work',
		description: 'Caption for the link issue component',
	},
	linkIssueCancel: {
		id: 'polaris-ideas.view-content.idea-list.cell.cells.linked-issues-field.empty.link-issue-cancel',
		defaultMessage: 'Cancel',
		description: 'Cancel message for the link issue component',
	},
	linkIssueSubmit: {
		id: 'polaris-ideas.view-content.idea-list.cell.cells.linked-issues-field.empty.link-issue-submit',
		defaultMessage: 'Link',
		description: 'Submit message for the link issue component',
	},
	buttonTitle: {
		id: 'polaris-ideas.view-content.idea-list.cell.cells.linked-issues-field.empty.button-title',
		defaultMessage: 'Link delivery work',
		description: 'Button title message',
	},
	iconLabel: {
		id: 'polaris-ideas.view-content.idea-list.cell.cells.linked-issues-field.empty.icon-label',
		defaultMessage: 'Link delivery work',
		description: 'Icon label message',
	},
});
