import React from 'react';
import { fg } from '@atlassian/jira-feature-gating';

import type {
	RowRendererProps,
	RowGroupRendererProps,
} from '../../common/types/react-base-table/index.tsx';
import { RowGroupComponent } from '../group-row/index.tsx';
import { RowPinnedBottom } from '../row-pinned-bottom/index.tsx';
import { RowComponent } from './main.tsx';

function isRowGroupRendererProps(
	props: RowRendererProps | RowGroupRendererProps,
): props is RowGroupRendererProps {
	return props.rowData.type === 'GROUP';
}

export const rowRenderer = (props: RowRendererProps | RowGroupRendererProps) => {
	if (isRowGroupRendererProps(props)) {
		return <RowGroupComponent {...props} />;
	}

	if (props.rowData.type === 'ITEM_PINNED_BOTTOM') {
		return <RowPinnedBottom {...props} />;
	}

	const withUFOSegment =
		props.rowData.type === 'ITEM' &&
		props.rowData.isFirstItemInList === true &&
		fg('jpd-trace-ufo-transition');

	return <RowComponent {...props} withUFOSegment={withUFOSegment} />;
};
