import type { ConnectionFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/connection/types.tsx';
import type { IssueTypeFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/issue-type/types.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { IssueCardProps } from '../../issue-card/index.tsx';

export type SelectableConnectionFieldOption = {
	value: LocalIssueId;
	label: string;
	issueKey: string;
	issueType: IssueTypeFieldValue;
	type: 'selectable';
	issueCardType?: IssueCardProps['type'];
};

export type CreatableConnectionFieldOption = {
	issueType: IssueTypeFieldValue;
	type: 'creatable';
};

export const isCreatableOption = (
	option: ConnectionFieldOption,
): option is CreatableConnectionFieldOption => option.type === 'creatable';

export const isSelectableOption = (
	option: ConnectionFieldOption,
): option is SelectableConnectionFieldOption => option.type === 'selectable';

export type ConnectionFieldOption =
	| SelectableConnectionFieldOption
	| CreatableConnectionFieldOption;

export type ConnectionFieldProps = {
	localIssueId: LocalIssueId;
	fieldKey: FieldKey;
	isEditable: boolean;
	menuPortalTarget?: HTMLElement;
	isActive?: boolean;
	onUpdate: (newValues?: ConnectionFieldValue[]) => Promise<void>;
	issueCardType?: IssueCardProps['type'];
};
