import React, { memo } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import FeedbackIcon from '@atlaskit/icon/core/migration/feedback';
import MegaphoneIcon from '@atlaskit/icon/core/megaphone';
import Button from '@atlaskit/button/new';
import { useIntl } from '@atlassian/jira-intl';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import messages from './messages.tsx';

type FeedbackButtonProps = {
	setFeedbackModalOpen: () => void;
};

export const FeedbackButton = memo(({ setFeedbackModalOpen }: FeedbackButtonProps) => {
	const { formatMessage } = useIntl();
	const visualRefreshEnabled = isVisualRefreshEnabled();

	return (
		<Box xcss={feedbackButtonWrapperStyles}>
			<Button
				appearance="subtle"
				onClick={setFeedbackModalOpen}
				iconBefore={visualRefreshEnabled ? MegaphoneIcon : FeedbackIcon}
			>
				{formatMessage(messages.feedback)}
			</Button>
		</Box>
	);
});

const feedbackButtonWrapperStyles = xcss({
	marginLeft: 'auto',
});
