/** @jsx jsx */
import { jsx } from '@compiled/react';
import { Box, xcss } from '@atlaskit/primitives';
import { IconButton } from '@atlaskit/button/new';
import EditIcon from '@atlaskit/icon/core/edit';
import { useIntl } from '@atlassian/jira-intl';
import { useColumn } from '@atlassian/jira-polaris-lib-data-arrangement/src/ui/column-context.tsx';
import { useGroup } from '@atlassian/jira-polaris-lib-data-arrangement/src/ui/group-context.tsx';
import { useColumnAndGroupAwareConnectionFieldIssueIdsSorted } from '../../../../controllers/issue/selectors/connection-hooks.tsx';
import type { ConnectionFieldProps } from '../types.tsx';
import { InlineConnectionsList } from '../../../connection/connection-list/inline/index.tsx';
import type { IssueCardProps } from '../../../issue-card/index.tsx';
import messages from './messages.tsx';

type Props = ConnectionFieldProps & {
	onEdit: (event: React.MouseEvent<HTMLElement>) => void;
	issueCardType?: IssueCardProps['type'];
};

export const ConnectionFieldView = ({
	onEdit,
	fieldKey,
	localIssueId,
	isActive,
	isEditable,
	issueCardType,
}: Props) => {
	const { formatMessage } = useIntl();
	const column = useColumn() ?? undefined;
	const group = useGroup() ?? undefined;
	const connectedIssueIds = useColumnAndGroupAwareConnectionFieldIssueIdsSorted(
		fieldKey,
		localIssueId,
		{ column, group },
	);

	return (
		<Box
			xcss={[
				containerStyles,
				connectedIssueIds.length === 0 && containerStylesEmpty,
				isEditable && isActive && containerHoveredStyles,
			]}
			onClick={isEditable ? onEdit : undefined}
		>
			<InlineConnectionsList
				fieldKey={fieldKey}
				localIssueId={localIssueId}
				isActive={isActive}
				issueCardType={issueCardType}
			/>
			{isActive && isEditable && (
				<IconButton
					icon={EditIcon}
					onClick={onEdit}
					spacing="compact"
					label={formatMessage(messages.editButtonLabel)}
				/>
			)}
		</Box>
	);
};

const containerStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	gap: 'space.050',
	paddingInlineEnd: 'space.050',
	width: '100%',
	borderRadius: '4px',
	minHeight: '32px',
});

const containerHoveredStyles = xcss({
	cursor: 'pointer',
	backgroundColor: 'color.background.neutral.subtle.hovered',
});

const containerStylesEmpty = xcss({
	paddingTop: 'space.050',
	paddingBottom: 'space.050',
});
