/* eslint-disable @typescript-eslint/no-extraneous-class */
// eslint-disable-next-line jira/restricted/react-component-props
import React, { useState, useEffect, type ComponentProps } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button, { LoadingButton } from '@atlaskit/button';
import { Field, Fieldset } from '@atlaskit/form';
import { ModalBody, ModalHeader, ModalTitle, ModalFooter } from '@atlaskit/modal-dialog';
import Select from '@atlaskit/select';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';
import { errorFlags } from '@atlassian/jira-flags';
import { JiraModal as ModalDialog } from '@atlassian/jira-modal/src/ui/jira-modal.tsx';
import {
	ContextualAnalyticsData,
	FireScreenAnalytics,
	fireTrackAnalytics,
	fireUIAnalytics,
	useAnalyticsEvents,
	MODAL,
} from '@atlassian/jira-product-analytics-bridge';
import { useNotificationSettingsService as useNotificationSettingsServiceDI } from '@atlassian/jira-project-settings-apps-notifications-services/src/services/notification-settings-service/main.tsx';
import type {
	NotificationEventType,
	NotificationEventTypeId,
} from '@atlassian/jira-project-settings-apps-notifications-services/src/services/notification-settings-service/types.tsx';
import { RecipientSelection } from '../recipient-selection/index.tsx';
import { DataIdWrapper } from '../styled.tsx';
import LoadingSkeleton from './loading-skeleton/index.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

class Util {
	static getSuccessProps() {
		return {
			type: 'success',
			title: messages.addSuccessFlagTitle,
			description: messages.addSuccessFlagDescription,
			testId: 'project-settings-apps-notifications-common.common.ui.add-notification.success-flag',
			isAutoDismiss: false,
			messageId:
				'project-settings-apps-notifications-common.common.ui.add-notification.flag.success',
			messageType: 'transactional',
		};
	}

	static getFailureProps() {
		return {
			type: 'error',
			title: messages.addFailureFlagTitle,
			description: messages.addFailureFlagDescription,
			testId: 'project-settings-apps-notifications-common.common.ui.add-notification.failure-flag',
			isAutoDismiss: false,
			messageId: 'project-settings-apps-notifications-common.common.ui.add-notification.flag.error',
			messageType: 'transactional',
		};
	}

	static convertToSelectOptions(eventTypes: NotificationEventType[] = []) {
		return eventTypes.map((type) => ({
			label: type.name,
			value: type.eventTypeId,
		}));
	}
}

const AddModal = (props: Props) => {
	const {
		onClose,
		useNotificationSettingsService,
		flagService: { showFlag },
		intl: { formatMessage },
		forceSelectOpen,
	} = props;

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [eventType, setEventType] = useState<unknown>();
	// NOTE : Can submit can be an independent state - this won 't make much difference
	const [recipients, setRecipients] = useState({ recipients: [], canSubmit: false });
	const recipientsList = recipients.recipients;
	const [
		{
			data: { eventTypes, notificationTypes, projectRoles },
			loading: isUpdating,
		},
		{ putNotification, fetchNotificationTypes, fetchProjectRoles },
	] = useNotificationSettingsService();

	useEffect(() => {
		const onFailure = () => {
			showFlag(errorFlags.genericError());
		};
		if (!eventTypes && !notificationTypes) {
			fetchNotificationTypes({ onFailure });
		}
		if (!projectRoles) {
			fetchProjectRoles({ onFailure });
		}
	}, [
		eventTypes,
		notificationTypes,
		projectRoles,
		fetchNotificationTypes,
		fetchProjectRoles,
		showFlag,
	]);

	const onAddSuccess = (eventTypeId: NotificationEventTypeId) => {
		// @ts-expect-error - TS2345 - Argument of type '{ type: string; title: MessageDescriptorV2; description: MessageDescriptorV2; testId: string; isAutoDismiss: boolean; }' is not assignable to parameter of type 'Flag'.
		showFlag(Util.getSuccessProps());

		const analyticsEvent = createAnalyticsEvent({});
		fireTrackAnalytics(analyticsEvent, 'projectNotification created', {
			notificationType: eventTypeId,
			// @ts-expect-error - TS2339 - Property 'key' does not exist on type 'never'.
			recipientTypes: recipientsList.map((recipient) => recipient.key),
		});
	};

	const onAddFailure = () => {
		// @ts-expect-error - TS2345 - Argument of type '{ type: string; title: MessageDescriptorV2; description: MessageDescriptorV2; testId: string; isAutoDismiss: boolean; }' is not assignable to parameter of type 'Flag'.
		showFlag(Util.getFailureProps());
	};

	const isInitialLoad = !eventTypes || !projectRoles || !notificationTypes;
	const addButtonProps = {
		appearance: 'primary',
		isDisabled: !eventType || recipientsList.length === 0 || !recipients.canSubmit,
		// @ts-expect-error - TS7006 - Parameter '_' implicitly has an 'any' type.
		onClick: (_, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'addNewNotificationButton');
			eventType &&
				putNotification(
					// @ts-expect-error - TS2322 - Type 'unknown' is not assignable to type 'NotificationEventType'.
					{ eventType, notifications: recipientsList },
					onAddSuccess,
					onAddFailure,
				);
			onClose();
		},
		isLoading: isUpdating && !isInitialLoad,
	};

	const cancelButtonProps = {
		appearance: 'subtle-link',
		onClick: () => {
			onClose();
		},
	};

	const eventSelectOptions = Util.convertToSelectOptions(eventTypes);

	const findEventSelectOption = (eventTypeId: string) =>
		eventSelectOptions.find((option) => option.value === eventTypeId);

	const findEventType = (optionValue: string) =>
		(eventTypes || []).find((type) => optionValue === type.eventTypeId);

	return (
		<>
			<ShortcutScope>
				<ModalDialog
					key="add-notification-by-event-type-modal"
					onClose={onClose}
					width="medium"
					messageId="project-settings-apps-notifications-common.common.ui.add-notification.modal-dialog"
					messageType="transactional"
				>
					<ModalHeader>
						<ModalTitle>{formatMessage(messages.addNotification)}</ModalTitle>
					</ModalHeader>
					<ModalBody>
						{isInitialLoad ? (
							<LoadingSkeleton />
						) : (
							<>
								{formatMessage(messages.addNotificationPrompt)}
								<Fieldset>
									<Field
										name="eventTypeSelection"
										label={formatMessage(messages.selectEventTypeLabel)}
									>
										{({ fieldProps }) => (
											<>
												<DataIdWrapper data-test-id="project-settings-apps-notifications-common.common.ui.add-notification.event-type-select-input-box">
													<Select
														{...fieldProps}
														menuIsOpen={forceSelectOpen || undefined}
														value={
															eventType &&
															findEventSelectOption(
																// @ts-expect-error - TS2571 - Object is of type 'unknown'.
																eventType.eventTypeId,
															)
														}
														options={eventSelectOptions}
														placeholder={formatMessage(messages.selectEventType)}
														instanceId="notification-event-type-selection"
														onChange={(e) => {
															setEventType(
																// @ts-expect-error - TS2571 - Object is of type 'unknown'.
																findEventType(e.value),
															);
														}}
														// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
														menuPortalTarget={document.body}
														styles={{
															menuPortal: (styles) => ({
																...styles,

																zIndex: layers.modal,
															}),
														}}
													/>
												</DataIdWrapper>
												{projectRoles && notificationTypes && (
													<RecipientSelection
														allRoles={projectRoles}
														eventTypeId={
															eventType
																? // @ts-expect-error - TS2571 - Object is of type 'unknown'.
																	eventType.eventTypeId
																: null
														}
														recipients={recipients.recipients}
														// @ts-expect-error - TS2322 - Type 'Dispatch<SetStateAction<{ recipients: never[]; canSubmit: boolean; }>>' is not assignable to type '(arg1: NotificationRecipientFormState) => void'.
														setRecipients={setRecipients}
														supportedRecipients={notificationTypes}
													/>
												)}
											</>
										)}
									</Field>
								</Fieldset>
							</>
						)}
					</ModalBody>
					<ModalFooter>
						<DataIdWrapper data-test-id="project-settings-apps-notifications-common.common.ui.add-notification.add-button">
							{/* @ts-expect-error - TS2322 - Type '{ children: string; appearance: string; isDisabled: boolean; onClick: (_: any, analyticsEvent: UIAnalyticsEvent) => void; isLoading: boolean; }' is not assignable to type 'Omit<BaseProps, "overlay">'. */}
							<LoadingButton {...addButtonProps}>{formatMessage(messages.add)}</LoadingButton>
						</DataIdWrapper>
						<DataIdWrapper data-test-id="project-settings-apps-notifications-common.common.ui.add-notification.cancel-button">
							{/* @ts-expect-error - TS2322 - Type '{ children: string; appearance: string; onClick: () => void; }' is not assignable to type 'ButtonProps'. */}
							<Button {...cancelButtonProps}>{formatMessage(messages.cancel)}</Button>
						</DataIdWrapper>
					</ModalFooter>
				</ModalDialog>
			</ShortcutScope>

			<FireScreenAnalytics />
		</>
	);
};

AddModal.defaultProps = {
	useNotificationSettingsService: useNotificationSettingsServiceDI,
};

export const AddNotificationModal = (
	props: JSX.LibraryManagedAttributes<typeof AddModal, ComponentProps<typeof AddModal>>,
) => (
	<ContextualAnalyticsData sourceType={MODAL} sourceName="addNotification">
		<AddModal {...props} />
	</ContextualAnalyticsData>
);
