import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { CreatorListResponse } from './types.tsx';

export const getCreatorsList = (
	projectId: string,
	users: string[],
	groups: string[],
): Promise<CreatorListResponse> =>
	performPostRequest('/rest/polaris/permissions/creators', {
		body: JSON.stringify({
			projectId,
			users,
			groups,
		}),
	});
