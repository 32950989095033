import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	createIdea: {
		id: 'polaris-ideas.view-content.idea-list.header.cell.summary-column-add-idea.create-idea',
		defaultMessage: 'Create an idea',
		description: 'Tooltip text visible while hovering over Create Idea icon button.',
	},
	createIdeaButton: {
		id: 'polaris-ideas.view-content.idea-list.header.cell.summary-column-add-idea.create-idea-button',
		defaultMessage: 'Create',
		description: 'Tooltip text visible while hovering over Create icon button.',
	},
});
