import { useState, useMemo, useEffect } from 'react';
import type { FieldsByKey } from '@atlassian/jira-polaris-domain-field/src/collections/types.tsx';
import type { SnippetProvider } from '@atlassian/jira-polaris-domain-field/src/snippet/types.tsx';
import type { DynamicFieldFormula } from '@atlassian/jira-polaris-lib-formula/src/utils/formula/types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { COUNT_ROLLUP, PROPERTY_ROLLUP } from '../../../constants.tsx';
import { conjunctiveLabelFilter } from '../conjuctive-label-filter/index.tsx';
import { useRollupFormulaParser, emptyState } from '../formula-parser/index.tsx';
import type { CountRollupField, PropertyRollupField } from '../../../types.tsx';
import type { RollupFormulaOperations, RollupFormulaStateExt } from './types.tsx';

const immutableSplice = <T,>(array: T[], index: number, insertElement: T): T[] => {
	const newArray: T[] = [
		...array.slice(0, index), // Copy elements before the index
		insertElement, // Insert the new value
		...array.slice(index + 1), // Copy elements after the index
	];

	return newArray;
};

const createFormulaState = (
	rollupFormulaParserFn: ReturnType<typeof useRollupFormulaParser>,
	formula?: DynamicFieldFormula,
	isInitAsWeightedScore?: boolean,
) =>
	formula
		? { ...rollupFormulaParserFn(formula), isUpdated: false }
		: { ...emptyState(isInitAsWeightedScore === true), isUpdated: false };

/**
 * @deprecated Please use `useFormulaStateNext`
 */
const useFormulaStateLegacy = (
	snippetProviders: SnippetProvider[],
	fieldsByKey: FieldsByKey,
	formula?: DynamicFieldFormula,
	initAsWeightedScore?: boolean,
	availableGlobalFieldsByKey?: FieldsByKey,
): [RollupFormulaStateExt, RollupFormulaOperations] => {
	const rollupFormulaParser = useRollupFormulaParser(
		snippetProviders,
		fieldsByKey,
		availableGlobalFieldsByKey,
	);

	const [state, setState] = useState(
		createFormulaState(rollupFormulaParser, formula, initAsWeightedScore),
	);

	useEffect(() => {
		setState(createFormulaState(rollupFormulaParser, formula, initAsWeightedScore));
	}, [rollupFormulaParser, formula, initAsWeightedScore]);

	return useMemo(
		() => [
			state,
			{
				addField: (rollupField) =>
					setState({
						rollupFields: [...state.rollupFields, rollupField],
						percentages: [...state.percentages, undefined],
						weightedScore: state.weightedScore,
						isUpdated: true,
					}),
				removeField: (index) =>
					setState({
						rollupFields: state.rollupFields.filter((_, i) => index !== i),
						percentages: state.percentages.filter((_, i) => index !== i),
						weightedScore: state.weightedScore,
						isUpdated: true,
					}),
				setPercentageValue: (index, value) => {
					state.percentages.splice(index, 1, value);
					setState({
						...state,
						percentages: [...state.percentages],
						isUpdated: true,
					});
				},
				setLabelFilter: (index, values) => {
					const rollupField = state.rollupFields[index];
					if (
						rollupField !== undefined &&
						(rollupField.kind === PROPERTY_ROLLUP || rollupField.kind === COUNT_ROLLUP)
					) {
						const newRollupFields = [...state.rollupFields];
						// @ts-expect-error - TS2345 - Argument of type '{ value: { filter: Conjunction; id: string; key: string; label: string; kind: string; appName: string; oauthClientId: string; avatarUrl: string; } | { filter: Conjunction; ... 4 more ...; avatarUrl?: string | undefined; }; kind: "property"; } | { ...; }' is not assignable to parameter of type 'RollupField'.
						newRollupFields.splice(index, 1, {
							...rollupField,
							value: {
								...rollupField.value,
								filter: conjunctiveLabelFilter(values || []),
							},
						});
						setState({
							...state,
							rollupFields: newRollupFields,
							isUpdated: true,
						});
					}
				},
				setWeightedScore: (weightedScore) =>
					setState({
						...state,
						weightedScore,
						isUpdated: true,
					}),
			},
		],
		[state],
	);
};

const useFormulaStateNext = (
	snippetProviders: SnippetProvider[],
	fieldsByKey: FieldsByKey,
	formula?: DynamicFieldFormula,
	initAsWeightedScore?: boolean,
	availableGlobalFieldsByKey?: FieldsByKey,
): [RollupFormulaStateExt, RollupFormulaOperations] => {
	const rollupFormulaParser = useRollupFormulaParser(
		snippetProviders,
		fieldsByKey,
		availableGlobalFieldsByKey,
	);

	const [state, setState] = useState(
		createFormulaState(rollupFormulaParser, formula, initAsWeightedScore),
	);

	const [prevItems, setPrevItems] = useState({
		rollupFormulaParser,
		formula,
		initAsWeightedScore,
	});

	// Adjust the state while rendering
	// This is done to avoid useEffect
	// @see https://react.dev/learn/you-might-not-need-an-effect#adjusting-some-state-when-a-prop-changes
	if (
		rollupFormulaParser !== prevItems.rollupFormulaParser ||
		formula !== prevItems.formula ||
		initAsWeightedScore !== prevItems.initAsWeightedScore
	) {
		setPrevItems({
			rollupFormulaParser,
			formula,
			initAsWeightedScore,
		});

		setState(createFormulaState(rollupFormulaParser, formula, initAsWeightedScore));
	}

	const operations: RollupFormulaOperations = useMemo(
		() => ({
			addField: (rollupField) =>
				setState((prevState) => ({
					rollupFields: [...prevState.rollupFields, rollupField],
					percentages: [...prevState.percentages, undefined],
					weightedScore: prevState.weightedScore,
					isUpdated: true,
				})),
			removeField: (index) =>
				setState((prevState) => ({
					rollupFields: prevState.rollupFields.filter((_, i) => index !== i),
					percentages: prevState.percentages.filter((_, i) => index !== i),
					weightedScore: prevState.weightedScore,
					isUpdated: true,
				})),
			setPercentageValue: (index, value) => {
				setState((prevState) => ({
					...prevState,
					percentages: immutableSplice(prevState.percentages, index, value),
					isUpdated: true,
				}));
			},
			setLabelFilter: (index, values) => {
				setState((prevState) => {
					const rollupField = prevState.rollupFields[index];

					if (
						rollupField !== undefined &&
						(rollupField.kind === PROPERTY_ROLLUP || rollupField.kind === COUNT_ROLLUP)
					) {
						const nextRollupField: CountRollupField | PropertyRollupField = {
							...rollupField,
						};

						nextRollupField.value = {
							...nextRollupField.value,
							filter: conjunctiveLabelFilter(values || []),
						};

						return {
							...prevState,
							rollupFields: immutableSplice(prevState.rollupFields, index, nextRollupField),
							isUpdated: true,
						};
					}

					return prevState;
				});
			},
			setWeightedScore: (weightedScore) =>
				setState((prevState) => ({
					...prevState,
					weightedScore,
					isUpdated: true,
				})),
		}),
		[],
	);

	return useMemo(() => [state, operations], [state, operations]);
};

export const useFormulaState = (
	snippetProviders: SnippetProvider[],
	fieldsByKey: FieldsByKey,
	formula?: DynamicFieldFormula,
	initAsWeightedScore?: boolean,
	availableGlobalFieldsByKey?: FieldsByKey,
): [RollupFormulaStateExt, RollupFormulaOperations] => {
	const formulaStateLegacy = useFormulaStateLegacy(
		snippetProviders,
		fieldsByKey,
		formula,
		initAsWeightedScore,
		availableGlobalFieldsByKey,
	);

	const formulaStateNext = useFormulaStateNext(
		snippetProviders,
		fieldsByKey,
		formula,
		initAsWeightedScore,
		availableGlobalFieldsByKey,
	);

	return fg('polaris_fields_formula_update_race_condition_fix')
		? formulaStateNext
		: formulaStateLegacy;
};
