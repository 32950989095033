import { differenceInCalendarMonths, parseISO } from 'date-fns';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { ScaleEnum } from '../../../../common/constants.tsx';
import {
	getTimelinePrimaryGridInterval,
	getTimelineSecondaryGridInterval,
} from '../../../../common/utils.tsx';
import type { GroupedItemArrangement, TimelineItem } from '../../../../types.tsx';
import { getGroupedIds } from '../../../selectors/groups.tsx';
import { getScale, getStartDate } from '../../../selectors/timeline.tsx';
import type { State } from '../../../types.tsx';
import { createItems, initGroupedItemRows, shouldShowMiddleOfTheMonth } from './utils.tsx';

export const initItemState =
	(items: TimelineItem[], itemArrangement: GroupedItemArrangement | undefined) =>
	({ setState, getState }: StoreActionApi<State>) => {
		const state = getState();
		const scale = getScale(state);
		const timelineStartDate = getStartDate(state);
		const primaryGridInterval = getTimelinePrimaryGridInterval(scale);
		const secondaryGridInterval = getTimelineSecondaryGridInterval(scale);
		const gridIntervalMultiplier =
			scale === ScaleEnum.QUARTERS ? secondaryGridInterval : primaryGridInterval;
		const rawItems = items.map(({ id, startDateInterval, endDateInterval }) => {
			const startDate = parseISO(startDateInterval.value.start);
			const endDate = parseISO(endDateInterval.value.end);

			const middleStartCorrection = shouldShowMiddleOfTheMonth(startDateInterval.value.start, true)
				? 1
				: 0;
			const middleEndCorrection = shouldShowMiddleOfTheMonth(endDateInterval.value.end, false)
				? 1
				: 0;

			return {
				id,
				start:
					differenceInCalendarMonths(startDate, timelineStartDate) * gridIntervalMultiplier +
					middleStartCorrection,
				size:
					(differenceInCalendarMonths(endDate, startDate) + 1) * gridIntervalMultiplier -
					middleEndCorrection -
					middleStartCorrection,
				startLabel: startDateInterval.label,
				endLabel: endDateInterval.label,
				isStartExternal: startDateInterval.isExternal,
				isEndExternal: endDateInterval.isExternal,
			};
		});

		setState({
			items: createItems(rawItems),
			itemArrangement: initGroupedItemRows(rawItems, getGroupedIds(getState()), itemArrangement),
		});
	};
