import React from 'react';

import { ViewLayoutType } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { VIEW_KIND_BOARD } from '@atlassian/jira-polaris-domain-view/src/view/constants.tsx';
import { useColumn } from '@atlassian/jira-polaris-lib-data-arrangement/src/ui/column-context.tsx';
import { useGroup } from '@atlassian/jira-polaris-lib-data-arrangement/src/ui/group-context.tsx';
import { useColumnAndGroupAwareConnectionFieldIssueIdsSorted } from '../../../../controllers/issue/selectors/connection-hooks.tsx';
import { ConnectionsList } from '../../../connection/connection-list/index.tsx';

import { useCurrentViewKind } from '../../../../controllers/views/selectors/view-hooks.tsx';
import { InlineConnectionsList } from '../../../connection/connection-list/inline/index.tsx';
import { FieldLabel } from '../field/styled.tsx';
import { useFieldLabel } from '../../../../controllers/field/selectors/field-hooks.tsx';
import { DetailedShort } from './detailed-short/index.tsx';

type ConnectionFieldProps = {
	variant?: ViewLayoutType;
	isSummary?: boolean;
	issueId: string;
	fieldKey: string;
	hideEmpty?: boolean;
};

export const ConnectionField = (props: ConnectionFieldProps) => {
	const { fieldKey, issueId, variant, isSummary, hideEmpty = false } = props;

	const column = useColumn() ?? undefined;
	const group = useGroup() ?? undefined;

	const connectedIssueIds = useColumnAndGroupAwareConnectionFieldIssueIdsSorted(fieldKey, issueId, {
		column,
		group,
	});

	const viewKind = useCurrentViewKind();
	const label = useFieldLabel(fieldKey);
	const isBoardView = viewKind === VIEW_KIND_BOARD;
	const isCompact = variant === ViewLayoutType.COMPACT;

	if (hideEmpty && (connectedIssueIds === undefined || connectedIssueIds.length === 0)) {
		return null;
	}

	// rendered under 'one-row' fields in card
	if (isCompact && isBoardView) {
		return null;
	}

	if (isSummary || isBoardView) {
		return (
			<ConnectionsList
				localIssueId={issueId}
				connectionItems={connectedIssueIds}
				fieldKey={fieldKey}
			/>
		);
	}

	switch (variant) {
		case ViewLayoutType.DETAILED:
			return (
				<>
					<FieldLabel>{label}</FieldLabel>
					<InlineConnectionsList localIssueId={issueId} fieldKey={fieldKey} />
				</>
			);
		case ViewLayoutType.COMPACT:
		default:
			return (
				<DetailedShort
					localIssueId={issueId}
					fieldKey={fieldKey}
					connectionItems={connectedIssueIds}
				/>
			);
	}
};
