import React, { useState, useCallback, useMemo } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import type {
	ValueDecorationRules,
	ValueDecoration,
	ValueRule,
	LocalDecorationId,
} from '@atlassian/jira-polaris-domain-field/src/decoration/types.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import {
	fireTrackAnalytics,
	fireUIAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { fg } from '@atlassian/jira-feature-gating';
import { useCallbacks } from '../../../../../controllers/selectors/callback-hooks.tsx';
import {
	useIsGlobalCustomField,
	useFieldType,
} from '../../../../../controllers/selectors/field-hooks.tsx';
import { SharedFieldDecorationPopup } from '../../shared-field-decoration-popup.tsx';
import { NumberDecorationRangeInput } from './input/index.tsx';
import { messages } from './messages.tsx';
import { NumberDecorationRangeTag } from './tag/index.tsx';
import { getRangeTypeFromRules } from './tag/utils.tsx';

export const SLIDER_FIELD_MIN_VALUE = 0;
export const SLIDER_FIELD_MAX_VALUE = 100;

type BaseDecoratorItemProps = {
	decoration: ValueDecoration;
	readonly?: boolean;
};

type DecoratorItemProps = {
	isOpen: boolean;
} & BaseDecoratorItemProps & {
		onOpenChanged: (open: boolean) => void;
	};

export const DecoratorNumberItem = ({
	onOpenChanged,
	isOpen,
	decoration,
	readonly = false,
}: DecoratorItemProps) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { formatMessage } = useIntl();
	const fieldType = useFieldType();
	const isGlobalCustomField = useIsGlobalCustomField();
	const [currentColor, setCurrentColor] = useState(decoration.backgroundColor);
	const [currentEmojiId, setCurrentEmojiId] = useState(decoration.emoji);

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const initialRulesState = (decoration as ValueDecorationRules).rules;
	const [currentRules, setCurrentRules] = useState(initialRulesState);
	const inputLabel = useMemo(() => {
		const { ltRule, gtRule } = getRangeTypeFromRules(currentRules);
		if (gtRule !== undefined && ltRule !== undefined) {
			return formatMessage(messages.between);
		}
		if (gtRule !== undefined && ltRule === undefined) {
			return formatMessage(messages.greaterThan);
		}
		if (gtRule === undefined && ltRule !== undefined) {
			return formatMessage(messages.lessThan);
		}
		return undefined; // should never happen from business perspective as it does not make sense, it is here for safety only
	}, [currentRules, formatMessage]);

	const { onValueDecorationUpdated, onValueDecorationDeleted } = useCallbacks();

	const onValueDecorationChanged = useCallback(
		async ({
			backgroundColor,
			emoji,
			highlightContainer,
			rules,
		}: {
			backgroundColor: string | undefined;
			emoji: string | undefined;
			highlightContainer: boolean;
			rules: ValueRule[];
		}) => {
			fireTrackAnalytics(createAnalyticsEvent({}), 'fieldDecoration changed', 'config-fields', {
				action: 'update',
				backgroundColor,
				emoji,
				highlightContainer,
				isGlobalCustomField,
			});

			if (onValueDecorationUpdated !== undefined) {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				await onValueDecorationUpdated({
					localDecorationId: decoration.localDecorationId,
					backgroundColor,
					emoji,
					highlightContainer,
					rules,
				} as ValueDecoration);
			}
		},
		[
			createAnalyticsEvent,
			decoration.localDecorationId,
			isGlobalCustomField,
			onValueDecorationUpdated,
		],
	);

	const onColorChanged = useCallback(
		(backgroundColor: string | undefined) => {
			setCurrentColor(backgroundColor);

			fireUIAnalytics(createAnalyticsEvent({}), 'color clicked', 'config-fields', {
				backgroundColor,
			});

			onValueDecorationChanged({
				backgroundColor,
				emoji: currentEmojiId,
				highlightContainer: !!decoration.highlightContainer,
				rules: currentRules,
			});
		},
		[
			createAnalyticsEvent,
			currentEmojiId,
			decoration.highlightContainer,
			onValueDecorationChanged,
			currentRules,
		],
	);

	const onEmojiChanged = useCallback(
		(emoji: string | undefined) => {
			setCurrentEmojiId(emoji);

			fireUIAnalytics(createAnalyticsEvent({}), 'emoji clicked', 'config-fields', {
				emoji,
			});

			onValueDecorationChanged({
				backgroundColor: currentColor,
				emoji,
				highlightContainer: !!decoration.highlightContainer,
				rules: currentRules,
			});
		},
		[
			createAnalyticsEvent,
			currentColor,
			decoration.highlightContainer,
			onValueDecorationChanged,
			currentRules,
		],
	);

	const onHighlightContainerChanged = useCallback(
		(highlightContainer: boolean) => {
			fireUIAnalytics(createAnalyticsEvent({}), 'highlightContainer clicked', 'config-fields', {
				highlightContainer,
			});

			onValueDecorationChanged({
				backgroundColor: currentColor,
				emoji: currentEmojiId,
				highlightContainer,
				rules: currentRules,
			});
		},
		[createAnalyticsEvent, currentColor, currentEmojiId, onValueDecorationChanged, currentRules],
	);

	const onRulesChanged = useCallback(
		(newRules: ValueRule[]) => {
			setCurrentRules(newRules);

			onValueDecorationChanged({
				backgroundColor: currentColor,
				emoji: currentEmojiId,
				highlightContainer: !!decoration.highlightContainer,
				rules: newRules,
			});
		},
		[currentColor, currentEmojiId, decoration.highlightContainer, onValueDecorationChanged],
	);

	const onDecorationDeleted = useCallback(
		(localDecorationId: LocalDecorationId) => {
			onValueDecorationDeleted && onValueDecorationDeleted({ localDecorationId });
		},
		[onValueDecorationDeleted],
	);

	const onToggleEdit = (isEditActive: boolean) => {
		onOpenChanged(isEditActive);
	};

	const isSlider = fieldType === FIELD_TYPES.SLIDER;
	const isLinkedIssue = fieldType === FIELD_TYPES.LINKED_ISSUES;
	const minLimit = isSlider ? SLIDER_FIELD_MIN_VALUE : undefined;
	const maxLimit = isSlider ? SLIDER_FIELD_MAX_VALUE : undefined;

	return (
		<SharedFieldDecorationPopup
			isOpen={isOpen}
			onOpen={onToggleEdit}
			readonly={readonly}
			decorationNode={
				<NumberDecorationRangeTag
					rules={currentRules}
					color={currentColor}
					emojiId={currentEmojiId}
				/>
			}
			onDelete={() => onDecorationDeleted(decoration.localDecorationId)}
			input={{
				label: inputLabel,
				labelPosition: 'start',
			}}
			isNameEditable
			isHighlighted={!!decoration.highlightContainer}
			color={currentColor}
			emojiId={currentEmojiId}
			inputNode={
				<NumberDecorationRangeInput
					rules={currentRules}
					onRulesChanged={onRulesChanged}
					minLimit={minLimit}
					maxLimit={maxLimit}
				/>
			}
			customDeleteLabel={
				isLinkedIssue && fg('polaris_pol-14070_fix_caption_for_formatting_rules')
					? formatMessage(messages.deleteFormattingRule)
					: undefined
			}
			onColorChanged={onColorChanged}
			onHighlightingChanged={onHighlightContainerChanged}
			onEmojiSelected={onEmojiChanged}
		/>
	);
};
