/** @jsx jsx */
import React, { type PropsWithChildren } from 'react';
import { jsx, css } from '@compiled/react';
import { Anchor, Box, Inline, xcss } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import LinkExternalIcon from '@atlaskit/icon/core/link-external';
import { useIssueLink } from '@atlassian/jira-polaris-common/src/common/utils/issue-link/index.tsx';
import { useConnectedIssueDataByIssueId } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/connection-hooks.tsx';
import { useIssueViewLayout } from '@atlassian/jira-polaris-common/src/controllers/route/index.tsx';
import { useCanConnectIssues } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { useIsSelectedIssueArchived } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { useIsIssueFromDifferentContainerByJiraId } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/issue-ids-hooks.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { UnlinkButton } from './unlink/index.tsx';

type Props = {
	issueId: number;
};

export const IssueSummaryCellRenderer = ({ issueId }: Props) => {
	const connectedIssue = useConnectedIssueDataByIssueId(issueId.toString());
	const canConnectIssues = useCanConnectIssues();
	const isIssueArchived = useIsSelectedIssueArchived();
	const isIssueFromDifferentContainer = useIsIssueFromDifferentContainerByJiraId(String(issueId));

	if (!connectedIssue) {
		return null;
	}

	return (
		<Inline space="space.100" alignBlock="center" xcss={containerStyles}>
			<img
				width={16}
				height={16}
				src={connectedIssue.issueType.iconUrl}
				alt={connectedIssue.issueType.name}
			/>

			<Box xcss={summaryStyles}>
				<Tooltip content={connectedIssue.summary}>
					<IssueLink
						issueKey={connectedIssue.issueKey}
						isIssueFromDifferentContainer={isIssueFromDifferentContainer}
					>
						{connectedIssue.summary}
					</IssueLink>
				</Tooltip>
			</Box>

			{canConnectIssues && !isIssueArchived && <UnlinkButton issueId={issueId} />}
		</Inline>
	);
};

const IssueLink = ({
	issueKey,
	children,
	isIssueFromDifferentContainer,
}: PropsWithChildren<{ issueKey: string; isIssueFromDifferentContainer: boolean }>) => {
	const issueViewLayout = useIssueViewLayout();
	const { link, target, onClick } = useIssueLink({
		issueKey,
		shouldOpenInSidebar: issueViewLayout === 'sidebar',
		issueViewSection: 'connections',
	});

	return (
		<div css={issueLinkContainerStyles}>
			<Anchor onClick={onClick} xcss={linkStyles} href={link} target={target}>
				{children}
				{isIssueFromDifferentContainer && fg('jpd_cross_project_connecting') && (
					<Box
						paddingInlineStart="space.100"
						data-component-selector="connection-cell-summary-39Ndf"
					>
						<LinkExternalIcon label="" />
					</Box>
				)}
			</Anchor>
		</div>
	);
};

const issueLinkContainerStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	"[data-component-selector='connection-cell-summary-39Ndf']": {
		display: 'none',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	"&:hover [data-component-selector='connection-cell-summary-39Ndf']": {
		display: 'inline',
	},
});

const containerStyles = xcss({
	paddingLeft: 'space.150',
	paddingRight: 'space.100',
	height: '100%',
});

const summaryStyles = xcss({
	overflow: 'hidden',
});

const linkStyles = xcss({
	color: 'color.text',
	textDecoration: 'none',
	display: 'block',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	position: 'relative',

	':hover': {
		color: 'color.text',
	},
});
