import type { IssueTypeId } from '@atlassian/jira-shared-types/src/general.tsx';

const getSettingsUrl = (projectKey: string) =>
	`/jira/polaris/projects/${projectKey}/ideas/settings`;

export const getWorkflowSettingsUrl = ({
	projectKey,
	issueTypeId,
}: {
	projectKey: string;
	issueTypeId: IssueTypeId;
}) => `${getSettingsUrl(projectKey)}/workflow?issueTypeId=${issueTypeId}`;

export const getTypesSettingsUrl = ({ projectKey }: { projectKey: string }) =>
	`${getSettingsUrl(projectKey)}/types`;
