import { createSelector } from 'reselect';
import { fg } from '@atlassian/jira-feature-gating';
import type { State } from '../types.tsx';

export const isContributorGroupEnabled = (state: State) => state.contributorGroupEnabled || false;
export const getContributorGroupName = (state: State) => state.contributorGroupName;
export const getContributorGroupId = (state: State) => state.contributorGroupId;
export const getContributorGroupIds = (state: State) => state.contributorGroupIds;
// This is the central selector for checking if identity based contributors are active.
// It checks the Jira FF, but ALSO implicitly the identity status as the BE would not
// return the contributor group IDs array if it's not active.
export const isIdentityBasedContributorHandlingEnabled = (state: State) =>
	fg('jpd_enable_identity_role_based_contributor_access') &&
	getContributorGroupIds(state) !== undefined;
export const getHasManuallyAddedContributorAccess = (state: State) =>
	state.hasManuallyAddedContributorAccess;

export const getContributorGroupIdsWithoutManuallyAddedContributorAccess = createSelector(
	getContributorGroupIds,
	getHasManuallyAddedContributorAccess,
	(contributorGroupIds, hasManuallyAddedContributorAccess) =>
		hasManuallyAddedContributorAccess
			? contributorGroupIds?.filter((groupId) => !hasManuallyAddedContributorAccess[groupId])
			: contributorGroupIds,
);
