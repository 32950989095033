import React, { type SyntheticEvent, useCallback } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import ModalDialog, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '@atlaskit/modal-dialog';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { ModalTransitionUniversal } from '@atlassian/jira-polaris-common/src/common/ui/modal-transition/index.tsx';
import { sendPendoTrackEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/pendo/index.tsx';
import {
	FireScreenAnalytics,
	ContextualAnalyticsData,
	MODAL,
	fireUIAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { messages } from './messages.tsx';

type Props = {
	isOpen: boolean;
	title: string;
	onCancel: () => void;
	onConfirm: () => void;
	type?: 'view' | 'viewSection';
};

export const DeleteModal = (props: Props) => {
	const { isOpen, title, onCancel, onConfirm } = props;
	const { formatMessage } = useIntl();

	const onConfirmHandler = useCallback(
		(_event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
			const { payload } = analyticsEvent;

			fireUIAnalytics(analyticsEvent, 'delete');
			sendPendoTrackEvent({
				actionSubjectAndAction: `${payload.actionSubject} ${payload.action}`,
				actionSubjectId: 'delete',
			});
			onConfirm();
		},
		[onConfirm],
	);

	const onCancelHandler = useCallback(
		(_event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
			const { payload } = analyticsEvent;

			fireUIAnalytics(analyticsEvent, 'cancel');
			sendPendoTrackEvent({
				actionSubjectAndAction: `${payload.actionSubject} ${payload.action}`,
				actionSubjectId: 'cancel',
			});
			onCancel();
		},
		[onCancel],
	);

	return (
		<ModalTransitionUniversal>
			<ContextualAnalyticsData
				sourceName={
					props.type === 'viewSection' ? 'confirmDeleteViewSection' : 'confirmDeleteViewModal'
				}
				sourceType={MODAL}
			>
				{isOpen && (
					<ShortcutScope>
						<ModalDialog onClose={onCancel}>
							<ModalHeader>
								<ModalTitle appearance="danger">
									{formatMessage(messages.deleteHeader, { title })}
								</ModalTitle>
							</ModalHeader>
							<ModalBody>
								<FireScreenAnalytics />
								{/* eslint-disable-next-line @atlaskit/design-system/use-primitives-text */}
								<p>{formatMessage(messages.description, { title })}</p>
							</ModalBody>
							<ModalFooter>
								<Button
									id="pendo.delete-modal.delete-button"
									appearance="danger"
									onClick={onConfirmHandler}
									testId="polaris-ideas.ui.sidebar.sections.common.delete-modal.delete"
								>
									{formatMessage(messages.delete)}
								</Button>
								<Button
									id="pendo.delete-modal.cancel-button"
									onClick={onCancelHandler}
									testId="polaris-ideas.ui.sidebar.sections.common.delete-modal.cancel"
								>
									{formatMessage(messages.cancel)}
								</Button>
							</ModalFooter>
						</ModalDialog>
					</ShortcutScope>
				)}
			</ContextualAnalyticsData>
		</ModalTransitionUniversal>
	);
};
