/** @jsx jsx */
import React from 'react';
import { cssMap, jsx } from '@compiled/react';
import FeedbackIcon from '@atlaskit/icon/core/migration/feedback';
import { ButtonItem, Section } from '@atlaskit/menu';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useFeedbackModal } from '@atlassian/jira-polaris-common/src/controllers/feedback-modal/index.tsx';
import messages from './messages.tsx';

const styles = cssMap({
	sectionInner: {
		marginTop: 0,
		marginRight: token('space.negative.100'),
		marginBottom: 0,
		marginLeft: token('space.negative.100'),
	},
	buttonItem: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Temporary workaround to make Emotion and Compiled specificity mesh until `@atlaskit/menu` is migrated to Compiled.
		'&&': {
			paddingBlockStart: token('space.050'),
			paddingBlockEnd: token('space.050'),
			// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
			paddingInlineStart: '18px',
			// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
			paddingInlineEnd: '18px',
			color: token('color.text.subtle'),
			'&:hover': {
				color: token('color.text'),
			},
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		'[data-item-elem-before]': {
			width: 24,
			height: 24,
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
			svg: {
				width: 18,
				height: 18,
				marginTop: token('space.025'),
				// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
				marginLeft: 3,
			},
		},
	},
	buttonItemSelected: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Temporary workaround to make Emotion and Compiled specificity mesh until `@atlaskit/menu` is migrated to Compiled.
		'&&': {
			color: token('color.text.brand'),
		},
	},
});

export const FeedbackButton = () => {
	const { formatMessage } = useIntl();
	const [{ isFeedbackModalOpen }, { setIsFeedbackModalOpen }] = useFeedbackModal();

	return (
		<Section hasSeparator>
			<div css={styles.sectionInner}>
				<ButtonItem
					id="polaris.ideas.give-feedback"
					// eslint-disable-next-line @atlaskit/design-system/no-unsafe-style-overrides
					css={[styles.buttonItem, isFeedbackModalOpen && styles.buttonItemSelected]}
					onClick={() => setIsFeedbackModalOpen(true)}
					isSelected={isFeedbackModalOpen}
					iconBefore={<FeedbackIcon spacing="spacious" label="feedback" />}
				>
					{formatMessage(messages.giveFeedback)}
				</ButtonItem>
			</div>
		</Section>
	);
};
