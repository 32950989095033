import { useMemo } from 'react';
import {
	useLocalIssueIdsByCell,
	useLocalIssueIdsByGroupIdentity,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/grouping-hooks.tsx';
import {
	useCurrentViewCollapsedSwimlanes,
	useCurrentViewHideEmptyBoardColumns,
	useCurrentViewHideEmptyGroups,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { functionWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import {
	BOARD_CARD_GAP,
	BOARD_COLUMN_MIN_HEIGHT,
} from '@atlassian/jira-polaris-lib-board/src/constants.tsx';
import type { Column, Group } from '@atlassian/jira-polaris-lib-board/src/types/common.tsx';
import { EMPTY_VALUE_ID } from '../../../../common/utils/board.tsx';
import { useGroupOptions } from '../../common/utils/group-options.tsx';
import { useExtendedVerticalGroupOptions } from '../../common/utils/vertical-group-options.tsx';
import {
	createBoardIssueId,
	useBoardViewCardHeights,
	type BoardIssue,
} from '../use-board-view-card-heights/index.tsx';
import { useBoardViewCardHeightsLegacy } from '../use-board-view-card-heights-legacy/index.tsx';
import {
	filterOutGroupColumnsWithoutCards,
	filterOutEmptyGroups,
	getAllColumnsWithEmptyValues,
	getRankedGroups,
} from './utils.tsx';

// FIXME: we need tests for this, it's crucial to get the groups right before giving it to the virtualization layer

/**
 * TODO: this hook can be expensive (up to 500ms for 1000 issues), especially because of useLocalIssueIdsByCell,
 * it would be best to move it to a selector that memoizes the result once (using memoize-one) for each board view with a map.
 * Spike it in the next iteration of the board view virtualization
 */
export const useBoardViewWithGroupsNew = (
	groupByField: Field,
	verticalGroupByField: Field,
): Group[] => {
	const idsByCell = useLocalIssueIdsByCell(groupByField.key, verticalGroupByField.key);
	const visibleGroups = useExtendedVerticalGroupOptions(verticalGroupByField);
	const visibleGroupKeys: string[] = useMemo(
		() => visibleGroups.map((group) => group.groupIdentity || EMPTY_VALUE_ID),
		[visibleGroups],
	);
	const isEmptyGroupVisible = visibleGroupKeys.includes(EMPTY_VALUE_ID);

	const visibleColumns = useGroupOptions(groupByField);
	const visibleColumnKeys: string[] = useMemo(
		() => visibleColumns.map((column) => column.groupIdentity || EMPTY_VALUE_ID),
		[visibleColumns],
	);
	const isEmptyColumnVisible = visibleColumnKeys.includes(EMPTY_VALUE_ID);
	const collapsedSwimlanes = useCurrentViewCollapsedSwimlanes();
	const hideEmptyColumns = useCurrentViewHideEmptyBoardColumns();
	const hideEmptyGroups = useCurrentViewHideEmptyGroups();

	const allColumns = useMemo(
		() => getAllColumnsWithEmptyValues(idsByCell, isEmptyColumnVisible, isEmptyGroupVisible),
		[idsByCell, isEmptyColumnVisible, isEmptyGroupVisible],
	);

	const filteredIssues = useMemo(() => {
		const items: BoardIssue[] = [];

		for (const [columnKey, columnGroupMap] of allColumns) {
			if (!visibleColumnKeys.includes(columnKey)) {
				// eslint-disable-next-line no-continue
				continue;
			}

			const columnGroups = Object.entries(columnGroupMap || {});
			for (const [groupKey, localIssueIds] of columnGroups) {
				if (!visibleGroupKeys.includes(groupKey)) {
					// eslint-disable-next-line no-continue
					continue;
				}

				const localIssueIdsSafe = localIssueIds || [];
				const columnOptionValue = visibleColumns.find(
					(column) => column.groupIdentity === columnKey,
				)?.value;
				const groupOptionValue = visibleGroups.find(
					(group) => group.groupIdentity === groupKey,
				)?.value;

				localIssueIdsSafe.forEach((localIssueId) => {
					items.push({
						id: createBoardIssueId({ localIssueId, columnId: columnKey, groupId: groupKey }),
						localIssueId,
						column: {
							fieldKey: groupByField.key,
							fieldValue: columnOptionValue,
						},
						group: {
							fieldKey: verticalGroupByField.key,
							fieldValue: groupOptionValue,
						},
					});
				});
			}
		}

		return items;
	}, [
		allColumns,
		groupByField.key,
		verticalGroupByField.key,
		visibleColumnKeys,
		visibleColumns,
		visibleGroupKeys,
		visibleGroups,
	]);

	const cardHeights = useBoardViewCardHeights({ filteredIssues });

	const groups = useMemo(() => {
		const groupsMap: Map<string, Column[]> = new Map();

		for (const [columnKey, columnGroupMap] of allColumns) {
			if (!visibleColumnKeys.includes(columnKey)) {
				// eslint-disable-next-line no-continue
				continue;
			}

			const columnGroups = Object.entries(columnGroupMap || {});
			for (const [groupKey, localIssueIds] of columnGroups) {
				if (!visibleGroupKeys.includes(groupKey)) {
					// eslint-disable-next-line no-continue
					continue;
				}

				if (groupsMap.get(groupKey) === undefined) {
					groupsMap.set(groupKey, []);
				}

				const localIssueIdsSafe = localIssueIds || [];

				const cards = localIssueIdsSafe.map((localIssueId, index) => {
					const id = createBoardIssueId({
						localIssueId,
						columnId: columnKey,
						groupId: groupKey,
					});

					return {
						uid: localIssueId,
						height: (cardHeights.get(id) || BOARD_COLUMN_MIN_HEIGHT) + BOARD_CARD_GAP,
						offset: 0,
						index,
					};
				});

				groupsMap.get(groupKey)?.push({
					uid: `${groupKey}.${columnKey}`,
					columnUid: columnKey,
					cards,
					height: 0,
					contentHeight: 0,
				});
			}
		}

		const rankedGroups = getRankedGroups(
			groupsMap,
			visibleGroupKeys,
			visibleColumnKeys,
			collapsedSwimlanes,
		);

		const groupsWithFinalColumns = hideEmptyColumns
			? filterOutGroupColumnsWithoutCards(rankedGroups, visibleColumnKeys)
			: rankedGroups;

		const finalGroups = hideEmptyGroups
			? filterOutEmptyGroups(groupsWithFinalColumns)
			: groupsWithFinalColumns;

		return finalGroups;
	}, [
		visibleGroupKeys,
		visibleColumnKeys,
		collapsedSwimlanes,
		hideEmptyColumns,
		hideEmptyGroups,
		allColumns,
		cardHeights,
	]);

	return groups;
};

/**
 * Used for the board view without groups. Fakes a group that contains all the columns.
 */
export const useBoardViewWithoutGroupsNew = (groupByField: Field): Group[] => {
	const visibleColumns = useGroupOptions(groupByField);
	const visibleColumnKeys: string[] = useMemo(
		() => visibleColumns.map((column) => column.groupIdentity || EMPTY_VALUE_ID),
		[visibleColumns],
	);
	const isEmptyColumnVisible = visibleColumnKeys.includes(EMPTY_VALUE_ID);
	const idsByColumn = useLocalIssueIdsByGroupIdentity(groupByField.key);
	const hideEmptyColumns = useCurrentViewHideEmptyBoardColumns();

	const filteredIssues = useMemo(() => {
		const items: BoardIssue[] = [];
		const allColumns = Object.entries(idsByColumn.groups);

		if (isEmptyColumnVisible) {
			allColumns.unshift([EMPTY_VALUE_ID, idsByColumn.empty || []]);
		}

		for (const [columnKey, localIssueIds] of allColumns) {
			if (!visibleColumnKeys.includes(columnKey)) {
				// eslint-disable-next-line no-continue
				continue;
			}

			localIssueIds.forEach((localIssueId) => {
				items.push({
					id: createBoardIssueId({ localIssueId, columnId: columnKey }),
					localIssueId,
					column: {
						fieldKey: groupByField.key,
						fieldValue: visibleColumns.find((column) => column.groupIdentity === columnKey)?.value,
					},
				});
			});
		}

		return items;
	}, [
		groupByField.key,
		idsByColumn.empty,
		idsByColumn.groups,
		isEmptyColumnVisible,
		visibleColumnKeys,
		visibleColumns,
	]);

	const cardHeights = useBoardViewCardHeights({ filteredIssues });

	const groups: Group[] = useMemo(() => {
		const columns: Column[] = [];
		const allColumns = Object.entries(idsByColumn.groups);

		if (isEmptyColumnVisible) {
			allColumns.unshift([EMPTY_VALUE_ID, idsByColumn.empty || []]);
		}

		for (const [columnKey, localIssueIds] of allColumns) {
			if (!visibleColumnKeys.includes(columnKey)) {
				// eslint-disable-next-line no-continue
				continue;
			}

			const cards = localIssueIds.map((localIssueId, index) => ({
				uid: localIssueId,
				height:
					(cardHeights.get(createBoardIssueId({ columnId: columnKey, localIssueId })) ||
						BOARD_COLUMN_MIN_HEIGHT) + BOARD_CARD_GAP,
				offset: 0,
				index,
			}));

			columns.push({
				uid: columnKey,
				columnUid: columnKey,
				cards,
				height: 0,
				contentHeight: 0,
			});
		}

		const rankedColumns: Column[] = visibleColumnKeys.map((uid) => ({
			uid,
			columnUid: uid,
			cards: [],
			height: 0,
			contentHeight: 0,
		}));

		for (const column of columns) {
			rankedColumns[visibleColumnKeys.indexOf(column.uid)] = column;
		}

		const finalColumns = hideEmptyColumns
			? rankedColumns.filter((column) => column.cards.length > 0)
			: rankedColumns;

		return [
			{
				uid: 'MOCKED_GROUP',
				columns: finalColumns,
				height: 0,
				contentHeight: 0,
				offset: 0,
				maxColumnContentHeight: 0,
				isCollapsed: false,
			},
		];
	}, [isEmptyColumnVisible, cardHeights, idsByColumn, visibleColumnKeys, hideEmptyColumns]);

	return groups;
};

export const useBoardViewWithGroupsLegacy = (
	groupByField: Field,
	verticalGroupByField: Field,
): Group[] => {
	const cardHeights = useBoardViewCardHeightsLegacy();
	const idsByCell = useLocalIssueIdsByCell(groupByField.key, verticalGroupByField.key);
	const visibleGroups = useExtendedVerticalGroupOptions(verticalGroupByField);
	const visibleGroupKeys: string[] = useMemo(
		() => visibleGroups.map((group) => group.groupIdentity || EMPTY_VALUE_ID),
		[visibleGroups],
	);
	const isEmptyGroupVisible = visibleGroupKeys.includes(EMPTY_VALUE_ID);

	const visibleColumns = useGroupOptions(groupByField);
	const visibleColumnKeys: string[] = useMemo(
		() => visibleColumns.map((column) => column.groupIdentity || EMPTY_VALUE_ID),
		[visibleColumns],
	);
	const isEmptyColumnVisible = visibleColumnKeys.includes(EMPTY_VALUE_ID);
	const collapsedSwimlanes = useCurrentViewCollapsedSwimlanes();
	const hideEmptyColumns = useCurrentViewHideEmptyBoardColumns();
	const hideEmptyGroups = useCurrentViewHideEmptyGroups();

	const groups = useMemo(() => {
		const groupsMap: Map<string, Column[]> = new Map();
		const allColumns = getAllColumnsWithEmptyValues(
			idsByCell,
			isEmptyColumnVisible,
			isEmptyGroupVisible,
		);

		for (const [columnKey, columnGroupMap] of allColumns) {
			if (!visibleColumnKeys.includes(columnKey)) {
				// eslint-disable-next-line no-continue
				continue;
			}

			const columnGroups = Object.entries(columnGroupMap || {});
			for (const [groupKey, localIssueIds] of columnGroups) {
				if (!visibleGroupKeys.includes(groupKey)) {
					// eslint-disable-next-line no-continue
					continue;
				}

				if (groupsMap.get(groupKey) === undefined) {
					groupsMap.set(groupKey, []);
				}

				const localIssueIdsSafe = localIssueIds || [];

				const cards = localIssueIdsSafe.map((localIssueId, index) => ({
					uid: localIssueId,
					height: (cardHeights.get(localIssueId) || BOARD_COLUMN_MIN_HEIGHT) + BOARD_CARD_GAP,
					offset: 0,
					index,
				}));

				groupsMap.get(groupKey)?.push({
					uid: `${groupKey}.${columnKey}`,
					columnUid: columnKey,
					cards,
					height: 0,
					contentHeight: 0,
				});
			}
		}

		const rankedGroups = getRankedGroups(
			groupsMap,
			visibleGroupKeys,
			visibleColumnKeys,
			collapsedSwimlanes,
		);

		const groupsWithFinalColumns = hideEmptyColumns
			? filterOutGroupColumnsWithoutCards(rankedGroups, visibleColumnKeys)
			: rankedGroups;

		const finalGroups = hideEmptyGroups
			? filterOutEmptyGroups(groupsWithFinalColumns)
			: groupsWithFinalColumns;

		return finalGroups;
	}, [
		idsByCell,
		visibleColumnKeys,
		visibleGroupKeys,
		isEmptyColumnVisible,
		isEmptyGroupVisible,
		cardHeights,
		collapsedSwimlanes,
		hideEmptyColumns,
		hideEmptyGroups,
	]);

	return groups;
};

/**
 * Used for the board view without groups. Fakes a group that contains all the columns.
 */
const useBoardViewWithoutGroupsLegacy = (groupByField: Field): Group[] => {
	const cardHeights = useBoardViewCardHeightsLegacy();
	const visibleColumns = useGroupOptions(groupByField);
	const visibleColumnKeys: string[] = useMemo(
		() => visibleColumns.map((column) => column.groupIdentity || EMPTY_VALUE_ID),
		[visibleColumns],
	);
	const isEmptyColumnVisible = visibleColumnKeys.includes(EMPTY_VALUE_ID);
	const idsByColumn = useLocalIssueIdsByGroupIdentity(groupByField.key);
	const hideEmptyColumns = useCurrentViewHideEmptyBoardColumns();

	const groups: Group[] = useMemo(() => {
		const columns: Column[] = [];
		const allColumns = Object.entries(idsByColumn.groups);

		if (isEmptyColumnVisible) {
			allColumns.unshift([EMPTY_VALUE_ID, idsByColumn.empty || []]);
		}

		for (const [columnKey, localIssueIds] of allColumns) {
			if (!visibleColumnKeys.includes(columnKey)) {
				// eslint-disable-next-line no-continue
				continue;
			}

			const cards = localIssueIds.map((localIssueId, index) => ({
				uid: localIssueId,
				height: (cardHeights.get(localIssueId) || BOARD_COLUMN_MIN_HEIGHT) + BOARD_CARD_GAP,
				offset: 0,
				index,
			}));

			columns.push({
				uid: columnKey,
				columnUid: columnKey,
				cards,
				height: 0,
				contentHeight: 0,
			});
		}

		const rankedColumns: Column[] = visibleColumnKeys.map((uid) => ({
			uid,
			columnUid: uid,
			cards: [],
			height: 0,
			contentHeight: 0,
		}));

		for (const column of columns) {
			rankedColumns[visibleColumnKeys.indexOf(column.uid)] = column;
		}

		const finalColumns = hideEmptyColumns
			? rankedColumns.filter((column) => column.cards.length > 0)
			: rankedColumns;

		return [
			{
				uid: 'MOCKED_GROUP',
				columns: finalColumns,
				height: 0,
				contentHeight: 0,
				offset: 0,
				maxColumnContentHeight: 0,
				isCollapsed: false,
			},
		];
	}, [isEmptyColumnVisible, cardHeights, idsByColumn, visibleColumnKeys, hideEmptyColumns]);

	return groups;
};

export const useBoardViewWithoutGroups = functionWithCondition(
	() => fg('jpd_issues_relationships'),
	useBoardViewWithoutGroupsNew,
	useBoardViewWithoutGroupsLegacy,
);

export const useBoardViewWithGroups = functionWithCondition(
	() => fg('jpd_issues_relationships'),
	useBoardViewWithGroupsNew,
	useBoardViewWithGroupsLegacy,
);
