// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import {
	deleteActor,
	deleteUserActor,
	deleteGroupActor,
} from '../../../services/jira/delete-actor/index.tsx';
import { isIdentityBasedContributorHandlingEnabled } from '../../selectors/contributor-group.tsx';
import { getGroupNameToGroupIdMapping } from '../../selectors/project-actors.tsx';
import { type ActorType, type Props, type State, GROUP_ROLE_ACTOR } from '../../types.tsx';
import { loadProjectActors } from '../load-project-actors/index.tsx';

export const deleteProjectActor =
	(roleTypeId: string, actorType: ActorType): Action<State, Props> =>
	({ getState, dispatch }, { projectId, projectKey, onError }) => {
		if (isIdentityBasedContributorHandlingEnabled(getState())) {
			if (actorType === GROUP_ROLE_ACTOR) {
				const groupId = getGroupNameToGroupIdMapping(getState())[roleTypeId];
				if (groupId) {
					deleteGroupActor(groupId, projectId)
						.then(() => dispatch(loadProjectActors()))
						.catch(onError);
				}
			} else {
				deleteUserActor(roleTypeId, projectId)
					.then(() => dispatch(loadProjectActors()))
					.catch(onError);
			}
		} else {
			deleteActor(projectKey, roleTypeId, actorType === GROUP_ROLE_ACTOR)
				.then(() => dispatch(loadProjectActors()))
				.catch((err) => onError(err));
		}
	};
