import React, { useMemo } from 'react';
import { styled } from '@compiled/react';
import isEmpty from 'lodash/isEmpty';
import { N200 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import ProgressBar from '@atlassian/jira-common-components-progress-bar/src/main.tsx';
import ProgressElement from '@atlassian/jira-common-components-progress-bar/src/progress-element/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { LinkedIssuesProgress } from '@atlassian/jira-polaris-domain-field/src/field-types/progress/types.tsx';
import {
	type StatusCategoryKey,
	DONE,
	IN_PROGRESS,
	TODO,
	type StatusCategory,
} from '@atlassian/jira-polaris-domain-field/src/field-types/status/types.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { DELIVERY_CALCULATION_MODE } from '@atlassian/jira-polaris-domain-field/src/presentation/constants.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { LinkedIssuesFormula } from '@atlassian/jira-polaris-lib-formula/src/utils/formula/linked-issues/types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { StatusCategoryMap } from '../../common/types/status-category/index.tsx';
import {
	useFieldFormula,
	useFieldKeysOfType,
} from '../../controllers/field/selectors/field-hooks.tsx';
import {
	useIsInitialized,
	useIsLoadingLinkedIssues,
} from '../../controllers/issue/selectors/meta-hooks.tsx';
import { useLinkedProgress } from '../../controllers/issue/selectors/properties/hooks.tsx';
import { useStatusCategories } from '../../controllers/workflow/selectors/status-categories-hook.tsx';
import { StatusLozenge } from '../common/status/index.tsx';
import { useIsCollectionView } from '../../controllers/environment/index.tsx';
import messages from './messages.tsx';

export const BAR_ONLY = 'bar-only' as const;
export const BAR_WITH_DONE_PERCENTAGE = 'bar-with-done-percentage' as const;
export const BAR_WITH_STATUS = 'bar-with-status' as const;
export const STATUS_ONLY = 'status-only' as const;

type DonePercentageProps = {
	isLoading: boolean;
	progress: LinkedIssuesProgress;
	doneCategory: StatusCategory;
};

const DonePercentage = (props: DonePercentageProps) => {
	const { progress, doneCategory, isLoading } = props;
	if (progress.total === 0) {
		return null;
	}
	const done = progress.distribution.done !== undefined ? progress.distribution.done : 0;
	const value = (done / progress.total) * 100;
	const roundedValue = isLoading ? '? ' : Math.round(value + Number.EPSILON);

	return (
		<Percentage>
			<span>{`${roundedValue}% ${doneCategory.name}`}</span>
		</Percentage>
	);
};

export type LinkedIssuesProgressComponentProps = {
	fieldKey: FieldKey;
	isLoading: boolean;
	statusCategories: StatusCategoryMap | undefined;
	progress: LinkedIssuesProgress;
	height: number;
	status?: {
		name: string;
		statusCategoryKey: StatusCategoryKey;
	};
	appearance?:
		| typeof BAR_ONLY
		| typeof BAR_WITH_DONE_PERCENTAGE
		| typeof BAR_WITH_STATUS
		| typeof STATUS_ONLY;
	minWidth?: number;
	hideTooltip?: boolean;
	deliveryIssuesExist?: boolean;
};

export const LinkedIssuesProgressComponent = (props: LinkedIssuesProgressComponentProps) => {
	const {
		fieldKey,
		progress,
		statusCategories,
		isLoading,
		height,
		appearance,
		status,
		minWidth,
		deliveryIssuesExist,
	} = props;

	const { formatMessage } = useIntl();
	const isCollectionView = useIsCollectionView();

	const hideTooltip =
		isCollectionView && fg('jpd-aurora-roadmap-delivery-fields') ? true : props.hideTooltip;

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const formula = useFieldFormula(fieldKey) as LinkedIssuesFormula;

	const isUnestimatedProgress = useMemo(
		() =>
			!progress.total &&
			formula?.parameters?.calculationMode === DELIVERY_CALCULATION_MODE.STORY_POINTS,
		[formula?.parameters?.calculationMode, progress.total],
	);

	const messageKeyForTooltip = useMemo(() => {
		const tooltipMessage = fg('polaris-issue-terminology-refresh')
			? 'tooltipIssuesIssueTermRefresh'
			: 'tooltipIssues';
		return formula?.parameters?.calculationMode === DELIVERY_CALCULATION_MODE.STORY_POINTS
			? 'tooltipStoryPoints'
			: tooltipMessage;
	}, [formula?.parameters?.calculationMode]);

	if (
		statusCategories === undefined ||
		isEmpty(statusCategories) ||
		deliveryIssuesExist === false
	) {
		return null;
	}

	if (!status && appearance === STATUS_ONLY) {
		return null;
	}

	const COLORS_HASH = {
		[statusCategories[TODO].key]: token('color.border'),
		[statusCategories[IN_PROGRESS].key]: token('color.border.information'),
		[statusCategories[DONE].key]: token('color.border.success'),
		loading: token('color.skeleton'),
	};

	const remainingTooltip = isUnestimatedProgress
		? formatMessage(messages.unestimatedProgress)
		: formatMessage(messages[messageKeyForTooltip], {
				count: progress.distribution[statusCategories[TODO].key] || 0,
				category: statusCategories[TODO].name,
				total: progress.total,
			});

	return isLoading ? (
		<Container>
			{appearance !== STATUS_ONLY && (
				<BarContainer minWidth={minWidth}>
					<ProgressBar
						sum={1}
						backgroundColor={COLORS_HASH.loading}
						height={height}
						remainingTooltipContent={
							hideTooltip === true ? null : formatMessage(messages.tooltipLoading)
						}
					>
						<ProgressElement
							key={statusCategories[DONE].key}
							value={0}
							color={COLORS_HASH[statusCategories[DONE].key]}
							tooltipContent={null}
						/>
					</ProgressBar>
				</BarContainer>
			)}
			{appearance === BAR_WITH_DONE_PERCENTAGE && (
				<DonePercentage
					progress={progress}
					doneCategory={statusCategories[DONE]}
					isLoading={isLoading}
				/>
			)}
		</Container>
	) : (
		<Container data-testid="polaris-common.ui.linked-issues-progress.container">
			{appearance !== STATUS_ONLY && (
				<BarContainer minWidth={minWidth}>
					<ProgressBar
						sum={
							isUnestimatedProgress ||
							(isCollectionView && !progress.total && fg('jpd-aurora-roadmap-delivery-fields'))
								? 1
								: progress.total
						}
						remainingTooltipContent={hideTooltip === true ? null : remainingTooltip}
						height={height}
					>
						{[statusCategories[DONE], statusCategories[IN_PROGRESS], statusCategories[TODO]].map(
							(statusCategory) => {
								const { key, name } = statusCategory;
								return (
									<ProgressElement
										key={key}
										value={progress.distribution[key] || 0}
										color={COLORS_HASH[key]}
										tooltipContent={
											hideTooltip === true
												? null
												: formatMessage(messages[messageKeyForTooltip], {
														count: progress.distribution[key] || 0,
														category: name,
														total: progress.total,
													})
										}
									/>
								);
							},
						)}
					</ProgressBar>
				</BarContainer>
			)}
			{appearance === BAR_WITH_DONE_PERCENTAGE && (
				<DonePercentage
					progress={progress}
					doneCategory={statusCategories[DONE]}
					isLoading={isLoading}
				/>
			)}
			{(appearance === BAR_WITH_STATUS || appearance === STATUS_ONLY) && status !== undefined && (
				<StatusLozenge statusCategoryKey={status.statusCategoryKey} statusName={status.name} />
			)}
		</Container>
	);
};

type LinkedIssuesProgressFieldProps = {
	issueId: LocalIssueId;
	hideTooltip?: boolean;
};

export const LinkedProgress = ({ issueId, hideTooltip }: LinkedIssuesProgressFieldProps) => {
	const progress = useLinkedProgress(issueId);
	const statusCategories = useStatusCategories();
	const isLoading = useIsLoadingLinkedIssues();
	const isInitialized = useIsInitialized();
	const deliveryProgressFields = useFieldKeysOfType(FIELD_TYPES.DELIVERY_PROGRESS);
	const fieldKey = useMemo(() => deliveryProgressFields[0], [deliveryProgressFields]);

	return (
		<LinkedIssuesProgressComponent
			progress={progress}
			fieldKey={fieldKey}
			statusCategories={statusCategories}
			isLoading={isLoading || !isInitialized}
			hideTooltip={hideTooltip}
			height={4}
		/>
	);
};

export default LinkedProgress;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
	alignItems: 'center',
	width: '100%',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	paddingLeft: '5px',
	boxSizing: 'border-box',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BarContainer = styled.div<{ minWidth?: number }>({
	display: 'flex',
	flexGrow: 1,
	marginLeft: token('space.025'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	minWidth: ({ minWidth }) => minWidth !== undefined && `${minWidth}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Percentage = styled.div({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginTop: '1px',

	font: token('font.body.small'),
	color: N200,
	width: '60px',
	display: 'flex',
	justifyContent: 'flex-end',
	marginLeft: token('space.150'),
});
