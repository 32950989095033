import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	shareModalTitle: {
		id: 'polaris-ideas.view-header.share-modal.share-with-your-team',
		defaultMessage: 'Share with your team',
		description: 'Share modal title',
	},
	closeModal: {
		id: 'polaris-ideas.view-header.share-modal.close-modal',
		defaultMessage: 'Close Modal',
		description: 'Close modal button',
	},
	shareButtonLabel: {
		id: 'polaris-ideas.view-header.share-dialog.share-button-label',
		defaultMessage: 'Share',
		description: 'Tooltip text displayed on hover for the share icon button',
	},
});
