import React, { useState } from 'react';
import { IconButton } from '@atlaskit/button/new';
import { Inline } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { IssueTypeAvatar } from '@atlassian/jira-polaris-component-issue-types/src/ui/issue-type-icon/issue-type-avatar.tsx';
import { JiraPopup as Popup } from '@atlassian/jira-popup/src/ui/jira-popup.tsx';
import { messages } from './messages.tsx';
import { AvatarPicker } from './avatar-picker.tsx';

type Props = {
	avatarId: string;
	onChange: (avatarId: string) => void;
	isDisabled: boolean;
};

export const IconField = ({ avatarId, onChange, isDisabled }: Props) => {
	const { formatMessage } = useIntl();
	const [isOpen, setIsOpen] = useState(false);

	return (
		<Inline space="space.050">
			<Popup
				isOpen={isOpen}
				onClose={() => setIsOpen(false)}
				placement="bottom-start"
				messageId="polaris-component-issue-types-manager.ui.popup"
				messageType="transactional"
				content={() => (
					<AvatarPicker
						onSelect={(value) => {
							onChange(value);
							setIsOpen(false);
						}}
					/>
				)}
				trigger={(triggerProps) => (
					<IconButton
						appearance="subtle"
						label={formatMessage(messages.change)}
						{...triggerProps}
						isDisabled={isDisabled}
						icon={() => <IssueTypeAvatar avatarId={avatarId} size="xsmall" />}
						onClick={() => setIsOpen(!isOpen)}
					/>
				)}
			/>
		</Inline>
	);
};
