/** @jsx jsx */
import React, { type PropsWithChildren, useRef } from 'react';
import { styled, jsx, css } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import {
	ProfileCardTrigger,
	type ProfileCardAction,
	type ProfilecardTriggerPosition,
	type TriggerType,
	type PrepopulatedData,
} from '@atlaskit/profilecard';

import { type FlagId, AkFlag, toFlagId } from '@atlassian/jira-flags';
import type { FlagCustomRenderProps } from '@atlassian/jira-flags/src/services/types';
import { AnalyticsEventToProps } from '@atlassian/jira-product-analytics-bridge';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useProfileClient } from '../../controllers/profileclient/index.tsx';
import { analyticsViewComponentName } from './constants.tsx';

const PEOPLE_PROFILE_PATH = '/people/';

const ProfileCardTriggerWithAnalytics = AnalyticsEventToProps(analyticsViewComponentName, {
	analytics: '',
})(ProfileCardTrigger);

export type Props = {
	accountId: string;
	actions?: ProfileCardAction[];
	position?: ProfilecardTriggerPosition;
	trigger?: TriggerType;
	onProfileClick?: (arg1: string) => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/react/handler-naming
	addFlag: (flag: any) => void;
	// eslint-disable-next-line jira/react/handler-naming
	dismissFlag: (flagId: FlagId) => void;
	testId?: string;
	prepopulatedData?: PrepopulatedData;
	ariaHideProfileTrigger?: boolean;
	isChildComment?: boolean;
};

export const ProfileCardView = ({
	children,
	position = 'top-start',
	trigger = 'hover',
	accountId,
	actions,
	onProfileClick,
	addFlag,
	dismissFlag,
	testId,
	prepopulatedData,
	ariaHideProfileTrigger,
	isChildComment,
}: PropsWithChildren<Props>) => {
	const profileCardWrapperRef = useRef<HTMLDivElement>(null);
	const cloudId = useCloudId();
	const viewingUserId = useAccountId();
	const { profileClient } = useProfileClient();

	return (
		// Stop the propagation of the click event when clicking on the profile card pop up,
		// to avoid closing the popup and opening the people picker when clicking the info inside.
		// Don't stop propagation when clicking the people picker directly.
		<Wrapper
			css={[isChildComment && replyProfileCardStyles, isChildComment && profileCardStyles]}
			ref={profileCardWrapperRef}
			onClick={(event) => {
				if (
					profileCardWrapperRef?.current !== null &&
					// @ts-expect-error - TS2571 - Object is of type 'unknown'.
					!profileCardWrapperRef?.current?.contains(event.target)
				) {
					event.stopPropagation();
				}
			}}
			data-vc={`profilecard-wrapper${__SERVER__ ? '-ssr' : ''}`}
			isVisualRefresh={isVisualRefreshEnabled()}
		>
			<ProfileCardTriggerWithAnalytics
				cloudId={cloudId}
				userId={accountId}
				resourceClient={profileClient}
				position={position}
				trigger={trigger}
				testId={testId}
				actions={actions}
				reportingLinesProfileUrl={PEOPLE_PROFILE_PATH}
				prepopulatedData={prepopulatedData}
				onReportingLinesClick={
					// @ts-expect-error - TS7006 - Parameter 'user' implicitly has an 'any' type.
					onProfileClick ? (user) => onProfileClick(user.accountIdentifier) : null
				}
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				addFlag={(flag: any) => {
					const flagId = toFlagId(flag.id);
					addFlag({
						id: flagId,
						render: (props: FlagCustomRenderProps) => (
							<AkFlag
								{...flag}
								onDismissed={() => {
									dismissFlag(flagId);
								}}
								{...props}
							/>
						),
					});
				}}
				viewingUserId={viewingUserId}
				ariaHideProfileTrigger={ariaHideProfileTrigger}
			>
				<div data-testid="profilecard-next.ui.profilecard.profilecard-trigger">
					<>{children}</>
				</div>
			</ProfileCardTriggerWithAnalytics>
		</Wrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div<{ isVisualRefresh: boolean }>({
	maxWidth: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	color: ({ isVisualRefresh }) => (isVisualRefresh ? token('color.text.subtle') : undefined),
});

const profileCardStyles = css({
	// 32 px for the padding given to the parent container (24+8)
	maxWidth: 'calc(100% - 32px)',
	position: 'relative',
	'--commentLineWidth': '1px',
	'--commentLineColor': token('color.background.accent.gray.subtler'),
	'--childTailWidth': token('space.200'),
	'&::after': {
		boxSizing: 'border-box',
		content: '',
		position: 'absolute',
		insetInlineStart: 'var(--commentInlineStart)',
		top: 'calc(-50% - var(--commentLineWidth)/2)',
		width: 'var(--childTailWidth)',
		borderInlineStart: 'var(--commentLineWidth) solid var(--commentLineColor)',
		borderBottom: 'var(--commentLineWidth) solid var(--commentLineColor)',
		backgroundColor: token('color.background.neutral.subtle'),
		borderEndStartRadius: token('space.100'),
		height: '100%',
	},
});

const replyProfileCardStyles = css({
	position: 'relative',
	'--commentsLineWidth': '1px',
	'--childTailWidth': token('space.200'),
	'--commentLineColor': token('color.background.accent.gray.subtler'),
	'--marginTopContainer': '14px', // 8+6px margins given to the containers
	'--topShift': 'calc(var(--marginTopContainer) + var(--commentsLineWidth) / 2)',
	'&::after': {
		content: '',
		boxSizing: 'border-box',
		position: 'absolute',
		insetInlineStart: 'var(--commentInlineStart)',
		top: 'calc(-50% - var(--commentsLineWidth) / 2)',
		width: 'var(--childTailWidth)',
		borderInlineStart: 'var(--commentsLineWidth) solid var(--commentLineColor)',
		borderBottom: 'var(--commentsLineWidth) solid var(--commentLineColor)',
		backgroundColor: token('color.background.neutral.subtle'),
		borderEndStartRadius: token('space.100'),
		height: '100%',
	},
});

Wrapper.displayName = 'Wrapper';
