/** @jsx jsx */
import React, { useMemo } from 'react';
import { css, jsx } from '@compiled/react';
import { IconButton } from '@atlaskit/button/new';
import EditFilledIcon from '@atlaskit/icon/core/migration/edit--edit-filled';
import { Stack, xcss, Flex } from '@atlaskit/primitives';
import { ReactRenderer as AkRenderer } from '@atlaskit/renderer';
import { token } from '@atlaskit/tokens';
import { getUnsupportedContentLevelsTracking } from '@atlassian/jira-common-util-unsupported-content/src/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import {
	onLinkClick,
	smartLinksDefault,
} from '@atlassian/jira-linking-platform-utils/src/index.tsx';
import {
	SEVERITY_DEGRADED_THRESHOLD,
	SEVERITY_NORMAL_THRESHOLD,
} from '@atlassian/jira-polaris-common/src/common/constants.tsx';
import { useIsSharedView } from '@atlassian/jira-polaris-common/src/controllers/environment/index.tsx';
import type { ViewDescription } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { WaitForAdfConsumerProps } from '@atlassian/jira-polaris-lib-editor/src/controllers/adf/main.tsx';
import messages from './messages.tsx';

type EmptyReadViewProps = {
	isEditingAllowed: boolean;
	onEdit?: () => void;
	testId?: string;
};

type ReadViewProps = {
	isEditingAllowed: boolean;
	description?: ViewDescription;
	onEdit?: () => void;
	testId?: string;
};

const EmptyDescriptionView = ({ onEdit, isEditingAllowed, testId }: EmptyReadViewProps) => {
	const { formatMessage } = useIntl();

	const isSharedView = useIsSharedView();
	const placeholder = useMemo(
		() => formatMessage(isSharedView ? messages.placeholderSharedView : messages.placeholder),
		[formatMessage, isSharedView],
	);

	return (
		<Stack testId={testId} xcss={readContentContainerStyles}>
			{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
			<div
				css={[
					rendererWrapperStyles,
					!isEditingAllowed && rendererWrapperIsEditingDisabledStyles,
					isEditingAllowed && rendererWrapperIsEditingAllowedStyles,
				]}
				onClick={onEdit}
			>
				{placeholder}

				{isEditingAllowed && (
					<Flex justifyContent="center" alignItems="center" xcss={editIconWrapperStyles}>
						<IconButton
							onClick={onEdit}
							spacing="compact"
							icon={(iconProps) => <EditFilledIcon {...iconProps} LEGACY_size="small" />}
							label={formatMessage(messages.editDescriptionButtonLabel)}
						/>
					</Flex>
				)}
			</div>
		</Stack>
	);
};

export const DescriptionReadView = ({
	onEdit,
	isEditingAllowed,
	description,
	testId,
}: ReadViewProps) => {
	const { formatMessage } = useIntl();
	const isEmptyDescription = useMemo(() => !description?.content?.length, [description]);
	const handleEdit = isEditingAllowed ? onEdit : undefined;

	if (!description || isEmptyDescription) {
		return (
			<EmptyDescriptionView
				onEdit={handleEdit}
				isEditingAllowed={isEditingAllowed}
				testId={testId}
			/>
		);
	}

	return (
		<Stack testId={testId} xcss={readContentContainerStyles}>
			<div
				css={[
					rendererWrapperStyles,
					!isEditingAllowed && rendererWrapperIsEditingDisabledStyles,
					isEditingAllowed && rendererWrapperIsEditingAllowedStyles,
				]}
			>
				<div
					css={[
						!isEditingAllowed && contentContainerStyles,
						isEditingAllowed && contentContainerIsEditingAllowedStyles,
					]}
				>
					<WaitForAdfConsumerProps>
						{({ akRendererProps }) => (
							<AkRenderer
								{...akRendererProps}
								eventHandlers={{
									onUnhandledClick: handleEdit,
									smartCard: { onClick: onLinkClick },
									link: { onClick: onLinkClick },
								}}
								document={description}
								analyticsEventSeverityTracking={{
									enabled: true,
									severityDegradedThreshold: SEVERITY_DEGRADED_THRESHOLD,
									severityNormalThreshold: SEVERITY_NORMAL_THRESHOLD,
								}}
								unsupportedContentLevelsTracking={getUnsupportedContentLevelsTracking()}
								useSpecBasedValidator
								disableHeadingIDs
								featureFlags={{
									codeBidiWarnings: true,
									'code-bidi-warnings': true,
									'renderer-tti-tracking': fg('renderer_tti_tracking_jira'),
								}}
								smartLinks={smartLinksDefault}
								UNSTABLE_allowTableResizing={expVal(
									'platform_editor_support_table_in_comment_jira',
									'isEnabled',
									false,
								)}
								UNSTABLE_allowTableAlignment={expVal(
									'platform_editor_support_table_in_comment_jira',
									'isEnabled',
									false,
								)}
							/>
						)}
					</WaitForAdfConsumerProps>
				</div>

				{isEditingAllowed && (
					<Flex justifyContent="center" alignItems="center" xcss={editIconWrapperStyles}>
						<IconButton
							onClick={handleEdit}
							spacing="compact"
							icon={(iconProps) => <EditFilledIcon {...iconProps} LEGACY_size="small" />}
							label={formatMessage(messages.editDescriptionButtonLabel)}
						/>
					</Flex>
				)}
			</div>
		</Stack>
	);
};

const readContentContainerStyles = xcss({
	width: '100%',
	overflowY: 'auto',
});

const editIconWrapperStyles = xcss({
	position: 'absolute',
	top: '5px',
	right: 'space.075',
	borderRadius: 'border.radius.100',
});

const contentContainerStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'.ak-renderer-document': {
		paddingRight: 0,
	},
});

const contentContainerIsEditingAllowedStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'.ak-renderer-document': {
		paddingRight: token('space.400'),
	},
});

const rendererWrapperStyles = css({
	overflow: 'auto',
	transition: '0.3s',
	position: 'relative',
	color: token('color.text.subtlest'),
});

const rendererWrapperIsEditingDisabledStyles = css({
	padding: 0,
	marginLeft: 0,
});

const rendererWrapperIsEditingAllowedStyles = css({
	paddingTop: token('space.100'),
	paddingRight: token('space.100'),
	paddingBottom: token('space.100'),
	paddingLeft: token('space.100'),
	marginLeft: token('space.negative.100'),
	'&:hover': {
		backgroundColor: token('color.background.neutral.subtle.hovered'),
		borderRadius: token('border.radius.100'),
	},
});
