import React from 'react';
import Heading from '@atlaskit/heading';
import { Box, xcss } from '@atlaskit/primitives';
import { token, useThemeObserver } from '@atlaskit/tokens';
import ViewPermissionsSpotlightImageDark from '@atlassian/jira-illustrations/src/ui/adg4/jira/other/assets/jpd-view-permission-lock-icon-spotlight-header-darkmode.svg';
import ViewPermissionsSpotlightImage from '@atlassian/jira-illustrations/src/ui/adg4/jira/other/assets/jpd-view-permission-lock-icon-spotlight-header.svg';
import { useIntl } from '@atlassian/jira-intl';
import { VIEW_PERMISSIONS_LOCK_SPOTLIGHT_DISMISSED } from '@atlassian/jira-polaris-lib-onboarding-flows/src/controllers/constants.tsx';
import { JiraSpotlight } from '@atlassian/jira-spotlight/src/ui/jira-spotlight.tsx';
import { useOnboardingFlowsActions } from '../../../controllers/index.tsx';
import { useGetViewPermissionLockSpotlight } from '../../../controllers/selectors/view-permissions-hooks.tsx';
import { messages } from './messages.tsx';

export const ViewPermissionsSpotlight = () => {
	const { formatMessage } = useIntl();
	const { colorMode } = useThemeObserver();
	const { setViewPermissionsSpotlightDismissed } = useOnboardingFlowsActions();
	const viewPermissionLockSpotlight = useGetViewPermissionLockSpotlight();

	if (viewPermissionLockSpotlight !== false) {
		return null;
	}

	return (
		<JiraSpotlight
			actions={[
				{
					onClick: setViewPermissionsSpotlightDismissed,
					text: formatMessage(messages.spotlightCta),
				},
			]}
			target={VIEW_PERMISSIONS_LOCK_SPOTLIGHT_DISMISSED}
			key={VIEW_PERMISSIONS_LOCK_SPOTLIGHT_DISMISSED}
			messageId="polaris-lib-onboarding-flows.ui.view-permissions.lock-button.jira-spotlight"
			messageType="engagement"
			dialogWidth={275}
			dialogPlacement="bottom right"
			image={
				colorMode === 'light' ? ViewPermissionsSpotlightImage : ViewPermissionsSpotlightImageDark
			}
			targetRadius={3}
			targetBgColor={token('elevation.surface')}
		>
			<Heading size="medium">{formatMessage(messages.spotlightTitle)}</Heading>
			<Box as="p" xcss={spotlightContentStyles}>
				{formatMessage(messages.spotlightContent)}
			</Box>
		</JiraSpotlight>
	);
};

const spotlightContentStyles = xcss({
	font: 'font.body',
	marginTop: 'space.100',
});
