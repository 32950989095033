import { runInBatch } from '@atlassian/jira-polaris-lib-run-in-batch/src/index.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import type { RemoteIssue } from '@atlassian/jira-polaris-remote-issue/src/controllers/types.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { logSafeErrorWithoutCustomerDataWrapper } from '@atlassian/jira-polaris-lib-errors/src/common/utils/index.tsx';
import {
	type Props,
	type State,
	LinkedDeliveryIssuesMergeOption,
	DataPointsMergeOption,
	ConnectionsMergeOption,
} from '../../types.tsx';
import { getIssuesToConnect } from './utils.tsx';

export const copyIssueLinks =
	(isSameProject: boolean): Action<State, Props> =>
	async (
		{ getState },
		{
			projectId,
			polarisDeliveryIssueLinkType,
			polarisDataPointIssueLinkType,
			issuesRemote,
			fields,
			onUpdateIssueConnections,
			onIssueLinked,
		},
	) => {
		const { targetIssue, selectedIssues, mergeOptions } = getState();
		if (projectId === null || targetIssue === null) {
			return;
		}

		const copyInsightIssueLinks = mergeOptions[DataPointsMergeOption];
		const copyDeliveryIssueLinks = mergeOptions[LinkedDeliveryIssuesMergeOption];
		const copyConnections = mergeOptions[ConnectionsMergeOption];

		if (fg('jpd_issues_relationships')) {
			if (!copyInsightIssueLinks && !copyDeliveryIssueLinks && !copyConnections) {
				return;
			}
		} else if (!copyInsightIssueLinks && !copyDeliveryIssueLinks) {
			return;
		}

		try {
			const selectedIssuesData: RemoteIssue[] = [];

			await runInBatch(
				selectedIssues.map((selectedIssue) => async () => {
					const selectedIssueData = await issuesRemote.fetchIssue({
						issueIdOrKey: String(selectedIssue.id),
					});

					selectedIssuesData.push(selectedIssueData);

					if ('meta' in selectedIssueData) {
						const selectedIssueLinks = selectedIssueData?.meta?.issueLinks?.links || [];
						const linksToCopy = selectedIssueLinks.filter(
							(link) =>
								(copyInsightIssueLinks &&
									String(link.typeId) === String(polarisDataPointIssueLinkType)) ||
								(copyDeliveryIssueLinks &&
									String(link.typeId) === String(polarisDeliveryIssueLinkType)),
						);

						await Promise.all(
							linksToCopy.map(async (link) => {
								const inwardIssueKey =
									selectedIssueData.key === link.inwardIssue.key
										? targetIssue.key
										: link.inwardIssue.key;
								const outwardIssueKey =
									selectedIssueData.key === link.outwardIssue.key
										? targetIssue.key
										: link.outwardIssue.key;

								await issuesRemote.createIssueLink({
									issueLinkTypeId: link.typeId.toString(),
									inwardIssueKey,
									outwardIssueKey,
								});

								if (onIssueLinked) {
									onIssueLinked(outwardIssueKey, inwardIssueKey, isSameProject);
								}
							}),
						);
					}
				}),
				5,
			);

			if (copyConnections && fg('jpd_issues_relationships')) {
				const connectionFieldKeys = Object.values(fields)
					.filter(({ type }) => type === FIELD_TYPES.CONNECTION)
					.map(({ key }) => key);

				const issuesToConnect = getIssuesToConnect({
					selectedIssuesData,
					connectionFieldKeys,
				});

				await onUpdateIssueConnections({
					issueId: targetIssue.id.toString(),
					issuesToConnect,
					issuesToDisconnect: [],
				});
			}
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (err: any) {
			logSafeErrorWithoutCustomerDataWrapper(
				'polaris.merge-ideas-error',
				'Failed to copy linked issues to target idea',
				err,
			);
			throw err;
		}
	};
