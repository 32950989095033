import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	heading: {
		id: 'polaris-ideas.common.link-issue.heading',
		defaultMessage: 'Link existing ticket',
		description:
			'Panel heading for creating an issue link from this idea to an existing Jira ticket in any other project.',
	},
	link: {
		id: 'polaris-ideas.common.link-issue.link',
		defaultMessage: 'Add',
		description:
			'Button label for create an issue link between this idea and a selected delivery work item.',
	},
	cancel: {
		id: 'polaris-ideas.common.link-issue.cancel',
		defaultMessage: 'Cancel',
		description: 'Button label for cancellation.',
	},
});
