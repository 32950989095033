import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	noProjectPermissionsErrorTitle: {
		id: 'polaris-ideas.right-sidebar.ideas-bucket.card.no-project-permissions-error-title',
		defaultMessage: "You can't edit this idea",
		description:
			'Error message title when user do not have project permissions to edit ideas, so to drag them around.',
	},
	noProjectPermissionsErrorDescription: {
		id: 'polaris-ideas.right-sidebar.ideas-bucket.card.no-project-permissions-error-description',
		defaultMessage:
			"You don't have permission to edit ideas from <b>{projectName}</b> project. Contact your project admin.",
		description:
			'Error message description when user do not have project permissions to edit ideas, so to drag them around.',
	},
	timelineFieldsNotAddedToProjectErrorTitle: {
		id: 'polaris-ideas.right-sidebar.ideas-bucket.card.timeline-field-not-added-to-project-error-title',
		defaultMessage: "You can't add this idea",
		description:
			'Error message title when user try to add an idea to timeline but the its project is missing one of the global fields used in start date or end date.',
	},
	timelineFieldsNotAddedToProjectErrorDescription: {
		id: 'polaris-ideas.right-sidebar.ideas-bucket.card.timeline-fields-not-added-to-project-error-description',
		defaultMessage:
			'You can’t add this idea to the timeline because either the start or end global date field needs to be added to <b>{projectName}</b>. To do so, select the global field > Edit and add the field to the project.',
		description:
			'Error message title when user try to add an idea to timeline but the its project is missing one of the global fields used in start date or end date.',
	},
	matrixFieldsNotAddedToProjectErrorTitle: {
		id: 'polaris-ideas.right-sidebar.ideas-bucket.card.matrix-field-not-added-to-project-error-title',
		defaultMessage: "You can't add this idea",
		description:
			'Error message title when user try to add an idea to matrix but the its project is missing one of the global fields used in x axis or y axis.',
	},
	matrixFieldsNotAddedToProjectErrorDescription: {
		id: 'polaris-ideas.right-sidebar.ideas-bucket.card.matrix-fields-not-added-to-project-error-description',
		defaultMessage:
			'The fields used to create this matrix haven’t been added to <b>{projectName}</b>. To do so, select the global field > Edit and add the field to the project.',
		description:
			'Error message title when user try to add an idea to matrix but the its project is missing one of the global fields used in x axis or y axis.',
	},
});
