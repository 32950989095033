import React, { memo } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import type { ExternalReferenceProvider } from '@atlassian/jira-polaris-domain-field/src/field/external-reference/types.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { RefObject } from '@atlassian/jira-shared-types/src/general.tsx';
import { CompactValuesList } from '../../compact-values-list/index.tsx';
import { ObservableValuesList } from '../../observable-values-list/index.tsx';
import { WithCheckProductAvailability } from '../common/check-product-availability/index.tsx';
import { SignInRequired } from '../common/sign-in/index.tsx';
import { ExternalReferenceRenderer } from '../renderer/index.tsx';

export type ExternalReferenceViewProps = {
	provider?: ExternalReferenceProvider;
	isActive?: boolean;
	isEditable: boolean;
	isMultiline?: boolean;
	isCompact?: boolean;
	fieldKey: FieldKey;
	options: string[];
	placeholder?: string | undefined;
	containerRef?: RefObject<HTMLDivElement | null>;
};

export const ExternalReferenceViewNonObservable = memo(
	({ isEditable, options, provider, isMultiline }: ExternalReferenceViewProps) => (
		<SingleValueContainer isEditable={isEditable}>
			<WithCheckProductAvailability aris={options}>
				{(availableAri: string[], unavailableCloudIds: string[]) => (
					<>
						{unavailableCloudIds.length ? (
							unavailableCloudIds.map((cloudId) => (
								<SignInRequired key={cloudId} cloudId={cloudId} />
							))
						) : (
							<ExternalReferenceRenderer
								provider={provider}
								aris={availableAri}
								maxLines={isMultiline ? 2 : undefined}
								showHoverCard
							/>
						)}
					</>
				)}
			</WithCheckProductAvailability>
		</SingleValueContainer>
	),
);

export const ExternalReferenceViewObservable = memo(
	({
		isActive,
		isEditable,
		containerRef,
		isMultiline,
		options,
		provider,
		isCompact,
	}: ExternalReferenceViewProps) => {
		const multiValueContainerTestId =
			'polaris-common.ui.fields.external-reference.multi-value-container';
		if (isCompact) {
			return (
				<WithCheckProductAvailability aris={options}>
					{(availableAri: string[], unavailableCloudIds: string[]) => (
						<CompactValuesList>
							{availableAri
								.map((value, index) => (
									<ExternalReferenceRenderer
										key={value}
										provider={provider}
										aris={[value]}
										isContainerClickable={index > 0}
										showHoverCard
									/>
								))
								.concat(
									unavailableCloudIds.map((cloudId) => (
										<SignInRequired key={cloudId} cloudId={cloudId} />
									)),
								)}
						</CompactValuesList>
					)}
				</WithCheckProductAvailability>
			);
		}

		return (
			<MultiValueContainer isEditable={isEditable} data-testid={multiValueContainerTestId}>
				<OptionsContainerWrapper isMultiline={isMultiline}>
					<WithCheckProductAvailability aris={options}>
						{(availableAri: string[], unavailableCloudIds: string[]) => (
							<ObservableValuesList
								isActive={isActive}
								containerRef={containerRef}
								MoreTagWrapper={MoreTagWrapper}
								previewChildren={
									<>
										{availableAri.length && (
											<ExternalReferenceRenderer
												provider={provider}
												aris={availableAri}
												maxLines={2}
												isContainerClickable
											/>
										)}
										{unavailableCloudIds.map((cloudId) => (
											<SignInRequired key={cloudId} cloudId={cloudId} />
										))}
									</>
								}
							>
								{availableAri
									.map((value) => (
										<ExternalReferenceRenderer
											key={value}
											provider={provider}
											aris={[value]}
											showHoverCard
										/>
									))
									.concat(
										unavailableCloudIds.map((cloudId) => (
											<SignInRequired key={cloudId} cloudId={cloudId} />
										)),
									)}
							</ObservableValuesList>
						)}
					</WithCheckProductAvailability>
				</OptionsContainerWrapper>
			</MultiValueContainer>
		);
	},
);

export const ExternalReferenceView = memo(
	({
		isActive,
		isEditable,
		isMultiline,
		fieldKey,
		placeholder,
		options,
		provider,
		containerRef,
		isCompact,
	}: ExternalReferenceViewProps) => {
		if (options.length === 0) {
			return (
				<PlaceholderContainer isEditable={isEditable}>
					{placeholder !== undefined ? placeholder : null}
				</PlaceholderContainer>
			);
		}

		if (options.length === 1) {
			return (
				<ExternalReferenceViewNonObservable
					provider={provider}
					fieldKey={fieldKey}
					isEditable={isEditable}
					options={options}
					isMultiline={isMultiline}
				/>
			);
		}

		return (
			<ExternalReferenceViewObservable
				provider={provider}
				fieldKey={fieldKey}
				isActive={isActive}
				isEditable={isEditable}
				options={options}
				containerRef={containerRef}
				isMultiline={isMultiline}
				isCompact={isCompact}
			/>
		);
	},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MoreTagWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'[data-component-selector="more-tag-wrapper-93Qc"]': {
		bottom: '3px',
		zIndex: 2,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PlaceholderContainer = styled.div<{ isEditable: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	cursor: ({ isEditable }) => (isEditable ? 'pointer' : 'auto'),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: '16px',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	paddingTop: '9px',
	paddingRight: token('space.075'),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	paddingBottom: '9px',
	paddingLeft: token('space.075'),
	minHeight: '16px',
	color: token('color.text.subtle'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SingleValueContainer = styled.div<{ isEditable: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	cursor: ({ isEditable }) => isEditable && 'pointer',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: '16px',
	minHeight: '16px',
	width: 'fit-content',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MultiValueContainer = styled.div<{ isEditable: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	cursor: ({ isEditable }) => isEditable && 'pointer',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: 1,
	overflow: 'hidden',
	display: 'grid',
	gridTemplateColumns: 'auto 1fr',
	gridTemplateRows: '1fr',
	gap: '0px 0px',
	gridTemplateAreas: '"weight options"',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OptionsContainerWrapper = styled.div<{ isMultiline?: boolean }>({
	gridArea: 'options',
	display: 'flex',
	flexWrap: 'wrap',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: ({ isMultiline }) => !isMultiline && 'max-content',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	alignItems: ({ isMultiline }) => !isMultiline && 'center',
});
