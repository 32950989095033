export const SEND_EVENTS_INTERVAL = 30 * 1000;
export const INTERVAL_1M = 60 * 1000;
export const INTERVAL_5M = 5 * INTERVAL_1M;

export const TRACKABLE_URL_PATTERNS = [
	'/gateway/api/graphql',
	'/rest/api',
	'/rest/polaris',
	'/gateway/api/jpd',
	'/gateway/api/polaris',
	'/rest/gira/1',
];

export const IGNORED_STATUS_CODES: Record<number, boolean> = {
	404: true,
};
