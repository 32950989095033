import React, { useEffect, useMemo } from 'react';
import { Box, Stack, xcss } from '@atlaskit/primitives';
import SectionMessage from '@atlaskit/section-message';
import { useIntl, type MessageDescriptor } from '@atlassian/jira-intl';
import { useCanExportViews } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { sendPendoTrackEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/pendo/index.tsx';
import {
	FireScreenAnalytics,
	fireUIAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { fg } from '@atlassian/jira-feature-gating';
import { ExportViewAsCsv, type ExportViewAsCsvProps } from './export-as-csv/index.tsx';
import { ExportViewAsImage, type ExportViewAsImageProps } from './export-as-image/index.tsx';
import messages from './messages.tsx';

const Warnings = ({ warnings }: { warnings: MessageDescriptor[] }) => {
	const { formatMessage } = useIntl();

	if (warnings.length === 1) {
		// eslint-disable-next-line @atlaskit/design-system/use-primitives-text
		return <p>{formatMessage(warnings[0])}</p>;
	}

	return (
		<Box as="ul" xcss={warningListStyles}>
			{warnings.map((warning) => (
				<li key={warning.id}>{formatMessage(warning)}</li>
			))}
		</Box>
	);
};

export type ExportDialogContentProps = ExportViewAsCsvProps &
	ExportViewAsImageProps & {
		isViewConfigured: boolean;
	};

export const ExportDialogContent = ({
	issueIds,
	containerName,
	isViewConfigured,
	viewKind,
	viewTitle,
	isLoading,
	statusCategories,
	visibleFields,
	onExporting,
	onExportStringValues,
}: ExportDialogContentProps) => {
	const canExportViews = useCanExportViews();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const isViewDisplayingIssues = issueIds.length;

	useEffect(() => {
		sendPendoTrackEvent({
			actionSubjectAndAction: 'exportModal opened',
		});

		return () => {
			sendPendoTrackEvent({
				actionSubjectAndAction: 'exportModal closed',
			});
			fireUIAnalytics(createAnalyticsEvent({}), 'modal closed', 'export');
		};
	}, [createAnalyticsEvent]);

	const warnings = useMemo(() => {
		const warningMessages: MessageDescriptor[] = [];

		if (!canExportViews) warningMessages.push(messages.noPermissions);
		if (!isViewConfigured || !isViewDisplayingIssues) warningMessages.push(messages.emptyView);

		return warningMessages;
	}, [canExportViews, isViewConfigured, isViewDisplayingIssues]);

	if (warnings.length) {
		return (
			<Box padding="space.100" xcss={warningsContainerStyles}>
				<SectionMessage testId="polaris-component-view-export.ui.export-view-dialog.export-dialog-content.section-message">
					<Warnings warnings={warnings} />
				</SectionMessage>
			</Box>
		);
	}

	return (
		<Stack
			testId="polaris-component-view-export.ui.export-view-dialog.export-dialog-content.stack"
			xcss={
				fg('polaris_pol-12839_view_header_redesign')
					? exportContainerStylesNew
					: exportContainerStylesOld
			}
		>
			<FireScreenAnalytics />
			<ExportViewAsImage
				viewTitle={viewTitle}
				containerName={containerName}
				viewKind={viewKind}
				isLoading={isLoading}
				onExporting={onExporting}
			/>
			<ExportViewAsCsv
				viewTitle={viewTitle}
				containerName={containerName}
				statusCategories={statusCategories}
				visibleFields={visibleFields}
				issueIds={issueIds}
				isLoading={isLoading}
				onExporting={onExporting}
				onExportStringValues={onExportStringValues}
			/>
		</Stack>
	);
};

const exportContainerStylesOld = xcss({
	minWidth: '180px',
	paddingTop: 'space.075',
	paddingBottom: 'space.075',
});

const exportContainerStylesNew = xcss({
	minWidth: '180px',
});

const warningsContainerStyles = xcss({
	maxWidth: '400px',
});

const warningListStyles = xcss({
	paddingInlineStart: 'space.200',
});
