import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import { createSocketIOCollabProvider } from '@atlaskit/collab-provider/socket-io-provider';
import type { ProviderError } from '@atlaskit/editor-common/collab';
import type { EditorState } from '@atlaskit/editor-prosemirror/state';
import type { ViewIdentifiers } from '@atlassian/jira-polaris-component-view-id-mapping/src/types.tsx';
import type { ViewUUID } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import { fireOperationalAnalyticsDeferred } from '@atlassian/jira-product-analytics-bridge';
import type { ADF } from '@atlassian/jira-rich-content/src/index.tsx';
import type { AccountId } from '@atlassian/jira-shared-types/src/general.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { fg } from '@atlassian/jira-feature-gating';
import type { CollabEventPresenceData, User } from '../../../domain/types.tsx';
import { EMPTY_DOCUMENT } from '../constants.tsx';
import type { CollabEditProvider, State } from '../types.tsx';
import { getCollabServiceUrl } from '../utils/collab-service.tsx';
import { permissionTokenRefresh } from '../utils/permission-token-refresh.tsx';
import { presenceLeft, presenceJoined } from './presence.tsx';
import { getUser } from './user.tsx';

const reset = (viewUUID: ViewUUID, { getState, setState }: StoreActionApi<State>) => {
	getState().views[viewUUID]?.provider?.destroy();
	setState({
		...getState(),
		views: {
			...getState().views,
			[viewUUID]: {
				provider: undefined,
				presence: [],
			},
		},
	});
};

export const resetProvider = (viewUUID: ViewUUID) => (api: StoreActionApi<State>) =>
	reset(viewUUID, api);

export const createCollabProviderInitialState = (clientId?: string) =>
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	({
		// @ts-expect-error - TS2352: Conversion of type '{ key: string; spec: { config: { clientID: string | undefined; }; }; }[]' to type 'Plugin[]' may be a mistake because neither type sufficiently overlaps with the other.
		plugins: [{ key: 'collab$', spec: { config: { clientID: clientId } } }],
	}) as Partial<EditorState> as EditorState;

type UpdateProviderProps = {
	createAnalyticsEvent: CreateUIAnalyticsEvent;
	onError: (error: Error, cause: String) => void;
	currentUser: AccountId;
	apolloClient: PolarisApolloClient;
};

export const initializeCollabProvider = ({
	provider,
	userId,
	doc = EMPTY_DOCUMENT,
}: {
	provider: CollabEditProvider;
	userId: string;
	doc?: ADF;
}) => {
	const initCollab = () => {
		provider.off('init', initCollab);
		// @ts-expect-error - Property 'channel' is private
		provider.channel.eventEmitter.emit('init', {
			doc,
			userId,
			version: 0,
			metadata: {},
		});
	};
	provider.on('init', initCollab);
};

export const updateProvider =
	(
		viewId: ViewIdentifiers,
		{ createAnalyticsEvent, onError, currentUser, apolloClient }: UpdateProviderProps,
	) =>
	({ getState, setState, dispatch }: StoreActionApi<State>) => {
		reset(viewId.viewUUID, { getState, setState, dispatch });

		const { criticalGetCollabTokenErrorHappened } = getState();
		if (criticalGetCollabTokenErrorHappened) {
			return;
		}

		if (viewId.viewAri === undefined) {
			// invalid props, do not create collab provider
			return;
		}

		const onPresence = (presenceData: CollabEventPresenceData) => {
			if (presenceData) {
				if (presenceData.left) {
					dispatch(presenceLeft(viewId.viewUUID, presenceData.left));
				}

				if (presenceData.joined) {
					dispatch(presenceJoined(viewId.viewUUID, presenceData.joined));
				}
			}
		};

		const onProviderError = (error: ProviderError) => {
			fireOperationalAnalyticsDeferred(createAnalyticsEvent({}), 'createCollabProvider failure', {
				status: error.status,
				code: error.code,
				message: error.message,
			});

			reset(viewId.viewUUID, { getState, setState, dispatch });
		};

		const onCriticalCollabTokenError = (error: Error, reason: String, status: Number) => {
			const viewState = getState().views[viewId.viewUUID];

			if (criticalGetCollabTokenErrorHappened) {
				// updateProvider may be called more than once in parallel.
				// And we want to be sure we do this only once.
				return;
			}

			fireOperationalAnalyticsDeferred(createAnalyticsEvent({}), 'createCollabProvider failure', {
				status,
				code: reason,
				message: error.message,
			});

			setState({
				...getState(),
				views: {
					...getState().views,
					[viewId.viewUUID]: {
						provider: undefined,
						presence: [],
					},
				},

				criticalGetCollabTokenErrorHappened: true,
			});

			viewState?.provider?.destroy();
			onError?.(error, reason);
		};

		const getUserAndUpdatePresence = async (id?: string): Promise<User> =>
			dispatch(getUser(viewId.viewUUID, currentUser, id));

		const provider = createSocketIOCollabProvider({
			url: getCollabServiceUrl(),
			documentAri: viewId.viewAri,
			getUser: getUserAndUpdatePresence,
			permissionTokenRefresh: permissionTokenRefresh.bind(
				null,
				apolloClient,
				viewId.viewAri,
				onCriticalCollabTokenError,
			),
			productInfo: {
				product: 'jira',
			},
			isPresenceOnly: fg('jira_polaris_use_cp_presence_only'),
		});

		// Add this as collab provider ^ 9 change Collab Participant type
		// Expected the data still there https://stash.atlassian.com/projects/JIRACLOUD/repos/jira-frontend/pull-requests/94145/overview?commentId=5310890
		// @ts-expect-error - Argument of type '(presenceData: CollabEventPresenceData) => void' is not assignable to parameter of type '(args: CollabEventPresenceData) => void'.
		provider.on('presence', onPresence);
		provider.on('error', onProviderError);

		provider.initialize(() => createCollabProviderInitialState(currentUser));
		initializeCollabProvider({ provider, userId: currentUser ?? 'unidentified' });
		setState({
			...getState(),
			views: {
				...getState().views,
				[viewId.viewUUID]: {
					provider,
					presence: [],
				},
			},
		});
	};
