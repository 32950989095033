import type { FormatMessage } from '@atlassian/jira-shared-types/src/general.tsx';
import { messages } from './messages.tsx';

export const getTranslatedRoleName = (
	roleName: string,
	originalTranslatedRoleName: string,
	formatMessage?: FormatMessage,
) => {
	if (formatMessage !== undefined) {
		if (roleName === 'Member') {
			return formatMessage(messages.creatorRoleName);
		}
		if (roleName === 'Viewer') {
			return formatMessage(messages.contributorRoleName);
		}
	}
	return originalTranslatedRoleName;
};

export const getTranslatedRoleDescription = (
	roleName: string,
	originalTranslatedRoleDescription: string,
	formatMessage?: FormatMessage,
) => {
	if (formatMessage !== undefined) {
		if (roleName === 'Administrator') {
			return formatMessage(messages.administratorRoleDescription);
		}
		if (roleName === 'Member') {
			return formatMessage(messages.creatorRoleDescription);
		}
		if (roleName === 'Viewer') {
			return formatMessage(messages.contributorRoleDescription);
		}
	}
	return originalTranslatedRoleDescription;
};
