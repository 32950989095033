import type { ProjectFieldValue } from '@atlassian/jira-polaris-domain-field/src/field/project/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import { createHigherLevelIssueHook, createIssueHook } from '../../utils.tsx';
import {
	createGetProject,
	createGetProjectId,
	createGetProjectIds,
	getAllIssuesProjectIds,
} from './index.tsx';

export const useProjectForIssue = createHigherLevelIssueHook<
	LocalIssueId | undefined,
	ProjectFieldValue | undefined
>(createGetProject);

export const useProjectIdForIssue = createHigherLevelIssueHook<
	LocalIssueId | undefined,
	ProjectId | undefined
>(createGetProjectId);

export const useProjectIdsForIssues = createHigherLevelIssueHook<LocalIssueId[], ProjectId[]>(
	createGetProjectIds,
);

export const useAllIssuesProjectIds = createIssueHook(getAllIssuesProjectIds);
