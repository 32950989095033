import type { CapturingResult } from './frame-capturer.tsx';

type OnFlush = (scrolls: CapturingResult[]) => void;

export const createScrollDataCollector = (onFlush: OnFlush, bufferingInterval: number) => {
	let timeoutId: ReturnType<typeof setTimeout> | undefined;
	let scrolls: CapturingResult[] = [];

	const flush = () => {
		onFlush(scrolls);

		scrolls = [];
		timeoutId = undefined;
	};

	const collect = (data: CapturingResult) => {
		scrolls.push(data);

		if (timeoutId === undefined) {
			timeoutId = setTimeout(flush, bufferingInterval);
		}
	};

	const dispose = () => {
		clearTimeout(timeoutId);
	};

	return {
		collect,
		dispose,
	};
};
