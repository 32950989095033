import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { RenameFieldOptionRequest } from './types.tsx';

export const renameFieldOption = ({
	projectId,
	issueTypeId,
	fieldKey,
	optionId,
	newTitle,
}: RenameFieldOptionRequest): Promise<void> =>
	performPutRequest(
		`/rest/polaris/v2/projects/${projectId}/fields/${fieldKey}/options/${optionId}`,
		{
			body: fg('jpd_use_field_association_helper')
				? JSON.stringify({
						value: newTitle,
					})
				: JSON.stringify({
						issueTypeId: +issueTypeId,
						value: newTitle,
					}),
		},
	);
