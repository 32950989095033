// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import type { State, Props } from '../../types.tsx';

export const toggleProjectAccessLevelModal =
	(): Action<State, Props> =>
	({ getState, setState }) => {
		const oldState = getState();
		setState({
			...oldState,
			ui: {
				...oldState.ui,
				accessLevel: {
					...oldState.ui.accessLevel,
					isOpen: !oldState.ui.accessLevel.isOpen,
				},
			},
		});
	};
