import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import { fg } from '@atlassian/jira-feature-gating';
import type { Ari } from '@atlassian/jira-platform-ari/src/index.tsx';
import {
	FIELD_TYPES,
	JIRA_API_FIELD_TYPES,
} from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import { fetchProject } from '@atlassian/jira-polaris-remote-legacy-project/src/services/project-config/get/index.tsx';
import { fireOperationalAnalytics } from '@atlassian/jira-product-analytics-bridge/src/utils/fire-analytics';
import type { IssueTypeId, ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import {
	getFieldsWithJql,
	getPolarisFields,
	getSelectedPolarisFields,
} from '../../../../services/jira/fetch/index.tsx';
import type { JiraFieldDict } from '../../../../services/jira/types.tsx';
import type { JiraField } from '../../../../services/types.tsx';
import { AccessForbiddenError, RemoteFieldsNotInitializedError } from '../../../utils.tsx';
import type { FetchResponse } from '../../types.tsx';
import { transformResponse } from '../transform/index.tsx';

const reasonOrEmpty = <T,>(promise: PromiseSettledResult<T>) =>
	promise.status === 'rejected' ? [promise.reason] : [];

const virtualFields: JiraField[] = [
	{
		key: 'issueid',
		name: 'issueid',
		description: '',
		type: FIELD_TYPES.ISSUE_ID,
		custom: false,
		clauseNames: ['issueid', 'id'],
		global: false,
	},
];

const getIssueTypesPerFieldKeysOld = (
	fields: JiraField[],
	issueTypeId: IssueTypeId,
): Record<FieldKey, IssueTypeId[]> => {
	const issueTypesPerFieldKeys: Record<FieldKey, IssueTypeId[]> = {};

	fields.forEach((field) => {
		issueTypesPerFieldKeys[field.key] = [issueTypeId];
	});

	return issueTypesPerFieldKeys;
};

const getIssueTypesPerFieldKeys = (jiraFieldDict: JiraFieldDict) => {
	const issueTypesPerFieldKeys: Record<FieldKey, IssueTypeId[]> = {};

	Object.entries(jiraFieldDict).forEach(([issueTypeId, fields]) => {
		fields.forEach((field) => {
			const issueTypes = issueTypesPerFieldKeys[field.key];
			issueTypesPerFieldKeys[field.key] = issueTypes ? [...issueTypes, issueTypeId] : [issueTypeId];
		});
	});

	return issueTypesPerFieldKeys;
};

export const fetchFields = async (
	apolloClient: PolarisApolloClient,
	createAnalyticsEvent: CreateUIAnalyticsEvent,
	projectId: ProjectId,
	projectAri: Ari,
): Promise<FetchResponse> => {
	const jiraFieldsRequestSafe = getPolarisFields(projectId);

	const settledResults = await Promise.allSettled([
		jiraFieldsRequestSafe.then((result) => Object.values(result)[0]),
		fetchProject(apolloClient, createAnalyticsEvent, projectAri),
	]);

	const [jiraFieldsSettledResult, gqlProjectSettledResult] = settledResults;

	const errors = [
		...reasonOrEmpty(jiraFieldsSettledResult),
		...reasonOrEmpty(gqlProjectSettledResult),
	];

	if (
		jiraFieldsSettledResult.status === 'rejected' ||
		gqlProjectSettledResult.status === 'rejected'
	) {
		const errorMessage = errors.join(', ');
		if (
			errorMessage.includes('API-9999') ||
			errorMessage.includes('API-9998') ||
			errorMessage.includes('JIRA_ACCESS_NOT_PERMITTED')
		) {
			throw new AccessForbiddenError();
		}

		throw new Error(`Failed to resolve prerequisite requests for fields loading: ${errorMessage}`);
	}

	// all requests succeeded
	const gqlProject = gqlProjectSettledResult.value;

	if (!gqlProject.onboarded) {
		// project is still onboarding, nothing to fetch
		throw new RemoteFieldsNotInitializedError(
			`project is not onboarded (onboarded: ${gqlProject.onboarded})`,
		);
	}

	const [issueTypeId, jiraFields] = await jiraFieldsRequestSafe.then(
		(result) => Object.entries(result)[0],
	);

	fireOperationalAnalytics(createAnalyticsEvent({}), 'jpdFetchFields succeeded', {
		projectId,
		fieldsWithOptions: jiraFields
			.filter((field) => field.options)
			.reduce(
				(acc, field) =>
					Object.assign(acc, {
						[field.key]: field.options?.length,
					}),
				{},
			),
		fieldsCount: jiraFields.length,
	});

	const jiraFieldsWithVirtual = [...jiraFields, ...virtualFields];

	if (fg('jpd_multiple_issue_types_per_field') || fg('jpd_issue_types_ga')) {
		const jiraFieldDict = await jiraFieldsRequestSafe;
		return transformResponse(jiraFieldsWithVirtual, getIssueTypesPerFieldKeys(jiraFieldDict));
	}

	return transformResponse(
		jiraFieldsWithVirtual,
		getIssueTypesPerFieldKeysOld(jiraFieldsWithVirtual, issueTypeId),
	);
};

export const fetchSelectedFields = async (projectId: ProjectId, fieldKeys: string[]) => {
	const jiraFields = await getSelectedPolarisFields(projectId, fieldKeys);

	const [issueTypeId, fields] = Object.entries(jiraFields)[0];

	if (fg('jpd_multiple_issue_types_per_field') || fg('jpd_issue_types_ga')) {
		return transformResponse(fields, getIssueTypesPerFieldKeys(jiraFields));
	}

	return transformResponse(fields, getIssueTypesPerFieldKeysOld(fields, issueTypeId));
};

const requiredFakeFields: JiraField[] = [
	{
		key: 'issueid',
		name: 'issueid',
		description: '',
		type: JIRA_API_FIELD_TYPES.ISSUE_ID,
		custom: false,
		clauseNames: ['issueid', 'id'],
		global: false,
	},
	{
		key: 'description',
		name: 'Description',
		description: '',
		type: JIRA_API_FIELD_TYPES.DESCRIPTION,
		custom: false,
		global: false,
		clauseNames: ['description'],
	},
];

export const fetchFieldsWithJql = (jql: string) =>
	getFieldsWithJql(jql).then(({ fields, issueTypes }) => {
		const allFields = [...requiredFakeFields, ...fields];

		const issueTypesPerFieldKeys: Record<FieldKey, IssueTypeId[]> = {};

		Object.entries(issueTypes).forEach(([issueTypeId, fieldKeys]) => {
			fieldKeys.forEach((fieldKey) => {
				const existingIssueTypeIds = issueTypesPerFieldKeys[fieldKey];
				issueTypesPerFieldKeys[fieldKey] = Array.isArray(existingIssueTypeIds)
					? [...existingIssueTypeIds, issueTypeId]
					: [issueTypeId];
			});
		});

		return transformResponse(allFields, issueTypesPerFieldKeys, {
			overrideAllowList: ['project'],
			filterOutAllGlobalSystemFields: !fg('jpd-roadmaps-show-gsf-part1'),
			filterOutAtlasGlobalSystemFields: !fg('jpd-roadmaps-show-gsf-part2'),
		});
	});
