import React, { type PropsWithChildren } from 'react';
import { Flex, Box, xcss } from '@atlaskit/primitives';
import { useThemeObserver } from '@atlaskit/tokens';
import ConnectionsEapLightImg from '../assets/connections-eap-light.png';
import ConnectionsEapDarkImg from '../assets/connections-eap-dark.png';

export const ConnectionsSectionContainer = ({ children }: PropsWithChildren) => {
	const { colorMode } = useThemeObserver();

	return (
		<Box xcss={containerStyles}>
			<Flex
				xcss={[
					childrenContainerStyles,
					colorMode === 'dark' ? darkContainerStyles : lightContainerStyles,
				]}
			>
				{children}
			</Flex>
		</Box>
	);
};

const containerStyles = xcss({
	overflow: 'hidden',
});

const childrenContainerStyles = xcss({
	minWidth: '1040px',
	backgroundColor: 'color.background.accent.purple.subtler',
	backgroundPosition: 'right',
	backgroundRepeat: 'no-repeat',
	backgroundSize: 'contain',
	borderRadius: '13px',
});

const lightContainerStyles = xcss({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
	backgroundImage: `url(${ConnectionsEapLightImg})`,
});

const darkContainerStyles = xcss({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
	backgroundImage: `url(${ConnectionsEapDarkImg})`,
});
