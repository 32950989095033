import React from 'react';
import { Inline } from '@atlaskit/primitives';
import { IssueTypeIcon } from '@atlassian/jira-polaris-component-issue-types/src/ui/issue-type-icon/issue-type-icon.tsx';
import {
	useIssueTypeByNameAndProjectIds,
	useIssueTypeName,
} from '@atlassian/jira-polaris-component-issue-types/src/controllers/index.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { useEnvironmentContainerId } from '@atlassian/jira-polaris-component-environment-container/src/controllers/store/index.tsx';
import { useProjectsIdsForContainer } from '@atlassian/jira-polaris-component-project-metadata/src/controllers/index.tsx';
import type { FieldValueComponentProps, FieldValueConfiguration } from '../types.tsx';

const ComponentOld = ({ groupIdentity }: FieldValueComponentProps) => {
	const issueTypeName = useIssueTypeName({ issueTypeId: groupIdentity });

	return (
		<Inline alignBlock="center" space="space.075">
			<IssueTypeIcon issueTypeId={groupIdentity} />
			{issueTypeName}
		</Inline>
	);
};

const ComponentNew = ({ groupIdentity }: FieldValueComponentProps) => {
	const containerId = useEnvironmentContainerId();
	const projectIds = useProjectsIdsForContainer({ containerId });
	const issueType = useIssueTypeByNameAndProjectIds({ name: groupIdentity, projectIds });

	return (
		<Inline alignBlock="center" space="space.075">
			<IssueTypeIcon issueTypeId={issueType?.id || ''} />
			{groupIdentity}
		</Inline>
	);
};

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const Component = componentWithFG(
	'jpd_view_config_using_issue_type_name',
	ComponentNew,
	ComponentOld,
) as FieldValueConfiguration['Component'];

export const issueTypeFieldValueConfig: FieldValueConfiguration = {
	isHideable: true,
	isDecoratable: false,
	Component,
};
