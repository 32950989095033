import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	dragDisabledPopup: {
		id: 'polaris-lib-timeline.grid.droppable.draggable.drag-disabled-popup',
		defaultMessage:
			"You can't move ideas that are only partially visible in the timeline. Change the dates by opening the idea.",
		description:
			'Popup message that appears when users start dragging ideas that are partly out of the timeline range',
	},
});
