import React, {
	forwardRef,
	memo,
	useCallback,
	useEffect,
	useRef,
	useState,
	type PropsWithChildren,
} from 'react';
import ReactDOM from 'react-dom';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import { draggable } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { setCustomNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { JiraPopup as Popup } from '@atlassian/jira-popup/src/ui/jira-popup.tsx';
import { ITEM_PADDING } from '../../constants.tsx';
import type { ItemCutDirection, DraggableItem } from '../../types/timeline/index.tsx';
import { useTimelineActions } from '../../../controllers/main.tsx';
import { useItem } from '../../../controllers/selectors/items-hooks.tsx';
import {
	useColumnsCount,
	useColumnWidth,
	useGridIntervals,
	useIsReadOnly,
	useIsVerticalCardReorderingEnabled,
	useItemHeight,
} from '../../../controllers/selectors/timeline-hooks.tsx';
import type { DraggableProps, ResizeHandlePosition } from '../../../types.tsx';
import messages from './messages.tsx';
import { preserveOffsetFromPointer } from './utils.tsx';

export const Draggable = memo((props: DraggableProps) => {
	const {
		id,
		type,
		colIndex,
		rowIndex,
		groupId,
		cutDirection,
		startLabel,
		endLabel,
		isStartExternal,
		isEndExternal,
		components,
		isPreview = false,
		canDrop = true,
		onDrop = noop,
	} = props;
	const [primaryGridInterval] = useGridIntervals();
	const size = props.size || primaryGridInterval;
	const item = useItem(id);
	const isVerticalCardReorderingEnabled = useIsVerticalCardReorderingEnabled();
	const isReadOnly = useIsReadOnly();
	const isDragDisabled = isPreview || (isReadOnly && !isVerticalCardReorderingEnabled);

	const {
		ExternalDateDragDisabledHint,
		ExternalDateResizeDisabledHint,
		Item: CardComponent,
		ResizeHandle,
	} = components;
	const containerRef = useRef<HTMLDivElement | null>(null);
	const [dragStatus, setDragStatus] = useState<'idle' | 'preview' | 'dragging'>('idle');
	const [previewContainer, setPreviewContainer] = useState<HTMLElement | null>(null);
	const isCut = !!cutDirection;
	const canDrag = !isCut && !isStartExternal && !isEndExternal;

	useEffect(() => {
		if (!containerRef.current) {
			return undefined;
		}

		const cleanupDragAndDrop = combine(
			draggable({
				element: containerRef.current,
				// we're not using canDrag here because we want to allow dnd
				// when canDrag is false and handle it differently in onGenerateDragPreview
				canDrag: () => !isDragDisabled,
				onGenerateDragPreview: ({ nativeSetDragImage, source, location }) => {
					setDragStatus('preview');
					setCustomNativeDragPreview({
						getOffset: preserveOffsetFromPointer({
							sourceElement: source.element,
							input: location.current.input,
						}),
						render: ({ container }) => {
							setPreviewContainer(container);
							return () => {
								setPreviewContainer(null);
							};
						},
						nativeSetDragImage,
					});
				},
				getInitialData() {
					const data: DraggableItem = {
						type,
						id,
						size,
						colIndex,
						rowIndex,
						oldGroupId: groupId,
						cutDirection,
						isStartExternal,
						isEndExternal,
						canDrop,
					};

					return data;
				},
				onDragStart() {
					setDragStatus('dragging');
				},
				onDrop() {
					setIsCutItemDragged(false);
					// the setTimeout is necessary in order to prevent a flicker on the previous position
					// after a successful drag to another position
					setTimeout(() => setDragStatus('idle'));
					onDrop();
				},
			}),
		);

		return cleanupDragAndDrop;
	}, [
		canDrag,
		type,
		id,
		size,
		colIndex,
		rowIndex,
		groupId,
		cutDirection,
		isStartExternal,
		isEndExternal,
		isCut,
		isDragDisabled,
		canDrop,
		onDrop,
	]);

	const { updateItem, setResizePosition } = useTimelineActions();

	const columnsCount = useColumnsCount();
	const columnWidth = useColumnWidth();
	const gridWidth = columnsCount * columnWidth;
	const left = colIndex * columnWidth;
	const width = size * columnWidth;

	const lastPositionRef = useRef({ left, width });
	const [localLeft, setLocalLeft] = useState(left);
	const [localWidth, setLocalWidth] = useState(width);

	const [isCutItemDragged, setIsCutItemDragged] = useState(false);
	const [resizeDirection, setResizeDirection] = useState<ResizeHandlePosition>();
	const { formatMessage } = useIntl();

	useEffect(() => {
		setLocalLeft(left);
		setLocalWidth(width);
		lastPositionRef.current = { left, width };
	}, [left, width]);

	const handleResize = useCallback(
		(newWidth: number, newLeft: number, direction: ResizeHandlePosition) => {
			if (newWidth < columnWidth || newLeft < 0 || newLeft + newWidth > gridWidth) {
				return;
			}

			lastPositionRef.current.left = newLeft;
			lastPositionRef.current.width = newWidth;
			setLocalLeft(newLeft);
			setLocalWidth(newWidth);

			const resizePosition =
				direction === 'left'
					? Math.round(newLeft / columnWidth) * columnWidth
					: Math.round((newLeft + newWidth) / columnWidth) * columnWidth;

			setResizePosition(resizePosition);
		},
		[columnWidth, gridWidth, setResizePosition],
	);

	const handleResizeLeft = useCallback(
		(delta: number) => {
			setResizeDirection('left');
			handleResize(localWidth - delta, localLeft + delta, 'left');
		},
		[handleResize, localWidth, localLeft],
	);

	const handleResizeRight = useCallback(
		(delta: number) => {
			setResizeDirection('right');
			handleResize(localWidth + delta, localLeft, 'right');
		},
		[handleResize, localWidth, localLeft],
	);

	const handleSnapToGrid = useCallback(
		(resizePosition: ResizeHandlePosition) => {
			setResizeDirection(undefined);
			setResizePosition(undefined);

			const newSize = Math.round(lastPositionRef.current.width / columnWidth);
			const newWidth = newSize * columnWidth;

			const leftLimitedByGridSize = Math.min(
				Math.max(lastPositionRef.current.left, 0),
				gridWidth - newWidth,
			);
			const newColIndex = Math.round(leftLimitedByGridSize / columnWidth);
			const newLeft = newColIndex * columnWidth;

			setLocalLeft(newLeft);
			setLocalWidth(newWidth);

			let colIndexDiff = newColIndex - colIndex;
			let sizeDiff = newSize - size;

			if (
				(cutDirection === 'left' || cutDirection === 'both') &&
				resizePosition === 'left' &&
				item
			) {
				colIndexDiff -= item.start;
				sizeDiff = -colIndexDiff;
			}

			if (resizePosition === 'right' && item) {
				if (cutDirection === 'right') {
					sizeDiff = newSize - item.size;
				}

				if (cutDirection === 'both') {
					sizeDiff = newSize - item.size - item.start;
				}
			}

			updateItem({
				id,
				rowIndex,
				groupId,
				oldGroupId: groupId,
				sizeDiff,
				colIndexDiff,
			});
		},
		[
			gridWidth,
			id,
			updateItem,
			rowIndex,
			groupId,
			size,
			colIndex,
			item,
			cutDirection,
			columnWidth,
			setResizePosition,
		],
	);

	const itemHeight = useItemHeight();

	let disableDragHint = <>{formatMessage(messages.dragDisabledPopup)}</>;
	switch (true) {
		case isStartExternal:
			// eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain, @typescript-eslint/no-non-null-assertion
			disableDragHint = <ExternalDateDragDisabledHint issueId={item?.id!} side="start" />;
			break;
		case isEndExternal:
			// eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain, @typescript-eslint/no-non-null-assertion
			disableDragHint = <ExternalDateDragDisabledHint issueId={item?.id!} side="end" />;
			break;
		default:
	}

	return (
		<>
			<DraggableContainer
				data-testid={`polaris-lib-timeline.ui.grid.droppable.draggable.${id}`}
				leftShift={localLeft - colIndex * columnWidth}
				width={localWidth}
				height={itemHeight}
				isDragging={dragStatus === 'dragging'}
				ref={containerRef}
				isResizing={resizeDirection !== undefined}
			>
				<ResizeHandle
					isDisabled={isStartExternal || false}
					position="left"
					isOneDirection={cutDirection === 'left' || cutDirection === 'both'}
					onResize={handleResizeLeft}
					onResizeStop={handleSnapToGrid}
					label={
						isStartExternal ? (
							<ExternalDateResizeDisabledHint issueId={id} side="start" />
						) : (
							startLabel
						)
					}
				/>
				<Popup
					isOpen={isCutItemDragged}
					onClose={() => setIsCutItemDragged(false)}
					placement="bottom-start"
					messageId="polaris-lib-timeline.common.ui.draggable.popup"
					messageType="transactional"
					content={() => (
						<DisabledDragPopup maxWidth={columnWidth * primaryGridInterval}>
							{disableDragHint}
						</DisabledDragPopup>
					)}
					trigger={({ ref, ...triggerProps }) => (
						<ChildrenContainer
							// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
							ref={ref!}
							{...triggerProps}
							cutDirection={cutDirection}
							isFocused={isCutItemDragged}
							isResizing={resizeDirection !== undefined}
						>
							<CardComponent
								id={id}
								isDraggable={!isDragDisabled}
								isResizing={resizeDirection !== undefined}
								isFillFullWidth={isCut || size === columnsCount}
							/>
						</ChildrenContainer>
					)}
				/>
				<ResizeHandle
					isDisabled={isEndExternal || false}
					position="right"
					isOneDirection={cutDirection === 'right' || cutDirection === 'both'}
					onResize={handleResizeRight}
					onResizeStop={handleSnapToGrid}
					label={
						isEndExternal ? <ExternalDateResizeDisabledHint issueId={id} side="end" /> : endLabel
					}
				/>
			</DraggableContainer>
			{previewContainer
				? ReactDOM.createPortal(<Draggable {...props} isPreview />, previewContainer)
				: null}
		</>
	);
});

// to be removed with cleanup of FG jpd-aurora-roadmap-inline-edit
export const DraggableContainerStyles = forwardRef<
	HTMLDivElement,
	PropsWithChildren<{
		width: number;
		height: number;
		leftShift: number;
		isDragging: boolean;
		isResizing: boolean;
	}>
>((props, ref) => <DraggableContainer {...props} ref={ref} />);

// to be removed with cleanup of FG jpd-aurora-roadmap-inline-edit
export const ChildrenContainerStyles = forwardRef<
	HTMLDivElement,
	PropsWithChildren<{
		cutDirection?: ItemCutDirection;
		isFocused: boolean;
		isResizing: boolean;
	}>
>((props, ref) => <ChildrenContainer {...props} ref={ref} />);

// to be removed with cleanup of FG jpd-aurora-roadmap-inline-edit
export const DisabledDragPopupStyles = forwardRef<
	HTMLDivElement,
	PropsWithChildren<{ maxWidth: number }>
>((props, ref) => <DisabledDragPopup {...props} ref={ref} />);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DraggableContainer = styled.div<{
	width: number;
	height: number;
	leftShift: number;
	isDragging: boolean;
	isResizing: boolean;
}>({
	position: 'relative',
	cursor: 'pointer',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	padding: `0 ${ITEM_PADDING}px`,
	boxSizing: 'border-box',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	opacity: ({ isDragging }) => isDragging && 0.3,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: ({ width }) => `${width}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: ({ height }) => `${height}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	transform: ({ leftShift }) => `translateX(${leftShift}px)`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	zIndex: ({ isResizing }) => (isResizing ? 4 : 1),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:has(div[id="emoji-picker"], div[data-component-selector="all-emojis-dialog"])': {
		zIndex: 6, // Group header has zIndex 5, so this should be higher to prevent overlap
	},
	'&:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		...({ isResizing }) => (isResizing ? '' : 'z-index: 2'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > *': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		filter: ({ isDragging }) => isDragging && 'saturate(0)',
	},
	// necessary in order to drag a card that is wider than 1 cell on itself
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	'pointer-events': ({ isDragging }) => isDragging && 'none',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ChildrenContainer = styled.div<{
	cutDirection?: ItemCutDirection;
	isFocused: boolean;
	isResizing: boolean;
}>({
	position: 'relative',
	zIndex: 2,
	width: '100%',
	height: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&& > *': {
		height: '100%',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		borderTopLeftRadius: ({ isResizing, cutDirection }) =>
			!isResizing && (cutDirection === 'left' || cutDirection === 'both') ? 0 : '4px',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		borderBottomLeftRadius: ({ isResizing, cutDirection }) =>
			!isResizing && (cutDirection === 'left' || cutDirection === 'both') ? 0 : '4px',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		borderTopRightRadius: ({ isResizing, cutDirection }) =>
			!isResizing && (cutDirection === 'right' || cutDirection === 'both') ? 0 : '4px',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		borderBottomRightRadius: ({ isResizing, cutDirection }) =>
			!isResizing && (cutDirection === 'right' || cutDirection === 'both') ? 0 : '4px',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DisabledDragPopup = styled.div<{ maxWidth: number }>({
	paddingTop: token('space.150'),
	paddingRight: token('space.150'),
	paddingBottom: token('space.150'),
	paddingLeft: token('space.150'),
	boxSizing: 'border-box',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	maxWidth: ({ maxWidth }) => `${maxWidth}px`,
});
