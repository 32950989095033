import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { createPolarisStore } from '@atlassian/jira-polaris-lib-react-sweet-state-utils/src/utils/store/index.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createHook, type StoreActionApi } from '@atlassian/react-sweet-state';
import type { State } from './types.tsx';

const actions = {
	setFilteredLocalIssueId:
		(filteredLocalIssueId?: LocalIssueId) =>
		({ setState }: StoreActionApi<State>) => {
			setState({
				filteredLocalIssueId,
			});
		},
} as const;

type Actions = typeof actions;

export const CreateWithFiltersStore = createPolarisStore<State, Actions>({
	initialState: {
		filteredLocalIssueId: undefined,
	},
	actions,
	name: 'PolarisCreateWithFiltersStore',
});

export const useCreateWithFilters = createHook(CreateWithFiltersStore);

export const useFilteredLocalIssueId = (): LocalIssueId | undefined => {
	const [state] = useCreateWithFilters();
	return state.filteredLocalIssueId;
};

export const useSetFilteredLocalIssueId = () => {
	const [, { setFilteredLocalIssueId }] = useCreateWithFilters();
	return setFilteredLocalIssueId;
};
