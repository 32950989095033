// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import { logSafeErrorWithoutCustomerDataWrapper } from '@atlassian/jira-polaris-lib-errors/src/common/utils/index.tsx';
import { type Props, type State, DataPointsMergeOption } from '../../types.tsx';

export const copyInsights =
	(targetProjectId: string): Action<State, Props> =>
	async ({ getState }, { projectId, insightsRemote }) => {
		const { targetIssue, selectedIssues, mergeOptions } = getState();
		if (projectId === null || targetIssue === null || !insightsRemote.copyInsights) {
			return;
		}
		if (!mergeOptions[DataPointsMergeOption]) {
			return;
		}

		try {
			await Promise.all(
				selectedIssues.map((selectedIssue) =>
					insightsRemote.copyInsights?.({
						source: {
							projectId,
							issueId: selectedIssue.id ? String(selectedIssue.id) : undefined,
						},
						destination: {
							projectId: targetProjectId,
							issueId: targetIssue.id ? String(targetIssue.id) : undefined,
						},
						insights: [],
					}),
				),
			);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (err: any) {
			logSafeErrorWithoutCustomerDataWrapper(
				'polaris.merge-ideas-error',
				'Failed to copy insights to target idea',
				err,
			);
			throw err;
		}
	};
