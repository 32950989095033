// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	createActionsHook,
	createStateHook,
	createStore,
	createContainer,
} from '@atlassian/react-sweet-state';
import * as actions from './actions.tsx';
import type { State, Props } from './types.tsx';

const initialState: State = {};

export const Store = createStore({
	initialState,
	actions,
	name: 'PolarisNavigationMetadataStore',
});

export const useActions = createActionsHook(Store);

export const useIsProjectOnboarded = createStateHook(Store, {
	selector: (state, { projectId }: { projectId: string }): boolean => {
		if (projectId === undefined) {
			return false;
		}
		return state[projectId]?.metadata?.isProjectOnboarded || false;
	},
});

export const useIsProjectMetadataLoading = createStateHook(Store, {
	selector: (state, { projectId }: { projectId: string }): boolean => {
		if (projectId === undefined) {
			return false;
		}
		return state[projectId]?.isLoading || false;
	},
});

export const NavigationMetadataContainer = createContainer<State, typeof actions, Props>(Store, {
	onInit:
		() =>
		({ dispatch }) => {
			dispatch(actions.loadProjectMetadata());
		},
});
