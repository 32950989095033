import { NOT_FOUND } from '@atlassian/jira-common-constants/src/http-status-codes.tsx';
import { FetchError } from '@atlassian/jira-fetch/src/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { IssueTypeId } from '@atlassian/jira-shared-types/src/general.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import { logSafeErrorWithoutCustomerDataWrapper } from '@atlassian/jira-polaris-lib-errors/src/common/utils/index.tsx';
import { getProjectProperty, setProjectProperty } from '../../services/project-property.tsx';
import type { Configurations, State } from '../types.tsx';
import { ISSUE_TYPE_CONNECTIONS_CONFIGURATION_PROPERTY_KEY } from './constants.tsx';

export const setIssueTypeConfiguration =
	(issueTypeId: IssueTypeId, configuration: FieldKey[]): Action<State, void, Promise<void>> =>
	async ({ setState, getState }) => {
		const state = getState();

		setState({
			configurations: {
				...state.configurations,
				[issueTypeId]: configuration,
			},
		});
	};

export const loadConfiguration =
	({
		projectId,
		issueTypeId,
		defaultConfiguration,
	}: {
		projectId: string;
		issueTypeId: IssueTypeId;
		defaultConfiguration: FieldKey[];
	}): Action<State, void, Promise<void>> =>
	async ({ setState, dispatch }) => {
		try {
			setState({
				isLoading: true,
				error: undefined,
			});

			const configurations = await getProjectProperty<Configurations>({
				projectIdOrKey: projectId,
				propertyKey: ISSUE_TYPE_CONNECTIONS_CONFIGURATION_PROPERTY_KEY,
			});

			setState({
				configurations: {
					...configurations,
					[issueTypeId]: configurations[issueTypeId] ?? defaultConfiguration,
				},
			});
		} catch (error) {
			if (error instanceof FetchError && error.statusCode === NOT_FOUND) {
				dispatch(setIssueTypeConfiguration(issueTypeId, defaultConfiguration));
				return;
			}

			if (error instanceof Error) {
				logSafeErrorWithoutCustomerDataWrapper(
					'polaris.error.loadingIssueTypesConnectionsConfigurationFailed',
					'Failed to load connection tab configuration',
					error,
				);

				setState({
					error,
				});
			}
		} finally {
			setState({
				isLoading: false,
			});
		}
	};

export const saveConfiguration =
	({
		projectId,
		issueTypeId,
		configuration,
	}: {
		projectId: string;
		issueTypeId: string;
		configuration: FieldKey[];
	}): Action<State, void, Promise<void>> =>
	async ({ setState, getState, dispatch }) => {
		const { configurations } = getState();

		dispatch(setIssueTypeConfiguration(issueTypeId, configuration));

		try {
			await setProjectProperty({
				projectIdOrKey: projectId,
				propertyKey: ISSUE_TYPE_CONNECTIONS_CONFIGURATION_PROPERTY_KEY,
				data: getState().configurations,
			});
		} catch (error) {
			setState({
				configurations,
			});

			if (error instanceof Error) {
				logSafeErrorWithoutCustomerDataWrapper(
					'polaris.error.savingIssueTypesConnectionsConfigurationFailed',
					'Failed to save connection tab configuration',
					error,
				);
			}
			throw error;
		}
	};

export const hideConnection =
	({
		fieldKey,
		issueTypeId,
		projectId,
	}: {
		projectId: string;
		issueTypeId: IssueTypeId;
		fieldKey: FieldKey;
	}): Action<State, void, Promise<void>> =>
	async ({ getState, dispatch }) => {
		const { configurations } = getState();
		const existingConfiguration = configurations[issueTypeId];

		if (!existingConfiguration) {
			return;
		}

		const configuration = existingConfiguration.filter((key) => key !== fieldKey);

		await dispatch(saveConfiguration({ projectId, issueTypeId, configuration }));
	};
