import React from 'react';
import { styled } from '@compiled/react';
import Spinner from '@atlaskit/spinner';
import { token } from '@atlaskit/tokens';

export const SavingSpinner = ({ testId }: { testId?: string }) => (
	<SavingSpinnerContainer>
		<Spinner size="medium" testId={testId} />
	</SavingSpinnerContainer>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SavingSpinnerContainer = styled.div({
	paddingTop: token('space.100'),
	paddingRight: 0,
	paddingBottom: token('space.100'),
	paddingLeft: 0,
	display: 'flex',
	justifyContent: 'center',
});
