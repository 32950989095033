/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import { xcss, Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { useColumn } from '@atlassian/jira-polaris-lib-data-arrangement/src/ui/column-context.tsx';
import { useGroup } from '@atlassian/jira-polaris-lib-data-arrangement/src/ui/group-context.tsx';
import {
	useFieldTypeIcon,
	useFieldLabel,
} from '../../../../../controllers/field/selectors/field-hooks.tsx';
import { useColumnAndGroupAwareConnectionFieldIssueIdsSorted } from '../../../../../controllers/issue/selectors/connection-hooks.tsx';

type SummaryConnectionField = {
	issueId: string;
	fieldKey: FieldKey;
	showFieldLabel?: boolean;
};

export const SummaryConnectionField = ({
	issueId,
	fieldKey,
	showFieldLabel,
}: SummaryConnectionField) => {
	const column = useColumn() ?? undefined;
	const group = useGroup() ?? undefined;
	const connectedIssueIds = useColumnAndGroupAwareConnectionFieldIssueIdsSorted(fieldKey, issueId, {
		column,
		group,
	});
	const fieldLabel = useFieldLabel(fieldKey);
	const icon = useFieldTypeIcon(fieldKey, undefined);

	if (!connectedIssueIds.length) {
		return null;
	}

	return (
		<div data-component-selector="content-wrapper-aw1" css={contentWrapperStyles}>
			<Box xcss={iconContainerStyles}>{icon}</Box>
			{showFieldLabel && fieldLabel ? (
				<>
					<Box xcss={labelWrapperStyles}>{fieldLabel}</Box>
					<Box data-component-selector="amount-wrapper-aw1" xcss={amountWrapperStyles}>
						{connectedIssueIds.length}
					</Box>
				</>
			) : (
				<Box paddingInlineEnd="space.025">{connectedIssueIds.length}</Box>
			)}
		</div>
	);
};

const iconContainerStyles = xcss({
	marginRight: 'space.050',
	display: 'flex',
	alignItems: 'center',
});

const labelWrapperStyles = xcss({
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	font: 'font.body.UNSAFE_small',
	color: 'color.text',
	overflow: 'hidden',
	marginRight: 'space.050',
});

// Hover state styles for this component are created in: src/packages/polaris/apps/common/src/ui/idea-card-v2/main.tsx

const amountWrapperStyles = xcss({
	backgroundColor: 'color.background.accent.gray.subtlest.hovered',
	paddingInline: 'space.050',
	borderRadius: 'border.radius.050',
	whiteSpace: 'nowrap',
	font: 'font.body.UNSAFE_small',
	color: 'color.text',
});

const contentWrapperStyles = css({
	display: 'flex',
	alignItems: 'center',
	backgroundColor: token('color.background.accent.gray.subtlest'),
	paddingInline: token('space.050'),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	paddingBlock: '3px',
	borderRadius: token('border.radius.100'),
	justifySelf: 'flex-start',
	gridColumn: '-1/1',
});
