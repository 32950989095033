import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import type { ProjectId, CloudId } from '@atlassian/jira-shared-types/src/general.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { notImplementedError } from '../../common/utils.tsx';
import { postCommentToJira } from '../../services/jira/comments/add-comment/index.tsx';
import { deleteCommentFromJira } from '../../services/jira/comments/delete-comment/index.tsx';
import { updateCommentInJira } from '../../services/jira/comments/update-comment/index.tsx';
import {
	updateIssueCommentsLastViewed,
	updateSharingIssueCommentsLastViewed,
} from '../../services/polaris-api/update-issue-comments-last-viewed/index.tsx';
import type { IssueCommentRemote, SharingIssueCommentRemote } from './types.tsx';

export const createNotImplementedIssueCommentRemote = (): IssueCommentRemote => ({
	createIssueComment: notImplementedError('createIssueComment'),
	deleteIssueComment: notImplementedError('deleteIssueComment'),
	updateIssueComment: notImplementedError('updateIssueComment'),
	updateLastCommentsViewedTimestamp: notImplementedError('updateLastCommentsViewedTimestamp'),
});

export const createSharingIssueCommentRemote = (cloudId: CloudId): SharingIssueCommentRemote => ({
	createIssueComment: ({ issueKey, comment }) => postCommentToJira(issueKey, comment),
	deleteIssueComment: ({ issueKey, commentId }) => deleteCommentFromJira(issueKey, commentId),
	updateIssueComment: ({ issueKey, commentId, comment }) =>
		updateCommentInJira(issueKey, commentId, comment),
	updateLastCommentsViewedTimestamp: ({ issueId, timestamp, projectId }) =>
		updateSharingIssueCommentsLastViewed({
			projectId,
			cloudId,
			ideaId: issueId,
			lastCommentsViewedTimestamp: timestamp,
		}).then(() => undefined),
});

export const createIssueCommentRemote = (
	apolloClient: PolarisApolloClient,
	cloudId: CloudId,
	projectId: ProjectId,
): IssueCommentRemote => ({
	createIssueComment: ({ issueKey, comment }) => postCommentToJira(issueKey, comment),
	deleteIssueComment: ({ issueKey, commentId }) => deleteCommentFromJira(issueKey, commentId),
	updateIssueComment: ({ issueKey, commentId, comment }) =>
		updateCommentInJira(issueKey, commentId, comment),
	updateLastCommentsViewedTimestamp: ({ issueId, timestamp }) =>
		updateIssueCommentsLastViewed(apolloClient, {
			projectId,
			cloudId,
			ideaId: issueId,
			lastCommentsViewedTimestamp: timestamp,
		}).then(() => undefined),
});

export const createCollectionIssueCommentRemote = (
	cloudId: CloudId,
): SharingIssueCommentRemote => ({
	createIssueComment: ({ issueKey, comment }) => postCommentToJira(issueKey, comment),
	deleteIssueComment: ({ issueKey, commentId }) => deleteCommentFromJira(issueKey, commentId),
	updateIssueComment: ({ issueKey, commentId, comment }) =>
		updateCommentInJira(issueKey, commentId, comment),
	updateLastCommentsViewedTimestamp: fg('jpd-aurora-roadmap-insights-field')
		? ({ issueId, timestamp, projectId }) =>
				updateSharingIssueCommentsLastViewed({
					projectId,
					cloudId,
					ideaId: issueId,
					lastCommentsViewedTimestamp: timestamp,
				}).then(() => undefined)
		: async () => {
				await Promise.resolve();
			},
});
