import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';

export const DraggableHandle = () => <Handle />;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Handle = styled.div({
	'&::before': {
		content: '⠿',
		cursor: 'grab',
		color: token('color.text'),
		font: token('font.body.large'),
	},
});
