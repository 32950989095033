import React, { useCallback } from 'react';
import { IconButton } from '@atlaskit/button/new';
import Heading from '@atlaskit/heading';
import ArrowLeftIcon from '@atlaskit/icon/core/migration/arrow-left';
import CrossIcon from '@atlaskit/icon/core/migration/close--cross';
import { Box, Inline, Stack, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { useRightSidebar } from '../../controllers/index.tsx';
import messages from './messages.tsx';
import type { RightSidebarHeaderProps } from './types.tsx';

export type ClickEvent = React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>;

export const RightSidebarHeader = ({
	title,
	upperTitle,
	onClose,
	onBack,
	backLabel,
	banner,
	children,
}: RightSidebarHeaderProps) => {
	const { formatMessage } = useIntl();
	const [{ onClose: closeSidebar }] = useRightSidebar();

	const handleClose = useCallback(
		(e: ClickEvent) => {
			closeSidebar(e.nativeEvent);

			onClose?.();
		},
		[onClose, closeSidebar],
	);

	return (
		<Stack space="space.100" xcss={rightSidebarHeaderStyles}>
			<Box paddingInlineStart="space.300" paddingInlineEnd="space.200">
				<Inline alignBlock="center" space="space.050">
					<Stack space="space.050" xcss={headingStyles}>
						<Inline alignBlock="center" space="space.050">
							{onBack && (
								<IconButton
									icon={ArrowLeftIcon}
									label={backLabel}
									onClick={onBack}
									appearance="subtle"
								/>
							)}
							<Stack>
								{upperTitle ? (
									<Heading size="xxsmall" as="div">
										{upperTitle}
									</Heading>
								) : null}
								<Heading size="medium">{title}</Heading>
							</Stack>
						</Inline>
					</Stack>
					{children}
					<IconButton
						testId="polaris-lib-right-sidebar.ui.header.close"
						icon={CrossIcon}
						onClick={handleClose}
						label={formatMessage(messages.close)}
						appearance="subtle"
					/>
				</Inline>
			</Box>
			{banner}
		</Stack>
	);
};

const rightSidebarHeaderStyles = xcss({
	marginBottom: 'space.300',
});

const headingStyles = xcss({ marginRight: 'auto' });
