// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import { logSafeErrorWithoutCustomerDataWrapper } from '@atlassian/jira-polaris-lib-errors/src/common/utils/index.tsx';
import { getPropertyValue } from '../../../../services/jira/project/properties/index.tsx';
import type { ProjectProperties, Props, State } from '../../types.tsx';

export const loadProperty =
	(
		propertyKey: ProjectProperties,
		loadedCallback?: (value: any) => void, // eslint-disable-line @typescript-eslint/no-explicit-any
	): Action<State, Props> =>
	({ setState, getState }, { projectIdOrKey }) => {
		// skip, if already loaded or loading
		if (
			(getState().properties[propertyKey]?.isLoading === false &&
				getState().properties[propertyKey]?.error === null) ||
			getState().properties[propertyKey]?.isLoading === true
		) {
			return;
		}

		setState({
			properties: {
				...getState().properties,
				[propertyKey]: {
					isLoading: true,
					error: null,
					value: null,
				},
			},
		});

		getPropertyValue(projectIdOrKey, propertyKey)
			.then((value) => {
				setState({
					properties: {
						...getState().properties,
						[propertyKey]: {
							isLoading: false,
							error: null,
							value,
						},
					},
				});
				loadedCallback && loadedCallback(value);
			})
			.catch((error) => {
				if (error.statusCode === 404) {
					setState({
						properties: {
							...getState().properties,
							[propertyKey]: {
								isLoading: false,
								error: null,
								value: null,
							},
						},
					});
					return;
				}
				logSafeErrorWithoutCustomerDataWrapper(
					'polaris.project-properties-error',
					`Failed to load project property with key: ${propertyKey}`,
					error,
				);
				setState({
					properties: {
						...getState().properties,
						[propertyKey]: {
							isLoading: false,
							error,
							value: null,
						},
					},
				});
			});
	};
