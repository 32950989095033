import { useState, useEffect, useRef } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import {
	useIssueTypesActions,
	useIssueTypesForProject,
} from '@atlassian/jira-polaris-component-issue-types/src/controllers/index.tsx';
import type { IssueTypeId } from '@atlassian/jira-shared-types/src/general.tsx';
import { useNotifications } from '@atlassian/jira-polaris-lib-notifications/src/controllers/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { messages } from '../messages.tsx';
import { useFirstAvailableAvatar } from '../avatars-provider.tsx';

type Props = {
	defaultAvatarId?: string;
	defaultName?: string;
	issueTypeId?: IssueTypeId; // issueTypeId is not existing for the issueType that is being created
	projectId: string;
};

export type OnUpdateIssueType = {
	newName?: string;
	newAvatarId?: string;
};

type OnUpdate = {
	newName: string;
	newAvatarId: string;
};

export const useFormState = ({
	defaultAvatarId = '',
	defaultName = '',
	issueTypeId,
	projectId,
}: Props) => {
	const issueTypes = useIssueTypesForProject({ projectId });
	const [avatarId, setAvatarId] = useState(defaultAvatarId);
	const [name, setName] = useState(defaultName);
	const { updateIssueType } = useIssueTypesActions();
	const { avatarId: firstAvailableAvatar } = useFirstAvailableAvatar();
	const { formatMessage } = useIntl();
	const { error } = useNotifications();
	// TODO: remove after jpd_validate_type_name FG cleanup https://pi-dev-sandbox.atlassian.net/browse/POL-14748
	const [isNameInvalid, setIsNameInvalid] = useState(false);
	const stateSubmitted = useRef({ name, avatarId });

	const nameError = (() => {
		if (fg('jpd_validate_type_name')) {
			if (name.trim() === '') {
				return formatMessage(messages.typeNameRequiredError);
			}

			const nameExists = issueTypes.some(
				(issueType) =>
					issueType.id !== issueTypeId &&
					issueType.name.toLowerCase() === name.trim().toLowerCase(),
			);

			if (nameExists) {
				return formatMessage(messages.typeNameUniqueError);
			}

			return null;
		}
	})();

	const onUpdateIssueType = async ({ newAvatarId, newName }: OnUpdate) => {
		if (fg('jpd_validate_type_name')) {
			if (nameError) {
				setName(defaultName);
				return;
			}
		} else if (isNameInvalid) {
			return;
		}

		// Don't perform an update if the name or avatarId of the issue type is the same as previously submitted
		if (
			newName.trim() === stateSubmitted.current.name &&
			newAvatarId === stateSubmitted.current.avatarId
		) {
			return;
		}

		const trimmedName = newName.trim();
		setName(trimmedName);

		if (issueTypeId) {
			try {
				await updateIssueType(issueTypeId, projectId, {
					avatarId: newAvatarId,
					name: trimmedName,
					description: '',
				});
				stateSubmitted.current = {
					name: trimmedName,
					avatarId: newAvatarId,
				};
			} catch (err) {
				error({
					title: formatMessage(messages.errorTitle),
					description: formatMessage(messages.errorDescription),
				});

				// revert UI state in case the update didn't succeed
				setName(stateSubmitted.current.name);
				setAvatarId(stateSubmitted.current.avatarId);
			}
		}
	};

	const onChangeName = (value: string) => {
		if (value === '') {
			setIsNameInvalid(true);
		} else {
			setIsNameInvalid(false);
		}
		setName(value);
	};

	const onChangeAvatarId = (value: string) => {
		setAvatarId(value);
	};

	const onChangeAndSubmitAvatarId = async (value: string) => {
		onChangeAvatarId(value);
		onUpdateIssueType({ newAvatarId: value, newName: name });
	};

	const onSubmitAvatarIdAndName = async () => {
		return onUpdateIssueType({ newAvatarId: avatarId, newName: name });
	};

	// If no avatar was provided, use default one from the system.
	useEffect(() => {
		if (!avatarId) {
			onChangeAvatarId(firstAvailableAvatar);
		}
	}, [avatarId, firstAvailableAvatar]);

	return {
		avatarId,
		name,
		onChangeName: fg('jpd_validate_type_name') ? setName : onChangeName,
		onChangeAndSubmitAvatarId,
		onSubmitAvatarIdAndName,
		isNameInvalid,
		nameError,
	};
};
