import type { RowGroupId, RowId, RowType } from '../../../common/types/index.tsx';
import type { RowData } from '../../../common/types/react-base-table/index.tsx';
import { NO_VALUE_GROUP_ID } from '../../../constants.tsx';
import type { RowGrouping, UpdatedRowProps } from '../../types.tsx';

export const emptyRow: RowData = {
	key: 'empty-row',
	index: -1,
	id: 'empty-row',
	isExpanded: false,
	type: 'EMPTY',
	updated: false,
};

export const createRowPinnedBottom = ({
	index,
	groupId,
}: {
	index: number;
	groupId: string;
}): RowData => ({
	id: `${groupId}/item-pinned-bottom`,
	index,
	key: `${groupId}/item-pinned-bottom`,
	isExpanded: false,
	groupId,
	type: 'ITEM_PINNED_BOTTOM',
	updated: false,
});

export const createBaseTableRows = (
	rowIds: RowId[],
	rowGroups?: RowGroupId[],
	groupsToItems?: RowGrouping,
	// @ts-expect-error - TS1016 - A required parameter cannot follow an optional parameter.
	collapsedGroupIds: RowGroupId[],
	updatedRowsProps: UpdatedRowProps[],
	hideEmptyGroups: boolean,
	shouldRenderRowPinnedBottomForGroups: boolean,
	shouldRenderRowPinnedBottomForNoGroups: boolean,
): RowData[] => {
	const rows: Array<RowData> = [];
	// @TODO: make multi idea update indication possible
	const { isMoved, id: movedRowId } = updatedRowsProps[0] ?? {};
	let findFirstItemInList = false;

	if (rowGroups !== undefined && groupsToItems !== undefined) {
		let index = 0;
		groupsToItems.noGroup?.forEach((rowId: RowId) => {
			rows.push({
				index,
				id: rowId,
				key: `${NO_VALUE_GROUP_ID}/${rowId}`,
				groupId: NO_VALUE_GROUP_ID,
				type: 'ITEM',
				isFirstItemInList: !findFirstItemInList,
			});
			findFirstItemInList = true;
			index += 1;
		});

		rowGroups.forEach((groupId) => {
			const noValueGroupLength = groupsToItems?.empty?.length;
			const groupLength = groupsToItems?.groups[groupId]?.length;
			const isEmpty = shouldRenderRowPinnedBottomForGroups
				? false
				: !(groupId === NO_VALUE_GROUP_ID ? noValueGroupLength : groupLength);

			rows.push({
				index,
				id: groupId,
				key: groupId,
				isExpanded: !collapsedGroupIds.includes(groupId),
				isEmpty,
				groupId,
				type: 'GROUP',
			});
			index += 1;

			if (!collapsedGroupIds.includes(groupId)) {
				const container =
					groupId === NO_VALUE_GROUP_ID ? groupsToItems?.empty : groupsToItems?.groups[groupId];

				container?.forEach((rowId: RowId) => {
					rows.push({
						id: rowId,
						index,
						key: `${groupId}/${rowId}`,
						groupId,
						type: 'ITEM',
						updated: isMoved && rowId === movedRowId,
						isFirstItemInList: !findFirstItemInList,
					});
					findFirstItemInList = true;
					index += 1;
				});

				if (shouldRenderRowPinnedBottomForGroups) {
					rows.push(
						createRowPinnedBottom({
							index,
							groupId,
						}),
					);
					index += 1;
				}
			}
		});

		// add special empty row to the beginning of the list only if there are rows available
		if (rows.length > 0) {
			rows.unshift(emptyRow);
		}

		return rows;
	}

	const result = [
		...(rowIds.length > 0 ? [emptyRow] : []),
		...rowIds.map((id, index) => {
			const data = {
				id,
				index,
				key: `${NO_VALUE_GROUP_ID}/${id}`,
				groupId: NO_VALUE_GROUP_ID,
				isExpanded: !collapsedGroupIds.includes(NO_VALUE_GROUP_ID),
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				type: 'ITEM' as RowType,
				isFirstItemInList: !findFirstItemInList,
				updated: isMoved && id === movedRowId,
			};
			findFirstItemInList = true;
			return data;
		}),
	];

	// We have to check for `result.length > 0` otherwise we won't display
	// "No results" state
	if (shouldRenderRowPinnedBottomForNoGroups && result.length > 0) {
		result.push(
			createRowPinnedBottom({
				groupId: NO_VALUE_GROUP_ID,
				index: result.length - 1,
			}),
		);
	}

	return result;
};
