// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import type { State } from '../../types.tsx';

type SetMetaPropertiesRequest = {
	projectId?: string;
};

export const setMetaProperties =
	({ projectId }: SetMetaPropertiesRequest): Action<State> =>
	({ getState, setState }) => {
		const currentProjectId = getState().meta.projectId;

		if (currentProjectId !== projectId) {
			setState({
				meta: {
					...getState().meta,
					...(currentProjectId !== projectId ? { projectId } : {}),
				},
			});
		}
	};
