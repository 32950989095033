import React, { type SyntheticEvent } from 'react';
import isEmpty from 'lodash/isEmpty';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import RetryIcon from '@atlaskit/icon/core/migration/retry';
import { useIntl } from '@atlassian/jira-intl';
import { useIsArchivingEnabled } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks.tsx';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { fg } from '@atlassian/jira-feature-gating';
import { useVisibleSelectedIssueIds } from '../../../utils.tsx';
import { BarActionButton } from '../bar-action-button/index.tsx';
import { messages } from './messages.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => {
	const { formatMessage } = useIntl();
	const visibleSelectedIssueIds = useVisibleSelectedIssueIds();
	const { unarchiveIssues } = useIssueActions();
	const { clearSelection } = useViewActions();
	const [isArchivingEnabled] = useIsArchivingEnabled();

	if (isEmpty(visibleSelectedIssueIds) || !isArchivingEnabled) {
		return null;
	}

	if (fg('jpd-aurora-roadmap-inline-edit')) {
		return (
			<BarActionButton
				interactionName="jpd.view-controls.unarchive-ideas"
				id="polaris-ideas.ui.view-controls.unarchive-ideas.button"
				onClick={(event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
					fireUIAnalytics(analyticsEvent, 'unarchiveIdeas');
					unarchiveIssues(visibleSelectedIssueIds);
					clearSelection(visibleSelectedIssueIds);
				}}
				iconBefore={<RetryIcon label={formatMessage(messages.buttonLabel)} />}
			>
				{formatMessage(messages.buttonLabel)}
			</BarActionButton>
		);
	}

	return (
		<Button
			interactionName="jpd.view-controls.unarchive-ideas"
			id="polaris-ideas.ui.view-controls.unarchive-ideas.button"
			onClick={(event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
				fireUIAnalytics(analyticsEvent, 'unarchiveIdeas');
				unarchiveIssues(visibleSelectedIssueIds);
				clearSelection(visibleSelectedIssueIds);
			}}
			appearance="subtle"
			iconBefore={<RetryIcon label={formatMessage(messages.buttonLabel)} />}
		>
			{formatMessage(messages.buttonLabel)}
		</Button>
	);
};
