// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import { logSafeErrorWithoutCustomerDataWrapper } from '@atlassian/jira-polaris-lib-errors/src/common/utils/index.tsx';
import { setPropertyValue } from '../../../../services/jira/project/properties/index.tsx';
import type { ProjectProperties, Props, State } from '../../types.tsx';

export const saveProperty =
	(propertyKey: ProjectProperties, value: unknown): Action<State, Props> =>
	({ setState, getState }, { projectIdOrKey }) => {
		if (!projectIdOrKey) {
			return;
		}

		const oldValue = getState().properties[propertyKey]?.value;

		setState({
			properties: {
				...getState().properties,
				[propertyKey]: {
					value,
					isLoading: false,
					error: null,
				},
			},
		});

		setPropertyValue(projectIdOrKey, propertyKey, value).catch((error) => {
			logSafeErrorWithoutCustomerDataWrapper(
				'polaris.project-properties-error',
				`Failed to save project property with key: ${propertyKey}`,
				error,
			);
			setState({
				properties: {
					...getState().properties,
					[propertyKey]: {
						value: oldValue,
					},
				},
			});
		});
	};
