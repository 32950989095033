import { token } from '@atlaskit/tokens';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getControlBackgroundStyles = (state: any) => {
	if (state.selectProps.menuIsOpen) {
		return token('color.background.selected');
	}

	return state.isFocused ? token('color.background.neutral') : token('color.background.input');
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getControlBorderColorStyles = (state: any) => {
	if (!state.isFocused) {
		return token('color.border');
	}

	return state.selectProps.menuIsOpen ? 'transparent' : token('color.border.focused');
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getControlHoverStyles = (state: any) => {
	let borderColorStyle = 'transparent';

	if (state.isFocused && !state.selectProps.menuIsOpen) {
		borderColorStyle = token('color.border.focused');
	}

	return {
		background: state.selectProps.menuIsOpen
			? token('color.background.selected')
			: token('color.background.neutral.hovered'),
		borderColor: borderColorStyle,
	};
};

export const getSelectStyles = () => ({
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	control: (provided: any, state: any) => ({
		...provided,
		cursor: 'pointer',
		background: getControlBackgroundStyles(state),
		height: '48px',
		paddingLeft: token('space.150'),
		borderRadius: '3px',
		borderColor: getControlBorderColorStyles(state),
		':hover': getControlHoverStyles(state),
	}),
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	valueContainer: (provided: any, state: any) => ({
		...provided,
		color: state.selectProps.menuIsOpen ? token('color.text.brand') : token('color.text'),
		display: 'flex',
		flexWrap: 'nowrap',
		minWidth: 0,
	}),
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	placeholder: (provided: any, state: any) => ({
		...provided,
		color: state.selectProps.menuIsOpen ? token('color.text.brand') : token('color.text'),
	}),
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	dropdownIndicator: (provided: any, state: any) => ({
		...provided,
		color: state.selectProps.menuIsOpen ? token('color.text.brand') : token('color.text'),
		':hover': 'none',
	}),
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	menuPortal: (provided: any) => ({ ...provided, zIndex: layers.modal }),
});
