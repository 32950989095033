import { fg } from '@atlassian/jira-feature-gating';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { getFeatureFlagValue } from '@atlassian/jira-feature-flagging';
import { sendPendoTrackEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/pendo/index.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { fetchIssueIssueBatches } from '../get-issues-rest-bulk/main.tsx';
import { getIssuesByJql } from '../get-issues-by-jql/index.tsx';
import type { JiraSearchApiResponse } from '../get-issues-rest-bulk/types.tsx';
import type { JiraSearchApiIssue } from '../types.tsx';
import type { Response, ChildIssueStub } from './types.tsx';
import { getFields } from './utils.tsx';

const transformIssues = (
	issues: JiraSearchApiIssue[],
	parentKey: IssueKey,
	storyPointsCustomFieldId?: string,
): ChildIssueStub[] =>
	issues.map((issue) => ({
		issueId: issue.id,
		issueKey: issue.key,
		summary: issue.fields.summary,
		status: issue.fields.status,
		issueType: issue.fields.issuetype,
		estimation: storyPointsCustomFieldId ? issue.fields[storyPointsCustomFieldId] : undefined,
		parentKey,
	}));

export const createResponse = (
	result: JiraSearchApiResponse,
	issueKey: IssueKey,
	storyPointsCustomFieldId?: string,
) => ({
	issues: transformIssues(result.issues, issueKey, storyPointsCustomFieldId),
	hitLimit: result.issues.length < result.total,
});

export const EMPTY: Response = {
	issues: [],
	hitLimit: false,
};

const getIssues = ({
	jql,
	fields,
	limit,
}: {
	jql: string;
	fields: string[];
	limit: number;
}): Promise<JiraSearchApiResponse> => {
	if (fg('polaris-issue-child-search-migration')) {
		return getIssuesByJql({
			jql,
			fields,
			limit,
		});
	}

	return fetchIssueIssueBatches({ jql }, fields, [], undefined, limit);
};

export const getChildIssues = async (
	issueKey: IssueKey,
	storyPointsCustomFieldId?: string,
): Promise<Response> => {
	const jql = `parent IN (${issueKey})`;

	try {
		const response = await getIssues({
			jql,
			fields: getFields(storyPointsCustomFieldId),
			limit: getFeatureFlagValue<number>('polaris.delivery-child-issues-loading-limit', 500),
		});

		if (response.issues.length < response.total) {
			sendPendoTrackEvent({
				actionSubjectAndAction: 'childIssuesLimit hit',
				attributes: { total: response.total },
			});
		}
		return createResponse(response, issueKey, storyPointsCustomFieldId);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		fireErrorAnalytics({
			meta: {
				id: 'polaris.remote.issue.get-child-issues.failed-to-load',
				teamName: 'JPD - Juno',
			},
			error,
		});

		throw error;
	}
};
