import React, { type ReactElement, createContext, useContext } from 'react';
import type { Card } from '../../types/common.tsx';

type BoardDimensionsState = {
	groupColumnContentHeights: Map<string, number>;
	subscribeToColumnChanges: (columnId: string, callback: (updatedCards: Card[]) => void) => void;
	unsubscribeToColumnChanges: (columnId: string, callback: (updatedCards: Card[]) => void) => void;
};

const BoardDimensionsContext = createContext<BoardDimensionsState | undefined>(undefined);

type BoardDimensionsProviderProps = {
	children: ReactElement;
} & BoardDimensionsState;

export const BoardDimensionsProvider = (props: BoardDimensionsProviderProps) => {
	const {
		children,
		groupColumnContentHeights,
		subscribeToColumnChanges,
		unsubscribeToColumnChanges,
	} = props;

	return (
		<BoardDimensionsContext.Provider
			value={{
				groupColumnContentHeights,
				subscribeToColumnChanges,
				unsubscribeToColumnChanges,
			}}
		>
			{children}
		</BoardDimensionsContext.Provider>
	);
};

export const useVirtualBoard = () => {
	const context = useContext(BoardDimensionsContext);

	if (context === undefined) {
		throw new Error('useVirtualBoard must be used within a BoardDimensionsProvider');
	}

	return context;
};
