import React, { useCallback, useState, useMemo } from 'react';
import { Flex, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useIsCollectionView } from '@atlassian/jira-polaris-common/src/controllers/environment/index.tsx';
import {
	useOpenRightSidebarOnCreate,
	useOpenRightSidebarOnField,
} from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/actions/hooks.tsx';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import { useFieldsForViewControls } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/fields-hooks.tsx';
import {
	useCurrentViewFieldKeys,
	useCurrentViewFields,
	useCurrentViewHiddenFieldKeys,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { TOGGLE_PERMISSIONS } from '@atlassian/jira-polaris-common/src/ui/field-config/constants.tsx';
import { FieldsList as ManageableFieldsList } from '@atlassian/jira-polaris-common/src/ui/field-config/main.tsx';
import { useProjectKeyUnsafe } from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import {
	useCanCreateFields,
	useCanEditFields,
	useCanManageGlobalFields,
} from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import type { Field, FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import { ExploreProjectFieldsSectionMessage } from '@atlassian/jira-polaris-lib-onboarding-flows/src/ui/project-fields/explore-section-message/index.tsx';
import { useProductUsageStartTimestamp } from '@atlassian/jira-polaris-lib-onboarding/src/controllers/selectors/product-usage-start-timestamp-hooks.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { Header } from '../header/fields/index.tsx';
import { Filters } from './filters/index.tsx';
import { Footer } from './footer/index.tsx';
import { messages } from './messages.tsx';

type Props = {
	onClose: () => void;
};

export const FieldsList = ({ onClose }: Props) => {
	const [searchValue, setSearchValue] = useState('');
	const isDragAndDropDisabled = searchValue !== '';

	const { formatMessage } = useIntl();
	const { hideViewField, toggleViewField, updateViewField } = useViewActions();
	const openFieldConfig = useOpenRightSidebarOnField();

	const fieldsForViewControls = useFieldsForViewControls();
	const currentViewFields = useCurrentViewFields();
	const currentViewFieldKeys = useCurrentViewFieldKeys();
	const currentViewHiddenFieldKeys = useCurrentViewHiddenFieldKeys();
	const openRightSidebarOnCreate = useOpenRightSidebarOnCreate();
	const canEditFields = useCanEditFields();
	const canCreateFields = useCanCreateFields();
	const canManageGlobalFields = useCanManageGlobalFields();
	const projectKey = useProjectKeyUnsafe();
	const isCollectionView = useIsCollectionView();

	const productUsageStartTimestamp = useProductUsageStartTimestamp();

	const unselectedFieldsAvailable = useMemo(
		() => Object.keys(fieldsForViewControls).some((key) => !currentViewFieldKeys.includes(key)),
		[currentViewFieldKeys, fieldsForViewControls],
	);

	const filteredSelectedFields = useMemo(
		() =>
			currentViewFields.filter(({ label }) =>
				label.toLowerCase().includes(searchValue.toLowerCase()),
			),
		[currentViewFields, searchValue],
	);

	const filteredProjectFields = useMemo(
		() =>
			Object.keys(fieldsForViewControls)
				.filter((key) => !currentViewFieldKeys.includes(key))
				.map((key) => fieldsForViewControls[key])
				.filter(({ label }) => label.toLowerCase().includes(searchValue.toLowerCase()))
				.sort((a, b) => a.label.localeCompare(b.label)),
		[currentViewFieldKeys, fieldsForViewControls, searchValue],
	);

	const onFieldsSort = useCallback(
		(updatedFields: Field[]) => {
			const fields = updatedFields.map((f) => f.key);
			updateViewField({ fields });
		},
		[updateViewField],
	);

	const onFieldHide = useCallback(
		(fieldKey: FieldKey) => {
			experience.fieldsSidebar.fieldHide.start();
			hideViewField(fieldKey, undefined, (view) => {
				if (isClientFetchError(view?.saveError)) {
					experience.fieldsSidebar.fieldHide.abort(view?.saveError);
				} else if (view?.saveError) {
					experience.fieldsSidebar.fieldHide.failure(view?.saveError);
				} else {
					experience.fieldsSidebar.fieldHide.success();
				}
			});

			fireCompoundAnalyticsEvent.RightSidebarFieldsFieldHidden();
		},
		[hideViewField],
	);

	const onFieldToggle = useCallback(
		(fieldKey: FieldKey, isChecked: boolean) => {
			experience.fieldsSidebar.fieldToggle.start();
			toggleViewField({ fieldKey, isChecked }, undefined, (view) => {
				if (isClientFetchError(view?.saveError)) {
					experience.fieldsSidebar.fieldToggle.abort(view?.saveError);
				} else if (view?.saveError) {
					experience.fieldsSidebar.fieldToggle.failure(view?.saveError);
				} else {
					experience.fieldsSidebar.fieldToggle.success();
				}
			});

			fireCompoundAnalyticsEvent.RightSidebarFieldsFieldHidden();
		},
		[toggleViewField],
	);

	const onSearchInputChange = useCallback((val: string) => setSearchValue(val), [setSearchValue]);

	const handleOpenRightSidebarOnCreate = useCallback(() => {
		openRightSidebarOnCreate(searchValue);
	}, [searchValue, openRightSidebarOnCreate]);

	return (
		<UFOSegment name="jpd.right-sidebar.fields-list">
			<Flex xcss={fieldsContainerStyles}>
				<Header title={formatMessage(messages.fields)} onClose={onClose} />
				{canEditFields && (
					<ExploreProjectFieldsSectionMessage
						paddingInline="space.200"
						paddingBlock="space.100"
						projectKey={projectKey}
						productUsageStartTimestamp={productUsageStartTimestamp}
					/>
				)}
				<Filters searchValue={searchValue} onSearchInputChange={onSearchInputChange} />
				<Flex xcss={fieldsConfigContainerStyles}>
					<ManageableFieldsList
						unselectedFieldsAvailable={unselectedFieldsAvailable}
						selectedFields={filteredSelectedFields}
						availableFields={filteredProjectFields}
						hiddenFieldKeys={currentViewHiddenFieldKeys}
						togglePermissionType={TOGGLE_PERMISSIONS.MANAGE_VIEWS}
						isDragAndDropDisabled={isDragAndDropDisabled}
						onFieldClick={openFieldConfig}
						onFieldHide={onFieldHide}
						onFieldToggle={onFieldToggle}
						onFieldsSort={onFieldsSort}
					/>
				</Flex>
				<Footer
					displayCreateFieldButton={canCreateFields}
					displayCreateGlobalFieldButton={isCollectionView && canManageGlobalFields}
					onCreate={handleOpenRightSidebarOnCreate}
				/>
			</Flex>
		</UFOSegment>
	);
};

const fieldsContainerStyles = xcss({
	height: '100%',
	justifyContent: 'space-between',
	flexDirection: 'column',
	overflowY: 'hidden',
});

const fieldsConfigContainerStyles = xcss({
	flex: 1,
	flexDirection: 'column',
	overflowY: 'auto',
	borderBottom: `1px solid ${token('color.border')}`,
});
