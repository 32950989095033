import React from 'react';
import { Flex } from '@atlaskit/primitives';
import { useIssueType } from '@atlassian/jira-polaris-component-issue-types/src/index.tsx';
import { IssueTypeAvatar } from '@atlassian/jira-polaris-component-issue-types/src/ui/issue-type-icon/issue-type-avatar.tsx';

type IssueTypeItemProps = {
	issueTypeId: string;
};

export const IssueTypeItem = ({ issueTypeId }: IssueTypeItemProps) => {
	const issueType = useIssueType(issueTypeId);

	if (!issueType) {
		return null;
	}

	return (
		<Flex alignItems="center" gap="space.100">
			<IssueTypeAvatar avatarId={issueType.avatarId} size="xsmall" />
			{issueType.name}
		</Flex>
	);
};
