import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { JiraIssueLinkTypeGetResponse, PolarisIssueLinkTypes } from './types.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { JiraIssueLinkTypeGetResponse };

export const getIssueLinkTypes = (): Promise<JiraIssueLinkTypeGetResponse> =>
	fetchJson(
		`/rest/api/3/issueLinkType${fg('jpd-trace-fetch-network-call') ? '?operation=getIssueLinkTypes' : ''}`,
		{
			method: 'GET',
		},
	);

export const getSpecialJpdIssueLinkTypes = (): Promise<PolarisIssueLinkTypes> =>
	fetchJson(
		`/rest/polaris/issueLinkType${fg('jpd-trace-fetch-network-call') ? '?operation=getSpecialJpdIssueLinkTypes' : ''}`,
		{
			method: 'GET',
		},
	);
