import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	administratorRoleDescription: {
		id: 'polaris-lib-role-translations.common.administrator-role-description',
		defaultMessage:
			'Admins have access to all the features available in the project and can manage project settings and people.',
		description: 'Translated description of the Admin role',
	},
	creatorRoleName: {
		id: 'polaris-lib-role-translations.common.creator-role-name',
		defaultMessage: 'Creator',
		description: 'Translated name of the Creator role',
	},
	creatorRoleDescription: {
		id: 'polaris-lib-role-translations.common.creator-role-description',
		defaultMessage:
			'Creators have access to all the features available in this project with the exception of features requiring administrator specific permissions.',
		description: 'Translated description of the Creator role',
	},
	contributorRoleName: {
		id: 'polaris-lib-role-translations.common.contributor-role-name',
		defaultMessage: 'Contributor',
		description: 'Translated name of the Contributor role',
	},
	contributorRoleDescription: {
		id: 'polaris-lib-role-translations.common.contributor-role-description',
		defaultMessage:
			'Contributors can access this project in read-only mode and contribute using the enabled features: creating ideas, adding comments, insights, votes or reactions.',
		description: 'Translated description of the Contributor role',
	},
});
