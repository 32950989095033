import React, { useCallback, useEffect, useRef, useMemo, useState } from 'react';
import { styled } from '@compiled/react';
import { useVirtualizer } from '@tanstack/react-virtual';
import { token } from '@atlaskit/tokens';
import { useBucketFilteredIssueIds } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/bucket-hooks.tsx';
import { useTimelineItems } from '@atlassian/jira-polaris-common/src/controllers/issue/utils/view-filtering/view-timeline/index.tsx';
import {
	useCurrentViewVerticalGroupBy,
	useCurrentViewXAxis,
	useCurrentViewYAxis,
	useCurrentViewKind,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import {
	useCardHeight,
	IdeaCardContextProvider,
} from '@atlassian/jira-polaris-common/src/ui/idea-card-v2/utils.tsx';
import {
	VIEW_KIND_MATRIX,
	VIEW_KIND_TIMELINE,
} from '@atlassian/jira-polaris-domain-view/src/view/constants.tsx';
import type { ViewKind } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { DRAGGABLE_ITEM_TYPE as MATRIX_DRAGGABLE_ITEM_TYPE } from '@atlassian/jira-polaris-lib-matrix/src/common/constants/index.tsx';
import { DRAGGABLE_ITEM_TYPE as TIMELINE_DRAGGABLE_ITEM_TYPE } from '@atlassian/jira-polaris-lib-timeline/src/common/constants.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { useCanCreateIssues } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useItems as useMatrixItems } from '../../view-content/idea-matrix/utils/items.tsx';
import { Card, MARGIN_BOTTOM, MARGIN_TOP } from './card/index.tsx';
import { Footer } from './footer/index.tsx';
import { Header } from './header/index.tsx';

type Props = {
	onClose: () => void;
};

type HandledViewKind = Extract<ViewKind, 'MATRIX' | 'TIMELINE'>;

const isHandledViewKind = (viewKind: unknown): viewKind is HandledViewKind =>
	typeof viewKind === 'string' && (viewKind === 'MATRIX' || viewKind === 'TIMELINE');

const dndTypeByViewKind: Record<HandledViewKind, string> = {
	MATRIX: MATRIX_DRAGGABLE_ITEM_TYPE,
	TIMELINE: TIMELINE_DRAGGABLE_ITEM_TYPE,
};

export const useBucketIssueIds = (searchQuery: string) => {
	const filteredIssueIds = useBucketFilteredIssueIds(searchQuery);
	const timelineItems = useTimelineItems();
	const selectedXAxisField = useCurrentViewXAxis();
	const selectedYAxisField = useCurrentViewYAxis();
	const matrixItems = useMatrixItems(selectedXAxisField, selectedYAxisField).items;
	const viewKind = useCurrentViewKind();

	if (viewKind !== VIEW_KIND_TIMELINE && viewKind !== VIEW_KIND_MATRIX) {
		return filteredIssueIds;
	}

	const itemsForCurrentViewKind = viewKind === VIEW_KIND_TIMELINE ? timelineItems : matrixItems;
	const issueIdsOnView: string[] = itemsForCurrentViewKind.map(({ id }) => id);

	return filteredIssueIds.filter((id) => !issueIdsOnView.includes(id));
};

const IdeasBucketInternal = ({ onClose }: Props) => {
	const [searchValue, setSearchValue] = useState('');
	const issueIds = useBucketIssueIds(searchValue);
	const viewKind = useCurrentViewKind();
	const [focusedIssueId, setFocusedIssueId] = useState<string | null>(null);
	const scrollElementRef = useRef<HTMLDivElement>(null);
	const newItemsIdsRef = useRef<string[]>([]);
	const [canCreateIssues] = useCanCreateIssues();

	const cardHeight = useCardHeight(false);

	useEffect(() => {
		if (searchValue !== '') {
			setFocusedIssueId(null);
		}
	}, [searchValue]);

	const estimateSize = useCallback(() => cardHeight + MARGIN_TOP + MARGIN_BOTTOM, [cardHeight]);

	const virtualizer = useVirtualizer({
		count: issueIds.length,
		getScrollElement: () => scrollElementRef.current,
		overscan: 5,
		getItemKey: (index) => `${index}-bucket-item`,
		estimateSize,
	});

	useEffect(() => {
		if (focusedIssueId && !newItemsIdsRef.current.includes(focusedIssueId)) {
			virtualizer.scrollToIndex(issueIds.indexOf(focusedIssueId));
			newItemsIdsRef.current.push(focusedIssueId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [focusedIssueId, virtualizer.scrollToIndex, issueIds]);

	const handleIdeaCreated = useCallback((id: string) => {
		setFocusedIssueId(id);
		setSearchValue('');
	}, []);

	const dndType = isHandledViewKind(viewKind) && dndTypeByViewKind[viewKind];
	if (!dndType) {
		return null;
	}

	return (
		<IdeasBucketContainer>
			<Header searchValue={searchValue} onSearchChange={setSearchValue} onClose={onClose} />
			<IdeasBucketContent ref={scrollElementRef}>
				<IdeasBucketContentInner height={virtualizer.getTotalSize()}>
					{virtualizer.getVirtualItems().map(({ index, key, start }) => (
						<CardWrapper
							key={key}
							start={start}
							ref={virtualizer.measureElement}
							data-index={index}
						>
							<Card
								isFocused={focusedIssueId === issueIds[index]}
								dndType={dndType}
								id={issueIds[index]}
							/>
						</CardWrapper>
					))}
				</IdeasBucketContentInner>
			</IdeasBucketContent>
			{(!fg('jpd-aurora-roadmap-inline-edit') || canCreateIssues) && (
				<Footer onIdeaCreated={handleIdeaCreated} />
			)}
		</IdeasBucketContainer>
	);
};

export const IdeasBucket = (props: Props) => {
	const groupByField = useCurrentViewVerticalGroupBy();
	const extraFields = useMemo(() => (groupByField ? [groupByField] : undefined), [groupByField]);
	return (
		<UFOSegment name="jpd.right-sidebar.ideas-bucket">
			<IdeaCardContextProvider extraFields={extraFields}>
				<IdeasBucketInternal {...props} />
			</IdeaCardContextProvider>
		</UFOSegment>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IdeasBucketContainer = styled.div({
	height: '100%',
	backgroundColor: token('elevation.surface'),
	display: 'flex',
	flexDirection: 'column',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IdeasBucketContent = styled.div({
	flex: '1 1 auto',
	overflowY: 'auto',
	position: 'relative',
	'&::before': {
		content: '',
		display: 'block',
		position: 'sticky',
		top: 0,
		zIndex: 1,
		height: '1px',
		backgroundColor: token('color.background.selected'),
	},
	'&::after': {
		content: '',
		display: 'block',
		position: 'absolute',
		top: 0,
		zIndex: 2,
		width: '100%',
		height: '1px',
		backgroundColor: token('color.background.inverse.subtle'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IdeasBucketContentInner = styled.div<{ height: number }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: ({ height }) => `${height}px`,
	width: '100%',
	position: 'relative',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginTop: '10px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CardWrapper = styled.div<{ start: number }>({
	position: 'absolute',
	top: 0,
	left: 0,
	width: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	transform: ({ start }) => `translateY(${start}px)`,
});
