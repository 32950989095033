import React, { useState, useCallback, type SyntheticEvent, useEffect } from 'react';
import { styled } from '@compiled/react';
import ButtonLegacy from '@atlaskit/button';
import Button from '@atlaskit/button/new';
import Heading from '@atlaskit/heading';
import LinkIcon from '@atlaskit/icon/core/migration/link';
import { Stack, Inline, xcss, Box } from '@atlaskit/primitives';
import Toggle from '@atlaskit/toggle';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import type { AccessLevel } from '@atlassian/jira-polaris-common/src/common/types/access/index.tsx';
import { useFullscreenQueryParameter } from '@atlassian/jira-polaris-common/src/controllers/route/index.tsx';
import { useCanExportViews } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { sendPendoTrackEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/pendo/index.tsx';
import { useNotifications } from '@atlassian/jira-polaris-lib-notifications/src/controllers/index.tsx';
import {
	type UIAnalyticsEvent,
	fireUIAnalytics,
	useAnalyticsEvents,
	FireScreenAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { expVal } from '@atlassian/jira-feature-experiments';
import {
	SmartUserPickerWithSuggestions,
	type SmartUserPickerWithSuggestionsPrincipal,
} from '@atlassian/jira-polaris-component-smart-user-picker-with-suggestions/src/ui/index.tsx';
import { useRolePicker } from '@atlassian/jira-polaris-component-role-picker/src/ui/index.tsx';
import { useIsSiteAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-site-admin/index.tsx';
import { getEdition } from '@atlassian/jira-shared-types/src/edition.tsx';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';
import { JIRA_SOFTWARE } from '@atlassian/jira-shared-types/src/application.tsx';
import { AccessBanner } from './access-banner/index.tsx';
import messages from './messages.tsx';
import { useShare } from './share/index.tsx';
import { useInvite } from './invite/index.tsx';

type ShareDialogContentProps = {
	accessLevel: AccessLevel | undefined;
	onCloseDialog: () => void;
};

export const ShareDialogContent = ({ accessLevel, onCloseDialog }: ShareDialogContentProps) => {
	const { error, success } = useNotifications();
	const fullscreen = useFullscreenQueryParameter();
	const { formatMessage } = useIntl();
	const [isFullscreen, setIsFullscreen] = useState(fullscreen);
	const canExportViews = useCanExportViews();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { onShare } = useShare();
	const isSiteAdmin = useIsSiteAdmin();
	const appEditions = useAppEditions();
	const appEdition = getEdition(JIRA_SOFTWARE, appEditions);
	const {
		data: { selectedRole },
		render: renderRolePicker,
	} = useRolePicker({ applicationEdition: appEdition });
	const { onInvite } = useInvite();

	useEffect(() => {
		sendPendoTrackEvent({
			actionSubjectAndAction: 'shareModal opened',
		});

		return () => {
			sendPendoTrackEvent({
				actionSubjectAndAction: 'shareModal closed',
			});
			fireUIAnalytics(createAnalyticsEvent({}), 'modal closed', 'share');
		};
	}, [createAnalyticsEvent]);

	const onCopy = useCallback(
		(event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'copyLink', { isFullscreen });
			sendPendoTrackEvent({
				source: 'shareModal',
				actionSubjectAndAction: 'button clicked',
				actionSubjectId: 'copyLink',
				attributes: {
					isFullscreen,
				},
			});

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			const shareUrl = new URL(window.location.href);
			const searchParams = new URLSearchParams(shareUrl.search);
			if (isFullscreen) {
				searchParams.set('fullscreen', 'true');
			}

			shareUrl.search = searchParams.toString();

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			(fg('jfp-magma-undefined-navigator') ? globalThis.navigator.clipboard : navigator.clipboard)
				.writeText(shareUrl.toString())
				.then(() =>
					success({
						title: formatMessage(messages.copiedTitle),
						description: formatMessage(messages.copiedMessage),
					}),
				)
				.catch(() =>
					error({
						title: formatMessage(messages.copyFailTitle),
						description: formatMessage(messages.copyFailMessage),
					}),
				);
		},
		[error, formatMessage, success, isFullscreen],
	);

	const onToggleFullscreen = useCallback(
		(event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'fullscreen', { isEnabled: !isFullscreen });
			sendPendoTrackEvent({
				source: 'shareModal',
				actionSubjectAndAction: 'toggle change',
				actionSubjectId: 'fullscreen',
				attributes: {
					isEnabled: !isFullscreen,
				},
			});
			setIsFullscreen((value) => !value);
		},
		[setIsFullscreen, isFullscreen],
	);

	const [principals, setPrincipals] = useState<SmartUserPickerWithSuggestionsPrincipal[]>([]);

	const onShareClick = useCallback(async () => {
		if (isSiteAdmin && selectedRole !== null) {
			await onInvite(principals, selectedRole.id);
		}
		await onShare(principals);
		onCloseDialog();
	}, [isSiteAdmin, onCloseDialog, onInvite, onShare, principals, selectedRole]);

	return (
		<Stack
			space={expVal('jpd_invite_from_share_dialog', 'isEnabled', false) ? 'space.200' : 'space.300'}
			xcss={containerStyles}
		>
			<FireScreenAnalytics attributes={{ isFullscreen }} />
			<Heading size="medium" as="h4">
				{formatMessage(messages.shareTitle)}
			</Heading>
			<AccessBanner accessLevel={accessLevel} />
			{expVal('jpd_invite_from_share_dialog', 'isEnabled', false) ? (
				<Stack space="space.200">
					<SmartUserPickerWithSuggestions
						onChangePrincipals={setPrincipals}
						principals={principals}
					/>
					{isSiteAdmin ? renderRolePicker() : null}
				</Stack>
			) : null}
			<Inline alignBlock="center">
				<Toggle
					isDisabled={!canExportViews}
					id="toggle-share-full-screen"
					onChange={onToggleFullscreen}
					isChecked={isFullscreen}
					size="large"
				/>
				<FullScreenTitle>{formatMessage(messages.shareFullScreenTitle)}</FullScreenTitle>
			</Inline>
			{expVal('jpd_invite_from_share_dialog', 'isEnabled', false) ? (
				<>
					<Box xcss={dividerStyles} />
					<Box xcss={actionButtonStyles}>
						<Button
							id="polaris-common.ui.share.share-content.button"
							onClick={onCopy}
							appearance="subtle"
							iconBefore={LinkIcon}
						>
							{formatMessage(messages.copyLink)}
						</Button>
						<Box xcss={shareButtonStyles}>
							<Button onClick={onCloseDialog} appearance="subtle">
								{formatMessage(messages.cancel)}
							</Button>
							<Button
								appearance="primary"
								isDisabled={principals.length === 0 || (isSiteAdmin && !selectedRole)}
								onClick={onShareClick}
							>
								{formatMessage(messages.share)}
							</Button>
						</Box>
					</Box>
				</>
			) : (
				<CopyAction>
					<ButtonLegacy
						id="polaris-common.ui.share.share-content.button"
						onClick={onCopy}
						appearance="primary"
						iconBefore={<LinkIcon label={formatMessage(messages.copyLink)} />}
					>
						{formatMessage(messages.copyLink)}
					</ButtonLegacy>
				</CopyAction>
			)}
		</Stack>
	);
};

const actionButtonStyles = xcss({
	alignItems: 'center',
	display: 'flex',
	justifyContent: 'space-between',
});

const shareButtonStyles = xcss({
	display: 'flex',
	gap: 'space.200',
});

const dividerStyles = xcss({
	borderTop: `1px solid ${token('color.border')}`,
	marginBottom: 'space.250',
	marginLeft: 'space.negative.300',
	marginRight: 'space.negative.300',
});

const containerStyles = xcss({
	width: '500px',
	padding: 'space.300',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CopyAction = styled.div({
	paddingTop: token('space.300'),
	position: 'relative',

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:before': {
		top: 0,
		left: 0,
		width: '100%',
		position: 'absolute',
		content: '',
		height: token('space.025'),

		backgroundColor: token('color.background.accent.gray.subtlest'),
		borderRadius: token('space.025'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FullScreenTitle = styled.p({
	font: token('font.body'),

	color: token('color.text'),
	marginTop: 0,
	marginRight: token('space.100'),
	marginBottom: 0,
	marginLeft: token('space.100'),
});
