import React from 'react';
import { Box } from '@atlaskit/primitives';
import { EmojiPicker } from '@atlassian/jira-polaris-lib-emoji-picker/src/ui/index.tsx';
import { IssueTypeIcon } from '@atlassian/jira-polaris-component-issue-types/src/ui/issue-type-icon/issue-type-icon.tsx';
import {
	useIssueTypeByNameAndProjectIds,
	useIssueTypeExists,
} from '@atlassian/jira-polaris-component-issue-types/src/controllers/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import {
	useConnectionFieldIssueTypeId,
	useConnectionFieldIssueTypeNameFilter,
} from '../../../controllers/selectors/field-hooks.tsx';

export const ConnectionFieldEmojiIcon = () => {
	const issueTypeId = useConnectionFieldIssueTypeId();
	const issueTypeNameFilter = useConnectionFieldIssueTypeNameFilter();
	const issueTypeByName = useIssueTypeByNameAndProjectIds({
		name: issueTypeNameFilter?.names[0],
		projectIds: issueTypeNameFilter?.projectIds,
	});
	const issueTypeExists = useIssueTypeExists({ issueTypeId });

	if (fg('jpd_cross_project_connecting')) {
		if (issueTypeByName) {
			return (
				<Box paddingBlockStart="space.050" paddingInlineStart="space.075">
					<IssueTypeIcon issueTypeId={issueTypeByName.id} />
				</Box>
			);
		}

		return <EmojiPicker readonly />;
	}

	if (!issueTypeId || !issueTypeExists) {
		return <EmojiPicker readonly />;
	}

	return (
		<Box paddingBlockStart="space.050" paddingInlineStart="space.075">
			<IssueTypeIcon issueTypeId={issueTypeId} />
		</Box>
	);
};
