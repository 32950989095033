import React from 'react';
import { Flex, Text, xcss } from '@atlaskit/primitives';
import { useIssueTypeIdsForProject } from '@atlassian/jira-polaris-component-issue-types/src/controllers/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { messages } from './messages.tsx';
import { IssueTypeItem } from './issue-type-item/index.tsx';

type IssueTypeConfigurationProps = {
	projectKey: string;
	projectId: string;
};

export const IssueTypeConfiguration = ({ projectKey, projectId }: IssueTypeConfigurationProps) => {
	const { formatMessage } = useIntl();
	const issueTypeIds = useIssueTypeIdsForProject({ projectId });

	if (!projectId || !projectKey) {
		return null;
	}

	return (
		<Flex direction="column" gap="space.200" xcss={containerStyles}>
			<Text size="UNSAFE_small">{formatMessage(messages.issueTypesLabel)}</Text>
			<Flex direction="column" gap="space.100">
				{issueTypeIds.map((issueTypeId) => (
					<IssueTypeItem key={issueTypeId} issueTypeId={issueTypeId} />
				))}
			</Flex>
		</Flex>
	);
};

const containerStyles = xcss({
	paddingBottom: 'space.200',
});
