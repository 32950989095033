import React, { useCallback, type FC } from 'react';
import { Pressable, Text, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

export type PressableLinkProps = {
	href?: string;
	targetBlank?: boolean;
	onClick?: (() => void) | ((e: React.MouseEvent | React.KeyboardEvent) => void);
	isBlue?: boolean;
	showUnderline?: boolean;
	fontSize?: 'small' | 'medium' | 'large';
	children?: React.ReactNode;
};

/**
 * PressableLink is a wrapper around Pressable that should appear to be a link.
 * Either href or onClick is required (both are possible):
 * 1) If href is supplied, we will call window.open to open it
 * 2) Is onClick is supplied, we will execute it when clicked
 */
export const PressableLink: FC<PressableLinkProps> = ({
	href,
	targetBlank,
	onClick,
	children,
	fontSize,
	showUnderline,
	isBlue,
}) => {
	const openLink = useCallback(() => {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.open(href, targetBlank ? '_blank' : undefined);
	}, [href, targetBlank]);

	return (
		<Pressable
			padding="space.0"
			onClick={onClick || openLink}
			xcss={[pressableLinkStyle, showUnderline && underlineStyle]}
		>
			<Text
				size={fontSize || 'medium'}
				color={isBlue ? 'color.text.accent.blue' : 'color.text.subtlest'}
			>
				{children}
			</Text>
		</Pressable>
	);
};

const pressableLinkStyle = xcss({
	background: 'transparent',
	':hover': {
		cursor: 'pointer',
	},
	':active': {
		color: 'color.text',
	},
});

const underlineStyle = xcss({
	textDecoration: 'underline',
	textDecorationColor: token('color.text.subtlest'),
});
