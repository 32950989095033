import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	atlasConnectSite: {
		id: 'polaris-ideas.settings.advanced.atlas.atlas-connect-site',
		defaultMessage: 'Connect Atlas site',
		description: 'Caption for connecting Atlas site',
	},
	atlasHelpMessage: {
		id: 'polaris-ideas.settings.advanced.atlas.atlas-help-message',
		defaultMessage:
			'Select the site with Atlas installed. The site needs to be in the same organization as <link>{currentSite}</link>. <a>Learn more</a>',
		description: 'Description for connecting Atlas site',
	},
	atlasHelpMessageV2: {
		id: 'polaris-ideas.settings.advanced.atlas.atlas-help-message-v2',
		defaultMessage:
			'Select the site with Atlas installed. This site will be used to search for projects in the Atlassian Project field. The site needs to be in the same organization as <link>{currentSite}</link>. <a>Learn more</a>',
		description: 'Description for connecting Atlas site for sites with global atls fields',
	},
});
