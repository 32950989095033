// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createHook } from '@atlassian/react-sweet-state';
import {
	PROJECT_PROPERTIES,
	type ProjectProperties,
	type RemoteProperty,
	type State,
} from '../../types.tsx';
import { ProjectPropertiesStore } from '../../index.tsx';
import { isBooleanOrUndefinedProperty, isStringOrUndefinedProperty } from './utils.tsx';

const getProjectProperty = (
	state: State,
	scopedPropertyKey: ProjectProperties,
): RemoteProperty<unknown> => {
	const property = state.properties[scopedPropertyKey];

	return {
		value: property?.value,
		isLoading: !!property?.isLoading,
		error: property?.error ?? null,
	};
};

const useProjectProperty = createHook(ProjectPropertiesStore, {
	selector: getProjectProperty,
});

const useBooleanProperty = (projectPropertyKey: ProjectProperties): RemoteProperty<boolean> => {
	const [projectProperty] = useProjectProperty(projectPropertyKey);

	if (isBooleanOrUndefinedProperty(projectProperty)) {
		return projectProperty;
	}

	throw new Error('Property value is not a boolean or undefined');
};

const useStringProperty = (projectPropertyKey: ProjectProperties): RemoteProperty<string> => {
	const [projectProperty] = useProjectProperty(projectPropertyKey);

	if (isStringOrUndefinedProperty(projectProperty)) {
		return projectProperty;
	}

	throw new Error('Property value is not a string or undefined');
};

export const useIsAtlasIntegrationEnabledProperty = (): RemoteProperty<boolean> =>
	useBooleanProperty(PROJECT_PROPERTIES.ATLAS_INTEGRATION_ENABLED);

export const useAtlasSiteCloudIdProperty = (): RemoteProperty<string> =>
	useStringProperty(PROJECT_PROPERTIES.ATLAS_SITE_CLOUD_ID);

export const useIsIssueCreationEnabledProperty = (): RemoteProperty<boolean> =>
	useBooleanProperty(PROJECT_PROPERTIES.IDEA_CREATION_BY_CONTRIBUTORS);

export const useSubmitIdeaFormViewProperty = (): RemoteProperty<string> =>
	useStringProperty(PROJECT_PROPERTIES.SUBMIT_IDEA_FORM_VIEW);

export const useSubmitIdeaFormMandatoryFieldsProperty = (): RemoteProperty<boolean> =>
	useBooleanProperty(PROJECT_PROPERTIES.SUBMIT_IDEA_FORM_MANDATORY_FIELDS);

export const useSubmitIdeaFormDescriptionTemplateProperty = (): RemoteProperty<string> =>
	useStringProperty(PROJECT_PROPERTIES.SUBMIT_IDEA_FORM_DESCRIPTION_TEMPLATE);
