import React, { useState, type SyntheticEvent } from 'react';
import isEmpty from 'lodash/isEmpty';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import TrashIcon from '@atlaskit/icon/core/migration/delete--trash';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import {
	fireUIAnalytics,
	ContextualAnalyticsData,
	MODAL,
} from '@atlassian/jira-product-analytics-bridge';
import { fg } from '@atlassian/jira-feature-gating';
import { useProjectIdsForIssues } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/project/hooks.tsx';
import { useCanDeleteIssues } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { useVisibleSelectedIssueIds } from '../../../utils.tsx';
import { BarActionButton } from '../bar-action-button/index.tsx';
import { messages } from './messages.tsx';
import { DeleteModal } from './modal/index.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => {
	const { formatMessage } = useIntl();
	const visibleSelectedIssueIds = useVisibleSelectedIssueIds();
	const { deleteIssues } = useIssueActions();
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const projectIds = useProjectIdsForIssues(visibleSelectedIssueIds);
	const [canDeleteIdeasForSelectedProjects] = useCanDeleteIssues({ projectIds });

	if (isEmpty(visibleSelectedIssueIds)) {
		return null;
	}

	if (fg('jpd-aurora-roadmap-inline-edit')) {
		return (
			<>
				<BarActionButton
					interactionName="jpd.view-controls.delete-ideas"
					id="polaris-ideas.ui.view-controls.delete-button"
					testId="polaris-ideas.ui.view-controls.manage-bar.delete-ideas.delete-ideas"
					onClick={(event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
						fireUIAnalytics(analyticsEvent, 'deleteIdeas');
						setIsDeleteModalOpen(true);
					}}
					isDisabled={!canDeleteIdeasForSelectedProjects}
					tooltipMessage={
						!canDeleteIdeasForSelectedProjects
							? formatMessage(messages.notAllowedTooltip)
							: undefined
					}
					iconBefore={<TrashIcon label={formatMessage(messages.buttonLabel)} />}
				>
					{formatMessage(messages.buttonLabel)}
				</BarActionButton>
				<ContextualAnalyticsData sourceName="deleteIdeas" sourceType={MODAL}>
					<DeleteModal
						isOpen={isDeleteModalOpen}
						onCancel={(event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
							fireUIAnalytics(analyticsEvent, 'cancel', {
								deleteIdeasCount: visibleSelectedIssueIds.length,
							});
							setIsDeleteModalOpen(false);
						}}
						onConfirm={(event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
							fireUIAnalytics(analyticsEvent, 'delete', {
								deleteIdeasCount: visibleSelectedIssueIds.length,
							});
							deleteIssues(visibleSelectedIssueIds);
							setIsDeleteModalOpen(false);
						}}
						issueIds={visibleSelectedIssueIds}
					/>
				</ContextualAnalyticsData>
			</>
		);
	}

	return (
		<>
			<Button
				interactionName="jpd.view-controls.delete-ideas"
				id="polaris-ideas.ui.view-controls.delete-button"
				testId="polaris-ideas.ui.view-controls.manage-bar.delete-ideas.delete-ideas"
				onClick={(event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
					fireUIAnalytics(analyticsEvent, 'deleteIdeas');
					setIsDeleteModalOpen(true);
				}}
				appearance="subtle"
				iconBefore={<TrashIcon label={formatMessage(messages.buttonLabel)} />}
			>
				{formatMessage(messages.buttonLabel)}
			</Button>
			<ContextualAnalyticsData sourceName="deleteIdeas" sourceType={MODAL}>
				<DeleteModal
					isOpen={isDeleteModalOpen}
					onCancel={(event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
						fireUIAnalytics(analyticsEvent, 'cancel', {
							deleteIdeasCount: visibleSelectedIssueIds.length,
						});
						setIsDeleteModalOpen(false);
					}}
					onConfirm={(event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
						fireUIAnalytics(analyticsEvent, 'delete', {
							deleteIdeasCount: visibleSelectedIssueIds.length,
						});
						deleteIssues(visibleSelectedIssueIds);
						setIsDeleteModalOpen(false);
					}}
					issueIds={visibleSelectedIssueIds}
				/>
			</ContextualAnalyticsData>
		</>
	);
};
