import type { StylesConfig } from '@atlaskit/select';
import { token } from '@atlaskit/tokens';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';
import type { OptionProperty } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';

export const stylesConfig: StylesConfig<OptionProperty, boolean> = {
	menu: (styles) => ({
		...styles,
		width: '240px',
		zIndex: layers.modal,
	}),
	menuPortal: (styles) => ({ ...styles, zIndex: layers.modal }),
	menuList: (styles) => ({
		...styles,
		padding: 0,
	}),
	control: (styles) => ({
		...styles,
		width: '350px',
	}),
	groupHeading: (styles) => ({
		...styles,
		':empty': {
			display: 'none',
		},
	}),
	group: (styles) => ({
		...styles,
		padding: `${token('space.075')} 0 ${token('space.075')} 0`,
		':not(:first-of-type)': {
			borderTop: `1px solid ${token('color.border')}`,
		},
	}),
	option: (styles) => ({
		...styles,
		padding: 0,
		paddingTop: 0,
		paddingBottom: 0,
	}),
};
