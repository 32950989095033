// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import type { Props } from '../../../types.tsx';
import type { State } from '../../types.tsx';

export const setResizePosition =
	(resizePosition: number | undefined): Action<State, Props> =>
	({ setState }) => {
		setState({
			resizePosition,
		});
	};
