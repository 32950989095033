import {
	type MessageProps,
	createPlacementComponent,
	useInitialData,
} from '@post-office/placement-common';
import { useEffect, useState } from 'react';

import { MessageRenderer, type renderer } from '../__generated__/client/render';
import { PLACEMENT_ID } from '../__generated__/common';
import { withJiraSideNavIntlProvider } from '../__generated__/intl-provider';
import { type JiraSideNavInitialData } from '../api/types';
import { type Placement as JiraSideNavPlacement } from '../contracts/Placement';

export type Message = MessageProps<typeof renderer>;

const excludedMessages = new Set(['test-message-inapp', 'message-template-template']);

export const JiraSideNavPlacementComponent = withJiraSideNavIntlProvider(
	createPlacementComponent<JiraSideNavPlacement>(
		PLACEMENT_ID,
		({ initialData, onHasMessageChanged }) => {
			const { data, isLoading } = useInitialData<JiraSideNavInitialData>(initialData);
			const [{ message, status }, setMessageState] = useState<{
				message: Message | undefined;
				status: 'loading' | 'done';
			}>({
				message: undefined,
				status: 'loading',
			});

			const handleOnDismissed = () => {
				setMessageState({ message: undefined, status: 'done' });
				onHasMessageChanged?.(false);
			};

			useEffect(() => {
				if (!isLoading) {
					const newMessage = ((data?.messages || []) as Message[]).filter(
						(dataMessage) => !excludedMessages.has(dataMessage.messageTemplateId),
					)[0];

					setMessageState({
						message: newMessage,
						status: 'done',
					});

					onHasMessageChanged?.(newMessage !== undefined);
				}
			}, [data, isLoading]);

			if (isLoading || status === 'loading') {
				return null;
			}

			return (
				message && (
					<MessageRenderer
						{...message}
						id={message.messageInstanceId}
						onDismissed={handleOnDismissed}
					/>
				)
			);
		},
		{
			choreographer: {
				isDefaultEnabled: false,
			},
		},
	),
);

export default JiraSideNavPlacementComponent;
