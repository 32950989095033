import React, { type SyntheticEvent } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button/loading-button';
import EmailIcon from '@atlaskit/icon/core/migration/email';
import ErrorIcon from '@atlaskit/icon/core/migration/error';
import { Box, Flex, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import ToolTip from '@atlaskit/tooltip';
import { borderRadius, gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { EmailAddress } from '@atlassian/jira-project-settings-apps-notifications-services/src/services/notification-email-service/types.tsx';
import messages from './messages.tsx';
import type { EmailStatus } from './types.tsx';

export type CustomEmailCardProps = {
	isModalLoading?: boolean;
	currentEmail: EmailAddress;
	status?: EmailStatus;
	onEditButtonClicked: () => void;
};

const CustomEmailCard = (props: CustomEmailCardProps) => {
	const { formatMessage } = useIntl();
	const { currentEmail, onEditButtonClicked, status = 'success', isModalLoading = false } = props;

	const onEditButton = (e: SyntheticEvent, analyticsEvent: UIAnalyticsEvent): void => {
		onEditButtonClicked();
		fireUIAnalytics(analyticsEvent, 'editDomainButton');
	};
	const renderCardContent = () => (
		<CardContent>
			<EmailIcon label="email-icon" LEGACY_size="medium" spacing="spacious" />
			{/* @ts-expect-error - TS2741 - Property 'status' is missing in type '{ children: string[]; }' but required in type 'Readonly<ThemedOuterStyledProps<ClassAttributes<HTMLDivElement> & HTMLAttributes<HTMLDivElement> & { status: any; }, any>>'. */}
			<EmailAddressField> {currentEmail} </EmailAddressField>
		</CardContent>
	);
	const renderCardErrorContent = () => (
		<CardContent>
			<EmailIcon label="email-icon" LEGACY_size="medium" spacing="spacious" />
			<ToolTip content={formatMessage(messages.errorDescription)}>
				<Flex alignItems="center" xcss={flexStyles}>
					<EmailAddressField status="error">{currentEmail}</EmailAddressField>
					<ErrorIcon
						LEGACY_size="medium"
						spacing="spacious"
						label=""
						color={token('color.icon.danger')}
					/>
				</Flex>
			</ToolTip>
		</CardContent>
	);
	return (
		<Box xcss={cardWrapperStyles}>
			<Card>
				{status === 'success' ? renderCardContent() : renderCardErrorContent()}
				<CardActions>
					<Button
						testId="project-settings-apps-notifications-custom-email-card.ui.edit-button"
						onClick={onEditButton}
						isLoading={isModalLoading}
					>
						{formatMessage(messages.editButton)}
					</Button>
				</CardActions>
			</Card>
		</Box>
	);
};

export default CustomEmailCard;

const cardWrapperStyles = xcss({
	marginTop: 'space.300',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Card = styled.div({
	borderWidth: '1px',
	borderStyle: 'solid',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderColor: `${colors.N30}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderRadius: `${borderRadius}px`,
	paddingTop: token('space.300'),
	paddingRight: token('space.300'),
	paddingBottom: token('space.300'),
	paddingLeft: token('space.300'),
	display: 'grid',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	gridTemplateColumns: `${gridSize}px auto max-content`,
	columnGap: token('space.200'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CardContent = styled.div({
	display: 'flex',
	alignItems: 'center',
	flex: 1,
	font: token('font.body'),
	flexFlow: 'row wrap',
	width: 'max-content',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EmailAddressField = styled.div<{ status: string }>({
	marginLeft: token('space.200'),
	// eslint-disable-next-line @typescript-eslint/no-explicit-any, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	color: (props: any) => (props.status === 'error' ? token('color.icon.danger') : undefined),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CardActions = styled.div({
	justifySelf: 'end',
});

const flexStyles = xcss({
	cursor: 'pointer',
});
