import React, { useCallback, useState, useEffect } from 'react';
import { useWorkflowActions } from '@atlassian/jira-polaris-common/src/controllers/workflow/main.tsx';
import { getIssueTypeExternalUuid } from '@atlassian/jira-polaris-common/src/services/jira/issue-type/index.tsx';
import { useProjectIdUnsafe } from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import { useIssueTypeIdsForProject } from '@atlassian/jira-polaris-component-issue-types/src/controllers/index.tsx';
import { AsyncWorkflowEditor } from '@atlassian/jira-workflow-editor/src/async.tsx';
import { useRouterActions } from '@atlassian/react-resource-router';
import { useIssueTypeIdQueryParameter } from '@atlassian/jira-polaris-common/src/controllers/route/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';

export const PolarisWorkflowEditor = () => {
	const [issueTypeUuid, setIssueTypeUuid] = useState<string | undefined>(undefined);
	const { loadStatuses } = useWorkflowActions();
	const { goBack } = useRouterActions();

	const projectId = useProjectIdUnsafe();
	const firstAvailableIssueTypeId = useIssueTypeIdsForProject({ projectId })[0];
	const issueTypeIdQueryParam = useIssueTypeIdQueryParameter();

	const issueTypeId = fg('jpd_issue_types_ga')
		? issueTypeIdQueryParam ?? firstAvailableIssueTypeId
		: firstAvailableIssueTypeId;

	const onClose = useCallback(() => {
		goBack();
	}, [goBack]);

	const onSuccess = useCallback(() => {
		loadStatuses();
	}, [loadStatuses]);

	useEffect(() => {
		if (issueTypeUuid === undefined && issueTypeId !== undefined) {
			getIssueTypeExternalUuid(issueTypeId, projectId).then(setIssueTypeUuid);
		}
	}, [issueTypeUuid, issueTypeId, projectId]);

	if (issueTypeUuid === undefined || issueTypeId === undefined) {
		return null;
	}

	return <AsyncWorkflowEditor issueTypeId={issueTypeId} onClose={onClose} onSuccess={onSuccess} />;
};
