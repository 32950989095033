import React, {
	type PointerEvent as ReactPointerEvent,
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react';
import { styled } from '@compiled/react';
import { B200, N200 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { GRID_BACKGROUND_COLOR } from '../../../constants.tsx';
import { useIsReadOnly } from '../../../../controllers/selectors/timeline-hooks.tsx';
import { useAnalytics } from '../../../../services/analytics/index.tsx';
import type { ResizeHandleProps, ResizeHandlePosition } from '../../../../types.tsx';
import { ZIG_ZAG_WIDTH } from './constants.tsx';

export const ResizeHandle = ({
	onResize,
	onResizeStop,
	position,
	isOneDirection,
	isDisabled,
	label,
}: ResizeHandleProps) => {
	const requestAnimationFrameId = useRef<number | null>(null);
	const startX = useRef(0);
	const { sendTimelineCardResizeEvent } = useAnalytics();
	const [isResizing, setIsResizing] = useState(false);
	const isReadOnly = useIsReadOnly();

	useEffect(
		() => () => {
			if (requestAnimationFrameId.current) {
				cancelAnimationFrame(requestAnimationFrameId.current);
			}
		},
		[],
	);

	const onPointerMove = useCallback(
		(e: PointerEvent) => {
			setIsResizing(true);

			if (requestAnimationFrameId.current) {
				cancelAnimationFrame(requestAnimationFrameId.current);
			}

			requestAnimationFrameId.current = requestAnimationFrame(() => {
				const deltaX = e.pageX - startX.current;
				onResize(deltaX);
			});
		},
		[onResize],
	);

	const onPointerUp = useCallback(
		(e: PointerEvent) => {
			setIsResizing(false);

			if (requestAnimationFrameId.current) {
				cancelAnimationFrame(requestAnimationFrameId.current);
			}

			const deltaX = e.pageX - startX.current;
			onResize(deltaX);
			onResizeStop(position);

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.removeEventListener('pointermove', onPointerMove);

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.removeEventListener('pointerup', onPointerUp);
			sendTimelineCardResizeEvent();
		},
		[onResize, onResizeStop, onPointerMove, sendTimelineCardResizeEvent, position],
	);

	const onPointerDown = useCallback(
		(e: ReactPointerEvent) => {
			if (isReadOnly || isDisabled) {
				return;
			}

			e.preventDefault();

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.addEventListener('pointermove', onPointerMove);

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.addEventListener('pointerup', onPointerUp);

			startX.current = e.pageX;
		},
		[onPointerMove, onPointerUp, isDisabled, isReadOnly],
	);

	return (
		<Tooltip
			content={(isOneDirection && !isResizing && !isReadOnly) || isDisabled ? label : null}
			position="mouse"
		>
			<ResizeHandleContainer
				id="pendo.timeline-grid.card-resize"
				role="separator"
				aria-orientation="vertical"
				position={position}
				isOneDirection={isOneDirection}
				isResizing={isResizing}
				onPointerDown={onPointerDown}
				isDisabled={isDisabled}
				isReadOnly={isReadOnly}
			/>
		</Tooltip>
	);
};

const leftZigZag =
	'conic-gradient(from 45deg at left, #0000, #000 1deg 89deg, #0000 90deg) 50%/100% 10px;';

const rightZigZag =
	'conic-gradient(from -135deg at right,#0000,#000 1deg 89deg,#0000 90deg) 50%/100% 10.00px;';

/* eslint-disable no-nested-ternary */
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ResizeHandleContainer = styled.div<{
	position: ResizeHandlePosition;
	isOneDirection: boolean;
	isResizing: boolean;
	isReadOnly: boolean;
	isDisabled: boolean;
}>({
	position: 'absolute',
	top: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	right: ({ position, isOneDirection, isResizing }) =>
		position === 'right' ? `${isOneDirection && !isResizing ? 6 : 0}px` : 'initial',
	bottom: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	left: ({ position, isOneDirection, isResizing }) =>
		position === 'left' ? `${isOneDirection && !isResizing ? 6 : 0}px` : 'initial',
	zIndex: 3,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: ({ isOneDirection, isResizing }) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		`${isOneDirection && !isResizing ? ZIG_ZAG_WIDTH : 10}px`,
	display: 'flex',
	alignItems: 'stretch',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	justifyContent: ({ position }) => (position === 'left' ? 'flex-end' : 'flex-start'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	cursor: ({ isOneDirection, position, isReadOnly, isDisabled }) =>
		isReadOnly
			? 'default'
			: isDisabled
				? 'not-allowed'
				: isOneDirection && position === 'left'
					? 'e-resize'
					: isOneDirection && position === 'right'
						? 'w-resize'
						: 'ew-resize',
	'&::before': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		display: ({ isOneDirection, isResizing }) => (isOneDirection && !isResizing ? 'block' : 'none'),
		position: 'absolute',
		top: 0,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		right: ({ position, isOneDirection, isResizing }) =>
			isOneDirection && !isResizing && position === 'right' ? 0 : 'auto',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		left: ({ position, isOneDirection, isResizing }) =>
			isOneDirection && !isResizing && position === 'left' ? 0 : 'auto',
		content: '',
		backgroundColor: token('color.border'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		width: `${ZIG_ZAG_WIDTH}px`,
		height: '100%',
		transition: 'background-color 0.2s',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		mask: ({ position, isOneDirection, isResizing }) =>
			isOneDirection && !isResizing ? (position === 'left' ? rightZigZag : leftZigZag) : 'none',
	},
	'&::after': {
		content: '',
		// size
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		width: ({ isOneDirection, isResizing }) =>
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			isOneDirection && !isResizing ? `${ZIG_ZAG_WIDTH}px` : '4px',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		height: ({ isOneDirection, isResizing }) => isOneDirection && !isResizing && '100%',
		// margin
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		marginTop: ({ isOneDirection, isResizing }) => (!(isOneDirection && !isResizing) ? '4px' : '0'),
		marginRight: 0,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		marginBottom: ({ isOneDirection, isResizing }) =>
			!(isOneDirection && !isResizing) ? '4px' : '0',
		marginLeft: 0,
		// else
		borderRadius: '2px',
		transition: 'background-color 0.2s',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		backgroundColor: ({ isOneDirection, isResizing }) =>
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			isOneDirection && !isResizing ? GRID_BACKGROUND_COLOR : 'transparent',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		position: ({ isOneDirection, isResizing }) =>
			isOneDirection && !isResizing ? 'absolute' : 'initial',
		top: 0,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		right: ({ position, isOneDirection, isResizing }) =>
			isOneDirection && !isResizing && position === 'right' ? '-1px' : 'auto',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		left: ({ position, isOneDirection, isResizing }) =>
			isOneDirection && !isResizing && position === 'left' ? '-1px' : 'auto',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		mask: ({ position, isOneDirection, isResizing }) =>
			isOneDirection && !isResizing ? (position === 'left' ? rightZigZag : leftZigZag) : 'none',
	},
	'&:hover, &:active': {
		'&::before': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
			backgroundColor: ({ isReadOnly, isDisabled }) =>
				isReadOnly ? token('color.border') : isDisabled ? N200 : B200,
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
			right: ({ position, isOneDirection, isResizing, isReadOnly }) =>
				isOneDirection && !isResizing && position === 'right' && !isReadOnly && '1px',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
			left: ({ position, isOneDirection, isResizing, isReadOnly }) =>
				isOneDirection && !isResizing && position === 'left' && !isReadOnly && '1px',
		},
		'&::after': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
			backgroundColor: ({ isReadOnly, isDisabled, isOneDirection, isResizing }) =>
				!(isOneDirection && !isResizing) && !isReadOnly
					? isDisabled
						? N200
						: B200
					: // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
						GRID_BACKGROUND_COLOR,
		},
	},
});
