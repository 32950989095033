import { useCallback } from 'react';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import {
	useFieldEditable,
	useFieldType,
} from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks.tsx';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import {
	useCanEditIssues,
	useCanModifyReporter,
} from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { REPORTER_FIELDKEY } from '@atlassian/jira-polaris-domain-field/src/field/constants.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { Value, LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import type { JPDExperience } from '@atlassian/jira-polaris-lib-analytics/src/common/utils/experience/types.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import {
	type CustomError,
	isBadRequest,
} from '@atlassian/jira-polaris-lib-errors/src/controllers/utils.tsx';
import {
	useProjectIdForIssue,
	useProjectIdsForIssues,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/project/hooks.tsx';
import { useCurrentViewSelectedIssueIdsAsList } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { useSortedIssueIds } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/sort-hooks.tsx';
import { useNotifications } from '@atlassian/jira-polaris-lib-notifications/src/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { fg } from '@atlassian/jira-feature-gating';
import { useIsFieldAssociatedToIssue } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { useIsIssueFromDifferentContainerByLocalId } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/issue-ids-hooks.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldType } from '@atlassian/jira-polaris-domain-field/src/field-types/types.tsx';
import { messages } from './messages.tsx';

export type EditableFieldProps = {
	isEditable: boolean;
	fieldKey: FieldKey;
	localIssueId: LocalIssueId;
	onUpdate: (inputValue: Value) => void;
};

export type FieldUpdater = (
	localIssueId: LocalIssueId,
	fieldKey: FieldKey,
	newValue: Value,
) => void;

export type EditableFieldContainerProps = {
	isEditable: boolean;
	localIssueId: LocalIssueId;
	fieldKey: string;
	onFieldUpdate: FieldUpdater;
};

const useCanEditSelectedIssues = () => {
	const selectedIssueIds = useCurrentViewSelectedIssueIdsAsList();
	const sortedIssueIds = useSortedIssueIds();
	const visibleSelectedIssueIds = sortedIssueIds.filter((id) => selectedIssueIds.includes(id));
	const isNotBulkEdit = selectedIssueIds.length === 0;

	const selectedIssueProjectIds = useProjectIdsForIssues(visibleSelectedIssueIds);
	const [canEditSelectedIssues] = useCanEditIssues({ projectIds: selectedIssueProjectIds });
	return isNotBulkEdit || canEditSelectedIssues;
};

const useFieldUpdateHandler = () => {
	const { updateFieldValueWithBulk } = useIssueActions();
	const { error } = useNotifications();
	const { formatMessage } = useIntl();
	const canEditSelectedIssues = fg('jpd-aurora-roadmap-inline-edit')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useCanEditSelectedIssues()
		: true;

	return useCallback(
		(
			localIssueId: LocalIssueId,
			fieldKey: FieldKey,
			newValue: Value,
			onSuccess?: () => void,
			onError?: (err?: CustomError) => void,
			onConnectionsUpdated?: () => void,
		) => {
			if (!canEditSelectedIssues && fg('jpd-aurora-roadmap-inline-edit')) {
				error({
					title: formatMessage(messages.noProjectPermissionsErrorTitle),
					description: formatMessage(messages.noProjectPermissionsErrorDescription),
				});
				return { filtered: false };
			}

			updateFieldValueWithBulk(
				localIssueId,
				fieldKey,
				newValue,
				onSuccess,
				onError,
				onConnectionsUpdated,
			);
		},
		[canEditSelectedIssues, error, formatMessage, updateFieldValueWithBulk],
	);
};

export const useFieldUpdate = (localIssueId: LocalIssueId, fieldKey: FieldKey) => {
	const onFieldUpdate = useFieldUpdateHandler();
	const fieldType = useFieldType(fieldKey);

	return useCallback(
		(newValue: Value, jpdExperience?: JPDExperience, onConnectionsUpdated?: () => void) => {
			const updateFieldExperience = jpdExperience || experience.listView.makeFieldUpdate();

			const onExperienceFailure = (err?: CustomError) => {
				if (isClientFetchError(err)) {
					return;
				}
				if (!err || !isBadRequest(err)) {
					updateFieldExperience.failure(err);
				}
			};

			updateFieldExperience.start();
			onFieldUpdate(
				localIssueId,
				fieldKey,
				newValue,
				updateFieldExperience.success,
				onExperienceFailure,
				onConnectionsUpdated,
			);
			fireCompoundAnalyticsEvent.ListView.fieldValueUpdatedByInlineEdit(fieldType);
		},
		[fieldKey, fieldType, localIssueId, onFieldUpdate],
	);
};

const NON_EDITABLE_FIELD_TYPES_FROM_DIFFERENT_CONTAINERS: FieldType[] = [
	FIELD_TYPES.ATLAS_PROJECT,
	FIELD_TYPES.ATLAS_PROJECT_STATUS,
	FIELD_TYPES.ATLAS_GOAL,
	FIELD_TYPES.PLATFORM_GOALS,
];

export const useIsEditable = (localIssueId: LocalIssueId, fieldKey: FieldKey): boolean => {
	const projectId = useProjectIdForIssue(localIssueId);
	const [canEditIssues] = useCanEditIssues({ projectId });
	const fieldEditable = useFieldEditable(fieldKey);
	const fieldType = useFieldType(fieldKey);
	const [hasModifyReportPermission] = useCanModifyReporter();
	const isIssueFromDifferentContainer = useIsIssueFromDifferentContainerByLocalId(localIssueId);
	const isFieldAssociatedToIssue = fg('jpd-aurora-roadmap-inline-edit')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useIsFieldAssociatedToIssue(fieldKey, localIssueId)
		: true;

	let isEditable = canEditIssues && fieldEditable && isFieldAssociatedToIssue;
	if (fieldKey === REPORTER_FIELDKEY) {
		isEditable = hasModifyReportPermission && isEditable;
	}

	if (
		isIssueFromDifferentContainer &&
		fieldType &&
		NON_EDITABLE_FIELD_TYPES_FROM_DIFFERENT_CONTAINERS.includes(fieldType)
	) {
		isEditable = false;
	}

	return isEditable;
};
