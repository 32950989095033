// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import type { CrossProjectViewTourSteps, State } from '../../../types.tsx';

export const setCrossProjectViewTourSpotlightStep =
	(step: CrossProjectViewTourSteps | null): Action<State> =>
	({ getState, setState }) => {
		const state = getState();

		setState({
			...state,
			crossProjectView: {
				...state.crossProjectView,
				tourSpotlightStep: step,
			},
		});
	};
