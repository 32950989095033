// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types.tsx';

export const closeDialog =
	(): Action<State, Props> =>
	({ setState }) => {
		setState({
			isLoading: false,
			error: null,
			config: null,
			properties: null,
			snippetData: null,
			url: null,
			oauthClientId: null,
			isSaving: false,
			saveError: null,
		});
	};
