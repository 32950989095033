import React from 'react';
import { useEmoji } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { Filter } from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import {
	useFieldTypeIcon,
	useFieldEmoji,
} from '../../../../controllers/field/selectors/field-hooks.tsx';
import { useOptionsWithLabels, VariableOptionRenderer } from '../options/index.tsx';
import { SelectFilterComponent } from '../select/index.tsx';

export type MultiOptionComponentProps = {
	isLastFilter: boolean;
	field: Field;
	filter: Filter;
	setFieldFilter: (arg1: (string | undefined)[]) => void;
	options: {
		groupIdentity: string | undefined;
		value?: unknown;
	}[];
	defaultOpen?: boolean;
	isDisabled?: boolean;
	isUsingCustomColor?: boolean;
};

export const MultiOptionComponent = ({
	isLastFilter,
	defaultOpen,
	field,
	filter,
	setFieldFilter,
	options,
	isDisabled,
	isUsingCustomColor,
}: MultiOptionComponentProps) => {
	const optionsWithLabels = useOptionsWithLabels(field, options);

	const componentOptions = optionsWithLabels.map((option) => ({
		label: option.label,
		id: option.groupIdentity,
		OptionRenderComponent: () => (
			<VariableOptionRenderer
				field={field}
				groupIdentity={option.groupIdentity}
				value={option.value}
			/>
		),
	}));

	const fieldTypeIcon = useFieldTypeIcon(field.key, undefined);
	const emojiId = useFieldEmoji(field.key);
	const emoji = useEmoji(emojiId);

	return (
		<SelectFilterComponent
			label={field.label}
			fieldType={field.type}
			fieldTypeIcon={fieldTypeIcon}
			emoji={emoji}
			selected={filter.type === 'FIELD' ? filter.values.map(({ stringValue }) => stringValue) : []}
			onChange={setFieldFilter}
			options={componentOptions}
			isLastFilter={isLastFilter}
			defaultOpen={defaultOpen}
			isDisabled={isDisabled}
			isUsingCustomColor={isUsingCustomColor}
		/>
	);
};
