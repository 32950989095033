const ATLAS_INTEGRATION_ENABLED = 'JPD_ATLAS_INTERGRATION_ENABLED';
const ATLAS_SITE_CLOUD_ID = 'jpd-atlas-site-cloud-id';
const SUBMIT_IDEA_FORM_VIEW = 'JPD_SUBMIT_IDEA_FORM_VIEW';
const SUBMIT_IDEA_FORM_DESCRIPTION_TEMPLATE = 'JPD_SUBMIT_IDEA_FORM_DESCRIPTION_TEMPLATE';
const SUBMIT_IDEA_FORM_MANDATORY_FIELDS = 'JPD_SUBMIT_IDEA_FORM_MANDATORY_FIELDS';
const IDEA_CREATION_BY_CONTRIBUTORS = 'idea-creation-by-contributors';

export type ProjectProperties =
	| typeof ATLAS_INTEGRATION_ENABLED
	| typeof ATLAS_SITE_CLOUD_ID
	| typeof SUBMIT_IDEA_FORM_VIEW
	| typeof SUBMIT_IDEA_FORM_DESCRIPTION_TEMPLATE
	| typeof SUBMIT_IDEA_FORM_MANDATORY_FIELDS
	| typeof IDEA_CREATION_BY_CONTRIBUTORS;

export type ProjectPropertiesMap = {
	ATLAS_INTEGRATION_ENABLED: typeof ATLAS_INTEGRATION_ENABLED;
	ATLAS_SITE_CLOUD_ID: typeof ATLAS_SITE_CLOUD_ID;
	SUBMIT_IDEA_FORM_VIEW: typeof SUBMIT_IDEA_FORM_VIEW;
	SUBMIT_IDEA_FORM_DESCRIPTION_TEMPLATE: typeof SUBMIT_IDEA_FORM_DESCRIPTION_TEMPLATE;
	SUBMIT_IDEA_FORM_MANDATORY_FIELDS: typeof SUBMIT_IDEA_FORM_MANDATORY_FIELDS;
	IDEA_CREATION_BY_CONTRIBUTORS: typeof IDEA_CREATION_BY_CONTRIBUTORS;
};

export const PROJECT_PROPERTIES: ProjectPropertiesMap = {
	ATLAS_INTEGRATION_ENABLED,
	ATLAS_SITE_CLOUD_ID,
	SUBMIT_IDEA_FORM_VIEW,
	SUBMIT_IDEA_FORM_DESCRIPTION_TEMPLATE,
	SUBMIT_IDEA_FORM_MANDATORY_FIELDS,
	IDEA_CREATION_BY_CONTRIBUTORS,
};

export type RemoteProperty<T> = {
	isLoading: boolean;
	error: Error | null;
	value: T | undefined;
};

export type State = {
	meta: {
		projectId?: string;
	};
	properties: {
		[PROJECT_PROPERTIES.IDEA_CREATION_BY_CONTRIBUTORS]?: RemoteProperty<string>;
		[PROJECT_PROPERTIES.SUBMIT_IDEA_FORM_VIEW]?: RemoteProperty<string>;
		[PROJECT_PROPERTIES.SUBMIT_IDEA_FORM_DESCRIPTION_TEMPLATE]?: RemoteProperty<string>;
		[PROJECT_PROPERTIES.SUBMIT_IDEA_FORM_MANDATORY_FIELDS]?: RemoteProperty<boolean>;
		[PROJECT_PROPERTIES.ATLAS_INTEGRATION_ENABLED]?: RemoteProperty<boolean>;
		[PROJECT_PROPERTIES.ATLAS_SITE_CLOUD_ID]?: RemoteProperty<string>;
	};
};
