import React, { useEffect, useState } from 'react';
import { IconButton } from '@atlaskit/button/new';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import TrashIcon from '@atlaskit/icon/core/migration/delete--trash';
import MoreIcon from '@atlaskit/icon/core/migration/show-more-horizontal--more';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { CollectionDeleteModal } from '@atlassian/jira-polaris-component-collection-delete-modal/src/ui/index.tsx';
import { RightSidebarHeader } from '@atlassian/jira-polaris-lib-right-sidebar/src/ui/header/index.tsx';
import { useCollectionName } from '@atlassian/jira-polaris-component-collections/src/controllers/collections/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useActions, useCollectionUUID, useViews } from '../../../controllers/index.tsx';
import messages from './messages.tsx';
import { PermissionsSettingsButton } from './permissions-modal/index.tsx';

export const DefaultSidebarContentHeader = () => {
	const { formatMessage } = useIntl();
	const [isDeleteConfirmationDialogOpen, setIsDeleteConfirmationDialogOpen] = useState(false);
	const views = useViews();
	const collectionUUID = useCollectionUUID();
	const [isViewsLoading, setIsViewsLoading] = useState(false);
	const { onClose, onAfterCollectionDelete, onBeforeCollectionDelete } = useActions();
	const collectionName = useCollectionName({ collectionUUID });

	useEffect(() => {
		if (collectionUUID && onBeforeCollectionDelete && isDeleteConfirmationDialogOpen) {
			setIsViewsLoading(true);
			onBeforeCollectionDelete().then(() => setIsViewsLoading(false));
		}
	}, [collectionUUID, onBeforeCollectionDelete, isDeleteConfirmationDialogOpen]);

	const handleDelete = () => {
		onAfterCollectionDelete?.();
		if (fg('polaris_close_sidebar_on_roadmap_deletion')) {
			onClose();
		}
	};

	if (!collectionUUID) {
		return null;
	}

	return (
		<>
			<RightSidebarHeader title={formatMessage(messages.title)} onClose={onClose}>
				<PermissionsSettingsButton collectionUUID={collectionUUID} />
				<DropdownMenu<HTMLButtonElement>
					trigger={({ triggerRef, ...props }) => (
						<IconButton
							{...props}
							icon={MoreIcon}
							label={formatMessage(messages.moreButtonLabel)}
							ref={triggerRef}
							isTooltipDisabled={props.isSelected}
							appearance="subtle"
							testId="polaris-component-collection-configure-sidebar.ui.default-content.header.more-actions-button"
						/>
					)}
					shouldRenderToParent
					spacing="compact"
				>
					<DropdownItemGroup>
						<DropdownItem
							testId="polaris-component-collection-configure-sidebar.ui.default-content.header.dropdown-item-delete-collection"
							elemBefore={
								<TrashIcon
									label=""
									color={token('color.icon')}
									LEGACY_primaryColor={token('color.icon')}
								/>
							}
							onClick={() => setIsDeleteConfirmationDialogOpen(true)}
						>
							{formatMessage(messages.deleteRoadmapOptionLabel)}
						</DropdownItem>
					</DropdownItemGroup>
				</DropdownMenu>
			</RightSidebarHeader>
			{isDeleteConfirmationDialogOpen && (
				<CollectionDeleteModal
					isLoading={isViewsLoading}
					collectionUUID={collectionUUID}
					onClose={() => setIsDeleteConfirmationDialogOpen(false)}
					onDelete={handleDelete}
					views={views}
					collectionName={collectionName}
				/>
			)}
		</>
	);
};
