import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	buttonLabel: {
		id: 'polaris-ideas.view-controls.manage-bar.delete-ideas.button-label',
		defaultMessage: 'Delete',
		description: 'Button label to permanently delete selected ideas',
	},
	notAllowedTooltip: {
		id: 'polaris-ideas.view-controls.manage-bar.delete-ideas.not-allowed-tooltip',
		defaultMessage: 'You don’t have permission to delete ideas. Contact your project admin.',
		description:
			'Tooltip message when user don’t have some selected ideas’s project’s delete ideas permission',
	},
});
