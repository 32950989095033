/** @jsx jsx */
import React, { type ReactNode } from 'react';
import { css, jsx } from '@compiled/react';
import { type EmojiDescription, Emoji } from '@atlaskit/emoji';
import { Box, Flex, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import type { NumericFieldFilterValue } from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { EmojiWrapper } from '../../../../field-config/item/emoji-wrapper/index.tsx';
import { filters, filtersRefresh } from '../utils/index.tsx';

export const SelectionLabel = ({ values }: { values: NumericFieldFilterValue[] }) => {
	const { formatMessage } = useIntl();
	const activeFilter = fg('polaris-issue-terminology-refresh')
		? filtersRefresh.find((filter) => filter.isChecked(values))
		: filters.find((filter) => filter.isChecked(values));
	if (activeFilter?.activeLabel === undefined) {
		return null;
	}
	return <>{formatMessage(activeFilter.activeLabel)}</>;
};

export const FilterButtonLabel = ({
	label,
	values,
	fieldTypeIcon,
	emoji,
}: {
	label: string;
	values: NumericFieldFilterValue[];
	fieldTypeIcon: ReactNode;
	emoji?: EmojiDescription;
}) => (
	<Flex alignItems="center">
		{emoji ? (
			<EmojiWrapper>
				<Emoji emoji={emoji} fitToHeight={16} showTooltip />
			</EmojiWrapper>
		) : (
			<Flex xcss={iconContainerStyles}>{fieldTypeIcon}</Flex>
		)}
		<div css={labelStyles}>{label}</div>
		{values.length > 0 && (
			<Box xcss={filterLabelValueStyles}>
				<SelectionLabel values={values} />
			</Box>
		)}
	</Flex>
);

const filterLabelValueStyles = xcss({
	marginInlineStart: 'space.050',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	maxWidth: '260px',
});

const iconContainerStyles = xcss({
	paddingInlineEnd: 'space.100',
	alignItems: 'center',
});

const labelStyles = css({
	fontWeight: token('font.weight.bold'),
});
