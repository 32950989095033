import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	errorTitle: {
		id: 'polaris-component-issue-types.error-title',
		defaultMessage: 'Something went wrong.',
		description: 'Flag title to communicate an error.',
	},
	errorDescription: {
		id: 'polaris-component-issue-types.error-description',
		defaultMessage:
			'We had difficulties to fetch some data from the server. Please refresh the page.',
		description: 'Flag description for an error occurring while loading data from the server',
	},
});
