/** @jsx jsx */
import React, { memo, useCallback, useMemo, useState, useEffect } from 'react';
import { jsx, css, styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import type { ColumnId } from '../../common/types/index.tsx';
import { CLASS_HEADER_ROW, CLASS_HEADER_CELL } from '../../common/ui/constants/index.tsx';
import { PRESENTATIONAL_FIELDKEYS } from '../../constants.tsx';
import { useFixedColumns } from '../../controllers/selectors/columns-hooks.tsx';
import { useHeaderComponent } from '../../controllers/selectors/components-hooks.tsx';
import { useColumnConfigurationByColumnId } from '../../controllers/selectors/items-hooks.tsx';
import { useIsColumnReorderingEnabled } from '../../controllers/selectors/operations-hooks.tsx';
import {
	useInvisibleColumnPlaceholderWidthLeft,
	useIsFramedDisplayMode,
	useVisibleColumnIds,
} from '../../controllers/selectors/ui-hooks.tsx';
import { SelectAllCheckBox } from '../cell/selection-checkbox/index.tsx';
import { ROW_BORDER_RADIUS, DEFAULT_HEADER_HEIGHT, borderColor } from '../constants.tsx';
import { useStickyColumnLeftPosition } from '../utils/columns.tsx';
import { CollapseAllButton } from './collapse-all/index.tsx';
import { ColumnDnd } from './column-dnd/index.tsx';
import { ColumnResizer } from './column-resizer/index.tsx';

type HeaderCellProps = {
	columnId: ColumnId;
	isFixed?: boolean;
	left?: number;
};

export const HeaderCell = memo<HeaderCellProps>(({ columnId }: HeaderCellProps) => {
	const Header = useHeaderComponent();

	const isColumnReorderingEnabled = useIsColumnReorderingEnabled();

	const header = useMemo(() => <Header columnId={columnId} />, [Header, columnId]);

	if (columnId === PRESENTATIONAL_FIELDKEYS.DND) {
		return <CollapseAllButton />;
	}

	if (columnId === PRESENTATIONAL_FIELDKEYS.SELECT) {
		return <SelectAllCheckBox />;
	}

	const isColDndAllowed = isColumnReorderingEnabled && columnId !== PRESENTATIONAL_FIELDKEYS.ADD;
	if (isColDndAllowed) {
		return <ColumnDnd columnId={columnId}>{header}</ColumnDnd>;
	}

	return header;
});

const HeaderCellSized = memo<HeaderCellProps>(({ columnId, left }: HeaderCellProps) => {
	const colConfig = useColumnConfigurationByColumnId(columnId);
	const [colWidth, setColWidth] = useState(colConfig?.width);

	useEffect(() => {
		setColWidth(colConfig?.width);
	}, [colConfig?.width]);

	const handleColumnResize = useCallback(
		(width: number) => {
			setColWidth(width);
		},
		[setColWidth],
	);

	const className = useMemo(
		() => `${CLASS_HEADER_CELL} ${colConfig?.headerClassName}`,
		[colConfig],
	);

	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<CellWrapper role="gridcell" className={className} $width={colWidth} $left={left}>
			<HeaderCell columnId={columnId} />
			{colConfig?.resizable && (
				<ColumnResizer
					onResize={handleColumnResize}
					columnId={columnId}
					minWidth={colConfig?.minWidth}
					width={colWidth}
				/>
			)}
		</CellWrapper>
	);
});

const FixedHeaderCellSized = memo<HeaderCellProps>(({ columnId }: HeaderCellProps) => {
	const left = useStickyColumnLeftPosition(columnId);

	return <HeaderCellSized key={columnId} columnId={columnId} left={left} />;
});

export const HeaderRow = memo(() => {
	const placeholderWidthLeft = useInvisibleColumnPlaceholderWidthLeft();
	const isFramedDisplayMode = useIsFramedDisplayMode();

	const fixedColumns = useFixedColumns();
	const fixedCells = useMemo(
		() =>
			fixedColumns.map((columnId) => <FixedHeaderCellSized key={columnId} columnId={columnId} />),
		[fixedColumns],
	);

	const visibleColumns = useVisibleColumnIds();
	const visibleCells = useMemo(
		() => visibleColumns.map((columnId) => <HeaderCellSized key={columnId} columnId={columnId} />),
		[visibleColumns],
	);

	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<HeaderRowWrapper role="row" className={CLASS_HEADER_ROW}>
			<RowContainer>
				{fixedCells}
				{placeholderWidthLeft > 0 && <PlaceholderLeft width={placeholderWidthLeft} />}
				{visibleCells}
				{!isFramedDisplayMode && <PlaceholderRight />}
			</RowContainer>
		</HeaderRowWrapper>
	);
});

const PlaceholderLeft = memo(({ width }: { width: number }) => {
	// eslint-disable-next-line jira/react/no-style-attribute
	return <div css={placeholderLeftStyles} style={{ width: `${width}px` }} />;
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RowContainer = styled.div({
	display: 'flex',
	width: '100%',
	height: '100%',
	backgroundColor: 'inherit',
	alignItems: 'stretch',
	alignSelf: 'flex-start',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderTopLeftRadius: `${ROW_BORDER_RADIUS}px`,
	zIndex: 50,
});

const placeholderLeftStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderTop: `1px solid ${borderColor}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderBottom: `1px solid ${borderColor}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PlaceholderRight = styled.div({
	flex: 1,
	boxSizing: 'border-box',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderTop: `1px solid ${borderColor}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderBottom: `1px solid ${borderColor}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeaderRowWrapper = styled.div({
	position: 'sticky',
	top: 0,
	zIndex: 200,
	display: 'flex',
	flex: 1,
	alignItems: 'center',
	boxSizing: 'border-box',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${DEFAULT_HEADER_HEIGHT}px`,
	backgroundColor: token('elevation.surface'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CellWrapper = styled.div<{ $width?: number; $left?: number }>({
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: ({ $width }) => `${$width}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	left: ({ $left }) => $left !== undefined && `${$left}px`,
});
