import { useCallback, useMemo } from 'react';
import keyBy from 'lodash/keyBy';
import type { Edge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/types';
import { useFieldType } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks.tsx';
import { useSortingAwareIssueRankingActions } from '@atlassian/jira-polaris-common/src/controllers/idea-ranking/index.tsx';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import {
	useLocalIssueIdsByGroupIdentity,
	useUnfilteredLocalIssueIdsByGroupIdentity,
	useLocalIssueIdsByCell,
	useUnfilteredLocalIssueIdsByCell,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/grouping-hooks.tsx';
import type { IssuesByCell } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/grouping.tsx';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import type { Field, FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import {
	COLUMN_DROPPABLE_ID,
	ROW_DROPPABLE_ID,
	EMPTY_VALUE_ID,
	getGroupIdentityForDroppableId,
	getGroupIdentityPairForDroppableId,
	type ExtendedOption,
} from '../../../../common/utils/board.tsx';
import { useGroupOptions } from '../../common/utils/group-options.tsx';
import { useExtendedVerticalGroupOptions } from '../../common/utils/vertical-group-options.tsx';
import type { DraggableCardData, DraggableLocation } from './draggable.types.tsx';

const getIdsForColumn = (
	idsByColumn: {
		empty: Array<string> | undefined;
		groups: {
			[key: string]: Array<string>;
		};
	},
	columnId: string,
): string[] | undefined => {
	if (columnId === EMPTY_VALUE_ID && idsByColumn.empty !== undefined) {
		return idsByColumn.empty;
	}

	const realColumnId = getGroupIdentityForDroppableId(columnId);
	return realColumnId !== undefined && idsByColumn.groups[realColumnId]
		? idsByColumn.groups[realColumnId]
		: undefined;
};

const getIdForColumn = (
	idsByColumn: {
		empty: Array<string> | undefined;
		groups: {
			[key: string]: Array<string>;
		};
	},
	columnId: string,
	index: number,
): string | undefined => getIdsForColumn(idsByColumn, columnId)?.[index];

const getIdsForCell = (idsByCell: IssuesByCell, cellId: string): string[] | undefined => {
	const [realRowId, realColumnId] = getGroupIdentityPairForDroppableId(cellId);
	if (realColumnId === undefined && realRowId !== undefined) {
		return idsByCell.xEmpty[realRowId];
	}
	if (realRowId === undefined && realColumnId !== undefined) {
		return idsByCell.yEmpty[realColumnId];
	}
	if (realColumnId !== undefined && realRowId !== undefined) {
		return idsByCell.groups[realColumnId]?.[realRowId];
	}
	return idsByCell.empty;
};

const getIdForCell = (idsByCell: IssuesByCell, cellId: string, index: number): string | undefined =>
	getIdsForCell(idsByCell, cellId)?.[index];

/**
 * handler for dnd behaviour of cards
 */
export const useCardDropHandler = (
	fieldKey: FieldKey,
	extendedOptions: ExtendedOption<unknown>[],
) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const { updateField, execWithIssueAnalyticsData } = useIssueActions();
	const { rankBefore, rankAfter } = useSortingAwareIssueRankingActions();

	const idsByColumn = useLocalIssueIdsByGroupIdentity(fieldKey);
	const unfilteredIdsByColumn = useUnfilteredLocalIssueIdsByGroupIdentity(fieldKey);
	const fieldType = useFieldType(fieldKey);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const stableExtendedOptions = useMemo(() => extendedOptions, [JSON.stringify(extendedOptions)]);

	return useCallback(
		({
			source,
			destination,
			afterItemIndex,
			beforeItemIndex,
		}: {
			source: DraggableLocation;
			destination: DraggableLocation | null | undefined;
			afterItemIndex?: number;
			beforeItemIndex?: number;
		}) => {
			const issueId = getIdForColumn(idsByColumn, source.droppableId, source.index);
			if (issueId === undefined) {
				return;
			}

			execWithIssueAnalyticsData(issueId, (issueAnalyticsData) => {
				fireCompoundAnalyticsEvent.BoardView.cardDropped(
					createAnalyticsEvent({}),
					issueAnalyticsData,
				);
			});
			// value change (drop in different column)
			if (
				destination?.droppableId !== undefined &&
				source.droppableId !== destination.droppableId
			) {
				const optionsByGroupIdentity = keyBy(
					stableExtendedOptions,
					({ groupIdentity }) => groupIdentity,
				);

				const sourceGroupIdentity = getGroupIdentityForDroppableId(source.droppableId);
				const sourceOption =
					sourceGroupIdentity !== undefined && sourceGroupIdentity in optionsByGroupIdentity
						? optionsByGroupIdentity[sourceGroupIdentity]
						: undefined;
				const sourceValue =
					sourceOption && typeof sourceOption === 'object' && 'value' in sourceOption
						? sourceOption.value
						: undefined;

				const destinationGroupIdentity = getGroupIdentityForDroppableId(destination.droppableId);
				if (destinationGroupIdentity === undefined) {
					updateField(fieldKey, false, issueId, undefined, sourceValue); // clear
					fireCompoundAnalyticsEvent.BoardView.fieldValueUpdatedByDragBetweenColumns(fieldType);
				} else {
					const targetOption = optionsByGroupIdentity[destinationGroupIdentity];
					const targetValue =
						typeof targetOption === 'object' && 'value' in targetOption
							? targetOption.value
							: undefined;
					updateField(fieldKey, true, issueId, targetValue, sourceValue);
					fireCompoundAnalyticsEvent.BoardView.fieldValueUpdatedByDragBetweenColumns(fieldType);
				}
			}

			// TODO: figure out when this was used
			if (afterItemIndex !== undefined) {
				const afterDestinationId = destination
					? getIdForColumn(idsByColumn, destination.droppableId, afterItemIndex)
					: undefined;
				if (afterItemIndex === -1) {
					const unfilteredIds =
						(destination && getIdsForColumn(unfilteredIdsByColumn, destination.droppableId)) || [];
					const beforeDestinationId = unfilteredIds[0];
					if (beforeDestinationId && beforeDestinationId !== issueId) {
						rankBefore(issueId, beforeDestinationId);
					}
					return;
				}
				if (afterDestinationId !== undefined && afterDestinationId !== issueId) {
					rankAfter(issueId, afterDestinationId);
					return;
				}
			}

			// TODO: figure out when this was used
			if (beforeItemIndex !== undefined) {
				const beforeDestinationId = destination
					? getIdForColumn(idsByColumn, destination.droppableId, beforeItemIndex)
					: undefined;
				if (beforeItemIndex === -2) {
					const unfilteredIds =
						(destination && getIdsForColumn(unfilteredIdsByColumn, destination.droppableId)) || [];
					const afterDestinationId = unfilteredIds[unfilteredIds.length - 1];
					if (afterDestinationId && afterDestinationId !== issueId) {
						rankAfter(issueId, afterDestinationId);
						return;
					}
				}
				if (beforeDestinationId !== undefined && beforeDestinationId !== issueId) {
					rankBefore(issueId, beforeDestinationId);
					return;
				}
			}

			// rank change (drop in different position on same column, or new position in different column)
			const destinationId = destination
				? getIdForColumn(idsByColumn, destination.droppableId, destination.index)
				: undefined;

			if (destination && destinationId === undefined) {
				// drag to another column on the last position (index out of range)
				const issueIds = getIdsForColumn(idsByColumn, destination.droppableId) || [];

				if (issueIds.length === 0) {
					return;
				}

				const lastIssuedId = issueIds.length === 1 ? issueIds[0] : issueIds[issueIds.length - 1];
				rankAfter(issueId, lastIssuedId);
				return;
			}

			if (destination && destinationId !== undefined && issueId !== destinationId) {
				// do not move issue when it is dropped on the same position as it was before
				if (source.index === destination.index && source.droppableId === destination.droppableId) {
					return;
				}

				if (source.index < destination.index && source.droppableId === destination.droppableId) {
					// drag down - rank after (only when drop target is in same column)
					rankAfter(issueId, destinationId);
				} else {
					// drag up - rank before
					rankBefore(issueId, destinationId);
				}
			}
		},
		[
			createAnalyticsEvent,
			execWithIssueAnalyticsData,
			stableExtendedOptions,
			fieldKey,
			fieldType,
			idsByColumn,
			rankAfter,
			rankBefore,
			unfilteredIdsByColumn,
			updateField,
		],
	);
};

/**
 * handler for dnd behaviour of columns
 */
const useColumnDropHandler = (field: Field) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const extendedOptions = useGroupOptions(field);
	const { setGroupValues } = useViewActions();

	return useCallback(
		({ source, destination }: { source: DraggableLocation; destination?: DraggableLocation }) => {
			if (!destination || destination.index === source.index) {
				return;
			}

			const newOrder: ExtendedOption<unknown>[] = [...extendedOptions];
			const sourceItem = newOrder[source.index];
			newOrder.splice(source.index, 1);
			newOrder.splice(destination.index, 0, sourceItem);

			const newSortedGroupOptions = newOrder.map((option: ExtendedOption<unknown>) => ({
				id: option.groupIdentity,
			}));

			setGroupValues(newSortedGroupOptions);

			fireCompoundAnalyticsEvent.BoardView.columnDropped(createAnalyticsEvent({}), {
				issueFieldKey: field.key,
				fieldValueId: sourceItem.groupIdentity,
			});
		},
		[createAnalyticsEvent, extendedOptions, field.key, setGroupValues],
	);
};

const useRowDropHandler = (field: Field) => {
	const extendedOptions = useExtendedVerticalGroupOptions(field);
	const { setVerticalGroupValues } = useViewActions();

	return useCallback(
		({ source, destination }: { source: DraggableLocation; destination?: DraggableLocation }) => {
			if (!destination) {
				return;
			}

			const newOrder: ExtendedOption<unknown>[] = [...extendedOptions];
			const sourceItem = newOrder[source.index];
			newOrder.splice(source.index, 1);
			newOrder.splice(destination.index, 0, sourceItem);

			const newSortedGroupOptions = newOrder.map((option: ExtendedOption<unknown>) => ({
				id: option.groupIdentity ?? EMPTY_VALUE_ID,
			}));

			setVerticalGroupValues(newSortedGroupOptions);
		},
		[extendedOptions, setVerticalGroupValues],
	);
};

/**
 * Helper hook to create an onDragEnd function. Deals with reordering and assigning
 * new values when dragging into different columns
 */
export const useDropCallback = (field: Field, extendedOptions: ExtendedOption<unknown>[]) => {
	const cardDropHandler = useCardDropHandler(field.key, extendedOptions);
	const columnDropHandler = useColumnDropHandler(field);

	return useCallback(
		(
			source: DraggableLocation,
			destination?: DraggableLocation,
			afterItemIndex?: number,
			beforeItemIndex?: number,
		) => {
			if (source.droppableId === COLUMN_DROPPABLE_ID) {
				return columnDropHandler({ source, destination });
			}
			return cardDropHandler({ source, destination, afterItemIndex, beforeItemIndex });
		},
		[cardDropHandler, columnDropHandler],
	);
};

export const useSwimlanesCardDropHandler = (
	fieldKey: FieldKey,
	verticalFieldKey: FieldKey,
	extendedOptions: ExtendedOption<unknown>[],
	extendedVerticalOptions: ExtendedOption<unknown>[],
) => {
	const { updateField, execWithIssueAnalyticsData } = useIssueActions();
	const { rankBefore, rankAfter } = useSortingAwareIssueRankingActions();
	const idsByCell = useLocalIssueIdsByCell(fieldKey, verticalFieldKey);
	const unfilteredIdsByCell = useUnfilteredLocalIssueIdsByCell(fieldKey, verticalFieldKey);
	const fieldType = useFieldType(fieldKey);

	const { createAnalyticsEvent } = useAnalyticsEvents();

	return useCallback(
		({
			source,
			destination,
			afterItemIndex,
			beforeItemIndex,
		}: {
			source: DraggableLocation;
			destination: DraggableLocation | null | undefined;
			afterItemIndex?: number;
			beforeItemIndex?: number;
		}) => {
			const issueId = getIdForCell(idsByCell, source.droppableId, source.index);
			execWithIssueAnalyticsData(issueId, (issueAnalyticsData) => {
				fireCompoundAnalyticsEvent.BoardView.cardDropped(
					createAnalyticsEvent({}),
					issueAnalyticsData,
				);
			});

			// value change (drop in different column)
			if (
				issueId !== undefined &&
				destination?.droppableId !== undefined &&
				source.droppableId !== destination.droppableId
			) {
				const [destinationVerticalGroupIdentity, destinationGroupIdentity] =
					getGroupIdentityPairForDroppableId(destination.droppableId);

				const [sourceVerticalGroupIdentity, sourceGroupIdentity] =
					getGroupIdentityPairForDroppableId(source.droppableId);

				if (destinationGroupIdentity !== sourceGroupIdentity) {
					const optionsByGroupIdentity = keyBy(
						extendedOptions,
						({ groupIdentity }) => groupIdentity,
					);
					const sourceOption =
						sourceGroupIdentity !== undefined && sourceGroupIdentity in optionsByGroupIdentity
							? optionsByGroupIdentity[sourceGroupIdentity]
							: undefined;
					const sourceValue =
						sourceOption && typeof sourceOption === 'object' && 'value' in sourceOption
							? sourceOption.value
							: undefined;
					if (destinationGroupIdentity === undefined) {
						updateField(fieldKey, false, issueId, undefined, sourceValue); // clear
						fireCompoundAnalyticsEvent.BoardView.fieldValueUpdatedByDragBetweenColumns(fieldType);
					} else {
						const targetOption = optionsByGroupIdentity[destinationGroupIdentity];
						const targetValue =
							typeof targetOption === 'object' && 'value' in targetOption
								? targetOption.value
								: undefined;

						updateField(fieldKey, true, issueId, targetValue, sourceValue);
						fireCompoundAnalyticsEvent.BoardView.fieldValueUpdatedByDragBetweenColumns(fieldType);
					}
				}

				if (destinationVerticalGroupIdentity !== sourceVerticalGroupIdentity) {
					const verticalOptionsByGroupIdentity = keyBy(
						extendedVerticalOptions,
						({ groupIdentity }) => groupIdentity,
					);
					const sourceOption =
						sourceVerticalGroupIdentity !== undefined &&
						sourceVerticalGroupIdentity in verticalOptionsByGroupIdentity
							? verticalOptionsByGroupIdentity[sourceVerticalGroupIdentity]
							: undefined;
					const sourceValue =
						sourceOption && typeof sourceOption === 'object' && 'value' in sourceOption
							? sourceOption.value
							: undefined;
					if (destinationVerticalGroupIdentity === undefined) {
						updateField(verticalFieldKey, false, issueId, undefined, sourceValue); // clear
						fireCompoundAnalyticsEvent.BoardView.fieldValueUpdatedByDragBetweenSwimlanes(fieldType);
					} else {
						const destinationOption =
							verticalOptionsByGroupIdentity[destinationVerticalGroupIdentity];
						const targetValue =
							destinationOption &&
							typeof destinationOption === 'object' &&
							'value' in destinationOption
								? destinationOption.value
								: undefined;

						updateField(verticalFieldKey, true, issueId, targetValue, sourceValue);
						fireCompoundAnalyticsEvent.BoardView.fieldValueUpdatedByDragBetweenSwimlanes(fieldType);
					}
				}
			}

			if (issueId === undefined) {
				return;
			}

			// rank change (drop in different position on same column, or new position in different column)

			if (afterItemIndex !== undefined) {
				const afterDestinationId = destination
					? getIdForCell(idsByCell, destination.droppableId, afterItemIndex)
					: undefined;
				if (afterItemIndex === -1) {
					const unfilteredIds =
						(destination && getIdsForCell(unfilteredIdsByCell, destination.droppableId)) || [];
					const beforeDestinationId = unfilteredIds[0];
					if (beforeDestinationId && beforeDestinationId !== issueId) {
						rankBefore(issueId, beforeDestinationId);
					}
					return;
				}
				if (afterDestinationId !== undefined && afterDestinationId !== issueId) {
					rankAfter(issueId, afterDestinationId);
					return;
				}
			}

			if (beforeItemIndex !== undefined) {
				const beforeDestinationId = destination
					? getIdForCell(idsByCell, destination.droppableId, beforeItemIndex)
					: undefined;
				if (beforeItemIndex === -2) {
					const unfilteredIds =
						(destination && getIdsForCell(unfilteredIdsByCell, destination.droppableId)) || [];
					const afterDestinationId = unfilteredIds[unfilteredIds.length - 1];
					if (afterDestinationId && afterDestinationId !== issueId) {
						rankAfter(issueId, afterDestinationId);
						return;
					}
				}
				if (beforeDestinationId !== undefined && beforeDestinationId !== issueId) {
					rankBefore(issueId, beforeDestinationId);
					return;
				}
			}

			if (
				destination &&
				source.index === destination.index &&
				source.droppableId === destination.droppableId
			) {
				return;
			}

			const destinationId = destination
				? getIdForCell(idsByCell, destination.droppableId, destination.index)
				: undefined;

			if (destination && destinationId === undefined) {
				// drag to another column on the last position (index out of range)
				const issueIds = getIdsForCell(idsByCell, destination.droppableId) || [];

				if (issueIds.length === 0) {
					return;
				}

				const lastIssuedId = issueIds.length === 1 ? issueIds[0] : issueIds[issueIds.length - 1];
				rankAfter(issueId, lastIssuedId);
				return;
			}

			if (
				destination &&
				issueId !== undefined &&
				destinationId !== undefined &&
				issueId !== destinationId
			) {
				// do not move issue when it is dropped on the same position as it was before

				if (source.index < destination.index && source.droppableId === destination.droppableId) {
					// drag down - rank after (only when drop target is in same column)
					rankAfter(issueId, destinationId);
				} else {
					// drag up - rank before
					rankBefore(issueId, destinationId);
				}
			}
		},
		[
			createAnalyticsEvent,
			execWithIssueAnalyticsData,
			extendedOptions,
			extendedVerticalOptions,
			fieldKey,
			fieldType,
			idsByCell,
			rankAfter,
			rankBefore,
			unfilteredIdsByCell,
			updateField,
			verticalFieldKey,
		],
	);
};

export const useSwimlanesDropCallback = (
	field: Field,
	verticalField: Field,
	extendedOptions: ExtendedOption<unknown>[],
	extendedVerticalOptions: ExtendedOption<unknown>[],
) => {
	const cardDropHandler = useSwimlanesCardDropHandler(
		field.key,
		verticalField.key,
		extendedOptions,
		extendedVerticalOptions,
	);
	const columnDropHandler = useColumnDropHandler(field);
	const rowDropHandler = useRowDropHandler(verticalField);

	return useCallback(
		(
			source: DraggableLocation,
			destination?: DraggableLocation,
			afterItemIndex?: number,
			beforeItemIndex?: number,
		) => {
			if (source.droppableId === COLUMN_DROPPABLE_ID) {
				return columnDropHandler({ source, destination });
			}
			if (source.droppableId === ROW_DROPPABLE_ID) {
				return rowDropHandler({ source, destination });
			}
			return cardDropHandler({
				source,
				destination,
				afterItemIndex,
				beforeItemIndex,
			});
		},
		[cardDropHandler, columnDropHandler, rowDropHandler],
	);
};

export const getTargetIndex = (
	sourceData: DraggableCardData,
	targetData: DraggableCardData,
	isSameColumn: boolean,
	dropEdge: Edge,
) => {
	let targetIndex;

	if (isSameColumn) {
		const isSourceJustBeforeTarget = sourceData.index + 1 === targetData.index;
		const isSourceJustAfterTarget = sourceData.index - 1 === targetData.index;
		const isDraggingDown = sourceData.index < targetData.index;
		if (dropEdge === 'top') {
			if (isDraggingDown) {
				targetIndex = isSourceJustBeforeTarget ? sourceData.index : targetData.index - 1;
			} else {
				targetIndex = targetData.index;
			}
		} else if (isDraggingDown) {
			targetIndex = targetData.index;
		} else {
			targetIndex = isSourceJustAfterTarget ? sourceData.index : targetData.index + 1;
		}
	} else if (dropEdge === 'top') {
		targetIndex = targetData.index;
	} else {
		targetIndex = targetData.index + 1;
	}

	return targetIndex;
};
