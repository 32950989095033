import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	projectGroupHeadingNonFinal: {
		id: 'polaris-ideas.idea-view.connections.content.footer.edit.project-group-heading-non-final',
		defaultMessage: 'In this project',
		description: 'Heading for the project options group',
	},
	siteGroupHeadingNonFinal: {
		id: 'polaris-common.fields.connection.edit.site-group-heading-non-final',
		defaultMessage: 'On the site',
		description: 'Heading for the site options group',
	},
});
