import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { createHigherLevelViewHook as createHigherLevelViewHookMain } from '../main.tsx';
import { useFieldForFieldKey } from './fields-hooks.tsx';
import {
	createGeIntervalFilterOrEmptyFilter,
	createGetFieldFilterOrEmptyFilter,
	createGetConnectionFieldFilter,
	createGetNumericFilterOrEmptyFilter,
	getActiveFiltersCount,
	getActivePermanentFiltersFields,
	getAllowedFiltersFields,
	getAvailableFiltersFields,
	getCurrentViewFilter,
	getCurrentViewFilterForShared,
	getCurrentViewInitialFilter,
	getQuickSearchFilter,
	hasSharedViewNoValueFilter,
	isQuickSearchFilterDefined,
} from './filters.tsx';
import { createHigherLevelViewHook, createViewHook } from './utils.tsx';

export const useFieldFilter = createHigherLevelViewHookMain(createGetFieldFilterOrEmptyFilter);

export const useConnectionFieldFilter = createHigherLevelViewHookMain(
	createGetConnectionFieldFilter,
);

export const usePermanentFieldFilter = (fieldKey: FieldKey) => useFieldFilter(fieldKey);

export const useNumericFilter = createHigherLevelViewHookMain(createGetNumericFilterOrEmptyFilter);

export const useIntervalFilter = createHigherLevelViewHookMain(createGeIntervalFilterOrEmptyFilter);

export const useCurrentViewFilter = createViewHook(getCurrentViewFilter);
export const useCurrentViewInitialFilter = createViewHook(getCurrentViewInitialFilter);
export const useHasSharedViewNoValueFilter = createHigherLevelViewHook(hasSharedViewNoValueFilter);

export const useCurrentViewFilterForShared = createViewHook(getCurrentViewFilterForShared);

export const useIsQuickSearchFilterDefined = createViewHook(isQuickSearchFilterDefined);

export const useQuickSearchFilter = createViewHook(getQuickSearchFilter);

export const useActiveFiltersCount = createViewHook(getActiveFiltersCount);

export const useAllowedFiltersFields = createViewHook(getAllowedFiltersFields);

export const useActivePermanentFiltersFields = createViewHook(getActivePermanentFiltersFields);

export const useAvailableFiltersFields = createViewHook(getAvailableFiltersFields);

export const useFilter = ({
	fieldKey,
	filterType,
}: {
	fieldKey: FieldKey;
	filterType: 'NUMBER' | 'FIELD' | 'TEXT' | 'INTERVAL' | undefined;
}) => {
	const fieldFilter = useFieldFilter(fieldKey);
	const numericFilter = useNumericFilter(fieldKey);
	const intervalFilter = useIntervalFilter(fieldKey);
	const qsFilter = useQuickSearchFilter();
	const field = useFieldForFieldKey(fieldKey);

	if (filterType === 'NUMBER') {
		if (field?.formula?.template === 'num_data_points' && intervalFilter.values.length) {
			return intervalFilter;
		}
		return numericFilter;
	}
	if (filterType === 'FIELD') {
		return fieldFilter;
	}
	if (filterType === 'TEXT') {
		return qsFilter;
	}
	if (filterType === 'INTERVAL') {
		return intervalFilter;
	}
	return undefined;
};
