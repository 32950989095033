import React, { useCallback, useEffect, useRef } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { useRetryInterval, useAllowedRetries } from '../../common/utils.tsx';
import { ProgressProvider, useProgress } from '../context/index.tsx';
import type { BulkIssueUpdateProgressProps } from '../types.tsx';
import {
	JIRA_LONG_RUNNING_TASK_STATUS,
	JIRA_LONG_RUNNING_TASK_FAILURE_STATUSES,
} from './types.tsx';

export const BulkIssueUpdateProgressPolling = ({
	children,
	onFinished,
	getUpdateIssueFieldsBulkProgress,
	taskId,
}: BulkIssueUpdateProgressProps) => {
	const { formatMessage } = useIntl();
	const { retryStatus, resetRetryCount } = useAllowedRetries();
	const { progress, setError, updateProgress } = useProgress();
	const clearRetryInterval = useRef<() => void>();

	const retry = useCallback(() => {
		getUpdateIssueFieldsBulkProgress({ taskId })
			.then((response) => {
				if (!response || !response.status) {
					return;
				}

				const { progress: newProgress, status } = response;
				const isProgressFinished =
					status === JIRA_LONG_RUNNING_TASK_STATUS.COMPLETE ||
					JIRA_LONG_RUNNING_TASK_FAILURE_STATUSES.includes(status);

				// Stop polling if the task has finished
				if (isProgressFinished) {
					clearRetryInterval.current?.();
					// Notify the parent component that the task has finished
					onFinished?.();
				}

				// If the progress has changed, reset the retry count
				if (progress !== newProgress) {
					resetRetryCount();
				}

				updateProgress(newProgress ?? 0);
			})
			.catch(() => {
				setError();
				clearRetryInterval.current?.();
			});
	}, [
		getUpdateIssueFieldsBulkProgress,
		onFinished,
		progress,
		resetRetryCount,
		setError,
		taskId,
		updateProgress,
	]);

	clearRetryInterval.current = useRetryInterval(taskId, retry);

	useEffect(() => {
		if (retryStatus !== 'underLimit') {
			setError();
			clearRetryInterval.current?.();
		}

		return () => {
			clearRetryInterval.current?.();
		};
	}, [clearRetryInterval, formatMessage, retryStatus, setError]);

	return <>{children}</>;
};

export const BulkIssueUpdateProgress = (props: BulkIssueUpdateProgressProps) => (
	<ProgressProvider>
		<BulkIssueUpdateProgressPolling {...props} />
	</ProgressProvider>
);
