import { createSelector } from 'reselect';
import flatten from 'lodash/flatten';
import { DRAGGABLE_ITEM_TYPE } from '../../common/constants.tsx';
import type { OccupiedCell } from '../../common/types/timeline/index.tsx';
import { getItemWithCutDirection, getTakenColsByItem } from '../../common/utils.tsx';
import { type ItemId, NO_VALUE_GROUP_ID } from '../../types.tsx';
import type { Item, Selector, State } from '../types.tsx';
import { getGroupIdsLookup } from './groups.tsx';
import { getColumnsCount, getGroupedItemRows } from './timeline.tsx';

export const getItems = (state: State): Item[] =>
	Object.values(state.items).filter((item): item is Item => item !== undefined);

export const getItemsPositions = createSelector(
	getItems,
	getGroupIdsLookup,
	getGroupedItemRows,
	getColumnsCount,
	(items, groupLookup, arrangement, columnCount) =>
		flatten(
			items.map(
				({ size, start, id, startLabel, endLabel, isStartExternal, isEndExternal }, index) => {
					const groups = groupLookup[id] || [NO_VALUE_GROUP_ID];

					return groups.map((groupId) =>
						getItemWithCutDirection(
							{
								id,
								type: DRAGGABLE_ITEM_TYPE,
								rowIndex:
									arrangement[groupId]?.[id] !== undefined ? arrangement[groupId]?.[id] : index,
								colIndex: start,
								size,
								groupId,
								startLabel,
								endLabel,
								isStartExternal,
								isEndExternal,
							},
							columnCount,
						),
					);
				},
			),
		),
);

export const getOccupiedCells: Selector<OccupiedCell[]> = createSelector(
	getItemsPositions,
	(items) =>
		items.reduce<OccupiedCell[]>((acc, item) => {
			const { size, colIndex, rowIndex, id, groupId = NO_VALUE_GROUP_ID } = item;

			if (colIndex === undefined) {
				return acc;
			}

			const takenCols = getTakenColsByItem(colIndex, size);

			takenCols.forEach((newColIndex) =>
				acc.push({
					rowIndex,
					colIndex: newColIndex,
					id,
					groupId,
				}),
			);

			return acc;
		}, []),
);

export const createGetItemById = (id: ItemId): Selector<Item | undefined> =>
	createSelector(getItems, (items) => items.find((item) => item.id === id));
