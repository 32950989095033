import React, { useCallback, useRef } from 'react';
import { isValidationError } from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import type { StatusValue } from '@atlassian/jira-issue-field-status/src/common/types.tsx';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import { useField } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/fields-hooks.tsx';
import {
	useSelectedIssueKey,
	useSelectedIssue,
	useSelectedIssueStatusValue,
	useIsSelectedIssueArchived,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { useStatusCategories } from '@atlassian/jira-polaris-common/src/controllers/workflow/selectors/status-categories-hook.tsx';
import { StatusField } from '@atlassian/jira-polaris-common/src/ui/fields/status/index.tsx';
import { useCanEditIssues } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import type { StatusCategory } from '@atlassian/jira-polaris-domain-field/src/field-types/status/types.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import type { JPDExperience } from '@atlassian/jira-polaris-lib-analytics/src/common/utils/experience/types.tsx';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';

type Props = {
	fieldKey: FieldKey;
};

/**
 * quite similar to the handler defined for the ideas app in the table. however, the single idea
 * view does not support any concept of bulk update, so in can be kept a lot simpler
 */
const useStatusSubmitHandler = (fieldKey: FieldKey) => {
	const { updateStatusInStateOnly } = useIssueActions();
	const localIssueId = useSelectedIssue();
	const statusCategoriesMap = useStatusCategories();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	return useCallback(
		(oldStatus: StatusValue | undefined, newValue: StatusValue) => {
			const statusCategories: StatusCategory[] = Object.values(statusCategoriesMap || {});
			const statusCategory =
				statusCategories.find(({ id }) => id === newValue.statusCategory.id) ??
				newValue.statusCategory;
			const newStatus = {
				...newValue,
				statusCategory,
			};

			// the status value type we get from the field is incomplete even though it contains
			// all the field that we need. that is why we have to resort to a type check
			// failure ignore annotations
			// @ts-expect-error - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
			updateStatusInStateOnly(fieldKey, localIssueId, newStatus);

			fireTrackAnalytics(createAnalyticsEvent({}), 'issueStatus changed', 'status-field', {
				action: 'single',
				oldStatus,
				newStatus,
			});
		},
		[createAnalyticsEvent, fieldKey, localIssueId, updateStatusInStateOnly, statusCategoriesMap],
	);
};

export const Status = ({ fieldKey }: Props) => {
	const field = useField(fieldKey);
	const value = useSelectedIssueStatusValue(fieldKey);
	const ideaKey = useSelectedIssueKey();
	const ideaId = useSelectedIssue();
	const [canEditIssues] = useCanEditIssues();
	const isIdeaArchived = useIsSelectedIssueArchived();
	const onSubmit = useStatusSubmitHandler(fieldKey);
	const fieldUpdateExperienceRef = useRef<JPDExperience>();

	const handleSubmit = () => {
		if (fieldUpdateExperienceRef.current) {
			// it's too complicated to manage conccurent field update experiences for this field
			fieldUpdateExperienceRef.current.abort();
		}

		fieldUpdateExperienceRef.current = experience.ideaView.makeFieldUpdate();
		fieldUpdateExperienceRef.current.start();
	};

	const handleSuccess = (newValue: StatusValue) => {
		onSubmit(value, newValue);

		if (!fieldUpdateExperienceRef.current) {
			return;
		}

		fieldUpdateExperienceRef.current.success();
		fieldUpdateExperienceRef.current = undefined;
	};

	const handleFailure = (error?: Error) => {
		if (!fieldUpdateExperienceRef.current) {
			return;
		}

		if (isClientFetchError(error)) {
			fieldUpdateExperienceRef.current.abort(error);
			fieldUpdateExperienceRef.current = undefined;
			return;
		}

		if (error && isValidationError(error)) {
			fieldUpdateExperienceRef.current.abort(error);
			fieldUpdateExperienceRef.current = undefined;
			return;
		}

		fieldUpdateExperienceRef.current.failure(error);
		fieldUpdateExperienceRef.current = undefined;
	};

	if (field === undefined) {
		return null;
	}

	return (
		<StatusField
			value={value}
			issueKey={ideaKey}
			issueId={ideaId}
			isEditable={canEditIssues && field.editable && !isIdeaArchived}
			onSubmit={handleSubmit}
			onSuccess={handleSuccess}
			onFailure={handleFailure}
		/>
	);
};
