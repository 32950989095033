import React from 'react';
import Badge from '@atlaskit/badge';
import { Text, Inline } from '@atlaskit/primitives';
import { components, type GroupHeadingProps } from '@atlaskit/react-select';
import { fg } from '@atlassian/jira-feature-gating';
import type { ConnectionFieldOption } from '../../types.tsx';
import { MAX_OPTIONS } from '../../constants.tsx';

export const ConnectionFieldGroupHeading = <IsMulti extends boolean>(
	props: GroupHeadingProps<ConnectionFieldOption, IsMulti>,
) => {
	const {
		data: { options, label },
	} = props;

	if (!label) {
		return null;
	}

	const badgeContent = fg('jpd_cross_project_connecting')
		? `${options.length}${options.length >= MAX_OPTIONS ? '+' : ''}`
		: options.length;

	return (
		<components.GroupHeading {...props}>
			<Inline alignBlock="center" space="space.100">
				<Text color="color.text.subtlest">{label}</Text>
				<Badge>{badgeContent}</Badge>
			</Inline>
		</components.GroupHeading>
	);
};
