import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	spotlightCreateIdeaBtn: {
		id: 'polaris-ideas.create-issue-interceptor.spotlight-create-idea-btn',
		defaultMessage: 'Contributors will be able to create ideas from here.',
		description: 'Spotlight message for create idea button for contributors',
	},
	spotlightCloseBtn: {
		id: 'polaris-ideas.create-issue-interceptor.spotlight-close-btn',
		defaultMessage: 'Got it',
		description: 'Button message for closing spotlight',
	},
});
