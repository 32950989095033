import React from 'react';
import { styled } from '@compiled/react';
import EditorMentionIcon from '@atlaskit/icon/core/migration/mention--editor-mention';
import { token } from '@atlaskit/tokens';
// eslint-disable-next-line jira/styled/no-styled-import-alias
import { SkeletonCompiled as Skeleton } from '@atlassian/jira-common-components-skeleton/src/styled.tsx';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

export const MentionPlaceholder = () => {
	const { formatMessage } = useIntl();

	return (
		<PlaceholderContainer>
			<EditorMentionIcon
				spacing="spacious"
				color={token('color.text.subtlest')}
				label={formatMessage(messages.loading)}
			/>
			<Skeleton height={token('space.075')} width="100%" />
		</PlaceholderContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PlaceholderContainer = styled.div({
	display: 'inline-flex',
	verticalAlign: 'middle',
	alignItems: 'center',
	width: '100px',
	backgroundColor: token('color.background.accent.gray.subtler'),
	height: token('space.250'),
	borderRadius: token('space.150'),
	marginLeft: token('space.050'),
	paddingRight: token('space.100'),
});
