import { useCallback } from 'react';
import omit from 'lodash/omit';
import {
	PolarisEnvironmentContainerTypes,
	useEnvironmentContainer,
} from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import type { SmartUserPickerWithSuggestionsPrincipal } from '@atlassian/jira-polaris-component-smart-user-picker-with-suggestions/src/ui/index.tsx';
import { principalsToUsersWithEmail } from './utils/principalsToUsersWithEmail.tsx';
import type { InviteUserArguments } from './service/types.tsx';
import { inviteUser } from './service/index.tsx';
import type { InviteUserResponse } from './service/rest/types.tsx';
import { useInviteNotifications } from './utils/useInviteNotifications.tsx';

export const useInvite = () => {
	const container = useEnvironmentContainer();
	const { sendInviteNotifications } = useInviteNotifications();

	const onInvite = useCallback(
		async (principals: SmartUserPickerWithSuggestionsPrincipal[], roleId: number) => {
			if (container?.type !== PolarisEnvironmentContainerTypes.PROJECT) {
				return;
			}

			const inviteUserArgumentsWithNameArray: Array<InviteUserArguments & { name: string }> =
				principalsToUsersWithEmail(principals).map((user) => ({
					...user,
					projectId: Number(container.projectId),
					roleId,
				}));

			const licenseLimitReachedEmails: string[] = [];
			const successNames: string[] = [];
			const unknownErrorEmails: string[] = [];
			for (const inviteUserArgumentsWithName of inviteUserArgumentsWithNameArray) {
				// eslint-disable-next-line no-await-in-loop
				await inviteUser(omit(inviteUserArgumentsWithName, ['name']))
					.then((response: InviteUserResponse) => {
						if (response.status === 'LICENSE_LIMIT_REACHED') {
							licenseLimitReachedEmails.push(inviteUserArgumentsWithName.email);
						} else {
							successNames.push(inviteUserArgumentsWithName.name);
						}
					})
					.catch(() => {
						unknownErrorEmails.push(inviteUserArgumentsWithName.email);
					});
			}

			sendInviteNotifications({
				licenseLimitReachedEmails,
				projectKey: container.projectKey,
				successNames,
				unknownErrorEmails,
			});
		},
		[container, sendInviteNotifications],
	);

	return { onInvite };
};
