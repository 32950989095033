import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { styled } from '@compiled/react';
import { parseISO } from 'date-fns';
import Button from '@atlaskit/button';
import { useIntl } from '@atlassian/jira-intl';
import { useCurrentViewTimelineMode } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import MonthPicker from '@atlassian/jira-polaris-common/src/ui/date-picker/fuzzy-date-picker-content/month-picker/index.tsx';
import QuarterPicker from '@atlassian/jira-polaris-common/src/ui/date-picker/fuzzy-date-picker-content/quarter-picker/index.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import {
	formatIsoLocalDate,
	formatMonthAndYearDate,
	formatQuarterAndYearDate,
} from '@atlassian/jira-polaris-lib-date-time/src/index.tsx';
import type { IntervalValue } from '@atlassian/jira-polaris-lib-date-time/src/types.tsx';
import { JiraPopup as Popup } from '@atlassian/jira-popup/src/ui/jira-popup.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { messages } from './messages.tsx';

type DatePickerProps = {
	setIntervalDate(date: string): void | Promise<void>;
	date: Date | null;
	selectedRangeDate: Date | null;
	dateType: string;
};

export const DatePicker = ({
	setIntervalDate,
	date,
	selectedRangeDate,
	dateType,
}: DatePickerProps) => {
	const mode = useCurrentViewTimelineMode();
	const [isOpen, setIsOpen] = useState(false);
	const [value, setValue] = useState<string | undefined>();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		const formattedDate = date ? formatIsoLocalDate(date) : undefined;
		setValue(formattedDate);
	}, [date]);

	const onClose = useCallback(() => {
		setIsOpen(false);
	}, []);

	const onChange = useCallback(
		(newValue?: IntervalValue) => {
			if (newValue) {
				const newDate = dateType === 'start' ? newValue.start : newValue.end;
				setValue(newDate);
				setIntervalDate(newDate);
				fireCompoundAnalyticsEvent.TimelineRangeChanged(createAnalyticsEvent({}), {
					newDate,
				});
			}
			onClose();
		},
		[createAnalyticsEvent, dateType, onClose, setIntervalDate],
	);

	const toggle = useCallback((val: boolean) => {
		setIsOpen(val);
	}, []);

	const { formatMessage } = useIntl();
	const noDateSetMessage = formatMessage(messages.noDateSet);

	const dateToDisplay = useMemo(() => {
		const newDateValue = value && parseISO(value);
		return (
			newDateValue &&
			(mode === 'MONTHS'
				? formatMonthAndYearDate(newDateValue)
				: formatQuarterAndYearDate(newDateValue))
		);
	}, [mode, value]);

	const PickerContent = mode === 'MONTHS' ? MonthPicker : QuarterPicker;

	return (
		<Popup
			zIndex={610}
			placement="bottom-start"
			messageId="polaris-ideas.ui.view-controls.config-timeline.date-picker.popup"
			messageType="transactional"
			content={() => (
				<DatePickerContainer>
					<PickerContent
						onSelect={onChange}
						date={value}
						selectedDate={selectedRangeDate}
						dateType={dateType}
					/>
				</DatePickerContainer>
			)}
			isOpen={isOpen}
			onClose={() => toggle(false)}
			trigger={(triggerProps) => (
				<Button {...triggerProps} isSelected={isOpen} onClick={() => toggle(!isOpen)}>
					{dateToDisplay || noDateSetMessage}
				</Button>
			)}
		/>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DatePickerContainer = styled.div({
	width: '260px',
});
