import React from 'react';
import { token } from '@atlaskit/tokens';
import Button from '@atlaskit/button';
import EmptyState from '@atlaskit/empty-state';
import ShortcutIcon from '@atlaskit/icon/core/migration/link-external--shortcut';
import { Box, xcss } from '@atlaskit/primitives';
import noResults from '@atlassian/jira-illustrations/src/ui/adg4/brand/spots/empty/components/search-no-results-old/index.tsx';
import celebrationIcon from '@atlassian/jira-illustrations/src/ui/adg4/brand/spots/success/components/celebration/index.tsx';
import { getAkEmptyStateRenderImageFn } from '@atlassian/jira-illustrations/src/ui/helpers/ak-empty-state/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useAreAllGlobalFieldsAdded } from '@atlassian/jira-polaris-common/src/controllers/global-fields/selectors/global-fields-hooks.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import messages from './messages.tsx';

export const EmptyScreen = () => {
	const { formatMessage } = useIntl();
	const { isAdmin } = useTenantContext();

	const areAllGlobalFieldsAdded = useAreAllGlobalFieldsAdded();

	return (
		<EmptyState
			header={formatMessage(
				areAllGlobalFieldsAdded ? messages.allFieldsAddedScreenHeader : messages.emptyScreenHeader,
			)}
			renderImage={getAkEmptyStateRenderImageFn(
				areAllGlobalFieldsAdded ? celebrationIcon : noResults,
			)}
			maxImageWidth={120}
			description={
				<>
					{formatMessage(
						areAllGlobalFieldsAdded
							? messages.allFieldsAddedDescription
							: messages.emptyScreenDescription,
					)}
					{!isAdmin && (
						<Box xcss={hintStyles}>{formatMessage(messages.contactAdminToCreateGlobalField)}</Box>
					)}
				</>
			}
			primaryAction={
				isAdmin && (
					<Button
						href="/jira/settings/products/jira-product-discovery/global-fields"
						target="_blank"
						appearance="primary"
						onClick={(_event, analyticsEvent) => {
							fireUIAnalytics(analyticsEvent, 'globalFields');
						}}
						iconAfter={<ShortcutIcon label="" LEGACY_size="medium" />}
					>
						{formatMessage(messages.createGlobalField)}
					</Button>
				)
			}
		/>
	);
};

const hintStyles = xcss({
	color: 'color.text.subtle',
	font: token('font.body.UNSAFE_small'),
	marginTop: 'space.200',
});
