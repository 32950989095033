import React from 'react';
import { Box, Text, Inline } from '@atlaskit/primitives';
import { IssueTypeIcon } from '@atlassian/jira-polaris-component-issue-types/src/ui/issue-type-icon/issue-type-icon.tsx';
import {
	useIssueTypeByNameAndProjectIds,
	useIssueTypeName,
} from '@atlassian/jira-polaris-component-issue-types/src/controllers/index.tsx';
import { useEnvironmentContainerId } from '@atlassian/jira-polaris-component-environment-container/src/controllers/store/index.tsx';
import { useProjectsIdsForContainer } from '@atlassian/jira-polaris-component-project-metadata/src/controllers/index.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import type { ColumnHeaderComponentProps, ColumnHeaderConfiguration } from '../types.tsx';

const ComponentOld = ({ groupIdentity }: ColumnHeaderComponentProps) => {
	const name = useIssueTypeName({ issueTypeId: groupIdentity });

	return (
		<Box padding="space.025">
			<Inline alignBlock="center" space="space.075">
				<IssueTypeIcon issueTypeId={groupIdentity} />
				<Text>{name}</Text>
			</Inline>
		</Box>
	);
};

const ComponentNew = ({ groupIdentity }: ColumnHeaderComponentProps) => {
	const containerId = useEnvironmentContainerId();
	const projectIds = useProjectsIdsForContainer({ containerId });
	const issueType = useIssueTypeByNameAndProjectIds({ name: groupIdentity, projectIds });

	return (
		<Box padding="space.025">
			<Inline alignBlock="center" space="space.075">
				<IssueTypeIcon issueTypeId={issueType?.id || ''} />
				<Text>{groupIdentity}</Text>
			</Inline>
		</Box>
	);
};

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const Component = componentWithFG(
	'jpd_view_config_using_issue_type_name',
	ComponentNew,
	ComponentOld,
) as ColumnHeaderConfiguration['Component'];

export const issueTypeColumnHeaderConfig: ColumnHeaderConfiguration = {
	isHideable: true,
	isDecoratable: false,
	Component,
};
