import type { LocalViewId } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types.tsx';
import { modifyView } from '../utils.tsx';

export const toggleView =
	(localId: LocalViewId): Action<State, Props> =>
	async ({ getState, setState }, props) => {
		const { navigationRemote, cloudId, projectId } = props;
		if (
			!cloudId ||
			!projectId ||
			!navigationRemote ||
			getState().projects[projectId]?.isLoading ||
			!getState().projects[projectId]?.initialized
		) {
			return;
		}

		setState(
			modifyView(getState(), projectId, (viewToModify) => {
				if (viewToModify.localId === localId) {
					return {
						...viewToModify,
						isExpanded: !viewToModify.isExpanded,
					};
				}
				return viewToModify;
			}),
		);
	};
