import React, { useCallback, useState, useMemo, type SyntheticEvent } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import DropdownMenu, { DropdownItemGroup, DropdownItem } from '@atlaskit/dropdown-menu';
import AddIcon from '@atlaskit/icon/core/migration/add';
import LinkIcon from '@atlaskit/icon/core/migration/link';
import SettingsIcon from '@atlaskit/icon/core/migration/settings';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { PRODUCT_DISCOVERY_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import {
	useFieldKeysOfType,
	useFieldLabel,
} from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks.tsx';
import { useIdeaActions } from '@atlassian/jira-polaris-common/src/controllers/idea/main.tsx';
import { useIsLoadingLinkedIssues } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/meta-hooks.tsx';
import {
	useSelectedIssueLinkedProgress,
	useSelectedIssueSummary,
	useIsSelectedIssueArchived,
	useSelectedIssueKey,
	useSelectedIssue,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { DeliveryDataRestrictedMarker } from '@atlassian/jira-polaris-common/src/ui/field-info-marker/delivery-data-restricted/index.tsx';
import {
	LinkedIssuesProgressComponent,
	BAR_ONLY,
} from '@atlassian/jira-polaris-common/src/ui/linked-issues-progress/index.tsx';
import { useIsInCollectionContainer } from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import { useStatusCategories } from '@atlassian/jira-polaris-component-environment-tenant/src/controllers/selectors/index.tsx';
import { iconForPolarisFieldType } from '@atlassian/jira-polaris-component-glyphs/src/ui/glyphs/main.tsx';
import {
	useCanEditFields,
	useCanManageDeliveryTickets,
} from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import { toKnownStatusCategoryMap } from '@atlassian/jira-polaris-domain-field/src/field-types/status/types.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import { SpotlightTypes } from '@atlassian/jira-polaris-lib-onboarding/src/common/types.tsx';
import { useSpotlights } from '@atlassian/jira-polaris-lib-onboarding/src/controllers/index.tsx';
import { LinkIssue } from '../../../../../common/ui/link-issue/index.tsx';
import { SendToBacklog } from '../../../../../common/ui/send-to-backlog/index.tsx';
import { DeliveryResourcesPopup } from '../resources-popup/index.tsx';
import linkIssueMessages from '../../../../../common/ui/link-issue/messages.tsx';
import messages from './messages.tsx';

const DeliveryProgressFieldHeader = () => {
	const icon = iconForPolarisFieldType(FIELD_TYPES.DELIVERY_PROGRESS);
	const canEditFields = useCanEditFields();
	const { setFieldSidebarMode, setFieldSidebarFieldKey } = useIdeaActions();
	const { formatMessage } = useIntl();
	const isLoadingLinkedIssues = useIsLoadingLinkedIssues();
	const progress = useSelectedIssueLinkedProgress();
	const statusCategories = useStatusCategories();
	const isIdeaArchived = useIsSelectedIssueArchived();
	const isCollection = useIsInCollectionContainer();

	const deliveryProgressFields = useFieldKeysOfType(FIELD_TYPES.DELIVERY_PROGRESS);

	const fieldKey = useMemo(() => deliveryProgressFields[0], [deliveryProgressFields]);
	const fieldLabel = useFieldLabel(fieldKey);

	const onManageField = useCallback(() => {
		setFieldSidebarFieldKey(fieldKey);
		setFieldSidebarMode('FIELD');
	}, [setFieldSidebarFieldKey, setFieldSidebarMode, fieldKey]);

	const knownStatusCategories = useMemo(
		() => toKnownStatusCategoryMap(statusCategories),
		[statusCategories],
	);

	if (progress === undefined) {
		return null;
	}

	// loading state, due to internal refresher, is not relevant for collection views
	const isLoading = isLoadingLinkedIssues && !isCollection;

	const isMenuDisabled = !canEditFields || isIdeaArchived;

	return (
		<DropdownMenu<HTMLDivElement>
			trigger={({ triggerRef, ...triggerProps }) => (
				<TriggerWrapper {...triggerProps} ref={triggerRef}>
					<HeaderButton disabled={isMenuDisabled}>
						<HeaderButtonLabelContainer>
							<DeliveryDataRestrictedMarker fieldKey={fieldKey} marginRight={6} />
							<IconContainer
								// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
								className="hide-from-export"
							>
								{icon}
							</IconContainer>
							<HeaderLabelContainer>{fieldLabel}</HeaderLabelContainer>
						</HeaderButtonLabelContainer>
						<ProgressContainer data-testid="polaris-ideas.ui.idea-view.sections.deliver.progress-summary.progress-container">
							<LinkedIssuesProgressComponent
								fieldKey={fieldKey}
								appearance={BAR_ONLY}
								isLoading={isLoading}
								progress={progress}
								statusCategories={knownStatusCategories}
								height={8}
							/>
						</ProgressContainer>
						{!isMenuDisabled && (
							<SettingsContainer>
								<Tooltip
									content={formatMessage(messages.editFieldTooltip)}
									delay={100}
									position="bottom"
								>
									<SettingsIcon LEGACY_size="small" label="header.more" />
								</Tooltip>
							</SettingsContainer>
						)}
					</HeaderButton>
				</TriggerWrapper>
			)}
			placement="bottom-start"
		>
			<DropdownItemGroup>
				<Tooltip content={canEditFields ? null : formatMessage(messages.noEditPermissions)}>
					<DropdownItem isDisabled={!canEditFields} onClick={onManageField}>
						{formatMessage(messages.editField)}
					</DropdownItem>
				</Tooltip>
			</DropdownItemGroup>
		</DropdownMenu>
	);
};

export const ProgressSummary = ({ isCompact }: { isCompact?: boolean }) => {
	const issueKey = useSelectedIssueKey();
	const localIssueId = useSelectedIssue();
	const { formatMessage } = useIntl();
	const canManageDelivery = useCanManageDeliveryTickets();
	const progress = useSelectedIssueLinkedProgress();
	const ideaSummary = useSelectedIssueSummary();
	const { refreshIssueLinks } = useIdeaActions();
	const isIdeaArchived = useIsSelectedIssueArchived();

	const [inCreate, setInCreate] = useState(false);
	const [inLink, setInLink] = useState(false);

	const { closeSpotlight } = useSpotlights();

	const onCreate = useCallback((event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
		fireCompoundAnalyticsEvent.IdeaView.DeliveryTab.createDeliveryTicketButtonClicked(
			analyticsEvent,
		);
		setInLink(false);
		setInCreate(true);
	}, []);

	const onLink = useCallback((event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
		fireCompoundAnalyticsEvent.IdeaView.DeliveryTab.linkDeliveryTicketButtonClicked(analyticsEvent);
		setInCreate(false);
		setInLink(true);
	}, []);

	const onCreateCanceled = useCallback(() => {
		setInCreate(false);
	}, []);

	const onCreatedAndLinked = useCallback(() => {
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		refreshIssueLinks(() => {});
		setInCreate(false);
		closeSpotlight(SpotlightTypes.CREATE_DELIVERY_TICKET);
	}, [refreshIssueLinks, closeSpotlight]);

	const onLinkCanceled = useCallback(() => {
		setInLink(false);
	}, []);

	const onIssueLinked = useCallback(() => {
		setInLink(false);
		closeSpotlight(SpotlightTypes.CREATE_DELIVERY_TICKET);
	}, [closeSpotlight]);

	return progress !== undefined && !inCreate && !inLink ? (
		<ProgressSummaryWrapper>
			<Container>
				<ProgressWrapper>
					<DeliveryProgressFieldHeader />
				</ProgressWrapper>
				{canManageDelivery && (
					<ButtonGroup>
						<Button
							id="polaris-idea.ui.sections.deliver.create-epic-button"
							appearance="primary"
							iconBefore={
								<AddIcon
									label={formatMessage(
										fg('polaris-issue-terminology-refresh')
											? messages.createEpicIssueTermRefresh
											: messages.createEpic,
									)}
									LEGACY_size="small"
								/>
							}
							onClick={onCreate}
							isDisabled={isIdeaArchived}
						>
							{formatMessage(
								fg('polaris-issue-terminology-refresh')
									? messages.createEpicIssueTermRefresh
									: messages.createEpic,
							)}
						</Button>
						<Box xcss={marginStyles} />
						<Button
							id="polaris-idea.ui.sections.deliver.link-issue-button"
							iconBefore={
								<LinkIcon
									label={formatMessage(
										fg('polaris-issue-terminology-refresh')
											? messages.linkToIssueIssueTermRefresh
											: messages.linkToIssue,
									)}
									LEGACY_size="small"
								/>
							}
							onClick={onLink}
							isDisabled={isIdeaArchived}
						>
							{formatMessage(
								fg('polaris-issue-terminology-refresh')
									? messages.linkToIssueIssueTermRefresh
									: messages.linkToIssue,
							)}
						</Button>
						{fg('jpd_delivery_resources_popup') && (
							<>
								<Box xcss={marginStyles} />
								<DeliveryResourcesPopup />
							</>
						)}
					</ButtonGroup>
				)}
			</Container>
		</ProgressSummaryWrapper>
	) : (
		<CreateContainer>
			{inCreate && (
				<SendToBacklog
					onCreated={onCreatedAndLinked}
					onCancel={onCreateCanceled}
					defaultSummary={ideaSummary}
					isCompact={isCompact}
				/>
			)}
			{inLink && (
				<LinkIssue
					isPolarisIssueLink
					excludedProjectTypes={[PRODUCT_DISCOVERY_PROJECT]}
					onIssueLinked={onIssueLinked}
					onCancel={onLinkCanceled}
					isCompact={isCompact}
					issueKey={issueKey}
					localIssueId={localIssueId}
					captionMessage={formatMessage(linkIssueMessages.heading)}
				/>
			)}
		</CreateContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
	alignItems: 'center',
	gap: token('space.100'),
	justifyItems: 'space-between',
	flexGrow: 1,
	flexWrap: 'wrap-reverse',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CreateContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	justifyItems: 'space-between',
	flexGrow: 1,
});

const marginStyles = xcss({
	marginLeft: 'space.150',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ProgressContainer = styled.div({
	display: 'flex',
	width: '229px', // Temporary fix for POL-6356, return to 230px when fixed
	flexShrink: 3,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonGroup = styled.div({
	display: 'flex',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ProgressWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
	flexGrow: 1,
	width: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TriggerWrapper = styled.div({
	marginRight: token('space.100'),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeaderButton = styled.button<{ disabled: any }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text'),
	flex: 1,
	width: '100%',
	display: 'inline-flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	border: 'none',
	backgroundColor: 'transparent',
	paddingTop: token('space.050'),
	paddingBottom: token('space.050'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	cursor: ({ disabled }) => (disabled ? 'inherit' : 'pointer'),
	textAlign: 'left',
	fontWeight: token('font.weight.regular'),
	minHeight: '32px',
	borderRadius: '3px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	':hover, :focus, :focus-within': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		backgroundColor: ({ disabled }) =>
			disabled ? 'inherit' : token('color.background.neutral.subtle.hovered'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		color: token('color.text'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'& svg': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			color: token('color.text'),
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'> #morecontainer': {
			display: 'inline-block',
		},
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SettingsContainer = styled.div({
	display: 'inline-block',
	marginLeft: token('space.100'),

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& svg': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		color: token('color.text'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeaderButtonLabelContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	marginRight: token('space.100'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeaderLabelContainer = styled.div({
	font: token('font.body.UNSAFE_small'),
	textAlign: 'left',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconContainer = styled.div({
	marginRight: token('space.075'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ProgressSummaryWrapper = styled.div({
	display: 'flex',
	alignItems: 'flex-end',
	justifyContent: 'space-between',
	paddingRight: token('space.500'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > [role="presentation"]': {
		minWidth: '32px',
		height: '100%',
	},
});
