import React from 'react';
import { Inline, Text, xcss, Stack } from '@atlaskit/primitives';
import Image from '@atlaskit/image';
import Heading from '@atlaskit/heading';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';
import CreateIdeasLight from './assets/create-ideas--light.svg';
import CreateIdeasDark from './assets/create-ideas--dark.svg';
import ConnectionFieldLight from './assets/connection-field--light.svg';
import ConnectionFieldDark from './assets/connection-field--dark.svg';
import ConfigureViewsLight from './assets/configure-views--light.svg';
import ConfigureViewsDark from './assets/configure-views--dark.svg';

type ColumnComponentProps = {
	imageSrc: string;
	imageDarkSrc: string;
	title: string;
	description: string;
};

const ColumnComponent = ({ imageSrc, imageDarkSrc, title, description }: ColumnComponentProps) => (
	<Stack xcss={columnStyles}>
		<Image src={imageSrc} srcDark={imageDarkSrc} alt={title} />
		<Stack space="space.100" xcss={textBlockStyles}>
			<Heading size="small">{title}</Heading>
			<Text>{description}</Text>
		</Stack>
	</Stack>
);

export const HowToSection = () => {
	const { formatMessage } = useIntl();

	return (
		<Inline space="space.300" alignBlock="stretch">
			<ColumnComponent
				imageSrc={CreateIdeasLight}
				imageDarkSrc={CreateIdeasDark}
				title={formatMessage(messages.howToCreateTypes)}
				description={formatMessage(messages.howToCreateTypesDescription)}
			/>
			<ColumnComponent
				imageSrc={ConnectionFieldLight}
				imageDarkSrc={ConnectionFieldDark}
				title={formatMessage(messages.howToCreateConnectionFields)}
				description={formatMessage(messages.howToCreateConnectionFieldsDescription)}
			/>
			<ColumnComponent
				imageSrc={ConfigureViewsLight}
				imageDarkSrc={ConfigureViewsDark}
				title={formatMessage(messages.howToConfigureViews)}
				description={formatMessage(messages.howToConfigureViewsDescription)}
			/>
		</Inline>
	);
};

const columnStyles = xcss({
	flex: '1 1 auto',
	borderRadius: '6px',
	backgroundColor: 'elevation.surface.sunken',
	maxWidth: '340px',
	overflow: 'hidden',
});

const textBlockStyles = xcss({
	padding: 'space.300',
});
