import React, { useMemo, type ReactElement } from 'react';
import { Flex, Text } from '@atlaskit/primitives';
import Select, { components, type OptionProps, type SingleValueProps } from '@atlaskit/select';
import { IssueTypeAvatar } from '@atlassian/jira-polaris-component-issue-types/src/ui/issue-type-icon/issue-type-avatar.tsx';
import {
	useEnvironmentContainer,
	PolarisEnvironmentContainerTypes,
} from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import { useIssueTypesForProject } from '@atlassian/jira-polaris-component-issue-types/src/controllers/index.tsx';
import { IssueTypeIcon } from '@atlassian/jira-polaris-component-issue-types/src/ui/issue-type-icon/issue-type-icon.tsx';
import type { IssueType } from '@atlassian/jira-polaris-project-select/src/services/types.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { iconForPolarisFieldType } from '@atlassian/jira-polaris-component-glyphs/src/ui/glyphs/main.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import { messages } from './messages.tsx';

type SelectOption = {
	label: string;
	id: string | undefined;
	OptionRenderComponent: () => ReactElement;
};

const Option = (props: OptionProps<SelectOption>) => (
	<components.Option {...props}>{props.data.OptionRenderComponent()}</components.Option>
);

type CreateSingleValueComponentProps = {
	icon: React.ReactNode;
};

const createSingleValueComponent =
	({ icon }: CreateSingleValueComponentProps) =>
	({ children, ...props }: SingleValueProps<SelectOption>) => {
		const { formatMessage } = useIntl();
		return (
			<components.SingleValue {...props}>
				<Flex alignItems="center" gap="space.100">
					{icon}
					<Text color={props.isDisabled ? 'color.text.disabled' : undefined} weight="bold">
						{formatMessage(messages.typeFieldLabelNonFinal)}
					</Text>
					{children}
				</Flex>
			</components.SingleValue>
		);
	};

const useIssueTypeOptions = (): SelectOption[] => {
	const container = useEnvironmentContainer();
	const projectId =
		container?.type === PolarisEnvironmentContainerTypes.PROJECT ? container.projectId : '';
	const issueTypes = useIssueTypesForProject({ projectId });

	return issueTypes.map(({ name, id }) => ({
		label: name,
		id,
		OptionRenderComponent: () => (
			<Flex alignItems="center" gap="space.075">
				<IssueTypeIcon issueTypeId={id} />
				{name}
			</Flex>
		),
	}));
};

type ConnectionSourceSelectProps = {
	isDisabled?: boolean;
	value: string | undefined;
	onChange: (value?: string) => void;
	issueTypes?: IssueType[];
};

export const ConnectionSourceSelect = ({
	isDisabled = false,
	value,
	onChange,
	issueTypes = [],
}: ConnectionSourceSelectProps) => {
	const { formatMessage } = useIntl();
	const fieldTypeIcon = useMemo(() => iconForPolarisFieldType(FIELD_TYPES.ISSUE_TYPE), []);

	const allOptions = issueTypes.map(({ name, id, avatarId }) => ({
		label: name,
		id: String(id),
		OptionRenderComponent: () => (
			<Flex alignItems="center" gap="space.075">
				<IssueTypeAvatar avatarId={String(avatarId)} name={name} size="xsmall" />
				{name}
			</Flex>
		),
	}));

	const selectedOption = allOptions.find((option) => option.label === value);

	const handleChange = (option: SelectOption | null) => {
		onChange(option?.label);
	};

	const SingleValue = useMemo(
		() => createSingleValueComponent({ icon: fieldTypeIcon }),
		[fieldTypeIcon],
	);

	return (
		<Select<SelectOption>
			options={allOptions}
			components={{
				Option,
				SingleValue,
			}}
			placeholder={
				<Flex alignItems="center" gap="space.100">
					{fieldTypeIcon}
					<Text color={isDisabled ? 'color.text.disabled' : undefined} weight="bold">
						{formatMessage(messages.typeFieldLabelNonFinal)}
					</Text>
				</Flex>
			}
			value={selectedOption ?? null}
			isSearchable={false}
			onChange={handleChange}
			isDisabled={isDisabled}
			isOptionSelected={(option) => option.label === selectedOption?.label}
		/>
	);
};

export const ConnectionSourceSelectLegacy = ({
	isDisabled = false,
	value,
	onChange,
}: ConnectionSourceSelectProps) => {
	const { formatMessage } = useIntl();
	const fieldTypeIcon = useMemo(() => iconForPolarisFieldType(FIELD_TYPES.ISSUE_TYPE), []);
	const allOptions = useIssueTypeOptions();
	const selectedOption = allOptions.find((option) => option.id === value);

	const handleChange = (option: SelectOption | null) => {
		onChange(option?.id);
	};

	const SingleValue = useMemo(
		() => createSingleValueComponent({ icon: fieldTypeIcon }),
		[fieldTypeIcon],
	);

	return (
		<Select<SelectOption>
			options={allOptions}
			components={{
				Option,
				SingleValue,
			}}
			placeholder={
				<Flex alignItems="center" gap="space.100">
					{fieldTypeIcon}
					<Text color={isDisabled ? 'color.text.disabled' : undefined} weight="bold">
						{formatMessage(messages.typeFieldLabelNonFinal)}
					</Text>
				</Flex>
			}
			value={selectedOption}
			isSearchable={false}
			onChange={handleChange}
			isDisabled={isDisabled}
			isOptionSelected={(option) => option.id === selectedOption?.id}
		/>
	);
};
