import React, { useMemo, useCallback, useEffect, useState, memo } from 'react';
import { styled } from '@compiled/react';
import Avatar, { AvatarItem } from '@atlaskit/avatar';
import InlineEdit, { type InlineEditProps } from '@atlaskit/inline-edit';
import { token } from '@atlaskit/tokens';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import UserPickerView from '@atlassian/jira-issue-user-picker/src/main.tsx';
import type { UserOption, UserOptionValue } from '@atlassian/jira-issue-user-picker/src/types.tsx';
import type { UserFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/user/types.tsx';
import { REPORTER_FIELDKEY } from '@atlassian/jira-polaris-domain-field/src/field/constants.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { callWhenIdle } from '@atlassian/jira-call-when-idle/src/index.tsx';
import { FireUiAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { User } from '../../../common/types/user/index.tsx';
import { getAssignableUsers } from '../../../services/jira/assignable-users-picker/index.tsx';
import { getUsers } from '../../../services/jira/user-picker/index.tsx';
import { UserFieldLoading } from './loading/index.tsx';
import messages from './messages.tsx';
import ReporterEdit from './reporter/index.tsx';

type UserFieldProps = {
	issueKey: IssueKey | undefined;
	isEditable: boolean;
	interactive?: boolean;
	fieldKey: FieldKey;
	user: UserFieldValue | undefined;
	portalElement?: HTMLElement;
	onUpdate: (inputValue: User | undefined) => void;
	onCancel?: () => void;
	onEdit?: () => void;
	testId?: string;
};

type UserFieldReadViewProps = {
	isImmediateRendering?: boolean;
	user?: UserFieldValue | null;
};

const getUserProps = (user?: UserFieldValue | null): User | null => {
	if (!user) {
		return null;
	}
	return {
		id: user.accountId,
		name: user.displayName ?? '',
		avatarUrl: user.avatarUrls?.['48x48'] ?? '',
	};
};

export const UserFieldReadView = ({
	user,
	isImmediateRendering = false,
}: UserFieldReadViewProps) => {
	const [render, setRender] = useState(isImmediateRendering);

	const { formatMessage } = useIntl();

	const currUser = useMemo(() => getUserProps(user), [user]);
	const name = currUser?.name ?? formatMessage(messages.unassigned);
	const avatarUrl = currUser?.avatarUrl;

	useEffect(() => callWhenIdle(() => setRender(true)), []);

	return render ? (
		<AvatarItem
			avatar={<Avatar size="xsmall" src={avatarUrl} name={name} />}
			backgroundColor="transparent"
			primaryText={name}
		/>
	) : (
		<UserFieldLoading name={name} />
	);
};

const UserFieldEditable = memo<UserFieldProps>(
	({
		user,
		issueKey,
		fieldKey,
		isEditable,
		interactive,
		onCancel,
		onEdit,
		onUpdate,
		portalElement,
	}: UserFieldProps) => {
		const { formatMessage } = useIntl();

		const fetchAssignableUsersViaQuery = useCallback(
			(query?: string) => {
				if (issueKey === undefined) {
					return Promise.resolve([]);
				}
				return getAssignableUsers(issueKey, query);
			},
			[issueKey],
		);

		const fetchFunc = fieldKey === 'assignee' ? fetchAssignableUsersViaQuery : getUsers;

		const currUser = useMemo(() => getUserProps(user), [user]);

		const handleConfirm = (userOption: UserOptionValue) => {
			// ESC
			if (typeof userOption === 'string') {
				return;
			}

			// Value removal
			if (!userOption && currUser !== null) {
				onUpdate(undefined);
			}

			// Value update
			if (userOption && userOption?.id !== currUser?.id) {
				const { id, name, avatarUrl } = userOption;
				onUpdate({ id, name, avatarUrl });
			}
		};

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const editView: InlineEditProps<any>['editView'] = (fieldProps) => {
			const onChange = (newValue: UserOptionValue | UserOption[]) => {
				fieldProps.onChange(newValue);
				if (newValue === null) {
					handleConfirm(newValue);
				}
			};

			if (fieldKey === REPORTER_FIELDKEY) {
				return (
					<ReporterEdit
						fieldId={fieldKey}
						appearance="compact"
						onChange={onChange}
						value={currUser}
						autoFocus
						placeholder={formatMessage(messages.placeholder)}
						isDisabled={!isEditable}
						fetchSuggestions={(query) => getUsers(query).then((users) => users)}
						isClearable={false}
						portalElement={portalElement}
					/>
				);
			}
			return (
				<>
					<FireUiAnalytics action="toggled" actionSubject="dropdown" actionSubjectId="issueField" />
					<UserPickerView
						{...fieldProps}
						onChange={onChange}
						portalElement={portalElement}
						value={currUser}
						placeholder={formatMessage(messages.placeholder)}
						fieldId={fieldKey}
						fetchSuggestions={(query) => fetchFunc(query).then((users) => users)}
					/>
				</>
			);
		};

		const readView = () => <UserFieldReadView user={user} isImmediateRendering={interactive} />;

		return (
			<Container>
				<InlineEdit
					defaultValue={currUser}
					readViewFitContainerWidth
					onConfirm={handleConfirm}
					onEdit={onEdit}
					onCancel={onCancel}
					readView={readView}
					editView={editView}
					hideActionButtons
				/>
			</Container>
		);
	},
);

export const UserField = memo<UserFieldProps>((props: UserFieldProps) => {
	const { isEditable, user, testId } = props;

	return isEditable ? (
		<UserFieldEditable {...props} />
	) : (
		// eslint-disable-next-line jira/react/no-style-attribute, @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
		<div style={{ border: '2px solid transparent' }} data-testid={testId}>
			<UserFieldReadView user={user} isImmediateRendering />
		</div>
	);
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	marginTop: token('space.negative.100'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'.fabric-user-picker__menu': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		zIndex: layers.modal,
	},

	/* FIXME: this style override is required for the placeholder styling to be compatible with react-select v4.
    Once JFE resolves to react-select v5, this style override can be removed (aka when the resolution for @atlaskit/select
    in the root package.json will be removed: https://ecosystem-platform.atlassian.net/browse/UNBOX-685). */
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'.fabric-user-picker__value-container': {
		display: 'grid',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'.fabric-user-picker__placeholder': {
			position: 'relative',
			top: 'initial',
			left: 'initial',
			transform: 'initial',
		},
	},
});
