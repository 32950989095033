import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	sendToBacklogButton: {
		id: 'polaris-ideas.view-controls.manage-bar.send-to-backlog.send-to-backlog-button',
		defaultMessage: 'Create delivery tickets',
		description: 'Button label to send an idea to the backlog of another project.',
	},
	sendToBacklogButtonIssueTermRefresh: {
		id: 'polaris-ideas.view-controls.manage-bar.send-to-backlog.send-to-backlog-button-issue-term-refresh',
		defaultMessage: 'Create work items',
		description: 'Button label to send an idea to the backlog of another project.',
	},
	notAllowedTooltip: {
		id: 'polaris-ideas.view-controls.manage-bar.send-to-backlog.not-allowed-tooltip',
		defaultMessage: 'You don’t have permission to create work items. Contact your project admin.',
		description:
			'Tooltip message when user don’t have the some selected ideas’s project’s edit ideas permission',
	},
});
