import React, { type ChangeEvent } from 'react';
import { styled } from '@compiled/react';
import { Radio } from '@atlaskit/radio';
import TextField from '@atlaskit/textfield';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import type { NumericFieldFilterValue } from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import messages from './messages.tsx';

type Props = {
	values: NumericFieldFilterValue[];
	onChange: (value: NumericFieldFilterValue[]) => void;
};

type OperatorVisualisation = 'EQ' | 'NEQ' | 'GT' | 'LT' | 'BETWEEN';

export const getOperatorVisualisationType = (
	values: NumericFieldFilterValue[],
): OperatorVisualisation | undefined => {
	if (values.length === 1) {
		if (values[0].operator === 'EQ') {
			return 'EQ';
		}
		if (values[0].operator === 'NEQ') {
			return 'NEQ';
		}
		if (values[0].operator === 'GT') {
			return 'GT';
		}
		if (values[0].operator === 'LT') {
			return 'LT';
		}
	} else if (values.length === 2) {
		const ops = values.map(({ operator }) => operator);
		if (ops.includes('LTE') && ops.includes('GTE')) {
			return 'BETWEEN';
		}
	}
	return undefined;
};

const NumberPicker = ({
	value,
	onChange,
}: {
	value: number | undefined;
	onChange: (arg1: number | undefined) => void;
}) => (
	<TextField
		type="number"
		value={value}
		onChange={(event: ChangeEvent<HTMLInputElement>) => {
			onChange(Number(event.target.value));
		}}
	/>
);

export const NumericFilterContentContainer = ({ values, onChange }: Props) => {
	const { formatMessage } = useIntl();

	const vizOp = getOperatorVisualisationType(values);

	const v0 = values.length > 0 ? values[0].numericValue : 0;
	const v1 = values.length > 1 ? values[1].numericValue : 0;

	const singleValueOpChangeHandler = (operator: string) => () =>
		onChange([
			{
				// @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'FilterOperator'.
				operator,
				numericValue: v0,
			},
		]);

	const singleValueChangeHandler = (value: undefined | number) => {
		onChange([
			{
				operator: values[0].operator || 'EQ',
				// @ts-expect-error - TS2532 - Object is possibly 'undefined'.
				numericValue: +value,
			},
		]);
	};

	return (
		<ContentContainer>
			<Radio
				name="oppicker"
				value="EQ"
				label={formatMessage(messages.opEquals)}
				isChecked={vizOp === 'EQ' && v0 !== undefined}
				onChange={() =>
					onChange([
						{
							operator: 'EQ',
							numericValue: v0 || 0,
						},
					])
				}
			/>
			{vizOp === 'EQ' && v0 !== undefined && (
				<NumberPicker value={v0} onChange={singleValueChangeHandler} />
			)}
			<Radio
				name="oppicker"
				value="NEQ"
				label={formatMessage(messages.opNotEquals)}
				isChecked={vizOp === 'NEQ'}
				onChange={singleValueOpChangeHandler('NEQ')}
			/>
			{vizOp === 'NEQ' && <NumberPicker value={v0} onChange={singleValueChangeHandler} />}
			<Radio
				name="oppicker"
				value="GT"
				label={formatMessage(messages.opGreaterThan)}
				isChecked={vizOp === 'GT'}
				onChange={singleValueOpChangeHandler('GT')}
			/>
			{vizOp === 'GT' && <NumberPicker value={v0} onChange={singleValueChangeHandler} />}
			<Radio
				name="oppicker"
				value="LT"
				label={formatMessage(messages.opLessThan)}
				isChecked={vizOp === 'LT'}
				onChange={singleValueOpChangeHandler('LT')}
			/>
			{vizOp === 'LT' && <NumberPicker value={v0} onChange={singleValueChangeHandler} />}
			<Radio
				name="oppicker"
				value="BETWEEN"
				label={formatMessage(messages.opBetween)}
				isChecked={vizOp === 'BETWEEN'}
				onChange={() =>
					onChange([
						{
							numericValue: v0,
							operator: 'GTE',
						},
						{
							numericValue: v1,
							operator: 'LTE',
						},
					])
				}
			/>
			{vizOp === 'BETWEEN' && (
				<BetweenContainer>
					<NumberPicker
						value={v0}
						onChange={(value) =>
							onChange([
								{
									operator: 'GTE',
									// @ts-expect-error - TS2532 - Object is possibly 'undefined'.
									numericValue: +value,
								},
								values[1],
							])
						}
					/>
					<NumberPicker
						value={v1}
						onChange={(value) =>
							onChange([
								values[0],
								{
									operator: 'LTE',
									// @ts-expect-error - TS2532 - Object is possibly 'undefined'.
									numericValue: +value,
								},
							])
						}
					/>
				</BetweenContainer>
			)}
			<Radio
				name="oppicker"
				value="EQ_E"
				label={formatMessage(messages.opEmpty)}
				isChecked={vizOp === 'EQ' && v0 === undefined}
				onChange={() =>
					onChange([
						{
							operator: 'EQ',
							numericValue: undefined,
						},
					])
				}
			/>
		</ContentContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContentContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
	width: '180px',
	maxWidth: '180px',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	padding: '14px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> *': {
		marginBottom: token('space.025'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BetweenContainer = styled.div({
	display: 'flex',
	flexDirection: 'row',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> *': {
		marginRight: token('space.100'),
	},
});
