import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { Filter } from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import {
	createHigherLevelIssueHook3,
	createHigherLevelIssueHook,
	createIssueHook,
} from './utils.tsx';
import {
	createGetFilterTypeForField,
	createIsInFilterResult,
	getActiveFiltersGroupValues,
	getFilteredIssueCount,
	getFilteredIssueIds,
	getIssueIdsConsideringArchived,
} from './filters.tsx';

export const useActiveFiltersGroupValues = createIssueHook(getActiveFiltersGroupValues);

export const useGetFilterTypeForField = createHigherLevelIssueHook<
	FieldKey,
	'NUMBER' | 'FIELD' | 'TEXT' | 'INTERVAL' | undefined
>(createGetFilterTypeForField);

export const useIsInFilterResult = createHigherLevelIssueHook3<
	FieldKey,
	Filter | undefined,
	LocalIssueId,
	boolean
>(createIsInFilterResult);

export const useFilteredIssueIds = createIssueHook(getFilteredIssueIds);

export const useFilteredIssueCount = createIssueHook(getFilteredIssueCount);

export const useIssueIdsConsideringArchived = createIssueHook(getIssueIdsConsideringArchived);
