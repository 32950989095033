// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types.tsx';

export const updateCopyValueTask =
	(
		taskId: string,
		update: {
			status: State['copyValuesTasks']['id']['status'];
			progress: State['copyValuesTasks']['id']['progress'];
			fromFieldLabel: string;
			toFieldLabel: string;
		},
	): Action<State, Props> =>
	async ({ getState, setState }) => {
		setState({
			copyValuesTasks: {
				...getState().copyValuesTasks,
				[taskId]: {
					taskId,
					...update,
				},
			},
		});
	};
