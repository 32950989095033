import React, { useEffect } from 'react';
import Heading from '@atlaskit/heading';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Image from '@atlassian/jira-illustrations/src/ui/adg4/jira/other/assets/jpd-share-view-spotlight.svg';
import { useIntl } from '@atlassian/jira-intl';
import { INVITE_FROM_SHARE_DIALOG_SPOTLIGHT_DISMISSED } from '@atlassian/jira-polaris-lib-onboarding-flows/src/controllers/constants.tsx';
import { JiraSpotlight } from '@atlassian/jira-spotlight/src/ui/jira-spotlight.tsx';
import { useIsSiteAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-site-admin/index.tsx';
import { useOnboardingFlowsActions } from '../../../controllers/index.tsx';
import {
	useGetShareDialogInviteSpotlightDismissed,
	useGetShareDialogShareSpotlightDismissed,
} from '../../../controllers/selectors/share-dialog-hooks.tsx';
import { messages } from './messages.tsx';

export const InviteFromShareDialogSpotlight = () => {
	const { formatMessage } = useIntl();
	const {
		loadInviteFromShareDialogSpotlightDismissed,
		setInviteFromShareDialogSpotlightDismissed,
	} = useOnboardingFlowsActions();
	const shareDialogInviteSpotlightDismissed = useGetShareDialogInviteSpotlightDismissed();
	const shareDialogShareSpotlightDismissed = useGetShareDialogShareSpotlightDismissed();
	const isSiteAdmin = useIsSiteAdmin();

	useEffect(() => {
		loadInviteFromShareDialogSpotlightDismissed();
	}, [loadInviteFromShareDialogSpotlightDismissed]);

	if (
		shareDialogInviteSpotlightDismissed !== false ||
		shareDialogShareSpotlightDismissed !== true ||
		!isSiteAdmin
	) {
		return null;
	}

	return (
		<JiraSpotlight
			actions={[
				{
					onClick: setInviteFromShareDialogSpotlightDismissed,
					text: formatMessage(messages.cta),
				},
			]}
			target={INVITE_FROM_SHARE_DIALOG_SPOTLIGHT_DISMISSED}
			key={INVITE_FROM_SHARE_DIALOG_SPOTLIGHT_DISMISSED}
			messageId="polaris-lib-onboarding-flows.ui.share-dialog.invite.jira-spotlight"
			messageType="engagement"
			dialogWidth={275}
			dialogPlacement="bottom right"
			image={Image}
			targetRadius={3}
			targetBgColor={token('elevation.surface')}
		>
			<Heading size="medium">{formatMessage(messages.title)}</Heading>
			<Box as="p" xcss={contentStyles}>
				{formatMessage(messages.content)}
			</Box>
		</JiraSpotlight>
	);
};

const contentStyles = xcss({
	font: 'font.body',
	marginTop: 'space.100',
});
