import React, { type SyntheticEvent, memo, useState, useCallback } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import AddIcon from '@atlaskit/icon/core/migration/add';
import LinkIcon from '@atlaskit/icon/core/migration/link';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { PRODUCT_DISCOVERY_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import HelpPanelButton from '@atlassian/jira-help-panel-button/src/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useIdeaActions } from '@atlassian/jira-polaris-common/src/controllers/idea/main.tsx';
import {
	useIsSelectedIssueArchived,
	useSelectedIssue,
	useSelectedIssueKey,
	useSelectedIssueSummary,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { useCanManageDeliveryTickets } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { SpotlightTypes } from '@atlassian/jira-polaris-lib-onboarding/src/common/types.tsx';
import { useSpotlights } from '@atlassian/jira-polaris-lib-onboarding/src/controllers/index.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { LinkIssue } from '../../../../../common/ui/link-issue/index.tsx';
import { SendToBacklog } from '../../../../../common/ui/send-to-backlog/index.tsx';
import { Restore } from '../../../restore/index.tsx';
import linkIssueMessages from '../../../../../common/ui/link-issue/messages.tsx';
import JiraBoardIcon from './assets/board.svg';
import messages from './messages.tsx';

type EmptyDeliveryProps = {
	isCompact?: boolean;
};

const HELP_ARTICLE_ID = '79Gsxo13vpSGA8lVFlA88n';
const HELP_FALLBACK_URL =
	'https://support.atlassian.com/jira-product-discovery/docs/create-a-jira-issue-from-jira-product-discovery/';

const LearnMoreLink = () => {
	const { formatMessage } = useIntl();

	return (
		<HelpPanelButton
			appearance="link"
			spacing="none"
			articleId={HELP_ARTICLE_ID}
			onClick={(e: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
				fireCompoundAnalyticsEvent.IdeaView.DeliveryTab.learnMoreButtonClicked(
					analyticsEvent,
					false,
				);
			}}
			fallbackComponent={
				<Button
					appearance="link"
					spacing="none"
					rel="noopener noreferrer"
					target="_blank"
					href={HELP_FALLBACK_URL}
				>
					{formatMessage(messages.learnMoreLink)}
				</Button>
			}
		>
			{formatMessage(messages.learnMoreLink)}
		</HelpPanelButton>
	);
};

export const EmptyDelivery = memo<EmptyDeliveryProps>(({ isCompact }: EmptyDeliveryProps) => {
	const issueKey = useSelectedIssueKey();
	const localIssueId = useSelectedIssue();
	const { formatMessage } = useIntl();
	const canManageDelivery = useCanManageDeliveryTickets();
	const ideaSummary = useSelectedIssueSummary();
	const isIdeaArchived = useIsSelectedIssueArchived();
	const { refreshIssueLinks } = useIdeaActions();
	const [inCreate, setInCreate] = useState(false);
	const [inLink, setInLink] = useState(false);

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const { closeSpotlight } = useSpotlights();

	const onCreate = useCallback(
		(isFromHint?: boolean) => {
			fireCompoundAnalyticsEvent.IdeaView.DeliveryTab.createDeliveryTicketButtonClicked(
				createAnalyticsEvent({}),
				isFromHint,
			);
			setInLink(false);
			setInCreate(true);
		},
		[createAnalyticsEvent],
	);

	const onLink = useCallback(
		(isFromHint?: boolean) => {
			fireCompoundAnalyticsEvent.IdeaView.DeliveryTab.linkDeliveryTicketButtonClicked(
				createAnalyticsEvent({}),
				isFromHint,
			);
			setInCreate(false);
			setInLink(true);
		},
		[createAnalyticsEvent],
	);

	const onCreateCanceled = useCallback(() => {
		setInCreate(false);
	}, []);

	const onCreated = useCallback(() => {
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		refreshIssueLinks(() => {});
		closeSpotlight(SpotlightTypes.CREATE_DELIVERY_TICKET);
	}, [refreshIssueLinks, closeSpotlight]);

	const onLinkCanceled = useCallback(() => {
		setInLink(false);
	}, []);

	const onIssueLinked = useCallback(() => {
		closeSpotlight(SpotlightTypes.CREATE_DELIVERY_TICKET);
	}, [closeSpotlight]);

	if (inCreate) {
		return (
			<CreateContainer>
				<SendToBacklog
					defaultSummary={ideaSummary}
					onCancel={onCreateCanceled}
					onCreated={onCreated}
					isCompact={isCompact}
				/>
			</CreateContainer>
		);
	}

	if (inLink) {
		return (
			<CreateContainer>
				<LinkIssue
					isPolarisIssueLink
					excludedProjectTypes={[PRODUCT_DISCOVERY_PROJECT]}
					onCancel={onLinkCanceled}
					onIssueLinked={onIssueLinked}
					isCompact={isCompact}
					issueKey={issueKey}
					localIssueId={localIssueId}
					captionMessage={formatMessage(linkIssueMessages.heading)}
				/>
			</CreateContainer>
		);
	}

	const descriptionArchived = fg('polaris-issue-terminology-refresh')
		? messages.descriptionArchivedIssueTermRefresh
		: messages.descriptionArchived;

	return (
		<Box xcss={wrapperStyles}>
			<Container>
				<ImageContainer src={JiraBoardIcon} alt="Delivery tickets" />
				<Content>
					{canManageDelivery && (
						<>
							<Box xcss={deliveryTitleStyles}>{formatMessage(messages.title)}</Box>
							<Description>
								{formatMessage(isIdeaArchived ? descriptionArchived : messages.description)}
							</Description>
							{isIdeaArchived ? (
								<Restore />
							) : (
								<ButtonGroup>
									<Button
										id="polaris-idea.ui.sections.deliver.create-epic-button"
										testId="polaris-ideas.ui.idea-view.sections.deliver.empty-state.create-epic-button"
										iconBefore={
											<AddIcon
												label={formatMessage(
													fg('polaris-issue-terminology-refresh')
														? messages.createDeliveryTicketIssueTermRefresh
														: messages.createDeliveryTicket,
												)}
												LEGACY_size="small"
											/>
										}
										appearance="primary"
										onClick={() => onCreate(false)}
										isDisabled={isIdeaArchived}
									>
										{formatMessage(
											fg('polaris-issue-terminology-refresh')
												? messages.createDeliveryTicketIssueTermRefresh
												: messages.createDeliveryTicket,
										)}
									</Button>
									<Box xcss={marginLeftStyles} />
									<Button
										id="polaris-idea.ui.sections.deliver.link-issue-button"
										iconBefore={
											<LinkIcon
												label={formatMessage(
													fg('polaris-issue-terminology-refresh')
														? messages.linkToIssueIssueTermRefresh
														: messages.linkToIssue,
												)}
												LEGACY_size="small"
											/>
										}
										onClick={() => onLink(false)}
										isDisabled={isIdeaArchived}
									>
										{formatMessage(
											fg('polaris-issue-terminology-refresh')
												? messages.linkToIssueIssueTermRefresh
												: messages.linkToIssue,
										)}
									</Button>
								</ButtonGroup>
							)}
						</>
					)}
					{!canManageDelivery && (
						<Description>
							{formatMessage(messages.descriptionReadOnly)} <LearnMoreLink />
						</Description>
					)}
				</Content>
			</Container>
		</Box>
	);
});

const wrapperStyles = xcss({
	width: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CreateContainer = styled.div({
	marginTop: token('space.150'),
	width: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	marginTop: token('space.300'),
	flexWrap: 'wrap',
	gap: token('space.300'),
	paddingTop: token('space.500'),
	paddingRight: token('space.250'),
	paddingBottom: token('space.500'),
	paddingLeft: token('space.250'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Content = styled.div({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	flex: 1,
	maxWidth: '460px',
	width: 'auto',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Description = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'a, button': {
		fontWeight: token('font.weight.regular'),
	},
	textAlign: 'center',
	marginTop: token('space.negative.050'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonGroup = styled.div({
	display: 'flex',
	justifyContent: 'center',
	marginTop: token('space.200'),
});

const marginLeftStyles = xcss({
	marginLeft: 'space.150',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ImageContainer = styled.img({
	height: '226px',
});

const deliveryTitleStyles = xcss({
	textAlign: 'center',
	font: 'font.body.large',
	fontWeight: 'font.weight.semibold',
	marginBottom: 'space.100',
});
