export type DraggableCardData = {
	ideaId: string;
	draggableId: string;
	droppableId: string;
	index: number;
	// CARD_NO_DROP value allow to disbale dropping the card while still being able to drag it
	type: 'CARD' | 'CARD_NO_DROP';
};

export const isDraggableCardData = (data: unknown): data is DraggableCardData =>
	typeof data === 'object' && data !== null && 'type' in data && data.type === 'CARD';

export type DraggableColumnHeaderData = {
	draggableId: string;
	index: number;
	type: 'COLUMN_HEADER' | 'STICKY_COLUMN_HEADER';
};

export const isDraggableColumnHeaderData = (data: unknown): data is DraggableColumnHeaderData => {
	return (
		typeof data === 'object' &&
		data !== null &&
		'type' in data &&
		['COLUMN_HEADER', 'STICKY_COLUMN_HEADER'].some((type) => type === data.type)
	);
};

export type DraggableColumnData = {
	draggableId: string;
	droppableId: string;
	index: number;
	ideaIds: string[];
	type: 'COLUMN';
};

export const isDraggableColumnData = (data: unknown): data is DraggableColumnData =>
	typeof data === 'object' && data !== null && 'type' in data && data.type === 'COLUMN';

export type DraggableGroupData = {
	draggableId: string;
	index: number;
	type: 'GROUP';
};

export const isDraggableGroupData = (data: unknown): data is DraggableGroupData =>
	typeof data === 'object' && data !== null && 'type' in data && data.type === 'GROUP';

export type DragState = 'idle' | 'preview' | 'dragging';

export type DraggableLocation = {
	droppableId: string;
	index: number;
};

export type DropResult = {
	source?: DraggableLocation;
	destination?: DraggableLocation;
};
