import trim from 'lodash/trim';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import { defaultScheme } from '../../../../common/utils/scheme/index.tsx';
import type { Props, State } from '../../types.tsx';

export const addAsLink =
	(): Action<State, Props> =>
	({ setState }, { url: dirtyUrl, onObjectResolved }) => {
		let url = trim(dirtyUrl);
		if (url === undefined || url === '') {
			return;
		}
		url = defaultScheme(url, 'https');
		const resolvedObject = {
			url,
			isUnsupported: true,
			oauthClientId: undefined,
			snippetData: undefined,
			properties: undefined,
		};
		setState({
			isResolving: false,
			tryAgain: false,
			auth: undefined,
			error: undefined,
			authToken: undefined,
			apiKey: undefined,
			resolvedObject,
		});
		onObjectResolved && onObjectResolved(resolvedObject);
	};
