import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import type { JPDExperience } from '@atlassian/jira-polaris-lib-analytics/src/common/utils/experience/types.tsx';
import { ValidationError } from '@atlassian/jira-fetch/src/utils/errors.tsx';

export const handleError = (experience: JPDExperience, error?: Error) => {
	if (error && isClientFetchError(error)) {
		return experience.abort(error);
	}

	if (error && error instanceof ValidationError) {
		return experience.abort(error);
	}

	return experience.failure(error);
};
