import React, { useEffect } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import { Box, Text, xcss } from '@atlaskit/primitives';
import SectionMessage, { SectionMessageAction } from '@atlaskit/section-message';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useOnboardingFlowsActions } from '../../../controllers/index.tsx';
import { useTimelineSectionMessageDismissed } from '../../../controllers/selectors/timeline-view-spotlight-hooks.tsx';
import { messages } from './messages.tsx';

export const TimelineSectionMessage = () => {
	const { formatMessage } = useIntl();
	const isTimelineSectionMessageDismissed = useTimelineSectionMessageDismissed();
	const { loadTimelineSectionMessageDismissed, setTimelineSectionMessageDismissed } =
		useOnboardingFlowsActions();

	useEffect(() => {
		loadTimelineSectionMessageDismissed();
	}, [loadTimelineSectionMessageDismissed]);

	if (isTimelineSectionMessageDismissed !== false) {
		return null;
	}

	return (
		<SectionMessageWrapper>
			<SectionMessage
				appearance="discovery"
				actions={[
					<SectionMessageAction key={messages.timelineFieldsSpotlightOkBtn.id}>
						<Button
							spacing="compact"
							appearance="default"
							onClick={setTimelineSectionMessageDismissed}
						>
							{formatMessage(messages.timelineFieldsSpotlightOkBtn)}
						</Button>
					</SectionMessageAction>,
					<SectionMessageAction key={messages.timelineFieldsSpotlightLearnMore.id}>
						<Box xcss={okSectionButtonStyles}>
							<Button
								appearance="link"
								spacing="none"
								href="https://support.atlassian.com/jira-product-discovery/docs/configure-autofill-dates/"
								target="_blank"
							>
								{formatMessage(messages.timelineFieldsSpotlightLearnMore)}
							</Button>
						</Box>
					</SectionMessageAction>,
				]}
			>
				<Text>
					{formatMessage(
						fg('polaris-issue-terminology-refresh')
							? messages.timelineFieldsSpotlightTextIssueTermRefresh
							: messages.timelineFieldsSpotlightText,
					)}
				</Text>
			</SectionMessage>
		</SectionMessageWrapper>
	);
};

const okSectionButtonStyles = xcss({
	alignSelf: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SectionMessageWrapper = styled.div({
	marginTop: 0,
	marginRight: token('space.200'),
	marginBottom: 0,
	marginLeft: token('space.200'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'& > section > div > div:first-child': {
		display: 'none',
	},
});
