/** @jsx jsx */
import React, { memo, useCallback, useEffect } from 'react';
import { css, jsx } from '@compiled/react';
import type { EmojiDescription } from '@atlaskit/emoji';
import { JiraProductDiscoveryLogo } from '@atlaskit/logo';
import { Flex, xcss, Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Blanket from '@atlaskit/blanket';
import { useIsExportingViewImage } from '@atlassian/jira-polaris-component-view-export/src/controllers/selectors.tsx';
import { PRODUCT_DISCOVERY_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import { useHasNoProjectPermissions } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { Visitors } from '@atlassian/jira-polaris-component-view-visitors/src/ui/index.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { ViewEmojiSpotlightProvider } from '@atlassian/jira-polaris-lib-control-sharing/src/controllers/view-emoji-spotlight-provider/index.tsx';
import { EditableViewTitle } from '@atlassian/jira-polaris-lib-editable-view-title/src/ui/index.tsx';
import { whyDidYouRenderPlaceholder } from '@atlassian/jira-polaris-why-did-you-render-placeholder/src/index.tsx';
import {
	useAnalyticsEvents,
	ContextualAnalyticsData,
	SCREEN,
	fireUIAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import type { View, ViewKind } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import type { SharingSettings } from '@atlassian/jira-polaris-domain-view/src/sharing/settings/index.tsx';
import { useIsPremiumEdition } from '@atlassian/jira-polaris-lib-entitlement-utils/src/index.tsx';
import { useMarkViewAsSeenLocally } from '@atlassian/jira-polaris-component-view-visitors/src/controllers/index.tsx';
import { ViewBreadcrumbs } from './breadcrumbs/index.tsx';
import { FeedbackButton } from './feedback-button/index.tsx';
import { EapLabel } from './eap-label/index.tsx';

type JpdLogoLinkProps = {
	children: React.ReactNode;
	sharingSettings?: SharingSettings;
};

export type ViewHeaderProps = {
	pageMarginX: number;
	isCollectionView: boolean;
	isSharedView: boolean;
	isInitialized: boolean;
	isIssuesLoading: boolean;
	isRightSidebarOpen: boolean;
	isIssueOpenInSidebar: boolean;
	isIssueOpenInFullscreen: boolean;
	isEditingTitle: boolean;
	title?: string;
	isArchiveView: boolean;
	viewKind?: ViewKind;
	emojiId?: string;
	canManageCurrentView: boolean;
	currentViewId?: string;
	viewUUID?: string;
	sharingSettings?: SharingSettings;
	setEditCurrentViewTitle: (isEditing: boolean) => void;
	viewButtons?: React.ReactNode;
	fullscreenButton?: React.ReactNode;
	avatarControl: React.ReactNode;
	viewIdeaCount: React.ReactNode;
	autoOpenSidebarCondition?: boolean;
	onRenameCurrentView: (
		newTitle: string,
		onAfterSave?: ((arg1: View | undefined) => void) | undefined,
	) => void;
	onAddCurrentViewEmoji: () => void;
	onOpenRightSidebarOnViewInfo: () => void;
	setFeedbackModalOpen: () => void;
	projectsPath: string;
	projectName?: string;
};

const PUBLIC_UTM_LINK =
	'https://www.atlassian.com/software/jira/product-discovery?utm_source=product-logo&utm_medium=in-product&utm_campaign=P%3Ajira-product-discovery%7CO%3Apmm-devops-agile%7CF%3Aawareness%7CC%3Aweb%7CW%3Asingle%7CE%3Acloud%7CD%3Adesktop%7CL%3Aen%7CI%3Ajpd-public-view';
const STAKEHOLDERS_UTM_LINK =
	'https://www.atlassian.com/software/jira/product-discovery?utm_source=product-logo&utm_medium=in-product&utm_campaign=P%3Ajira-product-discovery%7CO%3Apmm-devops-agile%7CF%3Aawareness%7CC%3Aweb%7CW%3Asingle%7CE%3Acloud%7CD%3Adesktop%7CL%3Aen%7CI%3Ajpd-view-publishing';

const getJpdProjectsListLink = () => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const siteUrl = `${window.location.protocol}//${window.location.host}`;
	return `${siteUrl}/jira/projects?types=${PRODUCT_DISCOVERY_PROJECT}`;
};

const JpdLogoLink = ({ children, sharingSettings }: JpdLogoLinkProps) => {
	const [hasNoProjectPermissions] = useHasNoProjectPermissions();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const isPublicView = sharingSettings?.publicSharingEnabled;
	let sharedViewLogoLink: string;
	if (hasNoProjectPermissions) {
		if (isPublicView) {
			sharedViewLogoLink = PUBLIC_UTM_LINK;
		} else {
			sharedViewLogoLink = STAKEHOLDERS_UTM_LINK;
		}
	} else {
		sharedViewLogoLink = getJpdProjectsListLink();
	}

	return (
		<a
			data-testid="polaris-component-view-header.ui.shared-view-logo"
			href={sharedViewLogoLink}
			id="pendo.shared-view.logo-link"
			onClick={() => {
				fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'jpdLogo');
			}}
		>
			{children}
		</a>
	);
};

export const ViewHeader = memo((props: ViewHeaderProps) => {
	const {
		pageMarginX,
		isCollectionView,
		isSharedView,
		isInitialized,
		isIssuesLoading,
		isRightSidebarOpen,
		isIssueOpenInSidebar,
		isIssueOpenInFullscreen,
		isEditingTitle,
		title,
		isArchiveView,
		viewKind,
		emojiId,
		canManageCurrentView,
		currentViewId,
		viewUUID,
		onRenameCurrentView,
		setEditCurrentViewTitle,
		onAddCurrentViewEmoji,
		viewButtons,
		fullscreenButton,
		avatarControl,
		viewIdeaCount,
		onOpenRightSidebarOnViewInfo,
		autoOpenSidebarCondition,
		setFeedbackModalOpen,
		projectsPath,
		projectName,
	} = props;

	const shouldShowFullscreenButtonInArchiveView =
		isArchiveView &&
		!isIssuesLoading &&
		!isRightSidebarOpen &&
		!isIssueOpenInSidebar &&
		!isIssueOpenInFullscreen;

	const isExportingViewImage = useIsExportingViewImage();

	const markViewAsSeenLocally = useMarkViewAsSeenLocally();

	useEffect(() => {
		if (autoOpenSidebarCondition) {
			// tracing `jpd.right-sidebar.view-info` will mess up the view page load experience - do not trace
			onOpenRightSidebarOnViewInfo();
			if (viewUUID !== undefined) {
				markViewAsSeenLocally(viewUUID);
			}
		}
	}, [onOpenRightSidebarOnViewInfo, autoOpenSidebarCondition, markViewAsSeenLocally, viewUUID]);

	const isPremiumEdition = useIsPremiumEdition();

	const onCancelEditTitle = useCallback(() => {
		setEditCurrentViewTitle(false);
		experience.listView.changeViewName.abort();
	}, [setEditCurrentViewTitle]);

	const onEditTitle = useCallback(() => {
		setEditCurrentViewTitle(true);
		experience.listView.changeViewName.start();
	}, [setEditCurrentViewTitle]);

	const onRenameView = useCallback(
		(newTitle: string) => {
			onRenameCurrentView(newTitle, (view): void => {
				if (isClientFetchError(view?.saveError)) {
					experience.listView.changeViewName.abort(view?.saveError);
				} else if (view && view.saveError) {
					experience.listView.changeViewName.failure(view.saveError);
				} else if (view && !view.saveError) {
					experience.listView.changeViewName.success();
				}
			});
		},
		[onRenameCurrentView],
	);

	const sharedViewLogo = (
		<Box xcss={logoContainerStyles}>
			<JiraProductDiscoveryLogo size="small" appearance="brand" />
		</Box>
	);

	const renderHeaderContent = (localProps?: {
		setLoadedEmoji: (emoji: EmojiDescription | null) => void;
	}) => (
		<div css={menuContainerStyles}>
			<Flex xcss={viewTitleWrapperStyles}>
				<EditableViewTitle
					title={title}
					emojiId={emojiId}
					viewKind={viewKind}
					isEditingTitle={isEditingTitle}
					onEmojiChange={onAddCurrentViewEmoji}
					onEmojiLoaded={localProps?.setLoadedEmoji}
					onTitleEdit={onEditTitle}
					onTitleCancel={onCancelEditTitle}
					onTitleRename={onRenameView}
					isReadOnly={!canManageCurrentView || isArchiveView || isRightSidebarOpen}
					isLoading={isIssuesLoading || !isInitialized}
				/>
				{!isEditingTitle && !isIssuesLoading && viewIdeaCount}
			</Flex>
			{isArchiveView ||
			isIssuesLoading ||
			isRightSidebarOpen ||
			isIssueOpenInSidebar ||
			isIssueOpenInFullscreen ||
			isSharedView ? null : (
				<>
					{!isCollectionView && (
						<Visitors testId="polaris-component-view-header.ui.visitors" viewUUID={viewUUID} />
					)}
					{viewButtons}
				</>
			)}
			{shouldShowFullscreenButtonInArchiveView && fullscreenButton}
		</div>
	);

	return (
		<ContextualAnalyticsData sourceName="viewHeader" sourceType={SCREEN}>
			{whyDidYouRenderPlaceholder('ViewHeader')}
			{isExportingViewImage && <Blanket isTinted />}
			<Box
				xcss={[wrapperStyles, isSharedView && sharedViewWrapperStyles]}
				// eslint-disable-next-line jira/react/no-style-attribute
				style={{ padding: `0 ${pageMarginX}px` }}
			>
				{!isSharedView && (
					<Flex
						xcss={[
							viewHeaderTopRowWrapper,
							(isRightSidebarOpen || isIssueOpenInSidebar) && viewHeaderTopRowWithoutButtonsStyles,
						]}
					>
						{!isIssuesLoading && (
							<ViewBreadcrumbs
								projectName={projectName}
								isCollectionView={isCollectionView}
								projectsPath={projectsPath}
							/>
						)}
						{!isRightSidebarOpen && !isIssueOpenInSidebar && (
							<Flex alignItems="center" gap="space.150" xcss={feedbackButtonWrapper}>
								<FeedbackButton setFeedbackModalOpen={setFeedbackModalOpen} />
								{isPremiumEdition && <EapLabel />}
							</Flex>
						)}
					</Flex>
				)}
				<Flex direction="column" gap="space.075" xcss={headerContentContainerStyles}>
					<Flex justifyContent="space-between" alignItems="center" gap="space.150">
						<ViewEmojiSpotlightProvider key={currentViewId}>
							{renderHeaderContent}
						</ViewEmojiSpotlightProvider>
					</Flex>
				</Flex>
				{isSharedView && !isIssuesLoading && (
					<div css={[menuContainerStyles, menuContainerSharedViewStyles]}>
						{viewButtons}
						<Flex
							alignItems="center"
							data-component-selector="shared-view-logo-container-Aw12"
							testId="polaris-component-view-header.ui.shared-view-logo-container"
							xcss={sharedViewLogoContainerStyles}
						>
							<JpdLogoLink>{sharedViewLogo}</JpdLogoLink>
							{avatarControl}
						</Flex>
					</div>
				)}
			</Box>
		</ContextualAnalyticsData>
	);
});

const menuContainerStyles = css({
	display: 'flex',
	alignItems: 'center',
	gap: token('space.075'),
	width: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'* + [data-component-selector="shared-view-logo-container-Aw12"]::before': {
		marginRight: token('space.200'),
		content: '',
		position: 'absolute',
		left: 0,
		top: '3px',
		bottom: '3px',
		borderLeft: `1px solid ${token('color.border')}`,
	},
});

const menuContainerSharedViewStyles = css({
	justifyContent: 'flex-end',
});

const wrapperStyles = xcss({
	position: 'relative',
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	flexDirection: 'column',
	boxSizing: 'border-box',
	marginTop: 'space.250',
	marginBottom: 'space.050',
	gap: 'space.075',
});

const sharedViewWrapperStyles = xcss({
	marginBottom: 'space.150',
	marginTop: 'space.negative.050',
	paddingTop: 'space.100',
	paddingBottom: 'space.100',
	boxShadow: 'elevation.shadow.overflow',
	minHeight: '70px',
	flexDirection: 'row',
});

const logoContainerStyles = xcss({
	height: '24px',
	marginInline: 'space.200',
	marginBlock: '0',
});

const sharedViewLogoContainerStyles = xcss({
	position: 'relative',
	marginLeft: 'space.050',
});

const headerContentContainerStyles = xcss({
	flexGrow: 1,
	width: '100%',
});

const viewTitleWrapperStyles = xcss({
	alignItems: 'center',
	width: '100%',
	minWidth: '0',
});

const viewHeaderTopRowWrapper = xcss({
	width: '100%',
	justifyContent: 'space-between',
	alignItems: 'center',
	paddingBlockEnd: 'space.025',
	flexWrap: 'wrap',
});

const feedbackButtonWrapper = xcss({
	marginLeft: 'auto',
});

const viewHeaderTopRowWithoutButtonsStyles = xcss({
	minHeight: '34px',
});
