import React, { useCallback, type SyntheticEvent } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import CalendarIcon from '@atlaskit/icon/core/migration/calendar--editor-date';
import { Flex } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useTimelineDuration } from '@atlassian/jira-polaris-common/src/controllers/issue/utils/view-filtering/view-timeline/index.tsx';
import { useOpenRightSidebarTimelineFieldConfig } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/actions/hooks.tsx';
import { useRightSidebarShowing } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/selectors/hooks.tsx';
import { RightSidebarShowingTimelineConfig } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/types.tsx';
import { useHasTimelineConfigUnsavedChanges } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { sendPendoTrackEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/pendo/index.tsx';
import { formatMonthAndYearDate } from '@atlassian/jira-polaris-lib-date-time/src/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { fg } from '@atlassian/jira-feature-gating';
import { ActionButton } from '../action-button/index.tsx';
import { messages } from './messages.tsx';

export const ConfigTimeline = () => {
	const [sidebarShowing] = useRightSidebarShowing();
	const openTimelineFieldConfig = useOpenRightSidebarTimelineFieldConfig();
	const { startDate, endDate } = useTimelineDuration();
	const { formatMessage } = useIntl();

	const formattedStartDate = startDate ? formatMonthAndYearDate(startDate) : undefined;
	const formattedEndDate = endDate ? formatMonthAndYearDate(endDate) : undefined;
	const timelineRangeLabel = `${formattedStartDate} - ${formattedEndDate}`;

	const handleOpenTimelineConfig = useCallback(
		(event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'timelineSettings');
			sendPendoTrackEvent({
				actionSubjectAndAction: 'openTimelineSettingsSidebarButton clicked',
			});
			openTimelineFieldConfig();
		},
		[openTimelineFieldConfig],
	);

	const hasTimelineConfigUnsavedChanges = useHasTimelineConfigUnsavedChanges();

	return (
		<ActionButton
			interactionName="jpd.view-controls.open-timeline-config-sidebar"
			id="polaris.ideas.ui.view-controls.open-timeline-config-sidebar"
			testId="polaris-ideas.ui.view-controls.config-timeline.action-button"
			isSelected={sidebarShowing.mode === RightSidebarShowingTimelineConfig}
			isEmpty={!formattedStartDate && !formattedEndDate}
			onClick={handleOpenTimelineConfig}
			hasUnsavedChanges={hasTimelineConfigUnsavedChanges}
		>
			<>
				{fg('polars_timeline_config_button_css_fix') ? (
					<Flex
						gap={isVisualRefreshEnabled() ? 'space.100' : 'space.050'}
						alignItems="center"
						wrap="nowrap"
					>
						<CalendarIcon
							label=""
							testId="polaris-ideas.ui.view-controls.config-timeline.calendar-icon"
						/>
						{formattedStartDate && formattedEndDate
							? timelineRangeLabel
							: formatMessage(messages.timelineLabel)}
					</Flex>
				) : (
					<TitleWrapper>
						<CalendarIcon spacing="spacious" label="calendar" />
						<Label>
							{formattedStartDate && formattedEndDate
								? timelineRangeLabel
								: formatMessage(messages.timelineLabel)}
						</Label>
					</TitleWrapper>
				)}
			</>
		</ActionButton>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Label = styled.span({
	marginLeft: token('space.050'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TitleWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
});
