// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import { getCreatorsList } from '../../../services/jira/get-creators-list/index.tsx';
import type { State, Props } from '../../types.tsx';

export const loadCreators =
	(users: string[], groups: string[]): Action<State, Props> =>
	({ setState }, { projectId, onError }) => {
		getCreatorsList(projectId, users, groups)
			.then((response) => {
				setState({
					creators: response,
				});
			})
			.catch((err) => onError(err));
	};
