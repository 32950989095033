import React, { useCallback, useEffect, useState } from 'react';
import { SpotlightTransition } from '@atlaskit/onboarding';
import { N0 } from '@atlaskit/theme/colors';
import Shortcuts from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcuts/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import {
	useUserPropertiesActions,
	useUserProperty,
} from '@atlassian/jira-polaris-common/src/controllers/user-properties/index.tsx';
import { IssueCreationModalDialog } from '@atlassian/jira-polaris-common/src/ui/create-idea-dialog/form-modal-dialog/index.tsx';
import { useIsPreviewContributorMode } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/main.tsx';
import {
	useCanCreateIssues,
	useCanEditIssues,
} from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import ExperienceFailErrorBoundary from '@atlassian/jira-polaris-lib-analytics/src/ui/index.tsx';
import { JiraSpotlight } from '@atlassian/jira-spotlight/src/ui/jira-spotlight.tsx';
import messages from './messages.tsx';

const CREATE_IDEA_CONTRIBUTORS_HINT_CLOSED_KEY = 'polaris.create-idea-by-contributors-hint-closed';

export const GLOBAL_CREATE_BUTTON_SELECTOR = '#createGlobalItem';
export const GLOBAL_CREATE_ICON_BUTTON_SELECTOR = '#createGlobalItemIconButton';
export const GLOBAL_CREATE_BUTTON_SELECTOR_NAV_V4 =
	'[data-testid="atlassian-navigation--create-button"]';

export const CreateIssueInterceptor = () => {
	const { formatMessage } = useIntl();

	const [isOpen, setIsOpen] = useState(false);
	const [createButton, setCreateButton] = useState<HTMLElement | undefined>();
	const [canCreateIssues] = useCanCreateIssues();
	const [canEditIssues] = useCanEditIssues();
	const [isPreviewContributorMode] = useIsPreviewContributorMode();

	const shouldAddListener = canCreateIssues || isPreviewContributorMode;

	const [, { loadProperty, saveProperty }] = useUserPropertiesActions();
	const [{ value: isHintClosed, isLoading: isLoadingHint, error: hintClosedError }] =
		useUserProperty(CREATE_IDEA_CONTRIBUTORS_HINT_CLOSED_KEY);

	useEffect(() => {
		loadProperty(CREATE_IDEA_CONTRIBUTORS_HINT_CLOSED_KEY);
	}, [loadProperty]);

	const handleCloseHint = useCallback(() => {
		saveProperty(CREATE_IDEA_CONTRIBUTORS_HINT_CLOSED_KEY, true);
	}, [saveProperty]);

	const userType = canCreateIssues && canEditIssues ? 'USER_TYPE_CREATOR' : 'USER_TYPE_CONTRIBUTOR';

	const onClick = useCallback(
		(e?: Event) => {
			if (shouldAddListener) {
				experience.issues.globalJpdIssueCreate.start();
				setIsOpen(true);
				e?.stopImmediatePropagation();
			}
		},
		[shouldAddListener],
	);

	const findCreateBtn = useCallback(() => {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		let element = document.querySelector(GLOBAL_CREATE_BUTTON_SELECTOR);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		if (element && window.getComputedStyle(element).display === 'none') {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			element = document.querySelector(GLOBAL_CREATE_ICON_BUTTON_SELECTOR);
		}
		if (getWillShowNav4() && !element) {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			element = document.querySelector(GLOBAL_CREATE_BUTTON_SELECTOR_NAV_V4);
		}
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		setCreateButton(element as HTMLElement);
	}, []);

	useEffect(() => {
		findCreateBtn();

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.addEventListener('resize', findCreateBtn);
		return () => {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.removeEventListener('resize', findCreateBtn);
		};
	}, [findCreateBtn]);

	useEffect(() => {
		if (!getWillShowNav4()) {
			return;
		}

		const observer = new MutationObserver((mutations) => {
			if (createButton?.isConnected && createButton?.parentNode) {
				return;
			}
			// Only check if mutations affect our target elements
			const shouldCheck = mutations.some((mutation) => {
				return Array.from(mutation.removedNodes).some(
					(node) =>
						node === createButton ||
						(node instanceof Element && createButton && node.contains(createButton)),
				);
			});

			if (shouldCheck) {
				findCreateBtn();
			}
		});

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		const container = document.querySelector('header');
		if (container && createButton) {
			observer.observe(container, {
				childList: true,
				subtree: true,
			});
		}

		const timeoutId = setTimeout(() => {
			findCreateBtn();
		}, 500);

		return () => {
			clearTimeout(timeoutId);
			observer.disconnect();
		};
	}, [findCreateBtn, createButton]);

	useEffect(() => {
		try {
			if (shouldAddListener && createButton) {
				createButton.addEventListener('click', onClick);
			}
		} catch (e) {
			// silently ignore it to not break anything
		}
		return () => {
			try {
				createButton && createButton.removeEventListener('click', onClick);
			} catch (e) {
				// silently ignore it to not break anything
			}
		};
	}, [createButton, onClick, shouldAddListener, userType]);

	return (
		<>
			<SpotlightTransition>
				{isPreviewContributorMode &&
					isHintClosed !== true &&
					hintClosedError === null &&
					!isLoadingHint && (
						<JiraSpotlight
							actions={[
								{
									onClick: handleCloseHint,
									text: formatMessage(messages.spotlightCloseBtn),
								},
							]}
							target="global-create-idea"
							targetNode={createButton}
							key="global-create-idea"
							dialogPlacement="bottom center"
							dialogWidth={224}
							targetRadius={3}
							targetBgColor={N0}
							messageId="polaris-ideas.ui.create-issue-interceptor.jira-spotlight.global-create-idea"
							messageType="transactional"
						>
							{formatMessage(messages.spotlightCreateIdeaBtn)}
						</JiraSpotlight>
					)}
			</SpotlightTransition>
			<ExperienceFailErrorBoundary experience={[experience.issues.globalJpdIssueCreate]}>
				<IssueCreationModalDialog isOpenModal={isOpen} setIsOpen={setIsOpen} />
			</ExperienceFailErrorBoundary>
			<Shortcuts
				keyMap={{
					c: {
						priority: 1,
						callback: onClick,
					},
				}}
			/>
		</>
	);
};
