type IssueData = {
	fields: {
		[key: string]: unknown;
	};
};

type GetIssueConnectionUpdatesArg = {
	selectedIssuesData: IssueData[];
	connectionFieldKeys: string[];
};

export const getIssuesToConnect = ({
	selectedIssuesData,
	connectionFieldKeys,
}: GetIssueConnectionUpdatesArg): { id: string }[] => {
	const issuesToConnect = new Set<string>();

	connectionFieldKeys.forEach((fieldKey) => {
		selectedIssuesData.forEach((selectedIssueData) => {
			const selectedIssueFieldValue = selectedIssueData.fields[fieldKey];

			if (Array.isArray(selectedIssueFieldValue)) {
				selectedIssueFieldValue.map((item) => issuesToConnect.add(item.toString()));
			}
		});
	});

	return [...issuesToConnect].map((id) => ({ id }));
};
