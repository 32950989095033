import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	issueModalAriaLabel: {
		id: 'issue.views.issue-details.issue-modal.aria-label',
		defaultMessage: 'Issue view modal',
		description: 'Aria label for the issue view modal',
	},
	issueModalAriaLabelIssueTermRefresh: {
		id: 'issue.views.issue-details.issue-modal.aria-label-issue-term-refresh',
		defaultMessage: 'Work item modal',
		description: 'Aria label for the issue view modal',
	},
});
