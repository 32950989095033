import {
	type AccessLevel,
	PRIVATE_ACCESS_LEVEL,
} from '@atlassian/jira-shared-types/src/project.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import { addContributorGroupToProject } from '../../../services/jira/add-contributor-group/index.tsx';
import { getCurrentAccessLevel } from '../../../services/jira/get-current-access-level/index.tsx';
import { getProjectAccessLevels } from '../../../services/jira/get-project-access-levels/index.tsx';
import { removeContributorGroupFromProject } from '../../../services/jira/remove-contributor-group/index.tsx';
import { setProjectAccessLevel } from '../../../services/jira/set-project-access-level/index.tsx';
import { isIdentityBasedContributorHandlingEnabled } from '../../selectors/contributor-group.tsx';
import type { Props, State } from '../../types.tsx';
import { loadProjectActors } from '../load-project-actors/index.tsx';

export const getCurrentProjectAccessLevel =
	(): Action<State, Props> =>
	({ setState }, { projectId, onError }) => {
		getCurrentAccessLevel(projectId)
			.then((response) => {
				setState({
					accessLevel: response,
				});
			})
			.catch((err) => onError(err));
	};

export const getAvailableProjectAccessLevels =
	(): Action<State, Props> =>
	({ setState }, { onError }) => {
		getProjectAccessLevels()
			.then((response) => {
				setState({
					availableAccessLevels: response,
				});
			})
			.catch((err) => onError(err));
	};

export const setCurrentProjectAccessLevel =
	(accessLevel: AccessLevel): Action<State, Props> =>
	async (
		{ getState, setState, dispatch },
		{ projectId, projectKey, onError, onProjectAccessLevelChanged },
	) => {
		const isContributorGroupEnabled =
			getState().contributorGroupEnabled || isIdentityBasedContributorHandlingEnabled(getState());

		setState({
			ui: {
				...getState().ui,
				accessLevel: {
					...getState().ui.accessLevel,
					isChangeInProgress: true,
				},
			},
		});

		try {
			if (isContributorGroupEnabled && accessLevel === PRIVATE_ACCESS_LEVEL) {
				await removeContributorGroupFromProject({ projectId });
			}

			await setProjectAccessLevel(accessLevel, projectId, projectKey).then((response) => {
				setState({
					accessLevel: response?.accessLevel,
				});
			});

			onProjectAccessLevelChanged && onProjectAccessLevelChanged(accessLevel);

			if (isContributorGroupEnabled && accessLevel !== PRIVATE_ACCESS_LEVEL) {
				await addContributorGroupToProject({ projectId });
			}
			dispatch(loadProjectActors());
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (err: any) {
			onError(err);
		} finally {
			setState({
				ui: {
					...getState().ui,
					accessLevel: {
						...getState().ui.accessLevel,
						isChangeInProgress: false,
					},
				},
			});
		}
	};
