import React from 'react';
import {
	useIsEmbedded,
	useIsSharedView,
} from '@atlassian/jira-polaris-common/src/controllers/environment/index.tsx';
import { useLinkedDeliveryIssuesExist } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { useIsExportingViewImage } from '@atlassian/jira-polaris-component-view-export/src/controllers/selectors.tsx';
import type { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { DeliveryFieldInteractive, DeliveryFieldPreview } from './delivery/index.tsx';
import {
	EmptyDeliveryInteractive,
	EmptyDeliveryPreview,
	EmptyDeliveryNext,
} from './empty/index.tsx';

type InteractiveProps = {
	localIssueId: LocalIssueId;
	appearance: 'list' | 'board';
	type: typeof FIELD_TYPES.DELIVERY_PROGRESS | typeof FIELD_TYPES.DELIVERY_STATUS;
};

type Props = {
	interactive: boolean;
} & InteractiveProps;

export const LinkedIssuesField = ({ localIssueId, appearance, type, interactive }: Props) => {
	const embedded = useIsEmbedded();
	const isSharedView = useIsSharedView();
	const linkedIssuesExist = useLinkedDeliveryIssuesExist(localIssueId);
	const isExportingViewImage = useIsExportingViewImage();

	if (linkedIssuesExist) {
		// has value
		if (!interactive || embedded || isSharedView) {
			return (
				<DeliveryFieldPreview type={type} issueId={localIssueId} hideTooltip={!isSharedView} />
			);
		}
		return <DeliveryFieldInteractive type={type} issueId={localIssueId} />;
	}

	if (embedded || isSharedView) {
		// no value and embedded - render nothing
		return null;
	}

	// render empty cell
	if (interactive) {
		return fg('jpd_delivery_ticket_improvements') ? (
			<EmptyDeliveryNext issueId={localIssueId} />
		) : (
			<EmptyDeliveryInteractive appearance={appearance} issueId={localIssueId} />
		);
	}

	if (isExportingViewImage) {
		return null;
	}
	return fg('jpd_delivery_ticket_improvements') ? (
		<EmptyDeliveryNext issueId={localIssueId} />
	) : (
		<EmptyDeliveryPreview />
	);
};
