import React, { useCallback } from 'react';
import { styled } from '@compiled/react';
import { DatePicker } from '@atlaskit/datetime-picker';
import InlineEdit, { type InlineEditProps } from '@atlaskit/inline-edit';
import { token } from '@atlaskit/tokens';
import type { ValueDecoration } from '@atlassian/jira-polaris-domain-field/src/decoration/types.tsx';
import { DATE_FORMAT_DFNS } from '@atlassian/jira-polaris-lib-date-time/src/index.tsx';
import { useLocale } from '@atlassian/jira-tenant-context-controller/src/components/locale/index.tsx';
import { DecoratedDate } from '../../decoration/date/index.tsx';

type DateFieldReadViewProps = {
	value?: string;
	skipWrap?: boolean | undefined;
	decoration: ValueDecoration | undefined;
	placeholder?: string | undefined;
	testId?: string;
};

type DateFieldEditViewProps = {
	value?: string;
	onUpdate: ((inputValue: string | undefined) => void) | undefined;
	onEdit?: () => void;
	onCancel?: () => void;
};

type DateFieldProps = {
	isEditable: boolean;
} & DateFieldReadViewProps &
	DateFieldEditViewProps;

export const DateFieldReadView = ({
	value,
	decoration,
	placeholder,
	skipWrap,
	testId,
}: DateFieldReadViewProps) => (
	<ReadViewContainer data-testid={testId}>
		{(value !== undefined || placeholder !== undefined) && (
			<DecoratedDate
				datetime={value}
				decoration={decoration}
				skipWrap={skipWrap}
				placeholder={placeholder}
			/>
		)}
	</ReadViewContainer>
);

export const DateFieldEditView = ({
	value,
	onUpdate,
	onEdit,
	onCancel,
}: DateFieldEditViewProps) => {
	const locale = useLocale();

	// value is expected in ISO format YYYY-MM-DD and needs to be converted properly
	const onChange = useCallback(
		(val: string) => {
			if (!onUpdate) {
				return;
			}

			if (val === '') {
				onUpdate(undefined);
			} else {
				onUpdate(val);
			}
		},
		[onUpdate],
	);

	return (
		<EditViewContainer>
			<DatePicker
				spacing="compact"
				value={value}
				onChange={onChange}
				onFocus={onEdit}
				onBlur={onCancel}
				locale={locale}
				dateFormat={DATE_FORMAT_DFNS}
				placeholder=" "
			/>
		</EditViewContainer>
	);
};

export const DateField = ({
	value,
	decoration,
	onUpdate,
	isEditable,
	placeholder,
	skipWrap,
	onEdit,
	onCancel,
	testId,
}: DateFieldProps) => {
	const initialValue = value !== undefined ? value : new Date().toISOString();

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleConfirm = (newValue: any) => {
		const valueForUpdate = newValue === '' ? undefined : newValue;
		onUpdate && onUpdate(valueForUpdate);
	};

	const readView = () => (
		<DateFieldReadView
			placeholder={placeholder}
			value={value}
			decoration={decoration}
			skipWrap={skipWrap}
			testId={testId}
		/>
	);

	const editView: InlineEditProps<string>['editView'] = (fieldProps) => (
		<DatePicker {...fieldProps} autoFocus spacing="compact" />
	);

	return isEditable ? (
		<Container>
			<InlineEdit
				defaultValue={initialValue}
				readViewFitContainerWidth
				onConfirm={handleConfirm}
				readView={readView}
				editView={editView}
				onEdit={onEdit}
				onCancel={onCancel}
			/>
		</Container>
	) : (
		// Inline Edit component wraps a border around the read view component, we do the same here
		// eslint-disable-next-line jira/react/no-style-attribute, @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
		<div style={{ border: '2px solid transparent' }}>{readView()}</div>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	marginTop: token('space.negative.100'),
	minWidth: '100px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ReadViewContainer = styled.div({
	minHeight: '16px',
	overflow: 'hidden',
	wordBreak: 'break-word',
	textOverflow: 'ellipsis',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const EditViewContainer = styled.div({
	width: '100%',
});
