import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	cantBeChangedHereHint: {
		id: 'polaris-ideas.view-content.idea-timeline.external-date-drag-disabled-hint.cant-be-changed-here-hint',
		defaultMessage: 'It cannot be changed from here',
		description: "Value can't be changed here hint",
	},
	dateIsOverriddenByHint: {
		id: 'polaris-ideas.view-content.idea-timeline.external-date-drag-disabled-hint.date-is-overridden-by-hint',
		defaultMessage: 'is overridden by:',
		description: 'The date is overriden by hint',
	},
});
