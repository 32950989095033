import React, { useEffect } from 'react';
import { useCurrentViewId } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import {
	PolarisEnvironmentContainerTypes,
	useEnvironmentContainer,
} from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import { useIssueTypesForCurrentContainerProjectIds } from '@atlassian/jira-polaris-component-issue-types/src/utils.tsx';
import { useCanManageViews } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';

const ViewIfConfiguredWithIssueTypeIdsUpdaterInternal = () => {
	const { updateViewIfConfiguredWithIssueTypeIds } = useViewActions();
	const currentViewId = useCurrentViewId();

	useEffect(() => {
		updateViewIfConfiguredWithIssueTypeIds();
	}, [updateViewIfConfiguredWithIssueTypeIds, currentViewId]);

	return null;
};

export const ViewIfConfiguredWithIssueTypeIdsUpdater = () => {
	const issueTypes = useIssueTypesForCurrentContainerProjectIds();
	const environmentContainer = useEnvironmentContainer();
	const canManageViews = useCanManageViews();

	if (
		!issueTypes.length ||
		environmentContainer?.type !== PolarisEnvironmentContainerTypes.PROJECT ||
		!canManageViews
	) {
		return null;
	}

	return <ViewIfConfiguredWithIssueTypeIdsUpdaterInternal />;
};
