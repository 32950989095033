import React from 'react';
import { Inline, Stack } from '@atlaskit/primitives';
import Lozenge from '@atlaskit/lozenge';
import Heading from '@atlaskit/heading';
import { useIntl } from '@atlassian/jira-intl';
import PageHeader from '@atlassian/jira-common-components-page-header/src/index.tsx';
import { BreadcrumbsProjectSettings } from '../breadcrumbs/index.tsx';
import messages from './messages.tsx';
import { HowToSection } from './how-to-section.tsx';
import { MainSection } from './main-section.tsx';

export const ConnectionsPageContent = () => {
	const { formatMessage } = useIntl();

	return (
		<>
			<PageHeader breadcrumbs={<BreadcrumbsProjectSettings />}>
				<Inline space="space.150" alignBlock="center">
					{formatMessage(messages.pageHeader)}
					<Lozenge appearance="new" isBold>
						{formatMessage(messages.earlyAccessLozenge)}
					</Lozenge>
				</Inline>
			</PageHeader>
			<Stack space="space.300">
				<MainSection />
				<Heading size="small">{formatMessage(messages.howToSectionTitle)}</Heading>
				<HowToSection />
			</Stack>
		</>
	);
};
