/** @jsx jsx */
import React, { useMemo } from 'react';
import { css, jsx } from '@compiled/react';
import Button from '@atlaskit/button/new';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import { useCurrentViewTimelineMode } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { PolarisTimelineMode } from '@atlassian/jira-polaris-domain-view/src/timeline/types.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { messages } from './messages.tsx';

export const ModeToggle = () => {
	const currentMode = useCurrentViewTimelineMode();
	const { setTimelineMode } = useViewActions();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { formatMessage } = useIntl();

	const availableModes = useMemo(
		() =>
			[
				{
					id: 'pendo.config-timeline.months-button',
					mode: PolarisTimelineMode.MONTHS,
					text: formatMessage(messages.months),
				},
				{
					id: 'pendo.config-timeline.quarters-button',
					mode: PolarisTimelineMode.QUARTERS,
					text: formatMessage(messages.quarters),
				},
			] as const,
		[formatMessage],
	);

	return (
		<div css={modeToggleContainerStyles}>
			{availableModes.map(({ id, mode, text }) => (
				<div key={id} css={btnContainerStyles}>
					<Button
						id={id}
						interactionName="jpd.timeline-fields-config.change-mode"
						shouldFitContainer
						isSelected={mode === currentMode}
						onClick={() => {
							if (mode !== currentMode) {
								fireCompoundAnalyticsEvent.TimelineGranularityChanged(createAnalyticsEvent({}), {
									timelineGranularity: mode.toLocaleLowerCase(),
								});
								setTimelineMode(mode);
							}
						}}
					>
						{text}
					</Button>
				</div>
			))}
		</div>
	);
};

const modeToggleContainerStyles = css({
	display: 'flex',
	alignItems: 'center',
	backgroundColor: token('color.background.inverse.subtle'),
	// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage -- The token value "4px" and fallback "3px" do not match and can't be replaced automatically.
	borderRadius: token('border.radius.100', '3px'),
	overflow: 'hidden',
	alignSelf: 'center',
	width: '288px',
	boxShadow: token('elevation.shadow.overlay'),
});

const btnContainerStyles = css({
	width: '50%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	button: {
		borderRadius: 0,
	},
});
