import React, { type PropsWithChildren, createContext, useContext, useMemo } from 'react';

import type { ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';

type ContextValue = {
	projectId: ProjectId;
	projectKey: string;
};

const Context = createContext<null | ContextValue>(null);

type Props = PropsWithChildren<{ projectId: ProjectId; projectKey: string }>;

export const ProjectProvider = ({ projectId, projectKey, children }: Props) => {
	const value = useMemo(() => ({ projectId, projectKey }), [projectId, projectKey]);

	return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useProject = () => {
	const value = useContext(Context);

	if (value === null) {
		throw new Error('Missing ProjectProvider');
	}

	return value;
};
