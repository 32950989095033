import React, { useCallback, useMemo } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useProjectId } from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import type { Insight } from '@atlassian/jira-polaris-domain-insight/src/insight/types.tsx';
import type {
	SnippetData,
	SnippetRefreshStatus,
	Snippet,
} from '@atlassian/jira-polaris-domain-insight/src/snippet/types.tsx';
import { useInsightRemote } from '@atlassian/jira-polaris-remote-insight/src/controllers/index.tsx';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import { useCloudId } from '../../../../common/utils/tenant-context/index.tsx';
import { useIssueActions } from '../../../../controllers/issue/main.tsx';
import { useLocalIssueIdForJiraIssueId } from '../../../../controllers/issue/selectors/issue-ids-hooks.tsx';
import { useSafeIssueKey } from '../../../../controllers/issue/selectors/properties/hooks.tsx';
import { usePolarisRouter } from '../../../../controllers/route/index.tsx';
import { UnfurlContainer } from '../../../../controllers/unfurl/index.tsx';
import type { ObjectResolved } from '../../../../controllers/unfurl/types.tsx';
import { isPendingRefresh, isAuthRefreshError } from '../../../common/refresh/index.tsx';
import { createCardWithUrlSnippetData } from '../../../snippet/card/utils.tsx';
import { PolarisSnippetContext } from '../../../snippet/index.tsx';

type Props = {
	jiraIssueId: IssueId;
	insights: Insight[];
};

type InsightPanelProps = {
	jiraIssueId: IssueId;
	insight: Insight;
};

type SnippetPanelProps = {
	snippetId: string;
	snippetData: SnippetData;
	refresh?: SnippetRefreshStatus | null;
	updated: string | null;
};

type FullSnippetProps = {
	jiraIssueId: IssueId;
	snippet: Snippet;
};

const RefreshErrorSnippetPanel = ({
	snippetData,
	refresh,
	updated,
	snippetId,
}: SnippetPanelProps) => {
	const { refreshSnippet } = useIssueActions();

	const onSnippetRefreshRequested = useCallback(() => {
		refreshSnippet(snippetId);
	}, [refreshSnippet, snippetId]);

	return (
		<SnippetPanel>
			<PolarisSnippetContext
				contentLessCard
				snippetData={snippetData}
				showContextActions
				showRefreshIndicator
				refresh={refresh}
				updated={updated}
				onSnippetRefreshRequested={onSnippetRefreshRequested}
			/>
		</SnippetPanel>
	);
};

const FullSnippet = ({ jiraIssueId, snippet }: FullSnippetProps) => {
	const { refreshSnippet } = useIssueActions();
	const cloudId = useCloudId();
	const projectId = useProjectId();
	let { data } = snippet;
	const { id, refresh, updated, url } = snippet;

	const onUnfurlCondition = useCallback(
		() => !isPendingRefresh(refresh) && isAuthRefreshError(refresh),
		[refresh],
	);

	const onObjectResolved = useCallback(
		({ isUnsupported }: ObjectResolved) => {
			if (!isUnsupported) {
				refreshSnippet(id);
			}
		},
		[id, refreshSnippet],
	);

	const insightsRemote = useInsightRemote();

	if (projectId === undefined) {
		return null;
	}

	if ((data === null || data === undefined) && url !== null && url !== undefined) {
		data = createCardWithUrlSnippetData(url);
	}

	if (data === null || data === undefined) {
		return null;
	}

	return (
		<UnfurlContainer
			insightsRemote={insightsRemote}
			onUnfurlCondition={onUnfurlCondition}
			key={id}
			url={url === null ? undefined : url}
			externalErrorType={refresh?.errorType}
			cloudId={cloudId}
			issueId={jiraIssueId}
			projectId={projectId}
			onObjectResolved={onObjectResolved}
		>
			<RefreshErrorSnippetPanel
				snippetId={id}
				snippetData={data}
				refresh={refresh}
				updated={updated}
			/>
		</UnfurlContainer>
	);
};

const RefreshErrorInsightPanel = ({ insight, jiraIssueId }: InsightPanelProps) => {
	const errorSnippets = useMemo(
		() =>
			insight.snippets.filter(
				(snippet) => snippet.refresh?.error !== undefined && snippet.refresh?.error !== null,
			),
		[insight],
	);

	return (
		<InsightPanel>
			{errorSnippets.map((snippet) => (
				<FullSnippet key={snippet.id} snippet={snippet} jiraIssueId={jiraIssueId} />
			))}
		</InsightPanel>
	);
};

export const RefreshErrorIssuePanel = ({ jiraIssueId, insights }: Props) => {
	const localIssueId = useLocalIssueIdForJiraIssueId(jiraIssueId);
	const issueKey = useSafeIssueKey(localIssueId);
	const { openIssueView } = usePolarisRouter();

	const handleIssueKeyClick = useCallback(() => {
		if (!issueKey) return;
		openIssueView(issueKey);
	}, [issueKey, openIssueView]);

	if (issueKey === undefined) {
		return null;
	}

	return (
		<PanelWrapper>
			<Box xcss={keyWrapperStyles}>
				<Button spacing="none" appearance="link" onClick={handleIssueKeyClick} autoFocus={false}>
					{issueKey}
				</Button>
			</Box>
			{insights.map((insight) => (
				<RefreshErrorInsightPanel key={insight.id} insight={insight} jiraIssueId={jiraIssueId} />
			))}
		</PanelWrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PanelWrapper = styled.div({
	display: 'flex',
	flexDirection: 'column',
	width: '100%',
	alignItems: 'start',
	paddingTop: token('space.025'),
	paddingRight: token('space.025'),
	paddingBottom: token('space.025'),
	paddingLeft: token('space.025'),
	boxSizing: 'border-box',
});

const keyWrapperStyles = xcss({
	marginBottom: 'space.100',
	marginTop: 'space.100',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InsightPanel = styled.div({
	width: '100%',
	boxShadow: token('elevation.shadow.raised'),
	borderRadius: '3px',
	boxSizing: 'border-box',
	minHeight: '48px',
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SnippetPanel = styled.div({
	boxSizing: 'border-box',
	display: 'flex',
	width: '100%',
	paddingTop: token('space.050'),
	paddingRight: token('space.050'),
	paddingBottom: token('space.050'),
	paddingLeft: token('space.050'),
	alignItems: 'center',
});
