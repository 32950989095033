import React, { type ComponentPropsWithoutRef } from 'react';
import { styled } from '@compiled/react';
import Heading from '@atlaskit/heading';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const LabelTextComponent = styled.div({
	display: 'block',
	fontFamily: token('font.family.body'),
	margin: 0,
	paddingTop: token('space.250'),
	paddingRight: '0px',
	paddingBottom: token('space.050'),
	paddingLeft: '0px',
});

export const LabelText = (props: ComponentPropsWithoutRef<typeof LabelTextComponent>) => (
	<Heading size="xxsmall" as="div">
		<LabelTextComponent {...props} />
	</Heading>
);
