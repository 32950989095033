import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import IssueFieldTeam from '@atlassian/jira-issue-field-team/src/ui/main.tsx';
import {
	createIssueAri,
	createIssueFieldValueAriWithFieldKey,
} from '@atlassian/jira-polaris-common/src/common/utils/ari/index.tsx';
import { useCanEditIssues } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { TeamValueRenderer } from '@atlassian/jira-polaris-lib-team-value/src/ui/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import {
	useLocalIssueIdToJiraIssueKeyMapper,
	useLocalIssueIdToJiraIssueIdMapper,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/issue-ids-hooks.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { useTeamValue } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { createUseTeamFieldOverride } from '../../../../../../common/utils/create-use-team-field-override.tsx';
import { useFieldUpdate } from '../../../../idea-list/cell/utils/index.tsx';
import messages from './messages.tsx';

type Props = {
	fieldKey: FieldKey;
	localIssueId: LocalIssueId;
};

export const Team = ({ fieldKey, localIssueId }: Props) => {
	const cloudId = useCloudId();
	const [canEditIssues] = useCanEditIssues();
	const value = useTeamValue(fieldKey, localIssueId);
	const onUpdate = useFieldUpdate(localIssueId, fieldKey);
	const getIssueKeyFromLocalIssueId = useLocalIssueIdToJiraIssueKeyMapper();
	const getJiraIssueIdFromLocalIssueId = useLocalIssueIdToJiraIssueIdMapper();
	const { formatMessage } = useIntl();

	const issueKey = getIssueKeyFromLocalIssueId(localIssueId);
	const jiraIssueId = getJiraIssueIdFromLocalIssueId(localIssueId);

	if (!issueKey || !jiraIssueId) {
		return null;
	}

	const issueAri = createIssueAri(cloudId, jiraIssueId);
	const fieldAri = createIssueFieldValueAriWithFieldKey(cloudId, jiraIssueId, fieldKey);

	return (
		<Box xcss={containerStyles}>
			<IssueFieldTeam
				issueKey={issueKey}
				issueId={issueAri}
				fieldKey={fieldAri}
				isFitContainerWidthReadView
				isCompact
				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				menuPortalTarget={document.body}
				readView={
					<TeamValueRenderer
						id={value?.id}
						name={value?.name}
						placeholder={formatMessage(messages.noValue)}
					/>
				}
				useTeamField={createUseTeamFieldOverride({
					issueKey,
					fieldKey,
					canEditIssues,
					initialValue: value,
					updateFieldValue: onUpdate,
				})}
			/>
		</Box>
	);
};

const containerStyles = xcss({
	marginLeft: 'space.050',
});
