import log from '@atlassian/jira-common-util-logging/src/log.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import { type Props, type State, FieldsMergeFromOption, GoalsMergeOption } from '../../types.tsx';
import { getGoalsArray } from './utils.tsx';

export const copyGoals =
	(isSameProject: boolean): Action<State, Props> =>
	async ({ getState }, props) => {
		const state = getState();
		const { projectId, fields, issuesRemote, onUpdateIssueGoals } = props;
		const { targetIssue, mergeOptions, mergeFromOptions } = state;

		if (projectId === null || targetIssue === null) {
			return;
		}

		if (!mergeOptions[GoalsMergeOption] || !isSameProject) {
			return;
		}
		const issueId = String(targetIssue.id);

		try {
			if (
				isSameProject &&
				mergeFromOptions[FieldsMergeFromOption] &&
				'fieldsForUpdate' in mergeFromOptions[FieldsMergeFromOption] &&
				mergeFromOptions[FieldsMergeFromOption].fieldsForUpdate &&
				mergeFromOptions[FieldsMergeFromOption].id !== targetIssue.id
			) {
				const selectedIssueData = await issuesRemote.fetchIssue({
					issueIdOrKey: String(mergeFromOptions[FieldsMergeFromOption].id),
				});

				const targetIssueData = await issuesRemote.fetchIssue({
					issueIdOrKey: issueId,
				});

				const goalsField = Object.values(fields).find(
					({ type }) => type === FIELD_TYPES.PLATFORM_GOALS,
				);
				const goalsFieldKey = goalsField?.key;
				if (goalsFieldKey) {
					const goalsToLink = getGoalsArray({
						issueData: selectedIssueData,
						goalsFieldKey,
					});
					const goalsToUnlink = getGoalsArray({
						issueData: targetIssueData,
						goalsFieldKey,
					});
					await onUpdateIssueGoals({
						fieldKey: goalsFieldKey,
						issueId,
						goalsToLink,
						goalsToUnlink,
					});
				}
			}
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (err: any) {
			log.safeErrorWithoutCustomerData(
				'polaris.merge-ideas-error',
				'Failed to copy goals to target idea',
				err,
			);
			throw err;
		}
	};
