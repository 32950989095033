/** @jsx jsx */
import React, { memo, useMemo, useCallback } from 'react';
import { css, jsx } from '@compiled/react';
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs';
import Avatar from '@atlaskit/avatar';
import { token } from '@atlaskit/tokens';
import { Box, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import {
	PolarisEnvironmentContainerTypes,
	useEnvironmentContainer,
	type PolarisEnvironmentContainer,
} from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import {
	useCollectionName,
	useCollectionEmoji,
} from '@atlassian/jira-polaris-component-collections/src/controllers/collections/index.tsx';
import { getCollectionsPageUrl } from '@atlassian/jira-polaris-component-collections/src/common/utils/collections.tsx';
import { useRouterActions } from '@atlassian/react-resource-router';
import { useProjectMetadata } from '@atlassian/jira-polaris-component-project-metadata/src/controllers/index.tsx';
import { useCollectionEmojiIcon } from '@atlassian/jira-polaris-component-collections/src/ui/emoji/index.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import messages from './messages.tsx';
import { ViewBreadcrumbsOld } from './index.old.tsx';

export type ViewBreadcrumbsProps = {
	isCollectionView: boolean;
	projectName?: string;
	projectsPath: string;
};

export const ViewBreadcrumbsNew = memo(
	({ projectName, projectsPath, isCollectionView }: ViewBreadcrumbsProps) => {
		const { formatMessage } = useIntl();
		const { push } = useRouterActions();
		const container = useEnvironmentContainer();

		const routeToRoadmaps = useCallback(
			(e: React.MouseEvent) => {
				e.preventDefault();
				push(getCollectionsPageUrl());
			},
			[push],
		);

		const routeToProjects = useCallback(
			(e: React.MouseEvent) => {
				e.preventDefault();
				push(projectsPath);
			},
			[projectsPath, push],
		);

		const firstBreadcrumbData = useMemo(() => {
			if (isCollectionView) {
				return {
					href: getCollectionsPageUrl(),
					text: formatMessage(messages.roadmaps),
					onclick: routeToRoadmaps,
				};
			}

			return {
				href: projectsPath,
				text: formatMessage(messages.projects),
				onclick: routeToProjects,
			};
		}, [formatMessage, isCollectionView, projectsPath, routeToProjects, routeToRoadmaps]);

		return (
			<Breadcrumbs>
				<BreadcrumbsItem
					href={firstBreadcrumbData.href}
					text={firstBreadcrumbData.text}
					onClick={firstBreadcrumbData.onclick}
				/>
				{container?.type === PolarisEnvironmentContainerTypes.PROJECT ? (
					<BreadcrumbsItemProject projectName={projectName} projectId={container.id} />
				) : null}
				{container?.type === PolarisEnvironmentContainerTypes.COLLECTION ? (
					<BreadcrumbsItemCollection container={container} />
				) : null}
			</Breadcrumbs>
		);
	},
);

type BreadcrumbsItemProjectProps = {
	projectName?: string;
	projectId?: string;
};

const BreadcrumbsItemProject = ({ projectName, projectId }: BreadcrumbsItemProjectProps) => {
	const projectMeta = useProjectMetadata({ projectId });

	if (!projectMeta || !projectName) {
		return null;
	}

	return (
		<BreadcrumbsItem
			iconBefore={
				<Box xcss={emojiProjectWrapperStyles}>
					<Avatar src={projectMeta?.avatarUrls['16x16']} appearance="square" size="xsmall" />
				</Box>
			}
			text={projectName}
		/>
	);
};

type BreadcrumbsItemCollectionProps = {
	container?: PolarisEnvironmentContainer;
};

const BreadcrumbsItemCollection = ({ container }: BreadcrumbsItemCollectionProps) => {
	const collectionName = useCollectionName({ collectionUUID: container?.id });
	const collectionEmojiId = useCollectionEmoji({ collectionUUID: container?.id });
	const CollectionEmoji = useCollectionEmojiIcon(collectionEmojiId);

	if (!container || !collectionName) {
		return null;
	}

	return (
		<BreadcrumbsItem
			iconBefore={
				<div css={emojiCollectionWrapperStyles}>
					<CollectionEmoji label="" size="small" />
				</div>
			}
			text={collectionName}
		/>
	);
};

export const ViewBreadcrumbs = componentWithFG(
	'polaris_pol-14025-add-icons-to-view-breadcrumbs',
	ViewBreadcrumbsNew,
	ViewBreadcrumbsOld,
);

const emojiCollectionWrapperStyles = css({
	paddingInlineEnd: token('space.050'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	span: {
		paddingInlineStart: '0',
	},
});

const emojiProjectWrapperStyles = xcss({
	paddingInlineEnd: 'space.050',
});
