import differenceBy from 'lodash/differenceBy';
import flatten from 'lodash/flatten';
import type { ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import { PROJECT_ACTORS_QUERY_PAGE_SIZE } from '../../../common/constants.tsx';
import { getProjectActors } from '../../../services/jira/get-project-actors/index.tsx';
import { getProjectActorsFilter } from '../../selectors/project-actors.tsx';
import {
	type ProjectActorsData,
	type ProjectActorsFilter,
	type State,
	type Props,
	type Actor,
	GROUP_ROLE_ACTOR,
	USER_ROLE_ACTOR,
} from '../../types.tsx';
import { loadCreators } from '../load-creators/index.tsx';
import { loadUsersHasAnyProductAccess } from '../load-product-access/index.tsx';

const getAllProjectActors = async ({
	projectId,
	stateFilter,
	page,
	lastQueryResponse,
}: {
	projectId: ProjectId;
	stateFilter: ProjectActorsFilter;
	page: number;
	lastQueryResponse: ProjectActorsData | undefined;
}): Promise<ProjectActorsData> => {
	if (lastQueryResponse !== undefined && lastQueryResponse.isLastBatch === true) {
		return lastQueryResponse;
	}

	const filter = {
		...stateFilter,
		page: {
			number: page,
			size: PROJECT_ACTORS_QUERY_PAGE_SIZE,
		},
	};

	const data = await getProjectActors(projectId, filter).then((response) => {
		if (lastQueryResponse !== undefined) {
			return {
				actors: flatten([...lastQueryResponse.actors, response.actors]),
				isLastBatch: response.isLastBatch,
			};
		}
		return response;
	});

	return getAllProjectActors({
		projectId,
		stateFilter,
		page: page + 1,
		lastQueryResponse: data,
	});
};

export const loadProjectActors =
	(
		shouldCheckContributorAccessForNewActors?: boolean,
		onShowFlag?: (users: Actor[]) => void,
	): Action<State, Props> =>
	({ dispatch, getState, setState }, { projectId, onError }) => {
		setState({
			ui: {
				...getState().ui,
				projectActors: {
					isLoading: true,
				},
			},
		});

		const stateFilter = getProjectActorsFilter(getState());
		const projectActors = getState().projectActors.data?.actors || [];

		getAllProjectActors({
			projectId,
			stateFilter,
			page: 1,
			lastQueryResponse: undefined,
		})
			.then((response) => {
				setState({
					projectActors: {
						...getState().projectActors,
						data: response,
					},
					ui: {
						...getState().ui,
						projectActors: {
							isLoading: false,
						},
					},
				});

				const accountIds = response.actors
					.filter((actor) => actor.type === USER_ROLE_ACTOR)
					.map((actor) => actor.roleTypeId);
				const groups = response.actors
					.filter((actor) => actor.type === GROUP_ROLE_ACTOR)
					.map((actor) => actor.roleTypeId);

				dispatch(loadCreators(accountIds, groups));

				if (shouldCheckContributorAccessForNewActors) {
					const newlyAddedUsers = differenceBy(response.actors, projectActors, 'roleTypeId').filter(
						(actor) => actor.type === USER_ROLE_ACTOR,
					);
					dispatch(loadUsersHasAnyProductAccess(accountIds, newlyAddedUsers, onShowFlag));
				} else {
					dispatch(loadUsersHasAnyProductAccess(accountIds));
				}
			})
			.catch((err) => onError(err));
	};
