import React from 'react';
import { components } from '@atlaskit/react-select';
import type { GroupProps } from '@atlaskit/select';
import { Box, Flex, xcss, Text } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import InfoIcon from '@atlaskit/icon/utility/information';
import { useIntl } from '@atlassian/jira-intl';
import { fg } from '@atlassian/jira-feature-gating';
import type { ConnectionFieldOption } from '../../types.tsx';
import { MAX_OPTIONS } from '../../constants.tsx';
import messages from './messages.tsx';

export const ConnectionFieldGroup = <IsMulti extends boolean>(
	props: GroupProps<ConnectionFieldOption, IsMulti>,
) => {
	const { options } = props;
	const { formatMessage } = useIntl();

	return (
		<components.Group {...props}>
			{props.children}
			{options.length >= MAX_OPTIONS && fg('jpd_cross_project_connecting') && (
				<Flex alignItems="center" gap="space.100" xcss={helperTextContainerStyles}>
					<Box xcss={infoIconStyles}>
						<InfoIcon color={token('color.icon')} label="" />
					</Box>
					<Text color="color.text.subtlest" size="small">
						{formatMessage(messages.limitedOptionsHelperTextNonFinal, {
							maxOptions: MAX_OPTIONS,
						})}
					</Text>
				</Flex>
			)}
		</components.Group>
	);
};

const helperTextContainerStyles = xcss({
	paddingBlockStart: 'space.025',
	paddingBlockEnd: 'space.100',
	paddingInline: 'space.150',
});

const infoIconStyles = xcss({
	paddingInlineEnd: 'space.025',
});
