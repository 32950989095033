import React, { useMemo } from 'react';
import UFOCustomData from '@atlaskit/react-ufo/custom-data';
import {
	updatePageLoadInteractionName,
	getActiveInteraction,
} from '@atlaskit/react-ufo/interaction-metrics';
import { fg } from '@atlassian/jira-feature-gating';

type SettingsUfoDataProps = {
	activeSection: string | undefined;
};

export const SettingsUfoData = ({ activeSection }: SettingsUfoDataProps) => {
	useMemo(() => {
		if (!fg('jpd-rename-ufo-project-transition') || !activeSection) {
			return;
		}
		const activeInteraction = getActiveInteraction();
		if (!activeInteraction || activeInteraction.ufoName !== 'jpd.project') {
			return;
		}
		updatePageLoadInteractionName('jpd.settings');
	}, [activeSection]);

	const data = useMemo(
		() => ({
			activeSection,
		}),
		[activeSection],
	);

	return (
		<>
			<UFOCustomData data={data} />
		</>
	);
};
