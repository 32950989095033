import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	aboutView: {
		id: 'polaris-ideas.view-header.about',
		defaultMessage: 'About',
		description: 'Open sidebar with view description',
	},
	openDropdownMenu: {
		id: 'polaris-ideas.view-header.open-dropdown-menu',
		defaultMessage: 'Open dropdown menu',
		description: 'Open view header dropdown menu',
	},
	share: {
		id: 'polaris-ideas.view-header.dropdown-menu.share',
		defaultMessage: 'Share',
		description: 'View header dropdown menu option to share the view',
	},
	saveAsNewList: {
		id: 'polaris-ideas.view-header.dropdown-menu.save-as-new-list',
		defaultMessage: 'Save as new list',
		description: 'View header dropdown menu option to save the view as new list',
	},
	saveAsNewBoard: {
		id: 'polaris-ideas.view-header.dropdown-menu.save-as-new-board',
		defaultMessage: 'Save as new board',
		description: 'View header dropdown menu option to save the view as new board',
	},
	saveAsNewTimeline: {
		id: 'polaris-ideas.view-header.dropdown-menu.save-as-new-timeline',
		defaultMessage: 'Save as new timeline',
		description: 'View header dropdown menu option to save the view as new timeline',
	},
	saveAsNewMatrix: {
		id: 'polaris-ideas.view-header.dropdown-menu.save-as-new-matrix',
		defaultMessage: 'Save as new matrix',
		description: 'View header dropdown menu option to save the view as new matrix',
	},
	deleteView: {
		id: 'polaris-ideas.view-header.dropdown-menu.delete-view',
		defaultMessage: 'Delete view',
		description: 'View header dropdown menu option to delete current view',
	},
	cloneViewErrorTitle: {
		id: 'polaris-ideas.dropdown-menu.save-as-new-view.error-title',
		defaultMessage: "We can't clone the view",
		description: 'Title of the error message when copying values fails',
	},
	cloneViewErrorDescription: {
		id: 'polaris-ideas.dropdown-menu.save-as-new-view.error-description',
		defaultMessage: 'Try again.',
		description: 'Description of the error message when copying values fails',
	},
});
