import React, { useMemo } from 'react';
import UFOCustomData from '@atlaskit/react-ufo/custom-data';
import {
	updatePageLoadInteractionName,
	getActiveInteraction,
} from '@atlaskit/react-ufo/interaction-metrics';
import {
	useIssueAnalitycsAttributes,
	useSelectedIssue,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import {
	useIssueViewLayout,
	useIssueViewSection,
} from '@atlassian/jira-polaris-common/src/controllers/route/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { usePrevious } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';
import { CommonUfoData } from '../../../common/utils/common-ufo-data/index.tsx';

export const IdeaViewUfoData = () => {
	const localIssueId = useSelectedIssue();
	const analyticsData = useIssueAnalitycsAttributes(localIssueId);

	const issueViewLayout = useIssueViewLayout();
	const issueViewSection = useIssueViewSection();
	const isIssueViewLayoutSidebar = issueViewLayout === 'sidebar';

	useMemo(() => {
		if (!localIssueId || !fg('jpd-rename-ufo-project-transition')) {
			return;
		}
		const activeInteraction = getActiveInteraction();
		if (
			!activeInteraction ||
			activeInteraction.ufoName !== 'jpd.project' ||
			// do not overwrite the interaction name if is a page load interaction and idea view is in sidebar
			(activeInteraction.type === 'page_load' && isIssueViewLayoutSidebar)
		) {
			return;
		}
		updatePageLoadInteractionName(`jpd.idea-view${isIssueViewLayoutSidebar ? '.sidebar' : ''}`);
	}, [localIssueId, isIssueViewLayoutSidebar]);

	const data = useMemo(
		() => ({ ...analyticsData, issueViewSection, issueViewLayout, localIssueId }),
		[analyticsData, issueViewSection, issueViewLayout, localIssueId],
	);

	return (
		<>
			<CommonUfoData trigger={`${localIssueId}-${issueViewLayout}`} />
			<UFOCustomData data={data} />
		</>
	);
};

type IdeaViewSidebarCloseUFORenamingProps = {
	isIssueViewSidebarOpen: boolean;
	isIssueViewFullscreenOpen: boolean;
};

export const IdeaViewSidebarCloseUFORenaming = ({
	isIssueViewFullscreenOpen,
	isIssueViewSidebarOpen,
}: IdeaViewSidebarCloseUFORenamingProps) => {
	const isIssueViewSidebarOpenPrevious = usePrevious(isIssueViewSidebarOpen);
	useMemo(() => {
		if (!fg('jpd-rename-ufo-project-transition')) {
			return;
		}
		if (isIssueViewFullscreenOpen) {
			return;
		}
		if (isIssueViewSidebarOpenPrevious && !isIssueViewSidebarOpen) {
			const activeInteraction = getActiveInteraction();
			if (!activeInteraction || activeInteraction.ufoName !== 'jpd.project') {
				return;
			}
			updatePageLoadInteractionName('jpd.idea-view.sidebar.close');
		}
	}, [isIssueViewSidebarOpenPrevious, isIssueViewSidebarOpen, isIssueViewFullscreenOpen]);
	return null;
};

export const IdeaViewErrorUFORenaming = () => {
	const issueViewLayout = useIssueViewLayout();
	const isIssueViewLayoutSidebar = issueViewLayout === 'sidebar';
	useMemo(() => {
		if (!fg('jpd-rename-ufo-project-transition')) {
			return;
		}
		const activeInteraction = getActiveInteraction();
		if (!activeInteraction || activeInteraction.ufoName !== 'jpd.project') {
			return;
		}
		updatePageLoadInteractionName(`jpd.idea-view${isIssueViewLayoutSidebar ? '.sidebar' : ''}`);
	}, [isIssueViewLayoutSidebar]);

	const data = useMemo(() => ({ withError: true }), []);

	return <UFOCustomData data={data} />;
};
