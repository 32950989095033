import { useCallback } from 'react';
import { useNotifications } from '@atlassian/jira-polaris-lib-notifications/src/controllers/index.tsx';
import type { PartialFlagProps } from '@atlassian/jira-polaris-lib-notifications/src/controllers/types.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useIsSiteAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-site-admin/index.tsx';
import messages from './messages.tsx';

type SendInviteNotificationsArguments = {
	licenseLimitReachedEmails: string[];
	projectKey: string;
	successNames: string[];
	unknownErrorEmails: string[];
};

type SendInviteNotificationsActions = {
	licenseLimitReached: PartialFlagProps['actions'];
	success: PartialFlagProps['actions'];
	unknownError: PartialFlagProps['actions'];
};

export const useInviteNotifications = () => {
	const { error, success } = useNotifications();
	const { formatMessage } = useIntl();
	const isSiteAdmin = useIsSiteAdmin();

	const sendInviteNotifications = useCallback(
		({
			licenseLimitReachedEmails,
			projectKey,
			successNames,
			unknownErrorEmails,
		}: SendInviteNotificationsArguments) => {
			let actions: SendInviteNotificationsActions = {
				licenseLimitReached: undefined,
				success: undefined,
				unknownError: [
					{
						content: formatMessage(messages.contactSupport),
						// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
						onClick: () => window.open('https://support.atlassian.com/contact/', '_blank'),
					},
				],
			};

			if (isSiteAdmin) {
				actions = {
					...actions,
					licenseLimitReached: [
						{
							content: formatMessage(messages.upgradePlan),
							// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
							onClick: () => window.open('/admin/billing/applications', '_blank'),
						},
						{
							content: formatMessage(messages.contactSupport),
							// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
							onClick: () => window.open('https://support.atlassian.com/contact/', '_blank'),
						},
					],
					success: [
						{
							content: formatMessage(messages.manageAccess),
							onClick: () => {
								// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
								window.location.assign(
									`/jira/polaris/projects/${projectKey}/ideas/settings/access`,
								);
							},
						},
					],
				};
			}

			if (licenseLimitReachedEmails.length === 1) {
				error({
					actions: actions.licenseLimitReached,
					title: formatMessage(messages.licenseLimitReachedTitle, {
						count: licenseLimitReachedEmails.length,
					}),
					description: formatMessage(messages.oneLicenseLimitReachedDescription, {
						email: licenseLimitReachedEmails[0],
					}),
				});
			} else if (licenseLimitReachedEmails.length > 1) {
				error({
					actions: actions.licenseLimitReached,
					title: formatMessage(messages.licenseLimitReachedTitle, {
						count: licenseLimitReachedEmails.length,
					}),
					description: formatMessage(messages.manyLicenseLimitReachedDescription, {
						count: licenseLimitReachedEmails.length,
					}),
				});
			}

			if (unknownErrorEmails.length === 1) {
				error({
					actions: actions.unknownError,
					title: formatMessage(messages.unknownErrorTitle, {
						count: unknownErrorEmails.length,
					}),
					description: formatMessage(messages.oneUnknownErrorDescription, {
						email: unknownErrorEmails[0],
					}),
				});
			} else if (unknownErrorEmails.length > 1) {
				error({
					actions: actions.unknownError,
					title: formatMessage(messages.unknownErrorTitle, {
						count: unknownErrorEmails.length,
					}),
					description: formatMessage(messages.manyUnknownErrorDescription, {
						count: unknownErrorEmails.length,
					}),
				});
			}

			if (successNames.length === 1) {
				success({
					actions: actions.success,
					title: formatMessage(messages.successTitle, {
						count: successNames.length,
					}),
					description: formatMessage(messages.oneSuccessDescription, {
						name: successNames[0],
					}),
				});
			} else if (successNames.length > 1) {
				success({
					actions: actions.success,
					title: formatMessage(messages.successTitle, {
						count: successNames.length,
					}),
					description: formatMessage(messages.manySuccessDescription, {
						count: successNames.length,
					}),
				});
			}
		},
		[error, formatMessage, isSiteAdmin, success],
	);

	return { sendInviteNotifications };
};
