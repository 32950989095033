import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	configureFieldButtonLabelNonFinal: {
		id: 'polaris-common.fields.connection.configure-field-button-label-non-final',
		defaultMessage: 'Configure field',
		description: 'Button label to open the right sidebar to configure the field',
	},
	cantConnectToTheSameIssueTypeTooltip: {
		id: 'polaris-common.fields.connection.cant-connect-to-the-same-issue-type-tooltip',
		defaultMessage: "Can't connect to an issue of the same type",
		description:
			"Tooltip shown when hovering over list view cell for connection field if it's for the same type, eg. it's not possible to connect an 'Idea' issue to another 'Idea' issue",
	},
});
