import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	viewAccessButtonLabel: {
		id: 'polaris-ideas.view-header.access.view-access-button-label',
		defaultMessage: 'View Access',
		description:
			'Button label displayed on hover for the icon button to open the view access modal',
	},
	viewRestrictionsButtonLabel: {
		id: 'polaris-ideas.view-header.access.view-restrictions-button-label',
		defaultMessage: 'View restrictions',
		description:
			'Button label displayed on hover for the icon button to open the view restrictions modal',
	},
});
