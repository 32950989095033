import React, { type SyntheticEvent, useCallback } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import { token } from '@atlaskit/tokens';
import { useSafeIssueKey } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { usePolarisRouter } from '@atlassian/jira-polaris-common/src/controllers/route/index.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useIsIssueFromDifferentContainerByLocalId } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/issue-ids-hooks.tsx';
import { useIssueLink } from '@atlassian/jira-polaris-common/src/common/utils/issue-link/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { LinkedIssuesProgress } from '../../../../../common/linked-issues-progress/index.tsx';
import { LinkedIssuesStatus } from '../../../../../common/linked-issues-status/index.tsx';

type Props = {
	issueId: LocalIssueId;
	type: typeof FIELD_TYPES.DELIVERY_STATUS | typeof FIELD_TYPES.DELIVERY_PROGRESS;
};

type ContentProps = Props & {
	hideTooltip?: boolean;
};

const DeliveryFieldContent = ({ type, issueId, hideTooltip }: ContentProps) => {
	if (type === FIELD_TYPES.DELIVERY_PROGRESS) {
		return <LinkedIssuesProgress issueId={issueId} hideTooltip={hideTooltip} />;
	}
	if (type === FIELD_TYPES.DELIVERY_STATUS) {
		return <LinkedIssuesStatus issueId={issueId} hideTooltip={hideTooltip} />;
	}
	return null;
};

export const DeliveryFieldPreview = ({ type, issueId, hideTooltip = true }: ContentProps) => (
	<PreviewWrapper>
		<DeliveryFieldContent issueId={issueId} type={type} hideTooltip={hideTooltip} />
	</PreviewWrapper>
);

export const DeliveryFieldInteractive = ({ issueId, type }: Props) => {
	const issueKey = useSafeIssueKey(issueId);
	const { openIssueView } = usePolarisRouter();
	const isIssueFromDifferentContainer = useIsIssueFromDifferentContainerByLocalId(issueId);
	const { onClick: onIssueLinkClick } = useIssueLink({ issueKey: issueKey ?? '' });

	const openIssueDeliverySection = useCallback(
		(_event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
			if (!issueKey) return;
			fireUIAnalytics(analyticsEvent, 'fieldValue', {
				issueField: 'deliveryProgress',
				isEmptyDelivery: false,
			});
			openIssueView(issueKey, { layout: 'sidebar', section: 'deliver' });
		},
		[issueKey, openIssueView],
	);

	return (
		<OpenDeliverySectionButton
			testId="polaris-ideas.ui.view-content.idea-list.cell.cells.linked-issues-field.delivery.open-delivery-section-button"
			appearance="subtle"
			onClick={
				isIssueFromDifferentContainer && issueKey && fg('jpd_cross_project_connecting')
					? onIssueLinkClick
					: openIssueDeliverySection
			}
		>
			<DeliveryFieldContent issueId={issueId} type={type} />
		</OpenDeliverySectionButton>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OpenDeliverySectionButton = styled(Button)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	width: 'calc(100% - 4px) !important',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	alignItems: 'center !important',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	paddingLeft: `${token('space.025')} !important`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	paddingRight: `${token('space.025')} !important`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PreviewWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&': {
		paddingTop: 0,
		paddingRight: token('space.075'),
		paddingBottom: 0,
		paddingLeft: token('space.050'),
	},
	marginRight: token('space.025'),
});
