import React, { useState, useEffect, useMemo, useCallback } from 'react';
import debounce from 'lodash/debounce';
import { ErrorMessage } from '@atlaskit/form';
import { Box, xcss } from '@atlaskit/primitives';
import Textfield from '@atlaskit/textfield';
import { token } from '@atlaskit/tokens';
import { filterSystemFields } from '@atlassian/jira-polaris-domain-field/src/field/utils.tsx';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { fieldsReferencableFrom } from '@atlassian/jira-polaris-domain-field/src/formula/index.tsx';
import { dotStartingDecimalRegex } from '../../../../common/constants.tsx';
import { compile } from './expressions/index.tsx';
import type { CompilationResult } from './expressions/types.tsx';
import { messages } from './messages.tsx';
import type { ExpressionTypeInProps } from './types.tsx';

export const ExpressionTypeIn = ({
	thisFieldKey,
	fields,
	initFormula,
	readonly,
	isPreview,
	onChange,
	onThrottledFetchSnippetProviders,
}: ExpressionTypeInProps) => {
	const { formatMessage } = useIntl();

	const filteredFields = filterSystemFields(fields);

	const initialValue: string = initFormula ? initFormula.parameters.expression : '';
	const [text, setText] = useState<string>(initialValue);
	const [parsed, setParsed] = useState<CompilationResult>(compile(initialValue, filteredFields));

	useEffect(() => {
		if (readonly) {
			setText(initialValue);
		}
	}, [initialValue, readonly]);

	// figure out which fields the user is allowed to use in the expression; if we are a new expression, then it's
	// any numeric field.  If we are editing an existing expression, then it's any numeric field that doesn't
	// reference this field directly or indirectly.
	const allowedFields = useMemo<Field[]>(
		() => fieldsReferencableFrom(thisFieldKey, filteredFields),
		[filteredFields, thisFieldKey],
	);

	useEffect(() => {
		if (readonly) {
			return;
		}
		// re-fetch the snippet providers when we mount this component, so that we have the most up-to-date information
		// for reporting on unknown props during parsing (NOTE, as of this writing, we don't actually report on unknown data
		// props, partly because that should be a warning and not an error and we don't have a notion of warnings. So this is
		// just setting the stage for better expression validation.
		// Also note that paying attention to data props would also enable us to specify props by *label* and not just by *key*,
		// something we very much need to do for oauth client ids)
		onThrottledFetchSnippetProviders(true);
	}, [onThrottledFetchSnippetProviders, readonly]);

	const errorMessage = () => {
		if (isPreview) {
			return null;
		}

		if (text.match(dotStartingDecimalRegex)) {
			return (
				<ErrorMessage testId="polaris-component-field-configuration.ui.configuration.formula.expression-type-in.error">
					{formatMessage(messages.customFormulaDecimalError)}
				</ErrorMessage>
			);
		}
		if (text.trim().length < 1) {
			return (
				<ErrorMessage testId="polaris-component-field-configuration.ui.configuration.formula.expression-type-in.error">
					{formatMessage(messages.expressionRequiredError)}
				</ErrorMessage>
			);
		}
		if (parsed.error !== undefined) {
			return (
				<ErrorMessage testId="polaris-component-field-configuration.ui.configuration.formula.expression-type-in.error">
					{formatMessage(parsed.error.msg, parsed.error.args)}
				</ErrorMessage>
			);
		}

		return null;
	};

	const onFormulaUpdate = useCallback(
		(value: string) => {
			const expr = value.trim();
			if (expr.length > 0) {
				const result = compile(expr, filteredFields, allowedFields);
				if (result.formula !== undefined && onChange !== undefined) {
					onChange(result.formula, result.fields.length);
				}
				setParsed(result);
			}
		},
		[filteredFields, allowedFields, onChange],
	);

	const updateFormula = useMemo(() => {
		if (initFormula) {
			return debounce((value: string) => onFormulaUpdate(value), 300);
		}
		return (value: string) => onFormulaUpdate(value);
	}, [initFormula, onFormulaUpdate]);

	return (
		<>
			<Box xcss={expressionLabelStyles}>{formatMessage(messages.expressionLabel)}</Box>
			<Textfield
				placeholder="{Reach} * {Impact} * {Confidence} / {Effort}"
				value={text}
				isCompact={readonly}
				testId="polaris-component-field-configuration.ui.configuration.formula.expression-type-in.custom"
				isReadOnly={readonly}
				appearance={readonly ? 'none' : 'standard'}
				onChange={(ev) => {
					if (ev.target instanceof HTMLInputElement) {
						const value = ev.target.value;
						setText(value);
						updateFormula(value);
					}
				}}
			/>
			{errorMessage()}
		</>
	);
};

const expressionLabelStyles = xcss({
	color: 'color.text.subtle',
	font: token('font.body.small'),
	display: 'inline-flex',
});
