import { useCallback, useEffect } from 'react';
import { useEnvironmentContainer } from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import type { ViewUUID } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { useLastSeenRecordAvailable } from '../../controllers/last-seen/index.tsx';
import {
	updateViewLastViewed,
	updateViewLastViewedForCollections,
	updateViewLastViewedForSharing,
} from '../../services/jpd-views-service/update-view-last-viewed/index.tsx';
import { updateViewLastViewedPolarisApi } from '../../services/polaris-api/update-view-last-viewed/index.tsx';
import { memoizedLeadingDebounce } from './utils.tsx';

// ensure that we don't actually update anything more than once every X ms
// no matter what (re)render reasons or frequency we have in our react app
const UPDATE_THROTTLE_INTERVAL = 5000;
const throttledUpdateOperations = {
	updateViewLastViewed: memoizedLeadingDebounce(updateViewLastViewed, UPDATE_THROTTLE_INTERVAL),
	updateViewLastViewedForSharing: memoizedLeadingDebounce(
		updateViewLastViewedForSharing,
		UPDATE_THROTTLE_INTERVAL,
	),
	updateViewLastViewedForCollections: memoizedLeadingDebounce(
		updateViewLastViewedForCollections,
		UPDATE_THROTTLE_INTERVAL,
	),
	updateViewLastViewedPolarisApi: memoizedLeadingDebounce(
		updateViewLastViewedPolarisApi,
		UPDATE_THROTTLE_INTERVAL,
	),
};

type LastSeenTimestampUpdaterProps = {
	viewUUID: ViewUUID | undefined;
	/**
	 * If true, the race condition check will be skipped and the last seen timestamp will be updated
	 * Use this in scenarios where we only SET the timestamp but never read it, e.g. published views
	 */
	force?: boolean;
};

export const LastSeenTimestampUpdater = ({
	viewUUID,
	force = false,
}: LastSeenTimestampUpdaterProps) => {
	const accountId = useAccountId();
	const lastSeenBackendRecordAvailable = useLastSeenRecordAvailable(viewUUID);

	const container = useEnvironmentContainer();

	const update = useCallback(() => {
		if (viewUUID === undefined || accountId === undefined || accountId === null) {
			return;
		}
		if (container?.type === 'VIEW') {
			throttledUpdateOperations.updateViewLastViewedForSharing(viewUUID);
		} else {
			throttledUpdateOperations.updateViewLastViewed(viewUUID);
		}
	}, [viewUUID, container, accountId]);

	useEffect(() => {
		if (!force && !lastSeenBackendRecordAvailable) {
			// ensure we don't update anything if we don't know if the user has seen the view
			// this avoids a race condition where the update may happen BEFORE the state is loaded
			// as long as hasSeen is undefined, we don't have any lastSeen data on this view yet,
			// so we can safely defer the update operation until we do
			return;
		}
		if (viewUUID === undefined) {
			return;
		}

		update();
	}, [viewUUID, update, lastSeenBackendRecordAvailable, force]);

	return null;
};
