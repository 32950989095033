import React, { useRef } from 'react';
import { styled } from '@compiled/react';
import Avatar from '@atlaskit/avatar';
import { SimpleTag as Tag } from '@atlaskit/tag';
import { token } from '@atlaskit/tokens';
import { ObservableValuesList } from '../../../observable-values-list/index.tsx';
import type { UserTagsProps } from '../types.tsx';

export const VerticalLayout = ({ users }: UserTagsProps) => {
	const containerRef = useRef<HTMLDivElement | null>(null);

	return (
		<Container ref={containerRef}>
			<ObservableValuesList isActive containerRef={containerRef}>
				{users.map((user) => (
					<Tag
						key={user.id}
						text={user.name}
						appearance="rounded"
						elemBefore={<Avatar src={user.avatarUrl} borderColor="transparent" size="xsmall" />}
					/>
				))}
			</ObservableValuesList>
		</Container>
	);
};
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	maxHeight: '85px',
	position: 'relative',
	overflow: 'hidden',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'> span': {
		marginTop: token('space.0'),
		marginRight: token('space.0'),
		marginBottom: token('space.0'),
		marginLeft: token('space.0'),
	},
});
