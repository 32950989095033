import React, { type FC, useState, useEffect, useMemo } from 'react';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import Button, { type ButtonProps } from '@atlaskit/button/new';
import AddIcon from '@atlaskit/icon/core/add';
import { Flex, Box, xcss } from '@atlaskit/primitives';
import type { User } from '@atlaskit/user-picker';
import { useUserSuggestions, type UseUserSuggestionsProps } from '../services/index.tsx';

const ENTRY_DURATION = 0.36;
const EXIT_DURATION = 0.12;

interface SuggestedUserPillProps {
	user: User;
	onClick: (user: User) => void;
}

const SuggestedUserPill: FC<SuggestedUserPillProps> = ({ user, onClick }) => {
	const [animationState, setAnimationState] = useState('entry');
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const handleUserPillClick: ButtonProps['onClick'] = (_, buttonEvent) => {
		setAnimationState('exit');
		setTimeout(() => {
			onClick(user);
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					...buttonEvent.payload,
					actionSubjectId: 'SuggestedUserButton',
				},
			}).fire();
		}, EXIT_DURATION * 1000);
	};

	const styles = useMemo(() => {
		if (animationState === 'entry') {
			return entryStyles;
		}

		if (animationState === 'exit') {
			return exitStyles;
		}

		return baseStyles;
	}, [animationState]);

	useEffect(() => {
		setTimeout(() => setAnimationState('base'));
	}, []);

	return (
		<Box
			// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage
			xcss={styles}
		>
			<Button
				spacing="compact"
				appearance="default"
				iconBefore={AddIcon}
				onClick={handleUserPillClick}
			>
				{user.name}
			</Button>
		</Box>
	);
};

type SuggestedUsersProps = UseUserSuggestionsProps & {
	onSuggestedUserClick: (selectedUser: User) => void;
};

export const SuggestedUsers: FC<SuggestedUsersProps> = ({
	excludedUserIds,
	onSuggestedUserClick,
}) => {
	const { suggestedUsers } = useUserSuggestions({ excludedUserIds });

	return (
		<Flex
			wrap="nowrap"
			justifyContent="start"
			alignItems="center"
			direction="row"
			xcss={containerStyles}
		>
			{suggestedUsers.map((user) => (
				<SuggestedUserPill user={user} onClick={onSuggestedUserClick} key={user.id} />
			))}
		</Flex>
	);
};

const containerStyles = xcss({
	marginTop: 'space.025',
	width: 'calc(100% + 8px)',
	position: 'relative',
	top: '-4px',
	left: '-4px',
});

const entryStyles = xcss({
	flex: '0 1 auto',
	maxWidth: '42%',
	opacity: 0,
	padding: 'space.050',
	transition: `opacity ${ENTRY_DURATION}s ease-in`,
});

const baseStyles = xcss({
	flex: '0 1 auto',
	maxWidth: '42%',
	opacity: 1,
	padding: 'space.050',
	overflow: 'hidden',
	transition: `opacity ${ENTRY_DURATION}s ease-in`,
});

const exitStyles = xcss({
	flex: '0 1 auto',
	maxWidth: '0%',
	opacity: 0,
	overflow: 'hidden',
	padding: 'space.0',
	transition: `all ${EXIT_DURATION}s ease-out`,
});
