import type { RemoteProperty } from '../../types.tsx';

export const isBooleanOrUndefinedProperty = (
	property: RemoteProperty<unknown>,
): property is RemoteProperty<boolean> =>
	typeof property.value === 'boolean' || typeof property.value === 'undefined';

export const isStringOrUndefinedProperty = (
	property: RemoteProperty<unknown>,
): property is RemoteProperty<string> =>
	typeof property.value === 'string' || typeof property.value === 'undefined';
