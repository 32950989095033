import {
	getUserProperty,
	setUserProperties,
} from '@atlassian/jira-common-rest/src/api/latest/user-properties/index.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { createErrorAnalytics } from '@atlassian/jira-polaris-lib-errors/src/controllers/index.tsx';
import { isNotFoundError } from '@atlassian/jira-polaris-lib-errors/src/controllers/utils.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import { SHARE_VIEW_SPOTLIGHT_DISMISSED } from '../../../../constants.tsx';
import type { Props, State } from '../../../../types.tsx';
import { normalizeError } from '../../../../../common/utils.tsx';

export const loadShareViewSpotlightDismissed =
	(): Action<State, Props> =>
	async ({ getState, setState }, { accountId }) => {
		if (!accountId) {
			return;
		}

		// User property is already loaded
		if (typeof getState().shareDialog.shareSpotlightDismissed === 'boolean') {
			return;
		}

		try {
			const shareViewSpotlightDismissed = await getUserProperty<boolean>(
				accountId,
				SHARE_VIEW_SPOTLIGHT_DISMISSED,
			);

			const state = getState();

			setState({
				...state,
				shareDialog: {
					...state.shareDialog,
					shareSpotlightDismissed: shareViewSpotlightDismissed,
				},
			});
		} catch (error) {
			// Expected error, the property is not set yet
			if (error instanceof FetchError && isNotFoundError(error)) {
				const state = getState();

				setState({
					...state,
					shareDialog: {
						...state.shareDialog,
						shareSpotlightDismissed: false,
					},
				});

				return;
			}

			fireErrorAnalytics(
				createErrorAnalytics(SHARE_VIEW_SPOTLIGHT_DISMISSED, normalizeError(error)),
			);
		}
	};

export const setShareViewSpotlightDismissed =
	(): Action<State, Props> =>
	async ({ getState, setState }, { accountId }) => {
		if (!accountId) {
			return;
		}

		const state = getState();

		setState({
			...state,
			shareDialog: {
				...state.shareDialog,
				shareSpotlightDismissed: true,
			},
		});

		try {
			await setUserProperties(accountId, SHARE_VIEW_SPOTLIGHT_DISMISSED, 'true');
		} catch (error) {
			fireErrorAnalytics(
				createErrorAnalytics(SHARE_VIEW_SPOTLIGHT_DISMISSED, normalizeError(error)),
			);
		}
	};
