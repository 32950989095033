import React, { type ReactNode } from 'react';
import { styled } from '@compiled/react';
import { Checkbox } from '@atlaskit/checkbox';
import { Field } from '@atlaskit/form';
import InfoIcon from '@atlaskit/icon/core/migration/information--info';
import Link from '@atlaskit/link';
import { ModalBody } from '@atlaskit/modal-dialog';
import { Box, Inline, Text, xcss } from '@atlaskit/primitives';
import SectionMessage from '@atlaskit/section-message';
import Select from '@atlaskit/select';
import TextArea from '@atlaskit/textarea';
import { B400 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';
import { FormattedI18nMessage } from '@atlassian/jira-formatted-i18n-message/src/ui/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';
import type { ContentProps } from './types.tsx';

const InformationIcon = () => (
	<InfoIcon label="" color={token('color.text.information')} LEGACY_primaryColor={B400} />
);

export const Content = ({
	feedbackCategories,
	onCategoryChange,
	selectedCategory,
	message,
	sectionMessage,
	setMessage,
}: ContentProps) => {
	const { formatMessage } = useIntl();

	return (
		<ModalBody>
			<Inline space="space.025" alignBlock="center">
				<InformationIcon />
				<Text as="p" size="small">
					<FormattedI18nMessage
						message={formatMessage(messages.feedbackWarning, {
							linkStart: '{linkStart}',
							linkEnd: '{linkEnd}',
						})}
						componentsMapping={{
							link: ({ children }: { children: ReactNode }) => (
								<Link
									href="https://community.atlassian.com/t5/Jira-Product-Discovery/ct-p/jpd"
									target="_blank"
									tabIndex={-1}
								>
									{children}
								</Link>
							),
						}}
					/>
				</Text>
			</Inline>

			<Field label={formatMessage(messages.categoryPickerLabel)} name="feedback-category-selection">
				{/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
				{({ fieldProps }: any) => (
					<Select
						{...fieldProps}
						spacing="compact"
						isClearable={false}
						defaultValue={selectedCategory}
						options={feedbackCategories}
						onChange={onCategoryChange}
						// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
						menuPortalTarget={document.body}
						styles={{
							// without properly set zIndex value dropdown with options is cut inside modal dialogs
							menuPortal: (base) => ({
								...base,

								zIndex: layers.modal,
							}),
							menu: (base) => ({ ...base, zIndex: 99 }),
						}}
					/>
				)}
			</Field>
			<Field
				// @ts-expect-error: Element implicitly has an 'any' type because expression of type '`${string}TextAreaLabel` | `${number}TextAreaLabel`'
				label={formatMessage(messages[`${selectedCategory.value}TextAreaLabel`])}
				isRequired
				name="feedback-body"
			>
				{/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
				{({ fieldProps }: any) => (
					<TextArea
						{...fieldProps}
						id="feedback-body"
						isRequired
						value={message}
						resize="none"
						minimumRows={5}
						onChange={(event) => {
							setMessage(event.currentTarget.value);
						}}
					/>
				)}
			</Field>
			{sectionMessage && (
				<Box xcss={sectionMessageContainerStyles}>
					<SectionMessage>{formatMessage(sectionMessage)}</SectionMessage>
				</Box>
			)}
			<StyledCheckbox>
				<Checkbox
					isChecked
					isDisabled
					label={formatMessage(messages.allowContactingLabel)}
					name="allow-contacting-checkbox"
				/>
			</StyledCheckbox>
		</ModalBody>
	);
};

// eslint-disable-next-line @atlaskit/design-system/use-primitives, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledCheckbox = styled.div({
	marginTop: token('space.100'),
	marginRight: '0px',
	marginBottom: '0px',
	marginLeft: token('space.negative.050'),
});

const sectionMessageContainerStyles = xcss({
	marginTop: 'space.100',
});
