import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { PermissionConfigurationResponse } from './types.tsx';

const getUrl = (projectId: ProjectId) =>
	`/rest/internal/simplified/1.0/permission-configuration?projectId=${projectId}`;

export const getPermissionConfiguration = (
	projectId: ProjectId,
): Promise<PermissionConfigurationResponse> => performGetRequest(getUrl(projectId));
