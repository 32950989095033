import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	dateIsOverriddenByHint: {
		id: 'polaris-common.fields.atlas-project-date.hint.date-is-overridden-by-hint',
		defaultMessage: 'The date is overridden by:',
		description: 'The date is overridden by hint',
	},
	configureButton: {
		id: 'polaris-common.fields.atlas-project-date.hint.configure-button',
		defaultMessage: 'Configure',
		description: 'Configure button hint',
	},
});
