import { v4 as uuid } from 'uuid';
import { fg } from '@atlassian/jira-feature-gating';

type FieldsMappingUrl = {
	url: string;
	traceId: string;
};

export const getFieldsMappingUrl = (
	projectIdOrKey: number | string,
	appendTraceId = false,
): FieldsMappingUrl => {
	const requestId = uuid();
	const params = new URLSearchParams({
		...(fg('jpd-trace-fetch-network-call') ? { operation: 'getFieldIdMapping' } : {}),
		...(appendTraceId ? { requestId } : {}),
	});
	const postfix = params.toString() ? `?${params.toString()}` : '';

	return {
		url: `/gateway/api/polaris/rest/projects/${projectIdOrKey}/field-id-mapping${postfix}`,
		traceId: requestId,
	};
};
