/** @jsx jsx */
import { useCallback, useMemo } from 'react';
import { cssMap, jsx } from '@compiled/react';
import ArchiveIcon from '@atlaskit/icon/core/migration/archive-box--archive';
import BulletListIcon from '@atlaskit/icon/core/migration/list-bulleted--bullet-list';
import SettingsIcon from '@atlaskit/icon/core/migration/settings';
import { ButtonItem, Section } from '@atlaskit/menu';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useIsArchivingEnabled } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks.tsx';
import {
	useCurrentPolarisRoute,
	usePolarisRouter,
} from '@atlassian/jira-polaris-common/src/controllers/route/index.tsx';
import type { PolarisRoute } from '@atlassian/jira-polaris-common/src/controllers/route/types.tsx';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import {
	useCurrentViewId,
	useSystemViewsContainingArchived,
	useSystemViewsNotContainingArchived,
	useViewContainsArchived,
	useViewTitle,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { useProjectId } from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import { useIsPreviewContributorMode } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/main.tsx';
import {
	useIsProjectAdmin,
	useCanArchiveIdeas,
} from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { useProjectMetadata } from '@atlassian/jira-polaris-component-project-metadata/src/controllers/index.tsx';
import type { LocalViewId } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import messages from './messages.tsx';

const styles = cssMap({
	sectionInner: {
		marginTop: 0,
		marginRight: token('space.negative.100'),
		marginBottom: 0,
		marginLeft: token('space.negative.100'),
	},
	buttonItem: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Temporary workaround to make Emotion and Compiled specificity mesh until `@atlaskit/menu` is migrated to Compiled.
		'&&': {
			paddingBlockStart: token('space.050'),
			paddingBlockEnd: token('space.050'),
			// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
			paddingInlineStart: 18,
			// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
			paddingInlineEnd: 18,
			color: token('color.text.subtle'),
			'&:hover': {
				color: token('color.text'),
			},
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		'[data-item-elem-before]': {
			width: 24,
			height: 24,
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
			'& svg': {
				width: 18,
				height: 18,
				marginTop: token('space.025'),
				// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
				marginLeft: 3,
			},
		},
	},
	buttonItemSelected: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Temporary workaround to make Emotion and Compiled specificity mesh until `@atlaskit/menu` is migrated to Compiled.
		'&&': {
			color: token('color.text.brand'),
		},
	},
});

type SettingsSectionProps = {
	onNavToSettings: (arg1: PolarisRoute) => void;
	isReadOnly?: boolean;
};

const ViewButton = ({ viewId }: { viewId: LocalViewId }) => {
	const { setCurrentView } = useViewActions();
	const title = useViewTitle(viewId);
	const currentViewId = useCurrentViewId();
	const containsArchived = useViewContainsArchived(viewId);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { closeIssueView } = usePolarisRouter();

	const onSelectView = useCallback(
		(selectViewId: string) => {
			if (selectViewId !== undefined) {
				fireCompoundAnalyticsEvent.NavSidebarArchiveViewClicked(createAnalyticsEvent({}));
				closeIssueView();
				setCurrentView(selectViewId);
			}
		},
		[createAnalyticsEvent, setCurrentView, closeIssueView],
	);

	return (
		<ButtonItem
			testId="polaris-ideas.ui.sidebar.settings.section.archive"
			onClick={() => onSelectView(viewId)}
			// eslint-disable-next-line @atlaskit/design-system/no-unsafe-style-overrides
			css={[styles.buttonItem, currentViewId === viewId && styles.buttonItemSelected]}
			isSelected={currentViewId === viewId}
			iconBefore={
				containsArchived ? (
					<ArchiveIcon spacing="spacious" label="" />
				) : (
					<BulletListIcon spacing="spacious" label={title || ''} />
				)
			}
		>
			{title}
		</ButtonItem>
	);
};

export const useProjectSettingsUrl = (): string | undefined => {
	const projectId = useProjectId();
	const projectMeta = useProjectMetadata({ projectId });
	return projectMeta?.key ? `/jira/polaris/projects/${projectMeta?.key}/ideas/settings` : undefined;
};

export const SettingsSection = ({ onNavToSettings }: SettingsSectionProps) => {
	const { formatMessage } = useIntl();
	const [isProjectAdmin] = useIsProjectAdmin();
	const canArchiveIdeas = useCanArchiveIdeas();
	const { isAdmin, isSiteAdmin } = useTenantContext();
	const projectSettingsUrl = useProjectSettingsUrl();
	const { routeTo } = usePolarisRouter();
	const polarisRoute = useCurrentPolarisRoute();
	const { section } = polarisRoute;

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const archivedViewIds = useSystemViewsContainingArchived();
	const systemViewIds = useSystemViewsNotContainingArchived();

	const onSelectSettings = useCallback(() => {
		fireCompoundAnalyticsEvent.NavSidebarSettingsClicked(createAnalyticsEvent({}));

		onNavToSettings(polarisRoute);
		routeTo({ section: 'settings', resource: 'details' });
	}, [createAnalyticsEvent, onNavToSettings, polarisRoute, routeTo]);

	const [isArchivingEnabled] = useIsArchivingEnabled();
	const [isPreviewContributorMode] = useIsPreviewContributorMode();

	const canChangeProjectSettings =
		(isSiteAdmin || isAdmin || isProjectAdmin) && !isPreviewContributorMode;

	const filteredViews = useMemo(
		() => (isArchivingEnabled && canArchiveIdeas ? archivedViewIds : systemViewIds),
		[isArchivingEnabled, canArchiveIdeas, archivedViewIds, systemViewIds],
	);

	if ((projectSettingsUrl !== undefined && canChangeProjectSettings) || filteredViews.length > 0) {
		return (
			<Section hasSeparator>
				<div css={styles.sectionInner}>
					{filteredViews.map((viewId) => (
						<ViewButton key={viewId} viewId={viewId} />
					))}
					{projectSettingsUrl !== undefined && canChangeProjectSettings ? (
						<Section>
							<ButtonItem
								id="polaris.ideas.src.ui.sidebar.settings.project-settings.button"
								isSelected={section === 'settings'}
								onClick={onSelectSettings}
								// eslint-disable-next-line @atlaskit/design-system/no-unsafe-style-overrides
								css={[styles.buttonItem, section === 'settings' && styles.buttonItemSelected]}
								iconBefore={
									<SettingsIcon spacing="spacious" label={formatMessage(messages.settings)} />
								}
							>
								{formatMessage(messages.settings)}
							</ButtonItem>
						</Section>
					) : null}
				</div>
			</Section>
		);
	}
	return null;
};
