import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	createIdeaButtonText: {
		id: 'polaris-ideas.view-content.idea-list.row-pinned-bottom.create-idea-button-text',
		defaultMessage: 'Create an idea',
		description: 'Button message for creating an idea',
	},
	createIdeaButton: {
		id: 'polaris-ideas.view-content.idea-list.row-pinned-bottom.create-idea-button',
		defaultMessage: 'Create',
		description: 'Button message for creating an idea',
	},
});
