import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	linkedIssues: {
		id: 'polaris-ideas.idea-view.issue-links.linked-issues',
		defaultMessage: 'Linked issues',
		description: 'Caption for linked issues section.',
	},
	linkedIssuesIssueTermRefresh: {
		id: 'polaris-ideas.idea-view.issue-links.linked-issues-issue-term-refresh',
		defaultMessage: 'Linked work item',
		description: 'Caption for linked issues section.',
	},
	linkButtonCaption: {
		id: 'polaris-ideas.idea-view.issue-links.link-button-caption',
		defaultMessage: 'Link issue',
		description: 'Button label for the link issue button',
	},
	linkButtonCaptionIssueTermRefresh: {
		id: 'polaris-ideas.idea-view.issue-links.link-button-caption-issue-term-refresh',
		defaultMessage: 'Link work item',
		description: 'Button label for the link issue button',
	},
	issueLinksDefaultGroupLabel: {
		id: 'polaris-ideas.idea-view.issue-links.issue-links-default-group-label',
		defaultMessage: 'In this project',
		description:
			'Option group label for link issue select dropdown content. This option group contains all project ideas',
	},
});
