import React from 'react';
import { styled } from '@compiled/react';
import LikeIcon from '@atlaskit/icon/core/migration/thumbs-up--like';
import { token } from '@atlaskit/tokens';
import { useGetPlayById } from '@atlassian/jira-polaris-common/src/controllers/project/selectors/plays-hooks.tsx';
import { useRightSidebarContextPlay } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/selectors/hooks.tsx';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import { IdeaPlayContributionsStream } from '@atlassian/jira-polaris-common/src/ui/plays/play-stream/index.tsx';
import type { PolarisPlay } from '@atlassian/jira-polaris-domain-field/src/play/types.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { IssueHeader } from '../header/issue/index.tsx';

type Props = {
	onClose: () => void;
};

export const PlayPanel = ({ onClose }: Props) => {
	const { setUnsavedChanges } = useViewActions();

	const [context] = useRightSidebarContextPlay();
	const play: PolarisPlay | undefined = useGetPlayById(context?.playId);

	if (context === undefined) {
		return null;
	}

	return (
		<UFOSegment name="jpd.right-sidebar.play">
			<Container>
				<IssueHeader
					localIssueId={context.localIssueId}
					title={play?.label}
					icon={<LikeIcon label="play" LEGACY_size="small" />}
					onClose={onClose}
				/>
				<ScrollContainer>
					<IdeaPlayContributionsStream
						localIssueId={context.localIssueId}
						playId={context.playId}
						onDirty={setUnsavedChanges}
					/>
				</ScrollContainer>
			</Container>
		</UFOSegment>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	height: 'calc(100vh - (var(--topNavigationHeight, 0px) + var(--bannerHeight, 0px)))',
	display: 'flex',
	flexDirection: 'column',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ScrollContainer = styled.div({
	overflowY: 'auto',
	paddingTop: 0,
	paddingRight: token('space.200'),
	paddingBottom: token('space.200'),
	paddingLeft: token('space.200'),
	marginTop: token('space.200'),
});
