import React from 'react';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { useEmoji } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import {
	useFieldEmoji,
	useFieldTypeIcon,
} from '../../../../controllers/field/selectors/field-hooks.tsx';
import { useViewActions } from '../../../../controllers/views/main.tsx';
import { useNumericFilter } from '../../../../controllers/views/selectors/filters-hooks.tsx';
import { NumericFilterComponent } from '../numeric/index.tsx';
import type { FilterComponentProps } from '../types.tsx';
import { FilterWrapper } from '../filter-wrapper/filter-wrapper.tsx';

export const NumericFilter = ({
	field,
	isLastFilter,
	defaultOpen,
	isDisabled = false,
	isUsingCustomColor = false,
	onChange,
}: FilterComponentProps) => {
	const numericFilter = useNumericFilter(field.key);

	const { updateNumericFilter } = useViewActions();

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const fieldTypeIcon = useFieldTypeIcon(field.key, undefined);
	const emojiId = useFieldEmoji(field.key);
	const emoji = useEmoji(emojiId);
	return (
		<FilterWrapper>
			<NumericFilterComponent
				label={field.label}
				values={numericFilter.values}
				fieldTypeIcon={fieldTypeIcon}
				emoji={emoji}
				isLastFilter={isLastFilter}
				defaultOpen={defaultOpen}
				onChange={(values) => {
					onChange?.();
					fireUIAnalytics(createAnalyticsEvent({}), 'numericFilter updated', 'view-controls', {
						fieldKey: field.key,
					});
					experience.headerView.viewFilter.start();
					updateNumericFilter(
						{
							...numericFilter,
							values,
						},
						() => {
							experience.headerView.viewFilter.success();
						},
						(error?: Error) => {
							experience.headerView.viewFilter.failure(error);
						},
					);
				}}
				isDisabled={isDisabled}
				isUsingCustomColor={isUsingCustomColor}
			/>
		</FilterWrapper>
	);
};
