import React, { useState, useEffect } from 'react';
import Button from '@atlaskit/button/new';
import AddIcon from '@atlaskit/icon/core/add';
import { Box, xcss } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import { useIssueTypeIdsForProject } from '@atlassian/jira-polaris-component-issue-types/src/controllers/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import type { ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import { ProjectProvider } from './project-provider.tsx';
import { AvatarsProvider } from './avatars-provider.tsx';
import { TypeCard } from './type-card.tsx';
import { TypeCardCreate } from './type-card-create.tsx';
import { messages } from './messages.tsx';

type Props = {
	projectId: ProjectId;
	projectKey: string;
	issueTypeLimit: number;
};

export const IssueTypesRenderer = ({ projectId, projectKey, issueTypeLimit }: Props) => {
	const issueTypeIds = useIssueTypeIdsForProject({ projectId });
	const { formatMessage } = useIntl();
	const [isCreatingNewType, setIsCreatingNewType] = useState(false);

	useEffect(() => {
		const onKeyDown = (event: KeyboardEvent) => {
			if (event.key === 'Esc' || event.key === 'Escape') {
				setIsCreatingNewType(false);
			}
		};
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.addEventListener('keydown', onKeyDown);
		return () => {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.removeEventListener('keydown', onKeyDown);
		};
	}, []);

	const isAddTypeButtonDisabled = issueTypeIds.length >= issueTypeLimit;

	return (
		<>
			<ProjectProvider projectKey={projectKey} projectId={projectId}>
				<AvatarsProvider projectId={projectId}>
					<Box paddingBlockEnd="space.200">
						{issueTypeIds.map((issueTypeId) => {
							return <TypeCard key={issueTypeId} issueTypeId={issueTypeId} />;
						})}

						{isCreatingNewType && <TypeCardCreate onCreate={() => setIsCreatingNewType(false)} />}

						{!isCreatingNewType && (
							<Box xcss={issueTypeCreateSectionStyles}>
								<Tooltip
									content={
										isAddTypeButtonDisabled
											? formatMessage(messages.addTypeLimit, { limit: issueTypeLimit })
											: undefined
									}
								>
									{(tooltipProps) => (
										<Button
											{...tooltipProps}
											iconBefore={AddIcon}
											onClick={() => setIsCreatingNewType(true)}
											isDisabled={isAddTypeButtonDisabled}
										>
											{formatMessage(messages.addType)}
										</Button>
									)}
								</Tooltip>
							</Box>
						)}
					</Box>
				</AvatarsProvider>
			</ProjectProvider>
		</>
	);
};

const issueTypeCreateSectionStyles = xcss({
	marginTop: 'space.150',
});
