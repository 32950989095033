import React, { memo, type ReactNode } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button/standard-button';
import { token } from '@atlaskit/tokens';
import type { JpdTreeItem } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/sections-tree.tsx';
import {
	PolarisIcon,
	PolarisIconType,
} from '@atlassian/jira-polaris-component-glyphs/src/ui/glyphs/main.tsx';
import type { ItemId } from '@atlassian/jira-polaris-lib-tree/src/types.tsx';

type GetIconProps = {
	item: JpdTreeItem;
	icon?: ReactNode;
	onExpandSection: (id: ItemId) => void;
	onCollapseSection: (id: ItemId) => void;
};

export const GetIcon = memo<GetIconProps>(
	({ item, onExpandSection, onCollapseSection, icon }: GetIconProps) => {
		const { id, isExpanded = false } = item;
		const isSectionItem = item.data?.type === 'section';

		if (!isSectionItem) {
			return icon ?? null;
		}

		return (
			<Button
				spacing="none"
				appearance="subtle-link"
				onClick={() => (isExpanded ? onCollapseSection(id) : onExpandSection(id))}
			>
				<IconWrapper isCollapsed={!isExpanded}>
					<PolarisIcon name={PolarisIconType.ChevronRight} size="medium" label="" />
				</IconWrapper>
			</Button>
		);
	},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconWrapper = styled.div<{ isCollapsed: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.icon'),
	transition: 'transform 150ms',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	transform: ({ isCollapsed }) =>
		isCollapsed ? 'translate(0, 1px) rotate(0)' : 'translate(0, 0) rotate(90deg)',
});
