import React, { useState, useEffect } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { useFieldType } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks.tsx';
import { useGetCurrentViewFieldRollup } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/fields-rollup-hooks.tsx';
import { CommentsFieldGrouped } from '@atlassian/jira-polaris-common/src/ui/fields-grouped/comments/index.tsx';
import { IntervalFieldGrouped } from '@atlassian/jira-polaris-common/src/ui/fields-grouped/interval/index.tsx';
import { LabelsFieldGrouped } from '@atlassian/jira-polaris-common/src/ui/fields-grouped/labels/index.tsx';
import { NumberFieldGrouped } from '@atlassian/jira-polaris-common/src/ui/fields-grouped/number/index.tsx';
import { SelectFieldGrouped } from '@atlassian/jira-polaris-common/src/ui/fields-grouped/select/index.tsx';
import { StringFieldGrouped } from '@atlassian/jira-polaris-common/src/ui/fields-grouped/string/index.tsx';
import { UserFieldGrouped } from '@atlassian/jira-polaris-common/src/ui/fields-grouped/user/index.tsx';
import { useCanEditGroupsRollup } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { DropdownGroupCell } from '../utils/dropdown-group-cell/index.tsx';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const Button = styled.button<{ disabled?: boolean; isSelected?: boolean }>({
	color: token('color.text.subtlest'),
	flex: 1,
	width: '100%',
	display: 'inline-flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	border: 'none',
	outline: 'none',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: ({ isSelected }) =>
		isSelected ? token('color.background.neutral.subtle.hovered') : 'inherit',
	textAlign: 'left',
	minHeight: '30px',
	borderRadius: '3px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	cursor: ({ disabled }) => (disabled ? 'inherit' : 'pointer'),

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	':active, :hover, :focus, :focus-within': {
		backgroundColor: token('color.background.neutral.subtle.hovered'),
		color: token('color.text.subtle'),
		boxShadow: 'none',
	},
});

type GroupRendererProps = {
	fieldKey: FieldKey;
	groupId: string;
	groupFieldKey: FieldKey;
};

const GroupedComponent = (props: GroupRendererProps) => {
	const { fieldKey } = props;
	const fieldType = useFieldType(fieldKey);
	const rollupOperation = useGetCurrentViewFieldRollup(fieldKey);

	// Bail out if the rollup operation is not present
	if (!rollupOperation) {
		return null;
	}

	switch (fieldType) {
		case FIELD_TYPES.ASSIGNEE:
		case FIELD_TYPES.REPORTER:
		case FIELD_TYPES.CREATOR:
		case FIELD_TYPES.PEOPLE:
		case FIELD_TYPES.JSW_PEOPLE:
			return <UserFieldGrouped {...props} rollupOperation={rollupOperation} />;
		case FIELD_TYPES.SINGLE_SELECT:
		case FIELD_TYPES.MULTI_SELECT:
		case FIELD_TYPES.JSW_MULTI_SELECT:
			return <SelectFieldGrouped {...props} rollupOperation={rollupOperation} />;
		case FIELD_TYPES.ISSUE_COMMENTS:
			return <CommentsFieldGrouped {...props} rollupOperation={rollupOperation} />;
		case FIELD_TYPES.NUMBER:
		case FIELD_TYPES.CHECKBOX:
		case FIELD_TYPES.FORMULA:
		case FIELD_TYPES.SLIDER:
		case FIELD_TYPES.RATING:
		case FIELD_TYPES.INSIGHTS:
		case FIELD_TYPES.LINKED_ISSUES:
			return <NumberFieldGrouped {...props} rollupOperation={rollupOperation} />;
		case FIELD_TYPES.SUMMARY:
		case FIELD_TYPES.SHORT_TEXT:
		case FIELD_TYPES.HYPERLINK:
			return <StringFieldGrouped {...props} rollupOperation={rollupOperation} />;
		case FIELD_TYPES.INTERVAL:
			return <IntervalFieldGrouped {...props} rollupOperation={rollupOperation} />;
		case FIELD_TYPES.LABELS:
		case FIELD_TYPES.CUSTOM_LABELS:
			return <LabelsFieldGrouped {...props} rollupOperation={rollupOperation} />;
		default:
			return null;
	}
};

type GroupCellRendererProps = {
	isHovered: boolean;
	fieldKey: FieldKey;
	groupId?: string;
	groupFieldKey?: FieldKey;
};

export const RenderGroupCell = ({
	fieldKey,
	groupFieldKey,
	groupId,
	isHovered,
}: GroupCellRendererProps) => {
	const isEditable = useCanEditGroupsRollup();
	const [isActivated, setActivated] = useState(false);

	useEffect(() => {
		if (isHovered && isEditable) {
			setActivated(true);
		}
	}, [isHovered, isEditable]);

	if (groupId === undefined || groupFieldKey === undefined) {
		return null;
	}

	return isActivated ? (
		<DropdownGroupCell fieldKey={fieldKey}>
			<GroupedComponent fieldKey={fieldKey} groupFieldKey={groupFieldKey} groupId={groupId} />
		</DropdownGroupCell>
	) : (
		<Button disabled={!isEditable}>
			<GroupedComponent fieldKey={fieldKey} groupFieldKey={groupFieldKey} groupId={groupId} />
		</Button>
	);
};
