import { performDeleteRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { DeleteFieldOptionRequest } from './types.tsx';

export const deleteFieldOption = ({
	projectId,
	issueTypeId,
	fieldKey,
	optionId,
}: DeleteFieldOptionRequest): Promise<void> =>
	performDeleteRequest(
		`/rest/polaris/v2/projects/${projectId}/fields/${fieldKey}/options/${optionId}`,
		{
			body: JSON.stringify(
				fg('jpd_use_field_association_helper')
					? {}
					: {
							issueTypeId,
						},
			),
		},
	);
