import React, { useCallback, useMemo, useState, useEffect } from 'react';
import debounce from 'lodash/debounce';
import { IconButton } from '@atlaskit/button/new';
import EditorSearchIcon from '@atlaskit/icon/core/migration/search--editor-search';
import SelectClearIcon from '@atlaskit/icon/core/migration/cross-circle--select-clear';
import { SpotlightTarget } from '@atlaskit/onboarding';
import { Box, Inline, xcss } from '@atlaskit/primitives';
import Textfield from '@atlaskit/textfield';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { PROJECT_FIELDS_TOUR_SPOTLIGHTS_STEP_3 } from '@atlassian/jira-polaris-lib-onboarding-flows/src/controllers/constants.tsx';
import { RoundedButton } from '@atlassian/jira-polaris-lib-rounded-button/src/ui/index.tsx';
import {
	type UIAnalyticsEvent,
	fireUIAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { useFieldsTableActions } from '../../../../../../../controllers/fields-table/index.tsx';
import {
	useFieldsScopeFilter,
	useSearchQueryFilter,
} from '../../../../../../../controllers/fields-table/selectors/fields-hooks.tsx';
import type { State } from '../../../../../../../controllers/fields-table/types.tsx';
import messages from './messages.tsx';

const SearchInput = ({
	value,
	onChange,
}: {
	value: string;
	onChange: (searchQuery: string) => void;
}) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { formatMessage } = useIntl();

	const [searchValue, setSearchValue] = useState(value);

	const debouncedChange = useMemo(() => debounce(onChange, 200), [onChange]);

	useEffect(() => {
		setSearchValue(value);
	}, [value]);

	return (
		<Box xcss={searchInputWrapperStyles}>
			<Textfield
				testId="polaris-ideas.ui.settings.fields.project-fields-screen.ui.fields-list.filters.search"
				value={searchValue}
				placeholder={formatMessage(messages.searchPlaceholder)}
				isCompact
				onChange={(event) => {
					fireUIAnalytics(createAnalyticsEvent({}), 'input updated', 'search');
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					const target = event.target as HTMLInputElement;
					setSearchValue(target.value);
					debouncedChange(target.value);
				}}
				elemBeforeInput={
					<EditorSearchIcon
						spacing="spacious"
						color={token('color.icon')}
						label={formatMessage(messages.searchPlaceholder)}
					/>
				}
				elemAfterInput={
					searchValue && (
						<IconButton
							icon={() => (
								<SelectClearIcon label="" LEGACY_size="small" color={token('color.icon')} />
							)}
							appearance="subtle"
							spacing="compact"
							onClick={(_event, analyticsEvent) => {
								fireUIAnalytics(analyticsEvent, 'clearSearch');
								setSearchValue('');
								onChange('');
							}}
							label={formatMessage(messages.clearSearch)}
						/>
					)
				}
			/>
		</Box>
	);
};

type FiltersProps = {
	filterCounters: {
		projectScopeFieldsCount: number;
		globalScopeFieldsCount: number;
	};
};

export const Filters = ({ filterCounters }: FiltersProps) => {
	const { formatMessage } = useIntl();

	const fieldScopeFilter = useFieldsScopeFilter();
	const searchQuery = useSearchQueryFilter();
	const { setFieldScopeFilter, setSearchQueryFilter } = useFieldsTableActions();

	const handleScopeFilterClick = useCallback(
		(scope: State['filters']['scope'], analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'scopeFilter', { scopeFilterValue: scope });
			setFieldScopeFilter(scope);
		},
		[setFieldScopeFilter],
	);

	return (
		<Inline spread="space-between" xcss={filterStyles}>
			<SpotlightTarget name={PROJECT_FIELDS_TOUR_SPOTLIGHTS_STEP_3}>
				<Inline space="space.100">
					<RoundedButton
						testId="polaris-ideas.ui.settings.fields.project-fields-screen.ui.fields-list.filters.all"
						isSelected={fieldScopeFilter === undefined}
						onClick={(_event, analyticsEvent) => handleScopeFilterClick(undefined, analyticsEvent)}
					>
						{formatMessage(messages.allFieldsFilter, {
							count: filterCounters.projectScopeFieldsCount + filterCounters.globalScopeFieldsCount,
						})}
					</RoundedButton>
					<RoundedButton
						testId="polaris-ideas.ui.settings.fields.project-fields-screen.ui.fields-list.filters.project"
						isSelected={fieldScopeFilter === 'project'}
						onClick={(_event, analyticsEvent) => handleScopeFilterClick('project', analyticsEvent)}
					>
						{formatMessage(messages.projectFieldsFilter, {
							count: filterCounters.projectScopeFieldsCount,
						})}
					</RoundedButton>
					<RoundedButton
						testId="polaris-ideas.ui.settings.fields.project-fields-screen.ui.fields-list.filters.global"
						isSelected={fieldScopeFilter === 'global'}
						onClick={(_event, analyticsEvent) => handleScopeFilterClick('global', analyticsEvent)}
					>
						{formatMessage(messages.globalFieldsFilter, {
							count: filterCounters.globalScopeFieldsCount,
						})}
					</RoundedButton>
				</Inline>
			</SpotlightTarget>
			<SearchInput value={searchQuery} onChange={setSearchQueryFilter} />
		</Inline>
	);
};

const filterStyles = xcss({
	marginBottom: 'space.200',
});

const searchInputWrapperStyles = xcss({
	width: '240px',
});
