import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	removeFilterButtonLabelNonFinal: {
		id: 'polaris-component-field-configuration.configuration.connection.remove-filter-button-label-non-final',
		defaultMessage: 'Remove filter',
		description: 'Label for the remove filter button',
	},
	headerNonFinal: {
		id: 'polaris-component-field-configuration.configuration.connection.header-non-final',
		defaultMessage: 'Source',
		description: 'Connection field configuration section header',
	},
	addFilterLabelNonFinal: {
		id: 'polaris-component-field-configuration.configuration.connection.add-filter-label-non-final',
		defaultMessage: 'Select source',
		description: 'Label for selecting the source for the connection field',
	},
	clearAllButtonLabelNonFinal: {
		id: 'polaris-component-field-configuration.configuration.connection.clear-all-button-label-non-final',
		defaultMessage: 'Clear all',
		description: 'Label for the clear all button',
	},
});
