import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	description: {
		id: 'polaris-ideas.idea-view.comments.empty-state.description',
		defaultMessage: 'Create comments to discuss, share opinions, and ask questions.',
		description: 'Description displayed when the idea has no comments',
	},
	descriptionReadOnly: {
		id: 'polaris-ideas.idea-view.comments.empty-state.description-read-only',
		defaultMessage: 'You will be able to follow here any discussions. Check it back later!',
		description:
			'Description displayed when the idea has no comments and the current user does have read-only permissions',
	},
	descriptionArchived: {
		id: 'polaris-ideas.idea-view.comments.empty-state.description-archived',
		defaultMessage:
			'The idea was archived before anyone commented on it. To add comments, you must restore the idea first.',
		description:
			'Description displayed when the view has no comments and the current idea is archived',
	},
});
