import {
	getUserProperty,
	setUserProperties,
} from '@atlassian/jira-common-rest/src/api/latest/user-properties/index.tsx';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import { logSafeErrorWithoutCustomerDataWrapper } from '@atlassian/jira-polaris-lib-errors/src/common/utils/index.tsx';
import { PROJECT_FIELDS_TOUR_DISMISSED } from '../../../constants.tsx';
import type { Props, State } from '../../../types.tsx';

export const loadProjectFieldsTourDismissed =
	(): Action<State, Props> =>
	async ({ getState, setState }, { accountId }) => {
		if (!accountId) {
			return;
		}

		// User property is already loaded
		if (typeof getState().projectFields.tourDismissed === 'boolean') {
			return;
		}

		try {
			const tourDismissed = await getUserProperty<boolean>(
				accountId,
				PROJECT_FIELDS_TOUR_DISMISSED,
			);

			const state = getState();

			setState({
				...state,
				projectFields: {
					...state.projectFields,
					tourDismissed,
				},
			});
		} catch (error) {
			// Expected error, the property is not set yet
			if (error instanceof FetchError && error.statusCode === 404) {
				const state = getState();

				setState({
					...state,
					projectFields: {
						...state.projectFields,
						tourDismissed: false,
					},
				});

				return;
			}

			logSafeErrorWithoutCustomerDataWrapper(
				'polaris.onboarding.project-fields.tour',
				`Failed to get user property with key: ${PROJECT_FIELDS_TOUR_DISMISSED}`,
				error instanceof Error ? error : undefined,
			);
		}
	};

export const setProjectFieldsTourDismissed =
	(): Action<State, Props> =>
	async ({ getState, setState }, { accountId }) => {
		if (!accountId) {
			return;
		}

		const state = getState();

		setState({
			...state,
			projectFields: {
				...state.projectFields,
				tourDismissed: true,
			},
		});

		try {
			await setUserProperties(accountId, PROJECT_FIELDS_TOUR_DISMISSED, 'true');
		} catch (error) {
			logSafeErrorWithoutCustomerDataWrapper(
				'polaris.onboarding.project-fields.tour',
				`Failed to set user property with key: ${PROJECT_FIELDS_TOUR_DISMISSED}`,
				error instanceof Error ? error : undefined,
			);
		}
	};
