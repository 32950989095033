// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createContainer, createStore, createActionsHook } from '@atlassian/react-sweet-state';
import actions from './actions/index.tsx';
import type { State, Props } from './types.tsx';
import { onInit, onUpdate } from './utils.tsx';

const DEFAULT_FILTER = {
	page: {
		number: 1,
		size: 1,
	},
	orderBy: {
		field: 'NAME',
		direction: 'ASC',
	},
};

const initialState: State = {
	projectActors: {
		filter: DEFAULT_FILTER,
	},
	ui: {
		manageRoles: {
			isOpen: false,
			isPersistingInProgress: false,
		},
		projectActors: {
			isLoading: false,
		},
		accessLevel: {
			isOpen: false,
			isChangeInProgress: false,
		},
	},
};

export type Actions = typeof actions;

export const ProjectAccessStore = createStore<State, Actions>({
	initialState,
	actions,
	name: 'PolarisProjectAccessComponentStore',
});

export const ProjectAccessContainer = createContainer<State, Actions, Props>(ProjectAccessStore, {
	onInit,
	onUpdate,
});

export const useActions = createActionsHook(ProjectAccessStore);
