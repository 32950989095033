import { useEffect, useMemo } from 'react';
import difference from 'lodash/difference';
import without from 'lodash/without';
import type { IssueTypeId, ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import { usePrevious } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';
import { useNotifications } from '@atlassian/jira-polaris-lib-notifications/src/controllers/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import {
	useAllIssueTypesById,
	useIssueTypesActions,
	useIssueTypeIdsForProject,
	useIssueTypeAvatarId,
	useIssueTypeDescription,
	useIssueTypeName,
	useIssueTypesProjectIds,
} from './controllers/index.tsx';
import type { IssueType } from './controllers/types.tsx';
import messages from './messages.tsx';

type ProjectIssueTypesFetcherProps = {
	projectId: ProjectId;
};

type ProjectsIssueTypesFetcherProps = {
	projectIds: ProjectId[];
};

export const ProjectsIssueTypesFetcher = ({ projectIds }: ProjectsIssueTypesFetcherProps) => {
	const fetchedProjectIds = useIssueTypesProjectIds();
	const { fetchIssueTypesForProjects } = useIssueTypesActions();
	const { errorWithRefresh } = useNotifications();
	const { formatMessage } = useIntl();

	useEffect(() => {
		const newProjectIds = difference(projectIds, fetchedProjectIds);

		if (!newProjectIds.length) {
			return;
		}

		fetchIssueTypesForProjects(newProjectIds).catch(() => {
			errorWithRefresh({
				title: formatMessage(messages.errorTitle),
				description: formatMessage(messages.errorDescription),
			});
		});
	}, [projectIds, fetchedProjectIds, fetchIssueTypesForProjects, errorWithRefresh, formatMessage]);

	return null;
};

export const ProjectIssueTypesFetcher = ({ projectId }: ProjectIssueTypesFetcherProps) => {
	const issueTypes = useIssueTypeIdsForProject({ projectId });
	const { fetchIssueTypesForProject } = useIssueTypesActions();

	useEffect(() => {
		if (issueTypes.length === 0) {
			fetchIssueTypesForProject(projectId);
		}
	}, [projectId, fetchIssueTypesForProject, issueTypes]);

	return null;
};

type IssueTypeChange = {
	current: IssueType;
	previous: IssueType;
};

type IssueTypeChangeProviderProps = {
	projectId: ProjectId;
	onChanged: (changed: IssueTypeChange[], deleted: IssueTypeId[]) => void;
};

export const IssueTypeChangeProvider = ({ projectId, onChanged }: IssueTypeChangeProviderProps) => {
	const issueTypeIds = useIssueTypeIdsForProject({ projectId });
	const issueTypes = useAllIssueTypesById();

	const prevIssueTypeIds = usePrevious(issueTypeIds);
	const prevIssueTypes = usePrevious(issueTypes);

	useEffect(() => {
		if (issueTypes !== prevIssueTypes) {
			const removedIds = without(prevIssueTypeIds || [], ...issueTypeIds);

			const changes: IssueTypeChange[] = [];

			issueTypeIds.forEach((id) => {
				const current = issueTypes[id];
				const previous = prevIssueTypes?.[id];
				if (current !== undefined && previous !== undefined && current !== previous) {
					changes.push({ current, previous });
				}
			});

			onChanged(changes, removedIds);
		}
	}, [issueTypeIds, prevIssueTypeIds, issueTypes, prevIssueTypes, onChanged]);

	return null;
};

export const useIssueType = (issueTypeId: IssueTypeId) => {
	const avatarId = useIssueTypeAvatarId({ issueTypeId });
	const name = useIssueTypeName({ issueTypeId });
	const description = useIssueTypeDescription({ issueTypeId });

	return useMemo(() => {
		if (avatarId === undefined || name === undefined || description === undefined) {
			return null;
		}
		return { avatarId, name, description };
	}, [avatarId, name, description]);
};
