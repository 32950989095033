/**
 * We cannot use a type guard here as importing TeamValue causes TS performance degradation.
 * See JFP-2288 for context.
 */
export const hasTeamName = (value: unknown): value is { name: string } => {
	return value !== null && typeof value === 'object' && Object.hasOwn(value, 'name');
};

/**
 * We cannot use a type guard here as importing TeamValue causes TS performance degradation.
 * See JFP-2288 for context.
 */
export const hasTeamAvatar = (value: unknown): value is { avatarUrl: string } => {
	return value !== null && typeof value === 'object' && Object.hasOwn(value, 'avatarUrl');
};
