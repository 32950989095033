import React from 'react';
import { styled } from '@compiled/react';
import { Flex } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

export const PolarisAppsPageHeader = () => {
	const { formatMessage } = useIntl();

	return (
		<Flex direction="column">
			<HeaderContainer>{formatMessage(messages.appsViewHeader)}</HeaderContainer>
			<SubheaderContainer>{formatMessage(messages.appsViewSubHeader)}</SubheaderContainer>
		</Flex>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeaderContainer = styled.div({
	font: token('font.heading.large'),
	maxWidth: '360px',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	color: colors.N700,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SubheaderContainer = styled.div({
	marginTop: token('space.100'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	color: colors.N500,
});
