import React, { type PropsWithChildren } from 'react';
import { useCurrentViewVerticalGroupBy } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { GroupProvider } from '@atlassian/jira-polaris-lib-data-arrangement/src/ui/group-context.tsx';
import { useExtendedVerticalGroupOptions } from '../../common/utils/vertical-group-options.tsx';

type GroupAwareWrapperProps = PropsWithChildren<{ groupId: string | undefined }>;
export const GroupAwareWrapper = ({ groupId, children }: GroupAwareWrapperProps) => {
	const verticalGroupByField = useCurrentViewVerticalGroupBy();
	const extendedOptions = useExtendedVerticalGroupOptions(verticalGroupByField);

	return verticalGroupByField ? (
		<GroupProvider
			fieldKey={verticalGroupByField.key}
			fieldValue={extendedOptions.find((option) => option.groupIdentity === groupId)?.value}
		>
			{children}
		</GroupProvider>
	) : (
		children
	);
};
