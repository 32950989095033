import React, { useEffect, useCallback, useState, useMemo, type ReactNode } from 'react';
import lowerCase from 'lodash/lowerCase';
import toString from 'lodash/toString';
import Button from '@atlaskit/button/new';
import { Stack, Box, Flex, Text, xcss } from '@atlaskit/primitives';
import Toggle from '@atlaskit/toggle';
import { token } from '@atlaskit/tokens';
import traceUFOPress from '@atlaskit/react-ufo/trace-press';
import { useIntl } from '@atlassian/jira-intl';
import { isGroupBySupportedFieldType } from '@atlassian/jira-polaris-common/src/controllers/field/utils/is-group-by-supported.tsx';
import { useOpenRightSidebarOnField } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/actions/hooks.tsx';
import {
	showingGroupByOptions,
	showingVerticalGroupByOptions,
	type Showing,
} from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/types.tsx';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import { useFieldsForViewControls } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/fields-hooks.tsx';
import {
	useCanManageCurrentView,
	useCurrentViewGroupBy,
	useCurrentViewHideEmptyBoardColumns,
	useCurrentViewHideEmptyGroups,
	useCurrentViewVerticalGroupBy,
	useCurrentViewKind,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { FieldSelect } from '@atlassian/jira-polaris-common/src/ui/common/field-select/index.tsx';
import { FieldSearchableDropdown } from '@atlassian/jira-polaris-component-field-searchable-dropdown/src/ui/field-searchable-dropdown/index.tsx';
import {
	useCanEditFields,
	useIsCollectionView,
} from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import type { Field, FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import {
	VIEW_KIND_TABLE,
	VIEW_KIND_BOARD,
} from '@atlassian/jira-polaris-domain-view/src/view/constants.tsx';
import type { ViewKind } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import { CrossProjectBoardViewFieldsOnboarding } from '@atlassian/jira-polaris-lib-onboarding-flows/src/ui/cross-project-view/fields/board-view/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { isGlobalNonSystemField } from '@atlassian/jira-polaris-domain-field/src/field/utils.tsx';
import { GroupOptions, VerticalGroupOptions } from './group-options/index.tsx';
import { messages } from './messages.tsx';

type BaseFieldConfigProps = {
	isClearable?: boolean;
	fields: {
		[key: string]: Field;
	};
	selectedField?: Field;
	helpText: ReactNode;
	children?: ReactNode;
	backTo?: Showing;
	testId?: string;
	onChange: (fieldKey?: FieldKey) => void;
	canHideEmptyGroups?: boolean;
	isDisabled?: boolean;
	createGlobalFieldLabel?: string;
	onBoardingMessage?: React.ReactNode;
};

const BaseFieldConfig = ({
	selectedField,
	fields,
	onChange,
	children,
	helpText,
	isClearable = false,
	backTo,
	testId = 'polaris-ideas.ui.view-controls.config-group-by.group-by-dropdown',
	canHideEmptyGroups = false,
	isDisabled = false,
	createGlobalFieldLabel,
	onBoardingMessage,
}: BaseFieldConfigProps) => {
	const { formatMessage } = useIntl();
	const openRightSidebarOnField = useOpenRightSidebarOnField();
	const canEditFields = useCanEditFields();
	const hideEmptyGroups = useCurrentViewHideEmptyGroups();
	const hideEmptyBoardColumns = useCurrentViewHideEmptyBoardColumns();
	const { setHideEmptyGroups, setHideEmptyColumns } = useViewActions();

	const { hideEmpty, setHideEmpty, toggleMessage } = useMemo(() => {
		if (backTo === showingGroupByOptions) {
			return {
				hideEmpty: hideEmptyBoardColumns,
				setHideEmpty: setHideEmptyColumns,
				toggleMessage: messages.hideEmptyColumnsToggle,
			};
		}
		return {
			hideEmpty: hideEmptyGroups,
			setHideEmpty: setHideEmptyGroups,
			toggleMessage: messages.hideEmptyGroupsToggle,
		};
	}, [backTo, hideEmptyBoardColumns, hideEmptyGroups, setHideEmptyColumns, setHideEmptyGroups]);

	// for optimistic updates
	const [isToggleOn, setToggle] = useState(hideEmpty);

	useEffect(() => setToggle(hideEmpty), [hideEmpty]);

	const fieldList = useMemo(
		() =>
			Object.keys(fields)
				.map((key) => fields[key])
				.filter((field) => field.key !== selectedField?.key)
				.filter((field) => isGroupBySupportedFieldType(field.type))
				.sort((a, b) => a.label.localeCompare(b.label))
				.map((field) => ({
					key: field.key,
					label: field.label,
					type: field.type,
					emoji: field.emoji,
					hasWeightType: field.configuration?.optionWeightType !== undefined,
					configuration: field.configuration,
					isGlobalNonSystemField: isGlobalNonSystemField(field),
				})),
		[fields, selectedField],
	);

	const onChangeToggle = () => {
		if (isToggleOn) {
			setHideEmpty(false);
		} else {
			setHideEmpty(true);
		}
		setToggle(!isToggleOn);
		if (fg('jpd_group_by_and_columns_config_ufo_fixes')) {
			traceUFOPress('jpd.view-controls.hide-empty-groups-toggle');
		}
	};

	return (
		<Box testId="polaris-ideas.ui.view-controls.config-group-by.group-by-dropdown">
			<Stack testId={testId}>
				<Box paddingInline="space.200" paddingBlockEnd="space.100" paddingBlockStart="space.200">
					{isDisabled ? (
						<Box xcss={sectionStyles}>{formatMessage(messages.groupByDisabledText)}</Box>
					) : (
						helpText
					)}
				</Box>
				{onBoardingMessage}
				<Box xcss={fieldGroupStyles}>
					<FieldSelect
						isClearable={isClearable}
						selectedField={selectedField}
						onChange={onChange}
						fieldOptions={fieldList}
						SearchableComponent={FieldSearchableDropdown}
						isDisabled={isDisabled}
						createGlobalFieldLabel={createGlobalFieldLabel}
					/>
				</Box>
				{children}
				{selectedField && (
					<Box paddingInlineStart="space.200">
						<Button
							interactionName="jpd.view-controls.open-field-settings"
							onClick={() => openRightSidebarOnField(selectedField.key, backTo)}
						>
							{!canEditFields
								? formatMessage(messages.fieldSettings)
								: formatMessage(messages.editField)}
						</Button>
					</Box>
				)}
				{selectedField && canHideEmptyGroups && (
					<>
						<Box xcss={dividerStyles} />
						<Box paddingInline="space.200" paddingBlockEnd="space.200">
							<Flex alignItems="center" gap="space.100">
								<Toggle isChecked={isToggleOn} onChange={onChangeToggle} isDisabled={isDisabled} />
								<Text color={isDisabled ? 'color.text.disabled' : undefined}>
									{formatMessage(toggleMessage)}
								</Text>
							</Flex>
						</Box>
					</>
				)}
			</Stack>
		</Box>
	);
};

export const ConfigGroupBy = () => {
	const { formatMessage } = useIntl();
	const viewKind = useCurrentViewKind();
	const groupBy = useCurrentViewGroupBy();
	const { setGroupBy } = useViewActions();
	const fields = useFieldsForViewControls();
	const canManageCurrentView = useCanManageCurrentView();
	const [isCollectionView] = useIsCollectionView();

	const isDisabled = !canManageCurrentView;

	const handleChange = useCallback(
		(fieldKey: string | undefined) => {
			if (fieldKey === undefined) {
				return;
			}
			if (fg('jpd_group_by_and_columns_config_ufo_fixes')) {
				traceUFOPress(
					fieldKey === undefined
						? 'jpd.view-controls.config-group-by.field-remove'
						: 'jpd.view-controls.config-group-by.field-select',
				);
			}
			setGroupBy(fieldKey);
			fireCompoundAnalyticsEvent.RightSidebarGroupByColumnsFieldChanged();
		},
		[setGroupBy],
	);

	const testId = useMemo(
		() => `polaris-ideas.ui.view-control.config-columns.group-by.${lowerCase(toString(viewKind))}`,
		[viewKind],
	);

	return (
		<BaseFieldConfig
			helpText={formatMessage(messages.columnsHelpText)}
			onBoardingMessage={isCollectionView && <CrossProjectBoardViewFieldsOnboarding />}
			fields={fields}
			selectedField={groupBy}
			backTo={showingGroupByOptions}
			onChange={handleChange}
			testId={testId}
			canHideEmptyGroups
			isDisabled={isDisabled}
			createGlobalFieldLabel={formatMessage(messages.createGlobalFieldButton)}
		>
			{groupBy && <GroupOptions field={groupBy} isDisabled={isDisabled} />}
		</BaseFieldConfig>
	);
};

const getMessageForViewKind = (viewKind?: ViewKind) => {
	switch (viewKind) {
		case VIEW_KIND_TABLE:
			return messages.groupByHelpTextForListView;
		case VIEW_KIND_BOARD:
			return messages.groupByHelpText;
		default:
			return messages.groupByHelpText;
	}
};

export const ConfigVerticalGroupBy = () => {
	const { formatMessage } = useIntl();
	const viewKind = useCurrentViewKind();
	const verticalGroupBy = useCurrentViewVerticalGroupBy();
	const { setVerticalGroupBy } = useViewActions();
	const fields = useFieldsForViewControls();
	const canManageCurrentView = useCanManageCurrentView();

	const isDisabled = !canManageCurrentView;

	const handleChange = useCallback(
		(fieldKey?: FieldKey): void => {
			if (fg('jpd_group_by_and_columns_config_ufo_fixes')) {
				traceUFOPress(
					fieldKey === undefined
						? 'jpd.view-controls.config-vertical-group-by.field-remove'
						: 'jpd.view-controls.config-vertical-group-by.field-select',
				);
			}
			experience.headerView.viewGroupBy.start();
			setVerticalGroupBy(
				fieldKey,
				() => {
					experience.headerView.viewGroupBy.success();
				},
				(error?: Error) => {
					experience.headerView.viewGroupBy.failure(error);
				},
			);
			fireCompoundAnalyticsEvent.RightSidebarGroupByRowsFieldChanged();
		},
		[setVerticalGroupBy],
	);

	return (
		<BaseFieldConfig
			isClearable={!isDisabled}
			helpText={formatMessage(getMessageForViewKind(viewKind))}
			fields={fields}
			selectedField={verticalGroupBy}
			onChange={handleChange}
			backTo={showingVerticalGroupByOptions}
			testId={`polaris-ideas.ui.view-control.config-columns.vertical-group-by.${lowerCase(
				toString(viewKind),
			)}`}
			canHideEmptyGroups
			isDisabled={isDisabled}
		>
			{verticalGroupBy ? (
				<VerticalGroupOptions field={verticalGroupBy} isDisabled={isDisabled} />
			) : null}
		</BaseFieldConfig>
	);
};

const fieldGroupStyles = xcss({
	marginBlock: 'space.100',
	paddingInline: 'space.200',
});

const dividerStyles = xcss({
	height: '1px',
	background: token('color.border'),
	marginInline: 'space.200',
	marginBlock: 'space.300',
});

const sectionStyles = xcss({
	padding: 'space.150',
	backgroundColor: 'color.background.information',
});
