import React, { useMemo } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { useIsSharedView } from '@atlassian/jira-polaris-common/src/controllers/environment/index.tsx';
import { useIssueViewSection } from '@atlassian/jira-polaris-common/src/controllers/route/index.tsx';
import { useCurrentViewSharingSettings } from '@atlassian/jira-polaris-common/src/controllers/sharing/selectors/hooks.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { InsightsSkeleton } from '../../../insights/insights/skeleton/index.tsx';
import { CommentsStreamSkeleton } from '../../comments/skeleton/index.tsx';
import { DeliverSkeleton } from '../deliver/skeleton/index.tsx';
import { useTabs } from '../utils.tsx';

export const SectionsSkeleton = ({ localIssueId }: { localIssueId: LocalIssueId }) => {
	const isSharedView = useIsSharedView();
	const sharingOptions = useCurrentViewSharingSettings();
	const tabs = useTabs({ localIssueId, isSharedView, sharingOptions });
	const issueViewSection = useIssueViewSection();
	const content = useMemo(() => {
		switch (issueViewSection) {
			case 'comments':
				return <CommentsStreamSkeleton />;
			case 'deliver':
				return <DeliverSkeleton />;
			case 'history':
				// history package has its own skeleton
				return <></>;
			case 'capture':
			default:
				return <InsightsSkeleton />;
		}
	}, [issueViewSection]);

	return (
		<>
			<DisabledTabsContainer>
				{tabs.map(({ label }, index) => (
					<DisabledTab key={index}>{label}</DisabledTab>
				))}
			</DisabledTabsContainer>
			{content}
		</>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DisabledTabsContainer = styled.div({
	display: 'flex',
	flexDirection: 'row',
	marginBottom: token('space.025'),
	position: 'relative',
	'&::after': {
		content: '',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		backgroundColor: token('color.background.disabled'),
		borderRadius: '2px',
		bottom: '0px',
		height: '2px',
		left: token('space.100'),
		margin: '0px',
		position: 'absolute',
		right: token('space.100'),
		width: 'inherit',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DisabledTab = styled.div({
	paddingTop: token('space.050'),
	paddingRight: token('space.100'),
	paddingBottom: token('space.050'),
	paddingLeft: token('space.100'),

	color: token('color.text.disabled'),
	fontWeight: token('font.weight.medium'),
});
