import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { MoveRoleRequest } from './types.tsx';

const getUrl = (projectId: ProjectId) =>
	`/rest/internal/simplified/1.0/actors/moveAll?projectId=${projectId}`;

export const moveRole = ({
	projectId,
	sourceProjectRoleId,
	destProjectRoleId,
}: MoveRoleRequest): Promise<void> =>
	performPostRequest(getUrl(projectId), {
		body: JSON.stringify({
			destProjectRoleId,
			sourceProjectRoleId,
		}),
	});
