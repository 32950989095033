import { createJpdContainer } from '@atlassian/jira-polaris-lib-react-sweet-state-utils/src/utils/hooks/index.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createStore } from '@atlassian/react-sweet-state';
import * as actions from './actions/index.tsx';
import type { Props, State } from './types.tsx';

type Actions = typeof actions;

const initialState: State = {
	filters: {
		search: '',
		scope: undefined,
	},
	sort: {
		column: undefined,
		direction: 'asc',
	},
	containerProps: {
		formatMessage: ({ defaultMessage }) => defaultMessage || '',
	},
};

const FieldsTableStore = createStore<State, Actions>({
	name: 'PolarisFieldsTableStore',
	initialState,
	actions,
});

export const {
	Container: FieldsTableContainer,
	useActions: useFieldsTableActions,
	createHook,
	createHigherLevelHook,
} = createJpdContainer<Props, State, Actions>(FieldsTableStore);
