import React, { type ComponentType } from 'react';
import { styled } from '@compiled/react';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

export type Props = {
	isRecommended: boolean;
	isActionEnabled: boolean;
	title: string;
	description: string;
	icon: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	Action?: ComponentType<Record<any, any>> | undefined;
};

export const PolarisAppPanel = ({
	title,
	description,
	icon,
	isRecommended,
	Action,
	isActionEnabled,
}: Props) => {
	const { formatMessage } = useIntl();

	return (
		<PanelWrapper isRecommended={isRecommended}>
			<PanelHeader>
				<PanelHeaderCaption>{title}</PanelHeaderCaption>
				{isRecommended && (
					<PanelHeaderRecommendedIndicator>
						{formatMessage(messages.recommended)}
					</PanelHeaderRecommendedIndicator>
				)}
			</PanelHeader>
			<MainContentPanel>
				<AppIconPanel>
					<AppIconImage src={icon} />
				</AppIconPanel>
				<InnerContentPanel>
					<DescriptionPanel>{description}</DescriptionPanel>
					{isActionEnabled ? (
						<Box xcss={actionPanelStyles}>
							{Action === undefined && (
								<ComingSoonPanel>{formatMessage(messages.comingSoon)}</ComingSoonPanel>
							)}
							{Action !== undefined && <Action />}
						</Box>
					) : null}
				</InnerContentPanel>
			</MainContentPanel>
		</PanelWrapper>
	);
};

PolarisAppPanel.defaultProps = {
	isActionEnabled: true,
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PanelWrapper = styled.div<{ isRecommended: boolean }>({
	display: 'flex',
	flexDirection: 'column',
	borderRadius: '3px',
	paddingTop: token('space.100'),
	paddingBottom: token('space.100'),
	paddingLeft: token('space.200'),
	paddingRight: token('space.200'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	background: ({ isRecommended }) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		isRecommended ? token('color.background.information') : undefined,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PanelHeader = styled.div({
	display: 'flex',
	flexDirection: 'row',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PanelHeaderCaption = styled.div({
	color: token('color.text'),
	font: token('font.body'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PanelHeaderRecommendedIndicator = styled.div({
	marginLeft: token('space.150'),

	color: token('color.text.inverse'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.background.information.bold'),
	borderRadius: '3px',
	paddingTop: token('space.025'),
	paddingRight: token('space.050'),
	paddingBottom: token('space.025'),
	paddingLeft: token('space.050'),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	textTransform: 'uppercase',
	font: token('font.body.small'),
	fontWeight: token('font.weight.bold'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MainContentPanel = styled.div({
	marginTop: token('space.100'),
	display: 'flex',
	flexDirection: 'row',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AppIconPanel = styled.div({
	width: '36px',
	height: '36px',
	minWidth: '36px',
	minHeight: '36px',
	display: 'flex',
	boxShadow: token('elevation.shadow.raised'),
	alignItems: 'center',
	justifyContent: 'center',

	backgroundColor: token('elevation.surface'),
	borderRadius: '3px',
	boxSizing: 'border-box',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AppIconImage = styled.img({
	width: '28px',
	height: '28px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InnerContentPanel = styled.div({
	marginLeft: token('space.100'),
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'flex-start',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DescriptionPanel = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtle'),
	font: token('font.body'),
});

const actionPanelStyles = xcss({
	marginTop: 'space.050',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ComingSoonPanel = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtle'),
});
