import React, { useCallback, useMemo } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import Avatar from '@atlaskit/avatar';
import { token } from '@atlaskit/tokens';
import { PRODUCT_DISCOVERY_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { POLARIS_GLOBAL_SUBMIT_IDEA_SWITCH_PROJECTS_TRIGGER } from '@atlassian/jira-packages-controllers-use-trigger-issue-create-modal/src/constants.tsx';
import { useTriggerIssueCreateModal } from '@atlassian/jira-packages-controllers-use-trigger-issue-create-modal/src/main.tsx';
import { useProjectId } from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import { useProjectMetadata } from '@atlassian/jira-polaris-component-project-metadata/src/controllers/index.tsx';
import ProjectPicker from '@atlassian/jira-project-picker/src/index.tsx';
import messages from './messages.tsx';

export const IMPORT_ISSUES_URL =
	'/secure/BulkCreateSetupPage!default.jspa?externalSystem=com.atlassian.jira.plugins.jim-plugin%3AbulkCreateCsv';

type Props = {
	onProjectSwitch: () => void;
};

export const ProjectInfo = ({ onProjectSwitch }: Props) => {
	const projectId = useProjectId();
	const projectMeta = useProjectMetadata({ projectId });
	const { formatMessage } = useIntl();

	const projectKey = projectMeta?.key;
	const projectName = projectMeta?.name;
	const projectAvatarUrls = projectMeta?.avatarUrls;

	const [, { openIssueCreateModal }] = useTriggerIssueCreateModal();

	const selectedProjectPicker = useMemo(() => {
		if (projectMeta?.id && projectKey) {
			return {
				value: projectKey,
				name: projectName,
				label: projectName,
				avatarUrl: projectAvatarUrls?.['16x16'],
				details: {
					id: projectMeta.id,
					key: projectKey,
					issueTypes: [],
					lead: undefined,
					projectTypeKey: PRODUCT_DISCOVERY_PROJECT,
					simplified: true,
				},
			};
		}
		return undefined;
	}, [projectMeta, projectAvatarUrls, projectKey, projectName]);

	const onGlobalIssueCreate = useCallback(() => {
		onProjectSwitch();

		openIssueCreateModal({
			triggerPointKey: POLARIS_GLOBAL_SUBMIT_IDEA_SWITCH_PROJECTS_TRIGGER,
		});
	}, [onProjectSwitch, openIssueCreateModal]);

	return (
		<Box>
			<Box xcss={projectLabelStyles}>{formatMessage(messages.projectLabel)}</Box>
			<ProjectPicker
				// eslint-disable-next-line jira/deprecations/no-base-url
				baseUrl=""
				isCompact
				isClearable={false}
				width="300px"
				selectedProject={selectedProjectPicker}
				requireBrowsePermission
				placeholder=""
				onChange={onGlobalIssueCreate}
				renderAvatar={(avatarUrl) => <Avatar appearance="square" size="xsmall" src={avatarUrl} />}
			/>
		</Box>
	);
};

const projectLabelStyles = xcss({
	font: token('font.body.UNSAFE_small'),
	fontWeight: token('font.weight.semibold'),
	marginBottom: 'space.100',
});
