import React, { useCallback } from 'react';
import Button, { type ButtonProps } from '@atlaskit/button';
import { Box } from '@atlaskit/primitives';
import { PRODUCT_DISCOVERY_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { getNextgenSettingsWorkflowUrl } from '@atlassian/jira-project-settings-apps-common/src/urls.tsx';
import { useRouterActions } from '@atlassian/react-resource-router';
import { fg } from '@atlassian/jira-feature-gating';
import { getTypesSettingsUrl } from '@atlassian/jira-polaris-component-issue-types-manager/src/ui/utils/get-settings-url.tsx';
import {
	useCanEditField,
	useProjectKey,
	useReadonly,
} from '../../../../controllers/selectors/config-hooks.tsx';
import { useCallbacks } from '../../../../controllers/selectors/callback-hooks.tsx';
import messages from './messages.tsx';

export const StatusDecorationItem = () => {
	const projectKey = useProjectKey();
	const isReadonly = useReadonly();
	const canEditField = useCanEditField();
	const { push } = useRouterActions();
	const { formatMessage } = useIntl();
	const { onEditWorkflow } = useCallbacks();

	const onClick = useCallback<NonNullable<ButtonProps['onClick']>>(
		(_, analyticsEvent) => {
			if (!projectKey) {
				return;
			}

			fireUIAnalytics(analyticsEvent, 'editWorkflowButton clicked', 'config-fields');

			if (fg('jpd_issue_types_ga')) {
				// user can manage workflows from types settings
				push(getTypesSettingsUrl({ projectKey }));
				onEditWorkflow?.();
			} else {
				// Third argument is issueTypeId which is not used for JPD, so it's
				// ok to pass an empty string
				push(getNextgenSettingsWorkflowUrl(PRODUCT_DISCOVERY_PROJECT, projectKey, ''));
			}
		},
		[onEditWorkflow, projectKey, push],
	);

	return (
		<Box>
			<Button appearance="default" isDisabled={isReadonly || !canEditField} onClick={onClick}>
				{formatMessage(messages.editWorkflow)}
			</Button>
		</Box>
	);
};
