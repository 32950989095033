import type { GroupUuid } from '@atlassian/jira-shared-types/src/general.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import { addContributorGroupToProject } from '../../../services/jira/add-contributor-group/index.tsx';
import { setRoleForGroups } from '../../../services/jira/set-role-for-actor/index.tsx';
import { getHasManuallyAddedContributorAccess } from '../../selectors/contributor-group.tsx';
import type { Props, RoleId, State } from '../../types.tsx';

/**
 * @deprecated only used for non-identity contributor role
 */
export const addContributorGroup =
	(): Action<State, Props> =>
	(api, { projectId }) => {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		addContributorGroupToProject({ projectId }).then(() => window.location.reload());
	};

export const addManuallyAddedGroupsWithRole =
	(groupIds: GroupUuid[], roleId: RoleId): Action<State, Props> =>
	(api, { projectId, onError }) => {
		setRoleForGroups(roleId, groupIds, projectId).catch(onError);
		groupIds.forEach((groupId) => {
			api.setState({
				hasManuallyAddedContributorAccess: {
					...getHasManuallyAddedContributorAccess(api.getState()),
					[groupId]: true,
				},
			});
		});
	};
