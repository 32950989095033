// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import type { SpotlightTypes } from '../../../common/types.tsx';
import { getRemoteState } from '../../selectors/remote-state.tsx';
import { createIsSpotlightClosed } from '../../selectors/spotlights.tsx';
import type { Props, State } from '../../types.tsx';
import { saveRemoteState } from '../remote-state/index.tsx';

export const closeSpotlight =
	(spotlightType: SpotlightTypes): Action<State, Props> =>
	({ getState, dispatch }) => {
		const state = getState();
		const remoteState = getRemoteState(state);
		if (!remoteState) {
			return;
		}

		const isClosed = createIsSpotlightClosed(spotlightType)(state);
		if (isClosed) {
			return;
		}

		dispatch(
			saveRemoteState({
				...remoteState,
				spotlights: {
					...remoteState.spotlights,
					[spotlightType]: true,
				},
			}),
		);
	};

export const ignoreAllSpotlights =
	(): Action<State, Props> =>
	({ setState }, { spotlights }) => {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		setState({ ignoredSpotlights: Object.keys(spotlights) as SpotlightTypes[] });
	};

export const updateIgnoredSpotlights =
	(): Action<State, Props> =>
	({ setState }, { spotlights }) => {
		setState({
			ignoredSpotlights: Object.entries(spotlights)
				.filter(([_, isAvailable]) => !isAvailable) // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				.map(([spotlightKey]) => spotlightKey as SpotlightTypes),
		});
	};
