import { useEffect } from 'react';
import type { Group } from '../types/common.tsx';

const groupsCache = new Map<string, Group>();

const createGroup = (uid: string): Group => ({
	uid,
	columns: [],
	height: 0,
	contentHeight: 0,
	offset: 0,
	maxColumnContentHeight: 0,
	isCollapsed: true,
});

export const createGroupNullObject = (groupUid: string) => {
	const cachedGroup = groupsCache.get(groupUid);
	if (cachedGroup) {
		return cachedGroup;
	}

	const group = createGroup(groupUid);
	groupsCache.set(group.uid, group);
	return group;
};

export const clearGroupsCache = () => {
	groupsCache.clear();
};

export const useClearGroupNullObjectCache = () => {
	useEffect(() => () => clearGroupsCache(), []);
};
