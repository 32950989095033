import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	title: {
		id: 'polaris-lib-onboarding-flows.share-view.spotlight.changeboarding-title',
		defaultMessage: 'Share with your team',
		description: 'Title for the share view spotlight',
	},
	content: {
		id: 'polaris-lib-onboarding-flows.share-view.spotlight.changeboarding-content',
		defaultMessage:
			'Add your team to the project by opening <b>Share</b> and searching by name or email.',
		description: 'Content for the share view spotlight',
	},
	cta: {
		id: 'polaris-lib-onboarding-flows.share-view.spotlight.cta',
		defaultMessage: 'Add people',
		description: 'CTA text for the share view spotlight',
	},
});
