import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { styled } from '@compiled/react';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import Button from '@atlaskit/button';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import type { Ari } from '@atlassian/jira-platform-ari/src/index.tsx';
import type { PolarisPlayContribution } from '@atlassian/jira-polaris-domain-field/src/play/types.tsx';
import { useEntityLimitMessage } from '@atlassian/jira-polaris-lib-limits/src/controllers/index.tsx';
import { CreateEntityLimitTooltip } from '@atlassian/jira-polaris-lib-limits/src/ui/index.tsx';
import { emptyAdfObject } from '@atlassian/jira-rich-content/src/common/adf-parsing-utils.tsx';
import { useIsIssueArchived } from '../../../controllers/issue/selectors/properties/hooks.tsx';
import {
	usePlayCommentsMeta,
	useHasReachedPlayContributionsLimit,
} from '../../../controllers/project/selectors/plays-hooks.tsx';
import {
	useCurrentUserAvatar,
	useCurrentUserDisplayName,
} from '../../../controllers/user/index.tsx';
import { DEFAULT_PRESELECTED_VOTE_VALUE } from '../constants.tsx';
import { calcVotesAvailable, getVoteAmountInRange } from '../utils.tsx';
import { VoteInput } from '../vote-input/index.tsx';
import { VoteButton } from '../vote-input/vote-button/index.tsx';
import { PlayContributionFooterComponent } from './footer/index.tsx';
import { PlayContributionHeaderComponent } from './header/index.tsx';
import { PlayContributionInputEditorComponent } from './input-editor/index.tsx';
import messages from './messages.tsx';

type Props = {
	playId: string;
	localIssueId: string;
	contribution?: PolarisPlayContribution;
	maxSpendPerPlay: number;
	totalContributionsByUser?: number;
	onSave: (arg1: ADF, arg2: number) => Promise<boolean>;
	onDelete: (arg1: Ari) => Promise<boolean>;
	onEnterEditMode?: () => void;
	onDirty: (_: boolean) => void;
};

type EmptyViewProps = {
	onActivate: () => void;
	playId: string;
	disabled?: boolean;
};

type EditViewProps = {
	contribution?: PolarisPlayContribution;
	totalContributionsByUser?: number;
	maxSpendPerPlay: number;
	onSave: (arg1: ADF, arg2: number) => Promise<boolean>;
	onDelete: (arg1: Ari) => Promise<boolean>;
	onDiscard: () => void;
	onDirty: (_: boolean) => void;
	localIssueId: string;
};

const AddContributionEmptyView = ({ onActivate, playId, disabled }: EmptyViewProps) => {
	const { formatMessage } = useIntl();
	const [limitType, limitValue] = useHasReachedPlayContributionsLimit(playId);
	const getEntityLimitMessage = useEntityLimitMessage();

	const isDisabled = disabled || !!limitType;

	return (
		<EmptyViewContainer>
			<CreateEntityLimitTooltip message={limitType && getEntityLimitMessage(limitType, limitValue)}>
				<Button
					testId="polaris-common.ui.plays.add-contribution.button"
					onClick={onActivate}
					isDisabled={isDisabled}
				>
					{formatMessage(messages.addContributionPlaceholder)}
				</Button>
			</CreateEntityLimitTooltip>
		</EmptyViewContainer>
	);
};

const AddContributionEditView = ({
	onSave,
	onDelete,
	onDiscard,
	onDirty,
	contribution,
	totalContributionsByUser = 0,
	maxSpendPerPlay,
	localIssueId,
}: EditViewProps) => {
	const currentUserAvatar = useCurrentUserAvatar();
	const currentUser = useCurrentUserDisplayName();
	const isIdeaArchived = useIsIssueArchived(localIssueId);

	const [isSaving, setIsSaving] = useState(false);
	const [isEditMode, setIsEditMode] = useState(false);

	const originalCommentBody = contribution?.comment?.body ?? emptyAdfObject;
	const [commentBody, setCommentBody] = useState(originalCommentBody);

	const playCommentsMeta = usePlayCommentsMeta();

	useEffect(() => {
		if (!commentBody.content.length) {
			setCommentBody(originalCommentBody);
		}
	}, [originalCommentBody, setCommentBody, commentBody]);

	const hasNoVotesLeft = maxSpendPerPlay - (totalContributionsByUser ?? 0) <= 0;

	const lastContributionAmount = contribution?.amount;
	const currentAmount =
		lastContributionAmount ?? (hasNoVotesLeft ? 0 : DEFAULT_PRESELECTED_VOTE_VALUE);

	const [localAmount, setLocalAmount] = useState(currentAmount);

	// Votes logic
	const votesLeft = Math.max(maxSpendPerPlay - totalContributionsByUser, 0);
	const votesAvailableForIdea = useMemo(
		() =>
			calcVotesAvailable(
				votesLeft,
				lastContributionAmount ?? 0,
				maxSpendPerPlay,
				totalContributionsByUser,
			),
		[lastContributionAmount, maxSpendPerPlay, totalContributionsByUser, votesLeft],
	);

	const doSave = useCallback(
		(value: ADF) => {
			setIsSaving(true);
			// Clear changed state
			setIsEditMode(false);
			// Update body
			setCommentBody(value);
			// Save it to polaris api
			const newAmount = getVoteAmountInRange(localAmount, votesAvailableForIdea);
			if (localAmount !== newAmount) {
				setLocalAmount(newAmount);
			}
			onSave(value, newAmount).then(() => {
				setIsSaving(false);
			});
		},
		[onSave, localAmount, votesAvailableForIdea],
	);

	const doDiscard = useCallback(() => {
		// Clear changed state after onEditorChange
		setTimeout(() => setIsEditMode(false));
		// Revert votes to original amount
		setLocalAmount(currentAmount);
		// Pass to the parent
		onDiscard();
	}, [currentAmount, onDiscard, setLocalAmount]);

	const doDelete = useCallback(
		(contributionId: Ari) => {
			onDelete(contributionId);
		},
		[onDelete],
	);

	const onEditModeActivate = useCallback(() => setIsEditMode(true), []);

	return (
		<ContributionContainer data-testid="polaris-common.ui.plays.add-contribution.contribution-container">
			<PlayContributionHeaderComponent
				avatarUrl={currentUserAvatar}
				displayName={currentUser}
				created={contribution?.comment?.created}
				updated={contribution?.comment?.updated}
			>
				{!isEditMode && contribution?.id && (
					<VoteButton
						isArchived={isIdeaArchived}
						isDisabled={(hasNoVotesLeft && !contribution?.id) || isIdeaArchived}
						value={localAmount}
						onClick={onEditModeActivate}
					/>
				)}
			</PlayContributionHeaderComponent>
			<EditViewBody>
				{(!contribution?.id || isEditMode) && (
					<VoteInput
						onChange={setLocalAmount}
						maxVotes={votesAvailableForIdea}
						localAmount={localAmount}
						isDisabled={hasNoVotesLeft && !contribution?.id}
						// currentAmount defaults to 1 if lastContributionAmount is undefined, however this is only reflected in the UI, the actual value used in calculations is 0
						// difference of currentAmount and localAmount is added to have the actual live number of votes left based on the value in the input
						votesLeft={votesLeft + (!lastContributionAmount ? 0 : currentAmount) - localAmount}
					/>
				)}
				<CommentInputWrapper data-testid="polaris-common.ui.plays.add-contribution.comment-input-wrapper">
					<PlayContributionInputEditorComponent
						commentBody={commentBody}
						onDiscard={doDiscard}
						onSave={doSave}
						onChange={onDirty}
						isCommentsLoading={playCommentsMeta.loading}
						isSaving={isSaving}
						isNewContribution={!contribution?.id}
						isEditMode={isEditMode}
					/>
					{contribution?.id && !isEditMode && !isIdeaArchived && (
						<PlayContributionFooterComponent
							contributionId={contribution.id}
							onDeleteRequested={() => doDelete(contribution.id)}
							onEditRequested={onEditModeActivate}
						/>
					)}
				</CommentInputWrapper>
			</EditViewBody>
		</ContributionContainer>
	);
};

export const AddPlayContributionComponent = ({
	playId,
	localIssueId,
	onSave,
	onDelete,
	onEnterEditMode,
	onDirty,
	maxSpendPerPlay,
	contribution,
	totalContributionsByUser,
}: Props) => {
	const [inEditMode, setEditMode] = useState(false);
	const isIdeaArchived = useIsIssueArchived(localIssueId);

	const enterEditMode = useCallback(() => {
		setEditMode(true);

		if (onEnterEditMode !== undefined) {
			onEnterEditMode();
		}
	}, [onEnterEditMode]);

	const handleSave = (content: ADF, localAmount: number) => {
		if (content.content.length === 0) {
			return Promise.resolve(true);
		}
		return onSave(content, localAmount).then((success) => {
			setEditMode(false);
			onDirty(false);
			return success;
		});
	};

	const handleDiscard = useCallback(() => {
		setEditMode(false);
		onDirty(false);
	}, [onDirty]);

	return (
		<AddContributionContainer data-testid="polaris-common.ui.plays.add-contribution.add-contribution-container">
			{(inEditMode || (!inEditMode && contribution)) && (
				<AddContributionEditView
					localIssueId={localIssueId}
					onSave={handleSave}
					onDelete={onDelete}
					onDiscard={handleDiscard}
					onDirty={onDirty}
					contribution={contribution}
					totalContributionsByUser={totalContributionsByUser}
					maxSpendPerPlay={maxSpendPerPlay}
				/>
			)}
			{!inEditMode && !contribution && (
				<AddContributionEmptyViewContainer>
					<AddContributionEmptyView
						onActivate={enterEditMode}
						playId={playId}
						disabled={isIdeaArchived}
					/>
				</AddContributionEmptyViewContainer>
			)}
		</AddContributionContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AddContributionContainer = styled.div({
	display: 'flex',
	flexDirection: 'row',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContributionContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
	width: '100%',
	paddingTop: token('space.200'),
	paddingRight: 0,
	paddingBottom: token('space.200'),
	paddingLeft: 0,
	boxSizing: 'border-box',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EmptyViewContainer = styled.div({
	display: 'flex',
	flexDirection: 'row',
	width: '100%',
	paddingTop: token('space.200'),
	paddingRight: 0,
	paddingBottom: token('space.200'),
	paddingLeft: 0,
	boxSizing: 'border-box',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EditViewBody = styled.div({
	display: 'grid',
	gridTemplateColumns: '1fr',
	gridTemplateRows: 'auto auto',
	gap: `${token('space.050')} 0px`,
	gridTemplateAreas: '"vote" "comment"',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginLeft: '42px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CommentInputWrapper = styled.div({
	display: 'flex',
	flexDirection: 'column',
	gridArea: 'comment',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AddContributionEmptyViewContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
});
