import React, { useCallback } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { Checkbox } from '@atlassian/jira-polaris-lib-checkbox/src/ui/index.tsx';

type CheckboxFieldProps = {
	isDisabled: boolean;
	value: number | undefined;
	onChange?: (arg1: number) => void;
	testId?: string;
};

export const CheckboxField = ({ value, onChange, isDisabled, testId }: CheckboxFieldProps) => {
	const handleChange = useCallback(() => {
		if (isDisabled) {
			return;
		}
		onChange?.(Number(!value));
	}, [isDisabled, onChange, value]);

	// add an extra div to ensure it's aligned to the left
	return (
		<Container data-testid={testId}>
			<Checkbox onChange={handleChange} isChecked={Boolean(value)} isDisabled={isDisabled} />
		</Container>
	);
};

CheckboxField.defaultProps = {
	isDisabled: false,
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	width: '100%',
	paddingLeft: token('space.025'),
});
