import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { Skeleton } from '@atlassian/jira-common-components-skeleton/src/styled.tsx';

const SkeletonGridItem = () => <Skeleton height="18px" width="100%" />;

const SkeletonHeader = () => (
	<SkeletonHeaderWrapper>
		<Skeleton height="18px" width="50%" />
	</SkeletonHeaderWrapper>
);

const SkeletonGridRow = () => (
	<>
		<SkeletonGridItem />
		<SkeletonGridItem />
	</>
);

export const FieldsSkeleton = () => (
	<>
		<SkeletonHeader />
		<SkeletonFieldsGrid>
			<SkeletonGridRow />
			<SkeletonGridRow />
			<SkeletonGridRow />
		</SkeletonFieldsGrid>
		<SkeletonHeader />
		<SkeletonFieldsGrid>
			<SkeletonGridRow />
			<SkeletonGridRow />
		</SkeletonFieldsGrid>
		<SkeletonHeader />
		<SkeletonFieldsGrid>
			<SkeletonGridRow />
			<SkeletonGridRow />
			<SkeletonGridRow />
			<SkeletonGridRow />
			<SkeletonGridRow />
		</SkeletonFieldsGrid>
	</>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SkeletonFieldsGrid = styled.div({
	marginLeft: token('space.100'),
	marginBottom: token('space.100'),
	display: 'grid',
	gridTemplateColumns: '[title] 100px [content] 0.7fr',
	gridAutoRows: 'auto',
	alignItems: 'center',
	gap: `${token('space.150')} ${token('space.100')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SkeletonHeaderWrapper = styled.div({
	maxWidth: '280px',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginTop: '18px',
	marginBottom: token('space.150'),
});
