import React, { useState } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import EditorSearchIcon from '@atlaskit/icon/core/migration/search--editor-search';
import Select, { components } from '@atlaskit/select';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import type {
	GroupType,
	OptionComponentProps,
	SelectProps,
} from '@atlassian/jira-polaris-common/src/common/types/select/index.tsx';
import { ESCAPE } from '@atlassian/jira-polaris-common/src/common/utils/key-code/index.tsx';
import messages from './messages.tsx';
import { SelectOptionComponent } from './select-option/index.tsx';

const OptionComponent = ({ data: { key }, ...rest }: OptionComponentProps) => (
	// @ts-expect-error - TS2740 - Type '{ children: Element; }' is missing the following properties from type 'CommonProps<OptionTypeBase, boolean, GroupTypeBase<OptionTypeBase>>': clearValue, cx, getStyles, getValue, and 8 more.
	<components.Option {...rest}>
		<SelectOptionComponent fieldKey={key} />
	</components.Option>
);

const GroupComponent = (props: GroupType) => {
	const [visibleFields, setVisibleFields] = useState(false);
	const onShow = () => setVisibleFields(!visibleFields);

	const { formatMessage } = useIntl();

	if (props.label === 'supported') {
		return (
			// @ts-expect-error - TS2740 - Type '{ label: string; options: OptionsType; data: { options: OptionsType; }; }' is missing the following properties from type 'CommonProps<OptionTypeBase, boolean, GroupTypeBase<OptionTypeBase>>': clearValue, cx, getStyles, getValue, and 7 more.
			<components.Group {...props} />
		);
	}

	const optionsLength = props.options.length;
	const isFoundOptions = props.data.options.length !== optionsLength;

	const btnMessage = visibleFields
		? formatMessage(messages.hideDisableFields)
		: formatMessage(messages.showDisableFields);

	const groupHeaderMessage = isFoundOptions
		? messages.foundDisableFields
		: messages.disableFieldsHeader;

	const componentsGroupItems = (
		// @ts-expect-error - TS2740 - Type '{ label: string; options: OptionsType; data: { options: OptionsType; }; }' is missing the following properties from type 'CommonProps<OptionTypeBase, boolean, GroupTypeBase<OptionTypeBase>>': clearValue, cx, getStyles, getValue, and 7 more.
		<components.Group {...props} />
	);

	return (
		<>
			<GroupTitle>{formatMessage(groupHeaderMessage, { count: optionsLength })}</GroupTitle>
			{visibleFields ? componentsGroupItems : null}
			<Button appearance="link" onClick={onShow}>
				{btnMessage}
			</Button>
		</>
	);
};

const DropdownIndicator = () => (
	<EditorSearchIcon spacing="spacious" color={token('color.text')} label="" />
);

export const MatrixSelectDropdown = ({ options, onSelect, onClose }: SelectProps) => {
	const { formatMessage } = useIntl();

	const noOptionsMessage = () => formatMessage(messages.noMatchesIndicator);

	return (
		<Select
			autoFocus
			backspaceRemovesValue={false}
			components={{
				DropdownIndicator,
				Option: OptionComponent,
				// @ts-expect-error - TS2322 - Type '(props: GroupType) => JSX.Element' is not assignable to type 'ComponentType<GroupProps<OptionType, false, GroupTypeBase<OptionType>>> | undefined'.
				Group: GroupComponent,
			}}
			controlShouldRenderValue={false}
			hideSelectedOptions={false}
			noOptionsMessage={noOptionsMessage}
			isClearable={false}
			escapeClearsValue
			menuIsOpen
			onKeyDown={(e) => {
				if (e.keyCode === ESCAPE) {
					onClose && onClose();
				}
			}}
			isOptionDisabled={(option) => !!option.disabled}
			// @ts-expect-error - TS2339 - Property 'key' does not exist on type 'OptionType | null'.
			onChange={({ key }) => onSelect(key)}
			options={options}
			placeholder={formatMessage(messages.searchHint)}
			styles={{
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				control: (styles: any) => ({
					...styles,
					width: 224,
					margin: token('space.100'),
					// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
					lineHeight: '20px',
					':hover': {
						cursor: 'text',
					},
				}),
				menu: () => ({
					width: 240,
					boxShadow: token('elevation.shadow.overflow'),
				}),
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				menuList: (styles: any) => ({
					...styles,
					height: 'calc(50vh - var(--topNavigationHeight) - 80px)',
				}),
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				group: (styles: any) => ({
					...styles,
					':not(:last-of-type)': {
						borderBottom: `1px solid ${token('color.border')}`,
					},
				}),
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				groupHeading: (styles: any) => ({
					...styles,
					display: 'none',
				}),
			}}
			tabSelectsValue={false}
		/>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const GroupTitle = styled.div({
	paddingTop: token('space.150'),
	paddingRight: token('space.150'),
	paddingBottom: 0,
	paddingLeft: token('space.150'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text'),
	font: token('font.body.UNSAFE_small'),
});
