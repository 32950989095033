// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import { getUsersHasContributorAccess } from '../../../services/jira/get-users-contributor-access/index.tsx';
import type { State, Props, Actor } from '../../types.tsx';

export const loadUsersHasAnyProductAccess =
	(
		users: string[],
		newlyAddedUsers?: Actor[],
		onShowFlag?: (users: Actor[]) => void,
	): Action<State, Props> =>
	({ setState }, { projectId, onError }) => {
		getUsersHasContributorAccess(projectId, users)
			.then((response) => {
				setState({
					hasContributorAccess: response.withContributorAccess,
					hasManuallyAddedContributorAccess: response.withManuallyAddedContributorAccess,
				});
				if (newlyAddedUsers) {
					const contributorAccessMap = response.withContributorAccess;
					const notProperlyConfiguredContributors = newlyAddedUsers.filter(
						(user) => contributorAccessMap[user.roleTypeId] === false,
					);
					if (notProperlyConfiguredContributors.length && onShowFlag) {
						onShowFlag(notProperlyConfiguredContributors);
					}
				}
			})
			.catch((err) => onError(err));
	};
