import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import UFOLoadHold from '@atlaskit/react-ufo/load-hold';
import { useIsSharedView } from '@atlassian/jira-polaris-common/src/controllers/environment/index.tsx';
import {
	useIssueViewLayout,
	useIssueViewSection,
} from '@atlassian/jira-polaris-common/src/controllers/route/index.tsx';
import {
	useIdeaDescriptionFieldKey,
	useIsCurrentViewSharingSettingsInitialLoading,
} from '@atlassian/jira-polaris-common/src/controllers/sharing/selectors/hooks.tsx';
import { DESCRIPTION_FIELDKEY } from '@atlassian/jira-polaris-domain-field/src/field/constants.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { AdfSkeleton } from '@atlassian/jira-polaris-lib-editor/src/common/ui/adf-skeleton/index.tsx';
import { DescriptionField } from './description-field/index.tsx';
import { StringField } from './string-field/index.tsx';

export const Description = ({ isSmartLink }: { isSmartLink?: boolean }) => {
	const isSharedView = useIsSharedView();
	const ideaDescriptionFieldKey = useIdeaDescriptionFieldKey();
	const isSharingSettingLoading = useIsCurrentViewSharingSettingsInitialLoading();
	const issueViewSection = useIssueViewSection();
	const issueViewLayout = useIssueViewLayout();

	if (isSharedView) {
		if (isSharingSettingLoading) {
			return (
				<UFOLoadHold name="jpd.idea-view-description-sharing-setting">
					<SkeletonContainer>
						<AdfSkeleton />
					</SkeletonContainer>
				</UFOLoadHold>
			);
		}
		if (ideaDescriptionFieldKey !== DESCRIPTION_FIELDKEY) {
			return (
				<Container>
					<StringField />
				</Container>
			);
		}
	}
	if (!isSmartLink) {
		experience.ideaView.pageSegmentLoad.success({
			metadata: {
				layout: issueViewLayout,
				section: issueViewSection,
			},
		});
		experience.ideaView.directPageSegmentLoad.success({
			metadata: {
				layout: issueViewLayout,
				section: issueViewSection,
			},
		});
	}

	return (
		<Container>
			<DescriptionField isSmartLink={isSmartLink} />
		</Container>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	paddingLeft: token('space.100'),
	paddingRight: token('space.100'),
	marginBottom: token('space.200'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SkeletonContainer = styled.div({
	paddingLeft: token('space.100'),
	paddingRight: token('space.100'),
	marginTop: token('space.100'),
	marginRight: 0,
	marginBottom: token('space.200'),
	marginLeft: 0,
});
