import React, { memo, useState, useMemo, useCallback } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import EditorRemoveIcon from '@atlaskit/icon/core/migration/delete--editor-remove';
import { FilmstripView, type ScrollEvent, type SizeEvent } from '@atlaskit/media-filmstrip';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import ModalDialog, {
	ModalBody,
	ModalTitle,
	ModalHeader,
	ModalFooter,
} from '@atlaskit/modal-dialog';
import Spinner from '@atlaskit/spinner';
import { token } from '@atlaskit/tokens';
import UFOLoadHold from '@atlaskit/react-ufo/load-hold';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { isAttachmentNumberExceedLimit } from '@atlassian/jira-issue-attachments-limit/src/common/utils.tsx';
import { JiraAttachment } from '@atlassian/jira-polaris-common/src/common/ui/attachment/index.tsx';
import { ModalTransitionUniversal } from '@atlassian/jira-polaris-common/src/common/ui/modal-transition/index.tsx';
import { useIsSharedView } from '@atlassian/jira-polaris-common/src/controllers/environment/index.tsx';
import { useIdeaActions } from '@atlassian/jira-polaris-common/src/controllers/idea/main.tsx';
import { useCommentMediaIds } from '@atlassian/jira-polaris-common/src/controllers/idea/selectors/comments/hooks.tsx';
import {
	useAttachments,
	useAttachmentsInUpload,
	useIsLoading,
} from '@atlassian/jira-polaris-common/src/controllers/idea/selectors/hooks.tsx';
import type { PolarisJiraIssueAttachment } from '@atlassian/jira-polaris-common/src/controllers/idea/types.tsx';
import {
	useDescription,
	useSelectedIssue,
	useSelectedIssueId,
	useIsSelectedIssueArchived,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { useGetPlaysMediaIds } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/plays/hooks.tsx';
import {
	useCurrentViewSharingSettings,
	useIsCurrentViewSharingSettingsInitialLoading,
} from '@atlassian/jira-polaris-common/src/controllers/sharing/selectors/hooks.tsx';
import {
	useCanDeleteAllAttachments,
	useCanDeleteOwnAttachments,
} from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { getMediaIds } from '@atlassian/jira-polaris-lib-adf-utils/src/utils/index.tsx';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import {
	useInsightsAttachments,
	useInsightsMediaIds,
} from '../../../controllers/insights/selectors/insights-hooks.tsx';
import { useIsInsightsLoading } from '../../../controllers/insights/selectors/meta-hooks.tsx';
import { ExceedWarning } from './exceed-warning/index.tsx';
import messages from './messages.tsx';

export const useAssociatedMediaIds = () => {
	const selectedIssue = useSelectedIssue();
	const description = useDescription(selectedIssue);
	const commentMediaIds = useCommentMediaIds();
	const insightsMediaIds = useInsightsMediaIds();
	const playsMediaIds = useGetPlaysMediaIds();

	const descriptionMedia = useMemo(() => getMediaIds(description), [description]);

	return useMemo(
		() => [...descriptionMedia, ...commentMediaIds, ...insightsMediaIds, ...playsMediaIds],
		[commentMediaIds, descriptionMedia, insightsMediaIds, playsMediaIds],
	);
};

export const useAssociatedAttachmentIds = () => {
	const insightsAttachments = useInsightsAttachments();

	return useMemo<string[]>(
		() => insightsAttachments.map(({ attachmentId }) => attachmentId),
		[insightsAttachments],
	);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Attachments = memo<Record<any, any>>(() => {
	const { formatMessage } = useIntl();
	const loading = useIsLoading();
	const isInsightsLoading = useIsInsightsLoading();
	const issueId = useSelectedIssueId();
	const associatedMedia = useAssociatedMediaIds();
	const associatedAttachments = useAssociatedAttachmentIds();
	const issueAttachments = useAttachments();
	const attachmentsInUpload = useAttachmentsInUpload();
	const [offset, setOffset] = useState(0);
	const [animate, setAnimate] = useState(false);
	const [inDelete, setInDelete] = useState<number | undefined>(undefined);
	const { deleteAttachment } = useIdeaActions();
	const [canDeleteAllAttachments] = useCanDeleteAllAttachments();
	const [canDeleteOwnAttachments] = useCanDeleteOwnAttachments();
	const accountId = useAccountId();
	const isSharedView = useIsSharedView();
	const sharingSettings = useCurrentViewSharingSettings();
	const isSharingSettingsLoading = useIsCurrentViewSharingSettingsInitialLoading();
	const isIdeaArchived = useIsSelectedIssueArchived();

	const attachments = useMemo(
		() =>
			issueAttachments.filter(
				({ file }) =>
					!associatedMedia.includes(file.attachmentMediaApiId) &&
					!associatedAttachments.includes(String(file.attachmentId)),
			),
		[associatedAttachments, associatedMedia, issueAttachments],
	);

	const handleScrollChange = useCallback((args: ScrollEvent) => {
		setOffset(args.offset);
		setAnimate(args.animate);
	}, []);

	const handleSizeChange = useCallback((args: SizeEvent) => {
		setOffset(args.offset);
	}, []);

	const onDelete = useCallback((attachmentId: number) => {
		setInDelete(attachmentId);
	}, []);

	const onDeleteConfirm = useCallback(() => {
		if (inDelete !== undefined) {
			deleteAttachment(inDelete);
		}
		setInDelete(undefined);
	}, [deleteAttachment, inDelete]);

	const isIdeaLoading = loading || issueId === undefined;
	const hasNoAttachments = attachments.length === 0;
	const shouldHideAttachmentsInSharedView =
		isSharedView && (isSharingSettingsLoading || !sharingSettings?.showIdeaAttachments);

	if (isIdeaLoading) {
		return <UFOLoadHold name="jpd.idea-view-attachments" />;
	}

	if (isInsightsLoading) {
		return <UFOLoadHold name="jpd.idea-view-attachments-for-insights" />;
	}

	if (
		(hasNoAttachments && !isAttachmentNumberExceedLimit(associatedMedia.length)) ||
		shouldHideAttachmentsInSharedView
	) {
		return null;
	}

	const getAttachmentActions = (attachement: PolarisJiraIssueAttachment['file']) => {
		if (
			!isIdeaArchived &&
			(canDeleteAllAttachments ||
				(canDeleteOwnAttachments && attachement.authorAccountId === accountId))
		) {
			return [
				{
					label: 'foo',
					icon: <EditorRemoveIcon spacing="spacious" label="delete attachment" />,
					handler: () => onDelete(attachement.attachmentId),
				},
			];
		}

		return [];
	};

	return (
		<AttachmentsContainer data-testid="polaris-ideas.ui.idea-view.attachments.attachments-container">
			<Header>{formatMessage(messages.sectionHeader, { count: attachments.length })}</Header>

			<ExceedWarning totalCount={associatedMedia.length} />

			<AllItemsContainer>
				<InUploadContainer>
					{attachmentsInUpload.map((id) => (
						<InUploadPlaceholder key={id}>
							<Spinner interactionName="uploading-attachment" />
						</InUploadPlaceholder>
					))}
				</InUploadContainer>
				<FilmstripContainer>
					<FilmstripView
						onScroll={handleScrollChange}
						onSize={handleSizeChange}
						offset={offset}
						animate={animate}
					>
						{attachments.map(({ file, mediaClientConfig }) => (
							<JiraAttachment
								key={file.attachmentId}
								attachment={{
									attachmentId: file.attachmentId,
									attachmentMediaApiId: file.attachmentMediaApiId,
									issueId,
								}}
								mediaClientConfig={mediaClientConfig}
								actions={getAttachmentActions(file)}
							/>
						))}
					</FilmstripView>
				</FilmstripContainer>
			</AllItemsContainer>
			<ModalTransitionUniversal>
				{inDelete !== undefined && (
					<ShortcutScope>
						<ModalDialog>
							<ModalHeader>
								<ModalTitle appearance="danger">
									{formatMessage(messages.deleteConfirmDialogHeader)}
								</ModalTitle>
							</ModalHeader>
							<ModalBody>{formatMessage(messages.deleteConfirmDialogContent)}</ModalBody>
							<ModalFooter>
								<Button appearance="danger" onClick={onDeleteConfirm}>
									{formatMessage(messages.deleteConfirmDialogConfirm)}
								</Button>
								<Button onClick={() => setInDelete(undefined)}>
									{formatMessage(messages.deleteConfirmDialogCancel)}
								</Button>
							</ModalFooter>
						</ModalDialog>
					</ShortcutScope>
				)}
			</ModalTransitionUniversal>
		</AttachmentsContainer>
	);
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AttachmentsContainer = styled.div({
	width: 'auto',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Header = styled.div({
	color: token('color.text'),
	font: token('font.heading.xsmall'),
	marginBottom: token('space.075'),
	paddingTop: 0,
	paddingRight: token('space.100'),
	paddingBottom: 0,
	paddingLeft: token('space.100'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AllItemsContainer = styled.div({
	display: 'flex',
	flexDirection: 'row',
	maxWidth: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InUploadContainer = styled.div({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginTop: '5px',
	marginLeft: token('space.050'),
	flex: '0 0 auto',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FilmstripContainer = styled.div({
	flex: '1 1 auto',
	minWidth: '0px',
	maxWidth: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InUploadPlaceholder = styled.div({
	display: 'inline-flex',
	width: '156px',
	height: '125px',
	borderRadius: '3px',
	marginRight: token('space.200'),
	justifyContent: 'center',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.background.selected'),
	boxShadow: token('elevation.shadow.raised'),
});
