import {
	isContributorGroupEnabled,
	getContributorGroupId,
	isIdentityBasedContributorHandlingEnabled,
} from './contributor-group.tsx';
import { createSelectorHook } from './utils.tsx';

export const useIsContributorGroupEnabled = createSelectorHook(isContributorGroupEnabled);
export const useContributorGroupId = createSelectorHook(getContributorGroupId);
export const useIsIdentityBasedContributorHandlingEnabled = createSelectorHook(
	isIdentityBasedContributorHandlingEnabled,
);
