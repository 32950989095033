import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	fieldNotSupportedInThisContextTooltipNonFinal: {
		id: 'polaris-ideas.view-content.idea-list.cell.cells.number-field.field-not-supported-in-this-context-tooltip-non-final',
		defaultMessage: 'Field is not supported in this context',
		description:
			'Shown for connection highlighted linked issues field for issues from different container (project)',
	},
});
