import { useCallback } from 'react';
import { useNotifications } from '@atlassian/jira-polaris-lib-notifications/src/controllers/index.tsx';
import messages from './messages.tsx';

export const useSwitchedToProjectRankFlag = () => {
	const { showFlag } = useNotifications();

	return useCallback(
		() =>
			showFlag({
				messageId:
					'polaris-component-view-sort-configuration.ui.advanced.view-rank.show-flag.success',
				messageType: 'transactional',
				id: 'polaris.sort-mode-switched-project-rank.success',
				type: 'success',
				title: messages.switchedSortModeHeader,
				description: messages.switchedSortModeToProjectRank,
			}),
		[showFlag],
	);
};
