import mapValues from 'lodash/mapValues';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { performPostRequest } from '@atlassian/jira-fetch/src/index.tsx';
import type { IssueId, ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import { normalizeAggregatedDeliveryProgressResponse } from '../../common/normalize-aggregated-delivery-progress-response/index.tsx';
import type {
	AggregatedDeliveryProgressResponse,
	FetchAggregatedDeliveryProgressRequest,
} from '../types.tsx';

export const getAggregatedDeliveryProgress = async (
	request: FetchAggregatedDeliveryProgressRequest,
) => {
	const operation = request.issueIds
		? 'getAggregatedDeliveryProgressForIssues'
		: 'getAggregatedDeliveryProgressForProject';
	return fetchJson(
		`/rest/polaris/delivery-data/data${fg('jpd-trace-fetch-network-call') ? `?operation=${operation}` : ''}`,
		{
			method: 'POST',
			body: JSON.stringify({
				projectId: request.projectId,
				issueIds: request.issueIds,
				type: request.calculationStrategy,
				calculationMode: request.calculationMode,
				dateFieldConfigurations: request.dateFieldConfigurations,
				archived: request.archivedFilter,
			}),
		},
	).then(normalizeAggregatedDeliveryProgressResponse);
};

type AggregatedDeliveryProgressFromJqlResponse = {
	issues: Record<IssueId, { ideaToProgressAggregation: number[]; projectId: ProjectId }>;
	projects: Record<
		ProjectId,
		{
			calculationMode: 'ISSUE_COUNT' | 'story_points';
			type: 'DEFAULT' | 'SIMPLE';
		}
	>;
	timestamp: string;
};

export const getAggregatedDeliveryProgressFromJql = (
	jql: string,
): Promise<AggregatedDeliveryProgressResponse> =>
	performPostRequest<AggregatedDeliveryProgressFromJqlResponse>(
		'/rest/polaris/delivery-data/searchJql',
		{
			body: JSON.stringify({ jql }),
		},
	).then((response) => ({
		ideaToProgressAggregation: mapValues(
			response.issues,
			({ ideaToProgressAggregation }) => ideaToProgressAggregation,
		),
		aggregationTimestamp: response.timestamp,
		ideaDateAggregation: {},
		aggregationLimit: undefined,
	}));
