import React, { useCallback, useMemo } from 'react';
import { Box, Text } from '@atlaskit/primitives/compiled';
import { cssMap } from '@atlaskit/css';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useProjectForIssue } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/project/hooks.tsx';
import { useCanEditIssues } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { useNotifications } from '@atlassian/jira-polaris-lib-notifications/src/index.tsx';
import { Draggable as DraggableComponent } from '@atlassian/jira-polaris-lib-timeline/src/common/ui/draggable/index.tsx';
import type {
	DraggableProps,
	ResizeHandleProps,
} from '@atlassian/jira-polaris-lib-timeline/src/types.tsx';
import { ResizeHandle } from '@atlassian/jira-polaris-lib-timeline/src/common/ui/draggable/resize-handle/index.tsx';
import { useCurrentViewVerticalGroupBy } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { useIsFieldAssociatedToIssue } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { messages } from './messages.tsx';

const styles = cssMap({
	strong: {
		fontWeight: token('font.weight.bold'),
		display: 'inline',
	},
});

export const Draggable = (props: DraggableProps) => {
	const { formatMessage } = useIntl();
	const project = useProjectForIssue(props.id);
	const [hasProjectPermission] = useCanEditIssues({ projectId: project?.id });
	const { error } = useNotifications();
	const isDragForbidden = !hasProjectPermission;
	const isResizeForbidden = !hasProjectPermission;
	const verticalGroupBy = useCurrentViewVerticalGroupBy();

	const verticalGroupByFieldIsAssociatedToIssue =
		useIsFieldAssociatedToIssue(verticalGroupBy?.key ?? '', props.id) || !verticalGroupBy;

	const permissionErrorMessage = useMemo(
		() =>
			formatMessage(messages.noProjectPermissionsErrorDescription, {
				projectName: project?.name,
				b: (chunks: React.ReactNode[]) => <Box xcss={styles.strong}>{chunks}</Box>,
			}),
		[formatMessage, project?.name],
	);

	const onDrop = useCallback(() => {
		if (isDragForbidden) {
			error({
				title: formatMessage(messages.noProjectPermissionsErrorTitle),
				description: permissionErrorMessage,
			});
		} else if (!verticalGroupByFieldIsAssociatedToIssue) {
			error({
				title: formatMessage(messages.groupByFieldsNotAddedToProjectErrorTitle),
				description: formatMessage(messages.groupByFieldsNotAddedToProjectErrorDescription, {
					projectName: project?.name,
					b: (chunks: React.ReactNode[]) => <Text weight="bold">{chunks}</Text>,
				}),
			});
		}
	}, [
		isDragForbidden,
		verticalGroupByFieldIsAssociatedToIssue,
		error,
		formatMessage,
		permissionErrorMessage,
		project?.name,
	]);

	const components: DraggableProps['components'] = useMemo(
		() => ({
			...props.components,
			ResizeHandle: (p: ResizeHandleProps) => (
				<ResizeHandle
					{...p}
					isDisabled={isResizeForbidden || p.isDisabled}
					label={isResizeForbidden ? permissionErrorMessage : p.label}
				/>
			),
		}),
		[permissionErrorMessage, isResizeForbidden, props.components],
	);

	return (
		<DraggableComponent
			{...props}
			canDrop={hasProjectPermission && verticalGroupByFieldIsAssociatedToIssue}
			onDrop={onDrop}
			components={components}
		/>
	);
};
