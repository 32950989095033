import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	label: {
		id: 'polaris.role-picker.label',
		defaultMessage: 'Role',
		description: 'The label for a dropdown component which allows the user to select the role.',
	},
	placeholder: {
		id: 'polaris.role-picker.placeholder',
		defaultMessage: 'Choose role',
		description:
			'The placeholder for a dropdown component which allows the user to select the role',
	},
	fetchingError: {
		id: 'polaris.role-picker.fetchingError',
		defaultMessage: 'Error fetching roles',
		description:
			'The error message for a dropdown component which allows the user to select the role',
	},
	fetching: {
		id: 'polaris.role-picker.fetching',
		defaultMessage: 'Fetching roles...',
		description:
			'The fetching message for a dropdown component which allows the user to select the role',
	},
	noOptions: {
		id: 'polaris.role-picker.noOptions',
		defaultMessage: 'No roles found',
		description:
			'The no options message for a dropdown component which allows the user to select the role',
	},
	permissionsError: {
		id: 'polaris.role-picker.permissions-error',
		defaultMessage:
			"Project roles aren't editable on the Free plan. Upgrade for advanced team configuration.",
		description: 'Shown in a tooltip when the user tries to edit a locked-down permission setting.',
	},
});
