// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import { asyncValues } from '@atlassian/jira-polaris-lib-ts-utils/src/async-value.tsx';
import { createErrorAnalytics } from '@atlassian/jira-polaris-lib-errors/src/controllers/index.tsx';
import { logSafeErrorWithoutCustomerDataWrapper } from '@atlassian/jira-polaris-lib-errors/src/common/utils/index.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { updateProductSettingsFinancialYear } from '../../../services/update-product-settings.tsx';
import type { Props, State } from '../../../common/types.tsx';

export const updateFinancialYearInitialMonth =
	(initialMonth: number): Action<State, Props, Promise<void>> =>
	async ({ setState, getState }) => {
		const state = getState();

		if (state.productSettings.status !== 'loaded') {
			return;
		}

		const previousInitialMonth = state.productSettings.value.financialYear.initialMonth;

		try {
			await updateProductSettingsFinancialYear(initialMonth);

			setState({
				productSettings: asyncValues.loaded({
					...state.productSettings.value,
					financialYear: {
						initialMonth,
					},
				}),
			});
		} catch (error) {
			if (error instanceof Error) {
				fireErrorAnalytics(createErrorAnalytics('polaris.error.fetchProductSettingsFailed', error));
			}

			logSafeErrorWithoutCustomerDataWrapper(
				'polaris.error.controllers.product-settings.update-financial-year',
				'Failed to update financial year',
				error instanceof Error ? error : new Error(String(error)),
			);

			setState({
				productSettings: asyncValues.loaded({
					...state.productSettings.value,
					financialYear: {
						initialMonth: previousInitialMonth,
					},
				}),
			});

			throw error;
		}
	};
