import { useCallback, useMemo } from 'react';
import flatten from 'lodash/flatten';
import keyBy from 'lodash/keyBy';
import mapKeys from 'lodash/mapKeys';
import mapValues from 'lodash/mapValues';
import { addMonths, addQuarters, endOfQuarter, format, isBefore, startOfQuarter } from 'date-fns';
import type { FieldValuesUpdateRequest } from '@atlassian/jira-polaris-common/src/controllers/issue/actions/update-field-value/types.tsx';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import {
	useJiraIdToLocalIssueId,
	useLocalIssueIdToJiraIssueId,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/issue-ids-hooks.tsx';
import { useIsSorted } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/sort-hooks.tsx';
import {
	useTimelineDateFieldsKeys,
	useTimelineDuration,
	useTimelineItemIds,
	useTimelineItems,
} from '@atlassian/jira-polaris-common/src/controllers/issue/utils/view-filtering/view-timeline/index.tsx';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import {
	useCurrentViewArrangementInformation,
	useCurrentViewTimelineMode,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import {
	PolarisTimelineMode,
	type GroupValueIdToIdArrangement,
} from '@atlassian/jira-polaris-domain-view/src/timeline/types.tsx';
import type { Header as TimelineHeader } from '@atlassian/jira-polaris-lib-timeline/src/common/types/timeline/index.tsx';
import type {
	GroupedItemArrangement,
	GroupId,
	ItemRows,
	ChangedItem,
} from '@atlassian/jira-polaris-lib-timeline/src/types.tsx';
import type { ExtendedOption } from '../../../../common/utils/board.tsx';
import { useGroupDropHandler, useRowGrouping } from '../../common/utils/group-options.tsx';

export const getHeaderLabel = (date: Date, timelineMode: PolarisTimelineMode) => {
	if (timelineMode === 'MONTHS') {
		return format(date, 'MMMM yyyy');
	}
	return `${format(startOfQuarter(date), 'MMMM')}-${format(endOfQuarter(date), 'MMMM yyyy')}`;
};

export const useHeaders = () => {
	const { startDate: initialStartDate, endDate } = useTimelineDuration();
	const mode = useCurrentViewTimelineMode();

	return useMemo(() => {
		if (!initialStartDate || !endDate || !mode) {
			return [];
		}

		let startDate = initialStartDate;
		const result: TimelineHeader[] = [];

		while (isBefore(startDate, endDate)) {
			const header = getHeaderLabel(startDate, mode);
			const subheaders = [];
			if (mode === PolarisTimelineMode.QUARTERS) {
				let startDateOfQuarter = startOfQuarter(startDate);
				while (isBefore(startDateOfQuarter, endOfQuarter(startDate))) {
					subheaders.push(format(startDateOfQuarter, 'MMMM'));
					startDateOfQuarter = addMonths(startDateOfQuarter, 1);
				}
			}
			result.push({ header, subheaders });
			startDate =
				mode === PolarisTimelineMode.MONTHS ? addMonths(startDate, 1) : addQuarters(startDate, 1);
		}

		return result;
	}, [endDate, initialStartDate, mode]);
};

export const getNewGroupValue = (
	groupIds: GroupId[],
	extendedOptions: ExtendedOption<unknown>[],
): unknown[] | unknown | undefined => {
	const optionsByGroupId = keyBy(extendedOptions, ({ groupIdentity }) => String(groupIdentity));
	const values = groupIds.map((groupId) => optionsByGroupId[groupId].value);
	if (values.length === 0) {
		return undefined;
	}
	return Array.isArray(values[0]) ? flatten(values) : values[0];
};

export const useUpdateItem = () => {
	const allItems = useTimelineItems();
	const [verticalGroupByField, extendedOptions] = useRowGrouping();
	const { updateFieldValues } = useIssueActions();
	const [startDateFieldKey, endDateFieldKey] = useTimelineDateFieldsKeys();

	return useCallback(
		(item: ChangedItem) => {
			if (!startDateFieldKey || !endDateFieldKey) {
				return;
			}

			const currentItem = allItems.find(({ id }) => id === item.id);
			const { startDateInterval, endDateInterval, oldGroupId, newGroupIds } = item;
			const isCurrentItemFromBucket = oldGroupId === undefined;
			const isStartDateChanged =
				startDateInterval.value.start !== currentItem?.startDateInterval.value.start;
			const isEndDateChanged =
				endDateInterval.value.start !== currentItem?.endDateInterval.value.start;

			const fields: FieldValuesUpdateRequest['fields'] = {
				...(isStartDateChanged && {
					[startDateFieldKey]: {
						newValue: JSON.stringify({
							start: startDateInterval.value.start,
							end: startDateInterval.value.end,
						}),
					},
				}),
				...(isEndDateChanged && {
					[endDateFieldKey]: {
						newValue: JSON.stringify({
							start: endDateInterval.value.start,
							end: endDateInterval.value.end,
						}),
					},
				}),
			};

			if (verticalGroupByField?.editable && newGroupIds) {
				const newValue = getNewGroupValue(newGroupIds, extendedOptions || []);
				const { key } = verticalGroupByField;

				fields[key] = {
					newValue,
					appendMultiValues: isCurrentItemFromBucket,
				};
			}

			updateFieldValues({
				localIssueIds: [item.id],
				fields,
			});
		},
		[
			startDateFieldKey,
			endDateFieldKey,
			allItems,
			verticalGroupByField,
			updateFieldValues,
			extendedOptions,
		],
	);
};

export const useLocalArrangementInformation = ():
	| Record<string, GroupedItemArrangement>
	| undefined => {
	const isSorted = useIsSorted();
	const remoteArrangement = useCurrentViewArrangementInformation();
	const jiraIdToLocalIssueId = useJiraIdToLocalIssueId();

	if (isSorted || remoteArrangement === undefined) {
		return undefined;
	}
	return mapValues(remoteArrangement, (itemArrangement: GroupedItemArrangement) =>
		mapValues(itemArrangement, (issuesJiraId) =>
			mapKeys(issuesJiraId, (_, jiraId) => jiraIdToLocalIssueId[Number.parseInt(jiraId, 10)]),
		),
	);
};

export const useOnArrangementUpdate = () => {
	const localIssueIdToJiraId = useLocalIssueIdToJiraIssueId();
	const { updateArrangementInformation } = useViewActions();
	return useCallback(
		(newLocalGroupArrangement: GroupedItemArrangement) => {
			const newRemoteGroupArrangement: GroupValueIdToIdArrangement = mapValues(
				newLocalGroupArrangement,
				(itemRows: ItemRows) => mapKeys(itemRows, (_, itemId) => localIssueIdToJiraId[itemId]),
			);
			updateArrangementInformation(newRemoteGroupArrangement);
		},
		[localIssueIdToJiraId, updateArrangementInformation],
	);
};

export const useOnGroupOrderChanged = () => {
	const groupDropHandler = useGroupDropHandler();
	const onGroupOrderChanged = useCallback(
		({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
			groupDropHandler({ oldIndex, newIndex });
		},
		[groupDropHandler],
	);
	return onGroupOrderChanged;
};

export const useTimelineIdeaCount = () => {
	const itemIds = useTimelineItemIds();

	return itemIds.length;
};
