import { createSelector } from 'reselect';
import difference from 'lodash/difference';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import {
	ADMIN_ROLE_NAME,
	ADMINISTER_PROJECTS_PERMISSION,
	MEMBER_ROLE_NAME,
	VIEWER_ROLE_NAME,
} from '../../common/constants.tsx';
import {
	type RoleId,
	type State,
	type RoleConfigurationId,
	type PermissionKey,
	RoleOperations,
} from '../types.tsx';
import { getContributorPermissions } from './perms.tsx';

const NO_PERMISSIONS: PermissionKey[] = [];

export const getRoles = (state: State) => state.roles;

export const getRoleConfigurationIds = createSelector(getRoles, (roles) =>
	(roles || []).map(({ roleConfigurationId }) => roleConfigurationId),
);

export const getRoleNames = createSelector(getRoles, (roles) =>
	(roles || []).map(({ name }) => name),
);

export const getRole = (id: RoleConfigurationId | RoleId | undefined) =>
	createSelector(getRoles, (roles) =>
		(roles || []).find((role) => (isNumber(id) ? role.id === id : role.roleConfigurationId === id)),
	);

export const getRoleName = (roleId: RoleConfigurationId | RoleId | undefined) =>
	createSelector(getRole(roleId), (role) => role?.name);

export const getRoleTranslatedName = (roleId: RoleConfigurationId | RoleId | undefined) =>
	createSelector(getRole(roleId), (role) => role?.translatedName);

export const getRoleDescription = (roleId: RoleConfigurationId | RoleId | undefined) =>
	createSelector(getRole(roleId), (role) => role?.description);

export const getRoleDeletable = (roleId: RoleConfigurationId | RoleId | undefined) =>
	createSelector(getRole(roleId), (role) =>
		(role?.allowedOperations || []).includes(RoleOperations.DELETE),
	);

export const getRoleEditable = (roleId: RoleConfigurationId | RoleId | undefined) =>
	createSelector(getRole(roleId), (role) =>
		(role?.allowedOperations || []).includes(RoleOperations.EDIT),
	);

export const getPermissionsOnRole = (roleId: RoleConfigurationId | RoleId | undefined) =>
	createSelector(getRole(roleId), (role) => role?.permissions || NO_PERMISSIONS);

export const getIsCreatorRole = (roleId: RoleConfigurationId | RoleId | undefined) =>
	createSelector(getRole(roleId), getContributorPermissions, (role, contributorPerms) => {
		const nonContribPerms = difference(role?.permissions || [], contributorPerms);
		return nonContribPerms.length > 0;
	});

export const getCreatorRoles = createSelector(
	getRoles,
	getContributorPermissions,
	(roles, contributorPerms) =>
		roles?.filter((role) => {
			const nonContribPerms = difference(role?.permissions || [], contributorPerms);
			return nonContribPerms.length > 0;
		}),
);

export const getCreatorRolesIds = createSelector(getCreatorRoles, (roles) =>
	roles?.map((role) => role.id),
);

export const getGetRoleByName = (name: string) =>
	createSelector(getRoles, (roles) => find(roles, (role) => role.name === name));

const getPermissions = (state: State) => state.permissions;

export const getDefaultRoles = createSelector(getRoles, getPermissions, (roles, permissions) => {
	if (isEmpty(roles) || isEmpty(permissions)) {
		return {
			administrator: undefined,
			creator: undefined,
			contributor: undefined,
		};
	}

	const adminRole = roles.find((role) => role.name === ADMIN_ROLE_NAME);
	const memberRole = roles.find((role) => role.name === MEMBER_ROLE_NAME);
	const viewerRole = roles.find((role) => role.name === VIEWER_ROLE_NAME);

	return {
		administrator: adminRole,
		creator: memberRole,
		contributor: viewerRole,
	};
});

export const getAdminRolesIds = createSelector(getRoles, (roles) => {
	const ids: number[] = [];

	roles?.forEach((role) => {
		if (role.permissions.includes(ADMINISTER_PROJECTS_PERMISSION)) {
			ids.push(role.id);
		}
	});

	return ids;
});
