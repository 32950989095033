import React, { useCallback } from 'react';
import { styled } from '@compiled/react';
import { HelperMessage } from '@atlaskit/form';
import LinkIcon from '@atlaskit/icon/glyph/link';
import LinkIconNew from '@atlaskit/icon/core/link';
import { token } from '@atlaskit/tokens';
import { Box, Inline } from '@atlaskit/primitives';
import type { ProjectType } from '@atlassian/jira-common-constants/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import type { SelectedProject } from '../../common/ui/project-picker/option/index.tsx';
import { useIssueLinksMap } from '../../controllers/idea/selectors/hooks.tsx';
import { IssueSelect } from '../common/issue-select/main.tsx';
import type { GroupedOption, IssueOption } from '../common/issue-select/types.tsx';
import { MinimalLozenge } from '../lozenge/index.tsx';
import { messages } from './messages.tsx';

type LinkIssueSelectProps = {
	isDisabled: boolean;
	projectId: string | undefined;
	excludedProjectTypes: ProjectType[];
	defaultOptions?: GroupedOption;
	hideArchived: boolean;
	onIssueSelected: (arg1: IssueOption | undefined) => void;
	isPolarisIssueLink: boolean;
	onProjectChanged?: (payload: SelectedProject | undefined) => void;
	showHelperMessage?: boolean;
};

export const LinkIssueSelect = ({
	isPolarisIssueLink,
	showHelperMessage = true,
	...props
}: LinkIssueSelectProps) => {
	const { formatMessage } = useIntl();
	const issueLinksMap = useIssueLinksMap();

	const renderOptionIconAfterOld = useCallback(
		({ item }: IssueOption) => {
			const id = String(item?.id);
			const linkedIssue = issueLinksMap.get(id);

			if (!isPolarisIssueLink || !linkedIssue) return null;

			return <LinkIconContainerOld />;
		},
		[isPolarisIssueLink, issueLinksMap],
	);

	const renderOptionIconAfterNew = useCallback(
		({ item }: IssueOption) => {
			const id = String(item?.id);
			const linkedIssue = issueLinksMap.get(id);

			if (!isPolarisIssueLink || !linkedIssue) return null;

			return <LinkIconContainerNew />;
		},
		[isPolarisIssueLink, issueLinksMap],
	);

	// Will render the icon in the dropdown list for already linked issues
	const renderOptionIconAfter =
		isVisualRefreshEnabled() && fg('jpd-visual-refresh_icons_polaris')
			? renderOptionIconAfterOld
			: renderOptionIconAfterNew;

	return (
		<>
			<IssueSelect
				isAttachedToBody
				renderOptionIconAfter={renderOptionIconAfter}
				autoFocus
				{...props}
			/>
			{showHelperMessage ? (
				<HelperMessage testId="polaris-common.ui.link-issue-select.helper-message">
					{fg('jpd_fix_issue_select_ui_bugs') ? (
						<Box>
							{formatMessage(
								fg('polaris-issue-terminology-refresh')
									? messages.linkIssueSelectHelperTextIssueTermRefresh
									: messages.linkIssueSelectHelperText,
								{
									status: (
										<MinimalLozenge appearance="success">
											{formatMessage(messages.doneStatusLabel)}
										</MinimalLozenge>
									),
								},
							)}
						</Box>
					) : (
						<Inline>
							{formatMessage(
								fg('polaris-issue-terminology-refresh')
									? messages.linkIssueSelectHelperTextIssueTermRefresh
									: messages.linkIssueSelectHelperText,
								{
									status: (
										<MinimalLozenge appearance="success">
											{formatMessage(messages.doneStatusLabel)}
										</MinimalLozenge>
									),
								},
							)}
						</Inline>
					)}
				</HelperMessage>
			) : null}
		</>
	);
};

// For use in examples.tsx
export const LinkIconContainerOld = () => (
	<LinkIconWrapper>
		{/* eslint-disable-next-line @atlaskit/design-system/no-legacy-icons */}
		<LinkIcon size="small" primaryColor={token('color.border.accent.blue')} label="link" />
	</LinkIconWrapper>
);

export const LinkIconContainerNew = () => (
	<LinkIconWrapper>
		<LinkIconNew color={token('color.icon.accent.blue')} LEGACY_size="small" label="link" />
	</LinkIconWrapper>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LinkIconWrapper = styled.div({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	width: 21,
	height: 13,
	backgroundColor: token('color.background.accent.blue.subtlest'),
	borderRadius: 8,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& svg': {
		transform: 'rotate(42deg)',
	},
});
