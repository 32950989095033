import React, { type PropsWithChildren } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { ProfileClient } from '@atlaskit/profilecard';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { useOrgId } from '@atlassian/jira-tenant-context-controller/src/components/org-id/index.tsx';
import { useFabricProfileDirectoryUrl } from '@atlassian/jira-tenant-context-controller/src/components/fabric-profile-directory-url/index.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { ProfileClientContainer } from '../../controllers/profileclient/index.tsx';

export type ProfileCardWrapperProps = {
	externalProfileClient?: ProfileClient;
	onLoadError?: (error: Error, analyticsEvent?: UIAnalyticsEvent) => void;
};

const ProfileCardWrapperWithoutOrgId = ({
	externalProfileClient,
	children,
	onLoadError,
}: PropsWithChildren<ProfileCardWrapperProps>) => {
	const cloudId = useCloudId();
	const accountId = useAccountId();
	const fabricPfDirUrl = useFabricProfileDirectoryUrl();

	if (accountId) {
		return (
			<ProfileClientContainer
				fabricPfDirUrl={fabricPfDirUrl}
				profileClient={externalProfileClient || null}
				onLoadError={onLoadError}
				cloudId={cloudId}
				isGlobal
			>
				{children}
			</ProfileClientContainer>
		);
	}

	// When there is no tenantContext, this will cause the child
	// component of <ProfileCard> to render without being wrapped by
	// a profile card and it will do nothing on hover
	return children;
};

const ProfileCardWrapperWithOrgId = ({
	externalProfileClient,
	children,
	onLoadError,
}: PropsWithChildren<ProfileCardWrapperProps>) => {
	const cloudId = useCloudId();
	const accountId = useAccountId();
	const fabricPfDirUrl = useFabricProfileDirectoryUrl();
	const orgId = useOrgId();

	if (accountId) {
		return (
			<ProfileClientContainer
				fabricPfDirUrl={fabricPfDirUrl}
				profileClient={externalProfileClient || null}
				onLoadError={onLoadError}
				cloudId={cloudId}
				isGlobal
				orgId={orgId}
			>
				{children}
			</ProfileClientContainer>
		);
	}

	// When there is no tenantContext, this will cause the child
	// component of <ProfileCard> to render without being wrapped by
	// a profile card and it will do nothing on hover
	return children;
};

export const ProfileCardWrapper = componentWithFG(
	'issue_view_prevent_unnecessary_orgid_query',
	ProfileCardWrapperWithOrgId,
	ProfileCardWrapperWithoutOrgId,
);
