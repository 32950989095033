import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	contributorAccessTitle: {
		id: 'polaris-lib-project-access.add-people.contributor-access-notification.contributor-access-title',
		defaultMessage: 'Contributor access required',
		description:
			'Flag title shown to the customer, when new people has not properly been added to the project as contributors',
	},
	oneUserAddedErrorMsg: {
		id: 'polaris-lib-project-access.add-people.contributor-access-notification.one-user-added-error-msg',
		defaultMessage:
			'To access this project, the site admin should add {name} to the contributors group.',
		description:
			'Flag description shown to the customer when users are not properly added as contributor',
	},
	twoPeopleAddedErrorMsg: {
		id: 'polaris-lib-project-access.add-people.contributor-access-notification.two-people-added-error-msg',
		defaultMessage:
			'To access this project, the site admin should add {name} and {secondName} to the contributors group.',
		description:
			'Flag description shown to the customer when two added users are not properly added as contributors.' +
			'{name} and {secondName} is replaced with actual user name',
	},
	multiplePeopleAddedErrorMsg: {
		id: 'polaris-lib-project-access.add-people.contributor-access-notification.multiple-people-added-error-msg',
		defaultMessage:
			'To access this project, the site admin should add {name} and {count, plural, one {# other} other {# others}} to the contributors group.',
		description:
			'Flag description shown to the customer when many users are are not properly added as contributors.' +
			'{name} is replaced with actual user name',
	},
	manageGroupsAction: {
		id: 'polaris-lib-project-access.add-people.contributor-access-notification.manage-groups-action',
		defaultMessage: 'Manage groups',
		description: 'Flag action shown to the customer to go to manage groups page',
	},
	learnMore: {
		id: 'polaris-lib-project-access.add-people.contributor-access-notification.learn-more',
		defaultMessage: 'Learn more',
		description: 'Flag action shown to the customer to open support page link',
	},
});
