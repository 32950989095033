import React, { useCallback, useMemo, useRef, useState } from 'react';
import { styled } from '@compiled/react';
import defer from 'lodash/defer';
import ErrorIcon from '@atlaskit/icon/core/migration/error';
import { Box, Text, Flex } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { ValueRuleOperator } from '@atlassian/jira-polaris-domain-field/src/decoration/constants.tsx';
import type { FieldOption } from '@atlassian/jira-polaris-domain-field/src/field-option/types.tsx';
import {
	WEIGHT_TYPE_NUMBER,
	WEIGHT_TYPE_RATING,
	type WeightType,
} from '@atlassian/jira-polaris-domain-field/src/field-types/option/types.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { CommonDecoratedTag } from '@atlassian/jira-polaris-lib-decoration/src/ui/decoration/common/index.tsx';
import { useEmoji } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';
import { FieldOptionsConfigMenuBar } from '@atlassian/jira-polaris-lib-field-option-configuration/src/ui/field-options-config-menu-bar/index.tsx';
import { useEntityLimitMessage } from '@atlassian/jira-polaris-lib-limits/src/controllers/index.tsx';
import { useCallbacks } from '../../../../../controllers/selectors/callback-hooks.tsx';
import {
	useCanEditField,
	useIsPreview,
} from '../../../../../controllers/selectors/config-hooks.tsx';
import { useDecorationForValue } from '../../../../../controllers/selectors/decoration-hooks.tsx';
import {
	useFieldIsLocked,
	useAreGlobalLabelsLoading,
	useFieldOptionAutoFormatting,
	useFieldOptionWeightType,
	useFieldOptions,
	useHasReachedFieldOptionsLimit,
	useIsGlobalCustomField,
	useIsGlobalLabelsLoadingFailed,
} from '../../../../../controllers/selectors/field-hooks.tsx';
import { ConversionWarningModal } from './conversion-warning-modal/index.tsx';
import messages from './messages.tsx';
import { OptionList, type OptionListApi } from './option-list/index.tsx';
import {
	numberFieldCanBeConvertedToRatingField,
	useFilteredOptions,
	useGetNextAutoFormattingColor,
} from './utils.tsx';

type DecoratorOptionsListProps = {
	showOptionCreateButton: boolean;
	onOpenOption: (id: string | null, field?: FieldKey) => void;
	readonly?: boolean;
	decorationConfigHeader?: React.JSX.Element;
};

export const DecoratedTagComponent = ({
	option,
	compact,
}: {
	option?: FieldOption;
	compact?: boolean;
}) => {
	const decoration = useDecorationForValue(option?.id);
	const emoji = useEmoji(decoration?.emoji);
	return (
		<CommonDecoratedTag
			mainColor={decoration?.backgroundColor}
			emoji={emoji}
			value={option?.value}
			compact={compact}
		/>
	);
};

export const DecoratorOptionsList = ({
	showOptionCreateButton,
	onOpenOption,
	readonly,
	decorationConfigHeader,
}: DecoratorOptionsListProps) => {
	const canEditField = useCanEditField();
	const optionWeightType = useFieldOptionWeightType();
	const isAutoFormattingEnabled = useFieldOptionAutoFormatting();
	const isFieldLocked = useFieldIsLocked();
	const options = useFieldOptions();
	const {
		onOptionAdded,
		onOptionsReordered,
		onOptionsSearch,
		onOptionOpened,
		onFieldOptionWeightTypeUpdated,
		onFieldOptionAutoFormattingUpdated,
		onAllValueDecorationsDeleted,
		onValueDecorationCreated,
	} = useCallbacks();
	const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
	const [optionSearchQuery, setOptionSearchQuery] = useState('');
	const filteredOptions = useFilteredOptions({
		query: optionSearchQuery,
		options,
	});
	const { formatMessage } = useIntl();
	const isSearching = optionSearchQuery !== '';
	const isPreview = useIsPreview();
	const getNextAutoFormattingColor = useGetNextAutoFormattingColor();
	const optionListRef = useRef<OptionListApi>(null);

	// get option limit message if reached
	const getEntityLimitMessage = useEntityLimitMessage();
	const [limitType, limitValue] = useHasReachedFieldOptionsLimit();

	const isGlobalField = useIsGlobalCustomField();
	const areGlobalLabelsLoading = useAreGlobalLabelsLoading();
	const isGlobalLabelsLoadingFailed = useIsGlobalLabelsLoadingFailed();

	const addOptionHandler = useCallback(
		async (newOption: string) => {
			if (!onOptionAdded) {
				return;
			}

			const optionId = await onOptionAdded(newOption);

			if (!optionId) {
				return;
			}

			if (isAutoFormattingEnabled) {
				const autoFormattingColor = getNextAutoFormattingColor();
				if (autoFormattingColor) {
					onValueDecorationCreated?.({
						backgroundColor: autoFormattingColor,
						emoji: undefined,
						highlightContainer: undefined,
						rules: [{ value: optionId, operator: ValueRuleOperator.EQ }],
					});
				}
			}

			defer(() => optionListRef.current?.scrollToOption(optionId));
		},
		[onOptionAdded, isAutoFormattingEnabled, onValueDecorationCreated, getNextAutoFormattingColor],
	);

	const rankOptionsHandler = useCallback(
		(rankedIds: string[]) => {
			onOptionsReordered && onOptionsReordered(rankedIds);
		},
		[onOptionsReordered],
	);

	const optionNames = useMemo(() => options.map((option) => option.value), [options]);

	const handleOptionWeightTypeChange = useCallback(
		(newOptionWeightType: WeightType | undefined) => {
			if (
				optionWeightType === WEIGHT_TYPE_NUMBER &&
				newOptionWeightType === WEIGHT_TYPE_RATING &&
				!numberFieldCanBeConvertedToRatingField(options)
			) {
				setIsWarningModalOpen(true);
				return;
			}

			onFieldOptionWeightTypeUpdated?.(newOptionWeightType);
			onOptionOpened?.(null);
		},
		[onFieldOptionWeightTypeUpdated, onOptionOpened, optionWeightType, options],
	);

	const handleSearch = useCallback(
		(query: string) => {
			setOptionSearchQuery(query);
			onOptionsSearch?.();
		},
		[onOptionsSearch],
	);

	const handleChangeAutoFormatting = useCallback(
		(value: boolean) => {
			onFieldOptionAutoFormattingUpdated?.(value);
		},
		[onFieldOptionAutoFormattingUpdated],
	);

	const handleClearFormattingForAllOptions = useCallback(() => {
		onAllValueDecorationsDeleted?.();
	}, [onAllValueDecorationsDeleted]);

	const areGlobalFieldLabelsLoading = isGlobalField && areGlobalLabelsLoading;
	const isGlobalFieldLabelsLoadingFailed = isGlobalField && isGlobalLabelsLoadingFailed;
	const isReadonlyWithoutOptions =
		!!readonly &&
		!options.length &&
		!areGlobalFieldLabelsLoading &&
		!isGlobalFieldLabelsLoadingFailed;

	return (
		<VStack>
			{!isPreview && (
				<Box>
					<FieldOptionsConfigMenuBar
						label={decorationConfigHeader}
						entityLimitMessage={limitType && getEntityLimitMessage(limitType, limitValue)}
						existingOptionNames={optionNames}
						hideActionButtons={!showOptionCreateButton}
						hideSearch={isReadonlyWithoutOptions}
						optionWeightType={optionWeightType}
						isOptionCreationDisabled={isFieldLocked || !showOptionCreateButton}
						onSearch={handleSearch}
						onOptionWeightTypeChange={handleOptionWeightTypeChange}
						onOptionCreation={addOptionHandler}
						isAutoFormattingOn={isAutoFormattingEnabled}
						onChangeAutoFormatting={handleChangeAutoFormatting}
						onClearFormattingForAllOptions={handleClearFormattingForAllOptions}
					/>
					{isReadonlyWithoutOptions && (
						<Text
							testId="polaris-component-field-configuration.ui.decoration.decoration-config-content.option-decoration.options.no-options"
							size="small"
						>
							{formatMessage(messages.noOptions)}
						</Text>
					)}
					<ConversionWarningModal
						isOpen={isWarningModalOpen}
						onClose={() => setIsWarningModalOpen(false)}
					/>
				</Box>
			)}
			<OptionList
				ref={optionListRef}
				options={filteredOptions}
				isDragDisabled={isSearching}
				onRankOption={rankOptionsHandler}
				onOptionClick={onOpenOption}
				isReadonly={!canEditField || isFieldLocked || readonly}
				isLoading={areGlobalFieldLabelsLoading}
				ErrorMessage={
					isGlobalFieldLabelsLoadingFailed
						? () => (
								<Flex alignItems="center" gap="space.050">
									<ErrorIcon spacing="spacious" label="" />
									{formatMessage(messages.globalLabelsLoadingError)}
								</Flex>
							)
						: undefined
				}
			/>
		</VStack>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const VStack = styled.div({
	height: '100%',
	display: 'flex',
	flexDirection: 'column',
	gap: token('space.100'),
});
