// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { Props, State } from '../types.tsx';
import {
	applyProjectTemplate,
	getProjectTemplate,
	checkProjectOnboarded,
} from './apply-template.tsx';
import { setProjectError } from './error.tsx';
import { loadInsights } from './insights/index.tsx';
import { loadProjectInformation } from './load-project-information.tsx';
import { loadProject } from './load-project.tsx';
import * as playsActions from './plays/index.tsx';
import { refreshAllSnippets } from './refresh-snippets.tsx';
import { setSelectedDeliveryProject } from './set-selected-delivery-project.tsx';
import { unLoadProject } from './unload-project.tsx';
import { updateProjectDetails } from './update-project-details.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default {
	loadProject,
	loadProjectInformation,
	unLoadProject,
	loadInsights,
	refreshAllSnippets,
	setSelectedDeliveryProject,
	updateProjectDetails,
	setProjectError,
	applyProjectTemplate,
	getProjectTemplate,
	checkProjectOnboarded,
	...playsActions,
	/**
	 * this action has no side effects. It is a helper accessor to get the container
	 * props in through the hook API and forwarded to the reselect selectors
	 */
	getContainerProps: () => (ops: StoreActionApi<State>, props: Props) => props,
};
