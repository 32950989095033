import type { State, PolarisProject } from './types.tsx';

export const EMPTY_PROJECT_STATE: PolarisProject = {
	id: undefined,
	name: undefined,
	key: undefined,
	jiraRankFieldKey: undefined,
	avatarUrls: undefined,
	onboarded: undefined,
	selectedDeliveryProject: undefined,
	onboardedAtTimestamp: undefined,
	templateVersion: undefined,
	insights: null,
	plays: null,
};

export const EMPTY_STATE: State = {
	meta: {
		loadingProps: undefined,
		project: {
			loading: false,
			error: undefined,
		},
		plays: {
			loading: false,
			error: undefined,
		},
		playComments: {
			loading: false,
			error: undefined,
		},
		insights: {
			loading: false,
			error: undefined,
		},
	},
	project: EMPTY_PROJECT_STATE,
	projectTemplate: undefined,
	permissions: [],
	containerProps: undefined,
};
