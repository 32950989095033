import React, { forwardRef } from 'react';
import { styled } from '@compiled/react';
import InfoIcon from '@atlaskit/icon/core/migration/information--info';
import { P200 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import Tooltip, { TooltipPrimitive } from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { IssueLinkingDisabledNotification } from '../../issue-linking/index.tsx';
import messages from './messages.tsx';

const IssueLinkingDisabledTooltip = forwardRef((props, ref) => (
	<InlineDialog {...props} ref={ref}>
		<IssueLinkingDisabledNotification />
	</InlineDialog>
));

export const IssueLinkingDisabledMarker = () => {
	const { formatMessage } = useIntl();
	return (
		// "infoIcon" is used as a tooltip's content only because this prop can't be empty,
		// otherwise the tooltip doesn't render the provided component
		<Tooltip
			component={IssueLinkingDisabledTooltip}
			content="infoIcon"
			testId="polaris-common.ui.field-info-marker.issue-linking-disabled.tooltip"
		>
			<InfoIcon
				color={token('color.icon.warning')}
				label={formatMessage(messages.warningMessage)}
				LEGACY_primaryColor={P200}
				LEGACY_size="small"
			/>
		</Tooltip>
	);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InlineDialog = styled<any>(TooltipPrimitive)({
	backgroundColor: token('elevation.surface.overlay'),
	borderRadius: '3px',
	boxShadow: '0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31)',
	paddingTop: token('space.150'),
	paddingRight: token('space.150'),
	paddingBottom: token('space.150'),
	paddingLeft: token('space.150'),
	maxWidth: '390px',
});
