import React, { useCallback, memo } from 'react';
import AvatarGroup from '@atlaskit/avatar-group';
import { Box, Inline, Stack, xcss } from '@atlaskit/primitives';
import traceUFOPress from '@atlaskit/react-ufo/trace-press';
import { useIntl } from '@atlassian/jira-intl';
import { type Ari, toAri } from '@atlassian/jira-platform-ari/src/index.tsx';
import { useLocalIssueIdForJiraIssueId } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/issue-ids-hooks.tsx';
import {
	useGetIdeaPlayTotalBudget,
	useGetIdeaPlayTotalContributors,
	useGetPlayContributionsStats,
	useGetIdeaPlayContributorsAvatars,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/plays/hooks.tsx';
import type { PlayStats } from '@atlassian/jira-polaris-common/src/controllers/issue/types.tsx';
import { useOpenRightSidebarOnPlay } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/actions/hooks.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { useInsight } from '../../../../controllers/insights/selectors/insights-hooks.tsx';
import { Selection } from '../insight/selection/index.tsx';
import { Card } from './card/index.tsx';
import { Header } from './header/index.tsx';
import { messages } from './messages.tsx';

type InsightProps = {
	issueId: IssueId;
	insightId: string;
};

type PlaySnippetProps = {
	playId: Ari;
	localIssueId: LocalIssueId;
};

export const useAtlassianAccountId = () => {
	const { atlassianAccountId } = useTenantContext();
	return { atlassianAccountId };
};

export const PlaySnippet = ({ playId, localIssueId }: PlaySnippetProps) => {
	const { formatMessage } = useIntl();

	const { comments, voters, votes }: PlayStats = useGetPlayContributionsStats(playId);
	const totalContributors = useGetIdeaPlayTotalContributors(playId, localIssueId);
	const ideaPlayContributorsAvatars = useGetIdeaPlayContributorsAvatars(playId, localIssueId);
	const totalBudget = useGetIdeaPlayTotalBudget(playId, localIssueId);

	const openRightSidebarOnPlay = useOpenRightSidebarOnPlay();

	const handleTogglePlayContributions = useCallback(() => {
		traceUFOPress('jpd.right-sidebar.play');

		openRightSidebarOnPlay(localIssueId, playId);
	}, [localIssueId, openRightSidebarOnPlay, playId]);

	return (
		<Box xcss={cardWrapperStyles}>
			<Card
				onClick={() => handleTogglePlayContributions()}
				title={formatMessage(messages.playCardTitleVoters)}
				footer={formatMessage(messages.votersInfo, { voters })}
			>
				<Box xcss={avatarGroupWrapperStyles}>
					<AvatarGroup
						appearance="stack"
						isTooltipDisabled
						// @ts-expect-error - TS2322 - Type 'Avatar[]' is not assignable to type 'AvatarProps[]'.
						data={ideaPlayContributorsAvatars}
					/>
				</Box>
			</Card>
			<Card
				onClick={() => handleTogglePlayContributions()}
				title={formatMessage(messages.playCardTitleVotes)}
				footer={formatMessage(messages.votesInfo, { votes })}
			>
				{totalBudget}
			</Card>
			<Card
				onClick={() => handleTogglePlayContributions()}
				title={formatMessage(messages.playCardTitleComments)}
				footer={formatMessage(messages.commentsInfo, { comments })}
			>
				{totalContributors}
			</Card>
		</Box>
	);
};

export const PlayInsight = memo(({ insightId, issueId }: InsightProps) => {
	const insight = useInsight(insightId);

	const localIssueId = useLocalIssueIdForJiraIssueId(issueId);
	const playId = toAri(insight?.play?.id ?? '');

	if (localIssueId === undefined || playId === undefined) {
		return null;
	}

	return (
		<Inline
			xcss={containerStyles}
			testId="polaris-ideas.ui.insights.insights.play-insight.container"
		>
			<Selection insightId={insightId} isDisabled />
			<Stack xcss={contentContainerStyles}>
				<Header playId={playId} insightId={insightId} />
				<PlaySnippet localIssueId={localIssueId} playId={playId} />
			</Stack>
		</Inline>
	);
});

PlayInsight.displayName = 'PlayInsight';

const avatarGroupWrapperStyles = xcss({
	pointerEvents: 'none',
});

const cardWrapperStyles = xcss({
	display: 'inline-flex',
	gap: 'space.100',
});

const containerStyles = xcss({
	position: 'relative',
	paddingTop: 'space.300',
	paddingLeft: 'space.075',
	flex: '1 1 auto',
});

const contentContainerStyles = xcss({
	flex: '1 1 auto',
});
