import React, { useState } from 'react';
import { Flex, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import type { IssueTypeId } from '@atlassian/jira-shared-types/src/general.tsx';
import {
	useIssueTypesActions,
	useIsIssueTypeDeletable,
	useIsIssueTypeEditable,
} from '@atlassian/jira-polaris-component-issue-types/src/controllers/index.tsx';
import { useNotifications } from '@atlassian/jira-polaris-lib-notifications/src/controllers/index.tsx';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { useIssueType } from '@atlassian/jira-polaris-component-issue-types/src/index.tsx';
import { useProject } from './project-provider.tsx';
import { messages } from './messages.tsx';
import { NameInput } from './name-input.tsx';
import { EditWorkflowButton } from './edit-workflow-button.tsx';
import { TrashButton } from './trash-button.tsx';
import { useFormState } from './utils/use-form-state.tsx';
import { DeleteModal } from './delete-modal.tsx';
import { IconField } from './icon-field.tsx';

type Props = {
	issueTypeId: IssueTypeId;
};

export const TypeCard = ({ issueTypeId }: Props) => {
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);
	const { error } = useNotifications();
	const { projectId } = useProject();
	const { deleteIssueType } = useIssueTypesActions();
	const issueType = useIssueType(issueTypeId);
	const {
		avatarId,
		name,
		onChangeAndSubmitAvatarId,
		onChangeName,
		onSubmitAvatarIdAndName,
		isNameInvalid,
		nameError,
	} = useFormState({
		defaultAvatarId: issueType?.avatarId,
		defaultName: issueType?.name,
		issueTypeId,
		projectId,
	});
	const isDeletable = useIsIssueTypeDeletable({ issueTypeId });
	const isEditable = useIsIssueTypeEditable({ issueTypeId });

	const onDeleteIssueType = async () => {
		if (isDeleting) return;
		setIsDeleting(true);

		try {
			await deleteIssueType(issueTypeId, projectId);

			// Reset local state
			setShowDeleteModal(false);
			setIsDeleting(false);
		} catch (err) {
			if (err instanceof FetchError && err.statusCode === 409) {
				error({
					title: formatMessage(messages.cantDeleteErrorTitle),
					description: formatMessage(messages.cantDeleteErrorDescription),
				});
			} else {
				error({
					title: formatMessage(messages.errorTitle),
					description: formatMessage(messages.errorDescription),
				});
			}
			// Reset local state
			setShowDeleteModal(false);
			setIsDeleting(false);
		}
	};

	const { formatMessage } = useIntl();
	return (
		<>
			<Flex xcss={wrapperStyles} columnGap="space.100" alignItems="center">
				<IconField
					avatarId={avatarId}
					isDisabled={!isEditable}
					onChange={onChangeAndSubmitAvatarId}
				/>
				<NameInput
					value={name}
					onChange={onChangeName}
					isDisabled={!isEditable}
					onSubmit={onSubmitAvatarIdAndName}
					isInvalid={isNameInvalid}
					submitOnBlur
					error={nameError}
				/>
				<EditWorkflowButton issueTypeId={issueTypeId} />
				<TrashButton isDisabled={!isDeletable} onClick={() => setShowDeleteModal(true)} />
			</Flex>
			{showDeleteModal && (
				<DeleteModal
					onClose={() => setShowDeleteModal(false)}
					onDelete={onDeleteIssueType}
					isDeleting={isDeleting}
				/>
			)}
		</>
	);
};

const wrapperStyles = xcss({
	borderWidth: '1px',
	borderRadius: '4px',
	borderColor: 'color.border',
	borderStyle: 'solid',
	width: 'initial',
	paddingBlock: 'space.025',
	paddingInline: 'space.050',
	marginBottom: 'space.050',

	':hover': {
		backgroundColor: 'color.background.neutral.subtle.hovered',
	},

	// Directly targeting the elements instead of using javascript listeners.
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-expect-error
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	':hover [data-component-selector="trash-button-tb1"], :hover [data-component-selector="edit-workflow-ew1"]':
		{
			visibility: 'visible',
		},
});
