import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	tooltipIssues: {
		id: 'polaris-common.linked-issues-progress.tooltip-issues',
		defaultMessage:
			'{category}: {count} of {total, plural, one {1 delivery ticket} other {# delivery tickets}}',
		description:
			'Tooltip text. Appears when hovering the progress bar. Represents the count of remaining issues in each status category.',
	},
	tooltipIssuesIssueTermRefresh: {
		id: 'polaris-common.linked-issues-progress.tooltip-issues-issue-term-refresh',
		defaultMessage:
			'{category}: {count} of {total, plural, one {1 delivery work item} other {# delivery work items}}',
		description:
			'Tooltip text. Appears when hovering the progress bar. Represents the count of remaining issues in each status category.',
	},
	tooltipStoryPoints: {
		id: 'polaris-common.linked-issues-progress.tooltip-story-points',
		defaultMessage:
			'{category}: {count} of {total, plural, one {# story point} other {# story points}}',
		description:
			'Tooltip text. Appears when hovering the progress bar. Represents the count of remaining story points in each status category.',
	},
	tooltipLoading: {
		id: 'polaris-common.linked-issues-progress.tooltip-loading',
		defaultMessage: 'loading...',
		description:
			'Tooltip that appears when hovering the progress bar and the progress is still being loaded from the backend.',
	},
	unestimatedProgress: {
		id: 'polaris-common.linked-issues-progress.unestimated-progress',
		defaultMessage: 'Unestimated',
		description: 'Tooltip that appears when hovering the progress bar that has no estimated issues',
	},
});
