import React, { memo } from 'react';
import Lozenge from '@atlaskit/lozenge';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

export const EapLabel = memo(() => {
	const { formatMessage } = useIntl();

	return (
		<Tooltip position="top" content={formatMessage(messages.tooltip)}>
			<Lozenge appearance="new" isBold>
				{formatMessage(messages.badge)}
			</Lozenge>
		</Tooltip>
	);
});
