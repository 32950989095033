import keyBy from 'lodash/keyBy';
import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import getAttachmentsQuery from '@atlassian/jira-issue-fetch-services-common/src/services/issue-gira-data/gira-fragments/attachments/index.tsx';
import getMediaContextQuery from '@atlassian/jira-issue-fetch-services-common/src/services/issue-gira-data/gira-fragments/media-context/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { transformData as transformMediaContext } from '@atlassian/jira-issue-view-services/src/issue/gira/media-context/index.tsx';
import type {
	JiraIssueAttachmentsMediaContextResponse,
	JiraIssueAttachmentFileTokens,
	JiraIssueAttachmentFileTokensRaw,
	JiraIssueAttachmentInfo,
} from './types.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { JiraIssueAttachmentsMediaContextResponse };

const attachmentsQuery = () => `
    query polarisAttachmentsQuery($issueKey: String) {
        ${getMediaContextQuery()}
        viewIssue(issueKey: $issueKey) {
            ${getAttachmentsQuery()}
        }
}`;

const getIssueAttachmentMediaRequestOptions = (issueKeyOrId: number | string) => ({
	body: JSON.stringify({
		query: attachmentsQuery(),
		variables: {
			issueKey: issueKeyOrId,
		},
	}),
});

const convertToJiraIssueAttachmentFileTokens = (
	tokenWithFiles: JiraIssueAttachmentFileTokensRaw,
	attachmentInfoMap: Record<string, JiraIssueAttachmentInfo>,
): JiraIssueAttachmentFileTokens => ({
	...tokenWithFiles,
	files: tokenWithFiles.files.map((file) => ({
		attachmentId: +file.attachmentId,
		issueId: +file.issueId,
		attachmentMediaApiId: file.attachmentMediaApiId,
		authorAccountId: attachmentInfoMap[file.attachmentId]?.author.accountId,
	})),
});

export const getIssueAttachmentWithMediaContext = (
	issueKeyOrId?: number | string,
): Promise<JiraIssueAttachmentsMediaContextResponse> => {
	if (issueKeyOrId === undefined) {
		return Promise.reject(new Error('Issue id must be supplied, cannot load attachments'));
	}

	return performPostRequest(
		`/rest/gira/1/${fg('jpd-trace-fetch-network-call') ? '?operation=getIssueAttachmentMedia' : ''}`,
		getIssueAttachmentMediaRequestOptions(issueKeyOrId),
	).then(({ data }) => {
		const attachmentInfoMap = keyBy(data.viewIssue.attachments.nodes, ({ id }) => id);

		const issueAttachments = data.mediaContext.readToken;

		issueAttachments.tokensWithFiles = issueAttachments.tokensWithFiles.map(
			(tokenWithFiles: JiraIssueAttachmentFileTokensRaw) =>
				convertToJiraIssueAttachmentFileTokens(tokenWithFiles, attachmentInfoMap),
		);

		return {
			issueAttachments,
			mediaContext: fg('jpd-idea-view-media-context-optimization')
				? transformMediaContext(data.mediaContext)
				: {
						viewContext: null,
						uploadContext: null,
						userAuth: null,
					},
		};
	});
};
