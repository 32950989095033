import { useCallback } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import { useNotifications } from '@atlassian/jira-polaris-lib-notifications/src/controllers/index.tsx';
import { useAllTeamInformationOnIssues } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/team-hooks.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useAllIssueTypesById } from '@atlassian/jira-polaris-component-issue-types/src/controllers/index.tsx';
import { functionWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { useSetFilteredLocalIssueId } from '../../../../controllers/create-with-filters/index.tsx';
import { messages } from './messages.tsx';
import { useTryApplyFiltersToCreatedIssue } from './utils.tsx';

const useAllIssueTypesByIdHook = functionWithCondition<typeof useAllIssueTypesById>(
	() => fg('jpd_issue_types_ga'),
	useAllIssueTypesById,
	() => ({}),
);

export const useOnCreatedOrMovedIssueFiltered = () => {
	const { clearAllFilters } = useViewActions();
	const { success } = useNotifications();
	const setFilteredLocalIssue = useSetFilteredLocalIssueId();
	const { formatMessage } = useIntl();
	const issueTeamInfos = useAllTeamInformationOnIssues();
	const tryApplyFiltersToCreatedIssue = useTryApplyFiltersToCreatedIssue(issueTeamInfos);
	const allIssueTypesById = useAllIssueTypesByIdHook();

	const onCreatedOrMovedIssueFilteredOld = useCallback(
		(issueId: string, _issueTypeId: string, isMoved = false, label?: string) => {
			const hasSuccessfullyAppliedFilters = tryApplyFiltersToCreatedIssue(issueId);
			if (hasSuccessfullyAppliedFilters) return;

			const movedTitle =
				label !== undefined
					? formatMessage(messages.moveSuccessSpecific, {
							columnLabel: label,
						})
					: formatMessage(messages.moveSuccessGeneric);

			const dismissFunction = success({
				title: isMoved ? movedTitle : formatMessage(messages.createSuccess),
				description: isMoved
					? formatMessage(messages.moveSuccessInvisibleDescription)
					: formatMessage(messages.createSuccessInvisibleDescription),
				actions: [
					{
						content: formatMessage(messages.clearFilters),
						onClick: () => {
							clearAllFilters();
							dismissFunction();
						},
					},
					{
						content: formatMessage(messages.editFiltersAndValues),
						onClick: () => {
							setFilteredLocalIssue(issueId);
							dismissFunction();
						},
					},
				],
			});
		},
		[clearAllFilters, formatMessage, setFilteredLocalIssue, success, tryApplyFiltersToCreatedIssue],
	);

	const onCreatedOrMovedIssueFilteredNew = useCallback(
		(issueId: string, issueTypeId: string, isMoved = false, label?: string) => {
			const hasSuccessfullyAppliedFilters = tryApplyFiltersToCreatedIssue(issueId);
			if (hasSuccessfullyAppliedFilters) return;

			const issueTypeName = allIssueTypesById[issueTypeId]?.name ?? formatMessage(messages.idea);

			const movedTitle =
				label !== undefined
					? formatMessage(messages.moveSuccessSpecificHeader, {
							issueTypeName,
							columnLabel: label,
						})
					: formatMessage(messages.moveSuccessGenericHeader, {
							issueTypeName,
						});

			const dismissFunction = success({
				title: isMoved
					? movedTitle
					: formatMessage(messages.createSuccessHeader, {
							issueTypeName,
						}),
				description: isMoved
					? formatMessage(messages.moveSuccessInvisibleDescription)
					: formatMessage(messages.createSuccessDescription, {
							issueTypeName: issueTypeName.toLocaleLowerCase(),
						}),
				actions: [
					{
						content: formatMessage(messages.clearFilters),
						onClick: () => {
							clearAllFilters();
							dismissFunction();
						},
					},
					{
						content: formatMessage(messages.editButton),
						onClick: () => {
							setFilteredLocalIssue(issueId);
							dismissFunction();
						},
					},
				],
			});
		},
		[
			allIssueTypesById,
			clearAllFilters,
			formatMessage,
			setFilteredLocalIssue,
			success,
			tryApplyFiltersToCreatedIssue,
		],
	);

	return fg('jpd_issue_types_ga')
		? onCreatedOrMovedIssueFilteredNew
		: onCreatedOrMovedIssueFilteredOld;
};
