import { useMemo } from 'react';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	type Action,
	createContainer,
	createStore,
	createActionsHook,
} from '@atlassian/react-sweet-state';
import { SpotlightTypes } from '../common/types.tsx';
import * as actions from './actions/index.tsx';
import type { Props, State } from './types.tsx';

type Actions = typeof actions;

const initialState: State = {
	shouldSeeOnboarding: undefined,
	productUsageStartTimestamp: undefined,
	remoteState: null,
	ignoredSpotlights: [],
};

export const OnboardingStore = createStore<State, Actions>({
	initialState,
	actions,
	name: 'PolarisOnboardingStore',
});

export const useActions = createActionsHook(OnboardingStore);

const handlePropsChange =
	(isInit = true): Action<State, Props> =>
	async ({ dispatch }, { hasUserCreatedIssues }) => {
		if (isInit) {
			dispatch(actions.ignoreAllSpotlights());

			await dispatch(actions.loadProductStartTimestamp());
			await dispatch(actions.loadShouldSeeOnboarding());
			await dispatch(actions.loadRemoteState());
		}

		dispatch(actions.updateIgnoredSpotlights());

		if (hasUserCreatedIssues) {
			await dispatch(actions.closeSpotlight(SpotlightTypes.CREATE_IDEA));
		}
	};

export const OnboardingSweetStateContainer = createContainer<State, Actions, Props>(
	OnboardingStore,
	{
		onInit: handlePropsChange,
		onUpdate: () => handlePropsChange(false),
	},
);

// publicly exposed actions view hook
export const useSpotlights = () => {
	const { closeSpotlight } = useActions();

	return useMemo(
		() => ({
			closeSpotlight,
		}),
		[closeSpotlight],
	);
};
