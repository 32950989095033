/** @jsx jsx */
import React, { useState, useMemo } from 'react';
import { css, jsx } from '@compiled/react';
import AKSmartUserPicker from '@atlaskit/smart-user-picker';
import type { UserPickerProps } from '@atlaskit/user-picker';
import { token } from '@atlaskit/tokens';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

const isPermissionError = (error: Error): boolean => {
	if (
		('statusCode' in error && error.statusCode === 403) ||
		error.message?.includes('403') ||
		error.message?.includes('not allowed')
	) {
		return true;
	}

	return false;
};

type SmartUserPickerProps = Pick<
	UserPickerProps,
	| 'addMoreMessage'
	| 'allowEmail'
	| 'isDisabled'
	| 'isMulti'
	| 'noOptionsMessage'
	| 'onBlur'
	| 'onChange'
	| 'onClose'
	| 'onFocus'
	| 'onInputChange'
	| 'onKeyDown'
	| 'onSelection'
	| 'open'
	| 'placeholder'
	| 'styles'
	| 'value'
> & {
	fieldId: string;
};

export const SmartUserPicker = ({
	addMoreMessage = '',
	allowEmail,
	noOptionsMessage,
	placeholder,
	value,
	...props
}: SmartUserPickerProps) => {
	const [error, setError] = useState<Error | null>(null);
	const cloudId = useCloudId();
	const { formatMessage } = useIntl();

	const handleError = (searchError: Error) => {
		if (searchError) {
			setError(searchError);
		}
	};

	const emptyResultsMessage = ({ inputValue }: { inputValue: string }) => {
		if (error) {
			if (isPermissionError(error)) {
				return formatMessage(messages.permissionErrorMessage);
			}

			return formatMessage(messages.searchErrorMessage);
		}

		if (noOptionsMessage !== undefined) {
			if (typeof noOptionsMessage === 'function') {
				return noOptionsMessage({ inputValue });
			}

			return noOptionsMessage;
		}

		if (value) {
			return null;
		}

		return formatMessage(messages.noOptionsMessage);
	};

	const finalPlaceholder = useMemo(() => {
		if (placeholder) {
			return placeholder;
		}

		if (allowEmail) {
			return formatMessage(messages.withEmailPlaceholder);
		}

		return formatMessage(messages.withoutEmailPlaceholder);
	}, [allowEmail, formatMessage, placeholder]);

	return (
		<div css={wrapperStyles}>
			<AKSmartUserPicker
				{...props}
				addMoreMessage={addMoreMessage}
				allowEmail={allowEmail}
				bootstrapOptions={[]}
				maxOptions={60}
				noOptionsMessage={emptyResultsMessage}
				onError={handleError}
				placeholder={finalPlaceholder}
				principalId="context" // infer principal ID from UCT
				productKey="jira"
				siteId={cloudId}
				value={value}
			/>
		</div>
	);
};

// copy of "DefaultWrapper" from "confluence/next/packages/user-and-group-search/src/UserAndGroupSearchPicker.tsx" styles
const wrapperStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> div > .fabric-user-picker__control': {
		minHeight: '40px',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'> div.fabric-user-picker__value-container': {
			paddingTop: token('space.025'),
			paddingBottom: token('space.025'),
		},
	},
});
