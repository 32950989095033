import {
	getRoles,
	getIsCreatorRole,
	getPermissionsOnRole,
	getRoleDeletable,
	getRoleDescription,
	getRoleEditable,
	getRoleConfigurationIds,
	getRoleName,
	getRoleTranslatedName,
	getRoleNames,
	getCreatorRoles,
	getGetRoleByName,
	getDefaultRoles,
	getCreatorRolesIds,
} from './roles.tsx';
import { createSelectorHook, createHigherLevelHook } from './utils.tsx';

export const useRoles = createSelectorHook(getRoles);
export const useRoleConfigurationIds = createSelectorHook(getRoleConfigurationIds);
export const useRoleNames = createSelectorHook(getRoleNames);
export const useRoleName = createHigherLevelHook(getRoleName);
export const useRoleTranslatedName = createHigherLevelHook(getRoleTranslatedName);

export const useRoleDescription = createHigherLevelHook(getRoleDescription);
export const useIsRoleDeletable = createHigherLevelHook(getRoleDeletable);
export const useIsRoleEditable = createHigherLevelHook(getRoleEditable);

export const usePermissionsOnRole = createHigherLevelHook(getPermissionsOnRole);

export const useIsCreatorRole = createHigherLevelHook(getIsCreatorRole);

export const useCreatorRoles = createSelectorHook(getCreatorRoles);

export const useCreatorRolesIds = createSelectorHook(getCreatorRolesIds);

export const useGetRoleByName = createHigherLevelHook(getGetRoleByName);

export const useGetDefaultRoles = createSelectorHook(getDefaultRoles);
