import React from 'react';
import Lozenge from '@atlaskit/lozenge';
import { useIsCollectionView } from '@atlassian/jira-polaris-common/src/controllers/environment/index.tsx';
import { useIssuesStatusesById } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/fields-hooks.tsx';
import { useAllStatusesById } from '@atlassian/jira-polaris-common/src/controllers/workflow/selectors/statuses-hooks.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { fg } from '@atlassian/jira-feature-gating';

type ColumnHeaderComponentProps = {
	fieldKey: FieldKey;
	groupIdentity: string;
};

const StatusLozenge = ({
	categoryName,
	statusName,
}: {
	categoryName: string;
	statusName: string;
}) => {
	switch (categoryName) {
		// Wrapping statusName in span to fix rendering of status in Download Roadmap view as JPEG
		case 'In Progress':
			return (
				<Lozenge appearance="inprogress">
					<span>{statusName}</span>
				</Lozenge>
			);
		case 'Done':
			return (
				<Lozenge appearance="success">
					<span>{statusName}</span>
				</Lozenge>
			);
		case 'To Do':
		default:
			return (
				<Lozenge>
					<span>{statusName}</span>
				</Lozenge>
			);
	}
};

const StatusColumnHeader = ({ groupIdentity }: ColumnHeaderComponentProps) => {
	const statiById = useAllStatusesById();
	const issuesStatusesById = useIssuesStatusesById();
	const isCollectionView = useIsCollectionView();
	const status =
		isCollectionView && !fg('jpd-aurora-roadmap-inline-edit')
			? issuesStatusesById[groupIdentity]
			: statiById[groupIdentity];

	if (!status && fg('polaris_react_18_status_fix')) {
		return null;
	}

	return <StatusLozenge categoryName={status.statusCategory.name} statusName={status.name} />;
};

export const statusColumnHeaderConfig = {
	isHideable: true,
	isDecoratable: false,
	Component: StatusColumnHeader,
	EmptyComponent: undefined,
} as const;
