import { useMemo } from 'react';
import has from 'lodash/has';
import type { Field, FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { FieldType } from '@atlassian/jira-polaris-domain-field/src/field-types/types.tsx';
import { ENTITY_LIMIT_TYPE } from '@atlassian/jira-polaris-domain-project/src/constants.tsx';
import { useEntityLimitMessage } from '@atlassian/jira-polaris-lib-limits/src/controllers/index.tsx';
import { useEntityLimitsByType } from '@atlassian/jira-polaris-component-entity-limits-store/src/controllers/entity-limits/selectors/entity-limits-hooks.tsx';
import { useFieldsByKey } from '../../field/selectors/field-hooks.tsx';
import {
	getFields,
	getFieldsForViewControls,
	getAllFieldsForViewControls,
	createGetFieldForFieldKey,
	createGetFieldByType,
	getAllFieldsForViewControlsCountByType,
} from './fields.tsx';
import { createViewHook, createHigherLevelViewHook } from './utils.tsx';
import { useCurrentViewFields } from './view-hooks.tsx';

type LimitValues = {
	[ENTITY_LIMIT_TYPE.FIELDS_PER_PROJECT]: string | null;
	[ENTITY_LIMIT_TYPE.PLAYS_PER_PROJECT]: string | null;
};

export const useFields = createViewHook(getFields);
export const useFieldsForViewControls = createViewHook(getFieldsForViewControls);
/**
 * All fields, including those that are hidden
 * Use useFieldsForViewControls if you want to exclude hidden fields
 */
export const useAllFieldsForViewControls = createViewHook(getAllFieldsForViewControls);

export const useFieldForFieldKey = createHigherLevelViewHook<
	FieldKey | undefined,
	Field | undefined
>(createGetFieldForFieldKey);

export const useFieldByType = createHigherLevelViewHook<FieldType, Field | undefined>(
	createGetFieldByType,
);

export const useSortedSelectedFields = () => {
	const fields = useFieldsForViewControls();
	const selectedFieldsBase = useCurrentViewFields();
	const [allFields] = useFieldsByKey();

	const selectedFields = useMemo(
		() => selectedFieldsBase.filter(({ key }) => has(allFields, key)),
		[selectedFieldsBase, allFields],
	);

	return useMemo(() => {
		const newFields = Object.keys(fields)
			.filter((key) => !selectedFields.includes(fields[key]))
			.map((key) => fields[key]);
		newFields.sort((a, b) => a.label.localeCompare(b.label));
		return [...selectedFields, ...newFields];
	}, [fields, selectedFields]);
};

const useAllFieldsForViewControlsCountByType = createViewHook(
	getAllFieldsForViewControlsCountByType,
);

// Returns the limit type and value if reached, otherwise returns null
export const useHasReachedFieldsLimit = (): LimitValues => {
	const entityLimitMessage = useEntityLimitMessage();

	const limits: LimitValues = {
		[ENTITY_LIMIT_TYPE.FIELDS_PER_PROJECT]: null,
		[ENTITY_LIMIT_TYPE.PLAYS_PER_PROJECT]: null,
	};

	const { totalFieldsCount, votesFieldsCount } = useAllFieldsForViewControlsCountByType();

	const fieldsLimitStandard = useEntityLimitsByType(ENTITY_LIMIT_TYPE.FIELDS_PER_PROJECT);
	const fieldsLimitVotes = useEntityLimitsByType(ENTITY_LIMIT_TYPE.PLAYS_PER_PROJECT);

	if (fieldsLimitStandard && totalFieldsCount >= fieldsLimitStandard) {
		limits[ENTITY_LIMIT_TYPE.FIELDS_PER_PROJECT] = entityLimitMessage(
			ENTITY_LIMIT_TYPE.FIELDS_PER_PROJECT,
			fieldsLimitStandard,
		);
	}

	if (fieldsLimitVotes && votesFieldsCount >= fieldsLimitVotes) {
		limits[ENTITY_LIMIT_TYPE.PLAYS_PER_PROJECT] = entityLimitMessage(
			ENTITY_LIMIT_TYPE.PLAYS_PER_PROJECT,
			fieldsLimitVotes,
		);
	}

	return limits;
};
