import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	noProjectPermissionsErrorTitle: {
		id: 'polaris-ideas.view-content.idea-timeline.draggable.no-project-permissions-error-title',
		defaultMessage: "You can't edit this idea",
		description:
			'Error message title when user do not have project permissions to edit ideas, so to drag them around.',
	},
	noProjectPermissionsErrorDescription: {
		id: 'polaris-ideas.view-content.idea-timeline.draggable.no-project-permissions-error-description',
		defaultMessage:
			"You don't have permission to edit ideas from <b>{projectName}</b> project. Contact your project admin.",
		description:
			'Error message description when user do not have project permissions to edit ideas, so to drag them around.',
	},
	groupByFieldsNotAddedToProjectErrorTitle: {
		id: 'polaris-ideas.view-content.idea-timeline.draggable.group-by-fields-not-added-to-project-error-title',
		defaultMessage: "You can't move this idea",
		description:
			'Error message title when user try to add an idea to timeline but the its project is missing the global field used for group by.',
	},
	groupByFieldsNotAddedToProjectErrorDescription: {
		id: 'polaris-ideas.view-content.idea-timeline.draggable.group-by-fields-not-added-to-project-error-description',
		defaultMessage:
			"You can't add this idea to the timeline because you are grouping by a field that has yet to be added to <b>{projectName}</b>. To do so, select the global field > Edit and add the field to the project.",
		description:
			'Error message title when user try to add an idea to timeline but the its project is missing the global field used for group by.',
	},
});
