import React, { useCallback } from 'react';
import { styled } from '@compiled/react';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button/new';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import ArrowDownIcon from '@atlaskit/icon/core/migration/arrow-down';
import ArrowUpIcon from '@atlaskit/icon/core/migration/arrow-up';
import MoreVerticalIcon from '@atlaskit/icon/core/migration/show-more-vertical--more-vertical';
import TrashIcon from '@atlaskit/icon/core/migration/delete--trash';
import { Box, Flex, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import type { HeaderProps } from '@atlassian/jira-polaris-lib-list/src/types.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useFieldsTableActions } from '../../../../../../../controllers/fields-table/index.tsx';
import { useSorting } from '../../../../../../../controllers/fields-table/selectors/fields-hooks.tsx';
import type { Column, State } from '../../../../../../../controllers/fields-table/types.tsx';
import messages from './messages.tsx';

const moreIconDataSelector = 'header-button-icon-fN99';

export const Header = ({ columnId }: HeaderProps) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { formatMessage } = useIntl();
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const column = columnId as Column;

	const sorting = useSorting();
	const { setSorting } = useFieldsTableActions();

	const isCurrentColumnSorted = column === sorting.column;

	const handleSortingChange = useCallback(
		(col: State['sort']['column'], direction: State['sort']['direction']) => {
			fireUIAnalytics(createAnalyticsEvent({}), 'menuItem clicked', 'sort', {
				sortColumn: col,
				sortDirection: direction,
			});
			setSorting(col, direction);
		},
		[createAnalyticsEvent, setSorting],
	);

	if (column === 'fieldSettings') {
		return null;
	}

	return (
		<DropdownMenu<HTMLButtonElement>
			placement="bottom-end"
			trigger={({ triggerRef, isSelected, testId, ...providedProps }) => (
				<Button appearance="subtle" {...providedProps} ref={triggerRef}>
					<HeaderTriggerWrapper>
						<Flex
							alignItems="center"
							gap="space.050"
							xcss={[isCurrentColumnSorted && sortedColumnHeaderStyles]}
						>
							{formatMessage(messages[column])}
							{isCurrentColumnSorted &&
								(sorting.direction === 'asc' ? (
									<ArrowUpIcon LEGACY_size="small" label="" />
								) : (
									<ArrowDownIcon LEGACY_size="small" label="" />
								))}
						</Flex>
						<Box xcss={moreIconWrapperStyles} data-component-selector={moreIconDataSelector}>
							<MoreVerticalIcon LEGACY_size="small" label="" />
						</Box>
					</HeaderTriggerWrapper>
				</Button>
			)}
		>
			<DropdownItemGroup>
				{(!isCurrentColumnSorted || sorting.direction !== 'asc') && (
					<DropdownItem onClick={() => handleSortingChange(column, 'asc')}>
						<Flex gap="space.050" alignItems="center">
							<ArrowUpIcon LEGACY_size="small" label="" />
							{formatMessage(messages.sortAscending)}
						</Flex>
					</DropdownItem>
				)}
				{(!isCurrentColumnSorted || sorting.direction !== 'desc') && (
					<DropdownItem onClick={() => handleSortingChange(column, 'desc')}>
						<Flex gap="space.050" alignItems="center">
							<ArrowDownIcon LEGACY_size="small" label="" />
							{formatMessage(messages.sortDescending)}
						</Flex>
					</DropdownItem>
				)}
				{isCurrentColumnSorted && (
					<DropdownItem onClick={() => handleSortingChange(undefined, 'asc')}>
						<Flex gap="space.050" alignItems="center">
							<TrashIcon LEGACY_size="small" label="" />
							{formatMessage(messages.removeSort)}
						</Flex>
					</DropdownItem>
				)}
			</DropdownItemGroup>
		</DropdownMenu>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeaderTriggerWrapper = styled.div({
	display: 'flex',
	justifyContent: 'space-between',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	[`:hover [data-component-selector="${moreIconDataSelector}"]`]: {
		display: 'block',
	},
});

const moreIconWrapperStyles = xcss({
	display: 'none',
});

const sortedColumnHeaderStyles = xcss({
	color: 'color.text.selected',
});
