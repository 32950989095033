import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import AttachmentIcon from '@atlaskit/icon/core/migration/attachment';
import type { MediaClientConfig } from '@atlaskit/media-core';
import {
	Browser,
	type UploadEndEventPayload,
	type UploadErrorEventPayload,
	type UploadsStartEventPayload,
} from '@atlaskit/media-picker';
import { useIntl } from '@atlassian/jira-intl';
import type { MediaUploadPermissionResponse } from '@atlassian/jira-issue-fetch-services-common/src/services/issue-media-upload-permissions/index.tsx';
import { useIdeaActions } from '@atlassian/jira-polaris-common/src/controllers/idea/main.tsx';
import { useSelectedIssueKey } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { getMediaUploadConfig } from '@atlassian/jira-polaris-common/src/controllers/media/utils.tsx';
import { useCanAddAttachments } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { ButtonWithTooltip } from '@atlassian/jira-polaris-lib-button-with-tooltip/src/ui/index.tsx';
import { useNotifications } from '@atlassian/jira-polaris-lib-notifications/src/controllers/index.tsx';
import { useIssueRemote } from '@atlassian/jira-polaris-remote-issue/src/main.tsx';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { isClientFetchError } from '@atlassian/jira-fetch/src/index.tsx';
import { isPermissionError } from '@atlassian/jira-polaris-lib-errors/src/controllers/utils.tsx';
import messages from './messages.tsx';

const defaultBrowserConfig = {
	multiple: false,
	uploadParams: {},
} as const;

type Props = {
	showLabel?: boolean;
};

export const AttachButton = ({ showLabel = false }: Props) => {
	const [isBrowserOpen, setIsBrowserOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [uploadContext, setUploadContext] = useState<MediaUploadPermissionResponse | undefined>(
		undefined,
	);
	const [autoHealAttempts, setAutoHealAttempts] = useState(0);
	const { error, errorWithRefresh } = useNotifications();
	const tokenRefreshTimer = useRef<ReturnType<typeof setTimeout> | undefined>();
	const [browserConfig, setBrowserConfig] = useState(defaultBrowserConfig);
	const { addAttachment, addAttachmentInUpload, abortAttachmentInUpload } = useIdeaActions();
	const issueKey = useSelectedIssueKey();
	const [canAddAttachments] = useCanAddAttachments();
	const accountId = useAccountId();

	const { addMediaToIssueAttachments } = useIssueRemote();

	const { formatMessage } = useIntl();

	useEffect(() => {
		clearTimeout(tokenRefreshTimer.current);
		setUploadContext(undefined);
	}, [issueKey]);

	const refreshUploadContext = useCallback(async () => {
		if (!issueKey) return;

		setIsLoading(true);
		try {
			const response = await getMediaUploadConfig(issueKey);
			setUploadContext(response);
			setBrowserConfig({
				...defaultBrowserConfig,
				uploadParams: {
					...defaultBrowserConfig.uploadParams,
					collection: response.targetCollection,
				},
			});

			// Clear any existing refresh timer
			if (tokenRefreshTimer.current !== undefined) {
				clearTimeout(tokenRefreshTimer.current);
			}

			// added 10s buffer to cover network calls
			const tokenExpiration = response.tokenDurationInMins * 60000 - 10000;
			tokenRefreshTimer.current = setTimeout(refreshUploadContext, tokenExpiration);
		} catch (e) {
			error({
				title: formatMessage(messages.failedToUploadCaption),
				description: formatMessage(messages.finalFailedToUploadMessage),
			});
		} finally {
			setIsLoading(false);
		}
	}, [issueKey, error, formatMessage]);

	const mediaClientConfig = useMemo<MediaClientConfig>(
		() => ({
			authProvider: () =>
				new Promise((resolve, reject) => {
					if (!uploadContext) {
						reject();
						return;
					}
					const { endpointUrl, clientId, token } = uploadContext;
					resolve({
						clientId,
						token,
						baseUrl: endpointUrl,
					});
				}),
		}),
		[uploadContext],
	);

	const refreshUploadContextOnAction = useCallback(async () => {
		// Fetch token if not already available
		if (!uploadContext && !isLoading) {
			await refreshUploadContext();
		}
	}, [uploadContext, isLoading, refreshUploadContext]);

	const openBrowser = useCallback(async () => {
		// Fetch token if not already available
		await refreshUploadContextOnAction();
		setIsBrowserOpen(true);
	}, [setIsBrowserOpen, refreshUploadContextOnAction]);

	const closeBrowser = useCallback(() => {
		setIsBrowserOpen(false);
	}, []);

	const onUploadStart = useCallback(
		({ files }: UploadsStartEventPayload) => {
			experience.ideaView.fileAttach.start();
			files.forEach(({ id }) => {
				addAttachmentInUpload(id);
			});
		},
		[addAttachmentInUpload],
	);

	const onUploadEnd = useCallback(
		({ file }: UploadEndEventPayload) => {
			if (issueKey !== undefined) {
				addMediaToIssueAttachments(issueKey, file.id)
					.then((attachmentId) => {
						addAttachment(attachmentId, file.id, mediaClientConfig, accountId);
					})
					.then(() => experience.ideaView.fileAttach.success());
			}
		},
		[addAttachment, issueKey, mediaClientConfig, accountId, addMediaToIssueAttachments],
	);

	const onError = useCallback(
		(err: UploadErrorEventPayload) => {
			if (
				isClientFetchError(err.error.rawError) ||
				(err.error.rawError && isPermissionError(err.error.rawError))
			) {
				experience.ideaView.fileAttach.abort(err.error.rawError);
			} else {
				experience.ideaView.fileAttach.failure(err.error.rawError);
			}
			abortAttachmentInUpload(err.fileId);
			// @ts-expect-error - Property 'reason' does not exist on type 'Error'.
			if (autoHealAttempts === 0 && err.error?.rawError?.reason === 'serverForbidden') {
				// token was not accepted, attempt to refresh it
				error({
					title: formatMessage(messages.failedToUploadCaption),
					description: formatMessage(messages.firstFailedToUploadMessage),
				});
				setAutoHealAttempts(autoHealAttempts + 1);
				refreshUploadContext();
			} else {
				errorWithRefresh({
					title: formatMessage(messages.failedToUploadCaption),
					description: formatMessage(messages.finalFailedToUploadMessage),
				});
			}
		},
		[
			autoHealAttempts,
			setAutoHealAttempts,
			refreshUploadContext,
			abortAttachmentInUpload,
			error,
			errorWithRefresh,
			formatMessage,
		],
	);

	if (!canAddAttachments) {
		return null;
	}

	return (
		<>
			<ButtonWithTooltip
				interactionName="jpd.idea-view.add-attachment"
				iconBefore={
					<AttachmentIcon spacing="spacious" label={formatMessage(messages.attachButtonCaption)} />
				}
				onMouseOver={refreshUploadContextOnAction}
				onClick={openBrowser}
				isDisabled={isLoading}
				tooltipProps={{
					content: showLabel ? undefined : formatMessage(messages.attachButtonCaption),
				}}
			>
				{showLabel && formatMessage(messages.attachButtonCaption)}
			</ButtonWithTooltip>
			{uploadContext !== undefined && (
				<Browser
					isOpen={isBrowserOpen}
					config={browserConfig}
					mediaClientConfig={mediaClientConfig}
					onEnd={onUploadEnd}
					onUploadsStart={onUploadStart}
					onClose={closeBrowser}
					onError={onError}
				/>
			)}
		</>
	);
};
