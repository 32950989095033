import React, { useCallback, useEffect } from 'react';
import Button, { LinkButton, type ButtonProps } from '@atlaskit/button/new';
import LinkExternal from '@atlaskit/icon/core/migration/link-external--shortcut';
import Link from '@atlaskit/link';
import { SpotlightTarget } from '@atlaskit/onboarding';
import { Box, Flex, xcss } from '@atlaskit/primitives';
import PageHeader from '@atlassian/jira-common-components-page-header/src/index.tsx';
import HelpPanelButton from '@atlassian/jira-help-panel-button/src/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useGlobalFieldsActions } from '@atlassian/jira-polaris-common/src/controllers/global-fields/main.tsx';
import { useRightSidebarActions } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/actions/hooks.tsx';
import { useCanManageGlobalFields } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { LinkPressable } from '@atlassian/jira-polaris-lib-link-pressable/src/ui/index.tsx';
import {
	PROJECT_FIELDS_TOUR_SPOTLIGHTS_STEP_1,
	PROJECT_FIELDS_TOUR_SPOTLIGHTS_STEP_2,
	PROJECT_FIELDS_TOUR_SPOTLIGHTS_STEP_5,
	PROJECT_FIELDS_TOUR_SPOTLIGHTS_STEP_LAST,
} from '@atlassian/jira-polaris-lib-onboarding-flows/src/controllers/constants.tsx';
import { useOnboardingFlowsActions } from '@atlassian/jira-polaris-lib-onboarding-flows/src/controllers/index.tsx';
import { useTrackProjectFieldsPageViewed } from '@atlassian/jira-polaris-lib-onboarding-flows/src/controllers/selectors/project-fields-hooks.tsx';
import { ProjectFieldsBenefitsModal } from '@atlassian/jira-polaris-lib-onboarding-flows/src/ui/project-fields/tour/benefits-modal/index.tsx';
import { ProjectFieldsSpotlightsTour } from '@atlassian/jira-polaris-lib-onboarding-flows/src/ui/project-fields/tour/spotlights/index.tsx';
import { type UIAnalyticsEvent, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { FieldsTableContainer } from '../../../../../controllers/fields-table/index.tsx';
import { BreadcrumbsProjectSettings } from '../../../breadcrumbs/index.tsx';
import { FieldsList } from './fields-list/index.tsx';
import messages from './messages.tsx';

const HELP_ARTICLE_ID = '4uANNCVcmNzvlm4WtcDL6G';
const HELP_FALLBACK_URL =
	'https://support.atlassian.com/jira-product-discovery/docs/manage-project-and-global-fields-from-your-project/';

export const ProjectFieldsScreen = () => {
	const { formatMessage } = useIntl();
	const canManageGlobalFields = useCanManageGlobalFields();
	const [, { openOnCreateForSettings, openOnGlobalFields }] = useRightSidebarActions();
	const { setProjectFieldsTourSpotlightStep } = useOnboardingFlowsActions();
	const { loadGlobalFields } = useGlobalFieldsActions();

	useTrackProjectFieldsPageViewed();

	useEffect(() => {
		loadGlobalFields();
	}, [loadGlobalFields]);

	const openFieldCreate = useCallback(
		(_event: React.MouseEvent, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'createField');

			openOnCreateForSettings();
		},
		[openOnCreateForSettings],
	);

	const openAddGlobalField = useCallback(
		(_event: React.MouseEvent, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'addGlobalField');

			openOnGlobalFields();
		},
		[openOnGlobalFields],
	);

	const startTour = useCallback<NonNullable<ButtonProps['onClick']>>(
		(_, analyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'startTour');
			setProjectFieldsTourSpotlightStep(PROJECT_FIELDS_TOUR_SPOTLIGHTS_STEP_1);
		},
		[setProjectFieldsTourSpotlightStep],
	);

	return (
		<Flex direction="column" xcss={pageStyles}>
			<Box xcss={headerWrapperStyles}>
				<PageHeader
					breadcrumbs={<BreadcrumbsProjectSettings />}
					actions={
						<Flex gap="space.100">
							{canManageGlobalFields && (
								<SpotlightTarget name={PROJECT_FIELDS_TOUR_SPOTLIGHTS_STEP_5}>
									<LinkButton
										href="/jira/settings/products/jira-product-discovery/global-fields"
										target="_blank"
										appearance="default"
										onClick={(_event, analyticsEvent) => {
											fireUIAnalytics(analyticsEvent, 'globalFields');
										}}
										iconAfter={LinkExternal}
									>
										{formatMessage(messages.globalFields)}
									</LinkButton>
								</SpotlightTarget>
							)}
							<SpotlightTarget name={PROJECT_FIELDS_TOUR_SPOTLIGHTS_STEP_2}>
								<Button
									interactionName="jpd.right-sidebar.global-fields"
									testId="polaris-ideas.ui.settings.fields.project-fields-screen.ui.add-global-field"
									appearance="default"
									onClick={openAddGlobalField}
								>
									{formatMessage(messages.addGlobalField)}
								</Button>
							</SpotlightTarget>
							<SpotlightTarget name={PROJECT_FIELDS_TOUR_SPOTLIGHTS_STEP_1}>
								<Button
									interactionName="jpd.right-sidebar.create-field"
									testId="polaris-ideas.ui.settings.fields.project-fields-screen.ui.create-field-button"
									appearance="primary"
									onClick={openFieldCreate}
								>
									{formatMessage(messages.createField)}
								</Button>
							</SpotlightTarget>
						</Flex>
					}
					bottomBar={
						<>
							{formatMessage(messages.pageDescription)}{' '}
							<HelpPanelButton
								articleId={HELP_ARTICLE_ID}
								appearance="link"
								spacing="none"
								fallbackComponent={
									<Box as="span" xcss={guideLinkStyles}>
										<Link href={HELP_FALLBACK_URL} rel="noopener noreferrer" target="_blank">
											{formatMessage(messages.learnMore)}
										</Link>
									</Box>
								}
							>
								{formatMessage(messages.learnMore)}
							</HelpPanelButton>
							{' • '}
							<SpotlightTarget name={PROJECT_FIELDS_TOUR_SPOTLIGHTS_STEP_LAST}>
								<LinkPressable onClick={startTour} label={formatMessage(messages.showFieldsTour)} />
							</SpotlightTarget>
						</>
					}
				>
					{formatMessage(messages.pageHeader)}
				</PageHeader>
			</Box>
			<FieldsTableContainer formatMessage={formatMessage}>
				<FieldsList />
			</FieldsTableContainer>
			<ProjectFieldsBenefitsModal />
			<ProjectFieldsSpotlightsTour canManageGlobalFields={canManageGlobalFields} />
		</Flex>
	);
};

const pageStyles = xcss({
	width: '1080px',
	height: '100%',
	margin: '0 auto',
	paddingBottom: 'space.050',
});

const headerWrapperStyles = xcss({
	marginBottom: 'space.100',
});

const guideLinkStyles = xcss({
	fontWeight: 'font.weight.medium',
});
