import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	isGlobalFieldNotAssociatedToProjectNonFinal: {
		id: 'polaris-common.fields.utils.is-global-field-not-associated-to-project-non-final',
		defaultMessage: 'This field is not associated with the project from this idea',
		description:
			'Tooltip content for a disabled cell (highlighted field in connections tab) for an issue from a different project that has a global field not associated with the project from that issue',
	},
	isConnectionNotCompatibleFieldNonFinal: {
		id: 'polaris-common.fields.utils.is-connection-not-compatible-field-non-final',
		defaultMessage: 'This field is not compatible with the connection',
		description:
			"Tooltip content for a disabled cell (highlighted field in connections tab) for an issue from a different project that doesn't have this field",
	},
	isUnsupportedWeightedScoreFormulaNonFinal: {
		id: 'polaris-common.fields.utils.is-unsupported-weighted-score-formula-non-final',
		defaultMessage: 'Weighted score formulas are not supported in this context',
		description:
			"Tooltip content for a disabled cell (highlighted field in connection tab) for an issue from a different project that don't support weighted score formulas",
	},
	isDeliveryFieldNonFinal: {
		id: 'polaris-common.fields.utils.is-delivery-field-non-final',
		defaultMessage: 'Delivery fields are not supported in this context',
		description:
			'Tooltip content for a disabled cell (highlighted field in connections tab) for an issue from a different project that has a delivery field',
	},
	fieldIsNotSupportedNonFinal: {
		id: 'polaris-common.fields.utils.field-is-not-supported-non-final',
		defaultMessage: 'This field is not available on the project from this idea',
		description:
			"Tooltip content for a disabled cell (highlighted field in connections tab) for an issue from a different project that doesn't have this field",
	},
});
