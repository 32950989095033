import React, { useCallback, useContext, useEffect, useMemo, useState, type Ref } from 'react';
import { styled } from '@compiled/react';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import UFOLoadHold from '@atlaskit/react-ufo/load-hold';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import MemoryObserver from '@atlassian/jira-memory-metrics/src/ui/index.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import {
	UNSAFE_DO_NOT_USE_TOP_NAVIGATION_HEIGHT,
	UNSAFE_DO_NOT_USE_BANNER_HEIGHT,
} from '@atlassian/jira-navigation-system/src/index.tsx';
import { PAGE_MARGIN_X } from '@atlassian/jira-polaris-common/src/common/constants.tsx';
import { useIsSharedView } from '@atlassian/jira-polaris-common/src/controllers/environment/index.tsx';
import { useIsSharedViewAuthenticationMissing } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/meta-hooks.tsx';
import { useCopyValuesTasks } from '@atlassian/jira-polaris-common/src/controllers/global-fields/selectors/global-fields-hooks.tsx';
import { useGlobalFieldsUpdateSync } from '@atlassian/jira-polaris-common/src/controllers/global-fields/utils/index.tsx';
import { useIsSingleIssueLoaded } from '@atlassian/jira-polaris-common/src/controllers/idea/selectors/hooks.tsx';
import { useIsInitialized } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/meta-hooks.tsx';
import { useSelectedIssueKey } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { useIsRightSidebarOpen } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/selectors/hooks.tsx';
import {
	useCurrentPolarisRoute,
	useIsIssueOpenInFullscreen,
	useIsIssueOpenInSidebar,
} from '@atlassian/jira-polaris-common/src/controllers/route/index.tsx';
import { useIsViewsLoading } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/meta-hooks.tsx';
import {
	useHasSharedViewConfigError,
	useCurrentViewKind,
	useViewAnalyticsData,
	useCurrentViewSlug,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { usePrioritizeViewCount } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view/view-sets/hooks.tsx';
import { ViewSectionTypeViews } from '@atlassian/jira-polaris-common/src/controllers/views/types.tsx';
import { PolarisAppsOverview } from '@atlassian/jira-polaris-common/src/ui/apps/index.tsx';
import { MergeIdeasModal } from '@atlassian/jira-polaris-common/src/ui/merge-ideas-dialog/index.tsx';
import { CopyValuesProgress } from '@atlassian/jira-polaris-component-copy-values/src/ui/copy-values-progress/index.tsx';
import { useIsPreviewContributorMode } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/main.tsx';
import {
	VIEW_KIND_BOARD,
	VIEW_KIND_TABLE,
	VIEW_KIND_TIMELINE,
} from '@atlassian/jira-polaris-domain-view/src/view/constants.tsx';
import { ContributorPreviewModeBanner } from '@atlassian/jira-polaris-ideas/src/ui/main-content/contributor-preview-mode-banner/index.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import IdeaView from '../idea-view/async/index.tsx';
import { SmartLinkIdeaView } from '../idea-view/smartlink-idea-view/index.tsx';
import { RightSidebar } from '../right-sidebar/main.tsx';
import { PolarisProjectSettings } from '../settings/index.tsx';
import { SharedViewSignupHero } from '../shared-view-signup-hero/index.tsx';
import { VIEW_CONTENT_CONTAINER_ID } from '../view-content/idea-board/utils/use-board-export.tsx';
import { ViewContent } from '../view-content/main.tsx';
import { ViewControls } from '../view-controls/index.tsx';
import { ViewHeader } from '../view-header/index.tsx';
import { PageLoadContext } from '../page-load-context/index.tsx';
import { IdeaViewSidebarCloseUFORenaming } from '../idea-view/ufo-data/index.tsx';
import { IssueLimitBanner } from './issue-limit-banner/index.tsx';
import { SubmitJpdProjectLoadedEvent } from './jpd-project-loaded-event/index.tsx';
import { NoViewsScreen } from './no-views-screen/index.tsx';
import { QuickstartViewVideoContainer } from './quick-start-view-video/index.tsx';
import { RealtimeEventsRefreshBanner } from './realtime-events-refresh-banner/index.tsx';
import { ViewUfoData } from './ufo-data/index.tsx';

export type MainContentProps = {
	fullscreen: boolean;
	embedded: boolean;
	section: string;
	viewControlsRef: Ref<HTMLDivElement>;
	onToggleFullscreen: () => void;
};

const IdeaViewWithSelectedIdea = ({ embedded }: { embedded: boolean }) => {
	const selectedIssueKey = useSelectedIssueKey();
	return embedded ? (
		<>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766 */}
			<style>{`
				[data-ds--page-layout--slot="main"] {
					height: auto;
				}

				body {
					overflow-y: auto;
				}

            `}</style>
			<SmartLinkIdeaView issueKey={selectedIssueKey} />
		</>
	) : (
		<Box xcss={ideaViewContainerStyles}>
			<IdeaView issueKey={selectedIssueKey} />
		</Box>
	);
};

const MainContent = ({
	fullscreen,
	embedded,
	section,
	viewControlsRef,
	onToggleFullscreen,
}: MainContentProps) => {
	const { resource } = useCurrentPolarisRoute();
	const [isViewContentReady, setIsViewContentReady] = useState(false);
	const isIssuesInitialized = useIsInitialized();
	const viewKind = useCurrentViewKind();
	const viewSlug = useCurrentViewSlug();
	const isSharedView = useIsSharedView();
	const isSharedViewAuthenticationMissing = useIsSharedViewAuthenticationMissing();
	const isSingleIssueLoaded = useIsSingleIssueLoaded();
	const hasSharedViewConfigError = useHasSharedViewConfigError();
	const isViewsLoading = useIsViewsLoading();
	const viewCount = usePrioritizeViewCount();
	const copyValuesTasks = useCopyValuesTasks();

	useGlobalFieldsUpdateSync();

	const onViewContentReady = useCallback(() => setIsViewContentReady(true), []);

	const viewAnalyticsData = useViewAnalyticsData();

	const isIssueViewSidebarOpen = useIsIssueOpenInSidebar();

	const isRightSidebarOpen = useIsRightSidebarOpen()[0] || isIssueViewSidebarOpen;

	const isIssueViewFullscreenOpen = useIsIssueOpenInFullscreen();

	const [isPreviewContributorMode] = useIsPreviewContributorMode();

	const isViewControlsHidden = isSharedView && hasSharedViewConfigError;

	const shouldDisplayPaddingRight = useCallback(() => {
		// This is for removing the whitespace between board and sidebar content. (see POL-3770) The padding condition should remain the same for all other use cases.
		if (viewKind === VIEW_KIND_BOARD && isRightSidebarOpen) {
			return false;
		}

		return viewKind !== VIEW_KIND_TABLE && viewKind !== VIEW_KIND_TIMELINE;
	}, [viewKind, isRightSidebarOpen]);

	const { isFirstMount } = useContext(PageLoadContext);

	useEffect(() => {
		if (!viewKind || !isViewContentReady) return;

		if (isFirstMount.current) {
			experience.project.pageLoad.success();
			experience.project.pageLoadWithRateLimit.success();
			isFirstMount.current = false;
		}
	}, [viewKind, isViewContentReady, isFirstMount]);

	useEffect(() => {
		if (!isIssueViewFullscreenOpen && section === ViewSectionTypeViews) return;
		onViewContentReady();
	}, [isIssueViewFullscreenOpen, section, onViewContentReady]);

	const attributes = useMemo(
		() => ({
			...viewAnalyticsData?.attributes,
			page: `polaris-project-${section}-${resource}`,
		}),
		[viewAnalyticsData?.attributes, section, resource],
	);

	return (
		<>
			<ContextualAnalyticsData attributes={attributes} containers={viewAnalyticsData?.containers}>
				<Box
					xcss={[
						mainContentContainerStyles,
						!getWillShowNav4() && fullscreen && mainContentContainerStylesFullscreen,
					]}
				>
					{isIssueViewFullscreenOpen && (
						<UFOSegment name="jpd.idea-view-fullscreen">
							<LeftContainer isHidden={!isIssueViewFullscreenOpen}>
								<IdeaViewWithSelectedIdea embedded={embedded} />
							</LeftContainer>
						</UFOSegment>
					)}
					<LeftContainer isHidden={isIssueViewFullscreenOpen}>
						{section === ViewSectionTypeViews && (
							<>
								{!isSharedView && fg('jpd-trace-ufo-transition') && (
									<ViewUfoData
										trigger={viewSlug}
										useInteractionRenaming={!isIssueViewFullscreenOpen}
									/>
								)}
								{!isViewsLoading && isIssuesInitialized && viewCount === 0 ? (
									<NoViewsScreen />
								) : (
									<>
										{isSharedView && isSharedViewAuthenticationMissing ? (
											<SharedViewSignupHero />
										) : (
											<>
												<IssueLimitBanner />
												<RealtimeEventsRefreshBanner />
												{!embedded && (
													<ViewControlsContainer
														ref={viewControlsRef}
														isFullWidth={fullscreen || isSharedView || getWillShowNav4()}
													>
														<UFOSegment name="jpd.view-header">
															<ViewHeader
																isFullscreen={fullscreen}
																onToggleFullscreen={onToggleFullscreen}
															/>
														</UFOSegment>
														{!isViewControlsHidden && (
															<UFOSegment name="jpd.view-controls">
																<ViewControls />
															</UFOSegment>
														)}
													</ViewControlsContainer>
												)}
												<UFOSegment name="jpd.view-content">
													<ViewContentContainer
														paddingRight={shouldDisplayPaddingRight()}
														// used by image export
														id={VIEW_CONTENT_CONTAINER_ID}
													>
														<ViewContent
															isHidden={isIssueViewFullscreenOpen}
															onReady={onViewContentReady}
														/>
														{fg('killswitch_jpd_roadmap_template_video_modal') && (
															<ErrorBoundary
																id="jira.polaris.quick-start-view-video"
																packageName="jira.polaris.quick-start-view-video"
																// eslint-disable-next-line @typescript-eslint/no-explicit-any
																onError={(error?: any) => {
																	fireErrorAnalytics({
																		meta: {
																			id: 'QuickstartViewVideoContainer',
																			packageName: 'jiraPolarisIdeas',

																			teamName: 'jpd-juno',
																		},
																		attributes: {
																			errorContext:
																				'Failed rendering quick start view video container',
																			errorMessage: error.message,
																			source: 'QuickstartViewVideoContainer',
																		},
																		error,
																	});
																}}
															>
																<QuickstartViewVideoContainer />
															</ErrorBoundary>
														)}
													</ViewContentContainer>
												</UFOSegment>
											</>
										)}
									</>
								)}
							</>
						)}
						{section === 'apps' && <PolarisAppsOverview />}
						{section === 'settings' && <PolarisProjectSettings />}
						<UFOSegment name="jpd.copy-values-progress">
							<CopyValuesProgress tasks={copyValuesTasks} />
						</UFOSegment>
					</LeftContainer>
					<UFOSegment name="jpd.merge-ideas-modal">
						<MergeIdeasModal />
					</UFOSegment>
					{isRightSidebarOpen && !embedded && (
						<UFOSegment name="jpd.right-sidebar">
							<RightSidebar isFullScreen={fullscreen} />
						</UFOSegment>
					)}
					{fg('jpd-trace-ufo-transition') && (
						<IdeaViewSidebarCloseUFORenaming
							isIssueViewSidebarOpen={isIssueViewSidebarOpen}
							isIssueViewFullscreenOpen={isIssueViewFullscreenOpen}
						/>
					)}
					{getWillShowNav4() && isPreviewContributorMode && <ContributorPreviewModeBanner />}
				</Box>
			</ContextualAnalyticsData>
			{isViewContentReady && (isIssuesInitialized || isSingleIssueLoaded) && (
				<SubmitJpdProjectLoadedEvent key="submit-jpd-project-loaded-event" />
			)}
			{isViewContentReady && isIssuesInitialized && <MemoryObserver />}
			{!isViewContentReady && fg('jpd-trace-ufo-transition') && (
				<UFOLoadHold name="jpd.view-content-readiness" />
			)}
		</>
	);
};

export default MainContent;

const mainContentContainerStyles = xcss({
	display: 'flex',
	width: '100%',
	height: '100%',
});

const mainContentContainerStylesFullscreen = xcss({
	position: 'absolute',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `calc(100vh - ${UNSAFE_DO_NOT_USE_BANNER_HEIGHT})`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	top: `calc(-1 * ${UNSAFE_DO_NOT_USE_TOP_NAVIGATION_HEIGHT})`,
	zIndex: 'navigation',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ViewControlsContainer = styled.div<{ isFullWidth: boolean }>({
	display: 'flex',
	flexDirection: 'column',
	paddingTop: token('space.050'),
	paddingRight: 0,
	paddingBottom: 0,
	paddingLeft: 0,
	boxSizing: 'border-box',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: ({ isFullWidth }) => (isFullWidth ? '100%' : 'calc(100vw - var(--leftSidebarWidth, 0px))'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ViewContentContainer = styled.div<{ paddingRight: boolean }>({
	display: 'flex',
	flex: '1 1 auto',
	minHeight: 0,
	boxSizing: 'border-box',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	paddingLeft: `${PAGE_MARGIN_X}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	paddingRight: ({ paddingRight }) => paddingRight && `${PAGE_MARGIN_X}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LeftContainer = styled.div<{ isHidden: boolean }>({
	flex: '1 0 0px',
	flexDirection: 'column',
	height: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	display: ({ isHidden }) => (isHidden ? 'none' : 'flex'),
	maxWidth: '100%',
	minWidth: 0, // POL-5375 Fixes flex behavior
	position: 'relative',
});

const ideaViewContainerStyles = xcss({
	width: '100%',
	height: '100%',
	maxWidth: '1600px',
	alignSelf: 'center',
});
