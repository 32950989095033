import type { LocalViewId } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types.tsx';
import { withRefreshBlocker } from '../refresh-blocker/index.tsx';
import { findView, modifyView } from '../utils.tsx';

export const deleteEmoji = (localId: LocalViewId): Action<State, Props> =>
	withRefreshBlocker(
		async (
			{ setState, getState },
			{ navigationRemote, cloudId, projectId, onSuccess, onFailure },
		) => {
			if (
				!cloudId ||
				!projectId ||
				!navigationRemote ||
				getState().projects[projectId]?.isLoading ||
				!getState().projects[projectId]?.initialized
			) {
				return;
			}

			const { view } = findView(getState().projects[projectId], localId);

			if (!view || !view.viewType || view.viewType === 'TWOXTWO') {
				return;
			}

			setState(
				modifyView(getState(), projectId, (innerView) => {
					if (innerView.localId === localId) {
						return {
							...innerView,
							emoji: undefined,
							isLocked: true,
						};
					}
					return innerView;
				}),
			);

			try {
				await navigationRemote.updateView({
					id: view.id,
					viewType: view.viewType,
					emoji: '',
				});

				setState(
					modifyView(getState(), projectId, (innerView) => {
						if (innerView.localId === localId) {
							return {
								...innerView,
								isLocked: false,
							};
						}
						return innerView;
					}),
				);

				onSuccess?.('removeEmoji');
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				setState(
					modifyView(getState(), projectId, (innerView) => {
						if (innerView.localId === localId) {
							return {
								...innerView,
								isLocked: false,
							};
						}
						return innerView;
					}),
				);

				onFailure?.(error, 'removeEmoji');
			}
		},
	);
