import React, { useCallback, type ReactNode } from 'react';
import Lozenge from '@atlaskit/lozenge';
import { useIntl } from '@atlassian/jira-intl';
import type { ClonedIssue } from '@atlassian/jira-polaris-common/src/controllers/issue/actions/clone-issue/index.tsx';
import {
	getEntityLimitError,
	getErrorMessageDescriptor,
} from '@atlassian/jira-polaris-lib-errors/src/common/utils/entity-limit-error/index.tsx';
import { useNotifications } from '@atlassian/jira-polaris-lib-notifications/src/controllers/index.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { messages } from './messages.tsx';

export const useCloneNotifications = () => {
	const { success, error, showFlag } = useNotifications();
	const { formatMessage } = useIntl();

	const onStart = useCallback(
		(issueKey: IssueKey) =>
			showFlag({
				messageId:
					'polaris-ideas.ui.idea-view.collaboration-controls.clone.notifications.show-flag.info',
				messageType: 'transactional',
				type: 'info',
				isAutoDismiss: true,
				title: [messages.cloneStartTitle, { issueKey }],
				description: messages.cloneStartDescription,
			}),
		[showFlag],
	);

	const onSuccess = useCallback(
		(targetIssue: ClonedIssue) => {
			const issueUrl = `/browse/${targetIssue.key}`;
			success({
				title: formatMessage(messages.notificationTitle),
				description: (
					<>
						{/* eslint-disable-next-line @atlaskit/design-system/use-primitives-text */}
						<p>{formatMessage(messages.notificationDescription)}</p>
						<a href={issueUrl} target="_blank">
							{targetIssue.summary}
						</a>
					</>
				),
				actions: [],
			});
		},
		[formatMessage, success],
	);

	const onFailure = useCallback(
		(err: Error) => {
			const entityLimitError = getEntityLimitError(err);
			const errorMessage = entityLimitError && getErrorMessageDescriptor(entityLimitError);

			error({
				title: formatMessage(messages.notificationErrorTitle),
				description: formatMessage(errorMessage ?? messages.notificationErrorDescription, {
					item: (chunks: ReactNode[]) => <Lozenge>{chunks}</Lozenge>,
				}),
			});
		},
		[error, formatMessage],
	);

	return { onSuccess, onFailure, onStart };
};
