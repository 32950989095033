import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	addButtonLabel: {
		id: 'polaris-common.fields.connection.editable-single-select.control.add-button-label',
		defaultMessage: 'Add',
		description: 'Button label for adding issues',
	},
	maxLengthError: {
		id: 'polaris-common.fields.connection.editable-single-select.control.max-length-error',
		defaultMessage: "Summary can't exceed 255 characters.",
		description: 'Error message for creatable select when value is too long',
	},
});
