// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import { getContributorPermissions } from '../../../services/jira/get-contributor-permissions/index.tsx';
import type { State, Props } from '../../types.tsx';

export const loadContributorPermissionsList =
	(): Action<State, Props> =>
	({ setState }, { onError }) => {
		getContributorPermissions()
			.then((response) => {
				setState({
					contributorPermissions: response.permissions.map(({ key }) => key),
				});
			})
			.catch((err) => onError(err));
	};
