// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	createActionsHook,
	createStateHook,
	createStore,
	type Selector,
} from '@atlassian/react-sweet-state';
import { setIsExportingViewImage, setIsReadyToExportViewImage } from './actions.tsx';
import type { State } from './types.tsx';

const actions = {
	setIsExportingViewImage,
	setIsReadyToExportViewImage,
};

const initialState: State = {
	isExportingViewImage: false,
	isReadyToExportViewImage: false,
};

const Store = createStore({
	initialState,
	actions,
	name: 'PolarisViewExportStore',
});

export const createViewExportStateHook = <T,>(selector: Selector<State, void, T>) =>
	createStateHook<State, typeof actions, T>(Store, { selector });

export const useViewExportActions = createActionsHook(Store);
