// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createContainer, createHook, createStore } from '@atlassian/react-sweet-state';
import actions from './actions/index.tsx';
import type { Props, State } from './types.tsx';

type Actions = typeof actions;

const initialState: State = Object.freeze({
	isLoading: false,
	error: null,
	config: null,
	properties: null,
	snippetData: null,
	url: null,
	oauthClientId: null,
	isSaving: false,
	onConfigChanged: null,
	saveError: null,
});

export const ConfigurePropertiesDialogStore = createStore<State, Actions>({
	initialState,
	actions,
	name: 'ConfigurePropertiesDialogStore',
});

export const useConfigurePropertiesDialog = createHook(ConfigurePropertiesDialogStore);

export const useConfigurePropertiesDialogState = (): State => {
	const [state] = useConfigurePropertiesDialog();
	return state;
};

const useConfigurePropertiesDialogActionsHook = createHook(ConfigurePropertiesDialogStore, {
	selector: null,
});

export const useConfigurePropertiesDialogActions = () => {
	const [, stateActions] = useConfigurePropertiesDialogActionsHook();
	return stateActions;
};

export const useConfigurePropertiesDialogProps = () => {
	const { getContainerProps } = useConfigurePropertiesDialogActions();
	return getContainerProps();
};

export const ConfigurePropertiesDialogContainer = createContainer<State, Actions, Props>(
	ConfigurePropertiesDialogStore,
);
