import { useMemo } from 'react';
import type { FieldsByKey } from '@atlassian/jira-polaris-domain-field/src/collections/types.tsx';
import type { FieldType } from '@atlassian/jira-polaris-domain-field/src/field-types/types.tsx';
import { isGlobalSystemField } from '@atlassian/jira-polaris-domain-field/src/field/utils.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useFields } from '../../views/selectors/fields-hooks.tsx';
import { createHook, createHigherLevelHook } from '../main.tsx';
import {
	getCopyValuesTasks,
	getAreGlobalFieldsLoading,
	getGlobalField,
	getGlobalFieldData,
	getGlobalFieldFormula,
	getGlobalFields,
	getIsGlobalFieldsLoadingFailed,
} from './global-fields.tsx';

const useGlobalFields = createHook(getGlobalFields);

export const useAvailableGlobalFields = () => {
	const globalFields = useGlobalFields();
	const projectFields = useFields();

	return useMemo(
		() =>
			globalFields.filter((globalField) => {
				if (fg('jpd-filter-system-fields-for-all-fields')) {
					if (isGlobalSystemField(globalField)) {
						return false;
					}
				}

				const isInUse = projectFields[globalField.key] !== undefined;
				const isContextRestricted = globalField.hasRestrictedContext;
				return !isInUse && !isContextRestricted;
			}),
		[globalFields, projectFields],
	);
};

/* Contains global fields that can be added to the project */
export const useAvailableGlobalFieldsByKey = () => {
	const availableGlobalFields = useAvailableGlobalFields();

	return useMemo(() => {
		const fields: FieldsByKey = availableGlobalFields.reduce(
			(result, field) =>
				Object.assign(result, {
					[field.key]: field,
				}),
			{},
		);
		return fields;
	}, [availableGlobalFields]);
};

export const useGlobalFieldKeysOfType = (type: FieldType) => {
	const globalFields = useGlobalFields();

	return useMemo(() => {
		const keys = globalFields
			.filter((globalField) => globalField.type === type)
			.map((globalField) => globalField.key);

		return new Set(keys);
	}, [globalFields, type]);
};

export const useGlobalField = createHigherLevelHook(getGlobalField);
export const useGlobalFieldData = createHigherLevelHook(getGlobalFieldData);

export const useAreGlobalFieldsLoading = createHook(getAreGlobalFieldsLoading);
export const useIsGlobalFieldsLoadingFailed = createHook(getIsGlobalFieldsLoadingFailed);

export const useAreAllGlobalFieldsAdded = () => {
	const globalFields = useGlobalFields();
	const availableGlobalFields = useAvailableGlobalFields();

	return availableGlobalFields.length === 0 && globalFields.length > 0;
};

export const useGlobalFieldFormula = createHigherLevelHook(getGlobalFieldFormula);

export const useCopyValuesTasks = createHook(getCopyValuesTasks);
