import type { ThemeProps } from '@atlaskit/button/types';
import { token } from '@atlaskit/tokens';

export const pickerButtonStyle =
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	(isSelected: boolean) => (current: (themeProps: ThemeProps) => any, themeProps: ThemeProps) => {
		const theme = current(themeProps);
		return {
			...theme,
			buttonStyles: {
				...theme.buttonStyles,
				backgroundColor: isSelected && token('color.background.selected'),
				color: isSelected && token('color.text.selected'),
			},
		};
	};
