import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	sectionHeading: {
		id: 'polaris-ideas.idea-view.sections.deliver.section-heading',
		defaultMessage: 'Delivery tickets',
		description: 'Caption for component that shows all linked delivery tickets for this idea.',
	},
	sectionHeadingIssueTermRefresh: {
		id: 'polaris-ideas.idea-view.sections.deliver.section-heading-issue-term-refresh',
		defaultMessage: 'Delivery work items',
		description: 'Caption for component that shows all linked delivery work items for this idea.',
	},
});
