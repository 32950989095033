// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	type StoreActionApi,
	createContainer,
	createHook,
	createStore,
} from '@atlassian/react-sweet-state';
import actions from './actions/index.tsx';
import type { Props, InvokeProps, State } from './types.tsx';

type Actions = typeof actions;

const initialState: State = {
	isResolving: false,
	tryAgain: false,
	auth: undefined,
	error: undefined,
	authToken: undefined,
	resolvedObject: undefined,
	apiKey: undefined,
};

export const UnfurlStore = createStore<State, Actions>({
	initialState,
	actions,
	name: 'PolarisUnfurlControllerStore',
});

export const useUnfurl = createHook(UnfurlStore);

export const useUnfurlState = (): State => {
	const [state] = useUnfurl();
	return state;
};

const useUnfurlActionsHook = createHook(UnfurlStore, {
	selector: null,
});

export const useUnfurlActions = () => {
	const [, stateActions] = useUnfurlActionsHook();
	return stateActions;
};

export const useUnfurlProps = () => {
	const { getContainerProps } = useUnfurlActions();
	return getContainerProps();
};

const handlePropsChange =
	() =>
	({ setState, dispatch }: StoreActionApi<State>, { url, externalErrorType }: Props) => {
		if (url === undefined) {
			// url has been set to undefined, clear the state
			setState({
				...initialState,
			});
		}

		// when the consumer knows this will be an authentication error, prevent automatic unfurls
		// on mount in favour of a user action
		if (externalErrorType !== 'NEED_AUTH') {
			dispatch(actions.unfurl());
		}
	};

export const UnfurlContainer = createContainer<State, Actions, Props>(UnfurlStore, {
	onUpdate: handlePropsChange,
});

export const InvokeContainer = createContainer<State, Actions, Props & InvokeProps>(UnfurlStore, {
	onInit: handlePropsChange,
	onUpdate: handlePropsChange,
});
