import { useCallback } from 'react';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { getEntityLimitError } from '@atlassian/jira-polaris-lib-errors/src/common/utils/entity-limit-error/index.tsx';
import {
	createErrorAnalytics,
	useErrorHandlers,
} from '@atlassian/jira-polaris-lib-errors/src/controllers/index.tsx';
import type { IssueError } from '@atlassian/jira-polaris-lib-errors/src/types.tsx';
import { useNotifications } from '@atlassian/jira-polaris-lib-notifications/src/controllers/index.tsx';
import { ValidationError } from '@atlassian/jira-fetch/src/index.tsx';
import { useAllIssueTypesById } from '@atlassian/jira-polaris-component-issue-types/src/controllers/index.tsx';
import type { IssueTypeId } from '@atlassian/jira-shared-types/src/general.tsx';
import { functionWithCondition } from '@atlassian/jira-feature-flagging-utils';
import messages from './messages.tsx';

const useAllIssueTypesByIdHook = functionWithCondition<typeof useAllIssueTypesById>(
	() => fg('jpd_issue_types_ga'),
	useAllIssueTypesById,
	() => ({}),
);

export const useIssueCreationFailedHandling = () => {
	const { formatMessage } = useIntl();
	const { errorWithRefresh } = useNotifications();
	const allIssueTypesById = useAllIssueTypesByIdHook();

	const onErrorOld = useCallback(
		(errorObject: Error) => {
			errorWithRefresh({
				title: formatMessage(messages.ideaCreationFailedErrorTitle),
				description:
					errorObject?.message ||
					formatMessage(
						fg('polaris-issue-terminology-refresh')
							? messages.issueCreationFailedDescriptionIssueTermRefresh
							: messages.issueCreationFailedDescription,
					),
			});
			fireErrorAnalytics(createErrorAnalytics('polaris.error.issueCreationFailed', errorObject));
		},
		[errorWithRefresh, formatMessage],
	);

	const onError = useCallback(
		(errorObject: Error, issueTypeId?: IssueTypeId) => {
			const issueTypeName = issueTypeId
				? allIssueTypesById[issueTypeId]?.name.toLocaleLowerCase()
				: undefined;

			errorWithRefresh({
				title: issueTypeName
					? formatMessage(messages.issueCreationFailedErrorTitle, { issueTypeName })
					: formatMessage(messages.errorTitle),
				description:
					errorObject?.message || formatMessage(messages.issueCreationFailedDescriptionGeneral),
			});
			fireErrorAnalytics(createErrorAnalytics('polaris.error.issueCreationFailed', errorObject));
		},
		[errorWithRefresh, formatMessage, allIssueTypesById],
	);

	return fg('jpd_issue_types_ga') ? onError : onErrorOld;
};

export const useIssueUpdateFailedHandling = () => {
	const { formatMessage } = useIntl();
	const { errorWithRefresh } = useNotifications();
	return useCallback(
		(errorObject: IssueError) => {
			// if we have dedicated errors, let's show it (the first error from the list) to users in the error flag
			if (errorObject.statusCode && errorObject.errors?.length) {
				const firstError = errorObject.errors[0];

				errorWithRefresh({
					title: formatMessage(messages.errorTitle),
					description: firstError.error,
				});

				return;
			}

			let description = formatMessage(
				fg('polaris-issue-terminology-refresh')
					? messages.issueUpdateFailedDescriptionIssueTermRefresh
					: messages.issueUpdateFailedDescription,
			);

			if (fg('jpd_issue_types_ga')) {
				description = formatMessage(messages.issueUpdateFailedDescriptionGeneral);
			}

			errorWithRefresh({
				title: formatMessage(messages.errorTitle),
				description,
			});
			fireErrorAnalytics(createErrorAnalytics('polaris.error.issueCreationFailed', errorObject));
		},
		[errorWithRefresh, formatMessage],
	);
};

export const useIssueLoadingFailedHandling = () => {
	const { formatMessage } = useIntl();
	const { errorWithRefresh } = useNotifications();
	return useCallback(
		(errorObject: Error) => {
			let description = formatMessage(
				fg('polaris-issue-terminology-refresh')
					? messages.issueLoadingFailedDescriptionIssueTermRefresh
					: messages.issueLoadingFailedDescription,
			);

			if (fg('jpd_issue_types_ga')) {
				description = formatMessage(messages.issueLoadingFailedDescriptionGeneral);
			}

			errorWithRefresh({
				title: formatMessage(messages.errorTitle),
				description,
			});
			fireErrorAnalytics(createErrorAnalytics('polaris.error.issueLoadingFailed', errorObject));
		},
		[errorWithRefresh, formatMessage],
	);
};

export const useIssueActionFailedHandling = () => {
	const { generalActionFailedError, generalDataUpdateFailedError } = useErrorHandlers();
	return useCallback(
		(errorObject: Error) => {
			// check if we have entity limit error
			const entityLimitError = getEntityLimitError(errorObject);
			if (entityLimitError) {
				generalDataUpdateFailedError(entityLimitError);
				return;
			}

			// fire general action error otherwise
			generalActionFailedError(errorObject);
		},
		[generalActionFailedError, generalDataUpdateFailedError],
	);
};

export const useIssueStatusUpdateFailedHandling = () => {
	const { formatMessage } = useIntl();
	const { showFlag, error } = useNotifications();

	return useCallback(
		(errorObject: Error) => {
			if (errorObject instanceof ValidationError) {
				showFlag({
					messageId: 'polaris-common.controllers.issue.error.show-flag.warning',
					messageType: 'transactional',
					title: formatMessage(messages.transitionFailed),
					description: errorObject.message,
					type: 'warning',
					isAutoDismiss: true,
				});

				return;
			}

			let description = '';
			if (fg('jpd_issue_types_ga')) {
				description = formatMessage(messages.issueUpdateFailedDescriptionGeneral);
			} else if (fg('polaris-issue-terminology-refresh')) {
				description = formatMessage(messages.issueUpdateFailedDescriptionIssueTermRefresh);
			} else {
				description = formatMessage(messages.issueUpdateFailedDescription);
			}

			error({
				title: formatMessage(messages.errorTitle),
				description,
			});
		},
		[showFlag, error, formatMessage],
	);
};
