import React from 'react';
import { styled } from '@compiled/react';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';

type SkeletonProps = {
	avatar: boolean;
	marginBottom?: 'noMargin' | 'mdMargin' | 'threeTimesGridSize';
	marginTop?: 'threeTimesGridSize';
};

export const SkeletonHeading = () => (
	<>
		<Box xcss={marginBottomMap.mdMargin}>
			<SkeletonHeaderContainer>
				<SkeletonItem
					height={gridSize * 1.25}
					width={gridSize * 6.5}
					data-testid="spa-apps-common.skeletons.small-heading"
				/>
			</SkeletonHeaderContainer>
		</Box>
		<Box xcss={marginBottomMap.noMargin}>
			<SkeletonHeaderContainer>
				<SkeletonItem
					height={gridSize * 3}
					width={gridSize * 24}
					data-testid="spa-apps-common.skeletons.large-heading"
				/>
			</SkeletonHeaderContainer>
		</Box>
	</>
);

export const SkeletonFilter = ({ avatar }: SkeletonProps) => (
	<Box xcss={[marginTopMap.threeTimesGridSize, marginBottomMap.threeTimesGridSize]}>
		<SkeletonHeaderContainer>
			<Box xcss={baseStyleMarginRightStyles}>
				<SkeletonItem
					height={5 * gridSize}
					width={15 * gridSize}
					data-testid="spa-apps-common.skeletons.filter"
				/>
			</Box>
			{avatar && <SkeletonAvatarList />}
		</SkeletonHeaderContainer>
	</Box>
);

export const SkeletonAvatarList = () => (
	<SkeletonContainer data-testid="spa-apps-common.skeletons.avatar-list">
		<SkeletonAvatar />
		<SkeletonAvatar />
		<SkeletonAvatar />
		<SkeletonAvatar />
		<SkeletonAvatar />
	</SkeletonContainer>
);

const baseStyleMarginRightStyles = xcss({
	marginRight: 'space.200',
});

const marginBottomMap = {
	noMargin: xcss({ marginBottom: 'space.0' }),
	mdMargin: xcss({ marginBottom: 'space.150' }),
	threeTimesGridSize: xcss({ marginBottom: 'space.300' }),
};

const marginTopMap = {
	threeTimesGridSize: xcss({ marginTop: 'space.300' }),
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SkeletonItem = styled.div<{
	height: string | number;
	width: string | number;
}>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.skeleton'),
	// eslint-disable-next-line @typescript-eslint/no-explicit-any, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: `${(props: any) => props.height}px`,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: `${(props: any) => props.width}px`,
	borderRadius: token('space.050'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SkeletonHeaderContainer = styled.div({
	display: 'flex',
	flexDirection: 'row',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SkeletonContainer = styled.div({
	display: 'flex',
	flexDirection: 'row',
	marginTop: token('space.050'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SkeletonAvatar = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.skeleton'),
	height: token('space.400'),
	width: token('space.400'),
	borderRadius: token('space.200'),
	marginLeft: token('space.negative.025'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:nth-child(1)': {
		marginLeft: token('space.0'),
	},
});
