import isEmpty from 'lodash/isEmpty';
import join from 'lodash/join';
import { fg } from '@atlassian/jira-feature-gating';
import type { JiraSearchApiIssue } from '../types.tsx';
import { bulkFetchIssues } from '../bulk-fetch-issues/index.tsx';
import { fetchLinkedIssuesStoryPointField } from './utils.tsx';

export const getLinkedIssuesStoryPoints = async (
	issueKeys: string[],
	storyPointsCustomFieldId?: string,
): Promise<JiraSearchApiIssue[]> => {
	if (fg('polaris-bulk-fetch')) {
		return bulkFetchIssues(issueKeys, storyPointsCustomFieldId ? [storyPointsCustomFieldId] : []);
	}
	if (isEmpty(issueKeys)) {
		return Promise.resolve([]);
	}
	const jql = `key IN (${join(issueKeys, ',')})`;
	return fetchLinkedIssuesStoryPointField(jql, storyPointsCustomFieldId);
};
