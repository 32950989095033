// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import fetchConnectApps from '../../../services/connect-apps/connect-apps.tsx';
import type { State } from '../types.tsx';

type ContainerProps = {
	onError?: (isFirstError: boolean) => void;
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (projectKey: string): Action<State, ContainerProps> =>
	async ({ setState, getState }, { onError }) => {
		const currentState = getState();
		if (!projectKey || currentState.loading) {
			return;
		}

		setState({
			loading: true,
			error: null,
		});

		try {
			const data = await fetchConnectApps(projectKey);

			setState({
				data,
				loading: false,
				error: null,
			});
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (err: any) {
			const { hasFirstError, data } = getState();
			// It's the first error if it does not already have a first error and there is no data currently in state
			const isFirstError = !hasFirstError && data === null;
			setState({
				error: err,
				loading: false,
				hasFirstError: hasFirstError || isFirstError,
			});

			if (onError) {
				onError(isFirstError);
			}
		}
	};
