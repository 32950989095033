import React from 'react';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useViewActions } from '../../../../controllers/views/main.tsx';
import { useFieldFilter } from '../../../../controllers/views/selectors/filters-hooks.tsx';
import { MultiOptionComponent } from '../multi-option-component/index.tsx';
import type { FilterComponentProps } from '../types.tsx';
import { FilterWrapper } from '../filter-wrapper/filter-wrapper.tsx';
import { useFilterOptions } from '../hooks/use-filter-options.tsx';

export const MultiOptionFilter = ({
	field,
	isLastFilter,
	defaultOpen,
	isDisabled = false,
	isUsingCustomColor = false,
	onChange,
	onClear,
}: FilterComponentProps) => {
	const filterOptions = useFilterOptions(field);
	const fieldFilter = useFieldFilter(field.key);

	const { updateFieldFilter, clearFieldFilter } = useViewActions();

	const { createAnalyticsEvent } = useAnalyticsEvents();

	return (
		<FilterWrapper>
			<MultiOptionComponent
				field={field}
				filter={fieldFilter}
				isLastFilter={isLastFilter}
				defaultOpen={defaultOpen}
				setFieldFilter={(values) => {
					fireUIAnalytics(createAnalyticsEvent({}), 'multiOptionFilter updated', 'view-controls', {
						fieldKey: field.key,
						fieldType: field.type,
					});

					if (values.length) {
						onChange?.();
						experience.headerView.viewFilter.start();
						updateFieldFilter(
							{
								...fieldFilter,
								values: values.map((stringValue) => ({ stringValue })),
							},
							() => {
								experience.headerView.viewFilter.success();
							},
							(error?: Error) => {
								experience.headerView.viewFilter.failure(error);
							},
						);
					} else {
						onClear?.();
						experience.headerView.viewFilter.start();
						clearFieldFilter(
							fieldFilter.field,
							() => {
								experience.headerView.viewFilter.success();
							},
							(error?: Error) => {
								experience.headerView.viewFilter.failure(error);
							},
						);
					}
				}}
				options={filterOptions}
				isDisabled={isDisabled}
				isUsingCustomColor={isUsingCustomColor}
			/>
		</FilterWrapper>
	);
};
