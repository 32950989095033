import React from 'react';
import { useEmoji } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { GENERIC_FIELD_FILTER } from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import { useFilterOptions } from '../../hooks/use-filter-options.tsx';
import {
	useFieldTypeIcon,
	useFieldEmoji,
} from '../../../../../controllers/field/selectors/field-hooks.tsx';
import { useOptionsWithLabels, VariableOptionRenderer } from '../../options/index.tsx';
import { FilterButtonLabel } from '../../select/filter-button-label.tsx';
import { useFieldFilter } from '../../../../../controllers/views/selectors/filters-hooks.tsx';

type Props = {
	field: Field;
};

const STABLE_EMPTY_ARRAY: string[] = [];

export const SelectionAwareFilterButtonLabel = ({ field }: Props) => {
	const filterOptions = useFilterOptions(field);
	const optionsWithLabels = useOptionsWithLabels(field, filterOptions);
	const options = optionsWithLabels.map((option) => ({
		label: option.label,
		id: option.groupIdentity,
		OptionRenderComponent: () => (
			<VariableOptionRenderer
				field={field}
				groupIdentity={option.groupIdentity}
				value={option.value}
			/>
		),
	}));

	const fieldTypeIcon = useFieldTypeIcon(field.key, undefined);
	const emojiId = useFieldEmoji(field.key);
	const emoji = useEmoji(emojiId);

	const filter = useFieldFilter(field.key);

	const selected =
		filter.type === GENERIC_FIELD_FILTER
			? filter.values.map(({ stringValue }) => stringValue)
			: STABLE_EMPTY_ARRAY;

	return (
		<FilterButtonLabel
			selected={selected}
			options={options}
			label={field.label}
			fieldType={field.type}
			fieldTypeIcon={fieldTypeIcon}
			emoji={emoji}
		/>
	);
};
