import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { fg } from '@atlassian/jira-feature-gating';

const getPropertyUrl = (projectIdOrKey: string | number, propertyKey: string): string =>
	`/rest/api/3/project/${projectIdOrKey}/properties/${propertyKey}${fg('jpd-trace-fetch-network-call') ? `?operation=getProjectProperty_${propertyKey}` : ''}`;

/**
 * @deprecated
 * Use `getPropertyValue` from project-properties package instead.
 */
export const getPropertyValue = (
	projectIdOrKey: string | number,
	propertyKey: string, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> =>
	fetchJson(getPropertyUrl(projectIdOrKey, propertyKey)).then((response) => {
		try {
			return JSON.parse(response.value);
		} catch {
			// This needs to be here to handle string typed properties. even though they are
			// set below as JSON.stringify(value), they are just strings in this response.
			// Therefore JSON.parse fails for them.
			if (typeof response.value === 'string') {
				return response.value;
			}
			return null;
		}
	});

/**
 * @deprecated
 * Use `setPropertyValue` from project-properties package instead.
 */
export const setPropertyValue = (
	projectIdOrKey: string | number,
	propertyKey: string,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	value: any,
): Promise<void> =>
	performPutRequest(getPropertyUrl(projectIdOrKey, propertyKey), {
		body: JSON.stringify(value),
	});
