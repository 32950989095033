import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

export const NoDataHint = () => {
	const { formatMessage } = useIntl();

	return (
		<NoDataHintDescription>
			{formatMessage(
				fg('polaris-issue-terminology-refresh')
					? messages.hasNoDataDescriptionIssueTermRefresh
					: messages.hasNoDataDescription,
			)}
		</NoDataHintDescription>
	);
};

// eslint-disable-next-line @atlaskit/design-system/use-primitives, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const NoDataHintDescription = styled.div({
	paddingTop: token('space.150'),
	paddingRight: token('space.100'),
	paddingBottom: token('space.150'),
	paddingLeft: token('space.100'),
});
