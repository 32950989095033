import { useCallback } from 'react';
import { useFieldType } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks.tsx';
import { useSortingAwareIssueRankingActions } from '@atlassian/jira-polaris-common/src/controllers/idea-ranking/index.tsx';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import type { Field, FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import type {
	OnCardDropChange,
	OnCardDropClear,
	OnCardDropStart,
	OnColumnDrop,
	OnRowDrop,
} from '@atlassian/jira-polaris-lib-board/src/types/ui.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { EMPTY_VALUE_ID } from '../../../../common/utils/board.tsx';

/**
 * handler for dnd behaviour of cards
 */
export const useCardDropHandler = (fieldKey: FieldKey, verticalFieldKey?: FieldKey) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const { updateField, execWithIssueAnalyticsData } = useIssueActions();
	const { rankBefore, rankAfter } = useSortingAwareIssueRankingActions();

	const fieldType = useFieldType(fieldKey);

	const onCardDropStart: OnCardDropStart = useCallback(
		(issueId: string) => {
			execWithIssueAnalyticsData(issueId, (issueAnalyticsData) => {
				fireCompoundAnalyticsEvent.BoardView.cardDropped(
					createAnalyticsEvent({}),
					issueAnalyticsData,
				);
			});
		},
		[createAnalyticsEvent, execWithIssueAnalyticsData],
	);

	const onCardDropClear: OnCardDropClear = useCallback(
		({ issueId, sourceValue }: { issueId: string; sourceValue: unknown }) => {
			updateField(fieldKey, false, issueId, undefined, sourceValue);
			fireCompoundAnalyticsEvent.BoardView.fieldValueUpdatedByDragBetweenColumns(fieldType);
		},
		[fieldKey, fieldType, updateField],
	);

	const onCardDropChange: OnCardDropChange = useCallback(
		({
			issueId,
			targetValue,
			sourceValue,
		}: {
			issueId: string;
			targetValue: unknown;
			sourceValue: unknown;
		}) => {
			updateField(fieldKey, true, issueId, targetValue, sourceValue);
			fireCompoundAnalyticsEvent.BoardView.fieldValueUpdatedByDragBetweenColumns(fieldType);
		},
		[fieldKey, fieldType, updateField],
	);

	const onCardDropVerticalClear: OnCardDropClear = useCallback(
		({ issueId, sourceValue }: { issueId: string; sourceValue: unknown }) => {
			if (verticalFieldKey) {
				updateField(verticalFieldKey, false, issueId, undefined, sourceValue);
				fireCompoundAnalyticsEvent.BoardView.fieldValueUpdatedByDragBetweenSwimlanes(fieldType);
			}
		},
		[fieldType, updateField, verticalFieldKey],
	);

	const onCardDropVerticalChange: OnCardDropChange = useCallback(
		({
			issueId,
			targetValue,
			sourceValue,
		}: {
			issueId: string;
			targetValue: unknown;
			sourceValue: unknown;
		}) => {
			if (verticalFieldKey) {
				updateField(verticalFieldKey, true, issueId, targetValue, sourceValue);
				fireCompoundAnalyticsEvent.BoardView.fieldValueUpdatedByDragBetweenSwimlanes(fieldType);
			}
		},
		[fieldType, updateField, verticalFieldKey],
	);

	return {
		onCardDropStart,
		onCardDropClear,
		onCardDropChange,
		onCardDropVerticalClear,
		onCardDropVerticalChange,
		rankBefore,
		rankAfter,
	};
};

/**
 * handler for dnd behaviour of columns
 */
export const useOnColumnDrop = (field: Field) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { setGroupValues } = useViewActions();

	const onColumnDrop: OnColumnDrop = useCallback(
		(nextExtendedOptions, source) => {
			const newSortedGroupOptions = nextExtendedOptions.map((option) => ({
				id: option.groupIdentity,
			}));

			setGroupValues(newSortedGroupOptions);

			fireCompoundAnalyticsEvent.BoardView.columnDropped(createAnalyticsEvent({}), {
				issueFieldKey: field.key,
				fieldValueId: source.groupIdentity,
			});
		},
		[createAnalyticsEvent, field.key, setGroupValues],
	);

	return onColumnDrop;
};

export const useOnRowDrop = () => {
	const { setVerticalGroupValues } = useViewActions();

	const onRowDrop: OnRowDrop = useCallback(
		(nextExtendedOptions) => {
			const newSortedGroupOptions = nextExtendedOptions.map((option) => ({
				id: option.groupIdentity ?? EMPTY_VALUE_ID,
			}));

			setVerticalGroupValues(newSortedGroupOptions);
		},
		[setVerticalGroupValues],
	);

	return onRowDrop;
};
