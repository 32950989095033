import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { fg } from '@atlassian/jira-feature-gating';

const getProjectPropertyUrl = (projectIdOrKey: string, propertyKey: string): string =>
	`/rest/api/3/project/${projectIdOrKey}/properties/${propertyKey}${fg('jpd-trace-fetch-network-call') ? `?operation=getProjectProperty_${propertyKey}` : ''}`;

export const setProjectProperty = async <T,>({
	projectIdOrKey,
	propertyKey,
	data,
}: {
	projectIdOrKey: string;
	propertyKey: string;
	data: T;
}): Promise<void> => {
	await performPutRequest(getProjectPropertyUrl(projectIdOrKey, propertyKey), {
		body: JSON.stringify(data),
	});
};

export const getProjectProperty = <T,>({
	projectIdOrKey,
	propertyKey,
}: {
	projectIdOrKey: string;
	propertyKey: string;
}): Promise<T> =>
	fetchJson(getProjectPropertyUrl(projectIdOrKey, propertyKey)).then(({ value }) => value);
