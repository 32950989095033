import type { User, UserWithEmail } from '@atlaskit/share';
import type { SmartUserPickerWithSuggestionsPrincipal } from '@atlassian/jira-polaris-component-smart-user-picker-with-suggestions/src/ui/index.tsx';

// based on "optionDataToUsers" from @atlaskit/share (jira/platform/packages/elements/share/src/components/utils.ts)
export const principalsToUsers = (principals: SmartUserPickerWithSuggestionsPrincipal[]): User[] =>
	principals.map((principal) => {
		switch (principal.type) {
			case 'email': {
				const user: UserWithEmail = {
					type: 'user',
					email: principal.id,
				};
				return user;
			}
			case 'external_user': {
				// When principal.type is 'external_user', we need to convert it to 'user'
				// because 'external_user' isn't a valid type for the '/gateway/api/share' API.
				return {
					type: 'user',
					id: principal.id,
				};
			}
			default: {
				return {
					type: principal.type || 'user',
					id: principal.id,
				};
			}
		}
	});
