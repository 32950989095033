import React, { useState, useEffect } from 'react';
import { Box } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { useAllTeamInformationOnIssues } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/team-hooks.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { TeamValueRenderer } from '@atlassian/jira-polaris-lib-team-value/src/ui/index.tsx';
import type { TeamInformation } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/team.tsx';
import messages from './messages.tsx';

type FieldValueComponentProps = {
	fieldKey: FieldKey;
	groupIdentity: string;
};

const TeamFieldValue = ({ groupIdentity }: FieldValueComponentProps) => {
	const issueTeamInfos = useAllTeamInformationOnIssues();
	const [teamInfo, setTeamInfo] = useState<TeamInformation[0]>();

	useEffect(() => {
		const teamByGroupIdentity = issueTeamInfos[groupIdentity];
		if (teamByGroupIdentity) {
			setTeamInfo(teamByGroupIdentity);
		}
	}, [groupIdentity, issueTeamInfos]);

	return (
		<Box>
			<TeamValueRenderer
				name={teamInfo?.name ?? ''}
				avatarUrl={teamInfo?.avatarUrl}
				renderWithInlineMargin={false}
			/>
		</Box>
	);
};

const EmptyTeamFieldValue = () => {
	const { formatMessage } = useIntl();

	return <Box>{formatMessage(messages.emptyFieldValue)}</Box>;
};

export const teamFieldValueConfig = {
	isHideable: true,
	isDecoratable: false,
	Component: TeamFieldValue,
	EmptyComponent: EmptyTeamFieldValue,
} as const;
