import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { Skeleton } from '@atlassian/jira-common-components-skeleton/src/styled.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { useIsIssueFromDifferentContainerByLocalId } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/issue-ids-hooks.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import {
	useDecorationForNumberValue,
	useDecorationsForField,
} from '../../../../controllers/field/selectors/decoration/hooks.tsx';
import {
	useFieldLabel,
	useFieldType,
	useHasFieldFormula,
} from '../../../../controllers/field/selectors/field-hooks.tsx';
import { useNumberValue } from '../../../../controllers/issue/selectors/properties/hooks.tsx';
import { DecoratedNumber } from '../../../decoration/number/index.tsx';
import { DecoratedRating } from '../../../decoration/rating/index.tsx';
import { CheckboxField } from '../../../fields/checkbox/index.tsx';
import { EmptyField } from '../empty/index.tsx';
import { FieldLabel, FieldValue } from '../field/styled.tsx';
import { messages } from './messages.tsx';

type Props = {
	issueId: LocalIssueId;
	fieldKey: FieldKey;
	isCompact?: boolean;
	isSummary?: boolean;
	hideEmpty?: boolean;
	disableLazyRendering?: boolean;
};

export const NumberField = ({
	issueId,
	fieldKey,
	isCompact = false,
	isSummary = false,
	hideEmpty = false,
	disableLazyRendering = false,
}: Props) => {
	const { formatMessage } = useIntl();
	const label = useFieldLabel(fieldKey);
	const { value, initialized } = useNumberValue(fieldKey, issueId);
	const fieldType = useFieldType(fieldKey);
	const decoration = useDecorationForNumberValue(fieldKey, value);
	const decorations = useDecorationsForField(fieldKey);
	const hasFormula = useHasFieldFormula(fieldKey);
	const isIssueFromDifferentContainer = useIsIssueFromDifferentContainerByLocalId(issueId);

	if (
		fieldType === FIELD_TYPES.LINKED_ISSUES &&
		isIssueFromDifferentContainer &&
		fg('jpd_cross_project_connecting')
	) {
		return null;
	}

	if (value === undefined && hasFormula) {
		return (
			<>
				{!isCompact && <FieldLabel>{label}</FieldLabel>}
				<FieldValue>
					{initialized ? (
						<UndefinedFormulaValue
							isCompact={isCompact}
							title={formatMessage(messages.noValueTooltip)}
						>
							{formatMessage(messages.noValue)}
						</UndefinedFormulaValue>
					) : (
						<Skeleton height="16px" />
					)}
				</FieldValue>
			</>
		);
	}

	if (hideEmpty && value === undefined) {
		return null;
	}

	let valueNode;

	if (fieldType === FIELD_TYPES.CHECKBOX) {
		valueNode = (
			<FieldValue>
				<FieldValueWrapper noMargin={isCompact}>
					<CheckboxField value={value} onChange={undefined} isDisabled />
				</FieldValueWrapper>
			</FieldValue>
		);
	} else if (fieldType === FIELD_TYPES.RATING) {
		valueNode = (
			<FieldValue data-component-selector="card-rating_4g6h">
				<FieldValueWrapper noMargin={isCompact}>
					<DecoratedRating value={value} decorations={decorations} onChange={undefined} />
				</FieldValueWrapper>
			</FieldValue>
		);
	} else {
		valueNode = initialized ? (
			<DecoratedNumber
				value={value}
				decoration={decoration}
				isCompact={isCompact}
				disableLazyRendering={disableLazyRendering}
			/>
		) : (
			<Skeleton height="16px" />
		);
	}

	return (
		<>
			{!isCompact && <FieldLabel>{label}</FieldLabel>}
			<FieldValue>{value !== undefined ? valueNode : isSummary && <EmptyField />}</FieldValue>
		</>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldValueWrapper = styled.div<{ noMargin?: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginLeft: ({ noMargin }) => (noMargin ? 0 : '3px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const UndefinedFormulaValue = styled.div<{ isCompact: boolean }>({
	color: token('color.text.subtlest'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	margin: ({ isCompact }) => (isCompact ? 0 : '0 5px'),
});
