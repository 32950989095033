import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types.tsx';

export const updateEntityLimits =
	(): Action<State, Props> =>
	async ({ setState, getState }, { entityLimits }: Props) => {
		// compare the new entity limits with the existing ones to avoid unnecessary re-renders
		const { entityLimits: existingEntityLimits } = getState();
		if (!isEmpty(entityLimits) && !isEqual(entityLimits, existingEntityLimits)) {
			setState({ entityLimits });
		}
	};
