import LinkButton, { type Appearance } from '@atlaskit/button/new';
import { Pressable, type XCSS } from '@atlaskit/primitives';
import { type Options } from '@atlassiansox/cross-flow-support';
import { type ClickedElementAttributes } from '@post-office/shared-contracts';
import { type ReactNode } from 'react';

import { useCrossflowMessageClicked } from './use-crossflow-message-clicked';

export type BaseButtonProps = {
	crossflowOptions: Options;
	ctaText: ReactNode;
	alt?: string;
};

export type LinkButtonProps = BaseButtonProps & {
	appearance: Appearance;
};

export type PressableProps = BaseButtonProps & {
	pressableStyle: XCSS;
};

const PRIMARY_LINK_CLICKED_ELEMENT: ClickedElementAttributes = {
	clickIdentifier: 'cta',
	clickedElement: 'button',
	buttonType: 'primary',
};
export const CrossflowCtaPrimaryLinkButton = ({
	crossflowOptions,
	ctaText,
	appearance,
	alt,
}: LinkButtonProps) => {
	const { handleClick } = useCrossflowMessageClicked(
		crossflowOptions,
		PRIMARY_LINK_CLICKED_ELEMENT,
	);

	return (
		<LinkButton onClick={handleClick} appearance={appearance} aria-label={alt}>
			{ctaText}
		</LinkButton>
	);
};

const PRIMARY_BUTTON_CLICKED_ELEMENT: ClickedElementAttributes = {
	clickIdentifier: 'cta',
	clickedElement: 'button',
	buttonType: 'primary',
};
export const CrossflowCtaPrimaryPressable = ({
	crossflowOptions,
	ctaText,
	pressableStyle,
	alt,
}: PressableProps) => {
	const { handleClick } = useCrossflowMessageClicked(
		crossflowOptions,
		PRIMARY_BUTTON_CLICKED_ELEMENT,
	);

	return (
		<Pressable onClick={handleClick} xcss={pressableStyle} aria-label={alt}>
			{ctaText}
		</Pressable>
	);
};

const SECONDARY_BUTTON_CLICKED_ELEMENT: ClickedElementAttributes = {
	clickIdentifier: 'cta',
	clickedElement: 'button',
	buttonType: 'secondary',
};
export const CrossflowCtaSecondaryPressable = ({
	crossflowOptions,
	ctaText,
	pressableStyle,
	alt,
}: PressableProps) => {
	const { handleClick } = useCrossflowMessageClicked(
		crossflowOptions,
		SECONDARY_BUTTON_CLICKED_ELEMENT,
	);

	return (
		<Pressable onClick={handleClick} xcss={pressableStyle} aria-label={alt}>
			{ctaText}
		</Pressable>
	);
};
