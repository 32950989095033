import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { logSafeErrorWithoutCustomerDataWrapper } from '@atlassian/jira-polaris-lib-errors/src/common/utils/index.tsx';
import { FuzzyScale } from '../../../../common/utils/date/date-picker.tsx';

const localStorage = createLocalStorageProvider('polaris');

const DATE_FIELD_SCALE_STORAGE_KEY = 'last-used-date-field-scale';

export const setLastUsedDateFieldScaleToStorage = (scale: FuzzyScale) => {
	if (fg('polaris_functional_storage_killswitch')) {
		return;
	}
	try {
		localStorage.set(DATE_FIELD_SCALE_STORAGE_KEY, scale);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		logSafeErrorWithoutCustomerDataWrapper(
			'polaris.date-field-error',
			'Failed to save date field scale to localStorage',
			error,
		);
	}
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isFuzzyScale = (value: any): value is FuzzyScale => {
	return Object.values(FuzzyScale).includes(value);
};

export const getLastUsedDateFieldScaleFromStorage = (): FuzzyScale | null => {
	if (fg('polaris_functional_storage_killswitch')) {
		return null;
	}
	const item = localStorage.get(DATE_FIELD_SCALE_STORAGE_KEY) || null;
	if (item && isFuzzyScale(item)) {
		return item;
	}
	return null;
};
