import React, { useMemo, useCallback } from 'react';
import { styled } from '@compiled/react';
import keyBy from 'lodash/keyBy';
import Icon from '@atlaskit/icon';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { getSnippetPropertyValue } from '@atlassian/jira-polaris-common/src/common/utils/snippet/index.tsx';
import { insightsGlyph } from '@atlassian/jira-polaris-component-glyphs/src/ui/glyphs/insights/index.tsx';
import { labelGlyph } from '@atlassian/jira-polaris-component-glyphs/src/ui/glyphs/label/index.tsx';
import type { SnippetProviderProperty } from '@atlassian/jira-polaris-domain-field/src/snippet/types.tsx';
import type { Snippet } from '@atlassian/jira-polaris-domain-insight/src/snippet/types.tsx';
import { T200 } from '@atlassian/jira-polaris-lib-color-palette/src/ui/colors/index.tsx';
import { Rating } from '@atlassian/jira-polaris-lib-rating/src/ui/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { Labels } from './labels/index.tsx';
import messages from './messages.tsx';

type PropertiesBarProps = {
	snippet?: Snippet; // no snippet iff this is a "polaris" (i.e., data point level) property and there's no polaris snippet yet,
	declared: ReadonlyArray<SnippetProviderProperty>;
	onUpdate?: (arg1: string, arg2: number | undefined | string[]) => void;
};

type PropertyWidgetProp = {
	decl: SnippetProviderProperty;
	snippet?: Snippet;
	onUpdate?: (arg1: number | undefined | string[]) => void;
};

const RatingProperty = ({ decl, snippet, onUpdate }: PropertyWidgetProp) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const ratingChanged = useCallback(
		(newValue: number | undefined) => {
			fireUIAnalytics(
				createAnalyticsEvent({ action: 'clicked', actionSubject: 'icon' }),
				'insightImpact',
			);
			return onUpdate?.(newValue);
		},
		[onUpdate, createAnalyticsEvent],
	);

	const rating = useMemo(() => {
		const properties = snippet?.properties;
		if (properties !== undefined && properties !== null) {
			const ratingAsNumber = getSnippetPropertyValue(properties, decl.key);
			if (typeof ratingAsNumber === 'number') {
				return Number(ratingAsNumber.toFixed());
			}
		}
		return undefined;
	}, [decl.key, snippet]);

	return (
		<Rating
			mainColor={T200}
			value={rating}
			onChange={onUpdate === undefined ? undefined : ratingChanged}
		/>
	);
};

const LabelsProperty = ({ decl, snippet, onUpdate }: PropertyWidgetProp) => {
	const labelsChanged = useCallback(
		(newValue: string[] | undefined) => {
			if (onUpdate && newValue) {
				onUpdate(newValue);
			}
		},
		[onUpdate],
	);

	const labels = useMemo(() => {
		const properties = snippet?.properties;
		if (properties !== undefined && properties !== null) {
			const value = getSnippetPropertyValue(properties, decl.key);
			if (Array.isArray(value)) {
				return value;
			}
		}
		return undefined;
	}, [decl.key, snippet]);

	return <Labels value={labels} onUpdate={labelsChanged} isEditable={onUpdate !== undefined} />;
};

export const PropertiesBar = ({ declared, snippet, onUpdate }: PropertiesBarProps) => {
	const { formatMessage } = useIntl();
	const declaredMap = keyBy(declared, (o) => o.key);
	return (
		<PropertiesWrapper data-testid="polaris-ideas.ui.insights.insights.insight.properties.properties-wrapper">
			{declaredMap.impact && (
				<PropertyWrapper
					key={declaredMap.impact.id}
					data-testid="polaris-ideas.ui.insights.insights.insight.properties.property-impact"
				>
					<PropertyIconContainer>
						<Icon
							size="small"
							glyph={insightsGlyph}
							label={formatMessage(messages.insightImpact)}
						/>
					</PropertyIconContainer>
					<PropertyLabel>{declaredMap.impact.label}</PropertyLabel>

					<RatingProperty
						decl={declaredMap.impact}
						snippet={snippet}
						onUpdate={
							onUpdate
								? (value: undefined | number | Array<string>) =>
										onUpdate(declaredMap.impact.key, value)
								: undefined
						}
					/>
				</PropertyWrapper>
			)}
			{declaredMap.labels && (
				<LabelPropertyWrapper data-testid="polaris-ideas.ui.insights.insights.insight.properties.property-label">
					<PropertyIconContainer>
						<Icon size="small" glyph={labelGlyph} label={formatMessage(messages.insightLabels)} />
					</PropertyIconContainer>
					<LabelPropertyLabel>{formatMessage(messages.labels)}</LabelPropertyLabel>
					<LabelsProperty
						decl={declaredMap.labels}
						snippet={snippet}
						onUpdate={
							onUpdate
								? (value: undefined | number | Array<string>) =>
										onUpdate(declaredMap.labels.key, value)
								: undefined
						}
					/>
				</LabelPropertyWrapper>
			)}
		</PropertiesWrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PropertyIconContainer = styled.span({
	marginRight: token('space.050'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.icon'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	span: {
		width: '12px',
		height: '12px',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LabelPropertyWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
	minHeight: '34px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LabelPropertyLabel = styled.div({
	marginRight: token('space.050'),
	font: token('font.body.UNSAFE_small'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtle'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PropertyWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
	minHeight: '34px',
	marginRight: token('space.400'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PropertyLabel = styled.div({
	marginRight: token('space.100'),
	font: token('font.body.UNSAFE_small'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtle'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PropertiesWrapper = styled.div({
	display: 'flex',
	flex: '1 1 auto',
	alignItems: 'baseline',
	minHeight: '35px',
	flexWrap: 'wrap',
});
