import React, { memo } from 'react';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { ScrollObserver } from '@atlassian/jira-polaris-lib-performance/src/controllers/scroll-observer/scroll-observer.tsx';
import { useBaseTableApi } from '../../controllers/selectors/ui-hooks.tsx';

type Props = {
	isGrouped: boolean;
	fieldsCount: number;
	issuesCount: number;
};

export const ListScrollPerformanceObserver = memo<Props>((props: Props) => {
	const baseTableApi = useBaseTableApi();

	const scrollingContainer =
		baseTableApi?.getDOMNode()?.querySelector<HTMLElement>('.table__body') ?? null;

	const jpdExperience = experience.listView.listScrollPerformance;

	return (
		scrollingContainer && (
			<ScrollObserver
				scrollingContainer={scrollingContainer}
				onMeasuresCollected={(measures) => {
					jpdExperience.start(0);
					jpdExperience.success(
						{
							metadata: {
								fps: String(measures.fpsAvg),
								fpsMin: String(measures.fpsAvgMin),
								latency: String(measures.latencyAvg),
								latencyMax: String(measures.latencyMax),
								...props,
							},
						},
						measures.latencyAvg,
					);
				}}
			/>
		)
	);
});
