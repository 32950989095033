import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';

type ConnectionsEAPConfig = {
	enabled: boolean;
};

const getEAPConfigUrl = () => '/rest/polaris/eap/issuetype/config';

export const fetchConnectionsEAPConfig = () => fetchJson<ConnectionsEAPConfig>(getEAPConfigUrl());

export const updateConnectionsEAPConfig = (enabled: boolean) =>
	fetchJson<ConnectionsEAPConfig>(getEAPConfigUrl(), {
		method: 'PUT',
		body: JSON.stringify({ enabled }),
	});
