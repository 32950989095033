/** @jsx jsx */
import type { ComponentPropsWithoutRef, MouseEvent } from 'react';
import { css, jsx } from '@compiled/react';
import { Anchor, Box, Text, xcss } from '@atlaskit/primitives';
import LinkExternalIcon from '@atlaskit/icon/core/link-external';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useCloseRightSidebar } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/actions/hooks.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIssueLink } from '../../common/utils/issue-link/index.tsx';
import { useConnectedIssueDataByIssueId } from '../../controllers/issue/selectors/connection-hooks.tsx';
import { useIsIssueFromDifferentContainerByJiraId } from '../../controllers/issue/selectors/issue-ids-hooks.tsx';
import messages from './messages.tsx';

export type IssueCardProps = {
	issueKey: string;
	summary: string;
	issueType: {
		iconUrl: string;
		name: string;
	};
	type?: 'default' | 'link' | 'link-with-icon';
	shouldCloseSidebar?: boolean;
	anchorProps?: Partial<ComponentPropsWithoutRef<typeof Anchor>>;
};

export const IssueCard = ({
	issueKey,
	summary,
	issueType,
	type = 'default',
	shouldCloseSidebar = false,
	anchorProps,
}: IssueCardProps) => {
	const {
		link,
		target,
		onClick: onIssueLinkClick,
	} = useIssueLink({
		issueKey,
		shouldOpenInSidebar: true,
	});

	const { formatMessage } = useIntl();

	const isLink = type === 'link';
	const isLinkWithIcon = type === 'link-with-icon';

	const closeRightSidebar = useCloseRightSidebar();

	const onClick = (e: MouseEvent) => {
		if (shouldCloseSidebar) {
			closeRightSidebar();
		}
		onIssueLinkClick(e);
	};

	const card = (
		<div css={[cardStyles, isLink && cardLinkStyles, isLinkWithIcon && cardLinkWithIconStyles]}>
			<img width={16} height={16} src={issueType.iconUrl} alt={issueType.name} />
			<Box xcss={summaryStyles}>
				<Text>{summary}</Text>
			</Box>
			{isLinkWithIcon && (
				<Anchor
					onClick={onClick}
					data-component-selector="box-icb1"
					xcss={linkIconWrapperStyles}
					href={link}
					target={target}
					{...anchorProps}
				>
					<LinkExternalIcon
						color={token('color.text')}
						label={formatMessage(messages.openIssueLinkLabel)}
					/>
				</Anchor>
			)}
		</div>
	);

	if (!isLink) {
		return card;
	}

	return (
		<Anchor
			data-component-selector="non-draggable-R7x4"
			onClick={onClick}
			xcss={linkStyles}
			href={link}
			target={target}
			{...anchorProps}
		>
			{card}
		</Anchor>
	);
};

export const ConnectedIssueCard = ({
	id,
	...props
}: { id: string } & Pick<IssueCardProps, 'type' | 'anchorProps' | 'shouldCloseSidebar'>) => {
	const connectedIssue = useConnectedIssueDataByIssueId(id);
	const isIssueFromDifferentContainer = useIsIssueFromDifferentContainerByJiraId(id);

	if (!connectedIssue) {
		return null;
	}

	return (
		<IssueCard
			{...props}
			issueKey={connectedIssue.issueKey}
			issueType={connectedIssue.issueType}
			summary={connectedIssue.summary}
			type={
				isIssueFromDifferentContainer && fg('jpd_cross_project_connecting')
					? 'link-with-icon'
					: props.type
			}
		/>
	);
};

const linkStyles = xcss({
	textDecoration: 'none',
	':hover': {
		textDecoration: 'none',
		cursor: 'pointer',
	},
	':focus': {
		textDecoration: 'none',
	},
	':active': {
		textDecoration: 'none',
	},
	display: 'flex',
	maxWidth: 'fit-content',
});

const linkIconWrapperStyles = xcss({
	display: 'none',
	alignItems: 'center',
	padding: 'space.025',
	borderRadius: '2px',
	':hover': {
		backgroundColor: 'color.background.neutral.subtle.hovered',
	},
});

const cardStyles = css({
	borderWidth: '1px',
	borderStyle: 'solid',
	borderColor: token('color.border'),
	borderRadius: '4px',
	paddingInline: token('space.050'),
	backgroundColor: token('elevation.surface'),
	height: '24px',
	maxWidth: 'fit-content',

	display: 'grid',
	boxSizing: 'border-box',
	alignContent: 'center',
	alignItems: 'center',
	gridTemplateColumns: 'auto auto',
	gap: token('space.050'),
});

const cardLinkStyles = css({
	color: token('color.text'),
	'&:hover': {
		backgroundColor: token('color.background.neutral.subtle.hovered'),
	},
});

const cardLinkWithIconStyles = css({
	'&:hover': {
		paddingInlineEnd: token('space.025'),
		gridTemplateColumns: 'auto auto auto',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'&:hover [data-component-selector="box-icb1"]': {
		display: 'flex',
	},
});

const summaryStyles = xcss({
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});
