import React from 'react';
import Avatar from '@atlaskit/avatar';
import { Box, Text } from '@atlaskit/primitives';
import { SimpleTag } from '@atlaskit/tag';
import { useIntl } from '@atlassian/jira-intl';
import { fg } from '@atlassian/jira-feature-gating';
import { messages } from './messages.tsx';

export const EmptyField = () => {
	const { formatMessage } = useIntl();
	return (
		<Box paddingInlineStart="space.050">
			<Text size="UNSAFE_small" weight="medium" color="color.text.disabled">
				{formatMessage(messages.emptyField)}
			</Text>
		</Box>
	);
};

export const EmptyDeliveryProgressField = () => {
	const { formatMessage } = useIntl();
	return (
		<Box paddingInlineStart="space.050">
			<Text size="UNSAFE_small" weight="medium" color="color.text.disabled">
				{formatMessage(
					fg('polaris-issue-terminology-refresh')
						? messages.emptyDeliveryProgressFieldIssueTermRefresh
						: messages.emptyDeliveryProgressField,
				)}
			</Text>
		</Box>
	);
};

export const EmptyUserField = () => {
	const { formatMessage } = useIntl();
	return (
		<Box paddingInlineStart="space.050">
			<SimpleTag
				text={formatMessage(messages.emptyUserField)}
				appearance="rounded"
				elemBefore={<Avatar borderColor="transparent" size="xsmall" />}
			/>
		</Box>
	);
};
