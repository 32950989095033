import chunk from 'lodash/chunk';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import type { JiraSearchApiIssue } from '../types.tsx';

// https://developer.atlassian.com/cloud/jira/platform/rest/v3/api-group-issues/#api-rest-api-3-issue-bulkfetch-post
const JIRA_SEARCH_URL = '/rest/api/3/issue/bulkfetch';
// given by REST API
const MAX_ISSUES_PER_REQUEST = 100;
// tunable parameter
const MAX_PARALLEL_REQUESTS = 50;

const fetchChunk = async (
	issueIdsOrKeys: string[],
	fieldIds: string[],
): Promise<JiraSearchApiIssue[]> => {
	const response = await fetchJson(JIRA_SEARCH_URL, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			issueIdsOrKeys,
			fields: fieldIds,
		}),
	});

	// Note: there is also a per-issue error response if the issue could not be fetched. We ignore this for now.
	if (!response || !response.issues) {
		throw new Error('Invalid response from JIRA API');
	}
	return response.issues;
};

const batchedFetchRecursive = async (
	issueIds: string[][],
	fieldIds: string[],
	results: JiraSearchApiIssue[] = [],
): Promise<JiraSearchApiIssue[]> => {
	if (issueIds.length === 0) {
		return results;
	}
	const promises = issueIds
		.slice(0, MAX_PARALLEL_REQUESTS)
		.map((batchIds) => fetchChunk(batchIds, fieldIds));
	const batchResults = await Promise.all(promises);
	return batchedFetchRecursive(
		issueIds.slice(MAX_PARALLEL_REQUESTS),
		fieldIds,
		results.concat(batchResults.flat()),
	);
};

export const bulkFetchIssues = async (
	issueIdsOrKeys: string[],
	fieldIds: string[],
): Promise<JiraSearchApiIssue[]> =>
	batchedFetchRecursive(chunk(issueIdsOrKeys, MAX_ISSUES_PER_REQUEST), fieldIds);
