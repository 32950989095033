import { useEffect, useState } from 'react';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import type { User } from '@atlaskit/user-picker';
import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';

export type UserSuggestionResponse = {
	suggestedUsers: User[];
	loading: boolean;
	error?: Error;
};

export type UserSuggestionsPromiseState = 'empty' | 'pending' | 'rejected' | 'resolved';

class UserSuggestionsCache {
	private _userSuggestions!: Promise<User[]>;

	private _resolveUserSuggestions!: (value: User[] | PromiseLike<User[]>) => void;

	private _rejectUserSuggestions!: (reason?: unknown) => void;

	private _userSuggestionsPromiseState: UserSuggestionsPromiseState = 'empty';

	/* URS API Route */
	private readonly _recommendationsApi = '/gateway/api/v1/recommendations';

	/* The number of displayed suggestions below which we trigger a fetch */
	private readonly _criticallyLowDisplayResults = 3;

	/* Max number of user suggestions shown at any one time */
	private readonly _maxNumberOfDisplayResults = 3;

	/* Self-imposed limit, which if reached turns off additional fetching */
	private readonly _requestedServerResultsHardCap = 60;

	/* The amount by which we increase the requested results every time we fetch.
	 * Since the API is not paginated, we have to increase the amount of suggestions
	 * we request from the API for the user to see additional suggestions. Each additional
	 * round we increase the requested results by 10 until we reach the self-imposed hard-cap.
	 */
	private readonly _serverResultsIncreaseDelta = 10;

	/* After initial hydration we look at this variable to know whether to trigger another fetch */
	private _shouldFetchAdditionalSuggestions = false;

	/* The current amount of suggestions we request from the API. Gets increased by _serverResultsIncreaseDelta
	 * with every following fetch until the hard cap of _requestedServerResultsHardCap is reached
	 * At that point we stop allowing fetching of additional results to avoid abusing the API
	 */
	private _currentRequestedServerResults = 10;

	/* If _currentRequestedServerResults is 10, but the API only returns 5 back, then we assume
	 * that there are _noMoreServerResults, a.k.a it becomes true
	 */
	private _noMoreServerResults = false;

	/* Cancel in-flight requests when needed */
	private _abortController?: AbortController;

	private _siteId = '';

	private _userId = '';

	constructor() {
		this.fullCacheAndStateReset();
	}

	public setContext(siteId: string, userId: string) {
		this._siteId = siteId;
		this._userId = userId;
	}

	public fullCacheAndStateReset() {
		this._abortAndResetAbortController();
		this._resetUserSuggestionsPromiseState();
		this._resetUserSuggestionsCacheState();
	}

	public async getSuggestedUsers(excludedUserIds: string[]): Promise<User[]> {
		/* Any errors here will get caught in the useUserSuggestions hook */
		if (this._userSuggestionsPromiseState === 'empty' || this._shouldFetchAdditionalSuggestions) {
			await this._fetchAndCacheUserSuggestions();
		}

		const usersIdsExcludedFromShownSuggestions = [
			...new Set([...excludedUserIds, this._userId].filter((id) => id !== '')),
		];
		const unfilteredServerResults = await this._userSuggestions;
		const userFacingDisplayResults = unfilteredServerResults
			.filter(({ id }) => !usersIdsExcludedFromShownSuggestions.includes(id))
			.slice(0, this._maxNumberOfDisplayResults);

		this._shouldFetchAdditionalSuggestions =
			userFacingDisplayResults.length < this._criticallyLowDisplayResults &&
			this._currentRequestedServerResults <= this._requestedServerResultsHardCap &&
			this._userSuggestionsPromiseState === 'resolved' &&
			this._noMoreServerResults === false;

		if (this._shouldFetchAdditionalSuggestions) {
			this._currentRequestedServerResults += this._serverResultsIncreaseDelta;
			return this.getSuggestedUsers(excludedUserIds);
		}

		return userFacingDisplayResults;
	}

	private _abortAndResetAbortController() {
		this._abortController?.abort();
		this._abortController = new AbortController();
	}

	private _resetUserSuggestionsPromiseState() {
		this._userSuggestions = new Promise((resolve, reject) => {
			this._resolveUserSuggestions = resolve;
			this._rejectUserSuggestions = reject;
		});
		this._userSuggestionsPromiseState = 'empty';
	}

	private _resetUserSuggestionsCacheState() {
		this._shouldFetchAdditionalSuggestions = false;
		this._currentRequestedServerResults = 10;
		this._noMoreServerResults = false;
	}

	private async _fetchAndCacheUserSuggestions() {
		try {
			/* No more fetching in the same dialog session if we got an error */
			if (this._userSuggestionsPromiseState === 'rejected') {
				return this._userSuggestions;
			}

			this._abortAndResetAbortController();
			this._resetUserSuggestionsPromiseState();
			this._userSuggestionsPromiseState = 'pending';

			const { recommendedUsers } = await performPostRequest(this._recommendationsApi, {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					context: {
						contextType: 'Generic',
						principalId: this._userId,
						productKey: 'jira',
						siteId: this._siteId,
					},
					searchQuery: {
						minimumAccessLevel: 'APPLICATION',
						productAccessPermissionIds: ['write', 'external-collaborator-write'], // TODO: what should be productAccessPermissionIds?
						queryString: '',
						searchUserbase: false,
					},
					includeUsers: true,
					performSearchQueryOnly: false,
					maxNumberOfResults: this._currentRequestedServerResults,
				}),
				signal: this._abortController?.signal,
			});

			const formattedUsers = recommendedUsers.map(
				({ id, name, email, avatarUrl }: User): User => ({
					type: 'user',
					id,
					name,
					email,
					avatarUrl,
				}),
			);

			this._resolveUserSuggestions(formattedUsers);
			this._noMoreServerResults = formattedUsers.length < this._currentRequestedServerResults;
			this._userSuggestionsPromiseState = 'resolved';
		} catch (e) {
			this._rejectUserSuggestions(e);
			this._userSuggestionsPromiseState = 'rejected';
		} finally {
			this._shouldFetchAdditionalSuggestions = false;
		}
	}
}

export const USER_SUGGESTIONS_CACHE = new UserSuggestionsCache();

export type UseUserSuggestionsProps = {
	excludedUserIds: string[];
};

export const useUserSuggestions = ({
	excludedUserIds,
}: UseUserSuggestionsProps): UserSuggestionResponse => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const accountId = useAccountId();
	const cloudId = useCloudId();

	const [suggestedUsers, setSuggestedUsers] = useState<User[]>([]);
	const [error, setError] = useState<Error>();
	const [loading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		USER_SUGGESTIONS_CACHE.setContext(cloudId, accountId ?? '');
		const _fetchUserSuggestions = async () => {
			setLoading(true);
			try {
				setSuggestedUsers(await USER_SUGGESTIONS_CACHE.getSuggestedUsers(excludedUserIds));

				/* Reset error state after successful fetch */
				setError(undefined);
			} catch (e) {
				setError(e instanceof Error ? e : new Error('An unknown error occurred'));
			} finally {
				setLoading(false);
			}
		};

		_fetchUserSuggestions();
	}, [excludedUserIds, accountId, cloudId]);

	useEffect(() => {
		if (error) {
			createAnalyticsEvent({
				type: 'sendOperationalEvent',
				data: {
					action: 'errored',
					actionSubject: 'useUserSuggestions',
					attributes: {
						message: error?.message,
						name: error?.name,
						stack: error?.stack,
						cause: error?.cause,
					},
				},
			}).fire();
		}
	}, [error, createAnalyticsEvent]);

	return {
		suggestedUsers,
		loading,
		error,
	};
};
