import { useCallback } from 'react';
import type { CommonFlagAction } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import { useNotifications } from '@atlassian/jira-polaris-lib-notifications/src/controllers/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useContributorGroupId } from '../../../controllers/selectors/contributor-group-hooks.tsx';
import { useIsSiteAdmin } from '../../../controllers/selectors/loading-props-hooks.tsx';
import type { Actor } from '../../../controllers/types.tsx';
import messages from './messages.tsx';

export const useContributorAccessRequiredNotification = () => {
	const isSiteAdmin = useIsSiteAdmin();
	const contributorGroupId = useContributorGroupId();

	const { formatMessage } = useIntl();
	const { error } = useNotifications();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const learnMoreLink =
		'https://community.atlassian.com/t5/Jira-Product-Discovery-articles/Adding-contributors-and-creators-to-a-Jira-Product-Discovery/ba-p/2324017';

	return useCallback(
		(users: Actor[]) => {
			const getDescriptionForContributorAccessFlag = (): string => {
				switch (users.length) {
					case 1:
						return formatMessage(messages.oneUserAddedErrorMsg, {
							name: users[0].displayName,
						});
					case 2:
						return formatMessage(messages.twoPeopleAddedErrorMsg, {
							name: users[0].displayName,
							secondName: users[1].displayName,
						});
					default:
						return formatMessage(messages.multiplePeopleAddedErrorMsg, {
							name: users[0].displayName,
							count: users.length - 1,
						});
				}
			};
			const getActions = (): CommonFlagAction[] => [
				...(isSiteAdmin
					? [
							{
								content: formatMessage(messages.manageGroupsAction),
								onClick: () => {
									const analyticsEvent = createAnalyticsEvent({
										action: 'clicked',
										actionSubject: 'button',
									});
									fireUIAnalytics(analyticsEvent, 'manageGroups');

									// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
									window.open(`/admin/groups/${contributorGroupId}`, '_blank');
								},
							},
						]
					: []),
				{
					content: formatMessage(messages.learnMore),
					onClick: () => {
						const analyticsEvent = createAnalyticsEvent({
							action: 'clicked',
							actionSubject: 'button',
						});
						fireUIAnalytics(analyticsEvent, 'learnMore');

						// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
						window.open(learnMoreLink, '_blank');
					},
				},
			];
			error({
				title: formatMessage(messages.contributorAccessTitle),
				description: getDescriptionForContributorAccessFlag(),
				actions: getActions(),
			});
		},
		[contributorGroupId, createAnalyticsEvent, error, formatMessage, isSiteAdmin],
	);
};
