import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { translateToPublicIssue } from '../util/index.tsx';
import type { GetIssueResponse, JpdJiraSearchApiResponse } from './types.tsx';

const POLARIS_SEARCH_V3_URL = '/rest/polaris/issues/v3/search';

interface Params {
	jql: string;
	fields: string[];
	limit: number;
}

// It's a version of getIssuesJql service that uses the v3 API
export const getIssuesByJql = async ({ jql, fields, limit }: Params): Promise<GetIssueResponse> => {
	return performPostRequest<JpdJiraSearchApiResponse>(
		`${POLARIS_SEARCH_V3_URL}${fg('jpd-trace-fetch-network-call') ? '?operation=getIssuesByJql' : ''}`,
		{
			body: JSON.stringify({
				jql,
				fields: {
					fieldKeys: fields,
				},
				include: {
					projectData: true,
					archived: 'ACTIVE_ONLY',
					metadata: true,
					connections: false,
				},
				pagination: {
					max: limit,
				},
			}),
		},
	).then((response) => ({
		issues: response.issues.map((issue) => translateToPublicIssue(response, issue)),
		total: response.pagination.total,
	}));
};
