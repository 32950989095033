// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action, StoreActionApi } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types.tsx';
import { refreshViews } from '../refresh-views/index.tsx';
import { modifyView, findViewBy } from '../utils.tsx';

export const refreshView =
	(viewUUID: string): Action<State, Props> =>
	async (
		{ setState, getState, dispatch }: StoreActionApi<State>,
		{ navigationRemote, cloudId, projectId, onFailure, onSuccess }: Props,
	) => {
		if (
			!cloudId ||
			!projectId ||
			!navigationRemote ||
			!getState().projects[projectId]?.initialized
		) {
			return;
		}

		const { view: viewToRefresh } = findViewBy(
			getState().projects[projectId],
			(view) => view.id === viewUUID,
		);

		if (
			getState().projects[projectId]?.isRefreshing ||
			getState().projects[projectId]?.numberOfRefreshBlocks > 0 ||
			viewToRefresh?.isRefreshing
		) {
			onSuccess?.('refreshView.conflict');
			// To avoid multiple refreshes, we schedule a whole tree refresh
			return dispatch(refreshViews());
		}

		setState(
			modifyView(getState(), projectId, (viewToModify) => {
				if (viewToModify.id === viewUUID) {
					return {
						...viewToModify,
						isRefreshing: true,
					};
				}
				return viewToModify;
			}),
		);

		const lastRefreshViewTime = Date.now();

		try {
			const remoteView = await navigationRemote.fetchView(viewUUID);

			if (
				getState().projects[projectId]?.lastRefreshBlockTime > lastRefreshViewTime ||
				getState().projects[projectId]?.lastRefreshViewsTime > lastRefreshViewTime ||
				getState().projects[projectId]?.numberOfRefreshBlocks > 0 ||
				getState().projects[projectId]?.isRefreshing
			) {
				onSuccess?.('refreshView.outdated');
				setState(
					modifyView(getState(), projectId, (viewToModify) => {
						if (viewToModify.id === viewUUID) {
							return {
								...viewToModify,
								isRefreshing: false,
							};
						}
						return viewToModify;
					}),
				);
				return dispatch(refreshViews());
			}

			const { view: viewToUpdate } = findViewBy(
				getState().projects[projectId],
				(view) => view.id === viewUUID,
			);

			if (!viewToUpdate) {
				return;
			}

			setState(
				modifyView(getState(), projectId, (viewToModify) => {
					if (viewToModify.id === viewUUID) {
						return {
							...viewToModify,
							name: remoteView.name,
							emoji: remoteView.emoji,
							isRefreshing: false,
						};
					}
					return viewToModify;
				}),
			);

			onSuccess?.('refreshView');
		} catch (err) {
			setState(
				modifyView(getState(), projectId, (viewToModify) => {
					if (viewToModify.id === viewUUID) {
						return {
							...viewToModify,
							isRefreshing: false,
						};
					}
					return viewToModify;
				}),
			);
			onFailure?.(err instanceof Error ? err : new Error('Unknown error'), 'refreshView');
		}
	};
