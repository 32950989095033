import React, { useMemo, useCallback, useState, useEffect } from 'react';
import noop from 'lodash/noop';
import { Box, Pressable, Stack, xcss } from '@atlaskit/primitives';
import traceUFOPress from '@atlaskit/react-ufo/trace-press';
import { useIntl } from '@atlassian/jira-intl';
import type { Field, FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { SortField } from '@atlassian/jira-polaris-domain-field/src/sort/types.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import { Draggable } from '@atlassian/jira-polaris-lib-dnd/src/ui/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { DroppableHandler } from './droppable-handler/index.tsx';
import { SortFieldComponent } from './field/index.tsx';
import { messages } from './messages.tsx';

// prevents infinite loop in useEffect, `sortFields = []` would result in the array reference changing on each render
const DEFAULT_SORT_FIELDS: SortField[] = [];

type SetSortFieldsCallback = (
	sortFields?: SortField[],
	onSuccess?: () => void,
	onError?: () => void,
) => void | Promise<void>;

type ConfigSortProps = {
	availableSortFields: Field[];
	permanentSortFields: SortField[] | undefined;
	setPermanentSortFields: SetSortFieldsCallback;
};

export const ConfigSort = ({
	availableSortFields,
	permanentSortFields = DEFAULT_SORT_FIELDS,
	setPermanentSortFields,
}: ConfigSortProps) => {
	const { formatMessage } = useIntl();

	const [permanentSortFieldsLocal, setPermanentSortFieldsLocal] = useState<SortField[]>([]);

	// Optimistically save permanentSortFieldsLocal.
	useEffect(() => {
		setPermanentSortFieldsLocal(permanentSortFields);
	}, [permanentSortFields]);

	const sortedAvailableSortFields = useMemo(
		() => availableSortFields.sort((a, b) => a.label.localeCompare(b.label)),
		[availableSortFields],
	);

	const availablePermanentSortFields = useMemo(() => {
		const usedFieldKeys = new Set(permanentSortFieldsLocal.map(({ fieldKey }) => fieldKey));
		return sortedAvailableSortFields.filter((field) => !usedFieldKeys.has(field.key));
	}, [sortedAvailableSortFields, permanentSortFieldsLocal]);

	const onSave = useCallback(
		(newSortFields: SortField[]) => {
			experience.headerView.viewSort.start();
			setPermanentSortFields(
				newSortFields,
				() => {
					experience.headerView.viewSort.success();
				},
				(error?: Error) => {
					experience.headerView.viewSort.failure(error);
				},
			);
		},
		[setPermanentSortFields],
	);

	const onReset = useCallback(() => {
		experience.headerView.viewSort.start();
		setPermanentSortFields(
			undefined,
			() => {
				experience.headerView.viewSort.success();
			},
			(error?: Error) => {
				experience.headerView.viewSort.failure(error);
			},
		);
	}, [setPermanentSortFields]);

	const onAddNewField = useCallback(
		(field: Field, asc = true) => {
			fg('jpd-trace-ufo-press') && traceUFOPress('jpd.view-sort-config.add-sort-field');
			const newFields = [...permanentSortFieldsLocal, { fieldKey: field.key, asc }];
			onSave(newFields);
			fireCompoundAnalyticsEvent.RightSidebarViewSortFieldAdded();
		},
		[onSave, permanentSortFieldsLocal],
	);

	const onToggleDirection = useCallback(
		(fieldKey: FieldKey) => {
			fg('jpd-trace-ufo-press') && traceUFOPress('jpd.view-sort-config.change-sort-direction');
			const newFields = permanentSortFieldsLocal.map((sortField) => {
				if (fieldKey === sortField.fieldKey) {
					return {
						...sortField,
						asc: !sortField.asc,
					};
				}

				return sortField;
			});
			// Optimistic save
			setPermanentSortFieldsLocal(newFields);
			onSave(newFields);
			fireCompoundAnalyticsEvent.RightSidebarViewSortDirectionChanged();
		},
		[onSave, permanentSortFieldsLocal],
	);

	const onChangeField = useCallback(
		(field: Field, fieldKey: FieldKey) => {
			fg('jpd-trace-ufo-press') && traceUFOPress('jpd.view-sort-config.change-sort-field');
			const newFields = permanentSortFieldsLocal.map((sortField) => {
				if (fieldKey === sortField.fieldKey) {
					return {
						fieldKey: field.key,
						asc: sortField.asc,
					};
				}
				return sortField;
			});
			// Optimistic save
			setPermanentSortFieldsLocal(newFields);
			onSave(newFields);
			fireCompoundAnalyticsEvent.RightSidebarViewSortFieldsChanged();
		},
		[onSave, permanentSortFieldsLocal],
	);

	const onClearSort = useCallback(
		(fieldKey: FieldKey) => {
			fg('jpd-trace-ufo-press') && traceUFOPress('jpd.view-sort-config.remove-sort-field');
			const newFields = permanentSortFieldsLocal.filter(({ fieldKey: key }) => key !== fieldKey);
			// Clear view sort if no sort fields left update otherwise
			if (newFields.length) {
				onSave(newFields);
				fireCompoundAnalyticsEvent.RightSidebarViewSortFieldRemoved();
			} else {
				onReset();
				fireCompoundAnalyticsEvent.RightSidebarViewSortReset();
			}
		},
		[onReset, onSave, permanentSortFieldsLocal],
	);

	const handlePermanentSortFieldsLocalUpdate = useCallback(
		(nextSortFields: SortField[]) => {
			fg('jpd-trace-ufo-press') && traceUFOPress('jpd.view-sort-config.change-sort-order');
			// Optimistic save
			setPermanentSortFieldsLocal(nextSortFields);
			// Save to the server
			onSave(nextSortFields);
			fireCompoundAnalyticsEvent.RightSidebarViewSortFieldsReordered();
		},
		[onSave],
	);

	return (
		<DroppableHandler
			sortFields={permanentSortFieldsLocal}
			onSortFieldsUpdate={handlePermanentSortFieldsLocalUpdate}
		>
			<Stack alignBlock="start" space="space.200" xcss={containerStyles}>
				<Stack space="space.150">
					<Box paddingInline="space.200">{formatMessage(messages.helpText)}</Box>
					{(!!permanentSortFieldsLocal.length || !!availablePermanentSortFields.length) && (
						<Box>
							{permanentSortFieldsLocal.map(({ asc, fieldKey }, idx) => {
								const selectedField = sortedAvailableSortFields.find(({ key }) => fieldKey === key);
								const labelMessage = idx ? messages.sortThenLabel : messages.sortFirstLabel;

								return (
									<Draggable key={fieldKey} id={fieldKey} isDragDisabled={false}>
										<SortFieldComponent
											key={fieldKey}
											selectedField={selectedField}
											asc={asc}
											fields={availablePermanentSortFields}
											label={formatMessage(labelMessage)}
											onClearSort={() => onClearSort(fieldKey)}
											onChangeField={(newField) => onChangeField(newField, fieldKey)}
											onClickDirection={() => onToggleDirection(fieldKey)}
										/>
									</Draggable>
								);
							})}
							{!!availablePermanentSortFields.length && (
								<SortFieldComponent
									fields={availablePermanentSortFields}
									asc
									label={formatMessage(
										permanentSortFieldsLocal.length
											? messages.sortThenLabel
											: messages.sortFirstLabel,
									)}
									onChangeField={(newField) => onAddNewField(newField)}
									onClickDirection={noop}
									isHoverable={false}
								/>
							)}
							{!!permanentSortFieldsLocal.length && (
								<Box paddingBlock="space.050" paddingInline="space.200">
									<Pressable
										interactionName="jpd.view-sort-config.reset-sort"
										testId="polaris-component-view-sort-configuration.ui.config-sort.reset-sort-button"
										id="polaris-ideas.ui.view-controls.config-sort.reset-sort-button"
										onClick={onReset}
										xcss={resetButtonStyles}
									>
										{formatMessage(messages.resetButton)}
									</Pressable>
								</Box>
							)}
						</Box>
					)}
				</Stack>
			</Stack>
		</DroppableHandler>
	);
};

const containerStyles = xcss({
	height: '100%',
	paddingBlock: 'space.200',
});

const resetButtonStyles = xcss({
	padding: '0',
	background: 'none',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: '2em',
	':hover': {
		textDecoration: 'underline',
	},
});
