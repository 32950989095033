import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type {
	PolarisArjConfigurationResponse,
	PolarisArjHierarchyConfigurationResponse,
} from './types.tsx';

export const getPolarisArjConfiguration = (): Promise<PolarisArjConfigurationResponse> =>
	fetchJson(
		`/rest/polaris/configuration/arj${fg('jpd-trace-fetch-network-call') ? '?operation=getPolarisArjConfiguration' : ''}`,
	);

export const getPolarisArjHierarchyConfiguration =
	(): Promise<PolarisArjHierarchyConfigurationResponse> =>
		fetchJson(
			`/rest/jpo/1.0/hierarchyConfiguration${fg('jpd-trace-fetch-network-call') ? '?operation=getPolarisArjHierarchyConfiguration' : ''}`,
		);
