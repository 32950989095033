/** @jsx jsx */
import React from 'react';
import { styled, jsx } from '@compiled/react';
import type { EmojiDescription } from '@atlaskit/emoji';
import { token } from '@atlaskit/tokens';
import { Box, xcss } from '@atlaskit/primitives';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
// eslint-disable-next-line jira/import/no-restricted-import
import type { ViewKind } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { ViewHeaderEmoji } from './view-title-emoji/index.tsx';
import { EditableTitle } from './view-title/index.tsx';

type Props = {
	title: string | undefined;
	emojiId: string | undefined;
	viewKind: ViewKind | undefined;
	isEditingTitle: boolean;
	isLoading?: boolean;
	isReadOnly?: boolean;
	onEmojiChange: (selectedEmojiId?: string) => void;
	onEmojiLoaded?: (emoji: EmojiDescription | null) => void;
	onTitleEdit: () => void;
	onTitleCancel: () => void;
	onTitleRename: (newTitle: string) => void;
};

const EditableViewTitleOld = ({
	title,
	emojiId,
	viewKind,
	isEditingTitle,
	isReadOnly = false,
	isLoading = false,
	onEmojiChange,
	onEmojiLoaded,
	onTitleEdit,
	onTitleCancel,
	onTitleRename,
}: Props) => (
	<TitleContainer>
		<ViewHeaderEmoji
			emojiId={emojiId}
			viewKind={viewKind}
			onEmojiChange={onEmojiChange}
			onEmojiLoaded={onEmojiLoaded}
			isReadOnly={isReadOnly}
			isLoading={isLoading}
		/>

		{title && (
			<EditableTitle
				title={title}
				isEditing={isEditingTitle}
				isReadOnly={isReadOnly}
				isLoading={isLoading}
				onConfirm={onTitleRename}
				onCancel={onTitleCancel}
				onEdit={onTitleEdit}
			/>
		)}
	</TitleContainer>
);

const EditableViewTitleNew = ({
	title,
	emojiId,
	viewKind,
	isEditingTitle,
	isReadOnly = false,
	isLoading = false,
	onEmojiChange,
	onEmojiLoaded,
	onTitleEdit,
	onTitleCancel,
	onTitleRename,
}: Props) => (
	<Box xcss={[titleContainerStyles, isEditingTitle && isEditingTitleStyles]}>
		<ViewHeaderEmoji
			emojiId={emojiId}
			viewKind={viewKind}
			onEmojiChange={onEmojiChange}
			onEmojiLoaded={onEmojiLoaded}
			isReadOnly={isReadOnly}
			isLoading={isLoading}
		/>

		{title && (
			<EditableTitle
				title={title}
				isEditing={isEditingTitle}
				isReadOnly={isReadOnly}
				isLoading={isLoading}
				onConfirm={onTitleRename}
				onCancel={onTitleCancel}
				onEdit={onTitleEdit}
			/>
		)}
	</Box>
);

export const EditableViewTitle = componentWithFG(
	'polaris_pol-12839_view_header_redesign',
	EditableViewTitleNew,
	EditableViewTitleOld,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const TitleContainer = styled.div({
	display: 'flex',
	flex: 1,
	alignItems: 'center',
	marginRight: token('space.050'),
	width: 'calc(100% - 650px)',
	minWidth: '200px',
	gap: token('space.100'),
});

const titleContainerStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	marginRight: 'space.050',
	minWidth: '0',
	gap: 'space.100',
});

const isEditingTitleStyles = xcss({
	width: 'calc(100% - 650px)',
	flex: 1,
});
