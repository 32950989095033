import type { FieldConfiguration } from '@atlassian/jira-issue-field-base/src/services/field-config-service/types.tsx';
import type {
	ServerTeam,
	Team,
	TeamValue,
} from '@atlassian/jira-issue-field-team/src/common/types.tsx';
import {
	useTeamField,
	type UseTeamField,
} from '@atlassian/jira-issue-field-team/src/services/index.tsx';
import type { JPDExperience } from '@atlassian/jira-polaris-lib-analytics/src/common/utils/experience/types.tsx';
import { extractId } from '@atlassian/jira-polaris-lib-team-value/src/utils.tsx';
import { toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { fg } from '@atlassian/jira-feature-gating';

const getAvatarUrl = async (team: Team, siteId: string): Promise<string | undefined> => {
	if (team.avatarUrl) {
		return team.avatarUrl;
	}
	if (!fg('polaris_team_field_avatar_workaround')) {
		return;
	}
	return fetchJson<ServerTeam>(`/gateway/api/v4/teams/${team.id}?siteId=${siteId}`).then(
		(response) => response.smallAvatarImageUrl,
	);
};

export const createUseTeamFieldOverride =
	({
		issueKey,
		fieldKey,
		canEditIssues,
		initialValue,
		updateFieldValue,
	}: {
		issueKey: string;
		fieldKey: string;
		canEditIssues: boolean;
		initialValue?: TeamValue;
		updateFieldValue: (value: TeamValue | undefined, jpdExperience?: JPDExperience) => void;
	}): UseTeamField =>
	(_args) => {
		const siteId = useCloudId();

		const [{ value, error, fieldConfig }, { resetError }] = useTeamField({
			issueKey: toIssueKey(issueKey),
			fieldKey,
			initialValue,
		});

		const fieldConfigOverride: FieldConfiguration<TeamValue> | null = !fieldConfig
			? null
			: {
					...fieldConfig,
					isEditable: canEditIssues,
				};

		return [
			{ value, error, fieldConfig: fieldConfigOverride },
			{
				saveValue: async (team) => {
					if (!team) {
						updateFieldValue(undefined);
						return;
					}
					const extractedId = extractId(team?.id);
					if (!extractedId) {
						return;
					}
					// The team create endpoint does not return avatarUrl. So we need to fetch it manually when it's missing.
					const avatarUrl = await getAvatarUrl(team, siteId);
					updateFieldValue({
						...team,
						avatarUrl,
						id: extractedId,
						teamId: extractedId,
					});
				},
				resetError,
			},
		];
	};
