import { useCallback } from 'react';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import type { Ari } from '@atlassian/jira-platform-ari/src/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createHook } from '@atlassian/react-sweet-state';
import { useViewActions } from '../../views/main.tsx';
import { RightSidebarStore } from '../main.tsx';
import { useRightSidebarShowing } from '../selectors/hooks.tsx';
import type {
	PreventClosing,
	RightSidebarOpeningSource,
	Showing,
	SidebarOrigin,
} from '../types.tsx';

export const useRightSidebarActions = createHook(RightSidebarStore, {
	selector: null,
});

export const usePreventRightSidebarClosing = () => {
	const [, { preventClosing: action }] = useRightSidebarActions();
	return useCallback(
		(preventClosing: PreventClosing) => {
			action(preventClosing);
		},
		[action],
	);
};

// hooks that return an action that opens the right sidebar (sending an
// analytic event along the way)
export const useOpenRightSidebarOnFieldList = () => {
	const [, { openOnFieldList }] = useRightSidebarActions();
	return useCallback(() => {
		fireCompoundAnalyticsEvent.RightSidebarFieldListViewOpened();
		openOnFieldList();
	}, [openOnFieldList]);
};

export const useOpenRightSidebarOnField = () => {
	const [, { openOnField }] = useRightSidebarActions();
	return useCallback(
		(field: FieldKey, backTo?: Showing, origin: SidebarOrigin = 'fieldsList') => {
			fireCompoundAnalyticsEvent.RightSidebarFieldEditViewOpened();
			openOnField(field, backTo, origin);
		},
		[openOnField],
	);
};

export const useOpenRightSidebarOnCreate = (openedFrom?: RightSidebarOpeningSource) => {
	const [, { openOnCreate }] = useRightSidebarActions();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [currentShowing] = useRightSidebarShowing();

	return useCallback(
		(defaultFieldName?: string, onCreate?: (fieldKey: FieldKey) => void) => {
			fireCompoundAnalyticsEvent.RightSidebarFieldCreateViewOpened(createAnalyticsEvent({}));
			openOnCreate(openedFrom || currentShowing, defaultFieldName, onCreate);
		},
		[createAnalyticsEvent, openOnCreate, currentShowing, openedFrom],
	);
};

export const useOpenRightSidebarOption = () => {
	const [, { openOnOption }] = useRightSidebarActions();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	return useCallback(
		(id: string | null, fieldKey?: FieldKey) => {
			fireCompoundAnalyticsEvent.RightSidebarFieldOptionOpened(createAnalyticsEvent({}));
			openOnOption(id, fieldKey);
		},
		[createAnalyticsEvent, openOnOption],
	);
};

export const useOpenRightSidebarOnFilter = () => {
	const [, { openOnFilter }] = useRightSidebarActions();
	const { updateQuickSearchFilter } = useViewActions();
	return useCallback(
		(fieldKey?: FieldKey) => {
			fireCompoundAnalyticsEvent.RightSidebarOnFilterOpened();
			// Clear quick filters.
			updateQuickSearchFilter([{ stringValue: '' }]);
			// Open filters.
			openOnFilter(fieldKey);
		},
		[openOnFilter, updateQuickSearchFilter],
	);
};

export const useOpenRightSidebarOnFilterRemoveTmpFieldKey = () => {
	const [, { onFilterRemoveTmpFieldKey }] = useRightSidebarActions();
	return onFilterRemoveTmpFieldKey;
};

export const useOpenRightSidebarOnPlay = () => {
	const [, { openOnPlay }] = useRightSidebarActions();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	return useCallback(
		(localIssueId: LocalIssueId, playId: Ari) => {
			fireCompoundAnalyticsEvent.RightSidebarOnPlayOpened(createAnalyticsEvent({}));
			openOnPlay(localIssueId, playId);
		},
		[createAnalyticsEvent, openOnPlay],
	);
};

export const useOpenRightSidebarOnSort = () => {
	const [, { openOnSort }] = useRightSidebarActions();
	return useCallback(() => {
		fireCompoundAnalyticsEvent.RightSidebarOnSortOpened();
		openOnSort();
	}, [openOnSort]);
};

export const useOpenRightSidebarGroupByOptions = () => {
	const [, { openGroupByOptions }] = useRightSidebarActions();
	return useCallback(() => {
		fireCompoundAnalyticsEvent.RightSidebarGroupByColumnsOpened();
		openGroupByOptions();
	}, [openGroupByOptions]);
};

export const useOpenRightSidebarVerticalGroupByOptions = () => {
	const [, { openVerticalGroupByOptions }] = useRightSidebarActions();
	return useCallback(() => {
		fireCompoundAnalyticsEvent.RightSidebarGroupByRowsOpened();
		openVerticalGroupByOptions();
	}, [openVerticalGroupByOptions]);
};

export const useOpenRightSidebarMatrixXAxisOptions = () => {
	const [, { openXAxisOptions }] = useRightSidebarActions();
	return useCallback(() => {
		fireCompoundAnalyticsEvent.OpenMatrixXAxisOptionsOpened();
		openXAxisOptions();
	}, [openXAxisOptions]);
};

export const useOpenRightSidebarMatrixYAxisOptions = () => {
	const [, { openYAxisOptions }] = useRightSidebarActions();
	return useCallback(() => {
		fireCompoundAnalyticsEvent.OpenMatrixYAxisOptionsOpened();
		openYAxisOptions();
	}, [openYAxisOptions]);
};

export const useOpenRightSidebarMatrixZAxisOptions = () => {
	const [, { openZAxisOptions }] = useRightSidebarActions();
	return useCallback(() => {
		fireCompoundAnalyticsEvent.OpenMatrixZAxisOptionsOpened();
		openZAxisOptions();
	}, [openZAxisOptions]);
};

export const useOpenRightSidebarTimelineFieldConfig = () => {
	const [, { openOnTimelineConfig }] = useRightSidebarActions();
	return useCallback(() => {
		fireCompoundAnalyticsEvent.RightSidebarTimelineConfigOpened();
		openOnTimelineConfig();
	}, [openOnTimelineConfig]);
};

export const useOpenRightSidebarOnIdeaTemplates = () => {
	const [, { openOnIdeaTemplates }] = useRightSidebarActions();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	return useCallback(
		(onApplyTemplate: (template: ADF | undefined) => void) => {
			fireCompoundAnalyticsEvent.RightSidebarIdeaTemplatesOpened(createAnalyticsEvent({}));
			openOnIdeaTemplates(onApplyTemplate);
		},
		[createAnalyticsEvent, openOnIdeaTemplates],
	);
};

export const useCloseRightSidebar = () => {
	const [, { close }] = useRightSidebarActions();
	return useCallback(() => {
		fireCompoundAnalyticsEvent.RightSidebarClosed();
		close();
	}, [close]);
};

export const useOpenRightSidebarOnIdeasBucket = () => {
	const [, { openOnIdeasBucket }] = useRightSidebarActions();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	return useCallback(() => {
		fireCompoundAnalyticsEvent.RightSidebarIdeasBucketOpened(createAnalyticsEvent({}));
		openOnIdeasBucket();
	}, [createAnalyticsEvent, openOnIdeasBucket]);
};

export const useOpenRightSidebarOnViewInfo = () => {
	const [, { openOnViewInfo }] = useRightSidebarActions();
	return useCallback(() => {
		openOnViewInfo();
	}, [openOnViewInfo]);
};
