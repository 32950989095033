import type { CapturingResult } from './frame-capturer.tsx';

const total = (values: number[]) => {
	let result = 0;
	for (const value of values) {
		result += value;
	}
	return result;
};

const avg = (values: number[]) => {
	if (values.length === 0) {
		return null;
	}
	return total(values) / values.length;
};

const getScrollFps = ({
	capturedFrameTimes,
	capturingStartTime,
	capturingStopTime,
}: CapturingResult) => {
	const scrollingDuration = capturingStopTime - capturingStartTime;
	return (capturedFrameTimes.length / scrollingDuration) * 1000;
};

const getFrameDurations = ({ capturedFrameTimes }: CapturingResult) => {
	const frameDurations = [];
	for (let i = 1; i < capturedFrameTimes.length; i++) {
		const currentFrameTime = capturedFrameTimes[i];
		const previousFrameTime = capturedFrameTimes[i - 1];
		frameDurations.push(currentFrameTime - previousFrameTime);
	}
	return frameDurations;
};

export type Measures = {
	fpsAvg: number;
	fpsAvgMin: number;
	latencyAvg: number;
	latencyMax: number;
};

export const processScrollingData = (scrolls: CapturingResult[]): Measures | null => {
	// Used to calculate weighted average of all latencies. By latency
	// we understand a time between 2 consecutive animation frames.
	const scrollLatencyAverages: number[] = [];

	// This variable tracks the biggest latency registered across all
	// captured scrolls.
	let latencyMax = 0;

	// For each scroll we calculate an average FPS (Frames Per Second).
	// We use that to later calculate an average FPS across all scrolls.
	const scrollFpsValues: number[] = [];

	for (const scroll of scrolls) {
		scrollFpsValues.push(getScrollFps(scroll));

		const durations = getFrameDurations(scroll);
		latencyMax = Math.max(latencyMax, ...durations);
		const avgFrameDuration = avg(durations);
		if (avgFrameDuration !== null) {
			scrollLatencyAverages.push(avgFrameDuration);
		}
	}

	const latencyAvg = avg(scrollLatencyAverages);
	const fpsAvg = avg(scrollFpsValues);
	const fpsAvgMin = Math.min(...scrollFpsValues);

	if (latencyAvg === null || fpsAvg === null) {
		return null;
	}

	return {
		latencyAvg,
		latencyMax,
		fpsAvg,
		fpsAvgMin,
	};
};
