import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import { messages } from './messages.tsx';
import type {
	RollupItemFormulaHelpTextComponentProps,
	RollupItemFormulaTypeComponentProps,
} from './types.tsx';

export const RollupItemFormulaTypeComponent = ({
	typeName,
	hasDefaultFieldOptionWeights,
}: RollupItemFormulaTypeComponentProps) => {
	const { formatMessage } = useIntl();

	return (
		<Box xcss={labelStyles}>
			{!hasDefaultFieldOptionWeights ? formatMessage(messages.weightType) : typeName}
		</Box>
	);
};

export const RollupItemFormulaHelpTextComponent = ({
	fieldType,
	helpText,
	hasDefaultFieldOptionWeights,
}: RollupItemFormulaHelpTextComponentProps) => {
	const { formatMessage } = useIntl();

	return (
		<>
			{!hasDefaultFieldOptionWeights
				? formatMessage(
						fieldType === FIELD_TYPES.SINGLE_SELECT
							? messages.weightSelectTypeHelpText
							: messages.weightMultiSelectTypeHelpText,
					)
				: helpText}
		</>
	);
};

const labelStyles = xcss({
	color: 'color.text.subtlest',
	font: 'font.body',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	textTransform: 'uppercase',
	fontWeight: 'font.weight.semibold',
});
