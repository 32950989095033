import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	groupedIdeas: {
		id: 'polaris-ideas.view-content.idea-matrix.bubble.grouped-ideas',
		defaultMessage: '{count} {count, plural, one {idea} other {ideas}}',
		description:
			'Tooltip content to show how many ideas were grouped together to show only one bubble in matrix view',
	},
	noProjectPermissionsErrorTitleSingle: {
		id: 'polaris-ideas.view-content.idea-matrix.bubble.no-project-permissions-error-title-single',
		defaultMessage: "You can't edit this idea",
		description:
			'Error message title when user do not have project permissions to edit ideas, so to drag a single idea.',
	},
	noProjectPermissionsErrorDescriptionSingle: {
		id: 'polaris-ideas.view-content.idea-matrix.bubble.no-project-permissions-error-description-single',
		defaultMessage:
			"You don't have permission to edit ideas from <b>{projectName}</b> project. Contact your project admin.",
		description:
			'Error message description when user do not have project permissions to edit ideas, so to drag a single idea.',
	},
	noProjectPermissionsErrorTitleCluster: {
		id: 'polaris-ideas.view-content.idea-matrix.bubble.no-project-permissions-error-title-cluster',
		defaultMessage: "You can't edit these ideas",
		description:
			'Error message title when user try to drag a cluser of ideas and they don’t have some of those ideas’s project’s edit ideas permission.',
	},
	noProjectPermissionsErrorDescriptionCluster: {
		id: 'polaris-ideas.view-content.idea-matrix.bubble.no-project-permissions-error-description-cluster',
		defaultMessage: 'You don’t have permission to edit ideas. Contact your project admin.',
		description:
			'Error message description when user try to drag a cluser of ideas and they don’t have some of those ideas’s project’s edit ideas permission',
	},
});
