import React, { type SyntheticEvent, useCallback } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button, { ButtonGroup } from '@atlaskit/button';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useIsSelectedIssueArchived } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { InlineDelete } from '@atlassian/jira-polaris-lib-inline-delete/src/ui/main.tsx';
import { CreateEntityLimitTooltip } from '@atlassian/jira-polaris-lib-limits/src/ui/index.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { isClientFetchError } from '@atlassian/jira-fetch/src/index.tsx';
import { isPermissionError } from '@atlassian/jira-polaris-lib-errors/src/controllers/utils.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useInsights } from '../../../../controllers/insights/main.tsx';
import {
	useHasReachedInsightsLimit,
	useSelectedInsightsCount,
} from '../../../../controllers/insights/selectors/insights-hooks.tsx';
import { InsightsActionModes } from '../../../../controllers/insights/types.tsx';
import { useErrorMessage } from '../common/utils.tsx';
import { CopyInsightsForm, CopySuccessMessage } from './copy-insights/index.tsx';
import { messages } from './messages.tsx';

export const InsightsActions = () => {
	const { formatMessage } = useIntl();
	const [
		{ activeAction },
		{ enableCopyInsightsMode, deleteSelectedInsights, clearInsightSeletion },
	] = useInsights();
	const selectedInsightsCount = useSelectedInsightsCount();
	const isArchived = useIsSelectedIssueArchived();
	const [limitType, limitValue] = useHasReachedInsightsLimit();
	const errorMessage = useErrorMessage({
		hasReachedInsightsLimit: { limitType, limitValue },
	});

	const handleEnableCopyInsightsModeClick = useCallback(
		(_event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'copyToIdea');
			enableCopyInsightsMode();
		},
		[enableCopyInsightsMode],
	);

	const handleClearInsightSeletionClick = useCallback(
		(_event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'clearInsightSelection');
			clearInsightSeletion();
		},
		[clearInsightSeletion],
	);

	const handleDeleteSelectedInsights = useCallback(() => {
		experience.ideaView.insightDelete.start();

		deleteSelectedInsights()?.then((results) => {
			if (results.every((result) => result.status === 'fulfilled')) {
				experience.ideaView.insightDelete.success({
					metadata: {
						isBulkDeletion: true,
					},
				});
				return;
			}

			if (
				results.every(
					(result) =>
						result.status === 'rejected' &&
						(isClientFetchError(result.reason) || isPermissionError(result.reason)),
				)
			) {
				experience.ideaView.insightDelete.abort({
					metadata: {
						isBulkDeletion: true,
					},
				});
				return;
			}

			experience.ideaView.insightDelete.failure({
				metadata: {
					isBulkDeletion: true,
				},
			});
		});
	}, [deleteSelectedInsights]);

	switch (activeAction?.mode) {
		case InsightsActionModes.COPY:
		case InsightsActionModes.COPY_IN_PROGRESS:
			return (
				<Container>
					<ActionsContainer>
						<CopyInsightsForm />
					</ActionsContainer>
				</Container>
			);
		case InsightsActionModes.COPY_SUCCESS:
			return (
				<Container>
					<CopySuccessMessage />
				</Container>
			);
		default:
			return (
				<Container>
					<ActionsContainer>
						<TextHint>
							<TextHintNumber>{selectedInsightsCount}</TextHintNumber>
							{formatMessage(messages.selectedInsightsCount, {
								count: selectedInsightsCount,
							})}
						</TextHint>
						<ButtonsWrapper>
							<ButtonGroup>
								<CreateEntityLimitTooltip message={!!limitType && errorMessage}>
									<Button
										testId="polaris-ideas.ui.insights.insights.actions.copy"
										isDisabled={!!limitType}
										onClick={handleEnableCopyInsightsModeClick}
									>
										{formatMessage(messages.copyButton)}
									</Button>
								</CreateEntityLimitTooltip>
								{!isArchived ? (
									<InlineDelete
										title={formatMessage(messages.deleteInsightWarningHeader, {
											selectedInsightsCount,
										})}
										onSubmit={
											fg('jpd_insights_manipulation_slo')
												? handleDeleteSelectedInsights
												: deleteSelectedInsights
										}
									>
										{({ onOpen }) => (
											<Button
												testId="polaris-ideas.ui.insights.insights.actions.delete"
												onClick={(event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
													fireUIAnalytics(analyticsEvent, 'deleteInsightSelection');
													onOpen();
												}}
											>
												{formatMessage(messages.deleteButton)}
											</Button>
										)}
									</InlineDelete>
								) : null}
								<Button
									testId="polaris-ideas.ui.insights.insights.actions.clear"
									appearance="link"
									onClick={handleClearInsightSeletionClick}
								>
									{formatMessage(messages.clearSelection)}
								</Button>
							</ButtonGroup>
						</ButtonsWrapper>
					</ActionsContainer>
				</Container>
			);
	}
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ActionsContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	width: '100%',
	flexWrap: 'wrap',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	paddingTop: token('space.150'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TextHint = styled.div({
	whiteSpace: 'nowrap',
	marginRight: token('space.250'),
	marginTop: token('space.150'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TextHintNumber = styled.span({
	fontWeight: token('font.weight.bold'),
	marginRight: token('space.075'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonsWrapper = styled.span({
	marginTop: token('space.150'),
});
