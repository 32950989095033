import type { State } from '../types.tsx';

export const isManageRolesOpen = (state: State) => state.ui.manageRoles.isOpen;

export const isProjectActorsLoading = (state: State) => state.ui.projectActors.isLoading;

export const isRolePersistingInProgress = (state: State) =>
	state.ui.manageRoles.isPersistingInProgress;

export const isAccessLevelChangeInProgess = (state: State) =>
	state.ui.accessLevel.isChangeInProgress;

export const isAccessLevelModalOpen = (state: State) => state.ui.accessLevel.isOpen;
