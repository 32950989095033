import React, { type MouseEvent } from 'react';
import noop from 'lodash/noop';
import { Box, xcss } from '@atlaskit/primitives';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { FieldName } from '../../../../common/ui/field/styled.tsx';
import { useFieldLabel } from '../../../../controllers/field/selectors/field-hooks.tsx';
import { usePlatformGoalsValue } from '../../../../controllers/issue/selectors/properties/hooks.tsx';
import { PlatformGoalsField as InnerField } from '../../../fields/goals/index.tsx';
import { EmptyField } from '../empty/index.tsx';
import { FieldLabel, FieldValue } from '../field/styled.tsx';

type GoalsFieldProps = {
	issueId: LocalIssueId;
	fieldKey: string;
	isCompact?: boolean;
	isSummary?: boolean;
	hideEmpty?: boolean;
};

export const GoalsField = ({
	issueId,
	fieldKey,
	isCompact = false,
	isSummary = false,
	hideEmpty,
}: GoalsFieldProps) => {
	const goalsValue = usePlatformGoalsValue(fieldKey, issueId);
	const label = useFieldLabel(fieldKey);

	const shouldRenderValue =
		goalsValue !== undefined && (!Array.isArray(goalsValue) || goalsValue.length > 0);

	if (!shouldRenderValue && hideEmpty) {
		return null;
	}

	return (
		<>
			{!isCompact && (
				<FieldLabel>
					<FieldName>{label}</FieldName>
				</FieldLabel>
			)}
			<FieldValue>
				<Box
					xcss={isCompact || isSummary ? noMarginFieldValueStyles : fieldValueStyles}
					onClick={(e: MouseEvent) => {
						if (fg('jpd_goals_field_stop_event_propagation')) {
							e.stopPropagation();
						}
					}}
				>
					{shouldRenderValue ? (
						<InnerField
							localIssueId={issueId}
							fieldKey={fieldKey}
							isEditable={false}
							isActive
							isCompact={isCompact}
							onUpdate={noop}
						/>
					) : (
						isSummary && <EmptyField />
					)}
				</Box>
			</FieldValue>
		</>
	);
};

const noMarginFieldValueStyles = xcss({
	marginLeft: 'space.0',
});

const fieldValueStyles = xcss({
	marginLeft: 'space.050',
});
