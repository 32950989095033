import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'polaris-lib-feedback.modal.title',
		defaultMessage: 'Share your thoughts',
		description: 'Header text on modal.',
	},
	feedbackOptionLabel: {
		id: 'polaris-lib-feedback.modal.feedback-option-label',
		defaultMessage: 'Submit feedback to the product team',
		description: 'First option on the category picker',
	},
	submitFeedbackToTheJiraProductDiscoveryTeam: {
		id: 'polaris-lib-feedback.modal.submit-feedback-to-the-jira-product-discovery-team',
		defaultMessage: 'Submit feedback to the Jira Product Discovery team',
		description: 'First option on the category picker (after view header redesign)',
	},
	helpOptionLabel: {
		id: 'polaris-lib-feedback.modal.help-option-label',
		defaultMessage: 'Get help from the support team',
		description: 'Second option on the category picker',
	},
});
