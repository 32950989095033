import uniq from 'lodash/uniq';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import { MANAGE_SPRINTS_PERMISSION } from '../../../common/constants.tsx';
import { createRole as remoteCreateRole } from '../../../services/jira/create-role/index.tsx';
import {
	type PermissionKey,
	type State,
	type Props,
	RoleOrigin,
	RoleOperations,
} from '../../types.tsx';

export const createRole =
	(
		name: string,
		description: string,
		explicitPermissions: PermissionKey[],
		onSuccess: () => void,
	): Action<State, Props> =>
	({ getState, setState }, { projectId, onError }) => {
		setState({
			ui: {
				...getState().ui,
				manageRoles: {
					...getState().ui.manageRoles,
					isPersistingInProgress: true,
				},
			},
		});

		/* implicitly grant MANAGE_SPRINTS_PERMISSION to every new role
           This doesn't influence JPD but leads to a higher likelihood of losing the ability to perform
           sprint actions on crazily configured CM Software Boards
           see https://jira.atlassian.com/browse/JRACLOUD-81290
         */
		const permissions = uniq([...explicitPermissions, MANAGE_SPRINTS_PERMISSION]);

		remoteCreateRole({ projectId, name, description, permissions })
			.then(({ roleId, roleUuid }) => {
				setState({
					roles: [
						...(getState().roles || []),
						{
							id: roleId,
							roleConfigurationId: roleUuid,
							name,
							translatedName: name,
							description,
							permissions,
							roleOrigin: RoleOrigin.CUSTOM,
							allowedOperations: [
								RoleOperations.DELETE,
								RoleOperations.EDIT,
								RoleOperations.VIEW,
								RoleOperations.MANAGE_MEMBERS,
							],
						},
					],
				});
				onSuccess();
			})
			.catch((err) => onError(err))
			.finally(() => {
				setState({
					ui: {
						...getState().ui,
						manageRoles: {
							...getState().ui.manageRoles,
							isPersistingInProgress: false,
						},
					},
				});
			});
	};
