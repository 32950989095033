// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createStore } from '@atlassian/react-sweet-state';
import { actions, type Actions } from './actions/index.tsx';
import type { State } from './types.tsx';

export const initialState: State = {
	data: null,
	loading: false,
	error: null,
	hasFirstError: false,
};

export default createStore<State, Actions>({
	name: 'connect-apps',
	initialState,
	actions,
});
