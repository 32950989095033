import React, { type SyntheticEvent, useCallback, useState } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button, { ButtonGroup } from '@atlaskit/button';
import SectionMessage, { SectionMessageAction } from '@atlaskit/section-message';
import Spinner from '@atlaskit/spinner';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { getIssueLink } from '@atlassian/jira-polaris-common/src/common/utils/issue-link/index.tsx';
import type { IssueOption } from '@atlassian/jira-polaris-common/src/ui/common/issue-select/types.tsx';
import { useProjectKeyUnsafe } from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { useInsights } from '../../../../../controllers/insights/main.tsx';
import { InsightsActionModes } from '../../../../../controllers/insights/types.tsx';
import { IssueSelect } from './issue-select/index.tsx';
import { messages } from './messages.tsx';

export const CopyInsightsForm = () => {
	const { formatMessage } = useIntl();
	const [selectedIssue, setSelectedIssue] = useState<IssueOption | undefined>(undefined);
	const [{ selectedInsights, activeAction }, { disableCopyInsightsMode, copyInsights }] =
		useInsights();

	const handleCopy = useCallback(
		(_event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
			if (!selectedIssue || !selectedIssue.item) {
				return;
			}
			fireUIAnalytics(analyticsEvent, 'confirmCopyToIdea');
			const { id, key } = selectedIssue.item;
			copyInsights(String(id), key);
		},
		[copyInsights, selectedIssue],
	);

	const handleCancel = useCallback(
		(_event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
			disableCopyInsightsMode();
			fireUIAnalytics(analyticsEvent, 'cancelCopyToIdea');
		},
		[disableCopyInsightsMode],
	);

	const selectedInsightsCount = Object.keys(selectedInsights).length;

	const inCopyProgress = activeAction?.mode === InsightsActionModes.COPY_IN_PROGRESS;

	return (
		<Container data-testid="polaris-ideas.ui.insights.insights.actions.copy-insights.container">
			<InfoWrapper>
				<TextHint>
					<TextHintNumber>{selectedInsightsCount}</TextHintNumber>
					{formatMessage(messages.selectedInsightsCount, {
						count: selectedInsightsCount,
					})}
				</TextHint>
				<InputWrapper>
					<IssueSelect isDisabled={inCopyProgress} onIssueSelected={setSelectedIssue} />
				</InputWrapper>
			</InfoWrapper>
			<ButtonsWrapper>
				<ButtonGroup>
					<Button
						id="polaris-insights.ui.actions.copy-insights.button"
						testId="polaris-ideas.ui.insights.insights.actions.copy-insights.button-copy"
						isDisabled={!selectedIssue || inCopyProgress}
						onClick={handleCopy}
					>
						{inCopyProgress ? <Spinner /> : formatMessage(messages.copyButton)}
					</Button>
					<Button
						testId="polaris-ideas.ui.insights.insights.actions.copy-insights.button-cancel"
						isDisabled={inCopyProgress}
						appearance="link"
						onClick={handleCancel}
					>
						{formatMessage(messages.cancelButton)}
					</Button>
				</ButtonGroup>
			</ButtonsWrapper>
		</Container>
	);
};

export const CopySuccessMessage = () => {
	const { formatMessage } = useIntl();
	const [{ activeAction }, { disableCopyInsightsMode, getContainerProps }] = useInsights();
	const projectKey = useProjectKeyUnsafe();

	return (
		<SectionMessage
			testId="polaris-ideas.ui.insights.insights.actions.copy-insights.section-message-success"
			appearance="success"
			actions={[
				{
					key: '1',
					testId: 'polaris-insights.ui.actions.copy-insights.section-message.button-open',
					onClick: () => {
						if (!activeAction || activeAction.mode !== InsightsActionModes.COPY_SUCCESS) {
							return;
						}
						if (
							projectKey === activeAction.meta.projectKey &&
							getContainerProps().onOpenIdeaView !== undefined
						) {
							getContainerProps().onOpenIdeaView?.(toIssueKey(activeAction.meta.issueKey));
							return;
						}

						// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
						window.open(
							getIssueLink(activeAction.meta.projectKey, activeAction.meta.issueKey),
							'_blank',
						);
					},
					text: formatMessage(messages.createdIdeaLinkHint),
				},
				{
					key: '2',
					testId: 'polaris-insights.ui.actions.copy-insights.section-message.button-cancel',
					onClick: disableCopyInsightsMode,
					text: formatMessage(messages.dismissButton),
				},
			].map(({ text, key, ...restAction }) => (
				<SectionMessageAction key={key} {...restAction}>
					{text}
				</SectionMessageAction>
			))}
		>
			{/* eslint-disable-next-line @atlaskit/design-system/use-primitives-text */}
			<p>{formatMessage(messages.successMessage)}</p>
		</SectionMessage>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
	alignItems: 'center',
	width: '100%',
	flex: 1,
	flexWrap: 'wrap',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InfoWrapper = styled.div({
	flex: '1 1 auto',
	alignItems: 'center',
	display: 'flex',
	marginTop: token('space.150'),
	maxWidth: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InputWrapper = styled.div({
	padding: '0 10px',
	flex: '1 1 auto',
	minWidth: '260px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TextHint = styled.div({
	whiteSpace: 'nowrap',
	flex: '0 0 auto',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TextHintNumber = styled.span({
	fontWeight: token('font.weight.bold'),
	marginRight: token('space.075'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonsWrapper = styled.span({
	justifySelf: 'end',
	flex: '0 0 auto',
	marginTop: token('space.150'),
});
