import React, { useRef, useCallback } from 'react';
import { styled } from '@compiled/react';
import { useVirtualizer } from '@tanstack/react-virtual';
import { token } from '@atlaskit/tokens';
import UFOLoadHold from '@atlaskit/react-ufo/load-hold';
import { fg } from '@atlassian/jira-feature-gating';
import { useItemIds } from '../../controllers/selectors/items-hooks.tsx';
import { useSelectedItems } from '../../controllers/selectors/ui-hooks.tsx';
import type { ItemId } from '../../types.tsx';
import { SidebarItem } from './sidebar-item/index.tsx';
import { SidebarSelectedHeader } from './sidebar-selected-header/index.tsx';

const MAX_ESTIMATED_CARD_HEIGHT = 500;

export const Sidebar = () => {
	const itemIds: ItemId[] = useItemIds();
	const selectedItemIds = useSelectedItems();
	const cards = selectedItemIds.length > 0 ? selectedItemIds : itemIds;

	const scrollElementRef = useRef<HTMLDivElement>(null);
	const estimateSize = useCallback(() => MAX_ESTIMATED_CARD_HEIGHT, []);
	const getItemKey = (idx: number) => cards[idx];

	const virtualizer = useVirtualizer({
		count: cards.length,
		getScrollElement: () => scrollElementRef.current,
		overscan: 5,
		getItemKey,
		estimateSize,
	});

	return (
		<SidebarWrapper ref={scrollElementRef}>
			{selectedItemIds.length > 0 && (
				<SidebarSelectedHeader selectedAmount={selectedItemIds.length} />
			)}
			<List $height={virtualizer.getTotalSize()}>
				{virtualizer.getVirtualItems().map(({ key: itemId, start, index }) => (
					<SidebarItemWrapper
						data-index={index}
						key={itemId}
						ref={virtualizer.measureElement}
						$top={start}
					>
						<SidebarItem
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							itemId={itemId as ItemId}
						/>
					</SidebarItemWrapper>
				))}
			</List>
			{cards.length > 0 &&
				virtualizer.getVirtualItems().length === 0 &&
				fg('jpd-trace-ufo-transition') && (
					<UFOLoadHold name="jpd.matrix-sidebar-virtualization-in-progress" />
				)}
		</SidebarWrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SidebarWrapper = styled.div({
	flex: '0 0 auto',
	marginLeft: token('space.150'),
	height: 'calc(100% - 20px)',
	width: '328px',
	overflow: 'auto',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SidebarItemWrapper = styled.div<{ $top: number }>({
	position: 'absolute',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	top: ({ $top }) => `${$top}px`,
	left: 0,
	width: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const List = styled.div<{ $height: number }>({
	boxSizing: 'border-box',
	flex: '1 1 auto',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: ({ $height }) => `${$height}px`,
	width: '100%',
	position: 'relative',
});
