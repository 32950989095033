import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { useSubmitIdeaFormView } from '@atlassian/jira-polaris-common/src/controllers/project-properties/index.tsx';
import { useInvalidIssueSubmissionFormFieldLabels } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useSubmitIdeaFormViewProperty } from '@atlassian/jira-polaris-component-project-properties/src/controllers/selectors/properties/index.tsx';
import { messages } from './messages.tsx';
import { SectionMessage } from './styled.tsx';

export const SectionMessageComponent = () => {
	const { formatMessage } = useIntl();
	const viewId = useSubmitIdeaFormView();
	const viewIdProperty = useSubmitIdeaFormViewProperty();
	const unsupportedFields = useInvalidIssueSubmissionFormFieldLabels(
		fg('polaris_extract-project-properties') ? viewIdProperty.value : viewId.value,
	);

	if (!unsupportedFields.length) {
		return null;
	}

	return (
		<SectionMessage appearance="information">
			{formatMessage(messages.unsupportedFieldsInfoFirst)}
			<br />
			{formatMessage(messages.unsupportedFieldsInfoSecond, {
				fields: unsupportedFields.join(', '),
			})}
		</SectionMessage>
	);
};
