import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	backToSettingsLabel: {
		id: 'polaris-component-copy-values.copy-values-sidebar.back-to-settings-label',
		defaultMessage: 'Back to field settings',
		description: 'Label for the back button in the copy values sidebar',
	},
	cancel: {
		id: 'polaris-component-copy-values.copy-values-sidebar.cancel',
		defaultMessage: 'Cancel',
		description: 'Label for the cancel button',
	},
	copyValues: {
		id: 'polaris-component-copy-values.copy-values-sidebar.copy-values',
		defaultMessage: 'Copy values',
		description: 'Title of the copy values section',
	},
	copyValuesErrorTitle: {
		id: 'polaris-component-copy-values.copy-values-sidebar.copy-values-error-title',
		defaultMessage: "We can't copy the values",
		description: 'Title of the error message when copying values fails',
	},
	copyValuesErrorDescription: {
		id: 'polaris-component-copy-values.copy-values-sidebar.copy-values-error-description',
		defaultMessage: 'Try again.',
		description: 'Description of the error message when copying values fails',
	},
});
