import React, { useCallback } from 'react';
import { Box, Text, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { isSafari as isBrowserSafari } from '@atlassian/jira-common-util-browser/src/index.tsx';
import {
	useCurrentViewGroupBy,
	useCurrentViewLayoutType,
	useCurrentViewVerticalGroupBy,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { IdeaCard } from '@atlassian/jira-polaris-common/src/ui/idea-card-v2/main.tsx';
import type { BoardProps } from '@atlassian/jira-polaris-lib-board/src/types/ui.tsx';
import { DraggableCard as DraggableCardComponent } from '@atlassian/jira-polaris-lib-board/src/ui/card-list/draggable-card/index.tsx';
import { useCanEditIssues } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { useProjectForIssue } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/project/hooks.tsx';
import { useNotifications } from '@atlassian/jira-polaris-lib-notifications/src/index.tsx';
import { useIntl, type MessageDescriptor } from '@atlassian/jira-intl';
import type { DraggableCardProps } from '@atlassian/jira-polaris-lib-board/src/types/custom-components.tsx';
import { useIsFieldAssociatedToIssue } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { messages } from './messages.tsx';

const isSafari = isBrowserSafari();
const CARD_CLONE_WIDTH = 326;

export const DraggableCard = (props: DraggableCardProps) => {
	const { formatMessage } = useIntl();
	const project = useProjectForIssue(props.ideaId);
	const [isDragAllowed] = useCanEditIssues({ projectId: project?.id });
	const { error } = useNotifications();
	const groupByField = useCurrentViewGroupBy();
	const isGroupByFieldAssociatedToIssue =
		useIsFieldAssociatedToIssue(groupByField?.key || '', props.ideaId) || !groupByField;

	const verticalGroupBy = useCurrentViewVerticalGroupBy();
	const verticalGroupByFieldIsAssociatedToIssue =
		useIsFieldAssociatedToIssue(verticalGroupBy?.key ?? '', props.ideaId) || !verticalGroupBy;

	const isDragForbidden = !isDragAllowed;
	const onDrop = useCallback(() => {
		if (
			isDragForbidden ||
			!isGroupByFieldAssociatedToIssue ||
			!verticalGroupByFieldIsAssociatedToIssue
		) {
			let errorTitle: MessageDescriptor;
			let errorDescription: MessageDescriptor;

			if (isDragForbidden) {
				errorTitle = messages.noProjectPermissionsErrorTitle;
				errorDescription = messages.noProjectPermissionsErrorDescription;
			} else if (!isGroupByFieldAssociatedToIssue) {
				errorTitle = messages.columnFieldNotAddedToProjectErrorTitle;
				errorDescription = messages.columnFieldNotAddedToProjectErrorDescription;
			} else {
				errorTitle = messages.groupByFieldNotAddedToProjectErrorTitle;
				errorDescription = messages.groupByFieldNotAddedToProjectErrorDescription;
			}

			error({
				title: formatMessage(errorTitle),
				description: formatMessage(errorDescription, {
					projectName: project?.name,
					b: (chunks: React.ReactNode[]) => <Text weight="bold">{chunks}</Text>,
				}),
			});
		}
	}, [
		error,
		formatMessage,
		isDragForbidden,
		isGroupByFieldAssociatedToIssue,
		project?.name,
		verticalGroupByFieldIsAssociatedToIssue,
	]);

	return (
		<DraggableCardComponent
			{...props}
			canDrop={
				isDragAllowed && isGroupByFieldAssociatedToIssue && verticalGroupByFieldIsAssociatedToIssue
			}
			onDrop={onDrop}
		/>
	);
};

export const Card: BoardProps['components']['Card'] = ({ id, dragStatus, isPreview }) => {
	const viewLayoutType = useCurrentViewLayoutType();

	if (isPreview) {
		return (
			<Box xcss={[cardCloneWrapperStyles]}>
				<IdeaCard
					id={id}
					hasHoverState
					viewLayoutType={viewLayoutType}
					hideEmptyFields
					hasMultilineStrings
					isDisabled
					disableLazyRendering
					cappedFieldsDisplay={isSafari}
				/>
			</Box>
		);
	}

	return (
		<IdeaCard
			id={id}
			hasHoverState={dragStatus !== 'dragging'}
			viewLayoutType={viewLayoutType}
			hideEmptyFields
			hasMultilineStrings
			isGrayedOut={dragStatus === 'dragging'}
			hoverBackgroundColor={token('elevation.surface.hovered')}
		/>
	);
};

const cardCloneWrapperStyles = xcss({
	width: `${CARD_CLONE_WIDTH}px`,
});
