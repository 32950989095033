import React, { useCallback, useState } from 'react';
import { styled } from '@compiled/react';
import InfoIcon from '@atlaskit/icon/core/migration/information--info';
import { Inline, xcss, Text, Pressable } from '@atlaskit/primitives';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useCollectionFiltersJql } from '@atlassian/jira-polaris-component-collections/src/controllers/collections/index.tsx';
import { useEnvironmentContainer } from '@atlassian/jira-polaris-component-environment-container/src/controllers/store/index.tsx';
import { PolarisEnvironmentContainerTypes } from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { useCurrentUser } from '@atlassian/jira-polaris-common/src/common/utils/tenant-context/index.tsx';
import { usePrioritizeViews } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { CollectionConfigureSidebar } from '@atlassian/jira-polaris-component-collection-configure-sidebar/src/ui/index.tsx';
import { getCollectionsPageUrl } from '@atlassian/jira-polaris-component-collections/src/common/utils/collections.tsx';
import type { View } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { useRouterActions } from '@atlassian/react-resource-router';
import { useIsCollectionView } from '@atlassian/jira-polaris-common/src/controllers/environment/index.tsx';
import { useCanEditCollection } from '@atlassian/jira-polaris-component-collections/src/controllers/combined-selectors.tsx';
import { Header } from '../header/index.tsx';
import { PolarisFilters } from '../../view-controls/config-filters/index.tsx';
import { messages } from './messages.tsx';

type Props = {
	onClose: () => void;
};

type ViewWithUUID = View & { uuid: string };

const useHasCurrentCollectionFilters = (): boolean => {
	const env = useEnvironmentContainer();
	const collectionUUID =
		env?.type === PolarisEnvironmentContainerTypes.COLLECTION ? env.collectionId : undefined;
	return !!useCollectionFiltersJql({ collectionUUID });
};

const CollectionFiltersMessage = () => {
	const { formatMessage } = useIntl();
	const hasCurrentCollectionFilters = useHasCurrentCollectionFilters();
	const environmentContainer = useEnvironmentContainer();
	const collectionUUID = environmentContainer?.id;
	const canEditCollection = useCanEditCollection({ collectionUUID });
	const [isCollectionSettingsSidebarOpen, setIsCollectionSettingsSidebarOpen] = useState(false);
	const views = usePrioritizeViews().filter(
		(view): view is ViewWithUUID => view.uuid !== undefined,
	);
	const { push } = useRouterActions();

	const currentUser = useCurrentUser();

	const handleAfterCollectionDelete = useCallback(() => push(getCollectionsPageUrl()), [push]);

	if (!hasCurrentCollectionFilters) {
		return;
	}

	return (
		<>
			<Inline xcss={roadmapFiltersInfoStyles} alignBlock="start" space="space.100">
				<InfoIcon spacing="spacious" label={formatMessage(messages.roadmapFiltersInfoText)} />
				<span>
					<Text>{formatMessage(messages.roadmapFiltersInfoText)}</Text>
					{canEditCollection && (
						<Pressable
							xcss={manageRoadmapLinkStyles}
							onClick={() => setIsCollectionSettingsSidebarOpen(true)}
						>
							{formatMessage(messages.manageRoadmapLink)}
						</Pressable>
					)}
				</span>
			</Inline>
			<CollectionConfigureSidebar
				currentUser={currentUser}
				isOpen={isCollectionSettingsSidebarOpen}
				onClose={() => setIsCollectionSettingsSidebarOpen(false)}
				collectionUUID={collectionUUID}
				views={views}
				onAfterCollectionDelete={handleAfterCollectionDelete}
				onAfterSelfAccessPermissionDelete={handleAfterCollectionDelete}
			/>
		</>
	);
};

export const FilterPanel = ({ onClose }: Props) => {
	const { formatMessage } = useIntl();
	const isCollectionView = useIsCollectionView();

	return (
		<UFOSegment name="jpd.right-sidebar.filters">
			<FieldsContainer>
				<Header title={formatMessage(messages.title)} onClose={onClose} />
				{isCollectionView && fg('jpd-aurora-roadmap-advanced-filtering') && (
					<CollectionFiltersMessage />
				)}
				<PolarisFilters />
			</FieldsContainer>
		</UFOSegment>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldsContainer = styled.div({
	minHeight: '100%',
	display: 'flex',
	flexDirection: 'column',
});

const roadmapFiltersInfoStyles = xcss({
	paddingInline: 'space.200',
	paddingBlock: 'space.100',
	backgroundColor: 'elevation.surface.sunken',
});

const manageRoadmapLinkStyles = xcss({
	color: 'color.link',
	padding: 'space.0',
	background: 'transparent',
	':hover': {
		textDecoration: 'underline',
	},
	':active': {
		color: 'color.link.pressed',
	},
});
