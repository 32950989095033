import React, { useCallback, useMemo, useState } from 'react';
import { RightSidebar } from '@atlassian/jira-polaris-lib-right-sidebar/src/ui/main.tsx';
import { RightSidebarTransition } from '@atlassian/jira-polaris-lib-right-sidebar/src/ui/transition/index.tsx';
import { ContextualAnalyticsData, DRAWER } from '@atlassian/jira-product-analytics-bridge';
import { fg } from '@atlassian/jira-feature-gating';
import {
	SIDEBAR_MANAGE_FILTERS_CONTENT_TYPE,
	SIDEBAR_ADD_PROJECT_CONTENT_TYPE,
	SIDEBAR_MANAGE_PROJECTS_CONTENT_TYPE,
	SIDEBAR_SETTINGS_CONTENT_TYPE,
} from '../common/constants.tsx';
import type { SidebarContentType } from '../common/types.tsx';
import { CollectionConfigureContextProvider } from '../controllers/index.tsx';
import { AddProjectSidebarContent } from './add-project-content/index.tsx';
import { DefaultSidebarContent } from './default-content/index.tsx';
import { ManageProjectsSidebarContent } from './manage-projects-content/index.tsx';
import type { Props } from './types.tsx';
import { ManageFiltersSidebarContent } from './manage-filters-content/index.tsx';

export const CollectionConfigureSidebar = ({
	collectionUUID,
	isOpen,
	views,
	currentUser,
	onClose,
	onBeforeCollectionDelete,
	onAfterCollectionDelete,
	onAfterSelfAccessPermissionDelete,
}: Props) => {
	const [content, setContent] = useState<SidebarContentType | undefined>();

	const handleClose = useCallback(() => {
		setContent(undefined);
		onClose();
	}, [onClose]);

	const sidebarContent = useMemo(() => {
		switch (content) {
			case SIDEBAR_ADD_PROJECT_CONTENT_TYPE:
				return <AddProjectSidebarContent onContentChange={setContent} />;
			case SIDEBAR_MANAGE_PROJECTS_CONTENT_TYPE:
				return <ManageProjectsSidebarContent onContentChange={setContent} />;
			case SIDEBAR_MANAGE_FILTERS_CONTENT_TYPE:
				return fg('jpd-aurora-roadmap-advanced-filtering') ? (
					<ManageFiltersSidebarContent onContentChange={setContent} />
				) : (
					<DefaultSidebarContent onContentChange={setContent} />
				);
			case SIDEBAR_SETTINGS_CONTENT_TYPE:
			default:
				return <DefaultSidebarContent onContentChange={setContent} />;
		}
	}, [content]);

	return (
		<ContextualAnalyticsData sourceType={DRAWER} sourceName="collectionConfiguration">
			<RightSidebarTransition>
				{isOpen && (
					<CollectionConfigureContextProvider
						currentUser={currentUser}
						collectionUUID={collectionUUID}
						views={views}
						onClose={handleClose}
						onAfterCollectionDelete={onAfterCollectionDelete}
						onBeforeCollectionDelete={onBeforeCollectionDelete}
						onAfterSelfAccessPermissionDelete={onAfterSelfAccessPermissionDelete}
					>
						<RightSidebar onClose={handleClose} preventCloseOnEscape={content !== undefined}>
							{sidebarContent}
						</RightSidebar>
					</CollectionConfigureContextProvider>
				)}
			</RightSidebarTransition>
		</ContextualAnalyticsData>
	);
};
