import isNumber from 'lodash/isNumber';
import FetchError, { ValidationError } from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { ClientError, Informational, Redirection, ServerError, Unknown } from './constants.tsx';
import type { JiraError } from './types.tsx';

export const getStatusCodeGroup = (error: Error | JiraError): string => {
	const getStatusCode = (status: number): string => {
		if (status >= 100 && status <= 199) {
			return Informational;
		}
		if (status >= 300 && status <= 399) {
			return Redirection;
		}
		if (status >= 400 && status <= 499) {
			return ClientError;
		}
		if (status >= 500 && status <= 599) {
			return ServerError;
		}
		return Unknown;
	};

	if (error instanceof FetchError) {
		const { statusCode } = error;
		return getStatusCode(statusCode);
	}
	if (error instanceof ValidationError || error instanceof TypeError) {
		return ClientError;
	}
	// @ts-expect-error - TS2339 - Property 'statusCode' does not exist on type 'Error | JiraError'. | TS2339 - Property 'statusCode' does not exist on type 'Error | JiraError'.
	if (error.statusCode !== undefined && error.statusCode !== null) {
		// @ts-expect-error - TS2339 - Property 'statusCode' does not exist on type 'Error | JiraError'. | TS2339 - Property 'statusCode' does not exist on type 'Error | JiraError'.
		return isNumber(error.statusCode) ? getStatusCode(error.statusCode) : Unknown;
	}

	return Unknown;
};
