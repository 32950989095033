import React from 'react';
import Button from '@atlaskit/button';
import Heading from '@atlaskit/heading';
import { Box, xcss } from '@atlaskit/primitives';
import { PRODUCT_DISCOVERY_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { FormattedMessage } from '@atlassian/jira-intl';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import { getNotificationsPersonalSettingsPath } from '@atlassian/jira-personal-settings-paths/src/index.tsx';
import { useProjectKeyUnsafe } from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import NotificationEmailPage from '@atlassian/jira-project-settings-apps-notification-email-page/src/ui/index.tsx';
import NotificationSettingsPageHeader from '@atlassian/jira-project-settings-apps-notifications-common/src/ui/notification-page-header/index.tsx';
import NotificationSettingsContainer from '@atlassian/jira-project-settings-apps-notifications-services/src/services/notification-settings-service/main.tsx';
import { AsyncNotificationsTable } from '@atlassian/jira-project-settings-apps-notifications-table/src/ui/async.tsx';
import { useProjectContext } from '@atlassian/jira-providers-project-context/src/index.tsx';
import { toProjectKey, toProjectUuid } from '@atlassian/jira-shared-types/src/general.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { BreadcrumbsProjectSettings } from '../breadcrumbs/index.tsx';
import messages from './messages.tsx';

export const PolarisProjectNotification = () => {
	const intl = useIntl();
	const { formatMessage } = useIntl();
	const projectKey = useProjectKeyUnsafe();
	const { data } = useProjectContext();
	if (data === null || data === undefined) {
		return null;
	}

	const { projectUuid } = data;

	return (
		<NotificationSettingsContainer
			baseUrl=""
			projectKey={toProjectKey(projectKey)}
			projectUuid={toProjectUuid(String(projectUuid))}
			projectType={PRODUCT_DISCOVERY_PROJECT}
			intl={intl}
		>
			<Box paddingBlockStart="space.300" xcss={breadcrumbsContainerStyles}>
				<BreadcrumbsProjectSettings />
			</Box>

			<NotificationSettingsPageHeader
				pageTitle={<Heading size="large">{formatMessage(messages.title)}</Heading>}
			>
				{/* eslint-disable-next-line @atlaskit/design-system/use-primitives-text */}
				<p>
					{fg('polaris-issue-terminology-refresh') ? (
						<FormattedMessage
							{...messages.pageDescriptionIssueTermRefresh}
							values={{
								personalNotificationsPrefLink: (
									<Button
										appearance="link"
										spacing="none"
										href={getNotificationsPersonalSettingsPath()}
										target="_blank"
									>
										{formatMessage(messages.personalNotificationsPreferences)}
									</Button>
								),
							}}
						/>
					) : (
						<FormattedMessage
							{...messages.pageDescription}
							values={{
								personalNotificationsPrefLink: (
									<Button
										appearance="link"
										spacing="none"
										href={getNotificationsPersonalSettingsPath()}
										target="_blank"
									>
										{formatMessage(messages.personalNotificationsPreferences)}
									</Button>
								),
							}}
						/>
					)}
				</p>
				<NotificationEmailPage projectContext={data} headerStyle="section" />
				<Box xcss={tableContainerStyles}>
					{/* @ts-expect-error - TS2739 - Type '{}' is missing the following properties from type 'Props': useNotificationSettingsService, flagService */}
					<AsyncNotificationsTable />
				</Box>
			</NotificationSettingsPageHeader>
		</NotificationSettingsContainer>
	);
};

const tableContainerStyles = xcss({
	marginTop: 'space.300',
});

const breadcrumbsContainerStyles = xcss({
	marginBottom: 'space.negative.300',
});
