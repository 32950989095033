import React, {
	useRef,
	useCallback,
	useEffect,
	useMemo,
	useState,
	type PropsWithChildren,
	type ReactNode,
} from 'react';
import noop from 'lodash/noop';
import { useApolloClient } from '@apollo/react-hooks';
import { fg } from '@atlassian/jira-feature-gating';
import { useIsFullscreen } from '@atlassian/jira-layout-controller/src/controllers/layout-controller/consumers/fullscreen/index.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import {
	jpdProjectPageLoadPermissionsStart,
	jpdProjectPageLoadPermissionsEnd,
} from '@atlassian/jira-polaris-component-metrics/src/project.tsx';
import {
	useCloudId,
	useCurrentUser,
} from '@atlassian/jira-polaris-common/src/common/utils/tenant-context/index.tsx';
import { AtlasContainer } from '@atlassian/jira-polaris-common/src/controllers/atlas/index.tsx';
import { AvailableProductsContainer } from '@atlassian/jira-polaris-common/src/controllers/available-products/main.tsx';
import { DynamicFieldContainer } from '@atlassian/jira-polaris-common/src/controllers/dynamic-field/index.tsx';
import {
	useIsCollectionView,
	useIsEmbedded,
	useIsSharedView,
} from '@atlassian/jira-polaris-common/src/controllers/environment/index.tsx';
import {
	FieldContainer,
	useFieldActions,
} from '@atlassian/jira-polaris-common/src/controllers/field/main.tsx';
import { useFieldValueDecorations } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/decoration/hooks.tsx';
import {
	useArchivedFieldsConfig,
	useFieldsArray,
	useAllFieldsArray,
	useFieldsByKey,
	useVisibleFieldsByKey,
	useFieldsLoading,
	useUniqConnectionFieldsProjectIds,
} from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks.tsx';
import { useIsInitialized as useIsFieldsInitialized } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/meta-hooks.tsx';
import { GlobalFieldsContainer } from '@atlassian/jira-polaris-common/src/controllers/global-fields/main.tsx';
import { useIsSingleIssueLoaded } from '@atlassian/jira-polaris-common/src/controllers/idea/selectors/hooks.tsx';
import { IssuesContainer } from '@atlassian/jira-polaris-common/src/controllers/issue/index.tsx';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import { useLocalIssueIdsByJiraIssueId } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/issue-ids-hooks.tsx';
import {
	useIsInitialized as useIsIssuesInitialized,
	useIsIssuesLoading,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/meta-hooks.tsx';
import {
	useAllExternalReferencesPropertiesForProvider,
	useAllGoalsAri,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { LicenceInformationContainer } from '@atlassian/jira-polaris-common/src/controllers/licence-information/main.tsx';
import { MergeIdeasDialogContainer } from '@atlassian/jira-polaris-common/src/controllers/merge-ideas-dialog/index.tsx';
import {
	PRELOAD_PROJECT_PROPERTIES,
	ProjectPropertiesContainer,
	useProjectPropertiesActions,
	useProjectProperty,
} from '@atlassian/jira-polaris-common/src/controllers/project-properties/index.tsx';
import { ProjectProperties } from '@atlassian/jira-polaris-common/src/controllers/project-properties/types.tsx';
import {
	useProjectActions,
	ProjectContainer,
} from '@atlassian/jira-polaris-common/src/controllers/project/main.tsx';
import { useInsightsByIssueId as useInsightsByIssueIdOld } from '@atlassian/jira-polaris-common/src/controllers/project/selectors/insights-hooks.tsx';
import {
	InsightsLoader,
	useInsightsByIssueId as useInsightsByIssueIdNew,
	useInsightsLoader,
} from '@atlassian/jira-polaris-component-insights/src/controllers/index.tsx';
import { useGetPlayContributionsByIssueId } from '@atlassian/jira-polaris-common/src/controllers/project/selectors/plays-hooks.tsx';
import {
	useIsProjectLoading,
	useIsProjectOnboarded,
	useProjectOnboardedAtTimestamp,
	useProjectTemplateVersion,
	useJiraRankFieldKey,
} from '@atlassian/jira-polaris-common/src/controllers/project/selectors/project-hooks.tsx';
import {
	useFullscreenQueryParameter,
	useCurrentPolarisRoute,
	useIsIssueOpenInSidebar,
	usePolarisRouter,
	useSelectedIssueQueryParameter,
} from '@atlassian/jira-polaris-common/src/controllers/route/index.tsx';
import { SharingContainer } from '@atlassian/jira-polaris-common/src/controllers/sharing/index.tsx';
import { UserPropertiesContainer } from '@atlassian/jira-polaris-common/src/controllers/user-properties/index.tsx';
import { useCurrentUserTimezone } from '@atlassian/jira-polaris-common/src/controllers/user/index.tsx';
import { ViewContainer } from '@atlassian/jira-polaris-common/src/controllers/views/index.tsx';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import { useCurrentViewFilter } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/filters-hooks.tsx';
import {
	useCurrentViewIssueRanking,
	useCurrentViewSelectedIssueIdsAsList,
	useCurrentViewSortMode,
	useCurrentViewUUID,
	useCanManageView,
	useCurrentViewId,
	useRemoveViewIfNoAccess,
	useViewAccessLevel,
	useCurrentViewContainsArchived,
	useCurrentViewSortBy,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { WorkflowContainer } from '@atlassian/jira-polaris-common/src/controllers/workflow/main.tsx';
import { useTransitionsMap } from '@atlassian/jira-polaris-common/src/controllers/workflow/selectors/transitions-hooks.tsx';
import { isGlobalExperienceWorkspaceForCloudId } from '@atlassian/jira-polaris-common/src/services/atlas/global-experience/index.tsx';
import { useReactionClient } from '@atlassian/jira-polaris-common/src/services/polaris-api/reactions/index.tsx';
import { useMergeNotifications } from '@atlassian/jira-polaris-common/src/ui/merge-ideas-dialog/notifications/index.tsx';
import { EntityLimitsContainer } from '@atlassian/jira-polaris-component-entity-limits-store/src/controllers/entity-limits/main.tsx';
import {
	useProjectId,
	useProjectIdUnsafe,
} from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import {
	useEnvironmentContainer,
	useEnvironmentContainerId,
} from '@atlassian/jira-polaris-component-environment-container/src/controllers/store/index.tsx';
import { useArjLicensed } from '@atlassian/jira-polaris-component-environment-tenant/src/controllers/arj/index.tsx';
import {
	useDataPointIssueLinkType,
	useDeliveryIssueLinkType,
	useHiddenPolarisIssueLinkTypes,
	useMergeIssueLinkType,
	useArjConfiguration,
	useStatusCategories,
	useIssueLinkTypes,
	useArjHierarchyConfiguration,
} from '@atlassian/jira-polaris-component-environment-tenant/src/controllers/selectors/index.tsx';
import { EnvironmentTenantProvider } from '@atlassian/jira-polaris-component-environment-tenant/src/index.tsx';
import { FieldAriProvider } from '@atlassian/jira-polaris-component-field-ari/src/index.tsx';
import {
	useAllIssueTypeIds,
	useIssueTypeIdsForProject,
	useAllIssueTypeIdsByProjectId,
} from '@atlassian/jira-polaris-component-issue-types/src/controllers/index.tsx';
import {
	ProjectIssueTypesFetcher,
	ProjectsIssueTypesFetcher,
} from '@atlassian/jira-polaris-component-issue-types/src/index.tsx';
import {
	NavigationMetadataContainer,
	useActions as useNavigationMetadataActions,
} from '@atlassian/jira-polaris-component-navigation-metadata-store/src/controllers/index.tsx';
import {
	NavigationContainer,
	useNavigationActions,
} from '@atlassian/jira-polaris-component-navigation-store/src/controllers/views/index.tsx';
import { useNavigationViews } from '@atlassian/jira-polaris-component-navigation-store/src/controllers/views/selectors/views-hooks.tsx';
import {
	PermissionsContainer,
	usePermissionActions,
} from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/main.tsx';
import {
	useCanMakeBulkChanges,
	useCanManageViews,
	useCanRankIdeas,
	useHasNoProjectPermissions,
	useIsAdmin,
	useIsLoadedPermissions,
	useIsProjectAdmin,
} from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { getProjectContainerScope } from '@atlassian/jira-polaris-component-permissions-store/src/utils.tsx';
import { ContainerProjectMetadataProvider } from '@atlassian/jira-polaris-component-project-metadata/src/index.tsx';
import { ViewAccessPermissionStoreInitializer } from '@atlassian/jira-polaris-component-view-access/src/controllers/store-initializer/index.tsx';
import { EXTERNAL_REFERENCE_PROVIDERS } from '@atlassian/jira-polaris-domain-field/src/field/external-reference/types.tsx';
import type { ViewAccessLevel } from '@atlassian/jira-polaris-domain-view/src/view-access/types.tsx';
import { fireAnalyticsEventForIssueUpdate } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import { EmojiContainer } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';
import {
	useOnCyclicFormulaFieldError,
	useErrorHandlers,
} from '@atlassian/jira-polaris-lib-errors/src/controllers/index.tsx';
import { useFieldRemote } from '@atlassian/jira-polaris-remote-field/src/main.tsx';
import { useGlobalFieldRemote } from '@atlassian/jira-polaris-remote-global-field/src/index.tsx';
import { useInsightRemote } from '@atlassian/jira-polaris-remote-insight/src/controllers/index.tsx';
import { useIssueRemote } from '@atlassian/jira-polaris-remote-issue/src/main.tsx';
import { useProjectConfig } from '@atlassian/jira-polaris-remote-legacy-project/src/services/project-config/main.tsx';
import { usePlayContributionRemote } from '@atlassian/jira-polaris-remote-play-contribution/src/main.tsx';
import { useProjectRemote } from '@atlassian/jira-polaris-remote-project/src/main.tsx';
import { useRankingRemote } from '@atlassian/jira-polaris-remote-ranking/src/controllers/index.tsx';
import { useViewRemote } from '@atlassian/jira-polaris-remote-view/src/main.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { useIsSiteAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-site-admin/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { useQueryParam } from '@atlassian/react-resource-router';
import { useProjectsIdsForContainer } from '@atlassian/jira-polaris-component-project-metadata/src/controllers/index.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import { useFieldByType } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/fields-hooks.tsx';
import {
	ProjectPropertiesLoader,
	useProjectPropertiesActions as useProjectPropertiesActionsNext,
} from '@atlassian/jira-polaris-component-project-properties/src/controllers/index.tsx';
import { PROJECT_PROPERTIES } from '@atlassian/jira-polaris-component-project-properties/src/controllers/types.tsx';
import { useIsAtlasIntegrationEnabledProperty } from '@atlassian/jira-polaris-component-project-properties/src/controllers/selectors/properties/index.tsx';
import { useProductSettingsActions } from '@atlassian/jira-polaris-component-product-settings-store/src/controllers/index.tsx';
import { useIssueTypesForCurrentContainerProjectIds } from '@atlassian/jira-polaris-component-issue-types/src/utils.tsx';
import { useAllStatusesById } from '@atlassian/jira-polaris-common/src/controllers/workflow/selectors/statuses-hooks.tsx';
import { TrackSelectedIdeasVisibility } from '../common/utils/track-selected-ideas-visibility.tsx';
import { PolarisIdeaViewDataContainer } from './idea/main.tsx';
import type { MergeIdeasDataContainerProps, PolarisDataContainerProps } from './types.tsx';
import { FireRoleAnalytics } from './utils.tsx';

export const FieldDataContainer = ({ children, containerAri }: PolarisDataContainerProps) => {
	const { generalActionFailedError, generalDataLoadingFailedError, generalDataUpdateFailedError } =
		useErrorHandlers();
	const onCyclicFormulaDependencyDetected = useOnCyclicFormulaFieldError();

	const projectId = useProjectId();
	const issueTypeIds = useIssueTypeIdsForProject({ projectId });

	const { cloudId, environment } = useTenantContext();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [hasNoProjectPermissions] = useHasNoProjectPermissions();
	const [isProjectOnboarded] = useIsProjectOnboarded();
	const isSiteAdmin = useIsSiteAdmin();
	const [isProjectAdmin] = useIsProjectAdmin();
	const isAdmin = useIsAdmin();
	const [isGlobalAtlasExperience, setIsGlobalExperience] = useState(false);

	const { deletePlay } = useProjectActions();
	const [, { saveProperty }] = useProjectPropertiesActions();
	const { saveProperty: savePropertyNext } = useProjectPropertiesActionsNext();

	const [{ isLoading, value: isAtlasIntegrationEnabled }] = useProjectProperty(
		ProjectProperties.ATLAS_INTEGRATION_ENABLED,
	);
	const { isLoading: isLoadingNext, value: isAtlasIntegrationEnabledNext } =
		useIsAtlasIntegrationEnabledProperty();

	useEffect(() => {
		isGlobalExperienceWorkspaceForCloudId(cloudId).then((isGlobalExperience) =>
			setIsGlobalExperience(isGlobalExperience),
		);
	}, [cloudId]);

	const fieldRemote = useFieldRemote();
	const globalFieldRemote = useGlobalFieldRemote();
	const isCollectionView = useIsCollectionView();

	const isAtlasIntegrationOn = useMemo(() => {
		if (fg('polaris_extract-project-properties')) {
			// If Atlas Integration is explicitly disabled, return false
			if (isAtlasIntegrationEnabledNext === false) {
				return false;
			}

			const shouldEnableAtlasIntegration =
				isAtlasIntegrationEnabledNext ||
				(isAtlasIntegrationEnabledNext === undefined && (isProjectAdmin || isAdmin));

			return fg('jpd_turn_on_atlas_integration')
				? shouldEnableAtlasIntegration
				: isGlobalAtlasExperience && shouldEnableAtlasIntegration;
		}

		// If Atlas Integration is explicitly disabled, return false
		if (isAtlasIntegrationEnabled === false) {
			return false;
		}

		const shouldEnableAtlasIntegration =
			isAtlasIntegrationEnabled ||
			(isAtlasIntegrationEnabled === null && (isProjectAdmin || isAdmin));

		return !!(fg('jpd_turn_on_atlas_integration')
			? shouldEnableAtlasIntegration
			: isGlobalAtlasExperience && shouldEnableAtlasIntegration);
	}, [
		isAdmin,
		isAtlasIntegrationEnabled,
		isAtlasIntegrationEnabledNext,
		isGlobalAtlasExperience,
		isProjectAdmin,
	]);

	const handleAtlasIntegration = useCallback(() => {
		if (fg('polaris_extract-project-properties')) {
			savePropertyNext(PROJECT_PROPERTIES.ATLAS_INTEGRATION_ENABLED, true);
		} else {
			saveProperty(ProjectProperties.ATLAS_INTEGRATION_ENABLED, true);
		}
	}, [saveProperty, savePropertyNext]);

	useEffect(() => {
		if (fg('polaris_extract-project-properties')) {
			if (isAtlasIntegrationEnabledNext || isLoadingNext) {
				return;
			}
		} else if (isAtlasIntegrationEnabled || isLoading) {
			return;
		}
		if (isAtlasIntegrationOn && (isProjectAdmin || isAdmin)) {
			handleAtlasIntegration();
		}
	}, [
		isLoading,
		isLoadingNext,
		isAtlasIntegrationOn,
		handleAtlasIntegration,
		isAtlasIntegrationEnabled,
		isAtlasIntegrationEnabledNext,
		isProjectAdmin,
		isAdmin,
	]);

	return (
		<FieldContainer
			scope="field-singleton"
			containerAri={containerAri}
			fieldRemote={fieldRemote}
			globalFieldRemote={globalFieldRemote}
			hasNoProjectPermissions={hasNoProjectPermissions}
			isProjectOnboarded={isProjectOnboarded}
			deletePlay={deletePlay}
			onFieldLoadingFailed={generalDataLoadingFailedError}
			onDecorationUpdateFailed={generalDataUpdateFailedError}
			onFieldUpdateFailed={generalDataUpdateFailedError}
			onActionFailed={generalActionFailedError}
			onCyclicFormulaDependencyDetected={onCyclicFormulaDependencyDetected}
			createAnalyticsEvent={createAnalyticsEvent}
			isAtlasIntegrationEnabled={isAtlasIntegrationOn}
			projectId={projectId}
			issueTypeIds={issueTypeIds}
			cloudId={cloudId}
			isSiteAdmin={isSiteAdmin}
			environment={environment}
			isCollectionView={isCollectionView}
		>
			{children}
		</FieldContainer>
	);
};

export const ViewDataContainer = ({ children, containerAri }: PolarisDataContainerProps) => {
	const [fieldsByKey] = useFieldsByKey();
	const { generalActionFailedError, generalDataLoadingFailedError, generalDataUpdateFailedError } =
		useErrorHandlers();
	const currentRoute = useCurrentPolarisRoute();
	const router = usePolarisRouter();
	const currentUser = useCurrentUser();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const cloudId = useCloudId();

	const [hasNoProjectPermissions] = useHasNoProjectPermissions();
	const canManageViews = useCanManageViews();

	const viewRemote = useViewRemote();
	const rankingRemote = useRankingRemote();

	const projectOnboardedAt = useProjectOnboardedAtTimestamp();
	const isIssueOpenInSidebar = useIsIssueOpenInSidebar();

	const isCollectionView = useIsCollectionView();

	const { syncViewName, syncViewEmoji, syncViewDeletion } = useNavigationActions();

	const NavigationViewsSynchronizer =
		getWillShowNav4() && fg('jpd-sidebar-v4-for-roadmaps')
			? NavigationViewsSynchronizerNext
			: NavigationViewsSynchronizerLegacy;

	const issueTypes = useIssueTypesForCurrentContainerProjectIds();

	return (
		<>
			<ViewAccessPermissionStoreInitializer hasManageViewsPermission={canManageViews} />
			<ViewContainer
				scope="view-singleton"
				containerAri={containerAri}
				currentViewSection={currentRoute?.section}
				currentViewSlug={currentRoute?.resource}
				isCollectionView={isCollectionView}
				fields={fieldsByKey}
				currentUser={currentUser}
				createAnalyticsEvent={createAnalyticsEvent}
				onViewLoadingFailed={generalDataLoadingFailedError}
				onViewUpdateFailed={generalDataUpdateFailedError}
				onActionFailed={generalActionFailedError}
				router={router}
				cloudId={cloudId}
				hasNoProjectPermissions={hasNoProjectPermissions}
				viewRemote={viewRemote}
				rankingRemote={rankingRemote}
				projectOnboardedAt={projectOnboardedAt}
				hasManageViewsPermission={canManageViews}
				onDeliveryDataFetchFailed={generalDataLoadingFailedError}
				delayDeliveryDataFetchFailedNotification={!isIssueOpenInSidebar}
				onSyncViewName={getWillShowNav4() ? syncViewName : noop}
				onSyncViewEmoji={getWillShowNav4() ? syncViewEmoji : noop}
				onSyncViewDeletion={getWillShowNav4() ? syncViewDeletion : noop}
				issueTypes={issueTypes}
			>
				<ViewPermissionsCurrentViewDataLoader />
				{getWillShowNav4() && <NavigationViewsSynchronizer />}
				{getWillShowNav4() && <NavigationMetadataSynchronizer />}
				{getWillShowNav4() && <FullScreenSyncronizer />}
				{children}
			</ViewContainer>
		</>
	);
};

const NavigationMetadataSynchronizer = () => {
	const { setIsProjectOnboarded } = useNavigationMetadataActions();
	const [isProjectOnboarded] = useIsProjectOnboarded();
	const projectId = useProjectIdUnsafe();

	useEffect(() => {
		isProjectOnboarded && setIsProjectOnboarded(projectId, isProjectOnboarded);
	}, [isProjectOnboarded, projectId, setIsProjectOnboarded]);

	return null;
};

const NavigationViewsSynchronizerLegacy = () => {
	const { syncViewsWithNavigation } = useViewActions();
	const projectId = useProjectIdUnsafe();
	const [navigationViews] = useNavigationViews({ projectId });

	useEffect(() => {
		syncViewsWithNavigation(navigationViews);
	}, [navigationViews, syncViewsWithNavigation]);

	return null;
};

const NavigationViewsSynchronizerNext = () => {
	const containerId = useEnvironmentContainerId();

	if (containerId) {
		return <NavigationViewsSynchronizerSafe containerId={containerId} />;
	}

	return null;
};

const NavigationViewsSynchronizerSafe = ({ containerId }: { containerId: string }) => {
	const { syncViewsWithNavigation } = useViewActions();
	const [navigationViews] = useNavigationViews({ projectId: containerId });

	useEffect(() => {
		syncViewsWithNavigation(navigationViews);
	}, [navigationViews, syncViewsWithNavigation]);

	return null;
};

// Syncs the fullscreen query parameter with the fullscreen layout state
const FullScreenSyncronizer = () => {
	const fullscreen = useFullscreenQueryParameter();
	const [_, { setIsFullscreen: setIsFullScreenLayout }] = useIsFullscreen();

	useEffect(() => {
		// Set the fullscreen layout state to the value of the fullscreen query parameter is it is defined
		if (fullscreen !== undefined) {
			setIsFullScreenLayout(fullscreen);
		}

		return () => {
			if (fg('polaris_pol-12839_view_header_redesign')) {
				setIsFullScreenLayout(false);
			}
		};
	}, [fullscreen, setIsFullScreenLayout]);

	return null;
};

const ViewPermissionsCurrentViewDataLoader = () => {
	const { setHasManageCurrentViewPermission, setCurrentViewAccessLevel } = useViewActions();
	const viewId = useCurrentViewId();
	const prevCanManageCurrentViewRef = useRef<boolean | undefined>(false);
	const canManageCurrentView = useCanManageView(viewId);
	const prevViewAccessLevel = useRef<ViewAccessLevel | undefined>(undefined);
	const viewAccessLevel = useViewAccessLevel(viewId);
	useRemoveViewIfNoAccess(viewId);

	if (viewAccessLevel !== prevViewAccessLevel.current) {
		prevViewAccessLevel.current = viewAccessLevel;
		setCurrentViewAccessLevel(viewAccessLevel);
	}

	if (canManageCurrentView !== prevCanManageCurrentViewRef.current) {
		prevCanManageCurrentViewRef.current = canManageCurrentView;
		setHasManageCurrentViewPermission(canManageCurrentView);
	}

	return null;
};

export const WorkflowDataContainer = ({ children }: { children: ReactNode }) => {
	const projectId = useProjectId();
	const issueTypeIds = useIssueTypeIdsForProject({ projectId });

	const [isProjectLoading] = useIsProjectLoading();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { generalActionFailedError } = useErrorHandlers();

	const statusCategories = useStatusCategories();
	const issuesRemote = useIssueRemote();

	const containerId = useEnvironmentContainerId();
	const projectIds = useProjectsIdsForContainer({ containerId });

	const issueTypeIdsByProjectId = useAllIssueTypeIdsByProjectId();

	return (
		<WorkflowContainer
			issuesRemote={issuesRemote}
			statusCategories={statusCategories}
			projectId={projectId}
			projectIds={projectIds}
			issueTypeIds={issueTypeIds}
			issueTypeIdsByProjectId={issueTypeIdsByProjectId}
			createAnalyticsEvent={createAnalyticsEvent}
			onActionFailed={generalActionFailedError}
			isProjectLoading={isProjectLoading}
		>
			{children}
		</WorkflowContainer>
	);
};

export const IssueDataContainer = ({ children }: { children: ReactNode }) => {
	const useInsightsByIssueId = fg('jpd-aurora-roadmap-insights-field')
		? useInsightsByIssueIdNew
		: useInsightsByIssueIdOld;

	const { locale } = useTenantContext();
	const timezone = useCurrentUserTimezone();
	const projectId = useProjectId();

	const issueTypeIdsForProject = useIssueTypeIdsForProject({ projectId });
	const allIssueTypeIds = useAllIssueTypeIds();
	const issueTypeIds = fg('jpd_cross_project_connecting')
		? allIssueTypeIds
		: issueTypeIdsForProject;

	const issueLinkTypes = useIssueLinkTypes();
	const [fields] = useAllFieldsArray();
	const insights = useInsightsByIssueId();
	const plays = useGetPlayContributionsByIssueId();
	const [fieldValueDecorations] = useFieldValueDecorations();
	const [jiraRankFieldKey] = useJiraRankFieldKey();
	const [archivedFieldsConfig] = useArchivedFieldsConfig();
	const sortBy = useCurrentViewSortBy();
	const filter = useCurrentViewFilter();
	const containsArchived = useCurrentViewContainsArchived();
	const polarisDeliveryIssueLinkType = useDeliveryIssueLinkType();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { generalActionFailedError } = useErrorHandlers();
	const apolloClient = useApolloClient();
	const reactionsClient = useReactionClient();
	const cloudId = useCloudId();
	const { refreshSnippets } = useInsightRemote();
	const [selectedIssue] = useQueryParam('selectedIssue');
	const embedded = useIsEmbedded();
	const currentRoute = useCurrentPolarisRoute();

	const onContainerReady = useCallback(() => refreshSnippets?.(), [refreshSnippets]);
	const currentUser = useCurrentUser();
	const arjConfiguration = useArjConfiguration();
	const arjHierarchyConfiguration = useArjHierarchyConfiguration();
	const arjLicensed = useArjLicensed();
	const hiddenIssueLinkTypes = useHiddenPolarisIssueLinkTypes();
	const transitions = useTransitionsMap();
	const selectedIssueIds = useCurrentViewSelectedIssueIdsAsList();
	const sortMode = useCurrentViewSortMode();
	const externalRanks = useCurrentViewIssueRanking();
	const canRankIdeas = useCanRankIdeas();
	const issuesRemote = useIssueRemote();
	const insightsRemote = useInsightRemote();
	const playContributionRemote = usePlayContributionRemote();
	const [hasBulkChangePermission] = useCanMakeBulkChanges();
	const [hasNoProjectPermissions] = useHasNoProjectPermissions();
	const [permissionsLoaded] = useIsLoadedPermissions();
	const isCollectionView = useIsCollectionView();

	const projectOnboardedAt = useProjectOnboardedAtTimestamp();
	const projectTemplateVersion = useProjectTemplateVersion();
	const isIssueOpenInSidebar = useIsIssueOpenInSidebar();
	const isSelectedIssueSet = useSelectedIssueQueryParameter() !== undefined;
	const singleIdeaVisible = isSelectedIssueSet && !isIssueOpenInSidebar;
	const { handleDeliveryDataFetchError } = useViewActions();
	const loadInsightsNew = useInsightsLoader();
	const { loadInsights: loadInsightsOld } = useProjectActions();
	const loadInsights = fg('jpd-aurora-roadmap-insights-field') ? loadInsightsNew : loadInsightsOld;
	const { fetchArchivedSnippetProviders } = useFieldActions();
	const handleLoadArchivedIssues = useCallback(() => {
		loadInsights({
			archivedOnly: true,
		});
		fetchArchivedSnippetProviders();
	}, [loadInsights, fetchArchivedSnippetProviders]);

	const containerId = useEnvironmentContainerId();
	const projectIds = useProjectsIdsForContainer({ containerId });

	const statusesById = useAllStatusesById();

	return (
		<IssuesContainer
			insightsRemote={insightsRemote}
			issuesRemote={issuesRemote}
			playContributionRemote={playContributionRemote}
			hasBulkChangePermissions={hasBulkChangePermission}
			hasNoProjectPermissions={hasNoProjectPermissions}
			arjLicensed={arjLicensed}
			arjConfiguration={arjConfiguration}
			arjHierarchyConfiguration={arjHierarchyConfiguration}
			currentUser={currentUser}
			locale={locale}
			timezone={timezone}
			projectId={projectId}
			projectIds={projectIds}
			issueTypeIds={issueTypeIds}
			statusesById={statusesById}
			issueLinkTypes={issueLinkTypes}
			fields={fields}
			insights={insights}
			plays={plays}
			rankField={jiraRankFieldKey}
			archivedFieldsConfig={archivedFieldsConfig}
			sortMode={sortMode}
			sortBy={sortBy}
			externalIssueRanking={externalRanks}
			filter={filter}
			containsArchived={containsArchived}
			decorations={fieldValueDecorations}
			polarisIssueLinkType={polarisDeliveryIssueLinkType}
			hiddenIssueLinkTypes={hiddenIssueLinkTypes}
			createAnalyticsEvent={createAnalyticsEvent}
			onActionFailed={generalActionFailedError}
			apolloClient={apolloClient}
			reactionsClient={reactionsClient}
			cloudId={cloudId}
			onContainerReady={onContainerReady}
			embedded={embedded}
			onLoadArchivedIssues={handleLoadArchivedIssues}
			currentViewSelectedIssueId={selectedIssueIds}
			selectedIssue={selectedIssue !== undefined ? toIssueKey(selectedIssue) : selectedIssue}
			workflowTransitions={transitions}
			isRankingEnabled={canRankIdeas}
			projectOnboardedAt={projectOnboardedAt}
			projectTemplateVersion={projectTemplateVersion}
			permissionsLoaded={permissionsLoaded}
			singleIdeaVisible={singleIdeaVisible}
			onDeliveryDataFetchFailed={handleDeliveryDataFetchError}
			selectedViewId={currentRoute?.resource}
			isCollectionView={isCollectionView}
		>
			{children}
		</IssuesContainer>
	);
};

export const ProjectDataContainer = ({ children, containerAri }: PolarisDataContainerProps) => {
	const [projectConfigFetcher] = useProjectConfig();
	const { generalActionFailedError, generalDataLoadingFailedError } = useErrorHandlers();
	const apolloClient = useApolloClient();
	const cloudId = useCloudId();
	const insightsRemote = useInsightRemote();
	const projectRemote = useProjectRemote();
	const playContributionRemote = usePlayContributionRemote();
	const { isSiteAdmin, isAdmin } = useTenantContext();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	return (
		<>
			{fg('jpd-aurora-roadmap-insights-field') && <InsightsLoader fireAnalyticsEvent />}
			<ProjectContainer
				// Removing insightsRemote prop when cleaning FG 'jpd-aurora-roadmap-insights-field'
				insightsRemote={insightsRemote}
				projectRemote={projectRemote}
				playContributionRemote={playContributionRemote}
				isSiteAdmin={isSiteAdmin || isAdmin}
				scope="project-singleton"
				containerAri={containerAri}
				createAnalyticsEvent={createAnalyticsEvent}
				projectFetcher={projectConfigFetcher}
				onProjectLoadingError={generalDataLoadingFailedError}
				onActionFailed={generalActionFailedError}
				apolloClient={apolloClient}
				cloudId={cloudId}
			>
				{children}
			</ProjectContainer>
		</>
	);
};

export const AtlasDataContainer = ({ children }: { children: ReactNode }) => {
	const goalArisFromJPDField = useAllExternalReferencesPropertiesForProvider(
		EXTERNAL_REFERENCE_PROVIDERS.ATLAS_GOAL,
	);
	const projectAris = useAllExternalReferencesPropertiesForProvider(
		EXTERNAL_REFERENCE_PROVIDERS.ATLAS_PROJECT,
	);
	const goalArisFromPlatformField = useAllGoalsAri();
	const platformGoalsField = useFieldByType(FIELD_TYPES.PLATFORM_GOALS);
	const isPlatformGoalsEnabled = fg('jpd_platform_goals_field_support') && platformGoalsField;

	const goalAris = isPlatformGoalsEnabled ? goalArisFromPlatformField : goalArisFromJPDField;

	const apolloClient = useApolloClient();
	const { updateExternalReferenceEntities } = useIssueActions();
	const isIssuesLoading = useIsIssuesLoading();
	const isIssuesInitialized = useIsIssuesInitialized();
	const isFieldsInitialized = useIsFieldsInitialized();
	const isSingleIssueLoaded = useIsSingleIssueLoaded();

	return (
		<AtlasContainer
			scope="atlas-singleton"
			goalAris={goalAris}
			projectAris={projectAris}
			apolloClient={apolloClient}
			isSingleIssueLoaded={isSingleIssueLoaded && isFieldsInitialized}
			initialized={isIssuesInitialized && !isIssuesLoading}
			onUpdate={updateExternalReferenceEntities}
		>
			{children}
		</AtlasContainer>
	);
};

const EntityLimitsDataContainer = ({ children }: { children: ReactNode }) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	return (
		<EntityLimitsContainer isGlobal createAnalyticsEvent={createAnalyticsEvent}>
			{children}
		</EntityLimitsContainer>
	);
};

export const PolarisRootDataContainer = ({ children, containerAri }: PolarisDataContainerProps) => {
	const isCollectionView = useIsCollectionView();
	const isEmbeddedView = useIsEmbedded();
	const isSharedView = useIsSharedView();
	const projectId = useProjectIdUnsafe();
	const containerId = useEnvironmentContainerId();
	const projectIds = useProjectsIdsForContainer({ containerId });

	return (
		<PermissionsContainer
			isCollectionView={isCollectionView}
			isEmbeddedView={isEmbeddedView}
			isSharedView={isSharedView}
			scope={getWillShowNav4() ? getProjectContainerScope(projectId) : 'permissions-singleton'}
			containerAri={containerAri}
			onLoadStart={jpdProjectPageLoadPermissionsStart}
			onLoadEnd={jpdProjectPageLoadPermissionsEnd}
			projectIds={projectIds}
		>
			<FireRoleAnalytics />
			<EntityLimitsDataContainer>
				<ProjectDataContainer containerAri={containerAri}>
					<EnvironmentTenantProvider />
					{children}
				</ProjectDataContainer>
			</EntityLimitsDataContainer>
		</PermissionsContainer>
	);
};

export const MergeIdeasDataContainer = ({ children }: MergeIdeasDataContainerProps) => {
	const cloudId = useCloudId();
	const projectId = useProjectIdUnsafe();
	const polarisDeliveryIssueLinkType = useDeliveryIssueLinkType();
	const polarisMergeIssueLinkType = useMergeIssueLinkType();
	const polarisDataPointLinkType = useDataPointIssueLinkType();
	const { archiveIssues, execWithIssueAnalyticsData, updateIssueConnections, updateIssueGoals } =
		useIssueActions();

	const { onSuccess, onFailure } = useMergeNotifications();
	const apolloClient = useApolloClient();
	const { openIssueView } = usePolarisRouter();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const idMap = useLocalIssueIdsByJiraIssueId();
	const [hasNoProjectPermissions] = useHasNoProjectPermissions();

	const insightsRemote = useInsightRemote();
	const issuesRemote = useIssueRemote();

	const [fields] = useFieldsByKey();

	return (
		<MergeIdeasDialogContainer
			insightsRemote={insightsRemote}
			issuesRemote={issuesRemote}
			hasNoProjectPermissions={hasNoProjectPermissions}
			apolloClient={apolloClient}
			cloudId={cloudId}
			projectId={projectId}
			polarisDeliveryIssueLinkType={polarisDeliveryIssueLinkType}
			polarisMergeIssueLinkType={polarisMergeIssueLinkType}
			polarisDataPointIssueLinkType={polarisDataPointLinkType}
			fields={fields}
			onUpdateIssueConnections={updateIssueConnections}
			onUpdateIssueGoals={updateIssueGoals}
			onSuccess={(targetIssue, mergedIssues) => {
				archiveIssues(mergedIssues.map(({ localIssueId }) => localIssueId));
				onSuccess(targetIssue);
				execWithIssueAnalyticsData(idMap[targetIssue.id], (issueAnalyticsData) => {
					fireAnalyticsEventForIssueUpdate(createAnalyticsEvent({}), targetIssue.id.toString(), {
						...issueAnalyticsData,
						issueId: targetIssue.id.toString(),
						mergedIssueIds: mergedIssues.map((i) => i.id.toString()),
					});
				});
				if (targetIssue.isSameProject === true) {
					openIssueView(targetIssue.key);
				}
			}}
			onFailure={onFailure}
		>
			{children}
		</MergeIdeasDialogContainer>
	);
};

const SharingDataContainer = ({ children }: PropsWithChildren) => {
	const { appEditions } = useTenantContext();
	const projectId = useProjectId();
	const viewUUID = useCurrentViewUUID();
	const viewRemote = useViewRemote();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [fields] = useFieldsArray();

	const { generalActionFailedError, generalDataLoadingFailedError } = useErrorHandlers();

	return (
		<SharingContainer
			fields={fields}
			edition={appEditions.productDiscovery}
			projectId={projectId}
			viewUUID={viewUUID}
			onActionFailed={generalActionFailedError}
			onLoadFailed={generalDataLoadingFailedError}
			viewRemote={viewRemote}
			createAnalyticsEvent={createAnalyticsEvent}
		>
			{children}
		</SharingContainer>
	);
};

const GlobalFieldsDataContainer = ({ children }: PropsWithChildren) => {
	const projectId = useProjectIdUnsafe();
	const globalFieldRemote = useGlobalFieldRemote();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [visibleFieldsByKey] = useVisibleFieldsByKey();

	return (
		<GlobalFieldsContainer
			globalFieldRemote={globalFieldRemote}
			projectId={projectId}
			createAnalyticsEvent={createAnalyticsEvent}
			visibleFieldsByKey={visibleFieldsByKey}
		>
			{children}
		</GlobalFieldsContainer>
	);
};

const EmojiDataContainer = ({ children }: PropsWithChildren) => {
	const { cloudId } = useTenantContext();
	const currentUser = useCurrentUser();
	return (
		<EmojiContainer cloudId={cloudId} currentUser={currentUser}>
			{children}
		</EmojiContainer>
	);
};

const WithNavigationContainerLegacy = ({
	children,
	isCollectionView,
}: {
	children: React.ReactNode;
	isCollectionView: boolean;
}) => {
	const projectId = useProjectIdUnsafe();
	const { cloudId } = useTenantContext();
	if (getWillShowNav4()) {
		return (
			<NavigationMetadataContainer isGlobal cloudId={cloudId} projectId={projectId}>
				<NavigationContainer
					isGlobal
					containerType={isCollectionView ? 'COLLECTION' : 'PROJECT'}
					cloudId={cloudId}
					projectId={projectId}
				>
					{children}
				</NavigationContainer>
			</NavigationMetadataContainer>
		);
	}
	return <>{children}</>;
};

const WithNavigationContainerNext = ({
	children,
	isCollectionView,
}: {
	children: React.ReactNode;
	isCollectionView: boolean;
}) => {
	const containerId = useEnvironmentContainerId();
	const { cloudId } = useTenantContext();

	if (getWillShowNav4() && containerId) {
		return (
			<NavigationMetadataContainer isGlobal cloudId={cloudId} projectId={containerId}>
				<NavigationContainer
					isGlobal
					containerType={isCollectionView ? 'COLLECTION' : 'PROJECT'}
					cloudId={cloudId}
					projectId={containerId}
				>
					{children}
				</NavigationContainer>
			</NavigationMetadataContainer>
		);
	}

	return <>{children}</>;
};

const IssueTypesFetcher = () => {
	const container = useEnvironmentContainer();
	const containerProjectIds = useProjectsIdsForContainer({ containerId: container?.id });
	const uniqConnectionFieldsProjectIds = useUniqConnectionFieldsProjectIds();
	const fieldsLoading = useFieldsLoading();

	const projectIds = useMemo(
		() => [...new Set([...containerProjectIds, ...uniqConnectionFieldsProjectIds])],
		[uniqConnectionFieldsProjectIds, containerProjectIds],
	);

	if (fieldsLoading) {
		return null;
	}

	return <ProjectsIssueTypesFetcher projectIds={projectIds} />;
};

const WithNavigationContainer = componentWithFG(
	'jpd-sidebar-v4-for-roadmaps',
	WithNavigationContainerNext,
	WithNavigationContainerLegacy,
);

export const PolarisDataContainer = ({ children, containerAri }: PolarisDataContainerProps) => {
	const { atlassianAccountId, cloudId } = useTenantContext();
	const projectId = useProjectIdUnsafe();
	const [selectedIssue] = useQueryParam('selectedIssue');
	const { setContributorCreateIssuePermission } = usePermissionActions();
	const onIssueCreateFormEnabled = useCallback(
		(enabled: boolean) => setContributorCreateIssuePermission(enabled),
		[setContributorCreateIssuePermission],
	);
	const isCollectionView = useIsCollectionView();
	const { loadProductSettings } = useProductSettingsActions();

	useEffect(() => {
		if (fg('polaris_pol-14239_custom_quarters')) {
			loadProductSettings();
		}
	}, [loadProductSettings]);

	return (
		<>
			{!isCollectionView && fg('polaris_extract-project-properties') && (
				<ProjectPropertiesLoader projectId={projectId} />
			)}
			{fg('polaris_extract-project-properties') ? (
				<>
					<ContainerProjectMetadataProvider />
					{!fg('jpd_issue_types_ga') && !fg('jpd-aurora-roadmap-inline-edit') && (
						<ProjectIssueTypesFetcher projectId={projectId} />
					)}
					<WithNavigationContainer isCollectionView={isCollectionView}>
						<UserPropertiesContainer accountId={atlassianAccountId}>
							<FieldDataContainer containerAri={containerAri}>
								{(fg('jpd-aurora-roadmap-inline-edit') || fg('jpd_issue_types_ga')) && (
									<IssueTypesFetcher />
								)}
								<GlobalFieldsDataContainer>
									<DynamicFieldContainer>
										<FieldAriProvider onlyFieldKeys={isCollectionView} />
										<ViewDataContainer containerAri={containerAri}>
											<WorkflowDataContainer>
												<IssueDataContainer>
													<PolarisIdeaViewDataContainer issueKey={selectedIssue}>
														<TrackSelectedIdeasVisibility />
														<MergeIdeasDataContainer>
															<AvailableProductsContainer cloudId={cloudId}>
																<LicenceInformationContainer>
																	<AtlasDataContainer>
																		<SharingDataContainer>
																			<EmojiDataContainer>{children}</EmojiDataContainer>
																		</SharingDataContainer>
																	</AtlasDataContainer>
																</LicenceInformationContainer>
															</AvailableProductsContainer>
														</MergeIdeasDataContainer>
													</PolarisIdeaViewDataContainer>
												</IssueDataContainer>
											</WorkflowDataContainer>
										</ViewDataContainer>
									</DynamicFieldContainer>
								</GlobalFieldsDataContainer>
							</FieldDataContainer>
						</UserPropertiesContainer>
					</WithNavigationContainer>
				</>
			) : (
				<ProjectPropertiesContainer
					projectIdOrKey={projectId}
					preloadProperties={PRELOAD_PROJECT_PROPERTIES}
					onSubmitIdeaFormEnabled={onIssueCreateFormEnabled}
				>
					<ContainerProjectMetadataProvider />
					{!fg('jpd_cross_project_connecting') && (
						<ProjectIssueTypesFetcher projectId={projectId} />
					)}
					<WithNavigationContainer isCollectionView={isCollectionView}>
						<UserPropertiesContainer accountId={atlassianAccountId}>
							<FieldDataContainer containerAri={containerAri}>
								{fg('jpd_cross_project_connecting') && <IssueTypesFetcher />}

								<GlobalFieldsDataContainer>
									<DynamicFieldContainer>
										<FieldAriProvider onlyFieldKeys={isCollectionView} />
										<ViewDataContainer containerAri={containerAri}>
											<WorkflowDataContainer>
												<IssueDataContainer>
													<PolarisIdeaViewDataContainer issueKey={selectedIssue}>
														<TrackSelectedIdeasVisibility />
														<MergeIdeasDataContainer>
															<AvailableProductsContainer cloudId={cloudId}>
																<LicenceInformationContainer>
																	<AtlasDataContainer>
																		<SharingDataContainer>
																			<EmojiDataContainer>{children}</EmojiDataContainer>
																		</SharingDataContainer>
																	</AtlasDataContainer>
																</LicenceInformationContainer>
															</AvailableProductsContainer>
														</MergeIdeasDataContainer>
													</PolarisIdeaViewDataContainer>
												</IssueDataContainer>
											</WorkflowDataContainer>
										</ViewDataContainer>
									</DynamicFieldContainer>
								</GlobalFieldsDataContainer>
							</FieldDataContainer>
						</UserPropertiesContainer>
					</WithNavigationContainer>
				</ProjectPropertiesContainer>
			)}
		</>
	);
};
