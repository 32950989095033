import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	tooltipIssues: {
		id: 'polaris-common.linked-issue-status.tooltip-issues',
		defaultMessage: '{category}: {count} of {total, plural, one {# issue} other {# issues}}',
		description: 'Tooltip text. Represents the count of remaining issues in each status category.',
	},
	tooltipIssuesIssueTermRefresh: {
		id: 'polaris-common.linked-issue-status.tooltip-issues-issue-term-refresh',
		defaultMessage:
			'{category}: {count} of {total, plural, one {# work item} other {# work items}}',
		description:
			'Tooltip text. Represents the count of remaining work items in each status category.',
	},
	tooltipStoryPoints: {
		id: 'polaris-common.linked-issue-status.tooltip-story-points',
		defaultMessage:
			'{category}: {count} of {total, plural, one {# story point} other {# story points}}',
		description:
			'Tooltip text. Represents the count of remaining story points in each status category.',
	},
	unestimatedStatus: {
		id: 'polaris-common.linked-issue-status.unestimated-status',
		defaultMessage: 'Unestimated',
		description: 'Tooltip that appears when hovering the status field that has no estimated issues',
	},
});
