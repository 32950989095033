// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types.tsx';
import { refreshViews } from '../refresh-views/index.tsx';
import { updateProjectState } from '../utils.tsx';

const blockRefresh =
	(): Action<State, Props> =>
	async ({ setState, getState }, props) => {
		const { navigationRemote, cloudId, projectId } = props;
		if (
			!cloudId ||
			!projectId ||
			!navigationRemote ||
			!getState().projects[projectId]?.initialized
		) {
			return;
		}

		const numberOfRefreshBlocks = getState().projects[projectId].numberOfRefreshBlocks + 1;

		setState(
			updateProjectState(getState(), projectId, {
				lastRefreshBlockTime: Date.now(),
				numberOfRefreshBlocks,
			}),
		);
	};

const unblockRefresh =
	(): Action<State, Props> =>
	async ({ setState, getState, dispatch }, props) => {
		const { navigationRemote, cloudId, projectId } = props;
		if (
			!cloudId ||
			!projectId ||
			!navigationRemote ||
			!getState().projects[projectId]?.initialized
		) {
			return;
		}

		const numberOfRefreshBlocks = getState().projects[projectId].numberOfRefreshBlocks - 1;

		setState(
			updateProjectState(getState(), projectId, {
				lastRefreshBlockTime: Date.now(),
				numberOfRefreshBlocks: numberOfRefreshBlocks < 0 ? 0 : numberOfRefreshBlocks,
			}),
		);

		if (getState().projects[projectId].scheduledRefresh) {
			dispatch(refreshViews());
		}
	};

export const withRefreshBlocker = (fn: Action<State, Props>): Action<State, Props> => {
	return async (api, props) => {
		const { dispatch } = api;
		dispatch(blockRefresh());
		try {
			await fn(api, props);
		} finally {
			dispatch(unblockRefresh());
		}
	};
};
