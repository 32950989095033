import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import JpdBoardBlankIllustration from '@atlassian/jira-illustrations/src/ui/adg4/jira/spots/software/components/jpd-board-blank/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { messages as commonMessages } from '../../../common/messages.tsx';
import { Container, Wrapper } from '../../../common/ui/container/styled.tsx';
import { ViewEmptyState } from '../../../common/ui/view-empty-state/index.tsx';
import type { ViewKindEmptyStateProps } from '../../../types.tsx';
import { BOARD_COLUMN_GAP, BOARD_COLUMN_WIDTH } from './constants.tsx';
import { messages } from './messages.tsx';

export const BoardViewEmptyState = ({
	onButtonClick,
	isHintHidden = false,
	interactionName,
}: ViewKindEmptyStateProps) => {
	const { formatMessage } = useIntl();

	return (
		<Container>
			{!isHintHidden && (
				<Wrapper>
					<ViewEmptyState
						renderImage={JpdBoardBlankIllustrationWrapper}
						header={formatMessage(messages.header)}
						description={formatMessage(messages.description)}
						buttonText={formatMessage(commonMessages.buttonTextFields, {
							fieldsCount: 1,
						})}
						onButtonClick={onButtonClick}
						interactionName={interactionName}
					/>
				</Wrapper>
			)}
			<BoardViewPlaceholder>
				{[...Array.from({ length: 8 })].map((_, i) => (
					<ColumnPlaceholder key={i} />
				))}
			</BoardViewPlaceholder>
		</Container>
	);
};

const JpdBoardBlankIllustrationWrapper = () => <JpdBoardBlankIllustration alt="" width={306} />;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const ColumnPlaceholder = styled.div({
	height: 'auto',
	minHeight: '100%',
	borderRadius: '3px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	minWidth: `${BOARD_COLUMN_WIDTH}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	marginRight: `${BOARD_COLUMN_GAP}px`,
	backgroundColor: token('color.background.input.hovered'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const BoardViewPlaceholder = styled.div({
	display: 'flex',
	position: 'absolute',
	width: '100%',
	height: '100%',
	zIndex: '0',
	boxSizing: 'border-box',
	top: '0',
	left: '0',
	paddingRight: '0',
	paddingBottom: token('space.300'),

	'&::before': {
		content: '',
		position: 'absolute',
		width: '100%',
		height: '100%',
		backgroundColor: token('elevation.surface'),
		opacity: '0.6',
		zIndex: '1',
	},
});
