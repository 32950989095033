import { useEffect, useRef } from 'react';
import intersection from 'lodash/intersection';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { IssueTypeId } from '@atlassian/jira-shared-types/src/general.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	createStore,
	createActionsHook,
	createSelector,
	createStateHook,
} from '@atlassian/react-sweet-state';
import * as actions from './actions/index.tsx';
import type { State } from './types.tsx';

const initialState: State = {
	configurations: {},
};

const Store = createStore<State, typeof actions>({
	initialState,
	actions,
	name: 'PolarisIssueTypesConnectionsConfigurationStore',
});

export const useIssueTypesConnectionsActions = createActionsHook(Store);

const getConfigurations = (state: State) => state.configurations;
const getConnectionsForIssueType = createSelector(
	getConfigurations,
	(_, { issueTypeId }: { issueTypeId: IssueTypeId }) => issueTypeId,
	(configurations, issueTypeId) => configurations[issueTypeId],
);

export const useConnectionsFieldKeysForIssueType = createStateHook(Store, {
	selector: getConnectionsForIssueType,
});

const getIsLoading = (state: State) => state.isLoading;
const getError = (state: State) => state.error;

const useIsLoading = createStateHook(Store, {
	selector: getIsLoading,
});
const useError = createStateHook(Store, {
	selector: getError,
});

type UseGetOrLoadConnectionsConfigurationForIssueType = {
	isLoading: boolean;
	configuration: FieldKey[];
	error?: Error;
};

export const useConnectionsConfigurationForIssueType = (
	issueTypeId: IssueTypeId,
	projectId: string | undefined,
	defaultConfiguration: FieldKey[],
): UseGetOrLoadConnectionsConfigurationForIssueType => {
	const hasRequested = useRef(false);
	const { loadConfiguration, setIssueTypeConfiguration } = useIssueTypesConnectionsActions();
	const configuration = useConnectionsFieldKeysForIssueType({ issueTypeId });
	const isLoading = useIsLoading();
	const error = useError();

	useEffect(() => {
		if (!projectId || hasRequested.current) {
			return;
		}

		if (isLoading === undefined || error) {
			loadConfiguration({ projectId, issueTypeId, defaultConfiguration });
			hasRequested.current = true;
			return;
		}

		if (!configuration) {
			// set default configuration if there is no remote configuration for the issue type
			setIssueTypeConfiguration(issueTypeId, defaultConfiguration);
		}
	}, [
		projectId,
		issueTypeId,
		defaultConfiguration,
		isLoading,
		configuration,
		loadConfiguration,
		setIssueTypeConfiguration,
		error,
	]);

	useEffect(() => {
		return () => {
			// whenever the issueTypeId changes, we want to reset the hasRequested flag
			// so we can refetch config in case of errors or set default config
			hasRequested.current = false;
		};
	}, [issueTypeId]);

	return {
		isLoading: isLoading ?? (!configuration && !error),
		// we use intersection here to ensure we don't show deleted connection fields that were saved previously
		configuration: configuration
			? intersection(configuration, defaultConfiguration)
			: defaultConfiguration,
		error,
	};
};
