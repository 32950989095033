import React, { type KeyboardEvent, useState } from 'react';
import { styled } from '@compiled/react';
import Avatar from '@atlaskit/avatar';
import CrossIcon from '@atlaskit/icon/core/migration/close--cross';
import { ButtonItem } from '@atlaskit/menu';
import { keyCodes } from '@atlassian/jira-common-constants/src/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import type { Watcher } from '@atlassian/jira-polaris-common/src/controllers/idea/types.tsx';
import messages from './messages.tsx';

type Props = {
	userInfo: Watcher;
	canDelete: boolean;
	onDelete: (user: Watcher) => void;
};

export const UserItem = ({ onDelete, canDelete, userInfo }: Props) => {
	const { formatMessage } = useIntl();
	const [mouseOver, setMouseOver] = useState(false);

	const { displayName, avatarUrls } = userInfo;
	const avatarUrl = avatarUrls?.['48x48'] ?? '';

	const handleMouseOver = () => {
		setMouseOver(true);
	};

	const handleMouseOut = () => {
		setMouseOver(false);
	};

	const handleDelete = () => {
		onDelete(userInfo);
	};

	const handleKeyDown = (event: KeyboardEvent) => {
		if (event.keyCode === keyCodes.enter) {
			handleDelete();
		}
	};

	const deleteIcon = canDelete ? (
		<DeleteIconStyle
			isHidden={!mouseOver}
			tabIndex={0}
			onClick={handleDelete}
			onKeyDown={handleKeyDown}
		>
			<CrossIcon
				testId="polaris-ideas.ui.idea-view.watchers.users-list.user-item.cross-icon"
				label={formatMessage(messages.removeWatcher)}
				LEGACY_size="small"
			/>
		</DeleteIconStyle>
	) : null;

	return (
		<ButtonItem
			iconBefore={
				<Avatar
					testId="polaris-ideas.ui.idea-view.watchers.users-list.user-item"
					size="small"
					src={avatarUrl}
					borderColor="transparent"
				/>
			}
			iconAfter={deleteIcon}
			// @ts-expect-error - TS2322 - Type '{ children: string; iconBefore: Element; iconAfter: Element | null; onMouseEnter: () => void; onFocus: () => void; onMouseLeave: () => void; onBlur: () => void; }' is not assignable to type 'IntrinsicAttributes & ButtonItemProps & RefAttributes<HTMLElement>'.
			onMouseEnter={handleMouseOver}
			onFocus={handleMouseOver}
			onMouseLeave={handleMouseOut}
			onBlur={handleMouseOut}
		>
			{displayName}
		</ButtonItem>
	);
};

// We hide the icon using `opacity: 0` so it can still be tabbed into.
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DeleteIconStyle = styled.div<{ isHidden?: boolean }>(
	{
		cursor: 'pointer',
		// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
		marginRight: '5px',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props) => (props.isHidden ? 'opacity: 0;' : ''),
);
