import React from 'react';
import { styled } from '@compiled/react';
import Heading from '@atlaskit/heading';
import { Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import SearchNoResultsIllustration from '@atlassian/jira-illustrations/src/ui/adg4/brand/spots/empty/components/search-no-results-old/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useComponents } from '../../../controllers/selectors/components-hooks.tsx';
import type { MatrixEmptyState } from '../../../types.tsx';
import { messages } from './messages.tsx';

type EmptyMatrixContentProps = {
	cause: MatrixEmptyState;
};

export const EmptyMatrixContent = ({ cause }: EmptyMatrixContentProps) => {
	const { formatMessage } = useIntl();
	const components = useComponents();
	const ActionAxis = components?.EmptyStateActionButtons?.axis;

	return (
		<EmptyContainer>
			<SearchNoResultsIllustration alt={formatMessage(messages.emptyMatrix)} width={175} />
			<Box paddingBlockStart="space.500">
				<Heading size="small">{formatMessage(messages.header)}</Heading>
			</Box>
			<Box paddingBlockStart="space.075">
				{formatMessage(cause === 'AXIS' ? messages.axis : messages.filters)}
			</Box>
			{cause === 'AXIS' && ActionAxis && <ActionAxis />}
		</EmptyContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EmptyContainer = styled.div({
	position: 'absolute',
	top: 0,
	left: 0,
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	height: '100%',
	width: '100%',
	flexDirection: 'column',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > button': {
		marginTop: token('space.200'),
	},
});
