import React, { useState, useCallback, useMemo } from 'react';
import { styled } from '@compiled/react';
import {
	startOfQuarter,
	endOfQuarter,
	isBefore,
	isAfter,
	startOfMonth,
	endOfMonth,
} from 'date-fns';
import Spinner from '@atlaskit/spinner';
import Button, { CustomThemeButton } from '@atlaskit/button';
import ChevronLeftLargeIcon from '@atlaskit/icon/utility/migration/chevron-left--chevron-left-large';
import ChevronRightLargeIcon from '@atlaskit/icon/utility/migration/chevron-right--chevron-right-large';
import { Box, Flex, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { formatIsoLocalDate } from '@atlassian/jira-polaris-lib-date-time/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useFinancialYearInitialMonth } from '@atlassian/jira-polaris-component-product-settings-store/src/controllers/selectors/product-settings-hooks.tsx';
import { useIntl } from '@atlassian/jira-intl';
import type { CalendarProps, CalendarState } from '../../../../common/types/date-picker/index.tsx';
import { getInitialMonthPickerDate } from '../../../../common/utils/date/date-picker.tsx';
import { format } from '../../../../common/utils/date/date.tsx';
import { pickerButtonStyle } from '../../utils.tsx';
import messages from './messages.tsx';

const QUARTER_DATA = (() => {
	const today = new Date();
	const quarters = [];
	for (let i = 0; i < 12; i += 3) {
		quarters.push({
			months: [i, i + 1, i + 2],
			name: `${format(new Date(today.getFullYear(), i), 'MMM')}-${format(
				new Date(today.getFullYear(), i + 2),
				'MMM',
			)}`,
		});
	}
	return quarters;
})();

const QuarterPicker = (props: CalendarProps) => {
	const [date, setDate] = useState(getInitialMonthPickerDate(props));
	const financialYearInitialMonth = useFinancialYearInitialMonth();
	const { formatDate, formatMessage } = useIntl();

	const { selectedDate, dateType, onSelect } = props;
	const { month, year, initialYear } = date;

	const CUSTOM_QUARTER_DATA = useMemo(() => {
		const quarters = [];
		const today = new Date();

		for (let i = 0; i < 12; i += 3) {
			const startMonth = ((financialYearInitialMonth || 1) - 1 + i) % 12;
			const endMonth = ((financialYearInitialMonth || 1) - 1 + i + 2) % 12;

			quarters.push({
				months: [startMonth, (startMonth + 1) % 12, (startMonth + 2) % 12],
				name: formatMessage(messages.customQuartersDisplayNames, {
					startMonth: formatDate(new Date(today.getFullYear(), startMonth), {
						month: 'short',
					}),
					endMonth: formatDate(new Date(today.getFullYear(), endMonth), {
						month: 'short',
					}),
				}),
			});
		}
		return quarters;
	}, [financialYearInitialMonth, formatDate, formatMessage]);

	const onDateChange = useCallback(
		(newDate: CalendarState) => {
			setDate(newDate);
			const newDateObject = new Date(newDate.year, newDate.month);
			const newDateInterval = {
				start: formatIsoLocalDate(startOfQuarter(newDateObject)),
				end: formatIsoLocalDate(endOfQuarter(newDateObject)),
			};
			onSelect(newDateInterval);
		},
		[onSelect],
	);

	const onCustomQuarterSelect = useCallback(
		(startQuarter: number, endQuarter: number) => {
			const isCrossingYear = endQuarter < startQuarter;
			const endYear = isCrossingYear ? year + 1 : year;

			const newDateInterval = {
				start: formatIsoLocalDate(startOfMonth(new Date(year, startQuarter))),
				end: formatIsoLocalDate(endOfMonth(new Date(endYear, endQuarter))),
			};
			onSelect(newDateInterval);
		},
		[onSelect, year],
	);

	const isQuarterDisabled = useCallback(
		(isSelected: boolean, endQuarter: number) => {
			if (!selectedDate) {
				return false;
			}
			const currentDate = new Date(year, endQuarter);
			return (
				!isSelected &&
				(dateType === 'start'
					? isBefore(selectedDate, currentDate)
					: isAfter(selectedDate, currentDate))
			);
		},
		[dateType, selectedDate, year],
	);

	if (!financialYearInitialMonth && fg('polaris_pol-14239_custom_quarters')) {
		return (
			<Flex
				testId="polaris-common.ui.date-picker.fuzzy-date-picker-content.quarter-picker.spinner-wrapper"
				alignItems="center"
				justifyContent="center"
				xcss={loadingContainerStyles}
			>
				<Spinner size="large" />
			</Flex>
		);
	}

	return (
		<Box xcss={quarterPickerWrapperStyles}>
			<YearPickerContainer>
				<Button
					appearance="subtle"
					spacing="none"
					onClick={() => setDate({ initialYear, month, year: year - 1 })}
					iconAfter={
						<ChevronLeftLargeIcon
							spacing="spacious"
							label="See previous year"
							color={token('color.icon.subtle')}
						/>
					}
				/>
				<div>
					<b>{year}</b>
				</div>
				<Button
					appearance="subtle"
					spacing="none"
					onClick={() => setDate({ initialYear, month, year: year + 1 })}
					iconAfter={
						<ChevronRightLargeIcon
							spacing="spacious"
							label="See next year"
							color={token('color.icon.subtle')}
						/>
					}
				/>
			</YearPickerContainer>
			<QuarterPickerContainer>
				{fg('polaris_pol-14239_custom_quarters') ? (
					<>
						{CUSTOM_QUARTER_DATA.map((item) => {
							const startQuarter = item.months[0];
							const endQuarter = item.months[2];
							const isSelected = date.initialYear === year && item.months.includes(month);
							return (
								<CustomThemeButton
									key={startQuarter}
									appearance="subtle"
									onClick={() => onCustomQuarterSelect(startQuarter, endQuarter)}
									isSelected={isSelected}
									isDisabled={isQuarterDisabled(isSelected, endQuarter)}
									theme={pickerButtonStyle(isSelected)}
								>
									{item.name}
								</CustomThemeButton>
							);
						})}
					</>
				) : (
					<>
						{QUARTER_DATA.map((item) => {
							const startQuarter = item.months[0];
							const endQuarter = item.months[2];
							const isSelected = date.initialYear === year && item.months.includes(month);
							return (
								<CustomThemeButton
									key={startQuarter}
									appearance="subtle"
									onClick={() => onDateChange({ initialYear, year, month: endQuarter })}
									isSelected={isSelected}
									isDisabled={isQuarterDisabled(isSelected, endQuarter)}
									theme={pickerButtonStyle(isSelected)}
								>
									{item.name}
								</CustomThemeButton>
							);
						})}
					</>
				)}
			</QuarterPickerContainer>
		</Box>
	);
};

export default QuarterPicker;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const YearPickerContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	paddingLeft: token('space.100'),
	paddingRight: token('space.100'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const QuarterPickerContainer = styled.div({
	marginTop: token('space.200'),
	display: 'flex',
	flexWrap: 'wrap',
	justifyContent: 'space-around',
	height: '225px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> *, > button': {
		flex: '0 0 50%',
		height: '105px',
		margin: '0 0 5px',
		'&:hover, &:disabled': {
			height: '105px',
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> button > span': {
		alignSelf: 'center',
	},
});

const quarterPickerWrapperStyles = xcss({
	padding: 'space.200',
});

const loadingContainerStyles = xcss({
	height: '100%',
	width: '100%',
});
