import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type {
	ProjectId,
	ProjectKey,
	GroupUuid,
} from '@atlassian/jira-shared-types/src/general.tsx';
import type { SetRoleForActorRequest, SetRoleForActorResponse } from './types.tsx';

const getUrl = (numericRoleId: number, projectKey: ProjectKey) =>
	`/rest/projectconfig/latest/roles/${projectKey}/${numericRoleId}`;

/**
 * @deprecated use setRoleForGroup / setRoleForUser as soon as the identity based contributor role is available
 * @param numericRoleId
 * @param projectKey
 * @param actorsRoleTypeId
 * @param isGroup
 */
export const setRoleForActor = ({
	numericRoleId,
	projectKey,
	actorsRoleTypeId,
	isGroup,
}: SetRoleForActorRequest): Promise<SetRoleForActorResponse> =>
	performPostRequest(getUrl(numericRoleId, projectKey), {
		body: JSON.stringify({
			groups: isGroup ? [actorsRoleTypeId] : [],
			users: isGroup ? [] : [actorsRoleTypeId],
		}),
	});

export const setRoleForGroups = (
	roleId: number,
	groupIds: GroupUuid[],
	projectId: ProjectId,
): Promise<void> =>
	performPostRequest(
		`/rest/polaris/contributors/projectconfig/latest/roles/${projectId}/${roleId}`,
		{
			body: JSON.stringify({
				groupIds,
				userIds: [],
			}),
		},
	);

export const setRoleForGroup = (
	roleId: number,
	groupId: GroupUuid,
	projectId: ProjectId,
): Promise<void> => setRoleForGroups(roleId, [groupId], projectId);

export const setRoleForUser = (
	roleId: number,
	userId: string,
	projectId: ProjectId,
): Promise<void> =>
	performPostRequest(
		`/rest/polaris/contributors/projectconfig/latest/roles/${projectId}/${roleId}`,
		{
			body: JSON.stringify({
				groupIds: [],
				userIds: [userId],
			}),
		},
	);
