import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	errorDescription: {
		id: 'polaris-ideas.view-header.share-dialog.share-dialog-content.share.error-description',
		defaultMessage: 'Unable to share',
		description: 'Description for error after sharing the view.',
	},
	errorTitle: {
		id: 'polaris-ideas.view-header.share-dialog.share-dialog-content.share.error-title',
		defaultMessage: 'Error',
		description: 'Title for error after sharing the view.',
	},
	successDescription: {
		id: 'polaris-ideas.view-header.share-dialog.share-dialog-content.share.success-description',
		defaultMessage: 'View shared',
		description: 'Description for success after sharing the view.',
	},
	successTitle: {
		id: 'polaris-ideas.view-header.share-dialog.share-dialog-content.share.success-title',
		defaultMessage: 'Success',
		description: 'Title for success after sharing the view.',
	},
});
