import { PROJECT_PROPERTIES } from './controllers/types.tsx';

export const PRELOAD_PROJECT_PROPERTIES = [
	PROJECT_PROPERTIES.ATLAS_INTEGRATION_ENABLED,
	PROJECT_PROPERTIES.ATLAS_SITE_CLOUD_ID,
	PROJECT_PROPERTIES.IDEA_CREATION_BY_CONTRIBUTORS,
	PROJECT_PROPERTIES.SUBMIT_IDEA_FORM_VIEW,
	PROJECT_PROPERTIES.SUBMIT_IDEA_FORM_DESCRIPTION_TEMPLATE,
	PROJECT_PROPERTIES.SUBMIT_IDEA_FORM_MANDATORY_FIELDS,
];
