import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { TooltipPrimitive, type TooltipPrimitiveProps } from '@atlaskit/tooltip';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-unsafe-style-overrides -- To migrate as part of go/ui-styling-standard
export const TooltipComponent = styled<TooltipPrimitiveProps>(TooltipPrimitive)({
	paddingTop: token('space.150'),
	paddingRight: token('space.150'),
	paddingBottom: token('space.150'),
	paddingLeft: token('space.150'),
	backgroundColor: token('elevation.surface'),
	transition: 'box-shadow 0.3s ease, background 0.3s ease',
	borderRadius: '4px',
	boxShadow: token('elevation.shadow.overlay'),
	width: 'fit-content',
	maxWidth: '500px',
});
