import defaultsDeep from 'lodash/defaultsDeep';
import {
	getUserProperty,
	setUserProperties,
} from '@atlassian/jira-common-rest/src/api/latest/user-properties/index.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import { logSafeErrorWithoutCustomerDataWrapper } from '@atlassian/jira-polaris-lib-errors/src/common/utils/index.tsx';
import { ONBOARDING_USER_PROPERTY, initialRemoteState } from '../../constants.tsx';
import { getRemoteState } from '../../selectors/remote-state.tsx';
import { getShouldSeeOnboarding } from '../../selectors/should-see-onboarding.tsx';
import type { Props, RemoteState, State } from '../../types.tsx';

export const loadRemoteState =
	(): Action<State, Props> =>
	async ({ setState, getState }, { accountId }) => {
		const state = getState();
		const currentRemoteState = getRemoteState(state);
		const shouldSeeOnboarding = getShouldSeeOnboarding(state);

		if (!accountId || currentRemoteState !== null || shouldSeeOnboarding === undefined) {
			return;
		}

		try {
			const remoteState = await getUserProperty<RemoteState>(accountId, ONBOARDING_USER_PROPERTY);

			setState({ remoteState: defaultsDeep(remoteState, initialRemoteState) });
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			if (error.statusCode === 404) {
				setState({ remoteState: initialRemoteState });
				return;
			}

			logSafeErrorWithoutCustomerDataWrapper(
				'polaris.onboarding.remote-state',
				`Failed to load user property with key: ${ONBOARDING_USER_PROPERTY}`,
				error,
			);
			setState({ remoteState: null });
		}
	};

export const saveRemoteState =
	(remoteState: RemoteState): Action<State, Props> =>
	async ({ setState, getState }, { accountId }) => {
		if (!accountId) {
			return;
		}

		const currentRemoteState = getRemoteState(getState());

		setState({ remoteState });

		try {
			await setUserProperties(accountId, ONBOARDING_USER_PROPERTY, JSON.stringify(remoteState));
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			logSafeErrorWithoutCustomerDataWrapper(
				'polaris.onboarding.remote-state',
				`Failed to save user property with key: ${ONBOARDING_USER_PROPERTY}`,
				error,
			);

			setState({ remoteState: currentRemoteState });
		}
	};
