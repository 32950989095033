import React, {
	memo,
	useCallback,
	useEffect,
	useRef,
	useState,
	type KeyboardEvent,
	type MouseEvent,
} from 'react';
import { styled } from '@compiled/react';
import clamp from 'lodash/clamp';
import { Box, xcss } from '@atlaskit/primitives';
import Textfield from '@atlaskit/textfield';
import { token } from '@atlaskit/tokens';
import {
	// eslint-disable-next-line jira/import/no-restricted-import
	PolarisIcon,
	// eslint-disable-next-line jira/import/no-restricted-import
	PolarisIconType,
} from '@atlassian/jira-polaris-component-glyphs/src/ui/glyphs/main.tsx';
// eslint-disable-next-line jira/import/no-restricted-import
import { WeightTag } from '@atlassian/jira-polaris-component-weight-tag/src/ui/index.tsx';
import { T300 } from '@atlassian/jira-polaris-lib-color-palette/src/ui/colors/index.tsx';
import { CommonDecoratedTag } from '@atlassian/jira-polaris-lib-decoration/src/ui/decoration/common/index.tsx';
import { useEmoji } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';
import { Rating } from '@atlassian/jira-polaris-lib-rating/src/ui/index.tsx';
import { DragHandle, type ItemPosition, type ReorderAction } from './drag-handle/index.tsx';

const DEFAULT_WEIGHT = 1;
const RATING_MIN_WEIGHT = 1;
const RATING_MAX_WEIGHT = 5;
const ONE_HUNDRED_MILLIONS = 100000000;
const WEIGHTING_WRAPPER_COMPONENT_ATTR = 'weighting-wrapper-j93k';

export type OptionListItemProps = {
	id: string;
	label: string;
	weight: number;
	weightType: 'RATING' | 'NUMBER' | undefined;
	emojiId: string | undefined;
	isReadonly: boolean;
	isDragDisabled: boolean;
	isSelected: boolean;
	position: ItemPosition;
	outerSpacing?: string;
	color?: string;
	defaultSelected?: boolean;
	defaultSelectedInitializedRef?: React.MutableRefObject<boolean>;
	onWeightChange: (optionId: string, weight: number) => void;
	onClick: (optionId: string, optionElement: HTMLElement) => void;
	onReorder: (reorderAction: ReorderAction, id: string) => void;
};

export const OptionListItem = memo((props: OptionListItemProps) => {
	const {
		id,
		label,
		weight,
		weightType,
		emojiId,
		isReadonly,
		isDragDisabled,
		isSelected,
		outerSpacing = '8px',
		color,
		position,
		defaultSelected,
		defaultSelectedInitializedRef,
		onWeightChange,
		onClick,
		onReorder,
	} = props;
	const optionWrapperElementRef = useRef<HTMLDivElement>(null);
	const [localWeight, setLocalWeight] = useState<number | undefined>(weight);
	const emoji = useEmoji(emojiId);

	const handleRatingWeightChange = useCallback(
		(updatedWeight: number | undefined) => {
			if (!updatedWeight || Number.isNaN(updatedWeight)) {
				setLocalWeight(DEFAULT_WEIGHT);
				onWeightChange(id, DEFAULT_WEIGHT);
				return;
			}

			const clampedNewWeight = Math.floor(
				clamp(updatedWeight, RATING_MIN_WEIGHT, RATING_MAX_WEIGHT),
			);

			setLocalWeight(clampedNewWeight);
			onWeightChange(id, clampedNewWeight);
		},
		[onWeightChange, id],
	);

	const handleNumberInputChange = useCallback(
		(updatedWeight: number | undefined) => {
			if (updatedWeight === localWeight) {
				return;
			}

			const clampedNewWeight =
				updatedWeight !== undefined
					? clamp(updatedWeight, -ONE_HUNDRED_MILLIONS, ONE_HUNDRED_MILLIONS)
					: undefined;

			setLocalWeight(clampedNewWeight);
		},
		[localWeight],
	);

	const handleNumberInputBlur = useCallback(() => {
		let newWeight = localWeight;

		if (newWeight === undefined || Number.isNaN(newWeight)) {
			setLocalWeight(DEFAULT_WEIGHT);
			newWeight = DEFAULT_WEIGHT;
		}

		if (newWeight !== weight) {
			onWeightChange(id, newWeight);
		}
	}, [id, localWeight, onWeightChange, weight]);

	const handleClick = useCallback(
		(event: MouseEvent<HTMLElement>) => {
			if (event.target instanceof HTMLElement) {
				let parent = event.target.parentElement;
				while (parent) {
					if (parent.getAttribute('data-component-selector') === WEIGHTING_WRAPPER_COMPONENT_ATTR) {
						// ignore click events inside the weighting wrapper
						return;
					}
					parent = parent.parentElement;
				}

				if (!isReadonly && optionWrapperElementRef.current) {
					onClick(id, optionWrapperElementRef.current);
				}
			}
		},
		[id, isReadonly, onClick],
	);

	const handleKeyDown = useCallback(
		(event: KeyboardEvent<HTMLElement>) => {
			if (event.code === 'Enter' || event.code === 'Space') {
				event.stopPropagation();
				event.preventDefault();

				if (event.target instanceof HTMLElement) {
					onClick(id, event.target);
				}
			}
		},
		[id, onClick],
	);

	const handleReorder = useCallback(
		(reorderAction: ReorderAction) => onReorder(reorderAction, id),
		[id, onReorder],
	);

	useEffect(() => {
		if (weight !== localWeight) {
			setLocalWeight(weight);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [weight]);

	useEffect(() => {
		if (
			defaultSelected &&
			optionWrapperElementRef.current &&
			defaultSelectedInitializedRef?.current === false
		) {
			onClick(id, optionWrapperElementRef.current);
			defaultSelectedInitializedRef.current = true;
		}
	}, [defaultSelected, defaultSelectedInitializedRef, id, onClick]);

	return (
		<OptionWrapper
			ref={optionWrapperElementRef}
			outerSpacing={outerSpacing}
			isReadonly={isReadonly}
			isDragDisabled={isDragDisabled}
			isSelected={isSelected}
			onClick={handleClick}
			onKeyDown={handleKeyDown}
			tabIndex={0}
			role="button"
			data-testid="polaris-lib-field-option-configuration.ui.option-list.option-list-item.option-wrapper"
		>
			{!isDragDisabled && (
				<Box
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
					xcss={xcss({
						marginLeft: 'space.negative.150',
					})}
				>
					<DragHandle position={position} onReorder={handleReorder} />
				</Box>
			)}
			<OptionContent>
				<CommonDecoratedTag mainColor={color} emoji={emoji} value={label} compact />
			</OptionContent>
			{isReadonly && weightType && (
				<WeightTag
					options={[
						{
							id,
							value: label,
							weight,
						},
					]}
				/>
			)}
			{!isReadonly && weightType === 'RATING' && (
				<InputWrapper data-component-selector={WEIGHTING_WRAPPER_COMPONENT_ATTR}>
					<PolarisIcon name={PolarisIconType.Scales} size="small" label="Weighting value" />
					<Rating mainColor={T300} value={localWeight} onChange={handleRatingWeightChange} />
				</InputWrapper>
			)}
			{!isReadonly && weightType === 'NUMBER' && (
				<InputWrapper data-component-selector={WEIGHTING_WRAPPER_COMPONENT_ATTR}>
					<PolarisIcon name={PolarisIconType.Scales} size="small" label="Weighting value" />
					<Box xcss={numberInputWrapperStyles}>
						<NumberInput
							aria-label="Weight number value"
							type="number"
							appearance="standard"
							value={localWeight}
							isCompact
							onChange={(event) => handleNumberInputChange(parseInt(event.currentTarget.value, 10))}
							onBlur={handleNumberInputBlur}
							testId="polaris-lib-field-option-configuration.ui.option-list.option-list-item.number-input"
						/>
					</Box>
				</InputWrapper>
			)}
		</OptionWrapper>
	);
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InputWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
	gap: token('space.075'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/design-system/no-html-button -- To migrate as part of go/ui-styling-standard
const OptionWrapper = styled.div<{
	outerSpacing: string;
	isDragDisabled: boolean;
	isReadonly: boolean;
	isSelected: boolean;
}>({
	height: '32px',
	display: 'flex',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	gap: '3px',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	cursor: ({ isReadonly }) => (isReadonly ? 'initial' : 'pointer'),

	color: token('color.text'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	padding: ({ outerSpacing }) => `0 ${outerSpacing}`,
	outline: '0px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:hover:not(:has([data-component-selector="weighting-wrapper-j93k"]:hover))': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		backgroundColor: ({ isReadonly }) =>
			isReadonly ? 'initial' : token('elevation.surface.hovered'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:active:not(:has([data-component-selector="weighting-wrapper-j93k"]:hover))': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		backgroundColor: ({ isReadonly }) =>
			isReadonly ? 'initial' : token('elevation.surface.pressed'),
	},
	'&:focus': {
		boxShadow: `inset 0 0 0 ${
			// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage -- The token value "1px" and fallback "2px" do not match and can't be replaced automatically.
			token('border.width', '2px')
		} ${token('color.border.focused')}`,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	backgroundColor: ({ isSelected }) =>
		isSelected ? token('elevation.surface.overlay.pressed') : 'initial',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OptionContent = styled.div({
	flex: '1 1 auto',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	maxWidth: '100%',
});

const numberInputWrapperStyles = xcss({
	width: '80px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const NumberInput = styled(Textfield)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > [data-ds--text-field--input][data-compact]': {
		height: '24px',
	},
});

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { ItemPosition, ReorderAction } from './drag-handle';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { DragHandle } from './drag-handle';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { DragHandleMock } from './drag-handle/mocks.tsx';
