import type { InviteUserArguments, InviteUserRequest } from './types.tsx';

const inviteUsersToProjectUrl = (projectId: number) =>
	`/rest/internal/latest/invite-user/request-access/project/${projectId}`;

const inviteUserToProjectOptions = (inviteUserRequest: InviteUserRequest) => {
	const data = JSON.stringify(inviteUserRequest);
	return {
		method: 'POST',
		credentials: 'same-origin',
		headers: {
			'Content-Type': 'application/json',
		},
		body: data,
	};
};

export const post = ({ projectId, payload }: InviteUserArguments): Promise<Response> =>
	fetch(
		inviteUsersToProjectUrl(projectId),
		// @ts-expect-error - TS2769 - No overload matches this call.
		inviteUserToProjectOptions(payload),
	);
