import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	pageHeader: {
		id: 'polaris-ideas.settings.types.project-types-screen.page-header',
		defaultMessage: 'Types',
		description: 'Header of the project types settings page',
	},
	pageDescription: {
		id: 'polaris-ideas.settings.types.project-types-screen.page-descriptionNonFinal',
		defaultMessage: ' ',
		description: 'Description for the project types settings page',
	},
});
