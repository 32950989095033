import React, { Fragment } from 'react';
import { styled } from '@compiled/react';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import {
	useFieldLabel,
	useFieldType,
} from '../../../../controllers/field/selectors/field-hooks.tsx';
import { useStringValue } from '../../../../controllers/issue/selectors/properties/hooks.tsx';
import { StringField as CommonField } from '../../../fields/string/index.tsx';
import { FIELD_HEIGHT_COMPACT, STRING_FIELD_VALUE_STYLES } from '../../constants.tsx';
import { EmptyField } from '../empty/index.tsx';
import { FieldLabel, FieldValue } from '../field/styled.tsx';

type Props = {
	issueId: string;
	fieldKey: FieldKey;
	isCompact?: boolean;
	isSummary?: boolean;
	hideEmpty?: boolean;
	isMultiline?: boolean;
	isResizing?: boolean;
};

export const StringField = ({
	issueId,
	fieldKey,
	isCompact = false,
	isSummary = false,
	hideEmpty,
	isMultiline,
	isResizing,
}: Props) => {
	const label = useFieldLabel(fieldKey);
	const value = useStringValue(fieldKey, issueId);
	const fieldType = useFieldType(fieldKey);
	const StringFieldWrapper = isCompact ? CompactStringFieldWrapper : Fragment;

	const shouldRenderValue = !isEmpty(value) && typeof value === 'string';

	if (fieldType === FIELD_TYPES.HYPERLINK) {
		return !hideEmpty || shouldRenderValue ? (
			<>
				{!isCompact && <FieldLabel>{label}</FieldLabel>}
				<HyperlinkFieldValue>
					{shouldRenderValue ? (
						<CommonField value={value} isEditable={false} onUpdate={noop} fieldType={fieldType} />
					) : (
						isSummary && <EmptyField />
					)}
				</HyperlinkFieldValue>
			</>
		) : null;
	}

	if (isSummary && !shouldRenderValue) {
		return (
			<>
				<FieldLabel>{label}</FieldLabel>
				<FieldValue>
					<EmptyField />
				</FieldValue>
			</>
		);
	}

	return fieldType !== FIELD_TYPES.SUMMARY && (!hideEmpty || shouldRenderValue) ? (
		<StringFieldWrapper>
			<StringFieldValue>
				{/* eslint-disable-next-line no-nested-ternary */}
				{!shouldRenderValue ? (
					'-'
				) : isMultiline ? (
					<div>{value}</div>
				) : (
					<Tooltip content={!isResizing && value} position="auto-start" hideTooltipOnMouseDown>
						<TooltipContent>
							<span>{value}</span>
						</TooltipContent>
					</Tooltip>
				)}
			</StringFieldValue>
		</StringFieldWrapper>
	) : null;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StringFieldValue = styled.div({
	gridColumn: 'span 2',
	width: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> div': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		...STRING_FIELD_VALUE_STYLES,
		color: token('color.text'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HyperlinkFieldValue = styled(FieldValue)<{
	isFullwidth?: boolean;
}>({
	marginLeft: 0,
	// for a brief moment, smart card links are visible and they take more
	// than 30px height when they wrap. This causes layout shifts so we
	// set a max height with overflow hidden instead.
	maxHeight: '30px',
	overflow: 'hidden',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CompactStringFieldWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${FIELD_HEIGHT_COMPACT}px`,
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TooltipContent = styled.div({
	display: 'inline-block',
	maxWidth: '100%',
	width: 'auto',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});
