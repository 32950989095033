import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	filterInField: {
		id: 'polaris-common.filters.filter-component.filters.connection-filter.filter-in-field-non-final',
		defaultMessage: 'Filter in the connection field',
		description:
			'Header for the connection field filter section in the sidebar. The section contains options to narrow down the set of values displayed in the given connection field.',
	},
	filterInFieldDescription: {
		id: 'polaris-common.filters.filter-component.filters.connection-filter.filter-in-field-description-non-final',
		defaultMessage: 'Determine which items are displayed in those fields',
		description:
			'Description for the options available for narrowing down items visible in the connection fields',
	},
	columns: {
		id: 'polaris-common.filters.filter-component.filters.connection-filter.columns-non-final',
		defaultMessage: 'Match column',
		description:
			'Label for option. When enabled connection field values are narrowed down to items matching given column.',
	},
	groups: {
		id: 'polaris-common.filters.filter-component.filters.connection-filter.groups-non-final',
		defaultMessage: 'Match group',
		description:
			'Label for option. When enabled connection field values are narrowed down to items matching given group.',
	},
	filterInView: {
		id: 'polaris-common.filters.filter-component.filters.connection-filter.filter-in-view-non-final',
		defaultMessage: 'Filter in the view',
		description:
			'Header for the connection field filter section in the sidebar. The section contains options to narrow down the items displayed in the view.',
	},
	filterInViewDescription: {
		id: 'polaris-common.filters.filter-component.filters.connection-filter.filter-in-view-description-non-final',
		defaultMessage: 'Determine which items are displayed in the view',
		description:
			'Description for the options available for narrowing down items visible in the view',
	},
});
