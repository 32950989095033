import React, { type ReactNode } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type {
	ProfileClient,
	ProfilecardTriggerPosition,
	TriggerType,
	PrepopulatedData,
} from '@atlaskit/profilecard';
import { useFlagsService } from '@atlassian/jira-flags';
import {
	fireTrackAnalytics,
	ContextualAnalyticsData,
	SCREEN,
} from '@atlassian/jira-product-analytics-bridge';
import { RouterActions } from '@atlassian/react-resource-router';
import { useIntl } from '@atlassian/jira-intl';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { profileCardNextSourceName, profileCardNextLegacySourceName } from './common/constants.tsx';
import type { Actions, AnalyticsData } from './types.tsx';
import { ProfileCardLegacy as ProfileCardLegacyView } from './ui/profilecard-legacy/index.tsx';
import { ProfileCardWrapper } from './ui/profilecard-wrapper/main.tsx';
import { ProfileCardView } from './ui/profilecard/index.tsx';
import { getSpaActions } from './utils/index.tsx';

type ProfileCardBaseProps = {
	externalProfileClient?: ProfileClient;
	actions?: Actions[];
	prepopulatedData?: PrepopulatedData;
};

export type ProfileCardProps = ProfileCardBaseProps & {
	accountId: string;
	analyticsData?: AnalyticsData;
	children: ReactNode;
	position?: ProfilecardTriggerPosition;
	testId?: string;
	trigger?: TriggerType;
	ariaHideProfileTrigger?: boolean;
	isChildComment?: boolean;
};

export const ProfileCard = ({
	externalProfileClient,
	accountId,
	actions,
	analyticsData,
	children,
	position,
	testId,
	trigger,
	prepopulatedData,
	ariaHideProfileTrigger,
	isChildComment = false,
}: ProfileCardProps) => {
	const intl = useIntl();
	const { showFlag, dismissFlag } = useFlagsService();
	const currentUserAccountId = useAccountId();

	return (
		<ContextualAnalyticsData
			sourceType={SCREEN}
			sourceName={profileCardNextSourceName}
			attributes={analyticsData}
		>
			<ProfileCardWrapper externalProfileClient={externalProfileClient}>
				{currentUserAccountId ? (
					<RouterActions>
						{({ push }) => (
							<ProfileCardView
								accountId={accountId}
								actions={getSpaActions(accountId, '', intl, push, actions)}
								position={position}
								onProfileClick={(profileAccountId) => push(`/jira/people/${profileAccountId}`)}
								addFlag={showFlag}
								dismissFlag={dismissFlag}
								testId={testId}
								trigger={trigger}
								prepopulatedData={prepopulatedData}
								ariaHideProfileTrigger={ariaHideProfileTrigger}
								isChildComment={isChildComment}
							>
								{children}
							</ProfileCardView>
						)}
					</RouterActions>
				) : (
					children
				)}
			</ProfileCardWrapper>
		</ContextualAnalyticsData>
	);
};

export const ProfileCardLegacy = ({ externalProfileClient, actions }: ProfileCardBaseProps) => {
	const intl = useIntl();
	const currentUserAccountId = useAccountId();

	const onLoadError = (error: Error, analyticsEvent?: UIAnalyticsEvent) => {
		if (analyticsEvent) {
			fireTrackAnalytics(
				analyticsEvent,
				`${analyticsEvent.payload.actionSubject} ${analyticsEvent.payload.action}`,
				`${analyticsEvent.payload.actionSubject}${analyticsEvent.payload.action}`,
				{
					error,
				},
			);
		}
	};

	return (
		<ContextualAnalyticsData sourceType={SCREEN} sourceName={profileCardNextLegacySourceName}>
			<ProfileCardWrapper externalProfileClient={externalProfileClient} onLoadError={onLoadError}>
				{currentUserAccountId ? (
					<LegacyProfileCardStyled>
						<RouterActions>
							{({ push }) => (
								<ProfileCardLegacyView
									onGetActions={(accountId: string, onActionCleanup?: () => void) =>
										getSpaActions(accountId, '', intl, push, actions, onActionCleanup)
									}
									onProfileClick={(profileAccountId) => push(`/jira/people/${profileAccountId}`)}
								/>
							)}
						</RouterActions>
					</LegacyProfileCardStyled>
				) : null}
			</ProfileCardWrapper>
		</ContextualAnalyticsData>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LegacyProfileCardStyled = styled.div({
	position: 'relative',
	zIndex: 100,
});
