import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	button: {
		id: 'polaris-component-view-export.export-view-dialog.export-dialog-content.export-as-csv.button',
		defaultMessage: 'Download CSV',
		description: 'Button label to run export view as CSV file',
	},
	exportAsCsv: {
		id: 'polaris-component-view-export.export-view-dialog.export-dialog-content.export-as-csv.button-label',
		defaultMessage: 'Export as CSV',
		description: 'Button label to run export view as CSV file',
	},
	label: {
		id: 'polaris-component-view-export.export-view-dialog.export-dialog-content.export-as-csv.label',
		defaultMessage: 'Download this view as a CSV file for you to share',
		description: 'Message explaining export view as CSV file',
	},
	deliveryStatusTooltip: {
		id: 'polaris-component-view-export.export-view-dialog.export-dialog-content.export-as-csv.delivery-status-tooltip',
		defaultMessage: '{category}: {count} of {total, plural, one {# issue} other {# issues}}',
		description: 'Tooltip on the view but description on the CSV export',
	},
	deliveryStatusTooltipIssueTermRefresh: {
		id: 'polaris-component-view-export.export-view-dialog.export-dialog-content.export-as-csv.delivery-status-tooltip-issue-term-refresh',
		defaultMessage:
			'{category}: {count} of {total, plural, one {# work item} other {# work items}}',
		description: 'Tooltip on the view but description on the CSV export',
	},
	deliveryTooltipStoryPoints: {
		id: 'polaris-component-view-export.export-view-dialog.export-dialog-content.export-as-csv.delivery-tooltip-story-points',
		defaultMessage:
			'{category}: {count} of {total, plural, one {# story point} other {# story points}}',
		description: 'Tooltip on the view but description on the CSV export',
	},
	deliveryProgressTooltip: {
		id: 'polaris-component-view-export.export-view-dialog.export-dialog-content.export-as-csv.delivery-progress-tooltip',
		defaultMessage:
			'{category}: {count} of {total, plural, one {# delivery ticket} other {# delivery tickets}}',
		description: 'Tooltip on the view but description on the CSV export',
	},
	deliveryProgressTooltipIssueTermRefresh: {
		id: 'polaris-component-view-export.export-view-dialog.export-dialog-content.export-as-csv.delivery-progress-tooltip-issue-term-refresh',
		defaultMessage:
			'{category}: {count} of {total, plural, one {# work item} other {# work items}}',
		description: 'Tooltip on the view but description on the CSV export',
	},
	unestimated: {
		id: 'polaris-component-view-export.export-view-dialog.export-dialog-content.export-as-csv.unestimated',
		defaultMessage: 'Unestimated',
		description: 'Tooltip on the view but description on the CSV export',
	},
	fieldNotSupported: {
		id: 'polaris-component-view-export.export-view-dialog.export-dialog-content.export-as-csv.field-not-supported',
		defaultMessage: 'Field not supported yet',
		description: 'Warning for not supported fields',
	},
});
