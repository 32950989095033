import { getAriConfig } from '@atlassian/jira-platform-ari/src/index.tsx';
import { toProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { AvatarUrls } from '@atlassian/jira-shared-types/src/rest/jira/user.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State } from '../types.tsx';

export const updateProjectDetails =
	(key: string, name: string, avatarId: number): Action<State, Props> =>
	async ({ getState, setState }) => {
		const { project } = getState();

		if (project.id) {
			const pid = getAriConfig(project.id).resourceId;
			const avatarUrls: AvatarUrls = {
				'16x16': `/secure/projectavatar?size=xsmall&s=xsmall&pid=${pid}&avatarId=${avatarId}`,
				'24x24': `/secure/projectavatar?size=small&s=small&pid=${pid}&avatarId=${avatarId}`,
				'32x32': `/secure/projectavatar?size=medium&s=medium&pid=${pid}&avatarId=${avatarId}`,
				'48x48': `/secure/projectavatar?pid=${pid}&avatarId=${avatarId}`,
			};
			setState({
				project: {
					...project,
					key: toProjectKey(key),
					name,
					avatarUrls,
				},
			});
		}
	};
