import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	emptyColumnHeader: {
		id: 'polaris-ideas.view-content.idea-board.column.header.empty-column-header',
		defaultMessage: 'No field value',
		description:
			'Header for columns in board that contain all issues that have no value set on them.',
	},
	addIdea: {
		id: 'polaris-ideas.view-content.idea-board.column.header.add-idea',
		defaultMessage: 'Add',
		description: 'Add idea button visible in group rows',
	},
	hideColumn: {
		id: 'polaris-ideas.view-content.idea-board.column.header.hide-column',
		defaultMessage: 'Hide',
		description: 'Dropdown menu item for hiding a column in the board view.',
	},
	editField: {
		id: 'polaris-ideas.view-content.idea-board.column.header.edit-field',
		defaultMessage: 'Edit field',
		description: 'Dropdown menu item for editing column field in the board view.',
	},
	fieldSettings: {
		id: 'polaris-ideas.view-content.idea-board.column.header.field-settings',
		defaultMessage: 'Show field settings',
		description: 'Button to open settings for the currently open field',
	},
	renameTable: {
		id: 'polaris-ideas.view-content.idea-board.column.header.rename-table',
		defaultMessage: 'Edit field option',
		description: 'Label of rename table menu option',
	},
	actions: {
		id: 'polaris-ideas.view-content.idea-board.column.header.actions',
		defaultMessage: 'Actions',
		description: 'Label of options menu',
	},
});
