import trim from 'lodash/trim';
import isUrl from '@atlassian/jira-common-util-is-url/src/index.tsx';
import type { SnippetAction } from '@atlassian/jira-polaris-domain-insight/src/snippet/types.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import { logSafeErrorWithoutCustomerDataWrapper } from '@atlassian/jira-polaris-lib-errors/src/common/utils/index.tsx';
import { defaultScheme } from '../../../../common/utils/scheme/index.tsx';
import type {
	Props,
	InvokeProps,
	State,
	ObjectAuthentication,
	ObjectResolved,
} from '../../types.tsx';
import type { ErrorBody } from './types.tsx';

const isAppError = (body?: ErrorBody | null) => {
	if (!body) {
		return false;
	}
	return body.status && body.message && body.type;
};

export const unfurl =
	(customAction?: SnippetAction): Action<State, Props & InvokeProps> =>
	(
		{ getState, setState },
		{
			url: dirtyUrl,
			issueId,
			projectId,
			action,
			snippetData,
			oauthClientId,
			onObjectResolved,
			onUnfurlCondition,
			insightsRemote,
		}: Props & InvokeProps,
	) => {
		/**
		 * check unfurl condition - early exit if the consumer wants to prevent automatic
		 * unfurls under certain conditions (e.g. error resolution)
		 */
		if (
			projectId === undefined ||
			(onUnfurlCondition !== undefined && !onUnfurlCondition(getState()))
		) {
			return;
		}

		let url = trim(dirtyUrl);

		if (url === undefined || url === '' || issueId === undefined) {
			setState({
				error: undefined,
			});
			return;
		}

		url = defaultScheme(url, 'https');

		/**
		 * helper to have this seem async all the time, even if the consumer has not supplied a promise
		 */
		const objectResolvedCallback = (resolvedObject: ObjectResolved): Promise<void> => {
			const consumerResponse = onObjectResolved && onObjectResolved(resolvedObject);
			if (consumerResponse === undefined) {
				return Promise.resolve(undefined);
			}
			return consumerResponse;
		};

		if (!isUrl(url)) {
			setState({
				error: new Error('Url is invalid'),
			});
			return;
		}

		const { authToken } = getState();

		setState({
			resolvedObject: undefined,
			isResolving: true,
			tryAgain: false,
			auth: undefined,
			error: undefined,
		});

		const invokeAction = customAction || action;

		const operation =
			invokeAction !== undefined && oauthClientId !== undefined && snippetData !== undefined
				? insightsRemote.invokeObject?.({
						issueId,
						url,
						oauthClientId,
						snippetData,
						invokeAction,
						authToken,
					})
				: insightsRemote.resolveObject?.({
						issueId,
						url,
						authToken,
						projectId,
					});

		operation &&
			operation
				.then((response) => {
					switch (response.statusCode) {
						case 200:
							if (response.body && isAppError(response.body)) {
								const errorBody: ErrorBody = response.body;
								setState({
									isResolving: false,
									tryAgain: true,
									error: new Error(
										`App error: ${errorBody.type}(${errorBody.status}, "${errorBody.message}"). Try again later.`,
									),
								});
								break;
							} else if (response.body?.meta?.access === 'not_found') {
								const resolvedObject = {
									url,
									isUnsupported: true,
									oauthClientId: undefined,
									snippetData: undefined,
									properties: undefined,
								};
								objectResolvedCallback(resolvedObject).then(() => {
									setState({
										isResolving: false,
										resolvedObject,
									});
								});
								break;
							} else if (
								response.body?.meta?.access === 'unauthorized' ||
								response.body?.meta?.access === 'forbidden'
							) {
								const auth: ObjectAuthentication = {
									isForbidden: response.body.meta.access === 'forbidden',
									authUrl:
										(Array.isArray(response.externalAuth) &&
											response.externalAuth.length > 0 &&
											response.externalAuth[0].url) ||
										undefined,
									authType: response.auth?.type || undefined,
									authHint: response.auth?.hint || undefined,
									authorizationUrl:
										response.body.meta.access === 'forbidden' && response.body.meta.authorizationUrl
											? response.body.meta.authorizationUrl
											: undefined,
								};
								setState({
									isResolving: false,
									auth,
								});
								break;
							} else if (response.body?.meta?.access === 'granted') {
								const resolvedObject = {
									url,
									isUnsupported: false,
									snippetData: response.body?.data || undefined,
									oauthClientId:
										response.oauthClientId !== null ? response.oauthClientId : undefined,
									properties: response.body?.data?.properties || undefined,
								};
								objectResolvedCallback(resolvedObject).then(() => {
									setState({
										auth: undefined,
										apiKey: undefined,
										isResolving: false,
										resolvedObject,
									});
								});
								break;
							}
							throw new Error(
								`Unfulring failed with body: ${
									response.body !== null && response.body !== undefined
										? JSON.stringify(response.body)
										: ''
								}`,
							);
						case 404: {
							const resolvedObject = {
								url,
								isUnsupported: true,
								oauthClientId: undefined,
								snippetData: undefined,
								properties: undefined,
							};
							objectResolvedCallback(resolvedObject).then(() => {
								setState({
									isResolving: false,
									resolvedObject,
								});
							});
							return;
						}
						case 504: {
							setState({
								isResolving: false,
								tryAgain: true,
								error: new Error('Request timeout. Try again.'),
							});
							return;
						}
						default:
							throw new Error(`Unfulring failed with status: ${response.statusCode}`);
					}
				})
				.catch((err) => {
					logSafeErrorWithoutCustomerDataWrapper(
						'polaris.object-resolver-error',
						'Failed to resolve object',
						err,
					);
					setState({
						isResolving: false,
						tryAgain: true,
						error: new Error('Something went wrong. Try again.'),
					});
				});
	};
