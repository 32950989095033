import type { ComponentType } from 'react';
import type {
	Components,
	ItemProps,
	ClusteredItemProps,
	DnDTooltipProps,
	ItemWrapperProps,
	DraggableBubbleProps,
} from '../../types.tsx';
import type { State } from '../types.tsx';

export const getComponents = (state: State): Components | undefined => state.components;

export const getItemRendererComponent = (state: State): ComponentType<ItemProps> | undefined =>
	state.components?.Item;

export const getItemWrapperRendererComponent = (
	state: State,
): ComponentType<ItemWrapperProps> | undefined => state.components?.ItemWrapper;

export const getClusteredItemRendererComponent = (
	state: State,
): ComponentType<ClusteredItemProps> | undefined => state.components?.ClusteredItem;

export const getDnDTooltipRendererComponent = (
	state: State,
): ComponentType<DnDTooltipProps> | undefined => state.components?.DnDTooltip;

export const getDraggableBubbleComponent = (
	state: State,
): ComponentType<DraggableBubbleProps> | undefined => state.components?.DraggableBubble;
