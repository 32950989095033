import React, { useCallback } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { WeightTag } from '@atlassian/jira-polaris-component-weight-tag/src/ui/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { FieldName } from '../../../../common/ui/field/styled.tsx';
import { useFieldLabel } from '../../../../controllers/field/selectors/field-hooks.tsx';
import { useIsWeightedField } from '../../../../controllers/issue/selectors/fields-hooks.tsx';
import { useMultiSelect } from '../../../../controllers/issue/selectors/properties/hooks.tsx';
import { DynamicOptionDecoratedTag } from '../../../common/decoration/decoration/tag/index.tsx';
import { CompactValuesList } from '../../../fields/compact-values-list/index.tsx';
import { MultiSelectField as MultiSelect } from '../../../fields/select/index.tsx';
import { EmptyField } from '../empty/index.tsx';
import { FieldLabel, FieldValue } from '../field/styled.tsx';

type Props = {
	issueId: LocalIssueId;
	fieldKey: FieldKey;
	isCompact?: boolean;
	isSummary?: boolean;
	hideEmpty?: boolean;
	cappedFieldsDisplay?: boolean;
	iconOnly?: boolean;
	showLabel?: boolean;
};

export const MultiSelectField = ({
	issueId,
	fieldKey,
	isCompact = false,
	isSummary = false,
	hideEmpty = false,
	cappedFieldsDisplay = false,
	iconOnly,
	showLabel = true,
}: Props) => {
	const options = useMultiSelect(fieldKey, issueId);
	const label = useFieldLabel(fieldKey);
	const isWeightTagVisible = useIsWeightedField(fieldKey);
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	const noop = useCallback(() => {}, []);

	if (hideEmpty && (options === undefined || options.length === 0)) {
		return null;
	}

	if (isCompact) {
		return (
			<CompactMultiSelectFieldValue>
				{isWeightTagVisible && <WeightTag options={options} />}
				{iconOnly && isWeightTagVisible ? null : (
					<CompactValuesList>
						{options.map((option, index) => (
							<DynamicOptionDecoratedTag
								key={option.id}
								fieldKey={fieldKey}
								id={option.id}
								iconOnly={iconOnly && index === 0}
								compact
							/>
						))}
					</CompactValuesList>
				)}
			</CompactMultiSelectFieldValue>
		);
	}

	return (
		<>
			{showLabel && (
				<FieldLabel>
					<FieldName>{label}</FieldName>
				</FieldLabel>
			)}
			<MultiSelectFieldValue isSummary={isSummary}>
				{options.length > 0 ? (
					<MultiSelect
						isEditable={false}
						localIssueId={issueId}
						fieldKey={fieldKey}
						onUpdate={noop}
						onConfigRequested={noop}
						compactForBoard
						isActive
						isMultiline={isSummary}
						cappedFieldsDisplay={cappedFieldsDisplay}
					/>
				) : (
					isSummary && <EmptyField />
				)}
			</MultiSelectFieldValue>
		</>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MultiSelectFieldValue = styled(FieldValue)<{ isSummary?: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginLeft: ({ isSummary }) => !isSummary && token('space.050'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CompactMultiSelectFieldValue = styled(FieldValue)({
	display: 'flex',
	gap: token('space.100'),
	alignItems: 'center',
});
