import React, { useEffect, type ReactNode } from 'react';
import Heading from '@atlaskit/heading';
import { Box, Text, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Image from '@atlassian/jira-illustrations/src/ui/adg4/jira/other/assets/jpd-share-view-spotlight.svg';
import { useIntl } from '@atlassian/jira-intl';
import { SHARE_VIEW_SPOTLIGHT_DISMISSED } from '@atlassian/jira-polaris-lib-onboarding-flows/src/controllers/constants.tsx';
import { JiraSpotlight } from '@atlassian/jira-spotlight/src/ui/jira-spotlight.tsx';
import { useOnboardingFlowsActions } from '../../../controllers/index.tsx';
import { useGetShareDialogShareSpotlightDismissed } from '../../../controllers/selectors/share-dialog-hooks.tsx';
import { messages } from './messages.tsx';

const Bold = (chunks: ReactNode) => <Text weight="bold">{chunks}</Text>;

export const ShareViewSpotlight = () => {
	const { formatMessage } = useIntl();
	const { loadShareViewSpotlightDismissed, setShareViewSpotlightDismissed } =
		useOnboardingFlowsActions();
	const shareDialogShareSpotlightDismissed = useGetShareDialogShareSpotlightDismissed();

	useEffect(() => {
		loadShareViewSpotlightDismissed();
	}, [loadShareViewSpotlightDismissed]);

	if (shareDialogShareSpotlightDismissed !== false) {
		return null;
	}

	return (
		<JiraSpotlight
			actions={[
				{
					onClick: setShareViewSpotlightDismissed,
					text: formatMessage(messages.cta),
				},
			]}
			target={SHARE_VIEW_SPOTLIGHT_DISMISSED}
			key={SHARE_VIEW_SPOTLIGHT_DISMISSED}
			messageId="polaris-lib-onboarding-flows.ui.share-dialog.share.jira-spotlight"
			messageType="engagement"
			dialogWidth={275}
			dialogPlacement="bottom right"
			image={Image}
			targetRadius={3}
			targetBgColor={token('elevation.surface')}
		>
			<Heading size="medium">{formatMessage(messages.title)}</Heading>
			<Box as="p" xcss={contentStyles}>
				{formatMessage(messages.content, { b: Bold })}
			</Box>
		</JiraSpotlight>
	);
};

const contentStyles = xcss({
	font: 'font.body',
	marginTop: 'space.100',
});
