import map from 'lodash/map';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import { updateRole as remoteUpdateRole } from '../../../services/jira/update-role/index.tsx';
import type { State, Props, RoleConfigurationId, PermissionKey } from '../../types.tsx';

export const updateRole =
	(
		roleConfigurationId: RoleConfigurationId,
		name: string,
		description: string,
		permissions: PermissionKey[],
		onSuccess: () => void,
	): Action<State, Props> =>
	({ getState, setState }, { projectId, onError }) => {
		setState({
			ui: {
				...getState().ui,
				manageRoles: {
					...getState().ui.manageRoles,
					isPersistingInProgress: true,
				},
			},
		});

		remoteUpdateRole({ roleConfigurationId, projectId, name, description, permissions })
			.then(() => {
				setState({
					roles: map(getState().roles, (role) => {
						if (role.roleConfigurationId === roleConfigurationId) {
							return {
								...role,
								name,
								description,
								permissions,
								translatedName: name, // any custom role that can be updated doesn't have a dedicated translated name
							};
						}
						return role;
					}),
				});
				onSuccess();
			})
			.catch((err) => onError(err))
			.finally(() => {
				setState({
					ui: {
						...getState().ui,
						manageRoles: {
							...getState().ui.manageRoles,
							isPersistingInProgress: false,
						},
					},
				});
			});
	};
