import React, { useMemo } from 'react';
import ErrorIcon from '@atlaskit/icon/core/error';
import InlineMessage from '@atlaskit/inline-message';
import { Box, Text, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import type { RollupInput } from '../../../../../common/types.tsx';
import { messages } from './messages.tsx';

type RemovedFieldErrorProps = {
	removedGlobalInputs: RollupInput[];
	isFieldTooltip?: boolean;
};

export const RemovedFieldError = ({
	removedGlobalInputs,
	isFieldTooltip,
}: RemovedFieldErrorProps) => {
	const { formatMessage } = useIntl();

	const removedGlobalFieldsNotice = useMemo(() => {
		const fields = removedGlobalInputs.map(({ rollupField }) => `"${rollupField.value.label}"`);

		return formatMessage(messages.removedGlobalFieldsMissingFields, {
			fields: fields.join(', '),
			fieldsCount: fields.length,
		});
	}, [formatMessage, removedGlobalInputs]);

	if (!removedGlobalInputs.length) {
		return null;
	}

	if (isFieldTooltip) {
		return (
			<ErrorIcon label={formatMessage(messages.iconLabel)} color={token('color.icon.danger')} />
		);
	}

	return (
		<Box xcss={containerStyles}>
			<InlineMessage
				placement="bottom"
				appearance="error"
				iconLabel={removedGlobalFieldsNotice}
				secondaryText={
					<Text size="small" color="color.text">
						{formatMessage(messages.removedGlobalFields, {
							fieldsCount: removedGlobalInputs.length,
						})}
					</Text>
				}
			>
				<Text>{removedGlobalFieldsNotice}</Text>
			</InlineMessage>
		</Box>
	);
};

const containerStyles = xcss({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: '18px',
});
