type IssueData = {
	fields: {
		[key: string]: unknown;
	};
};

type GetIssueGoalsUpdatesArg = {
	issueData: IssueData;
	goalsFieldKey: string;
};

export const getGoalsArray = ({ issueData, goalsFieldKey }: GetIssueGoalsUpdatesArg): string[] => {
	const selectedIssueFieldValue = issueData.fields[goalsFieldKey];
	if (Array.isArray(selectedIssueFieldValue)) {
		return selectedIssueFieldValue.map((item: string) => item);
	}
	return [];
};
