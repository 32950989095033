import type { PolarisIdeaVotes, DeferredMediaContext, DeferredPromise } from '../types.tsx';
import {
	getProjectId,
	isLoading,
	isLoadingArchived,
	getIdeaVotes,
	getIssueLinks,
	isLoadingLinkedIssues,
	getGroupedIssueLinks,
	getAttachments,
	getAttachmentsInUpload,
	getFieldsSidebarConfig,
	getPreloadedSummary,
	getPreloadedDescription,
	getPreloadedIssueTypeIcon,
	isIssueStateCallbackHandled,
	getDeliveryTicketsGroupedByParent,
	getIdeaNotFoundError,
	isWatching,
	getWatchCount,
	getIssueWatchersList,
	isEditingSummary,
	getIssueWatchersMap,
	getIssuesRemote,
	getIssueLinksMap,
	isSingleIssueLoaded,
	getCriticalDataLoadingError,
} from './index.tsx';
import { createHigherLevelIdeaHook, createIdeaHook } from './utils.tsx';

export const useIdeaMediaContext = createIdeaHook((state) => state.mediaContext);

export const useIdeaMediaContextDeferred: () => DeferredPromise<DeferredMediaContext> | undefined =
	createIdeaHook((state) => state.mediaContextDeferred);

export const useProjectId = createIdeaHook(getProjectId);
export const useIsLoading = createIdeaHook(isLoading);
export const useIsLoadingArchived = createIdeaHook(isLoadingArchived);
export const useVotes: () => PolarisIdeaVotes | undefined = createIdeaHook(getIdeaVotes);
export const useIssueLinks = createIdeaHook(getIssueLinks);
export const useIssueLinksMap = createIdeaHook(getIssueLinksMap);
export const useDeliveryTicketsByParent = createIdeaHook(getDeliveryTicketsGroupedByParent);
export const useIsWatching = createHigherLevelIdeaHook(isWatching);
export const useWatchCount = createIdeaHook(getWatchCount);
export const useIssueWatchersList = createIdeaHook(getIssueWatchersList);
export const useIssueWatchersMap = createIdeaHook(getIssueWatchersMap);
export const useIsLoadingLinkedIssues = createIdeaHook(isLoadingLinkedIssues);
export const useGroupedIssueLinks = createIdeaHook(getGroupedIssueLinks);
export const useAttachments = createIdeaHook(getAttachments);
export const useAttachmentsInUpload = createIdeaHook(getAttachmentsInUpload);
export const useFieldsSidebarConfig = createIdeaHook(getFieldsSidebarConfig);
export const usePreloadedSummary = createIdeaHook(getPreloadedSummary);
export const usePreloadedDescription = createIdeaHook(getPreloadedDescription);
export const usePreloadedIssueTypeIcon = createIdeaHook(getPreloadedIssueTypeIcon);
export const useIsIssueStateCallbackHandled = createIdeaHook(isIssueStateCallbackHandled);
export const useIsSingleIssueLoaded = createIdeaHook(isSingleIssueLoaded);
export const useIdeaNotFoundError = createIdeaHook(getIdeaNotFoundError);
export const useIsEditingSummary = createHigherLevelIdeaHook<string, boolean>(isEditingSummary);
export const useIssuesRemote = createIdeaHook(getIssuesRemote);
export const useCriticalDataLoadingError = createIdeaHook(getCriticalDataLoadingError);
