// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import { logSafeErrorWithoutCustomerDataWrapper } from '@atlassian/jira-polaris-lib-errors/src/common/utils/index.tsx';
import { getAvailableProductsIncludingGlobalExperienceForOrg } from '../../../services/jira/available-products/index.tsx';
import type { Props, State } from '../types.tsx';

export const loadAvailableProducts =
	(): Action<State, Props> =>
	async ({ getState, setState }, { cloudId }: Props) => {
		if (getState().loading || getState().data) {
			return;
		}

		setState({
			loading: true,
			error: null,
			data: null,
		});

		try {
			const response = await getAvailableProductsIncludingGlobalExperienceForOrg(cloudId);

			setState({
				loading: false,
				error: null,
				data: response,
			});
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			logSafeErrorWithoutCustomerDataWrapper(
				'polaris.load-available-products',
				'Failed to load available products',
				error,
			);
			setState({
				loading: false,
				error,
				data: null,
			});
		}
	};
