import React, { useEffect, useState } from 'react';
import { CommentsField as CommonCommentsField } from '@atlassian/jira-polaris-common/src/ui/fields/comments/index.tsx';
import { useIsExportingViewImage } from '@atlassian/jira-polaris-component-view-export/src/controllers/selectors.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { callWhenIdle } from '@atlassian/jira-call-when-idle/src/index.tsx';

type Props = {
	localIssueId: LocalIssueId;
};

export const CommentsFieldLazy = ({ localIssueId }: Props) => {
	const [render, setRender] = useState(false);
	const isExportingViewImage = useIsExportingViewImage();

	useEffect(() => callWhenIdle(() => setRender(true)), []);

	return render || isExportingViewImage ? (
		<CommonCommentsField appearance="list" localIssueId={localIssueId} />
	) : null;
};

export const CommentsField = CommentsFieldLazy;
