import React, { useEffect, useState, type KeyboardEvent, type ReactNode } from 'react';
import noop from 'lodash/noop';
import difference from 'lodash/difference';
import keyBy from 'lodash/keyBy';
import type { CSSObjectWithLabel } from 'react-select';
import InfoIcon from '@atlaskit/icon/core/migration/information--info';
import { Inline, xcss, Stack, Text } from '@atlaskit/primitives';
import type { User, Email, Value } from '@atlaskit/user-picker';
import { token } from '@atlaskit/tokens';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { PRODUCT_DISCOVERY_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { SuggestedUsers } from '@atlassian/jira-polaris-lib-suggest-users/src/ui/index.tsx';
import { SmartUserPicker } from '@atlassian/jira-polaris-lib-smart-user-picker/src/ui/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import {
	PolarisEnvironmentContainerTypes,
	useEnvironmentContainer,
} from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import { useIsSiteAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-site-admin/index.tsx';
import { getContributorPermissions } from '@atlassian/jira-polaris-lib-project-access/src/services/jira/get-contributor-permissions/index.tsx';
import { getRoles } from '@atlassian/jira-polaris-lib-project-access/src/services/jira/get-roles/index.tsx';
import { getRoleDetails } from '@atlassian/jira-polaris-lib-project-access/src/services/jira/get-role-details/index.tsx';
import { PolarisModal } from '@atlassian/jira-polaris-lib-project-access/src/ui/add-people/index.tsx';
import messages from './messages.tsx';
import { PressableLink } from './PressableLink.tsx';

const Bold = (chunks: ReactNode) => <Text weight="bold">{chunks}</Text>;

type InternalUser = User & { type: 'user' };

export type SmartUserPickerWithSuggestionsPrincipal = User | Email;

type SmartUserPickerWithSuggestionsProps = {
	onChangePrincipals: (principals: SmartUserPickerWithSuggestionsPrincipal[]) => void;
	principals: SmartUserPickerWithSuggestionsPrincipal[];
};

export const SmartUserPickerWithSuggestions = ({
	onChangePrincipals,
	principals,
}: SmartUserPickerWithSuggestionsProps) => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [isModalOpen, setModalOpen] = useState(false);

	const [isFetchingCreatorRolesIds, setIsFetchingCreatorRolesIds] = useState(false);
	const [isFetchingCreatorRolesIdsError, setIsFetchingCreatorRolesIdsError] =
		useState<Error | null>(null);
	const [creatorRolesIds, setCreatorRolesIds] = useState<number[]>([]);

	const isSiteAdmin = useIsSiteAdmin();
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const container = useEnvironmentContainer();
	const isProjectTypeContainer = container?.type === PolarisEnvironmentContainerTypes.PROJECT;
	const canShowInvitePeopleModal =
		!isFetchingCreatorRolesIds && !isFetchingCreatorRolesIdsError && isProjectTypeContainer;

	const emailPrincipals: Email[] = principals.filter(
		(principal): principal is Email => principal.type === 'email',
	);

	const areEmailsPresent = emailPrincipals.length > 0;

	const internalUserPrincipals: InternalUser[] = principals.filter(
		(principal): principal is InternalUser => principal.type === 'user',
	);

	const excludedUserIds = internalUserPrincipals.map(({ id }) => id);

	const handleSuggestedUserClick = (selectedUser: User) => {
		onChangePrincipals([...principals, selectedUser]);
	};

	const handleCtaClick = () => {
		setModalOpen(true);
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'invitePeople',
				attributes: {
					emailCount: emailPrincipals.length,
				},
			},
		}).fire();
	};

	useEffect(() => {
		if (!isProjectTypeContainer) {
			return;
		}

		setIsFetchingCreatorRolesIds(true);
		setIsFetchingCreatorRolesIdsError(null);

		Promise.all([
			getContributorPermissions(),
			getRoles(container.projectId),
			getRoleDetails(container.projectId, formatMessage),
		])
			.then(([contributorPermissionsResponse, rolesResponse, roleDetailsResponse]) => {
				const contributorPermissionsKeys = contributorPermissionsResponse.permissions.map(
					({ key }) => key,
				);
				const roleDetailsMap = keyBy(roleDetailsResponse, 'name');
				const roles = rolesResponse.roles.map((role) => {
					const roleDetails = roleDetailsMap[role.name];
					return {
						id: roleDetails.id,
						permissions: role.permissions,
					};
				});
				const nonContributorRoles = roles.filter((role) => {
					const nonContributorPermissions = difference(
						role.permissions || [],
						contributorPermissionsKeys,
					);
					return nonContributorPermissions.length > 0;
				});
				const nonContributorRolesIds = nonContributorRoles.map((role) => role.id);
				setCreatorRolesIds(nonContributorRolesIds);
				setIsFetchingCreatorRolesIds(false);
			})
			.catch((error) => {
				setIsFetchingCreatorRolesIdsError(error);
				setIsFetchingCreatorRolesIds(false);
			});
	}, [container, isProjectTypeContainer, formatMessage]);

	useEffect(() => {
		if (!areEmailsPresent) {
			return;
		}

		createAnalyticsEvent({
			type: 'sendScreenEvent',
			data: {
				name: 'informInviteOnShare',
			},
		}).fire();
	}, [areEmailsPresent, createAnalyticsEvent]);

	return (
		<Stack space="space.075">
			<SmartUserPicker
				allowEmail
				fieldId="share-dialog"
				isMulti
				noOptionsMessage={
					// this is not a separate component because it caused rerenders of the separate component and/or the parent (this) component - depending on where the "state" was stored
					areEmailsPresent ? (
						<Inline alignBlock="start" space="space.200" xcss={inviteMessageContainer}>
							<InfoIcon label="" color={token('color.icon.subtle')} />
							<Stack alignInline="start" space="space.100">
								<Text align="start" color="color.text.subtle" size="medium">
									{formatMessage(messages.inviteMessage, {
										userEmail: emailPrincipals[0].id,
										userCount: emailPrincipals.length - 1,
										b: Bold,
									})}
								</Text>
								{canShowInvitePeopleModal ? (
									<Text color="color.link" weight="semibold" size="medium">
										<PressableLink isBlue onClick={handleCtaClick}>
											{formatMessage(messages.invitePeopleCta)}
										</PressableLink>
									</Text>
								) : null}
							</Stack>
						</Inline>
					) : undefined
				}
				onChange={
					// In practice, the user picker always returns OptionData[] for this configuration, but TypeScript doesn't know that, so handle all cases
					(value: Value) => {
						if (!value) {
							onChangePrincipals([]);
						} else {
							const valueAsArray = Array.isArray(value) ? value : [value];
							const typedValue = valueAsArray.filter(
								(el): el is SmartUserPickerWithSuggestionsPrincipal =>
									el.type === 'email' || el.type === 'external_user' || el.type === 'user',
							);
							onChangePrincipals(typedValue);
						}
					}
				}
				onBlur={() => {
					setIsMenuOpen(false);
				}}
				onClose={() => {
					setIsMenuOpen(false);
				}}
				onFocus={() => {
					if (areEmailsPresent) {
						setIsMenuOpen(true);
					}
				}}
				onInputChange={() => {
					setIsMenuOpen(true);
				}}
				onKeyDown={(e: KeyboardEvent<Element>) => {
					if (e.key === 'Escape') {
						e.stopPropagation();
					}
				}}
				onSelection={() => {
					setIsMenuOpen(areEmailsPresent);
				}}
				open={isMenuOpen}
				styles={{
					control: (provided: CSSObjectWithLabel) => ({
						...provided,
						minHeight: '40px',
						maxHeight: '117px',
						width: '100%',
						maxWidth: '552px',
						overflowY: 'auto', // enables scrolling when content exceeds maxHeight
						overflowX: 'auto', // Enable horizontal scrolling when content exceeds maxWidth
					}),
					valueContainer: (provided: CSSObjectWithLabel) => ({
						...provided,
						paddingTop: token('space.025'),
						paddingBottom: token('space.025'),
					}),
				}}
				value={principals}
			/>
			<SuggestedUsers
				excludedUserIds={excludedUserIds}
				onSuggestedUserClick={handleSuggestedUserClick}
			/>
			{canShowInvitePeopleModal && (
				<PolarisModal
					isOpen={isModalOpen}
					onClose={() => setModalOpen(false)}
					projectId={+container.projectId}
					projectKey={container.projectKey}
					projectType={PRODUCT_DISCOVERY_PROJECT}
					isAdmin={isSiteAdmin}
					allowFlags
					analyticsPrefix="polaris.share"
					onError={noop}
					creatorRolesIds={creatorRolesIds}
					onAddSuccess={noop}
				/>
			)}
		</Stack>
	);
};

const inviteMessageContainer = xcss({
	padding: 'space.200',
	paddingTop: 'space.150',
	paddingBottom: 'space.150',
});
