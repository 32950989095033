import React from 'react';
import {
	SUMMARY_FIELDKEY,
	DESCRIPTION_FIELDKEY,
	ISSUETYPE_FIELDKEY,
} from '@atlassian/jira-polaris-domain-field/src/field/constants.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { useHasProjectCustomIssueTypes } from '@atlassian/jira-polaris-component-issue-types/src/controllers/index.tsx';
import {
	PolarisEnvironmentContainerTypes,
	useEnvironmentContainer,
} from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { useSubmitIdeaFormViewProperty } from '@atlassian/jira-polaris-component-project-properties/src/controllers/selectors/properties/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useSubmitIdeaFormView } from '../../../controllers/project-properties/index.tsx';
import { useValidIssueSubmissionFormFieldKeys } from '../../../controllers/views/selectors/view-hooks.tsx';
import { FieldRenderer } from '../render-field/index.tsx';
import { usePredefinedFieldValues, usePredefinedFiltersFieldValues } from '../utils.tsx';

const IssueCreationFormOld = ({
	isPreview,
	shouldUpdateTemplate,
	setFieldValue,
}: {
	isPreview?: boolean;
	shouldUpdateTemplate?: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	setFieldValue?: (name: string, value: any) => void;
}) => {
	const viewId = useSubmitIdeaFormView();
	const viewIdProperty = useSubmitIdeaFormViewProperty();
	const formFieldKeys = useValidIssueSubmissionFormFieldKeys(
		fg('polaris_extract-project-properties') ? viewIdProperty.value : viewId.value,
	);
	const predefinedValues = usePredefinedFiltersFieldValues(
		fg('polaris_extract-project-properties') ? viewIdProperty.value : viewId.value,
	);

	// use summary and description fields by default if empty fieldKeys list return for form
	const fieldKeysWithDescription = formFieldKeys.length ? [...formFieldKeys] : [SUMMARY_FIELDKEY];
	fieldKeysWithDescription.splice(
		fieldKeysWithDescription.indexOf(SUMMARY_FIELDKEY) + 1,
		0,
		DESCRIPTION_FIELDKEY,
	);

	return (
		<>
			{fieldKeysWithDescription.map((fieldKey: FieldKey) => {
				const predefinedValue = predefinedValues.find(
					(field) => field.fieldKey === fieldKey,
				)?.value;
				return (
					<FieldRenderer
						key={fieldKey}
						predefinedValue={predefinedValue}
						fieldKey={fieldKey}
						setFieldValue={setFieldValue}
						isPreview={isPreview}
						shouldUpdateTemplate={shouldUpdateTemplate}
					/>
				);
			})}
		</>
	);
};

const IssueCreationFormNew = ({
	isPreview,
	shouldUpdateTemplate,
	setFieldValue,
}: {
	isPreview?: boolean;
	shouldUpdateTemplate?: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	setFieldValue?: (name: string, value: any) => void;
}) => {
	const container = useEnvironmentContainer();
	const projectId =
		container?.type === PolarisEnvironmentContainerTypes.PROJECT ? container.projectId : undefined;
	const hasProjectCustomIssueTypes = useHasProjectCustomIssueTypes({ projectId });
	const viewId = useSubmitIdeaFormView();
	const viewIdProperty = useSubmitIdeaFormViewProperty();
	let formFieldKeys = useValidIssueSubmissionFormFieldKeys(
		fg('polaris_extract-project-properties') ? viewIdProperty.value : viewId.value,
	);

	const predefinedValues = usePredefinedFieldValues(
		fg('polaris_extract-project-properties') ? viewIdProperty.value : viewId.value,
	);

	if (!hasProjectCustomIssueTypes) {
		formFieldKeys = formFieldKeys.filter((fieldKey) => fieldKey !== ISSUETYPE_FIELDKEY);
	}

	return (
		<>
			{formFieldKeys.map((fieldKey: FieldKey) => (
				<FieldRenderer
					key={fieldKey}
					predefinedValue={predefinedValues.get(fieldKey)}
					fieldKey={fieldKey}
					setFieldValue={setFieldValue}
					isPreview={isPreview}
					shouldUpdateTemplate={shouldUpdateTemplate}
				/>
			))}
		</>
	);
};

export const IssueCreationForm = componentWithFG(
	'jpd_issue_types_ga',
	IssueCreationFormNew,
	IssueCreationFormOld,
);
