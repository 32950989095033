import { defineMessages } from '@atlassian/jira-intl';
// ESLint is having problems with the spelling of the word 'JPEG'

export default defineMessages({
	button: {
		id: 'polaris-component-view-export.export-view-dialog.export-dialog-content.export-as-image.button',
		// eslint-disable-next-line @atlassian/tangerine/i18n/messages-are-spelled-correctly
		defaultMessage: 'Download JPEG',
		description: 'Button label to run export view as JPEG image',
	},
	exportAsJpeg: {
		id: 'polaris-component-view-export.export-view-dialog.export-dialog-content.export-as-jpeg',
		// eslint-disable-next-line @atlassian/tangerine/i18n/messages-are-spelled-correctly
		defaultMessage: 'Export as JPEG',
		description: 'Button label to run export view as JPEG image',
	},
	label: {
		id: 'polaris-component-view-export.export-view-dialog.export-dialog-content.export-as-image.label',
		defaultMessage: 'Download this view as an image for you to share',
		description: 'Message explaining export view as JPEG image',
	},
});
