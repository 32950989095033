import React, { type MouseEvent, type PropsWithChildren } from 'react';
import { styled } from '@compiled/react';
import { Box } from '@atlaskit/primitives';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { ViewLayoutType } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import {
	useStatus,
	useSafeIssueKey,
	useMultiSelect,
	useSingleLinkedStatus,
} from '../../../../controllers/issue/selectors/properties/hooks.tsx';
import { StatusLozenge } from '../../../common/status/index.tsx';
import { CommentsField } from '../../../fields/comments/index.tsx';
import { InsightsField } from '../../../fields/insights/index.tsx';
import { ObservableValuesListContext } from '../../../fields/observable-values-list/context/index.tsx';
import { PlayField } from '../../../fields/play/index.tsx';
import { ReactionsField } from '../../../fields/reactions/index.tsx';
import { ConnectionField } from '../../body/connection/index.tsx';
import { FieldList } from '../../body/main.tsx';
import { MultiSelectField } from '../../body/multi-select/index.tsx';
import { SingleSelectField } from '../../body/single-select/index.tsx';
import { StringListField } from '../../body/string-list/index.tsx';
import { StringField } from '../../body/string/index.tsx';
import { isHandledFieldType } from '../../body/types.tsx';
import { DISPLAYING_FIELDS_MIN_CARD_WIDTH } from '../../constants.tsx';
import { KeyField } from '../../header/key-field/index.tsx';
import {
	FULL_WIDTH_FIELD_TYPES,
	MULTI_OPTION_COMPACT_MAX_WIDTH,
	OPTION_COMPACT_MAX_WIDTH,
} from '../constants.tsx';
import { SummaryCardFieldDeliveryStatus } from '../delivery-status/index.tsx';
import { HiddenCount } from '../hidden-count/index.tsx';

type SummaryCardFieldProps = {
	field: Field;
	issueId: LocalIssueId;
	reservedSpace: number;
	maxWidth: number;
	containerWidth: number;
	isResizing?: boolean;
	onHideSummaryTooltip: () => void;
	onShowSummaryTooltip: () => void;
};

type SummaryCardFieldTypeProps = SummaryCardFieldProps & { showCompactOption: boolean };

const ActionFieldContainer = ({ children }: PropsWithChildren<{}>) => (
	<Box onClick={(e: MouseEvent) => e.stopPropagation()}>{children}</Box>
);

const SummaryCardFieldType = (props: SummaryCardFieldTypeProps) => {
	const {
		field,
		issueId,
		isResizing = false,
		showCompactOption,
		onHideSummaryTooltip,
		onShowSummaryTooltip,
	} = props;
	const { key, type } = field;
	const status = useStatus(issueId);
	const issueKey = useSafeIssueKey(issueId);

	switch (type) {
		case FIELD_TYPES.DELIVERY_STATUS:
			return <SummaryCardFieldDeliveryStatus {...props} />;
		case FIELD_TYPES.SINGLE_SELECT:
			return (
				<SingleSelectField
					issueId={issueId}
					fieldKey={key}
					iconOnly={showCompactOption}
					isCompact
				/>
			);
		case FIELD_TYPES.MULTI_SELECT:
		case FIELD_TYPES.JSW_MULTI_SELECT:
			return (
				<MultiSelectField
					fieldKey={field.key}
					issueId={issueId}
					hideEmpty
					showLabel={false}
					isCompact={showCompactOption}
					iconOnly={showCompactOption}
				/>
			);

		case FIELD_TYPES.REACTIONS:
			return (
				<ActionFieldContainer>
					<ReactionsField
						localIssueId={issueId}
						fieldKey={key}
						onReactionPickerClose={onShowSummaryTooltip}
						onReactionPickerOpen={onHideSummaryTooltip}
						isEditable={!fg('jpd_cross_project_connecting')}
					/>
				</ActionFieldContainer>
			);
		case FIELD_TYPES.INSIGHTS:
			return (
				<ActionFieldContainer>
					<InsightsField localIssueId={issueId} appearance="board" />
				</ActionFieldContainer>
			);
		case FIELD_TYPES.ISSUE_COMMENTS:
			return (
				<ActionFieldContainer>
					<CommentsField localIssueId={issueId} appearance="board" />
				</ActionFieldContainer>
			);
		case FIELD_TYPES.VOTES:
			return (
				field.play && (
					<ActionFieldContainer>
						<PlayField playId={field.play.id} localIssueId={issueId} appearance="board" />
					</ActionFieldContainer>
				)
			);
		case FIELD_TYPES.ISSUE_KEY:
			return issueKey && <KeyField issueKey={issueKey} />;
		case FIELD_TYPES.STATUS:
			return (
				status && (
					<StatusLozenge statusCategoryKey={status.statusCategory.key} statusName={status.name} />
				)
			);
		case FIELD_TYPES.HYPERLINK:
			return (
				<HyperLinkWrapper>
					<StringField issueId={issueId} fieldKey={key} isCompact hideEmpty />
				</HyperLinkWrapper>
			);
		case FIELD_TYPES.SHORT_TEXT:
			return (
				<ShortTextWrapper>
					<StringField issueId={issueId} fieldKey={key} isCompact hideEmpty />
				</ShortTextWrapper>
			);
		case FIELD_TYPES.LABELS:
		case FIELD_TYPES.CUSTOM_LABELS:
			return <StringListField fieldKey={key} issueId={issueId} hideEmpty showLabel={false} />;
		case FIELD_TYPES.CONNECTION:
			return (
				<ConnectionField
					variant={ViewLayoutType.SUMMARY}
					fieldKey={key}
					issueId={issueId}
					hideEmpty
				/>
			);
		default:
			return isHandledFieldType(field) ? (
				<FieldList
					issueId={issueId}
					fields={[field]}
					isCompact
					isResizing={isResizing}
					hideEmptyFields
					hasMultilineStrings={false}
					disableLazyRendering
					isSummary={false}
				/>
			) : null;
	}
};

const observableListContextValue = {
	HiddenCountComponent: HiddenCount,
};

export const SummaryCardField = (props: SummaryCardFieldProps) => {
	const {
		field: { type, key },
		issueId,
		reservedSpace,
		maxWidth,
	} = props;
	const singleStatus = useSingleLinkedStatus(issueId);
	const options = useMultiSelect(key, issueId);

	const showCompactOption =
		reservedSpace <
		(options.length > 1 ? MULTI_OPTION_COMPACT_MAX_WIDTH : OPTION_COMPACT_MAX_WIDTH);

	const showFullField =
		FULL_WIDTH_FIELD_TYPES.includes(type) ||
		(type === FIELD_TYPES.DELIVERY_STATUS && !singleStatus) ||
		((type === FIELD_TYPES.SINGLE_SELECT ||
			type === FIELD_TYPES.MULTI_SELECT ||
			type === FIELD_TYPES.JSW_MULTI_SELECT) &&
			showCompactOption);

	return (
		<ObservableValuesListContext.Provider value={observableListContextValue}>
			<Container maxWidth={showFullField ? 'none' : `${maxWidth}px`}>
				<SummaryCardFieldType {...props} showCompactOption={showCompactOption} />
			</Container>
		</ObservableValuesListContext.Provider>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const HyperLinkWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'[data-smart-link-container=true]': {
		width: '24px',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const ShortTextWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginTop: '3px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const Container = styled.div<{ maxWidth: string }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	maxWidth: ({ maxWidth }) => maxWidth,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-container-queries, @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	[`@container cardContainer (max-width: ${DISPLAYING_FIELDS_MIN_CARD_WIDTH}px)`]: {
		display: 'none',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-container-queries -- Ignored via go/DSP-18766
	'@container cardContainer (max-width: 110px)': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'[data-component-selector="card-rating_4g6h"], [data-component-selector="reactions-button-wrapper"]':
			{
				display: 'none',
			},
	},
});
