import { getTime } from 'date-fns';
import { createAri } from '@atlassian/jira-platform-ari/src/index.tsx';
import type {
	ViewResponse as CollectionViewResponse,
	MatrixConfig as CollectionViewMatrixConfig,
} from '@atlassian/jira-polaris-domain-collection/src/index.tsx';
import type {
	FieldMap,
	Field,
	FieldKey,
} from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type {
	FieldRollup,
	FieldRollupOperation,
} from '@atlassian/jira-polaris-domain-field/src/rollup/types.tsx';
import type { SortField } from '@atlassian/jira-polaris-domain-field/src/sort/types.tsx';
import type { GroupValue } from '@atlassian/jira-polaris-domain-field/src/value/types.tsx';
import {
	CONNECTION_FIELD_FILTER,
	CONNECTION_FIELD_FILTER_BOARD_COLUMN,
	CONNECTION_FIELD_FILTER_VIEW_GROUP,
	GENERIC_FIELD_FILTER,
	NUMERIC_FIELD_FILTER,
	TEXT_FIELD_FILTER,
	type ConnectionFieldFilterEnumValue,
	type Filter,
	type NumericFieldFilterValue,
} from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import type { PolarisViewTableColumnSize } from '@atlassian/jira-polaris-domain-view/src/list/types.tsx';
import type { SortMode } from '@atlassian/jira-polaris-domain-view/src/sort/types.tsx';
import {
	PolarisTimelineMode,
	type ArrangementInformation,
} from '@atlassian/jira-polaris-domain-view/src/timeline/types.tsx';
import type { ViewSet } from '@atlassian/jira-polaris-domain-view/src/view-set/types.tsx';
import type {
	RemotePublishedView,
	View,
} from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import type { CloudId } from '@atlassian/jira-shared-types/src/general.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { transformIntervalFilter } from '../../../../common/transform/index.tsx';
import type {
	FetchResponse,
	TableFieldRollup,
	TableColumnSize,
	MatrixConfig,
	SortMode as RemoteSortMode,
	SortField as RemoteSortField,
	Filter as RemoteFilter,
} from '../../../../services/jpd-views-service/view-config/types.tsx';
import { FILTER_KIND } from '../../../../common/types.tsx';

export type GetFieldByKeyFunc = (
	fieldKey: string | { key: string } | undefined | null,
) => Field | undefined;

const tryParseArrangementString = (
	arrangementString: string | undefined,
): ArrangementInformation | undefined => {
	try {
		return arrangementString ? JSON.parse(arrangementString) : {};
	} catch (error) {
		return undefined;
	}
};

export const getTimelineConfig = (
	view: FetchResponse | CollectionViewResponse,
	getField: GetFieldByKeyFunc,
) => {
	if (view.visualizationType !== 'TIMELINE') {
		return undefined;
	}

	if (view.timelineConfig === undefined) {
		return {
			mode: PolarisTimelineMode.QUARTERS,
			startDateField: undefined,
			dueDateField: undefined,
			startTimestamp: undefined,
			endTimestamp: undefined,
		};
	}
	return {
		startDateField: getField(view.timelineConfig.startDateField),
		dueDateField: getField(view.timelineConfig.dueDateField),
		mode: view.timelineConfig.mode,
		startTimestamp: view.timelineConfig.startTimestamp ?? undefined,
		endTimestamp: view.timelineConfig.endTimestamp ?? undefined,
		summaryCardField: getField(view.timelineConfig.summaryCardField),
		arrangementInformation:
			'arrangementInfo' in view ? tryParseArrangementString(view.arrangementInfo) : {},
	};
};

const transformGroupValues = (groupValues?: Array<string | GroupValue>): GroupValue[] => {
	if (groupValues === undefined || groupValues === null) {
		return [];
	}

	const transformed: Array<GroupValue> = [];

	groupValues.forEach((value) => {
		if (value !== null && value !== undefined) {
			transformed.push({
				id: typeof value === 'string' ? value : value.id,
				label: undefined,
			});
		}
	});

	return transformed;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const transformDescription = (description: any) => (description !== null ? description : undefined);

const transformMatrixConfig = (
	matrixConfig: MatrixConfig | CollectionViewMatrixConfig | undefined,
	getField: GetFieldByKeyFunc,
) => {
	if (!matrixConfig) return undefined;

	const axes =
		matrixConfig.axes?.map((axis) => ({
			dimension: axis.dimension,
			field: getField(axis.field),
			fieldOptions: transformGroupValues(axis.fieldOptions),
			reversed: axis.reversed,
		})) ?? [];

	return {
		axes,
	};
};

const transformTableColumnSizes = (
	tableColumnSizes: TableColumnSize[],
	getField: GetFieldByKeyFunc,
): PolarisViewTableColumnSize[] =>
	tableColumnSizes
		.map(({ field, size }) => ({
			fieldKey: getField(field)?.key,
			size,
		}))
		.filter((col): col is PolarisViewTableColumnSize => !!col.fieldKey);

const transformFieldRollups = (
	fieldRollups: TableFieldRollup[],
	getField: GetFieldByKeyFunc,
): FieldRollup[] =>
	fieldRollups
		.map(({ field, rollup }) => {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			const rollupOperation = rollup as unknown as FieldRollupOperation;
			return {
				fieldKey: getField(field)?.key,
				rollup: rollupOperation,
			};
		})
		.filter((rollup): rollup is FieldRollup => !!rollup.fieldKey);

const transformFilter = (
	gqlFilter: RemoteFilter[] | undefined | null,
	getField: GetFieldByKeyFunc,
): Filter[] => {
	if (gqlFilter === undefined || gqlFilter === null) {
		return [];
	}

	const transformed: Array<Filter> = [];

	gqlFilter.forEach((value) => {
		if (value?.values !== null && value?.values !== undefined) {
			const resolvedField = getField(value.field);

			switch (value.kind) {
				case FILTER_KIND.FIELD_IDENTITY: {
					if (resolvedField !== undefined) {
						const filterValues: Array<{
							stringValue: string | undefined;
						}> = [];
						value.values.forEach((fv) => {
							if (fv !== null && fv !== undefined) {
								filterValues.push({
									stringValue:
										fv.stringValue !== null && fv.stringValue !== '' ? fv.stringValue : undefined,
								});
							}
						});
						transformed.push({
							type: GENERIC_FIELD_FILTER,
							field: resolvedField.key,
							fieldType: resolvedField.type,
							values: filterValues,
						});
					}
					break;
				}
				case FILTER_KIND.FIELD_NUMERIC: {
					if (resolvedField !== undefined) {
						const filterValues: Array<NumericFieldFilterValue> = [];
						value.values.forEach((fv) => {
							if (
								fv?.operator === 'EQ' ||
								fv?.operator === 'GT' ||
								fv?.operator === 'LT' ||
								fv?.operator === 'GTE' ||
								fv?.operator === 'LTE' ||
								fv?.operator === 'NEQ'
							) {
								filterValues.push({
									numericValue: fv.numericValue !== null ? fv.numericValue : undefined,
									operator: fv.operator,
								});
							}
						});
						transformed.push({
							type: NUMERIC_FIELD_FILTER,
							field: resolvedField.key,
							fieldType: resolvedField.type,
							values: filterValues,
						});
					}
					break;
				}
				case FILTER_KIND.INTERVAL: {
					if (resolvedField !== undefined) {
						transformed.push(transformIntervalFilter(value.values, resolvedField.key));
					}
					break;
				}
				case FILTER_KIND.TEXT: {
					const filterValues: Array<{
						stringValue: string | undefined;
					}> = [];
					value.values.forEach((fv) => {
						if (fv !== null && fv !== undefined) {
							filterValues.push({
								stringValue: fv.stringValue !== null ? fv.stringValue : undefined,
							});
						}
					});
					transformed.push({
						type: TEXT_FIELD_FILTER,
						values: filterValues,
					});
					break;
				}
				case FILTER_KIND.CONNECTION_FIELD_IDENTITY:
					if (fg('jpd_issues_relationships')) {
						if (resolvedField !== undefined) {
							const filterValues: Array<{
								enumValue: ConnectionFieldFilterEnumValue;
							}> = [];

							value.values.forEach((fv) => {
								if (fv !== null && fv !== undefined && fv.enumValue !== undefined) {
									if (
										fv.enumValue === CONNECTION_FIELD_FILTER_BOARD_COLUMN ||
										fv.enumValue === CONNECTION_FIELD_FILTER_VIEW_GROUP
									) {
										filterValues.push({
											enumValue: fv.enumValue,
										});
									}
								}
							});
							transformed.push({
								type: CONNECTION_FIELD_FILTER,
								field: resolvedField.key,
								fieldType: resolvedField.type,
								values: filterValues,
							});
						}
					}
					break;
				default:
				// do nothing
			}
		}
	});

	return transformed;
};

const transformSort = (
	sort: RemoteSortField[] | undefined,
	getField: GetFieldByKeyFunc,
): SortField[] | undefined =>
	sort?.reduce<SortField[]>((acc, sortField) => {
		const fieldKey = getField(sortField.field)?.key;

		if (fieldKey !== undefined) {
			acc.push({
				fieldKey,
				asc: sortField.order === 'ASC',
			});
		}

		return acc;
	}, []);

const getHiddenFields = (view: FetchResponse | CollectionViewResponse): FieldKey[] => {
	// ViewResponse
	if ('hidden' in view) {
		return view.hidden ?? [];
	}
	// FetchResponse
	if ('hiddenFields' in view) {
		return view.hiddenFields ?? [];
	}
	return [];
};

const transformSortMode = (
	sortMode: RemoteSortMode,
	sort: RemoteSortField[] | undefined,
): SortMode | undefined => {
	switch (sortMode) {
		case 'FIELDS_SORT':
			if (sort && sort.length) {
				return 'fieldSort';
			}
			return 'projectRank';
		case 'PROJECT_RANK':
			return 'projectRank';
		case 'VIEW_RANK':
			return 'viewRank';
		default:
			return undefined;
	}
};

export const transformViewConfig = (
	view: FetchResponse | CollectionViewResponse,
	fields: FieldMap,
): Pick<
	View,
	| 'filter'
	| 'description'
	| 'fields'
	| 'hidden'
	| 'fieldRollups'
	| 'groupBy'
	| 'verticalGroupBy'
	| 'groupValues'
	| 'verticalGroupValues'
	| 'hideEmptyGroups'
	| 'hideEmptyColumns'
	| 'containsArchived'
	| 'sortBy'
	| 'sortMode'
	| 'lastCommentsViewedTimestamp'
	| 'tableColumnSizes'
	| 'matrixConfig'
	| 'timelineConfig'
> => {
	const getField: GetFieldByKeyFunc = (fieldKey) => {
		if (!fieldKey) return undefined;

		return fields[typeof fieldKey === 'string' ? fieldKey : fieldKey.key];
	};

	const resolvedGroupByKey = getField(view.groupBy)?.key;
	const resolvedVerticalGroupByKey = getField(view.verticalGroupBy)?.key;

	const resolvedFieldKeys = view.fields
		?.map(getField)
		.filter(Boolean)
		.map(({ key }) => key)
		.filter(Boolean);

	const groupValues = transformGroupValues(view.groupValues);
	const verticalGroupValues = transformGroupValues(view.verticalGroupValues);

	const description = transformDescription(view.description);
	const filter = transformFilter(view.filter, getField);
	const hidden = getHiddenFields(view);

	return {
		description,
		filter,
		fields: resolvedFieldKeys,
		hidden,
		fieldRollups: transformFieldRollups(view.fieldRollups ?? [], getField),
		groupBy: resolvedGroupByKey,
		verticalGroupBy: resolvedVerticalGroupByKey,
		groupValues,
		verticalGroupValues,
		hideEmptyGroups: view.hideEmptyGroups || false,
		hideEmptyColumns: view.hideEmptyColumns || false,
		containsArchived: false,
		sortBy: transformSort(view.sort, getField),
		sortMode: view.sortMode ? transformSortMode(view.sortMode, view.sort) : undefined,
		lastCommentsViewedTimestamp:
			view.lastCommentsViewedTimestamp === undefined
				? undefined
				: getTime(new Date(view.lastCommentsViewedTimestamp)),
		tableColumnSizes: transformTableColumnSizes(view.tableColumnSizes ?? [], getField),
		matrixConfig: transformMatrixConfig(view.matrixConfig, getField),
		timelineConfig: getTimelineConfig(view, getField),
	};
};

export const transformViewInternal = (
	viewUUID: string,
	cloudId: string,
	view: FetchResponse,
	fields: FieldMap,
): RemotePublishedView => ({
	uuid: viewUUID,
	viewId: createAri({
		resourceOwner: 'jira',
		cloudId,
		resourceType: 'view',
		resourceId: viewUUID,
	}),
	projectId: view.projectId,
	viewLegacyId: undefined,
	kind: view.visualizationType,
	title: view.name,
	emoji: view.emoji,
	editable: false,
	rank: 0,
	isAutosaveEnabled: false,
	layoutType: view.layoutType ?? null,
	createdAtTimestamp: view.createdAtTimestamp,
	updatedAtTimestamp: view.updatedAtTimestamp,
	rankFieldKey: view.rankField,
	configError: view.error,
	statusCategories: view.statusCategories,
	viewSetId: 'unknown',
	...transformViewConfig(view, fields),
});

export const getRootViewSet = (views: Partial<View>[]): ViewSet => ({
	id: 'unknown',
	type: 'PRIORITIZE',
	name: 'root',
	rank: 0,
	collapsed: false,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	views: views as View[],
});

export const transformToViewSets = (
	viewUUID: string,
	view: FetchResponse,
	cloudId: CloudId,
	fields: FieldMap,
): ViewSet[] => [getRootViewSet([transformViewInternal(viewUUID, cloudId, view, fields)])];
