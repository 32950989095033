import React, { useRef } from 'react';
import { styled } from '@compiled/react';
import isEmpty from 'lodash/isEmpty';
import { Flex, Inline, xcss } from '@atlaskit/primitives';
import Spinner from '@atlaskit/spinner';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import { ISSUETYPE_FIELDKEY } from '@atlassian/jira-polaris-domain-field/src/field/constants.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { IssueTypeSelect } from '@atlassian/jira-polaris-component-issue-types/src/ui/issue-type-select/index.tsx';
import { useElementSize } from '../../../common/utils/react/elements.tsx';
import {
	useSummary,
	useIssueCreatedProperty,
	useLinkedDeliveryIssuesExist,
	useIssueType,
} from '../../../controllers/issue/selectors/properties/hooks.tsx';
import { IssueCreateStatusInTransition } from '../../../controllers/issue/types.tsx';
import {
	useCurrentViewSummaryCardField,
	useIsFieldVisibleInCurrentView,
} from '../../../controllers/views/selectors/view-hooks.tsx';
import { LinkedIssuesProgressField } from '../body/linked-issues-progress/index.tsx';
import { DISPLAYING_FIELDS_MIN_CARD_WIDTH } from '../constants.tsx';
import { Highlighter } from '../highlighter/index.tsx';
import { useIsSharedView } from '../../../controllers/environment/index.tsx';
import { SummaryCardField } from './summary-card-field-next/index.tsx';
import { calculateSummaryFieldSpace } from './utils.tsx';
import { SummaryCardField as SummaryCardFieldLegacy } from './summary-card-field/index.tsx';

type SummaryCardProps = {
	id: LocalIssueId;
	isResizing?: boolean;
	onHideSummaryTooltip: () => void;
	onShowSummaryTooltip: () => void;
};

export const SummaryCard = (props: SummaryCardProps) => {
	const { id, isResizing, onHideSummaryTooltip, onShowSummaryTooltip } = props;
	const [setSummaryContainerRef, summaryContainerSize] = useElementSize();
	const summaryRef = useRef<HTMLDivElement>(null);
	const summary = useSummary(id);
	const issueType = useIssueType(id);
	const issueCreatedProperty = useIssueCreatedProperty(id);
	const summaryCardField = useCurrentViewSummaryCardField();
	const deliveryIssuesExist = useLinkedDeliveryIssuesExist(id);
	const isIssueTypeVisibleInCurrentView = useIsFieldVisibleInCurrentView(ISSUETYPE_FIELDKEY);
	const isSharedView = useIsSharedView();

	const fieldSpace = calculateSummaryFieldSpace(
		summaryContainerSize.width,
		summaryRef?.current?.scrollWidth,
	);

	return (
		<>
			<SummaryContainer ref={setSummaryContainerRef}>
				<Flex
					justifyContent="space-between"
					alignItems="center"
					gap="space.050"
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
					xcss={xcss({ width: '100%' })}
				>
					<SummaryContent ref={summaryRef}>
						{!isSharedView && fg('jpd_issue_types_ga') ? (
							<>
								{isIssueTypeVisibleInCurrentView && issueType && (
									<Inline as="span" xcss={issueTypeStyles}>
										<IssueTypeSelect issueTypeId={issueType.id} readonly />
									</Inline>
								)}
								{isEmpty(summary) ? '-' : summary}
							</>
						) : (
							<>{isEmpty(summary) ? '-' : summary}</>
						)}
					</SummaryContent>

					{summaryCardField &&
						(fg('jpd_issues_relationships') ? (
							<SummaryCardField
								issueId={id}
								field={summaryCardField}
								containerWidth={summaryContainerSize.width}
								reservedSpace={fieldSpace.reserved}
								maxWidth={fieldSpace.maxWidth}
								isResizing={isResizing}
								onHideSummaryTooltip={onHideSummaryTooltip}
								onShowSummaryTooltip={onShowSummaryTooltip}
							/>
						) : (
							<SummaryCardFieldLegacy
								issueId={id}
								field={summaryCardField}
								containerWidth={summaryContainerSize.width}
								reservedSpace={fieldSpace.reserved}
								maxWidth={fieldSpace.maxWidth}
								isResizing={isResizing}
								onHideSummaryTooltip={onHideSummaryTooltip}
								onShowSummaryTooltip={onShowSummaryTooltip}
							/>
						))}
				</Flex>
				{issueCreatedProperty && issueCreatedProperty.status === IssueCreateStatusInTransition && (
					<SpinnerWrapper>
						<Spinner />
					</SpinnerWrapper>
				)}
				{summaryCardField?.type === FIELD_TYPES.DELIVERY_PROGRESS && deliveryIssuesExist && (
					<DeliveryProgressWrapper>
						<LinkedIssuesProgressField
							issueId={id}
							fieldKey={summaryCardField?.key}
							hideEmpty
							appearance="ultra-compact"
						/>
					</DeliveryProgressWrapper>
				)}
			</SummaryContainer>
			<Highlighter id={id} isResizing={isResizing} />
		</>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SummaryContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	gap: token('space.025'),
	fontWeight: token('font.weight.semibold'),
	width: '100%',
	height: '38px',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: token('space.300'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SummaryContent = styled.div({
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	flex: 1,
	maxWidth: 'fit-content',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-container-queries, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	[`@container cardContainer (max-width: ${DISPLAYING_FIELDS_MIN_CARD_WIDTH}px)`]: {
		overflow: 'visible',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SpinnerWrapper = styled.div({
	position: 'absolute',
	top: token('space.050'),
	right: token('space.050'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const DeliveryProgressWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginRight: '5px',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginBottom: '5px',
});

const issueTypeStyles = xcss({
	display: 'inline-flex',
	verticalAlign: 'sub',
	marginInlineEnd: 'space.075',
});
