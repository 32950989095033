/** @jsx jsx */
import React, { type SyntheticEvent, memo, useMemo, useCallback } from 'react';
import { jsx, css } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { Box, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { PAGE_MARGIN_X } from '@atlassian/jira-polaris-common/src/common/constants.tsx';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import { IssueCreateGroupTypeNoGroup } from '@atlassian/jira-polaris-common/src/controllers/issue/types.tsx';
import {
	useCloseRightSidebar,
	useOpenRightSidebarOnFieldList,
	useOpenRightSidebarOnFilter,
	useOpenRightSidebarOnSort,
} from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/actions/hooks.tsx';
import { useRightSidebarShowing } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/selectors/hooks.tsx';
import {
	RightSidebarShowingFieldList,
	RightSidebarShowingField,
	RightSidebarShowingCreateField,
	RightSidebarShowingFilter,
	RightSidebarShowingSort,
	showingFieldList,
	showingFilter,
	type Showing,
} from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/types.tsx';
import { useActiveFiltersCount } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/filters-hooks.tsx';
import {
	useCurrentViewGroupBy,
	useCurrentViewVerticalGroupBy,
	useCurrentViewFieldCount,
	useCurrentViewXAxis,
	useCurrentViewYAxis,
	useCurrentViewEndDateField,
	useCurrentViewStartDateField,
	useHasFieldsUnsavedChanges,
	useHasFiltersUnsavedChanges,
	useCanManageCurrentView,
	useCurrentViewKind,
	useCurrentViewContainsArchived,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import {
	useCanCreateAndEditIssues,
	useCanEditIssues,
	useCanDeleteIssues,
	useCanEditFields,
	useCanMergeIdeas,
	useCanArchiveIdeas,
	useCanManageDeliveryTickets,
} from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import {
	VIEW_KIND_TABLE,
	VIEW_KIND_TIMELINE,
	VIEW_KIND_MATRIX,
	VIEW_KIND_BOARD,
} from '@atlassian/jira-polaris-domain-view/src/view/constants.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import {
	type AnalyticsEvent,
	ContextualAnalyticsData,
	SCREEN,
	fireUIAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import type { ViewKind } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { useIsRankingAllowedDuringCreation } from '../utils.tsx';
import { ActionButton } from './action-button/index.tsx';
import { AddIdeaButton } from './add-idea-button/main.tsx';
import ConfigColumns from './config-columns/index.tsx';
import { ConfigExtra } from './config-extra/index.tsx';
import { PolarisQuickSearch } from './config-filters/index.tsx';
import ConfigLayout from './config-layout/index.tsx';
import { ConfigMatrixAxes } from './config-matrix-axis/main.tsx';
import { ConfigTimeline } from './config-timeline/index.tsx';
import { ManageBar } from './manage-bar/index.tsx';
import { messages } from './messages.tsx';
import { SortControlButton } from './sort-button/index.tsx';

type ViewControlsInternalProps = {
	viewKind: ViewKind;
};

const ViewControlsInternal = memo(({ viewKind }: ViewControlsInternalProps) => {
	const { formatMessage } = useIntl();

	const canCreateAndEditIssues = useCanCreateAndEditIssues();
	const [canEditIssues] = useCanEditIssues({ anyProject: true });
	const [canDeleteIssues] = useCanDeleteIssues();

	const canEditFields = useCanEditFields();
	const canManageCurrentView = useCanManageCurrentView();
	const canMergeIdes = useCanMergeIdeas();
	const canArchiveIdeas = useCanArchiveIdeas();
	const canManageDeliveryTickets = useCanManageDeliveryTickets();

	const openRightSidebarOnFieldList = useOpenRightSidebarOnFieldList();
	const openRightSidebarOnFilter = useOpenRightSidebarOnFilter();
	const openRightSidebarOnSort = useOpenRightSidebarOnSort();
	const closeRightSidebar = useCloseRightSidebar();
	const currentFieldCount = useCurrentViewFieldCount();
	const containsArchived = useCurrentViewContainsArchived();

	const [sidebarShowing] = useRightSidebarShowing();

	const activeFiltersCount = useActiveFiltersCount();

	const matrixXAxisField = useCurrentViewXAxis();
	const matrixYAxisField = useCurrentViewYAxis();
	const groupBy = useCurrentViewGroupBy();
	const verticalGroupBy = useCurrentViewVerticalGroupBy();
	const timelineStartDate = useCurrentViewStartDateField();
	const timelineEndDate = useCurrentViewEndDateField();

	const createIssueAction = useIssueActions().createIssue;

	const isRankingAllowedDuringCreation = useIsRankingAllowedDuringCreation();

	const onAddIdeaButtonClick = (_: SyntheticEvent, analyticsEvent: AnalyticsEvent) => {
		fireUIAnalytics(analyticsEvent, 'createIdea');
		experience.listView.createIdeaInline.start();
		experience.listView.createIdeaInlineClickResponse.start();

		createIssueAction(0, undefined, {
			groupType: IssueCreateGroupTypeNoGroup,
			rankingAllowed: isRankingAllowedDuringCreation,
		});
	};

	const hasFiltersUnsavedChanges = useHasFiltersUnsavedChanges();
	const hasFieldsUnsavedChanges = useHasFieldsUnsavedChanges();

	const hasCompletedViewOnboarding =
		(viewKind === VIEW_KIND_TIMELINE && timelineStartDate && timelineEndDate) ||
		(viewKind === VIEW_KIND_BOARD && groupBy) ||
		(viewKind === VIEW_KIND_MATRIX &&
			matrixXAxisField !== undefined &&
			matrixYAxisField !== undefined) ||
		(viewKind !== VIEW_KIND_BOARD &&
			viewKind !== VIEW_KIND_MATRIX &&
			viewKind !== VIEW_KIND_TIMELINE);

	// Click listener on buttons for right sidebar.
	const onControlButtonClick = useCallback(
		(showing: Showing) => {
			if (sidebarShowing.mode === showing.mode) {
				closeRightSidebar();
				return;
			}
			switch (showing.mode) {
				case RightSidebarShowingFieldList:
					openRightSidebarOnFieldList();
					break;
				case RightSidebarShowingFilter:
					openRightSidebarOnFilter();
					break;
				case RightSidebarShowingSort:
					openRightSidebarOnSort();
					break;
				default:
					break;
			}
		},
		[
			closeRightSidebar,
			openRightSidebarOnFieldList,
			openRightSidebarOnFilter,
			openRightSidebarOnSort,
			sidebarShowing.mode,
		],
	);

	const handleFieldsClick = useCallback(
		(event: SyntheticEvent, analyticsEvent: AnalyticsEvent) => {
			fireCompoundAnalyticsEvent.ViewControls.openFieldsSidebarButtonClicked(analyticsEvent);
			onControlButtonClick(showingFieldList);
		},
		[onControlButtonClick],
	);

	const handleFilterClick = useCallback(
		(event: SyntheticEvent, analyticsEvent: AnalyticsEvent) => {
			fireCompoundAnalyticsEvent.ViewControls.openFilterSidebarButtonClicked(analyticsEvent);
			onControlButtonClick(showingFilter());
		},
		[onControlButtonClick],
	);

	const handleSortClick = useCallback(
		(showing: Showing, analyticsEvent: AnalyticsEvent) => {
			fireCompoundAnalyticsEvent.ViewControls.openSortSidebarButtonClicked(analyticsEvent);
			onControlButtonClick(showing);
		},
		[onControlButtonClick],
	);

	// All possible actions with fields.
	const RightSidebarShowingFieldModes = [
		RightSidebarShowingCreateField,
		RightSidebarShowingField,
		RightSidebarShowingFieldList,
	];

	const configColumnsGroupBy = useMemo(
		() => ({
			selectedField: groupBy,
		}),
		[groupBy],
	);

	const configColumnsVerticalGroupBy = useMemo(
		() => ({
			selectedField: verticalGroupBy,
		}),
		[verticalGroupBy],
	);

	return (
		<>
			{viewKind === VIEW_KIND_BOARD && canManageCurrentView && (
				<ConfigColumns
					groupBy={configColumnsGroupBy}
					verticalGroupBy={configColumnsVerticalGroupBy}
				/>
			)}
			{viewKind === VIEW_KIND_MATRIX && canManageCurrentView && (
				<ConfigMatrixAxes matrixXAxis={matrixXAxisField} matrixYAxis={matrixYAxisField} />
			)}
			{viewKind === VIEW_KIND_TIMELINE && canManageCurrentView && <ConfigTimeline />}
			{viewKind === VIEW_KIND_TABLE && !containsArchived && canCreateAndEditIssues && (
				<AddIdeaButton onClick={onAddIdeaButtonClick} />
			)}
			{viewKind === VIEW_KIND_TABLE &&
				(canEditIssues ||
					canDeleteIssues ||
					canManageDeliveryTickets ||
					canMergeIdes ||
					canArchiveIdeas) && <ManageBar />}
			{hasCompletedViewOnboarding && (
				<>
					{(viewKind === VIEW_KIND_TABLE || viewKind === VIEW_KIND_TIMELINE) &&
						canManageCurrentView && (
							<ConfigColumns verticalGroupBy={configColumnsVerticalGroupBy} />
						)}
					<ActionButton
						interactionName="jpd.view-controls.open-filter-sidebar"
						id="polaris.ideas.ui.view-controls.filter-button"
						testId="polaris-ideas.ui.view-controls.filter-button"
						isSelected={sidebarShowing.mode === RightSidebarShowingFilter}
						elemAfter={activeFiltersCount > 0 ? activeFiltersCount : null}
						onClick={handleFilterClick}
						hasUnsavedChanges={hasFiltersUnsavedChanges}
						isEmpty={activeFiltersCount === 0}
					>
						{formatMessage(messages.filtersButton)}
					</ActionButton>
					{viewKind !== VIEW_KIND_MATRIX && (
						<SortControlButton onControlButtonClick={handleSortClick} />
					)}
					{(canManageCurrentView || canEditFields) && (
						<ActionButton
							interactionName="jpd.view-controls.open-fields-sidebar"
							id="polaris.ideas.ui.view-controls.open-fields-sidebar"
							testId="polaris-ideas.ui.view-controls.fields"
							isSelected={RightSidebarShowingFieldModes.some(
								(mode) => mode === sidebarShowing.mode,
							)}
							elemAfter={currentFieldCount > 0 ? currentFieldCount : null}
							onClick={handleFieldsClick}
							hasUnsavedChanges={hasFieldsUnsavedChanges}
							isEmpty={currentFieldCount === 0}
						>
							{formatMessage(messages.fieldsButton)}
						</ActionButton>
					)}
					{canManageCurrentView && viewKind !== VIEW_KIND_TABLE && <ConfigLayout />}
					<ConfigExtra />
					<Box xcss={pendoAnchorStyles} id="pendoAnchor" />
				</>
			)}
			<PolarisQuickSearch />
		</>
	);
});

ViewControlsInternal.displayName = 'ViewControlsInternal';

const ViewControls = memo(() => {
	const viewKind = useCurrentViewKind();

	const children = useMemo(
		() => (
			<div css={controlsContainerStyles}>
				<Box xcss={menuContainerStyles}>
					{viewKind ? <ViewControlsInternal viewKind={viewKind} /> : null}
				</Box>
			</div>
		),
		[viewKind],
	);

	return (
		<ContextualAnalyticsData sourceName="viewControls" sourceType={SCREEN}>
			{children}
		</ContextualAnalyticsData>
	);
});

ViewControls.displayName = 'ViewControls';

export { ViewControls };

const controlsContainerStyles = css({
	paddingTop: token('space.100'),
	paddingBottom: token('space.100'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
	paddingLeft: `${PAGE_MARGIN_X}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
	paddingRight: `${PAGE_MARGIN_X}px`,
	boxSizing: 'border-box',
});

const menuContainerStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	width: '100%',
	whiteSpace: 'nowrap',
	gap: 'space.075',
});

const pendoAnchorStyles = xcss({
	height: '1px',
	width: '1px',
});
