import type { State } from '../types.tsx';

export const getProjectName = (state: State) => state.loadingProps?.projectName;
export const getProjectId = (state: State) => state.loadingProps?.projectId;
export const getProjectKey = (state: State) => state.loadingProps?.projectKey;
export const getIsBeta = (state: State) => state.loadingProps?.isBeta;
export const getAccountId = (state: State) => state.loadingProps?.accountId;
export const isSelfServeEnabled = (state: State) =>
	state.loadingProps?.isSelfServeEnabledOnSite === true;
export const isSiteAdmin = (state: State) => state.loadingProps?.isSiteAdmin;
export const getAppEdition = (state: State) => state.loadingProps?.edition;
export const getIsEnforcePermissionChecks = (state: State) =>
	state.loadingProps?.enforcePermissionChecks;
export const getOnIssueCreationChanged = (state: State) =>
	state.loadingProps?.onIssueCreationChanged;
