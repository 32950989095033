import React from 'react';
import {
	useIssueKey,
	useIssueType,
	useSummary,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { ShortLabel } from '@atlassian/jira-polaris-common/src/ui/issue-short-label/index.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { IssueTypeIcon } from '@atlassian/jira-polaris-component-issue-types/src/ui/issue-type-icon/issue-type-icon.tsx';

type Props = {
	issueId: LocalIssueId;
	showIcon?: boolean;
};

const SelectableOld = ({ issueId }: Props) => {
	const summary = useSummary(issueId);
	const issueKey = useIssueKey(issueId);

	return <ShortLabel issueKey={issueKey} summary={summary} />;
};

const SelectableNew = ({ issueId, showIcon }: Props) => {
	const summary = useSummary(issueId);
	const issueKey = useIssueKey(issueId);
	const issueType = useIssueType(issueId);

	return (
		<ShortLabel
			issueKey={issueKey}
			summary={summary}
			icon={showIcon && issueType && <IssueTypeIcon issueTypeId={issueType.id} />}
		/>
	);
};

export const Selectable = componentWithCondition(
	() => fg('jpd_issue_types_ga'),
	SelectableNew,
	SelectableOld,
);
