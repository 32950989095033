import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { GroupUuid } from '@atlassian/jira-shared-types/src/general.tsx';

export const getUsersHasContributorAccess = (
	projectId: string,
	users: string[],
): Promise<{
	withContributorAccess: Record<string, boolean>;
	withManuallyAddedContributorAccess: Record<GroupUuid, boolean>;
}> =>
	performPostRequest('/rest/polaris/permissions/has-contributor-access', {
		body: JSON.stringify({
			projectId,
			users,
		}),
	}).then((result) => {
		const withManuallyAddedContributorAccess: Record<GroupUuid, boolean> = {};
		(result.manuallyAddedContributorGroups || []).forEach((groupId: GroupUuid) => {
			withManuallyAddedContributorAccess[groupId] = true;
		});
		return {
			withContributorAccess: result.withContributorAccess,
			withManuallyAddedContributorAccess,
		};
	});
