import React, { useCallback, useState } from 'react';
import EditorCloseIcon from '@atlaskit/icon/core/migration/close--editor-close';
import { ModalBody } from '@atlaskit/modal-dialog';
import Portal from '@atlaskit/portal';
import { Flex, Box, xcss } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import { IconButton } from '@atlaskit/button/new';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { JiraModal as ModalDialog } from '@atlassian/jira-modal/src/ui/jira-modal.tsx';
import { ModalTransitionUniversal } from '@atlassian/jira-polaris-common/src/common/ui/modal-transition/index.tsx';
import {
	useCreateWithFilters,
	useSetFilteredLocalIssueId,
} from '../../../controllers/create-with-filters/index.tsx';
import { ActiveFilters } from './active-filters/index.tsx';
import { Key } from './key/index.tsx';
import messages from './messages.tsx';
import { Summary } from './summary/index.tsx';

export const FilteredIssueModal = () => {
	const { formatMessage } = useIntl();

	const [portalElement, setPortalElement] = useState<HTMLElement | null>(null);

	const [{ filteredLocalIssueId }] = useCreateWithFilters();
	const setFilteredLocalIssue = useSetFilteredLocalIssueId();

	const onClose = useCallback(() => setFilteredLocalIssue(undefined), [setFilteredLocalIssue]);

	if (filteredLocalIssueId === undefined) {
		return null;
	}

	return (
		<ModalTransitionUniversal>
			<ShortcutScope>
				<ModalDialog
					autoFocus={false}
					onClose={onClose}
					width="large"
					focusLockAllowlist={(element) => element.tagName !== 'INPUT'}
					messageId="polaris-ideas.ui.view-content.filtered-issue-modal.modal-dialog"
					messageType="transactional"
				>
					<ModalBody>
						<Flex xcss={contentContainerStyles} direction="column">
							<Portal zIndex={9999}>
								<div ref={setPortalElement} />
							</Portal>
							<Box xcss={wrapperStyles}>
								<Box xcss={keyContainerStyles}>
									<Key localIssueId={filteredLocalIssueId} />
								</Box>
								<Box xcss={summaryContainerStyles}>
									<Summary localIssueId={filteredLocalIssueId} />
								</Box>
								<Box xcss={descriptionStyles}>{formatMessage(messages.description)}</Box>
								<Box xcss={filterContainerStyles}>
									<ActiveFilters
										localIssueId={filteredLocalIssueId}
										portalElement={portalElement ?? undefined}
										onClose={onClose}
									/>
								</Box>
							</Box>
							<Box xcss={closeButtonContainerStyles}>
								<Tooltip content={formatMessage(messages.closeButtonTooltip)}>
									<IconButton
										appearance="subtle"
										icon={EditorCloseIcon}
										onClick={onClose}
										label={formatMessage(messages.editorCloseIcon)}
									/>
								</Tooltip>
							</Box>
						</Flex>
					</ModalBody>
				</ModalDialog>
			</ShortcutScope>
		</ModalTransitionUniversal>
	);
};

const contentContainerStyles = xcss({
	flex: '1 1 auto',
	paddingTop: 'space.300',
	paddingBottom: 'space.300',
	overflow: 'auto',
	width: '100%',
	position: 'relative',
});

const keyContainerStyles = xcss({
	marginBottom: 'space.100',
	marginLeft: 'space.025',
});

const closeButtonContainerStyles = xcss({
	position: 'absolute',
	right: '0px',
	top: '18px',
});

const summaryContainerStyles = xcss({
	marginTop: 'space.025',
});

const wrapperStyles = xcss({
	flex: '0 0 auto',
});

const descriptionStyles = xcss({
	font: 'font.body',
	color: 'color.text',
	marginLeft: 'space.050',
});

const filterContainerStyles = xcss({
	marginTop: 'space.200',
	marginLeft: 'space.050',
});
