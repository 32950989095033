// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createStore, createActionsHook } from '@atlassian/react-sweet-state';
import type { Props, State } from '../common/types.tsx';
import { loadProductSettings } from './actions/load/index.tsx';
import { updateFinancialYearInitialMonth } from './actions/update-product-settings/update-financial-year.tsx';

const actions = { loadProductSettings, updateFinancialYearInitialMonth };

const initialState: State = {
	productSettings: {
		status: 'idle',
	},
};

export const ProductSettingsStore = createStore<State, typeof actions, Props>({
	name: 'ProductSettingsStore',
	initialState,
	actions,
});

export const useProductSettingsActions = createActionsHook(ProductSettingsStore);
