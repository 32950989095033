import React, {
	useState,
	useMemo,
	useRef,
	useEffect,
	useCallback,
	type ReactNode,
	type SyntheticEvent,
	type ChangeEvent,
} from 'react';
import { styled } from '@compiled/react';
import debounce from 'lodash/debounce';
import defer from 'lodash/defer';
import isString from 'lodash/isString';
import trim from 'lodash/trim';
import type { EmojiDescription } from '@atlaskit/emoji';
import { ErrorMessage } from '@atlaskit/form';
import { Flex, xcss } from '@atlaskit/primitives';
import Textfield from '@atlaskit/textfield';
import { N20A } from '@atlaskit/theme/colors';
import Toggle from '@atlaskit/toggle';
import { useThemeObserver, token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { DN100A } from '@atlassian/jira-polaris-lib-color-palette/src/ui/colors/index.tsx';
import { ColorPicker } from '@atlassian/jira-polaris-lib-color-picker/src/ui/index.tsx';
import { EmojiPicker } from '@atlassian/jira-polaris-lib-emoji-picker/src/ui/index.tsx';
import { InputWithEmojiPicker } from '@atlassian/jira-polaris-lib-input-with-emoji-picker/src/ui/index.tsx';
import messages from './messages.tsx';
import type { FieldDecoratorProps } from './types.tsx';

type HighlightProps = {
	highlighted: boolean;
	onChange: (arg1: boolean) => void;
	testId?: string;
};

export const Highlight = ({ highlighted, onChange, testId }: HighlightProps) => {
	const { formatMessage } = useIntl();
	return (
		<HighlightWrapper data-testid={testId}>
			<HighlightToggleWrapper data-testid={testId && `${testId}.toggle-wrapper`}>
				<Toggle
					testId="polaris-lib-decoration.ui.decorator.toggle-highlight"
					isChecked={highlighted}
					onChange={() => onChange(!highlighted)}
					size="large"
				/>
			</HighlightToggleWrapper>
			<HighlightLabel>{formatMessage(messages.highlightLabel)}</HighlightLabel>
		</HighlightWrapper>
	);
};

type ValidationResult = {
	isValid: boolean;
	error?: string;
};

type EditableNameProps = {
	isEditable: boolean;
	isInvalid: boolean;
	value: ReactNode | string;
	preventFocusPropagation?: boolean;
	autoFocusName: boolean;
	inputWidth?: string | number;
	inputType?: string;
	inputMin?: number;
	onChange: (arg1: string) => void;
	onCloseRequested?: () => void;
	label?: string;
};

const EditableName = ({
	isEditable,
	onChange,
	value,
	preventFocusPropagation,
	autoFocusName,
	onCloseRequested,
	inputWidth,
	isInvalid,
	inputType,
	inputMin,
	label,
}: EditableNameProps) => {
	const fieldRef = useRef<HTMLElement | null>(null);

	const absorb = useMemo(
		() =>
			preventFocusPropagation === true && {
				onMouseDown: (e: SyntheticEvent) => e.stopPropagation(),
			},
		[preventFocusPropagation],
	);

	useEffect(() => {
		if (autoFocusName) {
			fieldRef.current?.focus();
		}
	}, [autoFocusName]);

	if (!isEditable || !isString(value)) {
		return null;
	}

	return (
		<EditableNameWrapper>
			<Textfield
				testId="polaris-lib-decoration.ui.decorator.textfield"
				ref={fieldRef}
				defaultValue={`${value}`}
				isCompact
				onChange={(event: ChangeEvent<HTMLInputElement>) => onChange(event.target.value)}
				{...absorb}
				onKeyDown={(event) => {
					if (event.key === 'Enter') {
						if (onCloseRequested !== undefined) {
							// defer to ensure we don't unmount anything too early
							defer(onCloseRequested);
						}
					}
					if (preventFocusPropagation === true) {
						event.stopPropagation();
					}
				}}
				width={inputWidth ?? '100%'}
				isInvalid={isInvalid}
				type={inputType}
				min={inputMin}
				aria-label={label}
			/>
		</EditableNameWrapper>
	);
};

type InputWithEmojiPickerWrapperProps = {
	value: ReactNode | string;
	isInvalid: boolean;
	inputMin?: number;
	inputType?: string;
	inputWidth?: string | number;
	preventFocusPropagation?: boolean;
	autoFocusName: boolean;
	label?: string;
	emojiId?: string;
	isInputReadOnly: boolean;
	isEmojiReadOnly: boolean;
	renderEmojiPickerToParent?: boolean;
	onChange: (arg1: string) => void;
	onUpdateEmoji: (arg1: EmojiDescription | undefined) => void;
	onCloseRequested?: () => void;
};

const InputWithEmojiPickerWrapper = ({
	value,
	isInvalid,
	inputMin,
	inputType,
	inputWidth,
	preventFocusPropagation,
	autoFocusName,
	label,
	emojiId,
	isInputReadOnly,
	isEmojiReadOnly,
	onChange,
	onUpdateEmoji,
	onCloseRequested,
	renderEmojiPickerToParent = false,
}: InputWithEmojiPickerWrapperProps) => {
	const fieldRef = useRef<HTMLElement | null>(null);

	const absorb = useMemo(
		() =>
			preventFocusPropagation === true && {
				onMouseDown: (e: SyntheticEvent) => e.stopPropagation(),
			},
		[preventFocusPropagation],
	);

	useEffect(() => {
		if (autoFocusName) {
			fieldRef.current?.focus();
		}
	}, [autoFocusName]);

	if ((isInputReadOnly && isEmojiReadOnly) || !isString(value)) {
		return null;
	}

	return (
		<InputWithEmojiPicker
			testId="polaris-lib-decoration.ui.decorator.textfield"
			ref={fieldRef}
			emojiId={emojiId}
			onUpdateEmoji={onUpdateEmoji}
			emojiPickerPlacement="left-start"
			emojiPickerOffset={[-20, 27]}
			isInputReadOnly={isInputReadOnly}
			isEmojiPickerVisible={!isEmojiReadOnly}
			defaultValue={`${value}`}
			onChange={onChange}
			{...absorb}
			onKeyDown={(event) => {
				if (event.key === 'Enter') {
					if (onCloseRequested !== undefined) {
						// defer to ensure we don't unmount anything too early
						defer(onCloseRequested);
					}
				}
				if (preventFocusPropagation === true) {
					event.stopPropagation();
				}
			}}
			width={inputWidth ?? '100%'}
			isInvalid={isInvalid}
			type={inputType}
			min={inputMin}
			aria-label={label}
			renderEmojiPickerToParent={renderEmojiPickerToParent}
		/>
	);
};

export const FieldDecorator = (props: FieldDecoratorProps) => {
	const {
		color,
		value,
		emojiId,
		onColorChanged,
		onEmojiSelected,
		isHighlighted,
		onHighlightingChanged,
		isNameEditable,
		isEmojiEditable = true,
		isHighlightingEditable = true,
		onNameChange,
		preventFocusPropagation,
		autoFocusName,
		onNameValidate,
		onCloseRequested,
		input: {
			width: inputWidth,
			label: inputLabel,
			type: inputType,
			min: inputMin,
			labelPosition: inputLabelPosition,
			useSeparateInputForEmoji,
		} = {
			width: undefined,
			label: '',
			type: undefined,
			min: undefined,
			labelPosition: 'end',
		},
		inputNode,
		outerSpacing,
		testId,
		containerBackgroundColor,
		nameLabel,
		isInPopup,
	} = props;

	const { colorMode } = useThemeObserver();
	const [selectedColor, setSelectedColor] = useState(color);
	const [highlightSelected, setHighlightSelected] = useState(isHighlighted);
	const [validation, setValidation] = useState<ValidationResult>({ isValid: true });

	const colorChanged = useCallback(
		(hexCode?: string) => {
			setSelectedColor(hexCode);
			onColorChanged(hexCode);
		},
		[onColorChanged],
	);
	const emojiSelected = useCallback(
		(emojiDescription?: EmojiDescription) => {
			onEmojiSelected(emojiDescription?.id);
		},
		[onEmojiSelected],
	);
	const highlightingChanged = useCallback(
		(highlighted: boolean) => {
			setHighlightSelected(highlighted);
			onHighlightingChanged(highlighted);
		},
		[onHighlightingChanged],
	);

	const handleNameChange = useMemo(
		() =>
			debounce((newValueRaw) => {
				const newValue = trim(newValueRaw);
				let { isValid } = validation;
				if (onNameValidate !== undefined) {
					const validationResult = onNameValidate(newValue);
					setValidation(validationResult);
					isValid = validationResult.isValid;
				}
				if (onNameChange !== undefined && isValid) {
					onNameChange(newValue);
				}
			}, 350),
		[onNameChange, onNameValidate, validation],
	);

	const shouldShowEmojiPicker = Boolean((inputNode || useSeparateInputForEmoji) && isEmojiEditable);

	return (
		<FieldDecoratorWrapper
			backgroundColor={containerBackgroundColor ?? (colorMode === 'dark' ? DN100A : N20A)}
			hasTopPadding={!isInPopup}
		>
			<FieldDecoratorContainer outerSpacing={outerSpacing}>
				<InputWrapper isInvalid={!validation.isValid && validation.error !== undefined}>
					{inputLabel && inputLabelPosition === 'start' && <InputLabel>{inputLabel}</InputLabel>}
					<Flex gap="space.100" xcss={inputContentStyles}>
						{shouldShowEmojiPicker && (
							<EmojiPicker onEmojiSelected={emojiSelected} selectedEmojiId={emojiId} />
						)}
						{inputNode !== undefined ? (
							inputNode
						) : (
							<>
								{useSeparateInputForEmoji ? (
									<EditableName
										isEditable={isNameEditable}
										onChange={handleNameChange}
										value={value}
										preventFocusPropagation={preventFocusPropagation}
										autoFocusName={autoFocusName === true}
										inputWidth={inputWidth}
										isInvalid={!validation.isValid}
										inputType={inputType}
										inputMin={inputMin}
										onCloseRequested={onCloseRequested}
										label={nameLabel}
									/>
								) : (
									<InputWithEmojiPickerWrapper
										value={value}
										isInvalid={!validation.isValid}
										inputMin={inputMin}
										inputType={inputType}
										inputWidth={inputWidth}
										preventFocusPropagation={preventFocusPropagation}
										autoFocusName={autoFocusName === true}
										label={nameLabel}
										emojiId={emojiId}
										isInputReadOnly={!isNameEditable}
										isEmojiReadOnly={!isEmojiEditable}
										onChange={handleNameChange}
										onUpdateEmoji={emojiSelected}
										onCloseRequested={onCloseRequested}
										renderEmojiPickerToParent={isInPopup}
									/>
								)}
							</>
						)}
					</Flex>
					{inputLabel && (inputLabelPosition === undefined || inputLabelPosition === 'end') && (
						<InputLabel>{inputLabel}</InputLabel>
					)}
				</InputWrapper>
				{!validation.isValid && validation.error !== undefined && (
					<ErrorMessageWrapper>
						<ErrorMessage>{validation.error}</ErrorMessage>
					</ErrorMessageWrapper>
				)}
				<ColorPicker color={selectedColor} onChange={colorChanged} compact={false} />
				{isHighlightingEditable && (
					<Highlight
						highlighted={highlightSelected}
						onChange={highlightingChanged}
						testId={testId && `${testId}.highlight`}
					/>
				)}
			</FieldDecoratorContainer>
		</FieldDecoratorWrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldDecoratorContainer = styled.div<{ outerSpacing: string }>({
	display: 'flex',
	flexDirection: 'column',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	padding: (props) => `0 ${props.outerSpacing}`,
	width: '100%',
	boxSizing: 'border-box',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldDecoratorWrapper = styled.div<{ backgroundColor: string; hasTopPadding: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: ({ backgroundColor }) => backgroundColor,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	paddingTop: ({ hasTopPadding }) => (hasTopPadding ? token('space.100') : undefined),
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	width: '100%',
	maxWidth: '100%',
	margin: 'auto',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InputWrapper = styled.div<{ isInvalid: boolean }>({
	display: 'flex',
	gap: token('space.100'),
	alignItems: 'baseline',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	paddingBottom: ({ isInvalid }) => (isInvalid ? 0 : token('space.100')),
	flexDirection: 'column',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InputLabel = styled.label({
	display: 'flex',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EditableNameWrapper = styled.div({
	display: 'flex',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HighlightWrapper = styled.div({
	paddingTop: token('space.025'),
	paddingBottom: token('space.025'),
	display: 'flex',
	flex: '1 1 auto',
	alignItems: 'center',
	gap: token('space.050'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HighlightLabel = styled.div({
	display: 'flex',
	flexWrap: 'wrap',
	flex: '1 1 auto',
	whiteSpace: 'normal',
	font: token('font.body.small'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HighlightToggleWrapper = styled.div({
	display: 'flex',
	flex: '0 0 auto',
	marginLeft: token('space.negative.075'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ErrorMessageWrapper = styled.div({
	whiteSpace: 'break-spaces',
	paddingBottom: token('space.100'),
});

const inputContentStyles = xcss({
	width: '100%',
});
