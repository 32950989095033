import React, { useMemo } from 'react';
import { Emoji } from '@atlaskit/emoji';
import { Flex, Box, xcss } from '@atlaskit/primitives';
import Lozenge from '@atlaskit/lozenge';
import { useIntl } from '@atlassian/jira-intl';
import { iconForPolarisFieldType } from '@atlassian/jira-polaris-component-glyphs/src/ui/glyphs/main.tsx';
import {
	FIELD_TYPES,
	type FieldType,
} from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import { ISSUEID_FIELDKEY } from '@atlassian/jira-polaris-domain-field/src/field/constants.tsx';
import type {
	FieldConfiguration,
	FieldKey,
} from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { useEmoji } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';
import { GlobalFieldMarker } from '@atlassian/jira-polaris-lib-global-field-marker/src/ui/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import messages from './messages.tsx';

export type FieldSelectOptionComponentProps = {
	fieldKey: FieldKey;
	label?: string;
	type?: FieldType;
	emojiId?: string;
	configuration?: FieldConfiguration;
	isGlobalNonSystemField?: boolean;
};

export const FieldSelectOptionComponent = ({
	fieldKey,
	emojiId,
	type,
	label,
	configuration,
	isGlobalNonSystemField,
}: FieldSelectOptionComponentProps) => {
	const { formatMessage } = useIntl();

	const icon = useMemo(
		() => iconForPolarisFieldType(type, undefined, configuration),
		[configuration, type],
	);
	const emoji = useEmoji(emojiId);

	return (
		<Flex alignItems="center">
			<Flex alignItems="center" xcss={iconContainerStyles}>
				{emoji ? <Emoji emoji={emoji} fitToHeight={16} showTooltip /> : icon}
			</Flex>
			<Box xcss={optionContentStyles}>
				{fieldKey === ISSUEID_FIELDKEY ? formatMessage(messages.excludeIdeas) : label}
			</Box>
			{isGlobalNonSystemField && <GlobalFieldMarker />}
			{type === FIELD_TYPES.TEAM && fg('polaris_team_field_new_lozenge') && (
				<Flex xcss={lozengeStyles}>
					<Lozenge appearance="new">{formatMessage(messages.new)}</Lozenge>
				</Flex>
			)}
		</Flex>
	);
};

const iconContainerStyles = xcss({
	marginInlineEnd: 'space.100',
});

const optionContentStyles = xcss({
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	textAlign: 'left',
	minWidth: '0',
});

const lozengeStyles = xcss({
	marginLeft: 'space.100',
});
