import React, { type ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { styled } from '@compiled/react';
import debounce from 'lodash/debounce';
import { Box, xcss } from '@atlaskit/primitives';
import Textfield from '@atlaskit/textfield';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { ValueRuleOperator } from '@atlassian/jira-polaris-domain-field/src/decoration/constants.tsx';
import type { ValueRule } from '@atlassian/jira-polaris-domain-field/src/decoration/types.tsx';
import { messages } from './messages.tsx';

type Props = {
	rules: ValueRule[];
	onRulesChanged: (arg1: ValueRule[]) => void;
	minLimit?: number;
	maxLimit?: number;
};
type NumberFieldProps = {
	isDisabled?: boolean;
	isInvalid?: boolean;
	defaultValue: string;
	onChange: (arg1: number) => void;
	isValueValid: (value: number) => boolean;
};

const DEBOUNCE_TIME = 250;

const NumberField = ({
	defaultValue,
	isValueValid,
	onChange,
	isDisabled,
	isInvalid,
}: NumberFieldProps) => {
	const [value, setValue] = useState<number | string>(defaultValue || 0);
	if (isDisabled === true) {
		return <Box xcss={readonlyValueStyles}>{defaultValue}</Box>;
	}
	return (
		<TextfieldWrapper isInvalid={!!isInvalid}>
			<Textfield
				testId="polaris-component-field-configuration.ui.decoration.decoration-config-content.number-decoration.item.input"
				appearance="standard"
				type="number"
				step="any"
				width={110}
				isCompact
				value={value}
				isInvalid={!!isInvalid}
				defaultValue={String(defaultValue)}
				onChange={(event: ChangeEvent<HTMLInputElement>) => {
					const stringValue = event.target.value;
					const numberValue = Number(stringValue);

					setValue(stringValue);
					if (isValueValid(numberValue)) {
						onChange(numberValue);
					}
				}}
			/>
		</TextfieldWrapper>
	);
};

export const NumberDecorationRangeInput = ({
	rules,
	onRulesChanged,
	minLimit,
	maxLimit,
}: Props) => {
	const { formatMessage } = useIntl();
	const [isRangeValid, setIsRangeValid] = useState(true);
	const [minValue, setMinValue] = useState<number>();
	const [maxValue, setMaxValue] = useState<number>();

	const defaultMaxValue = useMemo(
		() =>
			rules.find(
				(rule) => rule.operator === ValueRuleOperator.LTE || rule.operator === ValueRuleOperator.LT,
			)?.value,
		[rules],
	);
	const defaultMinValue = useMemo(
		() =>
			rules.find(
				(rule) => rule.operator === ValueRuleOperator.GT || rule.operator === ValueRuleOperator.GTE,
			)?.value,
		[rules],
	);

	useEffect(() => {
		if (defaultMinValue !== undefined) {
			setMinValue(Number(defaultMinValue));
		}

		if (defaultMaxValue !== undefined) {
			setMaxValue(Number(defaultMaxValue));
		}
	}, [defaultMaxValue, defaultMinValue]);

	const isValueValid = useCallback(
		(value: number) => {
			const minValid = minLimit === undefined || value >= minLimit;
			const maxValid = maxLimit === undefined || value <= maxLimit;
			return minValid && maxValid;
		},
		[minLimit, maxLimit],
	);

	const handleMinValueChange = useMemo(
		() =>
			debounce((newMinValue: number) => {
				setMinValue(newMinValue);

				if (maxValue === undefined) {
					onRulesChanged([{ value: newMinValue.toString(), operator: ValueRuleOperator.GT }]);
					return;
				}

				const isValid = maxValue >= newMinValue;
				setIsRangeValid(isValid);

				if (isValid) {
					onRulesChanged([
						{ value: newMinValue.toString(), operator: ValueRuleOperator.GTE },
						{ value: maxValue.toString(), operator: ValueRuleOperator.LTE },
					]);
				}
			}, DEBOUNCE_TIME),
		[maxValue, onRulesChanged],
	);

	const handleMaxValueChange = useMemo(
		() =>
			debounce((newMaxValue: number) => {
				setMaxValue(newMaxValue);

				if (minValue === undefined) {
					onRulesChanged([{ value: newMaxValue.toString(), operator: ValueRuleOperator.LT }]);
					return;
				}

				const isValid = newMaxValue >= minValue;
				setIsRangeValid(isValid);

				if (isValid) {
					onRulesChanged([
						{ value: minValue.toString(), operator: ValueRuleOperator.GTE },
						{ value: newMaxValue.toString(), operator: ValueRuleOperator.LTE },
					]);
				}
			}, DEBOUNCE_TIME),
		[minValue, onRulesChanged],
	);

	if (defaultMinValue === undefined && defaultMaxValue !== undefined) {
		return (
			<RangeItem>
				<NumberField
					defaultValue={defaultMaxValue}
					onChange={handleMaxValueChange}
					isValueValid={isValueValid}
				/>
			</RangeItem>
		);
	}
	if (defaultMinValue !== undefined && defaultMaxValue === undefined) {
		return (
			<RangeItem>
				<NumberField
					defaultValue={defaultMinValue}
					onChange={handleMinValueChange}
					isValueValid={isValueValid}
				/>
			</RangeItem>
		);
	}
	// should be the default case, but flow doesn't get it
	if (defaultMinValue !== undefined && defaultMaxValue !== undefined) {
		return (
			<RangeItem>
				<NumberField
					defaultValue={defaultMinValue}
					onChange={handleMinValueChange}
					isValueValid={isValueValid}
				/>
				<Caption autoWidth>{formatMessage(messages.and)}</Caption>
				<NumberField
					defaultValue={defaultMaxValue}
					isInvalid={!isRangeValid}
					onChange={handleMaxValueChange}
					isValueValid={isValueValid}
				/>
			</RangeItem>
		);
	}
	return null;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RangeItem = styled.div({
	display: 'flex',
	alignItems: 'center',
	height: '32px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Caption = styled.div<{ autoWidth?: boolean }>({
	paddingLeft: token('space.100'),
	paddingRight: token('space.100'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: (props) => (props.autoWidth ? 'auto' : '100px'),
});

const readonlyValueStyles = xcss({
	paddingLeft: 'space.100',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TextfieldWrapper = styled.div<{ isInvalid?: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	input: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		color: ({ isInvalid }) => (isInvalid ? colors.R500 : 'initial'),
	},
});
