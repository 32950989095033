import React, { memo } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import ChevronDownIcon from '@atlaskit/icon/utility/migration/chevron-down';
import ChevronRightIcon from '@atlaskit/icon/utility/migration/chevron-right';
import { token } from '@atlaskit/tokens';
import { Title } from './styled.tsx';

type HeaderProps = {
	fieldsCount?: number;
	title?: string;
	helpText?: string;
};

type ExpandableHeaderProps = HeaderProps & {
	isExpanded: boolean;
	onChange: (isExpanded: boolean) => void;
};

export const Header = memo<HeaderProps>(({ fieldsCount, title, helpText }: HeaderProps) => (
	<Wrapper>
		{title ? (
			<Title>
				{title} ({fieldsCount})
			</Title>
		) : null}
		{helpText ? <HelpText>{helpText}</HelpText> : null}
	</Wrapper>
));

export const ExpandableHeader = memo<ExpandableHeaderProps>(
	({ fieldsCount, title, helpText, onChange, isExpanded }: ExpandableHeaderProps) => (
		<ExpandableHeaderWrapper>
			<div>
				{title ? (
					<Title>
						{title} ({fieldsCount})
					</Title>
				) : null}
				{helpText ? <HelpText>{helpText}</HelpText> : null}
			</div>
			<Button
				appearance="subtle"
				aria-expanded={isExpanded}
				spacing="none"
				iconBefore={
					isExpanded ? (
						<ChevronDownIcon spacing="spacious" label="collapse" />
					) : (
						<ChevronRightIcon spacing="spacious" label="expand" />
					)
				}
				onClick={() => onChange(!isExpanded)}
			/>
		</ExpandableHeaderWrapper>
	),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div({
	paddingTop: 0,
	paddingRight: token('space.200'),
	paddingBottom: token('space.050'),
	paddingLeft: token('space.200'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ExpandableHeaderWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	paddingTop: 0,
	paddingRight: token('space.200'),
	paddingBottom: token('space.050'),
	paddingLeft: token('space.200'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HelpText = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest'),
	font: token('font.body.small'),
	paddingTop: token('space.075'),
});
