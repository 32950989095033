import React, { useMemo, useCallback } from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useOpenPreviewMode } from '@atlassian/jira-polaris-common/src/controllers/preview-mode/index.tsx';
import {
	useIssueCreationEnabled,
	useSaveIssueCreationEnabled,
	useSaveSubmitIdeaDescriptionTemplateId,
	useSaveSubmitIdeaFormMandatoryFields,
	useSubmitIdeaFormMandatoryFields,
	useSaveSubmitIdeaFormViewId,
	useSubmitIdeaFormDescriptionTemplate,
	useSubmitIdeaFormView,
} from '@atlassian/jira-polaris-common/src/controllers/project-properties/index.tsx';
import {
	useAllIdeasViewId,
	useAllViewsNameAndIds,
	useViewSlug,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { IssueCreationForm } from '@atlassian/jira-polaris-common/src/ui/create-idea-dialog/creation-form/index.tsx';
import { useErrorHandlers } from '@atlassian/jira-polaris-lib-errors/src/controllers/index.tsx';
import type { Components } from '@atlassian/jira-polaris-lib-forms-settings/src/controllers/types.tsx';
import PolarisProjectFormSettingsScreen from '@atlassian/jira-polaris-lib-forms-settings/src/index.tsx';
import { useNotifications } from '@atlassian/jira-polaris-lib-notifications/src/controllers/index.tsx';
import {
	useIsIssueCreationEnabledProperty,
	useSubmitIdeaFormDescriptionTemplateProperty,
	useSubmitIdeaFormMandatoryFieldsProperty,
	useSubmitIdeaFormViewProperty,
} from '@atlassian/jira-polaris-component-project-properties/src/controllers/selectors/properties/index.tsx';
import {
	useSaveIsIssueCreationEnabled,
	useSaveSubmitIdeaDescriptionTemplateId as useSaveSubmitIdeaDescriptionTemplateIdNext,
	useSaveSubmitIdeaFormMandatoryFields as useSaveSubmitIdeaFormMandatoryFieldsNext,
	useSaveSubmitIdeaFormViewId as useSaveSubmitIdeaFormViewIdNext,
} from '@atlassian/jira-polaris-component-project-properties/src/controllers/selectors/save-properties/index.tsx';
import { IdeaTemplatesPanel } from '../../right-sidebar/idea-templates/main.tsx';
import { SectionMessageComponent } from '../../right-sidebar/idea-templates/section-message/index.tsx';
import { BreadcrumbsProjectSettings } from '../breadcrumbs/index.tsx';
import { messages } from './messages.tsx';

export const PolarisFormsSettings = () => {
	const { generalActionFailedError } = useErrorHandlers();
	const { formatMessage } = useIntl();
	const issueCreationEnabled = useIssueCreationEnabled();
	const isIssueCreationEnabledProperty = useIsIssueCreationEnabledProperty();
	const onIssueCreationChanged = useSaveIssueCreationEnabled();
	const onIssueCreationChangedNext = useSaveIsIssueCreationEnabled();
	const viewId = useSubmitIdeaFormView();
	const viewIdProperty = useSubmitIdeaFormViewProperty();
	const onViewIdChanged = useSaveSubmitIdeaFormViewId();
	const onViewIdChangedNext = useSaveSubmitIdeaFormViewIdNext();
	const allIdeasViewId = useAllIdeasViewId();
	const allIdeasViewSlug = useViewSlug(allIdeasViewId);
	const descriptionTemplateId = useSubmitIdeaFormDescriptionTemplate();
	const descriptionTemplateIdNext = useSubmitIdeaFormDescriptionTemplateProperty();
	const onTemplateIdChanged = useSaveSubmitIdeaDescriptionTemplateId();
	const onTemplateIdChangedNext = useSaveSubmitIdeaDescriptionTemplateIdNext();
	const areFieldsMandatory = useSubmitIdeaFormMandatoryFields();
	const areFieldsMandatoryProperty = useSubmitIdeaFormMandatoryFieldsProperty();
	const onFieldsMandatoryChanged = useSaveSubmitIdeaFormMandatoryFields();
	const onFieldsMandatoryChangedNext = useSaveSubmitIdeaFormMandatoryFieldsNext();
	const viewOptions = useAllViewsNameAndIds();
	const openPreviewMode = useOpenPreviewMode();
	const { error, success } = useNotifications();

	const components: Components = useMemo(
		() => ({
			IssueForm: (props) => <IssueCreationForm {...props} />,
			IssueFormSectionMessage: SectionMessageComponent,
			DescriptionTemplates: (props) => <IdeaTemplatesPanel {...props} />,
		}),
		[],
	);

	const viewIdValue = useMemo(() => viewId.value, [viewId.value]);

	const onCopyFormLink = useCallback(() => {
		let viewSlug: string | undefined;
		if (fg('polaris_extract-project-properties')) {
			viewSlug = viewIdProperty.value ? viewIdProperty.value.split('/')[1] : allIdeasViewSlug;
		} else {
			viewSlug = viewIdValue ? viewIdValue.split('/')[1] : allIdeasViewSlug;
		}

		const baseUrl =
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.location.origin +
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.location.pathname.replace('/settings/create-ideas', `/view/${viewSlug}`);
		const urlToCopy = `${baseUrl}?openIdeaCreationModal=true`;

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		(fg('jfp-magma-undefined-navigator') ? globalThis.navigator.clipboard : navigator.clipboard)
			.writeText(urlToCopy)
			.then(() =>
				success({
					title: formatMessage(messages.copiedTitle),
					description: formatMessage(messages.copiedMessage),
				}),
			)
			.catch(() =>
				error({
					title: formatMessage(messages.copyFailTitle),
					description: formatMessage(messages.copyFailMessage),
				}),
			);
	}, [error, formatMessage, success, viewIdValue, viewIdProperty.value, allIdeasViewSlug]);

	const onPreviewOpen = useCallback(
		(previewRoleName = 'contributor') => {
			openPreviewMode(previewRoleName);
		},
		[openPreviewMode],
	);

	return (
		<PolarisProjectFormSettingsScreen
			viewId={fg('polaris_extract-project-properties') ? viewIdProperty : viewId}
			issueCreationEnabled={
				fg('polaris_extract-project-properties')
					? isIssueCreationEnabledProperty
					: issueCreationEnabled
			}
			areFieldsMandatory={
				fg('polaris_extract-project-properties') ? areFieldsMandatoryProperty : areFieldsMandatory
			}
			components={components}
			viewOptions={viewOptions}
			descriptionTemplateId={
				fg('polaris_extract-project-properties')
					? descriptionTemplateIdNext
					: {
							...descriptionTemplateId,
							isLoading: descriptionTemplateId.isLoading,
						}
			}
			onError={generalActionFailedError}
			onIssueCreationChanged={
				fg('polaris_extract-project-properties')
					? onIssueCreationChangedNext
					: onIssueCreationChanged
			}
			onViewIdChanged={
				fg('polaris_extract-project-properties') ? onViewIdChangedNext : onViewIdChanged
			}
			onCopyIdeaFormLink={onCopyFormLink}
			onDescriptionTemplateIdChanged={
				fg('polaris_extract-project-properties') ? onTemplateIdChangedNext : onTemplateIdChanged
			}
			onFieldsMandatoryChanged={
				fg('polaris_extract-project-properties')
					? onFieldsMandatoryChangedNext
					: onFieldsMandatoryChanged
			}
			onPreviewModeOpen={onPreviewOpen}
			breadcrumbs={<BreadcrumbsProjectSettings />}
		/>
	);
};
