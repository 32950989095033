import React, { type ComponentType } from 'react';
import { styled, keyframes } from '@compiled/react';
import Button from '@atlaskit/button';
import { Checkbox } from '@atlaskit/checkbox';
import EditorEditIcon from '@atlaskit/icon/core/migration/edit--editor-edit';
import { Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { useCanEditFields } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { WeightTag } from '@atlassian/jira-polaris-component-weight-tag/src/ui/index.tsx';
import {
	useFieldOption,
	useHasDefaultFieldOptionWeights,
} from '../../../../../controllers/field/selectors/options-hooks.tsx';
import { FIELD_ITEM_OUTER_SPACING } from '../../../../config/fields/field-item/constants.tsx';
import { HAS_MORE_GLOBAL_LABELS_FLAG_ID } from '../menu-list/index.tsx';
import messages from './messages.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SelectOptionComponentProps = any;

type OptionComponentProps = {
	isMulti: boolean;
	DecoratedTagComponent: ComponentType<{
		id: string;
		compact?: boolean;
	}>;
};

// we are forking the implementation (and the props with them) between the Popup variant
// and the Right Sidebar variant.  Despite some amount of duplicated code, it becomes far
// easier to reason about how to handle state and clicking back and forth.  This way the
// popup variant can remain basically unchanged, until it gets completely removed, and
// the right sidebar variant can take advantage of the new controller management model
// (e.g., getting rid of the janky closeAllOtherOptions)

type EditOptionComponentProps = OptionComponentProps & {
	hideEditButton?: boolean;
	setOpenOption?: (optionId: string) => void;
};

export const createEditOptionComponent =
	({ isMulti, hideEditButton, DecoratedTagComponent, setOpenOption }: EditOptionComponentProps) =>
	({ data, innerProps, innerRef, isFocused, isSelected }: SelectOptionComponentProps) => {
		const { formatMessage } = useIntl();
		const canEditFields = useCanEditFields();

		const { id } = data;

		const onEditOption = setOpenOption
			? () => {
					setOpenOption(id);
				}
			: null;

		const { onClick, ...innerPropsRest } = innerProps;

		if (data.id === HAS_MORE_GLOBAL_LABELS_FLAG_ID) {
			return null;
		}

		return (
			<Wrapper>
				<OptionContentContainer isMulti={isMulti} isFocused={isFocused} {...innerPropsRest}>
					<OptionWrapper>
						{isMulti ? (
							<CheckboxWrapper>
								<Checkbox
									onClick={onClick}
									ref={innerRef}
									isChecked={isSelected}
									label={
										<OptionContent>
											<DecoratedTagComponent id={id} compact />
										</OptionContent>
									}
								/>
							</CheckboxWrapper>
						) : (
							<OptionContent onClick={onClick}>
								<DecoratedTagComponent id={id} compact />
							</OptionContent>
						)}
						{!hideEditButton && canEditFields && onEditOption && (
							<Tooltip content={formatMessage(messages.editOption)}>
								<EditButtonWrapper isFocused={isFocused}>
									<Button
										onClick={onEditOption}
										aria-label={formatMessage(messages.editOption)}
										iconAfter={<EditorEditIcon label="" LEGACY_size="medium" spacing="spacious" />}
										appearance="subtle"
										spacing="none"
									/>
								</EditButtonWrapper>
							</Tooltip>
						)}
					</OptionWrapper>
				</OptionContentContainer>
			</Wrapper>
		);
	};

type ReadOnlyOptionProps = {
	fieldKey?: string;
	optionId: string;
	isActive?: boolean;
	isMulti?: boolean;
	isSelected?: boolean;
	DecoratedTagComponent: ComponentType<{
		id: string;
		compact?: boolean;
	}>;
};

/**
 * A lightweight version of the right sidebar option component that has no interactions.
 * This component is meant to be used in areas where performance is crucial and
 * when the user cannot interract with the option (e.g. during a drag and drop).
 */
export const RightSidebarReadOnlyOption = (props: ReadOnlyOptionProps) => {
	const {
		fieldKey = '',
		isActive = false,
		isMulti = false,
		isSelected = false,
		optionId,
		DecoratedTagComponent,
	} = props;
	const option = useFieldOption(fieldKey, optionId);
	const hasDefaultFieldOptionWeights = useHasDefaultFieldOptionWeights(fieldKey);

	const container = (
		<Wrapper isActive={isActive}>
			<InvisibleDragHandleMock>⠿</InvisibleDragHandleMock>
			<OptionContentContainerSidebar isMulti={isMulti}>
				<OptionWrapper outerSpacing={FIELD_ITEM_OUTER_SPACING}>
					{isMulti && (
						<CheckboxWrapper>
							<Checkbox isChecked={isSelected} />
						</CheckboxWrapper>
					)}
					<OptionContent>
						<DecoratedTagComponent id={optionId} />
					</OptionContent>
					{option && !hasDefaultFieldOptionWeights && (
						<WeightTagContainer>
							<WeightTag options={[option]} tooltipPosition="left" />
						</WeightTagContainer>
					)}
				</OptionWrapper>
			</OptionContentContainerSidebar>
			<InvisibleMoreButtonMock />
		</Wrapper>
	);

	return <Box>{container}</Box>;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div<{ isActive?: boolean }>({
	display: 'flex',
	flex: '1 1 auto',
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: ({ isActive }) => (isActive ? token('color.background.disabled') : undefined),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OptionContentContainer = styled.div<{ isMulti?: boolean; isFocused?: boolean }>({
	width: '100%',
	overflow: 'hidden',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	padding: ({ isMulti }) =>
		isMulti ? `0 ${token('space.100')}` : `0 ${token('space.100')} 0 ${token('space.150')}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: ({ isFocused }) =>
		isFocused ? token('color.background.neutral.subtle.hovered') : 'transparent',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OptionContentContainerSidebar = styled(OptionContentContainer)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&': {
		padding: 0,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CheckboxWrapper = styled.div({
	width: '100%',
	marginTop: token('space.050'),
	marginRight: 0,
	marginBottom: token('space.050'),
	marginLeft: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OptionWrapper = styled.div<{ outerSpacing?: string }>({
	height: '32px',
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > div': {
		display: 'flex',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	paddingRight: (props) => props.outerSpacing,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OptionContent = styled.div({
	flex: '1 1 auto',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	maxWidth: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	font: token('font.body'),
	cursor: 'default',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const WeightTagContainer = styled.div({
	alignItems: 'center',
	marginRight: token('space.050'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text'),
});

const animationFadeIn = keyframes({
	from: {
		opacity: 0,
	},
	to: {
		opacity: 1,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EditButtonWrapper = styled.div<{ isFocused: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	display: ({ isFocused }) => (isFocused ? 'flex' : 'none'),
	opacity: 0,
	animationName: `${animationFadeIn}`,
	animationDuration: '150ms',
	animationDelay: '300ms',
	animationFillMode: 'forwards',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InvisibleDragHandleMock = styled.div({
	width: '16px',
	font: token('font.body'),
	visibility: 'hidden',
	flexShrink: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InvisibleMoreButtonMock = styled.div({
	width: '24px',
	flexShrink: 0,
});
