import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { logSafeErrorWithoutCustomerDataWrapper } from '@atlassian/jira-polaris-lib-errors/src/common/utils/index.tsx';

const localStorage = createLocalStorageProvider('polaris');

const SIDEBAR_WIDTH_STORAGE_KEY = 'sidebar-width';

export const setSidebarWidthToStorage = (width: number) => {
	if (fg('polaris_functional_storage_killswitch')) {
		return;
	}
	try {
		localStorage.set(SIDEBAR_WIDTH_STORAGE_KEY, width);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		logSafeErrorWithoutCustomerDataWrapper(
			'polaris.sidebar-resizer-error',
			'Failed to save sidebar size to localStorage',
			error,
		);
	}
};

export const getSidebarWidthFromStorage = (): number | null => {
	if (fg('polaris_functional_storage_killswitch')) {
		return null;
	}
	return parseFloat(localStorage.get(SIDEBAR_WIDTH_STORAGE_KEY)) || null;
};
