import { createSelector } from 'reselect';
import difference from 'lodash/difference';
import flatten from 'lodash/flatten';
import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import union from 'lodash/union';
import { FREE_EDITION } from '@atlassian/jira-shared-types/src/edition.tsx';
import { HIDDEN_PERMISSIONS } from '../../common/constants.tsx';
import type { PermissionCategoryKey, PermissionKey, State } from '../types.tsx';

const NO_PERMISSIONS: PermissionKey[] = [];

export const getPermissionCategoryKeys = createSelector(
	(state: State) => state.categories,
	(categories) => (categories || []).map(({ key }) => key),
);

const getPermissionCategory = (categoryKey: PermissionCategoryKey) =>
	createSelector(
		(state: State) => state.categories,
		(categories) => (categories || []).find(({ key }) => key === categoryKey),
	);

export const getPermissionCategoryName = (categoryKey: PermissionCategoryKey) =>
	createSelector(getPermissionCategory(categoryKey), (category) => category?.name);

export const getPermissionCategoryDescription = (categoryKey: PermissionCategoryKey) =>
	createSelector(getPermissionCategory(categoryKey), (category) => category?.description);

export const getNonHiddenPermissions = createSelector(
	(state: State) => state.permissions,
	(permissions) =>
		permissions?.filter((permission) => HIDDEN_PERMISSIONS.indexOf(permission.key) === -1),
);

export const getPermissionsWithoutCategory = createSelector(getNonHiddenPermissions, (perms) =>
	(perms || []).filter(({ category }) => category === undefined).map(({ key }) => key),
);

export const getPermissionsInCategory = (categoryKey: PermissionCategoryKey) =>
	createSelector(getNonHiddenPermissions, (perms) =>
		(perms || []).filter(({ category }) => categoryKey === category).map(({ key }) => key),
	);

const getPermission = (permissionKey: PermissionKey) =>
	createSelector(
		(state: State) => state.permissions,
		(permissions) => (permissions || []).find(({ key }) => key === permissionKey),
	);

export const getPermissionName = (permissionKey: PermissionKey) =>
	createSelector(getPermission(permissionKey), (permissions) => permissions?.name);

export const getPermissionDescription = (permissionKey: PermissionKey) =>
	createSelector(getPermission(permissionKey), (permissions) => permissions?.description);

export const getContributorPermissions = (state: State): PermissionKey[] =>
	state.contributorPermissions || NO_PERMISSIONS;

export const getIsCreatorPermission = (permissionKey: PermissionKey) => (state: State) =>
	!getContributorPermissions(state).includes(permissionKey);

export const getIsCreatorPermissionInCategory = (categoryKey: PermissionCategoryKey) =>
	createSelector(
		getPermissionsInCategory(categoryKey),
		getContributorPermissions,
		(permsInCategory, contributorPerms) => {
			const nonContribPerms = difference(permsInCategory, contributorPerms);
			return nonContribPerms.length > 0;
		},
	);

export const getPermissionCategories = (state: State) => state.categories;

const getPermissions = (state: State) => state.permissions;
const getRoles = (state: State) => state.roles;
const getActors = (state: State) => state.projectActors?.data?.actors;

export const getActorPermissionKeys = (roleTypeId: string) =>
	createSelector(getActors, getPermissions, getRoles, (actors, permissions, roles) => {
		const roleMap = keyBy(roles, 'id');
		const actorRoleIds = actors?.find((actor) => actor.roleTypeId === roleTypeId)?.roles;

		if (isEmpty(actorRoleIds) || isEmpty(permissions || isEmpty(roles))) {
			return NO_PERMISSIONS;
		}

		const actorPermissionKeys = union(
			actorRoleIds.reduce((acc: PermissionKey[][], roleId) => {
				const permissionKeys = roleMap[roleId]?.permissions;
				if (permissionKeys !== undefined) {
					acc.push(permissionKeys);
				}
				return acc;
			}, []),
		);

		return flatten(actorPermissionKeys);
	});

export const canCreateRoles = (state: State) =>
	state.loadingProps !== undefined && state.loadingProps.edition !== FREE_EDITION;
