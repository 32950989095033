import { differenceInBusinessDays } from 'date-fns';

export const checkIfActivatedMoreThanFourDays = (productUsageStartTimestamp: string) => {
	const diffInDays = differenceInBusinessDays(new Date(), new Date(productUsageStartTimestamp));

	return diffInDays >= 4;
};

export const normalizeError = (error: unknown) => {
	if (error instanceof Error) {
		return error;
	}
	return new Error('Unknown error');
};
