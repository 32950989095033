import React, {
	useCallback,
	useState,
	useEffect,
	useMemo,
	useRef,
	type KeyboardEvent,
	type ChangeEvent,
} from 'react';
import EditorErrorIcon from '@atlaskit/icon/core/migration/cross-circle--editor-error';
import AkTextField from '@atlaskit/textfield';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { OutsideClickAlerter } from '@atlassian/jira-polaris-lib-outside-click-alerter/src/index.tsx';
import { ENTER, ESCAPE } from '../../../utils/key-code/index.tsx';
import messages from './messages.tsx';

type Props = {
	isFocused: boolean;
	isCompact?: boolean;
	isDisabled?: boolean;
	defaultValue: string;
	// This prop can be used to provide an validation error for the current value in the text field.
	getErrorMessageIfInvalid?: (arg1: string | undefined) => string | undefined;
	maxLength?: number | undefined;
	onConfirm: (arg1: string) => void;
	onCancel: (arg1?: string) => void;
};

export const TextField = ({
	defaultValue,
	onConfirm,
	onCancel,
	isFocused,
	isCompact,
	isDisabled,
	maxLength,
	getErrorMessageIfInvalid,
}: Props) => {
	const { formatMessage } = useIntl();
	const [currentValue, setCurrentValue] = useState(defaultValue);
	const [userTyped, setUserTyped] = useState(false);
	const triggerRef = useRef(false);
	const textRef = useRef<HTMLInputElement | null>(null);

	const errorMessage = useMemo(
		() => (getErrorMessageIfInvalid ? getErrorMessageIfInvalid(currentValue) : undefined),
		[currentValue, getErrorMessageIfInvalid],
	);

	useEffect(() => {
		triggerRef.current = false;
		setCurrentValue(defaultValue);
		// focus the textfield async - there seems to be no reliable alternative to that
		const requestId = setTimeout(
			() => isFocused && textRef.current && textRef.current.focus(),
			100,
		);
		return () => clearTimeout(requestId);
	}, [defaultValue, isFocused]);

	const onTriggerCancel = useCallback(() => {
		if (userTyped && !triggerRef.current) {
			triggerRef.current = true;
			setUserTyped(false);
			setCurrentValue(defaultValue);
			onCancel();
		}
	}, [defaultValue, onCancel, userTyped]);

	const onTriggerConfirm = useCallback(() => {
		if (userTyped && errorMessage === undefined && !triggerRef.current) {
			triggerRef.current = true;
			setUserTyped(false);
			onConfirm(currentValue);
		} else {
			onCancel(currentValue);
		}
	}, [userTyped, errorMessage, onConfirm, currentValue, onCancel]);

	const onKeyDown = useCallback(
		(event: KeyboardEvent) => {
			if (event.keyCode === ESCAPE) {
				onTriggerCancel();
			}
			if (event.keyCode === ENTER) {
				onTriggerConfirm();
			}
		},
		[onTriggerCancel, onTriggerConfirm],
	);

	const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		setUserTyped(true);
		setCurrentValue(e.target.value || '');
	}, []);

	return (
		<OutsideClickAlerter onClickOutside={onTriggerConfirm}>
			{(outsideClickAlerterProps) => (
				<div {...outsideClickAlerterProps}>
					<AkTextField
						ref={textRef}
						value={currentValue}
						isCompact={isCompact}
						isDisabled={isDisabled}
						onChange={onChange}
						onKeyDown={onKeyDown}
						maxLength={maxLength}
						onBlur={onTriggerConfirm}
						elemAfterInput={
							userTyped &&
							errorMessage !== undefined && (
								<Tooltip content={errorMessage}>
									<EditorErrorIcon
										spacing="spacious"
										label={formatMessage(messages.errorTooltip)}
										color={token('color.icon.danger')}
									/>
								</Tooltip>
							)
						}
					/>
				</div>
			)}
		</OutsideClickAlerter>
	);
};
