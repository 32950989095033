/** @jsx jsx */
import React, { useCallback, useState } from 'react';
import { css, jsx } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { LoadingButton } from '@atlaskit/button';
import EditorAddIcon from '@atlaskit/icon/core/migration/add--editor-add';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { messages } from './messages.tsx';

export type AddColumnProps = {
	onAddColumn: (
		event: React.MouseEvent<HTMLElement>,
		analyticsEvent: UIAnalyticsEvent,
	) => Promise<void>;
};

export const AddColumn = ({ onAddColumn }: AddColumnProps) => {
	const { formatMessage } = useIntl();

	const [inCreation, setInCreation] = useState(false);

	const handleClick = useCallback(
		async (event: React.MouseEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => {
			setInCreation(true);
			await onAddColumn(event, analyticsEvent);
			setInCreation(false);
		},
		[onAddColumn],
	);

	return (
		<div css={addColumnContainerStyles}>
			<Tooltip content={formatMessage(messages.columnAddButtonHint)}>
				<LoadingButton
					id="polaris-ideas.ui.view-content.idea-board.add-option"
					isLoading={inCreation}
					iconBefore={
						<EditorAddIcon
							label={formatMessage(messages.columnAddButtonHint)}
							LEGACY_size="medium"
							spacing="spacious"
						/>
					}
					onClick={handleClick}
				/>
			</Tooltip>
		</div>
	);
};

const addColumnContainerStyles = css({
	display: 'flex',
	flexDirection: 'column',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	button: {
		height: '36px',
	},
});
