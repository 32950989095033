/** @jsx jsx */
import React, { memo } from 'react';
import { css, jsx } from '@compiled/react';
import { ButtonItem } from '@atlaskit/menu';
import { Box, Inline, Text, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import { useEmoji } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';
import { GlobalFieldMarker } from '@atlassian/jira-polaris-lib-global-field-marker/src/ui/index.tsx';
import { messages as commonMessages } from '../../../../../common/messages.tsx';
import { FieldTitleWithIcon } from '../../../../../common/ui/field-title-with-icon/index.tsx';
import { TooltipComponent } from '../../../../../common/ui/rollup-tooltip/index.tsx';
import {
	RollupItemFormulaHelpTextComponent,
	RollupItemFormulaTypeComponent,
} from '../../../../../common/ui/rollup/field-help-text/index.tsx';
import { RollupItemFieldIconComponent } from '../../../../../common/ui/rollup/field-icon/index.tsx';
import { fieldTypeNameForPolarisFieldType } from '../../../../../common/utils/field-type-name/index.tsx';
import {
	useFieldEmoji,
	useFieldType,
	useFieldTypeIcon,
	useHasDefaultFieldOptionWeights,
} from '../../../../../controllers/selectors/formula-hooks.tsx';
import { useIsGlobalNonSystemField } from '../../../../../controllers/selectors/field-hooks.tsx';
import { messages } from './messages.tsx';
import type { MultiselectOptionProps, MultiselectOptionsProps } from './types.tsx';

const buttonItemStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Temporary workaround to make Emotion and Compiled specificity mesh until `@atlaskit/menu` is migrated to Compiled.
	'&&': {
		paddingBlockStart: token('space.050'),
		paddingBlockEnd: token('space.050'),
		paddingInlineStart: token('space.200'),
		paddingInlineEnd: token('space.200'),
		maxWidth: '360px', // DEFAULT_SIDEBAR_WIDTH - 40px
	},
});

const MultiselectOption = memo<MultiselectOptionProps>(
	({ field, onClick, displayGlobalFieldIcons }: MultiselectOptionProps) => {
		const { formatMessage } = useIntl();

		const hasDefaultFieldOptionWeights = useHasDefaultFieldOptionWeights(field.key);

		const emojiId = useFieldEmoji(field.key);
		const emoji = useEmoji(emojiId);
		const icon = useFieldTypeIcon(field.key);
		const fieldType = useFieldType(field.key);
		const fieldTypeName = fieldTypeNameForPolarisFieldType(fieldType);
		const fieldTypeIcon = useFieldTypeIcon(field.key);
		const isGlobalNonSystemField = useIsGlobalNonSystemField();

		const title = formatMessage(commonMessages.fieldTypeLabel, {
			fieldTypeName: fieldTypeName ? formatMessage(fieldTypeName) : '',
		});

		return (
			<TooltipComponent
				header={<FieldTitleWithIcon emoji={emoji} icon={icon} title={title} />}
				content={
					<RollupItemFormulaHelpTextComponent
						fieldKey={field.key}
						fieldType={field.type}
						helpText={formatMessage(messages.countMultiSelectTypeHelpText)}
						hasDefaultFieldOptionWeights={hasDefaultFieldOptionWeights}
					/>
				}
			>
				<ButtonItem
					// eslint-disable-next-line @atlaskit/design-system/no-unsafe-style-overrides
					css={buttonItemStyles}
					testId="polaris-component-field-configuration.ui.configuration.formula.rollup.multi-select-options.field-option"
					iconBefore={<RollupItemFieldIconComponent emoji={emoji} fieldTypeIcon={fieldTypeIcon} />}
					iconAfter={
						<RollupItemFormulaTypeComponent
							fieldKey={field.key}
							typeName={formatMessage(messages.countType)}
							hasDefaultFieldOptionWeights={hasDefaultFieldOptionWeights}
						/>
					}
					onClick={() => {
						onClick({
							kind: 'field',
							value: field,
						});
					}}
				>
					<Inline>
						<Box xcss={labelStyles}>
							<Text>{field.label}</Text>
						</Box>
						{displayGlobalFieldIcons && isGlobalNonSystemField && <GlobalFieldMarker />}
					</Inline>
				</ButtonItem>
			</TooltipComponent>
		);
	},
);

export const MultiselectOptions = memo<MultiselectOptionsProps>(
	({ fields, onClick, isFiltered, displayGlobalFieldIcons }: MultiselectOptionsProps) => {
		const optionFields = fields.filter(
			({ type, label }) =>
				(type === FIELD_TYPES.SINGLE_SELECT ||
					type === FIELD_TYPES.MULTI_SELECT ||
					type === FIELD_TYPES.JSW_MULTI_SELECT) &&
				isFiltered(label),
		);
		if (optionFields.length === 0) return null;
		return (
			<>
				{optionFields.map((field) => (
					<MultiselectOption
						key={field.key}
						field={field}
						onClick={onClick}
						displayGlobalFieldIcons={displayGlobalFieldIcons}
					/>
				))}
			</>
		);
	},
);

const labelStyles = xcss({
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});
