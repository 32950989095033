import { v4 as uuid } from 'uuid';
import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { CreateRoleRequest, CreateRoleResponse } from './types.tsx';

const getUrl = (projectId: ProjectId) =>
	`/rest/internal/simplified/1.0/project-configuration/roles?projectId=${projectId}`;

export const createRole = ({
	projectId,
	name,
	description,
	permissions,
}: CreateRoleRequest): Promise<CreateRoleResponse> => {
	const roleUuid = uuid();

	return performPostRequest(getUrl(projectId), {
		body: JSON.stringify({
			name,
			description,
			permissions,
			roleUuid,
		}),
	}).then(({ roleId }: { roleId: number }) => ({ roleId, roleUuid }));
};
