import type { GroupOptions } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/grouping.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { FieldValueFilter } from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import { ISSUETYPE_FIELDKEY } from '@atlassian/jira-polaris-domain-field/src/field/constants.tsx';
import type { ExtendedOption } from '../../../../../common/utils/board.tsx';

export const getNewFieldValueFilterOnHide = (
	field: Field,
	groupOptions: GroupOptions<unknown>,
	extendedOptions: ExtendedOption<unknown>[],
	fieldFilter?: FieldValueFilter,
	groupIdentity?: string,
): FieldValueFilter => {
	const options =
		field.key === 'status' || field.key === ISSUETYPE_FIELDKEY
			? extendedOptions
			: groupOptions.options;
	if (fieldFilter === undefined || fieldFilter.values.length === 0) {
		const newOptions = options.map((option) => ({
			stringValue: option.groupIdentity,
		}));
		// add 'no-value' option as an available filter value
		if (groupOptions.allowEmpty) {
			newOptions.push({ stringValue: undefined });
		}

		// filter out the provided groupIdentity
		return {
			type: 'FIELD',
			field: field.key,
			fieldType: field.type,
			values: newOptions.filter((item) => item.stringValue !== groupIdentity),
		};
	}

	return {
		type: 'FIELD',
		field: field.key,
		fieldType: field.type,
		values: fieldFilter.values.filter((value) => value.stringValue !== groupIdentity),
	};
};

export const getNewFieldValueFilterOnShow = (
	field: Field,
	groupOptions: GroupOptions<unknown>,
	extendedOptions: ExtendedOption<unknown>[],
	fieldFilter?: FieldValueFilter,
	groupIdentity?: string,
): FieldValueFilter => {
	const options =
		field.key === 'status' || field.key === ISSUETYPE_FIELDKEY
			? extendedOptions
			: groupOptions.options;
	if (fieldFilter === undefined || fieldFilter.values.length === 0) {
		const newOptions = options.map((option) => ({
			stringValue: option.groupIdentity,
		}));
		// add 'no-value' option as an available filter value
		if (groupOptions.allowEmpty) {
			newOptions.push({ stringValue: undefined });
		}

		return {
			type: 'FIELD',
			field: field.key,
			fieldType: field.type,
			values: newOptions,
		};
	}

	const isExist = fieldFilter.values.find((value) => value.stringValue === groupIdentity);

	if (isExist) {
		return fieldFilter;
	}

	return {
		type: 'FIELD',
		field: field.key,
		fieldType: field.type,
		values: [...fieldFilter.values, { stringValue: groupIdentity }],
	};
};
