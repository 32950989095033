import React, { useCallback, useState, type SyntheticEvent } from 'react';
import { Anchor, Box, Inline, Stack, Text, xcss } from '@atlaskit/primitives';
import Heading from '@atlaskit/heading';
import QuestionCircleIcon from '@atlaskit/icon/core/question-circle';
import SettingsIcon from '@atlaskit/icon/core/settings';
import MegaphoneIcon from '@atlaskit/icon/core/megaphone';
import { useIntl } from '@atlassian/jira-intl';
import { useIsSiteAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-site-admin/index.tsx';
import { useIsConnectionsFeatureEnabled } from '@atlassian/jira-polaris-component-early-access-program/src/controllers/index.tsx';
import FeedbackModal from '@atlassian/jira-polaris-lib-feedback/src/ui/modal/index.tsx';
import { useNotifications } from '@atlassian/jira-polaris-lib-notifications/src/controllers/index.tsx';
import { ConnectionsSectionContainer } from '@atlassian/jira-polaris-component-early-access-program/src/ui/connection-section.tsx';
import messages from './messages.tsx';

const COMMUNITY_POST_URL =
	'https://community.atlassian.com/forums/Jira-Product-Discovery-articles/Introducing-types-and-connections-aka-idea-hierarchies/ba-p/2983037';

const EAP_PAGE_URL = '/jira/settings/products/jira-product-discovery/early-access-features';

const EnableConnectionsSection = () => {
	const { formatMessage } = useIntl();
	const isSiteAdmin = useIsSiteAdmin();
	const isConnectionsFeatureEnabled = useIsConnectionsFeatureEnabled();
	const { success, error } = useNotifications();
	const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);

	const handleFeedbackClick = useCallback((event: SyntheticEvent) => {
		event.preventDefault();
		setIsFeedbackModalOpen(true);
	}, []);

	// In case of undefined, the setting is not yet loaded, so we don't render anything
	if (isConnectionsFeatureEnabled === undefined) {
		return null;
	}

	return (
		<>
			<Box xcss={dividerStyles} />
			<Stack space="space.150" alignInline="start">
				{isConnectionsFeatureEnabled ? (
					<>
						<Heading size="small">{formatMessage(messages.earlyAccessTitle)}</Heading>
						<Text>{formatMessage(messages.earlyAccessDescription)}</Text>
						<Anchor xcss={anchorStyles} href="" onClick={handleFeedbackClick}>
							<Inline alignBlock="center" space="space.075">
								<MegaphoneIcon label={formatMessage(messages.feedbackButton)} />
								{formatMessage(messages.feedbackButton)}
							</Inline>
						</Anchor>
						{isFeedbackModalOpen && (
							<FeedbackModal
								onCancel={() => setIsFeedbackModalOpen(false)}
								onSuccess={success}
								onError={error}
							/>
						)}
					</>
				) : (
					<>
						<Heading size="small">
							{formatMessage(messages.howToEnableConnectionFieldsTitle)}
						</Heading>
						<Text>{formatMessage(messages.howToEnableConnectionFieldsDescription)}</Text>
						{isSiteAdmin && (
							<Anchor xcss={anchorStyles} href={EAP_PAGE_URL} target="_blank">
								<Inline alignBlock="center" space="space.075">
									<SettingsIcon label={formatMessage(messages.productSettingsLink)} />
									{formatMessage(messages.productSettingsLink)}
								</Inline>
							</Anchor>
						)}
					</>
				)}
			</Stack>
		</>
	);
};

export const MainSection = () => {
	const { formatMessage } = useIntl();

	return (
		<Box xcss={containerStyles}>
			<ConnectionsSectionContainer>
				<Stack xcss={innerContainerStyles} space="space.250">
					<Stack space="space.100" alignInline="start">
						<Text>{formatMessage(messages.descriptionText)}</Text>
						<Anchor xcss={anchorStyles} href={COMMUNITY_POST_URL} target="_blank">
							<Inline alignBlock="center" space="space.075">
								<QuestionCircleIcon label={formatMessage(messages.learnMoreLink)} />
								{formatMessage(messages.learnMoreLink)}
							</Inline>
						</Anchor>
					</Stack>
					<EnableConnectionsSection />
				</Stack>
			</ConnectionsSectionContainer>
		</Box>
	);
};

const containerStyles = xcss({
	maxWidth: '1068px',
});

const innerContainerStyles = xcss({
	paddingTop: 'space.600',
	paddingLeft: 'space.600',
	paddingRight: 'space.200',
	paddingBottom: 'space.500',
	maxWidth: '544px',
});

const dividerStyles = xcss({
	borderColor: 'color.border',
	borderWidth: 'border.width.0',
	borderTopWidth: 'border.width',
	borderStyle: 'solid',
	height: '1px',
	maxWidth: '450px',
});

const anchorStyles = xcss({
	paddingInline: 'space.150',
	paddingBlock: 'space.075',
	backgroundColor: 'elevation.surface',
	borderRadius: '3px',
	textDecoration: 'none',
});
