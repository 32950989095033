import React, { type ReactNode, useEffect } from 'react';
import Button from '@atlaskit/button';
import EmptyState, { type EmptyStateProps } from '@atlaskit/empty-state';
import type { Event } from '@atlassian/jira-common-util-logging/src/log.tsx';
import { DocumentTitle } from '@atlassian/jira-global-document-title/src/DocumentTitle.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { Link } from '@atlassian/react-resource-router';
import { PROJECTS_PATH } from '@atlassian/jira-polaris-common/src/controllers/route/constants/index.tsx';
import { logSafeErrorWithoutCustomerDataWrapper } from '@atlassian/jira-polaris-lib-errors/src/common/utils/index.tsx';
import ErrorGeneral from './assets/error-general.svg';
import ErrorImage from './assets/generic-error.svg';
import NotFoundError from './assets/not-found-error.svg';
import ViewUnavailableErrorImage from './assets/view-unavailable-error.svg';
import { ContactSupportLink } from './contact-support-link/index.tsx';
import messages from './messages.tsx';

const Error = (props: Partial<EmptyStateProps>) => {
	const { formatMessage } = useIntl();

	return (
		<>
			<DocumentTitle title={formatMessage(messages.genericErrorDocumentTitle)} />
			<EmptyState
				testId="polaris-common.common.utils.errors.empty-state"
				imageUrl={ErrorImage}
				maxImageHeight={257}
				maxImageWidth={400}
				header={formatMessage(messages.genericErrorTitle)}
				description={formatMessage(messages.genericErrorDescription)}
				{...props}
			/>
		</>
	);
};

type GenericErrorProps = {
	error: Event;
};

export const ProjectNotFoundError = () => {
	const { formatMessage } = useIntl();

	return (
		<>
			<DocumentTitle title={formatMessage(messages.notFoundDocumentTitle)} />
			<EmptyState
				testId="polaris-common.common.utils.errors.empty-state"
				width="wide"
				imageUrl={NotFoundError}
				header={formatMessage(messages.notFoundTitle)}
				description={formatMessage(messages.notFoundDescription)}
				primaryAction={
					<Link to={PROJECTS_PATH}>{formatMessage(messages.listAllProjectsAction)}</Link>
				}
			/>
		</>
	);
};

export const ProjectNotOnboardedError = ({ isAdmin }: { isAdmin?: boolean }) => {
	const { formatMessage } = useIntl();

	return (
		<>
			<DocumentTitle title={formatMessage(messages.notOnboardedDocumentTitle)} />
			<EmptyState
				testId="polaris-common.common.utils.errors.empty-state"
				width="wide"
				imageUrl={NotFoundError}
				header={formatMessage(messages.notOnboardedTitle)}
				description={
					isAdmin
						? formatMessage(messages.notOnboardedDescriptionForAdmins)
						: formatMessage(messages.notOnboardedDescription)
				}
				primaryAction={
					<Link to={PROJECTS_PATH}>{formatMessage(messages.listAllProjectsAction)}</Link>
				}
			/>
		</>
	);
};

export const ProjectLoadError = () => {
	const { formatMessage } = useIntl();

	return (
		<>
			<DocumentTitle title={formatMessage(messages.projectLoadErrorTitle)} />
			<EmptyState
				width="wide"
				imageUrl={NotFoundError}
				header={formatMessage(messages.projectLoadErrorTitle)}
				description={formatMessage(messages.projectLoadErrorDescription)}
				primaryAction={
					<Link to={PROJECTS_PATH}>{formatMessage(messages.listAllProjectsAction)}</Link>
				}
			/>
		</>
	);
};

export const GenericError = ({ error }: GenericErrorProps) => {
	useEffect(() => {
		logSafeErrorWithoutCustomerDataWrapper('polaris-generic-error.failure', 'Generic error', error);
	}, [error]);

	return <Error />;
};

type GenericErrorWithRefreshProps = {
	error: Event;
	onRefresh: () => void;
};

export const GenericErrorWithRefresh = ({
	error,
	onRefresh,
	...props
}: GenericErrorWithRefreshProps & Partial<EmptyStateProps>) => {
	const { formatMessage } = useIntl();

	useEffect(() => {
		logSafeErrorWithoutCustomerDataWrapper(
			'polaris-generic-error.failure',
			'Generic error with action',
			error,
		);
	}, [error]);

	return (
		<>
			<DocumentTitle title={formatMessage(messages.genericErrorDocumentTitle)} />
			<EmptyState
				testId="polaris-common.common.utils.errors.empty-state"
				imageUrl={ErrorGeneral}
				header={formatMessage(messages.genericErrorTitle)}
				description={formatMessage(messages.genericErrorDescription)}
				primaryAction={
					<Button appearance="link" onClick={onRefresh}>
						{formatMessage(messages.refreshButton)}
					</Button>
				}
				{...props}
			/>
		</>
	);
};

export const RateLimitError = () => {
	const { formatMessage } = useIntl();

	return (
		<>
			<DocumentTitle title={formatMessage(messages.rateLimitErrorTitle)} />
			<EmptyState
				testId="polaris-common.common.utils.errors.empty-state"
				imageUrl={ErrorGeneral}
				header={formatMessage(messages.rateLimitErrorTitle)}
				description={formatMessage(messages.rateLimitErrorDescription, {
					a: (chunks: ReactNode[]) => <ContactSupportLink>{chunks}</ContactSupportLink>,
				})}
			/>
		</>
	);
};

export const ViewUnavailableError = () => {
	const { formatMessage } = useIntl();

	return (
		<>
			<DocumentTitle title={formatMessage(messages.viewUnavailableTitle)} />
			<EmptyState
				testId="polaris-common.common.utils.errors.view-unavailable"
				width="narrow"
				imageUrl={ViewUnavailableErrorImage}
				header={formatMessage(messages.viewUnavailableTitle)}
				description={formatMessage(messages.viewUnavailableDescription)}
			/>
		</>
	);
};
