import { useEffect } from 'react';
import { initializeControls } from '@atlassian/browser-storage-controls';
import { fg } from '@atlassian/jira-feature-gating';

export const BrowserStorageControlsInit = () => {
	useEffect(() => {
		initializeControls({
			product: 'Jira',
			webStorageEnabled: fg('jfp-vulcan-browser-storage-providers'),
		});
	}, []);

	return null;
};
