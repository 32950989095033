import React, { memo, useCallback, useState } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import EditorAddIcon from '@atlaskit/icon/core/migration/add--editor-add';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useIdeaActions } from '@atlassian/jira-polaris-common/src/controllers/idea/main.tsx';
import {
	useGroupedIssueLinks,
	useIsLoadingArchived,
} from '@atlassian/jira-polaris-common/src/controllers/idea/selectors/hooks.tsx';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import { useUnarchivedIssuesForIssueSelect } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/issue-ids-hooks.tsx';
import {
	useIsSelectedIssueArchived,
	useSelectedIssue,
	useSelectedIssueKey,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { useCanLinkIssues } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import type { RemoteLinkedIssue } from '@atlassian/jira-polaris-remote-issue/src/controllers/issue-link/types.tsx';
import { LinkIssue } from '../../../common/ui/link-issue/index.tsx';
import { RelatedIssue } from '../../../common/ui/related-issue/index.tsx';
import messages from './messages.tsx';

type IssueLinkItemProps = {
	issueLinkId: string;
	isLoadingArchived: boolean;
	linkedIssue: RemoteLinkedIssue;
	selectedIssue: string | undefined;
	isReadOnly?: boolean;
};

const IssueLinkItem = memo<IssueLinkItemProps>((props: IssueLinkItemProps) => {
	const canLinkIssues = useCanLinkIssues();
	const { unlinkIssue } = useIdeaActions();

	const { unlinkIssueFromStore } = useIssueActions();
	const { linkedIssue, issueLinkId, isLoadingArchived, selectedIssue, isReadOnly } = props;
	const [isUnlinking, setIsUnlinking] = useState(false);

	const onRemoveIssueLink = useCallback(() => {
		if (linkedIssue !== undefined) {
			setIsUnlinking(true);
			unlinkIssue(issueLinkId).then(() => {
				setIsUnlinking(false);
				selectedIssue !== undefined && unlinkIssueFromStore(selectedIssue, issueLinkId);
			});
		}
	}, [issueLinkId, linkedIssue, selectedIssue, unlinkIssue, unlinkIssueFromStore]);

	return (
		<RelatedIssue
			issue={{
				issueId: +linkedIssue.id,
				issueKey: linkedIssue.key,
				summary: linkedIssue.fields.summary,
				isArchived: linkedIssue.isArchived,
				status: {
					categoryKey: linkedIssue.fields.status.statusCategory.key,
					categoryId: linkedIssue.fields.status.statusCategory.id,
					categoryName: linkedIssue.fields.status.statusCategory.name,
					categoryColorName: linkedIssue.fields.status.statusCategory.colorName,
					statusId: linkedIssue.fields.status.id,
					statusName: linkedIssue.fields.status.name,
				},
				issueType: {
					id: linkedIssue.fields.issuetype.id,
					name: linkedIssue.fields.issuetype.name,
					iconUrl: linkedIssue.fields.issuetype.iconUrl,
					subtask: linkedIssue.fields.issuetype.subtask,
				},
			}}
			isUnlinking={isUnlinking}
			isLoadingArchived={isLoadingArchived}
			onRemoveIssueLink={canLinkIssues && !isReadOnly ? onRemoveIssueLink : undefined}
		/>
	);
});

type DefaultOptions = {
	label: string;
	value: string;
	item: {
		id: number;
		key: string;
		summaryText: string;
		img: string;
	};
};

type Props = {
	isCreatingIssueLink: boolean;
	onLinkIssue: () => void;
	onCancelOrCreated: () => void;
};

export const IssueLinks = memo<Props>(
	({ isCreatingIssueLink, onLinkIssue, onCancelOrCreated }: Props) => {
		const selectedIssue = useSelectedIssue();
		const issueKey = useSelectedIssueKey();
		const groupedIssueLinks = useGroupedIssueLinks();
		const isLoadingArchived = useIsLoadingArchived();
		const canLinkIssues = useCanLinkIssues();
		const groups = Object.keys(groupedIssueLinks).sort();
		const isIdeaArchived = useIsSelectedIssueArchived();

		const unarchivedIdeas = useUnarchivedIssuesForIssueSelect();

		const { formatMessage } = useIntl();

		const defaultOptions = unarchivedIdeas.reduce((result: DefaultOptions[], idea) => {
			if (idea.localIssueId !== selectedIssue) {
				result.push({
					label: idea.summary,
					value: `${idea.key} ${idea.summary}`,
					item: {
						id: idea.id,
						key: idea.key,
						summaryText: idea.summary,
						img: idea.issuetype?.iconUrl || '',
					},
				});
			}
			return result;
		}, []);

		const groupedOptions = {
			label: formatMessage(messages.issueLinksDefaultGroupLabel),
			options: defaultOptions,
		};

		if (groups.length > 0 || isCreatingIssueLink) {
			return (
				<Container>
					<Heading>
						<div>
							{formatMessage(
								fg('polaris-issue-terminology-refresh')
									? messages.linkedIssuesIssueTermRefresh
									: messages.linkedIssues,
							)}
						</div>
						{canLinkIssues && !isIdeaArchived && (
							<div>
								<Tooltip
									content={formatMessage(
										fg('polaris-issue-terminology-refresh')
											? messages.linkButtonCaptionIssueTermRefresh
											: messages.linkButtonCaption,
									)}
								>
									<Button
										spacing="compact"
										appearance="subtle"
										iconBefore={
											<EditorAddIcon
												spacing="spacious"
												label={formatMessage(
													fg('polaris-issue-terminology-refresh')
														? messages.linkButtonCaptionIssueTermRefresh
														: messages.linkButtonCaption,
												)}
											/>
										}
										onClick={onLinkIssue}
									/>
								</Tooltip>
							</div>
						)}
					</Heading>
					{groups.map((group) => {
						const items = groupedIssueLinks[group].map((item) => (
							<IssueLinkItem
								key={item.linkedIssue.id}
								isLoadingArchived={isLoadingArchived}
								linkedIssue={item.linkedIssue}
								issueLinkId={item.issueLinkId}
								selectedIssue={selectedIssue}
								isReadOnly={isIdeaArchived}
							/>
						));

						if (items.length > 0) {
							return (
								<GroupItems key={group}>
									<SectionHeading>{group}</SectionHeading>
									<ItemsContainer>{items}</ItemsContainer>
								</GroupItems>
							);
						}
						return null;
					})}
					{isCreatingIssueLink && (
						<LinkIssue
							hideArchived={!fg('jpd-disable-hiding-archived-ideas-in-issue-picker')}
							defaultOptions={groupedOptions}
							excludedProjectTypes={[]}
							isPolarisIssueLink={false}
							onCancel={onCancelOrCreated}
							onIssueLinked={onCancelOrCreated}
							issueKey={issueKey}
							localIssueId={selectedIssue}
						/>
					)}
				</Container>
			);
		}

		return null;
	},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	flexDirection: 'row',
	marginTop: token('space.200'),
	flexGrow: 1,
	marginBottom: token('space.200'),
	marginRight: token('space.100'),
	marginLeft: token('space.100'),
	position: 'relative',
	// Idea view tabs have z-index 97, so we need to be above that in order to prevent overlap with opened select
	zIndex: 98,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const GroupItems = styled.div({
	marginTop: token('space.150'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SectionHeading = styled.div({
	font: token('font.body.UNSAFE_small'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ItemsContainer = styled.div({
	boxShadow: token('elevation.shadow.raised'),
	borderRadius: '3px',
	padding: 0,
	marginTop: token('space.100'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Heading = styled.div({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
});
