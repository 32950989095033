import { performDeleteRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type {
	GroupUuid,
	ProjectId,
	ProjectKey,
} from '@atlassian/jira-shared-types/src/general.tsx';
import type { RemoveRoleForActorRequest } from './types.tsx';

const getUrl = (
	numericRoleId: number,
	projectKey: ProjectKey,
	actorsRoleTypeId: string,
	isGroup: boolean,
) =>
	`/rest/api/2/project/${projectKey}/role/${numericRoleId}?${
		isGroup ? 'group' : 'user'
	}=${actorsRoleTypeId}`;

/**
 * @deprecated use removeRoleForGroup / removeRoleForUser as soon as the identity based contributor role is available
 * @param numericRoleId
 * @param projectKey
 * @param actorsRoleTypeId
 * @param isGroup
 */
export const removeRoleForActor = ({
	numericRoleId,
	projectKey,
	actorsRoleTypeId,
	isGroup,
}: RemoveRoleForActorRequest): Promise<void> =>
	performDeleteRequest(getUrl(numericRoleId, projectKey, actorsRoleTypeId, isGroup));

export const removeRoleForGroup = (
	roleId: number,
	groupId: GroupUuid,
	projectId: ProjectId,
): Promise<void> =>
	performDeleteRequest(
		`/rest/polaris/contributors/project/${projectId}/role/${roleId}?groupId=${groupId}`,
	);

export const removeRoleForUser = (
	roleId: number,
	userId: string,
	projectId: ProjectId,
): Promise<void> =>
	performDeleteRequest(
		`/rest/polaris/contributors/project/${projectId}/role/${roleId}?userId=${userId}`,
	);
