import { useIsCollectionView } from '@atlassian/jira-polaris-common/src/controllers/environment/index.tsx';
import { useCollectionName } from '@atlassian/jira-polaris-component-collections/src/controllers/collections/index.tsx';
import { useEnvironmentContainer } from '@atlassian/jira-polaris-component-environment-container/src/controllers/store/index.tsx';
import { useProjectMetadata } from '@atlassian/jira-polaris-component-project-metadata/src/controllers/index.tsx';
import { useProjectId } from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';

export const useContainerName = (): string | undefined => {
	const projectId = useProjectId();
	const projectMeta = useProjectMetadata({ projectId });
	const projectName = projectMeta?.name;
	const containerId = useEnvironmentContainer()?.id ?? '';
	const collectionName = useCollectionName({ collectionUUID: containerId });
	const isCollectionView = useIsCollectionView();

	return isCollectionView ? collectionName : projectName;
};
