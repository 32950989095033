import React, { useState, useMemo, memo, useCallback } from 'react';
import omit from 'lodash/omit';
import ErrorIcon from '@atlaskit/icon/core/migration/error';
import { Box, xcss } from '@atlaskit/primitives';
import Select, { CreatableSelect } from '@atlaskit/select';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import type { CSSObjectWithLabel } from '@atlaskit/react-select';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import { useSearchableLocalIssueIds } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/grouping-hooks.tsx';
import {
	useKeys,
	useSummaries,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { AlignedShortLabelContext } from '@atlassian/jira-polaris-common/src/ui/issue-short-label/index.tsx';
import { useProjectId } from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import { useIssueTypeIdForProject } from '@atlassian/jira-polaris-component-issue-types/src/controllers/index.tsx';
import { CustomTooltip as WarningTooltip } from '@atlassian/jira-polaris-lib-create-issue-input/src/ui/validation/index.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import type { FieldValuesUpdateRequest } from '@atlassian/jira-polaris-common/src/controllers/issue/actions/update-field-value/types.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { ISSUETYPE_FIELDKEY } from '@atlassian/jira-polaris-domain-field/src/field/constants.tsx';
import { useOnCreatedOrMovedIssueFiltered } from '../../../common/on-created-filtered-issue/index.tsx';
import messages from './messages.tsx';
import { Selectable } from './selectable/index.tsx';
import {
	isCreatableOption,
	type AddCardProps,
	type CreatableOption,
	type Option,
} from './types.tsx';
import { useOptions } from './utils.tsx';

const MAX_CHARS_LENGTH = 255;

const ValidationIcon = () => {
	const { formatMessage } = useIntl();

	return (
		<Tooltip component={WarningTooltip} content={formatMessage(messages.maxLengthError)}>
			<Box xcss={errorIconContainerStyles}>
				<ErrorIcon
					LEGACY_primaryColor={colors.R400}
					label={formatMessage(messages.error)}
					color={token('color.icon.accent.red')}
				/>
			</Box>
		</Tooltip>
	);
};

const AddCardNew = memo<AddCardProps>(
	({
		groupIdentity,
		onBlur,
		onMoveIssueToColumn,
		fieldKey,
		fieldValue,
		verticalFieldKey,
		verticalFieldValue,
		verticalGroupIdentity,
	}) => {
		const { formatMessage } = useIntl();
		const { createAnalyticsEvent } = useAnalyticsEvents();
		const { updateFieldValues, createAndUpdate } = useIssueActions();

		const [value, setValue] = useState<Option | null>(null);
		const [inputValue, setInputValue] = useState('');
		const [menuIsOpen, setMenuIsOpen] = useState(false);

		const { selectableOptions, creatableOptions } = useOptions({
			inputValue,
			fieldKey,
			groupIdentity,
			verticalFieldKey,
			verticalGroupIdentity,
		});

		const onCreatedIssueFiltered = useOnCreatedOrMovedIssueFiltered();
		const isInvalid = inputValue.length > MAX_CHARS_LENGTH;

		const createIssue = ({ issueType, value: summary }: CreatableOption) => {
			if (isInvalid) {
				return;
			}

			let fieldsToUpdate = {
				[fieldKey]: fieldValue,
			};

			if (verticalFieldKey !== undefined) {
				fieldsToUpdate[verticalFieldKey] = verticalFieldValue;
			}

			fieldsToUpdate = omit(fieldsToUpdate, ISSUETYPE_FIELDKEY);

			fireCompoundAnalyticsEvent.BoardView.searchOrCreateNewIdeaClicked(createAnalyticsEvent({}));

			createAndUpdate({
				issueType,
				summary,
				analyticsSource: 'ideasBoardViewScreen',
				optimisticUpdateFields: fieldsToUpdate,
				updateOperation: (id) => {
					const { filtered } = updateFieldValues({
						localIssueIds: [id],
						fields: Object.entries(fieldsToUpdate).reduce<FieldValuesUpdateRequest['fields']>(
							(acc, [keyToUpdate, valueToUpdate]) => {
								acc[keyToUpdate] = {
									newValue: valueToUpdate,
									appendMultiValues: true,
								};

								return acc;
							},
							{},
						),
					});

					if (filtered) {
						onCreatedIssueFiltered(id, issueType.id);
					}
				},
			});
		};

		const handleChange = (option: Option | null) => {
			if (!option) {
				return;
			}

			if (isCreatableOption(option)) {
				createIssue(option);
			} else {
				onMoveIssueToColumn(option.issueId, option.issueType.id);
				setValue(null);
			}
		};

		const handleBlur = () => {
			if (!isInvalid && inputValue && creatableOptions[0]) {
				createIssue(creatableOptions[0]);
			}

			onBlur();
		};

		const controlStyles = (base: CSSObjectWithLabel) => {
			const borderColor = token('color.border.danger');

			return {
				...base,
				...(isInvalid && {
					borderColor,
					':hover': {
						borderColor,
					},
				}),
			};
		};

		return (
			<Box
				xcss={containerStyles}
				testId="polaris-ideas.ui.view-content.idea-board-next.column-footer.search-and-create.creatable-select"
			>
				<AlignedShortLabelContext>
					<Select<Option>
						components={isInvalid ? { DropdownIndicator: ValidationIcon } : undefined}
						onMenuOpen={() => setMenuIsOpen(true)}
						onMenuClose={() => !isInvalid && setMenuIsOpen(false)}
						menuIsOpen={!isInvalid && menuIsOpen}
						styles={{ control: controlStyles }}
						inputValue={inputValue}
						value={value}
						spacing="compact"
						menuPlacement="auto"
						autoFocus
						placeholder={formatMessage(messages.placeholder)}
						onInputChange={setInputValue}
						onChange={handleChange}
						onBlur={handleBlur}
						options={inputValue ? selectableOptions.concat(creatableOptions) : selectableOptions}
						openMenuOnFocus
						openMenuOnClick={false}
					/>
				</AlignedShortLabelContext>
			</Box>
		);
	},
);

const AddCardOld = memo<AddCardProps>(
	({
		groupIdentity,
		onBlur,
		onMoveIssueToColumn,
		fieldKey,
		fieldValue,
		verticalFieldKey,
		verticalFieldValue,
		verticalGroupIdentity,
	}) => {
		const { formatMessage } = useIntl();
		const { createAnalyticsEvent } = useAnalyticsEvents();
		const summaries = useSummaries();
		const keys = useKeys();
		const issueIds = useSearchableLocalIssueIds(
			fieldKey,
			groupIdentity,
			verticalFieldKey,
			verticalGroupIdentity,
		);
		const projectId = useProjectId();
		const issueTypeId = useIssueTypeIdForProject({ projectId });

		const [value, setValue] = useState<unknown>(undefined);
		const [inputValue, setInputValue] = useState('');
		const [menuIsOpen, setMenuIsOpen] = useState(false);
		const { createAndUpdateLegacy } = useIssueActions();
		const { updateFieldValues } = useIssueActions();

		const options = useMemo(
			() =>
				issueIds.map((issueId) => ({
					label: <Selectable issueId={issueId} />,
					value: keys[issueId] + summaries[issueId],
					issueId,
				})),
			[issueIds, keys, summaries],
		);

		const onCreatedIssueFiltered = useOnCreatedOrMovedIssueFiltered();
		const isInvalid = inputValue.length > MAX_CHARS_LENGTH;

		const onMenuOpen = useCallback(() => {
			fireCompoundAnalyticsEvent.BoardView.searchOrCreateClicked(createAnalyticsEvent({}));
			setMenuIsOpen(true);
		}, [createAnalyticsEvent]);

		const onCreateIssue = useCallback(
			(summary: string) => {
				if (isInvalid || issueTypeId === undefined) {
					return;
				}

				const fieldsToUpdate = {
					[fieldKey]: fieldValue,
				};

				if (verticalFieldKey !== undefined) {
					fieldsToUpdate[verticalFieldKey] = verticalFieldValue;
				}

				fireCompoundAnalyticsEvent.BoardView.searchOrCreateNewIdeaClicked(createAnalyticsEvent({}));

				createAndUpdateLegacy(
					issueTypeId,
					summary,
					fieldsToUpdate,
					// eslint-disable-next-line @typescript-eslint/no-empty-function
					() => {},
					(id) => {
						const { filtered } = updateFieldValues({
							localIssueIds: [id],
							// eslint-disable-next-line @typescript-eslint/no-explicit-any
							fields: Object.entries(fieldsToUpdate).reduce<Record<string, any>>(
								// eslint-disable-next-line @typescript-eslint/no-explicit-any
								(acc, [keyToUpdate, valueToUpdate]: [any, any]) => {
									acc[keyToUpdate] = {
										newValue: valueToUpdate,
										appendMultiValues: true,
									};

									return acc;
								},
								{},
							),
						});

						if (filtered === true) {
							onCreatedIssueFiltered(id, '');
						}
					},
					'ideasBoardViewScreen',
				);
			},
			[
				createAnalyticsEvent,
				createAndUpdateLegacy,
				fieldKey,
				fieldValue,
				issueTypeId,
				isInvalid,
				onCreatedIssueFiltered,
				updateFieldValues,
				verticalFieldKey,
				verticalFieldValue,
			],
		);

		const onSelectIssue = useCallback(
			// @ts-expect-error - TS7006 - Parameter 'option' implicitly has an 'any' type.
			(option) => {
				onMoveIssueToColumn(option.issueId, '');
				setValue('');
			},
			[onMoveIssueToColumn],
		);

		const handleBlur = () => {
			if (!isInvalid && inputValue) {
				onCreateIssue(inputValue);
			}

			onBlur();
		};

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const controlStyles = (base: any) => {
			const borderColor = token('color.border.danger');

			return {
				...base,
				...(isInvalid && {
					borderColor,
					':hover': {
						borderColor,
					},
				}),
			};
		};

		if (issueTypeId === undefined) {
			return null;
		}

		return (
			<Box
				xcss={containerStyles}
				testId="polaris-ideas.ui.view-content.idea-board-next.column-footer.search-and-create.creatable-select"
			>
				<AlignedShortLabelContext>
					<CreatableSelect
						components={isInvalid ? { DropdownIndicator: ValidationIcon } : undefined}
						onMenuOpen={onMenuOpen}
						onMenuClose={() => !isInvalid && setMenuIsOpen(false)}
						menuIsOpen={!isInvalid && menuIsOpen}
						styles={{ control: controlStyles }}
						inputValue={inputValue}
						value={value}
						spacing="compact"
						menuPlacement="auto"
						autoFocus
						placeholder={formatMessage(messages.placeholder)}
						onInputChange={setInputValue}
						onChange={onSelectIssue}
						onBlur={handleBlur}
						options={options}
						openMenuOnFocus
						openMenuOnClick={false}
						onCreateOption={onCreateIssue}
					/>
				</AlignedShortLabelContext>
			</Box>
		);
	},
);

export const AddCard = componentWithFG('jpd_issue_types_ga', AddCardNew, AddCardOld);

const containerStyles = xcss({
	flexGrow: 1,
	/* NOTE: z-index and position are set here to get the dropdown menu working with AK AvatarGroups. These set the */
	/* z-index of avatars when stacked so that they can end up over the dropdown menu. */
	zIndex: 'card',
	position: 'relative',
	maxWidth: '100%',
});

const errorIconContainerStyles = xcss({
	paddingLeft: 'space.050',
});
