import React, { useState, useCallback } from 'react';
import differenceBy from 'lodash/differenceBy';
import type { FilterOptionOption, SingleValue } from '@atlaskit/react-select';
import Select, {
	type SelectComponentsConfig,
	type StylesConfig,
	type GroupedOptionsType,
} from '@atlaskit/select';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { MAX_OPTIONS } from '../constants.tsx';
import { ConnectionFieldSelectContext } from '../edit/context/index.tsx';
import { ConnectionFieldGroupHeading } from '../edit/group-heading/index.tsx';
import { ConnectionFieldMenuList } from '../edit/menu-list/index.tsx';
import commonMessages from '../edit/messages.tsx';
import { NoOptionsMessage } from '../edit/no-options-message/index.tsx';
import { ConnectionFieldIssueOption } from '../edit/option/index.tsx';
import { isCreatableOption, type ConnectionFieldOption } from '../types.tsx';
import {
	filterOption,
	useConnectionFieldOptions,
	useCreatableOptions,
	useSiteIssues,
} from '../utils.tsx';
import { useLocalIssueIdToJiraIssueId } from '../../../../controllers/issue/selectors/issue-ids-hooks.tsx';
import { ConnectionFieldGroup } from '../edit/group/index.tsx';
import { ControlComponent } from './control/index.tsx';

type Props = {
	localIssueId: LocalIssueId;
	fieldKey: FieldKey;
	menuPortalTarget?: HTMLElement;
};

const customComponents: SelectComponentsConfig<ConnectionFieldOption> = {
	Control: ControlComponent,
	MenuList: ConnectionFieldMenuList,
	Option: ConnectionFieldIssueOption,
	IndicatorsContainer: () => null,
	GroupHeading: ConnectionFieldGroupHeading,
	NoOptionsMessage,
	Group: ConnectionFieldGroup,
};

const MAX_CHARS_LENGTH = 255;

export const ConnectionFieldEditableSingleSelect = ({
	localIssueId,
	fieldKey,
	menuPortalTarget,
}: Props) => {
	const { formatMessage } = useIntl();
	const { siteIssues, debouncedLoadSiteIssues, loadSiteIssues } = useSiteIssues({ fieldKey });
	const [allIssuesOptions, siteIssuesOptions, connectedIssuesOptions] = useConnectionFieldOptions(
		localIssueId,
		fieldKey,
		siteIssues,
	);
	const [inputValue, setInputValue] = useState('');
	const creatableOptions = useCreatableOptions(fieldKey);
	const { updateIssueConnections, createAndConnect, refreshIssues, addConnectionIssuesData } =
		useIssueActions();
	const localIssueIdToJiraId = useLocalIssueIdToJiraIssueId();
	const [isInCreation, setIsInCreation] = useState(false);

	const isInvalidInputLength = inputValue.length > MAX_CHARS_LENGTH;

	const allAvailableOptions = differenceBy(
		allIssuesOptions.filter((issue) => filterOption(issue, inputValue)),
		connectedIssuesOptions,
		'value',
	);
	const projectOptions = allAvailableOptions.slice(0, MAX_OPTIONS);

	const localOptions: GroupedOptionsType<ConnectionFieldOption> = [
		{
			label: formatMessage(commonMessages.projectGroupHeadingNonFinal),
			options: inputValue ? [...projectOptions, ...creatableOptions] : projectOptions,
		},
		{
			label: formatMessage(commonMessages.siteGroupHeadingNonFinal),
			options: differenceBy(
				siteIssuesOptions.filter((issue) => filterOption(issue, inputValue)),
				connectedIssuesOptions,
				'value',
			),
		},
	];

	const handleChange = useCallback(
		async (newValue: SingleValue<ConnectionFieldOption>) => {
			if (!newValue || isInvalidInputLength) {
				return;
			}

			if (isCreatableOption(newValue)) {
				setIsInCreation(true);
				await createAndConnect({
					localIssueIdToConnect: localIssueId,
					summary: inputValue,
					issueType: newValue.issueType,
					analyticsSource: 'connectionsFieldDropdown',
				});
				setIsInCreation(false);
			} else {
				if (fg('jpd_cross_project_connecting')) {
					addConnectionIssuesData([
						{
							issueId: newValue.value,
							issueKey: newValue.issueKey,
							summary: newValue.label,
							issueType: newValue.issueType,
						},
					]);
				}

				await updateIssueConnections({
					localIssueId,
					issuesToConnect: [
						{
							id: newValue.value,
						},
					],
				});

				if (fg('jpd_cross_project_connecting')) {
					const issueIdToRefresh = localIssueIdToJiraId[localIssueId];
					if (issueIdToRefresh) {
						refreshIssues({
							jiraIssueIds: [issueIdToRefresh, newValue.value],
						});
					}
				}
			}
		},
		[
			addConnectionIssuesData,
			createAndConnect,
			inputValue,
			isInvalidInputLength,
			localIssueId,
			localIssueIdToJiraId,
			refreshIssues,
			updateIssueConnections,
		],
	);

	const handleInputChange = useCallback(
		(newValue: string) => {
			if (inputValue !== newValue) {
				setInputValue(newValue);
				debouncedLoadSiteIssues(newValue);
			}
		},
		[debouncedLoadSiteIssues, inputValue],
	);

	return (
		<ConnectionFieldSelectContext.Provider value={{ isInCreation }}>
			<Select<ConnectionFieldOption>
				isSearchable
				isClearable
				hideSelectedOptions
				components={customComponents}
				onChange={handleChange}
				value={null}
				onInputChange={handleInputChange}
				inputValue={inputValue}
				options={localOptions}
				filterOption={(option: FilterOptionOption<ConnectionFieldOption | null>) => !!option.data}
				enableAnimation={false}
				menuPlacement="auto"
				maxMenuHeight={280}
				styles={stylesConfig}
				menuPortalTarget={menuPortalTarget}
				placeholder=""
				closeMenuOnSelect
				blurInputOnSelect
				isInvalid={isInvalidInputLength}
				onMenuOpen={loadSiteIssues}
			/>
		</ConnectionFieldSelectContext.Provider>
	);
};

const stylesConfig: StylesConfig<ConnectionFieldOption> = {
	menuPortal: (styles) => ({
		...styles,
		zIndex: 100,
	}),
	menu: (styles) => ({
		...styles,
		minWidth: '400px',
		marginTop: '-6px',
		marginBottom: '-6px',
		zIndex: 100,
		width: 'calc(100% - 8px)',
		right: '4px',
	}),
	menuList: (styles) => ({
		...styles,
		padding: '8px 0',
	}),
	group: (styles) => ({
		...styles,
		padding: '6px 0',
	}),
	groupHeading: (styles) => ({
		...styles,
		marginBottom: '7px',
		'&:empty': {
			display: 'none',
		},
	}),
	control: (styles) => ({
		...styles,
		border: 0,
		'&:focus-within': {
			boxShadow: `inset 0 0 0 2px ${token('color.border.focused')}`,
		},
	}),
};
