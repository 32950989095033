import React, { type SyntheticEvent, useCallback } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { iconForPolarisFieldType } from '@atlassian/jira-polaris-component-glyphs/src/ui/glyphs/main.tsx';
import { useCanEditFields } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { useFieldOfType } from '../../../../controllers/field/selectors/field-hooks.tsx';
import { useField } from '../../../../controllers/issue/selectors/fields-hooks.tsx';
import { useExternalReferenceValue } from '../../../../controllers/issue/selectors/properties/hooks.tsx';
import { useIsIssueOpenInFullscreen } from '../../../../controllers/route/index.tsx';
import { ExternalReferenceRenderer } from '../../external-reference/renderer/index.tsx';
import messages from './messages.tsx';

type HintProps = {
	fieldKey: FieldKey;
	issueId: LocalIssueId;
	onConfiguration: () => void;
};

export const Hint = ({ fieldKey, issueId, onConfiguration }: HintProps) => {
	const field = useField(fieldKey);
	const { formatMessage } = useIntl();
	const isIssueOpenInFullscreen = useIsIssueOpenInFullscreen();

	const canEditFields = useCanEditFields();

	const onClick = useCallback(
		(evt: SyntheticEvent) => {
			evt.stopPropagation();
			onConfiguration();
		},
		[onConfiguration],
	);

	const atlasProjectField = useFieldOfType(FIELD_TYPES.ATLAS_PROJECT);
	const externalReferenceValue = useExternalReferenceValue(atlasProjectField?.key, issueId);
	if (!atlasProjectField || !field) {
		return null;
	}

	return (
		<Container>
			<Line>
				{iconForPolarisFieldType(FIELD_TYPES.ATLAS_PROJECT)}
				<Box xcss={fieldlabelStyles}>{field?.label}</Box>
			</Line>
			<Line>{formatMessage(messages.dateIsOverriddenByHint)}</Line>
			<Line>
				<ExternalReferenceRenderer
					provider={atlasProjectField.configuration?.provider}
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					aris={[externalReferenceValue as string]}
					maxLines={1}
					isContainerClickable
				/>
			</Line>
			{!isIssueOpenInFullscreen && (
				<Box xcss={buttonContainerStyles}>
					<Button
						onClick={onClick}
						isDisabled={!canEditFields}
						interactionName="jpd.right-sidebar.field-edit"
					>
						{formatMessage(messages.configureButton)}
					</Button>
				</Box>
			)}
		</Container>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	maxWidth: '400px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Line = styled.div({
	display: 'flex',
	alignItems: 'center',
	marginBottom: token('space.050'),
});

const fieldlabelStyles = xcss({
	marginLeft: 'space.050',
});

const buttonContainerStyles = xcss({
	paddingTop: 'space.050',
});
