import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	buttonTextIdea: {
		id: 'polaris-lib-view-empty-state.common.button-text-idea',
		defaultMessage: 'Create an idea',
		description: 'Button for creating an idea',
	},
	createIdeaButton: {
		id: 'polaris-lib-view-empty-state.common.create-idea-button',
		defaultMessage: 'Create',
		description: 'Button for creating an idea',
	},
	buttonTextFields: {
		id: 'polaris-lib-view-empty-state.common.button-text-fields',
		defaultMessage: '{fieldsCount, plural, =1 {Select field} other {Select fields}}',
		description: 'Button that opens dropdown for selecting fields',
	},
	linkArchiveLearnMore: {
		id: 'polaris-lib-view-empty-state.common.link-archive-learn-more',
		defaultMessage: 'Learn more about archiving',
		description: 'Link that opens in-app help with information about archiving',
	},
});
