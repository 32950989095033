import keyBy from 'lodash/keyBy';
import type { ContainerId } from '@atlassian/jira-polaris-component-environment-container/src/controllers/types.tsx';
import type { ProjectMetadata } from '@atlassian/jira-polaris-domain-project/src/types.tsx';
import type { ProjectId, ProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	createActionsHook,
	createSelector,
	createStateHook,
	createStore,
} from '@atlassian/react-sweet-state';
import * as actions from './actions.tsx';
import type { State } from './types.tsx';

const initialState: State = {};

export const Store = createStore({
	initialState,
	actions,
	name: 'PolarisProjectMetadataStore',
});

export const useActions = createActionsHook(Store);

export const useProjectMetadata = createStateHook(Store, {
	selector: (
		state,
		{ projectId }: { projectId: ProjectId | undefined },
	): ProjectMetadata | undefined => {
		if (projectId === undefined) {
			return undefined;
		}

		for (const container of Object.values(state)) {
			const projectMetadata = container.metadata?.[projectId];
			if (projectMetadata !== undefined) {
				return projectMetadata;
			}
		}
	},
});

export const useProjectMetadataByKey = createStateHook(Store, {
	selector: (state, { projectKey }: { projectKey: ProjectKey }): ProjectMetadata | undefined => {
		for (const container of Object.values(state)) {
			const projectsMetadata = container.metadata;
			if (projectsMetadata !== undefined) {
				for (const projectMetadata of Object.values(projectsMetadata)) {
					if (projectMetadata.key === projectKey) {
						return projectMetadata;
					}
				}
			}
		}
	},
});

const getContainerData = createSelector(
	(state: State) => state,
	(_, { containerId }: { containerId: ContainerId | undefined }) => containerId,
	(state, containerId) => (containerId ? state[containerId] : undefined),
);

const getProjectsMetadataForContainer = createSelector(
	getContainerData,
	(containerData) => containerData?.metadata,
);

const getProjectsMetadataListForContainer = createSelector(
	getProjectsMetadataForContainer,
	(metadata = {}) => Object.values(metadata),
);

const getProjectsKeysForContainer = createSelector(
	getProjectsMetadataListForContainer,
	(projects) => projects.map((project) => project.key),
);

const getProjectsIdsForContainer = createSelector(getProjectsMetadataListForContainer, (projects) =>
	projects.map((project) => String(project.id)),
);

const getProjectsCountForContainer = createSelector(
	getProjectsMetadataListForContainer,
	(projects) => projects.length,
);

const getProjectsMetadataLoading = createSelector(
	getContainerData,
	(containerData) => containerData?.isLoading === true,
);

const getProjectsMetadataHasError = createSelector(
	getContainerData,
	(containerData) => containerData?.error !== undefined,
);

const getAllProjectsMetadataByKey = (state: State) =>
	keyBy(
		Object.values(state).flatMap((container) => Object.values(container?.metadata ?? {})),
		'key',
	);

export const useProjectsKeysForContainer = createStateHook(Store, {
	selector: getProjectsKeysForContainer,
});

export const useProjectsIdsForContainer = createStateHook(Store, {
	selector: getProjectsIdsForContainer,
});

export const useProjectsCountForContainer = createStateHook(Store, {
	selector: getProjectsCountForContainer,
});

export const useProjectsMetadataForContainer = createStateHook(Store, {
	selector: getProjectsMetadataListForContainer,
});

export const useProjectsMetadataLoading = createStateHook(Store, {
	selector: getProjectsMetadataLoading,
});

export const useProjectsMetadataHasError = createStateHook(Store, {
	selector: getProjectsMetadataHasError,
});

const getProjectsMetadataForContainers = createSelector(
	(state: State) => state,
	(_, { containerIds }: { containerIds: ContainerId[] }) => containerIds,
	(state, containerIds) => {
		const projectsByKey = {};
		containerIds.forEach((containerId) => {
			const projectsMetadata = state[containerId]?.metadata;
			if (projectsMetadata) {
				Object.values(projectsMetadata).forEach((project) => {
					Object.assign(projectsByKey, {
						[project.key]: project,
					});
				});
			}
		});
		return projectsByKey;
	},
);

export const useCollectionsProjectsMetadata = createStateHook(Store, {
	selector: getProjectsMetadataForContainers,
});

export const useAllProjectsMetadataByKey = createStateHook(Store, {
	selector: getAllProjectsMetadataByKey,
});
