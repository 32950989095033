import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Container = styled.div<{ marginTop?: number }>({
	position: 'absolute',
	width: '100%',
	height: '100%',
	top: '0',
	left: '0',
	overflow: 'hidden',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginTop: ({ marginTop }) => (marginTop ? `${marginTop}px` : '0'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Wrapper = styled.div({
	position: 'absolute',
	top: '0',
	left: '0',
	height: '100%',
	width: '100%',
	zIndex: '1',
	boxSizing: 'border-box',
	paddingBottom: token('space.300'),
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
});
