import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	backToFieldsList: {
		id: 'polaris-ideas.right-sidebar.field.back-to-fields-list',
		defaultMessage: 'All fields',
		description:
			'Label on right sidebar header in single-field view to go back to the list showing all the fields',
	},
	fieldSettings: {
		id: 'polaris-ideas.right-sidebar.field.field-settings',
		defaultMessage: 'Field settings',
		description: 'Header for field settings sidebar on project fields page',
	},
	backToColumnsOptions: {
		id: 'polaris-ideas.right-sidebar.field.back-to-columns-options',
		defaultMessage: 'Back to columns',
		description:
			'Label on right sidebar header in single-field view to go back to the list showing board columns',
	},
	backToGroupByOptions: {
		id: 'polaris-ideas.right-sidebar.field.back-to-group-by-options',
		defaultMessage: 'Back to group by',
		description:
			'Label on right sidebar header in single-field view to go back to the list showing board group by',
	},
	backToXAxisConfiguration: {
		id: 'polaris-ideas.right-sidebar.field.back-to-x-axis-configuration',
		defaultMessage: 'Back to x-axis',
		description:
			'Label on right sidebar header in single-field view to go back to the X axis configuration',
	},
	backToYAxisConfiguration: {
		id: 'polaris-ideas.right-sidebar.field.back-to-y-axis-configuration',
		defaultMessage: 'Back to y-axis',
		description:
			'Label on right sidebar header in single-field view to go back to the Y axis configuration',
	},
	backToZAxisConfiguration: {
		id: 'polaris-ideas.right-sidebar.field.back-to-z-axis-configuration',
		defaultMessage: 'Back to size',
		description:
			'Label on right sidebar header in single-field view to go back to the size configuration',
	},
	deleteField: {
		id: 'polaris-ideas.right-sidebar.field.delete-field',
		defaultMessage: 'Delete field',
		description: 'Label on button that opens delete field dialog',
	},
	noPermissions: {
		id: 'polaris-ideas.right-sidebar.field.no-permissions',
		defaultMessage: 'You do not have the permission to delete this field',
		description: 'Tooltip text for missing delete permissions.',
	},
	fieldNotDeletable: {
		id: 'polaris-ideas.right-sidebar.field.field-not-deletable',
		defaultMessage: 'This field cannot be deleted',
		description: 'Tooltip text for missing delete permissions.',
	},
	backToTimelineFieldsConfiguration: {
		id: 'polaris-ideas.right-sidebar.field.back-to-timeline-fields-configuration',
		defaultMessage: 'Back to timeline fields',
		description:
			'Label on right sidebar header in single-field view to go back to the timeline fields sidebar',
	},
	systemField: {
		id: 'polaris-ideas.right-sidebar.field.system-field',
		defaultMessage: "Jira created this field. You can't delete it.",
		description: 'Tooltip text for system field that cannot be deleted',
	},
	editGlobalField: {
		id: 'polaris-ideas.right-sidebar.field.edit-global-field',
		defaultMessage: 'Edit',
		description: 'Label of the Edit global field link',
	},
	removeFromProject: {
		id: 'polaris-ideas.right-sidebar.field.remove-from-project',
		defaultMessage: 'Remove from project',
		description: 'Label of the Remove from project button',
	},
	copyValues: {
		id: 'polaris-ideas.right-sidebar.field.copy-values',
		defaultMessage: 'Copy values',
		description: 'Label of the button that copies values of a field to a global field',
	},
	copyValuesDisabledConnectionTooltipNonFinal: {
		id: 'polaris-ideas.right-sidebar.field.copy-values-disabled-connection-tooltip-non-final',
		defaultMessage: 'Values will be available automatically after creation of this field',
		description: 'Tooltip for disabled copy values button shown for connection fields',
	},
	globalFieldWarning: {
		id: 'polaris-ideas.right-sidebar.field.global-field-warning',
		defaultMessage: '<b>Global field: </b>Jira admins can edit this field on Global fields page',
		description: 'Warning message for when the user opens a global field from a project view',
	},
	hide: {
		id: 'polaris-ideas.right-sidebar.field.hide',
		defaultMessage: 'Hide',
		description: 'Label of the Hide button',
	},
	show: {
		id: 'polaris-ideas.right-sidebar.field.show',
		defaultMessage: 'Show',
		description: 'Label of the Show button',
	},
	showSystemFieldSuccessTitle: {
		id: 'polaris-ideas.right-sidebar.field.show-system-field-success-title',
		defaultMessage: '“{field}“ field is now available',
		description: 'Title of the success flag when a field is shown',
	},
	showSystemFieldSuccessDescription: {
		id: 'polaris-ideas.right-sidebar.field.show-system-field-success-description',
		defaultMessage:
			'This field is available for filtering, sorting, grouping, and using in custom formulas.',
		description: 'Description for the success flag when a field is shown',
	},
	copyValuesProgressErrorTitle: {
		id: 'polaris-ideas.right-sidebar.field.copy-values-progress-error-title',
		defaultMessage: "We can't copy the values",
		description: 'Title of the error message when copying values progress polling fails',
	},
	copyValuesProgressErrorDescription: {
		id: 'polaris-ideas.right-sidebar.field.copy-values-progress-error-description',
		defaultMessage: 'Try again.',
		description: 'Description of the error message when copying values fails',
	},
	editIssueTypesButtonLabel: {
		id: 'polaris-ideas.right-sidebar.field.edit-issue-types-button-label',
		defaultMessage: 'Edit types',
		description:
			'Edit types button label that redirects you to a page where you can manage current project issue types',
	},
});
