import { AnyAri } from '@atlassian/ari/any-ari';
import {
	ProjectOnboardingStatusEnum,
	ProjectTemplateEnum,
	type PolarisProjectTemplate,
} from '@atlassian/jira-polaris-domain-project/src/types.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { getDefaultProjectTemplateVersionVariant } from '@atlassian/jira-polaris-lib-onboarding/src/utils.tsx';
import { projectRemote as legacyProjectRemote } from '@atlassian/jira-polaris-remote-legacy-project/src/services/index.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import { fg } from '@atlassian/jira-feature-gating';
import { expVal } from '@atlassian/jira-feature-experiments';
import { logPolarisError } from '../../../common/utils/errors/index.tsx';
import type { Props, State } from '../types.tsx';

const getPolarisDefaultProjectTemplateVersion = () => {
	return fg('polaris-issue-terminology-refresh') ? 'v21' : 'v20';
};

const logError = (error: Error) => {
	logPolarisError('controllers.project.actions.applyTemplate', error);
};

export const getProjectTemplate =
	(): Action<State, Props, PolarisProjectTemplate | undefined> =>
	({ getState }) => {
		const { projectTemplate } = getState();
		return projectTemplate;
	};

type ValueOf<T> = T[keyof T];
const POLARIS_API_TEMPLATES: ValueOf<typeof ProjectTemplateEnum>[] = [
	ProjectTemplateEnum.DISCOVERY,
	ProjectTemplateEnum.ROADMAP,
	// GALILEO-2516 START
	ProjectTemplateEnum.PRIORITIZATION,
	// GALILEO-2516 END
];

const CUSTOM_PROJECT_TEMPLATE_TO_VERSION = new Map<string, string>([
	[ProjectTemplateEnum.PRIORITIZATION, 'prio_v1'],
]);
const NEW_ROADMAP_TEMPLATE_VERSION = 'roadmap_v1';

export const isPolarisApiTemplate = (projectTemplate?: PolarisProjectTemplate): boolean =>
	projectTemplate ? POLARIS_API_TEMPLATES.includes(projectTemplate.initialProjectTemplate) : false;

export const getProjectTemplateVersion = (
	projectTemplate?: PolarisProjectTemplate,
	isOnboardingExperimentEnabled?: boolean,
): string => {
	const templateKey = projectTemplate?.initialProjectTemplate;

	if (
		templateKey &&
		templateKey === ProjectTemplateEnum.ROADMAP &&
		expVal('jpd_roadmap_template', 'use_roadmap_template', false)
	) {
		return NEW_ROADMAP_TEMPLATE_VERSION;
	}

	if (templateKey && CUSTOM_PROJECT_TEMPLATE_TO_VERSION.has(templateKey)) {
		return (
			CUSTOM_PROJECT_TEMPLATE_TO_VERSION.get(templateKey) ||
			getPolarisDefaultProjectTemplateVersion()
		);
	}

	if (isOnboardingExperimentEnabled) {
		return getDefaultProjectTemplateVersionVariant();
	}

	return getPolarisDefaultProjectTemplateVersion();
};

export const applyProjectTemplate =
	(
		isOnboardingExperimentEnabled: boolean,
		issueTypes: string[],
	): Action<State, Props, Promise<boolean>> =>
	async ({ getState }, { apolloClient, cloudId }) => {
		const { project, projectTemplate } = getState();

		const templateVersion = getProjectTemplateVersion(
			projectTemplate,
			isOnboardingExperimentEnabled,
		);

		if (!project.id) {
			const error = new Error(
				'Cannot onboard missing project. Action must not be called without known project id.',
			);
			logError(error);
			throw error;
		}

		// Skip if not a Polaris API template (i.e. Blank template)
		if (!isPolarisApiTemplate(projectTemplate)) {
			return true;
		}

		// skip if no issue types
		if (issueTypes.length === 0) {
			return true;
		}

		if (templateVersion === 'none') {
			experience.project.applyTemplate.abort('Template version unknown');
			const error = new Error(
				'Cannot onboard without known template. Template version must be set.',
			);
			logError(error);
			throw error;
		}

		experience.project.applyTemplate.mark(
			experience.project.applyTemplate.marks.APPLY_TEMPLATE_POLLING_START_MARK,
		);

		await legacyProjectRemote.applyProjectTemplate(
			apolloClient,
			project.id,
			AnyAri.create({
				resourceOwner: 'jira',
				cloudId,
				resourceType: 'issuetype',
				resourceId: issueTypes[0],
			}).toString(),
			templateVersion,
		);

		return true;
	};

export const checkProjectOnboarded =
	(): Action<State, Props, Promise<boolean>> =>
	async ({ getState }, { apolloClient, projectRemote }) => {
		const { project, projectTemplate } = getState();

		if (!project.id) {
			const error = new Error('Cannot check missing project');
			logError(error);
			return false;
		}

		if (!isPolarisApiTemplate(projectTemplate)) {
			const { onboardingStatus } = await projectRemote.getOnboardingInformation();

			return onboardingStatus === ProjectOnboardingStatusEnum.DONE;
		}

		const { isOnboarded } = await legacyProjectRemote.checkProjectOnboarded(
			apolloClient,
			project.id,
		);

		return isOnboarded;
	};
