import { useMemo } from 'react';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import {
	useIsConnectionCompatibleSystemField,
	useIsGlobalCustomField,
	useIsSystemField,
} from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks.tsx';
import { useIsIssueFromDifferentContainerByLocalId } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/issue-ids-hooks.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { isWeightedScoreFormula } from '@atlassian/jira-polaris-lib-formula/src/utils/formula/weighted-score/index.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import { useIsFieldAssociatedToIssue } from '../../../controllers/issue/selectors/properties/hooks.tsx';
import { useField } from '../../../controllers/issue/selectors/fields-hooks.tsx';
import { messages } from './messages.tsx';

type UseIsUnsupportedHighlightedFieldRequest = {
	fieldKey: FieldKey;
	localIssueId: LocalIssueId;
	checkGlobalFieldAssociation?: boolean;
};

type UseIsUnsupportedHighlightedFieldResponse = {
	isUnsupportedField: boolean;
	isUnsupportedFieldReason?: string;
};

export const useIsUnsupportedHighlightedField = ({
	fieldKey,
	localIssueId,
	checkGlobalFieldAssociation = true,
}: UseIsUnsupportedHighlightedFieldRequest): UseIsUnsupportedHighlightedFieldResponse => {
	const { formatMessage } = useIntl();

	const field = useField(fieldKey);
	const isGlobalCustomField = useIsGlobalCustomField(fieldKey);
	const isFieldAssociatedToIssue = useIsFieldAssociatedToIssue(fieldKey, localIssueId);
	const isConnectionCompatibleSystemField = useIsConnectionCompatibleSystemField(fieldKey);
	const isIssueFromDifferentContainer = useIsIssueFromDifferentContainerByLocalId(localIssueId);
	const isSystemField = useIsSystemField(fieldKey);

	const isUnsupportedFormula = useMemo(
		() => Boolean(field?.formula && isWeightedScoreFormula(field.formula)),
		[field],
	);

	const isGlobalFieldAndIsAssociatedToProject = useMemo(
		() => isGlobalCustomField && isFieldAssociatedToIssue,
		[isGlobalCustomField, isFieldAssociatedToIssue],
	);

	const isDeliveryField = useMemo(
		() =>
			field?.type === FIELD_TYPES.DELIVERY_PROGRESS || field?.type === FIELD_TYPES.DELIVERY_STATUS,
		[field?.type],
	);

	const isUnsupportedField = useMemo(
		() =>
			isIssueFromDifferentContainer &&
			((!isGlobalFieldAndIsAssociatedToProject &&
				!isConnectionCompatibleSystemField &&
				checkGlobalFieldAssociation) ||
				isUnsupportedFormula ||
				isDeliveryField),
		[
			isIssueFromDifferentContainer,
			isGlobalFieldAndIsAssociatedToProject,
			isConnectionCompatibleSystemField,
			checkGlobalFieldAssociation,
			isUnsupportedFormula,
			isDeliveryField,
		],
	);

	const isUnsupportedFieldReason = useMemo(() => {
		if (isUnsupportedField) {
			if (isGlobalCustomField && !isFieldAssociatedToIssue) {
				return formatMessage(messages.isGlobalFieldNotAssociatedToProjectNonFinal);
			}

			if (isSystemField && !isConnectionCompatibleSystemField) {
				return formatMessage(messages.isConnectionNotCompatibleFieldNonFinal);
			}

			if (isUnsupportedFormula) {
				return formatMessage(messages.isUnsupportedWeightedScoreFormulaNonFinal);
			}

			if (isDeliveryField) {
				return formatMessage(messages.isDeliveryFieldNonFinal);
			}

			return formatMessage(messages.fieldIsNotSupportedNonFinal);
		}

		return undefined;
	}, [
		isUnsupportedField,
		isGlobalCustomField,
		isFieldAssociatedToIssue,
		isSystemField,
		isConnectionCompatibleSystemField,
		isUnsupportedFormula,
		isDeliveryField,
		formatMessage,
	]);

	return {
		isUnsupportedField,
		isUnsupportedFieldReason,
	};
};
