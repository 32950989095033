import React from 'react';
import Lozenge from '@atlaskit/lozenge';
import { Box, xcss } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import { useIsCollectionView } from '@atlassian/jira-polaris-common/src/controllers/environment/index.tsx';
import { useIssuesStatusesById } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/fields-hooks.tsx';
import { useAllStatusesById } from '@atlassian/jira-polaris-common/src/controllers/workflow/selectors/statuses-hooks.tsx';
import { fg } from '@atlassian/jira-feature-gating';

type Props = {
	groupIdentity: string;
};

const StatusLozenge = ({
	categoryName,
	statusName,
}: {
	categoryName: string;
	statusName: string;
}) => {
	switch (categoryName) {
		// Wrapping statusName in span to fix rendering of status in Download matrix view as JPEG
		case 'In Progress':
			return (
				<Lozenge appearance="inprogress" maxWidth="100%">
					<span>{statusName}</span>
				</Lozenge>
			);
		case 'Done':
			return (
				<Lozenge appearance="success" maxWidth="100%">
					<span>{statusName}</span>
				</Lozenge>
			);
		case 'To Do':
		default:
			return (
				<Lozenge>
					<span>{statusName}</span>
				</Lozenge>
			);
	}
};

export const StatusFieldLabel = ({ groupIdentity }: Props) => {
	const statusesById = useAllStatusesById();
	const issuesStatusesById = useIssuesStatusesById();
	const isCollectionView = useIsCollectionView();
	const status =
		isCollectionView && !fg('jpd-aurora-roadmap-inline-edit')
			? issuesStatusesById[groupIdentity]
			: statusesById[groupIdentity];

	if (status === undefined) return null;

	const statusName = status.name;

	return (
		<Box xcss={containerStyles}>
			<Tooltip content={statusName}>
				<StatusLozenge categoryName={status.statusCategory.name} statusName={statusName} />
			</Tooltip>
		</Box>
	);
};

const containerStyles = xcss({
	paddingBlock: 'space.025',
	paddingInlineStart: 'space.050',
	maxWidth: '100%',
});
