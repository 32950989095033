import React, { type RefObject } from 'react';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import {
	FIELD_TYPES,
	type FieldType,
} from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import { SummaryCardField } from '../../idea-card-v2/summary-card/summary-card-field-next/index.tsx';
import { useLocalIssueIdForJiraIssueId } from '../../../controllers/issue/selectors/issue-ids-hooks.tsx';
import {
	useConnectionFieldHighlightedFieldKey,
	useField,
} from '../../../controllers/field/selectors/field-hooks.tsx';
import { getHighlightedFieldWidth } from './utils.tsx';

const EXCLUDED_HIGHLIGHTED_FIELDS: FieldType[] = [FIELD_TYPES.SHORT_TEXT];

type Props = {
	issueId: string;
	containerRef: RefObject<HTMLDivElement>;
	fieldKey: FieldKey;
};

export const HighlightedField = ({ issueId, containerRef, fieldKey }: Props) => {
	const localIssueId = useLocalIssueIdForJiraIssueId(issueId);
	const highlightedFieldKey = useConnectionFieldHighlightedFieldKey(fieldKey) ?? '';
	const [highlightedField] = useField(highlightedFieldKey);
	const containerWidth = containerRef.current?.offsetWidth ?? 0;

	if (
		!localIssueId ||
		!highlightedField ||
		EXCLUDED_HIGHLIGHTED_FIELDS.includes(highlightedField.type)
	) {
		return null;
	}

	return (
		<SummaryCardField
			isFixed
			field={highlightedField}
			issueId={localIssueId}
			maxWidth={getHighlightedFieldWidth(highlightedField.type)}
			reservedSpace={getHighlightedFieldWidth(highlightedField.type)}
			containerWidth={containerWidth}
			showCompactOption={false}
		/>
	);
};
