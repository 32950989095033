import type { ProjectType } from '@atlassian/jira-common-constants/src/index.tsx';
import { PRODUCT_DISCOVERY_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { AvailableProjectAccessLevelsResponse } from './types.tsx';

const getUrl = (projectType: string) =>
	`/rest/internal/simplified/1.0/accesslevel?projectType=${projectType}`;

export const getProjectAccessLevels = (
	projectType?: ProjectType,
): Promise<AvailableProjectAccessLevelsResponse> =>
	performGetRequest(getUrl(projectType || PRODUCT_DISCOVERY_PROJECT));
