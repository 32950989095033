import React, { useState, useEffect, useCallback } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import { useProjectAriUnsafe } from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import { useErrorHandlers } from '@atlassian/jira-polaris-lib-errors/src/controllers/index.tsx';
import { useSubmitIdeaFormDescriptionTemplateProperty } from '@atlassian/jira-polaris-component-project-properties/src/controllers/selectors/properties/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { DEFAULT_TEMPLATES } from '../../../../common/types/idea-templates/default/index.tsx';
import { useSubmitIdeaFormDescriptionTemplate } from '../../../../controllers/project-properties/index.tsx';
import { getIdeaTemplates } from '../../../../services/polaris-api/idea-templates/index.tsx';
import type { FieldComponentProps } from '../types.tsx';
import { AdfComponent } from './adf/index.tsx';

export const Description = ({
	onUpdate,
	isEditable,
	isPreview,
	shouldUpdateTemplate,
}: FieldComponentProps) => {
	const descriptionTemplateId = useSubmitIdeaFormDescriptionTemplate();
	const descriptionTemplateIdNext = useSubmitIdeaFormDescriptionTemplateProperty();
	const apolloClient = useApolloClient();
	const projectAri = useProjectAriUnsafe();

	const [template, setTemplate] = useState<ADF | undefined>();
	const [isLoading, setLoading] = useState(true);
	const { generalDataLoadingFailedError } = useErrorHandlers();

	const loadDescriptionTemplate = useCallback(() => {
		if (fg('polaris_extract-project-properties')) {
			setLoading(true);
			if (!descriptionTemplateIdNext.value) {
				setLoading(false);
				return;
			}
			getIdeaTemplates(apolloClient, projectAri)
				.then((loadedTemplates) => {
					const templateForIdeaCreation = [...loadedTemplates, ...DEFAULT_TEMPLATES].find(
						({ id }) => id === descriptionTemplateIdNext.value,
					);
					setTemplate(templateForIdeaCreation?.template);
				})
				.catch(generalDataLoadingFailedError)
				.finally(() => setLoading(false));
		} else {
			setLoading(true);
			if (!descriptionTemplateId.value) {
				setLoading(false);
				return;
			}
			getIdeaTemplates(apolloClient, projectAri)
				.then((loadedTemplates) => {
					const templateForIdeaCreation = [...loadedTemplates, ...DEFAULT_TEMPLATES].find(
						({ id }) => id === descriptionTemplateId.value,
					);
					setTemplate(templateForIdeaCreation?.template);
				})
				.catch(generalDataLoadingFailedError)
				.finally(() => setLoading(false));
		}
	}, [
		apolloClient,
		descriptionTemplateId.value,
		descriptionTemplateIdNext.value,
		generalDataLoadingFailedError,
		projectAri,
	]);

	useEffect(() => {
		if (shouldUpdateTemplate) {
			loadDescriptionTemplate();
		}
	}, [shouldUpdateTemplate, loadDescriptionTemplate]);

	useEffect(() => {
		loadDescriptionTemplate();
	}, [descriptionTemplateId.value, descriptionTemplateIdNext.value, loadDescriptionTemplate]);

	return (
		<AdfComponent
			description={template}
			onUpdate={onUpdate}
			isLoadingTemplate={isLoading}
			isDisabled={!isEditable}
			isPreviewInProjectSettings={isPreview}
		/>
	);
};
