import {
	getUserProperty,
	setUserProperties,
} from '@atlassian/jira-common-rest/src/api/latest/user-properties/index.tsx';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import { logSafeErrorWithoutCustomerDataWrapper } from '@atlassian/jira-polaris-lib-errors/src/common/utils/index.tsx';
import { USER_PROPERTY_BY_STATE_KEY } from '../../../constants.tsx';
import type { Props, State } from '../../../types.tsx';

export const loadCrossProjectViewFieldsDismissed =
	(
		viewKindStateKey: keyof typeof USER_PROPERTY_BY_STATE_KEY,
	): Action<State, Props, Promise<boolean | undefined>> =>
	async ({ getState, setState }, { accountId }) => {
		if (!accountId) {
			return;
		}

		// User property is already loaded
		if (typeof getState().crossProjectView[viewKindStateKey] === 'boolean') {
			return;
		}

		const userProperty = USER_PROPERTY_BY_STATE_KEY[viewKindStateKey];

		try {
			const viewfieldsDismissed = await getUserProperty<boolean>(accountId, userProperty);

			const state = getState();

			setState({
				...state,
				crossProjectView: {
					...state.crossProjectView,
					[viewKindStateKey]: viewfieldsDismissed,
				},
			});
		} catch (error) {
			// Expected error, the property is not set yet
			if (error instanceof FetchError && error.statusCode === 404) {
				const state = getState();

				setState({
					...state,
					crossProjectView: {
						...state.crossProjectView,
						[viewKindStateKey]: false,
					},
				});

				return;
			}

			logSafeErrorWithoutCustomerDataWrapper(
				userProperty,
				`Failed to get user property with key: ${userProperty}`,
				error instanceof Error ? error : undefined,
			);
		}
		return getState().crossProjectView[viewKindStateKey];
	};

export const setCrossProjectViewFieldsDismissed =
	(viewKindStateKey: keyof typeof USER_PROPERTY_BY_STATE_KEY): Action<State, Props> =>
	async ({ getState, setState }, { accountId }) => {
		if (!accountId) {
			return;
		}

		const state = getState();

		setState({
			...state,
			crossProjectView: {
				...state.crossProjectView,
				[viewKindStateKey]: true,
			},
		});

		const userProperty = USER_PROPERTY_BY_STATE_KEY[viewKindStateKey];

		try {
			await setUserProperties(accountId, userProperty, 'true');
		} catch (error) {
			logSafeErrorWithoutCustomerDataWrapper(
				userProperty,
				`Failed to set user property with key: ${userProperty}`,
				error instanceof Error ? error : undefined,
			);
		}
	};
