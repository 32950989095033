import {
	getProjectName,
	getProjectId,
	getProjectKey,
	getIsBeta,
	getAccountId,
	isSelfServeEnabled,
	isSiteAdmin,
	getAppEdition,
	getIsEnforcePermissionChecks,
	getOnIssueCreationChanged,
} from './loading-props.tsx';
import { createSelectorHook } from './utils.tsx';

export const useProjectId = createSelectorHook(getProjectId);
export const useProjectName = createSelectorHook(getProjectName);
export const useProjectKey = createSelectorHook(getProjectKey);
export const useIsBeta = createSelectorHook(getIsBeta);
export const useAccountId = createSelectorHook(getAccountId);
export const useSelfServeEnabled = createSelectorHook(isSelfServeEnabled);
export const useIsSiteAdmin = createSelectorHook(isSiteAdmin);
export const useAppEdition = createSelectorHook(getAppEdition);
export const useIsEnforcePermissionChecks = createSelectorHook(getIsEnforcePermissionChecks);
export const useOnIssueCreationChanged = createSelectorHook(getOnIssueCreationChanged);
