import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	addCard: {
		id: 'polaris-ideas.view-content.idea-board-next.column-footer.add-card.non-final',
		defaultMessage: 'Add idea',
		description: 'Label for button to add card to board',
	},
	addCardButtonLabel: {
		id: 'polaris-ideas.view-content.idea-board-next.column-footer.add-card-button-label',
		defaultMessage: 'Add',
		description: 'Label for button to add card to board',
	},
});
