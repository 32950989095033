import {
	FIELD_TYPES,
	type FieldType,
} from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';

export const getHighlightedFieldWidth = (fieldType: FieldType) => {
	switch (fieldType) {
		case FIELD_TYPES.RATING:
			return 80;
		case FIELD_TYPES.DATE:
		case FIELD_TYPES.INTERVAL:
		case FIELD_TYPES.UPDATED:
		case FIELD_TYPES.CREATED:
			return 95;
		case FIELD_TYPES.ATLAS_PROJECT:
			return 130;
		case FIELD_TYPES.REACTIONS:
			return 120;
		case FIELD_TYPES.PEOPLE:
			return 75;
		case FIELD_TYPES.STATUS:
		case FIELD_TYPES.ATLAS_PROJECT_STATUS:
		case FIELD_TYPES.MULTI_SELECT:
			return 80;
		case FIELD_TYPES.TEAM:
			return 100;
		default:
			return 65;
	}
};
