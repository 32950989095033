import {
	B200,
	P200,
	G200,
} from '@atlassian/jira-polaris-lib-color-palette/src/ui/colors/index.tsx';
import type { IdeaTemplate } from '../types.tsx';
import EXPERIMENT_IDEA_TEMPLATE from './experiment-template/index.tsx';
import IDEA_EVALUATION_TEMPLATE from './idea-evaluation/index.tsx';
import OPPORTUNITY_STATEMENT_TEMPLATE from './opportunity-statement/index.tsx';

const OPPORTUNITY_STATEMENT: IdeaTemplate = {
	id: 'OpportunityStatement',
	title: 'Opportunity Statement',
	description: 'Transform customer problems into opportunities to improve people’s experiences',
	template: OPPORTUNITY_STATEMENT_TEMPLATE,
	color: B200,
	emoji: '1f680',
};

const EXPERIMENT_TEMPLATE: IdeaTemplate = {
	id: 'ExperimentTemplate',
	title: 'Experiment',
	description: 'This blueprint helps you capture and prioritise growth experiment ideas',
	template: EXPERIMENT_IDEA_TEMPLATE,
	color: P200,
	emoji: '1f9ea',
};

const IDEA_EVALUATION: IdeaTemplate = {
	id: 'IdeaEvaluation',
	title: 'Idea Evaluation',
	description:
		'Use this to evaluate ideas, assess MVP features, and ensure the product meets customer needs',
	template: IDEA_EVALUATION_TEMPLATE,
	color: G200,
	emoji: '1f9d0',
};

export const DEFAULT_TEMPLATES = [OPPORTUNITY_STATEMENT, EXPERIMENT_TEMPLATE, IDEA_EVALUATION];
