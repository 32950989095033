import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import type { RowProps } from '@atlassian/jira-polaris-lib-list/src/types.tsx';
import { useCanRankIdeas } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useRowOps } from '../../utils/hooks.tsx';
import { Highlighting } from './highlight/index.tsx';
import messages from './messages.tsx';

const DragHandle = () => {
	const { rankingAllowed, rankingDisallowedReason } = useRowOps();
	const { formatMessage } = useIntl();
	const canRankIdea = useCanRankIdeas();

	if (!canRankIdea && fg('jpd-aurora-roadmap-inline-edit')) {
		return null;
	}

	return rankingAllowed ? (
		<Handle
			data-testid="polaris-ideas.ui.view-content.idea-list.row.drag-handle.handle"
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className="drag-handle"
		>
			⠿
		</Handle>
	) : (
		<>
			{rankingDisallowedReason === 'sorted' && (
				<TooltipWrapper>
					<Tooltip content={formatMessage(messages.tooltip)}>
						<Handle
							// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
							className="drag-handle"
							disallowed
							data-testid="polaris-ideas.ui.view-content.idea-list.row.drag-handle.handle"
						>
							⠿
						</Handle>
					</Tooltip>
				</TooltipWrapper>
			)}
		</>
	);
};

export const DragHandleWithHighlight = ({ rowId, isRowHovered = false }: RowProps) => {
	const { rankingAllowed } = useRowOps();
	return (
		<HandleContainer dragAllowed={rankingAllowed}>
			<Highlighting issueId={rowId} isInteractive={isRowHovered} />
			{isRowHovered && <DragHandle />}
		</HandleContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HandleContainer = styled.div<{ dragAllowed?: boolean }>({
	height: '100%',
	width: '100%',
	display: 'flex',
	position: 'relative',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	cursor: ({ dragAllowed }) => !dragAllowed && 'auto',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	"& > div[role='presentation']": {
		height: '100%',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TooltipWrapper = styled.div({
	display: 'flex',
	width: '100%',
	alignItems: 'center',
	flexDirection: 'column',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Handle = styled.div<{ disallowed?: boolean }>({
	margin: 'auto',
	color: token('color.text'),
	font: token('font.body.large'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	cursor: ({ disallowed }) => (disallowed ? 'not-allowed' : 'grab'),
});
