// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State } from '../types.tsx';

export const loadProjectInformation =
	(): Action<State, Props> =>
	async ({ setState }, { projectRemote }) => {
		projectRemote.getOnboardingInformation?.().then((onboardingInformation) => {
			setState({ projectTemplate: onboardingInformation });
		});
	};
