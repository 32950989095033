import type { IssueTypeFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/issue-type/types.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { IssueTypeId } from '@atlassian/jira-shared-types/src/general.tsx';

export type AddCardProps = {
	groupIdentity: string | undefined;
	onBlur: () => void;
	onMoveIssueToColumn: (localIssueId: LocalIssueId, issueTypeId: IssueTypeId) => void;
	fieldKey: FieldKey;
	fieldValue: unknown;
	verticalFieldKey?: FieldKey;
	verticalFieldValue?: unknown;
	verticalGroupIdentity?: string;
};

type OptionBaseProps = {
	label: JSX.Element;
	value: string;
	issueType: IssueTypeFieldValue;
};

export type CreatableOption = OptionBaseProps & {
	type: 'creatable';
};

export type SelectableOption = OptionBaseProps & {
	issueId: LocalIssueId;
	type: 'selectable';
};

export type Option = CreatableOption | SelectableOption;

export const isCreatableOption = (option: Option): option is CreatableOption =>
	option.type === 'creatable';
