// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createStateHook } from '@atlassian/react-sweet-state';
import { ProductSettingsStore } from '../index.tsx';
import type { State } from '../../common/types.tsx';

export const useFinancialYearInitialMonth = createStateHook(ProductSettingsStore, {
	selector: (state: State) =>
		state.productSettings.status === 'loaded'
			? state.productSettings.value.financialYear.initialMonth
			: undefined,
});

export const useFinancialYearInitialMonthLoadingState = createStateHook(ProductSettingsStore, {
	selector: (state: State) => state.productSettings.status,
});
