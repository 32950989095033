import React, { useCallback, useMemo, type SyntheticEvent, type MouseEvent } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import EditorEditIcon from '@atlaskit/icon/core/migration/edit--editor-edit';
import MediaServicesActualSizeIcon from '@atlaskit/icon/core/migration/grow-diagonal--media-services-actual-size';
import { LinkItem } from '@atlaskit/side-navigation';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { shouldOpenInNewTab } from '@atlassian/jira-polaris-common/src/common/utils/events/index.tsx';
import { getIssueLink } from '@atlassian/jira-polaris-common/src/common/utils/issue-link/index.tsx';
import {
	useIsEmbedded,
	useIsSharedView,
} from '@atlassian/jira-polaris-common/src/controllers/environment/index.tsx';
import { useIdeaActions } from '@atlassian/jira-polaris-common/src/controllers/idea/main.tsx';
import {
	useIsIssueArchived,
	useSafeIssueKey,
	useSelectedIssueKey,
	useStringValue,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { useProjectForIssue } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/project/hooks.tsx';
import { usePolarisRouter } from '@atlassian/jira-polaris-common/src/controllers/route/index.tsx';
import { useProjectKeyUnsafe } from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import { useCanEditIssues } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { PolarisTooltip } from '@atlassian/jira-polaris-lib-tooltip/src/ui/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages.tsx';
import { getViewsItemStyles } from './styled.tsx';

type Props = {
	fieldKey: FieldKey;
	localIssueId: LocalIssueId;
	onEdit: () => void;
};

export const SummaryViewField = ({ fieldKey, localIssueId, onEdit }: Props) => {
	const { formatMessage } = useIntl();

	const embedded = useIsEmbedded();

	const unsafeProjectKey = useProjectKeyUnsafe();
	const issueKey = useSafeIssueKey(localIssueId);
	const [canEditIssues] = useCanEditIssues();
	const { openIssueView } = usePolarisRouter();
	const selectedIssueKey = useSelectedIssueKey();
	const value = useStringValue(fieldKey, localIssueId);
	const { closeSummaryEditor } = useIdeaActions();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const isIdeaArchived = useIsIssueArchived(localIssueId);
	const isSharedView = useIsSharedView();

	const projectOnIssue = useProjectForIssue(localIssueId);
	const projectKey = projectOnIssue?.key || unsafeProjectKey;

	const linkUrl = useMemo(
		() =>
			!embedded
				? // eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
					`${window.location.origin}${getIssueLink(projectKey, issueKey, isSharedView)}`
				: undefined,
		[embedded, issueKey, projectKey, isSharedView],
	);

	const fireLinkClickedUIAnalyticsEvent = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({ action: 'clicked', actionSubject: 'link' }),
			'fieldValue',
			{
				issueField: fieldKey,
			},
		);
	}, [createAnalyticsEvent, fieldKey]);

	const onOpenIssueFullscreen = useCallback(
		(e: SyntheticEvent) => {
			e.preventDefault();
			e.stopPropagation();

			if (!issueKey || embedded) return;

			fireLinkClickedUIAnalyticsEvent();

			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			if (shouldOpenInNewTab(e as MouseEvent) && linkUrl) {
				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				window.open(linkUrl, '_blank');
				return;
			}

			closeSummaryEditor();

			openIssueView(issueKey);
		},
		[
			embedded,
			issueKey,
			linkUrl,
			closeSummaryEditor,
			openIssueView,
			fireLinkClickedUIAnalyticsEvent,
		],
	);

	const onEditClick = useCallback(
		(e: SyntheticEvent) => {
			e.preventDefault();
			e.stopPropagation();
			setTimeout(onEdit, 15);
		},
		[onEdit],
	);

	const onOpenIssueSidebar = useCallback(
		(e: SyntheticEvent) => {
			e.preventDefault();

			if (issueKey === undefined) return;

			fireLinkClickedUIAnalyticsEvent();

			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			if (shouldOpenInNewTab(e as MouseEvent) && linkUrl) {
				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				window.open(linkUrl, '_blank');
				return;
			}

			if (issueKey !== selectedIssueKey) {
				openIssueView(issueKey, { layout: 'sidebar' });
			}
		},
		[issueKey, linkUrl, selectedIssueKey, openIssueView, fireLinkClickedUIAnalyticsEvent],
	);

	return (
		<PolarisTooltip hideTooltipOnMouseDown content={value} maxWidth="240px">
			<LinkItem
				data-testid="polaris-ideas.ui.view-content.idea-list.cell.cells.link-field.link-item"
				href={linkUrl}
				// eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis
				cssFn={getViewsItemStyles}
				onClick={onOpenIssueSidebar}
				isDisabled={!issueKey}
				iconAfter={
					<ButtonsWrapper>
						<ButtonsWrapperStart
							// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
							className="buttons-start"
						>
							{canEditIssues && !isIdeaArchived && (
								<Tooltip content={formatMessage(messages.editSummary)}>
									<Button
										testId="polaris-ideas.ui.view-content.idea-list.cell.cells.link-field.edit-summary-button"
										onClick={onEditClick}
										iconAfter={
											<EditorEditIcon
												label={formatMessage(messages.editSummary)}
												LEGACY_size="medium"
												spacing="spacious"
											/>
										}
										appearance="subtle"
									/>
								</Tooltip>
							)}
						</ButtonsWrapperStart>
						<div
							// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
							className="buttons-end"
						>
							<Tooltip content={formatMessage(messages.openIdea)}>
								<Button
									testId="polaris-ideas.ui.view-content.idea-list.cell.cells.link-field.idea-preview-button"
									onClick={onOpenIssueFullscreen}
									iconAfter={
										<MediaServicesActualSizeIcon
											label={formatMessage(messages.openIdea)}
											LEGACY_size="medium"
											spacing="spacious"
										/>
									}
									appearance="subtle"
								/>
							</Tooltip>
						</div>
					</ButtonsWrapper>
				}
			>
				{value}
			</LinkItem>
		</PolarisTooltip>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonsWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	"& > div[role='presentation']": {
		display: 'flex',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonsWrapperStart = styled.div({
	display: 'flex',
});
