import React from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';

import { ModalFooter } from '@atlaskit/modal-dialog';
import Spinner from '@atlaskit/spinner';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';
import type { FooterProps } from './types.tsx';

export const Footer = ({ isDisabled, onSubmit, onCancel, transmitting, testId }: FooterProps) => {
	const { formatMessage } = useIntl();

	return (
		<ModalFooter>
			<Button isDisabled={isDisabled} appearance="primary" onClick={onSubmit}>
				{transmitting && (
					<StyledSpinner>
						<Spinner size="small" testId={testId} />
					</StyledSpinner>
				)}
				{formatMessage(messages.sendFeedback)}
			</Button>

			<Button isDisabled={transmitting} appearance="subtle" onClick={onCancel}>
				{formatMessage(messages.cancel)}
			</Button>
		</ModalFooter>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledSpinner = styled.span({
	marginRight: token('space.100'),
});
