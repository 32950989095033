import React from 'react';
import { fg } from '@atlassian/jira-feature-gating';

import { useIntl } from '@atlassian/jira-intl';
import {
	PolarisEnvironmentContainerTypes,
	useEnvironmentContainer,
} from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import { IssueSelect as IssueSelectCommon } from '@atlassian/jira-polaris-common/src/ui/common/issue-select/main.tsx';
import type { IssueOption } from '@atlassian/jira-polaris-common/src/ui/common/issue-select/types.tsx';
import {
	useDefaultOptions,
	useExcludedProjectTypes,
} from '@atlassian/jira-polaris-common/src/ui/common/issue-select/utils.tsx';
import { messages } from './messages.tsx';
import { useExcludedIssueIds } from './utils.tsx';

type Props = {
	isDisabled: boolean;
	onIssueSelected: (arg1: IssueOption | undefined) => void;
};

export const IssueSelect = ({ isDisabled, onIssueSelected }: Props) => {
	const { formatMessage } = useIntl();
	const defaultOptions = useDefaultOptions();
	const excludedProjectTypes = useExcludedProjectTypes();
	const excludedIssueIds = useExcludedIssueIds();
	const container = useEnvironmentContainer();
	const projectId =
		container?.type === PolarisEnvironmentContainerTypes.PROJECT ? container.projectId : undefined;

	return (
		<IssueSelectCommon
			isAttachedToBody
			placeholder={formatMessage(messages.issueSelectPlaceholderText)}
			isDisabled={isDisabled}
			autoFocus
			projectId={projectId}
			excludedProjectTypes={excludedProjectTypes}
			defaultOptions={defaultOptions}
			hideArchived={!fg('jpd-disable-hiding-archived-ideas-in-issue-picker')}
			excludedIssueIds={excludedIssueIds}
			onIssueSelected={onIssueSelected}
		/>
	);
};
