import React from 'react';
import { styled } from '@compiled/react';
import { BreadcrumbsItem } from '@atlaskit/breadcrumbs';
import { token } from '@atlaskit/tokens';
import { Skeleton } from '@atlassian/jira-common-components-skeleton/src/styled.tsx';

const BreadcrumbsComponent = () => (
	<BreadcrumbSkeletonWrapper>
		<Skeleton height="18px" width="64px" />
	</BreadcrumbSkeletonWrapper>
);

export const BreadcrumbSkeleton = () => (
	// @ts-expect-error - TS2741 - Property 'text' is missing in type '{ component: ForwardRefExoticComponent<RefAttributes<unknown>>; }' but required in type 'BreadcrumbsItemProps'.
	<BreadcrumbsItem component={BreadcrumbsComponent} />
);

export const SkeletonIcon = () => <Skeleton height="16px" width="16px" />;

export const CompactSkeleton = () => <Skeleton height="12px" width="100%" />;

export type ButtonSkeletonProps = {
	width: string;
	className?: string;
};

export const ButtonSkeleton = ({ width, className }: ButtonSkeletonProps) => (
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
	<Skeleton height="32px" width={width} className={className} />
);

export const MenuItemSkeleton = () => (
	<SkeletonMenuItemWrapper>
		<SkeletonMenuItemIconWrapper>
			<Skeleton height="20px" width="20px" />
		</SkeletonMenuItemIconWrapper>
		<SkeletonMenuItemTextWrapper>
			<Skeleton height="20px" width="100%" />
		</SkeletonMenuItemTextWrapper>
	</SkeletonMenuItemWrapper>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BreadcrumbSkeletonWrapper = styled.div({
	height: '24px',
	paddingTop: token('space.050'),
	paddingRight: token('space.050'),
	paddingBottom: token('space.050'),
	paddingLeft: token('space.050'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SkeletonMenuItemWrapper = styled.div({
	paddingTop: token('space.100'),
	paddingBottom: token('space.100'),
	marginLeft: token('space.200'),
	display: 'flex',
	flexDirection: 'row',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SkeletonMenuItemIconWrapper = styled.div({
	flex: '0 0 auto',
	marginRight: token('space.200'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SkeletonMenuItemTextWrapper = styled.div({
	flex: '1 1 auto',
	marginRight: token('space.400'),
});
