import React, { createContext, useContext, useMemo, type PropsWithChildren } from 'react';

type ContextValue = {
	fieldKey: string;
	fieldValue: unknown;
};

const Context = createContext<ContextValue | null>(null);

type Props = PropsWithChildren<ContextValue>;

export const ColumnProvider = ({ children, fieldKey, fieldValue }: Props) => {
	const value = useMemo(() => {
		return {
			fieldKey,
			fieldValue,
		};
	}, [fieldKey, fieldValue]);

	return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useColumn = () => {
	const value = useContext(Context);

	return value;
};
