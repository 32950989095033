import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import type { ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { AvatarUrls } from '@atlassian/jira-shared-types/src/rest/jira/user.tsx';
import { fg } from '@atlassian/jira-feature-gating';

type JiraProjectPartial = {
	id: string;
	key: string;
	name: string;
	avatarUrls: AvatarUrls;
};

export const fetchProject = (projectId: ProjectId): Promise<JiraProjectPartial> =>
	fetchJson(
		`/rest/api/3/project/${projectId}${fg('jpd-trace-fetch-network-call') ? '?operation=getProject' : ''}`,
	);
