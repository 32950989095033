// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State } from '../types.tsx';

export const setProjectError =
	(error: Error): Action<State, Props> =>
	({ getState, setState }) => {
		setState({
			meta: {
				...getState().meta,
				project: {
					...getState().meta.project,
					error,
				},
			},
		});
	};
