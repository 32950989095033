import React from 'react';
import { styled } from '@compiled/react';
import { Radio } from '@atlaskit/radio';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import type { NumericFieldFilterValue } from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import messages from './messages.tsx';

type Props = {
	values: NumericFieldFilterValue[];
	onChange: (value: NumericFieldFilterValue[]) => void;
};

type OperatorVisualisation = 'EQ' | 'NEQ';

export const getOperatorVisualisationType = (
	values: NumericFieldFilterValue[],
): OperatorVisualisation | undefined => {
	if (values.length === 1) {
		if (values[0].operator === 'EQ') {
			return 'EQ';
		}
		if (values[0].operator === 'NEQ') {
			return 'NEQ';
		}
	}
	return undefined;
};

export const CheckboxFilterContentContainer = ({ values, onChange }: Props) => {
	const { formatMessage } = useIntl();

	const vizOp = getOperatorVisualisationType(values);
	const value = values.length > 0 ? values[0].numericValue : 0;
	const isChecked = vizOp === 'EQ' && value === 1;
	const isUnchecked = vizOp !== undefined && !isChecked;
	const singleValueOpChangeHandler = (operator?: string, numericValue?: number) => () =>
		onChange(
			operator !== undefined && numericValue !== undefined
				? [
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						{
							operator,
							numericValue,
						} as NumericFieldFilterValue,
					]
				: [],
		);

	return (
		<ContentContainer>
			<Radio
				name="oppicker"
				value="EQ"
				label={formatMessage(messages.opIsChecked)}
				isChecked={isChecked}
				onChange={singleValueOpChangeHandler('EQ', 1)}
			/>
			<Radio
				name="oppicker"
				value="NEQ"
				label={formatMessage(messages.opIsNotChecked)}
				isChecked={isUnchecked}
				onChange={singleValueOpChangeHandler('NEQ', 1)}
			/>
		</ContentContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContentContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
	width: '180px',
	maxWidth: '180px',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	padding: '14px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> *': {
		marginBottom: token('space.025'),
	},
});
