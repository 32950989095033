import type { QuickStartViewVideoViewKey } from '@atlassian/jira-polaris-lib-quick-start-view-video/src/types.tsx';

export const titleToSupportedQuickstartViewKey: Record<string, QuickStartViewVideoViewKey> = {
	'Všechny nápady': 'ideas',
	'Stanovení priorit': 'prioritization',
	'Roadmapa vedení': 'leadership',
	Dodávání: 'delivery',
	'Roadmapa časové osy': 'timeline',
	'Produktová roadmapa': 'product',
	'Dopad vs. úsilí': 'impact_vs_effort',
	'Kaikki ideat': 'ideas',
	Priorisointi: 'prioritization',
	'Johdon etenemissuunnitelma': 'leadership',
	Toimitus: 'delivery',
	'Aikajanan etenemissuunnitelma': 'timeline',
	'Tuotteen etenemissuunnitelma': 'product',
	'Vaikutus vs. työpanos': 'impact_vs_effort',
	'All ideas': 'ideas',
	Prioritization: 'prioritization',
	'Leadership roadmap': 'leadership',
	Delivery: 'delivery',
	'Timeline roadmap': 'timeline',
	'Product roadmap': 'product',
	'Impact vs Effort': 'impact_vs_effort',
	'Todas as ideias': 'ideas',
	Priorização: 'prioritization',
	'Roteiro da liderança': 'leadership',
	Entrega: 'delivery',
	'Roteiro do cronograma': 'timeline',
	'Roteiro do produto': 'product',
	'Impacto versus esforço': 'impact_vs_effort',
	所有構想: 'ideas',
	優先順序: 'prioritization',
	領導藍圖: 'leadership',
	交付: 'delivery',
	時間軸藍圖: 'timeline',
	產品藍圖: 'product',
	影響力與投入: 'impact_vs_effort',
	'Alle idéer': 'ideas',
	Prioritering: 'prioritization',
	Ledelseskøreplan: 'leadership',
	Levering: 'delivery',
	Tidslinjeoversigt: 'timeline',
	Produktkøreplan: 'product',
	'Effekt vs. indsats': 'impact_vs_effort',
	'Toutes les idées': 'ideas',
	Priorisation: 'prioritization',
	'Feuille de route de la direction': 'leadership',
	Livraison: 'delivery',
	'Feuille de route chronologique': 'timeline',
	'Feuille de route produit': 'product',
	'Impact et effort': 'impact_vs_effort',
	'모든 아이디어': 'ideas',
	'우선 순위 지정': 'prioritization',
	'리더십 로드맵': 'leadership',
	제공: 'delivery',
	'타임라인 로드맵': 'timeline',
	'제품 로드맵': 'product',
	'영향 및 노력 비교': 'impact_vs_effort',
	'Все идеи': 'ideas',
	'Расстановка приоритетов': 'prioritization',
	'Дорожная карта для руководителей': 'leadership',
	Поставка: 'delivery',
	'Дорожная карта в хронологии': 'timeline',
	'Дорожная карта продукта': 'product',
	'Влияние и трудозатраты': 'impact_vs_effort',
	'Alle Ideen': 'ideas',
	Priorisierung: 'prioritization',
	'Führungskräfte-Roadmap': 'leadership',
	Umsetzung: 'delivery',
	'Zeitleisten-Roadmap': 'timeline',
	'Produkt-Roadmap': 'product',
	'Auswirkung im Vergleich zum Aufwand': 'impact_vs_effort',
	'Minden ötlet': 'ideas',
	Rangsorolás: 'prioritization',
	'Vezetői útvonalterv': 'leadership',
	Szállítás: 'delivery',
	'Idővonal útvonalterve': 'timeline',
	Termékútvonalterv: 'product',
	'Hatás és szükséges erőfeszítések': 'impact_vs_effort',
	Lederveikart: 'leadership',
	'Veikart for tidslinje': 'timeline',
	Produktveikart: 'product',
	'Innvirkning kontra innsats': 'impact_vs_effort',
	'Alla idéer': 'ideas',
	Ledarskapsöversikt: 'leadership',
	Leverans: 'delivery',
	'Översikt över tidslinje': 'timeline',
	Produktöversikt: 'product',
	'Påverkan kontra ansträngning': 'impact_vs_effort',
	'Tutte le idee': 'ideas',
	'Definizione delle priorità': 'prioritization',
	'Roadmap per la leadership': 'leadership',
	Consegna: 'delivery',
	'Roadmap della timeline': 'timeline',
	'Roadmap del prodotto': 'product',
	'Impatto vs impegno': 'impact_vs_effort',
	'Alle ideeën': 'ideas',
	Leiderschapsroadmap: 'leadership',
	Tijdlijnroadmap: 'timeline',
	Productroadmap: 'product',
	'Impact versus inspanning': 'impact_vs_effort',
	'Tüm fikirler': 'ideas',
	Önceliklendirme: 'prioritization',
	'Liderlik yol haritası': 'leadership',
	Teslimat: 'delivery',
	'Zaman Çizelgesi Yol Haritası': 'timeline',
	'Ürün Yol Haritası': 'product',
	'Çaba - Etki Karşılaştırması': 'impact_vs_effort',
	'Todas las ideas': 'ideas',
	Priorización: 'prioritization',
	'Hoja de ruta del liderazgo': 'leadership',
	'Hoja de ruta del cronograma': 'timeline',
	'Hoja de ruta del producto': 'product',
	'Impacto frente a esfuerzo': 'impact_vs_effort',
	すべてのアイデア: 'ideas',
	優先順位付け: 'prioritization',
	リーダーシップのロードマップ: 'leadership',
	デリバリー: 'delivery',
	タイムラインのロードマップ: 'timeline',
	製品ロードマップ: 'product',
	影響と労力の比較: 'impact_vs_effort',
	'Wszystkie pomysły': 'ideas',
	'Ustalanie priorytetów': 'prioritization',
	'Harmonogram dla kierownictwa': 'leadership',
	Dostarczanie: 'delivery',
	'Harmonogram z osią czasu': 'timeline',
	'Harmonogram produktu': 'product',
	'Wpływ a nakład pracy': 'impact_vs_effort',
	所有想法: 'ideas',
	优先级: 'prioritization',
	领导层路线图: 'leadership',
	时间线路线图: 'timeline',
	产品路线图: 'product',
	影响力与工作量: 'impact_vs_effort',
};
