import { sendPendoTrackEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/pendo/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';

export const useAnalytics = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	return {
		sendGroupDnDDropEvent: () => {
			fireUIAnalytics(createAnalyticsEvent({}), 'group dragged', 'timeline');
			sendPendoTrackEvent({
				actionSubjectAndAction: 'timelineGroup dropped',
			});
		},
		sendTimelineCardDropEvent: () => {
			fireUIAnalytics(createAnalyticsEvent({}), 'card dragged', 'idea');
			sendPendoTrackEvent({
				actionSubjectAndAction: 'timelineCard dragged',
			});
		},
		sendTimelineCardResizeEvent: () => {
			fireUIAnalytics(createAnalyticsEvent({}), 'card resized', 'idea');
			sendPendoTrackEvent({
				actionSubjectAndAction: 'timelineCard resized',
			});
		},
	};
};
