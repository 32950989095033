// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import { setEnforcePermissionChecksOnSiteEnabled } from '../../../services/jira/toggle-enforce-permission-checks/index.tsx';
import type { State } from '../../types.tsx';

export const toggleEnforcePermissionChecks =
	(): Action<State> =>
	async ({ getState, setState }) => {
		const { features } = getState();
		const { isEnforcePermissionChecksEnabledOnSite } = features;

		setState({
			features: {
				...getState().features,
				isEnforcePermissionChecksEnabledOnSite: !isEnforcePermissionChecksEnabledOnSite,
			},
		});

		setEnforcePermissionChecksOnSiteEnabled(!isEnforcePermissionChecksEnabledOnSite).catch(() => {
			setState({
				features: {
					...getState().features,
					isEnforcePermissionChecksEnabledOnSite,
				},
			});
		});
	};
