import { fg } from '@atlassian/jira-feature-gating';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { IssueType } from '@atlassian/jira-polaris-component-issue-types/src/controllers/types.tsx';
import { logSafeErrorWithoutCustomerDataWrapper } from '@atlassian/jira-polaris-lib-errors/src/common/utils/index.tsx';
import type { JpdJiraSearchApiIssue, JpdJiraSearchApiPublicIssue } from '../types.tsx';
import { translateToPublicIssue, translateIssueTypes } from '../util/index.tsx';
import type { ArchivedFilter } from '../../../common/types.tsx';
import type {
	JpdJiraSearchApiResponseLegacy,
	JpdJiraIssueApiResponse,
	JpdJiraSearchApiResponse,
} from './types.tsx';

const POLARIS_SEARCH_URL = '/rest/polaris/issues/search';
const POLARIS_SEARCH_V3_URL = '/rest/polaris/issues/v3/search';
const POLARIS_ISSUE_URL = '/rest/polaris/issues/get';

type GetJpdIssuesProps = {
	projectIds: string[];
	issueTypeNames?: string[];
	issueIdsOrKeys?: string[];
	fields?: string[];
	archivedFilter?: ArchivedFilter;
	maxResults?: number;
	startAt?: number;
	issueNameSearch?: string;
	include?: {
		projectData?: boolean;
		connections?: boolean;
		metadata?: boolean;
	};
};

const getJpdIssuesLegacy = ({
	projectIds,
	issueIdsOrKeys,
	fields,
	archivedFilter,
	maxResults,
	startAt,
}: GetJpdIssuesProps): Promise<{
	total: number;
	issues: JpdJiraSearchApiPublicIssue[];
}> => {
	const operation = issueIdsOrKeys ? 'getJpdIssuesForIssues' : 'getJpdIssuesForProject';
	const operationParam = fg('jpd-trace-fetch-network-call') ? `?operation=${operation}` : '';
	return fetchJson(`${POLARIS_SEARCH_URL}${operationParam}`, {
		method: 'POST',
		body: JSON.stringify({
			projectId: projectIds[0],
			fields: fg('jpd_issues_relationships')
				? [...new Set([...(fields || []), 'issuelinks'])]
				: fields,
			includeProjectData: true,
			archived: archivedFilter,
			maxResults,
			startAt,
			...(issueIdsOrKeys ? { issueIdsOrKeys } : {}),
			includeAllFields: fg('jpd_issues_relationships'),
		}),
	}).then((response: JpdJiraSearchApiResponseLegacy) => ({
		total: response.issues.length,
		issues: response.issues.map((issue) => translateToPublicIssue(response, issue)),
	}));
};

export const getJpdIssues = ({
	projectIds,
	issueTypeNames,
	issueIdsOrKeys,
	fields,
	archivedFilter,
	maxResults,
	startAt,
	include,
	issueNameSearch,
}: GetJpdIssuesProps): Promise<{
	total: number;
	issues: JpdJiraSearchApiPublicIssue[];
	issueLists?: {
		primary?: IssueId[];
		connections?: IssueId[];
	};
	issueTypes?: Record<string, IssueType>;
}> => {
	if (!fg('jpd_issue_search_v3')) {
		return getJpdIssuesLegacy({
			projectIds,
			issueIdsOrKeys,
			fields,
			archivedFilter,
			maxResults,
			startAt,
		});
	}

	const operation = issueIdsOrKeys ? 'getJpdIssuesForIssues' : 'getJpdIssuesForProject';
	const operationParam = fg('jpd-trace-fetch-network-call') ? `?operation=${operation}` : '';
	let jql = '';

	if (fg('jpd_cross_project_connecting')) {
		if (issueIdsOrKeys) {
			jql = `issue in (${issueIdsOrKeys.join(',')})`;
		} else {
			jql = `project in (${projectIds.join(',')})`;
		}
	} else if (issueIdsOrKeys) {
		jql = `issue in (${issueIdsOrKeys.join(',')})`;
	} else if (projectIds.length > 1) {
		jql = `project in (${projectIds.join(',')})`;
	} else {
		jql = `project = ${projectIds[0]}`;
	}

	if (fg('jpd_cross_project_connecting')) {
		if (issueTypeNames && issueTypeNames.length > 0) {
			jql += ` && issueType in (${issueTypeNames.map((name) => `"${name}"`).join(',')})`;
		}

		if (issueNameSearch) {
			jql += ` && (summary ~ "${issueNameSearch}*" || summary ~ "${issueNameSearch}" || issuekey ~ "${issueNameSearch.toUpperCase()}")`;
		}
	}

	return fetchJson(`${POLARIS_SEARCH_V3_URL}${operationParam}`, {
		method: 'POST',
		body: JSON.stringify({
			jql,
			fields: {
				fieldKeys: fields,
			},
			include: {
				projectData: include?.projectData ?? true,
				archived: archivedFilter,
				metadata: include?.metadata ?? true,
				connections: fg('jpd_issues_relationships') && (include?.connections ?? true),
			},
			pagination: {
				max: maxResults,
			},
		}),
	}).then((response: JpdJiraSearchApiResponse) => ({
		issueTypes: fg('jpd_cross_project_connecting') ? translateIssueTypes(response) : undefined,
		total: response.pagination.total,
		issues: response.issues.map((issue) => translateToPublicIssue(response, issue)),
		issueLists: response.issueLists
			? {
					primary: response.issueLists.primary?.map(String),
					connections: response.issueLists.connections?.map(String),
				}
			: undefined,
	}));
};

export const getJpdIssue = (
	projectId: string,
	issueIdOrKey: string,
	fields: string[],
): Promise<JpdJiraSearchApiPublicIssue> => {
	if (!fg('jpd_issue_search_v3')) {
		return fetchJson(
			`${POLARIS_ISSUE_URL}${fg('jpd-trace-fetch-network-call') ? '?operation=getJpdIssue' : ''}`,
			{
				method: 'POST',
				body: JSON.stringify({
					projectId,
					issueIdOrKey,
					fields: [...new Set([...fields, 'description', 'comment', 'issuelinks', 'project'])],
					includeAllFields: fields.length === 0,
					includeProjectData: true,
				}),
			},
		).then((response: JpdJiraIssueApiResponse) => translateToPublicIssue(response, response.issue));
	}

	// Log error if issue ID or key is invalid: not a number or not in the format of "KEY-123"
	if (!(/^\d+$/.test(issueIdOrKey) || /^[A-Za-z0-9]+-\d+$/.test(issueIdOrKey))) {
		logSafeErrorWithoutCustomerDataWrapper(
			'polaris.remote.issues.services.jira.getJpdIssue.invalidIssueIdOrKey',
			`Invalid issue ID or key: "${issueIdOrKey}"`,
		);
	}

	return fetchJson(
		`${POLARIS_SEARCH_V3_URL}${fg('jpd-trace-fetch-network-call') ? '?operation=getJpdIssue' : ''}`,
		{
			method: 'POST',
			body: JSON.stringify({
				jql: `issue = ${issueIdOrKey}`,
				fields: {
					fieldKeys: [...new Set([...fields, 'description', 'comment', 'issuelinks', 'project'])],
					all: fields.length === 0,
				},
				include: {
					projectData: true,
					metadata: true,
					connections: fg('jpd_issues_relationships'),
					archived: 'ALL',
				},
				pagination: {
					max: 1,
				},
			}),
		},
	).then((response: JpdJiraSearchApiResponse) => {
		if (response.issueLists?.primary !== undefined && response.issueLists.primary.length > 0) {
			const issuesById: { [key: string]: JpdJiraSearchApiIssue } = {};
			response.issues.forEach((issue) => {
				issuesById[issue.id] = issue;
			});

			return translateToPublicIssue(response, issuesById[response.issueLists.primary[0]]);
		}

		throw new FetchError(404);
	});
};
