import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action, StoreActionApi } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types.tsx';
import { updateProjectState } from '../utils.tsx';
import { mergeViews } from './utils.tsx';

const debounceInterval = 2000; // 2 seconds
const throttleInterval = 12000; // 12 seconds ~ 5 times in min

const refreshViewsFunc = async (
	{ setState, getState, dispatch }: StoreActionApi<State>,
	{ navigationRemote, cloudId, projectId, onFailure, onSuccess }: Props,
) => {
	if (!cloudId || !projectId || !navigationRemote || !getState().projects[projectId]?.initialized) {
		return;
	}

	if (
		getState().projects[projectId]?.isRefreshing ||
		getState().projects[projectId]?.numberOfRefreshBlocks > 0
	) {
		setState(
			updateProjectState(getState(), projectId, {
				scheduledRefresh: true,
			}),
		);
		return;
	}

	const isScheduredRefresh = getState().projects[projectId]?.scheduledRefresh;
	const lastRefreshViewsTime = Date.now();

	setState(
		updateProjectState(getState(), projectId, {
			isRefreshing: true,
			initialized: true,
			scheduledRefresh: false,
			refreshError: null,
		}),
	);

	try {
		const views = await navigationRemote.fetch(projectId);

		if (
			getState().projects[projectId]?.lastRefreshBlockTime > lastRefreshViewsTime ||
			getState().projects[projectId]?.numberOfRefreshBlocks > 0
		) {
			onSuccess?.('refreshViews.outdated');
			setState(
				updateProjectState(getState(), projectId, {
					isRefreshing: false,
					scheduledRefresh: true,
				}),
			);
		} else {
			if (isScheduredRefresh) {
				onSuccess?.('refreshViews.scheduled');
			} else {
				onSuccess?.('refreshViews');
			}

			setState(
				updateProjectState(getState(), projectId, {
					isRefreshing: false,
					refreshError: null,
					lastRefreshViewsTime: Date.now(),
					views: mergeViews(getState().projects[projectId].views, views),
				}),
			);
		}

		// next load if scheduled
		if (getState().projects[projectId]?.scheduledRefresh) {
			dispatch(refreshViews());
		}
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		setState(
			updateProjectState(getState(), projectId, {
				isRefreshing: false,
				refreshError: error,
			}),
		);

		onFailure?.(error, 'refreshViews');
	}
};

export const refreshViews = (): Action<State, Props> => async (storeApi, props) => {
	const { getState, setState } = storeApi;
	if (!getState().projects[props.projectId]?.controlledRefresh) {
		const controlledRefresh = throttle(
			debounce(refreshViewsFunc, debounceInterval),
			throttleInterval,
		);
		setState(
			updateProjectState(getState(), props.projectId, {
				controlledRefresh,
			}),
		);
	}
	await getState().projects[props.projectId]?.controlledRefresh?.(storeApi, props);
};
