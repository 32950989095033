import { useCallback } from 'react';
import { ShareServiceClient } from '@atlaskit/share/clients';
import type { MetaData, ProductId } from '@atlaskit/share';
import type { SmartUserPickerWithSuggestionsPrincipal } from '@atlassian/jira-polaris-component-smart-user-picker-with-suggestions/src/ui/index.tsx';
import { useNotifications } from '@atlassian/jira-polaris-lib-notifications/src/controllers/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { principalsToUsers } from './principalsToUsers.tsx';
import { useContent } from './useContent.tsx';
import messages from './messages.tsx';

export const useShare = () => {
	const content = useContent();
	const { error, success } = useNotifications();
	const { formatMessage } = useIntl();

	const onShare = useCallback(
		async (principals: SmartUserPickerWithSuggestionsPrincipal[]) => {
			const shareClient = new ShareServiceClient();

			const recipients = principalsToUsers(principals);
			const productId: ProductId = 'jira-polaris';
			const metaData: MetaData = {
				productId,
				atlOriginId: 'non-empty', // it does not matter in this context
			};

			if (content === null) {
				error({
					title: formatMessage(messages.errorTitle),
					description: formatMessage(messages.errorDescription),
				});
				return;
			}

			await shareClient.share(content, recipients, metaData).then(
				() => {
					success({
						title: formatMessage(messages.successTitle),
						description: formatMessage(messages.successDescription),
					});
				},
				() => {
					error({
						title: formatMessage(messages.errorTitle),
						description: formatMessage(messages.errorDescription),
					});
				},
			);
		},
		[content, error, formatMessage, success],
	);

	return {
		onShare,
	};
};
