import { token } from '@atlaskit/tokens';

type Styles = { string: number | string };

export const stylesConfig = {
	menu: (styles: Styles) => ({
		...styles,
		width: '240px',
		zIndex: 100,
	}),
	menuPortal: (styles: Styles) => ({
		...styles,
		position: 'initial',
	}),
	groupHeading: (styles: Styles) => ({
		...styles,
		':empty': {
			display: 'none',
		},
	}),
	group: (styles: Styles) => ({
		...styles,
		padding: `${token('space.075')} 0 ${token('space.075')} 0`,
		':not(:first-of-type)': {
			borderTop: `1px solid ${token('color.border')}`,
		},
	}),
	control: (styles: Styles) => ({
		...styles,
		width: '350px',
	}),
};
