import { useCallback, useEffect, useRef } from 'react';
import { createSelector } from 'reselect';
import groupBy from 'lodash/groupBy';
import isEqual from 'lodash/isEqual';
import { useApolloClient } from '@apollo/react-hooks';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createActionsHook, createStateHook, createStore } from '@atlassian/react-sweet-state';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { getAriConfig } from '@atlassian/jira-platform-ari/src/index.tsx';
import { useProjectsIdsForContainer } from '@atlassian/jira-polaris-component-project-metadata/src/controllers/index.tsx';
import { useEnvironmentContainerId } from '@atlassian/jira-polaris-component-environment-container/src/controllers/store/index.tsx';
import { useErrorHandlers } from '@atlassian/jira-polaris-lib-errors/src/controllers/index.tsx';
import type { ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import * as actions from './actions.tsx';
import type { State } from './types.tsx';

const initialState: State = {
	insights: undefined,
	loading: false,
	error: undefined,
};

export const Store = createStore({
	initialState,
	actions,
	name: 'PolarisInsightStore',
});

const useActions = createActionsHook(Store);

type LoadInsightsExternalArgs = {
	fireAnalyticsEvent?: boolean;
	archivedOnly?: boolean;
};

export const useInsightsLoader = () => {
	const apolloClient = useApolloClient();
	const { loadInsights } = useActions();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const cloudId = useCloudId();
	const containerId = useEnvironmentContainerId();
	const projectIds = useProjectsIdsForContainer({ containerId });
	const { generalDataLoadingFailedError } = useErrorHandlers();

	return useCallback(
		({ fireAnalyticsEvent = false, archivedOnly = false }: LoadInsightsExternalArgs) => {
			const archivedMode = archivedOnly ? 'ARCHIVED_ONLY' : 'ACTIVE_ONLY';

			loadInsights({
				apolloClient,
				createAnalyticsEvent,
				cloudId,
				fireAnalyticsEvent,
				archivedMode,
				onError: generalDataLoadingFailedError,
				projectIds,
			});
		},
		[
			apolloClient,
			cloudId,
			createAnalyticsEvent,
			loadInsights,
			generalDataLoadingFailedError,
			projectIds,
		],
	);
};

const getInsights = (state: State) => state.insights;

const EMPTY_INSIGHTS = {};

export const getInsightsByIssueId = createSelector(getInsights, (insights) => {
	if (!insights) {
		return EMPTY_INSIGHTS;
	}
	return groupBy(insights || [], (i) =>
		i.container ? getAriConfig(i.container).resourceId : undefined,
	);
});

const getProjectInsights = createSelector(
	getInsights,
	(state: State, projectId: ProjectId) => projectId,
	(insights, projectId) => {
		if (!insights) {
			return [];
		}
		return insights.filter((i) => i.projectId === projectId);
	},
);

const getProjectInsightsCount = createSelector(getProjectInsights, (insights) => insights.length);

export const useInsightsByIssueId = createStateHook(Store, { selector: getInsightsByIssueId });

export const useProjectInsightsCount = createStateHook(Store, {
	selector: getProjectInsightsCount,
});

export const InsightsLoader = ({ fireAnalyticsEvent, archivedOnly }: LoadInsightsExternalArgs) => {
	const projectIdsLoaded = useRef<ProjectId[]>([]);
	const loadInsights = useInsightsLoader();
	const containerId = useEnvironmentContainerId();
	const projectIds = useProjectsIdsForContainer({ containerId });

	useEffect(() => {
		if (!isEqual(projectIdsLoaded.current, projectIds) && projectIds.length) {
			projectIdsLoaded.current = projectIds;
			loadInsights({ fireAnalyticsEvent, archivedOnly });
		}
	}, [archivedOnly, loadInsights, fireAnalyticsEvent, projectIds]);

	return null;
};
