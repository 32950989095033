import React from 'react';
import type { FilterComponentProps } from '../../types.tsx';
import { FilterWrapper } from '../../filter-wrapper/filter-wrapper.tsx';
import { SelectableButton } from './selectable-button.tsx';
import { ConnectionFieldFilterDialogContent } from './connection-field-filter-dialog-content.tsx';
import { SelectionAwareFilterButtonLabel } from './selection-aware-filter-button-label.tsx';
import { SelectionAwareDialog } from './selection-aware-dialog.tsx';

export const ConnectionFilter = ({
	field,
	isLastFilter,
	defaultOpen = false,
	isDisabled = false,
	isUsingCustomColor = false,
}: FilterComponentProps) => {
	return (
		<FilterWrapper>
			<SelectionAwareDialog
				fieldKey={field.key}
				content={<ConnectionFieldFilterDialogContent field={field} />}
				defaultOpen={defaultOpen}
				isDisabled={isDisabled}
				isLastFilter={isLastFilter}
			>
				{({ dialogOpen, toggleDialog }) => {
					return (
						<SelectableButton
							isSelected={dialogOpen}
							onClick={toggleDialog}
							isDisabled={isDisabled}
							isUsingCustomColor={isUsingCustomColor}
						>
							<SelectionAwareFilterButtonLabel field={field} />
						</SelectableButton>
					);
				}}
			</SelectionAwareDialog>
		</FilterWrapper>
	);
};
