/* eslint-disable max-classes-per-file */
import type { FieldValidationError } from '@atlassian/jira-fetch/src/utils.tsx';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { ValidationError } from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { toRankCustomFieldId } from '@atlassian/jira-polaris-domain-field/src/field-types/rank/types.tsx';
import { ASSIGNEE_FIELDKEY } from '@atlassian/jira-polaris-domain-field/src/field/constants.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { toIssueKey, type IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { logSafeErrorWithoutCustomerDataWrapper } from '@atlassian/jira-polaris-lib-errors/src/common/utils/index.tsx';
import { rankIdeaRest } from '../rank-idea-rest/index.tsx';
import type { CreateIdeaResponse } from './types.tsx';

const createIssueApiUrl = (): string => '/rest/api/3/issue';
const getIssueApiUrl = (issueId: string): string => `/rest/api/3/issue/${issueId}`;

export class CreateIssueError extends Error {}
export class RankIssueError extends Error {}
export class FetchIssueError extends Error {}
export class ValidValidationError extends Error {
	isValidValidationError = true;
}

const includesAssigneeError = (errors: FieldValidationError[]) =>
	errors.findIndex((error) => error.field === ASSIGNEE_FIELDKEY) >= 0;

const isValidValidationError = (error: Error) =>
	error instanceof ValidationError && includesAssigneeError(error.errors);

const fetchIssue = (issueId: string) =>
	fetchJson(getIssueApiUrl(issueId)).catch((err) => {
		throw new FetchIssueError(err?.message);
	});

export const createIdeaRest = (
	projectId: string,
	rankCustomFieldId: string | null | undefined,
	issueTypeId: number,
	fieldsMap: Record<FieldKey, unknown>,
	isRankingEnabled: boolean,
	updateLocalIssueIdToJiraId: (jiraIssueId: string) => void,
	rankBeforeIdeaKey?: IssueKey,
) =>
	performPostRequest(createIssueApiUrl(), {
		body: JSON.stringify({
			fields: {
				issuetype: { id: issueTypeId },
				project: { id: projectId },
				...fieldsMap,
			},
		}),
	})
		.then((response: CreateIdeaResponse) => {
			updateLocalIssueIdToJiraId(response.id);
			if (rankBeforeIdeaKey === undefined || !isRankingEnabled) {
				return fetchIssue(response.id).then((issueResponse) => issueResponse);
			}
			return rankIdeaRest(
				[toIssueKey(response.key)],
				rankBeforeIdeaKey,
				undefined,
				rankCustomFieldId != null ? toRankCustomFieldId(rankCustomFieldId) : undefined,
			)
				.then(() => fetchIssue(response.id).then((issueResponse) => issueResponse))
				.catch((err) => {
					logSafeErrorWithoutCustomerDataWrapper(
						'polaris-error.create-idea-rest.error.rank-issue',
						err?.message || 'Empty message',
						err || new Error('Unknown error'),
					);
					throw new RankIssueError(err?.message || 'Unknown error when fetchIssue()');
				});
		})
		.catch((err) => {
			if (isValidValidationError(err)) {
				logSafeErrorWithoutCustomerDataWrapper(
					'polaris-error.create-idea-rest.error.valid-validation',
					err?.message || 'Empty message',
					err || new Error('Unknown error'),
				);

				const [fieldValidationError] = err.errors ?? [];
				throw new ValidValidationError(
					fieldValidationError?.error || err?.message || 'Empty message',
				);
			}

			if (err instanceof ValidationError) {
				logSafeErrorWithoutCustomerDataWrapper(
					'polaris-error.create-idea-rest.error.validation',
					err?.message || 'Empty message',
					err || new Error('Unknown error'),
				);

				const [fieldValidationError] = err.errors ?? [];
				throw new ValidationError(fieldValidationError?.error || err?.message || 'Empty message');
			}

			logSafeErrorWithoutCustomerDataWrapper(
				'polaris-error.create-idea-rest.error.unknown',
				`${err?.name}: ${err?.message || 'Empty message'}`,
				err || new Error('Unknown error'),
			);
			throw new CreateIssueError(err?.message || 'Unknown error when performPostRequest()');
		});
