import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { UpdateRoleRequest } from './types.tsx';

const getUrl = (roleConfigurationId: string, projectId: ProjectId) =>
	`/rest/internal/simplified/1.0/project-configuration/roles/${roleConfigurationId}?projectId=${projectId}`;

export const updateRole = ({
	roleConfigurationId,
	projectId,
	permissions,
	name,
	description,
}: UpdateRoleRequest): Promise<void> =>
	performPutRequest(getUrl(roleConfigurationId, projectId), {
		body: JSON.stringify({
			description,
			name,
			permissions,
		}),
	});
