import React from 'react';
import { Flex, Box, Text, xcss } from '@atlaskit/primitives';
import Image from '@atlaskit/image';
import AsyncTeamProfileCardNext from '@atlassian/jira-profilecard-next/src/ui/team-profilecard/async.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { extractId } from '../utils.tsx';
import type { TeamNameProps, TeamValueProps } from './types.tsx';
import messages from './messages.tsx';

const Team = ({ name, avatarUrl, renderWithInlineMargin }: TeamNameProps) => {
	const { formatMessage } = useIntl();

	return (
		<Flex
			alignItems="center"
			gap="space.075"
			xcss={[teamNameContainerStyles, renderWithInlineMargin && teamNameContainerMarginStyles]}
		>
			{avatarUrl && (
				<Image
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop
					className="hide-from-export"
					src={avatarUrl}
					alt={formatMessage(messages.altText)}
					width={16}
					height={16}
				/>
			)}
			<Text maxLines={1}>{name}</Text>
		</Flex>
	);
};

export const TeamValueRenderer = ({
	id,
	name,
	avatarUrl,
	placeholder,
	displayPreview = false,
	renderWithInlineMargin = true,
}: TeamValueProps) => {
	if (!name && !placeholder) {
		return <Box xcss={emptyStyles} />;
	}

	if (!name) {
		return (
			<Box xcss={placeholderStyles} paddingInline="space.050">
				<Text color="color.text.subtlest">{placeholder}</Text>
			</Box>
		);
	}

	const extractedId = extractId(id);

	if (!displayPreview || !extractedId) {
		return (
			<Team name={name} avatarUrl={avatarUrl} renderWithInlineMargin={renderWithInlineMargin} />
		);
	}

	return (
		<Flex alignItems="center" xcss={teamFieldContainerStyles}>
			<AsyncTeamProfileCardNext
				teamId={extractedId}
				trigger="hover"
				triggerLinkType="none"
				shouldRenderToParent={false}
			>
				<Team name={name} avatarUrl={avatarUrl} renderWithInlineMargin={renderWithInlineMargin} />
			</AsyncTeamProfileCardNext>
		</Flex>
	);
};

const emptyStyles = xcss({
	height: '24px',
});

const placeholderStyles = xcss({
	// we need 5px margin to match label/select/multi-select, so that they align if used together
	// xcss doesn't allow '5px', hence the transparent border
	borderLeft: '1px solid transparent',
	paddingInline: 'space.050',
});

const teamFieldContainerStyles = xcss({
	cursor: 'pointer',
});

const teamNameContainerStyles = xcss({
	color: 'color.text',
	maxWidth: 'fit-content',
	backgroundColor: 'elevation.surface',
	marginBlock: 'space.025',
	paddingInline: 'space.050',
	height: '22px',
	border: '1px solid',
	borderRadius: '4px',
	borderColor: 'color.border',
});

const teamNameContainerMarginStyles = xcss({
	marginInline: 'space.050',
});
