import React, { useState, useEffect } from 'react';
import { TeamValueRenderer } from '@atlassian/jira-polaris-lib-team-value/src/ui/index.tsx';
import { useAllTeamInformationOnIssues } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/team-hooks.tsx';
import type { TeamInformation } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/team.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { NoValueContainer } from '../../../common/board-header-column/styled.tsx';
import messages from './messages.tsx';

type ColumnHeaderComponentProps = {
	groupIdentity: string;
};

const TeamColumnHeader = ({ groupIdentity }: ColumnHeaderComponentProps) => {
	const issueTeamInfos = useAllTeamInformationOnIssues();
	const [teamInfo, setTeamInfo] = useState<TeamInformation[0]>();

	useEffect(() => {
		const teamByGroupIdentity = issueTeamInfos[groupIdentity];
		if (teamByGroupIdentity) {
			setTeamInfo(teamByGroupIdentity);
		}
	}, [groupIdentity, issueTeamInfos]);

	return <TeamValueRenderer name={teamInfo?.name ?? ''} avatarUrl={teamInfo?.avatarUrl} />;
};

const EmptyTeamColumnHeader = () => {
	const { formatMessage } = useIntl();

	return <NoValueContainer>{formatMessage(messages.emptyColumnHeader)}</NoValueContainer>;
};

export const teamColumnHeaderConfig = {
	isHideable: true,
	isDecoratable: false,
	Component: TeamColumnHeader,
	EmptyComponent: EmptyTeamColumnHeader,
} as const;
