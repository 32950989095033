import { addMonths, endOfMonth, endOfQuarter, startOfMonth, startOfQuarter } from 'date-fns';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { ScaleEnum } from '../../../../common/constants.tsx';
import { type GroupId, type Props, type ItemId, NO_VALUE_GROUP_ID } from '../../../../types.tsx';
import { createGetGroupIdByItemIds, getGroupedIds } from '../../../selectors/groups.tsx';
import { createGetItemById, getItems } from '../../../selectors/items.tsx';
import {
	getGroupedItemRows,
	getPrimaryGridInterval,
	getScale,
	getSecondaryGridInterval,
	getStartDate,
	isSorted,
} from '../../../selectors/timeline.tsx';
import type { State } from '../../../types.tsx';
import {
	type UpdatedItem,
	calculateUpdatedItemArrangement,
	middleOfMonthStart,
	middleOfMonthEnd,
	transformToIntervalModel,
} from './utils.tsx';

const calculateGroupIds = (itemUpdate: ItemToUpdate, itemGroupIds: GroupId[]): GroupId[] =>
	(itemUpdate.oldGroupId === itemUpdate.groupId
		? itemGroupIds
		: [itemUpdate.groupId || NO_VALUE_GROUP_ID]
	).filter((groupId) => groupId !== NO_VALUE_GROUP_ID);

type ItemToUpdate = {
	id: ItemId;
	rowIndex: number;
	groupId?: GroupId;
	oldGroupId?: GroupId;
	sizeDiff: number;
	colIndexDiff: number;
};

export const updateItem =
	(itemUpdate: ItemToUpdate, isInNewRow = false) =>
	({ getState }: StoreActionApi<State>, { onItemChange, onArrangementUpdated }: Props) => {
		const state = getState();

		const itemGroupIds = createGetGroupIdByItemIds(itemUpdate.id)(state);
		const currentItem = createGetItemById(itemUpdate.id)(state);
		const areItemsSorted = isSorted(state);
		const primaryGridInterval = getPrimaryGridInterval(state);

		const shouldUpdateItem =
			currentItem &&
			(itemUpdate.sizeDiff !== 0 ||
				itemUpdate.colIndexDiff !== 0 ||
				itemUpdate.groupId !== itemUpdate.oldGroupId);

		const newColIndex = (currentItem?.start || 0) + itemUpdate.colIndexDiff;
		const newSize = (currentItem?.size || primaryGridInterval) + itemUpdate.sizeDiff;

		const secondaryGridInterval = getSecondaryGridInterval(state);
		const scale = getScale(state);
		const timelineStartDate = getStartDate(state);

		// start is divided by 2 because in timeline 2 cells = 1 month
		// Math.floor is used to round down for the case when newColIndex is odd number
		const newStartDate = addMonths(timelineStartDate, Math.floor(newColIndex / 2));

		let startDateStartIntervalFn = middleOfMonthStart;
		let startDateIntervalEndFn = middleOfMonthStart;
		let endDateStartIntervalFn = middleOfMonthEnd;
		let endDateEndIntervalFn = middleOfMonthEnd;
		let startsInTheMiddleOfMonth = false;
		let endsInTheMiddleOfMonth = false;

		// starts on the edge of primary granularity
		if (newColIndex % primaryGridInterval === 0) {
			startDateStartIntervalFn = scale === ScaleEnum.MONTHS ? startOfMonth : startOfQuarter;
			startDateIntervalEndFn = scale === ScaleEnum.MONTHS ? endOfMonth : endOfQuarter;
		} else if (
			// starts on the edge of secondary granularity
			newColIndex % secondaryGridInterval === 0 &&
			scale === ScaleEnum.QUARTERS
		) {
			startDateStartIntervalFn = startOfMonth;
			startDateIntervalEndFn = endOfMonth;
		} else {
			startsInTheMiddleOfMonth = true;
		}

		const startDateIntervalStart = startDateStartIntervalFn(newStartDate);
		const startDateIntervalEnd = startDateIntervalEndFn(newStartDate);

		// ends on the edge of primary granularity
		if ((newSize + newColIndex) % primaryGridInterval === 0) {
			endDateStartIntervalFn = scale === ScaleEnum.MONTHS ? startOfMonth : startOfQuarter;
			endDateEndIntervalFn = scale === ScaleEnum.MONTHS ? endOfMonth : endOfQuarter;
		} else if (
			// ends on the edge of secondary granularity
			(newSize + newColIndex) % secondaryGridInterval === 0 &&
			scale === ScaleEnum.QUARTERS
		) {
			endDateStartIntervalFn = startOfMonth;
			endDateEndIntervalFn = endOfMonth;
		} else {
			endsInTheMiddleOfMonth = true;
		}

		// Size is divided by 2 because in timeline 2 cells = 1 month
		// In order to not include the following month:
		// - for full months we need to subtract 1
		// - for mid-month we don't need to subtract because item.size / 2 will be fractional (e.g. 1.5) and will be rounded down inside addMonths
		const newEndDate = addMonths(
			startDateIntervalStart,
			newSize / 2 - (startsInTheMiddleOfMonth || endsInTheMiddleOfMonth ? 0 : 1),
		);

		const endDateIntervalStart = endDateStartIntervalFn(newEndDate);
		const endDateIntervalEnd = endDateEndIntervalFn(newEndDate);

		if (onItemChange && (!currentItem || shouldUpdateItem)) {
			onItemChange({
				id: itemUpdate.id,
				startDateInterval: {
					value: transformToIntervalModel(startDateIntervalStart, startDateIntervalEnd),
					label: currentItem?.startLabel,
					isExternal: currentItem?.isStartExternal,
				},
				endDateInterval: {
					value: transformToIntervalModel(endDateIntervalStart, endDateIntervalEnd),
					label: currentItem?.endLabel,
					isExternal: currentItem?.isEndExternal,
				},
				...(itemUpdate.groupId !== itemUpdate.oldGroupId && {
					newGroupIds: calculateGroupIds(itemUpdate, itemGroupIds),
					oldGroupId: itemUpdate.oldGroupId,
				}),
			});
		}

		if (areItemsSorted) {
			// the request for updating arrangement should not be sent while sorting applied
			return;
		}

		const updatedItem: UpdatedItem = {
			id: itemUpdate.id,
			rowIndex: itemUpdate.rowIndex,
			colIndex: newColIndex,
			size: newSize,
			groupId: itemUpdate.groupId,
		};

		const newArrangement = calculateUpdatedItemArrangement(
			updatedItem,
			isInNewRow,
			getGroupedItemRows(state),
			getItems(state),
			getGroupedIds(state),
		);
		onArrangementUpdated(newArrangement);
	};
