import keyBy from 'lodash/keyBy';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import { getRoleDetails } from '../../../services/jira/get-role-details/index.tsx';
import type { RoleDetailsResponse } from '../../../services/jira/get-role-details/types.tsx';
import { getRoles } from '../../../services/jira/get-roles/index.tsx';
import type { RolesResponse } from '../../../services/jira/get-roles/types.tsx';
import type { State, Props } from '../../types.tsx';

export const loadRoles =
	(): Action<State, Props> =>
	({ getState, setState }, { projectId, onError, formatMessage }) => {
		Promise.all([getRoles(projectId), getRoleDetails(projectId, formatMessage)])
			.then(([rolesResponse, roleDetailsResponse]: [RolesResponse, RoleDetailsResponse]) => {
				const oldState = getState();

				const roleDetailsMap = keyBy(roleDetailsResponse, 'name');
				const roles = rolesResponse.roles.map((role) => {
					const roleDetails = roleDetailsMap[role.name];
					return {
						id: roleDetails.id,
						name: role.name,
						translatedName: roleDetails.translatedName,
						description: roleDetails.description,
						roleConfigurationId: role.roleId,
						roleOrigin: role.roleOrigin,
						permissions: role.permissions,
						allowedOperations: role.allowedOperations,
					};
				});

				setState({
					...oldState,
					roles,
				});
			})
			.catch((err) => onError(err));
	};
