import React from 'react';
import { cssMap } from '@compiled/react';
import { cx } from '@atlaskit/css';
import { Inline, Pressable, type PressableProps } from '@atlaskit/primitives/compiled';
import Tooltip from '@atlaskit/tooltip';
import { token } from '@atlaskit/tokens';

type BarActionButtonProps = PressableProps & {
	spacing?: 'none';
	iconBefore: React.ReactNode;
	tooltipMessage?: string;
};

const styles = cssMap({
	common: {
		font: token('font.body'),
		fontWeight: token('font.weight.medium'),
		backgroundColor: 'transparent',
		borderRadius: token('border.radius.050'),
		color: token('color.text.inverse'),
		paddingBlock: token('space.0'),
		paddingInline: token('space.0'),
	},
	interactive: {
		'&:hover': {
			backgroundColor: token('color.background.neutral.bold.hovered'),
		},
		'&:active': {
			backgroundColor: token('color.background.neutral.bold.pressed'),
		},
	},
	disabled: {
		color: token('color.text.subtlest'),
	},
	spacing: {
		paddingInline: token('space.100'),
		height: '32px',
	},
});

export const BarActionButton = ({
	children,
	iconBefore,
	spacing,
	tooltipMessage,
	...props
}: BarActionButtonProps) => (
	<Tooltip content={tooltipMessage}>
		{(tooltipProps) => (
			<Pressable
				{...tooltipProps}
				{...props}
				xcss={cx(
					styles.common,
					props.isDisabled ? styles.disabled : styles.interactive,
					spacing !== 'none' && styles.spacing,
				)}
			>
				<Inline alignBlock="center" space="space.050">
					{iconBefore}
					{children}
				</Inline>
			</Pressable>
		)}
	</Tooltip>
);
