// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import type { State, Props, ProjectActorsFilter } from '../../types.tsx';
import { loadProjectActors } from '../load-project-actors/index.tsx';

export const updateProjectActorsFilter =
	({ search, roles }: { search?: string; roles?: number[] }): Action<State, Props> =>
	({ getState, setState, dispatch }) => {
		const currentState = getState();

		const currentFilter = currentState.projectActors.filter;

		const newFilterProps: ProjectActorsFilter = {
			orderBy: currentFilter.orderBy,
		};

		if (search !== undefined && search.length > 0) {
			newFilterProps.search = {
				contains: search,
			};
		}

		if (roles !== undefined && roles.length > 0) {
			newFilterProps.roles = {
				ids: roles,
			};
		}

		setState({
			...currentState,
			projectActors: {
				...currentState.projectActors,
				filter: {
					...newFilterProps,
				},
			},
		});

		dispatch(loadProjectActors());
	};
