import filter from 'lodash/filter';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import { deleteRole as remoteDeleteRole } from '../../../services/jira/delete-role/index.tsx';
import { moveRole } from '../../../services/jira/move-role/index.tsx';
import { getRole } from '../../selectors/roles.tsx';
import type { State, Props, RoleConfigurationId } from '../../types.tsx';

export const deleteRole =
	(
		roleConfigurationId: RoleConfigurationId,
		nextRoleConfigurationId: RoleConfigurationId | undefined,
	): Action<State, Props> =>
	({ getState, setState }, { projectId, onError }) => {
		const destRole = getRole(nextRoleConfigurationId)(getState());
		const sourceRole = getRole(roleConfigurationId)(getState());

		const newState = {
			...getState(),
			roles: filter(getState().roles, (role) => role.roleConfigurationId !== roleConfigurationId),
		};

		if (sourceRole !== undefined && destRole !== undefined) {
			moveRole({
				projectId,
				sourceProjectRoleId: sourceRole.roleConfigurationId,
				destProjectRoleId: destRole.roleConfigurationId,
			})
				.then(() =>
					remoteDeleteRole(roleConfigurationId, projectId)
						.then(() => {
							setState({
								...newState,
								projectActors: {
									...newState.projectActors,
									data: newState.projectActors.data
										? {
												...newState.projectActors.data,
												actors: newState.projectActors.data.actors.map((actor) => ({
													...actor,
													roles: [
														...actor.roles.map((id) => (id === sourceRole.id ? destRole.id : id)),
													],
												})),
											}
										: undefined,
								},
							});
						})
						.catch((err) => onError(err)),
				)
				.catch((err) => onError(err));
		} else {
			remoteDeleteRole(roleConfigurationId, projectId)
				.then(() => {
					setState({
						...newState,
					});
				})
				.catch((err) => onError(err));
		}
	};
