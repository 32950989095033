import { memo, useEffect, useRef } from 'react';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { createFrameCapturer } from './frame-capturer.tsx';
import { createScrollDataCollector } from './scroll-data-collector.tsx';
import { processScrollingData, type Measures } from './process-scrolling-data.tsx';

const bufferingInterval = 1000 * 60 * 1; // 1 minute

// After that many ms without consecutive scroll events
// we assume the user is no longer scrolling the view
const scrollStopThresholdTime = 100;

type OnMeasuresCollected = (measures: Measures) => void;

type ObserverProps = {
	scrollingContainer: HTMLElement;
	onMeasuresCollected: OnMeasuresCollected;
};

export const ScrollObserver = memo<ObserverProps>(
	({ scrollingContainer, onMeasuresCollected }: ObserverProps) => {
		const { createAnalyticsEvent } = useAnalyticsEvents();
		// We use ref here to not have to put onMeasuresCollected as a dependency
		// for the useEffect below. That way the scrollDataCollector callback
		// will use always the latest onMeasuresCollected callback value.
		const onMeasuresCollectedRef = useRef(onMeasuresCollected);
		onMeasuresCollectedRef.current = onMeasuresCollected;

		useEffect(() => {
			const frameCapturer = createFrameCapturer();
			const scrollDataCollector = createScrollDataCollector((scrolls) => {
				const result = processScrollingData(scrolls);
				if (result === null) {
					return;
				}
				onMeasuresCollectedRef.current(result);
			}, bufferingInterval);

			let scrollTimeoutId: ReturnType<typeof setTimeout>;
			const handleScroll = () => {
				try {
					if (frameCapturer.isIdle()) {
						frameCapturer.startCapturingFrames();
					}

					clearTimeout(scrollTimeoutId);
					scrollTimeoutId = setTimeout(() => {
						try {
							scrollDataCollector.collect(frameCapturer.stopCapturingFrames());
						} catch (error) {
							fireErrorAnalytics({
								meta: {
									id: 'polaris.scroll-observer.stop',
									teamName: 'JPD - Pulsar',
								},
								error: error instanceof Error ? error : new Error('Unexpected error'),
								sendToPrivacyUnsafeSplunk: true,
							});
						}
					}, scrollStopThresholdTime);
				} catch (error) {
					fireErrorAnalytics({
						meta: {
							id: 'polaris.scroll-observer.start',
							teamName: 'JPD - Pulsar',
						},
						error: error instanceof Error ? error : new Error('Unexpected error'),
						sendToPrivacyUnsafeSplunk: true,
					});
				}
			};

			scrollingContainer.addEventListener('scroll', handleScroll);

			return () => {
				clearTimeout(scrollTimeoutId);
				scrollingContainer.removeEventListener('scroll', handleScroll);

				scrollDataCollector.dispose();
				frameCapturer.dispose();
			};
		}, [scrollingContainer, createAnalyticsEvent]);

		return null;
	},
);
