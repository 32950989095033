import { setUserProperties } from '@atlassian/jira-common-rest/src/api/latest/user-properties/index.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import { logSafeErrorWithoutCustomerDataWrapper } from '@atlassian/jira-polaris-lib-errors/src/common/utils/index.tsx';
import type { Props, State } from '../../types.tsx';

export const saveProperty =
	(propertyKey: string, value: unknown, shouldRevertOnFail = true): Action<State, Props> =>
	({ setState, getState }, { accountId }) => {
		if (!accountId) {
			return;
		}

		const oldValue = getState().properties[propertyKey]?.value;

		setState({
			properties: {
				...getState().properties,
				[propertyKey]: {
					value,
					isLoading: false,
					error: null,
				},
			},
		});

		setUserProperties(accountId, propertyKey, JSON.stringify(value) || '').catch((error) => {
			logSafeErrorWithoutCustomerDataWrapper(
				'polaris.user-properties-error',
				`Failed to save user property with key: ${propertyKey}`,
				error,
			);
			if (shouldRevertOnFail) {
				setState({
					// @ts-expect-error - TS2322 - Type '{ [x: string]: UserPropertyState | { value: unknown; } | undefined; }' is not assignable to type '{ [userPropertyKey: string]: UserPropertyState | undefined; }'.
					properties: {
						...getState().properties,
						[propertyKey]: {
							value: oldValue,
						},
					},
				});
			} else {
				setState({
					properties: {
						...getState().properties,
						[propertyKey]: {
							value,
							isLoading: false,
							error,
						},
					},
				});
			}
		});
	};
