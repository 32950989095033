import type { SmartUserPickerWithSuggestionsPrincipal } from '@atlassian/jira-polaris-component-smart-user-picker-with-suggestions/src/ui/index.tsx';

type UserWithEmail = {
	email: string;
	name: string;
};

export const principalsToUsersWithEmail = (
	principals: SmartUserPickerWithSuggestionsPrincipal[],
): UserWithEmail[] =>
	principals
		.map((principal) => {
			switch (principal.type) {
				case 'email': {
					return { email: principal.id, name: principal.name };
				}
				default: {
					return { email: principal.email, name: principal.name };
				}
			}
		})
		.filter((user): user is UserWithEmail => user.email !== undefined);
