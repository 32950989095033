import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	addIdeaButton: {
		id: 'polaris-ideas.view-controls.add-idea-button.add-idea-button',
		defaultMessage: 'Create an idea',
		description: 'Button for creating an idea',
	},
	createIdeaButton: {
		id: 'polaris-ideas.view-controls.add-idea-button.create-idea-button',
		defaultMessage: 'Create',
		description: 'Button for creating an idea',
	},
});
