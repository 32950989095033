import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	badge: {
		id: 'polaris-components.view-header.premium-eap',
		// eslint-disable-next-line @atlassian/tangerine/i18n/messages-are-spelled-correctly
		defaultMessage: 'Premium EAP',
		description: 'Premium early access program badge in the view header',
	},
	tooltip: {
		id: 'polaris-components.view-header.premium-eap.tooltip',
		defaultMessage:
			// eslint-disable-next-line @atlassian/tangerine/i18n/messages-are-spelled-correctly
			'You are using features from the Early Access Program (EAP) for Jira Product Discovery Premium',
		description: 'Tooltip for premium early access program badge in the view header',
	},
});
