/** @jsx jsx */
import React, { type MouseEvent, type KeyboardEvent } from 'react';
import { css, jsx } from '@compiled/react';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import { DragHandleButtonSmall } from '@atlaskit/pragmatic-drag-and-drop-react-accessibility/drag-handle-button-small';
import { Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { DragHandleThin } from '@atlassian/jira-polaris-lib-custom-icons/src/ui/icons/drag-handle-thin/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import messages from './messages.tsx';

export type ReorderAction = 'MOVE_TO_TOP' | 'MOVE_UP' | 'MOVE_DOWN' | 'MOVE_TO_BOTTOM';

export type ItemPosition = 'FIRST' | 'LAST' | 'MIDDLE';

type DragHandleProps = {
	position: ItemPosition;
	onReorder: (reorderAction: ReorderAction) => void;
};

export const DragHandle = (props: DragHandleProps) => {
	const { position, onReorder } = props;
	const { formatMessage } = useIntl();

	const isMoveUpDisabled = position === 'FIRST';
	const isMoveDownDisabled = position === 'LAST';

	return (
		<Box
			onClick={(event: MouseEvent) => event.stopPropagation()}
			onKeyDown={(event: KeyboardEvent) => event.stopPropagation()}
		>
			<DropdownMenu<HTMLButtonElement>
				trigger={({ triggerRef, ...triggerProps }) =>
					isVisualRefreshEnabled() ? (
						<button {...triggerProps} ref={triggerRef} css={draggableButtonStyles}>
							<div css={draggableWrapperStyles}>
								<DragHandleThin
									label={formatMessage(messages.reorderLabel)}
									color={token('color.icon.subtle')}
								/>
							</div>
						</button>
					) : (
						<DragHandleButtonSmall
							appearance="subtle"
							ref={triggerRef}
							{...triggerProps}
							type="button"
							label={formatMessage(messages.reorderLabel)}
						/>
					)
				}
			>
				<DropdownItemGroup>
					<DropdownItemGroup>
						<DropdownItem isDisabled={isMoveUpDisabled} onClick={() => onReorder('MOVE_TO_TOP')}>
							{formatMessage(messages.moveToTop)}
						</DropdownItem>
						<DropdownItem isDisabled={isMoveUpDisabled} onClick={() => onReorder('MOVE_UP')}>
							{formatMessage(messages.moveUp)}
						</DropdownItem>
						<DropdownItem isDisabled={isMoveDownDisabled} onClick={() => onReorder('MOVE_DOWN')}>
							{formatMessage(messages.moveDown)}
						</DropdownItem>
						<DropdownItem
							isDisabled={isMoveDownDisabled}
							onClick={() => onReorder('MOVE_TO_BOTTOM')}
						>
							{formatMessage(messages.moveToBottom)}
						</DropdownItem>
					</DropdownItemGroup>
				</DropdownItemGroup>
			</DropdownMenu>
		</Box>
	);
};

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { DragHandleMock } from './mocks';

const draggableButtonStyles = css({
	border: 0,
	padding: 0,
	background: 'none',
});

const draggableWrapperStyles = css({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	paddingInlineStart: '1px',
	paddingInlineEnd: token('space.025'),
});
