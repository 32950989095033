import noop from 'lodash/noop';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { useCanEditCollection } from '@atlassian/jira-polaris-component-collections/src/controllers/combined-selectors.tsx';
import { useEnvironmentContainer } from '@atlassian/jira-polaris-component-environment-container/src/controllers/store/index.tsx';
import { useIsViewPermissionsEnabled } from '@atlassian/jira-polaris-lib-entitlement-utils/src/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createHook } from '@atlassian/react-sweet-state';
import { fg } from '@atlassian/jira-feature-gating';
import type { ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import { PermissionsStore, usePermissions, usePermissionActions } from '../main.tsx';
import type { Permission } from '../../../services/load-permissions/types.tsx';

const COLLECTION_VIEW_FORBIDDEN_PERMISSION_KEYS = ['CREATE_ISSUES', 'ADMINISTER_PROJECTS'];

// if ommitted, Permission is true for all projects
type Options =
	| {
			// Permission is true for a specific project
			projectId?: ProjectId;
	  }
	| {
			// Permission is true for a specific projects
			projectIds?: ProjectId[];
	  }
	| {
			// Permission is true for at least one project (in multi project context)
			anyProject?: boolean;
	  };

export const useIsEmbeddedView = createHook(PermissionsStore, {
	selector: (state) => state.meta.loadingProps?.isEmbeddedView || false,
});

export const useIsSharedView = createHook(PermissionsStore, {
	selector: (state) => state.meta.loadingProps?.isSharedView || false,
});

export const useIsCollectionView = createHook(PermissionsStore, {
	selector: (state) => state.meta.loadingProps?.isCollectionView || false,
});

export const usePermissionHook = createHook(PermissionsStore, {
	selector: (
		state,
		{
			permissionKey,
			projectId,
			projectIds,
			anyProject,
		}: {
			permissionKey: string;
			projectId?: ProjectId;
			projectIds?: ProjectId[];
			anyProject?: boolean;
		},
	) => {
		if (!fg('jpd-aurora-roadmap-inline-edit')) {
			const permissions = state.isPreviewContributorMode
				? state.contributorPermissions
				: state.permissions;
			return permissions.some((p) => p.key === permissionKey && p.havePermission === true);
		}

		let allPermissions: Permission[][];

		if (state.isPreviewContributorMode) {
			allPermissions = [state.contributorPermissions];
		} else if (projectId) {
			allPermissions = [state.permissionsByProjectId[projectId] ?? []];
		} else if (projectIds) {
			allPermissions = projectIds.map((id) => state.permissionsByProjectId[id] ?? []);
		} else {
			allPermissions = Object.values(state.permissionsByProjectId);
		}

		const checkMethod = anyProject ? 'some' : 'every';

		return (
			allPermissions.flat().length > 0 &&
			allPermissions[checkMethod]((permissions) =>
				permissions.some((p) => p.key === permissionKey && p.havePermission === true),
			)
		);
	},
});

export const createPermissionHook =
	(permissionKey: string, availableInSharedView = false) =>
	(options?: Options) => {
		const [val] = usePermissionHook({ ...options, permissionKey });
		const [sEmbeddedView] = useIsEmbeddedView();
		const [isSharedView] = useIsSharedView();

		const [isCollectionView] = useIsCollectionView();

		// in a collection view, certain permissions are not available.
		if (isCollectionView && COLLECTION_VIEW_FORBIDDEN_PERMISSION_KEYS.includes(permissionKey)) {
			return [false];
		}

		if (
			isCollectionView &&
			permissionKey === 'MANAGE_DISCOVERY_VIEWS' &&
			getWillShowNav4() &&
			fg('jpd-sidebar-v4-for-roadmaps')
		) {
			// This is done to be in sync with `useCanManageViews` hook
			// The functionality will be refactored & unified in https://pi-dev-sandbox.atlassian.net/browse/POL-12935
			return [true];
		}

		if (isSharedView && !availableInSharedView) {
			return [false];
		}

		return [!sEmbeddedView && val];
	};

const useIsInContributorPreviewMode = createHook(PermissionsStore, {
	selector: (state) => state.isPreviewContributorMode,
});

export const useIsSiteAdmin = () => {
	const { isSiteAdmin } = useTenantContext();
	const [isInContributorPreviewMode] = useIsInContributorPreviewMode();
	return isSiteAdmin && !isInContributorPreviewMode;
};

export const useIsAdmin = () => {
	const { isAdmin } = useTenantContext() ?? {};
	const [isInContributorPreviewMode] = useIsInContributorPreviewMode();
	return isAdmin && !isInContributorPreviewMode;
};

export const useIsLoadingPermissions = createHook(PermissionsStore, {
	selector: (state) => state.meta.loading,
});

export const useIsLoadedPermissions = createHook(PermissionsStore, {
	selector: (state) => state.meta.loaded,
});

const useProjectPermissionsLength = createHook(PermissionsStore, {
	selector: (state, projectId?: ProjectId) => {
		if (!fg('jpd-aurora-roadmap-inline-edit')) {
			return state.permissions.length;
		}
		return projectId
			? state.permissionsByProjectId[projectId]?.length ?? 0
			: Object.values(state.permissionsByProjectId).flat().length;
	},
});

export const useIsProjectAdmin = createPermissionHook('ADMINISTER_PROJECTS');

export const useCanCreateIssues = createPermissionHook('CREATE_ISSUES');
export const useCanEditIssues = createPermissionHook('EDIT_ISSUES');
export const useCanDeleteIssues = createPermissionHook('DELETE_ISSUES');
export const useCanMoveIssues = createPermissionHook('MOVE_ISSUES');
export const useCanAddComments = createPermissionHook('ADD_COMMENTS', true);
export const useHasEditOwnCommentsPermission = createPermissionHook('EDIT_OWN_COMMENTS', true);
export const useHasEditAllCommentsPermission = createPermissionHook('EDIT_ALL_COMMENTS');
export const useCanModifyReporter = createPermissionHook('MODIFY_REPORTER');

export const useCanManageWatchers = createPermissionHook('MANAGE_WATCHERS');
export const useCanViewWatchers = createPermissionHook('VIEW_VOTERS_AND_WATCHERS');

export const useCanAddAttachments = createPermissionHook('CREATE_ATTACHMENTS');
export const useCanDeleteOwnAttachments = createPermissionHook('DELETE_OWN_ATTACHMENTS');
export const useCanDeleteAllAttachments = createPermissionHook('DELETE_ALL_ATTACHMENTS');
export const useCanMakeBulkChanges = createPermissionHook('BULK_CHANGE');

const useHasLinkIssuesPermission = createPermissionHook('LINK_ISSUES');
const useCanScheduleIssues = createPermissionHook('SCHEDULE_ISSUES');
const useHasManageDeliveryPermission = createPermissionHook('MANAGE_DISCOVERY_DELIVERY');
const useHasMergeIdeasPermission = createPermissionHook('MERGE_DISCOVERY_ISSUES');
const useHasArchiveIdeasPermission = createPermissionHook('ARCHIVE_DISCOVERY_ISSUES');
const useHasVotePermission = createPermissionHook('DISCOVERY_VOTE');
const useHasCreateInsightsPermission = createPermissionHook('CREATE_DISCOVERY_INSIGHTS');
const useHasManageInsightsPermission = createPermissionHook('MANAGE_DISCOVERY_INSIGHTS');

const useHasManageViewsPermission = createPermissionHook('MANAGE_DISCOVERY_VIEWS');

const useHasPublishDiscoveryViewsPermission = createPermissionHook('PUBLISH_DISCOVERY_VIEWS');
const useHasPublishPublicDiscoveryViewsPermission = createPermissionHook(
	'PUBLISH_PUBLIC_DISCOVERY_VIEWS',
);

const useHasExportViewsPermission = createPermissionHook('SHARE_DISCOVERY_VIEWS');
const useHasCreateViewCommentsPermission = createPermissionHook('CREATE_DISCOVERY_VIEW_COMMENTS');
const useHasManageViewCommentsPermission = createPermissionHook('MANAGE_DISCOVERY_VIEW_COMMENTS');
const useHasManageIdeaTemplatesPermission = createPermissionHook('MANAGE_DISCOVERY_IDEA_TEMPLATES');
const useHasPinIssueViewFieldsPermission = createPermissionHook('PIN_DISCOVERY_ISSUE_VIEW_FIELDS');

export const useCanCreateAndEditIssues = (options?: Options): boolean => {
	const [canCreateIssues] = useCanCreateIssues(options);
	const [canEditIssues] = useCanEditIssues(options);
	return canCreateIssues && canEditIssues;
};

export const useCanLinkIssues = (options?: Options): boolean => {
	const [hasLinkIssuesPermission] = useHasLinkIssuesPermission(options);
	const [canEditIssues] = useCanEditIssues(options);
	return hasLinkIssuesPermission && canEditIssues;
};

export const useCanConnectIssues = (options?: Options): boolean => {
	const canLinkIssues = useCanLinkIssues(options);
	return canLinkIssues;
};

export const useCanPinIssueViewFields = (options?: Options): boolean => {
	const [hasPinIsssueViewFieldsPermission] = useHasPinIssueViewFieldsPermission(options);
	const [isCollectionView] = useIsCollectionView();
	return !isCollectionView && hasPinIsssueViewFieldsPermission;
};

export const useCanEditOwnComments = (options?: Options): boolean => {
	const [hasEditOwnCommentsPermission] = useHasEditOwnCommentsPermission(options);
	return hasEditOwnCommentsPermission;
};

export const useCanEditAllComments = (options?: Options): boolean => {
	const [hasEditAllCommentsPermission] = useHasEditAllCommentsPermission(options);
	return hasEditAllCommentsPermission;
};

export const useCanAddAndEditOwnViewComments = (options?: Options): boolean => {
	const [hasCreateViewComments] = useHasCreateViewCommentsPermission(options);
	return hasCreateViewComments;
};

export const useCanEditAllViewComments = (options?: Options): boolean => {
	const [hasManageViewCommentsPermission] = useHasManageViewCommentsPermission(options);
	return hasManageViewCommentsPermission;
};

export const useCanRankIdeas = (options?: Options): boolean => {
	const [canEditIssues] = useCanEditIssues(options);
	const [canScheduleIssues] = useCanScheduleIssues(options);
	const [isCollectionView] = useIsCollectionView();
	return (
		(!fg('jpd-aurora-roadmap-inline-edit') || !isCollectionView) &&
		canEditIssues &&
		canScheduleIssues
	);
};

export const useCanEditFields = (options?: Options): boolean => {
	const [isProjectAdmin] = useIsProjectAdmin(options);

	const [isCollectionView] = useIsCollectionView();
	const collectionUUID = useEnvironmentContainer()?.id ?? '';
	const canEditCollection = useCanEditCollection({
		collectionUUID,
		forceLoading: isCollectionView,
	});

	return isCollectionView ? canEditCollection : isProjectAdmin;
};

export const useCanCreateFields = (options?: Options): boolean => {
	const canEditFields = useCanEditFields(options);
	const [isCollectionView] = useIsCollectionView();
	return canEditFields && !isCollectionView;
};

export const useCanManageGlobalFields = (): boolean => {
	const isAdmin = useIsAdmin();
	return isAdmin;
};

export const useCanImportIssues = (options?: Options): boolean => {
	const [canEditIssues] = useCanEditIssues(options);
	const [canMakeBulkChanges] = useCanMakeBulkChanges(options);
	return canEditIssues && canMakeBulkChanges;
};

export const useCanManageIdeaTemplates = (options?: Options): boolean => {
	const [hasManageDescriptionTemplatesPermission] = useHasManageIdeaTemplatesPermission(options);
	return hasManageDescriptionTemplatesPermission;
};

export const useCanAddPlayContributions = (options?: Options): boolean => {
	const [hasVotePermissions] = useHasVotePermission(options);
	return hasVotePermissions;
};

export const useManageViewPermissions = (viewId: string | undefined, options?: Options) => {
	const isViewPermissionsEnabled = useIsViewPermissionsEnabled();
	const [canManageViews] = useHasManageViewsPermission(options);
	const { loadViewPermissionsForCurrentUser } = usePermissionActions();
	const [state] = usePermissions();

	if (!isViewPermissionsEnabled) {
		return {
			canManageView: canManageViews,
			canManageViews,
			canSeeView: true,
			loadViewPermissionsForCurrentUser: noop,
		};
	}

	if (!viewId) {
		return {
			canManageView: false,
			canManageViews,
			canSeeView: true,
			loadViewPermissionsForCurrentUser,
		};
	}

	const permissionsForThisView = state.viewPermissions[viewId];

	if (permissionsForThisView?.loading) {
		return {
			canManageView: false,
			canManageViews,
			canSeeView: true,
			loadViewPermissionsForCurrentUser,
		};
	}

	return {
		canManageView: canManageViews && Boolean(permissionsForThisView?.data?.canEdit),
		canManageViews,
		canSeeView:
			permissionsForThisView?.loading ||
			permissionsForThisView?.data === undefined ||
			Boolean(permissionsForThisView?.data.canView),
		loadViewPermissionsForCurrentUser,
	};
};

export const useCanManageViews = (options?: Options): boolean => {
	const [hasManageViewsPermission] = useHasManageViewsPermission(options);
	// temporarily give everyone manageViews permissions in a collection view
	// TODO
	const [isCollectionView] = useIsCollectionView();
	return hasManageViewsPermission || isCollectionView;
};

export const useCanMergeIdeas = (options?: Options): boolean => {
	const [hasMergeIdeasPermission] = useHasMergeIdeasPermission(options);
	return hasMergeIdeasPermission;
};

export const useCanArchiveIdeas = (options?: Options): boolean => {
	const [hasArchiveIdeasPermission] = useHasArchiveIdeasPermission(options);
	return hasArchiveIdeasPermission;
};

export const useCanPublishDiscoveryViews = (options?: Options): boolean =>
	useHasPublishDiscoveryViewsPermission(options)[0];

export const useCanPublishPublicDiscoveryViews = (options?: Options): boolean =>
	useHasPublishPublicDiscoveryViewsPermission(options)[0];

export const useCanExportViews = (options?: Options): boolean => {
	const [hasExportViewsPermission] = useHasExportViewsPermission(options);
	const collectionId = useEnvironmentContainer()?.id ?? '';
	const [isCollectionView] = useIsCollectionView();

	const canEditCollection = useCanEditCollection({
		collectionUUID: collectionId,
		forceLoading: isCollectionView,
	});

	return (isCollectionView && canEditCollection) || hasExportViewsPermission;
};

export const useCanManageInsights = (options?: Options): boolean => {
	const [hasManageInsightsPermission] = useHasManageInsightsPermission(options);
	return hasManageInsightsPermission;
};

export const useCanCreateInsights = (options?: Options): boolean => {
	const [hasCreateInsightsPermission] = useHasCreateInsightsPermission(options);
	return hasCreateInsightsPermission;
};

export const useCanManageDeliveryTickets = (options?: Options): boolean => {
	const [hasLinkIssuesPermission] = useHasLinkIssuesPermission(options);
	const [hasManageDeliveryPermission] = useHasManageDeliveryPermission(options);
	return hasManageDeliveryPermission && hasLinkIssuesPermission;
};

export const useHasNoProjectPermissions = (projectId?: ProjectId): [boolean] => {
	const [isCollectionView] = useIsCollectionView();
	const [projectPermissionsLength] = useProjectPermissionsLength(projectId);
	return [projectPermissionsLength === 0 && !isCollectionView];
};

export const useCanEditGroupsRollup = (options?: Options): boolean => {
	const [canEditIssues] = useCanEditIssues(options);
	const [isCollectionView] = useIsCollectionView();

	// TODO: should be only allowed for view owner in case of a collection view
	return isCollectionView || canEditIssues;
};
