// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { RowId, ColumnId } from '../../../common/types/index.tsx';
import type { ColumnDraggingInfo, RowDraggingInfo } from '../../../types.tsx';
import type {
	State,
	ScrollPosition,
	Dimensions,
	Props,
	RowVirtualisationState,
} from '../../types.tsx';

export const setColumnDraggingInfo =
	({ columnId, initialOffsetLeft, delta }: Partial<ColumnDraggingInfo>) =>
	({ getState, setState }: StoreActionApi<State>) => {
		setState({
			columnDraggingInfo: {
				...getState().columnDraggingInfo,
				...(columnId !== undefined && { columnId }),
				...(initialOffsetLeft !== undefined && { initialOffsetLeft }),
				...(delta !== undefined && { delta }),
			},
		});
	};

export const setDraggingRowId =
	(draggingRowId?: string) =>
	({ setState }: StoreActionApi<State>) => {
		setState({
			draggingRowId,
		});
	};

export const setPrevDraggingRowId =
	(prevDraggingRowId?: string) =>
	({ setState }: StoreActionApi<State>) => {
		setState({
			prevDraggingRowId,
		});
	};

export const trackRowDraggingInfo =
	(info: Partial<RowDraggingInfo>) =>
	({ getState }: StoreActionApi<State>, { onRowDragUpdate }: Props) => {
		const { draggingRowId } = getState();
		onRowDragUpdate &&
			onRowDragUpdate({
				rowId: draggingRowId,
				dropTarget: undefined,
				dropEdge: null,
				...info,
			});
	};

export const setScrollPosition =
	(scrollPosition: ScrollPosition) =>
	({ setState }: StoreActionApi<State>) => {
		setState({
			scrollPosition,
		});
	};

/**
 * Reset table horizontal scroll position to 0 for the given column
 * if fixedOnly is true, only reset if the column is fixed
 */
export const resetHorizontalScroll =
	(columnId: ColumnId, fixedOnly = false) =>
	({ getState }: StoreActionApi<State>) => {
		const { baseTableApi, fixedColumns } = getState();
		const isFixedColumn = fixedColumns.includes(columnId);
		if (fixedOnly && !isFixedColumn) {
			return;
		}
		baseTableApi?.scrollToLeft(0);
	};

export const setDimensions =
	(dimensions: Dimensions) =>
	({ setState }: StoreActionApi<State>) => {
		setState({
			dimensions,
		});
	};

export const setRowsRendered =
	(rowsRendered: RowVirtualisationState) =>
	({ setState }: StoreActionApi<State>) => {
		setState({
			rowsRendered,
		});
	};

export const setUpdatedRows =
	(updatedRows: RowId[]) =>
	({ setState }: StoreActionApi<State>, { onUpdatedRowsSeen }: Props) => {
		onUpdatedRowsSeen && onUpdatedRowsSeen(updatedRows);
		setState({
			updatedRows,
			updatedRowsProps: [{ id: null, idx: null, isMoved: false, isFiltered: false }],
		});
	};

export const showIdeaPreview =
	(id: RowId, updatedRows: RowId[]) =>
	({ setState }: StoreActionApi<State>, { onOpenIdeaPreview, onUpdatedRowsSeen }: Props) => {
		onOpenIdeaPreview && onOpenIdeaPreview(id);
		onUpdatedRowsSeen && onUpdatedRowsSeen(updatedRows);
		setState({
			updatedRows,
			updatedRowsProps: [{ id: null, idx: null, isMoved: false, isFiltered: false }],
		});
	};

export const setHighlightedRow =
	(highlightedRow?: RowId) =>
	({ setState }: StoreActionApi<State>) => {
		setState({
			highlightedRow,
		});
	};
