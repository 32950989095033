import React from 'react';
import { Box } from '@atlaskit/primitives';
import { components } from '@atlaskit/react-select';
import type { MenuListComponentProps } from '@atlaskit/select';
import { SearchBoxComponent } from '@atlassian/jira-polaris-lib-search-box/src/ui/index.tsx';
import type { ProjectOption } from '@atlassian/jira-polaris-project-select/src/ui/types.tsx';

export const ProjectSelectMenuList = ({
	children,
	...props
}: MenuListComponentProps<ProjectOption, true>) => {
	const { inputValue, onInputChange, onMenuClose } = props.selectProps;

	return (
		<>
			<Box paddingInline="space.150" paddingBlockStart="space.150">
				<SearchBoxComponent
					value={inputValue}
					onChange={(val) =>
						onInputChange(val, {
							action: 'input-change',
							prevInputValue: inputValue,
						})
					}
					isFocused
					isCompact={false}
					onBlur={onMenuClose}
				/>
			</Box>

			<components.MenuList {...props}>{children}</components.MenuList>
		</>
	);
};
