import { useMemo } from 'react';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';

import { useIsCollectionView } from '../../../../controllers/environment/index.tsx';
import { useSortedDistinctIssueStatuses } from '../../../../controllers/issue/selectors/fields-hooks.tsx';
import { useSortedGroupOptions } from '../../../../controllers/issue/selectors/grouping-hooks.tsx';
import { useHasSharedViewNoValueFilter } from '../../../../controllers/views/selectors/filters-hooks.tsx';
import { useSortedStatusesAsList } from '../../../../controllers/workflow/selectors/statuses-hooks.tsx';

export type FilterOption = {
	groupIdentity: string | undefined;
	value?: unknown;
};

export const useFilterOptions = (field: Field): FilterOption[] => {
	const fieldOptions = useSortedGroupOptions(field.key);
	const hasSharedViewNoValueFilter = useHasSharedViewNoValueFilter(field);
	const statusesFromProject = useSortedStatusesAsList(field);
	const distinctIssueStatusesSorted = useSortedDistinctIssueStatuses();
	const isCollectionView = useIsCollectionView();

	return useMemo(() => {
		if (field.type === FIELD_TYPES.STATUS) {
			const statuses = isCollectionView ? distinctIssueStatusesSorted : statusesFromProject;
			return statuses.map((status) => ({
				groupIdentity: status.id,
				value: status,
			}));
		}

		const nullableFieldOptions = (fieldOptions.options || []).map(
			({ groupIdentity, value }): FilterOption => ({
				groupIdentity,
				value,
			}),
		);

		if (!hasSharedViewNoValueFilter) {
			return nullableFieldOptions;
		}

		return [{ groupIdentity: undefined }, ...nullableFieldOptions];
	}, [
		field.type,
		fieldOptions.options,
		hasSharedViewNoValueFilter,
		isCollectionView,
		distinctIssueStatusesSorted,
		statusesFromProject,
	]);
};
