import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { getAnalyticsWebClient } from '@atlassian/jira-product-analytics-web-client';
import { post } from './rest/index.tsx';
import type { InviteUserResponse, InviteUserRequest, ResponseBody } from './rest/types.tsx';
import { getStatusCodeGroup } from './utils/index.tsx';
import {
	INVITED_STATUS,
	USER_EXISTS_STATUS,
	USER_LIMIT_ERROR_CODE,
	INVITED_PENDING_APPROVAL_STATUS,
	PENDING_INVITE_EXISTS_STATUS,
} from './constants.tsx';
import type { InviteUserArguments } from './types.tsx';

export class InviteUserFetchError extends FetchError {
	userInviteLimitReached: boolean;

	constructor(statusCode: number, message: string, userInviteLimitReached = false) {
		super(statusCode, message);
		this.name = 'InviteUserFetchError';
		this.userInviteLimitReached = userInviteLimitReached;
	}

	// go/jfe-eslint
	static of(response: Response): Promise<InviteUserFetchError> {
		return response
			.json()
			.then((body) => {
				const userLimitReached = body.errorMessages?.indexOf(USER_LIMIT_ERROR_CODE) !== -1;

				return new InviteUserFetchError(
					response.status,
					userLimitReached ? USER_LIMIT_ERROR_CODE : body.errorMessages,
					userLimitReached,
				);
			})
			.catch(() => new InviteUserFetchError(response.status, response.statusText));
	}
}

const transform = ({ inviteeAaId, inviteStatus }: ResponseBody): InviteUserResponse => {
	if (
		inviteStatus === INVITED_PENDING_APPROVAL_STATUS ||
		inviteStatus === PENDING_INVITE_EXISTS_STATUS
	) {
		getAnalyticsWebClient()
			.getInstance()
			.sendTrackEvent({
				action: 'requested',
				actionSubject: 'productAccess',
				source: 'addUsersToProjectModal',
				attributes: {
					requestedForUserId: inviteeAaId,
					exists: inviteStatus === PENDING_INVITE_EXISTS_STATUS,
				},
			});
	}
	return {
		userId: inviteeAaId,
		isInvite: inviteStatus === INVITED_STATUS || inviteStatus === USER_EXISTS_STATUS,
		status: inviteStatus,
	};
};

const checkInviteUserResponse = (response: Response): Promise<InviteUserResponse> => {
	if (response.ok) {
		return response.json().then(transform);
	}
	return InviteUserFetchError.of(response).then((error) => {
		fireErrorAnalytics({
			meta: {
				id: 'share-dialog',
				packageName: 'jira-polaris',
				teamName: 'jpd-juno',
			},
			error,
			attributes: {
				statusCode: getStatusCodeGroup(error),
			},
			sendToPrivacyUnsafeSplunk: true,
		});

		getAnalyticsWebClient()
			.getInstance()
			.sendOperationalEvent({
				action: 'failed',
				actionSubject: 'inviteOrRequestAccess',
				source: 'addUsersToProjectModal',
				attributes: {
					statusCode: error.statusCode,
				},
			});

		return Promise.reject(error);
	});
};

export const inviteUser = ({
	email,
	projectId,
	roleId,
}: InviteUserArguments): Promise<InviteUserResponse> => {
	const payload: InviteUserRequest = {
		atlOrigin: '',
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		continueUrl: window.location.href,
		email,
		projectRoleIds: [roleId],
	};
	return post({ projectId, payload }).then(checkInviteUserResponse);
};
