import React, { useState, useCallback, useEffect, useMemo } from 'react';
import difference from 'lodash/difference';
import noop from 'lodash/noop';
import { Flex } from '@atlaskit/primitives';
import { useIdeaActions } from '@atlassian/jira-polaris-common/src/controllers/idea/main.tsx';
import { usePreloadedSummary } from '@atlassian/jira-polaris-common/src/controllers/idea/selectors/hooks.tsx';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import {
	useSelectedIssue,
	useIsSelectedIssueArchived,
	useSelectedIssueKey,
	useSelectedIssueStringValue,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { useOpenRightSidebarOnIdeaTemplates } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/actions/hooks.tsx';
import {
	useIsRightSidebarOpen,
	useRightSidebarShowing,
} from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/selectors/hooks.tsx';
import { RightSidebarShowingIdeaTemplates } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/types.tsx';
import {
	useIsIssueOpenInFullscreen,
	useIssueViewLayout,
	usePolarisRouter,
} from '@atlassian/jira-polaris-common/src/controllers/route/index.tsx';
import { useCurrentUserAccountId } from '@atlassian/jira-polaris-common/src/controllers/user/index.tsx';
import { onHandleADFUploadError } from '@atlassian/jira-polaris-component-error-handlers/src/handle-afd-upload-error/index.tsx';
import { useCanEditIssues } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import type { DocumentFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/document/types.tsx';
import { getMediaIds } from '@atlassian/jira-polaris-lib-adf-utils/src/utils/index.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import { useConcurrentUpdate } from '@atlassian/jira-polaris-lib-concurrent-updates/src/controllers/index.tsx';
import { UpdateBanner } from '@atlassian/jira-polaris-lib-concurrent-updates/src/ui/banner/index.tsx';
import { UpdateModal } from '@atlassian/jira-polaris-lib-concurrent-updates/src/ui/update-modal/index.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { EditViewComponent } from './edit/index.tsx';
import { ReadViewComponent } from './read/index.tsx';

type DescriptionContentProps = {
	description: DocumentFieldValue;
	isReadOnly?: boolean;
	isSmartLink?: boolean;
};

export const DescriptionContent = ({
	description,
	isSmartLink = false,
	isReadOnly = false,
}: DescriptionContentProps) => {
	const [sidebarShowing] = useRightSidebarShowing();
	const [isRightSidebarOpen] = useIsRightSidebarOpen();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const isTemplatesSidebarOpen =
		isRightSidebarOpen && sidebarShowing.mode === RightSidebarShowingIdeaTemplates;
	const preloadedSummary = usePreloadedSummary();
	const summaryValue = useSelectedIssueStringValue('summary');
	const [isUpdating, setUpdating] = useState(false);

	const summary = useMemo(
		() => summaryValue ?? preloadedSummary ?? '',
		[summaryValue, preloadedSummary],
	);

	const [isEditing, setEditing] = useState(false);
	const [
		{ hasConcurrentUpdate, latestUpdate, isLoadingUpdate },
		{ setHasConcurrentUpdate, loadLatestUpdate },
	] = useConcurrentUpdate();
	const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);

	const { updateFieldValueForSelectedIssue } = useIssueActions();
	const [isEditingAllowed] = useCanEditIssues();
	const isArchived = useIsSelectedIssueArchived();
	const { loadDescriptionDraft, clearDescriptionDraft, removeAttachments } = useIdeaActions();
	const currentUserAccountId = useCurrentUserAccountId();
	const selectedIssue = useSelectedIssue();
	const issueViewLayout = useIssueViewLayout();
	const onOpenRightSidebarOnIdeaTemplates = useOpenRightSidebarOnIdeaTemplates();
	const issueKey = useSelectedIssueKey();
	const { openIssueView } = usePolarisRouter();
	const isIssueOpenInFullscreen = useIsIssueOpenInFullscreen();

	useEffect(() => {
		if (isTemplatesSidebarOpen) {
			setEditing(true);
		}
	}, [isTemplatesSidebarOpen]);

	useEffect(() => {
		// Closing editor when switching to another idea in the sidebar
		if (issueViewLayout === 'sidebar') {
			setEditing(false);
		}
	}, [selectedIssue, issueViewLayout]);

	useEffect(() => {
		if (!issueKey || !isEditing) return;
		setHasConcurrentUpdate(true);
		loadLatestUpdate(issueKey);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [description]);

	useEffect(() => {
		setHasConcurrentUpdate(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isEditing]);

	const onConfirm = useCallback(
		(value: DocumentFieldValue) => {
			if (isEditingAllowed) {
				setEditing(false);
				setUpdating(true);

				const deletedMediaIds = difference(getMediaIds(description), getMediaIds(value));
				removeAttachments(deletedMediaIds);

				updateFieldValueForSelectedIssue(
					{
						fieldKey: 'description',
						newValue: value,
						performSideEffects: true,
					},
					() => {
						clearDescriptionDraft(currentUserAccountId);
						setUpdating(false);
						experience.ideaView.descriptionUpdate.success();
					},
					(error?: Error) => {
						setUpdating(false);
						return onHandleADFUploadError(experience.ideaView.descriptionUpdate, error);
					},
				);
			}
		},
		[
			isEditingAllowed,
			updateFieldValueForSelectedIssue,
			description,
			removeAttachments,
			clearDescriptionDraft,
			currentUserAccountId,
		],
	);

	const onCancel = useCallback(() => {
		if (isEditingAllowed && !isArchived) {
			experience.ideaView.descriptionUpdate.abort();
			setEditing(false);
		}
		clearDescriptionDraft(currentUserAccountId);
	}, [isEditingAllowed, isArchived, clearDescriptionDraft, currentUserAccountId]);

	const onEdit = useCallback(() => {
		if (isEditingAllowed && !isArchived) {
			experience.ideaView.descriptionUpdate.start();
			setEditing(true);
		}
	}, [isArchived, isEditingAllowed]);

	const onTemplateBtnClick = useCallback(() => {
		fireCompoundAnalyticsEvent.IdeaDescriptionTemplateApplyClicked(createAnalyticsEvent({}));

		if (isIssueOpenInFullscreen) {
			onEdit();
		} else if (issueKey) {
			openIssueView(issueKey);
		}
		onOpenRightSidebarOnIdeaTemplates(noop);
	}, [
		createAnalyticsEvent,
		isIssueOpenInFullscreen,
		issueKey,
		onOpenRightSidebarOnIdeaTemplates,
		onEdit,
		openIssueView,
	]);

	const draft = loadDescriptionDraft(currentUserAccountId);

	if (isEditing && isEditingAllowed && !isReadOnly) {
		return (
			<>
				<UpdateModal
					isOpen={isUpdateModalOpen}
					summary={summary}
					description={<ReadViewComponent description={description} isEditingAllowed={false} />}
					onClose={() => setIsUpdateModalOpen(false)}
					latestUpdate={latestUpdate}
					isLoadingUpdate={isLoadingUpdate}
				/>
				<Flex gap="space.050" direction="column">
					<UpdateBanner
						display={hasConcurrentUpdate}
						isInSidebar={issueViewLayout === 'sidebar'}
						latestUpdate={latestUpdate}
						onOpenModal={() => setIsUpdateModalOpen(true)}
						isLoadingUpdate={isLoadingUpdate}
					/>
					<EditViewComponent
						description={draft || description}
						onConfirm={onConfirm}
						onCancel={onCancel}
					/>
				</Flex>
			</>
		);
	}

	return (
		<ReadViewComponent
			hasDraft={!!draft && !isSmartLink && !isUpdating}
			isSmartLink={isSmartLink}
			description={description}
			onClick={onEdit}
			isEditingAllowed={isEditingAllowed}
			onTemplatesClick={onTemplateBtnClick}
		/>
	);
};
