// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createHook } from '@atlassian/react-sweet-state';
import {
	ProductType,
	type AvailableProduct,
} from '../../../services/jira/available-products/types.tsx';
import { AvailableProductsStore } from '../main.tsx';

export const useIsProductAvailable = createHook(AvailableProductsStore, {
	selector: (
		state,
		{
			cloudId,
			productType,
		}: {
			productType: ProductType;
			cloudId: string;
		},
	) => {
		const product: AvailableProduct | undefined = state.data?.sites
			.find((site) => site.cloudId === cloudId)
			?.availableProducts.find((availableProduct) => availableProduct.productType === productType);
		const isAvailable = product !== undefined;
		return isAvailable;
	},
});

export const useIsProductAvailableForSites = createHook(AvailableProductsStore, {
	selector: (
		state,
		{
			cloudIds,
			productType,
		}: {
			productType: ProductType;
			cloudIds: string[];
		},
	) =>
		cloudIds.reduce(
			(result, cloudId) => {
				const product: AvailableProduct | undefined = state.data?.sites
					.find((site) => site.cloudId === cloudId)
					?.availableProducts.find(
						(availableProduct) => availableProduct.productType === productType,
					);
				const isAvailable = product !== undefined;
				return Object.assign(result, {
					[cloudId]: isAvailable,
				});
			},
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			{} as {
				[key: string]: boolean;
			},
		),
});

export const useSitesWithAvailableProducts = createHook(AvailableProductsStore, {
	selector: (state) => state.data?.sites || [],
});

export const useSitesWithAtlas = createHook(AvailableProductsStore, {
	selector: (state) =>
		state.data?.sites.filter((site) =>
			site.availableProducts.find((product) => product.productType === ProductType.ATLAS),
		) || [],
});

export const useIsAvailableProductsLoading = createHook(AvailableProductsStore, {
	selector: (state) => state.loading,
});

export const useAvailableProductsError = createHook(AvailableProductsStore, {
	selector: (state) => state.error,
});

export const useIsAtlasSiteFromSameOrg = createHook(AvailableProductsStore, {
	selector: (
		state,
		{
			currentSiteCloudId,
			atlasSiteCloudId,
		}: {
			currentSiteCloudId: string;
			atlasSiteCloudId: string | undefined;
		},
	) => {
		const availableAtlasSites = state.data?.sites.filter((site) =>
			site.availableProducts.find((product) => product.productType === ProductType.ATLAS),
		);

		if (
			!availableAtlasSites?.length ||
			!atlasSiteCloudId ||
			currentSiteCloudId === atlasSiteCloudId
		) {
			return true;
		}

		const currentSiteOrgId = availableAtlasSites.find((site) => site.cloudId === currentSiteCloudId)
			?.org?.id;
		const atlasSiteOrgId = availableAtlasSites.find((site) => site.cloudId === atlasSiteCloudId)
			?.org?.id;
		return currentSiteOrgId === atlasSiteOrgId;
	},
});
