import React from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button/new';
import { Mention } from '@atlaskit/editor-common/mention';
import WarningIcon from '@atlaskit/icon/core/migration/warning';
import { SlideIn, StaggeredEntrance, useResizingHeight } from '@atlaskit/motion';
import { Box, media } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { MentionPlaceholder } from '../../common/ui/placeholder/index.tsx';
import messages from './messages.tsx';
import type { AnimationContainerProps, UpdateBannerProps } from './types.tsx';

const Message = ({
	latestUpdate,
	isLoadingUpdate,
}: Pick<UpdateBannerProps, 'latestUpdate' | 'isLoadingUpdate'>) => {
	const { formatMessage } = useIntl();

	let message: React.ReactNode;

	if (isLoadingUpdate) {
		message = formatMessage(messages.warningLoading, {
			user: <MentionPlaceholder />,
		});
	} else if (!latestUpdate) {
		// eslint-disable-next-line @atlaskit/design-system/use-primitives-text
		message = <p>{formatMessage(messages.warningGeneric)}</p>;
	} else {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		const time = Intl.DateTimeFormat(navigator.language, {
			hour: '2-digit',
			minute: '2-digit',
			hour12: true,
		}).format(new Date(latestUpdate.date));
		message = formatMessage(messages.warning, {
			user: <Mention id={latestUpdate.authorId} text={`@${latestUpdate.authorName}`} />,
			time,
		});
	}

	return <Box>{message}</Box>;
};

export const UpdateBanner = ({
	display = false,
	isInSidebar,
	onOpenModal,
	latestUpdate,
	isLoadingUpdate,
}: UpdateBannerProps) => {
	const { formatMessage } = useIntl();

	return (
		<AnimationContainer display={display}>
			<Banner>
				<WarningIcon
					spacing="spacious"
					label={formatMessage(messages.warningIconLabel)}
					LEGACY_secondaryColor={token('color.background.warning.bold')}
				/>
				<BannerContent isInSidebar={isInSidebar}>
					<Message latestUpdate={latestUpdate} isLoadingUpdate={isLoadingUpdate} />
					<Button
						spacing="compact"
						onClick={(_e, analyticsEvent) => {
							onOpenModal();
							fireUIAnalytics(analyticsEvent, 'button clicked', 'concurrentUpdateComparison');
						}}
					>
						{formatMessage(messages.openModalButton)}
					</Button>
				</BannerContent>
			</Banner>
		</AnimationContainer>
	);
};

const AnimationContainer = ({ display, children }: AnimationContainerProps) => {
	return (
		<div {...useResizingHeight()}>
			<StaggeredEntrance columns={1} delayStep={0}>
				{display ? (
					<SlideIn enterFrom="top" fade="in">
						{(props) => <div {...props}>{children}</div>}
					</SlideIn>
				) : (
					<Box /> // StaggeredEntrance is typed to always have children
				)}
			</StaggeredEntrance>
		</div>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Banner = styled.div({
	display: 'flex',
	gap: token('space.050'),
	paddingTop: token('space.150'),
	paddingRight: token('space.150'),
	paddingBottom: token('space.150'),
	paddingLeft: token('space.150'),
	backgroundColor: token('color.background.warning.bold'),
	zIndex: '101', // editor toolbar is 100, left sidebar is 199
	position: 'sticky',
	top: token('space.0'),
	// The banner doesn't change color in dark mode, but has children with transparent backgrounds that do
	// This prevents a11y contrast issues
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	'*': { color: `${colors.N800} !important` },
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BannerContent = styled.div<{ isInSidebar?: boolean }>({
	width: '100%',
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	flexDirection: ({ isInSidebar }) => (isInSidebar ? 'column' : 'row'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	alignItems: ({ isInSidebar }) => (isInSidebar ? 'flex-start' : 'center'),
	gap: token('space.050'),
	justifyContent: 'space-between',
	[media.below.md]: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		flexDirection: 'column !important',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		alignItems: 'flex-start !important',
	},
});
