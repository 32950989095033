/** @jsx jsx */
import type { PropsWithChildren } from 'react';
import { css, jsx } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';

export const FilterWrapper = ({ children }: PropsWithChildren) => {
	return (
		<div
			css={[
				!fg('polaris_better_date_filters') && filterComponentWrapperStyles,
				fg('polaris_better_date_filters') && filterComponentWrapperStylesNext,
			]}
		>
			{children}
		</div>
	);
};

const filterComponentWrapperStyles = css({
	flexGrow: 1,
	marginTop: token('space.050'),
	marginRight: 0,
	marginBottom: token('space.050'),
	marginLeft: 0,
	minWidth: '0',
	maxWidth: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	button: {
		width: '100%',
		textAlign: 'left',
	},
});

const filterComponentWrapperStylesNext = css({
	flexGrow: 1,
	marginTop: token('space.050'),
	marginRight: 0,
	marginBottom: token('space.050'),
	marginLeft: 0,
	minWidth: '0',
	maxWidth: '100%',
});
