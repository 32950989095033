// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { State } from '../../types.tsx';

export const setMatrixContainerDimensions =
	(width: number, height: number) =>
	({ setState }: StoreActionApi<State>) => {
		setState({
			matrixContainer: {
				width,
				height,
			},
		});
	};
