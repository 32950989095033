// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import { getContributorGroupConfig } from '../../../services/jira/get-contributor-group-config/index.tsx';
import type { Props, State } from '../../types.tsx';

export const loadContributorGroupConfig =
	(): Action<State, Props> =>
	({ setState }) => {
		getContributorGroupConfig()
			.then((response) => {
				setState({
					contributorGroupEnabled: response.enabled,
					contributorGroupName: response.groupName,
					contributorGroupId: response.groupId,
					contributorGroupIds: response.contributorGroupIds,
				});
			})
			.catch(); // ignore error in this case - let's consider it as contributor group is not enabled
	};
