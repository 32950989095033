import React, { forwardRef, useCallback, useState } from 'react';
import { IconButton } from '@atlaskit/button/new';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import Icon, { type IconProps } from '@atlaskit/icon';
import { Flex, xcss } from '@atlaskit/primitives';
import { token, useThemeObserver } from '@atlaskit/tokens';
import Tooltip, { TooltipPrimitive, type TooltipPrimitiveProps } from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
// eslint-disable-next-line jira/import/no-restricted-import
import { scalesGlyph } from '@atlassian/jira-polaris-component-glyphs/src/ui/glyphs/scales/index.tsx';
import {
	// eslint-disable-next-line jira/import/no-restricted-import
	WEIGHT_TYPE_NUMBER,
	// eslint-disable-next-line jira/import/no-restricted-import
	WEIGHT_TYPE_RATING,
	// eslint-disable-next-line jira/import/no-restricted-import
	type WeightType,
} from '@atlassian/jira-polaris-domain-field/src/field-types/option/types.tsx';
import { sendPendoTrackEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/pendo/index.tsx';
import { OptionWeightTypeNumberTooltipImageDark } from './assets/option-weight-type-number-tooltip-image-dark.tsx';
import { OptionWeightTypeNumberTooltipImage } from './assets/option-weight-type-number-tooltip-image.tsx';
import { OptionWeightTypeRatingTooltipImageDark } from './assets/option-weight-type-rating-tooltip-image-dark.tsx';
import { OptionWeightTypeRatingTooltipImage } from './assets/option-weight-type-rating-tooltip-image.tsx';
import { OptionWeightTypeTooltipImageDark } from './assets/option-weight-type-tooltip-image-dark.tsx';
import { OptionWeightTypeTooltipImage } from './assets/option-weight-type-tooltip-image.tsx';
import { DeleteWeightingConfirmModal } from './delete-weighting-confirm-modal/index.tsx';
import messages from './messages.tsx';

type Props = {
	label: string;
	optionWeightType: WeightType | undefined;
	onOptionWeightTypeChange: (weightType: WeightType | undefined) => void;
};

export const ChangeOptionWeightTypeAction = ({
	label,
	optionWeightType,
	onOptionWeightTypeChange,
}: Props) => {
	const [isIconButtonTooltipEnabled, setIsIconButtonTooltipEnabled] = useState(true);
	const [isDeleteWeightingModalOpen, setIsDeleteWeightingModalOpen] = useState(false);
	const { formatMessage } = useIntl();
	const { colorMode } = useThemeObserver();
	const isDarkMode = colorMode === 'dark';

	// Stops the IconButton tooltip from appearing after clicking on a DropdownItem
	const handleOptionWeightTypeChange = useCallback(
		(weightType: WeightType | undefined) => {
			setIsIconButtonTooltipEnabled(false);
			onOptionWeightTypeChange(weightType);
			setTimeout(() => setIsIconButtonTooltipEnabled(true));
		},
		[onOptionWeightTypeChange],
	);

	return (
		<>
			<DropdownMenu<HTMLButtonElement>
				trigger={({ triggerRef, ...props }) => (
					<>
						{optionWeightType === undefined && isIconButtonTooltipEnabled ? (
							<Tooltip
								component={TooltipContainer}
								content={
									<>
										<Icon
											glyph={
												isDarkMode ? OptionWeightTypeTooltipImageDark : OptionWeightTypeTooltipImage
											}
											label={formatMessage(messages.weightTypeTooltipImageLabel)}
										/>
										{formatMessage(messages.addWeightsTooltipText)}
									</>
								}
								hideTooltipOnClick
							>
								<IconButton
									{...props}
									onClick={(e) => {
										sendPendoTrackEvent({
											actionSubjectAndAction: 'button clicked',
											actionSubjectId: 'optionWeightType',
											source: 'fieldOptionList',
										});
										props.onClick?.(e);
									}}
									testId="polaris-lib-field-option-configuration.ui.field-options-config-menu-bar.change-option-weight-type-action.icon-button"
									spacing="compact"
									appearance="subtle"
									icon={ScalesIcon}
									label={label}
									ref={triggerRef}
								/>
							</Tooltip>
						) : (
							<IconButton
								{...props}
								onClick={(e) => {
									sendPendoTrackEvent({
										actionSubjectAndAction: 'button clicked',
										actionSubjectId: 'optionWeightType',
										source: 'fieldOptionList',
									});
									props.onClick?.(e);
								}}
								testId="polaris-lib-field-option-configuration.ui.field-options-config-menu-bar.change-option-weight-type-action.icon-button"
								spacing="compact"
								appearance="subtle"
								icon={ScalesIcon}
								label={label}
								ref={triggerRef}
							/>
						)}
					</>
				)}
			>
				<DropdownItemGroup>
					{(optionWeightType === undefined || optionWeightType === WEIGHT_TYPE_RATING) && (
						<NumberTypeAction
							optionWeightType={optionWeightType}
							onOptionWeightTypeChange={handleOptionWeightTypeChange}
						/>
					)}
					{(optionWeightType === undefined || optionWeightType === WEIGHT_TYPE_NUMBER) && (
						<RatingTypeAction
							optionWeightType={optionWeightType}
							onOptionWeightTypeChange={handleOptionWeightTypeChange}
						/>
					)}
					{optionWeightType !== undefined && (
						<DropdownItem
							onClick={() => {
								sendPendoTrackEvent({
									actionSubjectAndAction: 'dropdownItem clicked',
									actionSubjectId: 'removeOptionWeightType',
									source: 'fieldOptionList',
								});
								setIsDeleteWeightingModalOpen(true);
							}}
							testId="polaris-lib-field-option-configuration.ui.field-options-config-menu-bar.change-option-weight-type-action.remove-weighting"
						>
							{formatMessage(messages.removeWeighting)}
						</DropdownItem>
					)}
				</DropdownItemGroup>
			</DropdownMenu>
			<DeleteWeightingConfirmModal
				isOpen={isDeleteWeightingModalOpen}
				onCancel={() => setIsDeleteWeightingModalOpen(false)}
				onConfirm={() => {
					handleOptionWeightTypeChange(undefined);
					setIsDeleteWeightingModalOpen(false);
				}}
			/>
		</>
	);
};

const NumberTypeAction = ({
	optionWeightType,
	onOptionWeightTypeChange,
}: Pick<Props, 'optionWeightType' | 'onOptionWeightTypeChange'>) => {
	const { formatMessage } = useIntl();
	const { colorMode } = useThemeObserver();
	const isDarkMode = colorMode === 'dark';

	return (
		<Tooltip
			component={TooltipContainer}
			position="left-start"
			content={
				<>
					<Icon
						glyph={
							isDarkMode
								? OptionWeightTypeNumberTooltipImageDark
								: OptionWeightTypeNumberTooltipImage
						}
						label={formatMessage(messages.weightTypeNumberTooltipImageLabel)}
					/>
					{formatMessage(messages.addWeightsTooltipText)}
				</>
			}
		>
			<DropdownItem
				onClick={() => {
					sendPendoTrackEvent({
						actionSubjectAndAction: 'dropdownItem clicked',
						actionSubjectId: 'numberOptionWeightType',
						source: 'fieldOptionList',
					});
					onOptionWeightTypeChange(WEIGHT_TYPE_NUMBER);
				}}
				testId="polaris-lib-field-option-configuration.ui.field-options-config-menu-bar.change-option-weight-type-action.number-type"
			>
				{optionWeightType === undefined
					? formatMessage(messages.addNumberBasedWeights)
					: formatMessage(messages.changeToNumberBasedWeights)}
			</DropdownItem>
		</Tooltip>
	);
};

const RatingTypeAction = ({
	optionWeightType,
	onOptionWeightTypeChange,
}: Pick<Props, 'optionWeightType' | 'onOptionWeightTypeChange'>) => {
	const { formatMessage } = useIntl();
	const { colorMode } = useThemeObserver();
	const isDarkMode = colorMode === 'dark';

	return (
		<Tooltip
			component={TooltipContainer}
			position="left-start"
			content={
				<>
					<Icon
						glyph={
							isDarkMode
								? OptionWeightTypeRatingTooltipImageDark
								: OptionWeightTypeRatingTooltipImage
						}
						label={formatMessage(messages.weightTypeRatingTooltipImage)}
					/>
					{formatMessage(messages.addWeightsTooltipText)}
				</>
			}
		>
			<DropdownItem
				onClick={() => {
					sendPendoTrackEvent({
						actionSubjectAndAction: 'dropdownItem clicked',
						actionSubjectId: 'ratingOptionWeightType',
						source: 'fieldOptionList',
					});
					onOptionWeightTypeChange(WEIGHT_TYPE_RATING);
				}}
				testId="polaris-lib-field-option-configuration.ui.field-options-config-menu-bar.change-option-weight-type-action.rating-type"
			>
				{optionWeightType === undefined
					? formatMessage(messages.addRatingBasedWeights)
					: formatMessage(messages.changeToRatingBasedWeights)}
			</DropdownItem>
		</Tooltip>
	);
};

const TooltipContainer = forwardRef<HTMLDivElement, TooltipPrimitiveProps>((props, ref) => {
	const { children, ...rest } = props;
	return (
		<TooltipPrimitive {...rest} ref={ref}>
			<Flex justifyContent="center" direction="column" gap="space.150" xcss={tooltipWrapperStyles}>
				{children}
			</Flex>
		</TooltipPrimitive>
	);
});

const ScalesIcon = forwardRef<unknown, IconProps>((props, ref) => (
	<Icon
		{...props}
		size="small"
		// @ts-expect-error Property 'ref' does not exist on type 'IntrinsicAttributes & IconProps'
		ref={ref}
		glyph={scalesGlyph}
	/>
));

const tooltipWrapperStyles = xcss({
	maxWidth: '300px',
	background: token('color.icon'),
	color: 'color.text.inverse',
	padding: 'space.150',
	borderRadius: 'border.radius.300',
});
