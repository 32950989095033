import React, { useState, useEffect, useMemo, useCallback } from 'react';
import merge from 'lodash/merge';
import debounce from 'lodash/debounce';
import Badge from '@atlaskit/badge';
import Button from '@atlaskit/button';
import EditorAddIcon from '@atlaskit/icon/core/migration/add--editor-add';
import { Section, HeadingItem, type CSSFn } from '@atlaskit/menu';
import { Box, Flex, xcss, Text, Inline } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { filterSystemFieldsByKey } from '@atlassian/jira-polaris-domain-field/src/field/utils.tsx';
import { fieldsReferencableFrom } from '@atlassian/jira-polaris-domain-field/src/formula/index.tsx';
import { PolarisInlineDialog } from '@atlassian/jira-polaris-lib-inline-dialog/src/ui/index.tsx';
import { SearchInput } from '@atlassian/jira-polaris-lib-search-input/src/ui/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import {
	COUNT_ROLLUP,
	FIELD_ROLLUP,
	POLARIS_OAUTH_CLIENT_ID,
	PROPERTY_ROLLUP,
} from '../../../../common/constants.tsx';
import type { PercentageProps, RollupField, RollupInput } from '../../../../common/types.tsx';
import { getAppNameForRollupField } from '../../../../common/utils/formula/app-name/index.tsx';
import { parseConjunctiveLabelFilter } from '../../../../common/utils/formula/conjuctive-label-filter/index.tsx';
import { createFormula } from '../../../../common/utils/formula/formula-create/index.tsx';
import { useFormulaState } from '../../../../common/utils/formula/formula-state/index.tsx';
import {
	getSplitPercentageValue,
	isInvalidPercentageDistribution,
} from '../../../../common/utils/formula/percentage/index.tsx';
import { useActions } from '../../../../controllers/index.tsx';
import { DataOptions } from './data-options/index.tsx';
import { FieldOptions } from './field-options/index.tsx';
import { RollupItemComponent } from './item/index.tsx';
import { messages } from './messages.tsx';
import { MultiselectOptions } from './multi-select-options/index.tsx';
import { RemovedFieldError } from './removed-field-error/index.tsx';
import type { RollupInputListProps, RollupProps } from './types.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const cssFn: CSSFn<any> = (currentStyles) => {
	const newStyle = {
		padding: `0px ${token('space.200')}`,
		marginTop: 0,
	};
	return merge(currentStyles, newStyle);
};

const useWeightCheck = (
	rollupInputs: RollupInput[],
	isWeightedScore: boolean,
): [boolean, number] => {
	const percentages = useMemo(
		() => (isWeightedScore ? rollupInputs.map(({ percentage }) => percentage?.value) : []),
		[rollupInputs, isWeightedScore],
	);

	const isUnbalanced = useMemo(
		() => !isWeightedScore || isInvalidPercentageDistribution(percentages),
		[percentages, isWeightedScore],
	);

	const defaultValue = useMemo(
		() => (isWeightedScore ? getSplitPercentageValue(percentages) : 0),
		[isWeightedScore, percentages],
	);

	return [isUnbalanced, defaultValue];
};

const FORMULA_CHANGE_DEBOUNCE_DELAY = 500;

const RollupInputList = ({
	title,
	rollupInputs,
	readonly,
	onRemoveField,
	onAddField,
	negative,
	setLabelFilter,
	nonCircularFields,
	hasSeparator,
	isWeightedScore,
	snippetProviders,
	sortedSnippetLabels,
	displayDataOptions = true,
	isGlobalField,
	displayGlobalFieldIcons,
	isFieldTooltip,
	isPreview,
}: RollupInputListProps) => {
	const { formatMessage } = useIntl();

	const [isAddInputDialogOpen, setIsAddInputDialogOpen] = useState(false);
	const [search, setSearch] = useState('');

	const isGlobalFieldPreview = isGlobalField && isPreview;

	const addFieldWithNegative = (selectedField: RollupField) =>
		onAddField({ ...selectedField, negative });

	const closeDialog = () => {
		setSearch('');
		setIsAddInputDialogOpen(false);
	};

	const isFiltered = (label: string) =>
		label.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1;

	const [availableInputs, removedGlobalInputs] = useMemo(() => {
		// if the inputs are displayed in a global formula preview
		// we don't want to show the removed field notice
		if (isGlobalFieldPreview) {
			return [rollupInputs, []];
		}

		const available: RollupInput[] = [];
		const removed: RollupInput[] = [];
		rollupInputs.forEach((rollupInput: RollupInput) => {
			if (
				rollupInput.rollupField.kind === FIELD_ROLLUP &&
				rollupInput.rollupField.isRemovedGlobalField
			) {
				removed.push(rollupInput);
			} else {
				available.push(rollupInput);
			}
		});
		return [available, removed];
	}, [rollupInputs, isGlobalFieldPreview]);

	const [isUnbalanced, defaultValue] = useWeightCheck(rollupInputs, isWeightedScore);

	return (
		<Section
			hasSeparator={hasSeparator}
			testId={`polaris-components.field-configuration.src.ui.configuration.formula.rollup-${negative ? 'negative' : 'positive'}`}
		>
			<Inline
				xcss={[
					rollupContainerHeaderStyles,
					Boolean(removedGlobalInputs.length) && rollupContainerHeaderWithErrorStyles,
				]}
				space="space.050"
				alignBlock="center"
			>
				<Badge>{availableInputs.length}</Badge>
				<Text size="small">{title}</Text>
				{isGlobalField && removedGlobalInputs.length > 0 && (
					<RemovedFieldError
						removedGlobalInputs={removedGlobalInputs}
						isFieldTooltip={isFieldTooltip}
					/>
				)}
			</Inline>
			{availableInputs.length > 0 && (
				<Flex direction="column" xcss={rollupFieldsContainerStyles}>
					{availableInputs.map(({ rollupField, percentage, index }) => (
						<RollupItemComponent
							readonly={readonly}
							key={`${rollupField.kind === FIELD_ROLLUP ? rollupField.value.key : rollupField.value.id}-${index}`}
							fieldKey={rollupField.kind === FIELD_ROLLUP ? rollupField.value.key : undefined}
							label={rollupField.value.label}
							isGlobalField={rollupField.kind !== PROPERTY_ROLLUP && rollupField.value.global}
							appName={getAppNameForRollupField(rollupField)}
							appAvatarUrl={
								rollupField.kind === PROPERTY_ROLLUP || rollupField.kind === COUNT_ROLLUP
									? rollupField.value.avatarUrl
									: undefined
							}
							isPolarisApp={
								rollupField.kind !== FIELD_ROLLUP &&
								rollupField.value?.oauthClientId === POLARIS_OAUTH_CLIENT_ID
							}
							onDelete={() => onRemoveField(index)}
							filteredLabels={
								// @ts-expect-error - TS2339 - Property 'filter' does not exist on type 'Field | SnippetPropertyItem | { id: string; label: string; appName: string; oauthClientId: string; avatarUrl?: string | undefined; filter?: InsightFilter | undefined; }'.
								rollupField.value.filter
									? // @ts-expect-error - TS2339 - Property 'filter' does not exist on type 'Field | SnippetPropertyItem | { id: string; label: string; appName: string; oauthClientId: string; avatarUrl?: string | undefined; filter?: InsightFilter | undefined; }'.
										parseConjunctiveLabelFilter(rollupField.value.filter)
									: undefined
							}
							percentage={percentage}
							defaultValue={defaultValue}
							isUnbalanced={isUnbalanced}
							onFilterChange={
								rollupField.kind === PROPERTY_ROLLUP || rollupField.kind === COUNT_ROLLUP
									? (labels: undefined | Array<string>) => setLabelFilter(index, labels)
									: undefined
							}
							sortedSnippetLabels={sortedSnippetLabels}
							displayGlobalFieldIcons={displayGlobalFieldIcons}
						/>
					))}
				</Flex>
			)}
			{!readonly && (
				<Flex alignItems="center" xcss={addInputContainerStyles}>
					<PolarisInlineDialog
						noPadding
						placement="bottom-start"
						messageId="polaris-component-field-configuration.ui.configuration.formula.rollup.polaris-inline-dialog"
						messageType="transactional"
						isOpen={isAddInputDialogOpen}
						onClose={closeDialog}
						content={
							<Flex direction="column">
								<Box padding="space.200" xcss={addInputSearchContainerStyles}>
									<SearchInput
										autoFocus
										value={search}
										onChange={setSearch}
										placeholder={formatMessage(messages.searchFieldsPlaceholder)}
									/>
								</Box>
								<Flex direction="column" xcss={rollupOptionsContainerStyles}>
									<Section>
										{/* eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis */}
										<HeadingItem cssFn={cssFn}>
											{formatMessage(messages.fieldSectionHeader)}
										</HeadingItem>
										<FieldOptions
											fields={nonCircularFields}
											isFiltered={isFiltered}
											onClick={(selectedField) => {
												addFieldWithNegative(selectedField);
												closeDialog();
											}}
											displayGlobalFieldIcons={displayGlobalFieldIcons}
										/>
									</Section>
									<Section>
										{/* eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis */}
										<HeadingItem cssFn={cssFn}>
											{formatMessage(messages.selectSectionHeader)}
										</HeadingItem>
										<MultiselectOptions
											fields={nonCircularFields}
											isFiltered={isFiltered}
											onClick={(selectedField) => {
												addFieldWithNegative(selectedField);
												closeDialog();
											}}
											displayGlobalFieldIcons={displayGlobalFieldIcons}
										/>
									</Section>
									{displayDataOptions && (
										<Section testId="polaris-component-field-configuration.ui.configuration.formula.rollup.insights-section">
											{/* eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis */}
											<HeadingItem cssFn={cssFn}>
												{formatMessage(messages.dataSectionHeader)}
											</HeadingItem>
											<DataOptions
												snippetProviders={snippetProviders}
												isFiltered={isFiltered}
												onClick={(selectedField) => {
													addFieldWithNegative(selectedField);
													closeDialog();
												}}
												isGlobalField={isGlobalField}
											/>
										</Section>
									)}
								</Flex>
							</Flex>
						}
					>
						<Box xcss={addButtonContainerStyles}>
							<Button
								id="polaris.common.src.ui.config.fields.common.rollup-formula.item.add-input-button"
								iconBefore={
									<EditorAddIcon
										label={formatMessage(messages.addInputButtonLabel)}
										LEGACY_size="medium"
									/>
								}
								onClick={() => setIsAddInputDialogOpen(true)}
								isSelected={isAddInputDialogOpen}
							>
								{formatMessage(messages.addInputButtonLabel)}
							</Button>
						</Box>
					</PolarisInlineDialog>
				</Flex>
			)}
		</Section>
	);
};

export const Rollup = ({
	fieldsByKey,
	availableGlobalFieldsByKey,
	initFormula,
	thisFieldKey,
	initAsWeightedScore,
	readonly,
	onChange,
	onThrottledFetchSnippetProviders,
	snippetProviders,
	sortedSnippetLabels,
	displayDataOptions = true,
	isGlobalField = false,
	displayGlobalFieldIcons = false,
	isFieldTooltip,
	isPreview,
}: RollupProps) => {
	const { formatMessage } = useIntl();
	const filteredFieldsByKey = useMemo(() => filterSystemFieldsByKey(fieldsByKey), [fieldsByKey]);

	const [
		{ rollupFields, percentages, weightedScore, isUpdated },
		{ addField, removeField, setPercentageValue, setWeightedScore, setLabelFilter },
	] = useFormulaState(
		snippetProviders,
		filteredFieldsByKey,
		initFormula,
		initAsWeightedScore,
		isGlobalField ? availableGlobalFieldsByKey : undefined,
	);
	const { setFormulaDataFieldsByKey } = useActions();

	// figure out which fields we are allowed to mention; if we are a new Rollup, then it's
	// basically anything.  If we are editing an existing Rollup, then it's anything that doesn't
	// reference this field directly or indirectly.
	const nonCircularFields = useMemo(() => {
		const fields = Object.values(filteredFieldsByKey);
		return fieldsReferencableFrom(thisFieldKey, fields);
	}, [filteredFieldsByKey, thisFieldKey]);

	useEffect(() => {
		setFormulaDataFieldsByKey({ ...filteredFieldsByKey, ...availableGlobalFieldsByKey });
	}, [filteredFieldsByKey, setFormulaDataFieldsByKey, availableGlobalFieldsByKey]);

	useEffect(() => {
		if (readonly) {
			return;
		}
		// re-fetch the snippet providers when we mount this component, which covers
		// both the EDIT and the CREATE case
		onThrottledFetchSnippetProviders(true);
	}, [onThrottledFetchSnippetProviders, readonly]);

	const debouncedOnChange = useMemo(
		() => debounce(onChange, FORMULA_CHANGE_DEBOUNCE_DELAY),
		[onChange],
	);

	const finalOnChange = fg('polaris_fields_formula_update_race_condition_fix')
		? debouncedOnChange
		: onChange;

	useEffect(() => {
		if (isUpdated) {
			const formula = createFormula(rollupFields, weightedScore ? percentages : undefined);
			const hasEmptyValue = percentages.some((value) => value === undefined);
			finalOnChange(formula, rollupFields.length, weightedScore && hasEmptyValue);
		}
	}, [finalOnChange, percentages, rollupFields, isUpdated, weightedScore]);

	useEffect(() => {
		if (initAsWeightedScore !== undefined && initAsWeightedScore !== weightedScore) {
			setWeightedScore(initAsWeightedScore);
		}
	}, [initAsWeightedScore, percentages, setWeightedScore, weightedScore]);

	const getPercentageProps = useCallback(
		(index: number): PercentageProps | undefined => {
			if (!weightedScore) {
				return undefined;
			}
			return {
				value: percentages[index],
				onChangePercentage: (percentage) => {
					setPercentageValue(index, percentage);
				},
			};
		},
		[percentages, setPercentageValue, weightedScore],
	);

	const rollupInputs = useMemo(
		() =>
			rollupFields.map((rollupField, index) => ({
				rollupField,
				index,
				percentage: getPercentageProps(index),
			})),
		[rollupFields, getPercentageProps],
	);

	const [positiveRollupInputs, negativeRollupInputs] = useMemo(() => {
		const posInputs: RollupInput[] = [];
		const negInputs: RollupInput[] = [];
		rollupInputs.forEach((rollupInput: RollupInput) => {
			if (rollupInput.rollupField.negative) {
				negInputs.push(rollupInput);
			} else {
				posInputs.push(rollupInput);
			}
		});
		return [posInputs, negInputs];
	}, [rollupInputs]);

	const sharedRollupInputListProps = {
		isWeightedScore: weightedScore,
		readonly,
		onRemoveField: removeField,
		onAddField: addField,
		setPercentageValue,
		displayDataOptions,
		setLabelFilter,
		nonCircularFields,
		isGlobalField,
		displayGlobalFieldIcons,
		isFieldTooltip,
	};

	return (
		<Box xcss={!readonly && editableContainerStyles}>
			{!readonly && weightedScore && (
				<Box paddingBlockStart="space.100" paddingBlockEnd="space.200" xcss={helpTextStyles}>
					<Text size="UNSAFE_small" color="color.text.subtle">
						{formatMessage(messages.weightedScoreFormulaHelp)}
					</Text>
				</Box>
			)}
			<RollupInputList
				{...sharedRollupInputListProps}
				data-testid="polaris-component-field-configuration.ui.configuration.formula.rollup.positive-input"
				rollupInputs={weightedScore ? positiveRollupInputs : rollupInputs}
				hasSeparator={!readonly && weightedScore}
				snippetProviders={snippetProviders}
				sortedSnippetLabels={sortedSnippetLabels}
				isPreview={isPreview}
				title={
					weightedScore
						? formatMessage(messages.positiveInputHeader, {
								count: positiveRollupInputs.length,
							})
						: formatMessage(messages.inputHeader, {
								count: rollupInputs.length,
							})
				}
			/>
			{weightedScore && (
				<>
					<RollupInputList
						{...sharedRollupInputListProps}
						data-testid="polaris-component-field-configuration.ui.configuration.formula.rollup.negative-input"
						rollupInputs={negativeRollupInputs}
						negative
						hasSeparator={!readonly}
						snippetProviders={snippetProviders}
						sortedSnippetLabels={sortedSnippetLabels}
						isPreview={isPreview}
						title={formatMessage(messages.negativeInputHeader, {
							count: negativeRollupInputs.length,
						})}
					/>
					{!readonly && <Section hasSeparator> </Section>}
				</>
			)}
		</Box>
	);
};

const rollupContainerHeaderStyles = xcss({
	color: 'color.text.subtlest',
	flex: '0 0 auto',
	paddingBlock: 'space.200',
});

/* The error InlineMessage is taller than the header. The reduced padding makes up for it */
const rollupContainerHeaderWithErrorStyles = xcss({
	paddingBlock: '0',
});

const editableContainerStyles = xcss({
	marginBottom: 'space.negative.100',
});

const addInputContainerStyles = xcss({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: 'space.100',
});

const addInputSearchContainerStyles = xcss({
	borderBottom: `1px solid ${token('color.border')}`,
});

const addButtonContainerStyles = xcss({
	marginBottom: 'space.150',
});

const rollupFieldsContainerStyles = xcss({
	marginInline: 'space.negative.200',
	paddingBottom: 'space.200',
});

const rollupOptionsContainerStyles = xcss({
	flex: '1 1 auto',
	maxHeight: '300px',
	overflowY: 'auto',
});

const helpTextStyles = xcss({
	whiteSpace: 'pre-line',
});
