import { createSelector } from 'reselect';
import { createHook } from '../index.tsx';
import type { State } from '../types.tsx';

const getShareDialogFlow = (state: State) => state.shareDialog;

const getShareDialogInviteSpotlightDismissed = createSelector(
	getShareDialogFlow,
	(shareDialog) => shareDialog.inviteSpotlightDismissed,
);
export const useGetShareDialogInviteSpotlightDismissed = createHook(
	getShareDialogInviteSpotlightDismissed,
);

const getShareDialogShareSpotlightDismissed = createSelector(
	getShareDialogFlow,
	(shareDialog) => shareDialog.shareSpotlightDismissed,
);
export const useGetShareDialogShareSpotlightDismissed = createHook(
	getShareDialogShareSpotlightDismissed,
);
