import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import getMediaContextQuery from '@atlassian/jira-issue-fetch-services-common/src/services/issue-gira-data/gira-fragments/media-context/index.tsx';
import type { MediaContext } from '@atlassian/jira-issue-gira-transformer-types/src/common/types/media-context.tsx';
import { transformData as transformMediaContext } from '@atlassian/jira-issue-view-services/src/issue/gira/media-context/index.tsx';
import { type IssueKey, toIssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { MediaContextResponse } from '../../../types.tsx';

const buildMediaContextQuery = () => `query polarisMediaContextQuery($issueKey: String!) {
    ${getMediaContextQuery()}
    viewIssue(issueKey: $issueKey) {
        issueId
    }
}`;

const getGiraRequestOptions = (issueKey: IssueKey) => ({
	method: 'POST',
	body: JSON.stringify({
		query: buildMediaContextQuery(),
		variables: {
			issueKey,
		},
	}),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const transformResponse = (response: any): MediaContext | undefined => {
	if (response.data.mediaContext) {
		return transformMediaContext(response.data.mediaContext);
	}
	return undefined;
};

export const fetchGiraMediaContext = (issueKey: IssueKey): Promise<MediaContextResponse> =>
	fetchJson(
		`/rest/gira/1/${fg('jpd-trace-fetch-network-call') ? '?operation=fetchGiraMediaContext' : ''}`,
		getGiraRequestOptions(issueKey),
	).then((response) => ({
		mediaContext: transformResponse(response),
		issueId: toIssueId(response.data.viewIssue.issueId),
	}));
