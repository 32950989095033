import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import type { GlobalFieldRemote } from '@atlassian/jira-polaris-remote-global-field/src/index.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action, StoreActionApi } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types.tsx';
import { updateCopyValueTask } from '../update-copy-value-task/index.tsx';

export const copyValues =
	(
		fromProjectField: Field,
		toGlobalField: Field,
		fieldOptionsValueMapping?: Record<string, string | undefined>,
		onComplete?: () => void,
		onProgressPollingError?: () => void,
	): Action<State, Props, Promise<void>> =>
	async ({ dispatch }, { globalFieldRemote, projectId, createAnalyticsEvent }) => {
		if (!fromProjectField) {
			throw new Error('Source field is required');
		}

		if (!toGlobalField) {
			throw new Error('Target field is required');
		}

		if (!projectId) {
			throw new Error('Project ID is required');
		}

		try {
			experience.globalFieldsAdministration.copyValues.start();

			const { taskId } = await globalFieldRemote.copyValuesToGlobalField(
				fromProjectField.key,
				toGlobalField.key,
				projectId,
				fieldOptionsValueMapping,
			);

			startTaskProgressPolling(
				{ taskId, fromFieldLabel: fromProjectField.label, toFieldLabel: toGlobalField.label },
				dispatch,
				globalFieldRemote,
				onComplete,
				onProgressPollingError,
			);

			fireTrackAnalytics(createAnalyticsEvent({}), 'jpd.copyValues started');
		} catch (err) {
			if (isClientFetchError(err)) {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				experience.globalFieldsAdministration.copyValues.abort(err as Error);
			}

			if (err instanceof Error) {
				experience.globalFieldsAdministration.copyValues.failure(err);
			}

			throw err;
		}
	};

const startTaskProgressPolling = async (
	taskInformation: {
		taskId: string;
		fromFieldLabel: string;
		toFieldLabel: string;
	},
	dispatch: StoreActionApi<State>['dispatch'],
	globalFieldRemote: GlobalFieldRemote,
	onComplete?: () => void,
	onProgressPollingError?: () => void,
) => {
	const MAX_RETRIES_LIMIT = 5;
	const POLLING_INTERVAL = 3000;

	let numberOfRetries = 0;

	const pollUpdaterTaskProgress = async () => {
		try {
			const taskProgress = await globalFieldRemote.getCopyValuesProgress(taskInformation.taskId);

			numberOfRetries = 0;

			const isDone = taskProgress.status === 'DONE';
			const isFailed = taskProgress.status === 'FAILED';

			const taskStopped = isDone || isFailed;

			if (taskStopped) {
				if (isDone) {
					experience.globalFieldsAdministration.copyValues.success();
				} else {
					experience.globalFieldsAdministration.copyValues.failure(
						new Error('Copy Values task failed'),
					);
				}

				onComplete?.();
			}

			dispatch(
				updateCopyValueTask(taskInformation.taskId, {
					...taskProgress,
					fromFieldLabel: taskInformation.fromFieldLabel,
					toFieldLabel: taskInformation.toFieldLabel,
				}),
			);

			return taskStopped;
		} catch (err) {
			numberOfRetries++;

			if (numberOfRetries >= MAX_RETRIES_LIMIT) {
				onProgressPollingError?.();

				if (isClientFetchError(err)) {
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					experience.globalFieldsAdministration.copyValues.abort(err as Error);
				} else {
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					experience.globalFieldsAdministration.copyValues.failure(err as Error);
				}

				return true;
			}

			return false;
		}
	};

	const isStopped = await pollUpdaterTaskProgress();

	if (isStopped) {
		return;
	}

	const intervalId = setInterval(async () => {
		const taskStopped = await pollUpdaterTaskProgress();

		if (taskStopped) {
			clearInterval(intervalId);
		}
	}, POLLING_INTERVAL);
};
