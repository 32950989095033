// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import {
	removeRoleForActor,
	removeRoleForGroup,
	removeRoleForUser,
} from '../../../services/jira/remove-role-for-actor/index.tsx';
import { isIdentityBasedContributorHandlingEnabled } from '../../selectors/contributor-group.tsx';
import { getGroupNameToGroupIdMapping } from '../../selectors/project-actors.tsx';
import { type State, type Props, type ActorType, GROUP_ROLE_ACTOR } from '../../types.tsx';

export const removeActorRole =
	(actorsRoleTypeId: string, actorsType: ActorType, numericRoleId: number): Action<State, Props> =>
	({ getState, setState }, { projectId, projectKey, onError }) => {
		const oldState = getState();

		if (oldState.projectActors.data === undefined) {
			return;
		}

		const newActors = oldState.projectActors.data.actors.map((actor) => {
			if (actor.roleTypeId !== actorsRoleTypeId) {
				return actor;
			}
			return {
				...actor,
				roles: actor.roles.filter((id) => id !== numericRoleId),
			};
		});

		setState({
			...oldState,
			projectActors: {
				...oldState.projectActors,
				data: {
					...oldState.projectActors.data,
					actors: newActors,
				},
			},
		});

		if (isIdentityBasedContributorHandlingEnabled(oldState)) {
			if (actorsType === GROUP_ROLE_ACTOR) {
				const groupId = getGroupNameToGroupIdMapping(oldState)[actorsRoleTypeId];
				if (groupId) {
					removeRoleForGroup(numericRoleId, groupId, projectId).catch(onError);
				}
			} else {
				removeRoleForUser(numericRoleId, actorsRoleTypeId, projectId).catch(onError);
			}
		} else {
			removeRoleForActor({
				numericRoleId,
				projectKey,
				actorsRoleTypeId,
				isGroup: actorsType === GROUP_ROLE_ACTOR,
			})
				// eslint-disable-next-line @typescript-eslint/no-empty-function
				.then(() => {})
				.catch((err) => onError(err));
		}
	};
