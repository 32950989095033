import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { StandardFieldType as NewStandardFieldType } from '@atlassian/jira-polaris-domain-field/src/field-types/types.tsx';
import type {
	SnippetProvider,
	SnippetProviderApp,
	SnippetProviderProperty,
} from '@atlassian/jira-polaris-domain-field/src/snippet/types.tsx';
import { ENTITY_LIMIT_TYPE } from '@atlassian/jira-polaris-domain-project/src/constants.tsx';
import { isInternalOauthClientId } from '../../../../common/types/snippet/constants.tsx';
import { messages } from '../common/messages.tsx';

export const PolarisIdeaAppPropertyFieldType = 'PolarisIdeaAppPropertyField';

export type FormValues = {
	fieldName?: string;
	fieldType?: FieldTypeOption;
	appProperty?: AppPropertyOption;
	formulaType?: CustomFormulaOption;
	maxSpend?: number;
};

export type AvailableFieldType =
	| NewStandardFieldType
	| typeof FIELD_TYPES.FORMULA
	| typeof FIELD_TYPES.VOTES
	| typeof PolarisIdeaAppPropertyFieldType;

export type FieldTypeOption = {
	label: string;
	value: AvailableFieldType;
	app?: SnippetProviderApp;
	isDisabled?: boolean;
	tooltipMessage?: string | null;
};

export type FieldTypeOptionGroup = {
	label: string;
	options: FieldTypeOption[];
};

export type CustomFormulaOption = {
	label: string;
	value: 'expression' | 'weightedScore' | 'rollup';
};

export type AppPropertyOption = {
	label: string;
	value?: SnippetProviderProperty;
	isNumDataPoints?: boolean;
	app: SnippetProviderApp;
};

export const getFieldTypeOptionGroups = (
	formatMessage: (arg1: MessageDescriptor) => string,
	snippetProviders: SnippetProvider[],
	fieldsLimit: {
		[ENTITY_LIMIT_TYPE.FIELDS_PER_PROJECT]: string | null;
		[ENTITY_LIMIT_TYPE.PLAYS_PER_PROJECT]: string | null;
	},
	connectionsEnabled?: boolean,
): FieldTypeOptionGroup[] => {
	const externalSnippetProviders: SnippetProvider[] = snippetProviders.filter(
		(snippetProvider) =>
			!isInternalOauthClientId(snippetProvider.app.oauthClientId) &&
			snippetProvider.properties.length,
	);

	const isStandardFieldsAtLimit = fieldsLimit[ENTITY_LIMIT_TYPE.FIELDS_PER_PROJECT];
	const isVotesFieldAtLimit = fieldsLimit[ENTITY_LIMIT_TYPE.PLAYS_PER_PROJECT];
	const standardFieldLimitProps = {
		isDisabled: !!isStandardFieldsAtLimit,
		tooltipMessage: isStandardFieldsAtLimit,
	};

	const groups = [
		{
			label: formatMessage(messages.standardFieldGroupType),
			options: [
				{
					...standardFieldLimitProps,
					label: formatMessage(messages.shortTextFieldType),
					value: FIELD_TYPES.SHORT_TEXT,
				},
				{
					...standardFieldLimitProps,
					label: formatMessage(messages.numberFieldType),
					value: FIELD_TYPES.NUMBER,
				},
				{
					...standardFieldLimitProps,
					label: formatMessage(messages.dropdownFieldType),
					value: FIELD_TYPES.SINGLE_SELECT,
				},
				{
					...standardFieldLimitProps,
					label: formatMessage(messages.checkboxFieldType),
					value: FIELD_TYPES.MULTI_SELECT,
				},
				{
					...standardFieldLimitProps,
					label: formatMessage(messages.userPickerFieldType),
					value: FIELD_TYPES.PEOPLE,
				},
				{
					...standardFieldLimitProps,
					label: formatMessage(messages.ratingFieldType),
					value: FIELD_TYPES.RATING,
				},
				{
					...standardFieldLimitProps,
					label: formatMessage(messages.dateFieldType),
					value: FIELD_TYPES.INTERVAL,
				},
				{
					...standardFieldLimitProps,
					label: formatMessage(messages.sliderFieldType),
					value: FIELD_TYPES.SLIDER,
				},
				{
					...standardFieldLimitProps,
					label: formatMessage(messages.checkboxPresentationFieldType),
					value: FIELD_TYPES.CHECKBOX,
				},
				{
					...standardFieldLimitProps,
					label: formatMessage(messages.hyperlinkFieldType),
					value: FIELD_TYPES.HYPERLINK,
				},
				{
					...standardFieldLimitProps,
					label: formatMessage(messages.reactionsFieldType),
					value: FIELD_TYPES.REACTIONS,
				},
				...(connectionsEnabled
					? [
							{
								...standardFieldLimitProps,
								label: formatMessage(messages.connectionFieldType),
								value: FIELD_TYPES.CONNECTION,
							},
						]
					: []),
			],
		},
		{
			label: formatMessage(messages.dynamicFieldGroupType),
			options: [
				{
					...standardFieldLimitProps,
					label: formatMessage(messages.polarisCalculatedFieldType),
					value: FIELD_TYPES.FORMULA,
				},
				{
					label: formatMessage(messages.playFieldType),
					value: FIELD_TYPES.VOTES,
					isDisabled: !!(isVotesFieldAtLimit || isStandardFieldsAtLimit),
					tooltipMessage: isVotesFieldAtLimit || isStandardFieldsAtLimit,
				},
			],
		},
		...(externalSnippetProviders.length
			? [
					{
						label: formatMessage(messages.dataFieldGroupType),
						options: externalSnippetProviders.map(
							(snippetProvider): FieldTypeOption => ({
								label: snippetProvider.app.name,
								app: snippetProvider.app,
								value: PolarisIdeaAppPropertyFieldType,
								isDisabled: !!isStandardFieldsAtLimit,
								tooltipMessage: isStandardFieldsAtLimit,
							}),
						),
					},
				]
			: []),
	];

	groups.forEach((group) => group.options.sort((a, b) => a.label.localeCompare(b.label)));

	return groups;
};

export const getCustomFormulaOptions = (
	formatMessage: (arg1: MessageDescriptor) => string,
): CustomFormulaOption[] => [
	{
		label: formatMessage(messages.customFormulaRollupOption),
		value: 'rollup',
	},
	{
		label: formatMessage(messages.customFormulaWeightedScoreOption),
		value: 'weightedScore',
	},
	{
		label: formatMessage(messages.customFormulaExpressionOption),
		value: 'expression',
	},
];

export const getAppPropertyOptions = (
	formatMessage: (arg1: MessageDescriptor) => string,
	oauthClientId: string | undefined,
	snippetProviders: SnippetProvider[],
): AppPropertyOption[] => {
	if (oauthClientId === undefined) {
		return [];
	}

	const selectedSnippetProvider = snippetProviders.find(
		(snippetProvider) => snippetProvider.app.oauthClientId === oauthClientId,
	);

	if (selectedSnippetProvider === undefined) {
		return [];
	}

	return [
		...selectedSnippetProvider.properties
			.filter((property) => property.kind.toLowerCase() === 'rating')
			.map((property) => ({
				label: property.label,
				value: property,
				app: selectedSnippetProvider.app,
			})),
		...selectedSnippetProvider.properties
			.filter((property) => property.kind.toLowerCase() === 'number')
			.map((property) => ({
				label: property.label,
				value: property,
				app: selectedSnippetProvider.app,
			})),
	];
};
