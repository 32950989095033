import React, { type ReactNode } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';

type CardProps = {
	title: string;
	footer: string;
	children: ReactNode;
	onClick: () => void;
};

export const Card = ({ title, footer, children, onClick }: CardProps) => (
	<Wrapper
		data-testid="polaris-ideas.ui.insights.insights.play-insight.card.wrapper"
		onClick={onClick}
	>
		<TitleWrapper>{title}</TitleWrapper>
		<ContentWrapper>{children}</ContentWrapper>
		<FooterWrapper>{footer}</FooterWrapper>
	</Wrapper>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div({
	display: 'flex',
	flex: 1,
	flexDirection: 'column',
	justifyContent: 'space-between',
	paddingTop: token('space.100'),
	paddingRight: token('space.100'),
	paddingBottom: token('space.100'),
	paddingLeft: token('space.100'),
	maxWidth: '128px',
	backgroundColor: token('elevation.surface.raised'),
	boxShadow: token('elevation.shadow.raised'),
	borderRadius: '3px',
	'&:hover': {
		cursor: 'pointer',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TitleWrapper = styled.h2({
	color: token('color.text.subtle'),
	font: token('font.body.UNSAFE_small'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContentWrapper = styled.h6({
	color: token('color.text'),
	font: token('font.heading.medium'),
	marginTop: token('space.075'),
	marginRight: 0,
	marginBottom: token('space.075'),
	marginLeft: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FooterWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
	marginTop: token('space.025'),
	color: token('color.text.subtlest'),
	font: token('font.body.small'),
});
