import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	issuePickerDefaultGroupLabel: {
		id: 'polaris-common.common.issue-select.issue-picker-default-group-label',
		defaultMessage: 'In this project',
		description:
			'Option group label for merge ideas issue select dropdown content. This option group contains all project ideas',
	},
});
