import React, { type ReactNode } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { RIGHT_SIDEBAR_WIDTH_CSS_VARIABLE } from '../../../../../controllers/right-sidebar/utils/sidebar-width-css-variable.tsx';

type Props = {
	scopes: (() => ReactNode)[];
};

export const ScopePicker = ({ scopes }: Props) => {
	return (
		scopes.length > 0 && (
			<Box
				xcss={pickerStyles}
				// eslint-disable-next-line jira/react/no-style-attribute, @atlaskit/ui-styling-standard/no-imported-style-values
				style={{ width: `calc(var(${RIGHT_SIDEBAR_WIDTH_CSS_VARIABLE}) - ${token('space.400')})` }}
			>
				<Box xcss={[boxStyle, withSeparator]}>{scopes[0]()}</Box>
				{scopes.slice(1).map((scope, index) => {
					return (
						<Box xcss={boxStyle} key={index}>
							{scope()}
						</Box>
					);
				})}
			</Box>
		)
	);
};

const pickerStyles = xcss({
	backgroundColor: 'elevation.surface',
	boxShadow: 'elevation.shadow.raised',
});

const withSeparator = xcss({
	borderBottomColor: 'color.border',
	borderBottomWidth: '1px',
	borderBottomStyle: 'solid',
});

const boxStyle = xcss({
	padding: 'space.150',
});
