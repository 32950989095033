import React from 'react';
import uniqBy from 'lodash/uniqBy';
import {
	useEnvironmentContainer,
	PolarisEnvironmentContainerTypes,
} from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import { useIssueTypeValuesForProject } from '@atlassian/jira-polaris-component-issue-types/src/controllers/index.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useIssueTypesValuesForCurrentContainerProjectIds } from '@atlassian/jira-polaris-component-issue-types/src/utils.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useViewActions } from '../../../../controllers/views/main.tsx';
import { useFieldFilter } from '../../../../controllers/views/selectors/filters-hooks.tsx';
import { MultiOptionComponent } from '../multi-option-component/index.tsx';
import type { FilterComponentProps } from '../types.tsx';
import { FilterWrapper } from '../filter-wrapper/filter-wrapper.tsx';

export const IssueTypeFilter = ({
	field,
	isLastFilter,
	defaultOpen,
	isDisabled,
	isUsingCustomColor,
	onChange,
	onClear,
}: FilterComponentProps) => {
	const { updateFieldFilter, clearFieldFilter } = useViewActions();

	const fieldFilter = useFieldFilter(field.key);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const container = useEnvironmentContainer();
	const projectId =
		container?.type === PolarisEnvironmentContainerTypes.PROJECT ? container?.projectId : '';
	const issueTypeValues = useIssueTypeValuesForProject({ projectId });
	const issueTypeValuesForCurrentContainerProjectIds =
		useIssueTypesValuesForCurrentContainerProjectIds();

	const filterOptions = fg('jpd_view_config_using_issue_type_name')
		? uniqBy(issueTypeValuesForCurrentContainerProjectIds, 'name').map((value) => ({
				value,
				groupIdentity: value.name,
			}))
		: issueTypeValues.map((value) => ({
				value,
				groupIdentity: value.id,
			}));

	return (
		<FilterWrapper>
			<MultiOptionComponent
				field={field}
				filter={fieldFilter}
				isLastFilter={isLastFilter}
				defaultOpen={defaultOpen}
				setFieldFilter={(values) => {
					fireUIAnalytics(createAnalyticsEvent({}), 'issueTypeFilter updated', 'view-controls', {
						fieldKey: field.key,
						fieldType: field.type,
					});

					if (values.length) {
						onChange?.();
						experience.headerView.viewFilter.start();
						updateFieldFilter(
							{
								...fieldFilter,
								values: values.map((stringValue) => ({ stringValue })),
							},
							() => {
								experience.headerView.viewFilter.success();
							},
							(error?: Error) => {
								experience.headerView.viewFilter.failure(error);
							},
						);
					} else {
						onClear?.();
						experience.headerView.viewFilter.start();
						clearFieldFilter(
							fieldFilter.field,
							() => {
								experience.headerView.viewFilter.success();
							},
							(error?: Error) => {
								experience.headerView.viewFilter.failure(error);
							},
						);
					}
				}}
				options={filterOptions}
				isDisabled={isDisabled}
				isUsingCustomColor={isUsingCustomColor}
			/>
		</FilterWrapper>
	);
};
