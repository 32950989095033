// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	type Action,
	createStore,
	createActionsHook,
	createContainer,
} from '@atlassian/react-sweet-state';
import { DEFAULT_ITEM_HEIGHT, ScaleEnum } from '../common/constants.tsx';
import type { Props } from '../types.tsx';
import * as actions from './actions/index.tsx';
import type { State } from './types.tsx';

export const initialState: State = {
	items: {},
	headers: [],
	components: undefined,
	groupIds: undefined,
	rowGroups: [],
	isReadOnly: false,
	isSorted: false,
	hideEmptyGroups: false,
	isGroupByFieldEditable: false,
	isVerticalCardReorderingEnabled: true,
	isMoveBetweenGroupsDisabled: false,
	itemHeight: 0,
	columnWidth: 0,
	itemArrangement: {},
	resizePosition: undefined,
	scale: ScaleEnum.QUARTERS,
	startDate: new Date(),
	endDate: new Date(),
	hasOffset: false,
	markers: [],
	isMarkerReadOnly: false,
};

export type Actions = typeof actions;

export const TimelineStore = createStore<State, Actions>({
	initialState,
	actions,
	name: 'PolarisTimelineComponentStore',
});

const updateStateFromProps =
	(): Action<State, Props> =>
	(
		{ setState, getState, dispatch },
		{
			items = [],
			headers,
			components,
			groupedIds,
			rowGroups,
			isReadOnly,
			isSorted,
			hideEmptyGroups,
			itemHeight,
			itemArrangement,
			isGroupByFieldEditable,
			isMoveBetweenGroupsDisabled,
			scale,
			startDate,
			endDate,
			markers,
			isMarkerReadOnly,
			minColumnWidth,
			hasOffset,
			isVerticalCardReorderingEnabled = true,
		},
	) => {
		const state = getState();
		setState({
			headers,
			rowGroups,
			isReadOnly,
			isSorted,
			hideEmptyGroups,
			isGroupByFieldEditable,
			isMoveBetweenGroupsDisabled,
			isVerticalCardReorderingEnabled,
			itemHeight: itemHeight ?? DEFAULT_ITEM_HEIGHT,
			scale,
			startDate,
			endDate,
			isMarkerReadOnly,
			minColumnWidth,
			hasOffset,
		});
		dispatch(actions.updateInitialGroupState(groupedIds, items));
		dispatch(actions.updateComponentsState(components));
		dispatch(actions.initMarkersState(markers));
		dispatch(actions.initItemState(items, itemArrangement));
		// Recalculate column width when props change. This is done on the controller level to avoid unnecessary re-renders
		dispatch(actions.setColumnWidth(false, state.scale));
	};

export const TimelineContainer = createContainer<State, Actions, Props>(TimelineStore, {
	onInit: updateStateFromProps,
	onUpdate: updateStateFromProps,
});

export const useTimelineActions = createActionsHook<State, Actions>(TimelineStore);
