import React from 'react';
import { useField } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/fields-hooks.tsx';
import {
	useIsSelectedIssueArchived,
	useSelectedIssue,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { ReactionsField } from '@atlassian/jira-polaris-common/src/ui/fields/reactions/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { useCanEditIssues } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { fg } from '@atlassian/jira-feature-gating';

type Props = {
	fieldKey: FieldKey;
};

export const Reactions = ({ fieldKey }: Props) => {
	const localIssueId = useSelectedIssue();
	const field = useField(fieldKey);
	const [canEditIssues] = useCanEditIssues();
	const isIdeaArchived = useIsSelectedIssueArchived();

	if (field === undefined || localIssueId === undefined) {
		return null;
	}

	return (
		<ReactionsField
			fieldKey={fieldKey}
			localIssueId={localIssueId}
			isEditable={
				fg('jpd_cross_project_connecting')
					? canEditIssues && field.editable && !isIdeaArchived
					: true
			}
		/>
	);
};
