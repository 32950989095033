import React, {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
	type PropsWithChildren,
} from 'react';
import noop from 'lodash/noop';
import { fg } from '@atlassian/jira-feature-gating';
import { useNotifications } from '@atlassian/jira-polaris-lib-notifications/src/controllers/index.tsx';
import { logSafeErrorWithoutCustomerDataWrapper } from '@atlassian/jira-polaris-lib-errors/src/common/utils/index.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { useIsConnectionsEnabled } from '@atlassian/jira-polaris-lib-entitlement-utils/src/index.tsx';
import { functionWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { useIntl } from '@atlassian/jira-intl';
import { fetchConnectionsEAPConfig, updateConnectionsEAPConfig } from '../service/index.tsx';
import { messages } from './messages.tsx';

type State = {
	connectionsEnabled: boolean | undefined;
};

type ContextValue = {
	state: State;
	updateConnectionsEnabled: (enabled: boolean) => void;
};

const Context = createContext<ContextValue | null>(null);

export const EarlyAccessProgramContainer = componentWithFG(
	'jpd_issues_relationships_eap_config_fe',
	({ children }: PropsWithChildren) => {
		const { formatMessage } = useIntl();
		const { errorWithRefresh } = useNotifications();
		const [state, setState] = useState<State>({
			connectionsEnabled: undefined,
		});

		useEffect(() => {
			fetchConnectionsEAPConfig().then(({ enabled }) => {
				setState({
					connectionsEnabled: enabled,
				});
			});
		}, []);

		const updateConnectionsEnabled = useCallback(
			async (enabled: boolean) => {
				try {
					setState({ connectionsEnabled: enabled });
					await updateConnectionsEAPConfig(enabled);
				} catch (error) {
					setState({ connectionsEnabled: !enabled });

					errorWithRefresh({
						title: formatMessage(messages.errorTitle),
						description: formatMessage(messages.errorDescription),
					});

					if (error instanceof Error) {
						logSafeErrorWithoutCustomerDataWrapper(
							'polaris.error.updateConnectionsEAPConfig',
							error.message,
							error,
						);
					}
				}
			},
			[errorWithRefresh, formatMessage],
		);

		const value = useMemo(
			() => ({ state, updateConnectionsEnabled }),
			[state, updateConnectionsEnabled],
		);

		return <Context.Provider value={value}>{children}</Context.Provider>;
	},
	({ children }: PropsWithChildren) => children,
);

export const useIsConnectionsFeatureEnabled = functionWithCondition(
	() => fg('jpd_issues_relationships_eap_config_fe'),
	() => {
		const context = useContext(Context);
		const isConnectionsEntitlementEnabled = useIsConnectionsEnabled();

		if (context?.state.connectionsEnabled === undefined) {
			return undefined;
		}

		return context.state.connectionsEnabled && isConnectionsEntitlementEnabled;
	},
	() => fg('jpd_issues_relationships'),
);

export const useUpdateConnectionsEnabled = () => {
	const context = useContext(Context);

	return context?.updateConnectionsEnabled ?? noop;
};
