import React, { memo, type SyntheticEvent } from 'react';
import { cssMap, styled } from '@compiled/react';
import isEmpty from 'lodash/isEmpty';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import EditorCloseIcon from '@atlaskit/icon/core/migration/close--editor-close';
import { token } from '@atlaskit/tokens';
import { Box, type BoxProps } from '@atlaskit/primitives/compiled';
import { useIntl } from '@atlassian/jira-intl';
import { useFullscreenQueryParameter } from '@atlassian/jira-polaris-common/src/controllers/route/index.tsx';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import { useCurrentViewContainsArchived } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import {
	useCanEditIssues,
	useCanDeleteIssues,
	useCanMergeIdeas,
	useCanArchiveIdeas,
	useCanManageDeliveryTickets,
} from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { fg } from '@atlassian/jira-feature-gating';
import { useIsCollectionView } from '@atlassian/jira-polaris-common/src/controllers/environment/index.tsx';
import { useVisibleSelectedIssueIds } from '../../utils.tsx';
import ArchiveIdeasButton from './archive-ideas/index.tsx';
import DeleteIdeasButton from './delete-ideas/index.tsx';
import { MergeIdeasButton } from './merge-ideas/index.tsx';
import { messages } from './messages.tsx';
import { SendToBacklogButton } from './send-to-backlog/index.tsx';
import { SendToTopButton } from './send-to-top/index.tsx';
import UnarchiveIdeasButton from './unarchive-ideas/index.tsx';
import { BarActionButton } from './bar-action-button/index.tsx';

const styles = cssMap({
	controlsButtonContent: {
		position: 'absolute',
		bottom: '0',
		backgroundColor: token('color.background.neutral.bold.pressed'),
		boxShadow: token('elevation.shadow.overlay'),
		borderRadius: '8px',
		paddingTop: token('space.100'),
		paddingRight: token('space.100'),
		paddingBottom: token('space.100'),
		paddingLeft: token('space.100'),
		color: token('color.text.inverse'),
		display: 'flex',
		alignItems: 'stretch',
	},
});

const ManageBarUnmemoized = () => {
	const isCollectionView = useIsCollectionView();
	const canRankIssues = useCanEditIssues()[0] && !isCollectionView;
	const [canDeleteIssues] = useCanDeleteIssues({ anyProject: true });
	const canMergeIdeas = useCanMergeIdeas() && !isCollectionView;
	const canArchiveIdeas = useCanArchiveIdeas({ anyProject: true });
	const canManageDeliveryTickets = useCanManageDeliveryTickets({ anyProject: true });
	const containsArchived = useCurrentViewContainsArchived();
	const visibleSelectedIssueIds = useVisibleSelectedIssueIds();
	const { formatMessage } = useIntl();
	const fullscreen = useFullscreenQueryParameter();

	const { clearSelection } = useViewActions();

	const onClose = (_event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
		fireUIAnalytics(analyticsEvent, 'clearSelection');
		clearSelection(visibleSelectedIssueIds);
	};

	if (isEmpty(visibleSelectedIssueIds)) {
		return null;
	}

	const canDisplayFirstSection =
		!containsArchived &&
		(!fg('jpd-aurora-roadmap-inline-edit') ||
			canRankIssues ||
			canMergeIdeas ||
			canManageDeliveryTickets);

	const canDisplayArchiveAction = !containsArchived && canArchiveIdeas;
	const canDisplayUnarchiveAction = containsArchived && canArchiveIdeas;

	const canDisplaySecondSection =
		!fg('jpd-aurora-roadmap-inline-edit') ||
		canDisplayArchiveAction ||
		canDisplayUnarchiveAction ||
		canDeleteIssues;

	const ControlsButtonContentComponent = fg('jpd-aurora-roadmap-inline-edit')
		? Box
		: ControlsButtonContent;
	const controlsButtonContentProps: Pick<BoxProps<'div'>, 'xcss'> | {} = fg(
		'jpd-aurora-roadmap-inline-edit',
	)
		? { xcss: styles.controlsButtonContent }
		: {};

	return (
		<ControlsButtonContainer isFullscreen={fullscreen}>
			<ControlsButtonContentComponent {...controlsButtonContentProps}>
				<LabelContainer>
					{fg('jpd-aurora-roadmap-inline-edit') ? (
						<BarActionButton
							spacing="none"
							iconBefore={
								<EditorCloseIcon
									label={formatMessage(messages.closeButtonLabel)}
									LEGACY_size="medium"
									spacing="spacious"
								/>
							}
							onClick={onClose}
						/>
					) : (
						<Button
							appearance="subtle"
							spacing="none"
							iconBefore={
								<EditorCloseIcon
									label={formatMessage(messages.closeButtonLabel)}
									LEGACY_size="medium"
									spacing="spacious"
								/>
							}
							onClick={onClose}
						/>
					)}
					<Label>{formatMessage(messages.label, { count: visibleSelectedIssueIds.length })}</Label>
				</LabelContainer>
				{canDisplayFirstSection && (
					<ButtonsWrapper>
						{canRankIssues && <SendToTopButton />}
						{canMergeIdeas && <MergeIdeasButton />}
						{canManageDeliveryTickets && <SendToBacklogButton />}
					</ButtonsWrapper>
				)}
				{canDisplaySecondSection && (
					<ButtonsWrapper>
						{canDisplayArchiveAction && <ArchiveIdeasButton />}
						{canDisplayUnarchiveAction && <UnarchiveIdeasButton />}
						{canDeleteIssues && <DeleteIdeasButton />}
					</ButtonsWrapper>
				)}
			</ControlsButtonContentComponent>
		</ControlsButtonContainer>
	);
};

ManageBarUnmemoized.displayName = 'ManageBar';

export const ManageBar = memo(ManageBarUnmemoized);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ControlsButtonContainer = styled.div<{
	isFullscreen: boolean;
}>({
	position: 'fixed',
	bottom: token('space.300'),
	zIndex: 3,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	left: ({ isFullscreen }) => (isFullscreen ? 0 : 'calc(var(--leftSidebarWidth, 0px) + 16px)'),
	width: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	maxWidth: ({ isFullscreen }) =>
		isFullscreen ? '100%' : 'calc(100vw - var(--leftSidebarWidth) - 32px)',
	display: 'flex',
	justifyContent: 'center',
});

// should be delete after FG cleanup jpd-aurora-roadmap-inline-edit
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ControlsButtonContent = styled.div({
	position: 'absolute',
	bottom: '0',
	backgroundColor: token('color.background.neutral.bold.pressed'),
	boxShadow: '0px 0px 1px 0px rgba(9, 30, 66, 0.31), 0px 8px 12px 0px rgba(9, 30, 66, 0.15)',
	borderRadius: '8px',
	paddingTop: token('space.100'),
	paddingRight: token('space.100'),
	paddingBottom: token('space.100'),
	paddingLeft: token('space.100'),
	color: token('color.text.inverse'),
	display: 'flex',
	alignItems: 'stretch',

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	button: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		color: `${token('color.text.inverse')} !important`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
		':hover': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
			color: `${token('color.text.inverse')} !important`,
			backgroundColor: token('color.background.neutral.bold.hovered'),
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
		':active': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
			color: `${token('color.text.inverse')} !important`,
			backgroundColor: token('color.background.neutral.bold'),
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'&[disabled]': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
			color: `${token('color.text.subtlest')} !important`,
		},
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonsWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
	flexWrap: 'wrap',
	gap: token('space.100'),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	paddingLeft: '9px',
	paddingRight: token('space.100'),
	position: 'relative',

	'&::before': {
		content: '',
		top: '3px',
		position: 'absolute',
		bottom: '3px',
		width: '1px',
		left: '0px',
		backgroundColor: token('color.border.accent.gray'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LabelContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	gap: token('space.100'),
	paddingRight: token('space.100'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Label = styled.div({
	font: token('font.body'),
	fontWeight: token('font.weight.semibold'),
	paddingRight: token('space.200'),
});
