import isEqual from 'lodash/isEqual';
import uniq from 'lodash/uniq';
import without from 'lodash/without';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { RowGroupId, RowId } from '../../../common/types/index.tsx';
import { NO_VALUE_GROUP_ID } from '../../../constants.tsx';
import type { State, Props, InlineRowCreationConfig } from '../../types.tsx';

export const updateSelection =
	(newSelectedRows?: RowId[]) =>
	({ setState, getState }: StoreActionApi<State>) => {
		const { selectedRows } = getState();

		if (!isEqual(selectedRows, newSelectedRows)) {
			setState({ selectedRows: newSelectedRows });
		}
	};

export const setRowSelection =
	(rowId: RowId, selected: boolean) =>
	({ setState, getState }: StoreActionApi<State>, { onSelectionChanged }: Props) => {
		const { selectedRows } = getState();

		if (selectedRows === undefined) {
			// selection disabled, bail
			return;
		}

		const newSelectedRows = selected ? [...selectedRows, rowId] : without(selectedRows, rowId);

		if (!isEqual(selectedRows, newSelectedRows)) {
			setState({ selectedRows: newSelectedRows });
			onSelectionChanged && onSelectionChanged(newSelectedRows);
		}
	};

export const selectAllRows =
	(state = true) =>
	({ setState, getState }: StoreActionApi<State>, { onSelectionChanged }: Props) => {
		const { selectedRows, rows } = getState();

		if (selectedRows === undefined) {
			// selection disabled, bail
			return;
		}

		const newSelectedRows = state ? [...rows] : [];

		if (!isEqual(selectedRows, newSelectedRows)) {
			setState({ selectedRows: newSelectedRows, lastSelectedRow: undefined });
			onSelectionChanged && onSelectionChanged(newSelectedRows);
		}
	};

export const selectAllRowsInGroup =
	(groupId: RowGroupId, state = true) =>
	({ setState, getState }: StoreActionApi<State>, { onSelectionChanged }: Props) => {
		const { selectedRows, groupedIds } = getState();

		if (selectedRows === undefined || groupedIds === undefined) {
			// selection disabled or no groups, bail
			return;
		}

		const rowIds =
			groupId === NO_VALUE_GROUP_ID ? groupedIds.empty || [] : groupedIds.groups[groupId] || [];

		const newSelectedRows = state
			? uniq([...selectedRows, ...rowIds])
			: selectedRows.filter((id) => !rowIds.includes(id));

		if (!isEqual(selectedRows, newSelectedRows)) {
			setState({ selectedRows: newSelectedRows });
			onSelectionChanged && onSelectionChanged(newSelectedRows);
		}
	};

export const extendSelectionFromLastSelectedRow =
	(endRowId: RowId) =>
	({ setState, getState }: StoreActionApi<State>, { onSelectionChanged }: Props) => {
		const { selectedRows, rows, rowGroups, groupedIds, lastSelectedRow } = getState();

		if (selectedRows === undefined) {
			// selection disabled, bail
			return;
		}

		if (!lastSelectedRow) {
			return;
		}

		const groups = rowGroups ?? [];
		const targetRows =
			groups.length === 0
				? rows
				: [
						...groups.flatMap((groupId) =>
							groupId !== undefined ? groupedIds?.groups[groupId] ?? [] : [],
						),
						...(groupedIds?.empty ?? []),
						...(groupedIds?.noGroup ?? []),
					];

		const selectedRowsSet = new Set(selectedRows);
		const isEndRowSelected = selectedRowsSet.has(endRowId);
		const startRowIndex = targetRows.indexOf(lastSelectedRow);
		const endRowIndex = targetRows.indexOf(endRowId);
		const fromIndex = Math.min(startRowIndex, endRowIndex);
		const toIndex = Math.max(startRowIndex, endRowIndex);

		for (let index = fromIndex; index <= toIndex; index++) {
			const rowId = targetRows[index];
			if (isEndRowSelected) {
				selectedRowsSet.delete(rowId);
			} else {
				selectedRowsSet.add(rowId);
			}
		}

		const newSelectedRows: string[] = Array.from(selectedRowsSet);

		setState({ selectedRows: newSelectedRows });
		onSelectionChanged && onSelectionChanged(newSelectedRows);
	};

export const setLastSelectedRow =
	(lastSelectedRow?: RowId) =>
	({ setState }: StoreActionApi<State>) => {
		setState({ lastSelectedRow });
	};

export const setHoveredRow =
	(hoveredRow?: RowId) =>
	({ setState }: StoreActionApi<State>) => {
		setState({ hoveredRow });
	};

export const setActiveInlineRowCreation =
	(activeInlineRowCreation?: InlineRowCreationConfig) =>
	({ setState }: StoreActionApi<State>) => {
		setState({ activeInlineRowCreation });
	};
