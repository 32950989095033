import React, { type SyntheticEvent, useCallback, useState } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { LoadingButton } from '@atlaskit/button';
import EditorAddIcon from '@atlaskit/icon/core/migration/add--editor-add';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { useFieldActions } from '@atlassian/jira-polaris-common/src/controllers/field/main.tsx';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import { usePermanentFieldFilter } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/filters-hooks.tsx';
import { useCurrentViewHideEmptyBoardColumns } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { useOptionsAsIdNameTuples } from '@atlassian/jira-polaris-common/src/ui/common/decoration/decoration/select/utils/options.tsx';
import type { SelectOption } from '@atlassian/jira-polaris-common/src/ui/common/decoration/select/types.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { FieldValueFilter } from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import { useNextValidAddValue } from '@atlassian/jira-polaris-lib-option-utils/src/common/utils/add-value-generator/index.tsx';
import {
	fireTrackAnalytics,
	fireUIAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { useOnAddedHiddenColumn } from '../on-added-hidden-column/index.tsx';
import messages from './messages.tsx';

type AddColumnProps = {
	fieldKey: FieldKey;
	onAddColumn: (newOptionId: string) => void;
};

const getFilterWithValues = (...filters: FieldValueFilter[]): FieldValueFilter | undefined =>
	filters.find((f) => f.values.length > 0);

export const AddColumn = ({ fieldKey, onAddColumn }: AddColumnProps) => {
	const { formatMessage } = useIntl();
	const permanentFieldFilter = usePermanentFieldFilter(fieldKey);
	const { updateFieldFilter } = useViewActions();
	const options = useOptionsAsIdNameTuples(fieldKey);
	const nextColumnName = useNextValidAddValue(
		undefined,
		options.map((option: SelectOption) => option.value),
	);
	const [inCreation, setInCreation] = useState(false);
	const hideEmptyColumns = useCurrentViewHideEmptyBoardColumns();
	const onAddedHiddenColumn = useOnAddedHiddenColumn();
	const { addOption } = useFieldActions();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const addColumnHandler = useCallback(
		(_event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
			setInCreation(true);
			addOption(fieldKey, nextColumnName.value).then((newOptionId) => {
				const newOptionString = String(newOptionId);
				fireUIAnalytics(analyticsEvent, 'addFieldValue', {
					fieldValueId: newOptionString,
				});

				const filter = getFilterWithValues(permanentFieldFilter);
				if (filter) {
					updateFieldFilter({
						...filter,
						values: [...filter.values, { stringValue: newOptionString }],
					});
				}

				if (hideEmptyColumns) {
					onAddedHiddenColumn();
				}
				onAddColumn(newOptionString);
				fireTrackAnalytics(createAnalyticsEvent({}), 'view updated', {
					viewFieldValuesUpdated: {
						added: [newOptionString],
					},
				});
				setInCreation(false);
			});
		},
		[
			addOption,
			permanentFieldFilter,
			fieldKey,
			hideEmptyColumns,
			nextColumnName.value,
			onAddColumn,
			onAddedHiddenColumn,
			updateFieldFilter,
			createAnalyticsEvent,
		],
	);

	return (
		<AddColumnContainer>
			<Tooltip content={formatMessage(messages.columnAddButtonHint)}>
				<LoadingButton
					id="polaris-ideas.ui.view-content.idea-board.add-option"
					isLoading={inCreation}
					iconBefore={<EditorAddIcon label="add" LEGACY_size="medium" spacing="spacious" />}
					onClick={addColumnHandler}
				/>
			</Tooltip>
		</AddColumnContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AddColumnContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	button: {
		height: '36px',
	},
});
