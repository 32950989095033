// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	type Action,
	createContainer,
	createHook,
	createStore,
} from '@atlassian/react-sweet-state';
import actions from './actions/index.tsx';
import { type Props, type State, initialState } from './types.tsx';

export type Actions = typeof actions;

export const InsightsStore = createStore<State, Actions>({
	initialState,
	actions,
	name: 'PolarisInsightsStore',
});

export const useInsights = createHook(InsightsStore);

const useInsightActionsHook = createHook(InsightsStore, {
	selector: null,
});

export const useInsightsActions = () => {
	const [, storeActions] = useInsightActionsHook();
	return storeActions;
};

export const useProjectLabels = () => {
	const [state] = useInsights();
	return state.labels;
};

export const useIsInsightSelected = (insightId?: string) => {
	const [{ selectedInsights }] = useInsights();
	return (insightId !== undefined && selectedInsights[insightId]) || false;
};

export const InsightsContainerInternal = createContainer<State, Actions, Props>(InsightsStore, {
	onInit:
		(): Action<State, Props> =>
		({ dispatch }) => {
			dispatch(actions.updateInsights());
		},
	onUpdate:
		(): Action<State, Props> =>
		({ dispatch }) => {
			dispatch(actions.updateInsights());
		},
});
