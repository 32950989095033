import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	ideaCount: {
		id: 'polaris-ideas.view-header.view-subtitle.view-idea-count.idea-count',
		defaultMessage: '{ideaCount, plural, one {# idea} other {# ideas}}',
		description: 'Number of ideas displayed on a view, shown under view title',
	},
	projectsCount: {
		id: 'polaris-ideas.view-header.view-subtitle.view-projects-count.projects-count',
		defaultMessage: '{projectsCount, plural, =0 {No projects} =1 {# project} other {# projects}}',
		description: 'Number of projects displayed on a view, shown under view title',
	},
});
