import { createSelector } from 'reselect';
import { type GroupToItems, type ItemId, type GroupId, NO_VALUE_GROUP_ID } from '../../types.tsx';
import type { State, Selector } from '../types.tsx';

export const getGroupedIds: Selector<GroupToItems | undefined> = (state: State) => state.groupIds;

export const getRowGroups: Selector<GroupId[]> = createSelector(
	(state: State) => state.rowGroups,
	(rowGroups) =>
		rowGroups?.map((groupId: undefined | GroupId) =>
			groupId !== undefined ? groupId : NO_VALUE_GROUP_ID,
		),
);

export const getHideEmptyGroups = (state: State) => state.hideEmptyGroups;

export const getGroupIdsLookup: Selector<Record<ItemId, GroupId[]>> = createSelector(
	getGroupedIds,
	(groupToItems = {}) => {
		const mapping: Record<ItemId, GroupId[]> = {};
		Object.keys(groupToItems).forEach((groupId) =>
			groupToItems[groupId].forEach((itemId) => {
				if (mapping[itemId] === undefined) {
					mapping[itemId] = [groupId];
				} else {
					mapping[itemId].push(groupId);
				}
			}),
		);
		return mapping;
	},
);

export const createGetGroupIdByItemIds = (itemId: ItemId): Selector<GroupId[]> =>
	createSelector(getGroupedIds, (groupIdsToItemIds = {}): GroupId[] =>
		Object.keys(groupIdsToItemIds).filter((groupId) => groupIdsToItemIds[groupId].includes(itemId)),
	);
