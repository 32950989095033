import React, { memo, useMemo, useCallback, type MouseEvent } from 'react';
import { styled } from '@compiled/react';
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs';
import { Emoji } from '@atlaskit/emoji/element';
import BoardIcon from '@atlaskit/icon/core/migration/board';
import BulletListIcon from '@atlaskit/icon/core/migration/list-bulleted--bullet-list';
import { BreadcrumbSkeleton } from '@atlassian/jira-polaris-common/src/common/ui/skeleton/index.tsx';
import { shouldOpenInNewTab } from '@atlassian/jira-polaris-common/src/common/utils/events/index.tsx';
import { getViewLink } from '@atlassian/jira-polaris-common/src/common/utils/view-link/index.tsx';
import { usePolarisRouter } from '@atlassian/jira-polaris-common/src/controllers/route/index.tsx';
import {
	useCurrentViewEmoji,
	useCurrentViewKind,
	useCurrentViewSlug,
	useCurrentViewTitle,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { useProjectKeyUnsafe } from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import {
	PolarisIcon,
	PolarisIconType,
} from '@atlassian/jira-polaris-component-glyphs/src/ui/glyphs/main.tsx';
import {
	VIEW_KIND_BOARD,
	VIEW_KIND_TIMELINE,
	VIEW_KIND_MATRIX,
} from '@atlassian/jira-polaris-domain-view/src/view/constants.tsx';
import { EmojiWrapper } from '@atlassian/jira-polaris-lib-emoji-wrapper/src/ui/index.tsx';
import { useEmoji } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';
import { IssueKey } from './key/index.tsx';

type IssueBreadcrumbsProps = {
	isInSidebar?: boolean;
	testid?: string;
};

export const IssueBreadcrumbs = memo<IssueBreadcrumbsProps>(
	({ isInSidebar = false, testid }: IssueBreadcrumbsProps) => {
		const projectKey = useProjectKeyUnsafe();
		const currentViewTitle = useCurrentViewTitle();
		const currentViewSlug = useCurrentViewSlug();
		const emojiId = useCurrentViewEmoji();
		const emoji = useEmoji(emojiId);
		const viewKind = useCurrentViewKind();
		const { closeIssueView } = usePolarisRouter();

		const href = useMemo(
			() => (currentViewSlug !== undefined ? getViewLink(projectKey, currentViewSlug) : undefined),
			[currentViewSlug, projectKey],
		);

		const onClickAllIssues = useCallback(
			(e: MouseEvent) => {
				if (!shouldOpenInNewTab(e)) {
					e.preventDefault();
					closeIssueView();
				}
			},
			[closeIssueView],
		);

		if (isInSidebar) {
			return (
				<Breadcrumbs>
					<IssueKey />
				</Breadcrumbs>
			);
		}

		const renderIcon = () => {
			if (emoji) {
				return (
					<EmojiWrapper>
						<Emoji emoji={emoji} fitToHeight={16} showTooltip />
					</EmojiWrapper>
				);
			}
			if (viewKind === VIEW_KIND_BOARD) {
				return (
					<IconWrapper>
						<BoardIcon spacing="spacious" label="viewKind" testId={testid} />
					</IconWrapper>
				);
			}
			if (viewKind === VIEW_KIND_MATRIX) {
				return (
					<IconWrapper>
						<PolarisIcon name={PolarisIconType.Matrix} size="medium" label="" />
					</IconWrapper>
				);
			}
			if (viewKind === VIEW_KIND_TIMELINE) {
				return (
					<IconWrapper>
						<PolarisIcon name={PolarisIconType.Timeline} size="medium" label="" />
					</IconWrapper>
				);
			}
			return <BulletListIcon spacing="spacious" label="viewKind" testId={testid} />;
		};

		return (
			<Breadcrumbs>
				{currentViewTitle === undefined ? (
					<BreadcrumbSkeleton />
				) : (
					<BreadcrumbsItem
						href={href}
						iconBefore={renderIcon()}
						text={currentViewTitle}
						onClick={onClickAllIssues}
					/>
				)}
				<IssueKey />
			</Breadcrumbs>
		);
	},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	svg: {
		transform: 'scale(0.66)',
	},
	display: 'flex',
	alignItems: 'center',
});
