import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	noProjectPermissionsErrorTitle: {
		id: 'polaris-ideas.view-content.idea-list.cell.utils.no-project-permissions-error-title',
		defaultMessage: "You can't edit these ideas",
		description:
			'Error message title when user try to bulk edit ideas and they don’t have some of those ideas’s project’s edit ideas permission.',
	},
	noProjectPermissionsErrorDescription: {
		id: 'polaris-ideas.view-content.idea-list.cell.utils.no-project-permissions-error-description',
		defaultMessage: 'You don’t have permission to edit ideas. Contact your project admin.',
		description:
			'Error message description when user try to bulk edit ideas and they don’t have some of those ideas’s project’s edit ideas permission',
	},
});
