/** @jsx jsx */
import React, { type MouseEvent, type PropsWithChildren } from 'react';
import { css, jsx } from '@compiled/react';
import { Box, xcss } from '@atlaskit/primitives';
import AkLozenge from '@atlaskit/lozenge';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import {
	useSafeIssueKey,
	useMultiSelect,
	useSingleLinkedStatus,
	useStringValue,
	useStatus,
} from '../../../../controllers/issue/selectors/properties/hooks.tsx';
import { CommentsField } from '../../../fields/comments/index.tsx';
import { InsightsField } from '../../../fields/insights/index.tsx';
import { ObservableValuesListContext } from '../../../fields/observable-values-list/context/index.tsx';
import { PlayField } from '../../../fields/play/index.tsx';
import { ReactionsField } from '../../../fields/reactions/index.tsx';
import { MultiSelectField } from '../../body/multi-select/index.tsx';
import { SingleSelectField } from '../../body/single-select/index.tsx';
import { StringListField } from '../../body/string-list/index.tsx';
import { StringField } from '../../body/string/index.tsx';
import { DISPLAYING_FIELDS_MIN_CARD_WIDTH } from '../../constants.tsx';
import { KeyField } from '../../header/key-field/index.tsx';
import {
	FULL_WIDTH_FIELD_TYPES,
	MULTI_OPTION_COMPACT_MAX_WIDTH,
	OPTION_COMPACT_MAX_WIDTH,
} from '../constants.tsx';
import { SummaryCardFieldDeliveryStatus } from '../delivery-status/index.tsx';
import { HiddenCount } from '../hidden-count/index.tsx';
import { SummaryConnectionField } from '../../body/connection/summary-connection-field/index.tsx';
import { DateField } from '../../body/date/index.tsx';
import { DateTimeField } from '../../body/datetime/index.tsx';
import { IntervalDateField } from '../../body/interval-date/index.tsx';
import { NumberField } from '../../body/number/index.tsx';
import { UserField } from '../../body/users/index.tsx';
import { ExternalReferenceField } from '../../body/external-reference/index.tsx';
import { ExternalReferencePropertyField } from '../../body/external-reference-property/index.tsx';
import { ProjectField } from '../../body/project/index.tsx';
import { TeamField } from '../../body/team/index.tsx';
import { GoalsField } from '../../body/goals/index.tsx';
import { getAppearance } from '../../utils.tsx';
import { useIsUnsupportedHighlightedField } from '../../../fields/utils/use-is-unsupported-highlighted-field.tsx';

type SummaryCardFieldProps = {
	field: Field;
	issueId: LocalIssueId;
	reservedSpace: number;
	maxWidth: number;
	containerWidth: number;
	isResizing?: boolean;
	isFixed?: boolean;
	onHideSummaryTooltip?: () => void;
	onShowSummaryTooltip?: () => void;
	showCompactOption?: boolean;
};

type SummaryCardFieldTypeProps = SummaryCardFieldProps & { showCompactOption: boolean };

const ActionFieldContainer = ({ children }: PropsWithChildren<{}>) => (
	<Box
		onClick={(e: MouseEvent) => {
			e.stopPropagation();
			e.preventDefault();
		}}
		xcss={actionFieldContainerStyles}
	>
		{children}
	</Box>
);

const SummaryCardFieldType = (props: SummaryCardFieldTypeProps) => {
	const {
		field,
		issueId,
		isResizing = false,
		showCompactOption,
		onHideSummaryTooltip,
		onShowSummaryTooltip,
	} = props;
	const { key, type } = field;
	const status = useStatus(issueId);
	const issueKey = useSafeIssueKey(issueId);
	const stringValue = useStringValue(key, issueId);
	const sharedProps = {
		issueId,
		fieldKey: key,
		isCompact: true,
		hideEmpty: true,
	};
	const { isUnsupportedField } = useIsUnsupportedHighlightedField({
		fieldKey: field.key,
		localIssueId: issueId,
		checkGlobalFieldAssociation: false,
	});

	if (fg('jpd_cross_project_connecting')) {
		if (isUnsupportedField) {
			return null;
		}
	}

	switch (type) {
		case FIELD_TYPES.DELIVERY_STATUS:
			return <SummaryCardFieldDeliveryStatus {...props} />;
		case FIELD_TYPES.SINGLE_SELECT:
			return (
				<SingleSelectField
					issueId={issueId}
					fieldKey={key}
					iconOnly={showCompactOption}
					hideEmpty
					isCompact
				/>
			);
		case FIELD_TYPES.MULTI_SELECT:
		case FIELD_TYPES.JSW_MULTI_SELECT:
			return (
				<MultiSelectField
					fieldKey={field.key}
					issueId={issueId}
					hideEmpty
					showLabel={false}
					isCompact
					iconOnly={showCompactOption}
				/>
			);

		case FIELD_TYPES.REACTIONS:
			return (
				<ActionFieldContainer>
					<ReactionsField
						localIssueId={issueId}
						fieldKey={key}
						onReactionPickerClose={onShowSummaryTooltip}
						onReactionPickerOpen={onHideSummaryTooltip}
						miniMode
						subtleReactionsSummaryAndPicker
						summaryViewEnabled
						overrideMoreButtonWithSummaryView
						isEditable={false}
					/>
				</ActionFieldContainer>
			);
		case FIELD_TYPES.INSIGHTS:
			return (
				<ActionFieldContainer>
					<InsightsField localIssueId={issueId} appearance="board" />
				</ActionFieldContainer>
			);
		case FIELD_TYPES.ISSUE_COMMENTS:
			return (
				<ActionFieldContainer>
					<CommentsField localIssueId={issueId} appearance="board" />
				</ActionFieldContainer>
			);
		case FIELD_TYPES.VOTES:
			return (
				field.play && (
					<ActionFieldContainer>
						<PlayField playId={field.play.id} localIssueId={issueId} appearance="board" />
					</ActionFieldContainer>
				)
			);
		case FIELD_TYPES.ISSUE_KEY:
			return issueKey && <KeyField issueKey={issueKey} />;
		case FIELD_TYPES.STATUS:
			return (
				status && (
					<AkLozenge
						appearance={getAppearance({
							name: status.name,
							isDone: status.statusCategory.key === 'done',
							isInProgress: status.statusCategory.key === 'indeterminate',
						})}
					>
						{status.name}
					</AkLozenge>
				)
			);
		case FIELD_TYPES.HYPERLINK:
			return (
				stringValue && (
					<div css={hyperLinkWrapperStyles}>
						<StringField
							issueId={issueId}
							fieldKey={key}
							isCompact
							hideEmpty
							isResizing={isResizing}
						/>
					</div>
				)
			);
		case FIELD_TYPES.SHORT_TEXT:
			return (
				stringValue && (
					<div css={shortTextWrapperStyles}>
						<StringField
							issueId={issueId}
							fieldKey={key}
							isCompact
							hideEmpty
							isResizing={isResizing}
						/>
					</div>
				)
			);
		case FIELD_TYPES.LABELS:
		case FIELD_TYPES.CUSTOM_LABELS:
			return <StringListField {...sharedProps} showLabel={false} />;
		case FIELD_TYPES.CONNECTION:
			return <SummaryConnectionField issueId={issueId} fieldKey={key} />;
		case FIELD_TYPES.DATE:
			return <DateField {...sharedProps} />;
		case FIELD_TYPES.CREATED:
		case FIELD_TYPES.UPDATED:
			return <DateTimeField key={key} {...sharedProps} />;
		case FIELD_TYPES.INTERVAL:
			return <IntervalDateField {...sharedProps} isCompact />;
		case FIELD_TYPES.NUMBER:
		case FIELD_TYPES.CHECKBOX:
		case FIELD_TYPES.FORMULA:
		case FIELD_TYPES.SLIDER:
		case FIELD_TYPES.RATING:
		case FIELD_TYPES.LINKED_ISSUES:
			return <NumberField {...sharedProps} />;
		case FIELD_TYPES.ASSIGNEE:
		case FIELD_TYPES.REPORTER:
		case FIELD_TYPES.CREATOR:
		case FIELD_TYPES.PEOPLE:
		case FIELD_TYPES.JSW_PEOPLE:
			return <UserField {...sharedProps} maxAvatarCount={4} avatarSize="xsmall" />;
		case FIELD_TYPES.ATLAS_GOAL:
		case FIELD_TYPES.ATLAS_PROJECT:
			return <ExternalReferenceField {...sharedProps} />;
		case FIELD_TYPES.ATLAS_PROJECT_STATUS:
			return <ExternalReferencePropertyField {...sharedProps} />;
		case FIELD_TYPES.PROJECT:
			return <ProjectField {...sharedProps} />;
		case FIELD_TYPES.TEAM:
			if (fg('polaris_team_field_integration')) {
				return <TeamField {...sharedProps} />;
			}
			return null;
		case FIELD_TYPES.PLATFORM_GOALS:
			if (fg('jpd_platform_goals_field_support')) {
				return <GoalsField {...sharedProps} />;
			}
			return null;
		default:
			return null;
	}
};

const observableListContextValue = {
	HiddenCountComponent: HiddenCount,
};

export const SummaryCardField = (props: SummaryCardFieldProps) => {
	const {
		field: { type, key },
		issueId,
		reservedSpace,
		maxWidth,
		isFixed,
	} = props;
	const singleStatus = useSingleLinkedStatus(issueId);
	const options = useMultiSelect(key, issueId);

	const showCompactOption =
		reservedSpace <
		(options.length > 1 ? MULTI_OPTION_COMPACT_MAX_WIDTH : OPTION_COMPACT_MAX_WIDTH);

	const showFullField =
		FULL_WIDTH_FIELD_TYPES.includes(type) ||
		(type === FIELD_TYPES.DELIVERY_STATUS && !singleStatus) ||
		((type === FIELD_TYPES.SINGLE_SELECT ||
			type === FIELD_TYPES.MULTI_SELECT ||
			type === FIELD_TYPES.JSW_MULTI_SELECT) &&
			showCompactOption);

	return (
		<ObservableValuesListContext.Provider value={observableListContextValue}>
			<div
				// eslint-disable-next-line jira/react/no-style-attribute
				style={{
					maxWidth: showFullField ? 'none' : `${maxWidth}px`,
					minWidth: isFixed ? `${maxWidth}px` : undefined,
				}}
				css={containerStyles}
			>
				<SummaryCardFieldType
					{...props}
					showCompactOption={props.showCompactOption ?? showCompactOption}
				/>
			</div>
		</ObservableValuesListContext.Provider>
	);
};

const hyperLinkWrapperStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'[data-smart-link-container=true]': {
		width: '24px',
	},
});

const shortTextWrapperStyles = css({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginTop: '3px',
});

const containerStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-container-queries, @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	[`@container cardContainer (max-width: ${DISPLAYING_FIELDS_MIN_CARD_WIDTH}px)`]: {
		display: 'none',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-container-queries -- Ignored via go/DSP-18766
	'@container cardContainer (max-width: 110px)': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'[data-component-selector="card-rating_4g6h"], [data-component-selector="reactions-button-wrapper"]':
			{
				display: 'none',
			},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'[data-component-selector="reactions-button-wrapper"] button': {
		height: '20px',
		display: 'flex',
		alignItems: 'center',
	},
	'&:empty': {
		display: 'none',
	},
});

const actionFieldContainerStyles = xcss({ maxWidth: '100%' });
