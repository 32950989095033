import memoize from 'lodash/memoize';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createHook } from '@atlassian/react-sweet-state';
import { MatrixStore } from '../index.tsx';
import type { State } from '../types.tsx';

/**
 * reselect selector
 */
export type Selector<TValue> = (arg1: State) => TValue;

/**
 * reselect selector creator
 */
export type SelectorCreator<TValue, SelectorCreatorArgs extends ReadonlyArray<unknown>> = (
	...rest: SelectorCreatorArgs
) => Selector<TValue>;

/**
 * Helper to create a proper react-sweet-state hook from a reselect selector function
 */
export const createSelectorHook = <TValue,>(selector: Selector<TValue>): (() => TValue) => {
	const innerHook = createHook(MatrixStore, {
		selector: (state) => selector(state),
	});

	return () => {
		const [val] = innerHook();
		return val;
	};
};

/**
 * Helper to create a proper react-sweet-state hook from a reselect selector creator
 */
export const createHigherLevelHook = <TValue, SelectorCreatorArgs extends ReadonlyArray<unknown>>(
	selectorCreator: SelectorCreator<TValue, SelectorCreatorArgs>,
): ((...rest: SelectorCreatorArgs) => TValue) => {
	const memoizedSelectorCreator = memoize(selectorCreator);

	const rssHook = createHook(MatrixStore, {
		// @ts-expect-error - TS2345 - Argument of type 'any[]' is not assignable to parameter of type 'SelectorCreatorArgs'. | TS2488 - Type 'void' must have a '[Symbol.iterator]()' method that returns an iterator.
		selector: (state, args) => memoizedSelectorCreator(...args)(state),
	});

	return (...args: SelectorCreatorArgs) => {
		// @ts-expect-error - TS2345 - Argument of type 'SelectorCreatorArgs' is not assignable to parameter of type 'void'.
		const [val] = rssHook(args);
		return val;
	};
};
