import React, { memo } from 'react';
import { useColumnDraggingInProgress } from '../../controllers/selectors/ui-hooks.tsx';
import { ColumnDndOverlay } from './column-dnd/index.tsx';
import { MovedRowOverlay } from './moved-row/index.tsx';

type OverlayRendererProps = {
	isBulkEditDialogShown: boolean;
};

export const OverlayRenderer = memo(({ isBulkEditDialogShown }: OverlayRendererProps) => {
	const columnDraggingInProgress = useColumnDraggingInProgress();

	return (
		<>
			{columnDraggingInProgress && <ColumnDndOverlay />}
			<MovedRowOverlay isBulkEditDialogShown={isBulkEditDialogShown} />
		</>
	);
});
