import React from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useExitPreviewMode } from '@atlassian/jira-polaris-common/src/controllers/preview-mode/index.tsx';
import messages from './messages.tsx';

export const PreviewModeBanner = () => {
	const { formatMessage } = useIntl();
	const exitPreviewMode = useExitPreviewMode();

	return (
		<PreviewBannerContainer>
			<Caption>{formatMessage(messages.previewModeCaption)}</Caption>
			<StyledButton onClick={exitPreviewMode}>{formatMessage(messages.exitPreview)}</StyledButton>
		</PreviewBannerContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PreviewBannerContainer = styled.div({
	paddingTop: token('space.200'),
	paddingRight: token('space.200'),
	paddingBottom: token('space.250'),
	paddingLeft: token('space.200'),
	marginTop: token('space.150'),
	marginRight: token('space.negative.100'),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginBottom: '-14px',
	marginLeft: token('space.negative.100'),
	boxSizing: 'border-box',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'flex-start',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.background.discovery.bold'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Caption = styled.div({
	color: token('color.text.inverse'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledButton = styled(Button)({
	backgroundColor: token('elevation.surface'),
	borderRadius: '3px',
	marginTop: token('space.150'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: `${token('color.text.selected')} !important`,
	'&:hover, &:active': {
		backgroundColor: token('elevation.surface'),
	},
});
