import type { CustomItemProps } from '@atlaskit/side-navigation';
import { token } from '@atlaskit/tokens';

export const getLinkStyle: CustomItemProps['cssFn'] = (currentStyles) => ({
	...currentStyles,
	userSelect: 'text',
	display: 'flex',
	padding: '0',
	backgroundColor: 'transparent',
	border: 'none',
	color: token('color.text'),
	'&:hover': {
		color: token('color.text'),
		backgroundColor: 'transparent',
		cursor: 'pointer',
		textDecoration: 'underline',
	},
	'&:focus, &:active': {
		color: token('color.text'),
		outline: 'none',
		boxShadow: 'none',
		backgroundColor: 'transparent',
	},
});
