// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import { fetchViewPermissionsForCurrentUser } from '../../../../services/load-permissions/index.tsx';
import type { State, Props } from '../../types.tsx';

const DEFAULT_CACHE_TTL = 30_000; // 30 seconds

export const loadViewPermissionsForCurrentUser =
	(viewId: string): Action<State, Props> =>
	async ({ getState, setState }, props) => {
		const cacheTTL = props.cacheTTL ?? DEFAULT_CACHE_TTL;
		const isCached = getState().viewPermissions[viewId]?.loadedTimestamp !== undefined;
		const isExpired =
			isCached &&
			performance.now() - (getState().viewPermissions[viewId].loadedTimestamp || 0) > cacheTTL;

		if ((isCached && !isExpired) || getState().viewPermissions[viewId]?.loading) {
			return;
		}

		setState({
			viewPermissions: {
				...getState().viewPermissions,
				[viewId]: {
					...getState().viewPermissions[viewId],
					loading: true,
				},
			},
		});

		try {
			const { canView, canEdit, accessLevel, withError } =
				await fetchViewPermissionsForCurrentUser(viewId);

			// When there is an error, we don't cache the response so the user can retry quickly
			if (withError) {
				throw new Error('Error fetching view permissions');
			}

			setState({
				viewPermissions: {
					...getState().viewPermissions,
					[viewId]: {
						loading: false,
						loadedTimestamp: performance.now(),
						data: {
							canView,
							canEdit,
							accessLevel,
						},
					},
				},
			});
		} catch (error) {
			setState({
				viewPermissions: {
					...getState().viewPermissions,
					[viewId]: {
						...getState().viewPermissions[viewId],
						loading: false,
					},
				},
			});
		}
	};
