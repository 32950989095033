import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	shareTitle: {
		id: 'polaris-ideas.view-header.share-dialog.share-dialog-content.share-title',
		defaultMessage: 'Share with your team',
		description: 'Title of the share dialog',
	},
	shareFullScreenTitle: {
		id: 'polaris-ideas.view-header.share-dialog.share-dialog-content.share-full-screen-title',
		defaultMessage: 'Share view in full screen',
		description: 'Title of the option to share URL in full screen mode.',
	},
	copyLink: {
		id: 'polaris-ideas.view-header.share-dialog.share-dialog-content.copy-link',
		defaultMessage: 'Copy link',
		description: 'Title of copy link button.',
	},
	share: {
		id: 'polaris-ideas.view-header.share-dialog.share-dialog-content.share',
		defaultMessage: 'Share',
		description: 'Title of share button.',
	},
	cancel: {
		id: 'polaris-ideas.view-header.share-dialog.share-dialog-content.cancel',
		defaultMessage: 'Cancel',
		description: 'Title of cancel button.',
	},
	copiedMessage: {
		id: 'polaris-ideas.view-header.share-dialog.share-dialog-content.copied-message',
		defaultMessage: 'URL copied to clipboard',
		description: 'Message for success after copying the sharing URL.',
	},
	copiedTitle: {
		id: 'polaris-ideas.view-header.share-dialog.share-dialog-content.copied-title',
		defaultMessage: 'Copied',
		description: 'Title for success after copying the sharing URL.',
	},
	copyFailMessage: {
		id: 'polaris-ideas.view-header.share-dialog.share-dialog-content.copy-fail-message',
		defaultMessage: 'Failed to copy URL to clipboard',
		description: 'Message for fail after copying the sharing URL.',
	},
	copyFailTitle: {
		id: 'polaris-ideas.view-header.share-dialog.share-dialog-content.copy-fail-title',
		defaultMessage: 'Error',
		description: 'Title for fail after copying the sharing URL.',
	},
});
