import React, { useEffect, useMemo, useState } from 'react';
import difference from 'lodash/difference';
import keyBy from 'lodash/keyBy';
import Tooltip from '@atlaskit/tooltip';
import Select, { type FormatOptionLabelMeta } from '@atlaskit/select';
import { Box, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import {
	PolarisEnvironmentContainerTypes,
	useEnvironmentContainer,
} from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import { getContributorPermissions } from '@atlassian/jira-polaris-lib-project-access/src/services/jira/get-contributor-permissions/index.tsx';
import { getRoles } from '@atlassian/jira-polaris-lib-project-access/src/services/jira/get-roles/index.tsx';
import { getRoleDetails } from '@atlassian/jira-polaris-lib-project-access/src/services/jira/get-role-details/index.tsx';
import { getSelectStyles } from '@atlassian/jira-project-invite-people-modal/src/ui/role/role-input/utils.tsx';
import { CreatorLozenge } from '@atlassian/jira-polaris-lib-invite-people-modal/src/ui/role-lozenge/index.tsx';
import {
	type ApplicationEdition,
	FREE_EDITION,
} from '@atlassian/jira-shared-types/src/edition.tsx';
import type { Option, Role } from './types.tsx';
import messages from './messages.tsx';
import { mapRoleToOption } from './utils.tsx';

type UseRolePickerProps = {
	applicationEdition: ApplicationEdition;
};

type UseRolePickerReturnType = {
	render: () => ReturnType<typeof RolePicker>;
	data: {
		selectedRole: Role | null;
	};
};

export const useRolePicker = ({
	applicationEdition,
}: UseRolePickerProps): UseRolePickerReturnType => {
	const [fetchingError, setFetchingError] = useState<Error | null>(null);
	const [isFetching, setIsFetching] = useState(false);
	const [roles, setRoles] = useState<Role[]>([]);
	const [selectedRole, setSelectedRole] = useState<Role | null>(null);

	const isDisabled =
		applicationEdition === FREE_EDITION || !!fetchingError || isFetching || roles.length === 0;

	const container = useEnvironmentContainer();
	const { formatMessage } = useIntl();

	const tooltipContent = useMemo(() => {
		if (applicationEdition === FREE_EDITION) {
			return formatMessage(messages.permissionsError);
		}

		if (fetchingError) {
			return formatMessage(messages.fetchingError);
		}

		if (isFetching) {
			return formatMessage(messages.fetching);
		}

		if (roles.length === 0) {
			return formatMessage(messages.noOptions);
		}

		return null;
	}, [applicationEdition, fetchingError, formatMessage, isFetching, roles]);

	useEffect(() => {
		if (container?.type !== PolarisEnvironmentContainerTypes.PROJECT) {
			return;
		}

		setFetchingError(null);
		setIsFetching(true);

		Promise.all([
			getContributorPermissions(),
			getRoles(container.projectId),
			getRoleDetails(container.projectId, formatMessage),
		])
			.then(([contributorPermissionsResponse, rolesResponse, roleDetailsResponse]) => {
				const contributorPermissionsKeys = contributorPermissionsResponse.permissions.map(
					({ key }) => key,
				);
				const roleDetailsMap = keyBy(roleDetailsResponse, 'name');

				setRoles(
					rolesResponse.roles.map((role) => {
						const roleDetails = roleDetailsMap[role.name];
						const nonContributorPermissions = difference(
							role.permissions || [],
							contributorPermissionsKeys,
						);
						return {
							admin: roleDetails.admin,
							default: roleDetails.default,
							description: roleDetails.description,
							id: roleDetails.id,
							isNonContributorRole: nonContributorPermissions.length > 0,
							translatedName: roleDetails.translatedName,
						};
					}),
				);
			})
			.catch((error) => {
				setFetchingError(error);
			})
			.finally(() => {
				setIsFetching(false);
			});
	}, [container, formatMessage]);

	useEffect(() => {
		if (selectedRole === null) {
			const defaultRole = roles.find((role) => role.default);

			if (applicationEdition === FREE_EDITION && !defaultRole?.admin) {
				const adminRole = roles.find((role) => role.admin);
				if (adminRole) {
					setSelectedRole(adminRole);
				}
			} else if (defaultRole) {
				setSelectedRole(defaultRole);
			}
		}
	}, [applicationEdition, roles, selectedRole]);

	return {
		render: () => (
			<RolePicker
				isDisabled={isDisabled}
				isLoading={isFetching}
				onChange={(optionValue) => {
					const role = roles.find((r) => r.id === optionValue);
					if (role) {
						setSelectedRole(role);
					}
				}}
				options={roles.map(mapRoleToOption)}
				tooltipContent={tooltipContent}
				value={selectedRole ? mapRoleToOption(selectedRole) : null}
			/>
		),
		data: {
			selectedRole,
		},
	};
};

export type RolePickerProps = {
	isDisabled: boolean;
	isLoading?: boolean;
	onChange: (optionValue: Option['value']) => void;
	options: Option[];
	tooltipContent: string | null;
	value: Option | null;
};

export const RolePicker = ({
	isDisabled,
	isLoading = false,
	onChange,
	options,
	tooltipContent,
	value,
}: RolePickerProps) => {
	const { formatMessage } = useIntl();

	return (
		<Box>
			<Box xcss={labelStyles}>{formatMessage(messages.label)}</Box>
			<Tooltip content={tooltipContent}>
				<Select
					fieldId="role-picker"
					formatOptionLabel={(
						{ description, isNonContributorRole, label }: Option,
						{ context }: FormatOptionLabelMeta<Option>,
					) => {
						if (context === 'menu') {
							return (
								<Box xcss={wrapperStyles}>
									<Box xcss={optionLabelStyles}>
										<Box>{label}</Box>
										{isNonContributorRole ? <CreatorLozenge /> : null}
									</Box>
									<Box xcss={descriptionStyles}>{description}</Box>
								</Box>
							);
						}
						return label;
					}}
					inputId="role-picker"
					isDisabled={isDisabled}
					isLoading={isLoading}
					isSearchable={false}
					onChange={(option) => {
						if (option) {
							onChange(option.value);
						}
					}}
					options={options}
					placeholder={formatMessage(messages.placeholder)}
					// eslint-disable-next-line @atlaskit/design-system/no-unsafe-style-overrides
					styles={getSelectStyles()}
					value={value}
				/>
			</Tooltip>
		</Box>
	);
};

const labelStyles = xcss({
	color: 'color.text.subtlest',
	display: 'block',
	font: 'font.body.UNSAFE_small',
	fontWeight: 'font.weight.semibold',
	marginBottom: 'space.050',
	marginTop: '0',
});

const wrapperStyles = xcss({
	display: 'flex',
	flexDirection: 'column',
});

const optionLabelStyles = xcss({
	display: 'flex',
	alignItems: 'center',
});

const descriptionStyles = xcss({
	font: 'font.body.UNSAFE_small',
	color: 'color.text.subtlest',
});
