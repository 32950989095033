const asyncStatuses = {
	idle: 'idle',
	loading: 'loading',
	loaded: 'loaded',
	failed: 'failed',
} as const;

type Idle = {
	status: typeof asyncStatuses.idle;
};

type Loading = {
	status: typeof asyncStatuses.loading;
};

type Loaded<T> = {
	status: typeof asyncStatuses.loaded;
	value: T;
};

type Failed = {
	status: typeof asyncStatuses.failed;
	error: Error;
};

export type AsyncValue<T> = Idle | Loading | Loaded<T> | Failed;

export const asyncValues = {
	idle: () => ({
		status: asyncStatuses.idle,
	}),
	loading: () => ({
		status: asyncStatuses.loading,
	}),
	loaded: <T,>(value: T) => ({
		status: asyncStatuses.loaded,
		value,
	}),
	failed: () => ({
		status: asyncStatuses.failed,
	}),
} as const;
