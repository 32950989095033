import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	inviteMessage: {
		id: 'polaris-component-smart-user-picker-with-suggestions.invite-message',
		defaultMessage:
			'<b>{userEmail} {userCount, plural, =0 {} one {and # other} other {and # others}}</b> can’t access any content in any spaces. Invite them to Jira Product Discovery to start collaborating.',
		description: 'Tells the user there might be restrictions on this content before sharing',
	},
	invitePeopleCta: {
		id: 'polaris-component-smart-user-picker-with-suggestions.invite-people-cta',
		defaultMessage: 'Invite people',
		description: 'Action for user to change the restrictions on this content being shared',
	},
});
