// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types.tsx';

export const loadPropertiesConfig =
	(): Action<State, Props> =>
	({ getState, setState }, { insightsRemote }: Props) => {
		const { snippetData, oauthClientId } = getState();
		const groupId = snippetData?.group?.id || null;
		if (oauthClientId === null || snippetData === null || groupId === null) {
			return;
		}
		setState({
			isLoading: true,
		});
		insightsRemote
			.fetchSnippetPropertiesConfig?.({
				oauthClientId,
				groupId,
			})
			.then((config) => {
				setState({
					config,
				});
			})
			.catch((error) => {
				setState({
					error,
				});
			})
			.finally(() => {
				setState({
					isLoading: false,
				});
			});
	};
