import {
	getColumnsCount,
	getColumnsHeaders,
	createGetRowsCount,
	isReadOnly,
	isSorted,
	isGroupByFieldEditable,
	isMoveBetweenGroupsDisabled,
	getItemHeight,
	getRowHeight,
	getColumnWidth,
	getGridIntervals,
	getResizePosition,
	getScale,
	getStartDate,
	getEndDate,
	isMarkerReadOnly,
	getMarkers,
	getHasMarkers,
	getMinColumnWidth,
	isVerticalCardReorderingEnabled,
} from './timeline.tsx';
import { createSelectorHook, createHigherLevelHook } from './utils.tsx';

export const useColumnsHeaders = createSelectorHook(getColumnsHeaders);
export const useColumnsCount = createSelectorHook(getColumnsCount);
export const useRowsCount = createHigherLevelHook(createGetRowsCount);
export const useIsReadOnly = createSelectorHook(isReadOnly);
export const useIsMarkerReadOnly = createSelectorHook(isMarkerReadOnly);
export const useIsSorted = createSelectorHook(isSorted);
export const useIsGroupByFieldEditable = createSelectorHook(isGroupByFieldEditable);
export const useIsMoveBetweenGroupsDisabled = createSelectorHook(isMoveBetweenGroupsDisabled);
export const useIsVerticalCardReorderingEnabled = createSelectorHook(
	isVerticalCardReorderingEnabled,
);
export const useItemHeight = createSelectorHook(getItemHeight);
export const useRowHeight = createSelectorHook(getRowHeight);
export const useColumnWidth = createSelectorHook(getColumnWidth);
export const useGridIntervals = createSelectorHook(getGridIntervals);
export const useResizePosition = createSelectorHook(getResizePosition);
export const useScale = createSelectorHook(getScale);
export const useStartDate = createSelectorHook(getStartDate);
export const useEndDate = createSelectorHook(getEndDate);
export const useMarkers = createSelectorHook(getMarkers);
export const useHasMarkers = createSelectorHook(getHasMarkers);
export const useMinColumnWidth = createSelectorHook(getMinColumnWidth);
