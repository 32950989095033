/** @jsx jsx */
import type { ReactNode } from 'react';
import { css, jsx } from '@compiled/react';
import find from 'lodash/find';
import { type EmojiDescription, Emoji } from '@atlaskit/emoji';
import { Flex, Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldType } from '@atlassian/jira-polaris-domain-field/src/field-types/types.tsx';
import { EmojiWrapper } from '../../../field-config/item/emoji-wrapper/index.tsx';
import type { SelectOption } from './content/index.tsx';
import messages from './messages.tsx';
import { ReactionsFirstFilterLabel } from './reactions-label/index.tsx';

type FilterButtonLabelProps = {
	label: string;
	fieldType?: FieldType;
	fieldTypeIcon: ReactNode;
	emoji?: EmojiDescription;
	options: SelectOption[];
	selected: (string | undefined)[];
};

export const FilterButtonLabel = ({
	label,
	fieldType,
	fieldTypeIcon,
	emoji,
	options,
	selected,
}: FilterButtonLabelProps) => {
	const { formatMessage } = useIntl();

	const firstSelection =
		selected.length > 0 ? find(options, ({ id }) => id === selected[0]) : undefined;

	if (firstSelection === undefined) {
		return (
			<Flex alignItems="center">
				{emoji ? (
					<EmojiWrapper>
						<Emoji emoji={emoji} fitToHeight={16} showTooltip />
					</EmojiWrapper>
				) : (
					<Flex xcss={iconContainerStyles}>{fieldTypeIcon}</Flex>
				)}
				{label}
			</Flex>
		);
	}

	const more = selected.length - 1;

	return (
		<Flex alignItems="center">
			{emoji ? (
				<EmojiWrapper>
					<Emoji emoji={emoji} fitToHeight={16} showTooltip />
				</EmojiWrapper>
			) : (
				<Flex xcss={iconContainerStyles}>{fieldTypeIcon}</Flex>
			)}
			<div css={labelStyles}>{label}</div>
			{fieldType === FIELD_TYPES.REACTIONS ? (
				<ReactionsFirstFilterLabel labelId={firstSelection.id} />
			) : (
				<Box xcss={filterLabelFirstItemStyles}>
					{firstSelection.previewLabel !== undefined
						? firstSelection.previewLabel
						: firstSelection.label}
				</Box>
			)}
			{more !== 0 && (
				<Box paddingInlineStart="space.050">
					{formatMessage(messages.moreOptionsSelectedIndicator, { number: more })}
				</Box>
			)}
		</Flex>
	);
};

const iconContainerStyles = xcss({
	paddingInlineEnd: 'space.100',
	alignItems: 'center',
});

const filterLabelFirstItemStyles = xcss({
	marginInlineStart: 'space.050',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	maxWidth: '260px',
});

const labelStyles = css({
	fontWeight: token('font.weight.bold'),
});
