import '@atlassian/jira-vendor/src/vendor-parcel.tsx';
import '@atlassian/jira-vendor/src/commons-parcel.tsx';

import React, { useEffect } from 'react';
import { render } from 'react-dom';
import traceUFOPageLoad from '@atlaskit/react-ufo/trace-pageload';
import AppBase from '@atlassian/jira-app-base/src/index.tsx';
import { initBrowserMetrics3 } from '@atlassian/jira-browser-metrics';
import { ViewExperienceTrackingProvider } from '@atlassian/jira-common-experience-tracking-viewing/src/view/experience-tracking-provider/index.tsx';
import { provideHealthcheck } from '@atlassian/jira-common-healthcheck/src/utils/healthcheck-utils/index.tsx';
import { JiraContextContainer } from '@atlassian/jira-context-container/src/JiraContextContainer.tsx';
import { initPlatformFeatureFlags } from '@atlassian/jira-feature-flagging';
import ModalIssueApp from '@atlassian/jira-issue-view/src/views/issue-details/modal-issue-app.tsx';
import { ContextualAnalyticsData, SCREEN } from '@atlassian/jira-product-analytics-bridge';
import ShareView from '@atlassian/jira-product-discovery-sharing/src/index.tsx';
import { StrictMode } from '@atlassian/jira-react-strict-mode/src/StrictMode.tsx';
import { Router } from '@atlassian/jira-router-components/src/ui/router/index.tsx';
import { themePreferenceResource } from '@atlassian/jira-router-resources-theme-preference/src/controllers/resource-erai-1615-old/index.tsx';
import { JIRA_PRODUCT_DISCOVERY } from '@atlassian/jira-shared-types/src/application.tsx';
import { SubProductUpdater } from '@atlassian/jira-spa-apps-common/src/analytics-sub-product/sub-product-updater/index.tsx';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';
import initReactUFO from '@atlassian/jira-ufo-interaction-metrics-init/src/index.tsx';
import { visualRefreshResolverOverride } from '@atlassian/jira-visual-refresh-rollout/src/resolver-override/index.tsx';
import { type Route, createBrowserHistory, RouteComponent } from '@atlassian/react-resource-router';
import global from '../global.tsx';
import { Wrapper } from './utils.tsx';

provideHealthcheck();
global.install();

// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
const container = document.getElementById('jira-frontend');

// ModalIssueApp is imported (but unused) to get webpack to bundle all dependencies properly.
// without this, package sizes all over jira-spa will explode
export const ModalIssueAppForWebpack = () => ModalIssueApp;

const JpdShareViewAppProvider = () => {
	initBrowserMetrics3();

	initReactUFO();
	traceUFOPageLoad('jpd.shared-view');

	initPlatformFeatureFlags(visualRefreshResolverOverride);

	const appEditions = useAppEditions();
	const routes: Route[] = [
		{
			name: 'jpd-share-view-static',
			exact: true,
			path: '/jira/discovery/share/:section/:resource',
			component: ShareView,
			resources: [themePreferenceResource],
		},
	];
	const history = createBrowserHistory();

	useEffect(() => {
		// override Jira's min sizes in embedded mode

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		if (document.body !== null && document.body !== undefined) {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.body.style.minWidth = '0';

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.body.style.minHeight = '0';
		}
	}, []);

	return (
		<StrictMode>
			<JiraContextContainer>
				<ViewExperienceTrackingProvider
					experienceId=""
					experience="viewJpdShareView"
					analyticsSource="jpdShareView"
					edition={appEditions.software}
					application={JIRA_PRODUCT_DISCOVERY}
				>
					<AppBase id="jpd-share-view">
						<ContextualAnalyticsData sourceType={SCREEN} sourceName="polaris">
							<SubProductUpdater subProduct="polaris" />
							<Router history={history} routes={routes}>
								<Wrapper>
									<RouteComponent />
								</Wrapper>
							</Router>
						</ContextualAnalyticsData>
					</AppBase>
				</ViewExperienceTrackingProvider>
			</JiraContextContainer>
		</StrictMode>
	);
};

if (container) {
	render(<JpdShareViewAppProvider />, container);
}
