import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';

const getUrl = (projectId: ProjectId) => `/rest/polaris/contributors/project/${projectId}/add`;

/**
 * @deprecated
 * @param projectId
 */
export const addContributorGroupToProject = ({
	projectId,
}: {
	projectId: ProjectId;
}): Promise<void> => performPutRequest(getUrl(projectId), {});
