import { getDaysInMonth } from 'date-fns';
import {
	type GRID_BACKGROUND_COLOR,
	type HEADER_BACKGROUND_COLOR,
	GRID_BORDER_COLOR,
	ITEM_PADDING,
	ScaleEnum,
} from './constants.tsx';
import type {
	DraggableItem,
	GridIntervals,
	ItemCutDirection,
	Scale,
} from './types/timeline/index.tsx';

export const getTakenColsByItem = (colIndex: number, size: number): number[] =>
	[...Array(size).keys()].map((i) => i + colIndex);

export const getItemWidthBySize = (size: number, columnWidth: number) => {
	if (size < 1) {
		throw new Error('Item size should be positive integer');
	}

	return size * columnWidth - 2 * ITEM_PADDING;
};

export const getCutDirection = (
	itemStart: number,
	itemSize: number,
	columnCount: number,
): ItemCutDirection | undefined => {
	const isCutLeft = itemStart < 0;
	const isCutRight = itemStart + itemSize > columnCount;

	if (isCutLeft && isCutRight) {
		return 'both';
	}
	if (isCutLeft) {
		return 'left';
	}
	if (isCutRight) {
		return 'right';
	}

	return undefined;
};

export type DraggableItemWithSize = DraggableItem & { size: number };

export const getItemWithCutDirection = (
	item: DraggableItemWithSize,
	columnCount: number,
): DraggableItemWithSize => {
	if (item.colIndex === undefined) {
		return item;
	}

	const cutDirection = getCutDirection(item.colIndex, item.size, columnCount);

	if (cutDirection === 'both') {
		return {
			...item,
			colIndex: 0,
			size: columnCount,
			cutDirection,
		};
	}

	if (cutDirection === 'left') {
		return {
			...item,
			colIndex: 0,
			size: item.size + item.colIndex,
			cutDirection,
		};
	}

	if (cutDirection === 'right') {
		return {
			...item,
			size: columnCount - item.colIndex,
			cutDirection,
		};
	}

	return item;
};

export const getGridBackgroundSize = (gridIntervals: GridIntervals, columnWidth: number) => {
	const [primaryGridInterval] = gridIntervals;

	return `10px 10px, ${columnWidth * primaryGridInterval}px ${columnWidth * primaryGridInterval}px`;
};

export const getGridBackgroundImage = (
	gridIntervals: GridIntervals,
	backgroundColor: typeof GRID_BACKGROUND_COLOR | typeof HEADER_BACKGROUND_COLOR,
) => {
	const [primaryGridInterval, secondaryGridInterval] = gridIntervals;

	const bgImage = `linear-gradient(to bottom, transparent 5px, ${backgroundColor} 5px), linear-gradient(to right, ${GRID_BORDER_COLOR} 1px, transparent 1px)`;

	if (!secondaryGridInterval) {
		return bgImage;
	}

	return new Array(primaryGridInterval / secondaryGridInterval).fill(bgImage).join(', ');
};

export const getGridBackgroundPosition = (gridIntervals: GridIntervals, columnWidth: number) => {
	const [primaryGridInterval, secondaryGridInterval] = gridIntervals;

	const primaryBgPosition = '0 0';

	if (!secondaryGridInterval) {
		return primaryBgPosition;
	}

	return new Array(primaryGridInterval / secondaryGridInterval)
		.fill(undefined)
		.map((_, index) =>
			new Array(2).fill(`${columnWidth * secondaryGridInterval * index}px 0`).join(', '),
		)
		.join(', ');
};

export const getTimelinePrimaryGridInterval = (mode: Scale) => (mode === ScaleEnum.MONTHS ? 2 : 6);
export const getTimelineSecondaryGridInterval = (mode: Scale) =>
	mode === ScaleEnum.MONTHS ? 1 : 2;

// Returns the middle of the month date for the given date in the format: [startOfIdea, endOfIdea]
export const getMiddleOfTheMonthDate = (date: Date): number[] => {
	const daysInMonth = getDaysInMonth(date);

	switch (daysInMonth) {
		case 28:
			return [14, 13];
		case 29:
			return [15, 14];
		default:
			return [16, 15];
	}
};
