import React from 'react';
import InfoIcon from '@atlaskit/icon/core/migration/information--info';
import { Flex, Box, xcss } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';

type ConfigHeaderProps = {
	title: string;
	tooltipText?: string;
};

export const ConfigHeader = ({ title, tooltipText }: ConfigHeaderProps) => (
	<Flex>
		<Box xcss={configHeaderTitleStyles}>{title}</Box>
		{tooltipText !== undefined && (
			<Tooltip content={tooltipText} delay={100} position="top">
				<InfoIcon LEGACY_size="small" label={tooltipText} />
			</Tooltip>
		)}
	</Flex>
);

const configHeaderTitleStyles = xcss({
	marginRight: 'space.050',
});
