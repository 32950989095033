import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	pageHeader: {
		id: 'polaris-ideas.settings.connections.page-header',
		defaultMessage: 'Connections',
		description: 'Header of the connections settings page',
	},
	earlyAccessLozenge: {
		id: 'polaris-ideas.settings.connections.early-access-lozenge',
		defaultMessage: 'Early access',
		description: 'Lozenge for the early access feature',
	},
	descriptionText: {
		id: 'polaris-ideas.settings.connections.description-text-1',
		defaultMessage:
			'Configure your product work hierarchy with connection fields. It’s flexible so you can use the model that works best for you, for example opportunity/solution/experiment, company program/team contribution or idea/sub-idea.',
		description: 'Description text for the connections settings page',
	},
	howToEnableConnectionFieldsTitle: {
		id: 'polaris-ideas.settings.connections.how-to-enable-connection-fields-title',
		defaultMessage: 'Enable connection fields on your site',
		description: 'Title of the how to enable connection fields section',
	},
	howToEnableConnectionFieldsDescription: {
		id: 'polaris-ideas.settings.connections.how-to-enable-connection-fields-description',
		defaultMessage:
			'Connection fields are currently in Early Access and are not enabled for your site. To start using this feature your Jira administrator needs to enable it.',
		description: 'Description text for the how to enable connection fields section',
	},
	earlyAccessTitle: {
		id: 'polaris-ideas.settings.connections.early-access-title',
		defaultMessage: 'Early access',
		description: 'Early access section title',
	},
	earlyAccessDescription: {
		id: 'polaris-ideas.settings.connections.early-access-description',
		defaultMessage:
			'Connection fields are currently in Early Access. Please share your experience using this feature and report any bugs or issues that you find.',
		description: 'Early access section description',
	},
	learnMoreLink: {
		id: 'polaris-ideas.settings.connections.learn-more-link',
		defaultMessage: 'Learn how to use connection fields',
		description: 'Learn more link label',
	},
	productSettingsLink: {
		id: 'polaris-ideas.settings.connections.product-settings-link',
		defaultMessage: 'Jira admin settings',
		description: 'Product settings link label',
	},
	feedbackButton: {
		id: 'polaris-ideas.settings.connections.feedback-button',
		defaultMessage: 'Share your feedback',
		description: 'Feedback button label',
	},
	howToSectionTitle: {
		id: 'polaris-ideas.settings.connections.how-to-section-title',
		defaultMessage: 'How to configure your product work hierarchy with connection fields',
		description: 'Title of the how section',
	},
	howToCreateTypes: {
		id: 'polaris-ideas.settings.connections.how-to-create-types',
		defaultMessage: 'Create types',
		description: 'Title of the how to create types section',
	},
	howToCreateTypesDescription: {
		id: 'polaris-ideas.settings.connections.how-to-create-types-description',
		defaultMessage:
			'Define types to describe different items that match how your team does product work: opportunity, solution, feature, etc.',
		description: 'Description of the how to create types section',
	},
	howToCreateConnectionFields: {
		id: 'polaris-ideas.settings.connections.how-to-create-connection-fields',
		defaultMessage: 'Create connection fields',
		description: 'Title of the how to create connection fields section',
	},
	howToCreateConnectionFieldsDescription: {
		id: 'polaris-ideas.settings.connections.how-to-create-connection-fields-description',
		defaultMessage:
			'Use connection fields to link items of different types (across projects) creating flexible relationships and hierarchies.',
		description: 'Description of the how to create connection fields section',
	},
	howToConfigureViews: {
		id: 'polaris-ideas.settings.connections.how-to-configure-views',
		defaultMessage: 'Configure views',
		description: 'Title of the how to configure views section',
	},
	howToConfigureViewsDescription: {
		id: 'polaris-ideas.settings.connections.how-to-configure-views-description',
		defaultMessage:
			'Use types and connection fields in your view configuration (e.g. as fields or groups) to visualize relationships and hierarchies.',
		description: 'Description of the how to configure views section',
	},
});
