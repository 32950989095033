import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { LINE_SEPARATOR, VALUE_SEPARATOR } from './constants.tsx';

export const getFieldsLabels = (fields: Field[]) => fields.map(({ label }) => `"${label}"`);

export const joinListAsCsv = (list: string[]) => list.join(VALUE_SEPARATOR);

export const joinMatrixAsCsv = (matrix: string[][]) =>
	matrix.map(joinListAsCsv).join(LINE_SEPARATOR);

const escapeCsvValue = (text: string): string => {
	let value = text;

	// Remove the starting and ending quotes, assuming they are correctly placed
	if (value.startsWith('"') && value.endsWith('"')) {
		value = value.slice(1, -1);
	}

	// Escape internal quotes
	value = value.replace(/"/g, '""');

	// Wrap the field in quotes again
	value = `"${value}"`;
	return value;
};

/**
 * If double-quotes are used to enclose fields, then a double-quote
 * appearing inside a field must be escaped by preceding it with another
 * double quote.
 * @see https://www.ietf.org/rfc/rfc4180.txt
 */
export const fixMatrixCsvQuotes = (matrix: string[][]): string[][] => {
	return matrix.map((row) => row.map(escapeCsvValue));
};

export const createCSVDownload = (csvString: string, filename: string) => {
	const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const link = document.createElement('a');
	const url = URL.createObjectURL(blob);
	link.setAttribute('href', url);
	link.setAttribute('download', filename);
	link.style.visibility = 'hidden';

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	document.body.appendChild(link);
	link.click();

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	document.body.removeChild(link);
};
