import React, { useEffect, type ReactNode } from 'react';
import SectionMessage from '@atlaskit/section-message';
import { useIntl } from '@atlassian/jira-intl';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { useRunOnce } from '@atlassian/jira-polaris-lib-run-once/src/index.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import messages from './messages.tsx';

type Props = {
	error?: Error;
	// TODO: make required after jpd_improve_publish_dialog_ufo_experience FG cleanup
	onRender?: () => void;
	hasSeen?: boolean;
};

const ErrorScreenOld = () => {
	const { formatMessage } = useIntl();

	useEffect(() => {
		experience.sharing.dialogLoad.failure();
	}, []);

	return (
		<SectionMessage
			title={formatMessage(messages.errorTitle)}
			appearance="error"
			testId="polaris-lib-control-sharing.ui.error-screen"
		>
			{formatMessage(messages.errorDescription, {
				a: (chunks: ReactNode[]) => (
					<a href="https://support.atlassian.com/" target="_blank">
						{chunks}
					</a>
				),
			})}
		</SectionMessage>
	);
};

const ErrorScreenNew = ({ onRender, hasSeen }: Props) => {
	const { formatMessage } = useIntl();

	useRunOnce(() => {
		onRender && onRender();

		if (hasSeen) {
			experience.sharing.dialogLoad.abort();
		} else {
			const { state } = experience.sharing.dialogLoad.ufoExperience;

			// Might be the case that we've rendered dialog content while refetching the settings,
			// when user opens the dialog for the second time.
			// In this scenario we need to start experience again to send failure event correctly
			if (state.id !== 'STARTED') {
				experience.sharing.dialogLoad.start();
			}

			experience.sharing.dialogLoad.failure();
		}
	});

	return (
		<SectionMessage
			title={formatMessage(messages.errorTitle)}
			appearance="error"
			testId="polaris-lib-control-sharing.ui.error-screen"
		>
			{formatMessage(messages.errorDescription, {
				a: (chunks: ReactNode[]) => (
					<a href="https://support.atlassian.com/" target="_blank">
						{chunks}
					</a>
				),
			})}
		</SectionMessage>
	);
};

export const ErrorScreen = componentWithFG(
	'jpd_improve_publish_dialog_ufo_experience',
	ErrorScreenNew,
	ErrorScreenOld,
);
