import React, { memo, Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { styled } from '@compiled/react';
import head from 'lodash/head';
import { Box, xcss } from '@atlaskit/primitives';
import { ReactRenderer as AkRenderer } from '@atlaskit/renderer';
import { token } from '@atlaskit/tokens';
import { getUnsupportedContentLevelsTracking } from '@atlassian/jira-common-util-unsupported-content/src/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import {
	onLinkClick,
	smartLinksDefault,
} from '@atlassian/jira-linking-platform-utils/src/index.tsx';
import { getAriConfig } from '@atlassian/jira-platform-ari/src/index.tsx';
import {
	SEVERITY_DEGRADED_THRESHOLD,
	SEVERITY_NORMAL_THRESHOLD,
} from '@atlassian/jira-polaris-common/src/common/constants.tsx';
import { POLARIS_OAUTH_CLIENT_ID } from '@atlassian/jira-polaris-common/src/common/types/snippet/constants.tsx';
import {
	getPermalinkStatus,
	PermalinkType,
} from '@atlassian/jira-polaris-common/src/common/utils/permalink/index.tsx';
import { useIsSelectedIssueArchived } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import {
	useAreMediaIdsKnown,
	useMedia,
	useViewMediaProvider,
} from '@atlassian/jira-polaris-common/src/controllers/media/index.tsx';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import { useHasUnsavedChanges } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { PolarisNavigationBlocker } from '@atlassian/jira-polaris-common/src/ui/navigation-blocker/index.tsx';
import {
	useCanCreateInsights,
	useCanManageInsights,
} from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { getMediaIds } from '@atlassian/jira-polaris-lib-adf-utils/src/utils/index.tsx';
import { prepareForRender } from '@atlassian/jira-polaris-lib-editor/src/common/utils/adf.tsx';
import { WaitForAdfConsumerPropsForInsight } from '@atlassian/jira-polaris-lib-editor/src/controllers/adf/main.tsx';
import { ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { isPermissionError } from '@atlassian/jira-polaris-lib-errors/src/controllers/utils.tsx';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { useInsightsActions } from '../../../../controllers/insights/main.tsx';
import {
	useGetRealInsightSnippetIds,
	useInsightAccountId,
	useInsightDescription,
	usePolarisSnippet,
	useRealSnippet,
	useInsightAnalyticsData,
} from '../../../../controllers/insights/selectors/insights-hooks.tsx';
import { useSnippetProviders } from '../../../../controllers/insights/selectors/meta-hooks.tsx';
import { isOptimisticInsight } from '../../../../controllers/insights/utils/optimistic-updates.tsx';
import { AddInsight, type SubmitArgs } from '../add-insight/index.tsx';
import { Header } from './header/index.tsx';
import { PropertiesBar } from './properties/index.tsx';
import { Selection } from './selection/index.tsx';
import { InsightPanelSnippet } from './snippet/index.tsx';

type InsightProps = {
	insightId: string;
};

export const useAtlassianAccountId = () => {
	const { atlassianAccountId } = useTenantContext();
	return { atlassianAccountId };
};

type RealSnippetsProps = {
	hasDescription: boolean;
	insightId: string;
	realSnippetIds: string[];
};

const RealSnippets = ({ insightId, realSnippetIds, hasDescription }: RealSnippetsProps) => {
	if (realSnippetIds.length > 0) {
		return (
			<SnippetWrapper spacing={!hasDescription ? 'default' : 'compact'}>
				<SnippetContent>
					{realSnippetIds.map((snippetId, index) => (
						<Fragment key={snippetId}>
							<InsightPanelSnippet insightId={insightId} snippetId={snippetId} />
							{index < realSnippetIds.length - 1 ? <Box xcss={snippetSeparatorStyles} /> : null}
						</Fragment>
					))}
				</SnippetContent>
			</SnippetWrapper>
		);
	}
	return null;
};

const EditInsight = ({ onCancel, insightId }: { insightId: string; onCancel: () => void }) => {
	const { deleteInsight, createInsight, updateInsight } = useInsightsActions();
	const descriptionAdf = useInsightDescription(insightId);
	const realSnippets = useRealSnippet(insightId);
	const polarisSnippets = usePolarisSnippet(insightId);

	const handleSubmit = useCallback(
		({ description, snippets, user, issueKey, isSnippetsDirty }: SubmitArgs) => {
			if (!fg('jpd_insights_manipulation_slo')) {
				if (isSnippetsDirty) {
					deleteInsight(insightId);
					createInsight({ user, description, snippets, issueKey });
				} else {
					updateInsight(insightId, description);
				}

				onCancel();

				return;
			}

			experience.ideaView.insightUpdate.start();

			if (isSnippetsDirty) {
				Promise.allSettled([
					deleteInsight(insightId),
					createInsight({ user, description, snippets, issueKey }),
				]).then(([deleteInsightResult, createInsightResult]) => {
					if (
						deleteInsightResult.status === 'fulfilled' &&
						createInsightResult.status === 'fulfilled'
					) {
						experience.ideaView.insightUpdate.success();
						return;
					}

					const isDeleteRejected = deleteInsightResult.status === 'rejected';
					const isCreateRejected = createInsightResult.status === 'rejected';

					if (
						isDeleteRejected &&
						(isClientFetchError(deleteInsightResult.reason) ||
							isPermissionError(deleteInsightResult.reason)) &&
						isCreateRejected &&
						(isClientFetchError(createInsightResult.reason) ||
							isPermissionError(createInsightResult.reason))
					) {
						experience.ideaView.insightUpdate.abort();
						return;
					}

					if (isDeleteRejected || isCreateRejected) {
						experience.ideaView.insightUpdate.failure(new Error('Failed to update insight'));
					}
				});
			} else {
				updateInsight(insightId, description)
					.then(() => {
						experience.ideaView.insightUpdate.success();
					})
					.catch((error) => {
						if (isClientFetchError(error) || isPermissionError(error)) {
							experience.ideaView.insightUpdate.abort(error);
						} else {
							experience.ideaView.insightUpdate.failure(error);
						}
					});
			}

			onCancel();
		},
		[createInsight, deleteInsight, insightId, onCancel, updateInsight],
	);

	const initialValues = useMemo(() => {
		const snippet = head(realSnippets);

		return {
			descriptionAdf,
			url: snippet?.url ?? null,
			properties: snippet?.properties,
			snippetData: snippet?.data,
			oauthClientId: snippet?.oauthClientId,
			snippetId: snippet?.id,
			polarisProperties: polarisSnippets?.properties,
		};
	}, [descriptionAdf, realSnippets, polarisSnippets]);

	return (
		<AddInsight
			isUpdating
			onSubmit={handleSubmit}
			onCloseEditMode={onCancel}
			initialValues={initialValues}
		/>
	);
};

export const Insight = memo(({ insightId }: InsightProps) => {
	const providers = useSnippetProviders();
	const description = useInsightDescription(insightId);
	const insightAccountId = useInsightAccountId(insightId);
	const viewMediaProvider = useViewMediaProvider();
	const polarisSnippet = usePolarisSnippet(insightId);
	const realSnippetIds = useGetRealInsightSnippetIds(insightId);
	const { setUnsavedChanges } = useViewActions();
	const hasUnsavedChanges = useHasUnsavedChanges();

	const isArchived = useIsSelectedIssueArchived();
	const canCreateInsights = useCanCreateInsights();
	const canManageInsights = useCanManageInsights();

	const insightAnalyticsData = useInsightAnalyticsData(insightId);

	const { atlassianAccountId } = useAtlassianAccountId();

	const isOwnInsight = atlassianAccountId === insightAccountId;

	const isOptimistic = isOptimisticInsight(insightId);
	const resourceId = useMemo(
		() => (isOptimistic ? '' : getAriConfig(insightId).resourceId),
		[insightId, isOptimistic],
	);

	const { hasPermalink, permalinkId } = getPermalinkStatus(
		PermalinkType.INSIGHTS,

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.location.search,
	);

	const scrollInsightRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		if (
			scrollInsightRef &&
			scrollInsightRef.current &&
			hasPermalink &&
			resourceId === permalinkId
		) {
			scrollInsightRef.current.scrollIntoView(true);
		}
	}, [resourceId, hasPermalink, permalinkId]);

	const { deleteInsight, updateInsight } = useInsightsActions();

	const [isEditing, setIsEditing] = useState(false);

	const handleEdit = useCallback(() => {
		setIsEditing(true);
	}, []);

	const handleDelete = useCallback(() => {
		experience.ideaView.insightDelete.start();

		deleteInsight(insightId)
			.then(() => {
				experience.ideaView.insightDelete.success();
			})
			.catch((error) => {
				if (isClientFetchError(error) || isPermissionError(error)) {
					experience.ideaView.insightDelete.abort(error);
				} else {
					experience.ideaView.insightDelete.failure(error);
				}
			});
	}, [deleteInsight, insightId]);

	const handleCancel = useCallback(() => {
		setIsEditing(false);
		setUnsavedChanges(false);
	}, [setUnsavedChanges]);

	const handleChangeOauthProperty = useCallback(
		(oauth: string, key: string, value?: number | string[]) => {
			experience.ideaView.insightUpdate.start();

			updateInsight(insightId, description, [
				{
					oauthClientId: oauth,
					setProperties:
						value === undefined
							? undefined
							: {
									[key]: {
										name: key,
										value,
									},
								},
					deleteProperties: value === undefined ? [key] : undefined,
				},
			])
				.then(() => {
					experience.ideaView.insightUpdate.success();
				})
				.catch((error) => {
					if (isClientFetchError(error) || isPermissionError(error)) {
						experience.ideaView.insightUpdate.abort(error);
					} else {
						experience.ideaView.insightUpdate.failure(error);
					}
				});
		},
		[description, insightId, updateInsight],
	);

	const handlePropertyUpdate = useCallback(
		(key: string, value?: number | string[]) =>
			handleChangeOauthProperty(POLARIS_OAUTH_CLIENT_ID, key, value),
		[handleChangeOauthProperty],
	);

	const polarisSnippetDeclaredProps = useMemo(() => {
		for (let i = 0; i < providers.length; i += 1) {
			if (providers[i].app?.oauthClientId === POLARIS_OAUTH_CLIENT_ID) {
				return providers[i].properties || [];
			}
		}
		return [];
	}, [providers]);

	const areMediaIdsKnown = useAreMediaIdsKnown(
		useMemo(() => getMediaIds(description), [description]),
	);
	const [{ loading: mediaLoading }] = useMedia();

	const descriptionWithoutMediaCollection = useMemo(
		() => prepareForRender(description),
		[description],
	);

	const onNavigationAllowed = useCallback(() => {
		setUnsavedChanges(false);
	}, [setUnsavedChanges]);

	return (
		<Container
			ref={scrollInsightRef}
			data-testid="polaris-ideas.ui.insights.insights.insight.container"
			isHighlighted={hasPermalink && resourceId === permalinkId && !isEditing}
		>
			<ContextualAnalyticsData
				attributes={insightAnalyticsData?.attributes}
				containers={insightAnalyticsData?.containers}
			>
				<PolarisNavigationBlocker
					isDirty={hasUnsavedChanges && isEditing}
					onDiscard={onNavigationAllowed}
				/>
				<Selection insightId={insightId} isDisabled={!canManageInsights} />
				<ContentContainer data-component-selector="content-container-q72J">
					<Header
						isEditing={isEditing}
						canEdit={!isArchived && (canManageInsights || (canCreateInsights && isOwnInsight))}
						onEdit={handleEdit}
						canDelete={!isArchived && (canManageInsights || (canCreateInsights && isOwnInsight))}
						onDelete={handleDelete}
						insightId={insightId}
						insightResourceId={resourceId}
						isOptimistic={isOptimistic}
					/>
					<BodyWrapper data-testid="polaris-ideas.ui.insights.insights.insight.body-wrapper">
						{!isEditing ? (
							<>
								{description && description?.content?.length > 0 ? (
									<DescriptionContainer spacing={realSnippetIds.length > 0 ? 'default' : 'compact'}>
										{!areMediaIdsKnown && mediaLoading ? null : (
											<WaitForAdfConsumerPropsForInsight viewMediaProvider={viewMediaProvider}>
												{({ akRendererProps }) => (
													<AkRenderer
														{...akRendererProps}
														appearance="comment"
														document={descriptionWithoutMediaCollection}
														analyticsEventSeverityTracking={{
															enabled: true,
															severityDegradedThreshold: SEVERITY_DEGRADED_THRESHOLD,
															severityNormalThreshold: SEVERITY_NORMAL_THRESHOLD,
														}}
														unsupportedContentLevelsTracking={getUnsupportedContentLevelsTracking()}
														useSpecBasedValidator
														disableHeadingIDs
														allowAltTextOnImages
														eventHandlers={{
															smartCard: {
																onClick: onLinkClick,
															},
															link: {
																onClick: onLinkClick,
															},
														}}
														featureFlags={{
															codeBidiWarnings: true,
															'code-bidi-warnings': true,
															// enables sending analytics event with renderer specific tti measurement
															// please do not clean up, this feature flag is meant to be rolled out permanently just for a fraction of users
															'renderer-tti-tracking': fg('renderer_tti_tracking_jira'),
														}}
														smartLinks={smartLinksDefault}
														UNSTABLE_allowTableResizing={expVal(
															'platform_editor_support_table_in_comment_jira',
															'isEnabled',
															false,
														)}
														UNSTABLE_allowTableAlignment={expVal(
															'platform_editor_support_table_in_comment_jira',
															'isEnabled',
															false,
														)}
													/>
												)}
											</WaitForAdfConsumerPropsForInsight>
										)}
									</DescriptionContainer>
								) : null}
							</>
						) : (
							<EditInsight onCancel={handleCancel} insightId={insightId} />
						)}
						{!isEditing && (
							<RealSnippets
								hasDescription={description && description?.content?.length > 0}
								insightId={insightId}
								realSnippetIds={realSnippetIds}
							/>
						)}
						{!isEditing && !isOptimistic && (
							<Footer>
								<PropertiesMarker />
								<PropertiesBar
									snippet={polarisSnippet}
									onUpdate={
										!isArchived && (canManageInsights || (canCreateInsights && isOwnInsight))
											? handlePropertyUpdate
											: undefined
									}
									declared={polarisSnippetDeclaredProps}
								/>
							</Footer>
						)}
					</BodyWrapper>
				</ContentContainer>
			</ContextualAnalyticsData>
		</Container>
	);
});

Insight.displayName = 'Insight';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div<{ isHighlighted: boolean }>({
	position: 'relative',
	paddingTop: token('space.300'),
	display: 'flex',
	flex: '1 1 auto',
	borderRadius: '3px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	background: ({ isHighlighted }) => isHighlighted && token('color.background.neutral'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BodyWrapper = styled.div({
	flex: 1,
	boxSizing: 'border-box',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Footer = styled.div({
	display: 'flex',
	marginTop: token('space.050'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PropertiesMarker = styled.div({
	width: '2px',
	backgroundColor: token('color.border'),
	borderRadius: '4px',
	marginRight: token('space.100'),
	flexShrink: 0,
	marginTop: token('space.100'),
	marginBottom: token('space.100'),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SnippetWrapper = styled.div<{ spacing: any }>({
	display: 'flex',
	flex: '1 1 auto',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginTop: ({ spacing }) => (spacing === 'compact' ? '0px' : token('space.050')),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SnippetContent = styled.div({
	display: 'flex',
	flexDirection: 'column',
	flex: '1 1 auto',
});

const snippetSeparatorStyles = xcss({
	marginTop: 'space.150',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContentContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
	flex: '1 1 auto',
	width: '90%',
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DescriptionContainer = styled.div<{ spacing: any }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginBottom: ({ spacing }) => (spacing === 'compact' ? '0px' : token('space.150')),
	color: token('color.text.accent.magenta.bolder'),
});
