import { useCallback } from 'react';
import { useEnabledHiddenFields as useHiddenFieldsByUim } from '@atlassian/jira-issue-adjustments/src/controllers.tsx';
import type { ContainersByType } from '@atlassian/jira-issue-layout-common-constants/src/index.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useUserPreferencesValue } from '@atlassian/jira-issue-user-preference-services/src/main.tsx';
import {
	useIsFullPageIssueView,
	useIssueKey,
} from '@atlassian/jira-issue-context-service/src/main.tsx';
import { SIDE_PANEL_EXPERIMENT_ISSUE_ACTIVITY_LAYOUT } from '@atlassian/jira-issue-shared-types/src/common/types/user-preferences-type.tsx';
import {
	useProjectKey,
	useProjectType,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import {
	SOFTWARE_PROJECT,
	CORE_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import {
	useIssueLayoutActionsStore,
	useIssueLayoutGlanceStore,
	useIssueLayoutContextPanelStore,
	useIssueLayoutContainersStore,
} from './context.tsx';
import type { Glance, ContextPanelItem } from './types.tsx';
import { filterOutItemsWithProvidedIds } from './utils.tsx';

export const useIssueLayoutContainers = (issueKey: IssueKey) => {
	const [containers] = useIssueLayoutContainersStore(issueKey);
	return [containers] as const;
};

export const useLayoutContainerByType = (issueKey: IssueKey, type: string) => {
	const [containers] = useIssueLayoutContainersStore(issueKey);
	const container = containers.find((innerContainer) => innerContainer.containerType === type);
	// Forge app with UI Modifications module can hide selected fields using
	// UI modifications Field API. We are removing them here from the layout fields
	const fieldsHiddenByUim = useHiddenFieldsByUim();

	return [filterOutItemsWithProvidedIds(container?.items.nodes ?? [], fieldsHiddenByUim)] as const;
};

export const useIssueLayoutGlance = (issueKey: IssueKey) => {
	const [glance] = useIssueLayoutGlanceStore(issueKey);

	return [glance] as const;
};

export const useIssueLayoutContextPanel = (issueKey: IssueKey) => {
	const [contextPanel] = useIssueLayoutContextPanelStore(issueKey);

	return [contextPanel] as const;
};

export const useIssueLayoutActivitySidePanel = () => {
	// Get required values
	const isFullIssueView = useIsFullPageIssueView();
	const [activityLayoutView] = useUserPreferencesValue(SIDE_PANEL_EXPERIMENT_ISSUE_ACTIVITY_LAYOUT);
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const projectType = useProjectType(projectKey);

	const isJiraIssue = projectType === SOFTWARE_PROJECT || projectType === CORE_PROJECT;

	// Determine if in experiment condition based on project type, view and inline experiment evaluation
	const isInSidePanelExperiment =
		isJiraIssue &&
		isFullIssueView &&
		expVal('issue-view-side-panel-activity', 'isActivityInSidePanel', false);

	// Compute final values directly
	const isActivityInVerticalSidePanel =
		isInSidePanelExperiment && activityLayoutView === 'VERTICAL';

	// Return object with named properties
	return {
		isActivityInVerticalSidePanel: Boolean(isActivityInVerticalSidePanel),
		isInSidePanelExperiment: Boolean(isInSidePanelExperiment),
		activityLayoutView,
	};
};

export const useIssueLayoutActions = () => {
	const [, actions] = useIssueLayoutActionsStore();

	const setIssueViewContainersLayout = useCallback(
		(issueKey: IssueKey, containersByType: ContainersByType) => {
			actions.setIssueViewContainersLayout(issueKey, containersByType);
		},
		[actions],
	);

	const setIssueViewLayoutGlance = useCallback(
		(issueKey: IssueKey, glance?: Glance) => {
			actions.setIssueViewLayoutGlance(issueKey, glance);
		},
		[actions],
	);

	const setIssueViewLayoutContextPanel = useCallback(
		(issueKey: IssueKey, newContextPanel?: ContextPanelItem) => {
			actions.setIssueViewLayoutContextPanel(issueKey, newContextPanel);
		},
		[actions],
	);

	return [
		null,
		{
			setIssueViewContainersLayout,
			setIssueViewLayoutGlance,
			setIssueViewLayoutContextPanel,
		},
	] as const;
};
