import { useEffect, useMemo } from 'react';
import type { ViewUUID } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import type { AccountId } from '@atlassian/jira-shared-types/src/general.tsx';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { usePresence } from '../controllers/collab/index.tsx';
import {
	useViewLastSeen,
	useActions,
	useUserData,
	useSortedVisitors,
} from '../controllers/last-seen/index.tsx';
import type { Visitor, Avatar, AccountIdWithPresence } from './types.tsx';

const NO_VISITORS: AccountIdWithPresence[] = [];

const useVisitorWithPresence = (viewUUID: ViewUUID): AccountIdWithPresence[] => {
	const presence = usePresence(viewUUID);
	const visitors = useSortedVisitors({ viewUUID });
	const currentUser = useAccountId();

	// create a list of visitors with presence data
	return useMemo((): AccountIdWithPresence[] => {
		if (!currentUser) return NO_VISITORS;

		const previousVisitors: AccountId[] = visitors?.filter(
			(visitor) => !presence.some((c) => c.clientId === visitor) && visitor !== currentUser,
		);

		const data = [];
		presence.forEach((p) => {
			if (p.clientId !== undefined) {
				data.push({ accountId: p.clientId, presence: 'online' });
			}
		});
		data.push(...previousVisitors.map((v) => ({ accountId: v })));
		if (data.length === 0) {
			return NO_VISITORS;
		}
		return data;
	}, [currentUser, visitors, presence]);
};

export const useVisitorsWithUserData = (viewUUID: ViewUUID): Visitor[] => {
	const visitors = useVisitorWithPresence(viewUUID);
	const userData = useUserData({ viewUUID });
	const { fetchUserData } = useActions();
	const { data: lastSeen } = useViewLastSeen(viewUUID);
	useEffect(() => {
		fetchUserData(
			viewUUID,
			visitors.map((v) => v.accountId),
		);
	}, [viewUUID, fetchUserData, visitors]);

	return useMemo((): Visitor[] => {
		const data: Visitor[] = [];
		visitors.forEach((visitor) => {
			const accountData = userData[visitor.accountId];

			if (accountData !== undefined && accountData.data !== undefined) {
				data.push({
					name: accountData.data.displayName,
					accountId: visitor.accountId,
					avatar: accountData.data.avatarUrls['48x48'],
					presence: visitor.presence,
					timestamp: visitor.presence === 'online' ? undefined : lastSeen[visitor.accountId],
				});
			}
		});
		return data;
	}, [visitors, userData, lastSeen]);
};

export const useAvatars = (viewUUID: ViewUUID): Avatar[] => {
	const visitors = useVisitorsWithUserData(viewUUID);

	return useMemo(() => {
		return visitors.map(
			(visitor): Avatar => ({
				name: visitor.name,
				src: visitor.avatar,
				href: '#',
				presence: visitor.presence,
			}),
		);
	}, [visitors]);
};
