import React, { type ReactNode, useCallback } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import type { User } from '@atlassian/jira-polaris-common/src/common/types/user/index.tsx';
import {
	useIsIssueArchived,
	usePeopleTransformed,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { UsersField as CommonField } from '@atlassian/jira-polaris-common/src/ui/fields/users/index.tsx';
import type {
	UserValue,
	LocalIssueId,
} from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { useListPortalElement } from '../../../portal/index.tsx';
import { PopperElement } from '../../../utils/popper-element/index.tsx';
import { useFieldUpdate, useIsEditable } from '../../utils/index.tsx';

type UsersFieldProps = {
	localIssueId: LocalIssueId;
	fieldKey: string;
	interactive: boolean;
};

const UsersFieldEditViewContainer = ({ children }: { children?: ReactNode }) => (
	<PopperElement>
		<Box xcss={inlineEditableUsersContainerStyles}>{children}</Box>
	</PopperElement>
);

export const UsersField = ({ localIssueId, fieldKey, interactive }: UsersFieldProps) => {
	const people = usePeopleTransformed(fieldKey, localIssueId);
	const portalElement = useListPortalElement();
	const isEditable = useIsEditable(localIssueId, fieldKey);
	const isIdeaArchived = useIsIssueArchived(localIssueId);
	const onUpdate = useFieldUpdate(localIssueId, fieldKey);

	const onUpdateForCustomField = useCallback(
		(users: User[]) => {
			const displayUsers: UserValue[] = users.map((user) => {
				//  user picker response (https://developer.atlassian.com/cloud/jira/platform/rest/v2/api-group-user-search/#api-rest-api-2-user-picker-get) is returning one avatarUrl while in the user object we want have multiple avatarUrls for different resolutions (16x16, 24x24, 32x32, 48x48)
				const avatarUrls: UserValue['avatarUrls'] = {
					'16x16': user.avatarUrl ?? '',
					'24x24': user.avatarUrl ?? '',
					'32x32': user.avatarUrl ?? '',
					'48x48': user.avatarUrl ?? '',
				};

				return {
					accountId: user.id,
					displayName: user.name || '',
					avatarUrls,
				};
			});
			onUpdate(displayUsers);
		},
		[onUpdate],
	);

	return (
		<CommonField
			interactive={interactive}
			fieldKey={fieldKey}
			isEditable={isEditable && !isIdeaArchived}
			onUpdate={onUpdateForCustomField}
			value={people}
			portalElement={portalElement}
			editViewContainer={UsersFieldEditViewContainer}
		/>
	);
};

const inlineEditableUsersContainerStyles = xcss({
	width: '100%',
});
