import React, { type PropsWithChildren, useState, useCallback } from 'react';
import { token } from '@atlaskit/tokens';
import ChevronRightLargeIcon from '@atlaskit/icon/utility/migration/chevron-right--chevron-right-large';
import HipchatChevronDownIcon from '@atlaskit/icon/utility/migration/chevron-down--hipchat-chevron-down';
import { Box, Flex, xcss } from '@atlaskit/primitives';

type SectionProps = {
	title: string;
	defaultExpanded?: boolean;
	testId?: string;
};

export const Section = ({
	defaultExpanded,
	title,
	testId,
	children,
}: PropsWithChildren<SectionProps>) => {
	const [isExpanded, setIsExpanded] = useState(defaultExpanded ?? false);

	const handleSectionToggle = useCallback(() => {
		setIsExpanded((prevIsExpanded) => !prevIsExpanded);
	}, [setIsExpanded]);

	return (
		<Box testId={testId}>
			<Box onClick={handleSectionToggle} xcss={sectionHeaderStyles}>
				<Flex alignItems="center" gap="space.050">
					{isExpanded ? (
						<HipchatChevronDownIcon spacing="spacious" label="" />
					) : (
						<ChevronRightLargeIcon spacing="spacious" label="" />
					)}
					{title}
				</Flex>
			</Box>
			{isExpanded && <Box>{children}</Box>}
		</Box>
	);
};

const sectionHeaderStyles = xcss({
	cursor: 'pointer',
	marginBottom: 'space.150',
	fontWeight: token('font.weight.semibold'),
	paddingTop: 'space.100',
	paddingBottom: 'space.100',
});
