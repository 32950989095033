import React, { useMemo } from 'react';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import {
	jpdProjectPageLoadPermissionsStart,
	jpdProjectPageLoadPermissionsEnd,
} from '@atlassian/jira-polaris-component-metrics/src/project.tsx';
import {
	useIsCollectionView,
	useIsEmbedded,
	useIsSharedView,
} from '@atlassian/jira-polaris-common/src/controllers/environment/index.tsx';
import {
	useContainerAri,
	useEnvironmentContainerId,
} from '@atlassian/jira-polaris-component-environment-container/src/controllers/store/index.tsx';
import { PermissionsContainer } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/main.tsx';
import { getProjectContainerScope } from '@atlassian/jira-polaris-component-permissions-store/src/utils.tsx';
import type { ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import { useProjectsIdsForContainer } from '@atlassian/jira-polaris-component-project-metadata/src/controllers/index.tsx';
import { useAllIssuesProjectIds } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/project/hooks.tsx';
import { fg } from '@atlassian/jira-feature-gating';

type Props = {
	children: React.ReactNode;
	projectId: ProjectId;
};

export const WithPermissionsDataContainer = ({ children, projectId }: Props) => {
	const containerAri = useContainerAri();
	const isCollectionView = useIsCollectionView();
	const isEmbeddedView = useIsEmbedded();
	const isSharedView = useIsSharedView();
	const containerId = useEnvironmentContainerId();
	const projectIdsForContainer = useProjectsIdsForContainer({ containerId });
	const projectIdsForIssues = useAllIssuesProjectIds();

	const scope = useMemo(() => {
		if (fg('jpd_cross_project_connecting')) {
			if (getWillShowNav4() && projectId) {
				return `${getProjectContainerScope(projectId)}--cross-project-issue-linking`;
			}

			return 'permissions-singleton--cross-project-issue-linking';
		}

		if (getWillShowNav4() && projectId) {
			return getProjectContainerScope(projectId);
		}

		return 'permissions-singleton';
	}, [projectId]);

	return isSharedView ? (
		<>{children}</>
	) : (
		<PermissionsContainer
			containerAri={containerAri}
			scope={scope}
			isSharedView={isSharedView}
			isCollectionView={isCollectionView}
			isEmbeddedView={isEmbeddedView}
			onLoadStart={jpdProjectPageLoadPermissionsStart}
			onLoadEnd={jpdProjectPageLoadPermissionsEnd}
			projectIds={fg('jpd_cross_project_connecting') ? projectIdsForIssues : projectIdsForContainer}
		>
			{children}
		</PermissionsContainer>
	);
};
