import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { User } from '@atlassian/jira-shared-types/src/rest/jira/user.tsx';

export type JiraIssueWatchersResponse = {
	isWatching: boolean;
	watchCount: number;
	watchers: User[];
};

export type JiraFetchAssignableWatchersRequest = {
	query: string;
	issueKey?: IssueKey;
	maxResults: number;
};

const getWatchersApiUrl = (issueIdOrKey: string): string =>
	`/rest/api/3/issue/${issueIdOrKey}/watchers${fg('jpd-trace-fetch-network-call') ? '?operation=getWatchers' : ''}`;

export const getWatchers = (issueIdOrKey: string): Promise<JiraIssueWatchersResponse> =>
	performGetRequest(getWatchersApiUrl(issueIdOrKey));

const getAssignableWatchersApiUrl = ({
	issueKey,
	query,
	maxResults,
}: JiraFetchAssignableWatchersRequest) =>
	`/rest/api/3/user/viewissue/search?issueKey=${issueKey}&query=${query}&maxResults=${maxResults}`;

export const getAssignableWatchers = (request: JiraFetchAssignableWatchersRequest) =>
	performGetRequest(getAssignableWatchersApiUrl(request));
