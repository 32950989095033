// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import type { State, Props } from '../../types.tsx';

export const setContributorCreateIssuePermission =
	(enabled: boolean): Action<State, Props> =>
	({ getState, setState }) => {
		const state = getState();
		setState({
			contributorPermissions: [
				...state.contributorPermissions.map((permission) =>
					permission.key === 'CREATE_ISSUES'
						? { key: permission.key, havePermission: enabled }
						: permission,
				),
			],
		});
	};
