import { token } from '@atlaskit/tokens';

export const MIN_COLUMN_WIDTH = 324;
export const MAX_COLUMN_WIDTH = 570;

export const HEADER_HEIGHT = 48;
export const DEFAULT_ITEM_HEIGHT = 76;
export const ROW_GAP = 12;
export const ITEM_PADDING = 8;

export const DRAGGABLE_ITEM_TYPE = 'TIMELINE_CARD';

export const HEADER_BACKGROUND_COLOR = token('color.background.accent.gray.subtlest');
export const GRID_BACKGROUND_COLOR = token('color.background.input.hovered');
export const GRID_BORDER_COLOR = token('color.border');

export const TIMELINE_END_SPACE = 24;

export const ScaleEnum = {
	MONTHS: 'MONTHS',
	QUARTERS: 'QUARTERS',
} as const;

// used for view image export
export const TIMELINE_VIEW_CONTENT_INNER_CONTAINER =
	'polaris-lib-timeline.ui.view-content-inner-container';
