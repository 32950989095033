import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	placeholder: {
		id: 'polaris-common.fields.comments.placeholder',
		defaultMessage: 'Add',
		description:
			'Table cell placeholder (comments field) when there are no issue comments yet for this issue.',
	},
	fieldNotSupportedInThisContextTooltipNonFinal: {
		id: 'polaris-common.fields.comments.field-not-supported-in-this-context-tooltip-non-final',
		defaultMessage: 'Field is not supported in this context',
		description:
			'Shown for connection highlighted comments field for issues from different container (project)',
	},
});
