import React from 'react';
import { styled } from '@compiled/react';
import { Emoji } from '@atlaskit/emoji/element';
import EmojiAddIcon from '@atlaskit/icon/core/migration/emoji-add';
import { useIntl } from '@atlassian/jira-intl';
import { ViewItemIcon } from '@atlassian/jira-polaris-component-glyphs/src/ui/glyphs/main.tsx';
import type { ViewKind } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { EmojiWrapper } from '@atlassian/jira-polaris-lib-emoji-wrapper/src/ui/index.tsx';
import { useEmoji } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';
import messages from '@atlassian/jira-polaris-lib-emoji-picker/src/ui/messages.tsx';

type Props = {
	isReadOnly: boolean;
	emojiId: string | undefined;
	viewKind: ViewKind | undefined;
};

const TitleIconResolver = (props: Props) => {
	const { emojiId, viewKind, isReadOnly } = props;

	const emoji = useEmoji(emojiId);

	const { formatMessage } = useIntl();

	if (emoji) {
		return (
			<EmojiWrapper>
				<Emoji emoji={emoji} fitToHeight={18} showTooltip />
			</EmojiWrapper>
		);
	}
	return (
		<>
			{!isReadOnly && (
				<AddEmojiContainer shouldHover={!isReadOnly}>
					<EmojiAddIcon spacing="spacious" label={formatMessage(messages.emojiButtonCaption)} />
				</AddEmojiContainer>
			)}
			<PolarisIconWrapper shouldHover={!isReadOnly}>
				<ViewItemIcon viewKind={viewKind} />
			</PolarisIconWrapper>
		</>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PolarisIconWrapper = styled.div<{ shouldHover: boolean }>({
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'[data-component-selector="icon-wrapper-69Rx"]:hover &': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		display: ({ shouldHover }) => (shouldHover ? 'none' : 'flex'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AddEmojiContainer = styled.div<{ shouldHover: boolean }>({
	display: 'none',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'[data-component-selector="icon-wrapper-69Rx"]:hover &': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		display: ({ shouldHover }) => (shouldHover ? 'flex' : 'none'),
	},
});

export default TitleIconResolver;
