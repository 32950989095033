import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	commentEdited: {
		id: 'polaris-common.comments.comment.comment-edited',
		defaultMessage: 'Edited',
		description: 'Indicator whether or not this comment was edited',
	},
	editComment: {
		id: 'polaris-common.comments.comment.edit-comment',
		defaultMessage: 'Edit',
		description: 'Comment action: edit this comment',
	},
	deleteComment: {
		id: 'polaris-common.comments.comment.delete-comment',
		defaultMessage: 'Delete',
		description: 'Comment action: delete this comment',
	},
	deleteCommentWarningHeader: {
		id: 'polaris-common.comments.comment.delete-comment-warning-header',
		defaultMessage: 'Delete this comment?',
		description: 'Header for delete comment confirmation dialog',
	},
	cancel: {
		id: 'polaris-common.comments.comment.cancel',
		defaultMessage: 'Cancel',
		description: 'Cancel button caption',
	},
	save: {
		id: 'polaris-common.comments.comment.save',
		defaultMessage: 'Save',
		description: 'Button caption to save edited comment',
	},
	copy: {
		id: 'polaris-common.comments.comment.copy',
		defaultMessage: 'Copy link to comment',
		description: 'Button caption to save edited comment',
	},
	anonymous: {
		id: 'polaris-common.comments.comment.anonymous',
		defaultMessage: 'Anonymous',
		description: 'Display name for comments without an author',
	},
});
