import { useRef } from 'react';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createStore, createActionsHook } from '@atlassian/react-sweet-state';
import { usePermissionActions } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/main.tsx';
import { PRELOAD_PROJECT_PROPERTIES } from '../constants.tsx';
import { PROJECT_PROPERTIES, type State } from './types.tsx';
import { loadProperty as loadPropertyAction } from './actions/load-property/index.tsx';
import { saveProperty as savePropertyAction } from './actions/save-property/index.tsx';
import { setMetaProperties as setMetaPropertiesAction } from './actions/set-meta-properties/index.tsx';
import { clearProperties as clearPropertiesAction } from './actions/clear-properties/index.tsx';

const actions = {
	loadProperty: loadPropertyAction,
	saveProperty: savePropertyAction,
	setMetaProperties: setMetaPropertiesAction,
	clearProperties: clearPropertiesAction,
};

type Actions = typeof actions;

const initialState: State = {
	meta: {},
	properties: {},
};

export const ProjectPropertiesStore = createStore<State, Actions>({
	initialState,
	actions,
	name: 'PolarisProjectPropertiesStore',
});

export const useProjectPropertiesActions = createActionsHook(ProjectPropertiesStore);

type ProjectPropertiesLoaderProps = {
	projectId: string;
};

export const ProjectPropertiesLoader = ({ projectId }: ProjectPropertiesLoaderProps) => {
	const { loadProperty, setMetaProperties, clearProperties } = useProjectPropertiesActions();
	const { setContributorCreateIssuePermission } = usePermissionActions();
	const initializationDataRef = useRef<ProjectPropertiesLoaderProps | null>(null);

	if (!initializationDataRef.current || initializationDataRef.current.projectId !== projectId) {
		initializationDataRef.current = {
			projectId,
		};

		setMetaProperties({
			projectId,
		});

		if (projectId !== initializationDataRef.current.projectId) {
			clearProperties();
		}

		PRELOAD_PROJECT_PROPERTIES.forEach((propertyKey) =>
			loadProperty(
				propertyKey,
				propertyKey === PROJECT_PROPERTIES.IDEA_CREATION_BY_CONTRIBUTORS
					? (value) => setContributorCreateIssuePermission?.(value === true)
					: undefined,
			),
		);
	}

	return null;
};
