// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { logSafeErrorWithoutCustomerDataWrapper } from '@atlassian/jira-polaris-lib-errors/src/common/utils/index.tsx';
import { type Props, type State, FieldsMergeFromOption } from '../../types.tsx';

export const copyFieldValues =
	(isSameProject: boolean) =>
	async ({ getState }: StoreActionApi<State>, { projectId, issuesRemote }: Props) => {
		const { targetIssue, mergeFromOptions } = getState();
		if (projectId === null || targetIssue === null) {
			return;
		}
		try {
			if (
				isSameProject &&
				mergeFromOptions[FieldsMergeFromOption] &&
				'fieldsForUpdate' in mergeFromOptions[FieldsMergeFromOption] &&
				mergeFromOptions[FieldsMergeFromOption].fieldsForUpdate &&
				mergeFromOptions[FieldsMergeFromOption].id !== targetIssue.id
			) {
				await issuesRemote.updateIssueFields({
					issueKey: targetIssue.key,
					update: mergeFromOptions[FieldsMergeFromOption].fieldsForUpdate,
				});
			}
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (err: any) {
			logSafeErrorWithoutCustomerDataWrapper(
				'polaris.merge-ideas-error',
				'Failed to copy field values to target idea',
				err,
			);
			throw err;
		}
	};
