import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	hasNoDataDescription: {
		id: 'polaris-ideas.idea-view.sections.deliver.no-permission.has-no-data-description',
		defaultMessage:
			"There are no delivery tickets. Either you don't have permission to view them or they have not been loaded yet. Try refreshing to get the latest data.",
		description: "Description displayed when user doesn't have permissions to view JSW tickets",
	},
	hasNoDataDescriptionIssueTermRefresh: {
		id: 'polaris-ideas.idea-view.sections.deliver.no-permission.has-no-data-description-issue-term-refresh',
		defaultMessage:
			"There are no delivery work items. Either you don't have permission to view them or they have not been loaded yet. Try refreshing to get the latest data.",
		description: "Description displayed when user doesn't have permissions to view Jira work items",
	},
});
