import isEqual from 'lodash/isEqual';
import { getMaxTokenLength } from '@atlassian/jira-issue-fetch-services-common/src/common/utils/token-browser-utils.tsx';
import { fetchIssueMediaReadPermission } from '@atlassian/jira-issue-fetch-services/src/services/issue-media-read-permissions/index.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import { logSafeErrorWithoutCustomerDataWrapper } from '@atlassian/jira-polaris-lib-errors/src/common/utils/index.tsx';
import type { File } from '../../../common/types/media/types.tsx';
import type { State, Props } from '../types.tsx';

export const initMedia =
	(): Action<State, Props> =>
	({ getState, setState }, { issueId, attachmentsFromSnippets, mediaIds }) => {
		const newMeta = { issueId, attachmentsFromSnippets, mediaIds };
		const { meta } = getState();

		if (isEqual(meta, newMeta)) {
			return;
		}

		setState({
			loading: true,
			error: null,
			meta: newMeta,
		});

		// issueId or issueKey can be used for the media read permissions endpoint
		fetchIssueMediaReadPermission('', issueId, getMaxTokenLength())
			.then((data) => {
				const tokenWithFiles: {
					files: File[];
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
				} = (data && data.tokensWithFiles && (data.tokensWithFiles[0] as any)) || [];
				const files: {
					[key: string]: File;
				} = // eslint-disable-next-line @typescript-eslint/no-explicit-any
					(tokenWithFiles.files || []).reduce<Record<string, any>>(
						(result, value) => ({
							// eslint-disable-next-line jira/js/no-reduce-accumulator-spread
							...result,
							[value.attachmentId]: value,
						}),
						{},
					);

				const responseMediaIds: {
					[key: string]: true;
				} = // eslint-disable-next-line @typescript-eslint/no-explicit-any
					(tokenWithFiles.files || []).reduce<Record<string, any>>(
						(result, value) => ({
							// eslint-disable-next-line jira/js/no-reduce-accumulator-spread
							...result,
							[value.attachmentMediaApiId]: true,
						}),
						{},
					);

				setState({
					loading: false,
					error: null,
					meta: newMeta,
					mediaReadPermissions: data,
					files,
					mediaIds: responseMediaIds,
				});
			})
			.catch((err) => {
				logSafeErrorWithoutCustomerDataWrapper(
					'polaris.fetch-media-permissions',
					'Failed to fetch media permissions',
					err,
				);
				setState({
					loading: false,
					error: err,
					meta: newMeta,
					files: {},
					mediaReadPermissions: null,
				});
			});
	};
