import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	permissionErrorMessage: {
		id: 'polaris-lib-smart-user-picker.permission-error-message',
		defaultMessage:
			"We couldn't find any results because you don't have permission to search the user directory.",
		description: 'Error message when search fails due to the user not having permission',
	},
	searchErrorMessage: {
		id: 'polaris-lib-smart-user-picker.search-error-message',
		defaultMessage: "Sorry, we're having trouble loading search results right now.",
		description: 'Error message to explain search dysfunction',
	},
	noOptionsMessage: {
		id: 'polaris-lib-smart-user-picker.no-options-message',
		defaultMessage: 'No results',
		description: 'Message to display when there are no results',
	},
	withEmailPlaceholder: {
		id: 'polaris-lib-smart-user-picker.with-email-placeholder',
		defaultMessage: 'Add people, emails',
		description: 'Placeholder for the user and email search input element',
	},
	withoutEmailPlaceholder: {
		id: 'polaris-lib-smart-user-picker.without-email-placeholder',
		defaultMessage: 'Type a user name',
		description: 'Placeholder for the user search input element',
	},
});
