import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import {
	getTranslatedRoleName,
	getTranslatedRoleDescription,
} from '@atlassian/jira-polaris-lib-role-translations/src/common/utils.tsx';
import type { FormatMessage } from '@atlassian/jira-shared-types/src/general.tsx';
import type { RoleDetailsResponse } from './types.tsx';

const getUrl = (projectKey: string) =>
	`/rest/api/3/project/${projectKey}/roledetails?excludeConnectAddons=true`;

const transformResponse = (
	roleDetails: RoleDetailsResponse,
	formatMessage?: FormatMessage,
): RoleDetailsResponse =>
	roleDetails.map((roleDetail) => {
		const { name, translatedName, description } = roleDetail;

		return {
			...roleDetail,
			translatedName: getTranslatedRoleName(name, translatedName, formatMessage),
			description: getTranslatedRoleDescription(name, description, formatMessage),
		};
	});

export const getRoleDetails = (
	projectKey: string,
	formatMessage?: FormatMessage,
): Promise<RoleDetailsResponse> =>
	performGetRequest(getUrl(projectKey)).then((response) =>
		transformResponse(response, formatMessage),
	);
