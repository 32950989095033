import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	excludeIdeas: {
		id: 'polaris-component-field-searchable-dropdown.field-searchable-dropdown.field-select-option.exclude-ideas',
		defaultMessage: 'Exclude ideas',
		description: 'Label of the virtual Exclude ideas filter field',
	},
	new: {
		id: 'polaris-component-field-searchable-dropdown.field-searchable-dropdown.field-select-option.new',
		defaultMessage: 'New',
		description: 'Lozenge label for new fields',
	},
});
