export const OPEN_ACCESS_LEVEL = 'OPEN' as const;
export const LIMITED_ACCESS_LEVEL = 'LIMITED' as const;
export const PRIVATE_ACCESS_LEVEL = 'PRIVATE' as const;
export const FREE_ACCESS_LEVEL = 'FREE' as const;

// this type should live in /src/project-access but stricter does not allow me to leave it there
// All products have different access levels, so make sure any changes to this type
// (e.g. to an enum) work with JSW, JSD, and all other products.
export type AccessLevel =
	| typeof OPEN_ACCESS_LEVEL
	| typeof LIMITED_ACCESS_LEVEL
	| typeof PRIVATE_ACCESS_LEVEL
	| typeof FREE_ACCESS_LEVEL;
