import React, { useCallback, useMemo } from 'react';
import { token } from '@atlaskit/tokens';
import Button, { type ButtonProps } from '@atlaskit/button/new';
import AddIcon from '@atlaskit/icon/utility/migration/add';
import { Box, Flex, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import { useSortedIssueIds } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/sort-hooks.tsx';
import {
	IssueCreateGroupFromLocationBottom,
	IssueCreateGroupTypeEmpty,
	IssueCreateGroupTypeNoGroup,
	IssueCreateGroupTypeSpecified,
	type IssueCreatedPropertyItemGroupType,
} from '@atlassian/jira-polaris-common/src/controllers/issue/types.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import type { RowPinnedBottomRendererComponentProps } from '@atlassian/jira-polaris-lib-list/src/types.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { fg } from '@atlassian/jira-feature-gating';
import { useIsRankingAllowedDuringCreation } from '../../../utils.tsx';
import { useRowGrouping } from '../../common/utils/group-options.tsx';
import { ROW_PINNED_BOTTOM_DATA_COMPONENT_SELECTOR } from '../constants.tsx';
import { RowSticky } from '../row-sticky/index.tsx';
import { messages } from './messages.tsx';

const RowAddIcon: NonNullable<ButtonProps['iconBefore']> = (props) => (
	<AddIcon {...props} LEGACY_size="small" />
);

export const RowPinnedBottom = ({ groupId }: RowPinnedBottomRendererComponentProps) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const { createIssue, createIssueAfter } = useIssueActions();
	const isRankingAllowedDuringCreation = useIsRankingAllowedDuringCreation();
	const rowIds = useSortedIssueIds();

	const [verticalGroupByField, extendedOptions, _rowGroups, groupingWithIdeasInCreation] =
		useRowGrouping();

	const fieldKey = verticalGroupByField?.key;
	const noGroupingLastItemLocalIssueId = rowIds.at(-1);

	const handleClickWhenNoGrouping = useCallback(() => {
		fireCompoundAnalyticsEvent.ListView.addIdeaFixedBottomRowClicked(
			createAnalyticsEvent({}),
			'no-group',
		);
		experience.listView.createIdeaInline.start();
		experience.listView.createIdeaInlineClickResponse.start();

		const grouping: IssueCreatedPropertyItemGroupType = {
			groupType: IssueCreateGroupTypeNoGroup,
			fromLocation: IssueCreateGroupFromLocationBottom,
			rankingAllowed: isRankingAllowedDuringCreation,
		};

		if (noGroupingLastItemLocalIssueId) {
			createIssueAfter(noGroupingLastItemLocalIssueId, undefined, grouping);
		} else {
			createIssue(0, undefined, grouping);
		}
	}, [
		createAnalyticsEvent,
		createIssue,
		createIssueAfter,
		isRankingAllowedDuringCreation,
		noGroupingLastItemLocalIssueId,
	]);

	const handleClickWhenGrouping = useCallback(() => {
		if (!fieldKey || !groupingWithIdeasInCreation) {
			return;
		}

		fireCompoundAnalyticsEvent.ListView.addIdeaFixedBottomRowClicked(
			createAnalyticsEvent({}),
			'group',
		);
		experience.listView.createIdeaInline.start();
		experience.listView.createIdeaInlineClickResponse.start();

		const isEmptyGroup = groupId === undefined;
		const extendedOption = extendedOptions?.find((option) => option.groupIdentity === groupId);

		const grouping: IssueCreatedPropertyItemGroupType = isEmptyGroup
			? {
					groupType: IssueCreateGroupTypeEmpty,
					fromLocation: IssueCreateGroupFromLocationBottom,
					rankingAllowed: isRankingAllowedDuringCreation,
				}
			: {
					groupType: IssueCreateGroupTypeSpecified,
					groupIdentity: groupId,
					fieldKey,
					fieldValue: extendedOption?.value,
					fromLocation: IssueCreateGroupFromLocationBottom,
					rankingAllowed: isRankingAllowedDuringCreation,
				};

		const group =
			grouping.groupType === IssueCreateGroupTypeEmpty
				? groupingWithIdeasInCreation.empty
				: groupingWithIdeasInCreation.groups[grouping.groupIdentity];

		const lastItemInGroupLocalIssueId = group?.at(-1);

		if (lastItemInGroupLocalIssueId) {
			createIssueAfter(lastItemInGroupLocalIssueId, undefined, grouping);
		} else {
			createIssue(0, undefined, grouping);
		}
	}, [
		createAnalyticsEvent,
		createIssue,
		createIssueAfter,
		extendedOptions,
		fieldKey,
		groupId,
		groupingWithIdeasInCreation,
		isRankingAllowedDuringCreation,
	]);

	const mode = useMemo(() => {
		if (!fieldKey && !groupingWithIdeasInCreation && groupId === undefined) {
			return 'no-group';
		}

		return 'group';
	}, [fieldKey, groupId, groupingWithIdeasInCreation]);

	return (
		<RowSticky>
			<Flex xcss={containerStyles} role="gridcell">
				<Button
					interactionName="jpd.list.add-idea"
					iconBefore={RowAddIcon}
					appearance="subtle"
					onClick={mode === 'group' ? handleClickWhenGrouping : handleClickWhenNoGrouping}
					data-component-selector={ROW_PINNED_BOTTOM_DATA_COMPONENT_SELECTOR}
				>
					<Box xcss={textStyles}>
						{formatMessage(
							fg('jpd_issue_types_ga') ? messages.createIdeaButton : messages.createIdeaButtonText,
						)}
					</Box>
				</Button>
			</Flex>
		</RowSticky>
	);
};

const containerStyles = xcss({
	position: 'relative',
	top: '-1px',
	left: '1px',
	paddingInlineStart: 'space.250',
	alignItems: 'center',
	height: '100%',
});

const textStyles = xcss({
	paddingInlineStart: 'space.050',
	color: 'color.text.subtlest',
	fontWeight: token('font.weight.regular'),
});
