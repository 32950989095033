import React, { type ComponentPropsWithRef } from 'react';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { GENERIC_FIELD_FILTER } from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import {
	useConnectionFieldFilter,
	useFieldFilter,
} from '../../../../../controllers/views/selectors/filters-hooks.tsx';
import { Dialog } from './dialog.tsx';

type Props = Omit<ComponentPropsWithRef<typeof Dialog>, 'initiallyOpen'> & {
	fieldKey: Field['key'];
	isLastFilter: boolean;
	defaultOpen: boolean;
};

export const SelectionAwareDialog = ({
	fieldKey,
	isDisabled,
	children,
	isLastFilter,
	defaultOpen,
	content,
}: Props) => {
	const filter = useFieldFilter(fieldKey);
	const connectionFilter = useConnectionFieldFilter(fieldKey);

	const someValuesAreSelected =
		(filter.type === GENERIC_FIELD_FILTER && filter.values.length > 0) ||
		!!connectionFilter?.values?.length;
	const initiallyOpen = defaultOpen || (isLastFilter && !someValuesAreSelected);

	return (
		<Dialog content={content} initiallyOpen={initiallyOpen} isDisabled={isDisabled}>
			{children}
		</Dialog>
	);
};
