import { fg } from '@atlassian/jira-feature-gating';
import { getProjectConfigInsights } from '../../services/polaris-api/get-insights/index.tsx';
import type {
	FetchProjectConfigInsightsRequest,
	ProviderProps,
	RemoteProjectConfigInsightsFetcher,
} from '../../types.tsx';

type EntityWithCreatedDate = {
	created: string;
};
const sortByCreatedDate = (a: EntityWithCreatedDate, b: EntityWithCreatedDate) =>
	new Date(a.created).getTime() - new Date(b.created).getTime();

export const fetchProjectConfigInsights =
	({ apolloClient, cloudId, projectId }: ProviderProps): RemoteProjectConfigInsightsFetcher =>
	({ createAnalyticsEvent, archivedMode, fireAnalyticsEvent }: FetchProjectConfigInsightsRequest) =>
		getProjectConfigInsights(
			apolloClient,
			cloudId,
			projectId,
			archivedMode,
			createAnalyticsEvent,
			fireAnalyticsEvent,
		)
			.then((insights) => [
				...insights.map((insight) => ({
					description: null,
					...insight,
					snippets: [
						...insight.snippets.map((snippet) => ({
							data: null,
							oauthClientId: '',
							url: null,
							...snippet,
						})),
					],
				})),
			])
			.then((insights) =>
				fg('jpd-aurora-fix-insights-notifications') ? insights.sort(sortByCreatedDate) : insights,
			);
