import React from 'react';
import {
	ContextualAnalyticsData,
	FireScreenAnalytics,
	SCREEN,
} from '@atlassian/jira-product-analytics-bridge';
import { useIsConnectionsEnabled } from '@atlassian/jira-polaris-lib-entitlement-utils/src/index.tsx';
import { ConnectionsPageContent } from './connections-page-content.tsx';

export const ConnectionsPage = () => {
	const isConnectionsEnabled = useIsConnectionsEnabled();

	if (!isConnectionsEnabled) {
		return null;
	}

	return (
		<ContextualAnalyticsData sourceType={SCREEN} sourceName="connectionsPage">
			<FireScreenAnalytics />
			<ConnectionsPageContent />
		</ContextualAnalyticsData>
	);
};
