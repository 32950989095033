import React from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import SectionMessage, { SectionMessageAction } from '@atlaskit/section-message';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

export const InsightsUnavailableSharedViewSection = ({ isCompact }: { isCompact?: boolean }) => {
	const { formatMessage } = useIntl();
	const EARLY_ACCESS_SHARING_LINK =
		'https://community.atlassian.com/t5/Jira-Product-Discovery-articles/Early-preview-of-the-Stakeholders-Sharing-experience/ba-p/2369621';

	return (
		<SectionContainer isCompact={isCompact}>
			<SectionMessage
				appearance="warning"
				actions={[
					<SectionMessageAction key="learn-more-early-access-insights">
						<Button
							appearance="link"
							href={EARLY_ACCESS_SHARING_LINK}
							spacing="none"
							target="_blank"
						>
							{formatMessage(messages.learnMore)}
						</Button>
					</SectionMessageAction>,
				]}
			>
				{/* eslint-disable-next-line @atlaskit/design-system/use-primitives-text */}
				<p>{formatMessage(messages.description)}</p>
			</SectionMessage>
		</SectionContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SectionContainer = styled.div<{ isCompact?: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	margin: ({ isCompact }) => (isCompact ? '0' : `${token('space.150')} 0`),
});
