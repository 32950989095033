import {
	getUserProperty,
	setUserProperties,
} from '@atlassian/jira-common-rest/src/api/latest/user-properties/index.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { createErrorAnalytics } from '@atlassian/jira-polaris-lib-errors/src/controllers/index.tsx';
import { isNotFoundError } from '@atlassian/jira-polaris-lib-errors/src/controllers/utils.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import { VIEW_PERMISSIONS_LOCK_SPOTLIGHT_DISMISSED } from '../../../constants.tsx';
import type { Props, State } from '../../../types.tsx';
import { normalizeError } from '../../../../common/utils.tsx';

export const loadViewPermissionsSpotlightDismissed =
	(): Action<State, Props> =>
	async ({ getState, setState }, { accountId }) => {
		if (!accountId) {
			return;
		}

		// User property is already loaded
		if (typeof getState().viewPermissions.spotlightDismissed === 'boolean') {
			return;
		}

		try {
			const viewPermissionsSpotlightDismissed = await getUserProperty<boolean>(
				accountId,
				VIEW_PERMISSIONS_LOCK_SPOTLIGHT_DISMISSED,
			);

			const state = getState();

			setState({
				...state,
				viewPermissions: {
					spotlightDismissed: viewPermissionsSpotlightDismissed,
				},
			});
		} catch (error) {
			// Expected error, the property is not set yet
			if (error instanceof FetchError && isNotFoundError(error)) {
				const state = getState();

				setState({
					...state,
					viewPermissions: {
						spotlightDismissed: false,
					},
				});

				return;
			}

			fireErrorAnalytics(
				createErrorAnalytics(
					'polaris.view-permissions-lock.spotlight-dismissed',
					normalizeError(error),
				),
			);
		}
	};

export const setViewPermissionsSpotlightDismissed =
	(): Action<State, Props> =>
	async ({ getState, setState }, { accountId }) => {
		if (!accountId) {
			return;
		}

		const state = getState();

		setState({
			...state,
			viewPermissions: {
				spotlightDismissed: true,
			},
		});

		try {
			await setUserProperties(accountId, VIEW_PERMISSIONS_LOCK_SPOTLIGHT_DISMISSED, 'true');
		} catch (error) {
			fireErrorAnalytics(
				createErrorAnalytics(
					'polaris.view-permissions-lock.spotlight-dismissed',
					normalizeError(error),
				),
			);
		}
	};
