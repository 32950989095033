import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { FinancialYear } from '../common/types.tsx';

export const updateProductSettingsFinancialYear = (
	financialYearInitialMonth: number,
): Promise<FinancialYear> =>
	performPutRequest('/rest/polaris/product-settings', {
		body: JSON.stringify({
			financialYear: {
				initialMonth: financialYearInitialMonth,
			},
		}),
	});
