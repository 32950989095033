import {
	createGetAllIssueIdsMatchingConnectionFieldFilters,
	createGetColumnAndGroupAwareConnectionFieldIssueIdsSorted,
	createGetColumnAndGroupAwareConnections,
	createGetConnectionFieldSortedIssueIds,
	createGetSelectedIssuesConnectionFieldIssuesCount,
	createIsIssueMatchingConnectionFieldFilter,
	getSelectedIssueConfiguredConnectionFields,
	getSelectedIssueConfiguredConnectionFieldsCount,
} from './connection.tsx';
import { useLocalIssueIdForJiraIssueId } from './issue-ids-hooks.tsx';
import { useIssueType, useSafeIssueKey, useSummary } from './properties/hooks.tsx';
import {
	createHigherLevelIssueHook3,
	createHigherLevelIssueHook2,
	createHigherLevelIssueHook,
	createIssueHook,
} from './utils.tsx';

export const useColumnAndGroupAwareConnectionFieldIssueIdsSorted = createHigherLevelIssueHook3(
	createGetColumnAndGroupAwareConnectionFieldIssueIdsSorted,
);

export const useColumnAndGroupAwareConnections = createHigherLevelIssueHook2(
	createGetColumnAndGroupAwareConnections,
);

export const useConnectionFieldSortedIssueIds = createHigherLevelIssueHook2(
	createGetConnectionFieldSortedIssueIds,
);

export const useIsIssueMatchingConnectionFieldFilter = createHigherLevelIssueHook2(
	createIsIssueMatchingConnectionFieldFilter,
);
export const useAllIssueIdsMatchingConnectionFieldFilters = createHigherLevelIssueHook2(
	createGetAllIssueIdsMatchingConnectionFieldFilters,
);

export const useSelectedIssuesConnectionFieldIssuesCount = createHigherLevelIssueHook(
	createGetSelectedIssuesConnectionFieldIssuesCount,
);

export const useConnectedIssueDataByIssueId = (jiraIssueId: string) => {
	const localIssueId = useLocalIssueIdForJiraIssueId(jiraIssueId) ?? '';
	const summary = useSummary(localIssueId);
	const issueType = useIssueType(localIssueId);
	const issueKey = useSafeIssueKey(localIssueId);

	if (!localIssueId || !issueType || !issueKey) {
		return undefined;
	}

	return {
		issueKey,
		summary,
		issueType,
	};
};

export const useSelectedIssueConfiguredConnectionFields = createIssueHook(
	getSelectedIssueConfiguredConnectionFields,
);

export const useSelectedIssueConfiguredConnectionFieldsCount = createIssueHook(
	getSelectedIssueConfiguredConnectionFieldsCount,
);
