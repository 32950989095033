import React, { type ReactNode } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';

type Props = {
	appearance?: 'inprogress' | 'success';
	children: ReactNode;
};

export const MinimalLozenge = ({ appearance, children }: Props) => (
	// @ts-expect-error - TS2322: Type 'undefined' is not assignable to type 'inprogress | success | default'.
	<LozengeContainer appearance={appearance}>
		<LozengeTextContainer>{children}</LozengeTextContainer>
	</LozengeContainer>
);

const COLORS = {
	inprogress: token('color.text.information'),
	success: token('color.text.success'),
	default: token('color.text'),
} as const;

const BACKGROUNDS = {
	inprogress: token('color.background.information'),
	success: token('color.background.success'),
	default: token('color.background.neutral'),
} as const;

type LozengeContainerType = {
	appearance: keyof typeof COLORS;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LozengeContainer = styled.div<LozengeContainerType>({
	paddingTop: token('space.025'),
	paddingRight: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	paddingBottom: () =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values
		isVisualRefreshEnabled() && fg('platform-dst-jira-web-fonts') ? token('space.025') : '3px',
	paddingLeft: 0,
	borderRadius: '3px',

	font: token('font.body.small'),
	fontWeight: token('font.weight.bold'),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: 1,
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	textTransform: 'uppercase',
	verticalAlign: 'baseline',
	display: 'inline-block',
	boxSizing: 'border-box',
	maxWidth: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	color: ({ appearance }) => (COLORS[appearance] ? COLORS[appearance] : COLORS.default),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: ({ appearance }) =>
		BACKGROUNDS[appearance] ? BACKGROUNDS[appearance] : BACKGROUNDS.default,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LozengeTextContainer = styled.span({
	maxWidth: '200px',
	display: 'inline-block',
	boxSizing: 'border-box',
	width: '100%',
	paddingTop: 0,
	paddingRight: token('space.050'),
	paddingBottom: 0,
	paddingLeft: token('space.050'),
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	verticalAlign: 'top',
	whiteSpace: 'nowrap',
});
