import { type Options, useCrossFlow } from '@atlassiansox/cross-flow-support';
import { useMessageInstanceEvent } from '@post-office/message-instance-event-frontend-client';
import { useMessageEvent } from '@post-office/message-lifecycle';
import { type ClickedElementAttributes } from '@post-office/shared-contracts';
import { useCallback } from 'react';

export const useCrossflowMessageClicked = (
	crossflowOptions: Options,
	clickedElement: ClickedElementAttributes,
) => {
	const crossflow = useCrossFlow();
	const { messageClicked } = useMessageEvent();
	const { messageInstanceClicked } = useMessageInstanceEvent();

	const handleClick = useCallback(() => {
		messageClicked().catch(() => {});
		messageInstanceClicked({
			messageEventType: 'clicked',
			clickedElement,
			action: {
				actionType: 'acknowledge',
			},
			additionalMetadataFromFrontend: {},
		}).catch(() => {});
		if (crossflow.isEnabled) {
			crossflow.api.open(crossflowOptions).catch(() => {});
		}
	}, [crossflow, crossflowOptions, clickedElement, messageClicked, messageInstanceClicked]);

	return { handleClick };
};
