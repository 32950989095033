import React, { useCallback } from 'react';
import { styled } from '@compiled/react';
import Button, { LoadingButton } from '@atlaskit/button';
import RefreshIcon from '@atlaskit/icon/core/migration/refresh';
import SettingsIcon from '@atlaskit/icon/core/migration/settings';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import type { SnippetRefreshStatus } from '@atlassian/jira-polaris-domain-insight/src/snippet/types.tsx';
import { isPendingRefresh, isAuthRefreshError } from '../../../common/refresh/index.tsx';
import { PolarisRefreshTooltipContent } from '../../refresh/index.tsx';
import { messages } from './messages.tsx';

type ActionPropertiesProps = {
	refresh?: SnippetRefreshStatus | null;
	updated: string | null;
	alwaysVisible: boolean;
	onSnippetRefreshRequested?: () => void;
	onConfigureProperties?: () => void;
};

export const ActionProperties = ({
	refresh,
	updated,
	alwaysVisible,
	onSnippetRefreshRequested,
	onConfigureProperties,
}: ActionPropertiesProps) => {
	const { formatMessage } = useIntl();
	const isRefreshable = refresh !== undefined && refresh !== null;
	const authRefreshError = isAuthRefreshError(refresh);
	const isPending = isPendingRefresh(refresh);
	const manualRefresh = useCallback(() => {
		if (onSnippetRefreshRequested !== undefined) {
			onSnippetRefreshRequested();
		}
	}, [onSnippetRefreshRequested]);

	return (
		<>
			{isRefreshable && !authRefreshError ? (
				<ActionWrapper alwaysVisible={alwaysVisible}>
					<Tooltip content={<PolarisRefreshTooltipContent refresh={refresh} updated={updated} />}>
						<LoadingButton
							testId="polaris-common.ui.snippet.properties.actions.loading-button"
							isLoading={isPending}
							appearance="subtle-link"
							spacing="compact"
							iconAfter={<RefreshIcon label="refresh" LEGACY_size="small" />}
							onClick={manualRefresh}
						/>
					</Tooltip>
				</ActionWrapper>
			) : null}
			{onConfigureProperties && !authRefreshError ? (
				<ActionWrapper alwaysVisible={alwaysVisible}>
					<Tooltip content={formatMessage(messages.configurePropertiesAction)}>
						<Button
							testId="polaris-common.ui.snippet.properties.actions.button"
							appearance="subtle-link"
							spacing="compact"
							iconAfter={<SettingsIcon label="settings" LEGACY_size="small" />}
							onClick={onConfigureProperties}
						/>
					</Tooltip>
				</ActionWrapper>
			) : null}
		</>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ActionWrapper = styled.div<{ alwaysVisible: boolean }>({
	marginTop: token('space.075'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	visibility: ({ alwaysVisible }) => !alwaysVisible && 'hidden',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'[data-component-selector="snippet-container-9Ks5"]:hover &': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		visibility: ({ alwaysVisible }) => !alwaysVisible && 'visible',
	},
});
