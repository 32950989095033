// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { RawItem } from '../../../common/types/timeline/index.tsx';
import {
	type GroupedIds,
	type Props,
	type GroupToItems,
	NO_VALUE_GROUP_ID,
	type TimelineItem,
} from '../../../types.tsx';
import { getRowGroups } from '../../selectors/groups.tsx';
import type { State } from '../../types.tsx';

export const reOrderGroups =
	({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) =>
	({ setState, getState }: StoreActionApi<State>, props: Props) => {
		const state = getState();
		const rowGroups = getRowGroups(state);
		const { onGroupOrderChanged } = props;

		if (rowGroups === undefined || oldIndex === newIndex) {
			return;
		}
		// cannot drag and drop before no value group
		if (rowGroups[newIndex] !== NO_VALUE_GROUP_ID) {
			const newRowGroups = [...rowGroups];
			const [removed] = newRowGroups.splice(oldIndex, 1);
			newRowGroups.splice(newIndex, 0, removed);
			setState({ rowGroups: newRowGroups });

			onGroupOrderChanged && onGroupOrderChanged({ oldIndex, newIndex });
		}
	};

export const updateInitialGroupState =
	// TODO: remove RawItem type after cleanup POL-11648


		(groupedIds: GroupedIds | undefined, items: (RawItem | TimelineItem)[]) =>
		({ setState }: StoreActionApi<State>) => {
			if (!groupedIds) {
				setState({ groupIds: groupedIds });
			} else {
				const groupIds: GroupToItems = {};
				const itemIds = items.map(({ id }: { id: string }) => id);
				Object.entries(groupedIds.groups).forEach(([id, values]) => {
					groupIds[id] = values.filter((value) => itemIds.includes(value));
				});
				groupIds[NO_VALUE_GROUP_ID] = (groupedIds.empty || []).filter((value) =>
					itemIds.includes(value),
				);

				setState({ groupIds });
			}
		};
