// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { BaseTableApi } from '../../../common/types/react-base-table/index.tsx';
import type { State } from '../../types.tsx';

export const setBaseTableApi =
	(baseTableApi?: BaseTableApi) =>
	({ setState }: StoreActionApi<State>) => {
		setState({ baseTableApi });
	};
