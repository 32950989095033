import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { DEFAULT_ROW_HEIGHT } from './constants.tsx';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ViewDescription = styled.span({
	whiteSpace: 'pre',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ViewImage = styled.img<{ imageMaxWidth?: string }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	maxWidth: ({ imageMaxWidth }) => imageMaxWidth ?? '300px',
	width: '100%',
	height: 'auto',
	marginBottom: token('space.300'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ListPlaceholder = styled.div({
	display: 'flex',
	flexDirection: 'column',
	position: 'absolute',
	width: '100%',
	height: '100%',
	zIndex: '0',
	top: '0',
	left: '0',
	marginRight: token('space.300'),
	paddingBottom: '0',
	backgroundColor: token('color.background.input.hovered'),
	borderStyle: 'solid',
	borderWidth: '1px',
	borderColor: token('color.border'),
	borderRadius: '6px',
	overflow: 'hidden',
	boxSizing: 'border-box',

	'&::before': {
		content: '',
		position: 'absolute',
		width: '100%',
		height: '100%',
		backgroundColor: token('elevation.surface'),
		opacity: '0.6',
		zIndex: '1',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const RowPlaceholder = styled.div<{ isHeader: boolean }>({
	height: 'auto',
	width: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	minHeight: `${DEFAULT_ROW_HEIGHT}px`,
	boxSizing: 'border-box',
	borderBottomWidth: '1px',
	borderBottomStyle: 'solid',
	borderColor: token('color.border'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: ({ isHeader }) => (isHeader ? undefined : `${token('elevation.surface')}`),
});
