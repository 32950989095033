import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { fetchJson } from '@atlassian/jira-fetch/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { translateToPublicIssue } from '../util/index.tsx';
import type {
	GetIssueResponse,
	JpdJiraSearchApiResponse,
	JpdJiraSearchApiResponseLegacy,
} from './types.tsx';

const SEARCH_ISSUES_JQL_URL = '/rest/polaris/issues/searchJql';
const POLARIS_SEARCH_V3_URL = '/rest/polaris/issues/v3/search';

type GetIssuesWithJqlInput = {
	jql: string;
	issueIdsOrKeys?: string[];
	maxResults?: number;
};

export const getIssuesWithJql = ({
	jql,
	issueIdsOrKeys,
	maxResults = 5000,
}: GetIssuesWithJqlInput): Promise<GetIssueResponse> => {
	if (fg('jpd_issues_relationships_roadmaps')) {
		return fetchJson<JpdJiraSearchApiResponse>(POLARIS_SEARCH_V3_URL, {
			method: 'POST',
			body: JSON.stringify({
				jql: issueIdsOrKeys ? `issue in (${issueIdsOrKeys.join(',')})` : jql,
				fields: {
					allGlobal: true,
				},
				include: {
					projectData: true,
					archived: 'ACTIVE_ONLY',
					metadata: true,
					connections: true,
				},
				pagination: {
					max: maxResults,
				},
			}),
		}).then((response) => ({
			issues: response.issues.map((issue) => translateToPublicIssue(response, issue)),
			total: response.pagination.total,
			issueLists: response.issueLists
				? {
						primary: response.issueLists.primary?.map(String),
						connections: response.issueLists.connections?.map(String),
					}
				: undefined,
		}));
	}

	return performPostRequest<JpdJiraSearchApiResponseLegacy>(SEARCH_ISSUES_JQL_URL, {
		body: JSON.stringify({
			jql,
			archived: 'ACTIVE_ONLY',
			includeProjectData: true,
		}),
	}).then((response) => ({
		issues: response.issues.map((issue) => translateToPublicIssue(response, issue)),
		total: response.issues.length,
	}));
};
