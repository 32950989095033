import React from 'react';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import EditorInfoIcon from '@atlaskit/icon/core/migration/information--editor-info';
import { Box, Flex, Text, xcss } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

type ConvertToProjectRankItemProps = {
	disabledButtonMessageWithCanEditIssues?: string;
	setSortModeOfCurrentViewToProjectRankAndCommitCurrentSort: () => void;
};

type ConvertToViewRankItemProps = {
	disabledButtonMessage?: string;
	setSortModeOfCurrentViewToViewRankAndAddCurrentSort: () => void;
};

type FieldSortContentProps = ConvertToProjectRankItemProps & ConvertToViewRankItemProps;

export const ConvertToViewRankItem = ({
	disabledButtonMessage,
	setSortModeOfCurrentViewToViewRankAndAddCurrentSort,
}: ConvertToViewRankItemProps) => {
	const { formatMessage } = useIntl();

	return (
		<Tooltip content={disabledButtonMessage}>
			<DropdownItem
				testId="polaris-component-view-sort-configuration.ui.advanced.field-sort.convert-to-view-rank"
				description={
					<Box xcss={descriptionStyles}>{formatMessage(messages.convertToViewRankDescription)}</Box>
				}
				isDisabled={!!disabledButtonMessage}
				onClick={setSortModeOfCurrentViewToViewRankAndAddCurrentSort}
			>
				{formatMessage(messages.convertToViewRank)}
			</DropdownItem>
		</Tooltip>
	);
};

export const ConvertToProjectRankItem = ({
	disabledButtonMessageWithCanEditIssues,
	setSortModeOfCurrentViewToProjectRankAndCommitCurrentSort,
}: ConvertToProjectRankItemProps) => {
	const { formatMessage } = useIntl();

	return (
		<Tooltip content={disabledButtonMessageWithCanEditIssues}>
			<DropdownItem
				testId="polaris-component-view-sort-configuration.ui.advanced.field-sort.convert-to-project-rank"
				description={
					<Box xcss={descriptionStyles}>
						{formatMessage(messages.convertToProjectRankDescription)}
					</Box>
				}
				isDisabled={!!disabledButtonMessageWithCanEditIssues}
				onClick={setSortModeOfCurrentViewToProjectRankAndCommitCurrentSort}
			>
				{formatMessage(messages.convertToProjectRank)}
			</DropdownItem>
		</Tooltip>
	);
};

export const FieldSortContent = ({
	disabledButtonMessage,
	disabledButtonMessageWithCanEditIssues,
	setSortModeOfCurrentViewToViewRankAndAddCurrentSort,
	setSortModeOfCurrentViewToProjectRankAndCommitCurrentSort,
}: FieldSortContentProps) => {
	const { formatMessage } = useIntl();
	return (
		<Box paddingBlockEnd="space.300">
			<Box paddingBlockStart="space.075" paddingBlockEnd="space.150">
				<Text>{formatMessage(messages.fieldSortDescription)}</Text>
			</Box>
			<Flex alignItems="center">
				<DropdownMenu
					testId="polaris-component-view-sort-configuration.ui.advanced.field-sort.change-to-ranked-mode-trigger"
					trigger={formatMessage(messages.changeToRankedModeTrigger)}
				>
					<Box xcss={dropdownMenuWrapperStyles}>
						<DropdownItemGroup>
							<ConvertToViewRankItem
								disabledButtonMessage={disabledButtonMessage}
								setSortModeOfCurrentViewToViewRankAndAddCurrentSort={
									setSortModeOfCurrentViewToViewRankAndAddCurrentSort
								}
							/>
							<ConvertToProjectRankItem
								disabledButtonMessageWithCanEditIssues={disabledButtonMessageWithCanEditIssues}
								setSortModeOfCurrentViewToProjectRankAndCommitCurrentSort={
									setSortModeOfCurrentViewToProjectRankAndCommitCurrentSort
								}
							/>
						</DropdownItemGroup>
					</Box>
				</DropdownMenu>
				<Box paddingInlineStart="space.050">
					<Tooltip content={formatMessage(messages.changeToRankedModeTooltip)}>
						<EditorInfoIcon spacing="spacious" label="info" />
					</Tooltip>
				</Box>
			</Flex>
		</Box>
	);
};

const descriptionStyles = xcss({
	whiteSpace: 'normal',
});

const dropdownMenuWrapperStyles = xcss({
	maxWidth: '320px',
});
