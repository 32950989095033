import React, { useCallback } from 'react';
import { token } from '@atlaskit/tokens';
import { Text, xcss, Box } from '@atlaskit/primitives';
import FilesIcon from '@atlaskit/icon/core/files';
import { components, type ControlProps } from '@atlaskit/select';
import type { ProjectOption } from '@atlassian/jira-polaris-project-select/src/ui/types.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { messages } from './messages.tsx';
import { useCurrentProject } from './utils.tsx';

export const ProjectSelectControl = ({ children, ...props }: ControlProps<ProjectOption, true>) => {
	const { formatMessage } = useIntl();
	const { value, menuIsOpen, onMenuOpen, onMenuClose } = props.selectProps;
	const currentProject = useCurrentProject();
	const selectedProjects: ProjectOption[] = Array.isArray(value) ? value : [];
	const hasOtherProjectsSelected = selectedProjects.some(({ id }) => id !== currentProject?.id);

	const isDisabled = props.isDisabled;

	const handleClick = useCallback(() => {
		if (isDisabled) {
			return;
		}

		if (menuIsOpen) {
			onMenuClose();
		} else {
			onMenuOpen();
		}
	}, [menuIsOpen, onMenuClose, onMenuOpen, isDisabled]);

	return (
		<Box onClick={handleClick}>
			<components.Control {...props}>
				{selectedProjects.length > 0 && (
					<Box xcss={valueContainerStyles}>
						<FilesIcon
							color={isDisabled ? token('color.icon.disabled') : undefined}
							label={formatMessage(messages.projectSelectIconLabelNonFinal)}
						/>
						<Text color={isDisabled ? 'color.text.disabled' : undefined} weight="bold">
							{hasOtherProjectsSelected
								? formatMessage(messages.selectedProjectsCountLabelNonFinal, {
										count: selectedProjects.length,
									})
								: formatMessage(messages.currentProjectLabelNonFinal)}
						</Text>
					</Box>
				)}
				{children}
			</components.Control>
		</Box>
	);
};

const valueContainerStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	gap: 'space.100',
	paddingInlineStart: 'space.075',
	flex: '1 1 auto',
	alignSelf: 'stretch',
});
