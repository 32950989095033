import React, { forwardRef } from 'react';

import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { type TooltipPrimitiveProps, TooltipPrimitive } from '@atlaskit/tooltip';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const Tooltip = styled(TooltipPrimitive)({
	display: 'block',
	boxSizing: 'border-box',
	margin: '0 -3px',
	paddingTop: token('space.050'),
	paddingRight: token('space.100'),
	paddingBottom: token('space.050'),
	paddingLeft: token('space.100'),
	// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage -- The token value "4px" and fallback "3px" do not match and can't be replaced automatically.
	borderRadius: token('border.radius.100', '3px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.icon.danger'),

	color: token('color.text.inverse'),
	boxShadow: token('elevation.shadow.overlay'),
});

export const CustomTooltip = forwardRef<HTMLDivElement, TooltipPrimitiveProps>((props, ref) => (
	<Tooltip {...props} ref={ref} />
));
