import React, { useCallback } from 'react';
import Button from '@atlaskit/button/new';
import EmptyState from '@atlaskit/empty-state';
import noResults from '@atlassian/jira-illustrations/src/ui/adg4/brand/spots/empty/components/search-no-results-old/index.tsx';
import { getAkEmptyStateRenderImageFn } from '@atlassian/jira-illustrations/src/ui/helpers/ak-empty-state/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useRightSidebarActions } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/actions/hooks.tsx';
import { type UIAnalyticsEvent, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages.tsx';

export const EmptyScreen = () => {
	const { formatMessage } = useIntl();
	const [, { openOnCreateForSettings }] = useRightSidebarActions();

	const handleCreateFieldClick = useCallback(
		(_event: React.MouseEvent, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'createField');
			openOnCreateForSettings();
		},
		[openOnCreateForSettings],
	);

	return (
		<EmptyState
			header={formatMessage(messages.emptyScreenHeader)}
			renderImage={getAkEmptyStateRenderImageFn(noResults)}
			maxImageWidth={120}
			description={formatMessage(messages.emptyScreenDescription)}
			primaryAction={
				<Button
					appearance="primary"
					onClick={handleCreateFieldClick}
					interactionName="jpd.right-sidebar.create-field"
				>
					{formatMessage(messages.createField)}
				</Button>
			}
		/>
	);
};
