import {
	IssueCreateGroupTypeSpecified,
	type IssueCreatedPropertyItemGroupType,
} from '@atlassian/jira-polaris-common/src/controllers/issue/types.tsx';
import type { IssueTypeId } from '@atlassian/jira-workflow-map-statuses/src/common/types.tsx';
import { useEnvironmentContainerId } from '@atlassian/jira-polaris-component-environment-container/src/controllers/store/index.tsx';
import { useProjectsIdsForContainer } from '@atlassian/jira-polaris-component-project-metadata/src/controllers/index.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import { useIssueTypeByNameAndProjectIds } from '@atlassian/jira-polaris-component-issue-types/src/controllers/index.tsx';

export const getIssueTypeGroupIdentity = (grouping: IssueCreatedPropertyItemGroupType) => {
	const isGroupedByIssueType =
		grouping.groupType === IssueCreateGroupTypeSpecified &&
		grouping.fieldKey === FIELD_TYPES.ISSUE_TYPE;

	return isGroupedByIssueType ? grouping.groupIdentity : undefined;
};

export const useIssueTypeIdForCreatedProperty = (
	grouping: IssueCreatedPropertyItemGroupType,
): IssueTypeId | undefined => {
	const containerId = useEnvironmentContainerId();
	const projectIds = useProjectsIdsForContainer({ containerId });

	const issueType = useIssueTypeByNameAndProjectIds({
		name: getIssueTypeGroupIdentity(grouping),
		projectIds,
	});

	return issueType?.id;
};
