import type { Content } from '@atlaskit/share';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { JiraWorkspaceAri } from '@atlassian/ari/jira/workspace';
import { useActivationId } from '@atlassian/jira-tenant-context-controller/src/components/activation-id/index.tsx';
import { createViewAri } from '@atlassian/jira-polaris-common/src/common/utils/ari/index.tsx';
import {
	useCurrentViewId,
	useCurrentViewTitle,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import {
	PolarisEnvironmentContainerTypes,
	useEnvironmentContainer,
} from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import { useProjectMetadata } from '@atlassian/jira-polaris-component-project-metadata/src/controllers/index.tsx';

export const useContent = (): Content | null => {
	const activationId = useActivationId();
	const cloudId = useCloudId();
	const currentViewId = useCurrentViewId();
	const currentViewTitle = useCurrentViewTitle();
	const container = useEnvironmentContainer();
	const isProjectTypeContainer = container?.type === PolarisEnvironmentContainerTypes.PROJECT;
	const projectMeta = useProjectMetadata({
		projectId: isProjectTypeContainer ? container.projectId : undefined,
	});

	if (!currentViewId) {
		return null;
	}

	if (!currentViewTitle) {
		return null;
	}

	const title = projectMeta ? `${currentViewTitle} (${projectMeta.name})` : currentViewTitle;

	return {
		ari: createViewAri(cloudId, currentViewId),
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		link: window.location.href,
		title,
		type: '', // it does not matter in this context
		workspaceAri: JiraWorkspaceAri.create({
			activationId,
			siteId: cloudId,
		}).toString(),
	};
};
