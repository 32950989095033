import React, { useEffect, useCallback, useState } from 'react';
import differenceBy from 'lodash/differenceBy';
import isEqual from 'lodash/isEqual';
import type { MultiValue } from '@atlaskit/react-select';
import Select, { type SelectComponentsConfig, type StylesConfig } from '@atlaskit/select';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { OutsideClickAlerter } from '@atlassian/jira-polaris-lib-outside-click-alerter/src/index.tsx';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useOpenIdeaViewFieldSidebar } from '../../../../controllers/idea/utils/idea-view.tsx';
import {
	isSelectableOption,
	type ConnectionFieldOption,
	type ConnectionFieldProps,
} from '../types.tsx';
import { filterOption, useConnectionFieldOptions } from '../utils.tsx';
import { useLocalIssueIdToJiraIssueId } from '../../../../controllers/issue/selectors/issue-ids-hooks.tsx';
import { useSelectedIssueIds } from '../../../../controllers/views/selectors/view-hooks.tsx';
import type { IssueForConnectionField } from '../../../../controllers/issue/types.tsx';
import { MAX_OPTIONS } from '../constants.tsx';
import { ConnectionFieldSelectContext } from './context/index.tsx';
import { ConnectionFieldControl } from './control/index.tsx';
import { ConnectionFieldGroupHeading } from './group-heading/index.tsx';
import { ConnectionFieldMenuList } from './menu-list/index.tsx';
import messages from './messages.tsx';
import { NoOptionsMessage } from './no-options-message/index.tsx';
import { ConnectionFieldIssueOption } from './option/index.tsx';
import { ConnectionFieldGroup } from './group/index.tsx';

type Props = ConnectionFieldProps & {
	onClose: () => void;
	onInputChange: (issueNameSearch: string) => void;
	siteIssues: IssueForConnectionField[];
	appliedIssueNameSearch?: string;
};

const customComponents: SelectComponentsConfig<ConnectionFieldOption, true> = {
	MultiValueContainer: () => null,
	Input: () => null,
	ClearIndicator: () => null,
	Control: ConnectionFieldControl,
	MenuList: ConnectionFieldMenuList<true>,
	Option: ConnectionFieldIssueOption<true>,
	GroupHeading: ConnectionFieldGroupHeading<true>,
	NoOptionsMessage: NoOptionsMessage<true>,
	Group: ConnectionFieldGroup<true>,
};

export const ConnectionFieldEditView = ({
	onClose,
	localIssueId,
	fieldKey,
	menuPortalTarget,
	onUpdate,
	onInputChange,
	issueCardType,
	siteIssues,
	appliedIssueNameSearch,
}: Props) => {
	const { formatMessage } = useIntl();
	const localIssueIdToJiraId = useLocalIssueIdToJiraIssueId();
	const selectedIssues = useSelectedIssueIds();
	const { refreshIssues, addConnectionIssuesData } = useIssueActions();

	const openIdeaViewFieldSidebar = useOpenIdeaViewFieldSidebar(localIssueId);
	const [allIssuesOptions, siteIssuesOptions, connectedIssuesOptions] = useConnectionFieldOptions(
		localIssueId,
		fieldKey,
		siteIssues,
		issueCardType,
	);

	const [value, setValue] = useState<MultiValue<ConnectionFieldOption>>(connectedIssuesOptions);
	const [inputValue, setInputValue] = useState('');
	const [menuIsOpen, setMenuIsOpen] = useState(false);

	const isLoading = appliedIssueNameSearch !== inputValue && fg('jpd_cross_project_connecting');

	// opens menu faster rather then passing menuIsOpen={true} to Select
	// investigate if it can be improved https://pi-dev-sandbox.atlassian.net/browse/POL-12366
	useEffect(() => {
		setMenuIsOpen(true);
	}, []);

	const selectedOptions = value.filter((issue) => filterOption(issue, inputValue));
	const allOptions = allIssuesOptions.filter((issue) => filterOption(issue, inputValue));

	const groupedOptions = [
		{ options: selectedOptions },
		{
			label: formatMessage(messages.projectGroupHeadingNonFinal),
			options: differenceBy(allOptions, selectedOptions, 'value').slice(0, MAX_OPTIONS),
		},
		{
			label: formatMessage(messages.siteGroupHeadingNonFinal),
			options: isLoading
				? []
				: differenceBy(
						siteIssuesOptions.filter((issue) => filterOption(issue, inputValue)),
						selectedOptions,
						'value',
					),
		},
	];

	const refreshUpdatedIssues = useCallback(
		(selectedOptionsIds: string[]) => {
			const selectedIssuesLocalIssueIds = Object.keys(selectedIssues);
			const localIssueIdsToRefresh = selectedIssuesLocalIssueIds.includes(localIssueId)
				? [...selectedIssuesLocalIssueIds]
				: [localIssueId];
			const issueIdsToRefresh = localIssueIdsToRefresh.map((id) => localIssueIdToJiraId[id]);

			if (issueIdsToRefresh.length > 0) {
				refreshIssues({
					jiraIssueIds: [...issueIdsToRefresh, ...selectedOptionsIds],
				});
			}
		},
		[localIssueId, localIssueIdToJiraId, refreshIssues, selectedIssues],
	);

	const onCloseRequested = useCallback(() => {
		if (!isEqual(connectedIssuesOptions, value)) {
			const options = value.filter(isSelectableOption);

			// get only new issues that were added and weren't connected through this field before
			if (fg('jpd_cross_project_connecting')) {
				const newIssueDataToAdd = siteIssuesOptions
					.filter(
						(siteOption) =>
							options.find((option) => option.value === siteOption.value) &&
							!connectedIssuesOptions.find((option) => option.value === siteOption.value),
					)
					.map((option) => ({
						issueId: option.value,
						issueKey: option.issueKey,
						summary: option.label,
						issueType: option.issueType,
					}));

				if (newIssueDataToAdd.length > 0) {
					addConnectionIssuesData(newIssueDataToAdd);
				}
			}

			onUpdate(
				options.map((option) => ({
					id: option.value,
				})),
			).then(() => {
				// if we awaited this then the select would close after the update is done
				if (fg('jpd_cross_project_connecting')) {
					refreshUpdatedIssues(options.map((option) => option.value));
				}
			});
		}

		onClose();
	}, [
		addConnectionIssuesData,
		connectedIssuesOptions,
		onClose,
		onUpdate,
		refreshUpdatedIssues,
		siteIssuesOptions,
		value,
	]);

	const onKeyDown = useCallback(
		(event: React.KeyboardEvent) => {
			if (event.key === 'Esc' || event.key === 'Escape') {
				onCloseRequested();
			}
		},
		[onCloseRequested],
	);

	const onEditFieldRequested = useCallback(() => {
		openIdeaViewFieldSidebar(fieldKey);
		onCloseRequested();
	}, [fieldKey, onCloseRequested, openIdeaViewFieldSidebar]);

	const handleInputChange = useCallback(
		(newValue: string) => {
			setInputValue(newValue);
			if (newValue !== inputValue) {
				onInputChange(newValue);
			}
		},
		[inputValue, onInputChange],
	);

	return (
		<OutsideClickAlerter onClickOutside={onCloseRequested}>
			{(outsideClickAlerterProps) => (
				<div {...outsideClickAlerterProps}>
					<ConnectionFieldSelectContext.Provider value={{ onEditFieldRequested }}>
						<Select<ConnectionFieldOption, true>
							isMulti
							autoFocus
							isSearchable
							isClearable
							menuIsOpen={menuIsOpen}
							hideSelectedOptions={false}
							components={customComponents}
							onChange={setValue}
							value={value}
							onKeyDown={onKeyDown}
							onInputChange={handleInputChange}
							inputValue={inputValue}
							options={groupedOptions}
							filterOption={() => true}
							enableAnimation={false}
							menuPlacement="auto"
							maxMenuHeight={390}
							// if minMenuHeight is bigger than the actual menu height (whole menu), the menuPlacement "auto" will work - here it is set to the biggest possible number to make it always work
							minMenuHeight={Number.MAX_SAFE_INTEGER}
							styles={stylesConfig}
							menuPortalTarget={menuPortalTarget}
							isLoading={isLoading}
						/>
					</ConnectionFieldSelectContext.Provider>
				</div>
			)}
		</OutsideClickAlerter>
	);
};

const stylesConfig: StylesConfig<ConnectionFieldOption, true> = {
	menu: (styles) => ({
		...styles,
		zIndex: 100,
		right: 0,
		minWidth: '400px',
	}),
	menuList: (styles) => ({
		...styles,
		padding: 0,
	}),
	groupHeading: (styles) => ({
		...styles,
		marginBottom: '7px',
		':empty': {
			display: 'none',
		},
	}),
	group: (styles) => ({
		...styles,
		padding: `${token('space.075')} 0 ${token('space.075')} 0`,
		':not(:first-of-type)': {
			borderTop: `1px solid ${token('color.border')}`,
		},
	}),
	valueContainer: (styles) => ({
		...styles,
		display: 'flex',
	}),
	noOptionsMessage: (styles) => ({
		...styles,
		padding: `${token('space.150')}`,
	}),
	control: (styles) => ({
		...styles,
		flexWrap: 'nowrap',
		'&&': {
			paddingLeft: token('space.050'),
			paddingRight: token('space.050'),
		},
	}),
};
