import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { RankFieldOptionRequest } from './types.tsx';

export const rankFieldOptions = ({
	projectId,
	issueTypeId,
	fieldKey,
	rankedOptionIds,
}: RankFieldOptionRequest): Promise<void> =>
	performPutRequest(`/rest/polaris/v2/projects/${projectId}/fields/${fieldKey}/options`, {
		body: fg('jpd_use_field_association_helper')
			? JSON.stringify({
					optionIds: rankedOptionIds,
				})
			: JSON.stringify({
					issueTypeId: +issueTypeId,
					optionIds: rankedOptionIds,
				}),
	});
