import React from 'react';
import Tooltip from '@atlaskit/tooltip';
import { Box, xcss } from '@atlaskit/primitives';
import { fg } from '@atlassian/jira-feature-gating';
import { useFieldType } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { useIsUnsupportedHighlightedField } from '@atlassian/jira-polaris-common/src/ui/fields/utils/use-is-unsupported-highlighted-field.tsx';
import { CommentsField } from './comments/index.tsx';
import { ConnectionField } from './connection/index.tsx';
import { DateField } from './date-field/index.tsx';
import { DateTimeField } from './datetime-field/index.tsx';
import { ExternalReferenceField } from './external-reference-field/index.tsx';
import { ExternalReferencePropertyField } from './external-reference-property-field/index.tsx';
import { FuzzyDateField } from './fuzzy-date-field/index.tsx';
import { GoalsField } from './goals-field/index.tsx';
import { InsightsField } from './insights-field/index.tsx';
import { IssueKeyField } from './key-field/index.tsx';
import { EditableLabelsField } from './labels-field/index.tsx';
import { EditableLinkField, EditableSummaryField } from './link-field/index.tsx';
import { LinkedIssuesField } from './linked-issues-field/index.tsx';
import { EditableMultiSelectField } from './multi-select/index.tsx';
import { NumberField } from './number-field/index.tsx';
import { PlayField } from './play-field/index.tsx';
import { ProjectFieldCell } from './project-field/index.tsx';
import { ReactionsField } from './reactions-field/index.tsx';
import { EditableSingleSelectField } from './single-select/index.tsx';
import { StatusField } from './status-field/index.tsx';
import { StringField } from './string-field/index.tsx';
import { TeamField } from './team-field/index.tsx';
import { UserField } from './user-field/index.tsx';
import { UsersField } from './users-field/index.tsx';

type CellRendererProps = {
	isHovered: boolean;
	localIssueId: LocalIssueId;
	fieldKey: FieldKey;
	isInConnectionsTable?: boolean;
};

export const RenderCell = ({
	fieldKey,
	localIssueId,
	isHovered,
	isInConnectionsTable = false,
}: CellRendererProps) => {
	const fieldType = useFieldType(fieldKey);
	const { isUnsupportedField, isUnsupportedFieldReason } = useIsUnsupportedHighlightedField({
		fieldKey,
		localIssueId,
	});

	if (fg('jpd_cross_project_connecting')) {
		if (isUnsupportedField) {
			return (
				<Tooltip content={isUnsupportedFieldReason}>
					<Box xcss={emptyCellStyles} />
				</Tooltip>
			);
		}
	}

	switch (fieldType) {
		case FIELD_TYPES.SUMMARY:
			return fg('jpd_issue_types_ga') ? (
				<EditableSummaryField
					localIssueId={localIssueId}
					fieldKey={fieldKey}
					interactive={isHovered}
				/>
			) : (
				<EditableLinkField
					localIssueId={localIssueId}
					fieldKey={fieldKey}
					interactive={isHovered}
				/>
			);
		case FIELD_TYPES.ISSUE_KEY:
			return <IssueKeyField localIssueId={localIssueId} interactive={isHovered} />;
		case FIELD_TYPES.STATUS:
			return <StatusField localIssueId={localIssueId} fieldKey={fieldKey} />;
		case FIELD_TYPES.SHORT_TEXT:
		case FIELD_TYPES.HYPERLINK:
			return <StringField localIssueId={localIssueId} fieldKey={fieldKey} />;
		case FIELD_TYPES.INSIGHTS:
			return <InsightsField localIssueId={localIssueId} />;
		case FIELD_TYPES.NUMBER:
		case FIELD_TYPES.SLIDER:
		case FIELD_TYPES.RATING:
		case FIELD_TYPES.CHECKBOX:
		case FIELD_TYPES.FORMULA:
		case FIELD_TYPES.LINKED_ISSUES:
			return (
				<NumberField localIssueId={localIssueId} fieldKey={fieldKey} interactive={isHovered} />
			);
		case FIELD_TYPES.ISSUE_COMMENTS:
			return <CommentsField localIssueId={localIssueId} />;
		case FIELD_TYPES.LABELS:
		case FIELD_TYPES.CUSTOM_LABELS:
			return <EditableLabelsField localIssueId={localIssueId} fieldKey={fieldKey} />;
		case FIELD_TYPES.UPDATED:
		case FIELD_TYPES.CREATED:
			return <DateTimeField localIssueId={localIssueId} fieldKey={fieldKey} />;
		case FIELD_TYPES.DATE:
			return <DateField localIssueId={localIssueId} fieldKey={fieldKey} interactive={isHovered} />;
		case FIELD_TYPES.INTERVAL:
			return (
				<FuzzyDateField localIssueId={localIssueId} fieldKey={fieldKey} interactive={isHovered} />
			);
		case FIELD_TYPES.ASSIGNEE:
		case FIELD_TYPES.CREATOR:
		case FIELD_TYPES.REPORTER:
			return <UserField localIssueId={localIssueId} fieldKey={fieldKey} interactive={isHovered} />;
		case FIELD_TYPES.PEOPLE:
		case FIELD_TYPES.JSW_PEOPLE:
			return <UsersField localIssueId={localIssueId} fieldKey={fieldKey} interactive={isHovered} />;

		case FIELD_TYPES.SINGLE_SELECT:
			return (
				<EditableSingleSelectField
					localIssueId={localIssueId}
					fieldKey={fieldKey}
					interactive={isHovered}
				/>
			);
		case FIELD_TYPES.MULTI_SELECT:
		case FIELD_TYPES.JSW_MULTI_SELECT:
			return (
				<EditableMultiSelectField
					localIssueId={localIssueId}
					fieldKey={fieldKey}
					interactive={isHovered}
				/>
			);
		case FIELD_TYPES.DELIVERY_PROGRESS:
		case FIELD_TYPES.DELIVERY_STATUS:
			return (
				<LinkedIssuesField
					type={fieldType}
					localIssueId={localIssueId}
					appearance="list"
					interactive={isHovered}
				/>
			);
		case FIELD_TYPES.VOTES:
			return <PlayField appearance="list" localIssueId={localIssueId} fieldKey={fieldKey} />;
		case FIELD_TYPES.ATLAS_GOAL:
		case FIELD_TYPES.ATLAS_PROJECT:
			return (
				<ExternalReferenceField
					fieldKey={fieldKey}
					localIssueId={localIssueId}
					isHovered={isHovered}
				/>
			);
		case FIELD_TYPES.ATLAS_PROJECT_STATUS:
			return <ExternalReferencePropertyField localIssueId={localIssueId} fieldKey={fieldKey} />;
		case FIELD_TYPES.REACTIONS:
			return (
				<ReactionsField
					localIssueId={localIssueId}
					fieldKey={fieldKey}
					overrideMoreButtonWithSummaryView={isInConnectionsTable}
				/>
			);
		case FIELD_TYPES.PROJECT:
			return <ProjectFieldCell localIssueId={localIssueId} />;
		case FIELD_TYPES.CONNECTION:
			return (
				<ConnectionField localIssueId={localIssueId} fieldKey={fieldKey} interactive={isHovered} />
			);
		case FIELD_TYPES.TEAM:
			if (fg('polaris_team_field_integration')) {
				return <TeamField localIssueId={localIssueId} fieldKey={fieldKey} />;
			}
			return <span>TBD Сustom ({fieldType})</span>;
		case FIELD_TYPES.PLATFORM_GOALS:
			if (fg('jpd_platform_goals_field_support')) {
				return <GoalsField localIssueId={localIssueId} fieldKey={fieldKey} isHovered={isHovered} />;
			}
			return <span>TBD Сustom ({fieldType})</span>;
		default:
			return <span>TBD Сustom ({fieldType})</span>;
	}
};
const emptyCellStyles = xcss({
	height: '41px',
});
