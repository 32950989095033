import React, { useMemo } from 'react';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import {
	VIEW_KIND_BOARD,
	VIEW_KIND_MATRIX,
	VIEW_KIND_TABLE,
	VIEW_KIND_TIMELINE,
} from '@atlassian/jira-polaris-domain-view/src/view/constants.tsx';
import { useCurrentViewKind } from '../../../../../controllers/views/selectors/view-hooks.tsx';
import { InViewConnectionFilter } from './in-view-connection-filter.tsx';
import { InFieldConnectionFilter } from './in-field-connection-filter.tsx';
import { ScopePicker } from './scope-picker.tsx';

type Props = {
	field: Field;
};

export const ConnectionFieldFilterDialogContent = ({ field }: Props) => {
	const currentViewKind = useCurrentViewKind();

	const scopes = useMemo(() => {
		switch (currentViewKind) {
			case VIEW_KIND_BOARD:
				return [
					() => <InFieldConnectionFilter field={field} togglable columnsEnabled groupsEnabled />,
					() => <InViewConnectionFilter field={field} togglable />,
				];

			case VIEW_KIND_TIMELINE:
			case VIEW_KIND_TABLE:
				return [
					() => <InFieldConnectionFilter field={field} togglable groupsEnabled />,
					() => <InViewConnectionFilter field={field} togglable />,
				];
			case VIEW_KIND_MATRIX:
				return [() => <InViewConnectionFilter field={field} />];

			default:
				return [() => <InViewConnectionFilter field={field} />];
		}
	}, [field, currentViewKind]);

	return <ScopePicker scopes={scopes} />;
};
