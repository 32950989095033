import { createSelector } from 'reselect';
import find from 'lodash/find';
import type { GroupUuid } from '@atlassian/jira-shared-types/src/general.tsx';
import { PRIVATE_ACCESS_LEVEL } from '@atlassian/jira-shared-types/src/project.tsx';
import { type Actor, type ProjectActorsData, type State, GROUP_ROLE_ACTOR } from '../types.tsx';
import { getCurrentProjectAccessLevel } from './access-level.tsx';
import {
	getContributorGroupIdsWithoutManuallyAddedContributorAccess,
	getContributorGroupName,
	isContributorGroupEnabled,
} from './contributor-group.tsx';
import { getAdminRolesIds } from './roles.tsx';
import { isAccessLevelChangeInProgess, isProjectActorsLoading } from './ui.tsx';

export const getProjectActorsFilter = (state: State) => state.projectActors.filter;
export const getProjectActorsData = (state: State) => state.projectActors.data;
export const getActors = (state: State) => state.projectActors?.data?.actors;

const EMPTY_ARRAY: number[] = [];

const isGroupActor = (actor: Actor): boolean => actor.type === GROUP_ROLE_ACTOR;

const isActorForGroups = (actor: Actor, groupIds: GroupUuid[]): boolean =>
	isGroupActor(actor) && groupIds.includes(actor.accountId);

const isActorForGroup = (actor: Actor, groupName: string | undefined): boolean =>
	actor.type === GROUP_ROLE_ACTOR && actor.roleTypeId === groupName;

export const getProjectActorRoles = (roleTypeId: string) =>
	createSelector(
		getActors,
		(actors) => actors?.find((actor) => actor.roleTypeId === roleTypeId)?.roles || EMPTY_ARRAY,
	);

export const isLastAdmin = () =>
	createSelector(getActors, getAdminRolesIds, (actors = [], adminRolesIds) => {
		let adminRolesCount = 0;

		for (const actor of actors) {
			if (adminRolesCount > 1) break;

			for (const role of actor.roles) {
				if (adminRolesIds.includes(role)) {
					adminRolesCount += 1;
				}
			}
		}

		return adminRolesCount <= 1;
	});

export const hasContributorGroupConfigurationError = createSelector(
	getProjectActorsData,
	isProjectActorsLoading,
	isAccessLevelChangeInProgess,
	isContributorGroupEnabled,
	getContributorGroupName,
	getCurrentProjectAccessLevel,
	(data, isLoading, accessLevelChangeInProgress, enabled, groupName, accessLevel): boolean => {
		if (data === undefined || !enabled || isLoading || accessLevelChangeInProgress) {
			return false;
		}

		if (accessLevel?.value === PRIVATE_ACCESS_LEVEL) {
			return false;
		}

		return find(data.actors, (actor) => isActorForGroup(actor, groupName)) === undefined;
	},
);

export const getProjectActorsDataWithoutSystemGroups = createSelector(
	getProjectActorsData,
	isContributorGroupEnabled,
	getContributorGroupName,
	(data, isEnabled, groupName): ProjectActorsData | undefined => {
		if (!isEnabled || data === undefined) {
			return data;
		}

		return {
			...data,
			actors: data.actors.filter((actor) => !isActorForGroup(actor, groupName)),
		};
	},
);

export const getProjectActorsWithoutAutomaticallyAssignedContributors = createSelector(
	getProjectActorsData,
	getContributorGroupIdsWithoutManuallyAddedContributorAccess,
	(data, hiddenContributorGroupIds): ProjectActorsData | undefined => {
		if (!data) {
			return undefined;
		}
		if (!hiddenContributorGroupIds) {
			return data;
		}
		return {
			...data,
			actors: data.actors.filter((actor) => !isActorForGroups(actor, hiddenContributorGroupIds)),
		};
	},
);

export const getGroupNameToGroupIdMapping = createSelector(getProjectActorsData, (data) => {
	if (!data) {
		return {};
	}
	const mapping: Record<string, string> = {};
	data.actors.forEach((actor) => {
		if (!isGroupActor(actor)) {
			return;
		}
		mapping[actor.roleTypeId] = actor.accountId;
	});
	return mapping;
});
