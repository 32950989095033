import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'polaris-common.create-idea-dialog.confirmation-dialog.title',
		defaultMessage: 'Your changes won’t be saved',
		description: 'Title for confirmation modal dialog',
	},
	description: {
		id: 'polaris-common.create-idea-dialog.confirmation-dialog.description',
		defaultMessage: 'We won’t be able to save your data if you move away from this page.',
		description: 'Description for confirmation modal dialog',
	},
	confirmButtonLabel: {
		id: 'polaris-common.create-idea-dialog.confirmation-dialog.confirm-button-label',
		defaultMessage: 'Discard',
		description: 'Confirm button message on confirmation dialog',
	},
	cancelButtonLabel: {
		id: 'polaris-common.create-idea-dialog.confirmation-dialog.cancel-button-label',
		defaultMessage: 'Go back',
		description: 'Cancel button message on confirmation dialog',
	},
});
