import { performDeleteRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { GroupUuid, ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';

const getUrl = (projectKey: string, roleTypeId: string, isGroup: boolean) =>
	`/rest/projectconfig/latest/roles/${projectKey}/actor/?${
		isGroup ? 'group' : 'user'
	}=${encodeURIComponent(roleTypeId)}`;

/**
 * @deprecated use deleteGroupActor / deleteUserActor as soon as the identity based contributor role is available
 * @param projectKey
 * @param roleTypeId
 * @param isGroup
 */
export const deleteActor = (
	projectKey: string,
	roleTypeId: string,
	isGroup: boolean,
): Promise<{}> => performDeleteRequest(getUrl(projectKey, roleTypeId, isGroup));

export const deleteGroupActor = (groupId: GroupUuid, projectId: ProjectId): Promise<{}> =>
	performDeleteRequest(
		`/rest/polaris/contributors/projectconfig/latest/roles/${projectId}/actor/?groupId=${groupId}`,
	);

export const deleteUserActor = (userId: string, projectId: ProjectId): Promise<{}> =>
	performDeleteRequest(
		`/rest/polaris/contributors/projectconfig/latest/roles/${projectId}/actor/?userId=${userId}`,
	);
