import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	noProjectPermissions: {
		id: 'polaris-ideas.view-content.idea-list.cell.tooltip.no-project-permissions',
		defaultMessage: 'You don’t have permissions to edit ideas from <b>{projectName}</b> project',
		description: 'Tooltip message when user don’t have the project permissions to edit the cell',
	},
	fieldNotAddedToProject: {
		id: 'polaris-ideas.view-content.idea-list.cell.tooltip.field-not-added-to-project-nonfinal',
		defaultMessage:
			'You can’t edit this global field because it’s not added to <b>{projectName}</b> project. ',
		description:
			'Tooltip message a user try to edit an idea global field value but the global field has not been added to the idea’s project',
	},
});
