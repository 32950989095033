import React, { useCallback } from 'react';
import { styled } from '@compiled/react';
import type { EmojiDescription } from '@atlaskit/emoji';
import EmojiAddIcon from '@atlaskit/icon/core/migration/emoji-add';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
// eslint-disable-next-line jira/import/no-restricted-import
import { ViewItemIcon } from '@atlassian/jira-polaris-component-glyphs/src/ui/glyphs/main.tsx';
// eslint-disable-next-line jira/import/no-restricted-import
import type { ViewKind } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import { ViewEmojiSpotlightTarget } from '@atlassian/jira-polaris-lib-control-sharing/src/controllers/view-emoji-spotlight-provider/index.tsx';
import { EmojiPicker } from '@atlassian/jira-polaris-lib-emoji-picker/src/ui/index.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';

type Props = {
	emojiId: string | undefined;
	viewKind: ViewKind | undefined;
	isLoading?: boolean;
	isReadOnly?: boolean;
	onEmojiChange: (selectedEmojiId?: string) => void;
	onEmojiLoaded?: (emoji: EmojiDescription | null) => void;
};

export const ViewHeaderEmoji = ({
	emojiId,
	viewKind,
	isLoading = false,
	isReadOnly = false,
	onEmojiChange,
	onEmojiLoaded,
}: Props) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onSelectEmoji = useCallback(
		(selectedEmojiId?: string) => {
			fireCompoundAnalyticsEvent.View.emojiChanged(createAnalyticsEvent({}));
			onEmojiChange(selectedEmojiId);
		},
		[createAnalyticsEvent, onEmojiChange],
	);

	const onRemoveEmoji = useCallback(() => {
		fireCompoundAnalyticsEvent.View.emojiChanged(createAnalyticsEvent({}));
		onEmojiChange('');
	}, [createAnalyticsEvent, onEmojiChange]);

	if (isLoading) {
		return <Box xcss={skeletonStyles} />;
	}

	const picker = (
		<EmojiPicker
			onEmojiSelected={(selectedEmoji) => {
				if (selectedEmoji?.id) {
					onSelectEmoji(selectedEmoji.id);
				} else {
					onRemoveEmoji();
				}
			}}
			onEmojiLoaded={onEmojiLoaded}
			readonly={isReadOnly}
			selectedEmojiId={emojiId}
			noPadding
			emojiAddIcon={(props) => (
				<EmojiAddIconContainer>
					{isReadOnly ? (
						<ViewItemIconContainer>
							<ViewItemIcon viewKind={viewKind} {...props} />
						</ViewItemIconContainer>
					) : (
						<>
							<ViewItemIconContainer data-component-selector="view-item-icon-container-adk3D">
								<ViewItemIcon viewKind={viewKind} {...props} />
							</ViewItemIconContainer>
							<div data-component-selector="default-add-icon-container-l8Dv3">
								<EmojiAddIcon color="currentColor" {...props} />
							</div>
						</>
					)}
				</EmojiAddIconContainer>
			)}
		/>
	);

	return (
		<ComponentWrapper hasEmoji={!!emojiId}>
			<ViewEmojiSpotlightTarget>{picker}</ViewEmojiSpotlightTarget>
		</ComponentWrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const ComponentWrapper = styled.div<{ hasEmoji: boolean }>({
	position: 'relative',
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'button:disabled': {
		opacity: 1,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		background: ({ hasEmoji }) => (hasEmoji ? 'transparent' : token('color.icon.subtle')),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		color: token('color.icon.inverse'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const ViewItemIconContainer = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.icon.inverse'),
	backgroundColor: token('color.icon.subtle'),
	// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage -- The token value "4px" and fallback "3px" do not match and can't be replaced automatically.
	borderRadius: token('border.radius.100', '3px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const EmojiAddIconContainer = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> [data-component-selector="view-item-icon-container-adk3D"]': {
		display: 'block',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'&:hover > [data-component-selector="view-item-icon-container-adk3D"]': {
		display: 'none',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> [data-component-selector="default-add-icon-container-l8Dv3"]': {
		display: 'none',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'&:hover > [data-component-selector="default-add-icon-container-l8Dv3"]': {
		display: 'block',
	},
});

const skeletonStyles = xcss({ width: '24px', height: '24px' });
