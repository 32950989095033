import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	currentProjectLabelNonFinal: {
		id: 'polaris-component-field-configuration.configuration.connection.current-project-label-non-final',
		defaultMessage: 'Current project',
		description: 'Label for the current project in the project select',
	},
	selectedProjectsCountLabelNonFinal: {
		id: 'polaris-component-field-configuration.configuration.connection.selected-projects-count-label-non-final',
		defaultMessage: '{count, plural, one {# project} other {# projects}}',
		description: 'Label for the projects count in the project select',
	},
	projectSelectIconLabelNonFinal: {
		id: 'polaris-component-field-configuration.configuration.connection.project-select-icon-label-non-final',
		defaultMessage: 'Project select icon',
		description: 'Label for the project select icon',
	},
});
