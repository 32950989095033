import { toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import {
	type Props,
	type State,
	DescriptionMergeFromOption,
	FieldsMergeFromOption,
} from '../types.tsx';

export const setTargetIssue =
	(
		targetIssueId: number,
		targetIssueKey: string,
		targetIssueSummary: string,
		targetIssueIcon: string,
	): Action<State, Props> =>
	async ({ setState }) => {
		const targetIssue = {
			id: targetIssueId,
			key: toIssueKey(targetIssueKey),
			summary: targetIssueSummary,
			icon: targetIssueIcon,
		};
		setState({
			targetIssue,
			mergeFromOptions: {
				[DescriptionMergeFromOption]: targetIssue,
				[FieldsMergeFromOption]: targetIssue,
			},
		});
	};
