import React from 'react';
import { styled } from '@compiled/react';
import EditorInfoIcon from '@atlaskit/icon/core/migration/information--editor-info';
import { token } from '@atlaskit/tokens';
import { IssueLinkingDisabledNotification } from '@atlassian/jira-polaris-common/src/ui/issue-linking/index.tsx';
import { DeliveryTicketsSkeleton } from './delivery-tickets-skeleton/index.tsx';

export const DisabledIssueLinking = () => (
	<Container data-testId="polaris-ideas.ui.delivery.issue-link-disabled-notification">
		<NotificationContainer>
			<EditorInfoIcon spacing="spacious" label="info" color={token('color.icon.discovery')} />
			<IssueLinkingDisabledNotification boldTitle />
		</NotificationContainer>
		<DeliveryTicketsSkeleton />
	</Container>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	marginTop: token('space.100'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const NotificationContainer = styled.div({
	display: 'flex',
});
