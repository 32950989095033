import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'polaris-component-collection-configure-sidebar.manage-filters-content.title',
		defaultMessage: 'Manage filters',
		description: 'Title for the roadmap settings sidebar on step of managing filters',
	},
	backButtonLabel: {
		id: 'polaris-component-collection-configure-sidebar.manage-filters-content.back-button-label',
		defaultMessage: 'Back to roadmap settings',
		description: 'Back button label for the roadmap settings sidebar on a step of managing filters',
	},
	errorTitle: {
		id: 'polaris-component-collection-configure-sidebar.manage-filters-content.error-title',
		defaultMessage: 'We couldn’t update the filters',
		description: 'Flag title to communicate an error.',
	},
	errorDescription: {
		id: 'polaris-component-collection-configure-sidebar.manage-filters-content.error-description',
		defaultMessage: 'Please try again or refresh the page.',
		description: 'Flag description for an error occurring while updating roadmap filters.',
	},
	applyFilter: {
		id: 'polaris-component-collection-configure-sidebar.manage-filters-content.apply-filter',
		defaultMessage: 'Apply a filter',
		description: 'Button label for appling and saving the changes made to filters',
	},
	cancelChanges: {
		id: 'polaris-component-collection-configure-sidebar.manage-filters-content.cancel-changes',
		defaultMessage: 'Cancel',
		description: 'Button label for canceling the changes made to filters',
	},
});
