import React, { type ReactNode } from 'react';
import { styled } from '@compiled/react';
import Breadcrumbs, { BreadcrumbsItem as AtkBreadcrumbsItem } from '@atlaskit/breadcrumbs';
import Button from '@atlaskit/button';
import CrossIcon from '@atlaskit/icon/core/migration/close--cross';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

type Props = {
	title: string;
	icon?: ReactNode | null;
	onClose: () => void;
};

export const Header = ({ title, icon = null, onClose }: Props) => (
	<HeaderContainer>
		<Left>
			<Breadcrumbs>
				<BreadcrumbsItem text={title} iconBefore={<Box xcss={iconContainerStyles}>{icon}</Box>} />
			</Breadcrumbs>
		</Left>
		<Button
			testId="polaris-ideas.ui.right-sidebar.header.fields.close"
			appearance="subtle-link"
			iconBefore={<CrossIcon label="close" LEGACY_size="medium" spacing="spacious" />}
			onClick={onClose}
		/>
	</HeaderContainer>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeaderContainer = styled.div({
	display: 'flex',
	justifyContent: 'space-between',
	paddingRight: token('space.150'),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	paddingLeft: '14px',
	alignItems: 'center',
	marginTop: token('space.150'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Left = styled.div({
	display: 'flex',
	alignItems: 'center',
});

const iconContainerStyles = xcss({
	paddingRight: 'space.050',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BreadcrumbsItem = styled(AtkBreadcrumbsItem)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&&& span': {
		display: 'flex',
		alignItems: 'center',
		pointerEvents: 'none',
	},
});
