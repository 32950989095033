import React from 'react';
import {
	ReactionsField as Reactions,
	type ReactionsFieldProps,
} from '@atlassian/jira-polaris-common/src/ui/fields/reactions/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIsEditable } from '../../utils/index.tsx';

export const ReactionsField = (props: ReactionsFieldProps) => {
	const { isEditable: isEditableProp, localIssueId, fieldKey } = props;

	const isEditable = useIsEditable(localIssueId, fieldKey);

	return (
		<Reactions
			{...props}
			isEditable={
				fg('jpd_cross_project_connecting') ? !isEditable || isEditableProp : isEditableProp
			}
		/>
	);
};
