// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { fg } from '@atlassian/jira-feature-gating';
import type { State } from '../types.tsx';
import { deleteInsight } from './delete-insight.tsx';

export const toogleInsightSeletion =
	(insight: string) =>
	({ setState, getState }: StoreActionApi<State>) => {
		const { selectedInsights } = getState();
		const newSelectedInsights = { ...selectedInsights };
		if (newSelectedInsights[insight] === true) {
			delete newSelectedInsights[insight];
		} else {
			newSelectedInsights[insight] = true;
		}
		if (!Object.keys(newSelectedInsights).length) {
			setState({
				selectedInsights: {},
			});
			return;
		}
		setState({
			selectedInsights: newSelectedInsights,
		});
	};

export const clearInsightSeletion =
	() =>
	({ setState }: StoreActionApi<State>) => {
		setState({
			selectedInsights: {},
			activeAction: undefined,
		});
	};

export const deleteSelectedInsights =
	() =>
	({ getState, dispatch }: StoreActionApi<State>) => {
		if (!fg('jpd_insights_manipulation_slo')) {
			Object.keys(getState().selectedInsights).forEach((selectedInsight) => {
				dispatch(deleteInsight(selectedInsight, { isBulkDeletion: true }));
			});
			dispatch(clearInsightSeletion());
		} else {
			const resultPromise = Promise.allSettled(
				Object.keys(getState().selectedInsights).map((selectedInsight) =>
					dispatch(deleteInsight(selectedInsight, { isBulkDeletion: true })),
				),
			);

			dispatch(clearInsightSeletion());

			return resultPromise;
		}
	};
