import isEqual from 'lodash/isEqual';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { Props, Marker, CreateMarker } from '../../../types.tsx';
import type { State } from '../../types.tsx';

export const deleteMarker =
	(date: string, id?: string) =>
	async ({ getState, setState }: StoreActionApi<State>, { onMarkerDelete }: Props) => {
		const state = getState();

		const updatedMarkers = state.markers.filter((marker) => marker.date !== date);
		setState({ ...state, markers: updatedMarkers });

		if (onMarkerDelete && id) {
			try {
				await onMarkerDelete(id);
			} catch (error) {
				// If the request fails, revert the state to the previous markers value
				setState({ markers: state.markers });
			}
		}
	};

export const updateMarkerOnlyStateByDate =
	(date: string, updatedMarker: Marker) =>
	({ getState, setState }: StoreActionApi<State>) => {
		const state = getState();
		const updatedMarkers = state.markers.map((marker) =>
			marker.date === date ? updatedMarker : marker,
		);
		setState({ markers: updatedMarkers });
	};

export const updateMarkerOnlyStateById =
	(id: string, updatedMarker: Marker) =>
	({ getState, setState }: StoreActionApi<State>) => {
		const state = getState();
		const updatedMarkers = state.markers.map((marker) =>
			marker.id === id ? updatedMarker : marker,
		);
		setState({ markers: updatedMarkers });
	};

export const updateRemoteMarker =
	(date: string, prevMarker: Marker | null, label?: string) =>
	async ({ getState, setState }: StoreActionApi<State>, { onMarkerUpdate }: Props) => {
		const state = getState();
		const markerToUpdate = state.markers.find((marker) => marker.date === date);
		if (!onMarkerUpdate || !markerToUpdate) {
			return;
		}
		const markerWithUpdatedLabel = label ? { ...markerToUpdate, label } : markerToUpdate;
		// optimistic state update
		setState({
			markers: state.markers.map((marker) =>
				marker.date === markerWithUpdatedLabel.date ? markerWithUpdatedLabel : marker,
			),
		});
		try {
			await onMarkerUpdate(markerWithUpdatedLabel);
		} catch (error) {
			if (prevMarker) {
				// revert the state to the previous marker's value
				setState({
					markers: state.markers.map((marker) =>
						marker.date === markerToUpdate.date ? prevMarker : marker,
					),
				});
			} else {
				// or revert the optimistic state update
				setState({
					markers: state.markers.map((marker) =>
						marker.date === markerToUpdate.date ? markerToUpdate : marker,
					),
				});
			}
		}
	};

export const updateMarker =
	(id: string, updatedMarkerProperties: Partial<Marker> = {}) =>
	async ({ getState, setState }: StoreActionApi<State>, { onMarkerUpdate }: Props) => {
		const state = getState();
		const markerToUpdate = state.markers.find((marker) => marker.id === id);

		if (!markerToUpdate) return;

		const newMarker = {
			...markerToUpdate,
			...updatedMarkerProperties,
		};

		setState({
			markers: state.markers.map((marker) => (marker.id === id ? newMarker : marker)),
		});

		try {
			await onMarkerUpdate?.(newMarker);
		} catch (error) {
			// revert optimistic update
			setState(state);
		}
	};

export const createMarkerState =
	(newMarker: CreateMarker) =>
	({ getState, setState }: StoreActionApi<State>) => {
		const state = getState();
		setState({ markers: [...state.markers, newMarker] });
	};

export const createMarker =
	(date: string, label?: string) =>
	async ({ getState, setState }: StoreActionApi<State>, { onMarkerCreate }: Props) => {
		const state = getState();
		const markerToCreate = state.markers.find((marker) => marker.date === date);
		if (!onMarkerCreate || !markerToCreate) {
			return;
		}
		const markerWithUpdatedLabel = label ? { ...markerToCreate, label } : markerToCreate;
		// optimistic state update
		setState({
			markers: state.markers.map((marker) =>
				marker.date === markerWithUpdatedLabel.date ? markerWithUpdatedLabel : marker,
			),
		});
		try {
			await onMarkerCreate(markerWithUpdatedLabel);
		} catch (error) {
			setState({
				markers: state.markers.filter((marker) => marker.date !== markerToCreate.date),
			});
		}
	};

export const initMarkersState =
	(markers: Marker[] = []) =>
	({ getState, setState }: StoreActionApi<State>) => {
		if (!isEqual(markers, getState().markers)) {
			setState({
				markers: [
					// Combine markers from props with the existing state markers and remove duplicates
					...new Map(
						[...getState().markers, ...markers].map((marker) => [marker.date, marker]),
					).values(),
				],
			});
		}
	};
