import type { DocNode as ADF } from '@atlaskit/adf-schema';
import type { PolarisComment } from '@atlassian/jira-polaris-domain-comment/src/index.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { getSortedComments } from '../../selectors/comments/index.tsx';
import type { State, Props } from '../../types.tsx';

// deduplicate actions for current issues - https://pi-dev-sandbox.atlassian.net/browse/POL-1516

const createNewState = (state: State, newComments: PolarisComment[]): State => ({
	...state,
	comments: newComments,
});

const updateCommentsOnIssueStore = ({ getState }: StoreActionApi<State>, props: Props) => {
	if (!props.issueKey) {
		return;
	}
	props.onCommentsUpdated(props.issueKey, getState().comments);
};

export const updateCommentOfIssue =
	(commentId: string, comment: ADF) =>
	async ({ getState, setState, dispatch }: StoreActionApi<State>, props: Props) => {
		const state = getState();
		if (props.issueKey === undefined) {
			return;
		}
		props.issuesRemote
			.updateIssueComment({
				issueKey: props.issueKey,
				commentId,
				comment,
			})
			.then((remoteComment) => {
				const comments = getSortedComments(state);
				setState(
					createNewState(state, [...comments.filter((c) => c.id !== commentId), remoteComment]),
				);
				dispatch(updateCommentsOnIssueStore);
			});
	};

export const deleteCommentFromIssue =
	(commentId: string) =>
	async ({ getState, setState, dispatch }: StoreActionApi<State>, props: Props) => {
		const state = getState();
		if (props.issueKey === undefined) {
			return;
		}
		props.issuesRemote
			.deleteIssueComment({
				issueKey: props.issueKey,
				commentId,
			})
			.then(() => {
				const comments = getSortedComments(state);
				setState(
					createNewState(
						state,
						comments.filter((comment) => comment.id !== commentId),
					),
				);
				dispatch(updateCommentsOnIssueStore);
			});
	};

export const addCommentToIssue =
	(comment: ADF) =>
	({ getState, setState, dispatch }: StoreActionApi<State>, props: Props) => {
		const state = getState();
		if (props.issueKey === undefined) {
			return Promise.resolve(false);
		}
		return props.issuesRemote
			.createIssueComment({
				issueKey: props.issueKey,
				comment,
			})
			.then((remoteComment) => {
				const comments = getSortedComments(state);
				setState(createNewState(state, [...comments, remoteComment]));
				dispatch(updateCommentsOnIssueStore);
				return true;
			});
	};

export const fetchAndUpdateIdeaComments =
	(issueKey: IssueKey) =>
	async ({ dispatch, setState }: StoreActionApi<State>, props: Props) => {
		if (!props.issueKey || !issueKey || props.issueKey !== issueKey) {
			return;
		}

		props.issuesRemote
			.fetchIssue({ issueIdOrKey: issueKey, fields: ['comment'] })
			.then((response) => {
				const comments = response.fields.comment?.comments;
				if (comments) {
					setState({
						comments,
					});
					dispatch(updateCommentsOnIssueStore);
				}
			});
	};
