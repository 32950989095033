/** @jsx jsx */
import React, { type SyntheticEvent, type ReactNode, useState, useMemo, useCallback } from 'react';
import { styled, jsx, css } from '@compiled/react';
import noop from 'lodash/noop';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import { IconButton } from '@atlaskit/button/new';
import DropdownMenu, {
	type DropdownMenuProps,
	DropdownItemGroup,
	DropdownItem,
} from '@atlaskit/dropdown-menu';
import EyeOpenStrikethrough from '@atlaskit/icon/core/eye-open-strikethrough';
import EditFilledIcon from '@atlaskit/icon/core/migration/edit--edit-filled';
import EditorSettingsIcon from '@atlaskit/icon/core/migration/settings--editor-settings';
import AddIcon from '@atlaskit/icon/core/migration/add';
import MoreIcon from '@atlaskit/icon/core/migration/show-more-horizontal--more';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useIsSharedView } from '@atlassian/jira-polaris-common/src/controllers/environment/index.tsx';
import {
	useIsGlobalCustomField,
	useFieldsByKey,
} from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks.tsx';
import { useSortedGroupOptions } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/grouping-hooks.tsx';
import {
	useOpenRightSidebarOption,
	useOpenRightSidebarOnField,
} from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/actions/hooks.tsx';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import { usePermanentFieldFilter } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/filters-hooks.tsx';
import { useCanEditFields } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { sendPendoTrackEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/pendo/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { fg } from '@atlassian/jira-feature-gating';
import { NoValueContainer } from '../../../common/board-header-column/styled.tsx';
import { useExtendedOptionsInNaturalOrder } from '../../../common/utils/field-visibility-options.tsx';
import { connectionColumnHeaderConfig } from './connection/index.tsx';
import { dateColumnHeaderConfig } from './dates/index.tsx';
import { externalReferencePropertyColumnHeaderConfig } from './external-reference-property/index.tsx';
import { externalReferenceColumnHeaderConfig } from './external-reference/index.tsx';
import { issueTypeColumnHeaderConfig } from './issue-type/index.tsx';
import { goalsColumnHeaderConfig } from './goals/index.tsx';
import { labelsColumnHeaderConfig } from './labels/index.tsx';
import messages from './messages.tsx';
import { numberColumnHeaderConfig } from './numbers/index.tsx';
import { optionsColumnHeaderConfig } from './options/index.tsx';
import { projectColumnHeaderConfig } from './project/index.tsx';
import { reactionsColumnHeaderConfig } from './reactions/index.tsx';
import { statusColumnHeaderConfig } from './status/index.tsx';
import type { ColumnHeaderComponentProps, HeaderConfig } from './types.tsx';
import { userColumnHeaderConfig } from './users/index.tsx';
import { getNewFieldValueFilterOnHide } from './utils.tsx';
import { teamColumnHeaderConfig } from './team/index.tsx';

const DefaultColumnHeader = ({ groupIdentity }: ColumnHeaderComponentProps) => <>{groupIdentity}</>;

const EmptyColumnHeader = () => {
	const { formatMessage } = useIntl();
	return <NoValueContainer>{formatMessage(messages.emptyColumnHeader)}</NoValueContainer>;
};

const headers: HeaderConfig = {
	config: {
		[FIELD_TYPES.SINGLE_SELECT]: optionsColumnHeaderConfig,
		[FIELD_TYPES.MULTI_SELECT]: optionsColumnHeaderConfig,
		[FIELD_TYPES.JSW_MULTI_SELECT]: optionsColumnHeaderConfig,
		[FIELD_TYPES.STATUS]: statusColumnHeaderConfig,
		[FIELD_TYPES.ASSIGNEE]: userColumnHeaderConfig,
		[FIELD_TYPES.CREATOR]: userColumnHeaderConfig,
		[FIELD_TYPES.REPORTER]: userColumnHeaderConfig,
		[FIELD_TYPES.PEOPLE]: userColumnHeaderConfig,
		[FIELD_TYPES.JSW_PEOPLE]: userColumnHeaderConfig,
		[FIELD_TYPES.LABELS]: labelsColumnHeaderConfig,
		[FIELD_TYPES.CUSTOM_LABELS]: labelsColumnHeaderConfig,
		[FIELD_TYPES.NUMBER]: numberColumnHeaderConfig,
		[FIELD_TYPES.FORMULA]: numberColumnHeaderConfig,
		[FIELD_TYPES.RATING]: numberColumnHeaderConfig,
		[FIELD_TYPES.SLIDER]: numberColumnHeaderConfig,
		[FIELD_TYPES.CHECKBOX]: numberColumnHeaderConfig,
		[FIELD_TYPES.INSIGHTS]: numberColumnHeaderConfig,
		[FIELD_TYPES.LINKED_ISSUES]: numberColumnHeaderConfig,
		[FIELD_TYPES.INTERVAL]: dateColumnHeaderConfig,
		[FIELD_TYPES.ATLAS_GOAL]: externalReferenceColumnHeaderConfig,
		[FIELD_TYPES.ATLAS_PROJECT]: externalReferenceColumnHeaderConfig,
		[FIELD_TYPES.ATLAS_PROJECT_STATUS]: externalReferencePropertyColumnHeaderConfig,
		[FIELD_TYPES.REACTIONS]: reactionsColumnHeaderConfig,
		[FIELD_TYPES.PROJECT]: projectColumnHeaderConfig,
		[FIELD_TYPES.CONNECTION]: connectionColumnHeaderConfig,
		[FIELD_TYPES.ISSUE_TYPE]: issueTypeColumnHeaderConfig,
		[FIELD_TYPES.PLATFORM_GOALS]: goalsColumnHeaderConfig,
		[FIELD_TYPES.TEAM]: teamColumnHeaderConfig,
	},
	default: {
		isHideable: false,
		isDecoratable: false,
		Component: DefaultColumnHeader,
		EmptyComponent: EmptyColumnHeader,
	},
};

type HideableColumnPropsType = {
	fieldKey: FieldKey;
	groupIdentity: string | undefined;
	isHideable: boolean;
	isReadOnly: boolean;
	isDecoratable: boolean;
	children: ReactNode;
	isRow: boolean;
	elemAfter: ReactNode;
	isAddIdeaVisible?: boolean;
	onAddIdea?: ({ groupIdentity, fieldKey }: { groupIdentity: string; fieldKey: FieldKey }) => void;
	onHideColumn: () => void;
	onMenuOpen?: (isOpen: boolean) => void;
};

type AddIdeaButtonProps = {
	groupIdentity: string;
	fieldKey: string;
	onClick: NonNullable<HideableColumnPropsType['onAddIdea']>;
	'aria-label'?: string;
};

const AddIdeaButton = (props: AddIdeaButtonProps) => {
	const { groupIdentity, fieldKey, onClick } = props;

	return (
		<Box xcss={addButtonStyles}>
			<Button
				iconAfter={<AddIcon label="" LEGACY_size="small" />}
				appearance="subtle"
				aria-label={props['aria-label']}
				onClick={(_event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
					fireUIAnalytics(analyticsEvent, 'addIdeaColumnHeader');

					sendPendoTrackEvent({
						actionSubjectAndAction: 'addIdeaColumnHeader clicked',
						actionSubjectId: 'addIdeaColumnHeader',
						attributes: {
							issueFieldKey: fieldKey,
							fieldValueId: groupIdentity,
						},
					});

					onClick({ groupIdentity, fieldKey });
				}}
			/>
		</Box>
	);
};

const HideableColumn = ({
	children,
	isHideable,
	isDecoratable,
	groupIdentity,
	fieldKey,
	isRow,
	elemAfter,
	isReadOnly,
	isAddIdeaVisible,
	onAddIdea = noop,
	onHideColumn,
	onMenuOpen,
}: HideableColumnPropsType) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const canEditFields = useCanEditFields();
	const isGlobalField = useIsGlobalCustomField(fieldKey);

	const [isMenuVisible, setMenuVisible] = useState(false);
	const [isDecoratorVisible, setDecoratorVisible] = useState(false);
	const [isMenuOpen, setMenuOpen] = useState(false);

	const openRightSidebarOnField = useOpenRightSidebarOnField();
	const openRightSidebarOption = useOpenRightSidebarOption();

	const isSharedView = useIsSharedView();

	const isSafeDecoratable = useMemo(
		() => isDecoratable && groupIdentity,
		[groupIdentity, isDecoratable],
	);

	const showMenu = () => {
		setMenuVisible(true);
		if (isSafeDecoratable) {
			setDecoratorVisible(true);
		}
	};

	const hideMenu = () => {
		setMenuVisible(false);
		setDecoratorVisible(false);
	};

	const sendFieldSettingsAnalytics = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'dropdownItem',
			}),
			canEditFields ? 'editFieldValue' : 'viewFieldSettings',
		);
		sendPendoTrackEvent({
			actionSubjectAndAction: 'dropdownItem clicked',
			actionSubjectId: canEditFields ? 'editFieldValue' : 'viewFieldSettings',
		});
	}, [canEditFields, createAnalyticsEvent]);

	const onHideColumnClick = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'dropdownItem',
			}),
			'hideFieldValue',
			{
				issueFieldKey: fieldKey,
				fieldValueId: groupIdentity,
			},
		);
		sendPendoTrackEvent({
			actionSubjectAndAction: 'dropdownItem clicked',
			actionSubjectId: 'hideFieldValue',
			attributes: {
				issueFieldKey: fieldKey,
				fieldValueId: groupIdentity || '',
			},
		});

		onHideColumn();
	}, [createAnalyticsEvent, onHideColumn, fieldKey, groupIdentity]);

	const editLabelButtonActions = useCallback(() => {
		openRightSidebarOption(groupIdentity || null, fieldKey);
		onMenuOpen?.(true);

		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'dropdownItem',
			}),
			'editFieldOptionValue',
		);

		sendPendoTrackEvent({
			actionSubjectAndAction: 'dropdownItem clicked',
			actionSubjectId: 'editLabel',
			attributes: {
				issueFieldKey: fieldKey,
				fieldValueId: groupIdentity || '',
			},
		});
	}, [createAnalyticsEvent, fieldKey, groupIdentity, onMenuOpen, openRightSidebarOption]);

	const displayMenu = (isDecoratorVisible || isMenuVisible || isMenuOpen) && !isSharedView;
	const displayEditFieldButton =
		canEditFields && isSafeDecoratable && groupIdentity !== undefined && !isGlobalField;

	const handleOpenChange: NonNullable<DropdownMenuProps['onOpenChange']> = useCallback(
		(attrs) => {
			setMenuOpen(attrs.isOpen);
		},
		[setMenuOpen],
	);

	const dropdownMenu = (
		<DropdownMenu
			isOpen={isMenuOpen}
			onOpenChange={handleOpenChange}
			trigger={({ triggerRef, ...triggerProps }) => (
				<IconButton
					{...triggerProps}
					onClick={() => {
						setMenuOpen(!isMenuOpen);
						onMenuOpen?.(!isMenuOpen);
					}}
					ref={triggerRef}
					isSelected={isMenuOpen}
					appearance="subtle"
					icon={MoreIcon}
					label={formatMessage(messages.actions)}
				/>
			)}
			spacing="compact"
			shouldFlip={false}
			placement="bottom-end"
		>
			{Boolean(isHideable || displayEditFieldButton) && (
				<DropdownItemGroup>
					{isHideable && (
						<DropdownItem
							interactionName="jpd.board-header.field-hide"
							data-id="pendo.dropdown-item.hide-field-button"
							onClick={onHideColumnClick}
							elemBefore={
								<EyeOpenStrikethrough spacing="spacious" color={token('color.icon')} label="" />
							}
						>
							{formatMessage(messages.hideColumn)}
						</DropdownItem>
					)}
					{displayEditFieldButton && (
						<DropdownItem
							interactionName="jpd.board-header.field-edit"
							onClick={editLabelButtonActions}
							elemBefore={
								<EditFilledIcon spacing="spacious" color={token('color.icon')} label="" />
							}
						>
							{formatMessage(messages.renameTable)}
						</DropdownItem>
					)}
				</DropdownItemGroup>
			)}
			<DropdownItemGroup hasSeparator={Boolean(isHideable || displayEditFieldButton)}>
				<DropdownItem
					onClick={() => {
						sendFieldSettingsAnalytics();
						openRightSidebarOnField(fieldKey);
						setMenuOpen(false);
					}}
					elemBefore={
						<EditorSettingsIcon spacing="spacious" color={token('color.icon')} label="" />
					}
					interactionName={
						canEditFields ? 'jpd.right-sidebar.field-edit' : 'jpd.right-sidebar.field-settings'
					}
				>
					{canEditFields
						? formatMessage(messages.editField)
						: formatMessage(messages.fieldSettings)}
				</DropdownItem>
			</DropdownItemGroup>
		</DropdownMenu>
	);

	const menu = displayMenu && (
		<HeaderMenuContainer>
			<MenuItemContainer isVisible={isMenuVisible || isMenuOpen}>{dropdownMenu}</MenuItemContainer>
		</HeaderMenuContainer>
	);

	const addIdeaButton = isAddIdeaVisible ? (
		<AddIdeaButton
			fieldKey={fieldKey}
			groupIdentity={groupIdentity || ''}
			onClick={onAddIdea}
			aria-label={formatMessage(messages.addIdea)}
		/>
	) : null;

	return (
		<ColumnWrapper
			isRow={isRow}
			onMouseEnter={showMenu}
			onMouseLeave={hideMenu}
			onFocus={showMenu}
			onBlur={hideMenu}
		>
			<HeaderContentContainer
				$draggable={!isReadOnly}
				isRow={isRow}
				isMenuVisible={isMenuVisible}
				isAddIdeaVisible={isAddIdeaVisible}
			>
				{children}
				{isRow ? (
					<>
						{elemAfter}
						<div
							css={[nonDraggableStyles, hidableMenuWrapperStyles]}
							data-component-selector="non-draggable-R7x4"
						>
							{menu}
							{addIdeaButton}
						</div>
					</>
				) : null}
			</HeaderContentContainer>
			{isRow ? null : (
				<>
					{elemAfter}
					{menu}
					{addIdeaButton}
				</>
			)}
		</ColumnWrapper>
	);
};

export type ColumnHeaderProps = {
	isRow?: boolean;
	isReadOnly: boolean;
	isAddVisible?: boolean;
	elemAfter: ReactNode;
	fieldKey: FieldKey;
	groupIdentity?: string;
	onMenuOpen?: (isOpen: boolean) => void;
	onAddClick?: ({ groupIdentity, fieldKey }: { groupIdentity: string; fieldKey: FieldKey }) => void;
};

export const ColumnHeader = ({
	fieldKey,
	groupIdentity,
	isRow = false,
	elemAfter = null,
	isReadOnly,
	isAddVisible = false,
	onAddClick,
	onMenuOpen,
}: ColumnHeaderProps) => {
	const [fields] = useFieldsByKey();
	const sortedGroupOptions = useSortedGroupOptions(fieldKey);
	const { updateFieldFilter } = useViewActions();
	const field = fields[fieldKey];
	const statusOptions = useExtendedOptionsInNaturalOrder(field);
	const permamentFieldFilter = usePermanentFieldFilter(fieldKey);

	const { Component, EmptyComponent, isHideable, isDecoratable } =
		(field?.type !== undefined ? headers.config[field.type] : undefined) || headers.default;

	const onHideColumn = useCallback(() => {
		updateFieldFilter(
			getNewFieldValueFilterOnHide(
				field,
				sortedGroupOptions,
				statusOptions,
				permamentFieldFilter,
				groupIdentity,
			),
		);
	}, [
		field,
		groupIdentity,
		permamentFieldFilter,
		sortedGroupOptions,
		statusOptions,
		updateFieldFilter,
	]);

	const headerContentComponent = useMemo(() => {
		if (groupIdentity !== undefined) {
			return <Component fieldKey={fieldKey} groupIdentity={groupIdentity} />;
		}
		const SafeEmptyComponent = EmptyComponent || EmptyColumnHeader;
		return <SafeEmptyComponent fieldKey={fieldKey} isRow={isRow} />;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [EmptyComponent, fieldKey, groupIdentity]);

	return (
		<HideableColumn
			isReadOnly={isReadOnly}
			isRow={isRow}
			elemAfter={elemAfter}
			fieldKey={fieldKey}
			groupIdentity={groupIdentity}
			isHideable={isHideable}
			isDecoratable={isDecoratable}
			isAddIdeaVisible={isAddVisible}
			onAddIdea={onAddClick}
			onHideColumn={onHideColumn}
			onMenuOpen={onMenuOpen}
		>
			{headerContentComponent}
		</HideableColumn>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ColumnWrapper = styled.div<{
	isRow: boolean;
}>({
	display: 'flex',
	maxWidth: '100%',
	width: '100%',
	alignItems: 'center',
	boxSizing: 'border-box',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	paddingLeft: ({ isRow }) => (isRow ? token('space.025') : 0),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeaderMenuContainer = styled.div({
	display: 'flex',
	flex: '0 0 auto',
	marginLeft: token('space.050'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeaderContentContainer = styled.div<{
	isRow: boolean;
	isMenuVisible: boolean;
	isAddIdeaVisible?: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	$draggable: any;
}>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	display: ({ isRow }) => isRow && 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	alignItems: ({ isRow }) => isRow && 'center',
	flex: '1 1 auto',
	maxWidth: '100%',
	width: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	paddingRight: ({ isMenuVisible, isAddIdeaVisible }) =>
		`${isMenuVisible || isAddIdeaVisible ? 0 : 10}px`,
	boxSizing: 'border-box',
	overflow: 'hidden',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	'&&': ({ $draggable }) =>
		// eslint-disable-next-line no-nested-ternary
		$draggable
			? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values
				fg('jpd_issues_relationships')
				? {
						'*:not([data-component-selector="non-draggable-R7x4"], [data-component-selector="non-draggable-R7x4"] *)':
							{
								cursor: 'grab',
							},
					}
				: {
						'*:not([data-component-selector="non-draggable-R7x4"] button, [data-component-selector="non-draggable-R7x4"] button span)':
							{
								cursor: 'grab',
							},
					}
			: undefined,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MenuItemContainer = styled.div<{ isVisible?: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	visibility: ({ isVisible }) => (isVisible ? 'visible' : 'hidden'),
	marginRight: token('space.050'),
});

const addButtonStyles = xcss({
	marginInlineStart: 'auto',
	flexShrink: 0,
});

const nonDraggableStyles = css({
	display: 'flex',
	flex: 1,
});

const hidableMenuWrapperStyles = css({
	minWidth: token('space.800'),
});
