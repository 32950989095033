import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	errorTitle: {
		id: 'polaris-component-early-access-program.controllers.error-title',
		defaultMessage: "Something's gone wrong",
		description: 'Notification title for failed field removal from the project',
	},
	errorDescription: {
		id: 'polaris-component-early-access-program.controllers.error-description',
		defaultMessage:
			'Our team has been notified. If the problem persists, please contact Atlassian Support.',
		description: 'Notification description for failed field removal from the project',
	},
});
