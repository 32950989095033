import { createSelector } from 'reselect';
import keyBy from 'lodash/keyBy';
import { fg } from '@atlassian/jira-feature-gating';
import { useIsSharedView } from '../../environment/index.tsx';
import { useStatusCategoriesForPublishedView } from '../../views/selectors/view-hooks.tsx';
import type { State, Props, StatusCategoryMap } from '../types.tsx';
import { createWorkflowHook } from './utils.tsx';

const getStatusCategories = (state: State) => state.statusCategories;
const getContainerStatusCategories = createSelector(
	(state: State, props?: Props) => props?.statusCategories,
	(statusCategories): StatusCategoryMap | undefined =>
		// @ts-expect-error TS-2739 https://stackoverflow.com/questions/68494879/why-does-type-record-sometimes-complain-about-missing-keys-and-sometimes-not
		statusCategories ? keyBy(statusCategories, (category) => category.key) : undefined,
);

const useContainerStatusCategories = createWorkflowHook(getContainerStatusCategories);
const useStatusCategoriesLocal = createWorkflowHook(getStatusCategories);

export const useStatusCategories = () => {
	const isSharedView = useIsSharedView();
	const statusCategoriesForPublished = useStatusCategoriesForPublishedView();
	const statusCategoriesOld = useStatusCategoriesLocal();
	const statusCategories = useContainerStatusCategories();

	if (fg('jpd-aurora-roadmap-delivery-fields')) {
		return isSharedView ? statusCategoriesForPublished : statusCategories;
	}
	return isSharedView ? statusCategoriesForPublished : statusCategoriesOld;
};
