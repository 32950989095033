// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import { logSafeErrorWithoutCustomerDataWrapper } from '@atlassian/jira-polaris-lib-errors/src/common/utils/index.tsx';
import { getLicenceInformation } from '../../../services/jira/xflow/index.tsx';
import type { State } from '../types.tsx';

export const loadLicenceInformation =
	(cloudId: string, refresh = false): Action<State> =>
	async ({ getState, setState }) => {
		if (
			getState().clouds[cloudId]?.isLoading ||
			getState().clouds[cloudId]?.data ||
			(getState().clouds[cloudId]?.error && !refresh)
		) {
			return;
		}

		setState({
			clouds: {
				...getState().clouds,
				[cloudId]: {
					isLoading: true,
					error: null,
					data: null,
				},
			},
		});

		try {
			const response = await getLicenceInformation(cloudId);

			setState({
				clouds: {
					...getState().clouds,
					[cloudId]: {
						isLoading: false,
						error: null,
						data: response,
					},
				},
			});
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			logSafeErrorWithoutCustomerDataWrapper(
				'polaris.load-licence-information',
				'Failed to load licence information',
				error,
			);
			setState({
				clouds: {
					...getState().clouds,
					[cloudId]: {
						isLoading: false,
						error,
						data: null,
					},
				},
			});
		}
	};
