import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { KEY_FIELD_HEIGHT } from '../../constants.tsx';

type Props = {
	issueKey: string;
};

export const KeyField = ({ issueKey }: Props) => {
	if (issueKey === undefined) {
		return null;
	}

	return (
		<KeyFieldContainer data-testid="polaris-common.ui.idea-card-v2.header.key-field.key-field-value">
			<KeyFieldValue>{issueKey}</KeyFieldValue>
		</KeyFieldContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const KeyFieldContainer = styled.div({
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${KEY_FIELD_HEIGHT}px`,
	alignItems: 'center',
	maxWidth: '75px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const KeyFieldValue = styled.div({
	font: token('font.body.small'),
	fontWeight: token('font.weight.bold'),
	color: token('color.text.subtlest'),
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});
