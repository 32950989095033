import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	noProjectPermissionsErrorTitle: {
		id: 'polaris-ideas.view-content.idea-board-next.card.no-project-permissions-error-title',
		defaultMessage: "You can't edit this idea",
		description:
			'Error message title when user do not have project permissions to edit ideas, so to drag them around.',
	},
	noProjectPermissionsErrorDescription: {
		id: 'polaris-ideas.view-content.idea-board-next.card.no-project-permissions-error-description',
		defaultMessage:
			"You don't have permission to edit ideas from <b>{projectName}</b> project. Contact your project admin.",
		description:
			'Error message description when user do not have project permissions to edit ideas, so to drag them around.',
	},
	columnFieldNotAddedToProjectErrorTitle: {
		id: 'polaris-ideas.view-content.idea-board-next.card.column-field-not-added-to-project-error-title',
		defaultMessage: "You can't move this idea",
		description:
			'Error message title when user try to drag an idea in board but the its project is missing the global fields used for board column.',
	},
	columnFieldNotAddedToProjectErrorDescription: {
		id: 'polaris-ideas.view-content.idea-board-next.card.column-fields-not-added-to-project-error-description',
		defaultMessage:
			'The field used to create the board columns hasn’t been added to <b>{projectName}</b>. To do so, select the global field > Edit from the header and add the field to the project.',
		description:
			'Error message title when user try to drag an idea in board but the its project is missing the global fields used for board column.',
	},
	groupByFieldNotAddedToProjectErrorTitle: {
		id: 'polaris-ideas.view-content.idea-board-next.card.group-by-field-not-added-to-project-error-title',
		defaultMessage: "You can't move this idea",
		description:
			'Error message title when user try to drag an idea in board but the its project is missing the global fields used for board group by.',
	},
	groupByFieldNotAddedToProjectErrorDescription: {
		id: 'polaris-ideas.view-content.idea-board-next.card.group-by-field-not-added-to-project-error-description',
		defaultMessage:
			"You can't move this idea because you are grouping by a field that has yet to be added to <b>{projectName}</b>. To do so, select the global field > Edit and add the field to the project.",
		description:
			'Error message title when user try to drag an idea in board but the its project is missing the global fields used for board group by.',
	},
});
