import React, { type SyntheticEvent } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import ModalDialog, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '@atlaskit/modal-dialog';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { ModalTransitionUniversal } from '@atlassian/jira-polaris-common/src/common/ui/modal-transition/index.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { FireScreenAnalytics } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages.tsx';

type Props = {
	isOpen: boolean;
	issueIds: LocalIssueId[];
	onCancel: (event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => void;
	onConfirm: (event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => void;
};

export const DeleteModal = (props: Props) => {
	const { isOpen, onCancel, onConfirm, issueIds } = props;
	const { formatMessage } = useIntl();

	return (
		<ModalTransitionUniversal>
			{isOpen && (
				<ShortcutScope>
					<ModalDialog>
						<ModalHeader>
							<ModalTitle appearance="danger">{formatMessage(messages.deleteHeader)}</ModalTitle>
						</ModalHeader>
						<ModalBody>
							<FireScreenAnalytics />
							{/* eslint-disable-next-line @atlaskit/design-system/use-primitives-text */}
							<p>
								{formatMessage(messages.description, {
									count: issueIds.length,
								})}
							</p>
						</ModalBody>
						<ModalFooter>
							<Button onClick={onConfirm} appearance="danger">
								{formatMessage(messages.delete)}
							</Button>
							<Button onClick={onCancel}>{formatMessage(messages.cancel)}</Button>
						</ModalFooter>
					</ModalDialog>
				</ShortcutScope>
			)}
		</ModalTransitionUniversal>
	);
};
