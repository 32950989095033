import { createPolarisStore } from '@atlassian/jira-polaris-lib-react-sweet-state-utils/src/utils/store/index.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createContainer } from '@atlassian/react-sweet-state';
import { actions, type Actions } from './actions/index.tsx';
import { type State, showingNothing } from './types.tsx';

export const RightSidebarStore = createPolarisStore<State, Actions>({
	initialState: {
		showing: showingNothing,
		preventClosing: false,
		option: null,
	},
	actions,
	name: 'PolarisRightSidebarStore',
});

export const RightSidebarContainer = createContainer(RightSidebarStore);
