import type { Ari } from '@atlassian/jira-platform-ari/src/index.tsx';
import type { PolarisPlayContribution } from '@atlassian/jira-polaris-domain-field/src/play/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { AccountId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { Avatar } from '../../../../../common/types/user/index.tsx';
import type { PlayStats } from '../../../types.tsx';
import {
	createIssueHook,
	createHigherLevelIssueHook,
	createHigherLevelIssueHook2,
	createHigherLevelIssueHook3,
} from '../../utils.tsx';
import {
	createGetIdeaPlayContributions,
	createGetIdeaPlayContributorsAvatars,
	createGetIdeaPlayTotalBudget,
	createGetIdeaPlayTotalContributors,
	createGetIdeaPlayUserContribution,
	createGetPlayContributionsStats,
	createGetPlaysMediaIds,
	createGetIdeaPlaysIdsWithContributions,
} from './index.tsx';

export const useGetIdeaPlayTotalBudget = createHigherLevelIssueHook2<Ari, LocalIssueId, number>(
	createGetIdeaPlayTotalBudget,
);

export const useGetIdeaPlayTotalContributors = createHigherLevelIssueHook2<
	Ari,
	LocalIssueId,
	number
>(createGetIdeaPlayTotalContributors);

export const useGetIdeaPlayContributions = createHigherLevelIssueHook2<
	Ari,
	LocalIssueId,
	PolarisPlayContribution[]
>(createGetIdeaPlayContributions);

export const useGetPlaysMediaIds = createIssueHook<string[]>(createGetPlaysMediaIds);

export const useGetIdeaPlayUserContribution = createHigherLevelIssueHook3<
	Ari,
	LocalIssueId,
	AccountId | null,
	PolarisPlayContribution | undefined
>(createGetIdeaPlayUserContribution);

export const useGetIdeaPlayContributorsAvatars = createHigherLevelIssueHook2<
	Ari,
	LocalIssueId,
	Avatar[]
>(createGetIdeaPlayContributorsAvatars);

export const useGetPlayContributionsStats = createHigherLevelIssueHook<Ari, PlayStats>(
	createGetPlayContributionsStats,
);

export const useGetIdeaPlaysIdsWithContributions = createHigherLevelIssueHook<
	LocalIssueId,
	{
		[key: string]: PolarisPlayContribution[];
	}
>(createGetIdeaPlaysIdsWithContributions);
