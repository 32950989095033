import React, { useEffect, useMemo, useState } from 'react';
import { styled } from '@compiled/react';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import { useApolloClient } from '@apollo/react-hooks';
import EditorSuccessIcon from '@atlaskit/icon/core/migration/success--editor-success';
import Spinner from '@atlaskit/spinner';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { getAriConfig, toAriOrDefault } from '@atlassian/jira-platform-ari/src/index.tsx';
import { useProjectIdUnsafe } from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import type { Insight } from '@atlassian/jira-polaris-domain-insight/src/insight/types.tsx';
import { useInsightRemote } from '@atlassian/jira-polaris-remote-insight/src/controllers/index.tsx';
import { toIssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { UserContainer } from '@atlassian/jira-user-services/src/main.tsx';
import { useInsightRefreshErrors } from '../../../controllers/issue/selectors/properties/insights/hooks.tsx';
import { RefreshErrorIssuePanel } from './issue/index.tsx';
import messages from './messages.tsx';

const NO_ISSUE = '-1';

export const RefreshErrorDialogContent = () => {
	const { formatMessage } = useIntl();
	const [errors, setErrors] = useState<Insight[] | undefined>(undefined);
	const [prevErrorsInSharedState, setPrevErrorsInSharedState] = useState<
		ReturnType<typeof useInsightRefreshErrors> | undefined
	>(undefined);
	const client = useApolloClient();
	const { cloudId } = useTenantContext();
	const projectId = useProjectIdUnsafe();
	const errorsInSharedState = useInsightRefreshErrors();
	const insightRemote = useInsightRemote();

	useEffect(() => {
		if (errorsInSharedState !== prevErrorsInSharedState) {
			setPrevErrorsInSharedState(errorsInSharedState);
			insightRemote.fetchInsightsWithErrors?.().then((data) => {
				setErrors(data);
			});
		}
	}, [
		client,
		cloudId,
		projectId,
		insightRemote,
		errorsInSharedState,
		prevErrorsInSharedState,
		setPrevErrorsInSharedState,
	]);

	const errorsByIssueId: {
		[key: string]: Insight[];
	} = useMemo(
		() =>
			groupBy(errors || [], ({ container }) => {
				if (container === undefined || container === null) {
					return NO_ISSUE;
				}
				const containerAri = getAriConfig(toAriOrDefault(container));
				return containerAri.resourceId;
			}),
		[errors],
	);

	const hasErrors = useMemo(() => Object.keys(errorsByIssueId).length > 0, [errorsByIssueId]);

	if (errors === undefined) {
		// no errors loaded yet, show spinner
		return (
			<LoadingWrapper>
				<Spinner />
			</LoadingWrapper>
		);
	}

	return (
		<ContentWrapper>
			{!hasErrors && (
				<SuccessWrapper>
					<EditorSuccessIcon
						color={token('color.icon.success')}
						label={formatMessage(messages.editorSuccessIcon)}
						LEGACY_primaryColor={token('color.text.success')}
						LEGACY_size="xlarge"
					/>
					{formatMessage(messages.allResolved)}
				</SuccessWrapper>
			)}
			{hasErrors && (
				<UserContainer baseUrl="" scope="user-singleton">
					<ExplanationWrapper>{formatMessage(messages.explanation)}</ExplanationWrapper>
					<DetailsWrapper>
						{map(errorsByIssueId, (insights: Insight[], jiraIssueId: string) => (
							<RefreshErrorIssuePanel
								key={jiraIssueId}
								jiraIssueId={toIssueId(jiraIssueId)}
								insights={insights}
							/>
						))}
					</DetailsWrapper>
				</UserContainer>
			)}
		</ContentWrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContentWrapper = styled.div({
	display: 'flex',
	flexDirection: 'column',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ExplanationWrapper = styled.div({
	display: 'flex',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DetailsWrapper = styled.div({
	marginTop: token('space.100'),
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'start',
	overflow: 'auto',
	minHeight: '100px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SuccessWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LoadingWrapper = styled.div({
	display: 'flex',
	width: '100%',
	height: '128px',
	alignItems: 'center',
	justifyContent: 'center',
});
