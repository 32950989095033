import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { AddFieldOptionRequest } from './types.tsx';

export const addFieldOption = ({
	projectId,
	issueTypeId,
	fieldKey,
	newOption,
}: AddFieldOptionRequest): Promise<number> =>
	performPostRequest(`/rest/polaris/v2/projects/${projectId}/fields/${fieldKey}/options`, {
		body: fg('jpd_use_field_association_helper')
			? JSON.stringify({
					value: newOption,
				})
			: JSON.stringify({
					issueTypeId: +issueTypeId,
					value: newOption,
				}),
	});
