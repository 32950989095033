import React, { useEffect, useState, useCallback, type SyntheticEvent } from 'react';
import { IconButton } from '@atlaskit/button/new';
import ShareButton from '@atlaskit/icon/core/migration/share';
import type { TriggerProps } from '@atlaskit/popup';
import UFOSegment from '@atlaskit/react-ufo/segment';
import { SpotlightTarget } from '@atlaskit/onboarding';
import { useIntl } from '@atlassian/jira-intl';
import type { AccessLevel } from '@atlassian/jira-polaris-common/src/common/types/access/index.tsx';
import { JiraPopup as Popup } from '@atlassian/jira-popup/src/ui/jira-popup.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { fetchProjectAccessLevel } from '@atlassian/jira-polaris-common/src/services/jira/project/access/index.tsx';
import {
	useProjectKeyUnsafe,
	useProjectIdUnsafe,
} from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import { useIsProjectAdmin } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import {
	OutsideClickAlerter,
	isFocusOutsideJFE,
} from '@atlassian/jira-polaris-lib-outside-click-alerter/src/index.tsx';
import {
	type UIAnalyticsEvent,
	fireUIAnalytics,
	MODAL,
	ContextualAnalyticsData,
} from '@atlassian/jira-product-analytics-bridge';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import {
	INVITE_FROM_SHARE_DIALOG_SPOTLIGHT_DISMISSED,
	SHARE_VIEW_SPOTLIGHT_DISMISSED,
} from '@atlassian/jira-polaris-lib-onboarding-flows/src/controllers/constants.tsx';
import { ShareViewSpotlight } from '@atlassian/jira-polaris-lib-onboarding-flows/src/ui/share-dialog/share/index.tsx';
import { InviteFromShareDialogSpotlight } from '@atlassian/jira-polaris-lib-onboarding-flows/src/ui/share-dialog/invite/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { getUserProperty } from '@atlassian/jira-common-rest/src/api/latest/user-properties/index.tsx';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import messages from './messages.tsx';
import { ShareDialogContent } from './share-dialog-content/index.tsx';

export const ShareDialog = () => {
	const [accessLevel, setAccessLevel] = useState<AccessLevel>();
	const [dialogOpen, setDialogOpen] = useState(false);
	const [isProjectAdmin] = useIsProjectAdmin();
	const { formatMessage } = useIntl();
	const projectId = useProjectIdUnsafe();
	const projectKey = useProjectKeyUnsafe();
	const accountId = useAccountId();

	useEffect(() => {
		if (dialogOpen) {
			fetchProjectAccessLevel(projectId).then(setAccessLevel);
		}
	}, [dialogOpen, projectId, projectKey, isProjectAdmin]);

	const handleTogglePopup = (event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
		if (!dialogOpen) {
			fireUIAnalytics(analyticsEvent, 'shareModal');
		}
		setDialogOpen(!dialogOpen);
	};

	const handleClickOutside = useCallback(
		(event: Event) => {
			if (isFocusOutsideJFE(event?.target) || !dialogOpen) {
				return;
			}
			setDialogOpen(false);
		},
		[setDialogOpen, dialogOpen],
	);

	// this state below kind of duplicate the responsibility of the onboarding-flows lib,
	// but it is needed as we want to show the invite spotlight only if the share spotlight was shown before
	// and both of this spotlights should be separate from each other (no tours)
	const [shareViewSpotlightDismissed, setShareViewSpotlightDismissed] = useState<boolean>(false);

	useEffect(() => {
		if (accountId && expVal('jpd_invite_from_share_dialog', 'isEnabled', false)) {
			getUserProperty<boolean>(accountId, SHARE_VIEW_SPOTLIGHT_DISMISSED).then(
				setShareViewSpotlightDismissed,
			);
		}
	}, [accountId]);

	const getTriggerComponent = (triggerProps: TriggerProps) => {
		const component = (
			<IconButton
				id="pendo.share-modal.open-button"
				testId="polaris-ideas.ui.view-header.share-dialog.button"
				{...triggerProps}
				label={formatMessage(messages.shareButtonLabel)}
				appearance={isVisualRefreshEnabled() ? 'default' : 'subtle'}
				isSelected={dialogOpen}
				onClick={handleTogglePopup}
				icon={ShareButton}
				isTooltipDisabled={false}
				{...(fg('jpd-trace-ufo-transition') ? { interactionName: 'jpd.view-share-modal' } : {})}
			/>
		);

		return expVal('jpd_invite_from_share_dialog', 'isEnabled', false) ? (
			<>
				<SpotlightTarget name={SHARE_VIEW_SPOTLIGHT_DISMISSED}>
					<SpotlightTarget name={INVITE_FROM_SHARE_DIALOG_SPOTLIGHT_DISMISSED}>
						{component}
					</SpotlightTarget>
				</SpotlightTarget>
				{shareViewSpotlightDismissed ? <InviteFromShareDialogSpotlight /> : <ShareViewSpotlight />}
			</>
		) : (
			component
		);
	};

	return (
		<OutsideClickAlerter onClickOutside={handleClickOutside}>
			{(outsideClickAlerterProps) => (
				<div {...outsideClickAlerterProps}>
					<Popup
						isOpen={dialogOpen}
						placement="bottom-end"
						messageId="polaris-ideas.ui.view-header.share-dialog.popup"
						messageType="transactional"
						content={() => (
							<UFOSegment name="jpd.view-share-modal">
								<ContextualAnalyticsData sourceName="share" sourceType={MODAL}>
									<ShareDialogContent
										accessLevel={accessLevel}
										onCloseDialog={() => setDialogOpen(false)}
									/>
								</ContextualAnalyticsData>
							</UFOSegment>
						)}
						trigger={getTriggerComponent}
					/>
				</div>
			)}
		</OutsideClickAlerter>
	);
};
