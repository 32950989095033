type FrameCapturing =
	| {
			state: 'idle';
	  }
	| {
			state: 'running';
			capturingId: number;
			capturingStartTime: number;
			capturedFrameTimes: number[];
	  }
	| {
			state: 'disposed';
	  };

export type CapturingResult = {
	capturingStartTime: number;
	capturingStopTime: number;
	capturedFrameTimes: number[];
};

const getInitialFrameCapturing = (): FrameCapturing => {
	return {
		state: 'idle',
	};
};

export const createFrameCapturer = () => {
	let frameCapturing = getInitialFrameCapturing();

	// This function should be scheduled with requestAnimationFrame
	const captureNextFrameTime = () => {
		if (frameCapturing.state !== 'running') {
			// We silently ignore this case since it's being called through requestAnimationFrame
			// and we don't have a feasible way of capturing error thrown from this function.
			// Also, this method is "private" so we fully controll when it's called.
			// We don't need to cover it with tests, as long as we have a test checking
			// if cancelAnimationFrame is called within stopCapturingFrames.
			return;
		}
		frameCapturing.capturedFrameTimes.push(performance.now());

		// We capture frames in a "loop"
		frameCapturing.capturingId = requestAnimationFrame(captureNextFrameTime);
	};

	const startCapturingFrames = () => {
		if (frameCapturing.state !== 'idle') {
			throw new Error('Frame capturing is not idle. Cannot start capturing frames.');
		}

		frameCapturing = {
			state: 'running',
			capturedFrameTimes: [],
			capturingStartTime: performance.now(),
			capturingId: requestAnimationFrame(captureNextFrameTime),
		};
	};

	const stopCapturingFrames = (): CapturingResult => {
		if (frameCapturing.state !== 'running') {
			throw new Error('Frame capturing is not running. Cannot stop capturing frames.');
		}

		const { capturedFrameTimes, capturingStartTime } = frameCapturing;

		// cleanup
		cancelAnimationFrame(frameCapturing.capturingId);
		frameCapturing = getInitialFrameCapturing();

		return {
			capturedFrameTimes,
			capturingStartTime,
			capturingStopTime: performance.now(),
		};
	};

	const isIdle = () => {
		return frameCapturing.state === 'idle';
	};

	const dispose = () => {
		if (frameCapturing.state === 'running') {
			cancelAnimationFrame(frameCapturing.capturingId);
		}

		frameCapturing = {
			state: 'disposed',
		};
	};

	return {
		isIdle,
		dispose,
		startCapturingFrames,
		stopCapturingFrames,
	};
};
