import React, { useEffect, useRef, useState } from 'react';
import noop from 'lodash/noop';
import {
	type Edge,
	attachClosestEdge,
	extractClosestEdge,
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { DropIndicator } from '@atlaskit/pragmatic-drag-and-drop-react-drop-indicator/box';
import { dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { Box, xcss } from '@atlaskit/primitives';
import type { ColumnId } from '../../../types/index.tsx';

type DropTargetProps = {
	columnId: ColumnId;
	isDraggingActive: boolean;
};

export const DropTarget = ({ columnId, isDraggingActive }: DropTargetProps) => {
	const dropTargetRef = useRef<HTMLDivElement>(null);
	const [closestEdge, setClosestEdge] = useState<Edge | null>(null);

	useEffect(() => {
		if (!isDraggingActive || !dropTargetRef.current) {
			return noop;
		}

		return dropTargetForElements({
			element: dropTargetRef.current,
			getIsSticky() {
				return true;
			},
			getData({ input, element }) {
				const data = { id: columnId };
				return attachClosestEdge(data, {
					input,
					element,
					allowedEdges: ['left', 'right'],
				});
			},
			canDrop({ source }) {
				return source.data.type === 'jpd-draggable-column';
			},
			onDrag({ source, self }) {
				if (source.data.id !== columnId) {
					setClosestEdge(extractClosestEdge(self.data));
				}
			},
			onDragLeave() {
				setClosestEdge(null);
			},
			onDrop() {
				setClosestEdge(null);
			},
		});
	}, [columnId, isDraggingActive]);

	return (
		<Box ref={dropTargetRef} xcss={[containerCss, isDraggingActive && visibleStyles]}>
			{closestEdge && <DropIndicator edge={closestEdge} type="no-terminal" />}
		</Box>
	);
};

const visibleStyles = xcss({
	display: 'block',
});

const containerCss = xcss({
	display: 'none',
	position: 'absolute',
	top: 'space.0',
	left: 'space.0',
	width: '100%',
	height: '100vh',
});
