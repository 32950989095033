/** @jsx jsx */
import React, {
	useCallback,
	memo,
	useMemo,
	type ReactNode,
	useEffect,
	useRef,
	useState,
} from 'react';
import { jsx, css } from '@compiled/react';
import { IconButton } from '@atlaskit/button/new';
import ChevronDownIcon from '@atlaskit/icon/utility/migration/chevron-down';
import ChevronRightIcon from '@atlaskit/icon/utility/migration/chevron-right';
import {
	attachClosestEdge,
	extractClosestEdge,
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import type { Edge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/types';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import { dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { Flex, xcss } from '@atlaskit/primitives';
import { N20 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import type { ColumnId, RowGroupId } from '../../../common/types/index.tsx';
import type { RowData } from '../../../common/types/react-base-table/index.tsx';
import {
	CLASS_ROW,
	CLASS_ROW_CELL,
	CLASS_SUMMARY_CELL,
	CLASS_FIXED_ROW_CELL,
} from '../../../common/ui/constants/index.tsx';
import { NO_VALUE_GROUP_ID } from '../../../constants.tsx';
import { useListActions } from '../../../controllers/index.tsx';
import { useResizingColumn } from '../../../controllers/selectors/columns-hooks.tsx';
import {
	useRowGroupComponent,
	useGroupCellComponent,
} from '../../../controllers/selectors/components-hooks.tsx';
import { useColumnConfigurationByColumnId } from '../../../controllers/selectors/items-hooks.tsx';
import { useCurrentStickyGroupRow } from '../../../controllers/selectors/rows-hooks.tsx';
import {
	useBaseTableApi,
	useVisibleColumnIds,
	useInvisibleColumnPlaceholderWidthLeft,
	useIsExporting,
} from '../../../controllers/selectors/ui-hooks.tsx';
import { SelectAllInGroupCheckBox } from '../../cell/selection-checkbox/index.tsx';
import { borderColor, DEFAULT_ROW_HEIGHT } from '../../constants.tsx';
import { RowContainer } from '../../row/main.tsx';
import { messages } from '../messages.tsx';

type GroupCellProps = {
	columnId: ColumnId;
	groupId: RowGroupId;
	isFixed?: boolean;
};

const GroupCell = memo<GroupCellProps>(({ columnId, groupId }: GroupCellProps) => {
	const GroupCellComponent = useGroupCellComponent();

	const colConfig = useColumnConfigurationByColumnId(columnId);
	const className = useMemo(() => `${CLASS_ROW_CELL} ${colConfig?.className}`, [colConfig]);

	const resizingColumn = useResizingColumn();
	const resizingColWidth = resizingColumn?.[columnId];

	const widthNumber = resizingColWidth ?? colConfig?.width;
	const width = widthNumber !== undefined ? `${widthNumber}px` : undefined;

	return (
		<div
			css={groupCellWrapperStyles}
			role="gridcell"
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className={className}
			// eslint-disable-next-line jira/react/no-style-attribute, @atlaskit/ui-styling-standard/enforce-style-prop
			style={width ? { width } : undefined}
		>
			<GroupCellComponent isHovered columnId={columnId} groupId={groupId} />
		</div>
	);
});

type FixedPartProps = {
	isExpanded?: boolean;
	groupId: string;
	children: React.ReactNode;
};

const FixedPart = ({ isExpanded, groupId, children }: FixedPartProps) => {
	const { formatMessage } = useIntl();
	const { toggleRowGroupExpansion } = useListActions();

	// `useResizingColumn` is required here to sync
	// rendering with column resizing
	useResizingColumn();

	const tableApi = useBaseTableApi();
	const fixedColumnsWidth = tableApi?.getColumnManager()?.getLeftFrozenColumnsWidth();

	const onChangeExpansion = useCallback(() => {
		toggleRowGroupExpansion(groupId);
	}, [groupId, toggleRowGroupExpansion]);

	return (
		<div
			css={groupCellWrapperStyles}
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className={`${CLASS_ROW_CELL} ${CLASS_SUMMARY_CELL} ${CLASS_FIXED_ROW_CELL}`}
			role="gridcell"
			// eslint-disable-next-line jira/react/no-style-attribute
			style={{
				width: fixedColumnsWidth && `${fixedColumnsWidth}px`,
			}}
		>
			<Flex xcss={fixedPartWrapperStyles}>
				<Flex alignItems="center" xcss={actionContainerStyles}>
					<div css={collapseButtonContainerStyles}>
						<IconButton
							onClick={onChangeExpansion}
							icon={isExpanded ? ChevronDownIcon : ChevronRightIcon}
							label={formatMessage(
								isExpanded
									? messages.collapseButtonCollapseLabel
									: messages.collapseButtonExpandLabel,
							)}
							appearance="subtle"
							spacing="compact"
						/>
					</div>
					<SelectAllInGroupCheckBox groupId={groupId} />
				</Flex>
				{children}
			</Flex>
		</div>
	);
};

const DefaultRowGroupContainer = memo<{
	children?: ReactNode;
	rowData: RowData;
	isDarkMode: boolean;
}>(({ children, rowData, isDarkMode }) => {
	useResizingColumn();
	const placeholderWidthLeft = useInvisibleColumnPlaceholderWidthLeft();

	const { isExpanded, isEmpty, id, type, key } = rowData;
	const groupId = key === undefined ? NO_VALUE_GROUP_ID : key;

	const visibleColumns = useVisibleColumnIds();
	const visibleCells = useMemo(
		() =>
			visibleColumns.map((columnId) => (
				<GroupCell key={columnId} columnId={columnId} groupId={groupId} />
			)),
		[groupId, visibleColumns],
	);

	const wrapperClassName = useMemo(
		() => `${CLASS_ROW} ${isExpanded ? 'expanded' : ''} ${isEmpty ? 'empty' : ''}`.trim(),
		[isExpanded, isEmpty],
	);
	const { trackRowDraggingInfo } = useListActions();

	const rowRef = useRef<HTMLDivElement>(null);
	const [closestEdge, setClosestEdge] = useState<Edge | null>(null);

	useEffect(() => {
		if (!rowRef.current) return undefined;

		const resetEdge = () => {
			setClosestEdge(null);
			trackRowDraggingInfo({
				dropEdge: null,
			});
		};

		const cleanupDragAndDrop = combine(
			dropTargetForElements({
				element: rowRef.current,
				getData({ input, element }) {
					return attachClosestEdge(
						{ id, groupId, type },
						{
							input,
							element,
							allowedEdges: ['top', 'bottom'],
						},
					);
				},
				canDrop({ source }) {
					return source.data.type === 'ITEM' || source.data.type === 'GROUP';
				},
				onDrag(args) {
					const dropEdge = extractClosestEdge(args.self.data);
					if (closestEdge !== dropEdge) {
						setClosestEdge(dropEdge);
						trackRowDraggingInfo({
							dropTarget: groupId,
							type: 'GROUP',
							dropEdge,
						});
					}
				},
				onDragLeave: resetEdge,
				onDrop: resetEdge,
			}),
		);

		return () => {
			cleanupDragAndDrop?.();
		};
	}, [closestEdge, id, trackRowDraggingInfo, type, groupId]);

	return (
		<div
			css={[groupRowWrapperStyles, !closestEdge && closestEdgeConditionalStyles]}
			// needed to add classes to the wrapper, props spreading doesn't work with @compiled/react
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop
			className={wrapperClassName}
			role="rowgroup"
			ref={rowRef}
		>
			<RowContainer data-testid="polaris-lib-list.ui.group-row.sticky.row">
				<Flex xcss={contentContainerStyles} alignItems="center">
					<FixedPart groupId={groupId} isExpanded={isExpanded}>
						{children}
					</FixedPart>
					{placeholderWidthLeft > 0 && (
						<div
							css={[
								placeholderStyles,
								isDarkMode && darkModeStyles,
								!isDarkMode && lightModeStyles,
							]}
							// eslint-disable-next-line jira/react/no-style-attribute
							style={{
								width: `${placeholderWidthLeft}px`,
							}}
						/>
					)}
					{visibleCells}
					<div
						css={[
							placeholderStyles,
							placeholderRightStyles,
							isDarkMode && darkModeStyles,
							!isDarkMode && lightModeStyles,
						]}
					/>
				</Flex>
			</RowContainer>
		</div>
	);
});

type StickyRowGroupComponentProps = {
	isDarkMode: boolean;
};

export const StickyRowGroupComponent = memo(({ isDarkMode }: StickyRowGroupComponentProps) => {
	const ConsumerComponent = useRowGroupComponent();
	const currentStickyGroupRow = useCurrentStickyGroupRow();
	const isExporting = useIsExporting();
	const { key: groupId } = currentStickyGroupRow ?? {};
	const { triggerRowGroupExpansion } = useListActions();

	const onExpandGroup = useCallback(() => {
		if (groupId !== undefined) {
			triggerRowGroupExpansion(groupId);
		}
	}, [groupId, triggerRowGroupExpansion]);

	// POL-8106 - Fix for broken sticky group row when exporting
	if (isExporting) {
		return null;
	}

	if (currentStickyGroupRow === undefined) {
		return null;
	}

	if (ConsumerComponent !== undefined) {
		return (
			<DefaultRowGroupContainer rowData={currentStickyGroupRow} isDarkMode={isDarkMode}>
				<ConsumerComponent
					groupId={groupId === NO_VALUE_GROUP_ID ? undefined : groupId}
					isExporting={isExporting}
					onExpandGroup={onExpandGroup}
				/>
			</DefaultRowGroupContainer>
		);
	}

	return (
		<DefaultRowGroupContainer rowData={currentStickyGroupRow} isDarkMode={isDarkMode}>
			<Flex xcss={rowGroupContainerStyles} alignItems="center">
				{groupId}
			</Flex>
		</DefaultRowGroupContainer>
	);
});

const actionContainerStyles = xcss({
	flex: 1,
});

const collapseButtonContainerStyles = css({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	minWidth: token('space.300'),
	minHeight: token('space.300'),
	marginTop: 0,
	marginRight: token('space.050'),
	marginBottom: 0,
	marginLeft: token('space.050'),
});

const placeholderStyles = css({
	boxSizing: 'border-box',
	height: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
	borderTop: `1px solid ${borderColor}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
	borderBottom: `1px solid ${borderColor}`,
});

const lightModeStyles = css({
	backgroundColor: N20,
});

const darkModeStyles = css({
	backgroundColor: token('elevation.surface.raised'),
});

const placeholderRightStyles = css({
	flex: 1,
});

const groupRowWrapperStyles = css({
	position: 'sticky',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values
	top: `${DEFAULT_ROW_HEIGHT}px`,
	zIndex: 100,
	display: 'flex',
	flex: 1,
	alignItems: 'center',
	boxSizing: 'border-box',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values
	height: `${DEFAULT_ROW_HEIGHT}px`,
	'&::before': {
		content: '',
		position: 'absolute',
		top: 'auto',
		bottom: '-1.5px',
		width: '100%',
		height: '3px',
		zIndex: 200,
		backgroundColor: token('color.border.brand'),
	},
});

const closestEdgeConditionalStyles = css({
	'&::before': {
		display: 'none',
	},
});

const groupCellWrapperStyles = css({
	display: 'flex',
	left: 0,
});

const fixedPartWrapperStyles = xcss({
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	marginLeft: 'space.negative.100',
});

const rowGroupContainerStyles = xcss({
	width: '100%',
});

const contentContainerStyles = xcss({
	width: '100%',
});
