import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { useCanEditIssues } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import type { ValueDecoration } from '@atlassian/jira-polaris-domain-field/src/decoration/types.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldType } from '@atlassian/jira-polaris-domain-field/src/field-types/types.tsx';
import { CheckboxNumberField } from './checkbox/index.tsx';
import { NumericNumberField, NumericNumberFieldReadView } from './numeric/index.tsx';
import { RatingNumberField } from './rating/index.tsx';
import { SliderNumberField, SliderNumberFieldEmptyView } from './slider/index.tsx';

type NumberFieldReadViewProps = {
	isEditable: boolean;
	value: number | undefined;
	decorations: ValueDecoration[] | undefined;
	placeholder?: string | undefined;
	fieldType: FieldType;
};

type NumberFieldProps = {
	isIdeaView?: boolean;
	isEditable: boolean;
	isCompact?: boolean;
	testId?: string;
} & NumberFieldReadViewProps & {
		onUpdate: ((inputValue: number | undefined) => void) | undefined;
		onModeChanged?: (arg1: { isEditing: boolean }) => void;
	};

export const NumberFieldReadView = ({
	decorations,
	value,
	placeholder,
	fieldType,
	isEditable,
}: NumberFieldReadViewProps) => {
	const [canEdit] = useCanEditIssues();

	if (fieldType === FIELD_TYPES.CHECKBOX) {
		return (
			<Container>
				<CheckboxNumberField value={value} />
			</Container>
		);
	}

	if (fieldType === FIELD_TYPES.RATING) {
		return (
			<Container>
				<RatingNumberField isEditable={false} value={value} decorations={decorations} />
			</Container>
		);
	}

	if (fieldType === FIELD_TYPES.SLIDER && canEdit && value === undefined) {
		return (
			<Container>
				<SliderNumberFieldEmptyView />
			</Container>
		);
	}

	return (
		<NumericNumberFieldReadView
			isEditable={isEditable}
			decorations={decorations}
			value={value}
			placeholder={placeholder}
		/>
	);
};

export const NumberField = ({
	isIdeaView,
	value,
	decorations,
	fieldType,
	onUpdate,
	isEditable,
	placeholder,
	onModeChanged,
	isCompact,
	testId,
}: NumberFieldProps) => {
	if (fieldType === FIELD_TYPES.CHECKBOX) {
		return (
			<Container isCompact={isCompact}>
				<CheckboxNumberField value={value} onUpdate={onUpdate} isEditable={isEditable} />
			</Container>
		);
	}

	if (fieldType === FIELD_TYPES.RATING) {
		return (
			<Container isCompact={isCompact}>
				<RatingNumberField
					isEditable={isEditable}
					value={value}
					decorations={decorations}
					onUpdate={onUpdate}
				/>
			</Container>
		);
	}

	if (fieldType === FIELD_TYPES.SLIDER && isEditable) {
		return (
			<Container isCompact={isCompact}>
				<SliderNumberField
					value={value}
					decorations={decorations}
					onUpdate={onUpdate}
					placeholder={placeholder}
				/>
			</Container>
		);
	}

	return (
		<NumericNumberField
			testId={testId}
			isIdeaView={isIdeaView}
			decorations={decorations}
			value={value}
			placeholder={placeholder}
			onUpdate={onUpdate}
			isEditable={isEditable}
			onModeChanged={onModeChanged}
		/>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div<{ isCompact?: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	padding: ({ isCompact }) => (isCompact ? 0 : `${token('space.075')} 5px ${token('space.075')}`),
});
