import React, { useCallback, useMemo, type ReactNode } from 'react';
import uniq from 'lodash/uniq';
import { Box } from '@atlaskit/primitives/compiled';
import { cssMap } from '@atlaskit/css';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import {
	useHighlightedDecorations,
	useHighlightedDecorationsForList,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/decorations-hooks.tsx';
import { useLocalIssueIdToJiraIssueIdMapper } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/issue-ids-hooks.tsx';
import { useIssueAnalitycsAttributes } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import {
	useCurrentViewXAxis,
	useCurrentViewYAxis,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { N300 } from '@atlassian/jira-polaris-lib-color-palette/src/ui/colors/index.tsx';
import type {
	ItemProps,
	ItemWrapperProps,
	ClusteredItemProps,
	DraggableBubbleProps,
} from '@atlassian/jira-polaris-lib-matrix/src/types.tsx';
import {
	DefaultBubbleComponent,
	DefaultBubbleClusterComponent,
	DraggableBubble as DraggableBubbleDefault,
} from '@atlassian/jira-polaris-lib-matrix/src/ui/bubble/index.tsx';
import { PolarisTooltip } from '@atlassian/jira-polaris-lib-tooltip/src/ui/index.tsx';
import { ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge';
import { useNotifications } from '@atlassian/jira-polaris-lib-notifications/src/index.tsx';
import { useCanEditIssues } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import {
	useProjectForIssue,
	useProjectIdsForIssues,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/project/hooks.tsx';
import { BubbleTooltip } from '../tooltip/index.tsx';
import { useItems } from '../utils/items.tsx';
import { messages } from './messages.tsx';

type MatrixBubbleTooltipProps = {
	id: string;
	children: ReactNode;
};

const MatrixBubbleTooltip = ({ id, children }: MatrixBubbleTooltipProps) => {
	const selectedXAxisField = useCurrentViewXAxis();
	const selectedYAxisField = useCurrentViewYAxis();

	const { items } = useItems(selectedXAxisField, selectedYAxisField);
	const currentItem = items.find((item) => item.id === id);

	return (
		<PolarisTooltip
			position="top"
			delay={100}
			hideTooltipOnClick
			content={
				currentItem !== undefined ? (
					<BubbleTooltip itemIds={[id]} xValue={currentItem.x} yValue={currentItem.y} />
				) : null
			}
		>
			{children}
		</PolarisTooltip>
	);
};

export const MatrixBubble = ({ id, isHovered, isDragging, ...rest }: ItemProps) => {
	const decorations = useHighlightedDecorations(id);
	const highlights: string[] = decorations
		.map(({ highlightContainer, backgroundColor }) =>
			highlightContainer ? backgroundColor : undefined,
		)
		.filter(Boolean);

	const bubbleComponent = (
		<DefaultBubbleComponent
			id={id}
			color={N300}
			borderColor={highlights.length === 0 ? N300 : highlights}
			isHovered={isHovered}
			isDragging={isDragging}
			{...rest}
		/>
	);

	return isHovered && !isDragging ? (
		<MatrixBubbleTooltip id={id}>{bubbleComponent}</MatrixBubbleTooltip>
	) : (
		bubbleComponent
	);
};

type MatrixClusterBubbleTooltipProps = {
	items: string[];
	children: ReactNode;
};

const MatrixClusterBubbleTooltip = ({ items, children }: MatrixClusterBubbleTooltipProps) => {
	const { formatMessage } = useIntl();

	const selectedXAxisField = useCurrentViewXAxis();
	const selectedYAxisField = useCurrentViewYAxis();

	const { items: matrixItems } = useItems(selectedXAxisField, selectedYAxisField);
	const currentItem = matrixItems.find((item) => item.id === items[0]);

	return (
		<PolarisTooltip
			position="top"
			delay={100}
			hideTooltipOnClick
			content={
				currentItem !== undefined ? (
					<BubbleTooltip
						itemIds={items}
						tooltipTitle={formatMessage(messages.groupedIdeas, { count: items.length })}
						xValue={currentItem.x}
						yValue={currentItem.y}
					/>
				) : null
			}
		>
			{children}
		</PolarisTooltip>
	);
};

export const MatrixClusterBubble = ({
	items,
	isHovered,
	isDragging,
	...rest
}: ClusteredItemProps) => {
	const decorations = useHighlightedDecorationsForList(items);
	const highlights: string[] = uniq(
		decorations
			.map(({ highlightContainer, backgroundColor }) =>
				highlightContainer ? backgroundColor : undefined,
			)
			.filter(Boolean),
	);

	const bubbleComponent = (
		<DefaultBubbleClusterComponent
			items={items}
			color={N300}
			borderColor={highlights.length === 0 ? N300 : highlights}
			isHovered={isHovered}
			isDragging={isDragging}
			{...rest}
		/>
	);

	return isHovered && !isDragging ? (
		<MatrixClusterBubbleTooltip items={items}>{bubbleComponent}</MatrixClusterBubbleTooltip>
	) : (
		bubbleComponent
	);
};

export const MatrixBubbleWrapper = ({ itemIds, children }: ItemWrapperProps) => {
	const localIssueIdToJiraIssueIdMapper = useLocalIssueIdToJiraIssueIdMapper();
	const issueAnalitycsAttributes = useIssueAnalitycsAttributes(itemIds[0]);

	const attributes = useMemo(() => {
		if (itemIds.length > 1) {
			return {
				clusteredIssueIds: itemIds?.map(localIssueIdToJiraIssueIdMapper),
			};
		}

		return issueAnalitycsAttributes;
	}, [issueAnalitycsAttributes, itemIds, localIssueIdToJiraIssueIdMapper]);

	return <ContextualAnalyticsData attributes={attributes}>{children}</ContextualAnalyticsData>;
};

const styles = cssMap({
	strong: {
		fontWeight: token('font.weight.bold'),
		display: 'inline',
	},
});

export const DraggableBubble = (props: DraggableBubbleProps) => {
	const { formatMessage } = useIntl();
	const projectIds = useProjectIdsForIssues(props.itemIds);
	const firstIdeaProject = useProjectForIssue(props.itemIds[0]);
	const [hasAllItemsProjectsPermission] = useCanEditIssues({ projectIds });
	const { error } = useNotifications();
	const isDropForbidden = !hasAllItemsProjectsPermission;
	const { onDragEnd: onDragEndDefault } = props;
	const isCluster = props.itemIds.length > 1;

	const onDragEnd = useCallback(() => {
		if (isDropForbidden) {
			if (isCluster) {
				error({
					title: formatMessage(messages.noProjectPermissionsErrorTitleCluster),
					description: formatMessage(messages.noProjectPermissionsErrorDescriptionCluster),
				});
			} else {
				error({
					title: formatMessage(messages.noProjectPermissionsErrorTitleSingle),
					description: formatMessage(messages.noProjectPermissionsErrorDescriptionSingle, {
						projectName: firstIdeaProject?.name,
						b: (chunks: React.ReactNode[]) => <Box xcss={styles.strong}>{chunks}</Box>,
					}),
				});
			}
		}
		return onDragEndDefault();
	}, [error, firstIdeaProject?.name, formatMessage, isCluster, isDropForbidden, onDragEndDefault]);

	return (
		<DraggableBubbleDefault
			{...props}
			canDrop={hasAllItemsProjectsPermission}
			onDragEnd={onDragEnd}
		/>
	);
};
