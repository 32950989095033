import React from 'react';
import {
	useProjectOnboardedAtTimestamp,
	useProjectTemplateVersion,
} from '@atlassian/jira-polaris-common/src/controllers/project/selectors/project-hooks.tsx';
import {
	useCurrentViewTitle,
	useGetCurrentView,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { getIsViewGeneratedByTemplate } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view/index.tsx';
import { AsyncPolarisQuickStartViewVideo } from '@atlassian/jira-polaris-lib-quick-start-view-video/src/index.tsx';
import {
	MountEvent,
	fireTrackAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { titleToSupportedQuickstartViewKey } from './titleToSupportedQuickstartViewKey.tsx';

const useGetQuickstartViewKeyForCurrentView = () => {
	const viewTitle = useCurrentViewTitle();

	if (!viewTitle) return undefined;
	return titleToSupportedQuickstartViewKey[viewTitle.trim()];
};

export const useIsCurrentViewGeneratedByTemplate = () => {
	const currentView = useGetCurrentView();
	const projectOnboardedAt = useProjectOnboardedAtTimestamp();

	if (!currentView) return false;

	if (!getIsViewGeneratedByTemplate(currentView, projectOnboardedAt)) return false;

	return true;
};

const useCheckOnboardingExperimentEnabledWithAaExposure = (
	quickstartViewKey: string | undefined,
) => {
	const projectTemplateVersion = useProjectTemplateVersion();
	const isQuickstartSupportedForCurrentView = useIsCurrentViewGeneratedByTemplate();

	if (projectTemplateVersion !== 'roadmap_v1') {
		return false;
	}

	if (!isQuickstartSupportedForCurrentView || quickstartViewKey === undefined) {
		return false;
	}

	return true;
};

export const QuickstartViewVideoContainer = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const quickstartViewKey = useGetQuickstartViewKeyForCurrentView();
	const shouldShowExperimentalVideo =
		useCheckOnboardingExperimentEnabledWithAaExposure(quickstartViewKey);

	const { atlassianAccountId } = useTenantContext();

	if (shouldShowExperimentalVideo && quickstartViewKey) {
		return (
			<>
				<MountEvent
					onMount={() => {
						fireTrackAnalytics(createAnalyticsEvent({}), 'QuickstartViewVideoContainer viewed');
					}}
				/>
				<AsyncPolarisQuickStartViewVideo
					data-testid="polaris-ideas.ui.main-content.quick-start-view-video.async-polaris-quickstart"
					viewKey={quickstartViewKey}
					accountId={atlassianAccountId}
				/>
			</>
		);
	}
	return null;
};
