import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	fields: {
		id: 'polaris-ideas.right-sidebar.fields.fields',
		defaultMessage: 'Fields',
		description: 'Header label for fields list',
	},
	searchHint: {
		id: 'polaris-ideas.right-sidebar.fields.search-hint',
		defaultMessage: 'Search all project fields',
		description: 'Search hint for fields list',
	},
});
