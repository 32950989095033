import React, { useState, useCallback } from 'react';
import { styled } from '@compiled/react';
import InlineEdit from '@atlaskit/inline-edit';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useIntervalDateDecoration } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/decoration/hooks.tsx';
import { useField } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/fields-hooks.tsx';
import {
	useSelectedIssue,
	useStringValue,
	useIsIssueArchived,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks.tsx';
import { FuzzyDatePicker } from '@atlassian/jira-polaris-common/src/ui/date-picker/index.tsx';
import { AtlasProjectTargetDate } from '@atlassian/jira-polaris-common/src/ui/fields/atlas-project-date/index.tsx';
import {
	DateFieldReadView,
	EditViewContainer,
} from '@atlassian/jira-polaris-common/src/ui/fields/date/index.tsx';
import { DeliveryDate } from '@atlassian/jira-polaris-common/src/ui/fields/delivery-date/index.tsx';
import { INTERVAL_FIELD_SOURCES } from '@atlassian/jira-polaris-domain-field/src/field/interval/index.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { JPDExperience } from '@atlassian/jira-polaris-lib-analytics/src/common/utils/experience/types.tsx';
import { useRenderDateString } from '@atlassian/jira-polaris-lib-date-time/src/index.tsx';
import type { IntervalValue } from '@atlassian/jira-polaris-lib-date-time/src/types.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { useFieldUpdate, useIsEditable } from '../../utils/index.tsx';
import { messages } from './messages.tsx';

type FuzzyDateFieldInternalProps = {
	fieldKey: string;
	interactive?: boolean;
	issueId: string;
	// eslint-disable-next-line jira/react/handler-naming
	jpdExperience?: JPDExperience | (() => JPDExperience);
};

const FuzzyDateFieldInternal = ({
	fieldKey,
	interactive,
	issueId,
	jpdExperience,
}: FuzzyDateFieldInternalProps) => {
	const { formatMessage } = useIntl();
	const [isEditing, setIsEditing] = useState(interactive || false);
	const { locale } = useTenantContext();
	const value = useStringValue(fieldKey, issueId);
	const parsedDate = value ? JSON.parse(value) : value;
	const decoration = useIntervalDateDecoration(fieldKey, parsedDate, locale);
	const isEditable = useIsEditable(issueId, fieldKey);
	const isIdeaArchived = useIsIssueArchived(issueId);
	const fieldUpdate = useFieldUpdate(issueId, fieldKey);
	const renderDateString = useRenderDateString();
	const isEditableField = isEditable && !isIdeaArchived;

	const onChange = useCallback(
		(newValue: IntervalValue | undefined) => {
			const newStringValue = newValue ? JSON.stringify(newValue) : undefined;
			if (newStringValue !== value) {
				let fieldUpdateExperience: JPDExperience | undefined;
				if (jpdExperience) {
					fieldUpdateExperience =
						typeof jpdExperience === 'function' ? jpdExperience() : jpdExperience;
					fieldUpdateExperience.start();
				}
				fieldUpdate(newStringValue, fieldUpdateExperience);
			}
			setIsEditing(false);
		},
		[fieldUpdate, jpdExperience, value],
	);

	const editView = () => (
		<EditViewContainer>
			<FuzzyDatePicker
				value={parsedDate}
				onChange={onChange}
				onClose={() => setIsEditing(false)}
				placeholder={formatMessage(messages.placeholder)}
			/>
		</EditViewContainer>
	);

	const readView = () => (
		<ReadViewContainer isEditable={isEditableField}>
			<DateFieldReadView value={renderDateString(parsedDate)} decoration={decoration} />
		</ReadViewContainer>
	);

	if (isEditableField) {
		return (
			<Container>
				<InlineEdit
					defaultValue={parsedDate}
					readViewFitContainerWidth
					// onConfirm is required, but in this case onChange is called directly from the edit view, so no need to pass it here
					// eslint-disable-next-line @typescript-eslint/no-empty-function
					onConfirm={() => {}}
					isEditing={isEditing}
					readView={readView}
					editView={editView}
					onEdit={() => setIsEditing(true)}
					hideActionButtons
				/>
			</Container>
		);
	}

	return readView();
};

type FuzzyDateFieldProps = {
	fieldKey: string;
	interactive?: boolean;
	localIssueId?: LocalIssueId;
} & Pick<FuzzyDateFieldInternalProps, 'jpdExperience'>;

export const FuzzyDateField = ({
	fieldKey,
	interactive,
	localIssueId,
	jpdExperience,
}: FuzzyDateFieldProps) => {
	const field = useField(fieldKey);
	const ideaId = useSelectedIssue();
	const issueId = localIssueId || ideaId || '';

	if (field?.configuration?.source === INTERVAL_FIELD_SOURCES.DELIVERY_DATE) {
		return (
			<DeliveryDate
				fieldKey={fieldKey}
				issueId={issueId}
				renderFallback={
					<FuzzyDateFieldInternal
						fieldKey={fieldKey}
						issueId={issueId}
						interactive={interactive}
						jpdExperience={jpdExperience}
					/>
				}
			/>
		);
	}

	if (field?.configuration?.source === INTERVAL_FIELD_SOURCES.ATLAS_PROJECT_TARGET_DATE) {
		return (
			<AtlasProjectTargetDate
				fieldKey={fieldKey}
				issueId={issueId}
				renderFallback={
					<FuzzyDateFieldInternal fieldKey={fieldKey} issueId={issueId} interactive={interactive} />
				}
			/>
		);
	}

	return <FuzzyDateFieldInternal fieldKey={fieldKey} issueId={issueId} interactive={interactive} />;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	marginTop: token('space.negative.100'),
	minWidth: '100px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ReadViewContainer = styled.div<{ isEditable?: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	cursor: ({ isEditable }) => isEditable && 'pointer',
	height: '30px',
	display: 'flex',
	alignItems: 'center',
});
