import React from 'react';
import { LinkButton } from '@atlaskit/button/new';
import { Box, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import type { IssueTypeId } from '@atlassian/jira-shared-types/src/general.tsx';
import { messages } from './messages.tsx';
import { useProject } from './project-provider.tsx';
import { getWorkflowSettingsUrl } from './utils/get-settings-url.tsx';

type Props = {
	issueTypeId: IssueTypeId;
};

export const EditWorkflowButton = ({ issueTypeId }: Props) => {
	const { projectKey } = useProject();
	const { formatMessage } = useIntl();
	return (
		<Box xcss={wrapperStyles} data-component-selector="edit-workflow-ew1">
			<LinkButton href={getWorkflowSettingsUrl({ projectKey, issueTypeId })}>
				{formatMessage(messages.editWorkflowButtonLabel)}
			</LinkButton>
		</Box>
	);
};

const wrapperStyles = xcss({
	visibility: 'hidden',
});
