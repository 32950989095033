import React, { useMemo, memo } from 'react';
import { useFieldType } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks.tsx';
import { useCurrentViewVerticalGroupBy } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import type { CellProps, GroupCellProps } from '@atlassian/jira-polaris-lib-list/src/types.tsx';
import { ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge';
import { fg } from '@atlassian/jira-feature-gating';
import { RenderCell } from './cells/main.tsx';
import { RenderGroupCell } from './group-cells/index.tsx';
import { CellTooltip } from './tooltip/index.tsx';

export const Cell = memo(({ columnId, rowId, isHovered }: CellProps) => {
	const fieldType = useFieldType(columnId);

	const analyticsData = useMemo(
		() => ({
			issueFieldKey: columnId,
			issueFieldType: fieldType,
		}),
		[columnId, fieldType],
	);

	const cell = (
		<ContextualAnalyticsData attributes={analyticsData}>
			<RenderCell fieldKey={columnId} localIssueId={rowId} key={columnId} isHovered={isHovered} />
		</ContextualAnalyticsData>
	);

	if (fg('jpd-aurora-roadmap-inline-edit')) {
		return (
			<CellTooltip columnId={columnId} rowId={rowId}>
				{cell}
			</CellTooltip>
		);
	}

	return cell;
});

Cell.displayName = 'Cell';

export const GroupCell = memo(({ columnId, isHovered, groupId }: GroupCellProps) => {
	const verticalGroupByField = useCurrentViewVerticalGroupBy();

	return (
		<RenderGroupCell
			key={columnId}
			fieldKey={columnId}
			groupId={groupId}
			groupFieldKey={verticalGroupByField?.key}
			isHovered={isHovered}
		/>
	);
});

GroupCell.displayName = 'GroupCell';
