import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import JpdMatrixBlankIllustration from '@atlassian/jira-illustrations/src/ui/adg4/jira/spots/software/components/jpd-matrix-blank/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { messages as commonMessages } from '../../../common/messages.tsx';
import { Container, Wrapper } from '../../../common/ui/container/styled.tsx';
import { ViewEmptyState } from '../../../common/ui/view-empty-state/index.tsx';
import type { ViewKindEmptyStateProps } from '../../../types.tsx';
import { messages } from './messages.tsx';

export type MatrixViewEmptyStateProps = ViewKindEmptyStateProps & {
	isXAxisActive: boolean;
	isYAxisActive: boolean;
};

export const MatrixViewEmptyState = ({
	onButtonClick,
	isHintHidden = false,
	isXAxisActive = false,
	isYAxisActive = false,
	interactionName,
}: MatrixViewEmptyStateProps) => {
	const { formatMessage } = useIntl();

	return (
		<Container>
			{!isHintHidden && (
				<Wrapper>
					<ViewEmptyState
						renderImage={JpdMatrixBlankIllustrationWrapper}
						header={formatMessage(messages.header)}
						description={formatMessage(messages.description)}
						buttonText={formatMessage(commonMessages.buttonTextFields, {
							fieldsCount: 2,
						})}
						onButtonClick={onButtonClick}
						interactionName={interactionName}
					/>
				</Wrapper>
			)}
			<MatrixViewPlaceholder isActive={isXAxisActive || isYAxisActive}>
				<AxisYPlaceholder isActive={isYAxisActive} />
				<AxisXPlaceholder isActive={isXAxisActive} />
			</MatrixViewPlaceholder>
		</Container>
	);
};

const JpdMatrixBlankIllustrationWrapper = () => <JpdMatrixBlankIllustration alt="" width={306} />;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const AxisYPlaceholder = styled.div<{ isActive: boolean }>({
	position: 'absolute',
	left: token('space.600'),
	bottom: token('space.1000'),
	width: '4px',
	height: '85%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: ({ isActive }) =>
		isActive ? token('color.background.accent.blue.subtler.pressed') : token('color.border'),
	borderRadius: '6px',

	'&::before': {
		content: 'Y-AXIS',
		position: 'absolute',
		bottom: 0,
		rotate: '-90deg',
		width: '100px',
		height: '100px',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		color: ({ isActive }) =>
			isActive
				? token('color.background.accent.blue.subtler.pressed')
				: token('color.text.disabled'),
		font: token('font.body.UNSAFE_small'),
		fontWeight: token('font.weight.semibold'),
		left: token('space.100'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const AxisXPlaceholder = styled.div<{ isActive: boolean }>({
	position: 'absolute',
	left: token('space.1000'),
	bottom: token('space.600'),
	width: '90%',
	height: '4px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: ({ isActive }) =>
		isActive ? token('color.background.accent.blue.subtler.pressed') : token('color.border'),
	borderRadius: '6px',

	'&::before': {
		content: 'X-AXIS',
		position: 'absolute',
		left: 0,
		width: '100%',
		height: '100%',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		color: ({ isActive }) =>
			isActive
				? token('color.background.accent.blue.subtler.pressed')
				: token('color.text.disabled'),
		font: token('font.body.UNSAFE_small'),
		fontWeight: token('font.weight.semibold'),
		bottom: token('space.250'),
		paddingBottom: token('space.025'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const MatrixViewPlaceholder = styled.div<{ isActive: boolean }>({
	display: 'flex',
	position: 'absolute',
	width: '100%',
	height: '100%',
	zIndex: '0',
	boxSizing: 'border-box',
	top: '0',
	left: '0',
	paddingBottom: token('space.300'),
	borderRadius: '6px',
	backgroundColor: token('color.background.input.hovered'),

	'&::before': {
		content: '',
		position: 'absolute',
		width: '100%',
		height: '100%',
		backgroundColor: token('elevation.surface'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		opacity: ({ isActive }) => (isActive ? '0' : '0.6'),
		transition: 'opacity 0.1s ease-in-out',
		zIndex: '2',
	},
});
