import React, { memo } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';

type Props = {
	value: string | undefined;
};

export const Tag = memo<Props>(({ value }: Props) => (
	<Content data-testid="polaris-ideas.ui.insights.insights.insight.properties.labels.tag.content">
		<ValueWrapper>{value}</ValueWrapper>
	</Content>
));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Content = styled.div({
	display: 'inline-flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.background.accent.gray.subtlest'),
	borderRadius: '3px',
	marginLeft: '0px',
	marginRight: token('space.050'),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginTop: '1px',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginBottom: '1px',
	maxWidth: '100%',
	transition: '0.3s',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ValueWrapper = styled.div({
	paddingLeft: token('space.050'),
	paddingRight: token('space.050'),
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	maxWidth: '250px',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: '20px',
});
