import React, { type ReactNode, useState, useCallback } from 'react';

import { PolarisInlineDialog } from '@atlassian/jira-polaris-lib-inline-dialog/src/ui/index.tsx';

type DialogProps = {
	content: ReactNode;
	initiallyOpen: boolean;
	isDisabled: boolean;
	children: (props: { toggleDialog: () => void; dialogOpen: boolean }) => ReactNode;
};

export const Dialog = ({ content, initiallyOpen, children }: DialogProps) => {
	const [dialogOpen, setDialogOpen] = useState(initiallyOpen);
	const toggleDialog = useCallback(() => setDialogOpen((prev) => !prev), []);

	return (
		<PolarisInlineDialog
			messageId="polaris-common.ui.filters.filter-component.filters.connection-filter.polaris-inline-dialog"
			messageType="transactional"
			noPadding
			onClose={() => {
				setDialogOpen(false);
			}}
			isOpen={dialogOpen}
			placement="bottom-start"
			content={content}
		>
			{children({ toggleDialog, dialogOpen })}
		</PolarisInlineDialog>
	);
};
