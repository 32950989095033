import { createSelector } from 'reselect';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { Team, TeamValue } from '@atlassian/jira-issue-field-team/src/common/types.tsx';
import type { PropertyMap } from '../types.tsx';
import { getFields } from './fields.tsx';
import { getRankedIssueIds } from './issue-ids.tsx';
import { getTeamProperties } from './properties/index.tsx';

export type TeamInformation = {
	[key: string]: TeamValue;
};

const getTeamField = createSelector(getFields, (fields: Field[]): Field | undefined => {
	return fields.find((field) => field.type === FIELD_TYPES.TEAM);
});

const getTeamInformation = createSelector(
	getRankedIssueIds,
	getTeamProperties,
	getTeamField,
	(
		allIssues: LocalIssueId[],
		teamProperties: PropertyMap<TeamValue>,
		field?: Field,
	): TeamInformation => {
		if (!field) {
			return {};
		}

		const teamInformation: Record<string, Team> = {};
		allIssues.forEach((issueId) => {
			const team = teamProperties[field.key]?.[issueId];
			if (team) {
				teamInformation[team.id] = team;
			}
		});
		return teamInformation;
	},
);

export const getAllTeamInformationOnIssues = createSelector(
	getTeamInformation,
	(teams: TeamInformation): TeamInformation => teams,
);
