import React, { memo, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import {
	attachClosestEdge,
	extractClosestEdge,
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import type { Edge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/types';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import {
	draggable,
	dropTargetForElements,
	monitorForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { preserveOffsetOnSource } from '@atlaskit/pragmatic-drag-and-drop/element/preserve-offset-on-source';
import { setCustomNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview';
import { token } from '@atlaskit/tokens';

import { fg } from '@atlassian/jira-feature-gating';
import { BOARD_CARD_HORIZONTAL_MARGIN } from '../../../constants.tsx';
import type { DraggableCardProps } from '../../../types/custom-components.tsx';
import {
	type DragState,
	type DraggableCardData,
	isDraggableCardData,
} from '../../../types/draggable.tsx';

export const DraggableCard = memo((props: DraggableCardProps) => {
	const {
		ideaId,
		draggableId,
		droppableId,
		index,
		canDrag,
		canDrop = true,
		onDrop = noop,
		initialDragStatus = 'idle',
		cardComponent: Card,
		onDragStart = noop,
	} = props;
	const cardRef = useRef<HTMLDivElement>(null);
	const [closestEdge, setClosestEdge] = useState<Edge | null>(null);
	const [dragStatus, setDragStatus] = useState<DragState>(initialDragStatus);
	const [previewContainer, setPreviewContainer] = useState<HTMLElement | null>(null);

	useEffect(() => {
		if (!cardRef.current || !canDrag) {
			return undefined;
		}

		const data: DraggableCardData = {
			ideaId,
			draggableId,
			droppableId,
			index,
			type: canDrop || !fg('jpd-aurora-roadmap-inline-edit') ? 'CARD' : 'CARD_NO_DROP',
		};

		const cleanupDragAndDrop = combine(
			draggable({
				element: cardRef.current,
				onGenerateDragPreview: ({ nativeSetDragImage, source, location }) => {
					setDragStatus('preview');
					setCustomNativeDragPreview({
						getOffset: preserveOffsetOnSource({
							element: source.element,
							input: location.current.input,
						}),
						render: ({ container }) => {
							setPreviewContainer(container);
							return () => {
								setPreviewContainer(null);
							};
						},
						nativeSetDragImage,
					});
				},
				getInitialData() {
					return data;
				},
				onDragStart() {
					setDragStatus('dragging');
					onDragStart(ideaId);
				},
				onDrop() {
					// the setTimeout prevents a flickering before the card is moved to another location
					setTimeout(() => setDragStatus('idle'));
					onDrop(ideaId);
				},
			}),
			dropTargetForElements({
				element: cardRef.current,
				canDrop({ source }) {
					return isDraggableCardData(source.data);
				},
				getData({ input, element }) {
					return attachClosestEdge(data, {
						input,
						element,
						allowedEdges: ['top', 'bottom'],
					});
				},
				onDrag({ source, self }) {
					if (!isDraggableCardData(source.data)) {
						return;
					}

					if (source.data.draggableId !== draggableId) {
						const dropEdge = extractClosestEdge(self.data);
						if (closestEdge !== dropEdge) {
							setClosestEdge(dropEdge);
						}
					}
				},
				onDragLeave() {
					setClosestEdge(null);
				},
				onDrop() {
					setClosestEdge(null);
				},
			}),
		);

		return () => {
			cleanupDragAndDrop?.();
		};
	}, [ideaId, draggableId, closestEdge, canDrag, droppableId, index, onDragStart, canDrop, onDrop]);

	// since cards are virtualized, the drag source card may be unmounted during a drag.
	// because of this, onDrop will not be triggered in the "draggable" api above.
	// therefore, we need to monitor all card drop events globally
	useEffect(() => {
		const cleanupDragAndDrop = monitorForElements({
			canMonitor: ({ source }) => source.data.type === 'CARD',
			onDrop: ({ source }) => {
				const sourceIdeaId = String(source.data.ideaId);

				if (sourceIdeaId !== ideaId) {
					return;
				}

				// the setTimeout prevents a flickering before the card is moved to another location
				setTimeout(() => setDragStatus('idle'));
			},
		});

		return cleanupDragAndDrop;
	}, [ideaId]);

	return (
		<>
			<CardWrapper
				ref={cardRef}
				data-testid="polaris-lib-board.ui.card-list.draggable-card.card"
				data-ideaid={ideaId}
				closestEdge={closestEdge}
				dragStatus={dragStatus}
			>
				<Card id={ideaId} dragStatus={dragStatus} isPreview={false} />
				<CardSeparator />
			</CardWrapper>
			{previewContainer
				? ReactDOM.createPortal(
						<Card id={ideaId} dragStatus={dragStatus} isPreview />,
						previewContainer,
					)
				: null}
		</>
	);
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CardWrapper = styled.div<{
	closestEdge: Edge | null;
	dragStatus: string;
}>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	opacity: ({ dragStatus }) => dragStatus === 'dragging' && 0.3,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:before': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		display: ({ dragStatus, closestEdge }) => (!closestEdge || dragStatus !== 'idle') && 'none',
		content: '',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		width: `calc(100% - ${BOARD_CARD_HORIZONTAL_MARGIN * 2}px)`,
		height: '2px',
		position: 'absolute',
		zIndex: 1,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		left: `${BOARD_CARD_HORIZONTAL_MARGIN}px`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		top: ({ closestEdge }) => (closestEdge === 'top' ? '-5px' : 'auto'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		bottom: ({ closestEdge }) => (closestEdge === 'bottom' ? '3px' : 'auto'),
		backgroundColor: token('color.border.brand'),
	},
	// necessary for the cards box shadow to show correctly
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	marginLeft: `${BOARD_CARD_HORIZONTAL_MARGIN}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	marginRight: `${BOARD_CARD_HORIZONTAL_MARGIN}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CardSeparator = styled.div({
	width: '100%',
	height: '8px',
});
