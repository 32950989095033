// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { createErrorAnalytics } from '@atlassian/jira-polaris-lib-errors/src/controllers/index.tsx';
import { asyncValues } from '@atlassian/jira-polaris-lib-ts-utils/src/async-value.tsx';
import { logSafeErrorWithoutCustomerDataWrapper } from '@atlassian/jira-polaris-lib-errors/src/common/utils/index.tsx';
import type { Props, State } from '../../../common/types.tsx';
import { getProductSettings } from '../../../services/get-product-settings.tsx';

export const loadProductSettings =
	(): Action<State, Props, Promise<void>> =>
	async ({ setState, getState }) => {
		const productSettingsState = getState().productSettings;

		if (productSettingsState.status === 'loaded') {
			return;
		}

		try {
			const productSettings = await getProductSettings();

			setState({
				productSettings: asyncValues.loaded(productSettings),
			});
		} catch (error) {
			if (error instanceof Error) {
				fireErrorAnalytics(createErrorAnalytics('polaris.error.fetchProductSettingsFailed', error));
			}

			logSafeErrorWithoutCustomerDataWrapper(
				'polaris.error.controllers.product-settings.load',
				'Failed to load product settings',
				error instanceof Error ? error : new Error(String(error)),
			);

			setState({
				productSettings: {
					status: 'failed',
					error: error instanceof Error ? error : new Error(String(error)),
				},
			});

			throw error;
		}
	};
