import React, { useState, useEffect } from 'react';
import { styled } from '@compiled/react';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { createAri, type Ari } from '@atlassian/jira-platform-ari/src/index.tsx';
import {
	useProjectIdUnsafe,
	useProjectKeyUnsafe,
} from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import { useCanAddPlayContributions } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import type { CommentUser } from '@atlassian/jira-polaris-domain-comment/src/index.tsx';
import type {
	PolarisPlayContributionComment,
	PolarisPlay,
	PolarisPlayContribution,
} from '@atlassian/jira-polaris-domain-field/src/play/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { AdfController } from '@atlassian/jira-polaris-lib-editor/src/controllers/adf/main.tsx';
import { useMediaRemote } from '@atlassian/jira-polaris-remote-media/src/controllers/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { isClientFetchError } from '@atlassian/jira-fetch/src/index.tsx';
import { isPermissionError } from '@atlassian/jira-polaris-lib-errors/src/controllers/utils.tsx';
import { useIssueActions } from '../../../controllers/issue/main.tsx';
import {
	useIssueKey,
	useSafeJiraIssueId,
} from '../../../controllers/issue/selectors/properties/hooks.tsx';
import {
	useGetIdeaPlayContributions,
	useGetIdeaPlayUserContribution,
} from '../../../controllers/issue/selectors/properties/plays/hooks.tsx';
import { useProjectActions } from '../../../controllers/project/main.tsx';
import {
	useGetPlayById,
	useTotalPlayContributionsAmountByUser,
} from '../../../controllers/project/selectors/plays-hooks.tsx';
import {
	useCurrentUserAccountId,
	useCurrentUserAvatar,
	useCurrentUserDisplayName,
} from '../../../controllers/user/index.tsx';
import { AddPlayContributionComponent } from '../add-contribution/index.tsx';
import { DEFAULT_MAX_SPEND_PER_CONTRIBUTION } from '../constants.tsx';
import { PlayContributionComponent } from '../contribution/index.tsx';
import { NoContributionssComponent } from '../no-contributions/index.tsx';
import { generateLocalContribution } from '../utils.tsx';
import messages from './messages.tsx';

type IdeaPlayContributionProps = {
	comment: PolarisPlayContributionComment;
	votes: number;
	author: CommentUser;
};

const IdeaPlayContribution = ({ comment, votes, author }: IdeaPlayContributionProps) => (
	<PlayContributionComponent comment={comment} votes={votes} author={author} />
);

type Props = {
	localIssueId: LocalIssueId;
	playId: Ari;
	onDirty: (_: boolean) => void;
};

export const IdeaPlayContributionsStream = ({ localIssueId, playId, onDirty }: Props) => {
	const { formatMessage } = useIntl();
	const { createPlayContribution, deletePlayContribution, updatePlayContribution } =
		useIssueActions();
	const projectActions = useProjectActions();
	const projectId = useProjectIdUnsafe();
	const projectKey = useProjectKeyUnsafe();
	const { fetchMediaContext } = useMediaRemote();
	const cloudId = useCloudId();
	const issueKey = useIssueKey(localIssueId || '');
	const jiraIssueId = useSafeJiraIssueId(localIssueId);
	const canAddPlayContributions = useCanAddPlayContributions();

	useEffect(() => {
		if (!playId || !jiraIssueId) {
			return;
		}
		projectActions.loadPlayContributions(jiraIssueId, playId);
	}, [projectActions, jiraIssueId, playId]);

	const play: PolarisPlay | undefined = useGetPlayById(playId);
	const maxSpendPerPlay = play?.parameters?.maxSpend ?? DEFAULT_MAX_SPEND_PER_CONTRIBUTION;

	const issueAri = createAri({
		resourceOwner: 'jira',
		cloudId,
		resourceType: 'issue',
		resourceId: jiraIssueId ?? '',
	});

	const contributions = useGetIdeaPlayContributions(playId, localIssueId);

	const curUserAccountId = useCurrentUserAccountId();
	const curUserAvatar = useCurrentUserAvatar();
	const curUserDisplayName = useCurrentUserDisplayName();
	const curUserContribution = useGetIdeaPlayUserContribution(
		playId,
		localIssueId,
		curUserAccountId,
	);

	// Store and update contribution locally for optimistic update
	const [localUserContribution, setLocalUserContribution] = useState(curUserContribution);
	useEffect(() => {
		setLocalUserContribution(curUserContribution);
	}, [curUserContribution]);

	const totalContributionsByUser = useTotalPlayContributionsAmountByUser(playId, curUserAccountId);

	const otherContributions = contributions.filter(({ id }) => curUserContribution?.id !== id);

	const onSave = (comment: ADF, amount: number) => {
		if (localIssueId === undefined || comment.content.length === 0) {
			return Promise.resolve(false);
		}

		// Since the `project` is the source of truth for `plays` (it manages also play contributions)
		// we need to make sure that it stays up to date with whatever state we manage in play stream.
		// Otherwise any time the `plays` state changes upstream the local one will get overriden,
		// effectively causing and contributions (votes and comments):
		// - created since last page refresh to disappear
		// - updated since last page refresh to get reverted to previous values
		//
		// Without this sync, the problem can be observed when after creating/updating contribution
		// user navigates to any other existing one, effectively opening the sidebar, which performs
		// "optimistic refresh" of plays - fetches only one of them, then updates the whole collection
		// and forces new state on play-stream. If that collection is out of sync with any recent modifications
		// we did here, we lose the data from the view (they are persisted on the BE though so
		// page-refresh brings them back).
		//
		// Therefore we use `syncToProject` after each successful create/update action.
		const syncToProject = (contribution: PolarisPlayContribution | undefined) => {
			if (contribution === undefined) {
				return;
			}
			projectActions.upsertPlayContribution(playId, contribution);
		};

		experience.playSidepanel.updatePlayField.start();

		if (curUserContribution) {
			const updatePromise = updatePlayContribution(
				curUserContribution.id,
				{
					amount,
					content: comment,
					comment: curUserContribution.comment?.id,
					play: playId,
					subject: issueAri,
				},
				experience.playSidepanel.updatePlayField.success,
				(error) =>
					isClientFetchError(error) || (error && isPermissionError(error))
						? experience.playSidepanel.updatePlayField.abort(error)
						: experience.playSidepanel.updatePlayField.failure(error),
			);

			updatePromise.then(syncToProject);
		} else {
			if (curUserAccountId !== null) {
				const optimisticContribution = generateLocalContribution({
					amount,
					comment,
					cloudId,
					subject: issueAri,
					user: {
						id: curUserAccountId,
						name: curUserDisplayName,
						avatarUrl: curUserAvatar,
					},
				});
				// Optimistically set:
				setLocalUserContribution(() => optimisticContribution);
			}
			const creationPromise = createPlayContribution(
				{
					amount,
					comment,
					play: playId,
					subject: issueAri,
				},
				experience.playSidepanel.updatePlayField.success,
				(error) =>
					isClientFetchError(error) || (error && isPermissionError(error))
						? experience.playSidepanel.updatePlayField.abort(error)
						: experience.playSidepanel.updatePlayField.failure(error),
			);

			creationPromise.then(syncToProject);
		}
		return Promise.resolve(true);
	};

	const onDelete = (contributionId: Ari) => {
		if (localIssueId === undefined) {
			return Promise.resolve(false);
		}

		deletePlayContribution(contributionId, {
			play: playId,
			subject: issueAri,
		});
		// TODO Here we can see that deleting play contributions locally is also propagated to the projects container
		//      which is the upstream source of truth for plays. We miss this for update/create part.
		projectActions.deletePlayContribution(playId, contributionId);
		return Promise.resolve(true);
	};

	if (jiraIssueId === undefined) {
		return null;
	}

	return (
		<AdfController
			issueKey={issueKey}
			projectId={projectId}
			projectKey={projectKey}
			getMediaContext={fetchMediaContext}
		>
			<ContributionsContainer data-testid="polaris-common.ui.plays.play-stream.contributions-container">
				{canAddPlayContributions && (
					<ContributionsActionsContainer key={localIssueId}>
						<AddPlayContributionComponent
							playId={playId}
							localIssueId={localIssueId}
							key={localUserContribution?.id}
							onSave={onSave}
							onDelete={onDelete}
							onDirty={onDirty}
							contribution={localUserContribution}
							totalContributionsByUser={totalContributionsByUser}
							maxSpendPerPlay={maxSpendPerPlay}
						/>
					</ContributionsActionsContainer>
				)}
				<ContributionsStreamContainer data-testid="polaris-common.ui.plays.play-stream.contributions-stream-container">
					{otherContributions.length > 0 && (
						<ContributionsStreamHeader>
							{formatMessage(messages.contributionsStreamHeader)}
						</ContributionsStreamHeader>
					)}
					{[...otherContributions]
						.reverse()
						.map(
							({ id, comment, author, amount = 0 }) =>
								comment && (
									<IdeaPlayContribution key={id} comment={comment} votes={amount} author={author} />
								),
						)}
					{!otherContributions.length ? (
						<NoContributionssComponent
							hasCurrentUserContribution={localUserContribution !== undefined}
						/>
					) : null}
				</ContributionsStreamContainer>
			</ContributionsContainer>
		</AdfController>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContributionsContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
	boxSizing: 'border-box',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContributionsStreamContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
	flex: '1 1 auto',
	boxSizing: 'border-box',
	marginTop: token('space.200'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContributionsActionsContainer = styled.div({
	flex: '0 0 auto',
	boxSizing: 'border-box',
	boxShadow: 'inset 0px -1px 0px rgba(9, 30, 66, 0.13)',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContributionsStreamHeader = styled.div({
	color: token('color.text.subtle'),
	font: token('font.heading.xxsmall'),
	marginTop: token('space.100'),
});
