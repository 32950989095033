import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { AccessLevel } from '@atlassian/jira-shared-types/src/project.tsx';
import type { SetProjectAccessLevelResponse } from './types.tsx';

const getUrl = (projectId: string) => `/rest/internal/simplified/1.0/projects/${projectId}/summary`;

export const setProjectAccessLevel = (
	accessLevel: AccessLevel,
	projectId: string,
	projectKey: string,
): Promise<SetProjectAccessLevelResponse> =>
	performPutRequest(getUrl(projectId), {
		body: JSON.stringify({
			accessLevel,
			key: projectKey,
		}),
	});
