/** @jsx jsx */
import React, { useCallback, useState } from 'react';
import { css, jsx } from '@compiled/react';
import Button from '@atlaskit/button/new';
import Tooltip from '@atlaskit/tooltip';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useCanEditFields } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { useIsConnectionFieldFilterConfigured } from '../../../controllers/field/selectors/field-hooks.tsx';
import { useIsIssueMatchingConnectionFieldFilter } from '../../../controllers/issue/selectors/connection-hooks.tsx';
import { useOpenRightSidebarOnField } from '../../../controllers/right-sidebar/actions/hooks.tsx';
import { ConnectionFieldEditView } from './edit/index.tsx';
import messages from './messages.tsx';
import type { ConnectionFieldProps } from './types.tsx';
import { ConnectionFieldView } from './view/index.tsx';
import { useSiteIssues } from './utils.tsx';

export const ConnectionField = (props: ConnectionFieldProps) => {
	const { fieldKey, localIssueId, isEditable } = props;
	const { formatMessage } = useIntl();
	const { siteIssues, appliedIssueNameSearch, loadSiteIssues, debouncedLoadSiteIssues } =
		useSiteIssues({ fieldKey });
	const [isEditing, setIsEditing] = useState(false);
	const isMatchingConnectionFieldFilter = useIsIssueMatchingConnectionFieldFilter(
		localIssueId,
		fieldKey,
	);
	const isConnectionFieldFilterConfigured = useIsConnectionFieldFilterConfigured(fieldKey);
	const canEditFields = useCanEditFields();
	const openRightSidebarOnField = useOpenRightSidebarOnField();

	const startEditing = useCallback(
		async (event: React.MouseEvent<HTMLElement>) => {
			event.stopPropagation();
			setIsEditing(true);
			loadSiteIssues();
		},
		[loadSiteIssues],
	);

	// don't allow connecting issues of the same type
	if (isMatchingConnectionFieldFilter) {
		if (isEditable) {
			return (
				<Tooltip content={formatMessage(messages.cantConnectToTheSameIssueTypeTooltip)}>
					{(tooltipProps) => <div {...tooltipProps} css={emptyBoxStyles} />}
				</Tooltip>
			);
		}

		return null;
	}

	if (!isConnectionFieldFilterConfigured) {
		return (
			canEditFields && (
				<Button
					onClick={() => openRightSidebarOnField(fieldKey)}
					appearance="subtle"
					interactionName="jpd.right-sidebar.field-edit"
				>
					{formatMessage(messages.configureFieldButtonLabelNonFinal)}
				</Button>
			)
		);
	}

	return isEditing && isEditable ? (
		<ConnectionFieldEditView
			{...props}
			onClose={() => setIsEditing(false)}
			onInputChange={debouncedLoadSiteIssues}
			siteIssues={siteIssues}
			appliedIssueNameSearch={appliedIssueNameSearch}
		/>
	) : (
		<ConnectionFieldView {...props} onEdit={startEditing} />
	);
};

const emptyBoxStyles = css({
	height: token('space.400'),
});
