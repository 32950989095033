// TODO: go/restrict-enums

import type { Locale } from '@atlassian/jira-common-constants/src/supported-locales.tsx';
import type { ApplicationEdition } from '@atlassian/jira-shared-types/src/edition.tsx';
import type {
	AccountId,
	ProjectId,
	ProjectKey,
	FormatMessage,
	GroupUuid,
} from '@atlassian/jira-shared-types/src/general.tsx';
import type { AccessLevel } from '@atlassian/jira-shared-types/src/project.tsx';

type RemoteProperty = {
	isLoading: boolean;
	error: Error | null;
	value: string | undefined | boolean;
};

export type Props = {
	projectId: ProjectId;
	projectKey: ProjectKey;
	projectName: string;
	cloudName: string;
	isAdmin: boolean;
	isSiteAdmin: boolean;
	hasProjectAccess: boolean;
	isSelfServeEnabledOnSite: boolean;
	issueCreationEnabled: RemoteProperty;
	accountId: AccountId | null;
	locale: Locale;
	formatMessage?: FormatMessage;
	isBeta: boolean | undefined;
	edition: ApplicationEdition;
	enforcePermissionChecks: boolean;
	breadcrumbs?: React.ReactNode;
	onError: (error: Error) => void;
	onToggleEnforcePermissionChecks: () => void;
	onRouteToFormSubmissionRequested?: () => void;
	onIssueCreationChanged: (isEnabled: boolean) => void;
	onProjectAccessLevelChanged?: (accessLevel: AccessLevel) => void;
};

export type ProjectAccessLevel = {
	value: AccessLevel;
	iconName: string;
	displayName: string;
	description: string;
};

export type PermissionCategoryKey = string;

type PermissionCategory = {
	key: PermissionCategoryKey;
	name: string;
	description: string;
};

export type PermissionKey = string;

type Permission = {
	key: PermissionKey;
	name: string;
	description: string;
	category?: string;
	requiredPermissions: string[];
};

export type RoleConfigurationId = string;
export type RoleId = number;

// TODO: go/restrict-enums
// eslint-disable-next-line no-restricted-syntax
export enum RoleOperations {
	DELETE = 'DELETE',
	MANAGE_MEMBERS = 'MANAGE_MEMBERS',
	EDIT = 'EDIT',
	VIEW = 'VIEW',
}

// TODO: go/restrict-enums
// eslint-disable-next-line no-restricted-syntax
export enum RoleOrigin {
	CUSTOM = 'CUSTOM',
	PROJECT_PRESET = 'PROJECT_PRESET',
}

export type Role = {
	id: RoleId;
	name: string;
	translatedName: string;
	description: string;
	roleConfigurationId: RoleConfigurationId;
	roleOrigin: RoleOrigin;
	permissions: PermissionKey[];
	allowedOperations: RoleOperations[];
};

export type ProjectActorsFilter = {
	orderBy: {
		field: string;
		direction: string;
	};
	roles?: {
		ids: number[];
	};
	search?: {
		contains: string;
	};
};

export const USER_ROLE_ACTOR = 'atlassian-user-role-actor' as const;
export const GROUP_ROLE_ACTOR = 'atlassian-group-role-actor' as const;
export const IMPLICIT_ROLE_ACTOR_CREATOR = 'implicit-role-actor-creator' as const;
export const IMPLICIT_ROLE_ACTOR_CONTRIBUTOR = 'implicit-role-actor-contributor' as const;

export type ActorType =
	| typeof USER_ROLE_ACTOR
	| typeof GROUP_ROLE_ACTOR
	| typeof IMPLICIT_ROLE_ACTOR_CREATOR
	| typeof IMPLICIT_ROLE_ACTOR_CONTRIBUTOR;

export type Actor = {
	email: string | null;
	avatarUrl: string | null;
	active: boolean;
	accountId: string;
	roleTypeId: string;
	type: ActorType;
	displayName: string;
	roles: number[];
};

type CreatorsList = {
	creators: Record<string, boolean>;
	creatorGroups: Record<string, boolean>;
	creatorCount: number;
};

export type ProjectActorsData = {
	actors: Actor[];
	isLastBatch: boolean;
};

export type State = {
	accessLevel?: ProjectAccessLevel;
	availableAccessLevels?: ProjectAccessLevel[];
	permissions?: Permission[];
	roles?: Role[];
	categories?: PermissionCategory[];
	contributorPermissions?: PermissionKey[];
	creators?: CreatorsList;
	hasContributorAccess?: Record<string, boolean>;
	hasManuallyAddedContributorAccess?: Record<GroupUuid, boolean>;
	projectActors: {
		filter: ProjectActorsFilter;
		data?: ProjectActorsData;
	};
	contributorGroupEnabled?: boolean;
	/**
	 * @deprecated use contributorGroupNames
	 */
	contributorGroupName?: string;
	contributorGroupIds?: GroupUuid[];
	contributorGroupId?: string;
	loadingProps?: {
		projectId: ProjectId;
		projectKey: string;
		isSelfServeEnabledOnSite: boolean;
		issueCreationEnabled: RemoteProperty;
		projectName: string;
		cloudName: string;
		isAdmin: boolean;
		isSiteAdmin: boolean;
		hasProjectAccess: boolean;
		accountId: AccountId | null;
		locale: Locale;
		isBeta: boolean | undefined;
		edition: ApplicationEdition;
		enforcePermissionChecks: boolean;
		onIssueCreationChanged: (isEnabled: boolean) => void;
		onRouteToFormSubmissionRequested?: () => void;
	};
	ui: {
		manageRoles: {
			isOpen: boolean;
			isPersistingInProgress: boolean;
		};
		projectActors: {
			isLoading: boolean;
		};
		accessLevel: {
			isOpen: boolean;
			isChangeInProgress: boolean;
		};
	};
};
