import React from 'react';
import LikeIcon from '@atlaskit/icon/core/migration/thumbs-up--like';
import { useIntl } from '@atlassian/jira-intl';
import { ButtonWithTooltip } from '@atlassian/jira-polaris-lib-button-with-tooltip/src/ui/index.tsx';
import messages from '../messages.tsx';

type SelectProps = {
	isDisabled: boolean;
	isArchived: boolean;
	value: number;
	onClick: () => void;
};

export const VoteButton = ({ isDisabled, value, isArchived, onClick }: SelectProps) => {
	const { formatMessage } = useIntl();
	const tooltipText = isArchived && isDisabled ? formatMessage(messages.archivedIdea) : undefined;

	return (
		<ButtonWithTooltip
			isDisabled={isDisabled}
			tooltipProps={{
				content: tooltipText,
			}}
			iconBefore={<LikeIcon LEGACY_size="medium" spacing="spacious" label="" />}
			onClick={onClick}
		>
			{value}
		</ButtonWithTooltip>
	);
};
