// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { Props, State } from '../types.tsx';
import { setMergeFromOption } from './merge-from/index.tsx';
import { mergeIdeas } from './merge/index.tsx';
import { setTargetIssue } from './target-issue.tsx';
import { toggleMergeIdeasDialog } from './toggle-dialog/index.tsx';
import { toggleMergeOption } from './toggle-merge-option/index.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default {
	toggleMergeIdeasDialog,
	setMergeFromOption,
	setTargetIssue,
	mergeIdeas,
	toggleMergeOption,
	getContainerProps: () => (ops: StoreActionApi<State>, props: Props) => props,
};
