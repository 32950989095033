import {
	useEnvironmentContainer,
	PolarisEnvironmentContainerTypes,
} from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import type { FieldValueFilter } from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useProjectsIdsForContainer } from '@atlassian/jira-polaris-component-project-metadata/src/controllers/index.tsx';
import { useEnvironmentContainerId } from '@atlassian/jira-polaris-component-environment-container/src/controllers/store/index.tsx';
import { functionWithCondition } from '@atlassian/jira-feature-flagging-utils';
import {
	useIssueTypeValuesForProject,
	useIssueTypeValuesForProjectIds,
	useIssueTypesForProjectIds,
} from './controllers/index.tsx';

export const useFilteredIssueTypeValues = ({ filter }: { filter: FieldValueFilter }) => {
	const container = useEnvironmentContainer();
	const projectId =
		container?.type === PolarisEnvironmentContainerTypes.PROJECT ? container.projectId : undefined;
	const issueTypesValues = useIssueTypeValuesForProject({ projectId });

	if (!filter.values.length) {
		return issueTypesValues;
	}

	if (fg('jpd_view_config_using_issue_type_name')) {
		return issueTypesValues.filter(({ name }) =>
			filter.values.some(({ stringValue }) => stringValue === name),
		);
	}

	return issueTypesValues.filter(({ id }) =>
		filter.values.some(({ stringValue }) => stringValue === id),
	);
};

const useIssueTypesForCurrentContainerProjectIdsInternal = () => {
	const containerId = useEnvironmentContainerId();
	const projectIds = useProjectsIdsForContainer({ containerId });
	const issueTypes = useIssueTypesForProjectIds({ projectIds });

	return issueTypes;
};

export const useIssueTypesForCurrentContainerProjectIds = functionWithCondition<
	typeof useIssueTypesForCurrentContainerProjectIdsInternal
>(
	() => fg('jpd_view_config_using_issue_type_name'),
	useIssueTypesForCurrentContainerProjectIdsInternal,
	() => [],
);

const useIssueTypesValuesForCurrentContainerProjectIdsInternal = () => {
	const containerId = useEnvironmentContainerId();
	const projectIds = useProjectsIdsForContainer({ containerId });
	const issueTypes = useIssueTypeValuesForProjectIds({ projectIds });

	return issueTypes;
};

export const useIssueTypesValuesForCurrentContainerProjectIds = functionWithCondition<
	typeof useIssueTypesValuesForCurrentContainerProjectIdsInternal
>(
	() => fg('jpd_view_config_using_issue_type_name'),
	useIssueTypesValuesForCurrentContainerProjectIdsInternal,
	() => [],
);
