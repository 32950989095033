/** @jsx jsx */
import React, {
	type SyntheticEvent,
	useCallback,
	useState,
	useEffect,
	forwardRef,
	useImperativeHandle,
} from 'react';
import { css, jsx } from '@compiled/react';
import differenceBy from 'lodash/differenceBy';
import Badge from '@atlaskit/badge';
import { LoadingButton } from '@atlaskit/button';
import { Box, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import AKUserPicker, {
	type OptionData,
	type Value as UserPickerValue,
	EmailType,
} from '@atlaskit/user-picker';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { sendPendoTrackEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/pendo/index.tsx';
import {
	fireUIAnalytics,
	useAnalyticsEvents,
	type UIAnalyticsEvent,
} from '@atlassian/jira-product-analytics-bridge';
import { isValidEmail } from '../common/utils.tsx';
import messages from './messages.tsx';

type InputProps = {
	value: OptionData[];
	isReadOnly: boolean;
	allowEmail: boolean;
	analyticsSourceId?: string;
	maxPickerHeight?: number;
	onFocus: () => void;
	onBlur: () => void;
	onChange: (value: OptionData[]) => void;
	onUserFetch?: (query: string | undefined) => Promise<OptionData[]>;
	onMenuOpen?: () => void;
	onMenuClose?: () => void;
};

export type UserPickerApi = {
	focus: () => void;
};

export const DIALOG_SEARCH_INPUT = 'jpd-user-picker-search-input';
const EMAIL_SEPARATOR = /[,;\n]+/;

const Input = forwardRef<UserPickerApi, InputProps>(
	(
		{
			value,
			isReadOnly,
			allowEmail,
			analyticsSourceId,
			maxPickerHeight = 56,
			onFocus,
			onBlur,
			onChange,
			onUserFetch,
			onMenuOpen,
			onMenuClose,
		},
		ref,
	) => {
		const [inputText, setInputText] = useState<string | undefined>('');
		const { formatMessage } = useIntl();
		const { createAnalyticsEvent } = useAnalyticsEvents();

		useImperativeHandle(
			ref,
			() => ({
				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				focus: () => document.querySelector<HTMLInputElement>(`#${DIALOG_SEARCH_INPUT}`)?.focus(),
			}),
			[],
		);

		const pasteHandler = useCallback(
			(event: ClipboardEvent) => {
				event.preventDefault();
				if (event.clipboardData === null) {
					return;
				}

				const pastedEmails = event.clipboardData.getData('text').split(EMAIL_SEPARATOR);
				const validEmailsObjects: OptionData[] = [];
				let invalidEmailsInput = '';

				pastedEmails.forEach((email: string) => {
					const trimmedEmail = email.trim();

					if (isValidEmail(trimmedEmail) === 'VALID') {
						validEmailsObjects.push({
							id: trimmedEmail,
							name: trimmedEmail,
							type: EmailType,
						});
					} else {
						invalidEmailsInput += (invalidEmailsInput ? ', ' : '') + trimmedEmail;
					}
				});

				setInputText(inputText + invalidEmailsInput);

				if (validEmailsObjects.length > 0) {
					const filteredValidEmailsObjects = validEmailsObjects.filter(
						({ id }) => !(value || []).some((user) => user.id === id),
					);

					const pastedValidEmails = [...(value || []), ...filteredValidEmailsObjects];

					const analyticsAttributes = {
						emailCount: pastedValidEmails.length,
					};
					fireUIAnalytics(createAnalyticsEvent({}), 'stakeholdersList pasted', analyticsAttributes);
					sendPendoTrackEvent({
						source: analyticsSourceId,
						actionSubjectAndAction: 'stakeholdersList pasted',
						attributes: analyticsAttributes,
					});

					onChange(pastedValidEmails);
				}
			},
			[inputText, value, createAnalyticsEvent, analyticsSourceId, onChange],
		);

		useEffect(() => {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, jira/jira-ssr/no-unchecked-globals-usage
			const inputElement = document.querySelector(`#${DIALOG_SEARCH_INPUT}`) as HTMLElement;

			inputElement?.addEventListener('paste', pasteHandler);
			return () => {
				inputElement?.removeEventListener('paste', pasteHandler);
			};
		}, [pasteHandler, isReadOnly]);

		const onOptionSelectionHandler = (selectedUsers: UserPickerValue) => {
			if (inputText === undefined) {
				return;
			}

			const filteredEmails = inputText
				.split(',')
				.map((email) => email.trim())
				.filter(
					(input) =>
						Array.isArray(selectedUsers) &&
						selectedUsers.length > 0 &&
						// This regular expression performs a case-insensitive search of 'input' in 'name'.
						// We need it to filter out input value if user's name or email is already added from the suggestions list.
						!selectedUsers.some(({ name }) => new RegExp(input, 'i').test(name)),
				)
				.join(', ');

			setInputText(filteredEmails);
		};

		const onOptionChangeHandler = (newUsers: UserPickerValue) => {
			if (Array.isArray(newUsers)) {
				const addedUser = differenceBy(newUsers, value, 'id')[0];
				const removedUser = differenceBy(value, newUsers, 'id')[0];

				if (addedUser) {
					if (addedUser.type === EmailType) {
						fireUIAnalytics(createAnalyticsEvent({}), 'userPicker clicked', {
							result: {
								type: addedUser.type,
							},
						});
					}
					sendPendoTrackEvent({
						source: analyticsSourceId,
						actionSubjectAndAction: 'userPicker clicked',
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						attributes: { 'result.type': addedUser.type as string },
					});
				}

				if (removedUser) {
					sendPendoTrackEvent({
						source: analyticsSourceId,
						actionSubjectAndAction: 'userPickerItem deleted',
					});
				}

				if (newUsers.length > 0) {
					onChange(newUsers);
					return;
				}
			}
			onChange([]);
		};

		const onInputChangeHandler = (inputValue: string | undefined) => setInputText(inputValue);

		return (
			<AKUserPicker
				inputId={DIALOG_SEARCH_INPUT}
				fieldId="user-picker"
				width="100%"
				isMulti
				noBorder
				search={inputText}
				placeholder={formatMessage(
					allowEmail ? messages.inputPlaceholder : messages.inputWithoutEmailsPlaceholder,
				)}
				onOpen={onMenuOpen}
				onClose={onMenuClose}
				addMoreMessage=""
				value={value}
				onFocus={onFocus}
				onSelection={onOptionSelectionHandler}
				onBlur={onBlur}
				isDisabled={isReadOnly}
				allowEmail={allowEmail}
				isValidEmail={isValidEmail}
				noOptionsMessage={() => formatMessage(messages.noOptions)}
				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				menuPortalTarget={document.body}
				styles={{
					placeholder: (base) => ({
						...base,

						color: colors.N100,
						textOverflow: 'ellipsis',
						overflow: 'hidden',
						whiteSpace: 'nowrap',
						maxWidth: '100%',
						paddingRight: token('space.100'),
						paddingLeft: token('space.050'),
					}),
					control: (base) => ({
						...base,
						background: 'none',
						minHeight: '36px',
						'&&': {
							border: 'none',
							boxShadow: 'none',
						},
					}),
					menuPortal: (base) => ({ ...base, zIndex: layers.modal }),
					menu: (base) => ({ ...base, zIndex: 10 }),
					container: (base) => ({
						...base,
						overflow: 'hidden',
						flex: 1,
						minHeight: '36px',
					}),
				}}
				maxPickerHeight={maxPickerHeight}
				appearance="compact"
				loadOptions={onUserFetch}
				onInputChange={onInputChangeHandler}
				onChange={onOptionChangeHandler}
			/>
		);
	},
);

export type UserPickerProps = {
	value: OptionData[];
	analyticsSourceId: string;
	isReadOnly?: boolean;
	isInvalid?: boolean;
	isLoading: boolean;
	testId?: string;
	addButtonId?: string;
	allowEmail?: boolean;
	maxPickerHeight?: number;
	onChange: (value: OptionData[]) => void;
	onSave?: (stakeholders: OptionData[]) => void;
	onUserFetch?: (query: string | undefined) => Promise<OptionData[]>;
	onMenuOpen?: () => void;
	onMenuClose?: () => void;
};

export const UserPicker = forwardRef<UserPickerApi, UserPickerProps>(
	(
		{
			value,
			analyticsSourceId,
			isReadOnly,
			isInvalid,
			isLoading,
			testId,
			addButtonId,
			allowEmail = false,
			maxPickerHeight,
			onSave,
			onUserFetch,
			onChange,
			onMenuOpen,
			onMenuClose,
		},
		ref,
	) => {
		const { formatMessage } = useIntl();
		const [isFocused, setIsFocused] = useState(false);

		const handleSave = useCallback(
			(event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
				fireUIAnalytics(analyticsEvent, 'add');
				setIsFocused(false);
				onSave?.(value);
			},
			[value, onSave],
		);

		const isAddingDisabled = value.length === 0;

		return (
			<div
				css={[
					selectContainerStyles,
					!isInvalid && !isFocused && defaultSelectContainerStyles,
					isInvalid && invalidSelectContainerStyles,
					isFocused && focusedSelectContainerStyles,
				]}
				data-testid={testId}
			>
				<Input
					ref={ref}
					onFocus={() => {
						setIsFocused(true);
						sendPendoTrackEvent({
							source: analyticsSourceId,
							actionSubjectAndAction: 'userPicker focused',
						});
					}}
					onBlur={() => {
						setIsFocused(false);
						sendPendoTrackEvent({
							source: analyticsSourceId,
							actionSubjectAndAction: 'userPicker cancelled',
						});
					}}
					onUserFetch={onUserFetch}
					onMenuOpen={onMenuOpen}
					onMenuClose={onMenuClose}
					value={value}
					isReadOnly={isReadOnly || isLoading}
					onChange={onChange}
					analyticsSourceId={analyticsSourceId}
					allowEmail={allowEmail}
					maxPickerHeight={maxPickerHeight}
				/>
				{isInvalid ? (
					<Box xcss={badgeWrapperStyles}>
						<Badge appearance="important" testId={testId && `${testId}.invalid-badge`}>
							{value.length}
						</Badge>
					</Box>
				) : (
					(isLoading || (!isAddingDisabled && !isReadOnly)) &&
					onSave && (
						<LoadingButton
							id={addButtonId}
							testId="polaris-lib-user-picker.ui.add-button"
							onClick={handleSave}
							appearance="primary"
							spacing="compact"
							isLoading={isLoading}
							// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
							css={css({ marginRight: token('space.100') })}
						>
							{formatMessage(messages.addButtonLabel)}
						</LoadingButton>
					)
				)}
			</div>
		);
	},
);

const selectContainerStyles = css({
	display: 'flex',
	flex: 1,
	flexDirection: 'row',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage -- The token value "4px" and fallback "3px" do not match and can't be replaced automatically.
	borderRadius: token('border.radius', '3px'),
	width: '100%',
	gap: token('space.050'),
	boxSizing: 'border-box',
	minWidth: 0,
});

const defaultSelectContainerStyles = css({
	backgroundColor: token('color.background.input'),
	borderWidth: `${
		// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage -- The token value "2px" and fallback "1px" do not match and can't be replaced automatically.
		token('border.width.outline', '1px')
	}`,
	borderStyle: 'solid',
	borderColor: `${token('color.border.input')}`,
	// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage -- The token value "2px" and fallback "1px" do not match and can't be replaced automatically.
	paddingTop: token('border.width.outline', '1px'),
	// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage -- The token value "2px" and fallback "1px" do not match and can't be replaced automatically.
	paddingRight: token('border.width.outline', '1px'),
	// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage -- The token value "2px" and fallback "1px" do not match and can't be replaced automatically.
	paddingBottom: token('border.width.outline', '1px'),
	// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage -- The token value "2px" and fallback "1px" do not match and can't be replaced automatically.
	paddingLeft: token('border.width.outline', '1px'),
});

const invalidSelectContainerStyles = css({
	backgroundColor: token('color.background.input'),
	borderWidth: `${token('space.025')}`,
	borderStyle: 'solid',
	borderColor: `${token('color.border.danger')}`,
});

const focusedSelectContainerStyles = css({
	backgroundColor: 'unset',
	borderWidth: `${token('space.025')}`,
	borderStyle: 'solid',
	borderColor: `${token('color.border.focused')}`,
});

const badgeWrapperStyles = xcss({
	marginRight: 'space.050',
});
