import React from 'react';
import keyBy from 'lodash/keyBy';
import { isShallowEqual } from '@atlassian/jira-polaris-lib-equals/src/index.tsx';
import { createJpdContainer } from '@atlassian/jira-polaris-lib-react-sweet-state-utils/src/utils/hooks/index.tsx';
import { createPolarisStore } from '@atlassian/jira-polaris-lib-react-sweet-state-utils/src/utils/store/index.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { fg } from '@atlassian/jira-feature-gating';
import actions from './actions/index.tsx';
import { loadStatuses, loadStatusesForSharedView } from './actions/load.tsx';
import { useWorkflowLoadingFailedHandling } from './error/index.tsx';
import type { State, Props, WorkflowContainerProps } from './types.tsx';

export type Actions = typeof actions;

const initialState: State = {
	statuses: {},
	statusCategories: undefined,
	transitions: {},
	meta: {
		loadingProps: undefined,
		initialized: false,
	},
	containerProps: undefined,
};

export const WorkflowStore = createPolarisStore<State, Actions>({
	initialState,
	actions,
	name: 'PolarisWorkflowStore',
});

export const onPropsChange = (
	{ dispatch, getState, setState }: StoreActionApi<State>,
	{
		issueTypeIds,
		projectId,
		statusCategories,
		isSharedView,
		isProjectLoading,
		issueTypeIdsByProjectId,
		projectIds,
	}: Props,
) => {
	if (fg('jpd-aurora-roadmap-inline-edit')) {
		const hasIssueTypes =
			!!projectIds?.length &&
			issueTypeIdsByProjectId &&
			!!issueTypeIdsByProjectId[projectIds[0]]?.length;
		if (isProjectLoading || (!isSharedView && !hasIssueTypes)) {
			return;
		}
	} else if (
		isProjectLoading ||
		(!isSharedView && (issueTypeIds === undefined || issueTypeIds.length === 0)) ||
		(!isSharedView && projectId === undefined)
	) {
		return;
	}

	const newLoadingProps = {
		projectId,
		projectIds,
		issueTypeIds,
		issueTypeIdsByProjectId,
		statusCategories,
		isSharedView,
		isProjectLoading,
	};
	if (isShallowEqual(newLoadingProps, getState().meta.loadingProps)) {
		return;
	}

	setState({
		meta: {
			...getState().meta,
			loadingProps: newLoadingProps,
		},
		// should remove from store after cleaning FG jpd-aurora-roadmap-delivery-fields
		// @ts-expect-error TS-2739 https://stackoverflow.com/questions/68494879/why-does-type-record-sometimes-complain-about-missing-keys-and-sometimes-not
		statusCategories: fg('jpd-aurora-roadmap-delivery-fields')
			? undefined
			: keyBy(statusCategories, (category) => category.key),
	});
	if (isSharedView) {
		dispatch(loadStatusesForSharedView());
	} else {
		dispatch(loadStatuses());
	}
};

export const { Container, useActions, createHook, createHigherLevelHook } = createJpdContainer<
	Props,
	State,
	Actions
>(WorkflowStore, {
	onInit: () => onPropsChange,
	onUpdate: () => onPropsChange,
});

export const useWorkflowActions = useActions;

export const WorkflowContainer = (props: WorkflowContainerProps) => {
	const { children, ...rest } = props;
	const handleLoadingFailed = useWorkflowLoadingFailedHandling();
	return (
		<Container {...rest} onWorkflowLoadingFailed={handleLoadingFailed}>
			{children}
		</Container>
	);
};
