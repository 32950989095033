import React from 'react';
import Tooltip from '@atlaskit/tooltip';
import type { ConnectionFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/connection/types.tsx';
import { ConnectionsList } from '../../../../connection/connection-list/index.tsx';
import { TooltipComponent } from '../../../../connection/tooltip-component/index.tsx';
import { SummaryConnectionField } from '../summary-connection-field/index.tsx';
import { ConnectedIssueCard } from '../../../../issue-card/index.tsx';

type Props = {
	connectionItems: ConnectionFieldValue[];
	fieldKey: string;
	localIssueId: string;
};

export const DetailedShort = ({ fieldKey, connectionItems, localIssueId }: Props) => {
	if (connectionItems.length === 1) {
		return <ConnectedIssueCard id={connectionItems[0].id} type="link" />;
	}

	return (
		<Tooltip
			content={
				<ConnectionsList
					localIssueId={localIssueId}
					fieldKey={fieldKey}
					connectionItems={connectionItems}
				/>
			}
			component={TooltipComponent}
			position="bottom-start"
			hideTooltipOnClick
		>
			<SummaryConnectionField issueId={localIssueId} fieldKey={fieldKey} showFieldLabel />
		</Tooltip>
	);
};
