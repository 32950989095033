import React, { useMemo } from 'react';
import AvatarGroup from '@atlaskit/avatar-group';
import Avatar, { type AvatarPropTypes } from '@atlaskit/avatar';
import { Box, Flex, xcss } from '@atlaskit/primitives';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import {
	useFieldLabel,
	useFieldType,
} from '../../../../controllers/field/selectors/field-hooks.tsx';
import { useField } from '../../../../controllers/issue/selectors/fields-hooks.tsx';
import { usePeople, useUser } from '../../../../controllers/issue/selectors/properties/hooks.tsx';
import { UserTags } from '../../../fields/users/user-tags/index.tsx';
import { EmptyField, EmptyUserField } from '../empty/index.tsx';
import { FieldLabel, FieldValue } from '../field/styled.tsx';
import { messages } from './messages.tsx';

type UserFieldProps = {
	issueId: string;
	fieldKey: FieldKey;
	isCompact?: boolean;
	isSummary?: boolean;
	hideEmpty?: boolean;
	maxAvatarCount?: number;
	avatarSize?: AvatarPropTypes['size'];
};

type UserFieldInternalProps = UserFieldProps & {
	label: string | undefined;
};

const UserFieldInternal = ({
	isCompact,
	isSummary,
	label,
	fieldKey,
	issueId,
	hideEmpty,
	maxAvatarCount = 9,
	avatarSize = 'small',
}: UserFieldInternalProps) => {
	const user = useUser(fieldKey, issueId);
	const data = useMemo(
		() =>
			user
				? [
						{
							name: user.displayName || '',
							src: fg('jpd_fix_user_field_avatar_url')
								? user.avatarUrls?.['32x32'] ?? user.avatarUrls?.['48x48']
								: user.avatarUrls?.['32x32'],
						},
					]
				: undefined,
		[user],
	);

	const userTagsData = useMemo(() => {
		if (!data || !isSummary) return [];
		return data.map(({ name, src }) => ({
			id: name,
			name,
			avatarUrl: src,
		}));
	}, [data, isSummary]);

	if (!data && hideEmpty) {
		return null;
	}

	let content: React.JSX.Element | null;

	if (data === undefined) {
		content = null;
	} else if (isSummary) {
		content = <UserTags users={userTagsData} />;
	} else {
		content = (
			<AvatarGroup
				appearance="stack"
				data={data}
				maxCount={maxAvatarCount}
				size={avatarSize === 'xsmall' ? 'small' : avatarSize}
				avatar={avatarSize === 'xsmall' ? XSmallAvatar : undefined}
			/>
		);
	}

	return (
		<>
			{!isCompact && <FieldLabel>{label}</FieldLabel>}
			<FieldValue>
				{data !== undefined ? (
					<Box xcss={avatarGroupStyles}>{content}</Box>
				) : (
					isSummary && <EmptyUserField />
				)}
			</FieldValue>
		</>
	);
};

const UsersFieldInternal = ({
	isCompact,
	isSummary,
	label,
	fieldKey,
	issueId,
	hideEmpty,
	maxAvatarCount = 9,
	avatarSize = 'small',
}: UserFieldInternalProps) => {
	const { formatMessage } = useIntl();
	const people = usePeople(fieldKey, issueId);
	const field = useField(fieldKey);
	const showFullName =
		field?.configuration?.displayMode === 'fullNameWithAvatar' && avatarSize !== 'xsmall';

	const data = useMemo(
		() =>
			people?.map((user) => ({
				name: user.displayName || '',
				src: user.avatarUrls?.['32x32'],
			})),
		[people],
	);

	const userTagsData = useMemo(() => {
		if (!data || !showFullName) return [];
		return data.map((user) => ({
			id: user.name,
			name: user.name,
			avatarUrl: user.src,
		}));
	}, [data, showFullName]);

	const MoreTagIndicatorOverride = useMemo(
		() => (
			<Flex alignItems="center" xcss={xSmallMoreIndicatorOverrideStyles}>
				{formatMessage(messages.moreAvatarsCount, {
					count: (data?.length ?? 0) - maxAvatarCount + 1,
				})}
			</Flex>
		),
		[data, formatMessage, maxAvatarCount],
	);

	if ((!data || data.length === 0) && hideEmpty) {
		return null;
	}

	let content: React.JSX.Element | null;

	if (data === undefined) {
		content = null;
	} else if (showFullName) {
		content = <UserTags users={userTagsData} verticalLayout={isSummary} />;
	} else {
		content = (
			<AvatarGroup
				appearance="stack"
				data={data}
				maxCount={maxAvatarCount}
				size={avatarSize === 'xsmall' ? 'small' : avatarSize}
				avatar={avatarSize === 'xsmall' ? XSmallAvatar : undefined}
				overrides={
					avatarSize === 'xsmall'
						? {
								MoreIndicator: {
									render: () => MoreTagIndicatorOverride,
								},
							}
						: undefined
				}
			/>
		);
	}

	return (
		<>
			{!isCompact && <FieldLabel>{label}</FieldLabel>}
			<FieldValue>
				{data !== undefined && data.length ? (
					<Box xcss={avatarGroupStyles}>{content}</Box>
				) : (
					isSummary && <EmptyField />
				)}
			</FieldValue>
		</>
	);
};

const XSmallAvatar = (props: AvatarPropTypes) => <Avatar {...props} size="xsmall" />;

export const UserField = (props: UserFieldProps) => {
	const type = useFieldType(props.fieldKey);
	const label = useFieldLabel(props.fieldKey);

	switch (type) {
		case FIELD_TYPES.ASSIGNEE:
		case FIELD_TYPES.REPORTER:
		case FIELD_TYPES.CREATOR:
			return <UserFieldInternal label={label} {...props} />;
		case FIELD_TYPES.PEOPLE:
		case FIELD_TYPES.JSW_PEOPLE:
			return <UsersFieldInternal label={label} {...props} />;
		default:
			return null;
	}
};

const avatarGroupStyles = xcss({
	marginLeft: 'space.050',
});

const xSmallMoreIndicatorOverrideStyles = xcss({
	paddingLeft: 'space.100',
	height: '100%',
});
