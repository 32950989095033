// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types.tsx';
import { withRefreshBlocker } from '../refresh-blocker/index.tsx';
import { findViewBy, updateProjectState } from '../utils.tsx';

export const deleteViewFromState = (viewUUID: string): Action<State, Props> =>
	withRefreshBlocker(async ({ setState, getState }, { cloudId, projectId, onSuccess }) => {
		if (
			!cloudId ||
			!projectId ||
			getState().projects[projectId]?.isLoading ||
			!getState().projects[projectId]?.initialized
		) {
			return;
		}

		if (!viewUUID) {
			return;
		}

		const { view } = findViewBy(
			getState().projects[projectId],
			(innerView) => innerView.id === viewUUID,
		);

		if (!view) {
			return;
		}

		setState(
			updateProjectState(getState(), projectId, {
				views: getState()
					.projects[projectId].views.filter((innerView) => innerView.id !== viewUUID)
					.map((childView) => {
						if (childView.views) {
							return {
								...childView,
								views: childView.views?.filter((innerView) => innerView.id !== viewUUID),
							};
						}
						return childView;
					}),
			}),
		);

		onSuccess?.('deleteViewFromState');
	});
