/** @jsx jsx */
import React, { useRef } from 'react';
import { css, jsx } from '@compiled/react';
import { Box, xcss } from '@atlaskit/primitives';

import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { ErrorContainer } from '@atlassian/jira-polaris-lib-inputs-error/src/ui/styled.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { messages } from './messages.tsx';

type Props = {
	isDisabled: boolean;
	shouldAutofocus?: boolean;
	onChange: (value: string) => void;
	value: string;
	onSubmit?: () => void;
	submitOnBlur?: boolean;
	// TODO: remove after jpd_validate_type_name FG cleanup https://pi-dev-sandbox.atlassian.net/browse/POL-14748
	isInvalid: boolean;
	error?: string | null;
};

const NameInputOld = ({
	onChange,
	value,
	shouldAutofocus = false,
	isDisabled,
	onSubmit,
	isInvalid,
	submitOnBlur,
}: Props) => {
	const { formatMessage } = useIntl();
	const ref = useRef<HTMLInputElement | null>(null);

	return (
		<input
			ref={ref}
			css={[inputStyles, isInvalid && inputStylesInvalid]}
			placeholder={formatMessage(messages.enterTypeName)}
			type="text"
			value={value}
			onChange={(event) => {
				onChange(event.target.value);
			}}
			disabled={isDisabled}
			onBlur={() => {
				if (value !== '') {
					onSubmit && submitOnBlur && onSubmit();
				}
			}}
			onKeyDown={(event) => {
				if (event.key === 'Enter') {
					if (submitOnBlur) {
						event.currentTarget.blur();
					} else {
						onSubmit && onSubmit();
					}
				}
			}}
			// eslint-disable-next-line jsx-a11y/no-autofocus
			autoFocus={shouldAutofocus}
		/>
	);
};

const NameInputNew = ({
	onChange,
	value,
	shouldAutofocus = false,
	isDisabled,
	onSubmit,
	error,
	submitOnBlur,
}: Props) => {
	const { formatMessage } = useIntl();
	const ref = useRef<HTMLInputElement | null>(null);

	return (
		<Box xcss={containerStyles}>
			<input
				ref={ref}
				css={[inputStyles, error && inputStylesInvalid]}
				placeholder={formatMessage(messages.enterTypeName)}
				type="text"
				value={value}
				onChange={(event) => {
					onChange(event.target.value);
				}}
				disabled={isDisabled}
				onBlur={() => {
					onSubmit && submitOnBlur && onSubmit();
				}}
				onKeyDown={(event) => {
					if (event.key === 'Enter') {
						if (submitOnBlur) {
							event.currentTarget.blur();
						} else {
							onSubmit && onSubmit();
						}
					}
				}}
				// eslint-disable-next-line jsx-a11y/no-autofocus
				autoFocus={shouldAutofocus}
			/>
			{error && (
				<Box xcss={errorContainerStyles}>
					<ErrorContainer>{error}</ErrorContainer>
				</Box>
			)}
		</Box>
	);
};

export const NameInput = componentWithFG('jpd_validate_type_name', NameInputNew, NameInputOld);

const containerStyles = xcss({
	width: '100%',
	position: 'relative',
	display: 'flex',
});

const errorContainerStyles = xcss({
	position: 'absolute',
	bottom: 'space.negative.400',
	left: 'space.0',
	right: 'space.0',
	zIndex: '1',
});

const inputStyles = css({
	font: token('font.body'),
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	color: token('color.text'),
	overflow: 'hidden',
	paddingTop: token('space.100'),
	paddingRight: token('space.100'),
	paddingBottom: token('space.100'),
	paddingLeft: token('space.100'),

	display: 'flex',
	borderWidth: '1px',
	borderColor: 'transparent',
	borderRadius: '4px',
	borderStyle: 'solid',
	width: '100%',
	background: 'transparent',

	'&:focus': {
		outline: 'none',
		boxShadow: 'none',
		borderColor: token('color.border.input'),
		backgroundColor: token('elevation.surface'),
	},
});

const inputStylesInvalid = css({
	borderColor: token('color.border.danger'),

	'&:focus': {
		borderColor: token('color.border.danger'),
	},
});
