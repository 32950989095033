import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { JiraField } from '../../types.tsx';

type UpdateFieldRequest = {
	projectId: ProjectId;
	fieldKey: FieldKey;
	issueTypeId: number;
	description?: string;
	name?: string;
};

export const updateField = ({
	projectId,
	fieldKey,
	issueTypeId,
	description,
	name,
}: UpdateFieldRequest): Promise<JiraField> =>
	performPutRequest(`/rest/polaris/v2/projects/${projectId}/fields/${fieldKey}`, {
		body: fg('jpd_use_field_association_helper')
			? JSON.stringify({ name, description })
			: JSON.stringify({ name, description, issueTypeId }),
	});
