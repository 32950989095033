import React, { useState, type SyntheticEvent } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import { useIntl } from '@atlassian/jira-intl';
import { useSortedIssueIds } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/sort-hooks.tsx';
import { useSelectedIssueIds } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import {
	PolarisIcon,
	PolarisIconType,
} from '@atlassian/jira-polaris-component-glyphs/src/ui/glyphs/main.tsx';
import { useCanManageDeliveryTickets } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { fg } from '@atlassian/jira-feature-gating';

import { useProjectIdsForIssues } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/project/hooks.tsx';

import { SendToJiraDialog } from '../../send-to-backlog-modal/index.tsx';
import { useVisibleSelectedIssueIds } from '../../../utils.tsx';
import { BarActionButton } from '../bar-action-button/index.tsx';
import { messages } from './messages.tsx';

export const SendToBacklogButton = () => {
	const { formatMessage } = useIntl();
	const canManageDelivery = useCanManageDeliveryTickets();

	const sortedIssueIds = useSortedIssueIds();
	const selectedIssueIds = useSelectedIssueIds();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const visibleSelectedIssueIds = useVisibleSelectedIssueIds();
	const projectIds = useProjectIdsForIssues(visibleSelectedIssueIds);
	const cantMergeIdeasForSelectedProjects = !useCanManageDeliveryTickets({ projectIds });

	if (sortedIssueIds.findIndex((id) => selectedIssueIds[id]) < 0) {
		return null;
	}

	if (fg('jpd-aurora-roadmap-inline-edit')) {
		return (
			<>
				<BarActionButton
					testId="polaris-ideas.ui.view-controls.manage-bar.send-to-backlog.add-idea-header-button"
					onClick={(event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
						fireUIAnalytics(analyticsEvent, 'createDeliveryTickets');
						setIsModalOpen(true);
					}}
					isDisabled={cantMergeIdeasForSelectedProjects}
					tooltipMessage={
						cantMergeIdeasForSelectedProjects
							? formatMessage(messages.notAllowedTooltip)
							: undefined
					}
					iconBefore={
						<PolarisIcon
							label={formatMessage(
								fg('polaris-issue-terminology-refresh')
									? messages.sendToBacklogButtonIssueTermRefresh
									: messages.sendToBacklogButton,
							)}
							name={PolarisIconType.DeliveryTickets}
						/>
					}
					{...(fg('jpd-trace-ufo-transition')
						? { interactionName: 'jpd.send-to-backlog-dialog' }
						: {})}
				>
					{formatMessage(
						fg('polaris-issue-terminology-refresh')
							? messages.sendToBacklogButtonIssueTermRefresh
							: messages.sendToBacklogButton,
					)}
				</BarActionButton>
				<SendToJiraDialog isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
			</>
		);
	}

	return (
		<>
			<Button
				testId="polaris-ideas.ui.view-controls.manage-bar.send-to-backlog.add-idea-header-button"
				onClick={(event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
					fireUIAnalytics(analyticsEvent, 'createDeliveryTickets');
					setIsModalOpen(true);
				}}
				isDisabled={!canManageDelivery}
				appearance="subtle"
				iconBefore={
					<PolarisIcon
						label={formatMessage(
							fg('polaris-issue-terminology-refresh')
								? messages.sendToBacklogButtonIssueTermRefresh
								: messages.sendToBacklogButton,
						)}
						name={PolarisIconType.DeliveryTickets}
					/>
				}
				{...(fg('jpd-trace-ufo-transition')
					? { interactionName: 'jpd.send-to-backlog-dialog' }
					: {})}
			>
				{formatMessage(
					fg('polaris-issue-terminology-refresh')
						? messages.sendToBacklogButtonIssueTermRefresh
						: messages.sendToBacklogButton,
				)}
			</Button>
			<SendToJiraDialog isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
		</>
	);
};
