import React, { useState, useCallback } from 'react';
import noop from 'lodash/noop';
import Button from '@atlaskit/button/new';
import { PRODUCT_DISCOVERY_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { useIntl } from '@atlassian/jira-intl';
import {
	AddPeopleModalBoundary as PolarisAddPeopleModalBoundary,
	type Props as PolarisAddPeopleProps,
	type Role,
} from '@atlassian/jira-polaris-lib-project-add-people/src/async.tsx';
import type PolarisAddPeopleModalType from '@atlassian/jira-polaris-lib-project-add-people/src/index.tsx';
import type { Party } from '@atlassian/jira-project-add-people/src/index.tsx';
import type { ProjectId, ProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { lazy, LazyWait } from '@atlassian/react-loosely-lazy';
import { useActions } from '../../controllers/index.tsx';
import { useIsIdentityBasedContributorHandlingEnabled } from '../../controllers/selectors/contributor-group-hooks.tsx';
import { useCreatorRolesIds } from '../../controllers/selectors/roles-hooks.tsx';
import { useContributorAccessRequiredNotification } from './contributor-access-notification/index.tsx';
import messages from './messages.tsx';

type AddPeopleButtonProps = {
	projectId: ProjectId;
	projectKey: ProjectKey;
};

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const PolarisAddPeopleModal = lazy<typeof PolarisAddPeopleModalType>(
	() =>
		import(
			/* webpackChunkName: "async-polaris-lib-project-add-people-dialog" */ '@atlassian/jira-polaris-lib-project-add-people'
		),
	{ ssr: false },
);

export const PolarisModal = (props: PolarisAddPeopleProps) => (
	<PolarisAddPeopleModalBoundary packageName="polaris">
		<LazyWait until={props.isOpen}>
			{/* @ts-expect-error - TS2322 - Type '{ isAdmin: boolean; isOpen: boolean; projectId: number; projectKey: string; projectType: ProjectType; analyticsPrefix: string; allowFlags: boolean; projectRoles?: Role[] | undefined; ... 8 more ...; onError: (errors: readonly string[]) => void; }' is not assignable to type 'Pick<Pick<ExternalProps | "isAdmin" | "onError" | "isOpen" | "onClose" | "projectType" | "projectKey" | "projectId" | "defaultRoleId" | "adminRoleId" | ... 8 more ... | "partiesInProject"> | ... 10 more ... | "onAddSuccess">'. */}
			<PolarisAddPeopleModal {...props} />
		</LazyWait>
	</PolarisAddPeopleModalBoundary>
);

const extractGroupIds = (addedParties: Party[]): string[] =>
	addedParties.filter((party) => party.type === 'group').map((party) => party.id);

export const AddPeopleButton = ({ projectId, projectKey }: AddPeopleButtonProps) => {
	const { formatMessage } = useIntl();
	const [isDialogOpen, setDialogOpen] = useState(false);
	const { loadProjectActors, addManuallyAddedGroupsWithRole } = useActions();
	const showContributorAccessWarningFlag = useContributorAccessRequiredNotification();
	const creatorRolesIds = useCreatorRolesIds();
	const isIdentityBasedContributorHandlingEnabled = useIsIdentityBasedContributorHandlingEnabled();
	const onAddSuccess = useCallback(
		(addedParties: Party[], role: Role) => {
			if (isIdentityBasedContributorHandlingEnabled) {
				addManuallyAddedGroupsWithRole(extractGroupIds(addedParties), role.id);
			}
			const isContributorRoleAssigned = creatorRolesIds && !creatorRolesIds.includes(role.id);
			loadProjectActors(isContributorRoleAssigned, (users) =>
				showContributorAccessWarningFlag(users),
			);
		},
		[
			addManuallyAddedGroupsWithRole,
			creatorRolesIds,
			isIdentityBasedContributorHandlingEnabled,
			loadProjectActors,
			showContributorAccessWarningFlag,
		],
	);

	return (
		<>
			<Button
				id="polaris.project-settings.add-users-btn"
				appearance="primary"
				onClick={() => setDialogOpen(true)}
			>
				{formatMessage(messages.addPeopleButtonCaption)}
			</Button>
			<PolarisModal
				isOpen={isDialogOpen}
				onClose={() => setDialogOpen(false)}
				projectId={+projectId}
				projectKey={projectKey}
				projectType={PRODUCT_DISCOVERY_PROJECT}
				isAdmin
				allowFlags
				analyticsPrefix="polaris.access"
				onError={noop}
				creatorRolesIds={creatorRolesIds}
				onAddSuccess={onAddSuccess}
				isIdentityBasedContributorHandlingEnabled={isIdentityBasedContributorHandlingEnabled}
			/>
		</>
	);
};
