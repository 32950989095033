export const MANAGE_SPRINTS_PERMISSION = 'MANAGE_SPRINTS_PERMISSION';
export const PUBLISH_DISCOVERY_VIEWS_PERMISSION = 'PUBLISH_DISCOVERY_VIEWS';
export const PUBLISH_PUBLIC_DISCOVERY_VIEWS_PERMISSION = 'PUBLISH_PUBLIC_DISCOVERY_VIEWS';

export const HIDDEN_PERMISSIONS: string[] = [
	'BROWSE_PROJECTS',
	'VIEW_PROJECTS',
	'VIEW_ISSUES',
	MANAGE_SPRINTS_PERMISSION,
];

export const PROJECT_ACTORS_QUERY_PAGE_SIZE = 100;

export const PROJECT_ACTORS_LIST_PAGE_SIZE = 15;

export const DEBOUNCE_DELAY_SEARCH_MS = 300;

export const ADMIN_ROLE_NAME = 'Administrator';
export const MEMBER_ROLE_NAME = 'Member';
export const VIEWER_ROLE_NAME = 'Viewer';

export const CUSTOMIZE_PROJECT_ROLES_DOCS_URL =
	'https://support.atlassian.com/jira-product-discovery/docs/customize-project-roles/';

export const ADMINISTER_PROJECTS_PERMISSION = 'ADMINISTER_PROJECTS';
