// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types.tsx';
import { findViewBy, modifyView } from '../utils.tsx';

export const expandSectionForSelectedView =
	(): Action<State, Props> =>
	async ({ setState, getState }, props) => {
		const { navigationRemote, cloudId, projectId, activeViewId, isActiveProject } = props;
		if (
			!cloudId ||
			!projectId ||
			!navigationRemote ||
			getState().projects[projectId]?.isLoading ||
			!getState().projects[projectId]?.initialized
		) {
			return;
		}

		if (!isActiveProject) {
			return;
		}

		const { parent } = findViewBy(
			getState().projects[projectId],
			(innerView) => String(innerView.polarisId) === activeViewId || innerView.id === activeViewId,
		);

		if (parent) {
			setState(
				modifyView(getState(), projectId, (viewToModify) => {
					if (viewToModify.localId === parent.localId) {
						return {
							...viewToModify,
							isExpanded: true,
						};
					}
					return viewToModify;
				}),
			);
		}
	};
