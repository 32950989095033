import React from 'react';
import { Box } from '@atlaskit/primitives';
import type { ProjectMetadata } from '@atlassian/jira-polaris-domain-project/src/types.tsx';
import { ProjectTag } from '@atlassian/jira-polaris-lib-project-tag/src/ui/index.tsx';

type Props = {
	project: ProjectMetadata | undefined;
	isLink?: boolean;
};

export const ProjectField = ({ project, isLink = true }: Props) => {
	if (!project) {
		return null;
	}

	// additional wrapping div is required otherwise the table cell will force override the fit-content width handler
	return (
		<Box>
			<ProjectTag
				projectName={project.name}
				projectAvatarUrl={project.avatarUrls['16x16']}
				projectKey={project.key}
				isLink={isLink}
			/>
		</Box>
	);
};
