import {
	addContributorGroup,
	addManuallyAddedGroupsWithRole,
} from './add-contributor-group-to-project/index.tsx';
import { createRole } from './create-role/index.tsx';
import { deleteProjectActor } from './delete-project-actor/index.tsx';
import { deleteRole } from './delete-role/index.tsx';
import { loadContributorGroupConfig } from './load-contributor-group-config/index.tsx';
import { loadContributorPermissionsList } from './load-contributor-permissions/index.tsx';
import { loadCreators } from './load-creators/index.tsx';
import { fetchPermissionConfiguration } from './load-permissions/index.tsx';
import { loadProjectActors } from './load-project-actors/index.tsx';
import { loadRoles } from './load-roles/index.tsx';
import {
	getCurrentProjectAccessLevel,
	getAvailableProjectAccessLevels,
	setCurrentProjectAccessLevel,
} from './project-access-level/index.tsx';
import { removeActorRole } from './remove-role-for-actor/index.tsx';
import { setActorRole } from './set-role-for-actor/index.tsx';
import { toggleEnforcePermissionChecks } from './toggle-enforce-permission-checks/index.tsx';
import { toggleManageRolesModal } from './toggle-manage-roles-modal/index.tsx';
import { toggleProjectAccessLevelModal } from './toggle-project-access-level-modal/index.tsx';
import { updateProjectActorsFilter } from './update-project-actors-filter/index.tsx';
import { updateRole } from './update-role/index.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default {
	getCurrentProjectAccessLevel,
	getAvailableProjectAccessLevels,
	setCurrentProjectAccessLevel,
	fetchPermissionConfiguration,
	loadCreators,
	loadRoles,
	loadContributorPermissionsList,
	createRole,
	updateRole,
	deleteRole,
	loadProjectActors,
	deleteProjectActor,
	updateProjectActorsFilter,
	setActorRole,
	removeActorRole,
	toggleManageRolesModal,
	loadContributorGroupConfig,
	toggleEnforcePermissionChecks,
	toggleProjectAccessLevelModal,
	addContributorGroup,
	addManuallyAddedGroupsWithRole,
};
