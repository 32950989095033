import { differenceInMinutes, parseISO } from 'date-fns';
import {
	getUserProperty,
	setUserProperties,
} from '@atlassian/jira-common-rest/src/api/latest/user-properties/index.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import { logSafeErrorWithoutCustomerDataWrapper } from '@atlassian/jira-polaris-lib-errors/src/common/utils/index.tsx';
import { SHOULD_SEE_ONBOARDING_USER_PROPERTY } from '../../constants.tsx';
import { getProductUsageStartTimestamp } from '../../selectors/product-usage-start-timestamp.tsx';
import type { Props, State } from '../../types.tsx';

export const loadShouldSeeOnboarding =
	(): Action<State, Props> =>
	async ({ setState, getState }, { accountId }) => {
		const state = getState();
		const startTimestamp = getProductUsageStartTimestamp(state);

		if (!accountId || !startTimestamp) {
			return;
		}

		try {
			const shouldSeeOnboarding = await getUserProperty<boolean>(
				accountId,
				SHOULD_SEE_ONBOARDING_USER_PROPERTY,
			);

			setState({
				shouldSeeOnboarding,
			});
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (getPropertyError: any) {
			if (getPropertyError.statusCode === 404) {
				// If the user started using product less than 60 minutes ago, we assume that this
				// is a new user and should have onboarding experience. 60 is an arbitrary number,
				// it is used in order to prevent cases when user property was not set on the first
				// load due to network issues etc, so product usage start can be checked within
				// this time and user property can be set correctly.
				const shouldSeeOnboarding =
					Math.abs(differenceInMinutes(parseISO(startTimestamp), Date.now())) < 60;

				try {
					await setUserProperties(
						accountId,
						SHOULD_SEE_ONBOARDING_USER_PROPERTY,
						shouldSeeOnboarding.toString(),
					);

					setState({
						shouldSeeOnboarding,
					});
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
				} catch (setPropertyError: any) {
					logSafeErrorWithoutCustomerDataWrapper(
						'polaris.onboarding.should-see-onboarding-user-property',
						`Failed to set user property with key: ${SHOULD_SEE_ONBOARDING_USER_PROPERTY}`,
						setPropertyError,
					);
				}
			}

			logSafeErrorWithoutCustomerDataWrapper(
				'polaris.onboarding.should-see-onboarding-user-property',
				`Failed to get user property with key: ${SHOULD_SEE_ONBOARDING_USER_PROPERTY}`,
				getPropertyError,
			);
		}
	};
