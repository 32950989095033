// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createStore, createActionsHook } from '@atlassian/react-sweet-state';
import { actions } from './actions/index.tsx';
import { initialState } from './constants.tsx';

export const Store = createStore({
	initialState,
	actions,
	name: 'jpd-environment-tenant-store',
});

export const useStoreActions = createActionsHook(Store);
