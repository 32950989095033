import { useCallback, useMemo } from 'react';
import keyBy from 'lodash/keyBy';
import { COLUMN_DROPPABLE_ID, EMPTY_VALUE_ID, ROW_DROPPABLE_ID } from '../constants.tsx';
import type { ExtendedOption, IssuesByCell, IssuesByGroupIdentity } from '../types/common.tsx';
import type { DraggableLocation } from '../types/draggable.tsx';
import type {
	OnCardDropChange,
	OnCardDropClear,
	OnCardDropStart,
	OnColumnDrop,
	OnRowDrop,
	RankAfter,
	RankBefore,
} from '../types/ui.tsx';
import {
	getGroupIdentityForDroppableId,
	getGroupIdentityPairForDroppableId,
	getIdsForGroupColumn,
} from './board.tsx';

const getIdsForColumn = (
	idsByColumn: IssuesByGroupIdentity,
	columnId: string,
): string[] | undefined => {
	if (columnId === EMPTY_VALUE_ID && idsByColumn.empty !== undefined) {
		return idsByColumn.empty;
	}

	const realColumnId = getGroupIdentityForDroppableId(columnId);
	return realColumnId !== undefined && idsByColumn.groups[realColumnId]
		? idsByColumn.groups[realColumnId]
		: undefined;
};

const getIdForColumn = (
	idsByColumn: IssuesByGroupIdentity,
	columnId: string,
	index: number,
): string | undefined => getIdsForColumn(idsByColumn, columnId)?.[index];

const getIdsForCell = (idsByCell: IssuesByCell, cellId: string): string[] | undefined => {
	const [realRowId, realColumnId] = getGroupIdentityPairForDroppableId(cellId);
	return getIdsForGroupColumn(idsByCell, realColumnId, realRowId);
};

const getIdForCell = (idsByCell: IssuesByCell, cellId: string, index: number): string | undefined =>
	getIdsForCell(idsByCell, cellId)?.[index];

const handleCardDrop = ({
	issueId,
	sourceGroupIdentity,
	destinationGroupIdentity,
	extendedOptions,
	onCardDropClear,
	onCardDropChange,
}: {
	issueId: string;
	sourceGroupIdentity?: string;
	destinationGroupIdentity?: string;
	extendedOptions: ExtendedOption<unknown>[];
	onCardDropClear: OnCardDropClear;
	onCardDropChange: OnCardDropChange;
}) => {
	if (destinationGroupIdentity === sourceGroupIdentity) {
		return;
	}

	const optionsByGroupIdentity = keyBy(extendedOptions, ({ groupIdentity }) => groupIdentity);

	const getOptionValue = (groupIdentity?: string) => {
		const option = groupIdentity && optionsByGroupIdentity[groupIdentity];
		return option && typeof option === 'object' && 'value' in option ? option.value : undefined;
	};

	const sourceValue = getOptionValue(sourceGroupIdentity);
	const targetValue = getOptionValue(destinationGroupIdentity);

	if (destinationGroupIdentity === undefined) {
		onCardDropClear({ issueId, sourceValue });
	} else {
		onCardDropChange({ issueId, sourceValue, targetValue });
	}
};

type UseCardDropHandlerOptions = {
	extendedOptions: ExtendedOption<unknown>[];
	idsByColumn: IssuesByGroupIdentity;
	unfilteredIdsByColumn: IssuesByGroupIdentity;
	onCardDropStart: OnCardDropStart;
	onCardDropClear: OnCardDropClear;
	onCardDropChange: OnCardDropChange;
	rankAfter: RankAfter;
	rankBefore: RankBefore;
};

/**
 * handler for dnd behaviour of cards
 */
export const useCardDropHandler = ({
	extendedOptions,
	idsByColumn,
	unfilteredIdsByColumn,
	onCardDropStart,
	onCardDropClear,
	onCardDropChange,
	rankAfter,
	rankBefore,
}: UseCardDropHandlerOptions) => {
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const stableExtendedOptions = useMemo(() => extendedOptions, [JSON.stringify(extendedOptions)]);

	return useCallback(
		({
			source,
			destination,
			afterItemIndex,
			beforeItemIndex,
		}: {
			source: DraggableLocation;
			destination: DraggableLocation | null | undefined;
			afterItemIndex?: number;
			beforeItemIndex?: number;
		}) => {
			const issueId = getIdForColumn(idsByColumn, source.droppableId, source.index);
			if (issueId === undefined) {
				return;
			}

			onCardDropStart(issueId);

			// value change (drop in different column)
			if (
				destination?.droppableId !== undefined &&
				source.droppableId !== destination.droppableId
			) {
				handleCardDrop({
					issueId,
					sourceGroupIdentity: getGroupIdentityForDroppableId(source.droppableId),
					destinationGroupIdentity: getGroupIdentityForDroppableId(destination.droppableId),
					extendedOptions: stableExtendedOptions,
					onCardDropClear,
					onCardDropChange,
				});
			}

			// TODO: figure out when this was used
			if (afterItemIndex !== undefined) {
				const afterDestinationId = destination
					? getIdForColumn(idsByColumn, destination.droppableId, afterItemIndex)
					: undefined;
				if (afterItemIndex === -1) {
					const unfilteredIds =
						(destination && getIdsForColumn(unfilteredIdsByColumn, destination.droppableId)) || [];
					const beforeDestinationId = unfilteredIds[0];
					if (beforeDestinationId && beforeDestinationId !== issueId) {
						rankBefore(issueId, beforeDestinationId);
					}
					return;
				}
				if (afterDestinationId !== undefined && afterDestinationId !== issueId) {
					rankAfter(issueId, afterDestinationId);
					return;
				}
			}

			// TODO: figure out when this was used
			if (beforeItemIndex !== undefined) {
				const beforeDestinationId = destination
					? getIdForColumn(idsByColumn, destination.droppableId, beforeItemIndex)
					: undefined;
				if (beforeItemIndex === -2) {
					const unfilteredIds =
						(destination && getIdsForColumn(unfilteredIdsByColumn, destination.droppableId)) || [];
					const afterDestinationId = unfilteredIds[unfilteredIds.length - 1];
					if (afterDestinationId && afterDestinationId !== issueId) {
						rankAfter(issueId, afterDestinationId);
						return;
					}
				}
				if (beforeDestinationId !== undefined && beforeDestinationId !== issueId) {
					rankBefore(issueId, beforeDestinationId);
					return;
				}
			}

			// rank change (drop in different position on same column, or new position in different column)
			const destinationId = destination
				? getIdForColumn(idsByColumn, destination.droppableId, destination.index)
				: undefined;

			if (destination && destinationId === undefined) {
				// drag to another column on the last position (index out of range)
				const issueIds = getIdsForColumn(idsByColumn, destination.droppableId) || [];

				if (issueIds.length === 0) {
					return;
				}

				const lastIssuedId = issueIds.length === 1 ? issueIds[0] : issueIds[issueIds.length - 1];
				rankAfter(issueId, lastIssuedId);
				return;
			}

			if (destination && destinationId !== undefined && issueId !== destinationId) {
				// do not move issue when it is dropped on the same position as it was before
				if (source.index === destination.index && source.droppableId === destination.droppableId) {
					return;
				}

				if (source.index < destination.index && source.droppableId === destination.droppableId) {
					// drag down - rank after (only when drop target is in same column)
					rankAfter(issueId, destinationId);
				} else {
					// drag up - rank before
					rankBefore(issueId, destinationId);
				}
			}
		},
		[
			idsByColumn,
			onCardDropStart,
			stableExtendedOptions,
			onCardDropClear,
			onCardDropChange,
			unfilteredIdsByColumn,
			rankBefore,
			rankAfter,
		],
	);
};

type UseColumnDropHandlerOptions = {
	extendedOptions: ExtendedOption<unknown>[];
	onColumnDrop: OnColumnDrop;
};

/**
 * handler for dnd behaviour of columns
 */
const useColumnDropHandler = ({ extendedOptions, onColumnDrop }: UseColumnDropHandlerOptions) => {
	return useCallback(
		({ source, destination }: { source: DraggableLocation; destination?: DraggableLocation }) => {
			if (!destination || destination.index === source.index) {
				return;
			}

			const newOrder: ExtendedOption<unknown>[] = [...extendedOptions];
			const sourceItem = newOrder[source.index];
			newOrder.splice(source.index, 1);
			newOrder.splice(destination.index, 0, sourceItem);

			onColumnDrop(newOrder, sourceItem);
		},
		[extendedOptions, onColumnDrop],
	);
};

type UseRowDropHandlerOptions = {
	extendedVerticalOptions: ExtendedOption<unknown>[];
	onRowDrop: OnRowDrop;
};

const useRowDropHandler = ({ extendedVerticalOptions, onRowDrop }: UseRowDropHandlerOptions) => {
	return useCallback(
		({ source, destination }: { source: DraggableLocation; destination?: DraggableLocation }) => {
			if (!destination) {
				return;
			}

			const newOrder = [...extendedVerticalOptions];
			const sourceItem = newOrder[source.index];
			newOrder.splice(source.index, 1);
			newOrder.splice(destination.index, 0, sourceItem);

			onRowDrop(newOrder);
		},
		[extendedVerticalOptions, onRowDrop],
	);
};

type UseSwimlanesCardDropHandlerOptions = {
	extendedOptions: ExtendedOption<unknown>[];
	extendedVerticalOptions: ExtendedOption<unknown>[];
	idsByCell: IssuesByCell;
	unfilteredIdsByCell: IssuesByCell;
	onCardDropChange: OnCardDropChange;
	onCardDropClear: OnCardDropClear;
	onCardDropStart: OnCardDropStart;
	onCardDropVerticalChange: OnCardDropChange;
	onCardDropVerticalClear: OnCardDropClear;
	rankAfter: RankAfter;
	rankBefore: RankBefore;
};

export const useSwimlanesCardDropHandler = ({
	extendedOptions,
	extendedVerticalOptions,
	idsByCell,
	unfilteredIdsByCell,
	onCardDropChange,
	onCardDropClear,
	onCardDropStart,
	onCardDropVerticalChange,
	onCardDropVerticalClear,
	rankAfter,
	rankBefore,
}: UseSwimlanesCardDropHandlerOptions) => {
	return useCallback(
		({
			source,
			destination,
			afterItemIndex,
			beforeItemIndex,
		}: {
			source: DraggableLocation;
			destination: DraggableLocation | null | undefined;
			afterItemIndex?: number;
			beforeItemIndex?: number;
		}) => {
			const issueId = getIdForCell(idsByCell, source.droppableId, source.index);
			if (issueId === undefined) {
				return;
			}

			onCardDropStart(issueId);

			// value change (drop in different column)
			if (
				destination?.droppableId !== undefined &&
				source.droppableId !== destination.droppableId
			) {
				const [destinationVerticalGroupIdentity, destinationGroupIdentity] =
					getGroupIdentityPairForDroppableId(destination.droppableId);

				const [sourceVerticalGroupIdentity, sourceGroupIdentity] =
					getGroupIdentityPairForDroppableId(source.droppableId);

				if (destinationGroupIdentity !== sourceGroupIdentity) {
					handleCardDrop({
						issueId,
						sourceGroupIdentity,
						destinationGroupIdentity,
						extendedOptions,
						onCardDropClear,
						onCardDropChange,
					});
				}

				if (destinationVerticalGroupIdentity !== sourceVerticalGroupIdentity) {
					handleCardDrop({
						issueId,
						sourceGroupIdentity: sourceVerticalGroupIdentity,
						destinationGroupIdentity: destinationVerticalGroupIdentity,
						extendedOptions: extendedVerticalOptions,
						onCardDropClear: onCardDropVerticalClear,
						onCardDropChange: onCardDropVerticalChange,
					});
				}
			}

			// rank change (drop in different position on same column, or new position in different column)

			if (afterItemIndex !== undefined) {
				const afterDestinationId = destination
					? getIdForCell(idsByCell, destination.droppableId, afterItemIndex)
					: undefined;
				if (afterItemIndex === -1) {
					const unfilteredIds =
						(destination && getIdsForCell(unfilteredIdsByCell, destination.droppableId)) || [];
					const beforeDestinationId = unfilteredIds[0];
					if (beforeDestinationId && beforeDestinationId !== issueId) {
						rankBefore(issueId, beforeDestinationId);
					}
					return;
				}
				if (afterDestinationId !== undefined && afterDestinationId !== issueId) {
					rankAfter(issueId, afterDestinationId);
					return;
				}
			}

			if (beforeItemIndex !== undefined) {
				const beforeDestinationId = destination
					? getIdForCell(idsByCell, destination.droppableId, beforeItemIndex)
					: undefined;
				if (beforeItemIndex === -2) {
					const unfilteredIds =
						(destination && getIdsForCell(unfilteredIdsByCell, destination.droppableId)) || [];
					const afterDestinationId = unfilteredIds[unfilteredIds.length - 1];
					if (afterDestinationId && afterDestinationId !== issueId) {
						rankAfter(issueId, afterDestinationId);
						return;
					}
				}
				if (beforeDestinationId !== undefined && beforeDestinationId !== issueId) {
					rankBefore(issueId, beforeDestinationId);
					return;
				}
			}

			if (
				destination &&
				source.index === destination.index &&
				source.droppableId === destination.droppableId
			) {
				return;
			}

			const destinationId = destination
				? getIdForCell(idsByCell, destination.droppableId, destination.index)
				: undefined;

			if (destination && destinationId === undefined) {
				// drag to another column on the last position (index out of range)
				const issueIds = getIdsForCell(idsByCell, destination.droppableId) || [];

				if (issueIds.length === 0) {
					return;
				}

				const lastIssuedId = issueIds.length === 1 ? issueIds[0] : issueIds[issueIds.length - 1];
				rankAfter(issueId, lastIssuedId);
				return;
			}

			if (
				destination &&
				issueId !== undefined &&
				destinationId !== undefined &&
				issueId !== destinationId
			) {
				// do not move issue when it is dropped on the same position as it was before

				if (source.index < destination.index && source.droppableId === destination.droppableId) {
					// drag down - rank after (only when drop target is in same column)
					rankAfter(issueId, destinationId);
				} else {
					// drag up - rank before
					rankBefore(issueId, destinationId);
				}
			}
		},
		[
			extendedOptions,
			extendedVerticalOptions,
			idsByCell,
			onCardDropChange,
			onCardDropClear,
			onCardDropStart,
			onCardDropVerticalChange,
			onCardDropVerticalClear,
			rankAfter,
			rankBefore,
			unfilteredIdsByCell,
		],
	);
};

/**
 * Helper hook to create an onDragEnd function. Deals with reordering and assigning
 * new values when dragging into different columns
 */
export const useDropCallback = ({
	extendedOptions,
	onColumnDrop,
	unfilteredIdsByColumn,
	idsByColumn,
	onCardDropChange,
	onCardDropClear,
	onCardDropStart,
	rankAfter,
	rankBefore,
}: UseColumnDropHandlerOptions & UseCardDropHandlerOptions) => {
	const columnDropHandler = useColumnDropHandler({ extendedOptions, onColumnDrop });
	const cardDropHandler = useCardDropHandler({
		extendedOptions,
		idsByColumn,
		onCardDropChange,
		onCardDropClear,
		onCardDropStart,
		rankAfter,
		rankBefore,
		unfilteredIdsByColumn,
	});

	return useCallback(
		(
			source: DraggableLocation,
			destination?: DraggableLocation,
			afterItemIndex?: number,
			beforeItemIndex?: number,
		) => {
			if (source.droppableId === COLUMN_DROPPABLE_ID) {
				return columnDropHandler({ source, destination });
			}
			return cardDropHandler({ source, destination, afterItemIndex, beforeItemIndex });
		},
		[cardDropHandler, columnDropHandler],
	);
};

export const useSwimlanesDropCallback = ({
	extendedOptions,
	extendedVerticalOptions,
	idsByCell,
	unfilteredIdsByCell,
	onCardDropChange,
	onCardDropClear,
	onCardDropStart,
	onCardDropVerticalChange,
	onCardDropVerticalClear,
	onColumnDrop,
	onRowDrop,
	rankAfter,
	rankBefore,
}: UseRowDropHandlerOptions & UseColumnDropHandlerOptions & UseSwimlanesCardDropHandlerOptions) => {
	const cardDropHandler = useSwimlanesCardDropHandler({
		extendedOptions,
		extendedVerticalOptions,
		idsByCell,
		unfilteredIdsByCell,
		onCardDropChange,
		onCardDropClear,
		onCardDropStart,
		onCardDropVerticalChange,
		onCardDropVerticalClear,
		rankAfter,
		rankBefore,
	});
	const columnDropHandler = useColumnDropHandler({ extendedOptions, onColumnDrop });
	const rowDropHandler = useRowDropHandler({ extendedVerticalOptions, onRowDrop });

	return useCallback(
		(
			source: DraggableLocation,
			destination?: DraggableLocation,
			afterItemIndex?: number,
			beforeItemIndex?: number,
		) => {
			if (source.droppableId === COLUMN_DROPPABLE_ID) {
				return columnDropHandler({ source, destination });
			}
			if (source.droppableId === ROW_DROPPABLE_ID) {
				return rowDropHandler({ source, destination });
			}
			return cardDropHandler({
				source,
				destination,
				afterItemIndex,
				beforeItemIndex,
			});
		},
		[cardDropHandler, columnDropHandler, rowDropHandler],
	);
};
