// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import { logSafeErrorWithoutCustomerDataWrapper } from '@atlassian/jira-polaris-lib-errors/src/common/utils/index.tsx';
import { isNotFoundError } from '@atlassian/jira-polaris-lib-errors/src/controllers/utils.tsx';
import type { ProjectProperties, State } from '../../types.tsx';
import { getPropertyValue } from '../../../services/jira/properties/index.tsx';

export const loadProperty =
	(propertyKey: ProjectProperties, loadedCallback?: (value: unknown) => void): Action<State> =>
	async ({ setState, getState }) => {
		// skip, if already loaded or loading
		const property = getState().properties[propertyKey];
		const projectId = getState().meta.projectId;

		if (
			!projectId ||
			(property?.isLoading === false && property?.error === null) ||
			property?.isLoading
		) {
			return;
		}

		setState({
			properties: {
				...getState().properties,
				[propertyKey]: {
					isLoading: true,
					error: null,
					value: undefined,
				},
			},
		});

		try {
			const value = await getPropertyValue(projectId, propertyKey);

			setState({
				properties: {
					...getState().properties,
					[propertyKey]: {
						isLoading: false,
						error: null,
						value,
					},
				},
			});

			loadedCallback?.(value);
		} catch (error) {
			if (error instanceof Error && isNotFoundError(error)) {
				setState({
					properties: {
						...getState().properties,
						[propertyKey]: {
							isLoading: false,
							error: null,
							value: undefined,
						},
					},
				});
				return;
			}

			logSafeErrorWithoutCustomerDataWrapper(
				'polaris.project-properties-error',
				`Failed to load project property with key: ${propertyKey}`,
				error instanceof Error
					? error
					: new Error(`Failed to load project property with key: ${propertyKey}`),
			);

			setState({
				properties: {
					...getState().properties,
					[propertyKey]: {
						isLoading: false,
						error,
						value: undefined,
					},
				},
			});
		}
	};
