import React from 'react';
import Lozenge from '@atlaskit/lozenge';
import { Box, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

export const CreatorLozenge = () => {
	const { formatMessage } = useIntl();
	return (
		<Box xcss={wrapperStyles}>
			<Lozenge appearance="success">{formatMessage(messages.creator)}</Lozenge>
		</Box>
	);
};

const wrapperStyles = xcss({
	marginLeft: 'space.100',
	marginTop: 'space.negative.025',
});
