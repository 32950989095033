import { ENTITY_LIMIT_TYPE as PROJECT_ENTITY_LIMIT_TYPE } from '@atlassian/jira-polaris-domain-project/src/constants.tsx';
import { ENTITY_LIMIT_TYPE as COLLECTION_ENTITY_LIMIT_TYPE } from '@atlassian/jira-polaris-domain-collection/src/constants.tsx';
import type { LocalViewId } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createHook, createStateHook, createSelector } from '@atlassian/react-sweet-state';
import { fg } from '@atlassian/jira-feature-gating';
import { findView } from '../actions/utils.tsx';
import { NavigationStore } from '../index.tsx';
import {
	type EntityLimitsHookProp,
	getEntityLimits,
	getProjectViewsCount,
	getProjectViewsetsCount,
} from './views.tsx';

export const useNavigationViews = createHook(NavigationStore, {
	selector: createSelector(
		(state, { projectId }: { projectId: string }) => state.projects[projectId],
		(projectState) => (projectState ? projectState.views : []),
	),
});

export const useIsNavigationLoading = createHook(NavigationStore, {
	selector: createSelector(
		(state, { projectId }: { projectId: string }) => state.projects[projectId],
		(projectState) => projectState?.isLoading || false,
	),
});

export const useIsNavigationInitialized = createHook(NavigationStore, {
	selector: createSelector(
		(state, { projectId }: { projectId: string }) => state.projects[projectId],
		(projectState) => projectState?.initialized || false,
	),
});

export const useIsNavigationLocked = createHook(NavigationStore, {
	selector: createSelector(
		(state, { projectId }: { projectId: string }) => state.projects[projectId],
		(projectState) => projectState?.isLocked || false,
	),
});

export const useNavigationView = createHook(NavigationStore, {
	selector: createSelector(
		(state, { projectId }: { projectId: string }) => state.projects[projectId],
		(_, { localId }: { projectId: string; localId: LocalViewId }) => localId,
		(projectState, localId) => findView(projectState, localId)?.view || null,
	),
});

export const useProjectViewsCount = createStateHook(NavigationStore, {
	selector: getProjectViewsCount,
});

export const useHasReachedViewsLimit = createStateHook(NavigationStore, {
	selector: createSelector(
		(_, props: EntityLimitsHookProp) => props,
		getEntityLimits,
		getProjectViewsCount,
		getProjectViewsetsCount,
		({ entityLimitType }, limit, viewsCount, viewsetsCount) => {
			if (!limit) {
				return null;
			}

			if (entityLimitType === PROJECT_ENTITY_LIMIT_TYPE.VIEWS_PER_PROJECT && limit <= viewsCount) {
				return limit;
			}

			if (
				entityLimitType === PROJECT_ENTITY_LIMIT_TYPE.VIEWSETS_PER_PROJECT &&
				limit <= viewsetsCount
			) {
				return limit;
			}

			if (fg('jpd-sidebar-v4-for-roadmaps')) {
				if (
					entityLimitType === COLLECTION_ENTITY_LIMIT_TYPE.VIEWS_PER_COLLECTION &&
					limit <= viewsCount
				) {
					return limit;
				}
			}

			return null;
		},
	),
});
