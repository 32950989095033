import React, { useEffect, useState } from 'react';
import { styled } from '@compiled/react';
import isEqual from 'lodash/isEqual';
import type { DocNode } from '@atlaskit/adf-schema';
import { Emoji } from '@atlaskit/emoji';
import DocumentsIcon from '@atlaskit/icon/glyph/documents';
import InfoIcon from '@atlaskit/icon/core/migration/information--info';
import { Section } from '@atlaskit/menu';
import { ReactRenderer as AkRenderer } from '@atlaskit/renderer';
import { token } from '@atlaskit/tokens';
import FilesIcon from '@atlaskit/icon/core/files';
import { Inline, xcss } from '@atlaskit/primitives';
import { getUnsupportedContentLevelsTracking } from '@atlassian/jira-common-util-unsupported-content/src/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import {
	onLinkClick,
	smartLinksDefault,
} from '@atlassian/jira-linking-platform-utils/src/index.tsx';
import {
	SEVERITY_DEGRADED_THRESHOLD,
	SEVERITY_NORMAL_THRESHOLD,
} from '@atlassian/jira-polaris-common/src/common/constants.tsx';
import type { IdeaTemplateData } from '@atlassian/jira-polaris-common/src/common/types/idea-templates/types.tsx';
import { N0 } from '@atlassian/jira-polaris-lib-color-palette/src/ui/colors/index.tsx';
import { prepareForRender } from '@atlassian/jira-polaris-lib-editor/src/common/utils/adf.tsx';
import { useEmoji } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { messages } from './messages.tsx';

export const IdeaTemplateModal = (props: IdeaTemplateData) => {
	const { color, emoji, title, template, testId } = props;

	const [safeDescription, setSafeDescription] = useState<unknown>(undefined);
	const selectedEmoji = useEmoji(emoji);

	const { formatMessage } = useIntl();

	useEffect(() => {
		const currentDescription = prepareForRender(template);

		if (!isEqual(currentDescription, safeDescription)) {
			setSafeDescription(currentDescription);
		}
	}, [safeDescription, template]);

	const EmptyIcon =
		isVisualRefreshEnabled() && fg('jpd-visual-refresh_icons_polaris') ? (
			<FilesIcon color={token('color.icon.inverse')} label="default_template" />
		) : (
			// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
			<DocumentsIcon primaryColor={N0} label="default_template" />
		);

	return (
		<IdeaTemplateModalWrapper data-testid={testId}>
			<ModalHeader>
				<IconContainer bgColor={color}>
					{selectedEmoji !== undefined ? (
						<Emoji fitToHeight={16} emoji={selectedEmoji} />
					) : (
						EmptyIcon
					)}
				</IconContainer>
				<Inline xcss={modalHeaderTitleStyles}>{title}</Inline>
			</ModalHeader>
			<TemplateContainer isScrollable>
				<AkRenderer
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					document={safeDescription as DocNode}
					analyticsEventSeverityTracking={{
						enabled: true,
						severityDegradedThreshold: SEVERITY_DEGRADED_THRESHOLD,
						severityNormalThreshold: SEVERITY_NORMAL_THRESHOLD,
					}}
					unsupportedContentLevelsTracking={getUnsupportedContentLevelsTracking()}
					useSpecBasedValidator
					disableHeadingIDs
					eventHandlers={{
						smartCard: { onClick: onLinkClick },
						link: { onClick: onLinkClick },
					}}
					featureFlags={{
						'renderer-tti-tracking': fg('renderer_tti_tracking_jira'),
					}}
					smartLinks={smartLinksDefault}
					UNSTABLE_allowTableResizing={expVal(
						'platform_editor_support_table_in_comment_jira',
						'isEnabled',
						false,
					)}
					UNSTABLE_allowTableAlignment={expVal(
						'platform_editor_support_table_in_comment_jira',
						'isEnabled',
						false,
					)}
				/>
			</TemplateContainer>
			<InfoTemplateMessageContainer>
				<InfoIcon spacing="spacious" label="info" color={token('color.text.discovery')} />
				<InfoTemplateMessage>{formatMessage(messages.templateInfoMessage)}</InfoTemplateMessage>
			</InfoTemplateMessageContainer>
		</IdeaTemplateModalWrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IdeaTemplateModalWrapper = styled.div({
	maxHeight: '448px',
	maxWidth: '484px',
	overflowY: 'auto',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ModalHeader = styled.div({
	display: 'flex',
	alignItems: 'center',
	paddingTop: token('space.250'),
	paddingRight: token('space.250'),
	paddingBottom: token('space.250'),
	paddingLeft: token('space.250'),
	boxShadow: 'inset 0px -2px 0px rgb(9 30 66 / 8%)',
});

const modalHeaderTitleStyles = xcss({
	color: 'color.text',
	font: 'font.heading.medium',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InfoTemplateMessageContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	boxShadow: '0px -2px 0px rgb(9 30 66 / 8%)',
	paddingTop: token('space.250'),
	paddingRight: token('space.200'),
	paddingBottom: token('space.250'),
	paddingLeft: token('space.200'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InfoTemplateMessage = styled.span({
	font: token('font.body'),
	fontWeight: token('font.weight.semibold'),
	marginLeft: token('space.100'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.accent.teal.bolder'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconContainer = styled.div<{ bgColor?: string }>({
	minWidth: '32px',
	height: '32px',
	borderRadius: '3px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: ({ bgColor }) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		bgColor !== undefined ? bgColor : token('color.background.selected'),
	marginRight: token('space.100'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TemplateContainer = styled(Section)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	padding: '16px 12px !important',
});
