import React, { memo, useCallback } from 'react';
import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useMatrixActions } from '../../../controllers/index.tsx';
import { useComponents } from '../../../controllers/selectors/components-hooks.tsx';
import {
	useHoveredItemIdsMap,
	useHoveredItemsArea,
} from '../../../controllers/selectors/ui-hooks.tsx';
import type { HoveredItem } from '../../../controllers/types.tsx';
import type { ItemId } from '../../../types.tsx';

type SidebarItemProps = {
	isHovered: boolean;
	itemId: ItemId;
};

const DefaultSidebarItem = memo<SidebarItemProps>(({ itemId, isHovered }: SidebarItemProps) => (
	<Container isHovered={isHovered}>{itemId}</Container>
));

type Props = {
	itemId: ItemId;
	onHeightChange?: () => void;
};

export const SidebarItem = ({ itemId, onHeightChange }: Props) => {
	const components = useComponents();
	const [, actions] = useMatrixActions();
	const hoveredItemIdsMap = useHoveredItemIdsMap();
	const hoveredItemsArea = useHoveredItemsArea();

	const { setHoveredItems } = actions;

	const isHoveredInMatrix = hoveredItemsArea === 'MATRIX' && hoveredItemIdsMap?.[itemId] === true;

	const setHover = useCallback(() => {
		const newHoveredItems: HoveredItem = {
			items: [itemId],
			area: 'SIDEBAR',
		};

		setHoveredItems(newHoveredItems);
	}, [itemId, setHoveredItems]);

	const clearHover = useCallback(() => {
		setHoveredItems();
	}, [setHoveredItems]);

	return (
		// eslint-disable-next-line jsx-a11y/no-static-element-interactions
		<div onMouseEnter={setHover} onMouseLeave={clearHover}>
			{components?.SidebarItem !== undefined ? (
				<components.SidebarItem
					itemId={itemId}
					isHovered={isHoveredInMatrix}
					onHeightChange={onHeightChange}
				/>
			) : (
				<DefaultSidebarItem itemId={itemId} isHovered={isHoveredInMatrix} />
			)}
		</div>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div<{ isHovered: boolean }>({
	paddingTop: token('space.100'),
	paddingRight: token('space.100'),
	paddingBottom: token('space.100'),
	paddingLeft: token('space.100'),
	borderRadius: '3px',
	marginTop: token('space.050'),
	marginRight: token('space.050'),
	marginBottom: token('space.050'),
	marginLeft: token('space.050'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: ({ isHovered }) => (isHovered ? colors.N30 : colors.N10),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	':hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		backgroundColor: colors.N30,
	},
});
