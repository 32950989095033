// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import actions from './actions/index.tsx';
import type { State, Props } from './types.tsx';

export const onInit =
	(): Action<State, Props> =>
	({ setState, dispatch }, props) => {
		setState({
			loadingProps: props,
		});
		dispatch(actions.getCurrentProjectAccessLevel());
		dispatch(actions.getAvailableProjectAccessLevels());
		dispatch(actions.fetchPermissionConfiguration());
		dispatch(actions.loadRoles());
		dispatch(actions.loadContributorPermissionsList());
		dispatch(actions.loadProjectActors());
		dispatch(actions.loadContributorGroupConfig());
	};

export const onUpdate =
	(): Action<State, Props> =>
	({ setState }, props) => {
		setState({
			loadingProps: props,
		});

		// we don't need to reload data on props change, as the relevant data for loading will not be different (e.g. projectId)
	};
